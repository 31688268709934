import { TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { getTableViewType } from '@/modules/module-builder/utils/get-table-view-type'
import { Module } from '@/modules/module/types'
import { getTableViewQuery } from '@/modules/table-view/helpers'
import { TICKET_HIDDEN_REFERENCES } from '@/modules/ticket/columns'
import { tableViewApi } from '@/services/api-service'
import { Button, SearchInput, Select } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { safeJsonParse } from '@/utils'
import { getColumnLabel } from '@/utils/get-column-label'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { nanoid } from 'nanoid'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  module: Module
  onSearchQueryFiltersChange: (searchQueryFilters: string[], order?: string | null) => void
  selectedView: any
  onSelectedViewChange: (selectedView: any) => void
}

export const TicketsFilterSection = ({
  module,
  onSearchQueryFiltersChange,
  onSelectedViewChange,
  selectedView
}: Props) => {
  const { l, labels } = useApp()
  const { pathname } = useLocation()

  const tableViewsQuery = useQuery({
    ...tableViewApi.list({
      'E[]': module?.hidden_views?.length ? [`id__in|${module.hidden_views.join(',')}`] : [],
      include_internal_view_ids: module?.internal_views,
      order: 'name',
      limit: 'None'
    }),

    select: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...item,
        filters: safeJsonParse(item.filters || '[]'),
        customForms: JSON.parse(item.options__custom_forms || '[]')
      }))
    })
  })

  const [searchQueryFilters, setSearchQueryFilters] = useState<
    {
      field: string
      type: string
      keyword: string | null
      id: string
    }[]
  >([
    { field: 'name', type: 'icontains', keyword: null, id: nanoid() },
    { field: 'location__name', type: 'icontains', keyword: null, id: nanoid() }
  ])

  const searchFieldOptions = useMemo(() => {
    const moduleAliases = module.config?.field_aliases ?? {}

    return [
      { label: l('ticket__afe__label', 'AFE No', moduleAliases), value: 'afe__user_afe_no' },
      { label: l('ticket__name__label', 'Name', moduleAliases), value: 'name' },
      { label: l('JobCode', 'Job Code', moduleAliases), value: 'job_code__user_job_code_no' },
      { label: l('Location', 'Location', moduleAliases), value: 'location__name' },
      { label: 'Status', value: 'computed_status' },
      { label: 'Team Member', value: 'contacts__contact__name' },
      { label: 'Customer Company', value: 'customer_office__name' },
      ...TICKET_HIDDEN_REFERENCES.map((col) => ({
        value: col.key,
        label: getColumnLabel(col, labels)
      }))
    ]
  }, [l, module.config?.field_aliases, labels])

  const tableViewsOptions = useMemo(() => {
    const tableViews = tableViewsQuery.data?.items ?? []
    const moduleType = (pathname.split('/')[3] || '').toUpperCase()

    if (!moduleType) {
      console.error('moduleType is not defined', pathname)
      return []
    }

    const viewType = getTableViewType({
      view_type: moduleType,
      data: { source_type: module.data?.source_type || '' }
    })

    const filter = viewType === 'K' ? { T: 1, L: 1 } : { [viewType]: 1 }

    return tableViews
      .filter((view) => filter[view.type as keyof typeof filter])
      .map((item) => ({
        label: item.name,
        value: item.id,
        item
      }))
  }, [tableViewsQuery.data?.items, module.data?.source_type, pathname])

  const filtersQuery = useMemo(() => getTableViewQuery(safeJsonParse(selectedView || {})), [selectedView])

  useEffect(() => {
    const arrayQueryFilters = searchQueryFilters
      .map(({ field, keyword, type }) => {
        if (!keyword) {
          return null
        }

        if (['afe', 'job_code', 'location'].some((f) => field.startsWith(f))) {
          // support for prefixes
          const fieldPrefix = field.split('__')[0]
          return [`${fieldPrefix}_str__${type}|${keyword}`, `${field}__${type}|${keyword}`]
        }

        return `${field}__${type}|${keyword}`
      })
      .filter((val) => Boolean(val))
      .flat() as string[]

    onSearchQueryFiltersChange([...arrayQueryFilters, ...filtersQuery.Q], filtersQuery.order || '-name')
  }, [searchQueryFilters, filtersQuery])

  // set to initial view
  useEffect(() => {
    if (module.selected_view) {
      const selectedView = tableViewsOptions.find((item) => item.value === module.selected_view)
      onSelectedViewChange(selectedView?.item)
    }

    if (!module.selected_view && module.data?.hide_default_none_view && tableViewsOptions.length) {
      onSelectedViewChange(tableViewsOptions[0].item)
    }
  }, [module.selected_view, module.data?.hide_default_none_view, tableViewsOptions])

  return (
    <div className="w-2/5">
      <SectionTitle rounded number={1}>
        Ticket Filter
      </SectionTitle>
      <div className="mt-16">
        <Form.Item label="View">
          <Select
            value={selectedView?.id || 0}
            options={[
              ...(module?.data?.hide_default_none_view ? [] : [{ label: 'Default', value: 0 }]),
              ...tableViewsOptions
            ]}
            onChange={(val, option: any) => {
              if (val === 0) {
                onSelectedViewChange(undefined)
                return
              }

              onSelectedViewChange(option.item)
            }}
          />
        </Form.Item>
        <div className="mb-16">- and -</div>
        {searchQueryFilters.map((queryFilter) => (
          <div className="grid grid-cols-3 gap-x-8" key={queryFilter.id}>
            <Form.Item>
              <Select
                popupMatchSelectWidth={false}
                value={queryFilter.field}
                options={searchFieldOptions}
                onChange={(val) =>
                  setSearchQueryFilters((prev) =>
                    prev.map((item) => (item.id === queryFilter.id ? { ...item, field: val } : item))
                  )
                }
              />
            </Form.Item>
            <Form.Item>
              <Select
                value={queryFilter.type}
                onChange={(val) =>
                  setSearchQueryFilters((prev) =>
                    prev.map((item) => (item.id === queryFilter.id ? { ...item, type: val } : item))
                  )
                }
                options={[
                  { label: 'Contains', value: 'icontains' },
                  { label: 'Equals', value: 'iexact' },
                  { label: 'Is Null', value: 'isnull' }
                ]}
              />
            </Form.Item>
            <div className="flex gap-x-8">
              <Form.Item className="w-full">
                {queryFilter.type !== 'isnull' && queryFilter.field != 'computed_status' && (
                  <SearchInput
                    autoFocus={false}
                    className="w-full md:w-full"
                    onSearch={(text) => {
                      setSearchQueryFilters((prev) =>
                        prev.map((item) => (item.id === queryFilter.id ? { ...item, keyword: text } : item))
                      )
                    }}
                  />
                )}
                {queryFilter.type !== 'isnull' && queryFilter.field === 'computed_status' && (
                  <Select
                    placeholder="Select"
                    popupMatchSelectWidth={false}
                    options={Object.entries(TICKET_STATUS).map(([key, value]) => ({
                      label: value,
                      value: key
                    }))}
                    onChange={(val) =>
                      setSearchQueryFilters((prev) =>
                        prev.map((item) => (item.id === queryFilter.id ? { ...item, keyword: val } : item))
                      )
                    }
                  />
                )}
                {queryFilter.type === 'isnull' && (
                  <Select
                    options={[
                      { label: 'Yes', value: '1' },
                      { label: 'No', value: '0' }
                    ]}
                    onChange={(val) =>
                      setSearchQueryFilters((prev) =>
                        prev.map((item) => (item.id === queryFilter.id ? { ...item, keyword: val } : item))
                      )
                    }
                  />
                )}
              </Form.Item>
              <Button
                iconName="fa:close"
                className="shrink-0"
                type="text"
                onClick={() => setSearchQueryFilters((prev) => prev.filter((item) => item.id !== queryFilter.id))}
              />
            </div>
          </div>
        ))}
        <Button
          iconName="fa:plus"
          onClick={() => {
            setSearchQueryFilters([
              ...searchQueryFilters,
              { field: 'name', type: 'icontains', keyword: null, id: nanoid() }
            ])
          }}
        >
          Add Filter
        </Button>
      </div>
    </div>
  )
}
