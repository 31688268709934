import { Button } from '@/ui'
import { Form, Input } from 'antd'
import { memo } from 'react'

type Props = {
  label: string
  value: string
  onChange: (value: string) => void
  onRemove: () => void
}

// eslint-disable-next-line react/display-name
export const AliasFormItem = memo(
  ({ label, value, onChange, onRemove }: Props) => {
    return (
      <Form.Item label={label}>
        <div className="flex gap-x-8">
          <Input value={value} onChange={(e) => onChange(e.target.value)} />
          <Button iconName="fa:trash" onClick={onRemove} className="shrink-0" />
        </div>
      </Form.Item>
    )
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value
)
