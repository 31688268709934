import { useApp, useSession } from '@/hooks'
import { companyApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useMutation } from '@tanstack/react-query'
import { Modal, Popconfirm, Tooltip } from 'antd'
import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { companyFieldAliasesAtom } from '../../atoms'
import { useUpsertConfiguration } from '../../hooks'
import { LineItemField } from '../../types'
import { useLineItemFieldName } from '../hooks'
import { SortableItemProvider } from './context'
import { Tabs } from './tabs'

type Props = {
  id: LineItemField
  isGrabbing?: boolean
}

export const SortableItem = ({ id, isGrabbing }: Props) => {
  const { company } = useSession()
  const { notification } = useApp()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPopconfirmOpen, setPopconfirmOpen] = useState(false)
  const { upsertConfiguration, createConfigurationMutation, updateConfigurationMutation } = useUpsertConfiguration()
  const { getLineItemFieldName } = useLineItemFieldName()

  const companyFieldAliases = useAtomValue(companyFieldAliasesAtom)
  const setCompanyFieldAliases = useSetAtom(companyFieldAliasesAtom)
  const updateCompanyMutation = useMutation({ ...companyApi.patch() })
  const fieldAliasKeys = [`lineitem__${id}`, `report__${id}`] as const

  const { setNodeRef, listeners, transform, transition, attributes } = useSortable({
    id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const { name, originalName } = getLineItemFieldName(id)

  const updateCompanyFieldAliases = useCallback(async () => {
    return updateCompanyMutation.mutateAsync({
      id: company.id,
      field_aliases: companyFieldAliases
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFieldAliases, company.id])

  const handleOk = async () => {
    await upsertConfiguration()

    if (
      (name !== companyFieldAliases[fieldAliasKeys[0]] && name !== originalName) ||
      (name === originalName && companyFieldAliases[fieldAliasKeys[0]])
    ) {
      setPopconfirmOpen(true)
      return
    }
    setIsModalOpen(false)
  }

  const syncConpanyFieldAliases = async () => {
    if (name === originalName) {
      setCompanyFieldAliases((fieldAliases) => {
        const { [fieldAliasKeys[0]]: _, [fieldAliasKeys[1]]: __, ...companyFieldAliases } = fieldAliases
        return companyFieldAliases
      })
    } else {
      setCompanyFieldAliases((fieldAliases) => {
        fieldAliasKeys.forEach((key) => {
          fieldAliases[key] = name
        })

        return fieldAliases
      })
    }

    await updateCompanyFieldAliases()
    notification.success({ message: 'Labels synchronized at company level' })
    setPopconfirmOpen(false)
    setIsModalOpen(false)
  }

  return (
    <SortableItemProvider lineItemField={id}>
      <li
        ref={setNodeRef}
        style={style}
        {...attributes}
        data-cy="configuration-sortable-item"
        className="list-none m-0 px-4 relative mb-8 last-of-type:mb-0 bg-container h-34 flex rounded border items-center"
      >
        <div
          {...listeners}
          className={classNames('flex h-full items-center grow', {
            'cursor-grabbing': isGrabbing,
            'cursor-grab': !isGrabbing
          })}
        >
          <Button iconName="fa:grip-dots-vertical" type="text" shape="circle" className="cursor-grab" />
          {originalName === name && (
            <Tooltip title={<div className="capitalize">{originalName}</div>}>
              <div>{name}</div>
            </Tooltip>
          )}
          {originalName !== name && (
            <div className="flex items-center">
              <div className="text-primary">{name}</div>
              <span className={'ml-6 text-text-muted text-xs'}>({originalName})</span>
            </div>
          )}
        </div>
        <Button
          iconName="fa:edit"
          type="text"
          shape="circle"
          onClick={() => setIsModalOpen(true)}
          data-cy="configuration-edit-button"
        />
      </li>
      <Modal
        title={`${originalName} settings`}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleOk}
        width={800}
        rootClassName="min-h-[386px]"
        destroyOnClose
        okText="Save"
        okButtonProps={{
          disabled: isPopconfirmOpen,
          loading: createConfigurationMutation.isLoading || updateConfigurationMutation.isLoading,
          'data-cy': 'configuration-save-confirm-button'
        }}
      >
        <Popconfirm
          cancelText="No"
          okText="Yes"
          placement="rightBottom"
          title="Sync to company level labels?"
          open={isPopconfirmOpen}
          onCancel={() => {
            setPopconfirmOpen(false)
            setIsModalOpen(false)
          }}
          onConfirm={syncConpanyFieldAliases}
          okButtonProps={{ loading: updateCompanyMutation.isLoading, disabled: updateCompanyMutation.isLoading }}
        >
          <Tabs />
        </Popconfirm>
      </Modal>
    </SortableItemProvider>
  )
}
