import { useApp } from '@/hooks'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { Provider } from 'jotai'
import { RoleSelect } from './role-select'
import { SearchInput } from './search-input'
import { ProfileListTable } from './table'

export const ProfileListView = () => {
  const { l } = useApp()

  return (
    <Provider>
      <div className="flex items-center justify-between">
        <PageTitle>{l('Users')}</PageTitle>
        <AppLink to={'add'}>
          <Button iconName="mi:add" type="primary">
            Add User
          </Button>
        </AppLink>
      </div>
      <div className="mt-10 flex items-end gap-x-30">
        <RoleSelect />
        <SearchInput />
      </div>

      <div className="mt-16">
        <ProfileListTable />
      </div>
    </Provider>
  )
}
