import { useRuleTemplatesQuery } from '@/hooks'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { searchTextAtom, sortDirectionAtom } from '../atoms'

export const useSortedRuleTemplates = () => {
  const sortDirection = useAtomValue(sortDirectionAtom)
  const searchText = useAtomValue(searchTextAtom)

  const ruleTemplatesQuery = useRuleTemplatesQuery({
    company_id__isnull: 0 // Exclude global rule templates
  })

  const sortedRuleTemplates = useMemo(() => {
    if (!ruleTemplatesQuery.data?.items) {
      return []
    }

    const items = [...ruleTemplatesQuery.data.items]

    if (sortDirection === 'asc') {
      items.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      items.sort((a, b) => b.name.localeCompare(a.name))
    }

    if (searchText) {
      return items.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
    }

    return items
  }, [ruleTemplatesQuery.data?.items, sortDirection, searchText])

  return {
    ruleTemplatesQuery,
    sortedRuleTemplates
  }
}
