import { DeferRender, Icon } from '@/ui'
import { cn } from '@/utils'
import { FC } from 'react'
import { useSettings } from '../hooks'
import { AdvancedModeForm } from './AdvancedModeForm'
import { ScheduledFunctionForm } from './ScheduledFunctionForm'
import { SyncModeForm } from './SyncModeForm'
import { FormatPicker } from './controls'

export const BpaBuilderForm: FC = () => {
  const { isAdvanced, isSimple, scheduled, scheduled_for } = useSettings()

  return (
    <div className={'flex flex-row h-full'}>
      <div className={cn('flex flex-col', isAdvanced ? 'w-full border-l' : 'w-9/12 border-x')}>
        <div className={'flex flex-col px-10'}>
          <h5>
            <Icon name={'fa:arrow-progress'} className={'mr-6'} />
            <span>Automation Logic</span>
          </h5>
          {(isSimple || isAdvanced) && (
            <div className={'my-10 rounded border border-warning p-10 flex flex-row items-center gap-10'}>
              <Icon name="fa:warning" className="text-warning" />
              <div>Warning! The v1 engine is deprecated. Please use the v2 engine.</div>
            </div>
          )}
        </div>
        <div className={'flex flex-col gap-10 overflow-auto px-10'}>
          {isAdvanced ? (
            <AdvancedModeForm />
          ) : scheduled && scheduled_for === 'function' ? (
            <ScheduledFunctionForm />
          ) : (
            <SyncModeForm />
          )}
        </div>
      </div>
      {!isAdvanced && (
        <div className={'w-3/12 h-full'}>
          <DeferRender>
            <FormatPicker />
          </DeferRender>
        </div>
      )}
    </div>
  )
}
