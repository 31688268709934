import { QuerySelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm } from '../../../hooks'
import { AssigneeSelect, Label } from '../../ui'

const KEY = 'assignee'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const AssigneeInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { customForm, isDisabledField, isHiddenField } = useCustomForm()

  if (isHiddenField(KEY) || !customForm.show_assignee) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__assignee__label'} />} {...formItemProps}>
      <AssigneeSelect disabled={isDisabledField(KEY)} {...inputProps} />
    </Form.Item>
  )
}
