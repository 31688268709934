const KEY_NAME = {
  work: 'Job',
  lines: 'Line Items',
  loads: 'Load Board',
  tickets: 'Tickets',
  contacts: 'Contacts',
  inventory: 'Inventory',
  reporting: 'Reporting',
  the_field: 'Records',

  ticket_signature: 'Allow E-Sign Capability',
  email_vendor: 'Secure Vendor Link',
  my_time: 'Allow Individual Time Tracking (My Time)',
  time_manager: 'Allow Time Manager',
  ticket_manager: 'Manage company tickets',
  company_admin: 'Manage users & company information',
  choices_manager: 'Choices Manager',
  module_builder: 'Module Builder',
  qb_input: 'QB Input Page',
  ems_user: 'EMS User',
  roles_manager: 'Roles Manager',
  support: 'OilCommand Support *',
  disable_ticket_reservation: 'Restrict from making tickets on mobile',
  ignore_partial_download: 'Ignore partial line items download',
  use_template: 'Use Template'
}

export const mapName = (key: string) => {
  if (Object.hasOwn(KEY_NAME, key)) {
    return KEY_NAME[key as keyof typeof KEY_NAME]
  }
  return key
}
