import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { useCustomForm, useTicketFlags } from '../../../hooks'
import { PropertySection } from './property-section'

export const PropertyGroups = () => {
  const { customForm } = useCustomForm()
  const { hidePropertyGroup } = useTicketFlags()

  // todo: disabled for now (we should rely on backend to handle this, for the shake of consistency)
  // watch for value changes and re-evaluate scripts
  // useTicketScriptWatch()

  const listRefs = useRef<(HTMLDivElement | null)[]>([])
  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100')
          entry.target.classList.remove('opacity-0')
        }
      })
    }

    // Trigger when 10% of the item is visible
    const observer = new IntersectionObserver(handleIntersect, { threshold: 0.02 })

    // Observe each list item
    listRefs.current.forEach((item) => {
      if (item) observer.observe(item)
    })

    // Clean up observer on unmount
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className={'flex flex-col md:px-10'}>
      {customForm?.property_groups.map((pg, index: any) => (
        <div
          key={pg.id}
          ref={(el) => (listRefs.current[index] = el)}
          className={cn('property-group mb-10 opacity-0 transition-opacity duration-500', pg.print_class)}
        >
          {hidePropertyGroup(pg) ? null : <PropertySection propertyGroup={pg} />}
        </div>
      ))}
    </div>
  )
}
