import { SectionTitle } from '@/ui/section-title'
import { Checkbox, Form } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export const SelectCustomFormsSection = () => {
  const form = useFormInstance()

  return (
    <div className="pt-12">
      <SectionTitle rounded number={2}>
        Select Custom Forms
      </SectionTitle>
      <div className="grid grid-cols-3 mt-12">
        <Form.List name="custom_forms">
          {(fields) =>
            fields.map((field) => (
              <Form.Item key={field.key} name={[field.name, 'checked']} valuePropName="checked" className="mb-10 ml-8">
                <Checkbox>{form.getFieldValue(['custom_forms', field.name, 'name'])}</Checkbox>
              </Form.Item>
            ))
          }
        </Form.List>
      </div>
    </div>
  )
}
