import { useApp } from '@/hooks'
import { ticketApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useButtonContext } from '../../../hooks'

export const SubmitToAccountingButton = () => {
  const { notification } = useApp()
  const { ticket, property, buttonProps, isSaving, saveTicket } = useButtonContext()
  const [submittedList, setSubmittedList] = useState<number[]>([])
  const accountingMutation = useMutation(ticketApi.accounting(ticket.id))

  const handleClick = async () => {
    if (!ticket.id || submittedList.includes(ticket.id)) {
      notification.error({ message: 'Ticket has already been posted to QuickBooks' })
    }

    if (ticket.parent__id) {
      // eslint-disable-next-line quotes
      notification.error({ message: "You can't submit summarized child ticket to QuickBooks" })
    }

    setSubmittedList((prev) => [...prev, ticket.id])
    try {
      await accountingMutation.mutateAsync({ id: ticket.id, service: 'qbo', trigger_source: 'scripted-button' })
      notification.success({ message: 'Success', description: 'Ticket submitted to accounting' })
      const qbSubmittedDateProp = ticket._tpByPropertyKey['QB Submitted Date']
      const payload = { [`p_${qbSubmittedDateProp.property_id}`]: new Date().toISOString() }
      await saveTicket(payload)
    } catch (e: any) {
      if ((e.data || 0).id) {
        notification.warning({ message: 'Ticket has already been posted to QuickBooks' })
        return
      }

      notification.error({ message: 'Failed to submit ticket to accounting' })
    }
  }

  return (
    <Button {...buttonProps} loading={isSaving} onClick={handleClick}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
