import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { isEqual } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { bpaConfigAtom } from '../atoms'
import { BPA_DEFAULT_SCHEDULE_RULE } from '../constants'
import { BpaConfigMode, BpaScheduleFilterCriteria, BpaScheduleRule } from '../schemas'

export const useSettings = () => {
  const [enableBpa, mode, scheduled, scheduled_for, cron_spec, run_as, cron_timezone, scheduleFilterCriteria] =
    useAtomValue(
      useMemo(
        () =>
          selectAtom(
            bpaConfigAtom,
            (config) => [
              config.enableBpa,
              config.mode,
              config.scheduled,
              config.schedule_rules?.scheduled_for,
              config.schedule_rules?.cron_spec,
              config.schedule_rules?.run_as,
              config.schedule_rules?.timezone,
              config.schedule_rules?.filter_criteria
            ],
            isEqual
          ),
        []
      )
    )

  return {
    enableBpa,
    mode,
    isSync: mode === 'sync',
    isPipeline: mode === 'pipeline',
    isAdvanced: mode === 'advanced',
    isSimple: mode === 'simple',
    scheduled,
    scheduled_for: scheduled_for ?? BPA_DEFAULT_SCHEDULE_RULE.scheduled_for,
    cron_spec,
    run_as,
    cron_timezone,
    scheduleFilterCriteria
  }
}

export const useSettingsHandlers = () => {
  const setConfig = useSetAtom(bpaConfigAtom)

  const onEnableBpaChange = useCallback(
    (value: boolean) => {
      setConfig((config) => {
        config.enableBpa = value
      })
    },
    [setConfig]
  )

  const onModeChange = useDebouncedCallback((value: BpaConfigMode) => {
    setConfig((config) => {
      config.mode = value
    })
  }, 200)

  const onScheduledBpaChange = useCallback(
    (value: boolean) => {
      setConfig((config) => {
        config.scheduled = value
        if (config.scheduled && !config.schedule_rules) config.schedule_rules = BPA_DEFAULT_SCHEDULE_RULE
      })
    },
    [setConfig]
  )

  const onScheduledForChange = useCallback(
    (value: BpaScheduleRule['scheduled_for']) => {
      setConfig((config) => {
        if (config.schedule_rules) config.schedule_rules.scheduled_for = value
      })
    },
    [setConfig]
  )

  const onCronSpecChange = useCallback(
    (value: string) => {
      setConfig((config) => {
        if (config.schedule_rules) config.schedule_rules.cron_spec = value
      })
    },
    [setConfig]
  )

  const onRunAsChange = useCallback(
    (value: number) => {
      setConfig((config) => {
        if (config.schedule_rules) config.schedule_rules.run_as = value
      })
    },
    [setConfig]
  )

  const onCronTimezoneChange = useCallback(
    (value: string) => {
      setConfig((config) => {
        if (config.schedule_rules) config.schedule_rules.timezone = value
      })
    },
    [setConfig]
  )

  const onScheduleFilterCriteriaChange = useCallback(
    (value: BpaScheduleFilterCriteria) => {
      setConfig((config) => {
        if (config.schedule_rules) config.schedule_rules.filter_criteria = value
      })
    },
    [setConfig]
  )

  return {
    onEnableBpaChange,
    onScheduledBpaChange,
    onScheduledForChange,
    onCronSpecChange,
    onRunAsChange,
    onCronTimezoneChange,
    onScheduleFilterCriteriaChange,
    onModeChange
  }
}
