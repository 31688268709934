import { mapName } from '@/modules/manage-user/utils/mapName'
import { Switch } from 'antd'
import { useAtomValue } from 'jotai'
import { useFetchProfile } from '../../../hooks'
import { selectedRoleIdAtom } from '../../atoms'

type DataType = {
  id: number
  name?: string
  title?: string
}
type ToggleSwitchProps = {
  data: string | DataType
  checked: boolean
  id: string
  onChangeRoleToggleModal: (x: boolean) => void
  onEnableRoleToggleModal: (x: boolean) => void
  onChange: ({ data, state }: { data: string; state: boolean }) => void
}
export const ToggleSwitch = ({
  data,
  checked,
  id,
  onChange,
  onChangeRoleToggleModal,
  onEnableRoleToggleModal
}: ToggleSwitchProps) => {
  const { data: profileData } = useFetchProfile()
  const selectedRoleId = useAtomValue(selectedRoleIdAtom)
  const currentRoleId = profileData?.role

  const checkPermission = () => {
    if (currentRoleId !== selectedRoleId && selectedRoleId !== 0) {
      // enableRoleModal(true)

      onEnableRoleToggleModal(true)
      return false
      // eslint-disable-next-line no-extra-boolean-cast
    } else if (!!selectedRoleId) {
      onChangeRoleToggleModal(true)
      return false
    } else {
      return true
    }
  }
  const handleChange = (data: string | DataType) => {
    const pass = checkPermission()
    if (!pass) return false
    let selectedData = null
    if (typeof data === 'object') {
      selectedData = data.id?.toString()
    } else {
      selectedData = data
    }
    onChange({
      data: selectedData,
      state: pass
    })
  }

  let name = ''
  if (typeof data === 'object') {
    if ('name' in data) {
      name = data.name as string
    }
    if ('title' in data) {
      name = data.title as string
    }
  } else {
    name = data
  }
  return (
    <>
      <div className="flex justify-between items-center w-full">
        <label htmlFor={id}>{mapName(name)}</label>
        <Switch checked={checked} id={id} onClick={() => handleChange(data)} />
      </div>
    </>
  )
}
