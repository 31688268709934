import { z } from 'zod'
import { PropertySchema, TicketPropertySchema } from '../../ticket/form/schemas'

export const SECURE_ACCESS_STATUS = {
  DISPUTED: 'D',
  SIGNED: 'S'
}

export const SECURE_ACCESS_TYPE = {
  SIGNATURE: 'S',
  VENDOR: 'V'
}

export const SecureAccessSchema = z.object({
  id: z.number(),
  type: z.enum(['S', 'V']),
  status: z.enum(['D', 'S']).nullable(),
  name: z.string(),
  email: z.string(),
  emailed_at: z.string(),
  print_signature: z.string(),
  contact__id: z.number(),
  property__id: z.number(),
  modified_at: z.string(),
  data: z
    .object({
      action_by: z.string().nullish(),
      timestamp: z.string().nullish(),
      _local_field: z.boolean()
    })
    .nullish()
})

export const SecureAccessWaitListSchema = z.object({
  id: z.number(),
  signed_url: z.string(),
  ticket__name: z.string(),
  ticket__custom_form__name: z.string(),
  ticket__location__name: z.string()
})

const CompanySchema = z.object({
  id: z.number(),
  name: z.string(),
  icon: z.string(),
  field_aliases: z.record(z.string())
})

const CustomFormSchema = z.object({
  id: z.number(),
  name: z.string()
})

export const SecureTicketAccessSchema = z.object({
  id: z.number(),
  name: z.string(),
  total: z.number(),
  custom_form__name: z.string(),
  office__name: z.string(),
  office__address__line1: z.string(),
  office__address__city: z.string(),
  office__address__state: z.string(),
  office__address__zipcode: z.string(),
  office__phone_number: z.string(),
  customer_office__name: z.string(),
  secure_access: SecureAccessSchema,
  company: CompanySchema,
  properties: z.array(TicketPropertySchema),
  custom_form_properties: z.array(PropertySchema)
})

export type SecureTicketAccess = z.infer<typeof SecureTicketAccessSchema>
export type SecureAccessWaitList = z.infer<typeof SecureAccessWaitListSchema>
export type Company = z.infer<typeof CompanySchema>
export type CustomForm = z.infer<typeof CustomFormSchema>
export type SecureAccess = z.infer<typeof SecureAccessSchema>
export type Property = z.infer<typeof PropertySchema>
