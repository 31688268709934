import { ConfigureTab as ConfigureTabComponent } from '@/modules/table-view/views/edit-table-view-view/configure-tab'
import { Property } from '@/modules/table-view/views/edit-table-view-view/types'
import { view } from '@/routing'
import { TableView } from '@/types/table-view'
import { useOutletContext } from 'react-router-dom'

export const ConfigureTab = view(Component, {
  title: () => 'Configure'
})

function Component() {
  const context = useOutletContext<{ tableView?: TableView; properties: Property[] }>()
  return (
    !!context && (
      <ConfigureTabComponent properties={context.properties} tableView={context.tableView} className="pl-5" />
    )
  )
}
