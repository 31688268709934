export const STYLE_DEFS = [
  { key: 'h1', label: 'H1 size (pt.)', css: 'h1 { font-size: {0}pt; }' },
  { key: 'h2', label: 'H2 size (pt.)', css: 'h2 { font-size: {0}pt; }' },
  { key: 'text', label: 'text size (pt.)', css: '* { font-size: {0}pt; }' },
  { key: 'sig-foot', label: 'Signature detail size (pt.)', css: '[oc-signature-footer] { font-size: {0}pt; }' },
  { key: 'p-title', label: 'Property title (pt.)', css: '[oc-property-title] { font-size {0}pt; }' },
  { key: 'margin-top', label: 'Additional margin top (in.)', css: 'body { padding-top: {0}in; }' },
  { key: 'sep-thick', label: 'Separator thickness (pt.)', css: '.oc-separator-start { border-top-width: {0}pt; }' },
  {
    key: 'sep-margin',
    label: 'Separator margin (in.)',
    css: '.oc-separator-start { margin-top: {0}in; padding-top: {0}in; }'
  },
  {
    key: 'image-h',
    label: 'Embedded image height (in.)',
    css: 'oc-property[oc-property-type="Image"] > div[oc-signature-area] { height: {0}in !important; }'
  }
] as const
