import { useImpersonate, useIsSuperAdmin } from '@/modules/auth/hooks'
import { profileApi } from '@/services/api-service'
import { QueryDeleteButton, QueryEditButton } from '@/ui'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Space, Tooltip } from 'antd'
type Props = {
  profileId: number
  profile: number
}

export const EditColumn = ({ profileId, profile }: Props) => {
  const isSuperAdmin = useIsSuperAdmin()

  const impersonate = useImpersonate()
  return (
    <Space size={4}>
      {isSuperAdmin && (
        <Tooltip title="Impersonate" mouseEnterDelay={0.5} className="cursor-pointer">
          <Button
            icon={<Icon name="fa:user" />}
            type="text"
            className="cursor-pointer"
            onClick={() => impersonate.create(profile)}
          />
        </Tooltip>
      )}

      <AppLink to={`${profileId}/edit`}>
        <Tooltip title="Edit" mouseEnterDelay={0.5} className="cursor-pointer">
          <QueryEditButton id={profileId.toString()} name={'Edit'} />
        </Tooltip>
      </AppLink>
      {isSuperAdmin && (
        <Tooltip title="Delete" mouseEnterDelay={0.5}>
          <QueryDeleteButton id={profileId.toString()} api={profileApi.delete} name={'Profile'} />
        </Tooltip>
      )}
    </Space>
  )
}
