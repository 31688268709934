import { CrontabEditorModel } from '@/components/crontab-editor-model'
import { UserSelect } from '@/components/user-select'
import { useSession } from '@/hooks'
import { timezoneApi } from '@/services/api-service'
import { Button, Icon, Select } from '@/ui'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { SettingOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Form, Switch } from 'antd'
import { startCase } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BPA_MODES_LIST, BPA_SCHEDULE_FILTER_FIELD, BPA_SCHEDULE_FILTER_VALUE } from '../../constants'
import { useSettings, useSettingsHandlers } from '../../hooks'
import { BpaScheduleFilterCriteria } from '../../schemas'
import { ScheduleFilterCriteriaModal } from './ScheduleFilterCriteria'

export const SettingsCard: FC = () => {
  const { user } = useSession()
  const timezonesQuery = useQuery({ ...timezoneApi.list() })
  const [isCronModalOpen, setCronModalOpen] = useState<boolean>(false)
  const [isScheduleFilterModalOpen, setScheduleFilterModalOpen] = useState<boolean>(false)
  const {
    enableBpa,
    mode,
    isSync,
    scheduled,
    scheduled_for,
    cron_spec,
    run_as,
    cron_timezone,
    scheduleFilterCriteria
  } = useSettings()
  const {
    onEnableBpaChange,
    onScheduledBpaChange,
    onScheduledForChange,
    onCronSpecChange,
    onRunAsChange,
    onCronTimezoneChange,
    onScheduleFilterCriteriaChange,
    onModeChange
  } = useSettingsHandlers()
  const [isShowAllTimezones] = useState(false)

  const timezoneOptions = useMemo(() => {
    const items = timezonesQuery.data?.items ?? ({} as Record<string, boolean>)

    const timezones = Object.keys(items).map((timezone) => ({
      label: timezone,
      value: timezone
    }))

    return isShowAllTimezones ? timezones : timezones.filter((timezone) => timezone.label.includes('America'))
  }, [isShowAllTimezones, timezonesQuery.data?.items])

  const scheduleFilterCriteriaDisplay = useMemo(
    () => scheduleFilterCriteria && humanizeScheduleFilterCriteria(scheduleFilterCriteria),
    [scheduleFilterCriteria]
  )

  const onModeChangeDebounced = useDebouncedCallback(onModeChange, 200)
  const onScheduleFilterModalClose = (value: BpaScheduleFilterCriteria, saveChanges: boolean) => {
    setScheduleFilterModalOpen(false)
    if (saveChanges) onScheduleFilterCriteriaChange(value)
  }

  if (scheduled && !run_as) {
    onRunAsChange(Number(user?.id))
  }

  return (
    <div className="mb-20">
      <h5>
        <Icon name={'fa:gears'} className={'mr-4'} /> Automation Settings
      </h5>
      <div className="rounded border p-10">
        <div className="flex flex-col gap-10">
          <div className="flex flex-row">
            <Form.Item label="Enable" className="w-5/12" tooltip="Same as Enable BPA">
              <Switch data-cy="enable-bpa-switch" checked={enableBpa} onChange={onEnableBpaChange} />
            </Form.Item>
            <Form.Item data-cy="engine-switch" key={mode} label="Mode" className="w-7/12" tooltip="Same as BPA Engine">
              <Select defaultValue={mode} onChange={onModeChangeDebounced} options={BPA_MODES_LIST} />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            {isSync && (
              <Form.Item label="Scheduled" className="w-5/12" tooltip="Runs periodically in the background">
                <Switch checked={scheduled} onChange={onScheduledBpaChange} />
              </Form.Item>
            )}
            {isSync && scheduled && (
              <Form.Item label="Scheduled For" className="w-7/12">
                <Select
                  showCopyText={false}
                  allowClear={false}
                  defaultValue={scheduled_for}
                  onChange={onScheduledForChange}
                  options={[
                    { value: 'automation', label: 'Automation' },
                    { value: 'function', label: 'Function' }
                  ]}
                />
              </Form.Item>
            )}
          </div>
          {isSync && scheduled && (
            <>
              <Form.Item label="Run As" rules={[{ required: true }]} required>
                <UserSelect allowClear={false} status={'active'} value={run_as || undefined} onChange={onRunAsChange} />
              </Form.Item>
              <Form.Item label="Timezone">
                <Select defaultValue={cron_timezone} onChange={onCronTimezoneChange} options={timezoneOptions} />
              </Form.Item>
            </>
          )}
          {isSync && scheduled && cron_spec && (
            <Form.Item>
              <Button type="primary" size="small" onClick={() => setCronModalOpen(true)} icon={<SettingOutlined />}>
                Repeat
              </Button>
              <CrontabEditorModel
                isOpen={isCronModalOpen}
                onChange={onCronSpecChange}
                cronExpression={cron_spec}
                onClose={() => setCronModalOpen(false)}
              />
              <div className="mt-8 text-sm">
                Repeats: {cron_spec}, {humanizeCronExpr(cron_spec)}
              </div>
            </Form.Item>
          )}
          {isSync && scheduled && scheduleFilterCriteria && scheduled_for === 'automation' && (
            <Form.Item>
              <Button
                type="primary"
                size="small"
                onClick={() => setScheduleFilterModalOpen(true)}
                icon={<SettingOutlined />}
              >
                Date Range Criteria
              </Button>
              <ScheduleFilterCriteriaModal
                isOpen={isScheduleFilterModalOpen}
                value={scheduleFilterCriteria}
                onClose={onScheduleFilterModalClose}
              />
              <div className="mt-8 text-sm">Filters: {scheduleFilterCriteriaDisplay}</div>
            </Form.Item>
          )}
        </div>
      </div>
    </div>
  )
}

const humanizeScheduleFilterCriteria = (criteria: BpaScheduleFilterCriteria): string => {
  const fieldLabel = BPA_SCHEDULE_FILTER_FIELD.filter((f) => f.value === criteria.field)[0].label
  const value1Label = BPA_SCHEDULE_FILTER_VALUE.filter((f) => f.value == criteria.value1)[0].label
  const operator = criteria.exclude ? 'not in' : 'in'

  if (['past-days', 'past-months'].includes(criteria.value1)) {
    const endix = startCase(criteria.value1.split('-')[1])

    return `${fieldLabel} ${operator} Past ${criteria.value2} ${endix}`
  }

  return `${fieldLabel} ${operator} ${value1Label}`
}
