import { ViewTicketLink } from '@/modules/ticket/components'
import { JasResourceEvent } from '@/types/jas-resource'
import { Button } from '@/ui/button'
import { Popover, Table } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

type Props = {
  events: JasResourceEvent[]
  name: string
  date: Dayjs
}

export const JobsHoursPopover = ({ events, name, date }: Props) => {
  const dataSource = useMemo(() => {
    return events.map(({ start_datetime, end_datetime, job_ticket, id }) => {
      const startDate = dayjs(start_datetime)
      const endDate = dayjs(end_datetime)

      let totalHours = 0

      if (startDate.isSame(endDate, 'day')) {
        totalHours = endDate.diff(startDate, 'hour')
      } else if (startDate.isSame(date, 'day')) {
        totalHours = date.endOf('day').diff(startDate, 'hour') + 1 // add 1 hour to calculate from 00:00
      } else if (endDate.isSame(date, 'day')) {
        totalHours = endDate.diff(date.startOf('day'), 'hour')
      } else {
        totalHours = 24
      }

      return {
        totalHours, // todo: Take into account minutes?
        startDate: startDate.isSame(date, 'day') ? startDate : date.startOf('day'),
        endDate: endDate.isSame(date, 'day') ? endDate : date.endOf('day'),
        location: job_ticket?.location_str,
        ticketNo: job_ticket?.name,
        key: id,
        ticketId: job_ticket?.id,
        customFormId: job_ticket?.custom_form
      }
    })
  }, [events, date])

  return (
    <Popover
      title={name}
      trigger={['click']}
      placement="bottomRight"
      arrow={true}
      content={
        <div>
          <Table size="small" dataSource={dataSource} pagination={false}>
            <Table.Column title="#" render={(_, __, index) => index + 1} />
            <Table.Column
              title="Ticket"
              render={(_, record: (typeof dataSource)[number]) => (
                <ViewTicketLink
                  ticketId={record.ticketId}
                  formId={record.customFormId}
                  target="_blank"
                  className="text-neutral hover:!text-neutral !underline"
                >
                  {record.ticketNo}
                </ViewTicketLink>
              )}
            />
            <Table.Column
              title="Period"
              render={(_, record: (typeof dataSource)[number]) => (
                <div>
                  {record.startDate.format('HH:mm')} - {record.endDate.format('HH:mm')}
                </div>
              )}
            />
            <Table.Column
              title="Time"
              align="center"
              render={(_, record: (typeof dataSource)[number]) => <div>{record.totalHours}h</div>}
            />
            <Table.Column title="Location" dataIndex="location" />
          </Table>
        </div>
      }
    >
      <div className="absolute top-4 right-4 z-10">
        <Button iconName="fa:ellipsis-vertical" type="text" />
      </div>
    </Popover>
  )
}
