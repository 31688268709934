import { useApp, useSession } from '@/hooks'
import { importExportFileApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { FormInstance } from 'antd'

type Params = {
  type: 'I' | 'E'
  form: FormInstance<any>
}

export const useImportExportFileMutation = ({ form, type }: Params) => {
  const { notification } = useApp()
  const { company } = useSession()
  const importExportFileMutation = useMutation(importExportFileApi.create())

  const handleSubmitImportExportFile = async () => {
    const { table, ticket_name_start, ticket_name_end, fields, with_inactive, file_name, url, file } =
      await form.validateFields()

    const formData = new FormData()

    const data = {
      type,
      table,
      url,
      file_name,
      company_id: company.id,
      ...(table === 'I' && {
        ticket_name_start,
        ticket_name_end,
        fields: fields
      }),
      ...(table === 'S' && {
        with_inactive
      }),
      ...(table === 'AD' && {
        table: 'A',
        delivery_location: true
      })
    }

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })

    if (type === 'I') {
      formData.append('file', file.file)
      formData.set('file_name', file.file.name)
    }

    try {
      const importExport = await importExportFileMutation.mutateAsync(formData)

      notification.success({
        message: type === 'E' ? 'File is ready to download' : 'File imported successfully',
        description: importExport.file_name
      })

      form.setFieldsValue({
        url: importExport.url,
        file_name: importExport.file_name
      })
    } catch (error: any) {
      const errorDescription =
        error?.response?.data?.non_field_errors?.[0] || error?.response?.data[0] || error.toString()
      notification.error({
        message: type === 'E' ? 'Failed to download file' : 'Failed to import file',
        description: errorDescription
      })
    }
  }

  return {
    importExportFileMutation,
    handleSubmitImportExportFile
  }
}
