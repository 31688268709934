import { FIELD_LABELS, TICKET_LABELS } from '@/constants/labels'
import { useApp } from '@/hooks'
import { Checkbox, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { useCustomFormQuery, useUpdateCustomFormMutation } from '../hooks'
import { PlaceholderBox } from '../placeholder-box'

type Props = {
  labelFilter: string
  ticketLabelKey: keyof typeof TICKET_LABELS
  staticLabel: string
  fieldsConfigKey: string
  hideFieldsConfig?: boolean
}

export const EditRelatedLabelModal = ({
  ticketLabelKey,
  labelFilter,
  staticLabel,
  fieldsConfigKey,
  hideFieldsConfig
}: Props) => {
  const { customFormQuery } = useCustomFormQuery()
  const { updateCustomFormMutation } = useUpdateCustomFormMutation()
  const [isOpen, setIsOpen] = useState(false)
  const [fieldAliasesForm] = Form.useForm()
  const [fieldsConfigForm] = Form.useForm()
  const { notification } = useApp()
  const ticketLabel = TICKET_LABELS[ticketLabelKey]
  const { name } = customFormQuery.data ?? {}
  const regex = new RegExp(labelFilter)
  const userdata = JSON.parse(customFormQuery.data?.userdata_json ?? '{}')
  const primaryCompanyFields = customFormQuery.data?.primary_company__fields ?? {}
  const fieldAliases = userdata.field_aliases ?? {}
  const fieldsConfig = userdata.fields_config ?? {}
  const relatedLabels = Object.entries(FIELD_LABELS).filter(([_, value]) => regex.test(value))

  const singularLabels = relatedLabels
    .filter(([_, value]) => !value.endsWith('s'))
    .map(([key, value]) => ({ key, value }))

  const pluralLabels = relatedLabels.filter(([_, value]) => value.endsWith('s')).map(([key, value]) => ({ key, value }))
  const formItems = [singularLabels, pluralLabels]
  const ticketLabelToRender = fieldAliases[ticketLabelKey] || primaryCompanyFields[ticketLabelKey] || ticketLabel

  const handleUpdateAllSingular = (value: string) => {
    singularLabels.forEach(({ key }) => {
      fieldAliasesForm.setFieldsValue({ [key]: value })
    })
  }

  const handleUpdateAllPlural = (value: string) => {
    pluralLabels.forEach(({ key }) => {
      fieldAliasesForm.setFieldsValue({ [key]: value })
    })
  }

  const handleSave = async () => {
    const fieldAliasesValues = await fieldAliasesForm.validateFields()
    const fieldsConfigValues = await fieldsConfigForm.validateFields()

    const newUserData = {
      ...userdata,
      field_aliases: {
        ...fieldAliases,
        ...fieldAliasesValues
      },
      fields_config: {
        ...fieldsConfig,
        [fieldsConfigKey]: {
          ...fieldsConfig[fieldsConfigKey],
          ...fieldsConfigValues
        }
      }
    }

    const userDataJson = JSON.stringify(newUserData)

    await updateCustomFormMutation.mutateAsync({
      id: customFormQuery.data?.id ?? '',
      userdata_json: userDataJson
    })

    notification.success({ message: 'Custom form labels updated' })
    setIsOpen(false)
  }

  return (
    <>
      <PlaceholderBox onClick={() => setIsOpen(true)} data-cy={`edit-related-label-modal-${staticLabel}`}>
        <div>&quot;{ticketLabelToRender}&quot;</div>
        <div>{staticLabel}</div>
      </PlaceholderBox>
      <Modal
        width={800}
        open={isOpen}
        title={`Edit Related Labels: ${labelFilter}`}
        okText="Save"
        onCancel={() => setIsOpen(false)}
        onOk={handleSave}
        destroyOnClose
        styles={{
          body: {
            maxHeight: 'calc(100vh - 276px)',
            overflowY: 'auto'
          }
        }}
      >
        <p>
          Edits will affect <span className="font-bold">{name}</span> custom_form-wide
        </p>
        <Form layout="vertical" initialValues={fieldAliases} form={fieldAliasesForm}>
          <h5 className="font-bold">Set All:</h5>
          <div className="grid grid-cols-3 gap-x-16">
            {singularLabels.length > 0 && (
              <Form.Item label="Singular">
                <Input
                  data-cy="edit-related-label-modal-singular"
                  placeholder="Singular"
                  onChange={(e) => handleUpdateAllSingular(e.target.value)}
                />
              </Form.Item>
            )}
            {pluralLabels.length > 0 && (
              <Form.Item label="Plural">
                <Input
                  data-cy="edit-related-label-modal-plural"
                  placeholder={pluralLabels[0].value}
                  onChange={(e) => handleUpdateAllPlural(e.target.value)}
                />
              </Form.Item>
            )}
            <Form.Item label="Custom Label" name={ticketLabelKey}>
              <Input data-cy="edit-related-label-modal-custom-label" />
            </Form.Item>
          </div>
          <h5 className="font-bold">Set Individual:</h5>
          <div className="grid grid-cols-2 gap-x-16">
            {formItems.map((items, index) => (
              <div key={index}>
                {items.map(({ key, value }) => (
                  <Form.Item key={key} label={key} name={key}>
                    <Input data-cy={`edit-related-label-modal-${key}`} placeholder={value} />
                  </Form.Item>
                ))}
              </div>
            ))}
          </div>
        </Form>
        {!hideFieldsConfig && (
          <Form layout="vertical" form={fieldsConfigForm} initialValues={fieldsConfig[fieldsConfigKey] ?? {}}>
            <div className="grid grid-cols-3">
              <Form.Item className="mb-0" name="hide_on_mobile" valuePropName="checked">
                <Checkbox data-cy="edit-related-label-modal-hide-on-mobile">Hide (Mobile)</Checkbox>
              </Form.Item>
              <Form.Item className="mb-0" name="hide_on_web" valuePropName="checked">
                <Checkbox data-cy="edit-related-label-modal-hide-on-web">Hide (Web)</Checkbox>
              </Form.Item>
              <Form.Item className="mb-0" name="hide_on_print" valuePropName="checked">
                <Checkbox data-cy="edit-related-label-modal-hide-on-print">Hide (Print)</Checkbox>
              </Form.Item>
            </div>
          </Form>
        )}
      </Modal>
    </>
  )
}
