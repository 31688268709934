import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MouseSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useAtomValue } from 'jotai'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { lineItemFieldsAtom, searchTextAtom } from '../atoms'
import { ContainerKey, LineItemField } from '../types'
import { DroppableContainer } from './droppable-container'
import { useCollisionDetectionStrategy, useHandleDragEnd, useHandleDragOver, useLineItemFieldName } from './hooks'
import { MoveAllItems } from './move-all-items'
import { SortableItem } from './sortable-item'

export const DragAndDropFields = () => {
  const searchText = useAtomValue(searchTextAtom)
  const lineItemFields = useAtomValue(lineItemFieldsAtom)
  const [containers] = useState(Object.keys(lineItemFields) as ContainerKey[])
  const [activeId, setActiveId] = useState<LineItemField | null>(null)
  const { collisionDetectionStrategy } = useCollisionDetectionStrategy({ activeId })
  const sensors = useSensors(useSensor(MouseSensor))
  const { handleDragOver } = useHandleDragOver()
  const { handleDragEnd } = useHandleDragEnd({ setActiveId })
  const { getLineItemFieldName } = useLineItemFieldName()

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id as LineItemField)
  }

  const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.5'
        }
      }
    })
  }

  const filteredLineItemFields = lineItemFields.available.filter((lineItemField) =>
    getLineItemFieldName(lineItemField).name.toLowerCase().includes(searchText.toLowerCase().trim())
  )

  const ref = useRef<HTMLDivElement>(null)

  return (
    <div className="flex gap-x-12" ref={ref}>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <DroppableContainer
          key={containers[0]}
          id={containers[0]}
          title="Available Line Item Fields:"
          items={lineItemFields.available}
        >
          <SortableContext items={lineItemFields.available} strategy={verticalListSortingStrategy}>
            {filteredLineItemFields.map((value) => {
              return <SortableItem key={value} id={value} />
            })}
          </SortableContext>
        </DroppableContainer>
        <MoveAllItems />
        <DroppableContainer
          key={containers[1]}
          id={containers[1]}
          title="Selected Line Item Fields:"
          items={lineItemFields.selected}
        >
          <SortableContext items={lineItemFields.selected} strategy={verticalListSortingStrategy}>
            {lineItemFields.selected.map((value) => {
              return <SortableItem key={value} id={value} />
            })}
          </SortableContext>
        </DroppableContainer>
        {ref.current &&
          createPortal(
            <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
              {activeId ? <SortableItem id={activeId} isGrabbing /> : null}
            </DragOverlay>,
            ref.current
          )}
      </DndContext>
    </div>
  )
}
