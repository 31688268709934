import { NOTIFICATION_TEMPLATE_STATUS, NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { useSession } from '@/hooks'
import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { companyApi, notificationTemplateApi } from '@/services/api-service'
import { AppLink, Button, PageTitle, QueryDeleteButton, QuerySelect, QueryTable, SearchInput, Select } from '@/ui'
import { Form, Space } from 'antd'
import { useState } from 'react'

export const NotificationTemplatesListView = () => {
  const [searchText, setSearchText] = useState('')
  const [filter, setFilter] = useState('')
  const [companyFilter, setCompanyFilter] = useState<number | null>(null)
  const { company, user } = useSession()

  return (
    <div>
      <PageTitle>Notification Templates</PageTitle>
      <Form layout="vertical">
        <div className="flex items-center gap-x-12 mt-12">
          <Form.Item label="Search Templates">
            <SearchInput onSearch={setSearchText} />
          </Form.Item>
          <Form.Item label="Filter by SMS & Email">
            <Select
              placeholder="Select"
              options={Object.entries(NOTIFICATION_TEMPLATE_TYPE).map(([key, value]) => ({ label: value, value: key }))}
              onChange={setFilter}
            />
          </Form.Item>
          {user?.is_superuser && (
            <Form.Item label="Company">
              <QuerySelect
                apiEndpoint={companyApi.list}
                apiSearchBy="name"
                className="min-w-[200px]"
                placeholder="Select"
                popupMatchSelectWidth={false}
                onChange={(values) => setCompanyFilter(values)}
                renderOption={(company) => ({ value: company.id, label: company.name })}
              />
            </Form.Item>
          )}
          <AppLink to={SETTINGS_PATHS.addNotificationTemplate} className="ml-auto">
            <Button iconName="fa:add" type="primary">
              Notification Template
            </Button>
          </AppLink>
        </div>
      </Form>
      <QueryTable
        queryApi={notificationTemplateApi.list}
        queryParams={{
          ...(!user?.is_superuser && { company__eq: company.id }),
          ...(searchText && { name__icontains: searchText }),
          ...(filter && { type__iexact: filter }),
          ...(companyFilter && { company__eq: companyFilter })
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: false
          },
          {
            title: 'Company',
            dataIndex: 'company__name',
            sorter: false
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: (type: keyof typeof NOTIFICATION_TEMPLATE_TYPE) => NOTIFICATION_TEMPLATE_TYPE[type]
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (status: keyof typeof NOTIFICATION_TEMPLATE_STATUS) => NOTIFICATION_TEMPLATE_STATUS[status]
          },
          {
            dataIndex: 'id',
            align: 'right',
            sorter: false,
            render: (id: any) => (
              <Space>
                <AppLink to={SETTINGS_PATHS.editNotificationTemplate(id)}>
                  <Button type="text" iconName="fa:edit"></Button>
                </AppLink>
                <QueryDeleteButton api={notificationTemplateApi.delete} id={id} />
              </Space>
            )
          }
        ]}
        loading={false}
        pagination={false}
        rowKey="id"
      />
    </div>
  )
}
