import { Select } from '@/ui'
import { Button } from '@/ui/button'
import { Checkbox, Form, Input, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { CodeEditor } from '../code-editor'
import { useUpsertUserData, useUserDataQuery } from './hooks'

type Props = {
  onChange: (value: number) => void
  value?: number | null
}

export const DataSourceManager = ({ onChange, value }: Props) => {
  const { userDataQuery } = useUserDataQuery()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const options = userDataQuery.data?.items.map((item) => ({ label: item.name, value: item.id })) ?? []
  const { mutation, upsertUserData } = useUpsertUserData()

  const [form] = Form.useForm<{
    id: number | undefined | null
    name: string
    content: string
    use_binding: boolean
    multiselect: boolean
    config: string
  }>()

  const isUseBinding = Form.useWatch('use_binding', form)

  const optionsWithAddNew = [
    ...options,
    {
      label: 'Create new source',
      value: 'new'
    }
  ]

  const handleSubmit = async () => {
    try {
      const { content, ...rest } = await form.validateFields()
      await upsertUserData({
        ...rest,
        content: content?.split('\n').map((item) => item.trim()) || [],
        config: isUseBinding ? JSON.parse(rest.config) : undefined
      })
      form.resetFields()
      setIsEditModalOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!userDataQuery.data || !isEditModalOpen) {
      return
    }

    const currentDataSource = userDataQuery.data?.items.find((item) => item.id === value)

    form.setFieldsValue({
      ...currentDataSource,
      content: currentDataSource?.content?.map((item: any) => item).join('\n'),
      config: JSON.stringify(currentDataSource?.config, null, 2)
    })
  }, [form, userDataQuery.data, value, isEditModalOpen])

  const handleSelect = (value: string | number) => {
    if (value === 'new') {
      form.setFieldsValue({
        id: undefined,
        name: '',
        content: '',
        use_binding: false,
        multiselect: false
      })
      return
    }

    const dataSource = userDataQuery.data?.items.find((item) => item.id === value)

    if (!dataSource) {
      console.error('Data source not found', value)
      return
    }

    form.setFieldsValue({
      ...dataSource,
      content: dataSource.content?.map((item: any) => item).join('\n'),
      config: JSON.stringify(dataSource.config, null, 2)
    })
  }

  return (
    <>
      <Form layout="vertical" component="div">
        <div className="flex gap-x-6 items-center">
          <Form.Item label="Data Source" className="w-full">
            <Select
              placeholder="Select"
              options={options}
              value={value}
              onChange={onChange}
              allowClear
              data-cy="data-source-select"
            />
          </Form.Item>
          <Button onClick={() => setIsEditModalOpen(true)} iconName="svg:edit" data-cy="data-source-edit-button">
            Edit
          </Button>
        </div>
      </Form>
      <Modal
        open={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
        title="Data Source Manager"
        width={800}
        okText="Save"
        okButtonProps={{
          loading: mutation.isLoading,
          'data-cy': 'data-source-save-confirm-button'
        }}
        onOk={handleSubmit}
      >
        {userDataQuery.isLoading ? (
          <Spin spinning={userDataQuery.isLoading} />
        ) : (
          <Form layout="vertical" form={form}>
            <Form.Item label="Data Source" name="id" className="w-full">
              <Select
                placeholder="Select"
                options={optionsWithAddNew}
                onChange={handleSelect}
                data-cy="data-source-select"
              />
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input data-cy="data-source-name-input" />
            </Form.Item>
            <div className="flex gap-x-12">
              <Form.Item name="use_binding" valuePropName="checked">
                <Checkbox data-cy="data-source-use-binding-checkbox">Use Data Binding</Checkbox>
              </Form.Item>
              <Form.Item name="multiselect" valuePropName="checked">
                <Checkbox data-cy="data-source-multiselect-checkbox">Multi Select</Checkbox>
              </Form.Item>
            </div>
            {isUseBinding ? (
              <Form.Item label="Data Binding Config JSON" name="config">
                <CodeEditor mode="json" minLines={15} maxLines={15} />
              </Form.Item>
            ) : (
              <Form.Item label="Items, one per line" name="content">
                <Input.TextArea rows={5} data-cy="data-source-content-input" />
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
    </>
  )
}
