import { useJmConfig, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { JobTicket } from '@/modules/jas/job-manager/types'
import { Icon, IconName } from '@/ui/icons'
import { Modal, Tag } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

type Props = {
  selected?: boolean
  onSelect?: (ticket: JobTicket) => void
  ticket: JobTicket
}

const getIcon = (name: string | null): IconName => {
  if (isEmpty(name)) return 'blank'
  return `mi:${name}` as IconName
}

export const JobTicketCard: React.FC<Props> = ({ selected, onSelect, ticket }) => {
  const [confirmChange, setConfirmChange] = useState(false)

  const config = useJmConfig()
  const { isModified } = useSelectedJobTicket()

  const { jobData } = ticket
  const [open, setOpen] = useState(false)

  const changeSelected = () => {
    onSelect?.(ticket)
    setConfirmChange(false)
  }

  const onCardClick = () => {
    if (isModified) {
      setConfirmChange(true)
      return
    }

    changeSelected()
  }

  return (
    <div className="w-full mb-12 lg:mb-6">
      <div
        className={cn(
          'transition-all border border-solid border-gray-200 shadow-sm rounded p-10 pb-4 cursor-pointer',
          'hover:border-primary-200 hover:bg-primary-50 dark:border-dark-border dark:bg-dark-container',
          {
            '!shadow-none !border-primary-300 bg-primary-50 dark:border-gray-800': selected
          }
        )}
        onClick={() => onCardClick()}
      >
        <div className="flex flex-wrap items-center mb-8">
          <div className="font-bold"># {ticket.name}</div>
          <div className="ml-auto">
            <Tag color={jobData.statusColor}>
              <span className="xl:w-auto w-[8px] inline-block overflow-hidden align-middle">{jobData.statusLabel}</span>
            </Tag>
            <Tag color={jobData.assignedPctColor}>{jobData.assignedPct || 0}%</Tag>
          </div>
          <div>
            <div
              className="transition select-none dark:bg-gray-700 dark:hover:bg-gray-600 bg-gray-200 hover:bg-gray-100 cursor-pointer text-gray-500 rounded-sm min-h-[24px] min-w-[24px] flex items-center justify-center"
              onClick={() => setOpen(!open)}
            >
              <Icon name="mi:chevron_left" className={cn('transition -rotate-90', { '!rotate-90': open })} />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-between mb-4">
          <div className="text-neutral dark:text-dark-neutral font-normal">
            {dayjs.formatLocalDate(jobData.startDate) || '--/--/----'}
          </div>
          <div className="font-bold text-sm">{dayjs.formatLocalTime(jobData.startDate) || '--:--'}</div>
        </div>
        <div className="flex flex-wrap justify-between mb-4">
          <div className="text-neutral dark:text-dark-neutral font-normal truncate">{jobData.jobType || 'N/A'}</div>
        </div>

        <div className="border-0 border-t border-solid border-gray-200 dark:border-gray-700 py-4">
          <div className="flex items-center py-2">
            <div className="max-w-[16px] w-full mr-6">
              <Icon name={getIcon(config?.line_1_icon)} className={'text-gray-600 text-[20px]'} />
            </div>
            <div className="text-neutral dark:text-dark-neutral truncate">{jobData.line1 || 'N/A'}</div>
          </div>

          <div className={`overflow-hidden transition-all duration-200 ease ${open ? 'max-h-[1000px]' : 'max-h-0'}`}>
            <div className="flex items-center py-2">
              <div className="max-w-[16px] w-full mr-8">
                <Icon
                  name={getIcon(config?.line_2_icon)}
                  className={'text-neutral dark:text-dark-neutral text-[20px]'}
                />
              </div>
              <div className="text-neutral dark:text-dark-neutral truncate">{jobData.line2}</div>
            </div>
            <div className="flex items-center py-2 border-b-">
              <div className="max-w-[16px] w-full mr-8">
                <Icon name={getIcon(config?.line_3_icon)} className={'text-gray-600 text-[20px]'} />
              </div>
              <div className="text-neutral dark:text-dark-neutral truncate">{jobData.line3 || 'N/A'}</div>
            </div>
            <div className=""></div>
            <div
              className="py-6 pb-0 mt-4 text-neutral dark:text-dark-neutral border-0 border-t border-gray-200 dark:border-gray-700 border-solid"
              dangerouslySetInnerHTML={{ __html: jobData.notes || 'N/A' }}
            ></div>
          </div>
        </div>
      </div>

      {/* save changes warning model */}
      <Modal
        centered
        title="Confirm Switching Job Ticket"
        style={{ top: 20 }}
        open={confirmChange}
        okText={'Yes, Switch'}
        cancelText={'No, Stay'}
        onOk={changeSelected}
        onCancel={() => setConfirmChange(false)}
      >
        <div className={'text-center'}>
          <p>You have unsaved changes.</p>
          <p>Switching to another job will reset your unsaved changed.</p>
          <p>Are you sure to switch?</p>
        </div>
      </Modal>
    </div>
  )
}
