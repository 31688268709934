import { useApp } from '@/hooks'
import { lineItemApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { Modal, ModalProps } from 'antd'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { customFormAtom } from '../../../atoms'
import { LineItemEditFields } from '../line-item-edit-fields'

type Props = {
  lineItem: any
  onSaveFinished: () => void
} & ModalProps

export const EditLineItemModal = ({ lineItem, onSaveFinished, ...props }: Props) => {
  const setCustomForm = useSetAtom(customFormAtom)
  const { notification } = useApp()

  const updateLineItemMutation = useMutation({
    ...lineItemApi.update()
  })

  const handleChange = useCallback(
    (lineItem: any, value: any) => {
      setCustomForm((prev) => ({
        ...prev,
        line_items: prev.line_items?.map((item) => {
          if (item.id !== lineItem.id) {
            return item
          }

          return {
            ...item,
            ...value
          }
        })
      }))
    },
    [setCustomForm]
  )

  const handleSave = async () => {
    await updateLineItemMutation.mutateAsync({
      ...lineItem,
      category: lineItem.category?.id
    })

    notification.success({
      message: 'Line Item updated successfully'
    })

    onSaveFinished()
  }

  return (
    <>
      <Modal
        title="Edit Line Item"
        okText="Save"
        onOk={handleSave}
        width={800}
        okButtonProps={{
          loading: updateLineItemMutation.isLoading,
          'data-cy': 'custom-form-line-item-edit-save-button'
        }}
        {...props}
      >
        <LineItemEditFields lineItem={lineItem} onChange={handleChange} />
      </Modal>
    </>
  )
}
