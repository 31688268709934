import { useAppTheme } from '@/hooks/use-app-theme'
import { Button, Icon, Tooltip } from '@/ui'
import { FC } from 'react'

export const DarkModeToggle: FC = () => {
  const { darkMode, setDarkMode } = useAppTheme()

  return (
    <Tooltip title={darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}>
      <Button
        size={'small'}
        variant={'filled'}
        color={'default'}
        shape={'circle'}
        icon={<Icon name={darkMode ? 'fa:sun' : 'fa:moon'} />}
        onClick={() => setDarkMode(!darkMode)}
      />
    </Tooltip>
  )
}
