import { useSession } from '@/hooks'
import { timeTicketApi } from '@/services/api-service'
import { AppLink, Button, PageTitle, QueryTable } from '@/ui'
import { withJotaiProvider } from '@/utils/with-jotai-provider'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { periodRangeAtom } from '../atoms'
import { TIME_PATHS } from '../constants'
import { PeriodSelect } from './period-select'
import { TotalHoursByType } from './total-hours-by-type'

export const MyTimeView = withJotaiProvider(() => {
  const { user } = useSession()
  const periodRange = useAtomValue(periodRangeAtom)

  const queryParams = useMemo(() => {
    return {
      employee: user?.profile.contact.id,
      ...(periodRange && periodRange !== 'notSet'
        ? {
            start_date: periodRange[0].format(),
            end_date: periodRange[1].format()
          }
        : {})
    }
  }, [periodRange, user?.profile.contact.id])

  return (
    <div>
      <div className="flex items-center justify-between mb-20">
        <PageTitle>My Time</PageTitle>
        <AppLink to={TIME_PATHS.add}>
          <Button type="primary" iconName="fa:add">
            Add Time
          </Button>
        </AppLink>
      </div>
      <div className="grid grid-cols-2 gap-x-20 mb-16 items-start">
        <PeriodSelect />
        <TotalHoursByType queryParams={queryParams} />
      </div>
      <QueryTable
        queryApi={timeTicketApi.list}
        queryParams={queryParams}
        queryOptions={{ enabled: periodRange !== 'notSet' }}
        columns={[
          {
            title: 'Time In Date',
            dataIndex: 'time_in',
            render: (value: any) => dayjs(value).format('MMM D, YYYY')
          },
          {
            title: 'Job Code',
            dataIndex: 'job_code',
            render: (value: any) => value?.code || 'N/A'
          },
          {
            title: 'Job Type',
            dataIndex: 'job_type__name'
          },
          {
            title: 'Duration',
            dataIndex: 'total_time',
            render: (value: number, record: any) =>
              record.type === 2 ? 'Days Off' : `${(value / 60).toFixed(2)} hours`
          },
          {
            dataIndex: 'id',
            sorter: false,
            align: 'right',
            render: (value: number) => (
              <AppLink to={TIME_PATHS.edit(value)}>
                <Button type="text" iconName="fa:edit" />
              </AppLink>
            )
          }
        ]}
      />
    </div>
  )
})
