import { InventoryIndicator } from '@/types/inventory-indicator'
import { Select } from '@/ui'
import { SelectProps } from 'antd'

type Props = {
  type: 'Y Max' | 'Y Min'
  inventoryIndicator: InventoryIndicator
  options?: string
} & Omit<SelectProps, 'options'>

export const YMaxMinSelect = ({ options, type, inventoryIndicator }: Props) => {
  return (
    <Select
      defaultValue={null}
      options={[
        {
          label: `${type}: Auto`,
          value: null
        },
        ...(options
          ?.split(',')
          .map((value) => ({ label: `Y Max: ${value} ${inventoryIndicator.equipment?.unit}`, value })) || [])
      ]}
    />
  )
}
