import { useApp } from '@/hooks'
import { transformationTaskApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Space } from 'antd'
import { useSetAtom } from 'jotai'
import { currentEditingTransformTaskAtom } from '../../../atoms'
import { useProgrammedReportQuery } from '../../../hooks'

export const ActionButtons = () => {
  const setCurrentEditingTransformTask = useSetAtom(currentEditingTransformTaskAtom)
  const updateTransformationTaskMutation = useMutation(transformationTaskApi.patch())
  const form = Form.useFormInstance()
  const { notification } = useApp()
  const { programmedReportQuery } = useProgrammedReportQuery()

  const handleSaveTransformationTask = async () => {
    const values = await form.validateFields()
    await updateTransformationTaskMutation.mutateAsync(values)
    await programmedReportQuery.refetch()
    notification.success({ message: 'Transformation task saved' })
    setCurrentEditingTransformTask(null)
  }

  return (
    <Space>
      <Button iconName="fa:close" onClick={() => setCurrentEditingTransformTask(null)} />
      <Button iconName="fa:save" type="success" onClick={handleSaveTransformationTask} />
    </Space>
  )
}
