import { useApp } from '@/hooks'
import { StatusSelect } from '@/modules/ticket/components'
import { customFormApi, equipmentApi, moduleApi, propertyApi, ticketApi } from '@/services/api-service'
import { Button, Popconfirm, QuerySelect, Select } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Divider, Form } from 'antd'
import { isEmpty, merge } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { z } from 'zod'
import { refreshQueries } from '../../../../helpers'
import { useTicket } from '../../../../hooks'

const _ModuleSchema = z.object({
  id: z.number(),
  data_json: z.string()
})
type _Module = z.infer<typeof _ModuleSchema>
const _PropertySchema = z.object({
  id: z.number(),
  button_config: z.any()
})
type _Property = z.infer<typeof _PropertySchema>

export const SummaryRebuild: FC = () => {
  const [form] = Form.useForm()
  const dispositionTickets = Form.useWatch('disposition_tickets', form)
  const { notification } = useApp()
  const { ticket } = useTicket()
  const currentSummaryConfig = ticket._data?.summary_config
  const buildSource = currentSummaryConfig?.build_source

  const [rebuildAttachments, setRebuildAttachments] = useState(false)
  const [syncConfig, setSyncConfig] = useState(false)

  const fetchSummaryWizardModule = useQuery({
    ...moduleApi.get<_Module>(buildSource?.type === 'summary_wizard_module' ? buildSource?.module_id : 0, undefined, {
      fields: _ModuleSchema
    }),
    enabled: Boolean(buildSource?.type === 'summary_wizard_module' && buildSource?.module_id && syncConfig)
  })
  const fetchProperty = useQuery({
    ...propertyApi.get<_Property>(buildSource?.type === 'scripted_button' ? buildSource?.property_id : 0, undefined, {
      fields: _PropertySchema
    }),
    enabled: Boolean(buildSource?.type === 'scripted_button' && buildSource?.property_id && syncConfig)
  })

  const rebuildMutation = useMutation(
    ticketApi.rebuildSummary(ticket.id, { skip_pdf: rebuildAttachments ? undefined : '1' })
  )

  useEffect(() => {
    let conf = currentSummaryConfig as any
    if (conf?.build_source?.type === 'summary_wizard_module' && fetchSummaryWizardModule.data?.data_json) {
      const {
        custom_form_id,
        summarized_tickets_status,
        summary_sku_id,
        list_line_items,
        group_items,
        summary_ticket_status,
        attachment_type
      } = JSON.parse(fetchSummaryWizardModule.data?.data_json || '{}')
      conf = merge(conf, {
        custom_form: custom_form_id,
        summary_sku: summary_sku_id,
        attachment_type,
        list_line_items,
        group_items,
        disposition_status: summary_ticket_status,
        disposition_tickets: ![null, undefined, false, 'None'].includes(summarized_tickets_status),
        disposition_status_summarized: summarized_tickets_status
      })
    }

    if (conf?.build_source?.type === 'scripted_button' && fetchProperty.data?.button_config) {
      const {
        custom_form_id,
        summarized_tickets_status,
        summary_sku_id,
        list_line_items,
        group_items,
        summary_ticket_status,
        attachment_type
      } = fetchProperty.data?.button_config?.vars || {}
      conf = merge(conf, {
        custom_form: custom_form_id?.value,
        summary_sku: summary_sku_id,
        attachment_type,
        list_line_items,
        group_items,
        disposition_status: summary_ticket_status,
        disposition_tickets: ![null, undefined, false, 'None'].includes(summarized_tickets_status),
        disposition_status_summarized: summarized_tickets_status
      })
    }

    form.setFieldsValue({
      ...conf,
      _item_conf: (Number(conf?.group_items as boolean) * 2) | (Number(conf?.list_line_items as boolean) * 1)
    })
  }, [fetchSummaryWizardModule.data, fetchProperty.data])

  const clearState = () => {
    // Reset the form and states when Popconfirm is closed
    form.resetFields()
    setRebuildAttachments(false)
    setSyncConfig(false)
    fetchSummaryWizardModule.remove()
    fetchProperty.remove()
  }

  const handleRebuild = useCallback(async () => {
    try {
      let newConfig
      if (syncConfig) {
        const formData = await form.validateFields()
        newConfig = {
          ...currentSummaryConfig,
          ...formData,
          list_line_items: Boolean(formData._item_conf & 1),
          group_items: Boolean(formData._item_conf & 2)
        }
      }

      await rebuildMutation.mutateAsync({
        id: ticket.id,
        summary_config: newConfig
      })

      notification.success({
        message: 'Summary Rebuilt',
        description: 'The summary has been rebuilt successfully'
      })

      refreshQueries()
    } catch (error) {
      notification.error({
        message: 'Error Rebuilding Summary',
        description: error?.toString()
      })
    }
  }, [notification, rebuildAttachments, rebuildMutation, ticket.id])

  return (
    <Popconfirm
      title={'Rebuild Summary'}
      placement={'leftTop'}
      onConfirm={handleRebuild}
      okText={'Rebuild'}
      onOpenChange={clearState}
      description={
        <Form form={form} layout="vertical" size="small">
          <Form.Item className={'m-0 pr-6 py-10'}>
            <Checkbox checked={rebuildAttachments} onChange={(e) => setRebuildAttachments(e.target.checked)}>
              Rebuild Summary Document
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={'m-0 pr-6 py-10'}
            tooltip="Update Summary Config from source configuration(Summary Wizard, Scripted Button)"
          >
            <Checkbox checked={syncConfig} onChange={(e) => setSyncConfig(e.target.checked)}>
              Sync & Update Summary Config
            </Checkbox>
          </Form.Item>
          {(fetchSummaryWizardModule.isFetching || fetchProperty.isFetching) && (
            <div className="flex flex justify-center">
              <Spin className="mb-4" />
            </div>
          )}
          {syncConfig && (
            <>
              <Divider className="my-6" />
              <Form.Item
                label={'Summary Ticket Form'}
                name={'custom_form'}
                rules={[{ required: true, message: 'Required' }]}
              >
                <QuerySelect
                  apiEndpoint={customFormApi.list}
                  apiQueryParams={{ fields: 'id,name' }}
                  apiSearchBy={'name'}
                  apiValueBy="id"
                  disabled
                  renderOption={(item) => ({ value: item.id, label: item.name })}
                />
              </Form.Item>
              <Form.Item
                label={'Summary Ticket Status'}
                name={'disposition_status'}
                rules={[{ required: true, message: 'Required' }]}
              >
                <StatusSelect formId={ticket.custom_form_id} />
              </Form.Item>
              <Form.Item name={'disposition_tickets'} valuePropName="checked">
                <Checkbox>Disposition Summarized Tickets?</Checkbox>
              </Form.Item>
              {dispositionTickets && (
                <Form.Item
                  label={'Summarized Ticket Status'}
                  name={'disposition_status_summarized'}
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <StatusSelect formId={ticket.custom_form_id} />
                </Form.Item>
              )}
              <Form.Item label={'Summary SKU'} name={'summary_sku'} rules={[{ required: true, message: 'Required' }]}>
                <QuerySelect
                  apiEndpoint={equipmentApi.list}
                  apiQueryParams={{ fields: 'id,sku,description', summary_sku__eq: '1' }}
                  apiValueBy="id"
                  apiSearchBy={(text) => ({
                    'Q[]': isEmpty(text) ? {} : [`sku__icontains|${text}`, `description__icontains|${text}`, 'or']
                  })}
                  renderOption={(item) => ({ value: item.id, label: `${item.sku} / ${item.description}` })}
                />
              </Form.Item>
              <Form.Item name={'_item_conf'} label="List Each Line Item Individually">
                <Select
                  value={0}
                  options={[
                    {
                      label: 'Yes - Group',
                      value: 3 as any
                    },
                    {
                      label: 'Yes',
                      value: 1 as any
                    },
                    {
                      label: 'No',
                      value: 0 as any
                    }
                  ]}
                />
              </Form.Item>
            </>
          )}
        </Form>
      }
    >
      <Button primary size={'small'}>
        Rebuild
      </Button>
    </Popconfirm>
  )
}
