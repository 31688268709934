import { PropertyChoice } from '@/types/property-choice'
import { Form, Input } from 'antd'
import { FormValues } from '../../types'

type Props = {
  propertyChoice: PropertyChoice
  field: string
  value: string
}

export const EditableTableColumn = ({ propertyChoice, field, value }: Props) => {
  const form = Form.useFormInstance()
  const editingPropertyChoice = Form.useWatch('editingPropertyChoice', form) as FormValues['editingPropertyChoice']

  if (propertyChoice.id === editingPropertyChoice?.id) {
    return (
      <Form.Item name={['editingPropertyChoice', field]} className="mb-0">
        <Input />
      </Form.Item>
    )
  }

  return <>{value}</>
}
