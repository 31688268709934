import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

export const PlaceholderBox = ({ children, onClick, disabled, ...rest }: Props) => {
  return (
    <div
      className={classNames(
        'border border-border rounded bg-gray-50 p-8 font-semibold flex flex-col justify-center cursor-pointer',
        {
          'opacity-50 !cursor-default': disabled
        }
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  )
}
