import { FC } from 'react'
import { LineItem, LineItemComponent } from '../../../../../schemas'
import { useComponentKind } from '../hooks'

type Props = { isEdit: boolean; item: Partial<LineItem>; components: LineItemComponent[] }

export const TableHead: FC<Props> = ({ isEdit, item, components }) => {
  const componentKind = useComponentKind(components[0] || item)

  return (
    <thead>
      <tr className={'[&>th]:py-8 [&>th]:px-10 [&>th]:border-b [&>th]:border-secondary [&>th]:text-left'}>
        {componentKind.visibleFields.map((f) => (
          <th key={f.field}>{f.label}</th>
        ))}
        {isEdit && <th></th>}
      </tr>
    </thead>
  )
}
