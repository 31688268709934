import { RecentActivities } from '@/components'
import { eventApi } from '@/services/api-service'
import { PageTitle } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { StepFour } from './step-four'
import { StepOne } from './step-one'
import { StepThree } from './step-three'
import { StepTwo } from './step-two'
import { UndoImportSection } from './undo-import-section'

const defaultCategoryKindFields = ['amount', 'description', 'sku', 'units_used', 'usage_rate']

export const ImportExportView = () => {
  const eventsQuery = useQuery(eventApi.list({ type: 'exportImport' }))

  return (
    <div>
      <PageTitle>Import / Export</PageTitle>
      <div className="grid grid-cols-2 mt-20 pb-20 border-b border-gray-200">
        <Form
          className="border-r border-gray-200 pr-20"
          layout="vertical"
          initialValues={{
            fields: defaultCategoryKindFields
          }}
        >
          <Form.Item name="fields" hidden />
          <Form.Item name="file_name" hidden />
          <Form.Item name="url" hidden />
          <h5 className="font-bold mb-16">Export Data</h5>
          <StepOne />
          <StepTwo />
        </Form>
        <Form layout="vertical" className="pl-20">
          <h5 className="font-bold mb-16">Import Data</h5>
          <StepThree />
          <StepFour />
        </Form>
      </div>
      <UndoImportSection />
      <div>
        <h5 className="font-bold">Recent Activity</h5>
        <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
      </div>
    </div>
  )
}
