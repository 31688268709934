import { atom } from 'jotai'
import { LINE_ITEM_FIELDS } from './constants'
import { Configuration } from './schema'
import { ContainerKey, LineItemField } from './types'

const initialConfiguration: Configuration = {
  fields: [],
  data: {},
  name: ''
}

export const initialConfigurationAtom = atom<Configuration>(initialConfiguration)
export const configurationAtom = atom<Configuration>(initialConfiguration)

export const lineItemFieldsAtom = atom<Record<ContainerKey, LineItemField[]>>({
  available: LINE_ITEM_FIELDS.map((field) => field),
  selected: []
})

export const searchTextAtom = atom('')
export const companyFieldAliasesAtom = atom<Record<string, string>>({})
