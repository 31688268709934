import { TABLE_DEFAULT_PAGE_SIZE } from '@/constants/general'
import { FIELD_LABELS } from '@/constants/labels'
import { customReportApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { CustomReport } from '../schema'

export const useCustomReportsQuery = () => {
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('id')

  const customReportsQuery = useQuery({
    ...customReportApi.list<CustomReport>({
      type: 'custom',
      limit,
      page,
      order,
      ...(search && { description: search.trim(), name: search.trim() })
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...item,
        filterfield_set: item.filterfield_set?.map((filterField) => ({
          ...filterField,
          _name:
            FIELD_LABELS[`report__${filterField.field_verbose}`] ||
            FIELD_LABELS[`lineitem__${filterField.field_verbose}`]
        }))
      }))
    })
  })

  return {
    customReportsQuery,
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    order,
    setOrder
  }
}
