import { customEmailSchema } from '@/schemas/custom-email'
import { customEmailApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useCustomEmailsQuery = () => {
  const { id } = useParams<{ id: string }>()

  const customEmailsQuery = useQuery({
    ...customEmailApi.list({
      custom_form__eq: id,
      limit: 100,
      order: 'name',
      fields: customEmailSchema
    }),
    enabled: !!id
  })

  return {
    customEmailsQuery
  }
}
