import { JotaiProvider } from '@/contexts'
import { Form } from 'antd'
import { ComponentType, FC, ReactNode } from 'react'
import { useLoaderData } from 'react-router-dom'
import { ViewComponent, ViewFormConfig, ViewOptions } from './types'

export const view = <TProps = unknown, TData = unknown>(
  Component: ComponentType<TProps>,
  { name, loader, form, scoped = true, ...handle }: ViewOptions<TData> = {}
): ViewComponent<TProps, TData> => {
  const viewName = name || Component.displayName || Component.name || 'View'
  const ViewComponent = ((props: TProps) => {
    let children = <Component {...(props as any)} />

    if (scoped) children = <JotaiProvider>{children}</JotaiProvider>
    if (form) children = <FormProvider {...form}>{children}</FormProvider>

    return children
  }) as ViewComponent<TProps, TData>

  ViewComponent.loader = loader
  ViewComponent.handle = handle
  ViewComponent.displayName = `View(${viewName})`

  return ViewComponent
}

const FormProvider: FC<ViewFormConfig> = ({ children, ...formProps }) => {
  const [form] = Form.useForm()
  const data = useLoaderData() || {}

  return (
    <Form form={form} component="div" initialValues={data} {...formProps}>
      {children as ReactNode}
    </Form>
  )
}
