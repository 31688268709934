import { Tag } from 'antd'
import { useTicket, useTicketFormName, useTicketFullName } from '../../../hooks'
import { ReloadButton } from '../../buttons'

export const TicketTitle = () => {
  const { isNew } = useTicket()
  const formName = useTicketFormName()
  const ticketName = useTicketFullName()

  return (
    <div className={'flex flex-row items-center gap-10'}>
      <span className="m-0 p-0 line-clamp-1">
        {formName} #{ticketName}
      </span>
      {isNew && <Tag color="green">New</Tag>}
      <div className={'hidden md:block relative -top-4'}>
        <ReloadButton />
      </div>
    </div>
  )
}
