import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { ViewTicketLink } from '@/modules/ticket/components'
import { Icon } from '@/ui/icons'
import { Button, Dropdown, MenuProps } from 'antd'
import React from 'react'

export const NewTicket: React.FC = () => {
  const { customForms } = useGlobalCustomForms()

  const items: MenuProps['items'] = customForms.map((cf) => ({
    key: cf.id,
    label: (
      <ViewTicketLink ticketId={null} formId={cf.id} className="inline-block py-2 !text-primary !line-clamp-1">
        {cf.name}
      </ViewTicketLink>
    ),
    icon: <Icon name="fa:file-circle-plus" className="text-primary" />
  }))

  return (
    <Dropdown
      overlayClassName={'[&>ul]:max-h-[300px] [&>ul]:min-w-[150px] [&>ul]:max-w-[250px] [&>ul]:overflow-y-auto'}
      arrow={true}
      menu={{ items }}
      trigger={['click']}
      placement="bottom"
    >
      <Button type="primary" className="!rounded-lg" icon={<Icon className="font-bold" name="fa:add" />} />
    </Dropdown>
  )
}
