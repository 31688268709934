import { Select } from '@/ui'
import { Form } from 'antd'
import { useTransformationTaskTabsOptions } from '../../../../hooks'

export const CopyDataSet = () => {
  const tabsOptions = useTransformationTaskTabsOptions()

  return (
    <>
      <Form.Item name={['params', 'copy_data_from']} label="Copy Data From">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item name={['params', 'copy_data_to']} label="Copy Data To">
        <Select options={tabsOptions} />
      </Form.Item>
    </>
  )
}
