import { JotaiProvider } from '@/contexts'
import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { DisplayField } from '@/modules/table-view/helpers'
import { ALERT_TYPES_MAP } from '@/modules/ticket/constants'
import { Icon, LazyDiv, Tooltip } from '@/ui'
import { cn, dig } from '@/utils'
import { FC, useMemo } from 'react'
import { ButtonCell } from './button-cell'

type Props = {
  customFormsService: ReturnType<typeof useGlobalCustomForms>
  field: DisplayField
  record: any
}

export const RegularCell: FC<Props> = ({ customFormsService, field, record }) => {
  const { getPropertyByKey, getPropertyById } = customFormsService

  if (field.isProperty) {
    const property =
      getPropertyById(record.custom_form_id, field.propertyId as any) ||
      getPropertyByKey(record.custom_form_id, field.propertyKey as any)

    if (field.fieldType === 'ScriptedButton') {
      return property ? (
        <JotaiProvider>
          <ButtonCell property={property} record={record} />
        </JotaiProvider>
      ) : null
    }

    return <span>{field.formatProperty(dig(record?.property, field.field), property)}</span>
  }

  if (field.key === 'name') {
    return (
      <div className={'flex items-center gap-10'}>
        <div>{record?.name}</div>
        <RaisedAlerts record={record} />
      </div>
    )
  }

  return field.formatField(record) || <div>{record[field.key]}</div>
}

function RaisedAlerts({ record }: { record: any }) {
  const alert = useMemo(() => {
    const _a = (record?.alerts || [])[0]
    return _a ? { ..._a, ...(ALERT_TYPES_MAP[_a.alert__alert_type] || {}) } : null
  }, [record?.alerts])

  if (!alert) return null

  const alertIcon = (
    <Icon name={alert?.icon || 'fa:warning'} className={cn('text-[16px] cursor-pointer', alert?.textColor)} />
  )

  return (
    <LazyDiv placeholder={alertIcon}>
      <Tooltip title={<div>{record?.alerts?.map((a: any) => a.alert__name).join(', ')}</div>}>
        <div>{alertIcon}</div>
      </Tooltip>
    </LazyDiv>
  )
}
