import { AddCompanyView } from '../../../add-company-view'

const AddCompaniesView = ({ isPrivate }: { isPrivate: boolean }) => {
  return (
    <div className="px-16 py-16">
      <AddCompanyView isPrivate={isPrivate} />
    </div>
  )
}

export { AddCompaniesView }
