import { Alert } from 'antd'
import { FC } from 'react'
import { BpaTrigger, BpaTriggerEventConfig } from '../../schemas'

type Props = {
  trigger: BpaTrigger
  config: BpaTriggerEventConfig
  setConfig: <K extends keyof BpaTriggerEventConfig>(key: K, value: BpaTriggerEventConfig[K]) => void
  index: number
}

export const EventTrigger: FC<Props> = () => {
  return (
    <div className="flex flex-col gap-10">
      <Alert message={'Event Triggers Are Coming Soon...'} />
    </div>
  )
}
