import { ViewTicketLink } from '@/modules/ticket/components'
import { Button, Tooltip } from '@/ui'
import { useCustomForm, useTicket, useTicketFlags } from '../../../hooks'
import { PrintButton } from '../../buttons/print-button'
import { SaveButton } from '../../buttons/save-button'
import { SupportButtons } from '../../buttons/support-buttons'
import { ChangesPreview } from '../changes-preview'
import { TabIndicator } from '../tab-indicator'

export const TicketActions = () => {
  const { customForm } = useCustomForm()
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()

  return (
    <div className={'flex flex-row items-center justify-between'}>
      <div className="flex flex-row items-center gap-10">
        <div className={'hidden md:flex flex-row items-center gap-10'}>
          <ChangesPreview />
          <TabIndicator />
          <div className={'h-30 border-l'}></div>
          <SupportButtons />
          <ViewTicketLink v={1} ticketId={ticket.id} formId={customForm.id} className="hidden md:block ml-2">
            <Button type="dashed" size="small" primary={true} iconName="fa:external-link">
              Open In V1
            </Button>
          </ViewTicketLink>
          <div className={'h-30 border-l'}></div>
        </div>
        <PrintButton type={'download'} />
        <PrintButton type={'print'} />
        <div className={'h-30 border-l'}></div>
        <Tooltip placement="topLeft" title={ticket._editRestrictedMsg}>
          <SaveButton type={'success'} iconName={'fa:save'} disabled={viewOnly}>
            Save
          </SaveButton>
        </Tooltip>
      </div>
    </div>
  )
}
