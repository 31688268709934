import { CalendarTable, CalendarViewSelect, DistrictSelect, SkillSelect } from '@/components'
import { CalendarTableDropdownCell } from '@/components/calendar-table/calendar-table-dropdown-cell'
import { TitleCellPopover } from '@/components/calendar-table/title-cell-popover'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { SCHEDULE_TYPES } from '@/utils'
import { useAtomValue } from 'jotai/index'
import { useCallback } from 'react'
import { useDeletePatchMutation, useMultipleScheduleView } from '../hooks'
import { EquipmentSearch } from './equipment-search'
import { TableTitleHeadCell } from './table-title-head-cell'

export const EquipmentsView = () => {
  const { dispatch, handlePatchMutation, query, state } = useMultipleScheduleView({
    type__iexact: 'equipment'
  })

  const { handleDeletePatchMutation } = useDeletePatchMutation({ onSuccess: query.refetch })
  const { selectedSkills, date, searchText, selectedDistricts } = state
  const calendarView = useAtomValue(calendarViewAtom)
  const equipments = query.data?.pages.map((page) => page.map((equipment) => equipment)).flat() || []

  const handleSearchTextChange = useCallback(
    (value: string) => {
      dispatch({ type: 'setSearchText', payload: value })
    },
    [dispatch]
  )

  return (
    <div className="h-[calc(100vh-96px)] relative">
      <div className="flex gap-x-12 items-center mb-12">
        <EquipmentSearch searchText={searchText} onChange={handleSearchTextChange} />
        <DistrictSelect
          value={selectedDistricts}
          onChange={(value) => dispatch({ type: 'setSelectedDistricts', payload: value })}
        />
        <SkillSelect
          type="equipment"
          value={selectedSkills}
          onChange={(value) => dispatch({ type: 'setSelectedSkills', payload: value })}
        />
        <div className="flex ml-auto">
          <CalendarViewSelect />
        </div>
      </div>
      <CalendarTable
        title={<TableTitleHeadCell onClick={() => dispatch({ type: 'toggleQueryOrder' })} />}
        withFooter
        scheduleLabels={Object.values(SCHEDULE_TYPES)
          .map((type) => type)
          .filter((type) => type.title !== 'Off day')}
        visibleDays={calendarView}
        data={equipments}
        date={date}
        loading={query.isLoading || query.isFetchingNextPage}
        onDateChange={(date) =>
          dispatch({
            type: 'setDate',
            payload: date
          })
        }
        onLoadMore={() => {
          if (query.hasNextPage) query.fetchNextPage()
        }}
        renderTitleCell={(equipment) => <TitleCellPopover resource={equipment} />}
        renderDateCell={(date, equipment) => (
          <CalendarTableDropdownCell
            date={date.format('YYYY-MM-DD')}
            data={equipment}
            isShortForm={calendarView === 'month'}
            hiddenMenuItems={['O']}
            onMenuItemClick={(type, selectedDates) =>
              type === 'DELETE'
                ? handleDeletePatchMutation(equipment, selectedDates)
                : handlePatchMutation(equipment, selectedDates, type)
            }
          />
        )}
      />
    </div>
  )
}
