import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'

export const AdvancedTab = () => {
  return (
    <Form.Item name="config_json">
      <CodeEditor mode="json" />
    </Form.Item>
  )
}
