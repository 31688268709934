import { Form, InputNumber } from 'antd'
import { useEffect } from 'react'
import { FormValues, PricingCustomer } from '../../../schemas'

type Props = {
  pricingCustomer: PricingCustomer
  price: NonNullable<PricingCustomer['prices']>[0] | undefined | null
}

export const PriceEditCell = ({ price, pricingCustomer }: Props) => {
  const form = Form.useFormInstance<FormValues>()

  useEffect(() => {
    if (!price) {
      return
    }

    form.setFieldsValue({
      [pricingCustomer.customer_office]: {
        usageRate: price.usage_rate
      }
    })
  }, [form, price, pricingCustomer])

  return (
    <>
      <Form.Item noStyle name={[pricingCustomer.customer_office, 'usageRate']}>
        <InputNumber type="number" className="w-full" />
      </Form.Item>
    </>
  )
}
