import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Dayjs } from 'dayjs'
import { useAtomValue } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import { useCallback, useEffect, useState } from 'react'
import { calendarViewAtom } from '../atoms'
import { filterLogicAtom, selectedScheduleTypeAtom } from './atoms'
import { getHiddenRowsNumbersByScheduleType } from './helpers'

export const useCalendarHiddenRows = (employees: JasResourceUseQueryResponse[], date: Dayjs) => {
  const [hiddenRows, setHiddenRows] = useState<Set<number>>()
  const calendarView = useAtomValue(calendarViewAtom)
  const filterLogic = useAtomValue(filterLogicAtom)
  const selectedScheduleType = useAtomValue(selectedScheduleTypeAtom)

  const resetSelectedScheduleType = useResetAtom(selectedScheduleTypeAtom)
  const resetFilterLogic = useResetAtom(filterLogicAtom)

  const resetFilter = useCallback(() => {
    resetSelectedScheduleType()
    resetFilterLogic()
  }, [resetFilterLogic, resetSelectedScheduleType])

  useEffect(() => {
    if (selectedScheduleType === 'all') {
      setHiddenRows(undefined)
    } else {
      setHiddenRows(
        getHiddenRowsNumbersByScheduleType(employees, selectedScheduleType, date, calendarView, filterLogic)
      )
    }
  }, [selectedScheduleType, employees, date, calendarView, filterLogic])

  return { hiddenRows, resetFilter }
}
