import { JobManagerIndex } from '@/modules/jas/job-manager/JobManagerIndex'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import React from 'react'

export const JobManagerView: React.FC = () => {
  const { module } = useCurrentModuleQuery()

  if (!module) {
    return <></>
  }

  return <JobManagerIndex />
}
