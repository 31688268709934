import { useFaviconIndicator, useFormWatch } from '@/hooks'
import { ColorInput, Tooltip } from '@/ui'
import { Form } from 'antd'

export function TabIndicator() {
  const value = useFormWatch('extra_data__tab_indicator_color')

  useFaviconIndicator({ color: value })

  return (
    <>
      <Tooltip title={'Tab Indicator Color'}>
        <Form.Item name={'extra_data__tab_indicator_color'} label={null} className={'m-0'}>
          <ColorInput allowClear size={'small'} />
        </Form.Item>
      </Tooltip>

      {value && <div className={'absolute left-0 bottom-0 h-5 w-full'} style={{ backgroundColor: value }} />}
    </>
  )
}
