import { useApp } from '@/hooks'
import { StatusSelect } from '@/modules/ticket/components'
import { useCustomForm } from '@/modules/ticket/form/hooks'
import { lineItemApiV2, ticketApiV2 } from '@/services/api-service'
import { Modal, QuerySelect } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { Fragment, useMemo, useState } from 'react'
import { useTicket } from '../../../../../hooks/use-ticket'

type Props = {
  onCancel: () => void
}

export const RemoveTicketModal = ({ onCancel }: Props) => {
  const { notification } = useApp()
  const { customForm } = useCustomForm()
  const [selectedTicketIds, setSelectedTicketIds] = useState<number[]>([])
  const [selectedTicketNames, setSelectedTicketNames] = useState<number[]>([])
  const [removedTicketStatus, setRemovedTicketStatus] = useState<string>(
    customForm._userData?.summary__move_removed_tickets_to_status || ''
  )

  const removeSubTicketsMutation = useMutation(ticketApiV2.update({}, 'remove_subtickets'))

  const { ticket } = useTicket()

  const lineItemsQuery = useQuery({
    ...lineItemApiV2.list({
      fields: 'notes,id,data_json,description',
      inc_archived: 1,
      ticket_id__eq: ticket.id,
      limit: 'None'
    })
  })

  const lineItems = useMemo(() => lineItemsQuery.data?.items || [], [lineItemsQuery.data?.items])

  const removingLineItems = useMemo(() => {
    return selectedTicketNames.map((name) => {
      const filteredLineItems = lineItems.filter((li) => (li.notes || '').includes(name))
      return { name, lineItems: filteredLineItems }
    })
  }, [selectedTicketNames, lineItems])

  const handleRemoveLineItems = async () => {
    if (!selectedTicketIds.length) {
      notification.error({ message: 'No tickets selected to remove' })
      return
    }

    try {
      await removeSubTicketsMutation.mutateAsync({
        id: ticket.id,
        subticket_ids: selectedTicketIds,
        removed_ticket_status: removedTicketStatus
      })
      notification.success({ message: `Successfully removed ${selectedTicketIds.length} Tickets from the Summary` })
      onCancel()
      setSelectedTicketIds([])
    } catch {
      notification.error({ message: 'Failed to remove Tickets from the Summary' })
    }
  }

  return (
    <Modal
      open
      title="Remove Tickets"
      width={600}
      withScreenMaxHeight
      okText="Remove"
      onOk={handleRemoveLineItems}
      okButtonProps={{
        disabled: removeSubTicketsMutation.isLoading || selectedTicketIds.length === 0,
        loading: removeSubTicketsMutation.isLoading
      }}
      cancelButtonProps={{ disabled: removeSubTicketsMutation.isLoading }}
      onCancel={onCancel}
    >
      <Form layout="vertical">
        <Form.Item label="Return Tickets to Status">
          <StatusSelect
            formId={customForm.id}
            value={removedTicketStatus}
            onChange={(value) => setRemovedTicketStatus(value)}
          />
        </Form.Item>
        <Form.Item
          label={
            <label>
              Tickets <small>(Enter ticket number to search)</small>
            </label>
          }
        >
          <QuerySelect
            mode="multiple"
            apiEndpoint={ticketApiV2.list}
            apiSearchBy="name"
            apiQueryParams={{ parent_id__eq: ticket.id }}
            onChange={(value, option) => {
              setSelectedTicketIds(value)
              setSelectedTicketNames(option?.map((v: any) => v.label) || [])
            }}
            renderOption={(item) => ({ label: item.name, value: item.id })}
          />
        </Form.Item>
        {removingLineItems.length ? (
          <div>
            {lineItemsQuery.isLoading ? (
              <Spin spinning />
            ) : (
              <>
                <h5>Remove Line Items?</h5>
                {removingLineItems.map((item) => (
                  <Fragment key={item.name}>
                    <div className="font-bold">{item.name}</div>
                    <ol className="pl-0 list-inside" key={item.name}>
                      {item.lineItems.map(({ notes, description, id }, index) => (
                        <li key={id}>{` ${notes} | ${description}`}</li>
                      ))}
                    </ol>
                  </Fragment>
                ))}
              </>
            )}
          </div>
        ) : null}
      </Form>
    </Modal>
  )
}
