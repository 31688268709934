import { categoryApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { TreeSelect, TreeSelectProps } from 'antd'
import { groupBy, split } from 'lodash'
import { FC, useMemo } from 'react'
import { z } from 'zod'

export type CategoryTreeSelectProps = TreeSelectProps

export const CategoryTreeSelect: FC<CategoryTreeSelectProps> = (props) => {
  const categoriesQuery = useQuery(
    categoryApi.list({
      fields: z.object({
        id: z.string(),
        name: z.string(),
        parent_category_id: z.string(),
        parent_category__name: z.string().nullable()
      }),
      parent_category_id__isnull: false,
      limit: 1000 // mostly all
    })
  )

  const treeData = useMemo(() => {
    return Object.entries(
      groupBy(categoriesQuery.data?.items, (i) => `${i.parent_category_id}:${i.parent_category__name}`)
    ).map(([key, value]) => {
      const [parentId, parentName] = split(key, ':', 2)

      return {
        key: parentId,
        value: Number(parentId),
        title: parentName,
        children: value.map((i) => ({ key: i.id, value: i.id, title: i.name }))
      }
    })
  }, [categoriesQuery.data?.items])

  return (
    <TreeSelect
      allowClear
      treeData={treeData}
      treeLine={true}
      treeCheckable={true}
      placeholder="Please select categories"
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      className="w-full"
      treeNodeFilterProp="title"
      {...props}
    />
  )
}
