import { useApp } from '@/hooks'
import { executeMutation, fetchQuery } from '@/query'
import { lineItemApi, lineItemApiV2, msNavApi } from '@/services/api-service'
import { Button } from '@/ui'
import { getApiMessage, uuid4 } from '@/utils'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { z } from 'zod'
import { useButtonContext } from '../../hooks'
import { VarsType } from './types'

export const MsNavSubmitButton: FC = () => {
  const { modal, notification } = useApp()
  const { vars, ticket, getValueById, property, buttonProps, saveTicket } = useButtonContext<VarsType>()
  const {
    msNavStatusPid,
    msNavMessagePid,
    msNavCustomerPid,
    customerSignaturePid,
    closedDatePid,
    serviceTypePid,
    workFieldPid,
    allowReSubmit
  } = vars
  const [loading, setLoading] = useState(false)

  const isDisabled = () => {
    let enable = !!ticket.timepoint_due
    if (serviceTypePid) enable &&= !!getValueById(serviceTypePid)
    if (msNavCustomerPid) enable &&= !!getValueById(msNavCustomerPid)
    if (workFieldPid) enable &&= !!getValueById(workFieldPid)
    if (customerSignaturePid) enable &&= !!(ticket.attachments || []).length || !!getValueById(customerSignaturePid)
    if (msNavStatusPid) enable ||= (getValueById(msNavStatusPid) || 'Draft').startsWith('Invoice')

    return !enable
  }

  const handleClick = async () => {
    try {
      setLoading(true)

      try {
        if (ticket.status === 'X') throw new Error('Ticket is already closed')

        if (allowReSubmit && (getValueById(msNavStatusPid) || '').startsWith('Credit')) {
          const createNew = await modal.confirm({
            title: 'Creating new invoice',
            content: 'This Oil Command ticket has already been invoiced. Create another?',
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            maskClosable: true
          })
          if (createNew) {
            // fetch line items
            const { items: lineItems } = await fetchQuery(
              lineItemApiV2.list({
                fields: z.object({ id: z.number(), uuid: z.string() }),
                ticket_id__eq: 1494745,
                inc_archived: 1,
                limit: 1000
              })
            )
            await executeMutation(lineItemApi.batch(), {
              ticket: ticket.id,
              items: lineItems.map((item) => ({ id: item.id, uuid: uuid4() }))
            })
            await saveTicket({ uuid: uuid4() })
          } else return // ignore
        }

        const { data } = await executeMutation(msNavApi.submit(ticket.id))

        const payload = {
          property: {
            [msNavStatusPid]: data.nav_status,
            [msNavMessagePid]: data.nav_remark || 'OK'
          }
        }
        if (closedDatePid) payload.property[closedDatePid] = dayjs().formatLocal()
        if (msNavCustomerPid)
          payload.property[msNavCustomerPid] = data.customer__number || getValueById(msNavCustomerPid) || ''

        await saveTicket(payload)

        if (data.nav_remark) throw new Error(`MS NAV: ${data.nav_remark}`)

        notification.success({ message: 'Submit Success', description: 'Successfully submitted to MS NAV' })
      } catch (error: any) {
        let message = error?.message || 'Unknown error'
        if (error?.response) {
          message = error?.response.headers ? getApiMessage(error?.response) : message
          await saveTicket({
            property: {
              [msNavStatusPid]: 'E',
              [msNavMessagePid]: message
            }
          })
        }

        notification.error({ message: 'Submit Failed', description: message })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button {...buttonProps} loading={loading} disabled={isDisabled()} onClick={handleClick}>
      {buttonProps?.children ? buttonProps.children : property.name}
    </Button>
  )
}
