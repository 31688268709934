import { useApp, useSession } from '@/hooks'
import { queryClient } from '@/query'
import { companyApi, officeApi, officeRelationApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { Button } from '@/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Card, Checkbox, Form, Popconfirm, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLODEX_PATHS } from '../../constants'

export const OfficeView = ({
  companyId,
  officeId,
  onCancel
}: {
  companyId: number
  officeId?: number
  onCancel: () => void
}) => {
  const { permissions, company: userCompany, user } = useSession()
  const officeQuery = useQuery({ ...officeApi.get<Office>(Number(officeId)), enabled: !!officeId })
  const companyQuery = useQuery({ ...companyApi.get<Company>(Number(companyId)), enabled: !!companyId })
  const deleteRelationMutation = useMutation({ ...officeRelationApi.delete() })
  const createRelationMutation = useMutation({ ...officeRelationApi.create() })
  const updateRelationMutation = useMutation({ ...officeRelationApi.patch() })

  const relationsQuery = useQuery({
    ...officeRelationApi.list(
      {
        limit: 'None' as any,
        from_office__eq: user?.profile.office.id,
        to_office__eq: officeId,
        page: 1
      },
      {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }
    )
  })

  const { address, phone_number } = officeQuery.data || {}
  const isPrivate = location.pathname.includes(ROLODEX_PATHS.privateCompanies)
  const deleteOfficeMutation = useMutation(officeApi.delete())
  const { l, notification } = useApp()

  const [formData, setFormData] = useState({
    doesBusinessWith: true,
    preferredVendor: false,
    canAccessAssets: false
  })

  const navigate = useNavigate()

  const showDelete = () => {
    const companyAdmin = permissions.includes('company_admin')
    return (
      companyAdmin &&
      (userCompany.id == companyQuery.data?.owner_company || userCompany.id == officeQuery.data?.creator_company)
    )
  }

  const handleDelete = async () => {
    await deleteOfficeMutation.mutate({ id: officeId })
    notification.success({
      message: 'Office deleted successfully'
    })
    await queryClient.invalidateQueries({ queryKey: ['companies'] })

    onCancel()
  }

  const backPath = () => {
    if (!companyId) {
      return ROLODEX_PATHS.contacts
    }

    return isPrivate ? ROLODEX_PATHS.editPrivateCompany(companyId) : ROLODEX_PATHS.editCompany(companyId)
  }

  useEffect(() => {
    if (!relationsQuery.data?.items?.length) {
      return
    }

    const relation = relationsQuery.data.items[0]
    setFormData({
      doesBusinessWith: !!relation.id,
      preferredVendor: relation.preferred,
      canAccessAssets: relation.manager
    })
    return () => {
      setFormData({
        doesBusinessWith: false,
        preferredVendor: false,
        canAccessAssets: false
      })
    }
  }, [relationsQuery.data])

  const handleSaveRelationMutation = async () => {
    const relation = relationsQuery.data?.items?.[0]

    // if there is a relation but the user unchecks the doesBusinessWith checkbox then delete the relation
    if (relation && !formData.doesBusinessWith) {
      await deleteRelationMutation.mutateAsync({ id: relation.id })
      await relationsQuery.refetch()
      notification.success({
        message: 'Relation deleted successfully'
      })

      return
    }

    if (relation) {
      await updateRelationMutation.mutateAsync({
        ...relation,
        id: formData.doesBusinessWith ? relation.id : null,
        preferred: formData.preferredVendor,
        manager: formData.canAccessAssets
      })
      await relationsQuery.refetch()
      notification.success({
        message: 'Relation updated successfully'
      })
      return
    }

    await createRelationMutation.mutateAsync({
      from_office: user?.profile.office.id,
      to_office: officeId,
      preferred: formData.preferredVendor,
      manager: formData.canAccessAssets
    })

    await relationsQuery.refetch()
    notification.success({
      message: 'Relation created successfully'
    })
  }

  // if (officeQuery.isInitialLoading || companyQuery.isInitialLoading) {
  //   return <Spin spinning />
  // }

  return (
    <Spin spinning={officeQuery.isLoading}>
      <div className="flex justify-end">
        <Space>
          <Button
            iconName="mi:save"
            type="success"
            loading={officeQuery.isLoading}
            onClick={handleSaveRelationMutation}
          >
            Save
          </Button>
        </Space>
      </div>
      <Card
        className="mt-20"
        title={officeQuery.data?.name}
        extra={
          <Space>
            {showDelete() && (
              <Popconfirm
                title="Delete office"
                description={`Are you sure you want to delete #${officeId}?`}
                okText="Delete"
                placement="topRight"
                cancelText="Cancel"
                onConfirm={handleDelete}
                okButtonProps={{
                  'data-cy': 'delete-button-confirm'
                }}
              >
                <Button type="primary" iconName="svg:trash" data-cy="delete-button">
                  Delete Office
                </Button>
              </Popconfirm>
            )}
          </Space>
        }
      >
        <div className="flex">
          <div className="flex flex-col gap-y-16 grow">
            <div>
              <div className="text-gray-400">Address</div>
              <div className="font-medium mt-4">{address?.attn_line}</div>
            </div>
            <div>
              <div className="text-gray-400">City</div>
              <div className="font-medium mt-4">{address?.city}</div>
            </div>
            <div>
              <div className="text-gray-400">State</div>
              <div className="font-medium mt-4">{address?.state}</div>
            </div>
          </div>
          <div className="flex flex-col gap-y-16 grow border-l border-l-border pl-20">
            <div>
              <div className="text-gray-400">Zipcode</div>
              <div className="font-medium mt-4">{address?.zipcode}</div>
            </div>
            <div>
              <div className="text-gray-400">Country</div>
              <div className="font-medium mt-4">{address?.country}</div>
            </div>
            <div>
              <div className="text-gray-400">Phone</div>
              <div className="font-medium mt-4">{phone_number}</div>
            </div>
          </div>
        </div>
      </Card>
      {officeId !== user?.profile.office.id && (
        <div className="mt-16">
          <Form layout="vertical">
            <Form.Item>
              <Checkbox
                disabled={!permissions.includes('company_admin')}
                checked={formData.doesBusinessWith}
                onChange={(e) => setFormData({ ...formData, doesBusinessWith: e.target.checked })}
              >
                Does business with {user?.profile.office.name}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                disabled={!(formData.doesBusinessWith && permissions.includes('company_admin'))}
                checked={formData.preferredVendor}
                onChange={(e) => setFormData({ ...formData, preferredVendor: e.target.checked })}
              >
                Preferred Vendor
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                disabled={!(formData.doesBusinessWith && permissions.includes('company_admin'))}
                checked={formData.canAccessAssets}
                onChange={(e) => setFormData({ ...formData, canAccessAssets: e.target.checked })}
              >
                Can access {user?.profile.office.name} {l('Assets')} & {l('Locations')}
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
      )}
    </Spin>
  )
}
