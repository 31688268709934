import { uuid4 } from '@/utils'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { set } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { bpaConfigAtom, customFormAtom } from '../atoms'
import { BpaAction } from '../schemas'

export const useActions = (ruleIndex: number) => {
  const index = useMemo(() => ruleIndex, [ruleIndex])

  const actions = useAtomValue(
    useMemo(() => selectAtom(bpaConfigAtom, (config) => config.rules[index].actions), [index])
  )

  return { actions }
}

export const useActionsHandlers = (ruleIndex: number) => {
  const setConfig = useSetAtom(bpaConfigAtom)

  const actionsLength = useAtomValue(
    useMemo(() => selectAtom(bpaConfigAtom, (config) => config.rules[ruleIndex].actions?.length || 0), [ruleIndex])
  )
  const customFormId = useAtomValue(useMemo(() => selectAtom(customFormAtom, (form) => form?.id), []))

  const generateKey = () => {
    return `A-${customFormId}-${actionsLength + 1}${Math.round(Math.random() * 1000)}`
  }

  const addAction = () => {
    setConfig((config) => {
      config.rules[ruleIndex].actions = config.rules[ruleIndex].actions || []
      config.rules[ruleIndex].actions.push({
        uuid: uuid4(),
        key: generateKey(),
        type: 'set_variable',
        name: 'Var 1',
        value: 'Hey!',
        d_type: 'str',
        data: {}
      })
    })
  }

  const deleteAction = (idx: number) => {
    setConfig((config) => {
      config.rules[ruleIndex].actions.splice(idx, 1)
    })
  }

  const onChange = useCallback(
    (data: Partial<BpaAction>, idx: number) => {
      setConfig((config) => {
        const action = config.rules[ruleIndex].actions[idx]
        Object.entries(data).forEach(([key, value]) => {
          set(action, key, value)
        })
      })
    },
    [ruleIndex, setConfig]
  )

  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  const onSortingChange = useCallback(
    (actions: BpaAction[]) => {
      setConfig((config) => {
        config.rules[ruleIndex].actions = actions
      })
    },
    [ruleIndex, setConfig]
  )

  return {
    generateKey,
    addAction,
    deleteAction,
    onChange,
    onChangeDebounced,
    onSortingChange
  }
}
