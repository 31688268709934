import { ticketApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { transformCategories } from '../helpers'
import { LineItemsStats } from '../schemas'

export const useItemsStats = (ticketId: number) => {
  const statsQuery = useQuery({
    ...ticketApi.lineItemsStats<LineItemsStats>(ticketId),
    enabled: !!ticketId
  })
  const categories = useMemo(() => (statsQuery?.data ? transformCategories(statsQuery?.data) : []), [statsQuery?.data])
  const getCategoryById = useCallback((id: number) => categories.find((c) => c.id === id), [categories])

  const lastGroupNo = useMemo(() => statsQuery?.data?.group.group__max || 0, [statsQuery?.data?.group.group__max])
  const lastSequenceNo = useMemo(
    () => statsQuery?.data?.sequence.sequence__max || 0,
    [statsQuery?.data?.sequence.sequence__max]
  )

  return {
    statsQuery,
    getCategoryById,
    categories,
    kinds: statsQuery?.data?.category_kinds,
    discounts: statsQuery?.data?.discounts || [],
    taxes: statsQuery?.data?.taxes || [],
    lastGroupNo,
    lastSequenceNo,
    totalAmount: statsQuery?.data?.total_amount || 0
  }
}
