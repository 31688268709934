import { ObjectReturn, Params, RequestConfig } from '@/types/api/core'
import { request } from '../core'

export const _integrationsApiV2: {
  supersetEmbed: (params?: Params, config?: RequestConfig) => ObjectReturn<{ token: string }>
} = {
  supersetEmbed(params, config) {
    return {
      queryKey: ['v2/integrations', 'superset', 'embed'],
      queryFn: ({ signal }) =>
        request({ ...config, params, method: 'GET', url: '/v2/integrations/superset/embed/', signal: signal })
    }
  }
}
