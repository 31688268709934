import { ErrorView } from '@/layouts/views'
import { nanoid } from 'nanoid'
import { RouteObject } from 'react-router-dom'
import { ViewComponent } from './types'

export const route = <TProps = unknown, TData = unknown>(
  path: RouteObject['path'],
  Component: ViewComponent<TProps, TData>,
  children?: RouteObject[] | null,
  props?: TProps,
  override?: RouteObject
): RouteObject => {
  const index = path === 'index'

  // important! generate a unique key for the route
  // this forces a re-render of the route component
  // when the route changes. this is necessary when using same
  // component for different routes (e.g. add/edit)
  const key = nanoid()

  return {
    index,
    path: index ? undefined : path,
    loader: Component.loader,
    handle: Component.handle,
    element: <Component key={key} {...(props as any)} />,
    children: children || undefined,
    ErrorBoundary: ErrorView,

    // revalidate only on path change
    shouldRevalidate: ({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname,
    ...override
  } as RouteObject
}
