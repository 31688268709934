import { EquipmentStats } from '@/types/equipment-stats'
import { InventoryIndicator } from '@/types/inventory-indicator'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Chart } from 'react-chartjs-2'

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  LineController,
  BarController
)

type Props = {
  inventoryIndicator: InventoryIndicator
  equipmentStats: EquipmentStats[]
  yMax?: string
  yMin?: string
}

export const TransactionsChart = ({ inventoryIndicator, equipmentStats, yMax, yMin }: Props) => {
  const applyDatePadding = (data: any[]) => {
    if (data.length === 0) {
      return []
    }

    const first = data[0]
    const last = data.length > 1 ? data[data.length - 1] : null

    return [
      { x: dayjs(first.x).subtract(1, 'day').format('YYYY-MM-DD'), y: null },
      first,
      ...(last ? last : []),
      {
        x: dayjs((last || first).x)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        y: null
      }
    ]
  }

  const chartData = useMemo(() => {
    const stats = equipmentStats[0]

    if (!stats) {
      return []
    }

    const transactions = stats.transactions?.reduce((acc: any, item: any) => {
      const sameDayItem = acc.find((i: any) => dayjs(i.timepoint).isSame(dayjs(item.timepoint), 'day'))
      const isNewer = sameDayItem && dayjs(item.timepoint).isAfter(dayjs(sameDayItem.timepoint))

      if (isNewer) {
        return acc.map((i: any) => (dayjs(i.timepoint).isSame(dayjs(item.timepoint), 'day') ? item : i))
      }

      if (sameDayItem) {
        return acc
      }

      return [...acc, item]
    }, [])

    const data = transactions.map((item: any) => ({
      x: dayjs(item.timepoint).format('YYYY-MM-DD'),
      y: item.balance
    }))

    return data
  }, [equipmentStats])

  const chartType = chartData.length < 2 ? 'bar' : 'line'

  return (
    <Chart
      type={chartType}
      data={{
        datasets: [
          {
            borderColor: 'rgba(85, 171, 255)',
            borderWidth: 2,
            fill: false,
            tension: 0.1,
            backgroundColor: 'rgba(85, 171, 255, 0.2)',
            data: chartType === 'bar' ? applyDatePadding(chartData) : chartData
          }
        ]
      }}
      options={{
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (val: any) => {
                return `Balance: ${val.raw.y}`
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            max: yMax ? parseFloat(yMax) : undefined,
            min: yMin ? parseFloat(yMin) : undefined
          },
          x: {
            type: chartType === 'line' ? 'time' : undefined,
            time: {
              displayFormats: { day: 'YYYY-MM-DD' },
              unit: 'day',
              tooltipFormat: 'YYYY-MM-DD'
            },
            ticks: {
              align: 'end',
              callback: function (value) {
                const date = dayjs(this.getLabelForValue(value as any))
                const format = dayjs().isSame(date, 'day') ? '[Today]' : 'MMM D'
                return date.format(format)
              }
            }
          }
        }
      }}
    />
  )
}
