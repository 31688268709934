import { CodeEditor } from '@/components/code-editor'
import { Select } from '@/ui'
import { dig } from '@/utils'
import { Divider, Form, Input } from 'antd'
import { FC } from 'react'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSyncCustomRecord } from '../../schemas'

type Props = {
  action: BpaActionSyncCustomRecord
  onChange: (data: Partial<BpaActionSyncCustomRecord>) => void
}

const STRING_FIELDS = [
  {
    name: 'label',
    label: 'Label'
  },
  {
    name: 'char_1',
    label: 'Field 1'
  },
  {
    name: 'char_2',
    label: 'Field 2'
  },
  {
    name: 'char_3',
    label: 'Field 3'
  },
  {
    name: 'char_4',
    label: 'Field 4'
  }
]

export const InputSyncCustomRecord: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      {STRING_FIELDS.map((f) => (
        <Form.Item key={f.name} label={f.label} className="mb-6">
          <Input
            onChange={(e) => onChangeDebounced({ data: { ...action.data, [f.name]: e.target.value } })}
            defaultValue={dig(action.data, f.name, '')}
          />
        </Form.Item>
      ))}
      <Form.Item label="Extra Data(YAML)">
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={action.data.data || ''}
          onChange={(value) => onChangeDebounced({ data: { ...action.data, data: value } })}
          mode="yaml"
        />
      </Form.Item>
      <Divider />
      <Form.Item label="Unique By">
        <Select
          mode="multiple"
          options={STRING_FIELDS.map((f) => ({ value: f.name, label: f.label }))}
          value={action.unique_by || ['label', 'char_1']}
          onChange={(val) => onChange({ unique_by: val })}
        />
      </Form.Item>
    </div>
  )
}

export const InputSyncCustomRecordMemo = React.memo(InputSyncCustomRecord)
