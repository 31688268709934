import { FC } from 'react'
import { TicketSignature } from '../../../../../schemas'
import { Card } from '../../../../ui'
import { DeleteSignatureButton } from '../delete-signature-button/DeleteSignatureButton'
import { SignaturePreview } from '../signature-preview'

export const SignatureCard: FC<{ signature: TicketSignature; disabled?: boolean }> = ({ signature, disabled }) => {
  return (
    <Card
      key={signature.id}
      content={<SignaturePreview signature={signature} />}
      actions={<DeleteSignatureButton signature={signature} disabled={disabled} />}
    />
  )
}
