import { TABLE_VIEW_TYPES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { authApi, profileApi, tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { Tooltip } from '@/ui'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Popconfirm, Radio, Select, Space } from 'antd'
import { cloneDeep } from 'lodash'
import { useMemo } from 'react'
import { ACCOUNT_SETTINGS_PATHS } from '../../../constants'
import { ModalButton } from './modal-button'

export const TicketViewsListView = view<any, any>(Component)

function Component() {
  const { notification, l } = useApp()
  const updateUserDataMutation = useMutation({ ...profileApi.update({}, 'user_data') })
  const deleteTableViewMutation = useMutation({ ...tableViewApi.delete() })
  const updateTableViewMutation = useMutation({ ...tableViewApi.update<TableView>() })
  const createTableViewMutation = useMutation({ ...tableViewApi.create<TableView>() })
  const session = useSession()

  // Note: Not getting user from useSession because it will not be updated after mutation using refetch
  // Todo: Add refetch method to useSession
  const { data: user, ...userQuery } = useQuery({ ...authApi.user() })
  const userData = user?.profile.userdata_json

  const viewTypes = useMemo(() => {
    if (!userData) {
      return {}
    }

    return {
      T: userData.active_table_view,
      L: userData.active_loads_view,
      J: userData.active_jobs_view,
      I: userData.active_lines_view
    }
  }, [userData])

  const handleSaveUserView = async (tableView: TableView, key?: any) => {
    const isActive = viewTypes[tableView.type as keyof typeof viewTypes] === tableView.id

    if (isActive) {
      return
    }

    const userData = cloneDeep(user?.profile.userdata_json)

    if (!userData) {
      return
    }

    const { type, id } = tableView

    if (key) {
      userData[key as keyof typeof userData] = id as any
    } else {
      if (type === 'T') {
        userData.active_table_view = id
      } else if (type === 'L') {
        userData.active_loads_view = id
      } else if (type === 'J') {
        userData.active_jobs_view = id
      } else if (type === 'I') {
        userData.active_lines_view = id
      }
    }

    await updateUserDataMutation.mutateAsync({
      id: user?.profile.id,
      userdata_json: userData
    })

    await userQuery.refetch()

    notification.success({
      message: `Active ${TABLE_VIEW_TYPES[
        tableView.type as keyof typeof TABLE_VIEW_TYPES
      ].toLowerCase()} successfully changed`
    })
  }

  const handleDeleteTableView = async (id: number) => {
    await deleteTableViewMutation.mutateAsync({
      id
    })

    notification.success({
      message: 'Table view deleted'
    })
  }

  const handleChangeViewScope = async (tableView: TableView & { viewScope: string }) => {
    const {
      user,
      filters,
      hide_ticket_links,
      selected_fields,
      sort_1,
      sort_2,
      config,
      config_json,
      description,
      merge_fields,
      name,
      type,
      viewScope
    } = tableView

    await updateTableViewMutation.mutateAsync({
      id: tableView.id,
      company: viewScope == 'company' ? session.company?.id : null,
      user,
      filters,
      hide_ticket_links,
      selected_fields,
      sort_1,
      sort_2,
      config,
      config_json,
      description,
      merge_fields,
      name,
      type
    })

    notification.success({
      message: 'Table view updated'
    })
  }

  const handleCopyTableView = async (tableView: TableView) => {
    const {
      id,
      created_by,
      uuid,
      name,
      modified_at,
      modified_by,
      created_at,
      deleted_at,
      deleted_by,
      options,
      ...restTableView
    } = tableView

    try {
      await createTableViewMutation.mutateAsync({
        ...restTableView,
        options: { custom_forms: options?.custom_forms || '[]' },
        name: `${name} - Copy`
      })

      notification.success({
        message: 'Table view copied'
      })
    } catch {
      notification.error({
        message: 'Failed to copy table view'
      })
    }
  }

  return (
    <ListView
      header={{
        title: l('TableViews'),
        border: false,
        accent: false,
        actions: [
          <ModalButton
            key={'add-ticket-view'}
            type={'primary'}
            shape={'default'}
            iconName={'fa:plus'}
            data-cy={'add-ticket-view-button'}
          >
            Add {l('TableView')}
          </ModalButton>
        ]
      }}
      table={{
        queryApi: tableViewApi.list,
        queryParams: {
          order: 'id',
          list_all: 1,
          deleted_at__isnull: true
        },
        columns: [
          {
            width: 50,
            sorter: false,
            render: (_, tableView) => (
              <Radio
                checked={viewTypes[tableView.type as keyof typeof viewTypes] === tableView.id}
                onChange={() => handleSaveUserView(tableView)}
              />
            )
          },
          {
            title: 'Name',
            sorter: false,
            dataIndex: 'name'
          },
          {
            title: 'Description',
            sorter: false,
            dataIndex: 'description'
          },
          {
            title: 'Owner',
            sorter: false,
            width: 200,
            render: (_, tableView) => (
              <Select
                placeholder="Select"
                value={tableView.company ? 'company' : 'user'}
                onChange={(value) => handleChangeViewScope({ ...tableView, viewScope: value })}
                popupMatchSelectWidth={false}
                options={[
                  {
                    label: 'User',
                    value: 'user'
                  },
                  {
                    label: 'Company',
                    value: 'company'
                  }
                ]}
              />
            )
          },
          {
            title: 'Type',
            sorter: false,
            width: 150,
            render: (_, tableView) => TABLE_VIEW_TYPES[tableView.type as keyof typeof TABLE_VIEW_TYPES]
          },
          {
            width: 150,
            sorter: false,
            align: 'right',
            render: (_, tableView: TableView) => {
              const isActiveTableView = viewTypes[tableView.type as keyof typeof viewTypes] === tableView.id
              return (
                (tableView.user === session?.user?.id || session.permissions.includes('company_admin')) && (
                  <Space>
                    <Button iconName="mi:file_copy" type="text" onClick={() => handleCopyTableView(tableView)} />
                    <AppLink
                      v={2}
                      to={ACCOUNT_SETTINGS_PATHS.editTicketView(tableView.id)}
                      state={{
                        from: location.pathname
                      }}
                    >
                      <Button iconName="svg:edit" type="text" />
                    </AppLink>
                    {isActiveTableView ? (
                      <Tooltip title={'Active view cannot be deleted'}>
                        <Button iconName="svg:trash" type="text" disabled />
                      </Tooltip>
                    ) : (
                      <Popconfirm
                        title="Delete Table View"
                        description={`Are you sure you want to delete table view #${tableView.id}?`}
                        onConfirm={() => handleDeleteTableView(tableView.id)}
                        okText="Yes"
                        placement="topLeft"
                        okButtonProps={{ loading: deleteTableViewMutation.isLoading }}
                      >
                        <Button iconName="svg:trash" type="text" disabled={isActiveTableView} />
                      </Popconfirm>
                    )}
                  </Space>
                )
              )
            }
          }
        ]
      }}
    />
  )
}
