import { Select } from '@/ui'
import { parseCsv } from '@/utils'
import { Input, InputProps } from 'antd'
import { FC } from 'react'
import { useDataSources, useFormWatch } from '../../../../../hooks'
import { FieldConfig } from '../../../../../schemas'

export const UserTextInput: FC<
  Omit<InputProps, 'value'> & {
    value?: string
    config?: FieldConfig
  }
> = ({ config, value, onChange, ...props }) => {
  const sourceKey = config?.field.replace('user_text_', 'equipment__string_') + '_source'
  const equipmentSource = useFormWatch(['data', sourceKey as any])
  const source = equipmentSource || config?.stringSource
  const { dataSourcesQuery, getDataSource } = useDataSources()
  const dataSource = getDataSource(source)

  if (!dataSource) return <Input value={value} onChange={onChange} {...props} />

  const { _options, multiselect } = dataSource

  const _value = multiselect ? parseCsv(value) : value

  const handleChange = (value: string | string[]) => {
    if (Array.isArray(value)) return onChange?.(value.join(',') as any)
    onChange?.(value as any)
  }

  return (
    <Select
      placeholder="Select"
      value={_value}
      mode={multiselect ? 'multiple' : undefined}
      options={_options}
      loading={dataSourcesQuery.isLoading}
      onChange={handleChange}
      {...props}
    />
  )
}
