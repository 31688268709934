import { apiHttp } from '@/services/api-service/core'
import { ScheduleType } from '@/utils'
import { UseMutationOptions, useMutation } from '@tanstack/react-query'

type Payload = {
  id?: number
  company: number
  data: { type: ScheduleType }
  end_date: string
  name: string
  resource: number
  start_date: string
}

type ResponseSuccess = {
  id: number
  created_at: string
  modified_at: string
  deleted_at: string | null
  uuid: string
  data: {
    type: ScheduleType
  }
  name: string
  start_date: string
  end_date: string
  created_by: number
  modified_by: number
  deleted_by: number | null
  resource: number
  company: number
}

export const useAddPatchMutation = (options?: UseMutationOptions<ResponseSuccess, unknown, Payload>) => {
  return useMutation({
    mutationFn: addPatch,
    ...options
  })
}

const addPatch = async (payload: Payload): Promise<ResponseSuccess> => {
  if ('id' in payload) {
    const response = await apiHttp.put(`${JAS_SCHEDULE_PATCH_ENDPOINT}${payload.id}/`, payload)
    return response.data
  }

  const response = await apiHttp.post(JAS_SCHEDULE_PATCH_ENDPOINT, payload)
  return response.data
}

// TODO: Move this to a constants file?
const JAS_SCHEDULE_PATCH_ENDPOINT = '/jas/schedule_patches/'
