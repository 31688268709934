import { queryClient } from '@/query'
import { ApiResource, MutationReturn, Params, RequestConfig } from '@/types/api/core'
import { R, createResourceApi, getPayloadId } from '../core'

type EtlGeneratePayload = {
  id: number
  ticket_id?: number
  custom_filter?: Record<string, string>
}

export type ProgrammedReportApi = ApiResource & {
  etlGenerate: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData, EtlGeneratePayload>
}

export const _programmedReportApi: ProgrammedReportApi = {
  ...createResourceApi('programmed_reports'),
  etlGenerate: (params, config) => ({
    mutationFn: (data: any) =>
      R('POST', `/programmed_reports/${getPayloadId(data)}/etl_generate/`, { params, data, ...config }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['programmed_reports'] })
      queryClient.invalidateQueries({ queryKey: ['transformation_logs'] })
    }
  })
}
