import { InputNumber } from 'antd'
import { useMemo } from 'react'
import { Component } from '../../types'

type Props = {
  component: Component
}

export const AmountField = ({ component }: Props) => {
  const calculatedAmount = useMemo(() => {
    if (!component || !component.data) {
      return
    }

    const { data } = component

    let amount = Number(data.amount || 0)
    const unitsStandby = Number(data.units_standby) || 0
    const standbyRate = Number(data.standby_rate) || 0
    const unitsMin = Number(data.units_min) || 0
    const usageRate = Number(data.usage_rate) || 0
    const hands = Number(data.hands) ? data.hands : 1
    const unitsUsed = Math.max(unitsMin, Number(data.units_used) || 0)
    const discount = Number(data.discount) || 0
    const minimum = Number(data.minimum) || 0

    if (!(unitsStandby || standbyRate || unitsUsed || usageRate)) {
      return amount
    }

    amount = (1 - (discount || 0) / 100) * (unitsStandby * standbyRate + hands * unitsUsed * usageRate)

    if (minimum) {
      amount = Math.max(amount, minimum)
    }

    return amount
  }, [component])

  return <InputNumber value={calculatedAmount} className="w-full" />
}
