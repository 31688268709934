import { equipmentApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export const useUpdateEquipmentUsageRate = () => {
  const updateEquipmentUsageRateMutation = useMutation(
    equipmentApi.patch<any, { id: number; usage_rate: number | null }>()
  )

  const updateEquipmentUsageRate = async (id: number, usageRate: number | null) => {
    await updateEquipmentUsageRateMutation.mutateAsync({ id, usage_rate: usageRate })
  }

  return {
    updateEquipmentUsageRate,
    updateEquipmentUsageRateMutation
  }
}
