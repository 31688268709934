import { z } from 'zod'

export const EquipmentSchema = z.object({
  id: z.string(),
  category__parent_category__name: z.string(), // Category
  category__name: z.string(), // Subcategory
  category__parent_category__kind__name: z.string(),
  category__kind__name: z.string(),
  sku: z.string(),
  description: z.string(),
  inactive: z.boolean(),
  modified_at: z.string()
})

export type Equipment = z.infer<typeof EquipmentSchema>
