import { ACCOUNTING_SERVICES } from '@/modules/ticket/form/constants'
import { Select } from '@/ui'
import { Form } from 'antd'
import React from 'react'
import { BpaActionSubmitToAccounting } from '../../schemas'

type Props = {
  action: BpaActionSubmitToAccounting
  onChange: (data: Partial<BpaActionSubmitToAccounting>) => void
}

export const InputSubmitToAccounting: React.FC<Props> = ({ action, onChange }) => {
  return (
    <div>
      <Form.Item label="Accounting Service" name={['button_config', 'service']}>
        <Select
          onChange={(val) => onChange({ service: val })}
          options={ACCOUNTING_SERVICES.map((service) => ({ value: service.key, label: service.label }))}
        />
      </Form.Item>
    </div>
  )
}

export const InputSubmitToAccountingMemo = React.memo(InputSubmitToAccounting)
