import { uuid4 } from '@/utils'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { isArray, set } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { bpaConfigAtom } from '../atoms'
import { BpaCondition } from '../schemas'

export const useConditions = (ruleIndex?: number, initialConditions?: BpaCondition[]) => {
  const index = useMemo(() => ruleIndex, [ruleIndex])
  const setConditions = useSetAtom(bpaConfigAtom)

  const conditions = useAtomValue(
    useMemo(
      () =>
        selectAtom(bpaConfigAtom, (config) =>
          index === undefined ? config.conditions : config.rules[index].conditions
        ),
      [index]
    )
  )

  useEffect(() => {
    if (initialConditions) {
      setConditions((config) => {
        if (index === undefined) config.conditions = initialConditions
        else config.rules[index].conditions = initialConditions
      })
    }
  }, [initialConditions, index, setConditions])

  return {
    conditions
  }
}

export const useConditionsHandlers = (ruleIndex?: number) => {
  const _ruleIndex = useMemo(() => ruleIndex, [ruleIndex])

  const setConfig = useSetAtom(bpaConfigAtom)

  const onAddCondition = () => {
    setConfig((config) => {
      const newCondition: BpaCondition = {
        uuid: uuid4(),
        logic: 'and',
        fieldType: 'ticket',
        field: 'name',
        operator: 'equals',
        value1: '',
        value2: '',
        cast: 'str'
      }

      if (_ruleIndex === undefined) config.conditions.push(newCondition)
      else config.rules[_ruleIndex].conditions.push(newCondition)
    })
  }

  const onDeleteCondition = (idx: number) => {
    setConfig((config) => {
      if (_ruleIndex === undefined) config.conditions.splice(idx, 1)
      else config.rules[_ruleIndex].conditions.splice(idx, 1)
    })
  }

  const onFieldChange = useCallback(
    (key: string, value: string, idx: number) => {
      setConfig((config) => {
        const condition = _ruleIndex === undefined ? config.conditions[idx] : config.rules[_ruleIndex].conditions[idx]
        set(condition, key, value)

        if (key === 'operator') {
          // on operator change
          condition.cast = ['gt', 'gte', 'lt', 'lte'].includes(value) ? 'int' : 'str'
        } else if (key === 'field') {
          // on field change
          condition.operator = 'equals'
          condition.cast = 'str'
          condition.value1 = ''
          condition.value2 = ''

          if (condition.field === 'local_status' || condition.field === 'status') {
            condition.operator = 're'
            condition.value2 = []
          }
        }

        // process local_status
        if (condition.field === 'local_status' || condition.field === 'status') {
          // convert value2 to regex
          condition.operator = 're'
          condition.value1 = isArray(condition.value2) ? '^[' + condition.value2.join('') + ']$' : ''
        }
      })
    },
    [_ruleIndex, setConfig]
  )

  const onFieldChangeDebounce = useDebouncedCallback(onFieldChange, 250)

  const onValuesChange = useCallback(
    (value: any, condition: BpaCondition, idx: number) => {
      if (condition.field === 'local_status' || condition.field === 'status')
        onFieldChangeDebounce('value2', value, idx)
      else onFieldChange('value1', value, idx)
    },
    [onFieldChange, onFieldChangeDebounce]
  )

  const onSortingChange = useCallback(
    (conditions: BpaCondition[]) => {
      setConfig((config) => {
        if (_ruleIndex === undefined) config.conditions = conditions
        else config.rules[_ruleIndex].conditions = conditions
      })
    },
    [_ruleIndex, setConfig]
  )

  return {
    onAddCondition,
    onDeleteCondition,
    onFieldChange,
    onValuesChange,
    onFieldChangeDebounce,
    onSortingChange
  }
}
