import { FC } from 'react'

type CoverageDateCellProps = {
  date: string
  jobRoleId: number
  dayOffEployeesQuantity: number
  availableEmployeesQuantity: number
  onClick: (date: string, jobRoleId: number) => void
}

export const CoverageDateCell: FC<CoverageDateCellProps> = ({
  date,
  jobRoleId,
  dayOffEployeesQuantity,
  availableEmployeesQuantity,
  onClick
}) => {
  const handleCellClick = () => {
    onClick(date, jobRoleId)
  }

  return (
    <div
      className="w-full h-full flex justify-center items-center hover:text-primary cursor-pointer"
      onClick={handleCellClick}
    >
      {dayOffEployeesQuantity}/{availableEmployeesQuantity}
    </div>
  )
}
