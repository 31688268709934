import { JobHourBar } from '@/components/calendar-table/job-hour-bar'
import { JobsHoursPopover } from '@/components/calendar-table/jobs-hours-popover'
import { useAppTheme } from '@/hooks/use-app-theme'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { SCHEDULE_TYPES } from '@/utils'
import { Dayjs } from 'dayjs'
import React, { useMemo } from 'react'

type Props = {
  schedule: JasResourceUseQueryResponse['schedules'][0]
  name: string
  isShortForm: boolean
  date: Dayjs
  darken?: boolean
}

export const ResourceCalendarDayCell: React.FC<Props> = ({ schedule, isShortForm, date, name, darken }) => {
  const { token } = useAppTheme()
  const scheduleType = SCHEDULE_TYPES[schedule.type as keyof typeof SCHEDULE_TYPES]

  const events = useMemo(() => {
    if (!Array.isArray(schedule.data)) {
      return []
    }

    return schedule.data
  }, [schedule.data])

  return (
    <div className={'w-full h-full relative'}>
      {schedule.type && (
        <div
          className="flex flex-col border-0 p-0 border-none justify-center items-center w-full h-full select-none"
          style={{
            backgroundColor: scheduleType.color,
            color: scheduleType.textColor ? scheduleType.textColor : token.colorTextBase
          }}
        >
          <div className="font-medium">{isShortForm ? scheduleType.titleShort : scheduleType.title}</div>
          {!isShortForm && events.length > 0 && (
            <>
              <JobsHoursPopover events={events} name={name} date={date} />
              <div className="w-full relative px-8 top-6">
                <JobHourBar events={events} date={date} />
              </div>
            </>
          )}
        </div>
      )}
      {darken && <div className="absolute inset-0 bg-black opacity-10 cursor-not-allowed" />}
    </div>
  )
}

export const ResourceCalendarDayCellMemo = React.memo(ResourceCalendarDayCell, (prevProps, props) => {
  return (
    prevProps.schedule.type == props.schedule.type &&
    prevProps.darken == props.darken &&
    prevProps.isShortForm == props.isShortForm
  )
})
