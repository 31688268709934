import { JOTAI_GLOBAL_STORE } from '@/constants/app'
import { useApp } from '@/hooks'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH, sidebarCollapsedAtom } from '@/layouts/dashboard-layout'
import { Button, CopyButton, TipButton } from '@/ui/button'
import { Grid, Tag } from 'antd'
import cn from 'classnames'
import { useAtomValue } from 'jotai'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import AceEditor, { IAceEditorProps } from 'react-ace'

// @todo: lazy load ace editor (reduces ~500KB)
import { safeJsonParse } from '@/utils'
import 'ace-builds/src-noconflict/ace'
import Beautify from 'ace-builds/src-noconflict/ext-beautify'
import 'ace-builds/src-noconflict/mode-css'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/mode-yaml'
import 'ace-builds/src-noconflict/mode-sql'

const { useBreakpoint } = Grid

type Props = {
  mode?: 'text' | 'html' | 'css' | 'javascript' | 'json' | 'yaml' | 'python' | 'sql'
  isInModal?: boolean
  hideToolbar?: boolean
  valueType?: 'string' | 'object'
} & IAceEditorProps

/**
 * Code editor component
 *
 * Notes about height and lines:
 * - height prop has priority over minLines and maxLines
 * - if height is not provided, minLines and maxLines will be used
 * - if height, minLines and maxLines are not provided, default values will be used
 *   - default values are 5 lines for minLines and maxLines
 */
export const CodeEditor: FC<Props> = ({
  mode,
  isInModal,
  hideToolbar,
  height,
  minLines,
  maxLines,
  value,
  valueType = 'string',
  onChange,
  ...restProps
}) => {
  const editorRef = useRef<any>()
  const { notification } = useApp()
  const screens = useBreakpoint()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const isSidebarCollapsed = useAtomValue(sidebarCollapsedAtom, { store: JOTAI_GLOBAL_STORE })

  restProps.tabSize = restProps.tabSize || 2

  if (height !== undefined) {
    minLines = 0
    maxLines = 0
  } else {
    minLines = minLines !== undefined ? minLines : 5
    maxLines = maxLines !== undefined ? maxLines : 5
  }

  const style = useMemo(() => {
    // Positioning left to offset the sidebar, because top parent has transform property
    if (isFullScreen && isInModal) return { left: 0 }
    if (isFullScreen && screens.xs) return { left: 0 }
    if (isFullScreen && isSidebarCollapsed) return { left: `-${SIDEBAR_COLLAPSED_WIDTH}px` }
    if (isFullScreen && !isSidebarCollapsed) return { left: `-${SIDEBAR_WIDTH}px` }

    return {}
  }, [isFullScreen, isInModal, isSidebarCollapsed, screens.xs])

  const handleFormat = useCallback(() => {
    if (!editorRef.current) return

    // for mode is
    if (mode === 'json') {
      try {
        const value = editorRef.current.editor.getValue()
        const json = JSON.parse(value)
        editorRef.current.editor.setValue(JSON.stringify(json, null, 2), -1)
        return
      } catch (e) {
        // another try with beautify below
      }
    }

    Beautify.beautify(editorRef.current.editor.session)
  }, [mode])

  const handleChange = useCallback(
    (value: any) => {
      if (valueType === 'object' && mode === 'json') value = safeJsonParse(value)

      onChange?.(value)
    },
    [mode, onChange, valueType]
  )

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen)
  }, [isFullScreen])

  const handlePaste = useCallback(() => {
    if (!navigator.clipboard) {
      notification.error({ message: 'Clipboard is not supported in your browser' })
      return
    }

    navigator.clipboard.readText().then((text) => {
      editorRef.current.editor.insert(text)
      Beautify.beautify(editorRef.current.editor.session)
    })
  }, [notification])

  return (
    <div
      style={style}
      className={cn({
        'fixed !top-0 right-0 bottom-0 !left-0 z-[1020] tablet:text-left h-screen': isFullScreen
      })}
    >
      <div
        className={cn('flex flex-col h-full border overflow-hidden', {
          rounded: !isFullScreen
        })}
      >
        {/* Toolbar */}
        {!hideToolbar && (
          <div className="flex flex-row items-center gap-4 bg-gray-200 p-4 border-b">
            <CopyButton copyText={editorRef.current?.editor?.getValue()} size="small" />
            <TipButton iconName="fa:paste" size="small" title="Paste" flashTitle="Pasted" onClick={handlePaste} />
            <TipButton
              className={cn({ hidden: mode === 'yaml' })}
              iconName="fa:brackets-curly"
              size="small"
              title="Format"
              flashTitle="Formatted"
              onClick={handleFormat}
            />
            <div className="flex-1" />
            <Tag className={'uppercase'}>{mode}</Tag>
            <Button
              iconName={isFullScreen ? 'fa:compress' : 'fa:expand'}
              type="default"
              size="small"
              onClick={toggleFullScreen}
            />
          </div>
        )}

        {/* Editor */}
        <AceEditor
          {...restProps}
          onChange={handleChange}
          ref={editorRef}
          value={typeof value === 'string' ? value : value ? JSON.stringify(value, null, 2) : value}
          mode={mode}
          name="ace-editor"
          width="100%"
          height={isFullScreen ? '100%' : height}
          showPrintMargin={false}
          minLines={isFullScreen ? undefined : minLines}
          maxLines={isFullScreen ? undefined : maxLines}
          commands={Beautify.commands as any} // issue with types between react-ace and ace-builds
          setOptions={{
            useWorker: false
          }}
        />
      </div>
    </div>
  )
}
