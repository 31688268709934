import { z } from 'zod'

export const CategorySchema = z.object({
  id: z.number().nullish(),
  name: z.string().nullish(),
  kind_id: z.number().nullish(),
  parent_category_id: z.number().nullish(),
  external_id: z.string().nullish(),
  tags: z.array(z.object({ id: z.number(), name: z.string() })),
  format_qty_as_duration: z.boolean().nullish()
})

export type Category = z.infer<typeof CategorySchema>

export type CategoryForm = {
  title: string
  mode: 'add' | 'edit'
  category: Category
}
