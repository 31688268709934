import { useSession } from '@/hooks'
import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Icon, Tooltip } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import cn from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedTypeAtom } from '../../atoms'
import { Layout } from '../../components/layout'
import { useReverseRelations } from '../../hooks'
import { AddOfficeView } from '../add-office-view'
import { OfficeView } from '../office-view'
import { AddCompaniesView, DisplayCompaniesView, EditCompaniesView } from './OffsetContent'

export const CompaniesView = () => {
  const [offsetActive, setOffsetActive] = useState(false)
  const { relationsMap } = useReverseRelations()
  const { permissions, company } = useSession()
  const deleteCompanyMutation = useMutation(companyApi.delete())
  const queryClient = useQueryClient()
  const [companyType, setCompanyType] = useState<'add' | 'edit' | 'view' | 'office-edit' | 'office-add'>('add')
  const selectedType = useAtomValue(selectedTypeAtom)
  const setSelectedType = useSetAtom(selectedTypeAtom)

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
  const [selectedOffice, setSelectedOffice] = useState<number | null>(null)

  const handleDelete = async (id: number) => {
    await deleteCompanyMutation.mutateAsync({ id })
    await queryClient.invalidateQueries(companyApi.list().queryKey)
  }

  const showDelete = (ownerCompany?: number) => {
    const superUser = permissions.includes('super_admin')
    const companyAdmin = permissions.includes('company_admin')
    return superUser || (companyAdmin && company?.id === ownerCompany)
  }

  const showEdit = (isPublic?: boolean) => {
    const superUser = permissions.includes('super_admin')
    const companyAdmin = permissions.includes('company_admin')
    return superUser || (companyAdmin && !!isPublic)
  }

  const onCancel = () => {
    setSelectedCompany(null)
    setSelectedOffice(null)
    setSelectedType(null)
    setOffsetActive(false)
  }

  useEffect(() => {
    if (selectedType && selectedType?.title === 'Companies') {
      setOffsetActive(true)
      setCompanyType('add')
      setSelectedCompany(null)
    }
  }, [selectedType])

  return (
    <Layout<Company>
      page="/rolodex/companies"
      columns={[
        {
          key: 'name',
          title: 'Company Name',
          width: 300,
          sorter: true
        },
        {
          key: 'actions',
          align: 'center',
          width: 80,
          render: (_, company) => {
            return (
              <Space>
                <Tooltip title={'View Company'} mouseEnterDelay={0.5}>
                  <Button
                    iconName="fa:chevron-circle-right"
                    className="text-16"
                    type="text"
                    onClick={() => {
                      setSelectedCompany(company)
                      setOffsetActive(true)
                      setCompanyType('view')
                      setSelectedType(null)
                      setSelectedOffice(null)
                    }}
                  />
                </Tooltip>
                {showEdit(company.public) && (
                  <Button
                    iconName="fa:edit"
                    type="text"
                    onClick={() => {
                      setSelectedCompany(company)
                      setOffsetActive(true)
                      setCompanyType('edit')
                      setSelectedType(null)
                    }}
                  />
                )}
                {showDelete(company.owner_company) && (
                  <Popconfirm
                    title="Delete Company"
                    description={`Are you sure you want to delete company #${company.id}?`}
                    placement="topLeft"
                    onConfirm={() => handleDelete(company.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      loading: deleteCompanyMutation.isLoading
                    }}
                  >
                    <Button iconName="svg:trash" type="text" />
                  </Popconfirm>
                )}
              </Space>
            )
          }
        },
        {
          key: 'company_office',
          title: 'Company Office(s)',
          width: 300,
          render: (_, company) => {
            return (
              <div>
                {company.offices?.map((office: any) => (
                  <div
                    key={office.id}
                    className={cn(
                      'flex justify-between items-center group hover:bg-primary-50 px-4 ' +
                        'py-2 relative border-b last:border-none',
                      {
                        'bg-primary-50': office.id === selectedOffice
                      }
                    )}
                  >
                    <div className="cursor-default flex flex-row">
                      <span className={'line-clamp-1'}>{office.name}</span>
                      {relationsMap[office.id] && (
                        <span className={'ml-10 flex flex-row gap-6'}>
                          <Tooltip title={'Does Business With'}>
                            <Icon name="fa:badge-check" className="text-primary cursor-pointer" />
                          </Tooltip>
                          {relationsMap[office.id].preferred && (
                            <Tooltip title={'Preferred Vendor'}>
                              <Icon name="fa:star" className="text-primary cursor-pointer" />
                            </Tooltip>
                          )}
                          {relationsMap[office.id].manager && (
                            <Tooltip title={'Manager'}>
                              <Icon name="fa:shield" className="text-primary cursor-pointer" />
                            </Tooltip>
                          )}
                        </span>
                      )}
                    </div>

                    <div className="flex">
                      <Tooltip title={'View Office'} mouseEnterDelay={0.5}>
                        <Button
                          iconName="fa:chevron-circle-right"
                          type="text"
                          onClick={() => {
                            setSelectedOffice(office.id)
                            setSelectedCompany(company)
                            setOffsetActive(true)
                            setCompanyType('view')
                            setSelectedType(null)
                          }}
                        />
                      </Tooltip>

                      <Tooltip title={'Edit Office'} mouseEnterDelay={0.5}>
                        <Button
                          iconName="fa:edit"
                          type="text"
                          onClick={() => {
                            setSelectedOffice(office.id)
                            setSelectedCompany(company)
                            setOffsetActive(true)
                            setCompanyType('office-edit')
                            setSelectedType(null)
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        }
      ]}
      isOffsetBlockActive={offsetActive}
      offsetContent={
        <div className="">
          <Button iconName="mi:close" onClick={() => onCancel()}>
            Cancel
          </Button>

          {!selectedCompany && companyType === 'add' && <AddCompaniesView isPrivate={false} />}
          {selectedCompany && companyType === 'edit' && (
            <EditCompaniesView company={selectedCompany} isPrivate={false} />
          )}
          {selectedCompany && !selectedOffice && companyType === 'view' && (
            <div className="px-16">
              <DisplayCompaniesView company={selectedCompany} onAddClick={() => setCompanyType('office-add')} />
            </div>
          )}
          {/*  offices */}
          {selectedCompany && ((selectedOffice && companyType === 'office-edit') || companyType === 'office-add') && (
            <div className="px-16">
              <AddOfficeView officeId={selectedOffice} onCancel={() => onCancel()} companyId={selectedCompany.id} />
            </div>
          )}
          {selectedCompany && selectedOffice && companyType === 'view' && (
            <div className="px-16">
              <OfficeView officeId={selectedOffice} onCancel={() => onCancel()} companyId={selectedCompany.id} />
            </div>
          )}
        </div>
      }
    />
  )
}
