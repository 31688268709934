import { contactApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm, useTicketCustomerOffice, useTicketLabel } from '../../../hooks'
import { EditCustomerButton } from '../../buttons/customer-buttons'
import { Label } from '../../ui'

const KEY = 'customer'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const CustomerContactInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const l = useTicketLabel()

  const { customForm, isDisabledField, isHiddenField } = useCustomForm()
  const customerOffice = useTicketCustomerOffice()

  const searchQuery = (text?: string) => {
    // custom contact search
    const config = customForm._features.contactSearch
    const q = [...((config?.enabled && config?.company && ['company|' + config.company]) || [])]

    // customer office search
    const customerCompanyId = customerOffice?.company_id
    if (customerCompanyId) {
      q.push('company|' + customerCompanyId)
      if (q.length > 1) q.push('or')
    }

    return { 'Q[]': q, search: text }
  }

  if (isHiddenField(KEY)) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__contact__label'} />} {...formItemProps}>
      <QuerySelect
        popupClassName={'z-[100]'} // fixes: overlap with contact modal
        apiEndpoint={contactApi.list}
        apiQueryParams={{ fields: 'id,first_name,last_name' }}
        apiSearchBy={searchQuery}
        renderOption={(item) => ({
          value: item.id,
          label: `${item?.first_name || ''} ${item?.last_name || ''}`
        })}
        disabled={isDisabledField(KEY)}
        prefetch={true}
        showCopyText={true}
        footer={
          <div className={'px-8 py-6'}>
            <EditCustomerButton
              block
              size={'small'}
              type={'dashed'}
              className={'!bg-transparent text-gray-400 '}
              iconName={'fa:add'}
              modalProps={{
                iconName: 'fa:add',
                title: `Add ${l('ticket__contact__label')}`
              }}
            >
              <span className={'overflow-ellipsis overflow-hidden'}>Add {l('ticket__contact__label')}</span>
            </EditCustomerButton>
          </div>
        }
        {...(inputProps as any)}
      />
    </Form.Item>
  )
}
