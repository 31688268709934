import { useApp, useFormWatch } from '@/hooks'
import { useCustomFormLabels } from '@/modules/custom-form/hooks'
import { customFormApiV2, ticketImportTemplateApi } from '@/services/api-service'
import { ActionButton, Button, Icon, QuerySelect, Select, SortableInput, UseSortable } from '@/ui'
import { makeMap } from '@/utils'
import { numberToLetter } from '@/utils/text'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Card, Divider, Form, FormProps, Input } from 'antd'
import cn from 'classnames'
import { useMemo } from 'react'
import { ImportTicketField, SelectedImportField } from './schemas'

export function TemplateForm({ onClose, ...formProps }: FormProps & { onClose: () => void }) {
  const { notification } = useApp()

  const [form] = Form.useForm()
  const templateId = useFormWatch('id', form)

  const customFormId = useFormWatch('custom_form_id', form) || undefined
  const { l } = useCustomFormLabels(customFormId)
  const selectedFields = useFormWatch('fields', form) as SelectedImportField[]
  const importFieldsQuery = useQuery(ticketImportTemplateApi.fields({ custom_form_id: customFormId }))
  const importFieldsMap = useMemo(
    () => makeMap(importFieldsQuery.data?.items || [], 'field'),
    [importFieldsQuery.data?.items]
  )
  const importFieldsOptions = useMemo(() => {
    const selectedFieldsKeys = selectedFields?.map((f) => f.field) || []

    return (importFieldsQuery.data?.items || [])
      .filter((f) => selectedFieldsKeys.indexOf(f.field) === -1)
      .map((f: ImportTicketField) => ({
        text: `${l(f.label, f.name)} (${f.field})`,
        value: f.field,
        label: (
          <div>
            {l(f.label, f.name)} <span className={'text-sm text-text-secondary'}>({f.field})</span>
          </div>
        )
      }))
  }, [importFieldsQuery.data?.items, l, selectedFields])

  const templateSaveMutation = useMutation(ticketImportTemplateApi.save())
  const saveTemplate = async () => {
    try {
      const values = await form.validateFields({ recursive: true })
      await templateSaveMutation.mutateAsync(values)
      notification.success({ message: 'Success', description: 'Template saved successfully' })
      onClose()
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to save template' })
    }
  }

  return (
    <Form form={form} layout={'vertical'} component={'div'} {...formProps}>
      <Card
        className={'h-full'}
        title={templateId ? `Edit Template #${templateId}` : 'Create Template'}
        styles={{ body: { padding: '10px' } }}
        extra={
          <div className={'flex gap-6'}>
            <Button size={'small'} shape={'round'} iconName={'fa:close'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={'small'}
              shape={'round'}
              iconName={'fa:save'}
              type={'success'}
              loading={templateSaveMutation.isLoading}
              onClick={saveTemplate}
            >
              Save
            </Button>
          </div>
        }
      >
        <div>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'custom_form_id'} label="Custom Form" rules={[{ required: true }]}>
            <QuerySelect
              apiEndpoint={customFormApiV2.list}
              apiQueryParams={{ fields: 'id,name' }}
              apiSearchBy="name"
              apiValueBy="id"
              renderOption={(cf) => ({ value: cf.id, label: cf.name })}
            />
          </Form.Item>
          <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'description'} label={'Description'}>
            <Input.TextArea />
          </Form.Item>
        </div>

        <div>
          <Divider>Template Fields</Divider>
          {Boolean(templateId) && (
            <Alert
              className="p-12 mb-12"
              type="warning"
              message={
                <div className={'text-warning'}>
                  <Icon name={'fa:exclamation-triangle'} className={'!text-[18px]'} /> Caution!
                </div>
              }
              description={
                <div>
                  Changing fields will break downloaded templates. Only proceed if you're sure it won't affect current
                  users. Create a new template for compatibility.
                </div>
              }
            />
          )}

          <div className={'mb-10'}>
            <Select
              value={null}
              placeholder={
                <div className={'flex gap-6 items-center'}>
                  <Icon name={'fa:plus'} />
                  Add Field
                </div>
              }
              className={'w-full'}
              options={importFieldsOptions}
              onChange={(value) => {
                const field = importFieldsMap[value]
                if (!field) return

                form.setFieldsValue({ fields: [...(selectedFields || []), { field: field.field, name: field.name }] })
              }}
            />
          </div>

          <div className={'flex flex-col'}>
            <Form.Item name={'fields'}>
              <SortableInput
                by={'field'}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                render={(f: any, index, sortable) => <SelectedFieldItem field={f} index={index} sortable={sortable} />}
              />
            </Form.Item>
          </div>
        </div>
      </Card>
    </Form>
  )
}

function SelectedFieldItem({
  field,
  index,
  sortable
}: { field: SelectedImportField; index: number; sortable: UseSortable }) {
  const form = Form.useFormInstance()

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-between relative bg-container rounded border px-10 py-4 mb-6 last:mb-0',
        { ' z-10': sortable?.isDragging }
      )}
      ref={sortable?.setNodeRef}
      style={sortable?.style}
      {...sortable?.attributes}
    >
      <div className={'flex-1 flex flex-row gap-6 items-center'}>
        <span>{numberToLetter(index + 1)}1: </span>

        <div className={'flex-1'}>
          <Form.Item className={'m-0'} name={['fields', index, 'name']}>
            <Input variant={'filled'} />
          </Form.Item>
        </div>
      </div>
      <div>
        <ActionButton
          {...sortable?.listeners}
          iconName={'fa:grip-dots-vertical'}
          size={'small'}
          onClick={(e) => e.stopPropagation()}
          className={'cursor-grab drag-handle'}
        />
        <ActionButton
          iconName={'fa:close'}
          danger={true}
          size={'small'}
          onClick={() => {
            form.setFieldsValue({
              fields: (form.getFieldValue('fields') as SelectedImportField[]).filter((f) => f.field !== field.field)
            })
          }}
        />
      </div>
    </div>
  )
}
