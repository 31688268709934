import { useApp } from '@/hooks'
import { transformationApi, transformationTaskApi } from '@/services/api-service'
import { TransformationTaskType } from '@/types/transformation-task'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { startCase } from 'lodash'
import { isAddTransformationTaskMutationLoadingAtom } from '../../atoms'
import { useProgrammedReportQuery } from '../../hooks'
import { Card } from '../card'

export const TransformStepsCard = () => {
  const { notification } = useApp()
  const { programmedReport, programmedReportQuery } = useProgrammedReportQuery()
  const setIsAddTransformationTaskMutationLoading = useSetAtom(isAddTransformationTaskMutationLoadingAtom)
  const updateTransformationOrderMutation = useMutation(transformationApi.patch())
  const addTransformationTaskMutation = useMutation(transformationTaskApi.create())

  const handleAddTransformationTask = async (type: TransformationTaskType, name: string) => {
    setIsAddTransformationTaskMutationLoading(true)

    const { id } = await addTransformationTaskMutation.mutateAsync({
      type,
      name,
      transformation: programmedReport.transformation?.id,
      params: {}
    })

    await updateTransformationOrderMutation.mutateAsync({
      id: programmedReport.transformation?.id,
      order: [...(programmedReport?.transformation?.order || []), id]
    })

    await programmedReportQuery.refetch()
    setIsAddTransformationTaskMutationLoading(false)
    notification.success({ message: 'Transformation task added' })
  }

  return (
    <Card title="Transform Steps">
      {Object.entries(TRANSFORM_TASKS_BY_CATEGORY).map(([key, taskTypes]) => (
        <div key={key} className="mb-16 pb-16 last:mb-0 last:pb-0 border-b border-gray-200 last:border-0">
          <div className="font-semibold mb-8">{startCase(key)}</div>
          {taskTypes.map((type, index) => (
            <Button
              key={index}
              className="mb-8 last:mb-0"
              block
              type="primary"
              onClick={() => handleAddTransformationTask(type, startCase(type))}
            >
              {startCase(type)}
            </Button>
          ))}
        </div>
      ))}
    </Card>
  )
}

const TRANSFORM_TASKS_BY_CATEGORY = {
  extract: ['LoadDataSet'],
  formatting: ['CreateTab', 'NameTab', 'CopyDataSet'],
  filter: ['SortBy'],
  logicFunction: ['Calculation'],
  editDataSet: ['DeleteRow', 'GenerateCustomVariable', 'RenameColumns'],
  misc: ['LoopScript', 'CustomScript'],
  end: ['DeliverDataSet']
} as Record<string, TransformationTaskType[]>
