import { useAtomSelect } from '@/hooks/atom'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { allTicketsSelectedAtom, selectedTicketsAtom, ticketsQueryResultAtom } from '../atoms'

export const useTicketSelection = () => {
  const ticketsTotal = useAtomSelect(ticketsQueryResultAtom, 'total')
  const currentQueryPage = useAtomSelect(ticketsQueryResultAtom, 'query.data.items')
  const [selectedTickets, _setSelectedTickets] = useAtom(selectedTicketsAtom)
  const [allTicketsSelected, _setAllTicketsSelected] = useAtom(allTicketsSelectedAtom)
  const selectedTicketsIds = useMemo(() => selectedTickets.map((item) => item.id), [selectedTickets])

  // Whenever user changes custom selection, it resets the "Select All Ticket" atom
  const setSelectedTickets = (tickets: Record<string, any>[]) => {
    _setSelectedTickets(tickets)
    _setAllTicketsSelected(false)
  }
  const setAllTicketsSelected = () => {
    _setAllTicketsSelected(true)
    _setSelectedTickets(currentQueryPage || [])
  }
  const resetSelection = () => {
    _setSelectedTickets([])
    _setAllTicketsSelected(false)
  }
  const selectedTicketsCount = useMemo(
    () => (allTicketsSelected ? ticketsTotal : selectedTicketsIds.length),
    [selectedTicketsIds.length, allTicketsSelected, ticketsTotal]
  )

  return {
    selectedTickets,
    selectedTicketsIds,
    selectedTicketsCount,
    setSelectedTickets,
    allTicketsSelected,
    setAllTicketsSelected,
    resetSelection
  }
}
