import { usePropertiesSelectOptions } from '@/modules/module-builder/hooks'
import { Button } from '@/ui/button'
import { Form, Select } from 'antd'

type Props = {
  selectedCustomForm: number[]
}

export const PicturesArrayAttributes = ({ selectedCustomForm }: Props) => {
  const { propertiesOptions, isLoading, isFetching } = usePropertiesSelectOptions({
    customFormsIds: selectedCustomForm
  })

  return (
    <Form.List name={['data', 'pictures']}>
      {(fields, { add, remove }) => {
        return (
          <div className="grid grid-cols-3 gap-x-16 items-center">
            {fields.map((field, index, arr) => {
              return (
                <div key={field.key} className="flex gap-x-8 items-center">
                  <Form.Item {...field} label={`Picture ${index + 1}`} className="grow">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="label"
                      options={propertiesOptions}
                      disabled={isLoading || isFetching}
                      loading={isLoading || isFetching}
                    />
                  </Form.Item>
                  <Button iconName="svg:trash" onClick={() => remove(index)} />
                </div>
              )
            })}
            <div>
              <Button iconName="mi:add" type="primary" onClick={() => add()}>
                Picture
              </Button>
            </div>
          </div>
        )
      }}
    </Form.List>
  )
}
