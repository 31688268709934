import { customFormApiV2, propertyApi } from '@/services/api-service'
import { Property } from '@/types/property'
import { useQuery } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import { z } from 'zod'
import { useSession } from './use-session'

export const useAllProperties = () => {
  const { user } = useSession()
  const customFormsQuery = useQuery(customFormApiV2.list({ fields: z.object({ id: z.number() }) }))
  const customForms = customFormsQuery.data?.items || []

  const propertiesQuery = useQuery({
    ...propertyApi.list<Property>({
      property_group__custom_form_id__in: customForms.map((cf) => cf.id).join(','),
      limit: 'None'
    }),
    enabled: !!customForms.length
  })

  const properties = propertiesQuery.data?.items || []

  const allProperties = orderBy(
    (user?.is_superuser ? [] : properties).map((p) => ({
      ...p,
      _label: `${p.custom_form__name} / ${p.id}: ${p.name}`
    })),
    '_label'
  )

  return { allProperties }
}
