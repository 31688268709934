import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp, useSession } from '@/hooks'
import { navigate } from '@/routing/helpers'
import {
  contactApi,
  customFormApi,
  jobCodeApi,
  jobTypeApi,
  timeTicketApi,
  timeTicketEventsApi
} from '@/services/api-service'
import { AppLink, Button, PageTitle, Select } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DatePicker, Form, Input, InputNumber, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { TIME_PATHS } from '../constants'

export const AddEditTimeView = () => {
  const { id } = useParams()
  const { company } = useSession()
  const eventsQuery = useQuery({ ...timeTicketEventsApi(id!).list(), enabled: !!id })
  const [form] = Form.useForm()
  const timeIn = Form.useWatch('time_in', form) as Dayjs | undefined
  const timeOut = Form.useWatch('time_out', form) as Dayjs | undefined
  const timeOnBreak = Form.useWatch('break_time', form) as number | undefined
  const [jobCodeQuerySearch, setJobCodeQuerySearch] = useState('')
  const debouncedSetJobCodeQuerySearch = useDebouncedCallback(setJobCodeQuerySearch, 500)
  const timeTicketQuery = useQuery({ ...timeTicketApi.get<any>(Number(id)), enabled: !!id })
  const createTimeTicketMutation = useMutation(timeTicketApi.create())
  const updateTicketMutation = useMutation(timeTicketApi.update())
  const { notification } = useApp()

  const customFormsQuery = useQuery(
    customFormApi.list({ incl_deleted: 1, include_time: 1, show_all: 'True', type__eq: 'A', fields: 'id,name' })
  )

  const contactsQuery = useQuery({
    ...contactApi.list({ fields: 'id,first_name,last_name', company__eq: company.id, incl_deleted: 1, limit: 'None' })
  })

  const jobTypesQuery = useQuery(
    jobTypeApi.list({ fields: 'id,name', limit: 'None', company__eq: company.id, incl_deleted: 1 })
  )

  const jobCodeQuery = useQuery({
    ...jobCodeApi.list({
      user_job_code_no__icontains: jobCodeQuerySearch,
      company__eq: company.id,
      order: 'user_job_code_no',
      compact: 1
    }),
    enabled: !!jobCodeQuerySearch
  })

  const handleSave = async () => {
    const values = await form.validateFields()
    const data = { ...values, company: company.id }
    const mutation = id ? updateTicketMutation : createTimeTicketMutation
    await mutation.mutateAsync(data)
    notification.success({ message: 'Time entry saved successfully' })
    if (id) {
      timeTicketQuery.refetch()
      eventsQuery.refetch()
    }
    setTimeout(() => navigate(TIME_PATHS.myTime), 1000)
  }

  useEffect(() => {
    let totalTime = timeIn && timeOut ? timeOut.diff(timeIn, 'minutes') : 0
    totalTime = totalTime - (timeOnBreak || 0)
    form.setFieldsValue({ total_time: totalTime })
  }, [timeIn, timeOut, form, timeOnBreak])

  if (
    customFormsQuery.isLoading ||
    contactsQuery.isLoading ||
    jobTypesQuery.isLoading ||
    timeTicketQuery.isInitialLoading
  ) {
    return <Spin spinning isCentered />
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-20">
        <PageTitle>{id ? `Edit Time Entry #${timeTicketQuery.data.name}` : 'New Time Entry'}</PageTitle>
        <Space>
          <AppLink to="/time/u">
            <Button iconName="fa:close" disabled={createTimeTicketMutation.isLoading || updateTicketMutation.isLoading}>
              Cancel
            </Button>
          </AppLink>
          <Button
            type="success"
            iconName="fa:save"
            loading={createTimeTicketMutation.isLoading || updateTicketMutation.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Space>
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={
          id
            ? {
                ...timeTicketQuery.data,
                time_in: timeTicketQuery.data?.time_in ? dayjs(timeTicketQuery.data.time_in) : undefined,
                time_out: timeTicketQuery.data?.time_out ? dayjs(timeTicketQuery.data.time_out) : undefined
              }
            : { custom_form: customFormsQuery.data?.items[0]?.id, employee: contactsQuery.data?.items[0]?.id }
        }
      >
        <Form.Item name="id" hidden />
        <div className="grid grid-cols-3 gap-x-20">
          <div>
            <Form.Item label="Ticket Type" name="custom_form" rules={[{ required: true }]}>
              <Select options={customFormsQuery.data?.items.map((item) => ({ label: item.name, value: item.id }))} />
            </Form.Item>
            <Form.Item label="Employee" name="employee" rules={[{ required: true }]}>
              <Select
                options={contactsQuery.data?.items.map((item) => ({
                  label: `${item.first_name} ${item.last_name}`,
                  value: item.id
                }))}
              />
            </Form.Item>
            <Form.Item label="Job Type" name="job_type" rules={[{ required: true }]}>
              <Select options={jobTypesQuery.data?.items.map((item) => ({ label: item.name, value: item.id }))} />
            </Form.Item>
            <Form.Item label="Job Notes" name="job_notes">
              <Input />
            </Form.Item>
            <Form.Item label="Job Code" name="job_code_id">
              <Select
                onSearch={(text) => debouncedSetJobCodeQuerySearch(text)}
                options={jobCodeQuery.data?.items.map((item) => ({ label: item.user_job_code_no, value: item.id }))}
                filterOption={false}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Time In" name="time_in" rules={[{ required: true }]}>
              <DatePicker className="w-full" showTime format="MMM DD, YYYY H:mm A" placeholder="Select date and time" />
            </Form.Item>
            <Form.Item label="Time Out" name="time_out" rules={[{ required: true }]}>
              <DatePicker
                className="w-full"
                showTime
                format="MMM DD, YYYY H:mm A"
                placeholder="Select date and time"
                disabledDate={timeIn ? (current) => current && timeIn && current.isBefore(timeIn) : undefined}
              />
            </Form.Item>
            <Form.Item label="Time on Break (minutes)" name="break_time">
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item
              label="Total Time"
              name="total_time"
              getValueProps={(val) => ({
                value: val ? `${(val / 60).toFixed(2)} hours` : ''
              })}
              rules={[
                {
                  validator: (_, value) =>
                    value && value < 0 ? Promise.reject('Total time is negative!') : Promise.resolve()
                }
              ]}
            >
              <Input disabled />
            </Form.Item>
          </div>
        </div>
      </Form>
      {id && (
        <div className="mt-30">
          <h5 className="font-bold">Recent Activity</h5>
          <ActivityLogsModal query={timeTicketEventsApi(id).list} />
          <div className="mt-16">
            <RecentActivities isLoading={eventsQuery.isLoading} events={eventsQuery.data?.items} />
          </div>
        </div>
      )}
    </div>
  )
}
