import { useSession } from '@/hooks'
import { companyOfficeApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'

export const useCompanyOfficesQuery = () => {
  const { company } = useSession()
  const companyOfficesQuery = useQuery({ ...companyOfficeApi(company.id).list({ limit: 'None' as any }) })

  return {
    companyOfficesQuery
  }
}
