import { useCallback } from 'react'
import { useTicketFormContext } from '../context'
import { Property } from '../schemas'

export const useCustomForm = () => {
  const { customForm, ticket } = useTicketFormContext()
  const fieldsConfig = customForm._fieldsConfig
  const features = customForm._features
  const viewOnly = ticket._viewOnly

  const isDisabledField = useCallback(
    (field: string) => viewOnly || fieldsConfig[field]?.disabled,
    [fieldsConfig, viewOnly]
  )
  const isHiddenField = useCallback((field: string) => fieldsConfig[field]?.hide_on_web, [fieldsConfig])

  const isDisabledProperty = useCallback((property: Property) => viewOnly || property.read_only, [viewOnly])

  const getPropertyByKey = useCallback((key: string) => customForm._propertyByKey[key], [customForm._propertyByKey])

  const getPropertyById = useCallback((id: number) => customForm._propertyById[id], [customForm._propertyById])

  const getPropertyIdByKey = useCallback(
    (key: string) => customForm._propertyByKey[key]?.id,
    [customForm._propertyByKey]
  )

  return {
    customForm,
    features,
    isDisabledField,
    isHiddenField,
    isDisabledProperty,
    getPropertyByKey,
    getPropertyById,
    getPropertyIdByKey
  }
}
