import { ContactSelect } from '@/components/contact-select'
import { LINE_ITEM_FIELDS } from '@/constants/general'
import { useSetAtom } from 'jotai'
import { equipmentAtom } from '../../../atoms'
import { Component } from '../../types'

type Props = {
  fieldItem: (typeof LINE_ITEM_FIELDS)[number]
  component: Component
}

export const AssigneeField = ({ fieldItem, component }: Props) => {
  const { data } = component ?? {}
  const setEquipment = useSetAtom(equipmentAtom)

  const handleAssigneeChange = (value?: number | null) => {
    setEquipment((prev) => ({
      ...prev,
      components: prev.components?.map((c) => {
        if (c.id === component.id) {
          return {
            ...component,
            assignee: value
          }
        }

        return component
      })
    }))
  }

  return (
    <ContactSelect
      selectProps={{
        placeholder: 'Select',
        value: data?.assignee,
        onChange: handleAssigneeChange
      }}
    />
  )
}
