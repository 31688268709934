import { CORE_SKU_FIELDS } from '../constants'
import { Equipment, LineItem } from '../schemas'

type Options = {
  override?: Partial<LineItem>
  nonEmptyOnly?: boolean
  noCoreFields?: boolean
}

export const copyCoreFields = (
  from: Partial<Equipment> | Partial<LineItem>,
  to: Partial<LineItem> = {},
  nonEmptyOnly = false
) => {
  if (nonEmptyOnly) {
    // copy non-empty from fields over to line item
    CORE_SKU_FIELDS.forEach((k) => {
      const value = from[k as keyof (Equipment | LineItem)]
      if (value !== null && value !== undefined) {
        to[k as keyof LineItem] = from[k as keyof (Equipment | LineItem)]
      }
    })
  } else {
    // copy from fields over to line item
    CORE_SKU_FIELDS.forEach((k) => {
      to[k as keyof LineItem] = from[k as keyof (Equipment | LineItem)]
    })
  }

  return to
}

export const copyFlagFields = (from: Partial<Equipment> | Partial<LineItem>, to: Partial<LineItem> = {}) => {
  to.discount_sku = from.discount_sku
  to.non_discountable = from.non_discountable
  to.tax_sku = from.tax_sku
  to.non_taxable = from.non_taxable
  to.skip_tax_calc = from.skip_tax_calc
  to.skip_calc = from.skip_calc
  to.taxable_tax_sku = from.taxable_tax_sku

  return to
}

export const copySourcesFromEquipment = (from: Partial<Equipment>, to: Partial<LineItem> = {}) => {
  return {
    ...to,
    equipment__string_1_source: from.string_1_source_id,
    equipment__string_2_source: from.string_2_source_id,
    equipment__string_3_source: from.string_3_source_id,
    equipment__string_4_source: from.string_4_source_id,
    equipment__string_5_source: from.string_5_source_id,
    equipment__string_6_source: from.string_6_source_id,
    equipment__string_7_source: from.string_7_source_id,
    equipment__string_8_source: from.string_8_source_id
  }
}

export const copySourcesFromLineItem = (from: Partial<LineItem>, to: Partial<LineItem> = {}) => {
  return {
    ...to,
    equipment__string_1_source: from.equipment__string_1_source,
    equipment__string_2_source: from.equipment__string_2_source,
    equipment__string_3_source: from.equipment__string_3_source,
    equipment__string_4_source: from.equipment__string_4_source,
    equipment__string_5_source: from.equipment__string_5_source,
    equipment__string_6_source: from.equipment__string_6_source,
    equipment__string_7_source: from.equipment__string_7_source,
    equipment__string_8_source: from.equipment__string_8_source
  }
}

export const copyFieldSourcesFromEquipment = (equipment?: Partial<Equipment>) => {
  return {
    user_text_1: equipment?.string_1_source_id || null,
    user_text_2: equipment?.string_2_source_id || null,
    user_text_3: equipment?.string_3_source_id || null,
    user_text_4: equipment?.string_4_source_id || null,
    user_text_5: equipment?.string_5_source_id || null,
    user_text_6: equipment?.string_6_source_id || null,
    user_text_7: equipment?.string_7_source_id || null,
    user_text_8: equipment?.string_8_source_id || null
  }
}

export const clearCoreFields = (lineItem: Partial<LineItem> = {}, shouldDelete = true) => {
  if (shouldDelete) CORE_SKU_FIELDS.forEach((k) => delete lineItem[k as keyof LineItem])
  else CORE_SKU_FIELDS.forEach((k) => (lineItem[k as keyof LineItem] = null))

  return { ...lineItem }
}

export const clearEmptyFields = (lineItem: Partial<LineItem> = {}) => {
  return Object.entries(lineItem).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null) acc[key as keyof LineItem] = value
      return acc
    },
    {} as Partial<LineItem>
  )
}

export const copyFromEquipment = (
  equipment: Partial<Equipment>,
  lineItem: Partial<LineItem> = {},
  options?: Options
) => {
  const { noCoreFields, nonEmptyOnly } = options || {}

  if (!noCoreFields) lineItem = copyCoreFields(equipment, lineItem, nonEmptyOnly)

  lineItem = copyFlagFields(equipment, lineItem)
  lineItem = copySourcesFromEquipment(equipment, lineItem) // important! for UserTextInput.tsx

  // important relationships
  lineItem.equipment_id = equipment.id
  lineItem.category_id = equipment.category_id
  lineItem.category__kind_id = equipment.category__kind_id
  lineItem.category__parent_category__kind_id = equipment.category__parent_category__kind_id

  return lineItem as LineItem
}

export const copyFromLineItem = (from: Partial<LineItem>, to: Partial<LineItem> = {}, options?: Options) => {
  const { noCoreFields } = options || {}

  to = { ...to, ...from }

  if (noCoreFields) return clearCoreFields(to)

  return to
}
