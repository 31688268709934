import { TICKET_STATUS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { companyApi, companyIntegrationApi, customFormApiV2, integrationApi, secretApi } from '@/services/api-service'
import { Button, QuerySelect, SaveButton, Select } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Divider, Form, Input, Switch } from 'antd'
import { NETSUITE_INTEGRATION_CODE } from '../../constants'
import { ReferenceFields } from './ReferenceFields'
import { NetSuiteConfigForm } from './schemas'

export const NetSuiteConfigureFormView = view<any, NetSuiteConfigForm>(Component, {
  title: () => 'NetSuite Configurations',
  loader: async () => {
    const netSuiteIntegration = await fetchQuery(
      integrationApi.list({ 'Q[]': `code__eq|${NETSUITE_INTEGRATION_CODE}` })
    )
    const companyIntegration = await fetchQuery(
      companyIntegrationApi.list({ integration__code: NETSUITE_INTEGRATION_CODE })
    )
    const integration = companyIntegration.items[0] || {}

    integration.integration_id = netSuiteIntegration.items[0]?.id
    integration._ns_configs = JSON.parse(integration.data || '{}')
    return integration
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { company } = useSession()
  const { notification } = useApp()

  const form = Form.useFormInstance<NetSuiteConfigForm>()
  const configId = Form.useWatch('id')
  const mutation = useMutation(configId ? companyIntegrationApi.update() : companyIntegrationApi.create())
  const updateCompanyMutation = useMutation(companyApi.patch())

  const updateCompanyPreference = async (enabled: boolean) => {
    const accounting = company.data.accounting || {}
    await updateCompanyMutation.mutateAsync({
      id: company.id,
      data_json: {
        ...company.data,
        accounting: {
          ...accounting,
          netsuite_enabled: enabled
        }
      }
    })
  }

  const save = async () => {
    try {
      const formData = await form.validateFields()

      const result = await mutation.mutateAsync({
        ...formData,
        data: JSON.stringify(formData._ns_configs),
        company_id: company.id
      })
      await updateCompanyPreference(formData._ns_configs.enabled)
      notification.success({ message: 'Success', description: 'NetSuite Configurations successfully updated' })
      form.setFieldValue('id', result.id)
    } catch (error) {
      notification.error({ message: 'Failed', description: 'Failed to update configurations' })
    }
  }

  return (
    <FormView
      header={{
        title: 'NetSuite Configurations',
        actions: [<SaveButton key="save" showReturn={false} loading={mutation.isLoading} onSave={save} />]
      }}
    >
      <div>
        <div className="grid grid-cols-4 gap-x-24">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="integration_id" hidden>
            <Input />
          </Form.Item>
          <InvoiceHeaders />
          <InvoiceLines />
          <Settings />
          <div>
            <ReferenceFields />
          </div>
        </div>
      </div>

      {/* TODO */}
      {/* <div className="mt-30">
        <h5 className="font-bold">Recent Activity</h5>
        <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'netsuite' }} />
        <div className="mt-16">
          <RecentActivities params={{ type: 'netsuite' }} />
        </div>
      </div> */}
    </FormView>
  )
}

const INVOICE_HEADER_FIELDS = [
  { name: 'tranid', label: 'Invoice #' },
  { name: 'entity_id', label: 'Customer ID' },
  { name: 'subsidiary_id', label: 'Subsidiary ID' },
  { name: 'location_id', label: 'Location ID' },
  { name: 'department_id', label: 'Department ID' },
  { name: 'class_id', label: 'Class ID' },
  { name: 'partner_id', label: 'Partner ID' },
  { name: 'account_id', label: 'Account ID' },
  { name: 'terms_id', label: 'Terms ID' },
  { name: 'next_approver_id', label: 'Next Approver ID' },
  { name: 'tran_date', label: 'Transaction Date' },
  { name: 'ref_name', label: 'Ref Name' },
  { name: 'memo', label: 'Memo' },
  { name: 'message', label: 'Message' },
  { name: 'originator', label: 'Originator' },
  { name: 'due_date', label: 'Due Date' }
]

const INVOICE_LINE_FIELDS = [
  { name: 'item_item_id', label: 'Item ID' },
  { name: 'item_department_id', label: 'Department ID' },
  { name: 'item_class_id', label: 'Class ID' },
  { name: 'item_location_id', label: 'Location ID' },
  { name: 'item_description', label: 'Description' }
]

function InvoiceHeaders() {
  return (
    <div>
      <h4>Invoice Headers</h4>
      {INVOICE_HEADER_FIELDS.map((f) => (
        <Form.Item key={f.name} label={f.label} name={['_ns_configs', f.name]}>
          <Input />
        </Form.Item>
      ))}
      <Divider />
      <CustomFields field_name={['_ns_configs', 'data', 'invoice_custom_fields']} />
    </div>
  )
}

function InvoiceLines() {
  return (
    <div>
      <h4>Lines</h4>
      {INVOICE_LINE_FIELDS.map((f) => (
        <Form.Item key={f.name} label={f.label} name={['_ns_configs', f.name]}>
          <Input />
        </Form.Item>
      ))}
      <Divider />
      <CustomFields field_name={['_ns_configs', 'data', 'invoice_item_custom_fields']} />
    </div>
  )
}

function Settings() {
  return (
    <div>
      <h4>Settings</h4>
      <Form.Item label="API Secrets" name={['_ns_configs', 'api_secret_id']}>
        <QuerySelect
          apiEndpoint={secretApi.list}
          apiQueryParams={{ fields: 'id,code_name' }}
          apiSearchBy="code_name"
          apiValueBy="id"
          renderOption={(secret) => ({ value: secret.id, label: secret.code_name })}
        />
      </Form.Item>
      <Form.Item label="Custom Forms" className="flex-300" name={['_ns_configs', 'data', 'custom_forms']}>
        <QuerySelect
          mode="multiple"
          apiEndpoint={customFormApiV2.list}
          apiQueryParams={{ fields: 'id,name' }}
          apiSearchBy="name"
          apiValueBy="id"
          renderOption={(cf) => ({ value: cf.id, label: cf.name })}
        />
      </Form.Item>
      <Form.Item label="Return success ticket status" name={['_ns_configs', 'success_ticket_status']}>
        <Select options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))} />
      </Form.Item>
      <Form.Item label="Enabled" name={['_ns_configs', 'enabled']}>
        <Switch />
      </Form.Item>
    </div>
  )
}

function CustomFields({ field_name }: { field_name: string[] }) {
  return (
    <Form.List name={field_name}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field) => (
            <div key={field.key}>
              <div className="flex flex-row justify-between items-center gap-x-8 w-full">
                <div className="w-full">
                  <Form.Item
                    label="Custom Field ID"
                    className="mb-[12px]"
                    name={[field.name, 'custom_field_id']}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item label="Value" name={[field.name, 'value']}>
                    <Input placeholder="Value" />
                  </Form.Item>
                </div>
                <Form.Item label=" ">
                  <Button iconName="fa:trash" onClick={() => remove(field.name)}></Button>
                </Form.Item>
              </div>
              <Divider className="my-[12px]" />
            </div>
          ))}
          <Form.Item>
            <Button type="primary" onClick={() => add()}>
              Add Custom Field
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>
  )
}
