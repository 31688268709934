import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { Equipment, equipmentSchema } from '@/schemas/equipment'
import { equipmentApi } from '@/services/api-service'
import { Modal, SearchInput } from '@/ui'
import { Button, ButtonProps } from '@/ui/button'
import { getLineItemName } from '@/utils/get-line-item-name'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, ModalProps, Radio, Space, Table, TableProps, Tooltip } from 'antd'
import { ChangeEvent, useCallback, useState } from 'react'
import { CategoriesSelect } from './categories-select'

type Props = {
  onAdd: (equipments: Equipment[]) => void | Promise<void>
  onSelect?: (equipment: Equipment | null | undefined) => void
  triggerProps?: ButtonProps
  triggerText?: string
  modalTitle?: string
  queryParams?: Record<string, any>
  extraOnSearchRight?: React.ReactNode
  extraAfterTable?: React.ReactNode
  isFullScreen?: boolean
  order?: string
  selectMode?: 'single' | 'multiple'
  modalProps?: ModalProps
  tableProps?: TableProps<any>
  customForm?: any
}

export const AddEquipmentsModal = ({
  onAdd,
  onSelect,
  extraOnSearchRight,
  modalTitle,
  triggerProps,
  triggerText,
  selectMode = 'multiple',
  order = 'sku',
  modalProps,
  tableProps,
  extraAfterTable,
  customForm
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { pagination, ...restTableProps } = tableProps || {}
  const [category, setCategory] = useState<number>(-1)
  const [subCategory, setSubCategory] = useState<number>(-1)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(pagination ? pagination.defaultPageSize : TABLE_DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string>('')
  const [selectedEquipments, setSelectedEquipments] = useState<Equipment[]>([])
  const [isExtendedSearch, setIsExtendedSearch] = useState(false)
  const { labels } = useApp()
  const { company } = useSession()
  const fieldAliases = JSON.parse(company.field_aliases) ?? {}
  const skuSearchFields = (customForm?.userdata?.sku_search?.fields ?? []).join(',')

  const query = equipmentApi.list<Equipment>({
    order,
    limit,
    page,
    search_fields: isExtendedSearch ? skuSearchFields : undefined,
    // Add parent category query if sub category is not selected and category is selected
    ...(category !== -1 && subCategory === -1 && { category__parent_category__exact: category }),
    ...(subCategory !== -1 && { category__exact: subCategory }),
    ...(search.trim() !== '' && { search })
    //...queryParams
  })

  const equipmentsQuery = useQuery({
    ...query,
    select: (data) => ({ ...data, items: equipmentSchema.array().parse(data.items) })
  })

  const handleCloseModal = useCallback(() => {
    setPage(1)
    setLimit(TABLE_DEFAULT_PAGE_SIZE)
    setCategory(-1)
    setSubCategory(-1)
    setSearch('')
    setSelectedEquipments([])
    onSelect?.(null)
    setIsOpen(false)
  }, [onSelect])

  const onClear = useCallback(() => {
    setCategory(-1)
    setSubCategory(-1)
    onSelect?.(null)
    setSelectedEquipments([])
    setPage(1)
  }, [onSelect])

  const handleAdd = async () => {
    await onAdd(selectedEquipments)
    handleCloseModal()
  }

  const onSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      setSelectedEquipments([])
      onSelect?.(null)
    },
    [onSelect]
  )

  return (
    <>
      <Button iconName="mi:add" {...triggerProps} onClick={() => setIsOpen(true)} data-cy="add">
        {triggerText ? triggerText : 'Add'}
      </Button>
      <Modal
        open={isOpen}
        onCancel={handleCloseModal}
        title={modalTitle}
        okText="Add"
        onOk={handleAdd}
        width={'75%'}
        withFullscreenOption
        withScreenMaxHeight
        zIndex={11000}
        {...modalProps}
      >
        <CategoriesSelect
          category={category}
          subCategory={subCategory}
          onChange={(category) => {
            setCategory(category)
            // Reset sub category
            setSubCategory(-1)
            // reset selected line item
            onSelect?.(null)
            setSelectedEquipments([])
            setPage(1)
          }}
          onSubCategoryChange={(subCategory) => {
            setSubCategory(subCategory)
            onSelect?.(null)
            setSelectedEquipments([])
            setPage(1)
          }}
          onClear={onClear}
        />
        <div className="flex items-center gap-x-12 mt-14">
          <SearchInput onChange={onSearchChange} />
          {extraOnSearchRight}
          {customForm && (
            <Space>
              <Tooltip></Tooltip>
              <Checkbox
                checked={isExtendedSearch}
                onChange={(e) => {
                  setIsExtendedSearch(e.target.checked)
                }}
              >
                Extended Search
              </Checkbox>
              {isExtendedSearch && !customForm.userdata?.sku_search?.enabled && (
                <div className="text-warning">
                  (No fields selected for extended search. Please set search fields from custom form settings.)
                </div>
              )}
              {isExtendedSearch && customForm.userdata?.sku_search?.enabled && (
                <div>
                  (Includes{': '}
                  {customForm.userdata?.sku_search?.fields
                    ?.map(
                      (field: any) =>
                        getLineItemName({
                          field,
                          labels,
                          fieldAliases
                        }).name
                    )
                    .join(', ')}
                  )
                </div>
              )}
            </Space>
          )}
        </div>
        <Table
          className="mt-14"
          dataSource={equipmentsQuery.data?.items.map((item) => ({
            ...item,
            key: item.id
          }))}
          loading={equipmentsQuery.isLoading || equipmentsQuery.isFetching}
          scroll={{
            y: 'calc(100vh - 460px)'
          }}
          pagination={{
            current: page,
            pageSize: limit,
            total: equipmentsQuery.data?.total,
            pageSizeOptions: TABLE_PAGE_SIZES,
            onChange: (page, limit) => {
              setPage(page)
              setLimit(limit)
            }
          }}
          {...restTableProps}
        >
          <Table.Column title="SKU" width={150} dataIndex="sku" />
          <Table.Column title="Description" dataIndex="description" />
          <Table.Column
            width={80}
            title="Select"
            render={(_, equipment: any) => (
              <div>
                <Radio
                  checked={selectedEquipments[0]?.id === equipment.id}
                  onChange={(e) => {
                    // Call onSelect callback
                    if (onSelect) {
                      // If already selected, then unselect
                      // If not selected, then select
                      if (selectedEquipments.some((item) => item.id === equipment.id)) {
                        onSelect(null)
                      } else {
                        onSelect(equipment)
                      }
                    }

                    if (selectMode === 'single') {
                      // In single mode
                      // If already selected, then unselect
                      // If not selected, then select
                      if (selectedEquipments[0]?.id === equipment.id) {
                        setSelectedEquipments([])
                      } else {
                        setSelectedEquipments([equipment])
                      }
                      return
                    }

                    // In multiple mode
                    if (e.target.checked) {
                      setSelectedEquipments((prev) => [...prev, equipment])
                    } else {
                      setSelectedEquipments((prev) => prev.filter((item) => item.id !== equipment.id))
                    }
                  }}
                />
              </div>
            )}
          />
        </Table>
        {extraAfterTable}
      </Modal>
    </>
  )
}
