import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

const options = [
  {
    label: 'View Only (Customer)',
    key: 'view_only_customer'
  },
  {
    label: 'Hide Activity Log from Customer',
    key: 'hide_activity_log'
  },
  {
    label: 'Hide Attachments from Customer',
    key: 'hide_attachments'
  },
  {
    label: 'Show "Third party support" field on the ticket',
    key: 'show_third_party'
  },
  {
    label: 'Allow customers to access these tickets.',
    key: 'allow_customer_access'
  },
  {
    label: 'Restrict edits by 3rd party to tickets in Reviewed/Closed/Archived status',
    key: 'restrict_thirdparty_edit'
  }
]

export const ThirdPartAndCustomerAccess = () => {
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }
  return (
    <div>
      <h5 className="font-bold">Manage 3rd Party & Customer Access</h5>
      {options.map(({ label, key }) => (
        <div key={key} className="mb-12">
          <Checkbox
            data-cy={`custom-form-${key}-checkbox`}
            checked={!!customForm[key as keyof typeof customForm]}
            onChange={(e) => handleChange(e, key)}
          >
            {label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
