import { TicketStatus } from '@/modules/ticket/types'
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors'
import { LiteralUnion } from 'antd/es/_util/type'
import dayjs from 'dayjs'
import { JobRequirementsByType, JobResources } from '../types'

export const getAssignedPctColor = (assignedPct: number): LiteralUnion<PresetColorType | PresetStatusColorType> => {
  if (assignedPct === 0) return 'default'
  else if (assignedPct === 100) return 'green'
  else return 'blue'
}

export const getStatusColor = (status: TicketStatus): LiteralUnion<PresetColorType | PresetStatusColorType> => {
  if (status == 'D') return 'orange'
  else if (status == 'A') return 'green'
  else if (status == 'O') return 'blue'
  else if (status == 'S') return 'orange-inverse'
  else if (status == 'B') return 'red-inverse'
  else if (status == 'C') return 'gold-inverse'
  else return 'default'
}

export const getEndDate = (startDate: dayjs.Dayjs, elapsedTime: number): dayjs.Dayjs => {
  return startDate.add(elapsedTime, 'hours')
}

export const getAssignedPercent = (requirement: JobRequirementsByType, resources: JobResources) => {
  const employeesCt = resources.employees.sku_ids.length
  const equipmentsCt = resources.equipments.sku_ids.length

  const reqEmployees = requirement.employees
  const reqEquipments = requirement.equipments

  if (reqEmployees === 0 && reqEquipments === 0) return 100

  const percentPerResource = 100 / (reqEmployees + reqEquipments)
  const maxEmployeesPercent = reqEmployees * percentPerResource
  const maxEquipmentsPercent = reqEquipments * percentPerResource

  const employeesPercent = Math.min(employeesCt * percentPerResource, maxEmployeesPercent)
  const equipmentsPercent = Math.min(equipmentsCt * percentPerResource, maxEquipmentsPercent)

  return Math.round(employeesPercent + equipmentsPercent)
}
