import { FC } from 'react'
import { AfeInput } from './afe-input'
import { AssigneeInput } from './assignee-input'
import { JobCodeInput } from './job-code-input'
import { LocationInput } from './location-input'
import { NameInput } from './name-input'
import { SupportOfficeInput } from './support-office-input'
import { TimepointDueInput } from './timepoint-due-input'
import { TimepointSubmittedInput } from './timepoint-submitted-input'

export const MetaFields: FC = () => {
  return (
    <div className={'[&>div]:mb-10 border rounded py-6 px-10'}>
      <NameInput />
      <TimepointDueInput />
      <TimepointSubmittedInput />
      <LocationInput />
      <AfeInput />
      <JobCodeInput />
      <SupportOfficeInput />
      <AssigneeInput />
    </div>
  )
}
