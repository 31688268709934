import { TableViewSelectedField } from '@/types/table-view'

type SortingItem = {
  category?: string
  _name_lower?: string
} & TableViewSelectedField

export const sortFields = (left: SortingItem, right: SortingItem) => {
  const diff = (left.category || 'uncategorized').localeCompare(right.category || 'uncategorized')

  if (diff) {
    return diff
  }

  return (left._name_lower || '').localeCompare(left._name_lower || '')
}
