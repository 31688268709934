import { TIME } from '@/constants/date'
import { Form, Select } from 'antd'

export const DefaultTimeFilterSelect = () => {
  return (
    <Form.Item label="Default Time Filter" name={['data', 'time_filter']}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        showSearch
        optionFilterProp="label"
        options={Object.entries(TIME).map(([value, label]) => ({ value, label }))}
      />
    </Form.Item>
  )
}
