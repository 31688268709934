import { FormatPicker as FormatPickerComponent } from '@/components/format-picker'
import { FC } from 'react'
import { useFormatPicker } from '../../hooks'

export const FormatPicker: FC = () => {
  const { isLoading, searchedFields } = useFormatPicker()

  return (
    <FormatPickerComponent
      title="Variables, Fields & Properties"
      searchedFields={searchedFields}
      isLoading={isLoading}
    />
  )
}
