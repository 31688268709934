import { CategoryTreeSelect } from '@/components'
import { useFormWatch } from '@/hooks'
import { Icon, Tooltip } from '@/ui'
import { Form, Input, Radio, RadioChangeEvent } from 'antd'
import { FC, useEffect, useState } from 'react'

type Props = {
  name: string[]
}

type FilterMode = 'all' | 'by_categories' | 'q_filter'

export const LineItemsSection: FC<Props> = ({ name }) => {
  const form = Form.useFormInstance()
  const [selectedMode, setSelectedMode] = useState<FilterMode>('all')
  const withLineItems = useFormWatch<boolean>([...name.slice(0, -1), 'line_items'], form)
  const lineItemsFilterOptions = useFormWatch<any>(name, form)

  const handleModeChange = (e: RadioChangeEvent) => {
    const val: FilterMode = e.target.value
    form.setFieldValue(name, {})
    setSelectedMode(val)
  }

  useEffect(() => {
    if (lineItemsFilterOptions) {
      if (lineItemsFilterOptions['categories']) {
        setSelectedMode('by_categories')
      } else if (lineItemsFilterOptions['q_filter']) {
        setSelectedMode('q_filter')
      }
    }
  }, [lineItemsFilterOptions])

  if (!withLineItems) return null

  return (
    <div className="pl-20">
      <Radio.Group value={selectedMode} onChange={handleModeChange} className="flex flex-col gap-10 mb-10">
        <div>
          <Radio value="all">All</Radio>
        </div>
        <div className="flex flex-col gap-10">
          <Radio value="by_categories">By Categories</Radio>
          <Form.Item name={[...name, 'categories']} hidden={selectedMode !== 'by_categories'} className="mb-0">
            <CategoryTreeSelect />
          </Form.Item>
        </div>
        <div>
          <Radio value="q_filter">
            <div className="flex">
              <span>Query Filter</span>
              <Tooltip title={'e.g. sku__icontains|drill'}>
                <div>
                  <Icon name="fa:info-circle" className="cursor-default ml-6" />
                </div>
              </Tooltip>
            </div>
          </Radio>
          <Form.Item name={[...name, 'q_filter']} hidden={selectedMode !== 'q_filter'} className="mt-10">
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              className="mt-10"
              hidden={selectedMode !== 'q_filter'}
            />
          </Form.Item>
        </div>
      </Radio.Group>
    </div>
  )
}
