import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'
import { useEffect } from 'react'

export const DataJsonCodeEditor = () => {
  const form = Form.useFormInstance()
  const data = Form.useWatch('data')
  const dataJson = Form.useWatch('data_json')

  // Set data_json value from data
  useEffect(() => {
    form.setFieldsValue({
      data_json: JSON.stringify(data, null, 2)
    })
  }, [data, form])

  // Set data value from data_json
  useEffect(() => {
    try {
      const data = JSON.parse(dataJson)
      form.setFieldsValue({
        data
      })
    } catch (error) {
      return
    }
  }, [dataJson, form])

  return (
    <div className="mb-30">
      <h5 className="font-bold">Configs</h5>
      <Form.Item
        label="data_json=String"
        name="data_json"
        validateTrigger="onSubmit"
        rules={[
          {
            validator: async (_, value) => {
              try {
                JSON.parse(value)
                return true
              } catch (error) {
                throw new Error('Please enter a valid JSON string')
              }
            }
          }
        ]}
      >
        <CodeEditor minLines={5} maxLines={Infinity} mode="json" />
      </Form.Item>
    </div>
  )
}
