import { Button, Select } from '@/ui'
import { Form } from 'antd'
import { useBaseReportDisplayfieldSetOptions, useTransformationTaskTabsOptions } from '../../../../hooks'

export const SortBy = () => {
  const tabsOptions = useTransformationTaskTabsOptions()
  const displayfieldSetOptions = useBaseReportDisplayfieldSetOptions()

  return (
    <>
      <Form.Item label="Base DataSet" name={['params', 'base_ds']}>
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.List name={['params', 'options']}>
        {(fields, { add, remove }) => {
          return (
            <>
              <div>
                {fields.map((field, index) => (
                  <div key={index} className="flex items-center gap-x-12">
                    <Form.Item
                      label="Sort by column"
                      rules={[{ required: true }]}
                      name={[field.name, 'field']}
                      className="w-[70%]"
                    >
                      <Select options={displayfieldSetOptions} />
                    </Form.Item>
                    <Form.Item
                      label="Sort"
                      name={[field.name, 'asc']}
                      getValueProps={(val) => ({ value: Number(val) })}
                      getValueFromEvent={(val) => Boolean(val)}
                      className="grow"
                    >
                      <Select
                        options={[
                          { label: 'A to Z', value: 1 },
                          { label: 'Z to A', value: 0 }
                        ]}
                      />
                    </Form.Item>
                    <Form.Item className="shrink-0 ml-auto" label=" ">
                      <Button iconName="fa:trash" type="text" onClick={() => remove(field.name)} />
                    </Form.Item>
                  </div>
                ))}
              </div>
              <Button iconName="fa:add" type="primary" onClick={() => add({ field: '', asc: true })}>
                Add Filter
              </Button>
            </>
          )
        }}
      </Form.List>
    </>
  )
}
