import { usePropertiesSelectOptions } from '@/modules/module-builder/hooks'
import { customFormApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Collapse, Form, Input, Select } from 'antd'
import { Fragment, useMemo } from 'react'
import { TableViewSelect } from '../../../table-view-select'

export const MapLayers = () => {
  const customFormsQuery = useQuery({ ...customFormApi.list({ fields: 'id,name' }) })
  const customForms = useMemo(() => customFormsQuery.data?.items ?? [], [customFormsQuery.data?.items])

  const { isFetching, isLoading, propertiesOptions } = usePropertiesSelectOptions({
    customFormsIds: customForms.map((item) => item.id),
    valueKey: 'key'
  })

  const form = Form.useFormInstance()
  const data = Form.useWatch('data', form) ?? {}
  const layers = data.layers ?? []

  return (
    <div className="mb-16">
      <div className="font-bold mb-12">Map Layers</div>
      <Form.List name={['data', 'layers']}>
        {(fields, { add, remove }) => {
          return (
            <div className="grid grid-cols-2 gap-16">
              {fields.map((field, index) => {
                const layer = layers[index] ?? {}

                return (
                  <Collapse
                    key={index}
                    defaultActiveKey={[index, '0']}
                    className="h-fit bg-white"
                    onChange={(key) => console.log(key)}
                  >
                    <Collapse.Panel
                      key={layer.id}
                      header={<div className="font-medium text-16">Layer: {layer.name}</div>}
                      extra={
                        <span
                          className="flex"
                          onClick={(e) => {
                            e.stopPropagation()
                            remove(index)
                          }}
                        >
                          <Icon name="svg:trash" />
                        </span>
                      }
                    >
                      <div className="grid grid-cols-3 gap-x-16 items-center">
                        <Form.Item label="Layer Name" name={[field.name, 'name']}>
                          <Input />
                        </Form.Item>
                        <TableViewSelect formItemProps={{ label: 'Layer Filter View', name: [field.name, 'view'] }} />
                        <Form.Item noStyle name={[field.name, 'enabled']} valuePropName="checked">
                          <Checkbox>Enabled</Checkbox>
                        </Form.Item>
                        <Form.Item label="Color By">
                          <Select
                            placeholder="Select"
                            options={layer?.fields?.map((field: any) => ({
                              label: field.name,
                              value: field.name
                            }))}
                          />
                        </Form.Item>
                      </div>
                      <div className="font-bold mb-12">Layer Field And Property Mapping</div>
                      <Form.List name={[field.name, 'fields']}>
                        {(fields, { add, remove }) => (
                          <div className="grid grid-cols-5 gap-x-16">
                            {fields.map((field, index) => {
                              const layerField = layer.fields?.[index] ?? {}

                              return (
                                <Fragment key={field.key}>
                                  <Form.Item label="Field Name" name={[field.name, 'name']} className="col-span-1">
                                    <Input />
                                  </Form.Item>
                                  <Form.Item label="Type" name={[field.name, 'type']} className="col-span-1">
                                    <Select
                                      placeholder="Select"
                                      options={[
                                        { label: 'Property', value: 'P' },
                                        { label: 'Custom', value: 'C' }
                                      ]}
                                    />
                                  </Form.Item>
                                  <div className="flex gap-x-6 col-span-3 items-center">
                                    {layerField.type === 'P' ? (
                                      <Form.Item
                                        label="Property (select)"
                                        name={[field.name, 'property']}
                                        className="grow"
                                      >
                                        <Select
                                          placeholder="Select"
                                          showSearch
                                          optionFilterProp="label"
                                          options={propertiesOptions}
                                          disabled={isFetching || isLoading}
                                          loading={isFetching || isLoading}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <Form.Item
                                        label="Custom Path (supports interpolation)"
                                        name={[field.name, 'property']}
                                        className="grow"
                                      >
                                        <Input />
                                      </Form.Item>
                                    )}
                                    {layerField.name !== 'Label' && layerField.name !== 'Position' && (
                                      <Button iconName="svg:trash" type="text" onClick={() => remove(index)} />
                                    )}
                                  </div>
                                </Fragment>
                              )
                            })}
                            <div>
                              <Button iconName="mi:add" onClick={() => add({})}>
                                Add Field
                              </Button>
                            </div>
                          </div>
                        )}
                      </Form.List>
                    </Collapse.Panel>
                  </Collapse>
                )
              })}
              <div>
                <Button
                  iconName="mi:add"
                  onClick={() => {
                    add({
                      name: `Untitled ${layers.length + 1}`,
                      view: null,
                      fields: [
                        { name: 'Label', type: 'P', property: null },
                        { name: 'Position', type: 'P', property: null }
                      ]
                    })
                  }}
                >
                  Add Layer
                </Button>
              </div>
            </div>
          )
        }}
      </Form.List>
    </div>
  )
}
