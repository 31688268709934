import { TABLE_PAGE_SIZES } from '@/constants/general'
import { useRemainingDimensions } from '@/hooks'
import { Progress } from '@/ui/progress'
import { formatNumber } from '@/utils/formatters'
import { ConfigProvider, Pagination, Table, TablePaginationConfig } from 'antd'
import cn from 'classnames'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { getQueryColumn, renderEmpty } from './helpers'
import { useQueryTableQuery } from './hooks'
import { TableLite } from './table-lite'
import { QueryTableBaseProps, QueryTableProps, QueryTableRef } from './types'

export const QueryTable = forwardRef<QueryTableRef, QueryTableProps>(({ variant = 'ant', ...props }, ref) => {
  const {
    pagination,
    columns,
    children,
    scroll,
    scrollOffset,
    fullHeight,
    fullWidth,
    className,
    onChange,
    onQueryChange,
    rowHoverable,
    renderPagination,
    lazyColumns,
    ...tableProps
  } = props

  // fetch data
  const { items, total, dataQuery, params, setParams, refresh } = useQueryTableQuery(props)

  // handle sorting change
  const onTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    if (extra.action === 'sort') {
      setParams({ order: `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}` })
    }

    if (onChange) onChange(pagination, filters, sorter, extra)
  }

  // handle pagination change
  const onPaginationChange = (page: number, pageSize: number) => {
    setParams({ page: page, pageSize: pageSize })

    // mimic antd table onChange
    onTableChange({ current: page, pageSize: pageSize }, {}, {}, { action: 'paginate' })
  }

  // calculate scroll area
  const containerRef = useRef<HTMLDivElement>(null)
  const remainingDimension = useRemainingDimensions(containerRef)
  const scrollY = useMemo(() => {
    if (scroll?.y) return scroll.y
    if (!fullHeight) return undefined
    return Math.max(250, remainingDimension.height) - 95 + (scrollOffset?.y || 0)
  }, [fullHeight, remainingDimension.height, scroll?.y, scrollOffset?.y])
  const scrollX = useMemo(() => {
    if (scroll?.x) return scroll.x
    if (!fullWidth) return undefined
    return Math.max(250, remainingDimension.width) - 95 + (scrollOffset?.x || 0)
  }, [fullWidth, remainingDimension.width, scroll?.x, scrollOffset?.x])

  // process columns
  const processedColumns = useMemo(() => ((columns || []) as any).map(getQueryColumn), [columns])
  const tableColumnsRef = useRef<any[]>(processedColumns)
  const tableColumns = useMemo(() => {
    if (!lazyColumns) return processedColumns

    // lazy columns: keep the columns until the data is ready
    if (!dataQuery.isFetching || !tableColumnsRef.current.length) {
      tableColumnsRef.current = processedColumns
    }
    return tableColumnsRef.current
  }, [lazyColumns, dataQuery.isFetching, processedColumns])

  // expose to parent component
  useImperativeHandle(ref, () => ({ query: dataQuery, params, setParams, refresh }))

  useEffect(() => {
    if (onQueryChange) onQueryChange(dataQuery, total)
  }, [dataQuery, onQueryChange, total])

  const _tableProps: QueryTableBaseProps = {
    className: cn('flex-grow [&.ant-table-wrapper>div>div>.ant-spin]:!hidden', className),
    scroll: { y: scrollY, x: scrollX },
    loading: dataQuery.isLoading || !!props.loading,
    dataSource: items,
    pagination: false,
    onChange: onTableChange,
    columns: tableColumns,
    rowHoverable: rowHoverable ?? true,
    ...(tableProps as any) // any: safe here
  }

  const paginationEl =
    pagination !== false ? (
      <div className={'flex justify-end w-full py-6 px-10 bg-gray-50 dark:bg-gray-800'}>
        <Pagination
          total={total}
          pageSizeOptions={TABLE_PAGE_SIZES}
          pageSize={params.pageSize}
          current={params.page}
          onChange={onPaginationChange}
          showTotal={(total, range) => `${range[0]} - ${range[1]}  of  ${formatNumber(total)}`}
          showSizeChanger={true}
          showLessItems={true}
          responsive={true}
          size={'small'}
          {...(pagination || {})}
        />
      </div>
    ) : null

  return (
    <div ref={containerRef} className={cn('flex-grow overflow-hidden flex flex-col border rounded', className)}>
      <div className={'relative'}>
        <div className={'absolute top-0 left-0 w-full z-50'}>
          <Progress mode={'indeterminate'} loading={dataQuery.isFetching || !!props.loading} />
        </div>
      </div>

      {variant === 'lite' ? (
        <TableLite
          style={{ height: remainingDimension.height - (scrollOffset?.y || 0) - 56, overflow: 'auto' }}
          {...(_tableProps as any)} // any: safe here
        />
      ) : (
        <ConfigProvider
          renderEmpty={(name) =>
            renderEmpty(name, remainingDimension.isReady && !dataQuery.isLoading && !props.loading)
          }
        >
          <Table {..._tableProps} />
        </ConfigProvider>
      )}

      {renderPagination ? renderPagination(paginationEl) : paginationEl}
    </div>
  )
})

QueryTable.displayName = 'QueryTable'
