import { useApp } from '@/hooks'
import { propertyChoiceApi } from '@/services/api-service'
import { PropertyChoice } from '@/types/property-choice'
import { Button, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { useState } from 'react'

type Payload = {
  property: number
} & FormValues

type FormValues = {
  value: string
  name: string
}

export const AddOptionModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const [form] = Form.useForm<FormValues>()
  const parentForm = Form.useFormInstance()
  const addPropertyChoiceMutation = useMutation(propertyChoiceApi.create<PropertyChoice, Payload>())
  const { notification } = useApp()
  const propertyId = Form.useWatch('id', parentForm) as number | undefined

  const handleSave = async () => {
    const values = await form.validateFields()

    if (!propertyId) {
      notification.error({ message: 'Please select a property' })
      return
    }

    await addPropertyChoiceMutation.mutateAsync({ property: propertyId, ...values })
    form.resetFields()
    toggleModal()

    notification.success({ message: 'Option added successfully' })
  }

  return (
    <>
      <Button type="primary" iconName="fa:add" onClick={toggleModal}>
        Add Option
      </Button>
      <Modal title="Add Option" open={isOpen} okText="Save" onCancel={toggleModal} onOk={handleSave}>
        <Form layout="vertical" form={form}>
          <Form.Item label="Display Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Value" name="value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
