import { Form, Input } from 'antd'

export const CustomerSection = () => {
  return (
    <div>
      <h4 className="font-bold">Customer</h4>
      <Form.Item label="Accounting Name" name="customer_accounting_name">
        <Input />
      </Form.Item>
    </div>
  )
}
