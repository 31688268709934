import { MapColors } from './map-colors'
import { MapLayers } from './map-layers'

export const DeliveriesCardTypeSection = () => {
  return (
    <div>
      <MapLayers />
      <MapColors />
    </div>
  )
}
