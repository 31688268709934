import { CodeEditor } from '@/components/code-editor'
import { Script } from '@/types/script'
import { Button } from '@/ui'
import { Form, Input, Space, Spin } from 'antd'

type Props = {
  script: Script | undefined
  isLoading?: boolean
  onCancel: () => void
  onSave: (formValues: any) => void
}

export const AddEditScript = ({ script, isLoading, onSave, onCancel }: Props) => {
  const [form] = Form.useForm()

  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout="vertical" component="div" initialValues={script} className="mt-24">
        <h5>Add Script</h5>
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="script">
          <CodeEditor maxLines={200} />
        </Form.Item>
        <Form.Item className="mb-0">
          <Space>
            <Button
              iconName="fa:check"
              type="success"
              onClick={async () => {
                const values = await form.validateFields()
                onSave(values)
              }}
            />
            <Button iconName="fa:close" onClick={onCancel} />
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}
