import { Form, Input } from 'antd'

export const ModuleTypeReportsFields = () => {
  return (
    <div>
      <h5 className="font-bold">Reports Config</h5>
      <div className="flex gap-x-16 items-center">
        <span>Report Description</span>
        <span className="font-bold">Contains</span>
        <Form.Item noStyle name={['data', 'report_desc_contains']}>
          <Input className="w-[200px]" />
        </Form.Item>
      </div>
    </div>
  )
}
