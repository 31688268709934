import { GmapRoute, GmapRouteLegStep } from '@/types/gmap-route'

export const processRoute = (route: GmapRoute, start: string, end: string) => {
  return {
    summary: route.summary,
    distance: formatRouteDistance(route),
    steps: [
      { icon: 'trip_origin', instructions: `Starting point ${start}` },
      ...route.legs.flatMap((leg) =>
        leg.steps.map((step) => ({
          icon: formatStepIcon(step),
          instructions: formatStepInstruction(step)
        }))
      ),
      { icon: 'location_on', instructions: `Your destination ${end}` }
    ]
  }
}

const formatRouteDistance = (route: GmapRoute) => {
  const distance = route.legs.reduce((sum, leg) => sum + leg.distance.value, 0)
  return (distance / 1609.34).toFixed(0) + ' mi'
}

const formatStepIcon = (step: GmapRouteLegStep) => {
  const maneuver = step.maneuver || ''
  if (maneuver.startsWith('keep') || maneuver === '') {
    return 'straight'
  } else if (maneuver === 'ramp') {
    return 'ramp_right'
  } else {
    return maneuver.replace(/-/g, '_')
  }
}

const formatStepInstruction = (step: GmapRouteLegStep) => {
  return (
    (step.html_instructions || '').replace(/<div.*<\/div>/g, '').trim() +
    (step.distance ? ` - <i>${step.distance.text}</i>` : '')
  )
}
