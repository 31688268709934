import { z } from 'zod'

export const ProgrammedReportSchema = z.object({
  id: z.number(),
  type: z.string(),
  report__id: z.number().nullish(),
  company__name: z.string().nullish(),
  report__name: z.string().nullish(),
  report__description: z.string().nullish()
})

export type ProgrammedReport = z.infer<typeof ProgrammedReportSchema>
