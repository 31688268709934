import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp, useSession } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery, refreshQueries } from '@/query'
import { view } from '@/routing'
import { categoryApi, categoryKindApi, categoryTagApi, eventApi } from '@/services/api-service'
import { BackButton, Checkbox, QuerySelect, SaveButton } from '@/ui'
import { getOptionValue } from '@/ui/select'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { omit } from 'lodash'
import { useParams } from 'react-router-dom'
import { RECORDS_FORM_VIEW_HEADER } from '../../../constants'
import { ManageTags } from './manage-tags'
import { CategoryForm, CategorySchema } from './schemas'

export const CategoryFormView = view<any, CategoryForm>(Component, {
  title: ({ data }) => data?.title || '',
  loader: async ({ params }) => {
    const categoryId = Number(params.id)

    // add mode
    if (!categoryId) return { mode: 'add', title: 'Add', category: {} }

    // edit mode
    const category = await fetchQuery(categoryApi.get<any>(categoryId, undefined, { fields: CategorySchema }))
    category.tags = category.tags?.map((tag: any) => ({ value: tag.id, label: tag.name }))
    return { mode: 'edit', title: `Edit ${category.name}`, category }
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { id: categoryId } = useParams()
  const { l, notification } = useApp()
  const { company } = useSession()

  const form = Form.useFormInstance<CategoryForm>()

  const mutation = useMutation(categoryId ? categoryApi.patch() : categoryApi.create())

  const save = async () => {
    try {
      const { category } = await form.validateFields()
      const data = {
        ...omit(category, ['kind_id', 'parent_category_id', 'tags']),
        kind: category.kind_id,
        parent_category: category.parent_category_id,
        tags: category.tags?.map((tag: any) => getOptionValue(tag))
      }

      try {
        const result = await mutation.mutateAsync(data)
        notification.success({ message: 'Success', description: `${l('Category')} successfully saved` })
        refreshQueries(['events'])
        return data.id ? undefined : `../${result.id}/edit`
      } catch (error) {
        notification.error({ message: 'Failed', description: `Failed to save ${l('Category')}` })
      }
    } catch (error) {
      notification.warning({ message: 'Warning', description: 'Please check input with validation error' })
    }
  }

  return (
    <FormView
      header={{
        ...RECORDS_FORM_VIEW_HEADER,
        title: categoryId ? `Edit ${l('Category')}` : `Add ${l('Category')}`,
        backButton: <BackButton data-cy={'category-back-button'} />,
        actions: [
          <SaveButton
            key={'save'}
            onSave={save}
            returnButtonProps={{ 'data-cy': 'save-and-return-category-button' }}
            data-cy="save-category-button"
          />
        ]
      }}
    >
      <Form.Item name={['category', 'id']} hidden>
        <Input />
      </Form.Item>

      <div className={'grid grid-cols-1 md:grid-cols-3 gap-10 items-center'}>
        <Form.Item name={['category', 'name']} label="Name" className="w-full" required>
          <Input data-cy="category-name-input" />
        </Form.Item>
        <Form.Item name={['category', 'kind_id']} label="Configuration" className="w-full">
          <QuerySelect
            apiEndpoint={categoryKindApi.list}
            apiQueryParams={{ fields: 'id,name', company_id__eq: company.id }}
            apiValueBy={'id'}
            apiSearchBy="name"
            mapOption={['id', 'name']}
            data-cy="category-kind-select"
          />
        </Form.Item>
        <Form.Item name={['category', 'parent_category_id']} label="Parent" className="w-full">
          <QuerySelect
            apiEndpoint={categoryApi.list}
            apiQueryParams={{ fields: 'id,name', parent_category__isnull: true }}
            apiValueBy={'id'}
            apiSearchBy="name"
            mapOption={['id', 'name']}
            data-cy="category-parent-select"
          />
        </Form.Item>
        <Form.Item name={['category', 'external_id']} label="External ID" className="w-full">
          <Input data-cy="category-external-id-input" />
        </Form.Item>
        <div className={'flex items-center gap-10'}>
          <Form.Item name={['category', 'tags']} label="Tags" className="flex-grow">
            <QuerySelect
              mode="multiple"
              apiEndpoint={categoryTagApi.list}
              apiQueryParams={{ fields: 'id,name' }}
              apiSearchBy="name"
              mapOption={['id', 'name']}
              data-cy="category-tags-select"
            />
          </Form.Item>
          <ManageTags />
        </div>
      </div>
      <Form.Item name={['category', 'format_qty_as_duration']} valuePropName={'checked'}>
        <Checkbox data-cy="category-format-checkbox">
          Format quantity as a duration in hours:minutes; 2:30 instead of 2.50 hours.
        </Checkbox>
      </Form.Item>

      {categoryId && (
        <div className="mt-30">
          <h5 className="font-bold">Recent Activity</h5>
          <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'category', target: categoryId }} />
          <div className="mt-16">
            <RecentActivities params={{ type: 'category', target: categoryId }} />
          </div>
        </div>
      )}
    </FormView>
  )
}
