import { SearchInput as UISearchInput } from '@/ui'
import { useSetAtom } from 'jotai'
import { searchTextAtom } from '../atoms'

export const SearchInput = () => {
  const setSearchText = useSetAtom(searchTextAtom)

  return (
    <UISearchInput
      placeholder="Search Available Fields"
      onChange={(e) => setSearchText(e.target.value)}
      data-cy="configuration-search-input"
    />
  )
}
