import { Select } from '@/ui'
import { SelectProps } from 'antd'
import { FC } from 'react'
import { RATE_UNIT } from '../../../../../constants'

export const UnitSelect: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Select"
      options={Object.entries(RATE_UNIT).map((r) => ({
        label: `${r[0]}: ${r[1]}`,
        value: r[0]
      }))}
      {...props}
    />
  )
}
