import type { SelectProps } from 'antd'

/**
 * Get the filterBy function for the select component.
 * @param filterBy
 */
export const getFilterBy = (filterBy?: string) => (input: string, option: any) => {
  option = option || {}
  input = input || ''

  // first by filterBy key, then by text or label, then by value
  let value = option[filterBy || ''] || option.text || option.label

  if (typeof value !== 'string') {
    console.warn('Select: FilterBy value is not a string')
    value = option.value.toString() || ''
  }

  return value.toLowerCase().includes(input.toLowerCase())
}

/**
 * Check if the add option button should be shown.
 * @param searchText
 * @param options
 */
export const showAddOption = (searchText: string, options: SelectProps['options']) => {
  searchText = (searchText || '').trim().toLowerCase()
  return (
    !!searchText &&
    !(options || []).some(
      (option) => (option.text || option.label || '').toString().trim().toLowerCase() === searchText
    )
  )
}

/**
 * Get the value of the option.
 * @param option
 */
export const getOptionValue = (option: any) => option?.value ?? option
