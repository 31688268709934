import { Select } from '@/ui'
import { Form } from 'antd'
import { FC } from 'react'
import { BPA_OPERATORS_LIST } from '../../constants'
import { BpaCondition } from '../../schemas'

type Props = {
  condition: BpaCondition
  className?: string
  onChange: (value: any) => void
}

export const OperatorSelect: FC<Props> = ({ condition, className, onChange }) => {
  // hide operator for local_status
  if (condition.field === 'local_status' || condition.field === 'status') {
    return <></>
  }

  return (
    <Form.Item label="Operator" className={className}>
      <Select
        data-cy="condition-operator-select"
        value={condition.operator}
        options={BPA_OPERATORS_LIST}
        onChange={onChange}
      />
    </Form.Item>
  )
}
