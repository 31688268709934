import { useIsMobile } from '@/hooks'
import { Icon, Tooltip } from '@/ui'
import { Empty, Form, Tag } from 'antd'
import cn from 'classnames'
import { FC, useMemo } from 'react'
import { useCurrentKind, useEquipmentSelection, useFormWatch } from '../../../hooks'
import { LineItemOptions } from '../../../schemas'
import { ItemField } from './item-field'

export const ItemEditor: FC = () => {
  const isMobile = useIsMobile()
  const isNew = useFormWatch(['isNew'])

  const options = useFormWatch<LineItemOptions | undefined>(['options']) || {}
  const kindId = Form.useWatch<number>(['data', 'category__kind_id'], { preserve: true })
  const parentKindId = Form.useWatch<number>(['data', 'category__parent_category__kind_id'], { preserve: true })

  const { hasMultiple, hasGroups, baseEquipment, equipments, equipmentGroupsMap, removeEquipmentId, setEquipmentIds } =
    useEquipmentSelection()

  const { fieldsConfig } = useCurrentKind({
    type: 'form',
    category: { kindId, parentKindId },
    options
  })

  const visibleFieldsConfig = useMemo(() => {
    if (hasMultiple || hasGroups || options.type === 'duplicate') {
      return fieldsConfig.filter((fc) => fc.field !== 'sku' && fc.field !== 'description')
    } else {
      return fieldsConfig
    }
  }, [fieldsConfig, hasGroups, hasMultiple, options.type])

  const displayEquipments = useMemo(() => {
    if (options.type === 'duplicate') return options.groupItems || []
    if (hasMultiple) return equipments
    if (hasGroups) {
      const groupId = equipments[0].group_no
      if (!groupId) return []
      const group = equipmentGroupsMap.get(groupId)
      return group?.equipment || []
    }
    return []
  }, [options.type, options.groupItems, hasMultiple, equipments, hasGroups, equipmentGroupsMap])

  return (
    <div className={'h-full w-full'}>
      {!!displayEquipments.length && (
        <div>
          <Form.Item
            label={
              <div className={'flex flex-row gap-4 items-center'}>
                <div>SKUs ({displayEquipments.length})</div>
                {hasMultiple && (
                  <div
                    onClick={() => setEquipmentIds([])}
                    className={'text-primary hover:text-primary-600 cursor-pointer text-sm p-2'}
                  >
                    Clear All
                  </div>
                )}
              </div>
            }
          >
            <div className={'border !p-4 rounded border-dashed flex flex-row flex-wrap gap-4'}>
              {displayEquipments?.map((equipment) => (
                <Tooltip
                  mouseEnterDelay={0.5}
                  key={equipment.id}
                  title={
                    <span>
                      {equipment.sku}: {equipment.description || 'None'}
                    </span>
                  }
                >
                  <Tag className={'text-wrap max-w-[250px] !line-clamp-1 m-0'} color={'orange'}>
                    <div className={'flex flex-row'}>
                      <span className={'line-clamp-1 mr-4'}>
                        {equipment.sku}: {equipment.description || 'None'}
                      </span>
                      {hasMultiple && (
                        <span onClick={() => removeEquipmentId(equipment.id as number)} className={'cursor-pointer'}>
                          <Icon name={'fa:close'} />
                        </span>
                      )}
                    </div>
                  </Tag>
                </Tooltip>
              ))}
            </div>
          </Form.Item>
          <div className={'-mt-10 mb-10'}>
            <div className={'border border-dashed rounded px-10 py-4 flex gap-6 items-center'}>
              <Icon name={'fa:info-circle'} />
              <span>
                Note: Leave blank to keep the defaults, or change specific fields to update for all selected line items
              </span>
            </div>
          </div>
        </div>
      )}

      {!isNew || equipments?.length ? (
        <div
          className={cn('py-4 flex flex-row flex-wrap', {
            'flex-col w-full': isMobile
          })}
        >
          {visibleFieldsConfig.map((fc) => (
            <ItemField key={fc.field} fieldConfig={fc} className={isMobile ? 'w-full' : ''} />
          ))}
        </div>
      ) : (
        <div className={'h-full w-full grid place-content-center'}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Select SKU on the left'} />
        </div>
      )}
    </div>
  )
}
