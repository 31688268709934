import { RecentActivities } from '@/components'
import { useApp } from '@/hooks'
import { propertyEventApi } from '@/services/api-service'
import { Property } from '@/types/property'
import { Button } from '@/ui/button'
import { Modal } from '@/ui/modal'
import { safeJsonParse } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useState } from 'react'
import { useCustomFormQuery } from '../../hooks'
import { useDeletePropertyMutation, usePropertyTypesQuery } from '../hooks'
import { AdvancedFields } from './advanced-fields'
import { BpaBuilderMemo } from './bpa-builder'
import { DefaultFields } from './default-fields'
import { DropdownFields } from './dropdown-fields'
import { SaveProperty } from './save-property'
import { ScriptField } from './script-field'
import { ScriptStub } from './script-stub'
import { ScriptedButtonFields } from './scripted-button-fields'

type Props = {
  property?: Property
  onClose: () => void
}

export const EditPropertyModal = ({ property, onClose }: Props) => {
  const [showAdvanced, setShowAdvanced] = useState(false)
  const { handleDeleteProperty } = useDeletePropertyMutation()
  const propertyEventQuery = useQuery({ ...propertyEventApi(property?.id ?? '').list(), enabled: !!property?.id })
  const { customFormQuery } = useCustomFormQuery()
  const { notification } = useApp()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [form] = Form.useForm()
  const formProperty = Form.useWatch([], form)
  const { getTypeNameById } = usePropertyTypesQuery()

  const typeName = getTypeNameById(formProperty?.property_type?.id || property?.property_type?.id)
  const isAutomation = typeName === 'Automation'

  const handleClose = () => {
    onClose()
  }

  const handleDelete = async () => {
    if (!property) {
      return
    }

    setIsDeleteLoading(true)
    await handleDeleteProperty(property.id)
    await customFormQuery.refetch()
    setIsDeleteLoading(false)

    notification.success({
      message: 'Property deleted successfully'
    })

    onClose()
  }

  if (!customFormQuery.data || !property) return <></>

  return (
    <Modal
      open={true}
      forceFullScreen={isAutomation}
      withScreenMaxHeight={!isAutomation}
      iconName={property.id ? 'fa:edit' : 'fa:plus'}
      title={property.id ? `Edit ${property.property_type?.name} Property (ID: ${property.id})` : 'Add Property'}
      destroyOnClose
      width={800}
      onCancel={handleClose}
      footer={() => (
        <div className="flex gap-6">
          {property.id && (
            <Button
              danger
              type="primary"
              onClick={handleDelete}
              loading={isDeleteLoading}
              data-cy="delete-property-button"
            >
              Delete
            </Button>
          )}
          <div className={'flex-grow'} />
          <Button htmlType="button" onClick={handleClose} data-cy="cancel-property-button">
            Cancel
          </Button>
          <SaveProperty property={formProperty} form={form} onSave={onClose} />
        </div>
      )}
    >
      <Form
        className={'h-full w-full'}
        form={form}
        layout="vertical"
        id="edit-property-form"
        initialValues={{
          ...property,
          layout: property.layout?.split(',')[0],
          skip: property.layout?.split(',')[1],
          web_binding: safeJsonParse(property.binding_json ?? '{}')?.web,
          use_web_binding: !!safeJsonParse(property.binding_json ?? '{}')?.web
        }}
      >
        {typeName === 'Automation' ? (
          <BpaBuilderMemo customForm={customFormQuery.data} property={property} />
        ) : (
          <>
            <DefaultFields />
            <Button
              data-cy="show-advanced-options-button"
              block
              className="mt-20"
              onClick={() => setShowAdvanced((prev) => !prev)}
            >
              {showAdvanced ? 'Hide Advanced Options' : 'Show Advanced Options'}
            </Button>
            <AdvancedFields isVisible={showAdvanced} />
            <DropdownFields />
            <ScriptField />
            <ScriptStub />
            {typeName === 'ScriptedButton' && <ScriptedButtonFields />}
          </>
        )}
      </Form>
      {typeName !== 'Automation' && (
        <div className="mt-40">
          <h5 className="font-bold">Recent Activity</h5>
          <RecentActivities
            events={propertyEventQuery.data?.items}
            isLoading={!property.id ? false : propertyEventQuery.isLoading}
          />
        </div>
      )}
    </Modal>
  )
}
