import { Select } from '@/ui'
import { Form, Input } from 'antd'
import { useBaseReportDisplayfieldSetOptions, useTransformationTaskTabsOptions } from '../../../../hooks'

export const GenerateCustomVariable = () => {
  const formValues = Form.useWatch([])
  const tabsOptions = useTransformationTaskTabsOptions()
  const displayfieldSetOptions = useBaseReportDisplayfieldSetOptions()

  console.log('fprs', formValues)

  return (
    <>
      <Form.Item name={['params', 'base_ds']} label="Base DataSet">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item name={['params', 'tab_to']} label="Store DataSet">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item name={['params', 'name']} label="Name Custom Field" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['params', 'field']} label="Column">
        <Select options={displayfieldSetOptions} />
      </Form.Item>
      <Form.Item name={['params', 'func']} label="Function">
        <Select options={Object.entries(MATH_FUNCTIONS).map(([value, label]) => ({ value, label }))} />
      </Form.Item>
    </>
  )
}

const MATH_FUNCTIONS = {
  sum: 'SUM',
  avg: 'AVERAGE',
  count: 'COUNT',
  countDist: 'COUNT DISTINCT',
  min: 'MIN',
  max: 'MAX'
} as const
