import { Button, Modal, SearchInput } from '@/ui'
import { Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'

type Props = {
  title: string
  items: Record<string, string>
  selectedItems: Record<string, string>
  onAdd: (selectedItems: Record<string, string>) => void
}

export const AddLabelModal = ({ title, items, selectedItems, onAdd }: Props) => {
  const [newSelectedItems, setNewSelectedItems] = useImmer(selectedItems)
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const filteredItems = Object.entries(items).filter(([key]) => key.includes(search))

  // Reset selected items when the selectedItems prop changes
  useEffect(() => {
    setNewSelectedItems(selectedItems)
  }, [selectedItems])

  return (
    <>
      <Button type="primary" iconName="fa:add" onClick={() => setIsOpen(true)}>
        Add Label
      </Button>
      <Modal
        title={title}
        open={isOpen}
        okText="Add Labels"
        withScreenMaxHeight
        onCancel={() => setIsOpen(false)}
        onOk={() => {
          setIsOpen(false)
          onAdd(newSelectedItems)
        }}
      >
        <SearchInput
          className="w-full mb-16 !max-w-none"
          debounce={1}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search labels"
        />
        {filteredItems.map(([key, value]) => {
          const isSelected = !!newSelectedItems[key]
          return (
            <div key={key} className="mb-12">
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  setNewSelectedItems((prev) => {
                    if (isSelected) {
                      delete prev[key]
                      return prev
                    }
                    return { ...prev, [key]: value }
                  })
                }}
              >
                {value}
              </Checkbox>
            </div>
          )
        })}
      </Modal>
    </>
  )
}
