import { useApp } from '@/hooks'
import { programmedReportApi } from '@/services/api-service'
import { Button, Tooltip } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { useDownloadReportMutation } from '../../../hooks'

type Props = {
  report: any
  format: string
  encoding: string
}

export const ActionsColumn = ({ encoding, format, report }: Props) => {
  const { notification } = useApp()
  const { downloadReportMutation } = useDownloadReportMutation()
  const generateReportMutation = useMutation(programmedReportApi.etlGenerate())

  const handleDownload = async (id: number) => {
    await downloadReportMutation.mutateAsync({ id, format, encoding })
  }

  const handleGenerateReport = async (id: number) => {
    await generateReportMutation.mutateAsync({ id: id })
    notification.success({ message: 'Programmed report successfully generated.' })
  }

  if (!report.programmed_report) {
    return (
      <Button
        type="text"
        iconName="mi:download"
        loading={downloadReportMutation.isLoading}
        onClick={() => handleDownload(report.id)}
      />
    )
  }

  if (report.programmed_report.type === 'E') {
    return (
      <Tooltip title="Generate">
        <Button
          type="text"
          iconName="mi:exit_to_app"
          onClick={() => handleGenerateReport(report.programmed_report?.id)}
        />
      </Tooltip>
    )
  }

  return (
    <Button
      type="text"
      iconName="mi:download"
      loading={downloadReportMutation.isLoading}
      onClick={() => handleDownload(report.programmed_report?.report?.id)}
    />
  )
}
