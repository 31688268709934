import { alertApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Divider, Form, FormInstance, Switch } from 'antd'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { z } from 'zod'
import { sharedDataAtom } from '../../atom'
import { SMSOptInOut } from './sms-opt-in-out'

type groupProps = {
  title: string
  types: { key: string; title: string }[]
}
type NotificationProps = {
  form: FormInstance
  groups: groupProps[]
}

const AlertSchema = z.object({
  id: z.number(),
  name: z.string(),
  email_template: z.string().nullable(),
  email_notification_template_id: z.number().nullable(),
  sms_template_id: z.number().nullable()
})

export const NotificationForm = ({ form, groups }: NotificationProps) => {
  const sharedData = useAtomValue(sharedDataAtom)
  const systemAlertsQuery = useQuery(alertApi.list({ fields: AlertSchema, custom_form_id__isnull: 1 }))
  const accountAlertsQuery = useQuery(
    alertApi.list({
      fields: AlertSchema,
      custom_form_id__isnull: 0,
      'E[]': sharedData?.userdata_json?.disabled_forms?.length
        ? [`custom_form_id__in|${sharedData?.userdata_json?.disabled_forms.join(',')}`]
        : undefined
    })
  )
  const smsAlerts = Form.useWatch('sms_alerts', form) || {}
  const emailAlerts = Form.useWatch('email_alerts', form) || {}

  const alerts = useMemo(() => {
    const result = {
      email: [],
      sms: []
    } as Record<string, any[]>

    accountAlertsQuery.data?.items.forEach((alert) => {
      if (
        alert.email_notification_template_id ||
        // for old versions of the alert
        alert.email_template ||
        (!alert.email_notification_template_id && !alert.email_template && !alert.sms_template_id)
      ) {
        result.email.push(alert)
      }

      if (alert.sms_template_id) {
        result.sms.push(alert)
      }
    }, result)

    return result
  }, [accountAlertsQuery.data])

  return (
    <Form name="notificationForm" form={form} className="pb-24" layout="vertical">
      <Form.Item hidden name="email_alerts" />
      <Form.Item hidden name="sms_alerts" />
      <div className="grid grid-cols-3 gap-x-16">
        <div>
          {groups?.map((group, i) => (
            <div key={i}>
              <h5 className="font-bold">{group.title}</h5>
              {group.types.map((type, i) => {
                return (
                  <div className=" mb-16" key={i}>
                    <Form.Item name={type.key} valuePropName="checked" key={type.key} className="mb-0">
                      <div className="flex items-center">
                        <Checkbox checked={form.getFieldValue(type.key)}>{type.title}</Checkbox>
                      </div>
                    </Form.Item>
                    {type.key === 'email_ticket_submit' && form.getFieldValue('email_ticket_submit') && (
                      <div className="flex items-center ml-6">
                        <Form.Item name="email_ticket_include_attachments" valuePropName="checked" className="mb-0">
                          <Switch checked={form.getFieldValue('email_ticket_include_attachments')} />
                        </Form.Item>
                        <span className="block ml-4">Include Attachements</span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          ))}
          <Divider />
          {systemAlertsQuery?.data?.items?.map((alert) => (
            <Form.Item key={alert.id} valuePropName="checked">
              <Checkbox
                checked={!!emailAlerts[alert.id]}
                onChange={(e) => {
                  form.setFieldsValue({
                    email_alerts: {
                      ...form.getFieldValue('email_alerts'),
                      [alert.id]: e.target.checked
                    }
                  })
                }}
              >
                {alert.name}
              </Checkbox>
            </Form.Item>
          ))}
        </div>
        <div>
          <h5 className="font-bold">Account Email Notifications</h5>
          <div>
            {alerts.email.map((alert: { id: number; name: string }, i: number) => (
              <Form.Item key={i} valuePropName="checked" className="mb-16">
                <Checkbox
                  checked={!!emailAlerts[alert.id]}
                  onChange={(e) => {
                    form.setFieldsValue({
                      email_alerts: {
                        ...form.getFieldValue('email_alerts'),
                        [alert.id]: e.target.checked
                      }
                    })
                  }}
                >
                  {alert.name}
                </Checkbox>
              </Form.Item>
            ))}
          </div>
        </div>
        <div className="">
          <h5 className="font-bold">Account SMS Notifications</h5>
          <SMSOptInOut />
          <div>
            {alerts.sms.map((alert: { id: number; name: string }, i: number) => (
              <Form.Item key={i} className="mb-16">
                <Checkbox
                  checked={!!smsAlerts[alert.id]}
                  onChange={(e) => {
                    form.setFieldsValue({
                      sms_alerts: {
                        ...form.getFieldValue('sms_alerts'),
                        [alert.id]: e.target.checked
                      }
                    })
                  }}
                >
                  {alert.name}
                </Checkbox>
              </Form.Item>
            ))}
          </div>
        </div>
      </div>
    </Form>
  )
}
