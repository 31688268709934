import { Property } from '@/modules/ticket/form/schemas'
import { Button } from '@/ui'
import { FC } from 'react'
import { ScriptedButton } from '../../../buttons/scripted-button'

type Props = {
  property: Property
  disabled?: boolean
}

export const FormButton: FC<Props> = ({ property, disabled }) => {
  return (
    <div className={'flex flex-row justify-center'}>
      {!property._buttonConfig?.force_enable && disabled ? (
        // ScriptedButton's `disabled` prop gets overwritten by the dynamic button props
        // This might seem weird solution, but it works
        <Button disabled={disabled}>{property.name}</Button>
      ) : (
        <ScriptedButton property={property} />
      )}
    </div>
  )
}
