import { Navigation } from '@/modules/navigation'
import { Button } from '@/ui/button'
import cn from 'classnames'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { isMiniSidebarAtom } from '../atoms'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from '../constants'
import { Logo } from './Logo'

export const SidebarDesktop: FC = () => {
  const [mini, setMini] = useAtom(isMiniSidebarAtom)

  return (
    <aside
      style={{ width: mini ? `${SIDEBAR_COLLAPSED_WIDTH}px` : `${SIDEBAR_WIDTH}px` }}
      className={cn(
        'h-full bg-dark-container relative transition-all duration-300 ease-in-out',
        'dark:border-r dark:border-dark-border'
      )}
    >
      <div className={'flex flex-row items-center'}>
        <div className={cn('h-[70px] flex items-center w-full')}>
          <Logo url="/" iconOnly={mini} />
        </div>
      </div>

      <div className={cn('flex-1 overflow-hidden overflow-y-auto h-[calc(100vh-75px)]')}>
        <Navigation collapsed={mini} />
      </div>

      <div className={'absolute top-[85%] -right-12'}>
        <Button
          type={'primary'}
          iconName={'fa:chevron-right'}
          iconClassName={cn('rotate-180 relative -left-4 transition-all duration-250 ease-in', {
            '!rotate-0': mini
          })}
          className={'rounded-full opacity-60 hover:opacity-80'}
          onClick={() => setMini(!mini)}
        />
      </div>
    </aside>
  )
}
