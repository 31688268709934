import { Form, Input, Select, Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const NumberFormat = () => {
  const { lineItemField } = useSortableItemContext()
  const numberFormats = useAtomValue(useMemo(() => selectAtom(configurationAtom, (v) => v.formats ?? {}), []))
  const setConfiguration = useSetAtom(configurationAtom)
  const value = numberFormats[lineItemField]

  const [isCustomNumberFormat, setIsCustomNumberFormat] = useState(
    value && numberFormatOptions.every((option) => option.value !== value)
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfiguration((prev) => ({
      ...prev,
      formats: {
        ...prev.formats,
        [lineItemField]: e.target.value
      }
    }))
  }

  const handleSelectChange = (value: any) => {
    setConfiguration((prev) => ({
      ...prev,
      formats: {
        ...prev.formats,
        [lineItemField]: value
      }
    }))
  }

  return (
    <Form layout="vertical">
      <Form.Item label="Format" className="mb-0">
        <div className="flex items-center gap-x-8">
          {isCustomNumberFormat ? (
            <Input value={value} onChange={handleInputChange} data-cy="number-format-input" />
          ) : (
            <Select
              placeholder="Select"
              value={value}
              allowClear
              onClear={() => handleSelectChange(undefined)}
              options={numberFormatOptions}
              onChange={(value) => handleSelectChange(value)}
              data-cy="number-format-select"
            />
          )}
          <Space>
            <Switch
              checked={!!isCustomNumberFormat}
              onChange={(checked) => setIsCustomNumberFormat(checked)}
              data-cy="number-format-switch"
            />
            <span>Custom</span>
          </Space>
        </div>
      </Form.Item>
    </Form>
  )
}

export const numberFormatOptions = [
  {
    label: '#,##0',
    value: '#,##0'
  },
  {
    label: '#,##0.0',
    value: '#,##0.0'
  },
  {
    label: '#,##0.00',
    value: '#,##0.00'
  },
  {
    label: '#,##0.000',
    value: '#,##0.000'
  },
  {
    label: '#,##0.0000',
    value: '#,##0.0000'
  },
  {
    label: '$#,##0.00',
    value: '$#,##0.00'
  },
  {
    label: '#,##0.##%',
    value: '#,##0.##%'
  },
  {
    label: 'Date: %-m/%-d/%Y',
    value: '?datetime:%-m/%-d/%Y'
  },
  {
    label: 'Time: %-I:%M %p %Z',
    value: '?datetime:%-I:%M %p %Z'
  }
]
