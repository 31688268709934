import { useApp } from '@/hooks'
import { queryClient } from '@/query'
import { TipButton } from '@/ui'
import { Spin } from '@/ui/spin'
import { FC } from 'react'
import { refreshQueries } from '../../../helpers'
import { useTicket, useTicketLoader } from '../../../hooks'

export const ReloadButton: FC = () => {
  const { message } = useApp()
  const { isFetching } = useTicketLoader()
  const { isNew } = useTicket()

  const handleReload = async () => {
    queryClient.refetchQueries(['custom_forms']) // refresh custom forms as well
    await refreshQueries()
    message.success('Ticket refreshed')
  }

  if (isNew) return null

  if (isFetching) {
    return <Spin size="small" spinning />
  }

  return (
    <TipButton
      loading={isFetching}
      tooltip={{ mouseEnterDelay: 1.2 }}
      title={'Refresh'}
      type="text"
      size="small"
      iconName="fa:redo"
      onClick={handleReload}
    />
  )
}
