import { Form, FormInstance } from 'antd'
import { NamePath } from 'antd/es/form/interface'

/**
 * Wrapper for Form.useWatch
 * It makes watching form fields easier by providing a default form instance.
 * It also handles the case when the value is undefined during first render.
 *
 * @param dependencies
 * @param form
 */
export const useFormWatch = <T = any>(dependencies: NamePath<any>, form?: FormInstance<any>) => {
  const defaultForm = Form.useFormInstance()
  form = form ?? defaultForm

  let value = Form.useWatch<T>(dependencies, { form, preserve: true })

  // handle undefined watch value during first render
  if (value === undefined) value = form.getFieldValue(dependencies)

  return value
}
