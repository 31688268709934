import { ACCESS_TOKEN_KEY, OLD_ACCESS_TOKEN_KEY, PERMISSIONS } from '@/constants/auth'
import { AuthUser, Permission } from '@/types/auth-user'
import { safeJsonParse } from '@/utils'

/**
 * Set token in local storage.
 * JSON stringify the token for backward compatibility with old app.
 * @param token
 */
export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token))
}

export const clearV1AppCash = () => {
  for (const key in localStorage) {
    if (key.startsWith('appcachedata.')) {
      localStorage.removeItem(key)
    }
  }
}

/**
 * Get token from local storage.
 *
 * todo: cache token in state to support multiple users in different tabs
 *  or implement support for multiple users switching feature
 */
export const getAccessToken = (): string | null => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  return token && token !== '' ? safeJsonParse(token, null) : null
}

/**
 * Clear token in local storage.
 */
export const clearAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(OLD_ACCESS_TOKEN_KEY)
  localStorage.removeItem('isImpersonating')
}

/**
 *  Set Original Access token in localstorage as OLD_ACCESS_TOKEN_KEY
 *  Specially used while impersonating by Admin
 */
export const setOldAccessToken = (token: string) => {
  localStorage.setItem(OLD_ACCESS_TOKEN_KEY, token)
}

/**
 * Get Original / Old Access token from local storage.
 *
 * todo: cache token in state to support multiple users in different tabs
 *  or implement support for multiple users switching feature
 */
export const getOldAccessToken = (): string | null => {
  const token = localStorage.getItem(OLD_ACCESS_TOKEN_KEY)
  return token && token !== '' ? safeJsonParse(token, null) : null
}

export const setImpersonatingToken = (token: string) => {
  const oldAccessToken = getAccessToken()
  setOldAccessToken(oldAccessToken!)
  localStorage.setItem('isImpersonating', 'true')
  setAccessToken(`${token}`)
}

export const clearImpersonating = () => {
  const oldAccessToken = localStorage.getItem(OLD_ACCESS_TOKEN_KEY)
  setAccessToken(oldAccessToken!)
  localStorage.removeItem(OLD_ACCESS_TOKEN_KEY)
  localStorage.removeItem('isImpersonating')
}

export const isImpersonating = () => {
  if (localStorage.getItem('isImpersonating')) {
    return true
  }
  return false
}

/**
 * Get user permissions from user object.
 * @param user
 */
export const getUserPermissions = (user: AuthUser) => {
  const groups = (user?.groups || []) as Permission[]
  const permissions = new Set<Permission>(groups)

  if (user.is_superuser) permissions.add(PERMISSIONS.superAdmin)

  Object.entries(user?.profile?.userdata_json?.tab_permissions || {}).forEach((p) => {
    if (p[1]) permissions.add(p[0] as Permission)
  })

  return permissions
}

/**
 * Check if user has access to a permission.
 * @param perms
 * @param chkPerms
 * @param every
 */
export const checkAccess = (perms: Permission[], chkPerms: Permission[], every = false): boolean => {
  if (chkPerms.length === 0) return true

  if (every) return chkPerms.every((p) => perms.includes(p))

  return chkPerms.some((p) => perms.includes(p))
}

export const getUserRole = (user: any) => {
  const permissions = [...getUserPermissions(user)]

  if (checkAccess(permissions, ['company_admin'])) return 'Admin'
  else if (checkAccess(permissions, ['ticket_manager'])) return 'Ticket Manager'
  else return 'User'
}
