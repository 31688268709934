import { DATE_TIME_FORMAT } from '@/constants/date'
import { TABLE_DEFAULT_PAGE_SIZE, TRANSFORMATION_LOG_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { transformationLogApi } from '@/services/api-service'
import { apiHttp } from '@/services/api-service/core'
import { AppLink, Button, Popover, QueryTable, QueryTableRef, Tooltip } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Dropdown, Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useRef, useState } from 'react'

export type GeneratedReport = {
  id: number
  name: string
  status: string
  created_by__first_name: string
  created_by__last_name: string
  created_at: string
  report_files?: any[]
  result_json?: {
    metadata?: {
      user_full_name?: string
    }
    result?: {
      failed_task_type: string
      message: string
      traceback: string
    }
  }
}

type Props = {
  Q?: string[]
}

export const GeneratedReportsSection = ({ Q }: Props) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [order, setOrder] = useState('-created_at')
  const { notification } = useApp()
  const queryTableRef = useRef<QueryTableRef>(null)
  const transformationLogsQuery = useQuery(
    transformationLogApi.list<GeneratedReport>({
      limit,
      order,
      page,
      Q
    })
  )

  const abortTransformationLogMutation = useMutation({
    mutationFn: (variables: { id: number }) =>
      apiHttp.get(`/transformation_logs/${variables.id}/abort`).then((res) => res.data)
  })

  const deleteTransformationLogMutation = useMutation(transformationLogApi.delete())

  const handleAbortReport = async (id: number) => {
    await abortTransformationLogMutation.mutateAsync({ id })
    notification.success({ message: 'Transformation log aborted' })
    transformationLogsQuery.refetch()
  }

  const handleDeleteReport = async (id: number) => {
    await deleteTransformationLogMutation.mutateAsync({ id })
    notification.success({ message: 'Transformation log deleted' })
    transformationLogsQuery.refetch()
  }

  const handleRefresh = useCallback(() => {
    if (queryTableRef.current && queryTableRef.current?.query) {
      queryTableRef.current.query.refetch()
    }
  }, [])

  return (
    <div>
      <div className="flex items-center justify-between mb-16">
        <h5 className="font-bold mb-0">Generated Reports</h5>
        <Button type="primary" onClick={handleRefresh}>
          Refresh
        </Button>
      </div>
      <QueryTable
        ref={queryTableRef}
        queryApi={transformationLogApi.list}
        queryParams={{ order: '-created_at', Q }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Date',
            dataIndex: 'created_at',
            render: (createdAt) => <div>{dayjs(createdAt).format(DATE_TIME_FORMAT)}</div>
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => <div>{TRANSFORMATION_LOG_STATUS[status as keyof typeof TRANSFORMATION_LOG_STATUS]}</div>
          },
          {
            title: 'Run As (User)',
            render: (_, report) => <div>{report?.result_json?.metadata?.user_full_name}</div>
          },
          {
            align: 'right',
            render: (_, report) => (
              <Space>
                {report.status === 'P' && (
                  <Popconfirm
                    placement="topLeft"
                    okText="Yes"
                    title="Abort report"
                    description="Are you sure you want to abort this report?"
                    onConfirm={() => handleAbortReport(report.id)}
                  >
                    <Button type="text" iconName="mi:cancel" />
                  </Popconfirm>
                )}
                {report.status === 'C' && report.report_files?.length === 1 && (
                  <AppLink download to={report.report_files?.[0].url}>
                    <Button type="text" iconName="fa:download" />
                  </AppLink>
                )}
                {report.status === 'C' && report.report_files?.length && report.report_files?.length > 1 && (
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: report.report_files.map((file: any, index: number) => ({
                        key: index,
                        label: <AppLink to={file.url}>{(file.name || '').split('/').pop() || ''}</AppLink>
                      }))
                    }}
                  >
                    <Button type="text" iconName="fa:download" />
                  </Dropdown>
                )}
                {report.status === 'F' && (
                  <Tooltip
                    color={'orange'}
                    placement={'left'}
                    title={`${report?.result_json?.result?.failed_task_type}: ${report?.result_json?.result?.message}`}
                  >
                    <Popover
                      color={'gray'}
                      placement={'left'}
                      trigger={'click'}
                      content={
                        <div className={'text-white text-xs leading-5'}>
                          <pre className={'m-0 p-0'}>{report?.result_json?.result?.traceback}</pre>
                        </div>
                      }
                    >
                      <Button type="text" warning={true} iconName="fa:warning" />
                    </Popover>
                  </Tooltip>
                )}
                <Popconfirm
                  placement="topLeft"
                  okText="Yes"
                  title="Delete report"
                  description="Are you sure you want to delete this report?"
                  onConfirm={() => handleDeleteReport(report.id)}
                >
                  <Button type="text" danger={true} iconName="fa:trash" />
                </Popconfirm>
              </Space>
            )
          }
        ]}
      />
    </div>
  )
}
