import { useApp } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { companyApi } from '@/services/api-service'
import { AppLink, Button, PageTitle, PageWithStickyFooter, Tabs } from '@/ui'
import { Spin } from '@/ui/spin'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Form, Space } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyInfoTab } from './company-info-tab'
import { NotificationsTab } from './notifications-tab'

export const AddEditCompanyView = () => {
  const { id } = useParams()
  const companyQuery = useQuery({ ...companyApi.get<any>(Number(id)), enabled: !!id })
  const createCompanyMutation = useMutation({ ...companyApi.create() })
  const updateCompanyMutation = useMutation({ ...companyApi.update() })
  const [form] = Form.useForm()
  const { notification } = useApp()

  const handleSave = async () => {
    const { data, ...values } = await form.validateFields()
    const data_json = JSON.stringify(data)

    if (id) {
      await updateCompanyMutation.mutateAsync({ id: Number(id), ...values, data, data_json })
    } else {
      await createCompanyMutation.mutateAsync({ ...values, data, data_json })
    }

    notification.success({ message: 'Company saved' })
    navigate('/companies')
  }

  // Set initial form values
  useEffect(() => {
    form.setFieldsValue(companyQuery.data)
  }, [form, companyQuery.data])

  if (companyQuery.isInitialLoading) {
    return <Spin isCentered spinning />
  }

  if (id && !companyQuery.isInitialLoading && !companyQuery.data) {
    return <Alert message="Company not found" type="error" />
  }

  return (
    <PageWithStickyFooter
      footer={
        <Space className="ml-auto">
          <AppLink to="/companies">
            <Button iconName="fa:close">Cancel</Button>
          </AppLink>
          <Button
            type="success"
            iconName="fa:save"
            onClick={handleSave}
            loading={createCompanyMutation.isLoading || updateCompanyMutation.isLoading}
          >
            Save
          </Button>
        </Space>
      }
    >
      <PageTitle>{id ? 'Edit Company' : 'Add Company'}</PageTitle>
      <Form layout="vertical" form={form}>
        <Tabs
          className="mt-16"
          type="solid"
          items={[
            {
              key: 'company-info',
              label: 'Company Info',
              children: <CompanyInfoTab company={companyQuery.data} />
            },
            {
              key: 'notifications',
              label: 'Notifications',
              children: <NotificationsTab />
            }
          ]}
        />
      </Form>
    </PageWithStickyFooter>
  )
}
