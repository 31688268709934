import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { CodeEditor } from '@/components/code-editor'
import { useApp, useModalState, useSession } from '@/hooks'
import { refreshQueries } from '@/query'
import { clientRecordApi, eventApi } from '@/services/api-service'
import { ActionButton, ButtonProps, Modal } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AutoComplete, Form, Input } from 'antd'
import { FC, useState } from 'react'
import { CustomRecord } from '../schemas'
import { CUSTOM_RECORDS_FIELDS, TYPE_VALIDATION_RULE } from './constants'

type Props = ButtonProps & {
  customRecord?: Partial<CustomRecord>
}

export const ModalButton: FC<Props> = ({ customRecord, ...buttonProps }) => {
  const { company } = useSession()
  const { l, notification } = useApp()
  const [form] = Form.useForm()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()
  const [isDisabled, setIsDisabled] = useState(true)

  const statsQuery = useQuery({
    ...clientRecordApi.stats<{ key: string; count: number }>(),
    enabled: isOpen
  })

  const saveMutation = useMutation(customRecord?.id ? clientRecordApi.patch() : clientRecordApi.create())

  const handleSave = async () => {
    const data = await form.validateFields()

    if (!customRecord?.id) data.company = company.id

    await saveMutation
      .mutateAsync(data)
      .then(() => {
        closeModal()
        notification.success({
          message: `${l('CustomRecord')} Saved`,
          description: `${l('CustomRecord')} ${data.char_1} has been saved`
        })
        refreshQueries(['events'])
      })
      .catch(() => {
        notification.error({
          message: `${l('CustomRecord')} Error`,
          description: `Failed to save ${l('CustomRecord')} ${data.char_1}`
        })
      })

    if (!customRecord?.id) form.resetFields()
  }

  const handleFieldsChange = () => {
    setIsDisabled(form.getFieldsError().some(({ errors, warnings }) => warnings.length || errors.length))
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          withScreenMaxHeight
          open={isOpen}
          width={800}
          title={customRecord?.id ? 'Update ' + customRecord?.char_1 : `Add ${customRecord?.label || 'Record'}`}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-record-button', disabled: isDisabled }}
          okText="Save"
          cancelText="Cancel"
          onCancel={closeModal}
        >
          <Form form={form} initialValues={customRecord} layout="vertical" onFieldsChange={handleFieldsChange}>
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item
              label={'Type'}
              name="label"
              rules={[{ required: true }, { ...TYPE_VALIDATION_RULE }]}
              data-cy="label-input"
            >
              <AutoComplete
                options={(statsQuery.data?.items || [])
                  .filter((i) => !!i.key)
                  .map((i) => ({ label: i.key, value: i.key }))}
              />
            </Form.Item>
            <div className={'grid grid-cols-2 gap-10'}>
              {CUSTOM_RECORDS_FIELDS.map(({ name, label, 'data-cy': dataCy, rules }) => (
                <Form.Item key={name} name={name} label={label} data-cy={dataCy} rules={rules}>
                  <Input />
                </Form.Item>
              ))}
            </div>
            <Form.Item label={'Data'} name="data" data-cy="data-input">
              <CodeEditor minLines={5} maxLines={Infinity} mode="json" valueType={'object'} />
            </Form.Item>

            {customRecord?.id && (
              <div className="mt-30">
                <h5 className="font-bold">Recent Activity</h5>
                <ActivityLogsModal
                  query={eventApi.list}
                  queryVariables={{ type: 'client_record', target: customRecord?.id }}
                />
                <div className="mt-16">
                  <RecentActivities params={{ type: 'client_record', target: customRecord?.id }} />
                </div>
              </div>
            )}
          </Form>
        </Modal>
      )}
    </>
  )
}
