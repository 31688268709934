import { PROPERTY_TYPE as PT } from '@/modules/custom-form/constants'
import { objId } from '@/utils'
import dayjs from 'dayjs'
import { Property } from '../schemas'

export const DEFAULT_FORMATTER = (v: any): any => (v === undefined || v === '' ? null : v)

export const FIELD_FORMATTERS: Record<string, (v: any) => any> = {
  timepoint_due: (v) => (v ? dayjs.formatISO(v) : DEFAULT_FORMATTER(v)),
  timepoint_submitted: (v) => (v ? dayjs.formatISO(v) : DEFAULT_FORMATTER(v)),
  customer_office: (v) => objId(v),
  customer: (v) => objId(v),
  location: (v) => objId(v),
  afe: (v) => objId(v),
  job_code: (v) => objId(v),
  support_office: (v) => objId(v),
  assignee: (v) => objId(v)
}

export const PROPERTY_FORMATTER: Record<number, (v: any) => string | null> = {
  [PT.MultivariateDropdown]: (v) => (v ? v.join(',') : DEFAULT_FORMATTER(v)),
  [PT.DateTime]: (v) => (v ? v?.formatISO() : DEFAULT_FORMATTER(v)),
  [PT.Date]: (v) => (v ? v?.formatISODate(false) : DEFAULT_FORMATTER(v)),
  [PT.Time]: (v) => (v ? v?.formatISO() : DEFAULT_FORMATTER(v))
}

export const formatTicketField = (key: string, value: any) => {
  const formatter = FIELD_FORMATTERS[key]
  return formatter ? formatter(value) : DEFAULT_FORMATTER(value)
}

export const formatTicketProperty = (property: Property, value: any) => {
  try {
    const formatter = PROPERTY_FORMATTER[property.property_type_id]
    return formatter ? formatter(value) : DEFAULT_FORMATTER(value)
  } catch (error) {
    console.log('Failed to format ticket property:', error)
    return DEFAULT_FORMATTER(value)
  }
}
