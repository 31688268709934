import { useApp, useAppSelector } from '@/hooks'
import { FILTER_FIELDS } from '@/modules/jas/job-manager/constants'
import { getDateRange, getLabel } from '@/utils'
import { Button, DatePicker, Form, FormInstance, Input, Modal, Space } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Filters } from '../../types'
import { StatusSelect } from './StatusSelect'

type Props = {
  open: boolean
  onCancel(): void
  onSubmit(values: Filters): void
  form: FormInstance<any>
  onClear(): void
}

const { RangePicker } = DatePicker

const JobManagerFilterModal = ({ open, form, onCancel, onSubmit, onClear }: Props) => {
  const { labels } = useApp()
  const { advanceSearch } = useAppSelector((state) => state.jobManager)

  const handleSubmit = async () => {
    let values = await form.validateFields()
    if (values?.timepoint_due) {
      const startDate = dayjs(values?.timepoint_due[0].$d).format('YYYY-MM-DD')
      const endDate = dayjs(values?.timepoint_due[1].$d).format('YYYY-MM-DD')
      values = { ...values, timepoint_due: `${startDate},${endDate}` }
    }
    if (values?.modified_at__date) {
      const startDate = dayjs(values?.modified_at__date[0].$d).format('YYYY-MM-DD')
      const endDate = dayjs(values?.modified_at__date[1].$d).format('YYYY-MM-DD')
      values = { ...values, modified_at__date: `${startDate},${endDate}` }
    }
    if (values?.created_at__date) {
      const startDate = dayjs(values?.created_at__date[0].$d).format('YYYY-MM-DD')
      const endDate = dayjs(values?.created_at__date[1].$d).format('YYYY-MM-DD')
      values = { ...values, created_at__date: `${startDate},${endDate}` }
    }
    onSubmit?.(values)
  }

  const filterFields = useMemo(
    () => FILTER_FIELDS.map((s) => ({ label: getLabel(s.label, labels), value: s.value, type: s.type })),
    [labels]
  )

  return (
    <Modal
      title="Filter"
      open={open}
      width={1000}
      onCancel={onCancel}
      cancelButtonProps={{
        size: 'large'
      }}
      okButtonProps={{
        size: 'large'
      }}
      onOk={handleSubmit}
      okText="Search"
      footer={(props) => (
        <Space>
          <Button size="large" type="text" key="clear" onClick={onClear}>
            Clear
          </Button>
          {props}
        </Space>
      )}
    >
      <div className="py-12 pb-40">
        <Form form={form} layout="vertical" className="c-modal-form" initialValues={advanceSearch}>
          <div className="flex flex-wrap -mx-8">
            {filterFields.map((field, i) => {
              return (
                <Form.Item
                  label={field.label}
                  name={field.value}
                  className="w-full lg:w-3/12 px-16"
                  key={field.value + '_' + i}
                >
                  {field.value === 'computed_status' && <StatusSelect value={field.value} />}
                  {field.type === 'text' && <Input size="large" className="w-full" />}
                  {field.type === 'date' && <RangePicker size="large" className="w-full" presets={getDateRange()} />}
                </Form.Item>
              )
            })}
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default JobManagerFilterModal
