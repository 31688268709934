import { Icon, Select } from '@/ui'
import { Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSyncTeamMember } from '../../schemas'

type Props = {
  action: BpaActionSyncTeamMember
  onChange: (data: Partial<BpaActionSyncTeamMember>) => void
}

export const InputSyncTeamMember: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div>
      <div className="flex flex-row gap-10">
        <Form.Item label="Source" className="w-3/12">
          <Select
            defaultValue={action.source || ''}
            onChange={(value) => onChange({ source: value })}
            options={[
              { value: 'contact', label: 'Contact' },
              { value: 'equipment', label: 'Equipment' }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Value (Text)"
          tooltip="Comma separated id or placeholder like P[Key], F[name]"
          className="flex-grow"
        >
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 10 }}
            defaultValue={action.value}
            onChange={(e) => onChangeDebounced({ value: e.target.value })}
          />
        </Form.Item>
      </div>

      <div className="flex flex-row items-center gap-10 rounded border p-10 text-text-muted text-sm">
        <Icon name="fa:circle-info" />
        <div>
          {action.source === 'contact' && <span>Contact (ID) source will use id as it is to add team member.</span>}
          {action.source === 'equipment' && (
            <span>
              Equipment (ID) source will fetch equipment and use its <code>assignee_id</code> as team contact id. Please
              be sure assignee is set correctly for Equipment.
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
export const InputSyncTeamMemberMemo = React.memo(InputSyncTeamMember)
