import { ScriptedButtonConfig } from '../../../types'
import { CleanLineItemsButton } from './CleanLineItemsButton'

export const DesCleanLineItemsButtonConfig: ScriptedButtonConfig = {
  type: 'des_clean_line_items_button',
  iconName: 'fa:delete-right',
  label: 'Clean Line Items',
  group: 'DES',
  render: () => <CleanLineItemsButton />
}
