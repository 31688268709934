import { Tooltip } from '@/ui'
import { FC } from 'react'
import { EmployeeRow } from '../employee-row'
import { JobRoleStats } from '../schemas'

type JobRoleEmployeesListProps = {
  job_role_stats: JobRoleStats
  date: string
}

export const JobRoleEmployeesList: FC<JobRoleEmployeesListProps> = ({ job_role_stats, date }) => {
  const { job_role } = job_role_stats
  const dayOffTotal = job_role_stats?.stats_by_date[date]?.off.total || 0
  const availableTotal = job_role_stats?.stats_by_date[date]?.available.total || 0
  const dayOffEmployees = job_role_stats?.stats_by_date[date]?.off.employees_data || []
  const jobRoleColor = job_role_stats.job_role_color || '#000000'

  const jobRoleStatsStr = `${dayOffTotal}/${availableTotal} - ${
    Math.round((dayOffTotal * 100) / (availableTotal + dayOffTotal)) || 0
  }%`

  return (
    <div className="flex">
      <div>
        <p className="font-bold text-black mb-5 w-[150px]">{job_role}</p>
        <div className="inline-block">
          <Tooltip
            title={
              <div>
                <p className="mb-0">{jobRoleStatsStr}</p>
                <p className="mb-0">Off / Available - % off for the day</p>
              </div>
            }
            placement="right"
          >
            <div className="inline-block text-[#5C5C5C] font-semibold">{jobRoleStatsStr}</div>
          </Tooltip>
        </div>
      </div>
      <div className="w-6 min-h-full mx-[15px]" style={{ backgroundColor: jobRoleColor }}></div>
      <div>
        {dayOffEmployees.map(({ id, name }) => (
          <EmployeeRow key={id} id={id} name={name} />
        ))}
      </div>
    </div>
  )
}
