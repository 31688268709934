import { useIsManager } from '@/modules/auth/hooks'
import { getTableViewQuery } from '@/modules/table-view/helpers'
import { ticketApiV2 } from '@/services/api-service'
import { deepMerge } from '@/utils'
import { formatNumber } from '@/utils/formatters'
import { useQueries } from '@tanstack/react-query'
import cn from 'classnames'
import { cloneDeep } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { useActiveTableView, useModuleTableViewsQuery, useTicketsParam } from '../../hooks'

type Props = {
  viewIds?: number[]
}

export const TicketVerticalViewFilter: FC<Props> = ({ viewIds }) => {
  const isManager = useIsManager()
  const viewsQuery = useModuleTableViewsQuery()
  const filterViews = useMemo(() => {
    if (!viewIds || viewIds.length === 0) return viewsQuery.data?.items || []
    return viewsQuery.data?.items?.filter((view) => viewIds.includes(view.id)) || []
  }, [viewIds, viewsQuery.data?.items])

  const { activeViewId, setActiveView } = useActiveTableView()
  const onTableViewChange = useCallback((value: any) => setActiveView(value), [setActiveView])
  const { params } = useTicketsParam({ noStatusFilter: true, noActiveView: true })

  const countQueries = useQueries({
    queries:
      filterViews.map((view) =>
        ticketApiV2.list(deepMerge(cloneDeep(params), getTableViewQuery(view, isManager, { no_data: true })))
      ) || []
  })

  return (
    <div className={'flex flex-col gap-2'}>
      {filterViews.map((view, idx) => (
        <div
          key={view.id}
          className={cn(
            'flex flex-row items-center justify-between gap-4',
            'rounded border px-10 py-8 cursor-pointer transition-all',
            'hover:text-primary hover:bg-primary-50 hover:border-primary-300',
            {
              'text-primary bg-primary-50 border-primary-300': view.id === activeViewId
            }
          )}
          onClick={() => onTableViewChange(view)}
        >
          <div className={'line-clamp-1 flex-grow'}>{view.name}</div>
          <div
            className={cn('rounded-full bg-gray-100 text-sm px-6 py-2', {
              'animate-pulse bg-gray-200': countQueries[idx].isFetching
            })}
          >
            {countQueries[idx].isFetched && <span>{formatNumber(countQueries[idx]?.data?.total)}</span>}
            {!countQueries[idx].isFetched && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          </div>
        </div>
      ))}
    </div>
  )
}
