import { getAssignedPctColor, getAssignedPercent } from '@/modules/jas/job-manager/helpers'
import { JobData, JobResource, JobResources } from '@/modules/jas/job-manager/types'
import { TableView } from '@/modules/table-view/types'
import { SearchData } from '@/types/search'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Filters } from './types'

export type JobManagerState = {
  pagination: {
    page: number
    pageSize: number
  }
  activeView: TableView | null
  search: SearchData
  isEditing: boolean
  lastJobId: number | null
  originalSelectedJobData: JobData | null
  selectedJobData: JobData | null
  advanceSearch: Filters
}

const initialState: JobManagerState = {
  pagination: {
    page: 1,
    pageSize: 50
  },
  activeView: null,
  search: {
    field: 'name',
    type: 'icontains',
    value: ''
  },
  isEditing: false,
  lastJobId: null,
  originalSelectedJobData: null,
  selectedJobData: null,
  advanceSearch: {
    name: '',
    computed_status: '',
    afe__user_afe_no: '',
    contact__name: '',
    contacts__contact__first_name: '',
    customer_office__name: '',
    job__type: '',
    job_code__user_job_code_no: '',
    location__name: '',
    modified_at__date: '',
    created_at__date: '',
    timepoint_due: ''
  }
}

const slice = createSlice({
  name: 'jobManager',
  initialState: initialState,
  reducers: {
    setActiveView(state, action: PayloadAction<TableView | null>) {
      state.activeView = action.payload
      state.pagination.page = 1 // reset page to 1 when view changes
    },
    setSearch(state, action: PayloadAction<{ field: string; type: string; value: string }>) {
      state.search = action.payload
      state.pagination.page = 1 // reset page to 1 when search changes
    },
    setPagination(state, action: PayloadAction<{ page?: number; pageSize?: number }>) {
      state.pagination.page = action.payload.page || state.pagination.page
      state.pagination.pageSize = action.payload.pageSize || state.pagination.pageSize
    },
    setSelectedJobData(state, action: PayloadAction<JobData>) {
      state.selectedJobData = action.payload
      // backup original job data for reset
      state.originalSelectedJobData = action.payload
    },
    setIsEditing(state, action: PayloadAction<boolean>) {
      state.isEditing = action.payload
    },
    addResourceToJob(state, { payload }: PayloadAction<{ type: 'employee' | 'equipment'; resource: JobResource }>) {
      if (!state.selectedJobData) return

      const key: 'employees' | 'equipments' = payload.type === 'employee' ? 'employees' : 'equipments'

      // check if assigned sku is set
      const assignedSku = payload.resource.assigned_sku
      const skuId = assignedSku?.id
      if (!skuId) return

      // check if assigned sku already exists
      if (state.selectedJobData.resources[key].sku_ids.includes(skuId)) return

      // add resource to job
      // - by type
      state.selectedJobData.resources[key].sku_ids.push(skuId)
      state.selectedJobData.resources[key].res_items.push(payload.resource)
      state.selectedJobData.resources[key].assigned += 1
      // - by role
      if (state.selectedJobData.resourcesByRole) {
        const resData = state.selectedJobData.resourcesByRole.find((res) => res.id === assignedSku.job_role_id)
        if (resData) {
          resData.sku_ids.push(skuId)
          resData.res_items.push(payload.resource)
          resData.assigned += 1
        }
      }

      // auto enable editing
      state.isEditing = true
    },
    removeResourceFromJob(
      state,
      { payload }: PayloadAction<{ type: 'employee' | 'equipment'; resource: JobResource }>
    ) {
      if (!state.selectedJobData) return

      const key: 'employees' | 'equipments' = payload.type === 'employee' ? 'employees' : 'equipments'

      // check if assigned sku is set
      const skuId = payload.resource.assigned_sku?.id
      if (!skuId) return

      // check if assigned sku already exists
      if (!state.selectedJobData.resources[key].sku_ids.includes(skuId)) return

      // remove resource from job
      // - by type
      state.selectedJobData.resources[key].sku_ids = state.selectedJobData.resources[key].sku_ids.filter(
        (id) => id !== payload.resource.assigned_sku?.id
      )
      state.selectedJobData.resources[key].res_items = state.selectedJobData.resources[key].res_items.filter(
        (item) => item.id !== payload.resource.id
      )
      state.selectedJobData.resources[key].assigned -= 1
      // - by role
      if (state.selectedJobData.resourcesByRole) {
        const resData = state.selectedJobData.resourcesByRole.find(
          (res) => res.id === payload.resource.assigned_sku?.job_role_id
        )
        if (resData) {
          resData.sku_ids = resData.sku_ids.filter((id) => id !== payload.resource.assigned_sku?.id)
          resData.res_items = resData.res_items.filter((item) => item.id !== payload.resource.id)
          resData.assigned -= 1
        }
      }

      // auto enable editing
      state.isEditing = true
    },
    refreshAssignedPercent(state, action: PayloadAction<void>) {
      if (!state.selectedJobData) return

      // update assigned percent and color
      state.selectedJobData.assignedPct = getAssignedPercent(
        state.selectedJobData.requirements || { employees: 0, equipments: 0 },
        state.selectedJobData.resources
      )
      state.selectedJobData.assignedPctColor = getAssignedPctColor(state.selectedJobData.assignedPct)
    },
    loadSelectedJobResources(state, action: PayloadAction<JobResources>) {
      if (!state.selectedJobData) return

      state.selectedJobData.resources = action.payload

      // backup original job data for reset
      state.originalSelectedJobData = state.selectedJobData

      // set last job id
      state.lastJobId = state.selectedJobData.id
    },
    resetSelectedJobData(state, action: PayloadAction<void>) {
      if (!state.originalSelectedJobData) return

      state.selectedJobData = state.originalSelectedJobData
    },
    setAdvanceSearch(state, action: PayloadAction<Filters>) {
      state.advanceSearch = action.payload
      state.pagination.page = 1 // reset page to 1 when search changes
    }
  }
})
export const {
  setActiveView,
  setSearch,
  setPagination,
  setSelectedJobData,
  setIsEditing,
  addResourceToJob,
  removeResourceFromJob,
  refreshAssignedPercent,
  loadSelectedJobResources,
  resetSelectedJobData,
  setAdvanceSearch
} = slice.actions
export const jobManagerReducer = slice.reducer
