/**
 * Transform custom print css string to object
 */
export const transformCustomPrintCss = (val?: string | null) => {
  if (!val) {
    return undefined
  }

  const regex = /(?<=<<{).+?(?=}>>)/g // match <<{ ... }>>

  // extract fields that are used in inputs
  const fields = val
    .match(regex)
    ?.map((m) => m.trim())
    .join(', ')
    .split(',')
    .map((m) => ({
      [m.split(':')[0].trim().replace(/"/g, '')]: m
        .split(':')[1]
        .trim()
        .match(/\d+(\.\d+)?/g)
        ?.join('')
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const otherCss = val.replace(/^.*end \*\//s, '').trim() // remove everything before "end */"

  return {
    fields,
    otherCss,
    originalCustomPrintCss: val
  }
}
