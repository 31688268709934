import { RuleTemplate } from '@/schemas/rule-template'
import { atom } from 'jotai'
import { SetOptional } from 'type-fest'
import { combineRules } from './utils'

export const isDeleteModeAtom = atom(false)
export const isAddModeAtom = atom(false)
export const sortDirectionAtom = atom('asc')
export const searchTextAtom = atom('')
export const currentRuleTemplateAtom = atom<SetOptional<RuleTemplate, 'id'> | null>(null)
export const deletedRuleTemplateIdsAtom = atom([])
export const currentRuleTemplateCombinedRulesAtom = atom<('day_on' | 'day_off')[]>([])

export const toggleDeleteModeAtom = atom(null, (_, set) => {
  set(currentRuleTemplateAtom, null)
  set(isDeleteModeAtom, (prev) => !prev)
})

export const toggleSortDirectionAtom = atom(null, (_, set) => {
  set(sortDirectionAtom, (prev) => (prev === 'asc' ? 'desc' : 'asc'))
})

export const setEmptyCurrentRuleTemplateAtom = atom(null, (_, set) => {
  set(currentRuleTemplateAtom, null)
  set(currentRuleTemplateCombinedRulesAtom, [])
})

export const setNewCurrentRuleTemplateAtom = atom(null, (_, set, payload: Omit<RuleTemplate, 'id'>) => {
  set(currentRuleTemplateAtom, payload)
  set(currentRuleTemplateCombinedRulesAtom, combineRules(payload.data || []))
})

export const setCurrentRuleTemplateAtom = atom(null, (get, set, payload: RuleTemplate) => {
  const currentRuleTemplate = get(currentRuleTemplateAtom)

  if (currentRuleTemplate && currentRuleTemplate.id === payload.id) {
    set(currentRuleTemplateAtom, null)
    set(currentRuleTemplateCombinedRulesAtom, [])
  } else {
    set(currentRuleTemplateAtom, payload)
    set(currentRuleTemplateCombinedRulesAtom, combineRules(payload?.data || []))
  }
})

export const setCurrentRuleTemplateNameAtom = atom(null, (get, set, payload: string) => {
  const currentRuleTemplate = get(currentRuleTemplateAtom)
  set(currentRuleTemplateAtom, currentRuleTemplate ? { ...currentRuleTemplate, name: payload } : null)
})

export const setCurrentRuleTemplateCommentAtom = atom(null, (get, set, payload: string) => {
  const currentRuleTemplate = get(currentRuleTemplateAtom)
  set(currentRuleTemplateAtom, currentRuleTemplate ? { ...currentRuleTemplate, comment: payload } : null)
})

export const removeRuleFromCurrentRuleTemplateAtom = atom(null, (get, set, id: string) => {
  const currentRuleTemplate = get(currentRuleTemplateAtom)

  if (!currentRuleTemplate) {
    console.error('Trying to remove rule from rule template, but currentRuleTemplate is undefined')
    return
  }

  set(currentRuleTemplateAtom, {
    ...currentRuleTemplate,
    data: currentRuleTemplate.data.filter((rule) => rule.id !== id)
  })

  set(currentRuleTemplateCombinedRulesAtom, combineRules(get(currentRuleTemplateAtom)?.data || []))
})

export const setCurrentRuleTemplateRulesAtom = atom(
  null,
  (get, set, payload: { index: number; val: number; type: 'day_on' | 'day_off' }) => {
    const currentRuleTemplate = get(currentRuleTemplateAtom)

    if (!currentRuleTemplate) {
      console.error('Trying to change rules of rule template, but currentRuleTemplate is undefined')
      return
    }

    const rule = currentRuleTemplate['data'][payload.index]

    if (!rule) {
      return
    }

    set(currentRuleTemplateAtom, {
      ...currentRuleTemplate,
      data: currentRuleTemplate.data.map((rule, index) => {
        if (index === payload.index) {
          return {
            ...rule,
            [payload.type]: payload.val
          }
        }

        return rule
      })
    })

    set(currentRuleTemplateCombinedRulesAtom, combineRules(get(currentRuleTemplateAtom)?.data || []))
  }
)

export const addRuleToCurrentRuleTemplateAtom = atom(null, (get, set, payload: RuleTemplate['data'][number]) => {
  const currentRuleTemplate = get(currentRuleTemplateAtom)

  if (!currentRuleTemplate) {
    console.error('Trying to add rule to rule template, but currentRuleTemplate is undefined')
    return
  }

  set(currentRuleTemplateAtom, {
    ...currentRuleTemplate,
    data: [...currentRuleTemplate.data, payload]
  })

  set(currentRuleTemplateCombinedRulesAtom, combineRules(get(currentRuleTemplateAtom)?.data || []))
})
