import { Button } from '@/ui/button'
import { LocalDateTimePicker } from '@/ui/date'
import { Icon } from '@/ui/icons'
import { DatePicker, Space } from 'antd'
import cn from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { getDateRangeByView } from '../helpers'
import { CalendarViewType } from '../schemas'

type Props = {
  view: CalendarViewType
  value?: string
  onChange?: (date: string) => void
}

export function MonthWeekChanger({ value, onChange, view }: Props) {
  const valueDate = useMemo(() => dayjs.parse(value) || dayjs.now(), [value])
  const [hoveredDate, setHoveredDate] = useState(valueDate)

  const coveredRange = useMemo(
    () => getDateRangeByView(view, hoveredDate).map((d) => d.formatISODate(false)),
    [view, hoveredDate]
  )

  const startDateIso = coveredRange[0]
  const endDateIso = coveredRange[coveredRange.length - 1]
  const todayIsoDate = dayjs().formatISODate(false)
  const dateIsoDate = valueDate?.formatISODate(false)

  const handleChange = (date: Dayjs) => {
    if (date) {
      onChange?.(date.formatISODate(false))
      // setHoveredDate(date)
    }
  }

  const handlePrev = () => {
    if (view === 'week') handleChange(valueDate.subtract(1, 'week'))
    else if (view === 'three_days') handleChange(valueDate.subtract(3, 'days'))
    else handleChange(valueDate.subtract(1, 'month'))
  }

  const handleNext = () => {
    if (view === 'week') handleChange(valueDate.add(1, 'week'))
    else if (view === 'three_days') handleChange(valueDate.add(3, 'days'))
    else handleChange(valueDate.add(1, 'month'))
  }

  const formatSelected = (dt?: Dayjs) => {
    const selectedRange = getDateRangeByView(view, dt || dayjs())
    const startDate = selectedRange[0]
    const endDate = selectedRange[selectedRange.length - 1]

    return `${startDate?.formatLocalDate()} - ${endDate.formatLocalDate()}`
  }

  const cellRender: any = (current: Dayjs | number, info: any) => {
    if (typeof current === 'number') return info.originNode
    if (info.type !== 'date') return info.originNode

    const currentIsoDate = current.formatISODate(false)

    return (
      <div
        onMouseOver={() => setHoveredDate(current)}
        className={cn(
          `C:${currentIsoDate} T:${todayIsoDate} D:${dateIsoDate}`,
          'ant-picker-cell-inner !rounded-none !w-full',
          coveredRange.includes(currentIsoDate) ? '!bg-primary !text-primary-50' : '!bg-transparent',
          {
            '!text-neutral': currentIsoDate === todayIsoDate || currentIsoDate === dateIsoDate,
            '!rounded-l': currentIsoDate === startDateIso,
            '!rounded-r': currentIsoDate === endDateIso
          }
        )}
      >
        {current.date()}
      </div>
    )
  }

  return (
    <div className="flex [&_.ant-picker-cell-selected>.ant-picker-cell-inner]:!text-neutral">
      <Space>
        <Button shape={'circle'} icon={<Icon name={'mi:chevron_left'} />} type="text" onClick={handlePrev} />
        <Button shape={'circle'} icon={<Icon name={'mi:chevron_right'} />} type="text" onClick={handleNext} />
        {view === 'month' && (
          <DatePicker.MonthPicker
            className={'w-[210px]'}
            value={valueDate}
            format="MMM YYYY"
            allowClear={false}
            onChange={(date) => {
              if (date) {
                handleChange(date)
              }
            }}
          />
        )}
        {(view === 'week' || view === 'three_days') && (
          <LocalDateTimePicker
            showTime={false}
            showNow={false}
            value={valueDate}
            format={formatSelected}
            allowClear={false}
            onChange={(date) => {
              if (date) {
                handleChange(date)
                setHoveredDate(date)
              }
            }}
            onPanelChange={() => setHoveredDate(valueDate)}
            cellRender={cellRender}
            className={'w-[210px]'}
          />
        )}
      </Space>
    </div>
  )
}
