import { useApp } from '@/hooks'
import { ticketApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useButtonContext } from '../../hooks'

export const SummarizeTicketButton = () => {
  const { notification } = useApp()
  const { ticket, property, buttonProps, saveTicket, vars } = useButtonContext()
  const [isLoading, setIsLoading] = useState(false)

  const ticketSummaryMutation = useMutation(ticketApi.summary())

  const handleClick = async () => {
    setIsLoading(true)
    try {
      const dispositionSummarized = ![null, undefined, false, 'None'].includes(vars.summarized_tickets_status)

      const params = {
        selected_tickets: [ticket.id],
        custom_form: vars.custom_form_id.value,
        disposition_status: vars.summary_ticket_status,
        disposition_status_summarized: dispositionSummarized ? vars.summarized_tickets_status : null,
        disposition_tickets: dispositionSummarized,
        summary_sku: vars.summary_sku_id,
        list_line_items: vars.list_item_conf === 1 || vars.list_item_conf === 3,
        group_items: vars.list_item_conf === 3,
        attachment_type: vars.attachment_type || 'Z',
        build_source: {
          type: 'scripted_button',
          property_id: property.id
        }
      }

      await ticketSummaryMutation.mutateAsync({ id: ticket.id, ...params })
      await saveTicket({})
      notification.success({ message: 'Completed summarizing ticket' })
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      notification.error({ message: 'Failed to build summarizing ticket' })
    }
  }

  return (
    <Button {...buttonProps} onClick={handleClick} loading={isLoading}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
