import { useApp } from '@/hooks'
import { ticketAlertApi } from '@/services/api-service'
import { Button, Popconfirm } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useButtonContext } from '../../../hooks'

export const ReDispatchButton: FC = () => {
  const { ticket, property, buttonProps, saveTicket } = useButtonContext()
  const { dispatch_status, clear_property_ids, clear_alert_ids, is_re_dispatched_pid } =
    property._buttonConfig?.vars || {}
  const { notification } = useApp()

  const ticketAlertsQuery = useQuery({
    ...ticketAlertApi.list({
      'Q[]': [
        `alert__in|${(clear_alert_ids || []).join(',')}`, // alert id for New Rossco Job
        `ticket|${ticket.id}`
      ]
    }),
    enabled: false
  })
  const ticketAlertDeleteMutation = useMutation(ticketAlertApi.delete())

  const handleReDispatch = async () => {
    if (!isEmpty(clear_alert_ids)) {
      try {
        const ticketAlertsResult = await ticketAlertsQuery.refetch()
        await Promise.all(
          ticketAlertsResult.data?.items.map(async (alert: any) => ticketAlertDeleteMutation.mutateAsync(alert.id)) ||
            []
        )
      } catch (error) {
        console.warn('Failed to delete ticket alerts', error)
        // just ignore
      }
    }

    const payload: Record<string, any> = { status: dispatch_status || ticket.status }
    clear_property_ids?.forEach((id: number) => {
      payload[`p_${id}`] = ''
    })
    payload[`p_${is_re_dispatched_pid}`] = ' UPDATE'
    await saveTicket(payload)

    notification.success({
      message: 'Ticket Re-Dispatched',
      description: 'Ticket has been re-dispatched successfully'
    })
  }

  return (
    <Popconfirm title={'Re-Dispatching Ticket'} description={'Are you sure?'} onConfirm={handleReDispatch}>
      <Button {...buttonProps}>{buttonProps?.children ? buttonProps.children : property.name}</Button>
    </Popconfirm>
  )
}
