import { Select, SelectProps } from '@/ui'
import { FC } from 'react'

export const TypeSelect: FC<SelectProps> = (props) => {
  return (
    <Select
      options={[
        { value: 'Employee', label: 'Employee' },
        { value: 'Equipment', label: 'Equipment' }
      ]}
      data-cy="resource-type"
      {...props}
    />
  )
}
