import { APP_MODE } from '@/constants/app'
import { useCompanyFeatures, useRedirectHelper } from '@/hooks'
import { url } from '@/routing/helpers'
import { Button } from '@/ui'
import { FC, useCallback } from 'react'
import { useTicketModule } from '../../hooks'

export const TicketUseOldVersionButton: FC = () => {
  const { forceV2Tickets } = useCompanyFeatures()
  const { removeLocalRedirect } = useRedirectHelper()
  const { module } = useTicketModule()

  const onSwitch = useCallback(() => {
    const pathname = window.location.pathname
    let v1Url = '/'

    if (pathname.startsWith('/tickets')) {
      v1Url = '/tickets/'
      removeLocalRedirect('tickets')
    } else if (pathname.startsWith('/loads')) {
      v1Url = '/loads/'
      removeLocalRedirect('loads')
    } else if ((pathname.startsWith('/m/') || pathname.startsWith('/m2/')) && module) {
      const viewType = module.view_type.toLowerCase()
      v1Url = `/m/${module.path}/${viewType}/${module.id}/`
      removeLocalRedirect(`module_${viewType}_${module.id}`)
    } else {
      console.warn('Unknown page, cannot redirect to old version')
      return
    }

    // reload page
    window.location.href = url(v1Url || '/', { v: 1 })
  }, [module, removeLocalRedirect])

  if (APP_MODE !== 'production' || forceV2Tickets.enabled) return null

  return (
    <Button shape={'round'} size={'small'} primary={true} onClick={onSwitch}>
      Use Old Version
    </Button>
  )
}
