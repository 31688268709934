import { createContext, useContext } from 'react'
import { LineItemField } from '../../types'

const SortableItemContext = createContext<{ lineItemField: LineItemField } | null>(null)

type Props = {
  lineItemField: LineItemField
  children: React.ReactNode
}

export const SortableItemProvider = ({ children, lineItemField }: Props) => {
  return <SortableItemContext.Provider value={{ lineItemField }}>{children}</SortableItemContext.Provider>
}

export const useSortableItemContext = () => {
  const context = useContext(SortableItemContext)

  if (!context) {
    throw new Error('useSortableItemContext must be used within a SortableItemProvider')
  }

  return context
}
