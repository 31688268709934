import { atom } from 'jotai'

export type TabType = 'profile' | 'notification' | 'autofill' | 'access-rights' | 'relations' | 'schedule' | 'settings'
export const formTypeAtom = atom('add')
export const selectedTabAtom = atom<TabType>('profile')
export const tabDisabledAtom = atom(false)
export const sharedDataAtom = atom<any>({
  first_name: '',
  last_name: '',
  title: '',
  phone_number: '',
  new_email: '',
  username: '',
  is_active: false,
  timezone: null,
  profile: '',
  company_id: null,
  profile_id: null,
  contact_id: null,
  sync_notifications_from_role: false,
  userdata_json: {
    auto_fills: [],
    email_ticket_include_attachments: false,
    notifications: {
      email_new_ticket: false,
      email_assigned_to_me: false,
      email_ticket_submit: false,
      email_ticket_alert: false,
      email_property_change: false,
      email_alerts: {}
    }
  },
  company_admin: null,
  ticket_manager: null,
  support: null,
  ticket_signature: null,
  time_manager: null,
  my_time: null,
  qb_input: null,
  choices_manager: null,
  module_builder: null,
  ems_user: null,
  email_vendor: null,
  roles_manager: null,
  role_id: null,
  extra_json: {
    module_changes: { desc: '', added: [], removed: [] }
  }
})
