import { useApp } from '@/hooks'
import { ALERT_TYPES_MAP } from '@/modules/ticket/constants'
import { ticketAlertApi } from '@/services/api-service'
import { Button, Icon } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Alert } from 'antd'
import { useCallback } from 'react'
import { refreshQueries } from '../../helpers'
import { useTicket } from '../../hooks'
import { TicketAlert } from '../../schemas'

export const RaisedAlerts = () => {
  const { notification } = useApp()
  const { ticket } = useTicket()

  const mutation = useMutation(ticketAlertApi.patch())

  const handleAlertDismiss = useCallback(
    async (alert: TicketAlert) => {
      try {
        await mutation.mutateAsync({ id: alert.id, active: false })
        await refreshQueries()

        notification.success({
          message: 'Alert Dismissed',
          description: (
            <span>
              <b>{alert.alert__name}</b> alert dismissed
            </span>
          )
        })
      } catch (error) {
        notification.error({
          message: 'Alert Dismiss Failed',
          description: (
            <span>
              <b>{alert.alert__name}</b> alert dismiss failed
            </span>
          )
        })
        return false
      }
    },
    [mutation, notification]
  )

  return (
    <div>
      <div className="flex flex-col gap-4">
        {ticket.alerts?.map((alert) => (
          <Alert
            showIcon
            closable={false}
            icon={<Icon name={ALERT_TYPES_MAP[alert.alert__alert_type].icon} />}
            key={alert.alert__id}
            message={alert.alert__name}
            className={'mt-16'}
            type={ALERT_TYPES_MAP[alert.alert__alert_type].type}
            action={
              <Button size={'small'} type={'text'} iconName={'fa:close'} onClick={() => handleAlertDismiss(alert)} />
            }
          />
        ))}
      </div>
    </div>
  )
}
