import { TicketStatus } from '@/modules/ticket/types'
import { IconName } from '@/ui'

export const TICKET_STATUS: Record<TicketStatus, string> = {
  D: 'Draft',
  A: 'Created',
  O: 'Open',
  N: 'Needs Signature',
  S: 'Signed',
  B: 'Disputed',
  R: 'Reviewed',
  C: 'Closed',
  Z: 'Archived',
  X: 'Deleted'
}

// important! keep the order
export const TICKET_STATUS_LIST = ['D', 'A', 'O', 'N', 'S', 'B', 'R', 'C', 'Z', 'X']

export const SEARCH_FIELDS = [
  { label: 'ticket__name__label', value: 'name' },
  { label: 'ticket__timepoint_due__label', value: 'timepoint_due' },
  { label: 'ticket__afe__label', value: 'afe__user_afe_no' },
  { label: 'ticket__job_code__label', value: 'job_code__user_job_code_no' },
  { label: 'ticket__location__label', value: 'location__name' },
  { label: 'ticket__computed_status__label', value: 'computed_status' },
  { label: 'ticket__office__label', value: 'customer_office__name' },
  { label: 'ticket__contact__label', value: 'contact__name' },
  { label: 'Team Member', value: 'contacts__contact__first_name' }
]

export const SEARCH_TYPES = [
  { label: 'Contains', value: 'icontains' },
  { label: 'Equals', value: 'iexact' },
  { label: 'Null', value: 'isnull' }
]

export const CLONE_TICKET_SECTIONS = [
  { key: 'header', label: 'Ticket Header Data' },
  { key: 'properties', label: 'Custom Fields (Regular)' },
  { key: 'internal_properties', label: 'Custom Fields (Internal)' },
  { key: 'members', label: 'Team Members' },
  { key: 'attachments', label: 'Attachments' },
  { key: 'metadata', label: 'Metadata' },
  { key: 'line_items', label: 'Line Items' }
]

type AlertType = {
  key: 'I' | 'W' | 'E' | 'S'
  icon: IconName
  type: 'info' | 'warning' | 'error' | 'success'
  textColor: 'text-danger' | 'text-info' | 'text-success' | 'text-warning'
}

export const ALERT_TYPES: AlertType[] = [
  {
    key: 'I',
    icon: 'fa:circle-info',
    type: 'info',
    textColor: 'text-info'
  },
  {
    key: 'W',
    icon: 'fa:warning',
    type: 'warning',
    textColor: 'text-warning'
  },
  {
    key: 'E',
    icon: 'fa:warning',
    type: 'error',
    textColor: 'text-danger'
  },
  {
    key: 'S',
    icon: 'fa:circle-check',
    type: 'success',
    textColor: 'text-success'
  }
]

export const ALERT_TYPES_MAP: Record<string, AlertType> = ALERT_TYPES.reduce(
  (res, type_) => ({ ...res, [type_.key]: type_ }),
  {}
)
