import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { SaveButton } from '@/ui'
import { Form } from 'antd'
import { CompanyInformationSection } from './company-information-section'
import { useCompanyOfficesQuery, useUpdateCompanyMutation } from './hooks'
import { useCompanyQuery } from './hooks/use-company-query'
import { OfficesSection } from './offices-section'
import { FormValues } from './types'

export const CompanyInformationView = view<any, any>(Component, {
  title: () => 'Company Information'
})

function Component() {
  const { companyQuery } = useCompanyQuery()
  const { companyOfficesQuery } = useCompanyOfficesQuery()
  const { updateCompany, updateCompanyMutation } = useUpdateCompanyMutation()
  const [form] = Form.useForm<FormValues>()
  const { notification } = useApp()

  const handleSave = async () => {
    const company = companyQuery.data

    if (!company) {
      console.error('Company not found', company)
      return
    }

    const values = await form.validateFields()
    await updateCompany(values, company)
    await companyQuery.refetch()
    await companyOfficesQuery.refetch()
    notification.success({ message: 'Company updated successfully' })
  }

  const isLoading = companyQuery.isLoading || companyOfficesQuery.isLoading

  return (
    <PageView
      header={{
        title: 'Company Information',
        border: false,
        accent: false,
        actions: [
          <SaveButton
            key={'save-company-information'}
            loading={updateCompanyMutation.isLoading}
            onSave={handleSave}
            showReturn={false}
          >
            Save Company
          </SaveButton>
        ]
      }}
      loading={isLoading}
    >
      {/* temporary solution before we find a way to pass context into route loader */}
      {!isLoading && (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...companyQuery.data, offices: companyOfficesQuery.data?.items ?? [] }}
        >
          <CompanyInformationSection />
          <OfficesSection />
        </Form>
      )}
    </PageView>
  )
}
