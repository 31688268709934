import { UserSelect } from '@/components/user-select'
import { useFormWatch } from '@/hooks'
import { DistrictSelect, JobRoleSelect, SkillSelect } from '@/modules/jas/resource-manager/components'
import { contactApi } from '@/services/api-service'
import { LinkButton, TipButton } from '@/ui'
import { generateAvatarInitials } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { Card, Form, Input } from 'antd'
import { useEffect } from 'react'
import { Resource, ResourceContactSchema, ResourceForm } from '../schemas'
import { StatusFormItem } from './StatusFormItem'

export function ResourceDetailsCard() {
  const form = Form.useFormInstance<ResourceForm>()
  const resourceType = useFormWatch<Resource['type']>(['resource', 'type'])
  const contactId = useFormWatch(['resource', 'contact_id'])

  const { data: contact } = useQuery({
    ...contactApi.get(Number(contactId), undefined, { fields: ResourceContactSchema }),
    // useful when user changes profile and come back to this page
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: true
  })

  useEffect(() => {
    if (!contact) return

    const fullName = `${contact.first_name} ${contact.last_name}`
    form.setFieldsValue({
      resource: {
        name: fullName,
        short_code: generateAvatarInitials(fullName),
        contact__first_name: contact.first_name,
        contact__last_name: contact.last_name
      }
    })
  }, [contact, form])

  return (
    <Card
      title={`${resourceType} Details`}
      extra={
        contactId ? (
          <LinkButton
            size={'small'}
            shape={'round'}
            iconName={'fa:external-link'}
            to={`/profiles/${contact?.profile__id}/edit`}
            target={'_blank'}
          >
            Edit User Profile
          </LinkButton>
        ) : (
          <LinkButton
            size={'small'}
            shape={'round'}
            hidden={resourceType !== 'Employee'}
            iconName={'fa:external-link'}
            to={'/profiles/add'}
            target={'_blank'}
          >
            Create User Profile
          </LinkButton>
        )
      }
    >
      <StatusFormItem />
      <UserAccountFormItem />
      <Form.Item
        name={['resource', 'name']}
        label={`${resourceType} Name`}
        rules={[{ required: true }]}
        help={
          !!contactId && (
            <span className={'text-sm text-gray-400 p-6'}>Name can be changed from edit user profile page.</span>
          )
        }
      >
        <Input disabled={!!contactId} data-cy="resource-name" />
      </Form.Item>
      <Form.Item
        name={['resource', 'short_code']}
        label={'Short Code'}
        rules={[{ required: true }]}
        className={'relative'}
      >
        <Input
          data-cy="resource-short-code"
          suffix={
            <TipButton
              type={'text'}
              iconName={'fa:refresh'}
              className={'absolute top-0 right-0 border'}
              title="Generate"
              flashTitle="Generated"
              onClick={() => {
                const name = form.getFieldValue(['resource', 'name'])
                form.setFieldsValue({ resource: { short_code: generateAvatarInitials(name) } })
              }}
            />
          }
        />
      </Form.Item>
      <Form.Item name={['resource', '_jobRole']} label={'Job Role (Main)'} rules={[{ required: true }]}>
        <JobRoleSelect apiQueryParams={{ char_2__eq: resourceType }} allowClear={false} />
      </Form.Item>
      <Form.Item name={['resource', '_skills']} label={'Skills (Attributes)'}>
        <SkillSelect
          mode={'multiple'}
          maxTagCount={25}
          apiQueryParams={{ char_2__eq: resourceType }}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item name={['resource', 'district']} label={'District'}>
        <DistrictSelect maxTagCount={25} allowClear={false} />
      </Form.Item>
      <Form.Item name={['resource', 'descriptor']} label={'Description'}>
        <Input.TextArea data-cy="resource-description" />
      </Form.Item>
    </Card>
  )
}

function UserAccountFormItem() {
  const resourceType = useFormWatch<Resource['type']>(['resource', 'type'])
  const contactId = useFormWatch(['resource', 'contact_id'])

  if (resourceType !== 'Employee') return null

  return (
    <Form.Item name={['resource', 'contact_id']} label={'User Profile'}>
      <UserSelect
        status={'all'}
        apiValueBy={'id'}
        apiQueryOptions={{ cacheTime: 0, staleTime: 0, refetchOnWindowFocus: true }}
        apiQueryParams={{ Q: ['resources__isnull|true', `id__eq|${contactId || ''}`, 'or'] }}
      />
    </Form.Item>
  )
}
