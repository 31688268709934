import { TICKET_STATUS } from '@/constants/general'
import { Select } from '@/ui'
import { Form, Space, Switch } from 'antd'

export const TrackPaymentStatusSection = () => {
  const isTrackPaymentStatus = Form.useWatch('track_payment_status')

  return (
    <div>
      <h4 className="font-bold">Track Payment Status</h4>
      <Form.Item>
        <Space>
          <Form.Item noStyle name="track_payment_status">
            <Switch />
          </Form.Item>
          <span>Enable</span>
        </Space>
      </Form.Item>
      <Form.Item label="Paid Ticket Status" name="payment_success_ticket_status">
        <Select
          disabled={!isTrackPaymentStatus}
          options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
        />
      </Form.Item>
    </div>
  )
}
