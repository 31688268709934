import { useBootstrap } from '@/hooks/use-bootstrap'
import { appRouter } from '@/routes'
import { AppThemeProvider } from '@/themes'
import { LinkButton } from '@/ui'
import { App as AntApp, Result } from 'antd'
import React, { ReactNode } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ServerSelect } from './components'
import { DebugTools } from './components/debug-tools'

export const App: React.FC = () => {
  const bootstrap = useBootstrap()

  return bootstrap.isReady ? (
    <AppBase>
      <RouterProvider router={appRouter} />
      <DebugTools />
    </AppBase>
  ) : (
    <AppNotReady bootstrap={bootstrap} />
  )
}

function AppBase({ children }: { children: ReactNode }) {
  return (
    <AppThemeProvider>
      <AntApp message={{ maxCount: 1 }} notification={{ placement: 'bottomRight' }}>
        {children}
      </AntApp>
    </AppThemeProvider>
  )
}

function AppNotReady({ bootstrap }: { bootstrap: ReturnType<typeof useBootstrap> }) {
  const { query, endUserSession } = bootstrap

  return query.failureCount > 2 ? (
    <AppBase>
      <div className={'flex flex-col items-center justify-center h-screen'}>
        <Result
          className={'flex flex-col items-center justify-center animate-fade-in'}
          status={500}
          title={query.failureReason?.message || 'Unknown Error'}
          subTitle={'Please check your network connection or contact support.'}
          extra={
            <div className={'flex flex-col items-center justify-center gap-20'}>
              <ServerSelect size={'middle'} className={'animate-pulse'} />
              <div className={'flex flex-row gap-10'}>
                <LinkButton shape={'round'} iconName={'fa:arrow-left-from-arc'} onClick={endUserSession}>
                  Logout
                </LinkButton>
                <LinkButton shape={'round'} iconName={'fa:redo'}>
                  Reload
                </LinkButton>
              </div>
            </div>
          }
        />
      </div>
    </AppBase>
  ) : null
}
