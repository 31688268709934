import { FormatPicker } from '@/components/format-picker'
import { memo } from 'react'
import { useAllFields } from '../hooks/use-all-fields'

const Component = () => {
  const { fields, isLoading } = useAllFields()

  return (
    <div className="col-span-1">
      <FormatPicker title="Fields & Properties" isLoading={isLoading} isCollapsable searchedFields={fields} />
    </div>
  )
}

export const FieldNamePicker = memo(Component)
