import { nanoid } from '@reduxjs/toolkit'
import { z } from 'zod'

export const ruleTemplateSchema = z.object({
  id: z.number(),
  company: z.number().nullish(),
  comment: z.string(),
  name: z.string(),
  start_date: z.string().nullish(),
  end_date: z.string().nullish(),
  data: z.array(
    z.object({
      id: z
        .string()
        .optional()
        .default(() => nanoid()),
      day_on: z
        .string()
        .transform((val) => Number(val))
        .or(z.number()),
      day_off: z
        .string()
        .transform((val) => Number(val))
        .or(z.number())
    })
  ),
  type: z.enum(['Equipment', 'Employee']).nullish(),
  rules_count: z.number()
})

export type RuleTemplate = z.infer<typeof ruleTemplateSchema>
