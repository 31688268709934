import { request } from '@/services/api-service/core'
import { ObjectReturn, RequestConfig } from '@/types/api/core'
import { GmapRoute } from '@/types/gmap-route'

type Params = {
  origin?: string
  destination?: string
}

export type GmapApi = {
  directions: (params?: Params, config?: RequestConfig) => ObjectReturn<{ routes: GmapRoute[] }>
}

export const _gmapApi: GmapApi = {
  directions: (params, config) => ({
    queryKey: ['gmap', 'directions'],
    queryFn: ({ signal }) => request({ ...config, params, method: 'GET', url: '/gmap/directions/', signal: signal })
  })
}
