import { z } from 'zod'

export const QueryLookupOperators = z.enum([
  'exact',
  'iexact',
  'contains',
  'icontains',
  'in',
  'gt',
  'gte',
  'lt',
  'lte',
  'startswith',
  'istartswith',
  'endswith',
  'iendswith',
  'range',
  'isnull',
  'regex',
  'iregex'
])

export const QueryFieldConditionSchema = z.object({
  field: z.string(),
  lookup: QueryLookupOperators,
  value: z.any()
})

export const QueryFilterSchema: any = z.lazy(() =>
  z.object({
    AND: z.array(QueryFilterSchema).optional(),
    OR: z.array(QueryFilterSchema).optional(),
    NOT: QueryFilterSchema.optional(),
    conditions: z.array(QueryFieldConditionSchema).optional()
  })
)

export type TQueryFilter = z.infer<typeof QueryFilterSchema>

export type QueryFilterProps = {
  value: TQueryFilter
  onChange: (value: TQueryFilter) => void
}

export function QueryFilter(props: QueryFilterProps) {
  return <div>todo: for future</div>
}
