import { useSession } from '@/hooks'
import { equipmentApi, equipmentInventoryStatsApi, officeApi } from '@/services/api-service'
import { EquipmentStats } from '@/types/equipment-stats'
import { AppLink, Button, QueryTable, QueryTableRef, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, DatePicker, Form, Space } from 'antd'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { Layout } from '../../components/layout'
import { INVENTORY_PATHS } from '../../constants'
import { downloadReport } from './utils/download-report'
import { getEquipmentStats } from './utils/get-equipment-stats'

export const InventoryView = () => {
  const { company } = useSession()
  const [office, setOffice] = useState<number | string>('')
  const [date, setDate] = useState<string>('current')
  const queryTableRef = useRef<QueryTableRef>(null)

  const equipmentStatsQuery = useQuery(
    equipmentInventoryStatsApi.list<EquipmentStats>({
      company_wide: 1,
      current: date === 'current' ? 1 : 0,
      limit: 'None',
      ...(office ? { 'offices[]': office } : {}),
      ...(date !== 'current' && date ? { end_date: date } : {})
    })
  )

  const officesQuery = useQuery(officeApi.list({ company_id__exact: company.id, limit: 'None', compact: 1 }))
  const offices = officesQuery.data?.items.map((office) => ({ label: office.name, value: office.id })) || []
  const equipmentsStats = equipmentStatsQuery.data?.items || []
  const equipmentStats = (id: number) => getEquipmentStats(id, equipmentsStats)

  return (
    <Layout>
      <Form layout="vertical">
        <div className="flex justify-between">
          <div className="flex gap-x-24">
            <Form.Item label="Office">
              <Select
                popupMatchSelectWidth={false}
                loading={officesQuery.isLoading}
                options={[{ label: 'All offices', value: '' }, ...offices]}
                value={office}
                onChange={(value) => setOffice(value)}
              />
            </Form.Item>
            <Form.Item label="Filter Date/Time">
              <Space>
                <Checkbox checked={date === 'current'} onChange={(e) => setDate(e.target.checked ? 'current' : '')}>
                  Now
                </Checkbox>
                {date !== 'current' && (
                  <DatePicker
                    showTime
                    format="MM/DD/YYYY HH:mm"
                    onChange={(value) => setDate(value ? value.toISOString() : '')}
                  />
                )}
              </Space>
            </Form.Item>
          </div>
          <AppLink>
            <Button
              type="primary"
              iconName="fa:download"
              onClick={() => downloadReport(equipmentsStats, queryTableRef.current?.query.data?.items || [])}
            >
              Download Report
            </Button>
          </AppLink>
        </div>
      </Form>
      <QueryTable
        ref={queryTableRef}
        queryApi={equipmentApi.list}
        loading={equipmentStatsQuery.isFetching}
        queryParams={{
          compact: 1,
          company_id__exact: company.id,
          inventory__exact: 1,
          limit: 'None'
        }}
        columns={[
          { title: 'SKU', dataIndex: 'sku', sorter: false },
          { title: 'Description', dataIndex: 'description', sorter: false },
          { title: 'Unit', dataIndex: 'unit', sorter: false },
          {
            title: 'Date',
            dataIndex: 'id',
            sorter: false,
            render: (id: number) =>
              equipmentStats(id)?.timepoint ? dayjs(equipmentStats(id)?.timepoint).format('M/D/YYYY') : ''
          },
          {
            title: 'Quantity to Date',
            dataIndex: 'id',
            sorter: false,
            render: (id: number) => equipmentStats(id)?.balance
          },
          {
            dataIndex: 'id',
            align: 'right',
            sorter: false,
            render: (id: number) => (
              <AppLink to={`${INVENTORY_PATHS.ledger}/?id=${id}`}>
                <Button type="text" iconName="fa:chevron-circle-right" className="text-18" />
              </AppLink>
            )
          }
        ]}
      />
    </Layout>
  )
}
