import { atom } from 'jotai'
import { DraggableItem } from './types'

const COLUMN_KEYS = {
  available: 'available',
  selected: 'selected'
} as const

export const columnsAtom = atom<Record<typeof COLUMN_KEYS.available | typeof COLUMN_KEYS.selected, DraggableItem[]>>({
  [COLUMN_KEYS.available]: [],
  [COLUMN_KEYS.selected]: []
})
