import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { eventApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Switch } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import './RecentActivity.scss'
interface DataType {
  key: number
  id: number
  action_text: string
  target_text: string
  contact_name: string
  contact_title: string
  contact_company_name: string
  date: string
  days: string
  time: string
  version_stats_platform: string
  version_stats_app: string
  version_stats_version: string
}
const RecentActivity = () => {
  const [showActivity, setShowActivity] = useState(false)
  const { id } = useParams<{ id: string }>()
  const eventsQuery = useQuery(eventApi.list({ target: id, type: 'profile' }))

  return (
    <div>
      <div className="flex items-center mb-16">
        <h4 className="mr-10">Recent Activity</h4>
        <div className="relative -top-2">
          <Switch checked={showActivity} onChange={(x) => setShowActivity(x)} />
        </div>
      </div>
      {showActivity && (
        <>
          <ActivityLogsModal query={eventApi.list} queryVariables={{ target: id, type: 'profile' }} />
          <div className="mt-16">
            <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
          </div>
        </>
      )}
    </div>
  )
}

export default RecentActivity
