import { useAppTheme } from '@/hooks/use-app-theme'
import { JasResourceEvent } from '@/types/jas-resource'
import { Progress } from '@/ui/progress'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import styles from './JobHourBar.module.scss'

type Props = {
  events: JasResourceEvent[]
  date: Dayjs
}

export const JobHourBar = ({ events, date }: Props) => {
  const { token } = useAppTheme()

  const progresses = useMemo(() => {
    return events.map(({ start_datetime, end_datetime }) => {
      const startDate = dayjs(start_datetime)
      const endDate = dayjs(end_datetime)

      let totalHours = 0

      if (startDate.isSame(endDate, 'day')) {
        totalHours = endDate.diff(startDate, 'hour')
      } else if (startDate.isSame(date, 'day')) {
        totalHours = date.endOf('day').diff(startDate, 'hour') + 1 // add 1 hour to calculate from 00:00
      } else if (endDate.isSame(date, 'day')) {
        totalHours = endDate.diff(date.startOf('day'), 'hour')
      } else {
        totalHours = 24
      }

      return {
        totalHours,
        startDate,
        endDate
      }
    })
  }, [events, date])

  return (
    <div className={styles.JobHourBar}>
      {progresses.map(({ totalHours, startDate }, index) => (
        <div key={index} className="absolute top-0 left-0 w-full">
          <Progress
            percent={totalHours ? (Math.abs(totalHours) * 100) / 24 : 0}
            showInfo={false}
            strokeColor={token.colorPrimary}
            startFrom={
              startDate.isSame(date, 'day') ? startDate.diff(date.startOf('day'), 'hour') * (100 / 24) : undefined
            }
            trailColor="rgb(255,255,255,0%)" // Make trail transparent
          />
        </div>
      ))}
    </div>
  )
}
