import { Icon } from '@/ui/icons'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { FC, useMemo } from 'react'
import { bpaConfigAtom } from '../../atoms'
import { RulesHeaderButtons } from './RulesHeaderButtons'
import { RulesSortable } from './RulesSortable'

export const RulesCard: FC = () => {
  const noRules = useAtomValue(
    useMemo(
      () =>
        selectAtom(bpaConfigAtom, (config) => {
          return config.rules.length === 0
        }),
      []
    )
  )

  return (
    <div className="flex flex-col rounded border border-l-2 ">
      <div className="flex flex-row border-b p-10">
        <div className="flex-grow text-16">
          <Icon name={'fa:webhook'} className="mr-2" /> Rules
        </div>
        <div className="flex-shrink-0 flex gap-10">
          <RulesHeaderButtons />
        </div>
      </div>
      <div></div>
      <div className="flex flex-col gap-10 p-10 pl-16 relative">
        {noRules ? (
          <div className=" text-center text-text-muted py-16">
            <Icon name="fa:info-circle" className="mr-2" /> No Rules
          </div>
        ) : (
          <RulesSortable />
        )}
      </div>
    </div>
  )
}
