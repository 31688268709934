import { useApp } from '@/hooks'
import { documentApi, moduleApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'

export const SaveButton = () => {
  const form = Form.useFormInstance()
  const updateModuleMutation = useMutation(moduleApi.patch())

  const { notification } = useApp()
  const createDocumentMutation = useMutation(documentApi.create())
  const updateDocumentMutation = useMutation(documentApi.patch())
  const queryClient = useQueryClient()

  const handleSave = async () => {
    const {
      icon_file,
      config,
      data,
      hidden_views,
      $list_item_conf,
      view_type,
      document,
      document__title,
      title,
      ...values
    } = await form.validateFields()

    if (view_type === 'D') {
      const payload = {
        id: document.id,
        title: document__title || title
      }
      if (!payload.id) {
        const doc = await createDocumentMutation.mutateAsync(payload)
        document.id = doc.id
      } else {
        await updateDocumentMutation.mutateAsync(payload)
      }
    }

    const item_conf_flags = data.$list_item_conf as number | undefined
    const item_conf = item_conf_flags !== void 0 && {
      $list_item_conf: undefined,
      list_line_items: Boolean(item_conf_flags & 1),
      group_items: Boolean(item_conf_flags & 2)
    }

    const dataJson = JSON.stringify(
      {
        ...config,
        ...data,
        ...item_conf,

        hidden_statuses: data?.hidden_statuses?.join(','),
        statuses: data?.statuses?.join(','),
        layers: data?.layers?.map((layer: any) => ({
          ...layer,
          fields: layer.fields?.map((field: any) => [field.name, field.type, field.property])
        }))
      },
      null,
      2
    )

    const module = await updateModuleMutation.mutateAsync({
      view_type,
      title,
      ...values,
      hidden_views_json: JSON.stringify(hidden_views),
      config_json: JSON.stringify(config, null, 2),
      data_json: dataJson,
      document: document.id
    })

    if (icon_file) {
      const formData = new FormData()
      formData.append('icon', icon_file.file)
      formData.append('icon_name', icon_file.file.name)
      formData.append('id', module.id)
      await updateModuleMutation.mutateAsync(formData) // TODO: This may be sent in the same request. But backend throwing error for null values (ie. selected_view) sent in FormData
    }

    // set data_json and config_json to form
    form.setFieldsValue({ data_json: module.data_json, config_json: module.config_json })

    notification.success({ message: 'Module saved' })

    queryClient.refetchQueries(['events'])
  }

  return (
    <Button type="success" iconName="mi:save" onClick={handleSave}>
      Save
    </Button>
  )
}
