import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { moduleApi } from '@/services/api-service'
import { Outlet } from 'react-router-dom'

type Module = {
  id: number
  title: string
} | null

type LoaderData = {
  title: string
  module: Module
}

export const layoutTicket = (title: string) => {
  return view<any, LoaderData>(() => <Outlet />, {
    title: ({ data }) => data?.title || 'Tickets',
    loader: async ({ params }) => {
      const moduleId = Number(params.moduleId)
      let module = null

      if (moduleId) {
        // handle the error here
        // because, we should still show child ticket page even if module is not found
        try {
          module = (await fetchQuery(moduleApi.get(moduleId, undefined, { fields: 'id,title' }))) as Module
          title = module?.title || title
        } catch (error) {
          // do nothing
        }
      }

      return { title, module }
    }
  })
}
