import { Tabs, TabsProps } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedAutofillTabAtom } from '../atoms'
import { useCustomForms } from '../hooks'
import styles from './tabHeader.module.scss'

export const TabHeader = () => {
  const { data: customFormData, isLoading } = useCustomForms()
  const [tabItems, setTabItems] = useState<TabsProps['items']>([])
  const selectedAutofillTab = useAtomValue(selectedAutofillTabAtom)
  const setSelectedAutofillTab = useSetAtom(selectedAutofillTabAtom)

  const handleTabChange = (key: string) => {
    const tabType = Number(key)
    setSelectedAutofillTab(tabType)
  }
  useEffect(() => {
    if (customFormData && customFormData?.items && customFormData.items.length > 0) {
      const itemsList = customFormData?.items.map((cf) => {
        return {
          key: cf.id.toString(),
          label: cf.name
        }
      })

      setTabItems(itemsList)
      setSelectedAutofillTab(Number(itemsList[0].key))
    }
  }, [customFormData])
  return (
    <Tabs
      className={styles.wrapper}
      defaultActiveKey={selectedAutofillTab?.toString()}
      items={tabItems}
      onChange={handleTabChange}
    />
  )
}
