import { queryClient } from '@/query'
import {
  ApiResource,
  BlobMutationReturn,
  FieldsParam as FP,
  ListParams,
  ListReturn,
  MutationReturn,
  ObjectReturn,
  Params,
  RequestConfig
} from '@/types/api/core'
import { UseQueryOptions } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { R, createResourceApi, getParams, getPayloadId } from '../core'

export type TicketApi = Omit<ApiResource, 'batch'> & {
  clone: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData, { id: number }>
  bundle: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  batch: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  setContacts: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  contacts: <TData = any>(id: number | null, params?: ListParams<TData>, config?: RequestConfig) => ListReturn<TData>
  lineItemsStats: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => ObjectReturn<TData>
  reorderLines: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  batchPatchLines: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  print: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => BlobMutationReturn<TData>
  accounting: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
  summary: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
  rebuildSummary: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
  mergeSummary: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _ticketApi: TicketApi = {
  ...createResourceApi('tickets'),
  clone: (params, config) => ({
    mutationFn: (data: any) =>
      R('POST', `/tickets/${getPayloadId(data)}/clone/`, { params, data, ...config }, { isList: true })
  }),
  summary: (params, config) => ({
    mutationFn: (data: any) => R('POST', '/tickets/summary/', { params, data, ...config })
  }),
  bundle: (params, config) => ({
    mutationFn: (data: any) => R('POST', '/tickets/bundle/', { params, data, ...config })
  }),
  batch: (params, config) => ({
    mutationFn: (data: any) => R('PATCH', '/tickets/batch/', { params, data, ...config })
  }),
  setContacts: (params, config) => ({
    mutationFn: (data: any) => R('POST', `/tickets/${getPayloadId(data)}/contacts/`, { params, data, ...config }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['contact_links'] })
    }
  }),
  contacts: (id, params, config) => ({
    enabled: !!id,
    queryKey: ['tickets', id, 'contacts', getParams(params, true)],
    queryFn: ({ signal }: RequestConfig) =>
      R(
        'GET',
        `/tickets/${id}/contact_links/`,
        { ...config, signal, params: getParams(params, true) },
        { isList: true }
      )
  }),
  lineItemsStats: (id, params, config) => ({
    enabled: !!id,
    queryKey: ['tickets', id, 'line_items_stats', getParams(params, true)],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', `/tickets/${id}/line_items_stats/`, { ...config, signal, params: getParams(params, true) })
  }),
  reorderLines: (params, config) => ({
    mutationFn: (data: any) => R('POST', `/tickets/${getPayloadId(data)}/reorder_lines/`, { params, data, ...config })
  }),
  batchPatchLines: (params, config) => ({
    mutationFn: (data: any) =>
      R('PATCH', `/tickets/${getPayloadId(data)}/line_items/batch/`, { params, data, ...config })
  }),
  print: (id, params, config) => ({
    mutationFn: ({ format, ...options }: any) =>
      R(
        'GET',
        `/tickets/${id}/print.${format || 'pdf'}`,
        { params: { ...params, ...options }, responseType: 'blob', ...config },
        { isBlob: true }
      )
  }),
  accounting: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('POST', `/tickets/${id}/accounting/`, { params, data, ...config })
  }),
  rebuildSummary: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('POST', `/tickets/${id}/rebuild_summary/`, { params, data, ...config })
  }),
  mergeSummary: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('PATCH', `/tickets/${id}/merge_summary/`, { params, data, ...config })
  })
}

export type TicketApiV2 = ApiResource & {
  stats: (params?: ListParams, config?: RequestConfig) => UseQueryOptions<any>
  subscriberAccess: <TData = any>(token?: string, params?: FP<TData>, config?: RequestConfig) => ObjectReturn<TData>
  secureDetail: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => ObjectReturn<TData>
  signTicket: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
  dispute: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _ticketApiV2: TicketApiV2 = {
  ...createResourceApi('v2/tickets', ['tickets', 'v2']),
  stats: (params, config) => ({
    queryKey: ['tickets', 'v2', 'stats', params],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', '/v2/tickets/stats/', { signal, params, ...config }, { isList: true })
  }),
  subscriberAccess: (token, params, config) => ({
    enabled: !isEmpty(token),
    queryKey: ['tickets', 'v2', 'subscriber_access', token, getParams(params, true)],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', `/v2/tickets/subscriber_access/${token}/`, { ...config, signal, params: getParams(params, true) })
  }),
  secureDetail: (id, params, config) => ({
    enabled: !!id,
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', `/v2/tickets/${id}/secure_detail/`, { ...config, signal, params: getParams(params, true) })
  }),
  signTicket: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('POST', `/v2/tickets/${id}/sign_ticket`, { params, data, ...config })
  }),
  dispute: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('POST', `/v2/tickets/${id}/dispute`, { params, data, ...config })
  })
}
