import classNames from 'classnames'
import { useSetAtom } from 'jotai/react'
import React from 'react'
import { colIndexAtom } from '../atoms'
import { CalendarTableVisibleDays } from '../types'

type Props = {
  colIndex: number
  children: React.ReactNode
  visibleDays: CalendarTableVisibleDays
  withBorder?: boolean
}

export const DayColCell = ({ children, colIndex, visibleDays, withBorder, ...rest }: Props) => {
  const setAtom = useSetAtom(colIndexAtom)

  const handleMouseEnter = () => {
    setAtom(colIndex)
  }

  const handleMouseLeave = () => {
    setAtom(-1)
  }

  return (
    <div
      className={classNames({
        'border-b border-0 border-solid border-border': withBorder ?? true,
        'w-full': visibleDays === 'week' || visibleDays === 'three_days',
        'w-50 shrink-0': visibleDays === 'month'
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children}
    </div>
  )
}
