import { APP_SITE } from '@/constants/app'
import { useApp } from '@/hooks'
import { ViewHandle } from '@/routing'
import { FC, useEffect } from 'react'
import { UIMatch, useMatches, useNavigation, useParams } from 'react-router-dom'
import { ProgressBar } from './progress-bar'

type Props = {
  className?: string
}

export const NavigationSync: FC<Props> = ({ className }) => {
  usePageTitleSync()
  const navigation = useNavigation()

  return navigation.state === 'loading' ? <ProgressBar className={className} isLoading={true} /> : null
}

const usePageTitleSync = () => {
  const { l } = useApp()

  const params = useParams()
  const matches = useMatches() as UIMatch<any, ViewHandle>[]

  useEffect(() => {
    const title = matches
      .filter((m) => m.handle?.title)
      .map(({ handle, data }) => l(handle?.title?.({ data: data || {}, params }) || '', '').trim() || '-')
      .reverse()
      .join(' · ')

    document.title = title ? `${title} | ${APP_SITE.name}` : APP_SITE.name
  }, [l, matches, params])
}
