import { EntityColumn } from '@/types/entity-column'
import { TICKET_COLUMNS_WITH_PREFIX, TICKET_HIDDEN_REFERENCES } from '../ticket/columns'

const CAT_ITEM = 'lineItem' as const
const CAT_PARENT_ITEM = 'parentLineItem' as const

let LINE_ITEM_COLUMNS: EntityColumn[] = [
  {
    path: 'category__parent_category__',
    path_verbose: 'category',
    field: 'name',
    name: 'Parent Category',
    field_verbose: 'parent_category',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: 'category__',
    path_verbose: 'category',
    field: 'name',
    name: 'Category',
    field_verbose: 'category',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'sku',
    name: 'SKU',
    field_verbose: 'sku',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'sku_designation',
    name: 'SKU Designation',
    field_verbose: 'sku_designation',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'uuid',
    name: 'ID',
    field_verbose: 'uuid',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'description',
    name: 'Description',
    field_verbose: 'description',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'unit',
    name: 'Unit',
    field_verbose: 'unit',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'sequence',
    name: 'Sequence',
    field_verbose: 'sequence',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'usage_rate',
    name: 'Usage Rate',
    field_verbose: 'usage_rate',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'standby_rate',
    name: 'Standby Rate',
    field_verbose: 'standby_rate',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'hands',
    name: 'Hands',
    field_verbose: 'hands',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'units_used',
    name: 'Units Used',
    field_verbose: 'units_used',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'units_standby',
    name: 'Units Standby',
    field_verbose: 'units_standby',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'units_min',
    name: 'Units Min',
    field_verbose: 'units_min',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'barrels',
    name: 'Barrels',
    field_verbose: 'barrels',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'start_datetime',
    name: 'Start Datetime',
    field_verbose: 'start_datetime',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'end_datetime',
    name: 'End Datetime',
    field_verbose: 'end_datetime',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'minimum',
    name: 'Minimum',
    field_verbose: 'minimum',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'discount',
    name: 'Discount',
    field_verbose: 'discount',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'discountable_amount',
    name: 'Discountable Amount',
    field_verbose: 'discountable_amount',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'amount',
    name: 'Amount',
    field_verbose: 'amount',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'cost',
    name: 'Cost',
    field_verbose: 'cost',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'tax_rate',
    name: 'Tax Rate',
    field_verbose: 'tax_rate',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'taxable_amount',
    name: 'Taxable Amount',
    field_verbose: 'taxable_amount',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'created_at',
    name: 'Created',
    field_verbose: 'created_at',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'modified_at',
    name: 'Modified',
    field_verbose: 'modified_at',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_decimal_1',
    name: 'Custom Decimal 1',
    field_verbose: 'user_decimal_1',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_decimal_2',
    name: 'Custom Decimal 2',
    field_verbose: 'user_decimal_2',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_decimal_3',
    name: 'Custom Decimal 3',
    field_verbose: 'user_decimal_3',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_integer_1',
    name: 'Custom Integer 1',
    field_verbose: 'user_integer_1',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_integer_2',
    name: 'Custom Integer 2',
    field_verbose: 'user_integer_2',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_integer_3',
    name: 'Custom Integer 3',
    field_verbose: 'user_integer_3',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_integer_4',
    name: 'Custom Integer 4',
    field_verbose: 'user_integer_4',
    field_type: 'number',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_1',
    name: 'Custom Text 1',
    field_verbose: 'user_text_1',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_2',
    name: 'Custom Text 2',
    field_verbose: 'user_text_2',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_3',
    name: 'Custom Text 3',
    field_verbose: 'user_text_3',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_4',
    name: 'Custom Text 4',
    field_verbose: 'user_text_4',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_5',
    name: 'Custom Text 5',
    field_verbose: 'user_text_5',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_6',
    name: 'Custom Text 6',
    field_verbose: 'user_text_6',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_7',
    name: 'Custom Text 7',
    field_verbose: 'user_text_7',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_text_8',
    name: 'Custom Text 8',
    field_verbose: 'user_text_8',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_long_text_1',
    name: 'Custom Long Text 1',
    field_verbose: 'user_long_text_1',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_datetime_1',
    name: 'Custom Date 1',
    field_verbose: 'user_datetime_1',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'user_datetime_2',
    name: 'Custom Date 2',
    field_verbose: 'user_datetime_2',
    field_type: 'date',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'notes',
    name: 'Notes',
    field_verbose: 'notes',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'status',
    name: 'Status',
    field_verbose: 'status',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'assignee',
    name: 'Assignee',
    field_verbose: 'assignee',
    field_type: 'text',
    category: CAT_ITEM
  },
  {
    path: '',
    path_verbose: '',
    field: 'group_subtotal',
    name: 'Subtotal',
    field_verbose: 'group_subtotal',
    field_type: 'number',
    category: CAT_ITEM
  }
].map((c) => ({ ...c, key: c.path + c.field }))

LINE_ITEM_COLUMNS = [
  ...LINE_ITEM_COLUMNS,
  ...LINE_ITEM_COLUMNS.map((column) => ({
    ...column,
    category: CAT_PARENT_ITEM,
    field_verbose: `finished_good_li__${column.field_verbose}`,
    name: `Parent ${column.name}`,
    path: `finished_good_li__${column.path}`,
    path_verbose: column.path_verbose ? `parent ${column.path_verbose}` : 'parent item'
  }))
].map((c) => ({ ...c, key: c.path + c.field }))

export const LINE_ITEM_FILTER_COLUMNS = [
  ...LINE_ITEM_COLUMNS,
  ...TICKET_COLUMNS_WITH_PREFIX,
  ...TICKET_HIDDEN_REFERENCES.map((col) => ({
    ...col,
    path: 'ticket__' + col.path,
    path_verbose: 'ticket' + col.path_verbose
  }))
]

export { LINE_ITEM_COLUMNS }

/**
 * Map of ticket columns by key (path + field)
 * @type {Record<string, EntityColumn>}
 */
export const LINE_ITEM_COLUMNS_MAP: Record<string, EntityColumn> = LINE_ITEM_COLUMNS.reduce(
  (acc, c) => {
    acc[c.key] = c
    return acc
  },
  {} as Record<string, EntityColumn>
)
