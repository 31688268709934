import { useApp } from '@/hooks'
import { ticketApi, ticketApiV2 } from '@/services/api-service'
import { Button, Popconfirm, QuerySelect } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form } from 'antd'
import { FC, useCallback, useState } from 'react'
import { refreshQueries } from '../../../../helpers'
import { useTicket } from '../../../../hooks'

export const SummaryAddTicket: FC = () => {
  const { ticket } = useTicket()
  const { notification } = useApp()

  const [rebuildAttachments, setRebuildAttachments] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])

  const mergeSummaryMutation = useMutation(
    ticketApi.mergeSummary(ticket.id, undefined, { params: { skip_pdf: rebuildAttachments ? undefined : '1' } })
  )

  const handleAddTicket = useCallback(async () => {
    try {
      await mergeSummaryMutation.mutateAsync({
        id: ticket.id,
        selected_tickets: [...(ticket._data?.summary_config?.selected_tickets || []), ...selectedTickets]
      })

      notification.success({
        message: 'Ticket Added to Summary',
        description: 'The ticket has been added to the summary successfully'
      })

      refreshQueries()

      setRebuildAttachments(false)
      setSelectedTickets([])
    } catch (error) {
      notification.error({
        message: 'Error Adding Ticket to Summary',
        description: error?.toString()
      })
    }
  }, [
    mergeSummaryMutation,
    notification,
    rebuildAttachments,
    selectedTickets,
    ticket._data?.summary_config?.selected_tickets,
    ticket.id
  ])

  return (
    <Popconfirm
      title={'Add Ticket To Summary'}
      placement={'left'}
      onConfirm={handleAddTicket}
      okText={'Add'}
      okButtonProps={{ disabled: selectedTickets.length === 0 }}
      description={
        <Form layout="vertical" component="div">
          <div className={'w-[300px] -ml-10 mr-10 mb-10'}>
            <Form.Item className={'m-0 pr-6 py-10'}>
              <Checkbox value={rebuildAttachments} onChange={(e) => setRebuildAttachments(e.target.checked)}>
                Rebuild Summary Document
              </Checkbox>
            </Form.Item>
            <Form.Item required label="Selected Tickets">
              <QuerySelect
                maxTagCount={undefined}
                value={selectedTickets}
                onChange={(values) => setSelectedTickets(values)}
                placeholder={'Search By Name'}
                mode={'multiple'}
                apiEndpoint={ticketApiV2.list}
                apiQueryParams={{ fields: 'id,name,custom_form__name' }}
                apiSearchBy={'name'}
                apiQueryOptions={{ enabled: true }}
                renderOption={(item) => ({
                  value: item.id,
                  label: (
                    <span>
                      #{item.name} <small>({item.custom_form__name})</small>
                    </span>
                  )
                })}
              />
            </Form.Item>
          </div>
        </Form>
      }
    >
      <Button primary size={'small'}>
        Add Ticket
      </Button>
    </Popconfirm>
  )
}
