import { INDICATOR_DATE_DELTAS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { equipmentApi, inventoryIndicatorApi } from '@/services/api-service'
import { InventoryIndicator } from '@/types/inventory-indicator'
import { Modal, ModalProps, Select } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DatePicker, Form, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

type Props = {
  inventoryIndicator?: InventoryIndicator
  sequence?: number
} & ModalProps

export const AddEditIndicatorModal = ({ inventoryIndicator, sequence, onCancel, ...props }: Props) => {
  const { company } = useSession()
  const { notification } = useApp()
  const [form] = Form.useForm()
  const dateDelta = Form.useWatch('date_delta', form)
  const [skuSearch, setSkuSearch] = useState('')

  const equipmentsQuery = useQuery(
    equipmentApi.list({
      compact: 1,
      company_id__exact: company.id,
      inventory__exact: 1,
      ...(skuSearch ? { search: skuSearch, limit: 'None' } : {})
    })
  )

  const createInventoryIndicatorMutation = useMutation(inventoryIndicatorApi.create())
  const updateInventoryIndicatorMutation = useMutation(inventoryIndicatorApi.update())

  const handleSave = async () => {
    const { id, equipment, begin_date, end_date, ...values } = await form.validateFields()

    // checking for string because initial value of equipment is a sku and description in a Select
    const equipmentId = equipment
      ? typeof equipment === 'string'
        ? inventoryIndicator?.equipment?.id
        : equipment
      : null

    const beginDate = begin_date ? begin_date.format('YYYY-MM-DD') : null
    const endDate = end_date ? end_date.format('YYYY-MM-DD') : null

    const payload = {
      ...values,
      equipment: equipmentId,
      sequence,
      company: company.id,
      begin_date: beginDate,
      end_date: endDate
    }

    if (id) {
      await updateInventoryIndicatorMutation.mutateAsync({ id, ...payload })
    } else {
      await createInventoryIndicatorMutation.mutateAsync(payload)
    }

    notification.success({ message: 'Indicator saved successfully' })
  }

  return (
    <Modal
      title={inventoryIndicator ? 'Edit Indicator' : 'Add New Indicator'}
      open
      withScreenMaxHeight
      okText="Save"
      width={700}
      onOk={async (e) => {
        await handleSave()
        onCancel?.(e)
      }}
      onCancel={onCancel}
      {...props}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          id: inventoryIndicator?.id,
          name: inventoryIndicator?.name || '',
          equipment: inventoryIndicator
            ? `${inventoryIndicator.equipment?.sku || ''} - ${inventoryIndicator.equipment?.description}`
            : undefined,
          notes: inventoryIndicator?.notes,
          y_max_options: inventoryIndicator?.y_max_options,
          y_min_options: inventoryIndicator?.y_min_options,
          alert_level: inventoryIndicator?.alert_level,
          date_delta: inventoryIndicator?.date_delta || 'T',
          begin_date: inventoryIndicator?.begin_date ? dayjs(inventoryIndicator.begin_date) : undefined,
          end_date: inventoryIndicator?.end_date ? dayjs(inventoryIndicator.end_date) : undefined
        }}
      >
        <Form.Item name="id" hidden />
        <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="SKU" name="equipment" required rules={[{ required: true }]}>
          <Select
            onSearch={setSkuSearch}
            filterOption={false}
            options={equipmentsQuery.data?.items.map((equipment) => ({
              label: `${equipment.sku} - ${equipment.description}`,
              value: equipment.id
            }))}
          />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input />
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-16">
          <Form.Item label="Y-Max Options" name="y_max_options" tooltip="(eg. 100, 300, 500)">
            <Input />
          </Form.Item>
          <Form.Item label="Y-Min Options" name="y_min_options" tooltip="(eg. 100, 300, 500)">
            <Input />
          </Form.Item>
          <Form.Item label="Alert Level" name="alert_level" tooltip="(eg. 500)">
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item label="Date Delta" name="date_delta">
            <Select
              options={Object.entries(INDICATOR_DATE_DELTAS).map(([value, label]) => ({ value, label }))}
              onChange={(val) => {
                if (val === 'C') {
                  form.setFieldsValue({ begin_date: dayjs(), end_date: dayjs() })
                }
              }}
            />
          </Form.Item>
          {dateDelta === 'C' && (
            <>
              <Form.Item label="Begin Date" name="begin_date">
                <DatePicker format="MM/DD/YYYY" className="w-full" />
              </Form.Item>
              <Form.Item label="End Date" name="end_date">
                <DatePicker format="MM/DD/YYYY" className="w-full" />
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </Modal>
  )
}
