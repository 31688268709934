import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { Tooltip } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { AddEquipmentsModal } from '../../add-equipments-modal'
import { equipmentAtom } from '../../atoms'
import { DetailedEquipment } from '../../schema'

export const AddComponentsModal = () => {
  const [isComponentSkuOnly, setIsComponentSkuOnly] = useState(true)
  const setEquipment = useSetAtom(equipmentAtom)
  const equipment = useAtomValue(equipmentAtom)
  const { notification } = useApp()

  const handleAdd = async (equipments: DetailedEquipment[]) => {
    const alreadyAddedComponents = equipments.filter((item) => {
      return equipment.components?.some((component) => component.sub_component?.id === item.id)
    })

    if (alreadyAddedComponents.length > 0) {
      notification.error({
        message: `Some of the selected components are already added. SKUs: ${alreadyAddedComponents
          .map((item) => item.sku)
          .join(', ')}`
      })
      return
    }

    const components = equipments.map((component) => {
      const data = CATEGORY_KIND_FIELDS.reduce(
        (acc, field) => {
          acc[field] = component[field]
          return acc
        },
        {
          sequence: 0
        } as Record<string, any>
      )

      return {
        id: nanoid(),
        is_new: true,
        sub_component: component,
        finished_good: null,
        data,
        data_json: JSON.stringify(data)
      }
    })

    const componentsOrder = JSON.parse(equipment.components_order_json ?? '[]') as number[]

    setEquipment((prev) => ({
      ...prev,
      components: [...(prev.components ?? []), ...(components as any[])],
      components_order_json: JSON.stringify([...componentsOrder, ...equipments.map((item) => item.id)])
    }))

    setIsComponentSkuOnly(true)
  }

  return (
    <AddEquipmentsModal
      onAdd={handleAdd}
      modalTitle="Add Components"
      triggerProps={{
        iconName: 'mi:add'
      }}
      extraOnSearchRight={
        <Tooltip title="Component SKUs only">
          <Button
            iconName="mi:filter_alt"
            type={isComponentSkuOnly ? 'primary' : 'default'}
            onClick={() => setIsComponentSkuOnly((prev) => !prev)}
          />
        </Tooltip>
      }
    />
  )
}
