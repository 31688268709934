import { Button } from '@/ui/button'
import { Alert, Form, Input, Switch } from 'antd'

import { CodeEditor } from '@/components/code-editor'
import { useApp, useSession } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { companyApi } from '@/services/api-service'
import { SectionTitle } from '@/ui/section-title'
import { yamlParseSafe, yamlStringifySafe } from '@/utils/yaml'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'

const defaultYaml = `
es-mx:
  "Example Message": "Ejemplo de mensaje"
`

const notes = (
  <span>
    You can use <i>'[context].Message'</i> format for <b>Message ID</b> to avoid collisions.
    <br />
    <br></br>
    Available context variables:
    <br />- property
    <br />- property_group
    <br />- sku
    <br />
    <br />
    <br />
    Use <i>`Message.__comment`</i> as <b>Message ID</b> to leave a relevant comment if applicable
  </span>
)

const parseLocales = (locales: string) => {
  return Object.entries((yamlParseSafe(locales) || { 'es-mx': {} })['es-mx']).map(([key, value]) => ({ key, value }))
}

const stringifyLocales = (spanish: Array<{ key: string; value: string }>): string => {
  return (
    yamlStringifySafe({
      'es-mx': spanish
        .filter((obj) => obj.key)
        .reduce((result, current) => ({ ...result, [current.key]: current.value }), {})
    }) || ''
  )
}

export const CompanyLocalesView = view<any, any>(Component, {
  title: () => 'Locales'
})

function Component() {
  const { notification } = useApp()
  const { company } = useSession()
  const companyRetrieve = useQuery(
    companyApi.get<{ id: number; locales: string }>(company.id, undefined, { fields: 'id,locales' })
  )
  const [form] = Form.useForm<{ locales: string; translationsList: Array<{ key: string; value: string }> }>()
  const updateCompanyMutation = useMutation({ ...companyApi.patch() })
  const [useYamlEditor, setUseYamlEditor] = useState(false)

  const handleSave = async () => {
    const formValues = await form.validateFields()
    let locales = formValues.locales
    if (!useYamlEditor) {
      locales = stringifyLocales(formValues['translationsList']) || ''
    }

    await updateCompanyMutation.mutateAsync({
      id: company.id,
      locales
    })
    notification.success({ message: 'Company locales are updated successfully' })
  }

  const switchEditor = async (useYaml: boolean) => {
    setUseYamlEditor(useYaml)
    const formValues = await form.validateFields()
    if (useYaml) {
      form.setFieldValue('locales', stringifyLocales(formValues['translationsList']))
    } else {
      form.setFieldValue('translationsList', parseLocales(formValues['locales']))
    }
  }

  return (
    <PageView
      header={{
        title: 'Locales',
        border: false,
        accent: false,
        actions: [
          <Button
            key={'save-locales-button'}
            type="success"
            iconName="mi:save"
            loading={updateCompanyMutation.isLoading}
            onClick={handleSave}
          >
            Save Locales
          </Button>
        ]
      }}
      loading={companyRetrieve.isLoading}
    >
      <SectionTitle>Spanish translations</SectionTitle>
      <div className="mt-12 mb-16 flex flex-row items-center gap-10">
        <span>Use YAML Editor</span>
        <Switch onChange={(e) => switchEditor(e)} />
      </div>
      {!companyRetrieve.isLoading && (
        <Form
          form={form}
          layout="vertical"
          className="mt-16"
          initialValues={{
            translationsList: parseLocales(companyRetrieve.data?.locales || defaultYaml),
            locales: companyRetrieve.data?.locales || defaultYaml
          }}
        >
          {useYamlEditor ? (
            <Form.Item label="Spanish translations" name="locales" validateTrigger="onSubmit">
              <CodeEditor minLines={10} maxLines={Infinity} mode="yaml" />
            </Form.Item>
          ) : (
            <div>
              <Form.List name="translationsList">
                {(fields, { remove, add }) => (
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.key} className="flex flex-row items-center gap-10">
                        <Form.Item name={[field.name, 'key']} label="Message ID">
                          <Input placeholder="Message ID" />
                        </Form.Item>
                        <Form.Item name={[field.name, 'value']} label="Translation">
                          <Input placeholder="Translation" />
                        </Form.Item>
                        <Button
                          onClick={() => remove(field.name)}
                          size="small"
                          danger
                          icon={<DeleteOutlined />}
                        ></Button>
                      </div>
                    ))}
                    <Button type="primary" htmlType="button" size="small" onClick={add} icon={<PlusOutlined />}>
                      Add
                    </Button>
                  </div>
                )}
              </Form.List>
            </div>
          )}
          <Form.Item>
            <Alert className="mt-30" message={notes} closable={true} type="info" showIcon />
          </Form.Item>
        </Form>
      )}
    </PageView>
  )
}
