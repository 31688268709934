import { useApp } from '@/hooks'
import { ticketApi } from '@/services/api-service'
import { Button, Popconfirm } from '@/ui'
import { parseCsv } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { useButtonContext } from '../../../hooks'

export const DispatchButton: FC = () => {
  const { ticket, property, getValueById, buttonProps, saveTicket, vars } = useButtonContext()
  const { dispatch_status, contact_ids_pid, clear_property_ids } = vars

  const { notification } = useApp()
  const contactsMutation = useMutation(ticketApi.setContacts())

  const handleDispatch = async () => {
    const contactIds = parseCsv(getValueById(contact_ids_pid))
      .map((id) => Number(id))
      .filter((id) => id > 0)
    if (!isEmpty(contactIds)) {
      try {
        await contactsMutation.mutateAsync({ id: ticket.id, contact_ids: contactIds })
      } catch (error) {
        notification.warning({
          message: 'Skipped Contact Assignment',
          description: 'Failed to assign contacts to ticket'
        })
      }
    }

    const payload: Record<string, any> = { status: dispatch_status || ticket.status }
    clear_property_ids?.forEach((id: number) => {
      payload[`p_${id}`] = ''
    })
    await saveTicket(payload)

    notification.success({
      message: 'Ticket Dispatched',
      description: 'Ticket has been dispatched successfully'
    })
  }

  return (
    <Popconfirm title={'Dispatching Ticket'} description={'Are you sure?'} onConfirm={handleDispatch}>
      <Button {...buttonProps}>{buttonProps.children ? buttonProps.children : property.name}</Button>
    </Popconfirm>
  )
}
