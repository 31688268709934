import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { lineItemFieldsAtom } from '../../atoms'
import { ContainerKey, LineItemField } from '../../types'

export const useFindContainer = () => {
  const lineItemFields = useAtomValue(useMemo(() => selectAtom(lineItemFieldsAtom, (c) => c), []))

  const findContainer = (id: ContainerKey | LineItemField): ContainerKey | undefined => {
    if (id in lineItemFields) {
      return id as keyof typeof lineItemFields
    }

    return Object.keys(lineItemFields).find((key) =>
      lineItemFields[key as ContainerKey].includes(id as LineItemField)
    ) as ContainerKey
  }

  return { findContainer }
}
