export const breakdownMeasurement = (value: number, showQuarter?: boolean) => {
  const feet = value ? Math.floor(value / 12) : 0
  const inches = showQuarter ? Math.floor(value % 12) : value % 12
  const quarter = showQuarter ? Math.floor((value % 1) * 4) : 0

  return {
    feet,
    inches,
    quarter,
    display: getMeasurementDisplay(feet, inches, quarter)
  }
}

export const getMeasurementDisplay = (feet: number, inches: number, quarter: number) => {
  return `${feet}' ${inches}"${quarter ? ` ${quarter}/4"` : ''}`
}

export const getMeasurementValue = (feet: number, inches: number, quarter: number) => {
  return (feet * 12 + inches + quarter / 4).toString()
}
