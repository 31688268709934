import { z } from 'zod'

export const customEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  email_template_id: z.number(),
  email_template__name: z.string().nullish(),
  data: z
    .object({
      print_styles: z.array(z.string()).nullish(),
      attach_ticket: z.boolean().nullish(),
      reply_to_name: z.string().nullish(),
      reply_to_email: z.string().nullish(),
      _local_field: z.undefined()
    })
    .nullish(),
  custom_form_id: z.number()
})

export type CustomEmail = z.infer<typeof customEmailSchema>
