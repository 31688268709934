import { API_OPERATORS, DYNAMIC_DATES, TICKET_STATUS } from '@/constants/general'
import { Button } from '@/ui'
import { ProgrammedReport } from '../../schema'

type Props = {
  pr: ProgrammedReport
  setEditingCriteria: (criteria: any) => void
  setEditingAttribute: (attribute: any) => void
  setEditingReport: (report: any) => void
}

export const CriteriaColumnProgrammedReport = ({
  pr,
  setEditingAttribute,
  setEditingCriteria,
  setEditingReport
}: Props) => {
  return (
    <div>
      {(pr.type === 'E' ? pr.base_custom_report?.filterfield_set || [] : pr.report?.filterfield_set)?.map(
        (ff, index) => {
          if (pr.base_report?.editable_fields?.indexOf(ff.field_verbose) === -1) {
            return null
          }

          let content

          switch (ff.filter_type) {
            case 'range':
              content = (
                <span>
                  {!DYNAMIC_DATES[ff.filter_value as keyof typeof DYNAMIC_DATES] ? (
                    <span>
                      {ff.filter_value} to {ff.filter_value2}
                    </span>
                  ) : (
                    <span>
                      {{ ld: 1, pm: 1 }[ff.filter_value as string] ? (
                        <span>
                          Past {ff.filter_value2} {ff.filter_value === 'ld' ? 'Days' : 'Months'}
                        </span>
                      ) : (
                        <span>{DYNAMIC_DATES[ff.filter_value as keyof typeof DYNAMIC_DATES] || ff.filter_value}</span>
                      )}
                    </span>
                  )}
                </span>
              )
              break
            default:
              content = (
                <span>
                  {ff.field_verbose === 'computed_status' ? (
                    <span>
                      {(ff.filter_value || '')
                        .split(',')
                        .map((k: any) => TICKET_STATUS[k as keyof typeof TICKET_STATUS] || k)
                        .join(',\n')}
                    </span>
                  ) : (
                    <span>{ff.filter_value}</span>
                  )}
                </span>
              )
          }

          return (
            <div key={index} className="flex items-center gap-x-6">
              <span>{ff._name}</span>
              <strong>
                {ff.exclude && <span>Not</span>}
                <span>{API_OPERATORS[ff.filter_type as keyof typeof API_OPERATORS] || ff.filter_type}</span>
              </strong>
              <span>{content}</span>
              <Button
                type="text"
                iconName="fa:edit"
                className="ml-4"
                onClick={() => {
                  setEditingCriteria(ff)
                  setEditingReport(pr)
                }}
              />
            </div>
          )
        }
      )}

      {pr.attributes?.map((attr, index) => {
        if (!attr.editable) {
          return null
        }

        return (
          <div key={index} className="flex items-center">
            <div>
              {attr.name} {attr.values.join(', ')}
            </div>
            <Button
              type="text"
              iconName="svg:edit"
              onClick={() => {
                setEditingAttribute(attr)
                setEditingReport(pr)
              }}
              className="ml-8"
            />
          </div>
        )
      })}
    </div>
  )
}
