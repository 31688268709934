import { TLabels } from '@/hooks/use-app'
import { EntityColumn } from '@/types/entity-column'

const _getTicketColumnLabel = (column: Partial<EntityColumn>, labels: TLabels) => {
  if (column.field_verbose === 'computed_status') return column.name || ''
  return labels[`ticket__${column.field_verbose}__label`] || column.name || ''
}

export const getColumnLabel = (column: Partial<EntityColumn>, labels: TLabels) => {
  return column.category === 'lineItem'
    ? labels[`lineitem__${column.field_verbose}`] || column.name || ''
    : _getTicketColumnLabel(column, labels)
}
