import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { RecentActivities } from '@/components/recent-activities'
import { eventApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

export const RecentActivitiesSection = () => {
  const { id } = useParams<{ id: string }>()

  const query = { target: id, type: 'equipment', limit: 10 }
  const eventsQuery = useQuery({
    ...eventApi.list(query),
    enabled: !isEmpty(id)
  })

  if (!id) return null

  return (
    <div className="mt-40">
      <h5 className="font-bold">Recent Activity</h5>
      <ActivityLogsModal query={eventApi.list} queryVariables={query} />
      <div className="mt-16">
        <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
      </div>
    </div>
  )
}
