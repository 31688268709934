import { Button } from '@/ui'
import { getCronFrequency } from '@/utils/get-cron-frequency'
import { Form, Select, Space, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

export const SetReportFrequencySection = () => {
  const form = Form.useFormInstance()
  const cronExpression = (Form.useWatch('cron_spec', form) || '0 0 * * *') as string

  const cronFrequency: 'month' | 'week' | 'day' | null = useMemo(() => {
    return getCronFrequency(cronExpression)
  }, [cronExpression])

  const cronDaysOfMonth = useMemo(() => {
    const fields = cronExpression.split(' ')
    const dayOfMonth = fields[2]
    return dayOfMonth.split(',').map((day) => parseInt(day))
  }, [cronExpression])

  const handleDayOfWeekClick = (day: number) => {
    const fields = cronExpression.split(' ')
    const daysOfWeek = fields[4].split(',')
    const index = daysOfWeek.indexOf(day.toString())

    // select at least one day
    if (index !== -1 && daysOfWeek.length === 1) {
      return
    }

    if (index === -1) {
      daysOfWeek.push(day.toString())
    } else {
      daysOfWeek.splice(index, 1)
    }

    form.setFieldsValue({
      cron_spec: `0 0 * * ${daysOfWeek.join(',')}`
    })
  }

  const handleTimeChange = (value: dayjs.Dayjs | null) => {
    if (!value) {
      return
    }

    const fields = cronExpression.split(' ')
    fields[0] = value?.minute().toString() || '0'
    fields[1] = value?.hour().toString() || '0'

    form.setFieldsValue({
      cron_spec: fields.join(' ')
    })
  }

  const handleFrequencyChange = (value: 'month' | 'week' | 'day') => {
    if (value === 'day') {
      form.setFieldsValue({
        cron_spec: '0 0 * * *'
      })
    } else if (value === 'week') {
      form.setFieldsValue({
        cron_spec: '0 0 * * 1'
      })
    } else if (value === 'month') {
      form.setFieldsValue({
        cron_spec: '0 0 1 * *'
      })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item label="Repeat every">
        <Select
          value={cronFrequency}
          options={[
            {
              label: 'Day',
              value: 'day'
            },
            {
              label: 'Week',
              value: 'week'
            },
            {
              label: 'Month',
              value: 'month'
            }
          ]}
          onChange={handleFrequencyChange}
        />
      </Form.Item>
      {cronFrequency === 'week' && (
        <Form.Item label="Repeat on">
          <Space>
            {Array.from({ length: 7 }, (_, i) => i + 1).map((day) => (
              <Button
                shape="circle"
                key={day}
                value={day}
                type={cronExpression.split(' ')[4].split(',').includes(day.toString()) ? 'primary' : 'default'}
                onClick={() => handleDayOfWeekClick(day)}
              >
                {dayjs().day(day).format('dd')}
              </Button>
            ))}
          </Space>
        </Form.Item>
      )}
      {cronFrequency === 'month' && (
        <Form.Item label="Monthly on day">
          <Select<number[]>
            value={cronDaysOfMonth}
            mode="multiple"
            options={Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
              label: dayjs('01-01-2024').date(day).format('Do'), // 01-01-2024 for always rendering 31 days in options
              value: day
            }))}
            onChange={(value) => {
              // select at least one day
              if (value.length === 0) {
                return
              }

              form.setFieldsValue({
                cron_spec: `0 0 ${value.join(',')} * *`
              })
            }}
          />
        </Form.Item>
      )}
      <Form.Item label="At Hour / Minute">
        <TimePicker
          format="hh:mm A"
          showNow={false}
          value={dayjs()
            .hour(parseInt(cronExpression.split(' ')[1]))
            .minute(parseInt(cronExpression.split(' ')[0]))}
          onChange={handleTimeChange}
        />
      </Form.Item>
    </Form>
  )
}
