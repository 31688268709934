import cn from 'classnames'
import React from 'react'
import CancelIcon from './icon-cancel.svg'
import EditIcon from './icon-edit.svg'
import { FontAwesomeIcon } from './icon-fa-types'
import FlipHorizontalIcon from './icon-flip-horizontal.svg'
import FlipVerticalIcon from './icon-flip-vertical.svg'
import LoadingDotsIcon from './icon-loading-dots.svg'
import LoadingRingIcon from './icon-loading-ring.svg'
import LoadingSpinnerIcon from './icon-loading-spinner.svg'
import SaveIcon from './icon-save.svg'
import TrashIcon from './icon-trash.svg'
import { BlankIcon, MaterialIcon } from './icon-types'
import UndoIcon from './icon-undo.svg'
import UserIcon from './icon-user.svg'

const icons = {
  undo: <UndoIcon />,
  edit: <EditIcon />,
  cancel: <CancelIcon />,
  save: <SaveIcon />,
  user: <UserIcon />,
  trash: <TrashIcon />,
  'loading-dots': <LoadingDotsIcon />,
  'loading-spinner': <LoadingSpinnerIcon />,
  'loading-ring': <LoadingRingIcon />,
  'flip-horizontal': <FlipHorizontalIcon />,
  'flip-vertical': <FlipVerticalIcon />
  // ... add more svg icons here
}

const iconsMap = new Map(Object.entries(icons))

type SvgIcon = keyof typeof icons

export type IconName =
  | `svg:${SvgIcon}`
  | `mi:${MaterialIcon}`
  | `url:${string}`
  | `fa:${FontAwesomeIcon}`
  | `fa-solid:${FontAwesomeIcon}`
  | `fa-duotone:${FontAwesomeIcon}`
  | `fa-brands:${FontAwesomeIcon}`
  | BlankIcon

type Props = {
  name: IconName
  className?: string
}

const splitReg = /:(.*)/s

/**
 * This component is used to render icons in the application.
 * It supports the Material Icons font, SVG, and Image Url.
 *
 * SVG Icons: `@/ui/icons`
 *
 * @see https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Icons
 * @see https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Symbols
 *
 * @param name
 * @param className
 */
export const Icon: React.FC<Props> = ({ name, className }) => {
  const [type, code]: string[] = name.split(splitReg)

  if (type === 'svg') return <span className={cn('flex', className)}>{iconsMap.get(code)}</span>
  else if (type === 'fa') return <i className={cn(`flex scale-110 fa-regular fa-${code}`, className)} />
  else if (type.startsWith('fa-')) return <i className={cn(`flex scale-110 ${type} fa-${code}`, className)} />
  else if (type === 'mi') return <span className={cn('flex material-symbols-rounded', className)}>{code}</span>
  else if (type === 'url')
    return <img className={cn('flex', className)} height="24px" width="24px" src={code} alt="icon" />
  else return <svg className={cn('flex', className)} height="24px" width="24px"></svg>
}
