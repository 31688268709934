import { Checkbox } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'

type Props = {
  formItemProps?: FormItemProps
}

export const HideDefaultNoneView: FC<Props> = ({ formItemProps }) => {
  return (
    <Form.Item {...formItemProps} label=" " name={['data', 'hide_default_none_view']} valuePropName="checked">
      <Checkbox>Hide "Default" View</Checkbox>
    </Form.Item>
  )
}
