import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

const options = [
  {
    label: 'Show line items summation footer',
    key: 'show_li_summation'
  },
  {
    label: 'Make line items header sticky',
    key: 'sticky_li_header'
  },
  {
    label: 'Make line items summation sticky',
    key: 'sticky_li_summation'
  },
  {
    label: 'Refresh customer specific pricing (Usage Rate) of line items when customer office changes',
    key: 'sync_customer_pricing'
  }
]

export const LineItem = () => {
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }

  return (
    <div className="border-r border-border">
      <h5 className="font-bold">Line Item Settings</h5>
      {options.map(({ label, key }) => (
        <div key={key} className="mb-12">
          <Checkbox
            data-cy={`custom-form-${key}-checkbox`}
            checked={!!customForm[key as keyof typeof customForm]}
            onChange={(e) => handleChange(e, key)}
          >
            {label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
