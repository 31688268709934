import { UseSortableArguments, useSortable as useDndSortable } from '@dnd-kit/sortable'
import { CSSProperties } from 'react'
import { UseSortable } from './types'

export const useSortable = (props: UseSortableArguments): UseSortable => {
  const sortable = useDndSortable(props)

  // prevent scaling on drag
  const transform = sortable.transform ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)` : ''

  const style: CSSProperties = {
    transform: transform,
    transition: sortable.transition || '',
    zIndex: sortable.isDragging ? 1000 : 'auto', // Ensure the dragged element is above others
    position: sortable.isDragging ? 'relative' : 'static'
  }

  return {
    ...sortable,
    style: style
  }
}
