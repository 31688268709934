import { DATE_TIME_FORMAT } from '@/constants/date'
import { useApp } from '@/hooks'
import { profileApi } from '@/services/api-service'
import { Button } from '@/ui'
import { cn } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const SMSOptInOut = () => {
  const { id: editingProfileId } = useParams()
  const profileQuery = useQuery(profileApi.get<any>(Number(editingProfileId)))
  const profile = profileQuery.data
  const optInStatus = profile?.sms_optin?.status
  const statusDate = profile?.sms_optin?.date
  const optInStatusMutation = useMutation(profileApi.create({}, `${editingProfileId}/init_sms_optin`))
  const { notification } = useApp()

  const handleOptinStatus = async () => {
    try {
      const res = await optInStatusMutation.mutateAsync({})
      notification.success({ message: res.message })
      profileQuery.refetch()
    } catch (error: any) {
      notification.error({ message: error.response.data.message })
    }
  }

  useEffect(() => {
    if (optInStatus !== 'Sent-Opt-In') {
      return
    }

    const interval = setInterval(() => {
      if (optInStatus === 'Sent-Opt-In') {
        profileQuery.refetch()
      } else {
        clearInterval(interval)
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [optInStatus])

  return (
    <div className="mb-16">
      <div className="mb-8">
        {
          <Button type="primary" iconName="fa:sms" loading={optInStatusMutation.isLoading} onClick={handleOptinStatus}>
            {optInStatus === 'Opted-In' ? 'Send Opt-Out SMS' : 'Send Opt-In SMS'}
          </Button>
        }
      </div>
      <div className="flex gap-x-8">
        <div>Status:</div>
        <div
          className={cn({
            'text-green-500': optInStatus === 'Opted-In',
            'text-red-500': optInStatus === 'Opted-Out',
            'text-orange-500': optInStatus === 'Sent-Opt-In'
          })}
        >
          {optInStatus || 'Not Opted In'}
        </div>
      </div>
      {statusDate && (
        <Space className="flex gap-x-8">
          <div>Date:</div>
          <div>{dayjs(statusDate).format(DATE_TIME_FORMAT)}</div>
        </Space>
      )}
    </div>
  )
}
