import { useApp } from '@/hooks'
import { queryClient } from '@/query'
import { ApiResource } from '@/types/api/core'
import { ButtonProps } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { FC } from 'react'
import { ActionButton } from './ActionButton'

type Props = Omit<ButtonProps, 'id'> & {
  id: string | number
  api: ApiResource['delete']
  name?: string
  onSuccess?: () => void
}
export const QueryDeleteButton: FC<Props> = ({ api, id, name, onSuccess, ...buttonProps }) => {
  name = name || 'Record'

  const { notification } = useApp()
  const options = api<any, { id: number }>()
  const deleteMutation = useMutation({
    ...options,
    onSuccess: async () => {
      const key = (options.mutationKey || [])[0]
      if (key) queryClient.refetchQueries({ queryKey: [key] })

      onSuccess?.()

      notification.success({
        message: `${name} Deleted`,
        description: (
          <span>
            {name} <b>#{id}</b> deleted successfully
          </span>
        )
      })
    }
  })

  const handleDelete = async () => {
    await deleteMutation.mutateAsync({ id: Number(id) })
  }

  return (
    <ActionButton
      danger={true}
      iconName={'fa:trash'}
      data-cy={'delete-button'}
      confirm={{
        title: 'Confirm Delete',
        description: `Deleting ${name} #${id}, are you sure?`,
        onConfirm: handleDelete,
        placement: 'topRight',
        okText: 'Delete',
        okButtonProps: { danger: true }
      }}
      {...buttonProps}
    />
  )
}
