import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const Hide = () => {
  const { lineItemField } = useSortableItemContext()

  const setConfiguration = useSetAtom(configurationAtom)

  const hiddenFields = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.hidden_fields ?? []), [])
  )

  const isChecked = useMemo(() => hiddenFields?.includes(lineItemField), [hiddenFields, lineItemField])

  const handleChange = (checked: boolean) => {
    if (checked) {
      setConfiguration((configuration) => ({
        ...configuration,
        hidden_fields: [...hiddenFields, lineItemField]
      }))
    } else {
      setConfiguration((configuration) => ({
        ...configuration,
        hidden_fields: hiddenFields.filter((field) => field !== lineItemField)
      }))
    }
  }

  return (
    <Space>
      <Switch checked={isChecked} onChange={handleChange} data-cy="hide-switch" />
      <span>Hide</span>
    </Space>
  )
}
