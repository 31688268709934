import { ScriptedButtonConfig } from '../../types'
import { MsNavCheckButton } from './MsNavCheckButton'

export const MsNavCheckButtonConfig: ScriptedButtonConfig = {
  type: 'common_ms_nav_check_button',
  iconName: 'fa:refresh',
  label: 'MS Nav (Check)',
  group: 'Common',
  render: () => <MsNavCheckButton />,
  vars: []
}
