import { Form } from 'antd'
import { BillSection } from './bill-section'
import { CallbackSection } from './callback-section'
import { ConnectSection } from './connect-section'
import { CustomerSection } from './customer-section'
import { InvoiceHeaderSection } from './invoice-header-section'
import { InvoiceLineSection } from './invoice-line-section'
import { QBOInvoiceSection } from './invoice-section'
import { LineItemsSection } from './line-items-section'
import { ReferenceFields } from './reference-fields'
import { TrackPaymentStatusSection } from './track-payment-status-section'

export const ConfiguredTab = () => {
  return (
    <div>
      <div className="grid grid-cols-4 gap-x-24">
        <Form.Item hidden name="id" />
        <Form.Item hidden name="doc" />
        <CustomerSection />
        <LineItemsSection />
        <CallbackSection />
        <TrackPaymentStatusSection />
      </div>
      <ConnectSection />
      <div className="grid grid-cols-8 gap-x-24">
        <div className="col-span-6">
          <BillSection />
          <QBOInvoiceSection />
          <InvoiceHeaderSection />
          <InvoiceLineSection />
        </div>
        <div className="col-span-2">
          <ReferenceFields />
        </div>
      </div>
    </div>
  )
}
