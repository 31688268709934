import { uniq } from 'lodash'
import { Property } from '../../types'

export const processPropertyFields = (properties: Property[], customForms: number[], mergeProperties?: boolean) => {
  const applicableProperties = properties.filter(
    (p) => p.property_group__custom_form__id && customForms.includes(p.property_group__custom_form__id)
  )

  const propertiesByKey = properties.reduce(
    (tbl, p) => ((tbl[p.key] = [...(tbl[p.key] || []), p]), tbl),
    {} as Record<string, Property[]>
  )

  const formidsByPropertykey = Object.entries(propertiesByKey).reduce((tbl, [key, arr]) => {
    const uniqueForms = uniq(arr.map((p) => p.property_group__custom_form__id).filter((x) => x))
    tbl[key] = uniqueForms
    return tbl
  }, {} as any)

  const propertiesFields = applicableProperties.map((p) => ({
    property_id: p.id,
    path: 'ticket__property__',
    field: mergeProperties && (formidsByPropertykey[p.key] || 0).length > 1 ? `k=${p.key}` : p.id,
    merge_fields_count: (formidsByPropertykey[p.key] || 0).length,
    path_verbose: 'ticket_property',
    field_verbose: 'property',
    extra: '',
    type: p.property_type__name,
    name: `${p.property_group__custom_form__name || ''}, ${p.name || ''}`,
    category: p.property_group__custom_form__name || ''
  }))

  return propertiesFields
}
