import { APP_MODE, APP_RELEASE, INTERCOM_APP_ID } from '@/constants/app'
import { AuthUser } from '@/types/auth-user'
import { Intercom, boot as bootIntercom, shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk'
import { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types'
import { fetchIntercomUser } from './helpers'

export const intercom = {
  appID: INTERCOM_APP_ID,
  appRelease: APP_RELEASE,
  currentId: null as number | null,
  enabled() {
    return Boolean(this.appID)
  },
  getBaseSettings(): IntercomSettings {
    return {
      app_id: this.appID,
      react_version: this.appRelease,
      app_env: APP_MODE,
      custom_launcher_selector: '#intercom-launcher',
      hide_default_launcher: true,
      alignment: 'right'
    }
  },
  init() {
    if (!this.enabled()) return false // disabled

    try {
      Intercom(this.getBaseSettings())
      return true
    } catch (err) {
      console.error('Intercom: Failed to initialize', err)
      return false
    }
  },
  async identify(user: AuthUser | null) {
    if (!this.enabled() || this.currentId === (user?.id || null)) return false // disabled or already identified

    try {
      const intercomUser = user ? await fetchIntercomUser() : shutdownIntercom()

      bootIntercom({ ...this.getBaseSettings(), ...(intercomUser || {}) })

      this.currentId = user?.id || null
      return true
    } catch (err) {
      console.error('Intercom: Failed to identify user', err)
      return false
    }
  }
}
