import { PropertySelect as DefaultPropertySelect, PropertySelectProps } from '@/components'
import { useAtomValue } from 'jotai'
import { FC } from 'react'
import { customFormAtom } from '../../atoms'

export const PropertySelect: FC<Omit<PropertySelectProps, 'formId'>> = (props) => {
  const customForm = useAtomValue(customFormAtom)

  return <DefaultPropertySelect formId={customForm?.id} {...props} />
}
