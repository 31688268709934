import { view } from '@/routing'
import { Spin } from 'antd'
import { useCustomFormQuery } from '../../hooks'
import { Admin } from './admin'
import { Configuration } from './configuration'
import { LineItem } from './line-item'
import { MobileApp } from './mobile-app'
import { RequiredFieldsOnMobile } from './required-fields-on-mobile'
import { ThirdPartAndCustomerAccess } from './third-party-and-customer-access'
import { TicketDisplay } from './ticket-display'
import { TicketRules } from './ticket-rules'

export const Settings = view(Component, {
  title: () => 'Settings',
  scoped: false
})

function Component() {
  const { customFormQuery } = useCustomFormQuery()

  return (
    <Spin spinning={customFormQuery.isLoading}>
      <div className="grid grid-cols-3 gap-x-30 gap-y-16">
        <TicketDisplay />
        <ThirdPartAndCustomerAccess />
        <div className="col-span-3 border-b border-border"></div>
        <MobileApp />
        <RequiredFieldsOnMobile />
        <TicketRules />
        <div className="col-span-3 border-b border-border"></div>
        <LineItem />
        <Admin />
        <Configuration />
      </div>
    </Spin>
  )
}
