import { AddEditContactView } from '../../../add-edit-contact-view'

const EditContactView = ({ id }: { id: string }) => {
  return (
    <div className="px-16">
      <AddEditContactView id={id} />
    </div>
  )
}

export { EditContactView }
