import { useSession } from '@/hooks'
import { categoryTagApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useQuery } from '@tanstack/react-query'
import { Divider, Modal } from 'antd'
import { useState } from 'react'
import { ManageTagItem } from './manage-tag-item'
import { TagSchema } from './schemas'

export const ManageTags = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { company } = useSession()

  const tagsQuery = useQuery(
    categoryTagApi.list({ company_id__exact: company.id, fields: TagSchema, limit: 'None' as any })
  )

  const toggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      <Button iconName="fa:list" type="primary" onClick={toggleModal}></Button>
      <Modal open={isOpen} title="Manage Tag" onCancel={toggleModal} footer={null}>
        {tagsQuery.data?.items.map((tag) => (
          <ManageTagItem key={tag.id} tag={tag} />
        ))}
        <Divider>Add Tag</Divider>
        <ManageTagItem tag={{ id: 0, name: '', company: company.id }} />
      </Modal>
    </>
  )
}
