import { TICKET_SUBSCRIBER_STATUS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { contactApi, ticketSubscriberApi } from '@/services/api-service'
import { Button, Icon, QuerySelect, Select, Tooltip } from '@/ui'
import { Spin } from '@/ui/spin'
import { cn } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Space, Tag } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { refreshQueries } from '../../../helpers'
import { useTicket, useTicketFlags } from '../../../hooks'
import { Contact, ContactSchema, TicketSubscriber } from '../../../schemas'
import { Card } from '../../ui'

export const TabSubscribers = () => {
  const { company } = useSession()
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()
  const { notification } = useApp()
  const addSubscriberMutation = useMutation(ticketSubscriberApi.create())
  const removeSubscriberMutation = useMutation(ticketSubscriberApi.delete())
  const [showContactSelect, setShowContactSelect] = useState(false)
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('')
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>([])
  const [removingSubscriberId, setRemovingSubscriberId] = useState<number | null>(null)

  const subscribersIds = useMemo(
    () => ticket.subscribers.map((subscriber) => subscriber.contact__id),
    [ticket.subscribers]
  )

  const subscribersMap = useMemo(
    () =>
      ticket.subscribers.reduce(
        (prev, current) => {
          return { ...prev, [current.status]: [...prev[current.status as Status], current] }
        },
        {
          I: [],
          A: [],
          R: [],
          D: []
        } as Record<Status, TicketSubscriber[]>
      ),
    [ticket.subscribers]
  )

  const closeContactSelect = useCallback(() => {
    setShowContactSelect(false)
    setSelectedContactIds([])
  }, [])

  const handleAddSubscribers = useCallback(async () => {
    closeContactSelect()

    if (selectedContactIds.length === 0) {
      return
    }

    try {
      const promises = selectedContactIds.map((id) =>
        addSubscriberMutation.mutateAsync({ ticket_id: ticket.id, contact_id: id })
      )

      await Promise.all(promises)
      await refreshQueries()
      notification.success({ message: 'Subscribe Invitation Sent' })
    } catch (error) {
      notification.error({
        message: 'Failed to send invitation',
        description: 'An error occurred while sending invitation'
      })
    }
  }, [addSubscriberMutation, selectedContactIds, ticket.id, closeContactSelect, notification])

  const handleRemoveSubscriber = useCallback(
    async (id: number) => {
      try {
        await removeSubscriberMutation.mutateAsync({ id })
        await refreshQueries()
        notification.success({ message: 'Subscriber Removed' })
        setRemovingSubscriberId(null)
      } catch {
        notification.error({
          message: 'Failed to remove subscriber',
          description: 'An error occurred while removing subscriber'
        })
      }
    },
    [notification, removeSubscriberMutation]
  )

  return (
    <div>
      <div className="flex items-center justify-between">
        {showContactSelect ? (
          <div className="min-w-[300px] flex items-center gap-x-16">
            <QuerySelect
              className="grow"
              mode="multiple"
              apiEndpoint={contactApi.list}
              apiQueryParams={{
                fields: ContactSchema,
                company_id__eq: company.id,
                profile__user__is_active: 1,
                E: subscribersIds.length ? [`id__in|${subscribersIds?.join(',')}`] : [],
                order: 'first_name'
              }}
              apiSearchBy={searchBy}
              renderOption={formatOption}
              onChange={setSelectedContactIds}
            />
            <Space>
              <Button size="small" shape="circle" iconName="fa:check" onClick={handleAddSubscribers} type="primary" />
              <Button size="small" shape="circle" iconName="fa:close" onClick={closeContactSelect} />
            </Space>
          </div>
        ) : (
          <Button
            shape="round"
            iconName="fa:user-plus"
            loading={addSubscriberMutation.isLoading}
            onClick={() => setShowContactSelect(true)}
            disabled={viewOnly}
            primary
          >
            Add Subscriber
          </Button>
        )}
        <Space>
          <span>Status</span>
          <Select
            value={selectedFilterStatus}
            className="min-w-[200px]"
            allowClear={false}
            showSearch={false}
            onChange={setSelectedFilterStatus}
            options={[
              { label: 'All', value: '' },
              ...Object.entries(TICKET_SUBSCRIBER_STATUS).map(([value, label]) => ({
                label,
                value
              }))
            ]}
          />
        </Space>
      </div>
      {Object.entries(subscribersMap).map(([status, subscribers]) => (
        <div
          key={status}
          className={cn('mt-20', {
            hidden: selectedFilterStatus && status !== selectedFilterStatus
          })}
        >
          <h5>{TICKET_SUBSCRIBER_STATUS[status as Status]}</h5>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-16">
            {subscribers.map(
              ({
                id,
                status,
                contact__company__id,
                contact__company__name,
                contact__first_name,
                contact__last_name,
                modified_at,
                notes
              }) => (
                <Spin key={id} spinning={removeSubscriberMutation.isLoading && id === removingSubscriberId}>
                  <Card
                    icon={'fa:user-alt'}
                    shape={'circle'}
                    title={`${contact__first_name} ${contact__last_name}`}
                    description={
                      <div className="flex flex-col">
                        {contact__company__id !== company.id && (
                          <small className="mb-2">
                            @ <span>{contact__company__name}</span>
                          </small>
                        )}
                        <Space className="mt-2">
                          <Tag
                            className="rounded-full mr-0"
                            color={status === 'R' ? 'red' : status === 'A' ? 'green' : 'blue'}
                          >
                            {TICKET_SUBSCRIBER_STATUS[status]}
                          </Tag>
                          <Tooltip
                            title={
                              <div>
                                <div>{dayjs.formatLocal(modified_at)}</div>
                                <div>{notes}</div>
                              </div>
                            }
                            placement="right"
                          >
                            <Icon name="fa:info-circle" className="text-14 text-primary-500" />
                          </Tooltip>
                        </Space>
                      </div>
                    }
                    actions={
                      <Button
                        size="small"
                        shape="circle"
                        type="text"
                        iconName="fa:close"
                        danger
                        disabled={viewOnly}
                        onClick={() => {
                          setRemovingSubscriberId(id)
                          handleRemoveSubscriber(id)
                        }}
                      />
                    }
                  />
                </Spin>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const formatOption = (item: Contact) => ({
  value: item.id,
  label: (
    <span>
      {item.first_name} {item.last_name} <span className={'font-medium'}>({item.profile__user__username})</span>
    </span>
  )
})

const searchBy = (text?: string) => ({
  'Q[]': isEmpty(text)
    ? {}
    : [
        `first_name__icontains|${text}`,
        `last_name__icontains|${text}`,
        'or',
        `profile__user__username__icontains|${text}`,
        'or'
      ]
})

type Status = TicketSubscriber['status']
