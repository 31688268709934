import { FC } from 'react'
import { useJobRoles, useSelectedJobTicket } from '../../hooks'
import { JobRequirement } from './JobRequirement'

type Props = {
  type?: 'employee' | 'equipment'
}

export const JobRequirementsSection: FC<Props> = ({ type }) => {
  const { selectedJobData: jobData } = useSelectedJobTicket()
  const { jobRolesMap } = useJobRoles()

  if (!jobData) return null

  const { resourcesByRole } = jobData
  const { employees, equipments } = jobData.resources

  return (
    <>
      {resourcesByRole ? (
        resourcesByRole?.map((resData) =>
          !type || (resData?.id && type === (jobRolesMap[resData?.id].resource_type || '').toLowerCase()) ? (
            <JobRequirement key={resData?.id || resData.name} label={resData.name} data={resData} />
          ) : null
        )
      ) : (
        <>
          {(!type || type === 'employee') && <JobRequirement label="Employees" data={employees} />}
          {(!type || type === 'equipment') && <JobRequirement label="Equipment" data={equipments} />}
        </>
      )}
    </>
  )
}
