import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { eventApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const RecentActivitySection = () => {
  const { id } = useParams()
  const eventsQuery = useQuery({ ...eventApi.list({ target: id, type: 'scheduled_report' }), enabled: !!id })

  if (!id) return null

  return (
    <div>
      <h5 className="font-bold">Recent Activity</h5>
      <ActivityLogsModal query={eventApi.list} queryVariables={{ target: id, type: 'scheduled_report' }} />
      <div className="mt-16">
        <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isInitialLoading} />
      </div>
    </div>
  )
}
