import { request } from '@/services/api-service/core'
import { ObjectReturn, RequestConfig } from '@/types/api/core'

export const _wikiApi: {
  access: (config?: RequestConfig) => ObjectReturn<{ id: string; signature: string; exp: number; url: string }>
} = {
  access: (config) => ({
    queryKey: ['wikis', 'access'],
    queryFn: ({ signal }) => request({ ...config, method: 'GET', url: '/wikis/access/', signal: signal })
  })
}
