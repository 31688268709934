import { API_OPERATORS } from '@/constants/general'
import { useApp } from '@/hooks'
import { AddEditReportCriteriaModal } from '@/modules/reports/components/add-edit-report-criteria-modal'
import { getFilterFieldName } from '@/modules/reports/components/utils'
import { EntityColumn } from '@/types/entity-column'
import { Filterfield } from '@/types/filterfield'
import { Button } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { renderCriteria } from '@/utils/render-criteria'
import { Form, FormListFieldData, Space, Table } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SetOptional } from 'type-fest'

export const FilterYourDataSection = () => {
  const { id: reportId } = useParams()
  const form = Form.useFormInstance()
  const filterfields = (Form.useWatch('filterfield_set', form) ?? []) as Filterfield[]
  const options = Form.useWatch('options', form) ?? {}
  const fieldAliasesJson = options.field_aliases_json || '{}'
  const [editingRecord, setEditingRecord] = useState<Record | null>(null)
  const isLineItemReport = Form.useWatch('is_line_item_report', form) || false
  const { labels } = useApp()

  const handleAddFilter = async (values: Filterfield, selectedFilterfield: EntityColumn) => {
    const { options, exclude, filter_value, filter_type, filter_value2, path, path_verbose, id } = values

    const data = {
      options,
      isFieldAvailableForUpdate: false,
      exclude,
      filter_type,
      report: Number(reportId),
      filter_value: filter_value,
      filter_value2: filter_value2,
      path,
      path_verbose
    }

    const filterfieldFromFormKey = filterfields[editingRecord?.name as number] || undefined

    // If new filter field, add a new one
    if (!filterfieldFromFormKey) {
      form.setFieldValue('filterfield_set', [...filterfields, { ...selectedFilterfield, ...data }])
      setEditingRecord(null)
      return
    }

    form.setFieldValue(
      'filterfield_set',
      filterfields.map((filterfield, index) => {
        if (index === editingRecord?.name) {
          return {
            ...filterfield,
            ...selectedFilterfield,
            ...data
          }
        }

        return filterfield
      })
    )

    setEditingRecord(null)
  }

  return (
    <div>
      <SectionTitle rounded number={3}>
        Filter your data
      </SectionTitle>
      <div className="mt-16">
        <Form.List name="filterfield_set">
          {(fields, { remove }) => (
            <>
              <Table
                pagination={false}
                dataSource={fields.map((field) => ({
                  ...field,
                  ff: filterfields[field.name] ?? {}
                }))}
              >
                <Table.Column
                  title="Filter By"
                  render={(_, { ff }: any) => <div>{getFilterFieldName(ff, fieldAliasesJson, labels)}</div>}
                />
                <Table.Column
                  title="Relationship"
                  render={(_, { ff }: Record) => (
                    <div>
                      {ff.exclude && <span>Not </span>}
                      <span>{API_OPERATORS[ff.filter_type as keyof typeof API_OPERATORS]}</span>
                    </div>
                  )}
                />
                <Table.Column title="Criteria" render={(_, { ff }: Record) => renderCriteria(ff)} />
                <Table.Column
                  align="right"
                  width={80}
                  render={(_, record: Record) => (
                    <Space>
                      <Button iconName="fa:edit" type="text" onClick={() => setEditingRecord(record)} />
                      <Button iconName="fa:trash" type="text" onClick={() => remove(record.name)} />
                    </Space>
                  )}
                />
              </Table>
              <Button
                iconName="fa:add"
                type="primary"
                className="mt-12"
                onClick={() =>
                  setEditingRecord({
                    ff: {
                      options: {
                        editable: true,
                        enabled: true
                      }
                    },
                    key: filterfields.length + 1,
                    name: filterfields.length + 1
                  })
                }
              >
                Add Filter
              </Button>
              <Form.Item name="config" hidden noStyle />
              {editingRecord && (
                <AddEditReportCriteriaModal
                  filterfield={editingRecord.ff}
                  onSave={handleAddFilter}
                  onClose={() => setEditingRecord(null)}
                  rootModelName={isLineItemReport ? 'line item' : 'ticket'}
                />
              )}
            </>
          )}
        </Form.List>
      </div>
    </div>
  )
}

type Record = FormListFieldData & { ff: SetOptional<Filterfield, 'id'> }
