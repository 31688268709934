import { useReverseOfficeRelations } from '@/hooks'
import { useMemo } from 'react'

type OfficeRelation = {
  id: number
  to_office_id: number
  preferred: boolean
  manager: boolean
}

export const useReverseRelations = () => {
  const { relations } = useReverseOfficeRelations<OfficeRelation>({
    fields: 'id,to_office_id,preferred,manager'
  })
  const relationsMap: Record<number, OfficeRelation> = useMemo(() => {
    return relations.reduce((acc: any, relation) => {
      acc[relation.to_office_id] = relation
      return acc
    }, {})
  }, [relations])

  return {
    relations,
    relationsMap
  }
}
