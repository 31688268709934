import { useFormWatch } from '@/hooks'
import { useTicket, useTicketFlags } from '@/modules/ticket/form/hooks'
import { Button } from '@/ui'
import cn from 'classnames'
import { FC, useCallback } from 'react'
import { useInlineItemEdit } from '../../../../hooks'
import { ItemColumnType, LineItem } from '../../../../schemas'
import { ItemField } from '../../../item-form/item-editor/item-field'

type Props = {
  item: Partial<LineItem>
  column: ItemColumnType
  index: number
}

export const ItemEditable: FC<Props> = ({ item, column, index }) => {
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()
  const { FORM_KEY, isEditingField, handleEdit, handleCancelEdit, saveItems, saveMutation } = useInlineItemEdit()
  const itemFormValue = useFormWatch([FORM_KEY, item.id as number]) as Partial<LineItem> // watch it for re-render
  const isColumnEditable = !viewOnly && column.config && column.config.editable

  const handleSave = useCallback(
    async (item: Partial<LineItem>, key?: string) => {
      if (!key) return

      await saveItems(ticket.id, [{ id: item.id, [key]: itemFormValue?.[key as keyof typeof item] }])
      handleCancelEdit(item, key)
    },
    [handleCancelEdit, itemFormValue, saveItems, ticket.id]
  )

  return (
    <td
      key={column.key}
      className={cn('group relative', column.className, {
        'rounded outline-dashed outline-1 outline-transparent hover:outline-border':
          isColumnEditable && !isEditingField(item, column.config?.field || '')
      })}
      onDoubleClick={() => isColumnEditable && handleEdit(item, column.config?.field)}
    >
      <div className={isEditingField(item, column.config?.field || '') ? '!h-0 !opacity-0' : ''}>
        {column?.render?.(item, item, index) as any}
      </div>

      {isColumnEditable && (
        <div>
          {isEditingField(item, column?.config?.field) ? (
            <div className={'flex items-center gap-4'}>
              <ItemField
                label={''}
                className={'!m-0 w-full !min-w-[150px]'}
                name={[FORM_KEY, item.id?.toString(), column?.config?.field]}
                fieldConfig={column.config}
                inputProps={{ rows: 1 }}
              />
              {!itemFormValue?._hide_inline_actions && (
                <div className={'-m-4 flex flex-col items-center'}>
                  <Button
                    loading={saveMutation.isLoading}
                    className={'scale-[0.7]'}
                    primary
                    shape={'circle'}
                    size={'small'}
                    iconName={'fa:check'}
                    onClick={() => handleSave(item, column.config?.field)}
                  />
                  <Button
                    disabled={saveMutation.isLoading}
                    className={'scale-[0.7]'}
                    shape={'circle'}
                    size={'small'}
                    iconName={'fa:close'}
                    onClick={() => handleCancelEdit(item, column.config?.field)}
                  />
                </div>
              )}
            </div>
          ) : (
            <Button
              className={'absolute right-0 top-0 z-50 scale-[0.7] opacity-0 transition-none group-hover:opacity-100'}
              type={'default'}
              shape={'circle'}
              size={'small'}
              iconName={'fa:edit'}
              onClick={() => handleEdit(item, column.config?.field)}
            />
          )}
        </div>
      )}
    </td>
  )
}
