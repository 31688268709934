import { customFormApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { customFormSchema } from '../schema'

type CustomForm = z.input<typeof customFormSchema>

type Payload = Omit<
  CustomForm,
  | 'id'
  | 'default_customer_contact'
  | 'default_customer_office'
  | '_contact_search'
  | '_existing_lines_only'
  | '_sku_search'
  | 'default_customer_contact_id'
  | 'default_team_members'
> & {
  default_customer_contact: number | null
  default_customer_office: number | null
  default_team_members: number[]
  id?: number
}

export const useUpsertCustomForm = () => {
  const { id } = useParams<{ id: string }>()

  const createCustomFormMutation = useMutation({
    ...customFormApi.create<CustomForm, Payload>()
  })

  const updateCustomFormMutation = useMutation({
    ...customFormApi.update<CustomForm, Payload>({}, undefined, undefined, { show_all: true })
  })

  const upsertCustomForm = async (payload: Payload) => {
    if (id) {
      return updateCustomFormMutation.mutateAsync({
        ...payload,
        id: Number(id)
      })
    } else {
      return createCustomFormMutation.mutateAsync({
        ...payload
      })
    }
  }

  return {
    createCustomFormMutation,
    updateCustomFormMutation,
    upsertCustomForm
  }
}
