import { TIME } from '@/constants/date'
import { Button } from '@/ui'
import { DatePicker, Select, Space } from 'antd'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { periodRangeAtom } from '../../atoms'

export const PeriodSelect = () => {
  const [selectedTime, setSelectedTime] = useState<keyof typeof TIME>(
    (localStorage.getItem('selectedTimeInMyTime') as keyof typeof TIME) || 'W'
  )

  const [periodRange, setPeriodRange] = useAtom(periodRangeAtom)
  const [date, setDate] = useState(dayjs())

  const handlePeriodBackAndForwardChange = (direction: 1 | -1) => {
    if (selectedTime === 'W') {
      setDate(date.add(direction, 'week'))
    } else if (selectedTime === 'M') {
      setDate(date.add(direction, 'month'))
    } else if (selectedTime === 'Q') {
      setDate(date.add(direction, 'quarter'))
    } else if (selectedTime === 'Y') {
      setDate(date.add(direction, 'year'))
    } else if (selectedTime === 'B' || selectedTime === 'T') {
      setDate(date.add(direction, 'week'))
    }
  }

  useEffect(() => {
    if (selectedTime === 'W') {
      setPeriodRange([date.startOf('week'), date.endOf('week')])
    } else if (selectedTime === 'M') {
      setPeriodRange([date.startOf('month'), date.endOf('month')])
    } else if (selectedTime === 'Q') {
      setPeriodRange([date.startOf('quarter'), date.endOf('quarter')])
    } else if (selectedTime === 'Y') {
      setPeriodRange([date.startOf('year'), date.endOf('year')])
      // workweek
    } else if (selectedTime === 'B') {
      setPeriodRange([date.startOf('week').add(1, 'day'), date.endOf('week').subtract(1, 'day')])
    } else {
      setPeriodRange(null)
    }
  }, [selectedTime, date])

  useEffect(() => {
    localStorage.setItem('selectedTimeInMyTime', selectedTime)
  }, [selectedTime])

  return (
    <Space>
      <Select
        value={selectedTime}
        className="min-w-[200px]"
        popupMatchSelectWidth={false}
        onChange={setSelectedTime}
        options={Object.entries(TIME).map(([key, value]) => ({
          label: value,
          value: key
        }))}
      />
      {['M', 'W', 'Q', 'Y', 'B'].includes(selectedTime) && (
        <Space>
          <Button type="primary" iconName="fa:chevron-left" onClick={() => handlePeriodBackAndForwardChange(-1)} />
          <Button type="primary" iconName="fa:chevron-right" onClick={() => handlePeriodBackAndForwardChange(1)} />
          {periodRange && periodRange !== 'notSet' && (
            <div>
              {TIME[selectedTime]}: {periodRange[0].format('MM/DD/YYYY')} - {periodRange[1].format('MM/DD/YYYY')}
            </div>
          )}
        </Space>
      )}
      {selectedTime === 'C' && (
        <DatePicker.RangePicker
          onChange={(dates) => {
            if (!dates || !dates?.[0] || !dates[1]) {
              console.error('Invalid date range')
              return
            }

            setPeriodRange([dates[0], dates[1]])
          }}
        />
      )}
    </Space>
  )
}
