import { Spin } from 'antd'
import { FC } from 'react'

export type PageViewLoadingProps = {
  active?: boolean
}

export const PageViewLoading: FC<PageViewLoadingProps> = ({ active }) => {
  if (!active) return null

  return (
    <div className={'absolute z-99999 top-0 left-0 w-full h-full flex items-center justify-center'}>
      <Spin spinning />
    </div>
  )
}
