import { Equipment } from '@/schemas/equipment'
import { EquipmentStats } from '@/types/equipment-stats'
import { saveBlob } from '@/utils/blob'
import dayjs from 'dayjs'
import { getEquipmentStats } from './get-equipment-stats'

export const downloadReport = (equipmentStats: EquipmentStats[], equipments: Equipment[]) => {
  // csv header
  let csvContent = 'SKU ID,SKU,Description,Unit,Date,Quantity to Date\r\n'

  equipments.forEach((equipment) => {
    const stats = getEquipmentStats(equipment.id, equipmentStats)

    const timepoint = stats?.timepoint ? dayjs(stats.timepoint).format('M/D/YYYY') : undefined
    const balance = stats?.balance || undefined

    csvContent += `${equipment.id},${equipment.sku},${equipment.description},${equipment.unit || ''},${timepoint},${balance}\r\n`
  })

  const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const csvFileName = `inventory-${dayjs().format('YYYY-MM-DD')}.csv`

  return saveBlob(csvBlob, csvFileName)
}
