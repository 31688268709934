import { useTicket } from '../../../hooks'
import { AccountingButton } from './accounting-button'
import { SecureAccess } from './secure-access'

export const SectionButtons = () => {
  const { isNew } = useTicket()

  if (isNew) return null

  return (
    <div className={'flex flex-row gap-10 mb-10'}>
      {/* TODO: action buttons */}
      <AccountingButton />
      <SecureAccess type={'S'} checkAccess />
      <SecureAccess type={'V'} checkAccess />
    </div>
  )
}
