import { Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const CustomNavigationColumn = () => {
  const tabPermissions =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.tab_permissions), [])) ?? {}

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)
  const isTabEnabled = (name: keyof typeof tabPermissions) => !!tabPermissions[name]

  const handleChange = (name: keyof typeof tabPermissions, checked: boolean) => {
    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        tab_permissions: {
          ...prev?.data?.tab_permissions,
          [name]: checked
        }
      }
    }))
  }

  return (
    <div>
      {accountRoles.map((role) => (
        <div key={role.name} className="flex w-full justify-between mb-20">
          <div>{role.label}</div>
          <Switch checked={isTabEnabled(role.name)} onChange={(checked) => handleChange(role.name, checked)} />
        </div>
      ))}
    </div>
  )
}

const accountRoles = [
  {
    name: 'work',
    label: 'Jobs'
  },
  {
    name: 'loads',
    label: 'Load Board'
  },
  {
    name: 'the_field',
    label: 'Records'
  },
  {
    name: 'tickets',
    label: 'Tickets'
  },
  {
    name: 'lines',
    label: 'Line Items'
  },
  {
    name: 'reporting',
    label: 'Reporting'
  },
  {
    name: 'contacts',
    label: 'Contacts'
  },
  {
    name: 'inventory',
    label: 'Inventory'
  }
] as const
