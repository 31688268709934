import { Button, Popconfirm, Progress } from '@/ui'
import { cn } from '@/utils'
import { FC, useState } from 'react'
import { JobResultModal } from './JobResultModal'
import { useBackgroundJobsHook } from './hooks'
import { BackgroundJobState } from './types'

export const StatusBar: FC = () => {
  const { backgroundJobs, removeTrackingBackgroundJob, isJobInProgress, isJobFinished, haltJobMutation } =
    useBackgroundJobsHook()
  const [isPopconfirmOpen, setPopconfirmOpen] = useState(false)

  const getStyles = (bgJob: BackgroundJobState): string => {
    if (isJobInProgress(bgJob)) return 'bg-blue-100'

    if (bgJob.status === 'F') return 'bg-green-200'

    if (bgJob.status === 'H' || bgJob.status === 'R') return 'bg-warning-200'

    return 'bg-red-100'
  }

  const getMessage = (bgJob: BackgroundJobState): string => {
    if (isJobInProgress(bgJob)) return 'is in progress'

    if (bgJob.status === 'F') return 'is finished'
    if (bgJob.status === 'R') return 'stoping job ...'
    if (bgJob.status === 'H') return 'stoped'

    return 'failed'
  }

  if (backgroundJobs.length === 0) return null

  return (
    <div className={'w-full p-6 -mb-2'}>
      <div className="w-full flex flex-col gap-2 overflow-auto max-h-[110px]">
        {backgroundJobs.map((bgJob) => (
          <div
            key={bgJob.id}
            className={cn('h-[25px] flex flex-row items-center justify-between px-10 gap-10 rounded', getStyles(bgJob))}
          >
            <div className="flex flex-row items-center gap-10">
              <small className="p-0 m-0">
                Job <b>{bgJob.name}</b> {getMessage(bgJob)}
              </small>
              {isJobInProgress(bgJob) && (
                <>
                  <div className="w-[100px]">
                    <Progress percent={bgJob.progress * 100} size="small" status="active" showInfo={false} />
                  </div>
                </>
              )}
              <small>{bgJob.total_count > 0 ? `${bgJob.handled_count}/${bgJob.total_count}` : ''}</small>
              {isJobInProgress(bgJob) && (
                <>
                  <Popconfirm
                    title={'Are you sure you want to stop this job?'}
                    placement="rightBottom"
                    open={isPopconfirmOpen}
                    onCancel={() => setPopconfirmOpen(false)}
                    className={'h-[18px] text-sm'}
                    onConfirm={() => {
                      haltJobMutation.mutateAsync({ id: bgJob.id })
                      setPopconfirmOpen(false)
                    }}
                    okButtonProps={{ loading: haltJobMutation.isLoading }}
                  >
                    <Button
                      size="small"
                      shape="round"
                      className={'h-[18px] text-sm'}
                      iconName="fa:stop"
                      onClick={() => setPopconfirmOpen(true)}
                    >
                      Stop
                    </Button>
                  </Popconfirm>
                </>
              )}
              {isJobFinished(bgJob) && <JobResultModal jobId={bgJob.id} />}
            </div>
            <Button
              iconName="fa:close"
              type="text"
              size="small"
              onClick={() => removeTrackingBackgroundJob(bgJob.id)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
