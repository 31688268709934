import { url } from '@/routing/helpers'
import { Icon } from '@/ui/icons'
import { Tag } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { NavTabItem } from '../types'

export type Props = NavTabItem

export const NavTab: FC<NavTabItem> = ({ iconName, label, badge, to, orientation = 'horizontal' }) => {
  const vertical = orientation === 'vertical'

  return (
    <NavLink to={url(to)}>
      {({ isActive }) => (
        <div
          className={cn('relative group text-neutral', {
            '!text-primary': isActive,
            'pb-6': !vertical,
            'pr-8': vertical
          })}
        >
          {isActive && (
            <div
              className={cn('absolute bg-primary rounded', {
                'w-[calc(95%)] h-[2px] left-1/2 bottom-0 transform -translate-x-1/2': !vertical,
                'w-[3px] h-[calc(95%)] right-0 top-1/2 transform -translate-y-1/2': vertical
              })}
            ></div>
          )}
          <div
            className={cn(
              'rounded px-8 py-4 transition-all duration-200',
              'group-hover:bg-gray-100 group-active:bg-gray-200',
              'group-hover:dark:bg-gray-700',
              {
                'py-6 line-clamp-1': vertical,
                'bg-gray-100/80 dark:bg-primary-900/20': vertical && isActive
              }
            )}
          >
            <div className={'flex flex-row items-center gap-10'}>
              {iconName && <Icon name={iconName} />}
              <div className={cn('flex-grow text-nowrap', { 'overflow-hidden overflow-ellipsis': vertical })}>
                {label}
              </div>
              {badge && (
                <Tag bordered={false} className={'rounded-lg'}>
                  {badge}
                </Tag>
              )}
            </div>
          </div>
        </div>
      )}
    </NavLink>
  )
}
