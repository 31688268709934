import { useApp } from '@/hooks'
import { TipButton, TipButtonProps } from '@/ui'
import { dig } from '@/utils'
import { FC } from 'react'
import { UseTicketSaveProps, useNextTicketName, useTicketFormWatch, useTicketSave } from '../../../hooks'

type Props = TipButtonProps & {
  onSave?: () => void
  options?: UseTicketSaveProps
}

export const SaveButton: FC<Props> = ({ onSave, options, ...props }) => {
  const ticketName = useTicketFormWatch('name')
  const { notification } = useApp()
  const { saveTicket, isSaving } = useTicketSave(options)
  const { onDuplicateError } = useNextTicketName()

  const handleSave = async () => {
    try {
      await saveTicket({
        forceSave: true,
        message: 'Ticket Saved',
        description: 'Ticket has been successfully saved'
      })
      onSave?.()
    } catch (error) {
      let errorMsg: string | undefined =
        dig(error, 'response.status') === 400 ? dig(error, 'response.data.0') : undefined
      if (errorMsg === 'name_is_not_unique') {
        errorMsg = `Ticket #${ticketName} is already in use`
        onDuplicateError()
      }
      notification.error({ message: 'Save Failed', description: errorMsg || 'Failed to save ticket' })
    }
  }

  return <TipButton loading={isSaving} onClick={handleSave} tooltip={{ placement: 'bottom' }} {...props} />
}
