import {
  getJasResourcesInfiniteQueryKey,
  useAddPatchMutation,
  useApp,
  useAppSelector,
  useGetJasResourcesInfiniteQuery
} from '@/hooks'
import { calendarViewAtom, queryLimitAtom } from '@/modules/jas/scheduler/atoms'
import { getEndDateByView, getStartDateByView } from '@/modules/jas/scheduler/helpers'
import { ListParams } from '@/types/api/core'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { ScheduleType } from '@/utils'
import { InfiniteData, UseInfiniteQueryOptions, useQueryClient } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import { useAtomValue } from 'jotai'
import { ImmerReducer, useImmerReducer } from 'use-immer'

type MultipleScheduleViewState = {
  date: Dayjs
  searchText: string
  queryOrder: 'name' | '-name'
  queryPage: number
  selectedDistricts: string[]
  selectedSkills: string[]
}

const initialState: MultipleScheduleViewState = {
  date: dayjs(),
  searchText: '',
  queryOrder: 'name',
  queryPage: 1,
  selectedDistricts: [],
  selectedSkills: []
}

type SetSelectedDistrictsAction = {
  type: 'setSelectedDistricts'
  payload: string[]
}

type ToggleQueryOrderAction = {
  type: 'toggleQueryOrder'
}

type SetSelectedSkillsAction = {
  type: 'setSelectedSkills'
  payload: string[]
}

type SetSearchTextAction = {
  type: 'setSearchText'
  payload: string
}

type SetQueryPageAction = {
  type: 'setQueryPage'
  payload: number
}

type SetDateAction = {
  type: 'setDate'
  payload: Dayjs
}

type MultipleScheduleViewAction =
  | SetSelectedDistrictsAction
  | ToggleQueryOrderAction
  | SetSelectedSkillsAction
  | SetSearchTextAction
  | SetQueryPageAction
  | SetDateAction

const reducer: ImmerReducer<MultipleScheduleViewState, MultipleScheduleViewAction> = (draftState, action) => {
  switch (action.type) {
    case 'setSelectedDistricts':
      draftState.selectedDistricts = action.payload
      break
    case 'toggleQueryOrder':
      draftState.queryOrder = draftState.queryOrder === 'name' ? '-name' : 'name'
      break
    case 'setSelectedSkills':
      draftState.selectedSkills = action.payload
      break
    case 'setSearchText':
      draftState.searchText = action.payload
      break
    case 'setQueryPage':
      draftState.queryPage = action.payload
      break
    case 'setDate':
      draftState.date = action.payload
      break
    default:
      throw new Error('Invalid action type', action)
  }
}

type QueryVariables = ListParams

export const useMultipleScheduleView = (
  variables: QueryVariables = {},
  options?: UseInfiniteQueryOptions<JasResourceUseQueryResponse[], unknown>
) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState)
  const { company } = useAppSelector((state) => state.session)
  const { notification } = useApp()
  const queryClient = useQueryClient()
  const { date, queryOrder, queryPage, searchText, selectedDistricts, selectedSkills } = state
  const calendarView = useAtomValue(calendarViewAtom)
  const queryLimit = useAtomValue(queryLimitAtom)

  const queryVariables = {
    for_calendar: true,
    limit: queryLimit,
    page: queryPage,
    order: queryOrder,
    status__iexact: 'A', // active only
    attributes__has_keys: selectedSkills.join(','),
    district__in: selectedDistricts.join(','),
    name__icontains: searchText.trim(),
    start_date: getStartDateByView(calendarView, date).format('YYYY-MM-DD'),
    end_date: getEndDateByView(calendarView, date).format('YYYY-MM-DD'),
    ...variables
  }

  const query = useGetJasResourcesInfiniteQuery(queryVariables, options)

  const addPatchMutation = useAddPatchMutation({
    // Optimistic update
    onMutate: async (payload) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getJasResourcesInfiniteQueryKey(queryVariables))

      // Snapshot the previous value
      const previousQueryData = queryClient.getQueryData<InfiniteData<JasResourceUseQueryResponse[]>>(
        getJasResourcesInfiniteQueryKey(queryVariables)
      )

      const { resource, start_date, data: payloadData } = payload

      if (previousQueryData && previousQueryData.pages.length) {
        queryClient.setQueryData<InfiniteData<JasResourceUseQueryResponse[]>>(
          getJasResourcesInfiniteQueryKey(queryVariables),
          {
            ...previousQueryData,
            pages: previousQueryData.pages.map((page) => {
              return page.map((data) => {
                if (data.id === resource) {
                  const schedules = data.schedules

                  return {
                    ...data,
                    schedules: {
                      ...schedules,
                      [start_date]: {
                        data: payloadData as any, // Typing as any during optimistic update. Valid data will be passed after mutation,
                        type: payloadData.type
                      }
                    }
                  }
                }

                return data
              })
            })
          }
        )
      }

      return { previousQueryData }
    },
    onError: (_, __, context: any) => {
      notification.error({
        message: 'Error',
        description: 'Something went wrong'
      })
      queryClient.setQueryData(getJasResourcesInfiniteQueryKey(queryVariables), context.previousQueryData)
    },
    onSettled: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(getJasResourcesInfiniteQueryKey(queryVariables))
    }
  })

  const handlePatchMutation = async (data: JasResourceUseQueryResponse, dates: Dayjs[], type: ScheduleType) => {
    const promises = dates.map((date) => {
      const schedule = data.schedules[date.format('YYYY-MM-DD')]

      // if schedule already exists and type is the same, do nothing
      if (schedule.data && schedule.type === type) {
        return
      }

      // if schedule already exists and type is different and it is not based on a rule, then update
      // TODO: maybe find better way to check if schedule is based on a rule
      if (schedule.data && schedule.type !== type && 'id' in schedule.data && !('schedule' in schedule.data)) {
        return addPatchMutation.mutateAsync({
          company: company.id,
          resource: data.id,
          start_date: date.format('YYYY-MM-DD'),
          end_date: date.format('YYYY-MM-DD'),
          data: { type },
          name: `${data.name} patch`,
          id: schedule.data.id
        })
      }

      return addPatchMutation.mutateAsync({
        company: company.id,
        resource: data.id,
        start_date: date.format('YYYY-MM-DD'),
        end_date: date.format('YYYY-MM-DD'),
        data: { type },
        name: `${data.name} patch`
      })
    })

    await Promise.all(promises)
    notification.success({
      message: 'New patch added'
    })
  }

  return {
    state,
    dispatch,
    query,
    handlePatchMutation
  }
}
