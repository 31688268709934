import { useAppTheme } from '@/hooks/use-app-theme'
import { Select } from '@/ui'
import { Form } from 'antd'
import { get } from 'lodash'
import { RESOURCE_STATUS_MAP } from '../../../constants'

export function StatusFormItem() {
  const { token } = useAppTheme()

  return (
    <Form.Item name={['resource', 'status']} label={'Status'} rules={[{ required: true }]}>
      <Select
        allowClear={false}
        options={Object.entries(RESOURCE_STATUS_MAP).map(([k, v]) => ({
          value: k,
          text: v?.label,
          label: (
            <div className={'flex flex-row items-center gap-6'}>
              <div className={'h-[14px] w-[6px] rounded bg-gray'} style={{ backgroundColor: get(token, v?.color) }} />
              <span>{v?.label}</span>
            </div>
          )
        }))}
      />
    </Form.Item>
  )
}
