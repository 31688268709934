import { Select } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  UniqueIdentifier,
  closestCorners,
  defaultDropAnimation,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { Checkbox, Form } from 'antd'
import { useAtomValue } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { DraggableItem, TableViewFormValues } from '../../types'
import { availableFieldsAtom, selectedFieldsAtom } from '../atoms'
import { COLUMN_KEYS } from '../constants'
import { sortFields } from '../utils/sort-fields'
import { DroppableColumn } from './droppable-column'
import { Item } from './item'
import { findBoardSectionContainer } from './utils/find-board-section-container'

export const DragAndDropFieldsSection = () => {
  const form = Form.useFormInstance<TableViewFormValues>()
  const sensors = useSensors(useSensor(MouseSensor))
  const [availableItemsSearchText, setAvailableItemsSearchText] = useState('')
  const [selectedItemsSearchText, setSelectedItemsSearchText] = useState('')
  const [activeId, setActiveId] = useState<string | null>(null)
  const sortWatch = Form.useWatch('sort_1', form)
  const availableFields = useAtomValue(availableFieldsAtom)
  const selectedFields = useAtomValue(selectedFieldsAtom)
  const sort1Watch = Form.useWatch('sort_1', form)
  const sort2Watch = Form.useWatch('sort_2', form)
  const mergeProperties = Form.useWatch('merge_fields', form) as TableViewFormValues['merge_fields']

  const [columns, setColumns] = useState<{
    available: DraggableItem[]
    selected: DraggableItem[]
  }>({ [COLUMN_KEYS.available]: [], [COLUMN_KEYS.selected]: [] })

  const filterAvailableFields = useCallback(
    (available: DraggableItem[], selected: DraggableItem[]) =>
      available.filter(
        (field: DraggableItem) =>
          !selected.find((sf) => sf.id === field.id || (mergeProperties && sf.field === field.field))
      ),
    [mergeProperties]
  )

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id as any)
  }

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    // Find the containers
    const activeContainer = findBoardSectionContainer(columns, active.id as string)
    const overContainer = findBoardSectionContainer(columns, over?.id as string)

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return
    }

    setColumns((columns) => {
      const activeItems = columns[activeContainer as keyof typeof columns]
      const overItems = columns[overContainer as keyof typeof columns]

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === active.id)
      const overIndex = overItems.findIndex((item) => item.id !== over?.id)

      const activeContainerItems = columns[activeContainer as keyof typeof columns]
      const overContainerItems = columns[overContainer as keyof typeof columns]

      const newColumns = {
        ...columns,
        [activeContainer]: [...activeContainerItems.filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...overContainerItems.slice(0, overIndex),
          activeContainerItems[activeIndex],
          ...overContainerItems.slice(overIndex, overContainerItems.length)
        ]
      }
      newColumns.available = filterAvailableFields(availableFields, newColumns.selected).sort(sortFields)
      return newColumns
    })
  }

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeContainer = findBoardSectionContainer(columns, active.id as string)
    const overContainer = findBoardSectionContainer(columns, over?.id as string)

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return
    }

    const activeContainerItems = columns[activeContainer as keyof typeof columns]
    const overContainerItems = columns[overContainer as keyof typeof columns]

    const activeIndex = activeContainerItems.findIndex((task) => task.id === active.id)
    const overIndex = overContainerItems.findIndex((task) => task.id === over?.id)

    if (activeIndex !== overIndex) {
      const items = arrayMove(activeContainerItems, activeIndex, overIndex)

      setColumns((columns) => {
        const newColumns = { ...columns, [overContainer]: items }
        newColumns.available = filterAvailableFields(availableFields, newColumns.selected).sort(sortFields)
        return newColumns
      })
    }

    setActiveId(null)
  }

  const handleSelectedFieldNameChange = (id: UniqueIdentifier, value: string) => {
    setColumns((columns) => ({
      ...columns,
      selected: columns.selected.map((selectedField) => {
        if (selectedField.id === id) {
          return {
            ...selectedField,
            custom_name: value
          }
        }
        return selectedField
      })
    }))
  }
  useEffect(() => {
    setColumns({
      available: filterAvailableFields(availableFields, selectedFields),
      selected: selectedFields
    })
  }, [availableFields, selectedFields, mergeProperties, filterAvailableFields])

  useEffect(() => {
    // Update form values if fields are changed
    form.setFieldsValue({
      selected_fields: columns.selected.map((field) => field)
    })
  }, [columns, form])

  const filteredAvailableItems = columns.available.filter((item) =>
    item.name?.toLowerCase().includes(availableItemsSearchText.toLowerCase().trim())
  )

  const filteredSelectedItems = columns.selected.filter((item) =>
    item.custom_name
      ? item.custom_name.toLowerCase().includes(selectedItemsSearchText.toLocaleLowerCase().trim())
      : item.name.toLowerCase().includes(selectedItemsSearchText.toLowerCase().trim())
  )

  const draggingField = activeId
    ? Object.values(columns)
        .flat()
        .find((field) => field?.id === activeId)
    : null

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Form.Item name="selected_fields" hidden noStyle />
      <div className="pt-24">
        <div className="grid grid-cols-2 gap-x-24 items-end">
          <DroppableColumn
            id={COLUMN_KEYS.available}
            items={filteredAvailableItems}
            title={
              <SectionTitle rounded number={4}>
                Drag fields left to right
              </SectionTitle>
            }
            onSearch={(val) => setAvailableItemsSearchText(val)}
            extra={
              <div>
                <Form.Item name="merge_fields" valuePropName="checked" className="mb-12 mt-12">
                  <Checkbox className="ml-3">Merge Identically Named Fields</Checkbox>
                </Form.Item>
              </div>
            }
          />
          <DroppableColumn
            id={COLUMN_KEYS.selected}
            items={filteredSelectedItems}
            title={
              <SectionTitle rounded number={5}>
                Organize your columns & sorting
              </SectionTitle>
            }
            extra={
              <div className="flex gap-x-16 mt-12">
                <Form.Item name="sort_1" hidden noStyle />
                <Form.Item name="sort_2" hidden noStyle />
                <Form.Item label="Sort By" className="mb-0 grow">
                  <Select
                    popupMatchSelectWidth={false}
                    value={(sortWatch || '').replace('-', '')}
                    options={availableFields.map((field) => ({
                      label: field.name,
                      value: field.id
                    }))}
                    onChange={(val) => {
                      form.setFieldsValue({
                        sort_1: val
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item label="Select" className="mb-0 grow">
                  <Select
                    value={(sort1Watch || '').charAt(0) === '-' ? 'desc' : 'asc'}
                    onChange={(val) => {
                      form.setFieldsValue({
                        sort_1: val === 'asc' ? sort1Watch?.replace('-', '') : `-${sort1Watch}`
                      })
                    }}
                    options={[
                      {
                        label: 'Ascending',
                        value: 'asc'
                      },
                      {
                        label: 'Descending',
                        value: 'desc'
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            }
            onSearch={(val) => setSelectedItemsSearchText(val)}
            onFieldNameChange={handleSelectedFieldNameChange}
          />
          {createPortal(
            <DragOverlay adjustScale={false} dropAnimation={defaultDropAnimation}>
              {activeId && draggingField ? <Item item={draggingField} isDragging /> : null}
            </DragOverlay>,
            document.body
          )}
        </div>
      </div>
    </DndContext>
  )
}
