import { InfoCircleOutlined } from '@ant-design/icons'
import { FC } from 'react'

type FooterCellProps = {
  date: string
  onClick: (date: string) => void
}

export const FooterCell: FC<FooterCellProps> = ({ date, onClick }) => {
  const handleClick = () => {
    onClick(date)
  }

  return (
    <div
      className="w-full h-full flex justify-center items-center cursor-pointer text-[#00000026] hover:text-primary"
      onClick={handleClick}
    >
      <div>
        <InfoCircleOutlined
          style={{
            fontSize: 20,
            transform: 'translateY(2.5px)'
          }}
        />
      </div>
    </div>
  )
}
