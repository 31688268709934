type Props = {
  label: string
  value: React.ReactNode
  icon?: React.ReactNode
}

export const LabelValue = ({ label, value, icon }: Props) => {
  return (
    <div className="flex items-center gap-x-12">
      {icon}
      <div>
        <div className="text-sm">{label}</div>
        <div className="font-semibold">{value}</div>
      </div>
    </div>
  )
}
