import { Button, Icon, Popconfirm, TipButton } from '@/ui'
import { UseSortable } from '@/ui/sortable'
import { cn } from '@/utils'
import { Input } from 'antd'
import { FC } from 'react'
import { useRulesHandlers } from '../../hooks'
import { BpaRule } from '../../schemas'

type Props = {
  rule: BpaRule
  ruleIndex: number
  sortable?: UseSortable
}

export const RuleHeader: FC<Props> = ({ ruleIndex, rule, sortable }) => {
  const { onLabelChange, onCloneRule, onDeleteRule } = useRulesHandlers()

  return (
    <div className="flex flex-row items-center !pl-0">
      <div className={'m-0 mr-2'}>
        <span className="absolute -left-20 top-10 text-14 text-border">
          <Icon name={'fa-solid:horizontal-rule'} className="w-[6px]" />
          <Icon name={'fa-solid:circle-small'} />
        </span>
        <span className="font-medium text-[15px]">{ruleIndex + 1}.</span>
      </div>
      <div className={'flex-grow mr-10'}>
        <Input
          placeholder={'Enter Title'}
          className={cn('border-dashed border-transparent bg-transparent font-medium text-[15px]')}
          defaultValue={rule.label}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onLabelChange(e, ruleIndex)}
          data-cy="rule-label-input"
        />
      </div>
      <div className="flex flex-row gap-4" onClick={(e) => e.stopPropagation()}>
        <TipButton
          type={'text'}
          iconName={'fa:clone'}
          size={'small'}
          title="Clone"
          flashTitle="Cloned"
          onClick={() => onCloneRule(ruleIndex)}
          data-cy="clone-rule-button"
        />
        <Popconfirm
          title="Confirm Delete"
          description={
            <span>
              Deleting rule <b>Rule {ruleIndex + 1}</b>. Are you sure?
            </span>
          }
          onConfirm={() => onDeleteRule(ruleIndex)}
          okButtonProps={{
            'data-cy': 'delete-rule-button-confirm'
          }}
        >
          <Button
            data-cy="delete-rule-button"
            type={'text'}
            icon={<Icon name={'fa:trash'} />}
            size={'small'}
            danger={true}
          />
        </Popconfirm>
        <Button
          {...sortable?.listeners}
          type={'text'}
          iconName={'fa:grip-dots-vertical'}
          size={'small'}
          onClick={(e) => e.stopPropagation()}
          className={'cursor-grab drag-handle'}
          data-cy="drag-rule-button"
        />
      </div>
    </div>
  )
}
