import { LINE_ITEM_FIELDS } from '@/constants/general'
import { Input, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom } from '../../../atoms'
import { useUserDataQuery } from '../../../hooks'
import { Component } from '../../types'

type Props = {
  fieldItem: (typeof LINE_ITEM_FIELDS)[number]
  component: Component
}

// Component for user_text_... fields. With dynamic options fetched from API for Select component.
export const UserTextField = ({ fieldItem, component }: Props) => {
  const setEquipment = useSetAtom(equipmentAtom)
  const userDataQuery = useUserDataQuery()
  const components = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.components), [])) ?? []
  const subComponent = components.find((c) => c.id === component.id)?.sub_component
  const data = components.find((c) => c.id === component.id)?.data
  const value = data?.[fieldItem.field]
  const fieldItemNo = fieldItem.field.split('_')[2] // user_text_1 -> 1
  const stringSourceKey = `string_${fieldItemNo}_source` // string_{number}_source
  const stringSource = subComponent?.[stringSourceKey]

  const userData = userDataQuery.data?.items.find((item) => item.id === stringSource)
  const options = userData ? userData.content.map((item) => ({ label: item, value: item })) : []

  const handleUserTextValueChange = (value: string | string[]) => {
    if (Array.isArray(value)) {
      value = value.join(',')
    }

    const componentIndex = components.findIndex((c) => c.id === component.id)
    const newData = { ...data, [fieldItem.field]: value }
    const newComponents = [...components]
    newComponents[componentIndex] = { ...components[componentIndex], data: newData }
    setEquipment((prev) => ({ ...prev, components: newComponents }))
  }

  if (!userData) {
    return <Input value={value} onChange={(e) => handleUserTextValueChange(e.target.value)} />
  }

  return (
    <Select
      loading={userDataQuery.isLoading}
      options={options}
      value={userData?.multiselect ? value?.split(',') : value}
      onChange={handleUserTextValueChange}
      placeholder="Select"
      mode={userData?.multiselect ? 'multiple' : undefined}
      popupMatchSelectWidth={false}
    />
  )
}
