import { contactApi } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { FC, useCallback, useMemo } from 'react'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiQueryParams' | 'apiSearchBy' | 'renderOption' | 'mapOption'> & {
  extraQueryParams?: ListParams
}
export const ContactSelect: FC<Props> = (props) => {
  const queryParam = useMemo(
    () => ({ fields: 'id,first_name,last_name', order: 'first_name', ...props?.extraQueryParams }),
    []
  )

  const searchBy = useCallback(
    (text?: string) => ({
      'Q[]': text ? [`first_name__icontains|${text}`, `last_name__icontains|${text}`, 'or'] : []
    }),
    []
  )

  const renderOption = useCallback(
    (item: any) => ({
      value: item.id,
      label: item.first_name + ' ' + item.last_name
    }),
    []
  )

  return (
    <QuerySelect
      apiEndpoint={contactApi.list}
      apiQueryParams={queryParam}
      apiSearchBy={searchBy}
      renderOption={renderOption}
      apiValueBy={'id'}
      {...props}
    />
  )
}
