import cn from 'classnames'
import { FC, useRef } from 'react'
import { useNavTabs } from './hooks'
import { MoreDropdown } from './more-dropdown'
import { NavTab } from './nav-tab'
import { NavTabItem } from './types'

export type NavTabsProps = {
  className?: string
  items: NavTabItem[]
  overflowThreshold?: number
  orientation?: 'horizontal' | 'vertical'
}

export const NavTabs: FC<NavTabsProps> = ({ className, items, overflowThreshold, orientation = 'horizontal' }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tabRefs = useRef<(HTMLDivElement | null)[]>([])

  const { moreTabs, scrollToTab } = useNavTabs(items, containerRef, tabRefs, overflowThreshold)

  return (
    <div
      className={cn(
        'flex  items-start',
        {
          'flex-row w-full gap-4': orientation === 'horizontal',
          'flex-col min-w-[150px]': orientation === 'vertical'
        },
        className
      )}
    >
      <div
        ref={containerRef}
        className={cn('w-full flex no-scrollbar gap-8', {
          'flex-row overflow-x-auto': orientation === 'horizontal',
          'flex-col': orientation === 'vertical'
        })}
      >
        {items.map((tab, index) => (
          <div
            key={tab.key || tab.to}
            data-index={index}
            onClick={() => scrollToTab(tab)}
            ref={(el) => (tabRefs.current[index] = el)}
          >
            <NavTab orientation={orientation} {...tab} />
          </div>
        ))}
      </div>

      {orientation === 'horizontal' && <MoreDropdown items={moreTabs} onTabSelect={scrollToTab} />}
    </div>
  )
}
