import { Hide } from './hide'
import { Readonly } from './readonly'
import { ShowOnList } from './show-on-list'

export const MobileSettings = () => {
  return (
    <div className="flex flex-col gap-12">
      <Hide />
      <Readonly />
      <ShowOnList />
    </div>
  )
}
