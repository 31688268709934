export const EMPLOYEES_SCHEDULE_TYPES = {
  all: 'All',
  W: 'Work',
  O: 'Off Day',
  U: 'Unavailable'
} as const

export const EMPLOYEES_SCHEDULE_OPTIONS = Object.entries(EMPLOYEES_SCHEDULE_TYPES).map(([value, label]) => ({
  value,
  label
}))

export const FILTER_LOGIC_OPS = {
  any: 'Any date',
  all: 'All dates'
} as const

export const FILTER_LOGIC_OPTIONS = Object.entries(FILTER_LOGIC_OPS).map(([value, label]) => ({
  value,
  label
}))
