import { useApp } from '@/hooks'
import { equipmentApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useDefaultLiUpdate = () => {
  const { id } = useParams<{ id: string }>()
  const { notification } = useApp()

  const defaultLiUpdateMutation = useMutation({
    ...equipmentApi.create({}, `${id}/default_li_update`),
    onSuccess: () => {
      notification.success({
        message: 'Custom forms default line items updated'
      })
    }
  })

  const updateDefaultLi = async () => {
    await defaultLiUpdateMutation.mutateAsync({ id })
  }

  return {
    updateDefaultLi,
    defaultLiUpdateMutation
  }
}
