import { AppLink, Button } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { Form } from 'antd'

export const StepTwo = () => {
  const fileName = Form.useWatch('file_name')
  const url = Form.useWatch('url')

  return (
    <div className="mt-30">
      <SectionTitle rounded number={2}>
        Download File
      </SectionTitle>
      <div className="flex items-center gap-x-16 mt-16">
        <span>{fileName ?? 'No File Generated'}</span>
        <AppLink to={url}>
          <Button iconName="fa:cloud-download-alt" disabled={!fileName}>
            Download
          </Button>
        </AppLink>
      </div>
    </div>
  )
}
