// important! initialize sentry before anything else
import { sentry } from '@/services/monitoring/sentry'
sentry.init()

// before React and ReactDOM imports
import { scan } from 'react-scan'
if (localStorage.ocReactScan === '1') scan({ enabled: true, dangerouslyForceRunInProduction: true })

// ---
import { App } from '@/App'
import { afterRootRender, beforeRootRender } from '@/helpers'
import { queryClient } from '@/query'
import { store } from '@/store'
import '@/styles/app.scss'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { JOTAI_GLOBAL_STORE } from './constants/app'
import { JotaiProvider } from './contexts'

beforeRootRender()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <JotaiProvider store={JOTAI_GLOBAL_STORE}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </JotaiProvider>
    </ReduxProvider>
  </StrictMode>
)

afterRootRender()
