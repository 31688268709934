import { useAtom } from 'jotai'
import { set } from 'lodash'
import { FC } from 'react'
import { bpaConfigAtom } from '../atoms'
import { BpaActionFunctionCall } from '../schemas'
import { InputFunctionCallMemo } from './actions'

export const ScheduledFunctionForm: FC = () => {
  const [bpaConfig, setBpaConfig] = useAtom(bpaConfigAtom)
  const sfnAction = bpaConfig.schedule_rules?.function_config || {
    function: '',
    kwargs: ''
  }

  const handleChanges = (data: Partial<BpaActionFunctionCall>) => {
    setBpaConfig((value) => {
      set(value, 'schedule_rules.function_config', {
        ...value.schedule_rules?.function_config,
        ...data
      })
    })
  }

  return (
    <div className={'p-10'}>
      {sfnAction && <InputFunctionCallMemo action={sfnAction as BpaActionFunctionCall} onChange={handleChanges} />}
    </div>
  )
}
