import { Select } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionAddTeamMember, BpaActionRemoveTeamMember } from '../../schemas'
import { ContactSelect } from '../controls'

type Props = {
  action: BpaActionAddTeamMember | BpaActionRemoveTeamMember
  onChange: (data: Partial<BpaActionAddTeamMember | BpaActionRemoveTeamMember>) => void
  type: 'add' | 'remove'
}

const getDefaultValue = (action: BpaActionAddTeamMember | BpaActionRemoveTeamMember) => {
  if (action.contacts) return action.contacts
  else if (action.contact) return [Number(action.contact)]
  return []
}

const FILTER_CONTACTS_TYPE: Record<string, 'O' | 'E' | 'A'> = {
  only: 'O',
  except: 'E',
  all: 'A'
}

export const InputAddTeamMember: FC<Props> = ({ action, onChange, type }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  // TODO: reset action config when action type was changed
  useEffect(() => {
    if (
      ['add_team_member', 'add_ticket_subscriber'].includes(action.type) &&
      action.filter_type !== FILTER_CONTACTS_TYPE.only
    ) {
      onChange({ filter_type: FILTER_CONTACTS_TYPE.only })
    }
  }, [action.filter_type, action.type, onChange])

  return (
    <div>
      {type === 'remove' && (
        <Form.Item label={'Remove Type'} className="flex-grow">
          <Select
            showCopyText={false}
            allowClear={false}
            showSearch={false}
            defaultValue={action.filter_type || FILTER_CONTACTS_TYPE.only}
            onChange={(v) => onChange({ filter_type: v })}
            options={[
              { label: 'Only Selected', value: FILTER_CONTACTS_TYPE.only },
              { label: 'Except Selected', value: FILTER_CONTACTS_TYPE.except },
              { label: 'All', value: FILTER_CONTACTS_TYPE.all }
            ]}
          />
        </Form.Item>
      )}
      {action.filter_type !== 'A' && (
        <div className="flex flex-row gap-10">
          {action.asText ? (
            <Form.Item
              label="Contacts (Text)"
              tooltip="Comma separated id or placeholder like P[Key], F[name]"
              className="flex-grow"
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 10 }}
                defaultValue={String(action.contacts || '') || action.contact || ''}
                onChange={(e) => onChangeDebounced({ contacts: e.target.value })}
              />
            </Form.Item>
          ) : (
            <Form.Item label="Contacts (Select)" className="flex-grow">
              <ContactSelect
                mode="multiple"
                defaultValue={getDefaultValue(action)}
                extraQueryParams={{ profile__user__is_active: 1 }}
                onChange={(_, values: any) => {
                  onChange({ contacts: values.map(({ value }: any) => value) })
                }}
              />
            </Form.Item>
          )}

          <Form.Item
            label="As Text"
            tooltip="Using as text will allow using placeholder string in non textual value input"
          >
            <Checkbox
              checked={action.asText}
              onChange={(e) => onChange({ asText: e.target.checked as any, contacts: undefined })}
            />
          </Form.Item>
        </div>
      )}
    </div>
  )
}
export const InputAddTeamMemberMemo = React.memo(InputAddTeamMember)
