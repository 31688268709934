import { Form, Modal, Select } from 'antd'
import { useState } from 'react'

export const MapTypeSelect = () => {
  const form = Form.useFormInstance()
  const data = Form.useWatch('data', form) ?? {}
  const cardType = data.cardType ?? ''
  const [changingCardType, setChangingCardType] = useState<string | null>(null)

  return (
    <>
      <Form.Item
        label="Map Type"
        name={['data', 'cardType']}
        className="w-[200px]"
        getValueFromEvent={() => {
          return cardType // Prevent changing the value. Only allow changing on the modal confirm
        }}
      >
        <Select
          placeholder="Select"
          options={options}
          onChange={(value) => {
            if (value === cardType) {
              return
            }

            setChangingCardType(value)
          }}
        />
      </Form.Item>
      <Modal
        title="Change Card Type"
        open={!!changingCardType}
        onCancel={() => setChangingCardType(null)}
        onOk={() => {
          form.setFieldsValue({ data: { ...data, cardType: changingCardType } })
          setChangingCardType(null)
        }}
      >
        <div>Are you sure? This will reset the current mapping.</div>
      </Modal>
    </>
  )
}

const options = [
  {
    label: 'Deliveries',
    value: 'D'
  },
  {
    label: 'Chemical',
    value: 'C'
  },
  {
    label: 'Listing',
    value: 'L'
  }
]
