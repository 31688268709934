import { ScriptedButtonConfig } from '../../../types'
import { SubmitToAccountingButton } from './SubmitToAccountingButton'

export const IpsmSubmitToAccountingButtonConfig: ScriptedButtonConfig = {
  type: 'ipsm_submit_to_accounting_button',
  iconName: 'fa:money-check-alt',
  label: 'Submit To Accounting (Depreciated)',
  group: 'IPSM',
  render: () => <SubmitToAccountingButton />
}
