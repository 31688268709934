import { DATE_FORMAT, DATE_TIME_FORMAT, ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT } from '@/constants/date'
import { useApp } from '@/hooks'
import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { afeApi, jobCodeApi, locationApi, officeApi } from '@/services/api-service'
import { QuerySelect, Select } from '@/ui'
import { getColumnLabel } from '@/utils/get-column-label'
import { recordBy } from '@/utils/record-by'
import { Checkbox, DatePicker, Form, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import React, { FC, useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { customFormAtom } from '../../atoms'
import { FieldInputComponentName, TICKET_FIELDS } from '../../constants'
import { BpaActionSetTicketField } from '../../schemas'
import { ContactSelect as ContactSelectControl } from '../controls'

type Props = {
  action: BpaActionSetTicketField
  onChange: (data: Partial<BpaActionSetTicketField>) => void
}

export const InputSetTicketField: FC<Props> = (props) => {
  const { l, labels } = useApp()
  const { action, onChange } = props

  const writableFields = useMemo(
    () =>
      TICKET_FIELDS.filter((f) => !!f.input).map((f) => ({
        ...f,
        value: f.value,
        label: getColumnLabel({ category: 'ticket', field_verbose: f.value, name: f.label }, labels)
      })),
    []
  )

  const fieldsByValue = useMemo(() => recordBy(writableFields, 'value'), [writableFields])

  const InputComponent = useMemo(() => {
    if (!action.field) return TextInput
    const spec = fieldsByValue[action.field]
    if (!spec || !spec.input) return TextInput
    return Inputs[spec.input]
  }, [action.field, fieldsByValue])

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Type" className="flex-grow">
          <Select
            defaultValue={action.field}
            options={writableFields}
            onChange={(value) => onChange({ field: value, value: null, searchText: '' })}
          />
        </Form.Item>
        <div className="flex flex-row justify-center items-center">
          <AsTextCheckbox {...props} />
          <SetNullCheckbox {...props} />
        </div>
      </div>
      <div>{action.asText || action.setNull ? <Inputs.TextInput {...props} /> : <InputComponent {...props} />}</div>
    </div>
  )
}
export const InputSetTicketFieldMemo = React.memo(InputSetTicketField)

// ------------ Control Inputs ------------
const AsTextCheckbox: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="As Text" tooltip="Using as text will allow using placeholder string in non textual value input">
      <Checkbox checked={action.asText} onChange={(e) => onChange({ asText: e.target.checked as any })} />
    </Form.Item>
  )
}

const SetNullCheckbox: FC<Props> = ({ action, onChange }) => {
  const handleOnChange = useCallback(
    (e: any) => {
      onChange({
        value: null,
        searchText: null,
        setNull: e.target.checked
      })
    },
    [onChange]
  )

  return (
    <Form.Item label="Clear" tooltip="Same as set null or remove value.">
      <Checkbox checked={action.setNull} onChange={handleOnChange} />
    </Form.Item>
  )
}

// ------------ Action Inputs ------------
const TextInput: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]">
      {action.setNull ? (
        <Input disabled />
      ) : (
        <Input.TextArea
          count={{ show: false }}
          autoSize={{ minRows: 1, maxRows: 10 }}
          defaultValue={action.value || ''}
          onChange={(e) => onChangeDebounced({ value: e.target.value })}
        />
      )}
    </Form.Item>
  )
}

const NumberInput: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <Form.Item label="Value (Number)">
      <InputNumber defaultValue={action.value || ''} onChange={(value) => onChangeDebounced({ value: value })} />
    </Form.Item>
  )
}

const DateInput: FC<Props> = ({ action, onChange }) => {
  const value = useMemo(() => (dayjs(action.value).isValid() ? dayjs(action.value) : undefined), [action.value])

  return (
    <Form.Item label="Value (Date)">
      <DatePicker
        className="w-full"
        format={DATE_FORMAT}
        defaultValue={value}
        onChange={(date, dateStr) =>
          onChange({ value: date?.format(ISO_DATE_FORMAT), searchText: Array.isArray(dateStr) ? dateStr[0] : dateStr })
        }
      />
    </Form.Item>
  )
}

const DateTimeInput: FC<Props> = ({ action, onChange }) => {
  const value = useMemo(() => (dayjs(action.value).isValid() ? dayjs(action.value) : undefined), [action.value])

  return (
    <Form.Item label="Value (DateTime)">
      <DatePicker
        className="w-full"
        format={DATE_TIME_FORMAT}
        defaultValue={value}
        showTime={true}
        onChange={(date, dateStr) =>
          onChange({
            value: date?.format(ISO_DATE_TIME_FORMAT),
            searchText: Array.isArray(dateStr) ? dateStr[0] : dateStr
          })
        }
      />
    </Form.Item>
  )
}

const AfeSelect: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="Value (Select)">
      <QuerySelect
        apiEndpoint={afeApi.list}
        apiQueryParams={{ fields: 'id,user_afe_no' }}
        apiSearchBy={(text) => ({ user_afe_no__icontains: text })}
        renderOption={(item) => ({ value: item.id, label: item.user_afe_no })}
        defaultValue={{
          value: action.value || '',
          label: action.searchText
        }}
        onChange={(_, o: any) => onChange({ value: o.value, searchText: o.label })}
      />
    </Form.Item>
  )
}

const ContactSelect: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="Value (Select)">
      <ContactSelectControl
        defaultValue={{
          value: action.value || '',
          label: action.searchText
        }}
        onChange={(_, o: any) => onChange({ value: o.value, searchText: o.label })}
      />
    </Form.Item>
  )
}

const JobCodeSelect: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="Value (Select)">
      <QuerySelect
        apiEndpoint={jobCodeApi.list}
        apiQueryParams={{ fields: 'id,user_job_code_no' }}
        apiSearchBy={(text) => ({ user_job_code_no__icontains: text })}
        renderOption={(item) => ({ value: item.id, label: item.user_job_code_no })}
        defaultValue={{
          value: action.value || '',
          label: action.searchText
        }}
        onChange={(_, o: any) => onChange({ value: o.value, searchText: o.label })}
      />
    </Form.Item>
  )
}

const LocationSelect: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="Value (Select)">
      <QuerySelect
        apiEndpoint={locationApi.list}
        apiQueryParams={{ fields: 'id,name', order: 'name' }}
        apiSearchBy={(text) => ({ name__icontains: text })}
        renderOption={(item) => ({ value: item.id, label: item.name })}
        defaultValue={{
          value: action.value || '',
          label: action.searchText
        }}
        onChange={(_, o: any) => onChange({ value: o.value, searchText: o.label })}
      />
    </Form.Item>
  )
}

const OfficeSelect: FC<Props> = ({ action, onChange }) => {
  return (
    <Form.Item label="Value (Select)">
      <QuerySelect
        apiEndpoint={officeApi.list}
        apiQueryParams={{ fields: 'id,name', order: 'name' }}
        apiSearchBy={(text) => ({ name__icontains: text })}
        renderOption={(item) => ({ value: item.id, label: item.name })}
        defaultValue={{
          value: action.value || '',
          label: action.searchText
        }}
        onChange={(_, o: any) => onChange({ value: o.value, searchText: o.label })}
      />
    </Form.Item>
  )
}

const StatusSelect: FC<Props> = ({ action, onChange }) => {
  const customForm = useAtomValue(customFormAtom)
  const { getStatusOptions } = useCustomFormsStatus()

  return (
    <Form.Item label="Value (Select)">
      <Select
        defaultValue={action.value || ''}
        onChange={(value) => onChange({ value: value })}
        options={getStatusOptions(customForm?.id)}
      />
    </Form.Item>
  )
}

const Inputs: Record<FieldInputComponentName, FC<Props>> = {
  TextInput: React.memo(TextInput),
  NumberInput: React.memo(NumberInput),
  DateInput: React.memo(DateInput),
  DateTimeInput: React.memo(DateTimeInput),
  AfeSelect: React.memo(AfeSelect),
  ContactSelect: React.memo(ContactSelect),
  JobCodeSelect: React.memo(JobCodeSelect),
  LocationSelect: React.memo(LocationSelect),
  OfficeSelect: React.memo(OfficeSelect),
  StatusSelect: React.memo(StatusSelect)
}
