import { Modal, ModalProps } from '@/ui'
import { Form, Space, Switch } from 'antd'

export const ConfigureTicketModal = ({ onCancel, onOk, ...props }: ModalProps) => {
  return (
    <Modal
      title="Configure Ticket"
      cancelText="Close"
      okText="Save"
      onCancel={onCancel}
      onOk={(e) => {
        onCancel?.(e)
        onOk?.(e)
      }}
      {...props}
    >
      <div className="font-bold mb-16">Select styles to print</div>
      <Form.Item>
        <Space>
          <Form.Item
            noStyle
            name={['doc', 'print', 'form_options', '*', 'stylesheets']}
            getValueProps={(value) => {
              return {
                checked: value?.includes('default')
              }
            }}
            getValueFromEvent={(checked) => {
              return checked ? ['default'] : []
            }}
          >
            <Switch />
          </Form.Item>
          <span>Default</span>
        </Space>
      </Form.Item>
      <div className="font-bold mb-16">Select which bottom sections you'd like to include</div>
      <Form.Item>
        <Space>
          <Form.Item
            noStyle
            name={['doc', 'print', 'form_options', '*', 'attachments']}
            getValueProps={(value) => {
              return {
                checked: value === 1
              }
            }}
            getValueFromEvent={(checked) => {
              return checked ? 1 : undefined
            }}
          >
            <Switch />
          </Form.Item>
          <span>Attachments</span>
        </Space>
      </Form.Item>
      <Form.Item noStyle>
        <Space>
          <Form.Item
            noStyle
            name={['doc', 'print', 'form_options', '*', 'line_item_attachments']}
            getValueProps={(value) => {
              return {
                checked: value === 1
              }
            }}
            getValueFromEvent={(checked) => {
              return checked ? 1 : undefined
            }}
          >
            <Switch />
          </Form.Item>
          <span>Line item attachments</span>
        </Space>
      </Form.Item>
    </Modal>
  )
}
