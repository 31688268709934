import { TABLE_VIEW_TYPES } from '@/constants/general'
import { useApp, useModalState, useSession } from '@/hooks'
import { ACCOUNT_SETTINGS_PATHS } from '@/modules/account-settings/constants'
import { tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { ActionButton, ButtonProps } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, Modal, Select } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

export const ModalButton = (buttonProps: ButtonProps) => {
  const [form] = Form.useForm<TableView>()
  const createTableViewMutation = useMutation({ ...tableViewApi.create<TableView>() })
  const { company } = useSession()
  const { notification } = useApp()
  const location = useLocation()
  const navigate = useNavigate()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const handleSave = async () => {
    const values = await form.validateFields()

    const tableView = await createTableViewMutation.mutateAsync({
      ...values,
      company: company.id
    })

    notification.success({
      message: 'Tickets added'
    })

    setTimeout(() => {
      navigate(ACCOUNT_SETTINGS_PATHS.editTicketView(tableView.id), { state: { from: location.pathname } })
    }, 500)
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          title="New Ticket View"
          okText="Save"
          onCancel={closeModal}
          onOk={handleSave}
          confirmLoading={createTableViewMutation.isLoading}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              type: 'T'
            }}
          >
            <Form.Item label="Type" name="type">
              <Select
                options={Object.entries(TABLE_VIEW_TYPES).map(([value, label]) => ({
                  value,
                  label
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter a name'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" className="mb-0">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
