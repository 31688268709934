export const ACCOUNTING_SERVICES = [
  { key: 'qb_wc', option_key: 'qbwc_enabled', label: 'QB Web Connector' },
  { key: 'qbo', option_key: 'qbo_enabled', label: 'QB Online' },
  { key: 'netsuite', option_key: 'netsuite_enabled', label: 'NetSuite' }
]

export const SECURE_ACCESS_TYPES = {
  V: 'Vendor Access',
  S: 'Customer Signature'
} as const
