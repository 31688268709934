import { useApp } from '@/hooks'
import { ticketImportLogApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Upload, UploadProps } from 'antd'

export function UploadDataButton({ id, name }: { id: number; name: string }) {
  const { notification } = useApp()

  const uploadMutation = useMutation(ticketImportLogApi.create())

  const handleUpload: UploadProps['onChange'] = async ({ file }) => {
    try {
      const formData = new FormData()
      formData.append('template_id', id.toString())
      formData.append('file_name', file.fileName || file.name)
      formData.append('file', file as any)

      await uploadMutation.mutateAsync(formData)

      notification.info({
        message: 'Import Started',
        description: 'Importing tickets, this may take a while.'
      })
    } catch (error: any) {
      notification.error({
        message: 'Import Failed',
        description: error?.message || 'Failed to import tickets'
      })
    }
  }

  return (
    <Upload showUploadList={false} beforeUpload={() => false} multiple={false} onChange={handleUpload}>
      <Button
        size={'small'}
        type={'primary'}
        shape={'round'}
        iconName={'fa:cloud-upload'}
        loading={uploadMutation.isLoading}
      >
        Import Data
      </Button>
    </Upload>
  )
}
