import { useSession } from '@/hooks'
import { accountRoleApi } from '@/services/api-service'
import { AccountRole } from '@/types/account-role'
import { useQuery } from '@tanstack/react-query'

export const useAccountRolesQuery = () => {
  const { company } = useSession()

  const accountRolesQuery = useQuery({
    ...accountRoleApi.list<AccountRole>({
      company_id__exact: company.id
    })
  })

  return { accountRolesQuery, isLoading: accountRolesQuery.isLoading }
}
