import { ButtonProps } from '@/ui'
import { FC, ReactNode, createContext } from 'react'
import { Property } from '../../../schemas'

export type ScriptedButtonContextType = {
  property: Property
  buttonProps: ButtonProps
}

export const ScriptedButtonContext = createContext<ScriptedButtonContextType>({} as any)

type Props = ScriptedButtonContextType & { children?: ReactNode }

export const ScriptedButtonProvider: FC<Props> = ({ children, ...props }) => {
  return <ScriptedButtonContext.Provider value={props}>{children}</ScriptedButtonContext.Provider>
}
