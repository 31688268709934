import { programmedReportApi } from '@/services/api-service'
import { ProgrammedReport } from '@/types/programmed-report'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

/**
 * @description Fetches programmed report based on id param
 */
export const useProgrammedReportQuery = () => {
  const { id } = useParams()
  const programmedReportQuery = useQuery({ ...programmedReportApi.get<ProgrammedReport>(Number(id)), enabled: !!id })
  const programmedReport = programmedReportQuery.data

  const transformation =
    typeof programmedReport?.transformation !== 'number' ? programmedReport?.transformation : undefined

  return {
    programmedReport: {
      ...programmedReport,
      transformation
    },
    programmedReportQuery
  }
}

export const useTransformationTaskTabsOptions = () => {
  const { programmedReport } = useProgrammedReportQuery()

  const tabsOptions = useMemo(() => {
    const tabs = [{ label: 'DataSet', value: 'DataSet' }]

    programmedReport.transformation?.tasks?.forEach((task) => {
      if (task.type === 'CreateTab' && task.params?.name) {
        tabs.push({ label: task.params.name, value: task.params.name })
      }
    })

    return tabs
  }, [programmedReport.transformation?.tasks])

  return tabsOptions
}

export const useBaseReportDisplayfieldSetOptions = () => {
  const { programmedReport } = useProgrammedReportQuery()

  const displayfieldSetOptions = useMemo(() => {
    return programmedReport.base_report?.report?.displayfield_set?.map((df: any) => {
      let value = (df.path || '') + df.field

      if (df.field === '_rpt_property') {
        const propertyId = (df.field_verbose || '').split('**p=')[1] || '0'
        value += '_' + propertyId
      }

      return {
        value,
        label: df.name
      }
    })
  }, [programmedReport.base_report?.report?.displayfield_set])

  return displayfieldSetOptions
}
