import { unstable_usePrompt, useBeforeUnload } from 'react-router-dom'

export const useBlocker = (props: { when: boolean; message?: string }) => {
  const message = props.message ?? 'Are you sure you want to leave?'

  // before closing/reloading the page
  useBeforeUnload((event: any) => {
    if (props.when) {
      event.preventDefault()
      event.returnValue = ''
    }
  })

  // before navigating to another page
  unstable_usePrompt({ when: props.when, message })
}
