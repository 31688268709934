import { API_OPERATORS, DYNAMIC_DATES, TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Button } from '@/ui'
import { CustomReport } from '../../schema'
import { getFilterFieldName } from '../utils'

type Props = {
  customReport: CustomReport
  setEditingCriteria: (criteria: any) => void
  setEditingReport: (report: any) => void
}

export const CriteriaColumnCustomReport = ({ customReport, setEditingCriteria, setEditingReport }: Props) => {
  const { labels } = useApp()
  return (
    <div>
      {customReport?.filterfield_set?.map((ff, index) => {
        if (!(!ff.options || ff.options.editable)) {
          return null
        }

        let content

        switch (ff.filter_type) {
          case 'range':
            content = (
              <span>
                {!DYNAMIC_DATES[ff.filter_value as keyof typeof DYNAMIC_DATES] ? (
                  <span>
                    {ff.filter_value} to {ff.filter_value2}
                  </span>
                ) : (
                  <span>
                    {{ ld: 1, pm: 1 }[ff.filter_value as string] ? (
                      <span>
                        Past {ff.filter_value2} {ff.filter_value === 'ld' ? 'Days' : 'Months'}
                      </span>
                    ) : (
                      <span>{DYNAMIC_DATES[ff.filter_value as keyof typeof DYNAMIC_DATES] || ff.filter_value}</span>
                    )}
                  </span>
                )}
              </span>
            )
            break
          default:
            content = (
              <span>
                {ff.field_verbose === 'computed_status' ? (
                  <span>
                    {(ff.filter_value || '')
                      .split(',')
                      .map((k: any) => TICKET_STATUS[k as keyof typeof TICKET_STATUS] || k)
                      .join(',\n')}
                  </span>
                ) : (
                  <span>{ff.filter_value}</span>
                )}
              </span>
            )
        }

        return (
          <div key={index} className="flex items-center gap-x-6">
            <span>
              {getFilterFieldName(ff, JSON.parse(customReport.options?.field_aliases_json || '{}') || {}, labels)}
            </span>
            <strong>
              {ff.exclude && <span>Not</span>}
              <span>{API_OPERATORS[ff.filter_type as keyof typeof API_OPERATORS] || ff.filter_type}</span>
            </strong>
            <span>{content}</span>
            <Button
              type="text"
              iconName="fa:edit"
              className="ml-4"
              onClick={() => {
                setEditingCriteria(ff)
                setEditingReport(customReport)
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
