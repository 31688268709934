import { FC } from 'react'
import { SecureAccess } from '../../../section-buttons/secure-access'
import { useButtonContext } from '../../hooks'

export const EmailSignatureButton: FC = () => {
  const { property, buttonProps } = useButtonContext()

  const label = buttonProps?.children ? buttonProps.children : property.name

  return (
    <SecureAccess
      type={'S'}
      checkAccess={false}
      buttonProps={{ ...buttonProps, children: label }}
      modalProps={{ iconName: buttonProps?.iconName, title: label }}
    />
  )
}
