import { z } from 'zod'

export const ResourceEquipmentSchema = z.object({
  id: z.number(),
  sku: z.string(),
  description: z.string(),
  category__name: z.string(),
  category__parent_category__name: z.string(),
  user_text_7: z.string().describe('Job Role ID: Job Role Name'),
  assignee_id: z.number().nullish()
})
export const ResourceScheduleRuleSchema = z.object({
  id: z.number(),
  name: z.string(),
  start_date: z.string(),
  end_date: z.string(),
  template: z.number(),
  template__name: z.string(),
  template__comment: z.string()
})
export const ResourceContactSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  profile__id: z.number()
})
export const ResourceSchema = z.object({
  id: z.number().nullish(),
  type: z.enum(['Employee', 'Equipment']).nullish(),
  status: z.string().nullish(),

  name: z.string().nullish(),
  short_code: z.string().nullish(),
  descriptor: z.string().nullish(),

  contact_id: z.number().nullish(),
  contact__first_name: z.string().nullish(),
  contact__last_name: z.string().nullish(),

  job_roles: z.array(z.object({ id: z.number(), char_1: z.string() })).nullish(),
  equipments: z.array(z.object({ id: z.number() })).nullish(),

  district: z.string().nullish(),
  attributes: z.record(z.string(), z.string()).nullish(),

  modified_at: z.string().nullish(),

  // transformed fields
  _equipmentIds: z.array(z.number()).nullish(),
  _jobRole: z.object({ value: z.number(), label: z.string() }).nullish(),
  _skills: z.array(z.string()).nullish()
})

export type Resource = z.infer<typeof ResourceSchema>
export type ResourceEquipment = z.infer<typeof ResourceEquipmentSchema>
export type ResourceScheduleRule = z.infer<typeof ResourceScheduleRuleSchema>
export type ResourceContact = z.infer<typeof ResourceContactSchema>

export type ResourceForm = {
  title: string
  mode: 'add' | 'edit'
  resource: Resource
}
