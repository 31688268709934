import { TICKET_STATUS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { useAtomSelect } from '@/hooks/atom'
import { categoryKindApi } from '@/services/api-service'
import { Button } from '@/ui'
import { filterSelectOption } from '@/utils/filter-select-option'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Select, Space, Tooltip } from 'antd'
import { useSetAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../atoms'

const groupItemsOptions = [
  {
    label: 'By category',
    value: ''
  },
  {
    label: 'By item configuration',
    value: 'kind'
  },
  {
    label: 'By other configuration',
    value: 'other'
  }
]

export const PrintingOptions = () => {
  const { notification } = useApp()
  const restrictPrint = useAtomSelect(customFormAtom, '_restrict_print')
  const propertyGroups = useAtomSelect(customFormAtom, 'property_groups')
  const printDpiAware = useAtomSelect(customFormAtom, 'print_dpi_aware')
  const print = useAtomSelect(customFormAtom, '_print')
  const setCustomForm = useSetAtom(setCustomFormAtom)
  const [embedIconInProgress, setEmbedIconInProgress] = useState(false)
  const customPrintCss = useAtomSelect(customFormAtom, 'custom_print_css')
  const customFormIcon = useAtomSelect(customFormAtom, 'ticket_logo_url')
  const { company } = useSession()
  const categoryKindsQuery = useQuery({
    ...categoryKindApi.list({
      limit: 1000, // TODO: Find a better way to get all items
      company__eq: company.id,
      fields: 'id,name'
    })
  })

  const bindPrintRestrictOptions = useMemo(() => {
    return (
      propertyGroups?.flatMap(
        ({ name, properties }) =>
          properties?.map((property) => ({
            label: `${name} / ${property.name}`,
            value: property.id
          })) ?? []
      ) ?? []
    ).sort((a, b) => a.label.localeCompare(b.label, 'en'))
  }, [propertyGroups])

  const allowedStatusesOptions = useMemo(() => {
    return Object.entries(TICKET_STATUS).map(([key, value]) => ({
      label: value,
      value: key
    }))
  }, [])

  const embedIconIntoCustomCss = () => {
    if (!customFormIcon && !company.icon) {
      return
    }

    setEmbedIconInProgress(true)

    fetch(customFormIcon || company.icon)
      .then((response) => response.blob()) // Convert to blob
      .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = function () {
          const base64String = reader.result // Get base64 string

          const otherCss =
            (customPrintCss?.otherCss || '') +
            `


oc-tile[field="logo"] > oc-image {
  background-image: url(${base64String}) !important;
  background-size: contain;
}
`

          setCustomForm({
            custom_print_css: {
              ...customPrintCss,
              otherCss: otherCss
            } as any
          })
          setEmbedIconInProgress(false)
          notification.success({ message: 'Icon is successfully baked into CSS' })
        }
        reader.readAsDataURL(blob) // Convert blob to base64
      })
  }

  return (
    <div className="flex-1">
      <h3 className="font-bold">Printing - Options</h3>
      <Form layout="vertical">
        <Form.Item>
          <Checkbox
            checked={!!print?.default_options?.show_internal_use_only}
            value={print?.default_options?.show_internal_use_only}
            onChange={(e) => {
              setCustomForm({
                _print: {
                  ...print,
                  default_options: {
                    show_internal_use_only: e.target.checked
                  }
                }
              })
            }}
          >
            When printing, print internal section by default
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={!!restrictPrint?.enabled}
            value={restrictPrint?.pid}
            onChange={(e) => {
              setCustomForm({
                _restrict_print: {
                  ...restrictPrint,
                  enabled: e.target.checked
                }
              })
            }}
          >
            Restrict when a ticket can be printed
          </Checkbox>
        </Form.Item>
        {restrictPrint?.enabled && (
          <>
            <Form.Item label="Bind Print Restrict Property">
              <Select
                options={bindPrintRestrictOptions}
                placeholder="Select"
                showSearch
                filterOption={filterSelectOption}
                value={restrictPrint?.pid}
                onChange={(value) => {
                  setCustomForm({
                    _restrict_print: {
                      ...restrictPrint,
                      pid: value
                    }
                  })
                }}
              />
            </Form.Item>
            <Form.Item label="Allowed Statuses">
              <Select
                options={allowedStatusesOptions}
                placeholder="Select"
                showSearch
                mode="multiple"
                value={restrictPrint?.statuses}
                onChange={(value) => {
                  setCustomForm({
                    _restrict_print: {
                      ...restrictPrint,
                      statuses: value
                    }
                  })
                }}
              />
            </Form.Item>
          </>
        )}
        <Space align="end">
          <Form.Item>
            <Checkbox
              checked={!!print?.enabled}
              onChange={(e) => {
                setCustomForm({
                  _print: {
                    ...print,
                    enabled: e.target.checked
                  }
                })
              }}
            >
              Group items differently when printing
            </Checkbox>
          </Form.Item>
          {print?.enabled && (
            <Form.Item label="Group items" className="w-[200px]">
              <Select
                placeholder="Select"
                options={groupItemsOptions}
                popupMatchSelectWidth={false}
                defaultValue=""
                value={print.group_mode}
                onChange={(value) => {
                  setCustomForm({
                    _print: {
                      ...print,
                      group_mode: value
                    }
                  })
                }}
              />
            </Form.Item>
          )}
          {print?.enabled && print?.group_mode === 'other' && (
            <Form.Item label="Default Config">
              <Select
                placeholder="Select"
                popupMatchSelectWidth={false}
                value={print.default_kind}
                onChange={(value) => {
                  setCustomForm({
                    _print: {
                      ...print,
                      default_kind: value
                    }
                  })
                }}
                options={categoryKindsQuery.data?.items.map((categoryKind) => ({
                  label: categoryKind.name,
                  value: categoryKind.id
                }))}
              />
            </Form.Item>
          )}
        </Space>
        <Form.Item>
          <Tooltip title="When unset, the printer may zoom in to better fit the contents into a single page">
            <Checkbox
              checked={!!printDpiAware}
              onChange={(e) => {
                setCustomForm({
                  print_dpi_aware: e.target.checked
                })
              }}
            >
              Fixed zoom; disable smart shrinking
            </Checkbox>
          </Tooltip>
        </Form.Item>
        <Tooltip title="Embed Icon into CSS for offline print">
          <Button primary onClick={embedIconIntoCustomCss} loading={embedIconInProgress}>
            Embed Icon into CSS
          </Button>
        </Tooltip>
      </Form>
    </div>
  )
}

export const useCategoryKindsQuery = () => {}
