import { z } from 'zod'

// schemas
export const EquipmentSchema = z.object({
  id: z.number(),

  category_id: z.number(),
  category__kind_id: z.number(),
  category__parent_category__kind_id: z.number().nullable(),

  sku: z.string(),
  description: z.string(),
  usage_rate: z.number().nullable(),
  standby_rate: z.number().nullable(),
  hands: z.number().nullable(),
  unit: z.string(),
  units_used: z.number(),
  units_standby: z.number().nullable(),
  units_min: z.number().nullable(),
  minimum: z.number().nullable(),
  discount: z.number().nullable(),
  discountable_amount: z.number().nullable(),
  tax_rate: z.number().nullable(),
  taxable_amount: z.number().nullable(),
  barrels: z.number().nullable(),
  cost: z.number().nullable(),
  amount: z.number().nullable(),

  discount_sku: z.boolean(),
  non_discountable: z.boolean(),
  tax_sku: z.boolean(),
  non_taxable: z.boolean(),
  taxable_tax_sku: z.boolean(),
  skip_tax_calc: z.boolean(),
  skip_calc: z.boolean(),

  start_datetime: z.string().nullable(),
  end_datetime: z.string().nullable(),
  user_datetime_1: z.string().nullable(),
  user_datetime_2: z.string().nullable(),

  user_decimal_1: z.number().nullable(),
  user_decimal_2: z.number().nullable(),
  user_decimal_3: z.number().nullable(),

  user_integer_1: z.number().nullable(),
  user_integer_2: z.number().nullable(),
  user_integer_3: z.number().nullable(),
  user_integer_4: z.number().nullable(),

  user_text_1: z.string().nullable(),
  user_text_2: z.string().nullable(),
  user_text_3: z.string().nullable(),
  user_text_4: z.string().nullable(),
  user_text_5: z.string().nullable(),
  user_text_6: z.string().nullable(),
  user_text_7: z.string().nullable(),
  user_text_8: z.string().nullable(),

  string_1_source_id: z.number().nullable(),
  string_2_source_id: z.number().nullable(),
  string_3_source_id: z.number().nullable(),
  string_4_source_id: z.number().nullable(),
  string_5_source_id: z.number().nullable(),
  string_6_source_id: z.number().nullable(),
  string_7_source_id: z.number().nullable(),
  string_8_source_id: z.number().nullable(),

  notes: z.string().nullable(),
  user_long_text_1: z.string().nullable(),

  assignee_id: z.number().nullable(),
  assignee__first_name: z.string().nullable(),
  assignee__last_name: z.string().nullable(),

  status: z.string().nullable(),

  is_finished_good: z.boolean(),
  is_sub_component: z.boolean(),
  show_components: z.boolean(),

  sequence: z.number(),
  group_no: z.number().nullable(),

  print_hide: z.boolean(),
  hide_accounting: z.boolean(),
  hide_mobile: z.boolean(),
  summary_sku: z.boolean(),
  line_item_format: z.string().nullable()

  // note: available but not used
  // inventory: z.boolean(),
  // inactive: z.boolean(),
  // public: z.boolean(),
  // include_on_total: z.boolean(),
  // link_type_id: z.number().nullable(),
  // link_id: z.number().nullable(),
  // created_by_id: z.number(),
  // created_at: z.string(),
  // modified_by_id: z.number(),
  // modified_at: z.string(),
  // deleted_at: z.string().nullable(),
  // deleted_by_id: z.number().nullable(),
  // uuid: z.string(),
  // type: z.string(),
  // company_id: z.number(),
})
export const SelectedEquipmentSchema = z.object({
  id: z.number(),
  sku: z.string().nullable(),
  description: z.string().nullable()
})
export const EquipmentGroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  subtotal: z.boolean(),
  sync_datetime: z.boolean(),
  equipment: z.array(
    z.object({
      id: z.number(),
      sku: z.string(),
      description: z.string(),
      category: z.number()
    })
  )
})

// types
export type Equipment = z.infer<typeof EquipmentSchema>
export type SelectedEquipment = z.infer<typeof SelectedEquipmentSchema>
export type EquipmentGroup = z.infer<typeof EquipmentGroupSchema>
