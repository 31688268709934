import { ROLODEX_PATHS } from '../../constants'

export const searchFieldCompany = {
  everyone: 'Everyone (default)',
  created_by_company: 'Created By Company',
  approved: 'Preferred Vendor',
  business: 'Does Business With',
  internal: 'Internal Contacts',
  contacts: 'OC Directory Contacts'
} as const

export const searchFieldContact = {
  everyone: 'Everyone',
  approved: 'Approved Vendors',
  internal: 'Internal',
  ocdir: 'OC Directory'
} as const

export const rolodexPage = {
  [ROLODEX_PATHS.contacts]: {
    title: 'Contacts',
    addText: 'Add Contact',
    addDataCy: 'add-contact-button',
    selectTypeDataCy: 'contact-type-select',
    searchDataCy: 'search-contact-input',
    addLink: ROLODEX_PATHS.addContact,
    selectOptions: Object.entries(searchFieldContact).map(([key, value]) => ({
      label: value,
      value: key
    })) as any
  },
  [ROLODEX_PATHS.companies]: {
    title: 'Companies',
    addText: 'Add Company',
    addDataCy: '',
    selectTypeDataCy: '',
    searchDataCy: '',
    addLink: ROLODEX_PATHS.addCompany,
    selectOptions: Object.entries(searchFieldCompany).map(([key, value]) => ({
      label: value,
      value: key
    })) as any
  },
  [ROLODEX_PATHS.privateCompanies]: {
    title: 'Private Companies',
    addText: 'Add Private Company',
    addDataCy: 'add-private-company-button',
    selectTypeDataCy: 'company-type-select',
    searchDataCy: 'search-company-input',
    addLink: ROLODEX_PATHS.addPrivateCompany,
    selectOptions: Object.entries(searchFieldCompany).map(([key, value]) => ({
      label: value,
      value: key
    })) as any
  }
} as const

export const tabItems = [
  {
    key: ROLODEX_PATHS.contacts,
    label: 'Contacts',
    children: null,
    'data-cy': 'contacts-tab'
  },
  {
    key: ROLODEX_PATHS.companies,
    label: 'Companies',
    children: null,
    'data-cy': 'companies-tab'
  },
  {
    key: ROLODEX_PATHS.privateCompanies,
    label: 'Private Companies',
    children: null,
    'data-cy': 'private-companies-tab'
  }
]
