import { Icon } from '@/ui/icons'
import { Input } from 'antd'
import classNames from 'classnames'
import { PrimitiveAtom, atom as jotaiAtom, useSetAtom } from 'jotai'
import { Dispatch, SetStateAction } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type Props = {
  atom?: PrimitiveAtom<string>
  setSearch?: Dispatch<SetStateAction<string>>
  placeholder?: string
  /** default: 1000 */
  debounceTime?: number
  className?: string
}

/**
 * Input with search icon.
 * It sets the search text to the atom or useState with debounce
 *
 * @deprecated use @/ui/input/SearchInput.tsx instead
 */
export const SearchInput = ({
  atom,
  setSearch,
  className,
  debounceTime = 1000,
  placeholder = 'Search or type command',
  ...rest
}: Props) => {
  const setSearchText = useSetAtom(atom ?? jotaiAtom(''))

  const debounceSearchText = useDebouncedCallback((value: string) => {
    if (atom) {
      setSearchText(value)
      return
    }

    setSearch?.(value)
  }, debounceTime)

  return (
    <Input
      prefix={<Icon name="fa:search" />}
      className={classNames('w-[300px]', className)}
      placeholder={placeholder}
      onChange={(e) => debounceSearchText(e.target.value)}
      {...rest}
    />
  )
}
