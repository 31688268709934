import { MutationOptions, QueryClient, QueryKey, QueryOptions } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: Infinity,
      cacheTime: 0,
      retry: 0
    },
    mutations: {
      retry: 0
    }
  }
})

export const QUERY_AUTO_REFRESH_OPTIONS = {
  cacheTime: 0,
  staleTime: 0,
  refetchOnMount: true,
  refetchInterval: 60 * 1000, // 1 minute
  refetchOnWindowFocus: true
}

export const fetchQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  return queryClient.getQueryCache().build(queryClient, options).fetch(options)
}

export const executeMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  options: MutationOptions<TData, TError, TVariables, TContext>,
  payload?: TVariables
) => {
  return queryClient
    .getMutationCache()
    .build(queryClient, { ...options, variables: payload })
    .execute()
}

export const refreshQueries = async (keys: string[]) => {
  return Promise.all(keys.map((key) => queryClient.invalidateQueries([key])))
}
