import { AppLink, Button } from '@/ui'
import dayjs from 'dayjs'
import { FC, useMemo } from 'react'
import { useButtonContext } from '../../hooks'

export const SchedulerAssignerButton: FC = () => {
  const { ticket, property, buttonProps } = useButtonContext()
  const {
    scheduler_module_id,
    resource_type,
    target,
    attributes_pid,
    requirements_pid,
    start_date_pid,
    estimated_hours_pid,
    line_3_pid,
    district_pid
  } = property._buttonConfig?.vars || {}

  const compiledLink = useMemo(() => {
    const params = new URLSearchParams({
      _v: '2',
      from: 'ticket'
    })

    const startDateStr = ticket._tpByPropertyId[start_date_pid]?.value || ticket.timepoint_due
    const districtVal = ticket._tpByPropertyId[district_pid]?.value

    if (startDateStr) params.set('filter.ref_date', dayjs.parse(startDateStr)?.formatISODate(true) || '')

    if (districtVal) {
      params.set('filter.f[0]', 'name')
      params.set('filter.f[1]', 'district')
      params.set('filter.q.district[0]', districtVal)
    }

    if (attributes_pid) params.set('attributes_pid', attributes_pid)
    if (requirements_pid) params.set('requirements_pid', requirements_pid)
    if (start_date_pid) params.set('start_date_pid', start_date_pid)
    if (estimated_hours_pid) params.set('estimated_hours_pid', estimated_hours_pid)
    if (line_3_pid) params.set('line_3_pid', line_3_pid)

    return `/m2/${scheduler_module_id}/schedule/${resource_type}/assign/ticket/${ticket.id}?${params.toString()}`
  }, [
    attributes_pid,
    estimated_hours_pid,
    line_3_pid,
    requirements_pid,
    resource_type,
    scheduler_module_id,
    start_date_pid,
    ticket.name
  ])

  return (
    <AppLink to={compiledLink} target={target || '_top'}>
      <Button {...buttonProps}>{buttonProps?.children ? buttonProps.children : property.name}</Button>
    </AppLink>
  )
}
