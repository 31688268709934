import { FilePreview, Icon, IconName } from '@/ui'
import { AvatarProps } from 'antd'
import cn from 'classnames'
import { FC, ReactNode } from 'react'

type Props = {
  src?: string | null
  icon?: IconName | null
  shape?: AvatarProps['shape']
  title?: ReactNode
  description?: ReactNode
  actions?: ReactNode
  content?: ReactNode
  className?: string
}

export const Card: FC<Props> = ({ src, icon, shape, title, description, actions, content, className }) => {
  return (
    <div
      className={cn(
        'group relative flex flex-row items-center border border-border dark:border-dark-border rounded p-8 gap-8',
        className
      )}
    >
      {(src || icon) && (
        <div>
          <FilePreview
            shape={shape}
            size={40}
            className={'bg-primary-50'}
            src={src}
            icon={<Icon name={icon || 'blank'} className="text-16 text-primary" />}
          />
        </div>
      )}
      {content ? (
        <div className={'flex flex-col flex-grow'}>{content}</div>
      ) : (
        <div className="flex flex-col flex-grow line-clamp-1">
          <div className="line-clamp-1 text-14 font-medium">{title}</div>
          <div className="line-clamp-1 text-sm">{description}</div>
        </div>
      )}
      <div className={'flex flex-row'}>{actions}</div>
    </div>
  )
}
