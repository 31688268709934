import { profileApi } from '@/services/api-service'
import { MutationReturn } from '@/types/api/core'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

export const useSaveUserData = <TData, TPayload>(options?: MutationReturn<TData, TPayload>) => {
  const mutation = useMutation({
    ...profileApi.patch(),
    ...(options || {})
  })

  const saveUserData = useCallback(
    async (data: TPayload) => {
      await mutation.mutateAsync(data)
    },
    [mutation]
  )

  return { ...mutation, saveUserData }
}
