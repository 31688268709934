import { PropertyList } from '../../hooks'
import Section from './Section'

type SectionProps = {
  lists: {
    section: string
    data: PropertyList[]
  }[]
}
const Sections = ({ lists }: SectionProps) => {
  return (
    <div className="py-30">
      <h4 className="mb-16 border-b border-gray-200 pb-16">Section</h4>

      {lists.length > 0 &&
        lists.map((section, i) => {
          return (
            <div key={i}>
              <div className={`px-16 ${i % 2 === 0 ? 'bg-gray-50/60' : 'bg-white'}`}>
                <Section name={section.section} data={section.data} />
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Sections
