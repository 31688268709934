import { view } from '@/routing'
import { AdvancedSettings } from './advanced-settings'
import { CustomFeatures } from './custom-features'

export const Advanced = view(Component, {
  title: () => 'Advanced',
  scoped: false
})

function Component() {
  return (
    <div className="flex gap-x-16">
      <AdvancedSettings />
      <CustomFeatures />
    </div>
  )
}
