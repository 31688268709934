import { SectionTitle } from '@/ui/section-title'
import { Checkbox, Form, Modal } from 'antd'
import { useAtomValue } from 'jotai'
import { columnsAtom } from '../atoms'

type Props = {
  customForms: any[]
}

export const SelectCustomFormsSection = ({ customForms }: Props) => {
  const form = Form.useFormInstance<any>()
  const selectedCustomForms = Form.useWatch(['options', 'custom_forms'], form) || []
  const columns = useAtomValue(columnsAtom)
  const [modal, contextHolder] = Modal.useModal()

  const isCustomFormUsed = (customFormId: number) => {
    return columns.selected.some((item) => item.p_customform === customFormId)
  }

  return (
    <div>
      {contextHolder}
      <SectionTitle rounded number={2}>
        Select Custom Forms
      </SectionTitle>
      <div className="mt-16">
        {customForms.map((cf) => (
          <Form.Item key={cf.id} className="mb-10 ml-8">
            <Checkbox
              checked={selectedCustomForms.includes(cf.id)}
              onChange={(e) => {
                if (!e.target.checked && isCustomFormUsed(cf.id)) {
                  modal.warning({
                    title: 'Cannot deselect',
                    content: 'Tickets in which fields are selected in section 4 cannot be removed.'
                  })
                  return
                }

                form.setFieldValue(
                  ['options', 'custom_forms'],
                  e.target.checked
                    ? [...selectedCustomForms, cf.id]
                    : selectedCustomForms.filter((id: any) => id !== cf.id)
                )
              }}
            >
              {cf.name}
            </Checkbox>
          </Form.Item>
        ))}
      </div>
    </div>
  )
}
