import { useCompanyFeatures, useSession } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { companyApi, contactApi } from '@/services/api-service'
import { QueryTable, SearchInput } from '@/ui/'
import { AlphabetFilter } from '@/ui/alphabet-filter'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { Tabs } from '@/ui/tabs'
import { Alert, Select } from 'antd'
import { ColumnProps } from 'antd/es/table'
import { useSetAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { selectedTypeAtom } from '../../atoms'
import { ROLODEX_PATHS } from '../../constants'
import { rolodexPage, tabItems } from './constants'

type Props<RecordType> = {
  page: keyof typeof rolodexPage
  columns?: Omit<
    {
      key: string
      sorter?: boolean | string
    } & ColumnProps<RecordType>,
    'dataIndex'
  >[]
  isOffsetBlockActive?: boolean
  offsetContent?: React.ReactNode
  showTabs?: boolean
}

/**
 * Rolodex Layout Component. This component is used to render the layout of the Rolodex module.
 * It renders the tabs, the search input, the alphabetical filter, add button, and the table.
 * It also handles queries and the pagination and sorting of the table.
 */
export const Layout = <RecordType,>(props: Props<RecordType>) => {
  const { user, permissions } = useSession()
  const { page, columns, isOffsetBlockActive, offsetContent, showTabs = true } = props
  const [search, setSearch] = useState('')
  const [activeLetter, setActiveLetter] = useState<string | null>(null)
  const [searchField, setSearchField] = useState('')
  const setSelectedType = useSetAtom(selectedTypeAtom)
  const hasOffsetState = useMemo(() => isOffsetBlockActive, [isOffsetBlockActive])
  const {
    forcePrivateCompanies: { enabled: forcePrivateCompanies }
  } = useCompanyFeatures()

  const queryParams = {
    order: page === '/rolodex/contacts' ? 'id' : 'name',
    letter: activeLetter,
    ...(search && { search: search.trim() })
  }

  const { addText, selectOptions, title, addDataCy, selectTypeDataCy, searchDataCy } = useMemo(
    () => rolodexPage[page],
    [page]
  )

  const shouldRenderAdd = useMemo(() => {
    if (page === '/rolodex/contacts') return true

    if (page === '/rolodex/private_companies' || !forcePrivateCompanies) {
      if (user?.is_superuser || permissions.includes('company_admin')) {
        return true
      }
    }
  }, [page, forcePrivateCompanies, permissions, user?.is_superuser])

  return (
    <div>
      <PageTitle className="mb-16">{title}</PageTitle>
      {showTabs && <Tabs activeKey={page} onTabClick={(key) => navigate(key)} type="solid" items={tabItems} />}
      {page === ROLODEX_PATHS.companies && forcePrivateCompanies && (
        <Alert
          message="Only Private Companies getting displayed (per settings)"
          type="info"
          showIcon
          className="inline-flex mb-12"
        />
      )}
      <div className="flex gap-x-8 items-center mb-14">
        <Select
          options={selectOptions}
          className="w-[250px]"
          placeholder="Select"
          onSelect={(value) => setSearchField(value?.toString() || '')}
          data-cy={selectTypeDataCy}
        />
        <SearchInput onSearch={setSearch} data-cy={searchDataCy} />
        {shouldRenderAdd && (
          <Button
            data-cy={addDataCy}
            iconName="mi:add"
            type="primary"
            onClick={() => setSelectedType(rolodexPage[page])}
          >
            {addText}
          </Button>
        )}
      </div>
      <AlphabetFilter onChange={(letter) => setActiveLetter(letter)} />
      <div className="flex">
        <div style={{ width: hasOffsetState ? '60%' : '100%' }}>
          <QueryTable
            queryApi={page === ROLODEX_PATHS.contacts ? contactApi.list : companyApi.list}
            queryParams={
              page === ROLODEX_PATHS.contacts
                ? {
                    ...queryParams,
                    searchFieldContacts: searchField,
                    'Q[]': ['profile__user__isnull|1', 'profile__user__is_active|1', 'or']
                  }
                : {
                    ...queryParams,
                    searchFieldCompany: searchField,
                    ...(page === '/rolodex/private_companies' ? { private_only: true } : { public_only: true })
                  }
            }
            columns={
              columns?.map((column) => ({ ...column, dataIndex: column.key, sorter: column.sorter || false })) || []
            }
          />
        </div>
        {hasOffsetState && (
          <div
            className={'overflow-x-hidden overflow-y-auto relative w-[40%] pl-16'}
            style={{ maxHeight: 'calc(-300px + 100vh)' }}
          >
            {offsetContent}
          </div>
        )}
      </div>
    </div>
  )
}
