import { useApp, useIsMobile } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { categoryApi } from '@/services/api-service'
import { Checkbox, LinkButton, QueryDeleteButton, QueryEditButton, UrlStateFormItem } from '@/ui'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { z } from 'zod'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'

export const CategoryListView = view(Component)

function Component() {
  const { l } = useApp()
  const isMobile = useIsMobile()

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('Categories'),
        actions: [
          <LinkButton key={'add'} to={'add'} type={'primary'} iconName={'fa:plus'} data-cy="add-category-button">
            Add {l('Category')}
          </LinkButton>
        ]
      }}
      filter={{
        search: true,
        query: ({ filter }) => ({
          parent_category__isnull: filter?.parent ? undefined : false,
          ...(filter?.search
            ? {
                Q: [`name__icontains|${filter?.search}`, `parent_category__name__icontains|${filter?.search}`, 'or']
              }
            : {})
        }),
        render: (searchEl) => (
          <div className={'flex items-center gap-10'}>
            <div className={'flex-grow md:flex-grow-0'}>{searchEl}</div>
            <UrlStateFormItem name={['filter', 'parent']} valuePropName={'checked'}>
              <Checkbox data-cy={'filter-parent-checkbox'}>Include Parent</Checkbox>
            </UrlStateFormItem>
          </div>
        )
      }}
      table={{
        queryApi: categoryApi.list,
        queryParams: { order: 'name', fields: CategorySchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'parent_category__name', title: 'Parent Category', hidden: isMobile, render: getParent },
          { dataIndex: 'name', title: 'Category Name' },
          { dataIndex: 'kind__name', title: 'Configuration', hidden: isMobile, render: getKind },
          { dataIndex: 'tags', title: 'Tags', hidden: isMobile, render: getTags },
          { dataIndex: 'equipment__count', title: 'SKUs Count', render: (value) => <Tag>{value}</Tag> },
          { dataIndex: 'modified_at', title: 'Last Updated', hidden: isMobile, render: getModified },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Category) => (
              <div>
                <QueryEditButton to={`${row.id}/edit`} />
                <QueryDeleteButton id={row.id} api={categoryApi.delete} name={l('Category')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}

const CategorySchema = z.object({
  id: z.string(),
  parent_category__name: z.string(), // Category
  name: z.string(), // Subcategory
  parent_category__kind__name: z.string(),
  kind__name: z.string(),
  equipment__count: z.number(),
  tags: z.array(z.object({ id: z.number(), name: z.string() })),
  modified_at: z.string()
})

type Category = z.infer<typeof CategorySchema>

const getParent = (value: any) => (value ? value : <Tag color={'orange'}>Parent</Tag>)
const getKind = (_: any, row: Category) => row.kind__name || row.parent_category__kind__name || 'N/A'
const getTags = (tags?: Category['tags']) => tags?.map((tag) => <Tag key={tag.id}>{tag.name}</Tag>) || null
const getModified = (value: string) => dayjs.parse(value)?.formatLocal()
