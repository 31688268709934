import { jobManagerReducer } from '@/modules/jas/job-manager/slice'
import { appReducer } from '@/slices/app-slice'
import { sessionReducer } from '@/slices/session-slice'
import { configureStore } from '@reduxjs/toolkit'
import { createContext } from 'react'
import { ReactReduxContextValue } from 'react-redux'

export const store = configureStore({
  reducer: {
    app: appReducer,
    session: sessionReducer,
    jobManager: jobManagerReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const AppContext = createContext<ReactReduxContextValue>(null as any)
