import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { eventApi } from '@/services/api-service'
import { PageTitle } from '@/ui/page-title'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Alert } from 'antd'
import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AddModuleModal } from './add-module-modal'
import { MODULE_BUILDER_PATH } from './constants'
import { useModulesQuery } from './hooks'
import { ModuleTabsContent } from './module-tabs-content'
import { ModuleTabsHeader } from './module-tabs-header'

export const ModuleBuilder = () => {
  const { id } = useParams()
  const { modulesQuery } = useModulesQuery()
  const navigate = useNavigate()
  const eventsQuery = useQuery(eventApi.list({ type: 'module', target: id }))

  const modules = useMemo(
    () => modulesQuery.data?.items.sort((a, b) => a.position - b.position) ?? [],
    [modulesQuery.data]
  )

  useEffect(() => {
    if (!id && modules.length > 0) {
      navigate(`${MODULE_BUILDER_PATH}/${modules[0].id}`)
    }
  }, [modules, id, navigate])

  const handleTabChange = (id: string) => {
    navigate(`${MODULE_BUILDER_PATH}/${id}`)
  }

  const handleModuleSaved = async (id: number) => {
    await modulesQuery.refetch()
    navigate(`${MODULE_BUILDER_PATH}/${id}`)
  }

  if (modulesQuery.isLoading) {
    return <Spin spinning isCentered />
  }

  if (!modules.length) {
    return (
      <div>
        <div className="flex justify-end mb-16">
          <AddModuleModal onSaved={handleModuleSaved} />
        </div>
        <Alert message="No modules" />
      </div>
    )
  }

  if (!id) {
    return null
  }

  return (
    <div>
      <div className="flex justify-between mb-16">
        <PageTitle>Module Builder</PageTitle>
        <AddModuleModal onSaved={handleModuleSaved} />
      </div>
      <ModuleTabsHeader modules={modules} onTabChange={handleTabChange} />
      <ModuleTabsContent key={id} />
      <div className="mt-30">
        <h5 className="font-bold">Recent Activity</h5>
        <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'module', target: id }} />
        <div className="mt-16">
          <RecentActivities isLoading={eventsQuery.isLoading} events={eventsQuery.data?.items} />
        </div>
      </div>
    </div>
  )
}
