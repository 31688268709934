import { Select } from '@/ui'
import { Form } from 'antd'
import { FC } from 'react'
import { BpaCondition } from '../../schemas'

type Props = {
  condition: BpaCondition
  className?: string
  onChange: (value: any) => void
}

const CAST_LIST = [
  { value: 'str', label: 'STR' },
  { value: 'int', label: 'INT' },
  { value: 'float', label: 'FLOAT' }
]

export const CastSelect: FC<Props> = ({ condition, className, onChange }) => {
  if (!['equals', 'not_equals'].includes(condition.operator)) {
    return <></>
  }

  return (
    <Form.Item label="Cast" className={className}>
      <Select
        data-cy="condition-cast-select"
        popupMatchSelectWidth={false}
        defaultValue={condition.cast}
        options={CAST_LIST}
        onChange={onChange}
      />
    </Form.Item>
  )
}
