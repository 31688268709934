import { DYNAMIC_DATES, TICKET_STATUS } from '@/constants/general'

export const renderCriteria = (criteria: any) => {
  const type = criteria.type || criteria.filter_type
  const value = criteria.value || criteria.filter_value
  const value2 = criteria.value2 || criteria.filter_value2
  const field = criteria.field || criteria.filter_field
  const pastTimesSuffix = value === 'ld' ? 'Days' : value === 'pm' ? 'Months' : ''

  return (
    <div>
      {type === 'range' ? (
        <span>
          {!DYNAMIC_DATES[value as keyof typeof DYNAMIC_DATES] ? (
            `${value} to ${value2}`
          ) : (
            <span>
              {value === 'ld' || value === 'pm'
                ? `Past ${value2} ${pastTimesSuffix}`
                : DYNAMIC_DATES[value as keyof typeof DYNAMIC_DATES] || value}
            </span>
          )}
        </span>
      ) : (
        <div>
          {field === 'computed_status' || field === 'ticket_computed_status' ? (
            <span>
              {type === 'in'
                ? `${(value || '')
                    .split(',')
                    .map((val: string) => TICKET_STATUS[val as keyof typeof TICKET_STATUS])
                    .join(', ')}`
                : `${TICKET_STATUS[value as keyof typeof TICKET_STATUS]}`}
            </span>
          ) : (
            <span>{value}</span>
          )}
        </div>
      )}
    </div>
  )
}
