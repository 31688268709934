import { DATE_FORMAT } from '@/constants/date'
import { ALLOWED_OPERATORS_BY_TYPE, API_OPERATORS, DYNAMIC_DATES, TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { LINE_ITEM_FILTER_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_FILTER_COLUMNS } from '@/modules/ticket/columns'
import { EntityColumn } from '@/types/entity-column'
import { Filterfield } from '@/types/filterfield'
import { Button, Modal } from '@/ui'
import { getColumnLabel } from '@/utils/get-column-label'
import { Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Space, Switch } from 'antd'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SetOptional } from 'type-fest'

type Props = {
  filterfield: SetOptional<Filterfield, 'id'>
  rootModelName: 'line item' | 'ticket'
  readonlyFilterField?: boolean
  onClose: () => void
  onSave: (values: Filterfield, selectedFilterfield: EntityColumn) => void
}

export const AddEditReportCriteriaModal = ({
  filterfield,
  rootModelName,
  readonlyFilterField,
  onClose,
  onSave
}: Props) => {
  const [form] = Form.useForm()
  const fieldVerbose = Form.useWatch('field_verbose', form) ?? filterfield.field_verbose
  const exclude = Form.useWatch('exclude', form) ?? false
  const filterType = Form.useWatch('filter_type', form) || filterfield.filter_type || ''
  const editable = Form.useWatch(['options', 'editable'], form) ?? true
  const value = Form.useWatch('filter_value', form)

  const columns = useMemo(
    () => (rootModelName === 'line item' ? LINE_ITEM_FILTER_COLUMNS : TICKET_FILTER_COLUMNS),
    [rootModelName]
  )

  const selectedColumn = useMemo<EntityColumn | undefined>(
    () => (fieldVerbose ? columns.find((item) => item.field_verbose === fieldVerbose) : undefined),
    [fieldVerbose, columns]
  )
  const fieldType = useMemo(() => selectedColumn?.field_type ?? 'text', [selectedColumn])

  const [isDynamicDates, setIsDynamicDates] = useState(() => {
    // this field is not saved in the database, so we need to check based on following conditions
    if (filterfield.filter_type === 'range' && fieldType === 'date' && !dayjs(filterfield.filter_value).isValid()) {
      return true
    }

    return false
  })

  const [conditionLogic, setConditionLogic] = useState('Text')
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [isTicketStatusField, setIsTicketStatusField] = useState(
    filterfield.field === 'computed_status' || filterfield.field === 'ticket_computed_status'
  )
  const isDisabled = readonlyFilterField || !editable
  const { labels } = useApp()

  const categoriesOptions = useMemo(
    () =>
      [...new Set(columns.map((column) => column.category || ''))]
        .map((category) => ({
          value: category,
          label: startCase(category)
        }))
        .sort((a, b) => a.label.localeCompare(b.label, 'en')),
    [columns]
  )

  const apiOperators = ALLOWED_OPERATORS_BY_TYPE[fieldType as keyof typeof ALLOWED_OPERATORS_BY_TYPE]

  const filterByOptions = useMemo(() => {
    if (selectedCategory === 'all') {
      return columns.map((field) => ({
        value: field.field_verbose,
        label: getColumnLabel(field, labels)
      }))
    }

    const options = columns
      .filter((field: EntityColumn) => field.category === selectedCategory)
      .map((field: EntityColumn) => ({
        value: field.field_verbose,
        label: field.name
      }))

    return options
  }, [columns, selectedCategory])

  const handleItemSelect = (val: string) => {
    const item = columns.find((col: EntityColumn) => col.field_verbose === val)

    if (!item) {
      console.error('item not found', val)
      return
    }

    const allowedOperators = ALLOWED_OPERATORS_BY_TYPE[item.field_type as keyof typeof ALLOWED_OPERATORS_BY_TYPE]
    const isTicketStatus = item.field_verbose === 'computed_status' || item.field_verbose === 'ticket_computed_status'

    if (isTicketStatus) {
      form.setFieldsValue({ filter_value: 'D', filter_type: 'in' })
    } else {
      form.setFieldsValue({ filter_value: null, filter_type: allowedOperators[0] })
    }
    form.setFieldsValue({ filter_value2: '' })
    setIsTicketStatusField(isTicketStatus)
  }

  const handleSave = async () => {
    const { filter_value, filter_value2, filter_type, ...values } = await form.validateFields()

    let filterValue = filter_value
    let filterValue2 = filter_value2

    if (fieldType === 'date' && !isDynamicDates && filter_type !== 'isnull') {
      filterValue = dayjs(filter_value).format('YYYY-MM-DD')
      filterValue2 = filter_value2 && dayjs(filter_value2).format('YYYY-MM-DD')
    } else if (fieldType === 'date' && isDynamicDates && filter_value !== 'ld' && filter_value !== 'pm') {
      filterValue2 = filterValue
    }
    filterValue2 = filterValue2 ? filterValue2 : undefined

    onSave(
      {
        ...values,
        filter_value: filterValue,
        filter_value2: filterValue2,
        filter_type: filter_type,
        path: selectedColumn?.path,
        path_verbose: selectedColumn?.path_verbose
      },
      selectedColumn as EntityColumn
    )
  }

  // set condition logic
  useEffect(() => {
    if (filterType === 'range') {
      setConditionLogic('Range')
    } else if (filterType === 'isnull') {
      setConditionLogic('Conditional')
    } else if (fieldType === 'date') {
      setConditionLogic('Date')
    } else if (fieldType === 'number') {
      setConditionLogic('Number')
    } else if (fieldType === 'text') {
      setConditionLogic('Text')
    } else {
      console.error('unhandled lookup in switchCondition!  fieldType=', fieldType, ' filterType=', filterType)
    }
  }, [filterType, fieldType])

  const initialValues = useMemo(
    () => ({
      ...filterfield,
      filter_value:
        fieldType === 'date' && !isDynamicDates && filterType !== 'isnull'
          ? dayjs(filterfield.filter_value)
          : filterfield.filter_value,
      filter_value2:
        fieldType === 'date' && filterfield.filter_value2 && !isDynamicDates && filterType !== 'isnull'
          ? dayjs(filterfield.filter_value2)
          : ['ld', 'pm'].includes(filterfield.filter_value as string)
            ? Number(filterfield.filter_value2)
            : filterfield.filter_value2
    }),
    [filterfield, fieldType, isDynamicDates, filterType]
  )

  return (
    <Modal
      withScreenMaxHeight
      destroyOnClose
      open
      title="Edit Report Criteria"
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
    >
      <Form form={form} initialValues={initialValues} layout="vertical" key={filterfield.id} className="p-5">
        <Form.Item name="id" hidden />
        <Form.Item name="filter_value" hidden />
        <Form.Item name="filter_type" hidden />
        <Form.Item name="filter_value2" hidden />
        <Form.Item>
          <Space>
            <span>Enabled</span>
            <Form.Item name={['options', 'enabled']} noStyle valuePropName="checked">
              <Switch />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Category">
          <Select
            disabled={isDisabled}
            value={selectedCategory}
            onChange={(value) => setSelectedCategory(value)}
            options={[
              {
                value: 'all',
                label: 'All'
              },
              ...categoriesOptions
            ]}
          />
        </Form.Item>
        <Form.Item label="Filter By" name="field_verbose" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="label"
            options={filterByOptions}
            disabled={isDisabled}
            onChange={handleItemSelect}
          />
        </Form.Item>
        <div>
          {isTicketStatusField ? (
            <div className="mb-16">
              {value &&
                value.split(',').map((item: string, index: number) => (
                  <div className="flex gap-x-8" key={item + index}>
                    <Form.Item className="grow" label="Criteria">
                      <Select
                        value={item}
                        options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
                        onChange={(val) => {
                          form.setFieldsValue({
                            filter_value: value
                              .split(',')
                              .map((value: string, i: number) => (value + i === item + index ? val : value))
                              .join(',')
                          })
                        }}
                      />
                    </Form.Item>
                    {value.split(',').length > 1 && (
                      <Form.Item label=" ">
                        <Button
                          type="text"
                          iconName="fa:trash"
                          onClick={() => {
                            form.setFieldsValue({
                              filter_value: value
                                .split(',')
                                .filter((value: string, i: number) => value + i !== item + index)
                                .join(',')
                            })
                          }}
                        />
                      </Form.Item>
                    )}
                  </div>
                ))}
              <Button
                type="primary"
                onClick={() => {
                  form.setFieldsValue({ filter_value: `${value},D` })
                }}
              >
                Add Another
              </Button>
            </div>
          ) : (
            <div>
              <div className="flex gap-x-24 items-center">
                <Form.Item label=" " name="exclude" valuePropName="checked">
                  <Checkbox disabled={!editable} />
                </Form.Item>
                <span className={exclude ? '' : 'opacity-50'}>Not</span>
                <Form.Item label="Comparison" name="filter_type" className="grow">
                  <Select
                    disabled={!editable}
                    placeholder="Select"
                    options={apiOperators.map((operator) => ({
                      label: API_OPERATORS[operator as keyof typeof API_OPERATORS] || operator,
                      value: operator
                    }))}
                    onChange={() => {
                      form.setFieldsValue({ filter_value: null, filter_value2: null })
                      setIsDynamicDates(false)
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                {conditionLogic === 'Text' && (
                  <Form.Item label="Value" name="filter_value" rules={[{ required: true }]}>
                    <Input disabled={!editable} />
                  </Form.Item>
                )}
                {conditionLogic === 'Number' && (
                  <Form.Item label="Value" name="filter_value" rules={[{ required: true }]}>
                    <InputNumber disabled={!editable} />
                  </Form.Item>
                )}
                {conditionLogic === 'Conditional' && (
                  <Form.Item name="filter_value" rules={[{ required: true }]}>
                    <Radio.Group disabled={!editable}>
                      <Radio value="True">True</Radio>
                      <Radio value="False">False</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
                {conditionLogic === 'Date' && (
                  <Form.Item label="Value" name="filter_value" rules={[{ required: true }]}>
                    <DatePicker disabled={!editable} format={DATE_FORMAT} />
                  </Form.Item>
                )}
                {conditionLogic === 'Range' && (
                  <div>
                    {(fieldType === 'text' || fieldType === 'number') && (
                      <div className="flex gap-x-16">
                        <Form.Item label="Start/Low" name="filter_value" rules={[{ required: true }]}>
                          <Input disabled={!editable} />
                        </Form.Item>
                        <Form.Item label="End/High" name="filter_value2">
                          <Input disabled={!editable} />
                        </Form.Item>
                      </div>
                    )}
                    {fieldType === 'date' && (
                      <div>
                        <Checkbox
                          disabled={!editable}
                          className="mb-16"
                          checked={isDynamicDates}
                          onChange={(e) => {
                            setIsDynamicDates(e.target.checked)
                            form.setFieldsValue({ filter_value: null, filter_value2: null })
                          }}
                        >
                          Dynamic Dates
                        </Checkbox>
                        {isDynamicDates ? (
                          <>
                            <Form.Item label="Value" name="filter_value" rules={[{ required: true }]}>
                              <Select
                                disabled={!editable}
                                options={Object.entries(DYNAMIC_DATES).map(([value, label]) => ({ value, label }))}
                                onChange={() => form.setFieldValue('filter_value2', undefined)}
                              />
                            </Form.Item>
                            {(value === 'ld' || value === 'pm') && (
                              <Form.Item
                                name="filter_value2"
                                label={value === 'ld' ? 'Days' : 'Months'}
                                rules={[{ type: 'number', min: 0 }, { required: true }]}
                              >
                                <InputNumber className="w-full" />
                              </Form.Item>
                            )}
                          </>
                        ) : (
                          <div className="flex gap-x-16">
                            <Form.Item label="Start date" name="filter_value" rules={[{ required: true }]}>
                              <DatePicker disabled={!editable} format={DATE_FORMAT} />
                            </Form.Item>
                            <Form.Item label="End date" name="filter_value2">
                              <DatePicker disabled={!editable} format={DATE_FORMAT} />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {!readonlyFilterField && (
          <Form.Item name={['options', 'editable']} valuePropName="checked" className="mb-0">
            <Checkbox>Make Editable</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
