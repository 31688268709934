import { useSession } from '@/hooks'
import { pricingCustomerApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { PricingCustomerSchema } from '../schemas'

export const usePricingCustomerQuery = () => {
  const { company } = useSession()

  const pricingCustomerQuery = useQuery({
    ...pricingCustomerApi.list({
      limit: 'None',
      company_id__exact: company.id,
      deleted_at__isnull: 1
      // TODO: get only the fields we need, but adding prices to the fields causes an api error
      //fields: 'id,customer_office__name,prices'
    }),
    select: (data) => ({
      ...data,
      items: PricingCustomerSchema.array().parse(data.items)
    })
  })

  return {
    pricingCustomerQuery
  }
}
