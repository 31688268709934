import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useTicketModule = () => {
  const { pathname } = useLocation()
  const moduleQuery = useCurrentModuleQuery()
  const { isModule, module, moduleId } = moduleQuery

  const [isDefaultTicketsView, isDefaultLoadsView] = useMemo(
    () => [pathname.startsWith('/tickets'), pathname.startsWith('/loads')],
    [pathname]
  )

  const viewType = useMemo(() => {
    if (isModule) return module?.view_type
    if (isDefaultTicketsView) return 'T'
    if (isDefaultLoadsView) return 'L'
    return 'T'
  }, [isDefaultLoadsView, isDefaultTicketsView, isModule, module?.view_type])

  const userDataViewKey = useMemo(() => {
    if (isModule) return `mod_view_${moduleId}`
    if (isDefaultTicketsView) return 'active_table_view'
    if (isDefaultLoadsView) return 'active_loads_view'
    return 'active_table_view'
  }, [isDefaultLoadsView, isDefaultTicketsView, isModule, moduleId])

  const moduleFormId = useMemo(
    () => module?.data?.stages_form_id || module?.data?.custom_form_id || null,
    [module?.data?.custom_form_id, module?.data?.stages_form_id]
  )

  return {
    ...moduleQuery,
    isLoadsView: viewType === 'L',
    isTicketsView: viewType === 'T',
    isSchedulerView: viewType === 'SD',
    isTicketsCalendarView: viewType === 'TC',
    viewType,
    userDataViewKey,
    moduleFormId,
    showStatusFilter: module?.data?.show_status_filter || false,
    isVerticalViews: module?.data?.show_views_vertically || false
  }
}
