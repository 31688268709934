import { useApp } from '@/hooks'
import { jasScheduleRuleTemplateApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentRuleTemplateAtom, isAddModeAtom, setEmptyCurrentRuleTemplateAtom } from '../atoms'
import { useSortedRuleTemplates } from './use-sorted-rule-templates'

export const useAddRuleTemplateMutation = () => {
  const { notification } = useApp()
  const currentRuleTemplate = useAtomValue(currentRuleTemplateAtom)
  const setIsAddMode = useSetAtom(isAddModeAtom)
  const setEmptyCurrentRuleTemplate = useSetAtom(setEmptyCurrentRuleTemplateAtom)
  const { ruleTemplatesQuery } = useSortedRuleTemplates()

  const mutation = useMutation({
    mutationFn: currentRuleTemplate?.id
      ? jasScheduleRuleTemplateApi.update().mutationFn
      : jasScheduleRuleTemplateApi.create().mutationFn,
    onSuccess: async () => {
      await ruleTemplatesQuery.refetch()
      notification.success({
        message: currentRuleTemplate?.id ? 'Rule template updated successfully' : 'Rule template added successfully'
      })
      setIsAddMode(false)
      setEmptyCurrentRuleTemplate()
    }
  })

  const addRuleTemplate = async () => {
    if (!currentRuleTemplate) {
      console.error('new rule template is not defined')
      return
    }

    if (!currentRuleTemplate.name) {
      notification.error({
        message: 'Please enter a name'
      })
      return
    }

    if (currentRuleTemplate.data.length === 0) {
      notification.error({
        message: 'Please add at least one rule'
      })
      return
    }

    const hasInvalidRule = currentRuleTemplate.data.some((rule) => rule.day_on === 0 && rule.day_off === 0)

    // Checking for NaN is required because we are providing NaN as a default
    // value for new rule's day_on and day_off to make input placeholder visible
    const hasNaNRule = currentRuleTemplate.data.some((rule) => isNaN(rule.day_on) || isNaN(rule.day_off))

    if (hasInvalidRule || hasNaNRule) {
      notification.error({
        message: 'Please enter a valid rule'
      })
      return
    }

    // Don't send rule id to backend
    await mutation.mutateAsync({
      ...currentRuleTemplate,
      data: currentRuleTemplate.data.map(({ day_off, day_on }) => ({
        day_off,
        day_on
      }))
    })
  }

  return {
    addRuleTemplate,
    addRuleTemplateMutation: mutation
  }
}
