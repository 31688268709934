import { useSession } from '@/hooks'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import { RangeValueType } from 'rc-picker/es/PickerInput/RangePicker'
import { FC } from 'react'
import { LocalDatePicker } from '../local-date-picker'

export type LocalDateRangePickerProps = Omit<RangePickerProps, 'value' | 'defaultValue'> & {
  value?: string | RangePickerProps['value']
  defaultValue?: string | RangePickerProps['defaultValue']
  format?: string
  tz?: 'convert' | 'replace' | 'ignore'
}

export const LocalDateRangePicker: FC<LocalDateRangePickerProps> = ({ value, defaultValue, format, tz, ...props }) => {
  const valueObj = parseDates(value, tz)
  const defaultValueObj = parseDates(defaultValue, tz)

  const { user } = useSession()
  const militaryTime = user?.profile?.userdata_json?.preferences?.military_time_input || {}

  const defaultFormat = props.showTime
    ? militaryTime
      ? dayjs.$localFormat.militaryTime
      : dayjs.$localFormat.dateTime
    : dayjs.$localFormat.date

  return (
    <LocalDatePicker.RangePicker
      value={valueObj}
      defaultValue={defaultValueObj}
      format={format || defaultFormat}
      {...props}
    />
  )
}

function parseDates<T>(
  value: LocalDateRangePickerProps['value'],
  tz: LocalDateRangePickerProps['tz']
): RangeValueType<Dayjs> | undefined {
  if (!value) return undefined

  if (typeof value === 'string') {
    const [start, end] = value.split(',')
    const startDate = dayjs.parse(start, { tz })
    const endDate = dayjs.parse(end, { tz })

    return startDate && endDate ? [startDate, endDate] : undefined
  }

  return value as any
}
