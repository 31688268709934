import { useCustomFormLabels } from '@/modules/custom-form/hooks'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'

export const useTicketLabel = () => {
  const { ticket } = useTicket()
  const { customForm } = useCustomForm()
  const { l } = useCustomFormLabels(customForm.id, ticket)
  return l
}
