import { Icon } from '@/ui'
import { FC } from 'react'

export const TableEmpty: FC = () => {
  return (
    <tbody>
      <tr>
        <td colSpan={5} className={'text-center p-20'}>
          <Icon name={'fa:info-circle'} className={'mr-8'} />
          <span>No Components</span>
        </td>
      </tr>
    </tbody>
  )
}
