import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { getLineItemName } from '@/utils/get-line-item-name'
import { Checkbox, Form, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../atoms'

export const WebOptions = () => {
  const { labels } = useApp()
  const { company } = useSession()
  const hideItemFields = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s._hide_item_fields), []))
  const setCustomForm = useSetAtom(setCustomFormAtom)

  const options = useMemo(() => {
    const fieldAliases = JSON.parse(company.field_aliases) ?? {}

    return CATEGORY_KIND_FIELDS.map((field) => ({
      label: `${getLineItemName({ field, labels, fieldAliases }).name} (${field})`,
      value: field
    }))
  }, [labels, company.field_aliases])

  return (
    <div className="flex-1">
      <h3 className="font-bold">Web - Options</h3>
      <Form layout="vertical">
        <Form.Item>
          <Checkbox
            checked={!!hideItemFields?.enabled}
            onChange={(e) => {
              setCustomForm({
                _hide_item_fields: {
                  ...hideItemFields,
                  enabled: e.target.checked
                }
              })
            }}
          >
            Hide certain line item fields from the ticket
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Select
            placeholder="Select"
            options={options}
            mode="multiple"
            value={hideItemFields?.fields}
            onChange={(value) => {
              setCustomForm({
                _hide_item_fields: {
                  ...hideItemFields,
                  fields: value
                }
              })
            }}
          />
        </Form.Item>
      </Form>
    </div>
  )
}
