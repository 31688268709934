import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { Radio, RadioChangeEvent } from 'antd'
import { useAtom } from 'jotai'

export const CalendarViewSelect = () => {
  const [view, setView] = useAtom(calendarViewAtom)

  const handleChange = (e: RadioChangeEvent) => {
    setView(e.target.value)
  }

  // note: changed this to radio just so that it looks like request in OC-3975
  return (
    <Radio.Group value={view} onChange={handleChange}>
      <Radio value="month">Month</Radio>
      <Radio value="week">Week</Radio>
      <Radio value="three_days">3-Day</Radio>
    </Radio.Group>
  )
}
