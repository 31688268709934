import { AppLogo } from '@/components/app-logo'
import { AppLink } from '@/ui'
import { Button } from 'antd'
import { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

type Props = {
  children?: ReactNode
}

export const SecureLayout: FC<Props> = ({ children }) => {
  return (
    <div className="bg-[url('/assets/images/non-auth-bg-pattern.png')] bg-repeat overflow-auto relative h-screen w-screen">
      <header className="w-full md:fixed top-0 left-0 z-10 flex py-16 px-20 items-center justify-between">
        <AppLink to="/public">
          <AppLogo className="w-[240px]" dark />
        </AppLink>
        <AppLink to="/auth/login">
          <Button type="primary">Login</Button>
        </AppLink>
      </header>
      <div className="mt-[30px] md:mt-[100px]">{children ? children : <Outlet />}</div>
    </div>
  )
}
