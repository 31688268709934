import { categoryApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'

export const useCategoriesQuery = () => {
  const categoriesQuery = useQuery({
    ...categoryApi.list({
      limit: 'None' as any,
      serializer: 'v2'
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...item,
        parent_category: data.items.find((i) => i.id === item.parent_category) || null
      }))
    })
  })

  return {
    categoriesQuery
  }
}
