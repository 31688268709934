import { equipmentApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { DetailedEquipment, detailedEquipmentSchema } from '../schema'

export const useEquipmentsQuery = () => {
  const { id } = useParams<{ id: string }>()

  const equipmentQuery = useQuery({
    ...equipmentApi.get<DetailedEquipment>(Number(id)),
    select: (data) => detailedEquipmentSchema.parse(data),
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  return { equipmentQuery }
}
