import { ApiResource, FieldsParam, MutationReturn, ObjectReturn, RequestConfig } from '@/types/api/core'
import { isEmpty } from 'lodash'
import { R, createResourceApi, getParams } from '../core'

export type SubscriberRespondPayload = { type: 'accept' | 'reject'; notes?: string }

export type TicketSubscriberApi = ApiResource & {
  getByToken: <TData = any>(
    token?: string | undefined,
    params?: FieldsParam<TData>,
    config?: RequestConfig
  ) => ObjectReturn<TData>

  respond: <TData = any, TPayload = SubscriberRespondPayload>(
    token?: string,
    params?: FieldsParam<TData>,
    config?: RequestConfig
  ) => MutationReturn<TData, TPayload>
}

export const _ticketSubscriberApi: TicketSubscriberApi = {
  ...createResourceApi('v2/ticket_subscribers'),
  getByToken: (token, params, config) => ({
    enabled: !isEmpty(token),
    queryKey: ['v2/ticket_subscribers', token, 'retrieve_subscriber', params],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', `/v2/ticket_subscribers/${token}/retrieve_subscriber/`, { ...config, signal, params: getParams(params) })
  }),
  respond: (token, params, config) => ({
    enabled: !isEmpty(token),
    mutationFn: (data: any) =>
      R('POST', `/v2/ticket_subscribers/${token}/${data.type}/`, {
        ...config,
        params: { ...getParams(params) },
        data
      })
  })
}
