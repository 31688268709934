import { layout, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { SecretFormView, SecretListView } from './views'

export const routes: RouteObject[] = [
  route('account/secrets-manager', layout('Secrets Manager', 'Secrets Manager'), [
    route('index', SecretListView),
    route('add', SecretFormView),
    route(':id/edit', SecretFormView)
  ])
]
