import { useRedirect, useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { Navigation } from '@/modules/navigation'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { Icon, IconName } from '@/ui/icons'
import { Menu } from 'antd'
import cn from 'classnames'
import { useAtom } from 'jotai'
import { FC, useEffect, useMemo } from 'react'
import { To, useLocation } from 'react-router-dom'
import { isMobileMenuOpenAtom } from '../atoms'

type MenuItem = {
  key: string
  label: string
  icon: IconName
  to?: To
  onClick?: () => void
  danger?: boolean
}

export const SidebarMobile: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useAtom(isMobileMenuOpenAtom)
  const { endUserSession } = useBootstrap()
  const { toggleIsAppOff } = useRedirect()
  const { user, contact } = useSession()
  const profileLabel = useMemo(() => `${contact.first_name} ${contact.last_name} (${user?.username})`, [contact, user])

  const bottomMenuItems: MenuItem[] = useMemo(
    () => [
      { key: 'disable-v2', label: 'Disable V2 App', icon: 'fa-brands:react', onClick: toggleIsAppOff },
      { key: 'my-profile', label: profileLabel, icon: 'fa:user-circle', to: '/my_profile' },
      { key: 'log-out', label: 'Log Out', icon: 'fa:sign-out-alt', onClick: endUserSession, danger: true }
    ],
    [endUserSession, profileLabel, toggleIsAppOff]
  )

  const { pathname } = useLocation()
  useEffect(() => {
    setIsMenuOpen(false)

    // note: just need to watch pathname
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <aside
        className={cn(
          'z-[9999] fixed top-0 left-0 h-screen w-full bg-dark-container',
          { hidden: !isMenuOpen },
          'flex flex-col'
        )}
      >
        <div className={'h-[5%] flex flex-row items-center px-18 py-10 border-b border-dark-border'}>
          <Button
            iconClassName={'text-white text-20'}
            className={'mr-10'}
            type={'text'}
            iconName="fa:close"
            onClick={() => setIsMenuOpen(false)}
          />
          <h4 className={'text-white m-0 p-0'}>Menu</h4>
        </div>

        <div className={cn('h-[70%] overflow-y-auto')}>
          <Navigation collapsed={false} />
        </div>

        <div className="h-[25%]">
          <Menu
            className={cn('oc-nav-menu px-16 transition-all duration-300 ease-in-out ')}
            selectedKeys={[pathname]}
            theme="dark"
            mode={'inline'}
            items={bottomMenuItems.map(
              (item) =>
                ({
                  key: item.to || item.key,
                  label: (
                    <AppLink
                      className={cn('flex flex-row items-center gap-8', { '!text-red-500': item.danger })}
                      to={item.to}
                      onClick={item.onClick}
                    >
                      <Icon className={'menu-item-icon'} name={item.icon} />
                      <span className={'menu-item-label'}>{item.label}</span>
                    </AppLink>
                  )
                }) as any
            )}
          />
        </div>
      </aside>
    </>
  )
}
