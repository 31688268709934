import { transformationLogApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Empty, Skeleton } from 'antd'
import { useEffect } from 'react'
import { useTicket } from '../../../../../../hooks'
import { ReportLog } from '../../types'
import { FileCard } from './file-card'

export const RecentReports = () => {
  const { ticket } = useTicket()

  const logQuery = useQuery({
    ...transformationLogApi.list<ReportLog>({
      ticket_id__eq: ticket.id || 0,
      order: '-created_at',
      limit: 100
    })
  })

  // refetch every 1 second if any item has status='P' or 'I'
  useEffect(() => {
    const interval = setInterval(() => {
      if (logQuery.data?.items.some((item) => item.status === 'P' || item.status === 'I')) {
        logQuery.refetch()
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [logQuery, logQuery.data?.items])

  return (
    <>
      <h5 className={'font-medium'}>Recent Reports</h5>
      <div className={'border rounded  p-10 flex flex-col gap-10 max-h-[250px] overflow-y-auto'}>
        {logQuery.isLoading && <Skeleton />}

        {!logQuery.isLoading && !logQuery.data?.items.length && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Reports'} />
        )}

        {logQuery.data?.items.map((reportLog) =>
          reportLog.report_files?.map((file) => <FileCard key={file.id} reportLog={reportLog} file={file} />)
        )}
      </div>
    </>
  )
}
