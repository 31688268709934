import { PropertyQuerySelect, PropertyQuerySelectProps } from '@/components'
import { useApp, useModalState, useSession } from '@/hooks'
import { categoryApi, clientRecordApi } from '@/services/api-service'
import { ActionButton, Button, ButtonProps, ColorInput, Modal, QuerySelect } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { merge } from 'lodash'
import { FC } from 'react'
import { TypeSelect } from '../../components'
import { RecordTypeOption } from '../constants'
import { Record } from '../schemas'

type Props = ButtonProps & {
  record?: Record
  recordType: RecordTypeOption
}

export const RecordFormModal: FC<Props> = ({ record, recordType, ...buttonProps }) => {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const [form] = Form.useForm()
  const { company } = useSession()
  const { notification } = useApp()
  const saveMutation = useMutation(record?.id ? clientRecordApi.patch() : clientRecordApi.create())

  const handleSave = async () => {
    try {
      await form.validateFields()

      const formData = form.getFieldsValue()
      formData.data = merge({}, record?.data, formData.data)

      if (!record?.id) {
        formData.label = recordType.label
        formData.company = company.id
      }

      await saveMutation
        .mutateAsync(formData)
        .then(() => {
          closeModal()
          notification.success({
            message: `${recordType.type} Saved`,
            description: `${recordType.type} has been saved`
          })
        })
        .catch(() => {
          notification.error({
            message: 'Save Error',
            description: `Failed to save ${recordType.type.toLowerCase()}`
          })
        })
    } catch (error) {
      notification.warning({
        message: 'Validation Error',
        description: 'Please check the form for errors'
      })
    }
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          width={600}
          iconName={buttonProps.iconName}
          title={record?.id ? `Edit ${recordType.type} ${record?.char_1}` : `Add ${recordType.type}`}
          onCancel={closeModal}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-button' }}
          okText="Save"
          cancelText="Cancel"
          destroyOnClose
          afterOpenChange={() => form.resetFields()}
        >
          <Form
            className={'rounded pt-10'}
            form={form}
            initialValues={record}
            onFinish={handleSave}
            layout="horizontal"
            labelCol={{ span: 7 }}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>

            {recordType.fields.map((field) => (
              <FieldFormItem key={field.key} field={field} />
            ))}

            <Button type={'primary'} htmlType={'submit'} hidden />
          </Form>
        </Modal>
      )}
    </>
  )
}

function FieldFormItem({ field }: { field: RecordTypeOption['fields'][0] }) {
  let input = <Input allowClear={!field.required} />

  if (field.input === 'property') input = <PropertyInput allowClear={!field.required} />
  else if (field.input === 'type') input = <TypeSelect allowClear={!field.required} />
  else if (field.input === 'color') input = <ColorInput className={'w-full'} showText allowClear={!field.required} />
  else if (field.input === 'category') input = <CategoryInput allowClear={!field.required} />

  return (
    <Form.Item
      key={field.key}
      label={field.name}
      name={field.key.split('__')}
      tooltip={field.tooltip}
      rules={[{ required: field.required }]}
      data-cy={`record-${field.key}`}
    >
      {input}
    </Form.Item>
  )
}

function PropertyInput({ value, ...props }: PropertyQuerySelectProps) {
  return <PropertyQuerySelect value={Number(value) || undefined} valueField={'id'} {...props} />
}

function CategoryInput(props: PropertyQuerySelectProps) {
  return (
    <QuerySelect
      apiEndpoint={categoryApi.list}
      apiQueryParams={{
        fields: 'id,parent_category__name,name',
        order: 'parent_category__name,name',
        parent_category__isnull: false
      }}
      apiValueBy={'id'}
      apiSearchBy={(text) => ({
        'Q[]': text ? [`parent_category__name__icontains|${text}`, `name__icontains|${text}`, 'or'] : undefined
      })}
      renderOption={(p) => ({
        value: p.id,
        label: `${p.parent_category__name} / ${p.name}`
      })}
      {...props}
    />
  )
}
