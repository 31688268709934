import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { ResourceCalendarEvent } from '../../schemas'
import { calculateProgress } from './helpers'

export function JobEventBar({ event, date }: { event: ResourceCalendarEvent; date: Dayjs }) {
  const progress = useMemo(() => {
    if (!event || !event.start_datetime || !event.end_datetime) return { offset: 0, width: 0 }

    const startDate = dayjs.parse(event.start_datetime) || dayjs.now()
    const endDate = dayjs.parse(event.end_datetime) || dayjs.now()

    return calculateProgress(startDate, endDate, date)
  }, [event, date])

  return (
    <div
      className={'absolute h-full bg-primary rounded'}
      style={{ left: `${progress.offset}%`, width: `${progress.width}%` }}
    />
  )
}
