import { ListParams } from '@/types/api/core'

export const getSearchQuery = (
  searchText: string,
  searchBy: string | ((searchText: string) => ListParams) = 'search',
  searchType?: string
) => {
  searchText = (searchText || '').toString().trim()

  if (!searchText) return {}

  if (typeof searchBy === 'string') {
    return { [`${searchBy}__${searchType || 'icontains'}`]: searchText }
  } else {
    return searchBy(searchText)
  }
}
