import { ViewTicketLink } from '@/modules/ticket/components'
import { Property } from '@/modules/ticket/form/schemas'
import { Button, ButtonProps, Icon, IconName, Popover } from '@/ui'
import { Spin } from '@/ui/spin'
import { FC, useMemo } from 'react'
import { useTicket } from '../../../hooks'
import { ScriptedButtonProvider } from './context'
import { getAlignClassName } from './helpers'
import { SCRIPTED_BUTTONS_CONFIG_MAP } from './registry'

type Props = {
  property: Property
  buttonProps?: ButtonProps
}

export const ScriptedButton: FC<Props> = ({ property, buttonProps }) => {
  const { ticket, isNew } = useTicket()

  const defaultConfig = SCRIPTED_BUTTONS_CONFIG_MAP[property._buttonConfig?.type || '']

  const { defaultButtonProps } = useDefaultButtonProps({
    property,
    buttonProps: {
      ...buttonProps,
      disabled: buttonProps?.disabled || isNew || ticket.status === 'X'
    }
  })

  if (!defaultConfig) {
    return (
      <Popover
        title={
          <div className={'flex items-center gap-6'}>
            <Icon name={'fa:exclamation-triangle'} />
            <span>Needs Migration</span>
          </div>
        }
        content={
          <div>
            <span>This button is not compatible with new interface.</span> <br />
            <span>Please </span>
            <ViewTicketLink
              v={1}
              ticketId={ticket.id}
              formId={ticket.custom_form_id}
              params={{ click_button: property.id }}
            >
              (click here)
            </ViewTicketLink>
            <span> to use it in old interface.</span>
          </div>
        }
      >
        <Button disabled>{property.name}</Button>
      </Popover>
    )
  }

  return (
    <ScriptedButtonProvider property={property} buttonProps={defaultButtonProps}>
      <div className={getAlignClassName(property?._buttonConfig?.align)}>{defaultConfig.render()}</div>
    </ScriptedButtonProvider>
  )
}

export const ScriptedButtonShim: FC<{ property: Property; loading?: boolean }> = ({ property, loading = false }) => {
  const { defaultButtonProps } = useDefaultButtonProps({ property })

  return (
    <div className={getAlignClassName(property?._buttonConfig?.align)}>
      <Spin size={'small'} spinning={loading}>
        <Button primary {...defaultButtonProps} type={'dashed'}>
          {property.name}
        </Button>
      </Spin>
    </div>
  )
}

export const useDefaultButtonProps = ({ property, buttonProps }: { property: Property; buttonProps?: ButtonProps }) => {
  const propertyConfig = property._buttonConfig
  const buttonTypeKey = propertyConfig?.type || ''
  const defaultConfig = SCRIPTED_BUTTONS_CONFIG_MAP[buttonTypeKey]

  const defaultButtonProps: ButtonProps = useMemo(() => {
    const buttonIcon = propertyConfig?.icon || ''
    const buttonAlign = propertyConfig?.align || 'center'
    const buttonVariant = propertyConfig?.variant || 'primary'
    const buttonShape = propertyConfig?.shape || 'default'

    const iconName = ((buttonIcon ? `fa:${buttonIcon}` : null) || defaultConfig?.iconName || undefined) as IconName

    return {
      iconName,
      shape: buttonShape,
      type: buttonVariant === 'default' ? 'default' : 'primary',
      block: buttonAlign === 'stretch',
      success: buttonVariant === 'success',
      info: buttonVariant === 'info',
      danger: buttonVariant === 'danger',
      ...buttonProps
    }
  }, [
    propertyConfig?.icon,
    propertyConfig?.align,
    propertyConfig?.variant,
    propertyConfig?.shape,
    defaultConfig?.iconName,
    buttonProps
  ])

  return { defaultButtonProps }
}
