import { APP_SITE } from '@/constants/app'
import { SiteTheme } from '@/site'
import { darkModeAtom, motionAtom, siteThemeAtom } from '@/themes'
import { theme } from 'antd'
import { GlobalToken } from 'antd/es/theme/interface'
import { useAtom } from 'jotai/react'
import { isEqual } from 'lodash'
import { useCallback, useMemo } from 'react'

const { useToken } = theme

type AppThemeReturn = {
  token: GlobalToken
  darkMode: boolean
  setDarkMode: (value: boolean) => void
  theme: SiteTheme | null
  setTheme: (theme: Partial<SiteTheme> | null) => void
  motion: boolean
  setMotion: (value: boolean) => void
}

/**
 * Hook that provides the application's current theme settings and related functionality.
 */
export const useAppTheme = (): AppThemeReturn => {
  const { token } = useToken()

  const [darkMode, setDarkMode] = useAtom(darkModeAtom)
  const [siteTheme, setSiteTheme] = useAtom(siteThemeAtom)
  const [motion, setMotion] = useAtom(motionAtom)

  const setTheme = useCallback(
    (theme: Partial<SiteTheme> | null) => {
      // note: deep comparison
      if (isEqual(siteTheme, theme)) return

      // clear the theme
      if (!theme) return setSiteTheme(null)

      // only set keys that are not undefined
      const _theme = { ...APP_SITE.theme } as SiteTheme
      if (theme.name) {
        _theme.name = theme.name
        _theme.nameProps = theme.nameProps || null
      }
      if (theme.iconUrl) {
        _theme.iconUrl = theme.iconUrl
        _theme.iconProps = theme.iconProps || null
      }
      if (theme.color) _theme.color = theme.color

      setSiteTheme(_theme)

      // refresh the meta theme
      setTimeout(() => window.site.refreshMeta(), 0)
    },
    [siteTheme, setSiteTheme]
  )

  const theme = useMemo(() => ({ ...APP_SITE.theme, ...siteTheme }), [siteTheme])

  return {
    token: token,
    darkMode,
    setDarkMode,
    theme,
    setTheme,
    motion,
    setMotion
  }
}
