import { ScriptedButtonConfig } from '../../../types'
import { WorkApprovedByXtoButton } from './WorkApprovedByXtoButton'

export const IpsmWorkApprovedByXtoButtonConfig: ScriptedButtonConfig = {
  type: 'ipsm_work_approved_by_xto_button',
  iconName: 'fa:check-to-slot',
  label: 'Work Approved By XTO',
  group: 'IPSM',
  render: () => <WorkApprovedByXtoButton />
}
