import { Module } from '@/modules/module/types'
import { checkAccess } from '@/services/auth-service'
import { Permission } from '@/types/auth-user'
import { IconName } from '@/ui/icons'
import { sortBy, startCase } from 'lodash'
import { MENU_ITEMS, MENU_ITEMS_MOBILE, MenuItem } from './constants'

/**
 * Get the accessible menu items.
 * This is based on the user's permissions.
 * @param perms
 * @param isMobile
 */
export const getAccessibleMenuItems = (perms: Permission[], isMobile?: boolean): MenuItem[] => {
  if (isMobile) {
    return MENU_ITEMS_MOBILE.filter((item) => checkAccess(perms, item.perms || []))
  }

  return MENU_ITEMS.filter((item) => checkAccess(perms, item.perms || []))
}

export const moduleToMenuItem = (module: Module, forceV2Tickets: boolean): MenuItem => {
  const mData = module.data
  const viewType = (module.view_type || '').toLowerCase()

  const link: MenuItem = {
    v: 1,
    key: `/m/${module.id}`,
    icon: getModuleIcon(module),
    label: module?.title || `Module ${module.id}`,
    items: []
  }

  if (viewType === 'c') {
    link.to = `/tickets/create/${mData?.custom_form_id}/:${module.id}`
  } else if (viewType === 'mt') {
    link.to = '/time/u/'
  } else if (viewType === 'tm') {
    link.to = '/time_manager/'
  } else if (viewType === 'sd') {
    link.key = `/m2/${module.id}`
    link.items = ['employees', 'equipment', 'coverage'].map((i) => ({
      v: 2,
      label: startCase(i),
      to: `/m2/${module.id}/schedule/${i}`
    }))
  } else if (viewType === 'rm') {
    link.v = 2
    link.key = `/resource_manager/${module.id}`
    link.to = `/resource_manager/${module.id}`
  } else if (viewType === 'jm') {
    link.v = 2
    link.to = `/m2/${module.id}/job-manager`
  } else if (viewType === 'tc') {
    link.v = 2
    link.to = `/m2/${module.id}/${viewType}/${module.path}/`
  } else if (viewType === 'b') {
    link.v = 2
    link.to = `/m/${module.id}/${viewType}/${module.path}/`
  } else {
    if (mData?.cardType === 'D') {
      link.to = `/tickets/map_logistics/${module.id}/`
    } else {
      if (module.use_selected_view) {
        link.to = `/tickets/map/${module.id}/view/${module.selected_view}/`
      } else {
        const isM2 = (forceV2Tickets && (viewType === 't' || viewType === 'l')) || viewType === 'tc'
        if (isM2) {
          link.v = 2
          link.to = `/m2/${module.id}/${viewType}/${module.path}/`
        } else {
          link.to = `/m/${module.path}/${viewType}/${module.id}/`
        }
      }
    }
  }

  return link
}

/**
 * Transform modules into menu items.
 * - Filter by is_active and for_desktop
 * - Sort by position
 * - Map to MenuItem
 * @param modules
 * @param contactId
 * @param forceTickets
 */
export const processModules = (modules: Module[], contactId: number, forceTickets: boolean) => {
  modules = modules.filter((m) => m.is_active && m.for_desktop && m.contacts?.includes(contactId))
  modules = sortBy(modules, 'position')
  return modules.map((m) => moduleToMenuItem(m, forceTickets))
}

/**
 * Get the module icon.
 * Use font_icon or icon_url
 * If none, returns `mi:deployed_code` as default
 * @param module
 */
export const getModuleIcon = (module: Module) => {
  if (module.font_icon && module.font_icon.length) {
    return `mi:${module.font_icon}` as IconName
  } else if (module.icon_url && module.icon_url.length) {
    return `url:${module.icon_url}` as IconName
  }

  return 'mi:deployed_code'
}

/**
 * Get the active menu key.
 * This is based on the current URL.
 */
export const getActiveKeys = (pathname: string): [string[], string[]] => {
  let currentModule = pathname
  const pathnameDepth = pathname.split('/').filter((val) => Boolean(val)).length

  if (pathname.startsWith('/m2/')) {
    currentModule = currentModule.split('/').splice(0, 3).join('/')
    pathname = pathnameDepth > 4 ? pathname.split('/').splice(0, 5).join('/') : pathname
  } else {
    currentModule = `/${currentModule.split('/')[1]}/`
    pathname = pathnameDepth > 2 ? pathname.split('/').splice(0, 3).join('/') : pathname
  }

  const active = [pathname]

  // hack: special case for tickets/v2
  if (pathname === '/tickets/v2') {
    active.push('/tickets/')
  } else if (pathname === '/loads/v2') {
    active.push('/loads/')
  } else if (pathname === '/loads') {
    active.push('/loads/')
  } else if (pathname === '/tickets') {
    active.push('/tickets/')
  }

  return [active, [currentModule]]
}
