import { LINE_ITEM_FIELDS } from '@/constants/general'
import { AmountField } from '../amount-field'
import { AssigneeField } from '../assignee-field'
import { FormItem } from '../form-item'
import { UserTextField } from '../user-text-field'

type FieldName = (typeof LINE_ITEM_FIELDS)[number]['field']

const fieldNames: FieldName[] = [
  'notes',
  'unit',
  'standby_rate',
  'usage_rate',
  'tax_rate',
  'cost',
  'minimum',
  'barrels',
  'amount',
  'units_used',
  'units_min',
  'hands',
  'discountable_amount',
  'taxable_amount',
  'units_standby',
  'user_long_text_1',
  'user_decimal_1',
  'user_decimal_2',
  'user_decimal_3',
  'discount',
  'status',
  'assignee',
  'start_datetime',
  'end_datetime',
  'user_datetime_1',
  'user_datetime_2',
  'user_integer_1',
  'user_integer_2',
  'user_integer_3',
  'user_integer_4',
  'user_text_1',
  'user_text_2',
  'user_text_3',
  'user_text_4',
  'user_text_5',
  'user_text_6',
  'user_text_7',
  'user_text_8'
]

export const allFieldsMap = fieldNames.reduce(
  (acc, fieldName) => {
    const fieldItem = LINE_ITEM_FIELDS.find((item) => item.field === fieldName)
    if (!fieldItem) {
      return acc
    }

    if (fieldName === 'assignee') {
      acc[fieldItem.field] = <FormItem fieldItem={fieldItem} Component={<AssigneeField />} />
      return acc
    }

    // user_text_... fields should be Select component with dynamic options fetched from API
    if (fieldName.startsWith('user_text')) {
      acc[fieldItem.field] = <FormItem fieldItem={fieldItem} Component={<UserTextField fieldItem={fieldItem} />} />
      return acc
    }

    // amount field is calculated based on other fields
    if (fieldName === 'amount') {
      acc[fieldItem.field] = <FormItem fieldItem={fieldItem} Component={<AmountField />} />
      return acc
    }

    acc[fieldName] = <FormItem fieldItem={fieldItem} />
    return acc
  },
  {} as { [key in FieldName]?: JSX.Element }
)
