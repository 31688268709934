import { useApp } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { programmedReportApi } from '@/services/api-service'
import { ProgrammedReport } from '@/types/programmed-report'
import { Checkbox, Form, Input } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { ActionsButtons } from './components/action-buttons'
import { Attributes } from './components/attributes'
import { BaseReportSelect } from './components/base-report-select'
import { CompanySelect } from './components/company-select'
import { CurrentEditingTransformTaskCard } from './components/current-editing-transform-task-card'
import { TransformStepsCard } from './components/transform-steps-card'
import { TransformationTasksCard } from './components/transformation-tasks-card'

export const ProgrammedReportEtlEditorView = view<any, ProgrammedReport & { title: string }>(Component, {
  loader: async ({ params }) => {
    const programmedReport = await fetchQuery(programmedReportApi.get<ProgrammedReport>(Number(params.id)))
    const transformation =
      typeof programmedReport?.transformation !== 'number' ? programmedReport?.transformation : undefined

    return {
      ...programmedReport,
      transformation,
      title: `Edit ${programmedReport.report?.name || ''}`
    }
  },
  form: { layout: 'vertical' },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const form = Form.useFormInstance()
  const { l } = useApp()
  const reportName = useWatch(['report', 'name'], form)

  return (
    <FormView
      header={{
        title: l('EtlEditorTitle') + ' ' + reportName,
        actions: <ActionsButtons form={form} />,
        breadcrumbs: true
      }}
    >
      <Form.Item name="id" hidden />
      <Form.Item name="report" hidden />
      <Form.Item name="transformation" hidden />
      <div className="grid grid-cols-3 gap-x-16 mb-24">
        <Form.Item label="Report Name" name={['report', 'name']} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Report Description" name={['report', 'description']}>
          <Input />
        </Form.Item>
        <Form.Item label=" ">
          <Checkbox>Public</Checkbox>
        </Form.Item>
        <Form.Item label="Company">
          <Form.Item name="company" hidden />
          <CompanySelect />
        </Form.Item>
        <Form.Item label="Base Report">
          <Form.Item name="base_report" hidden />
          <BaseReportSelect />
        </Form.Item>
      </div>
      <div className="grid grid-cols-12 gap-x-16">
        <div className="col-span-3 2xl:col-span-2">
          <TransformStepsCard />
        </div>
        <div className="col-span-4 2xl:col-span-5">
          <TransformationTasksCard />
        </div>
        <div className="col-span-5">
          <CurrentEditingTransformTaskCard />
          <Attributes />
        </div>
      </div>
    </FormView>
  )
}
