import { CalendarView } from '@/modules/jas/scheduler/types'
import cn from 'classnames'
import { Dayjs } from 'dayjs'
import React, { FC } from 'react'
import { DayColCell } from '../day-col-cell'
import { TitleColCell } from '../title-col-cell'

type CalendarTableRowProps = {
  renderTitleCell: (record: any) => React.ReactNode
  record: any
  renderDateCell: ((date: Dayjs, record: any) => React.ReactNode) | undefined
  dateColumns: Dayjs[]
  visibleDays: CalendarView
  hiddenRows: Set<number> | undefined
  rowIndex: number
}

export const CalendarTableRow: FC<CalendarTableRowProps> = ({
  renderTitleCell,
  record,
  renderDateCell,
  dateColumns,
  visibleDays,
  hiddenRows,
  rowIndex
}) => {
  return (
    <div className={cn('flex group relative w-full', { hidden: hiddenRows && hiddenRows.has(rowIndex) })}>
      <div className="absolute top-0 left-0 h-full w-full bg-black opacity-5 hidden group-hover:block z-20 pointer-events-none" />
      <TitleColCell resource={record as any}> {renderTitleCell(record)}</TitleColCell>
      <div className="relative flex w-full">
        {dateColumns.map((date, j) => (
          <DayColCell
            key={j}
            colIndex={j}
            visibleDays={visibleDays}
            data-cy={`calendar-cell-${date.format('YYYY-MM-DD')}`}
          >
            {renderDateCell ? renderDateCell(date, record) : null}
          </DayColCell>
        ))}
      </div>
    </div>
  )
}
