import { z } from 'zod'

export const reportModuleSchema = z.object({
  data_json: z.string().nullish(),
  title: z.string()
})

export const filterfieldSchema = z.object({
  id: z.number(),
  field: z.string(),
  filter_type: z.string(),
  exclude: z.boolean(),
  filter_value: z.any(),
  filter_value2: z.any(),
  options: z.any(),
  editable: z.boolean(),
  field_type: z.string(),
  field_verbose: z.string(),
  path: z.string(),
  path_verbose: z.string(),
  _name: z.string().nullish()
})

export const attributeSchema = z.object({
  id: z.number(),
  name: z.string(),
  key: z.string(),
  type: z.string(),
  values: z.array(z.string()),
  editable: z.boolean()
})

export const customReportSchema = z.object({
  id: z.number(),
  filterfield_set: z.array(filterfieldSchema).nullish(),
  displayfield_set: z.array(
    z.object({
      name: z.string()
    })
  ),
  options: z.object({
    field_aliases_json: z.string()
  }),
  type: z.string().nullish(),
  description: z.string().nullish(),
  name: z.string().nullish(),
  root_model_name: z.literal('ticket').or(z.literal('line item'))
})

export const programmedReportSchema = z.object({
  id: z.number(),
  report: customReportSchema.nullish(),
  base_report: z
    .object({
      editable_fields: z.array(z.string()).nullish()
    })
    .nullish(),
  base_custom_report: customReportSchema.nullish(),
  attributes: z.array(attributeSchema).nullish(),
  type: z.string().nullish()
})

export type FilterField = z.infer<typeof filterfieldSchema>
export type CustomReport = z.infer<typeof customReportSchema>
export type ProgrammedReport = z.infer<typeof programmedReportSchema>
export type Attribute = z.infer<typeof attributeSchema>
export type ReportModule = z.infer<typeof reportModuleSchema>
