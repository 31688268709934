import { ViewTicketLink } from '@/modules/ticket/components'
import { Icon } from '@/ui'
import { Alert } from 'antd'
import { FC } from 'react'
import { useTicket } from '../../hooks'

export const FooterRibbon: FC = () => {
  const { ticket } = useTicket()

  if (!ticket.parent__id) return null

  return (
    <div className={'px-10 py-6'}>
      <Alert
        showIcon
        icon={<Icon name={'fa:info-circle'} />}
        message={
          <span className={'align-middle'}>
            <span>This ticket is part of </span>
            <ViewTicketLink ticketId={ticket.parent__id} formId={ticket.parent__custom_form_id || 0} target={'_blank'}>
              <strong>
                {ticket.parent__custom_form__name} #{ticket.parent__name}
                <Icon name={'fa:external-link'} className={'text-[6px] ml-4 align-super'} />
              </strong>
            </ViewTicketLink>
          </span>
        }
      />
    </div>
  )
}
