import { NOTIFICATION_TEMPLATE_CONTENT_BASE, NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { view } from '@/routing'
import { navigate } from '@/routing/helpers'
import { companyApi, customFormApi, notificationTemplateApi, ticketApi } from '@/services/api-service'
import { NotificationTemplate } from '@/types/notification-template'
import { QuerySelect, SaveButton, Select } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Space, Switch } from 'antd'
import DOMPurify from 'dompurify'
import { useParams } from 'react-router-dom'
import { ContentEditor } from './content-editor'
import { FieldNamePicker } from './field-name-picker'

export const AddEditNotificationTemplateView = view(Component, {
  title: ({ params }) => (params?.id ? 'Edit Notification Template' : 'Add Notification Template')
})

function Component() {
  const [form] = Form.useForm()
  const templateType = Form.useWatch('type', { form })
  const { id } = useParams()
  const customFormsQuery = useQuery(customFormApi.list<{ id: number; name: string }>({ fields: 'id,name' }))
  const createNotificationTemplateMutation = useMutation(notificationTemplateApi.create())
  const updateNotificationTemplateMutation = useMutation(notificationTemplateApi.update())
  const { notification } = useApp()
  const isSuperAdmin = useIsSuperAdmin()

  const notificationTemplateQuery = useQuery({
    ...notificationTemplateApi.get<NotificationTemplate>(Number(id)),
    enabled: !!id
  })

  const handleSave = async () => {
    const values = await form.validateFields()

    // If the template type is SMS, set the content to the plain text content, add line breaks and sanitize the content
    if (values.type === 'S') {
      values.content = DOMPurify.sanitize(values.content, {
        ALLOWED_TAGS: ['br'],
        ALLOWED_ATTR: []
      }).replace(/<br>/g, '\n')
    }

    if (id) {
      await updateNotificationTemplateMutation.mutateAsync({ id, ...values })
    } else {
      const notificationTemplate = await createNotificationTemplateMutation.mutateAsync(values)
      setTimeout(() => {
        navigate(SETTINGS_PATHS.editNotificationTemplate(notificationTemplate.id))
      }, 500)
    }

    notification.success({ message: 'Template saved successfully' })
  }

  const notificationTemplate = notificationTemplateQuery.data
  if (!!id && notificationTemplateQuery.isLoading) return <Spin isCentered spinning />

  return (
    <PageView
      header={{
        backButton: true,
        breadcrumbs: true,
        title: id ? 'Edit Notification Template' : 'Add Notification Template',
        actions: [<SaveButton key={'save'} onSave={handleSave} />]
      }}
      loading={notificationTemplateQuery.isInitialLoading || customFormsQuery.isInitialLoading}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={
          notificationTemplate
            ? {
                ...notificationTemplate,
                content:
                  notificationTemplate.type === 'S'
                    ? notificationTemplate.content?.replace(/\n/g, '<br>')
                    : notificationTemplate.content
              }
            : {
                name: 'Draft Template',
                type: 'E',
                custom_forms: [],
                subject: '',
                content: '',
                status: 'D'
              }
        }
      >
        <Form.Item name="id" hidden />
        <div className="flex items-center gap-x-12 mt-20">
          <Form.Item label="Template Name" name="name">
            <Input placeholder="Enter template name" />
          </Form.Item>
          <Form.Item label="Template Type" name="type">
            <Select
              placeholder="Select"
              options={Object.entries(NOTIFICATION_TEMPLATE_TYPE).map(([key, value]) => ({ label: value, value: key }))}
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input placeholder="Enter description" />
          </Form.Item>
          {templateType === 'E' && (
            <Form.Item label="Content Base" name="content_base" className={'min-w-[150px]'}>
              <Select
                placeholder="Select"
                defaultValue={'P'}
                options={Object.entries(NOTIFICATION_TEMPLATE_CONTENT_BASE).map(([key, value]) => ({
                  label: value,
                  value: key
                }))}
              />
            </Form.Item>
          )}
          {isSuperAdmin && (
            <Form.Item label="Company" name={'company_id'}>
              <QuerySelect
                apiEndpoint={companyApi.list}
                apiQueryParams={{ fields: 'id,name' }}
                apiValueBy={'id'}
                apiSearchBy="name"
                className="min-w-[200px]"
                placeholder="Select"
                popupMatchSelectWidth={false}
                renderOption={(company) => ({ value: company.id, label: company.name })}
              />
            </Form.Item>
          )}
          <Form.Item label="Status" className="ml-auto">
            <Space>
              <Form.Item
                noStyle
                name="status"
                valuePropName="checked"
                getValueProps={(checked) => ({ checked: checked === 'P' })}
                getValueFromEvent={(checked) => (checked ? 'P' : 'D')}
              >
                <Switch />
              </Form.Item>
              <span>Publish</span>
            </Space>
          </Form.Item>
        </div>
        <div className="max-w-[500px]">
          {templateType === 'E' && (
            <Form.Item label="Subject" className="grow" name="title">
              <Input placeholder="Enter Subject" />
            </Form.Item>
          )}
          {templateType === 'E' && (
            <>
              <Form.Item name="reply_to_email" label="Reply To Email">
                <Input type="email" />
              </Form.Item>
              <Form.Item name="reply_to_name" label="Reply To Name">
                <Input />
              </Form.Item>
            </>
          )}
        </div>
        <div className="flex flex-row gap-10 justify-end">
          <Form.Item label="Custom Form" className="w-[300px]" name="custom_forms">
            <Select
              placeholder="Select"
              mode="multiple"
              popupMatchSelectWidth={false}
              loading={customFormsQuery.isLoading}
              options={customFormsQuery.data?.items.map((item) => ({ label: item.name, value: item.id })) || []}
            />
          </Form.Item>
          <Form.Item name="preview_ticket" label="Preview Ticket" className="w-[300px]">
            <QuerySelect
              className="grow"
              apiEndpoint={ticketApi.list}
              apiQueryParams={{ fields: 'id,custom_form__name,name' }}
              apiSearchBy={(text) => ({ name__icontains: text })}
              renderOption={(item) => ({ value: item.id, label: `${item.custom_form__name} #${item.name}` })}
              defaultValue={{
                value: '',
                label: ''
              }}
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-3 gap-x-12">
          <FieldNamePicker />
          <ContentEditor />
        </div>
      </Form>
    </PageView>
  )
}
