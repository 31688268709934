import { useApp } from '@/hooks'
import { StatusSelect } from '@/modules/ticket/components'
import { useTicketSelection } from '@/modules/ticket/list/hooks'
import { TicketStatus } from '@/modules/ticket/types'
import { customFormApi, equipmentApi, ticketApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form, Select } from 'antd'
import { isEmpty } from 'lodash'
import { FC } from 'react'
import { TicketBulkAction } from './TicketBulkAction'

type SummaryOptions = {
  custom_form: number | null
  disposition_status: TicketStatus | null
  disposition_tickets: boolean
  disposition_status_summarized: TicketStatus | null
  summary_sku: number | null
  _list_each_line_item: number
  group_items: boolean
  selected_tickets: number[]
}

export const TicketSummaryButton: FC = () => {
  const [form] = Form.useForm<SummaryOptions>()
  const customFormId = Form.useWatch('custom_form', form)
  const dispositionTickets = Form.useWatch('disposition_tickets', form)

  const { selectedTickets } = useTicketSelection()

  const { notification } = useApp()
  const summaryMutation = useMutation(ticketApi.summary())

  const onOk = async (closeFn: any) => {
    try {
      await form.validateFields()
    } catch (error) {
      return
    }

    try {
      // call api
      const summaryConf = form.getFieldsValue()
      await summaryMutation.mutateAsync({
        ...{
          ...summaryConf,
          list_line_items: Boolean(summaryConf._list_each_line_item),
          group_items: summaryConf._list_each_line_item === 3,
          selected_tickets: selectedTickets.map((ticket) => ticket.id)
        }
      })

      // notify and close
      notification.success({ message: 'Success', description: 'Tickets summary built.' })
      closeFn(true)
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: (
          <div>
            <div>Failed to build tickets summary.</div>
            <div>{e.toString()}</div>
          </div>
        )
      })
    }
  }

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:object-group', children: 'Summary Ticket' }}
      modal={{
        title: 'Summary Tickets',
        okText: 'Build Summary',
        onOk: onOk,
        okButtonProps: { loading: summaryMutation.isLoading }
      }}
      optionsTitle={'Summary Options'}
    >
      <div>
        <Form form={form} layout={'vertical'} validateTrigger={'onBlur'}>
          <Form.Item
            label={'Summary Ticket Form'}
            name={'custom_form'}
            rules={[{ required: true, message: 'Required' }]}
          >
            <QuerySelect
              apiEndpoint={customFormApi.list}
              apiQueryParams={{ fields: 'id,name' }}
              apiSearchBy={'name'}
              renderOption={(item) => ({ value: item.id, label: item.name })}
            />
          </Form.Item>
          <Form.Item
            label={'Summary Ticket Status'}
            name={'disposition_status'}
            rules={[{ required: true, message: 'Required' }]}
          >
            <StatusSelect formId={customFormId} />
          </Form.Item>
          <Form.Item name={'disposition_tickets'} valuePropName="checked">
            <Checkbox>Disposition Summarized Tickets?</Checkbox>
          </Form.Item>
          {dispositionTickets && (
            <Form.Item
              label={'Summarized Ticket Status'}
              name={'disposition_status_summarized'}
              rules={[{ required: true, message: 'Required' }]}
            >
              <StatusSelect formId={customFormId} />
            </Form.Item>
          )}
          <Form.Item label={'Summary SKU'} name={'summary_sku'} rules={[{ required: true, message: 'Required' }]}>
            <QuerySelect
              apiEndpoint={equipmentApi.list}
              apiQueryParams={{ fields: 'id,sku,description', summary_sku__eq: '1' }}
              apiSearchBy={(text) => ({
                'Q[]': isEmpty(text) ? {} : [`sku__icontains|${text}`, `description__icontains|${text}`, 'or']
              })}
              renderOption={(item) => ({ value: item.id, label: `${item.sku} / ${item.description}` })}
            />
          </Form.Item>
          <Form.Item name={'_list_each_line_item'} label="List Each Line Item Individually">
            <Select
              value={0}
              options={[
                {
                  label: 'Yes - Group',
                  value: 3 as any
                },
                {
                  label: 'Yes',
                  value: 1 as any
                },
                {
                  label: 'No',
                  value: 0 as any
                }
              ]}
            />
          </Form.Item>
        </Form>
      </div>
    </TicketBulkAction>
  )
}
