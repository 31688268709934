import { JotaiProvider } from '@/contexts/JotaiProvider'
import cn from 'classnames'
import { ReactNode } from 'react'
import { PageViewContent } from './page-view-content'
import { PageViewHeader, PageViewHeaderProps } from './page-view-header'
import { PageViewLoading } from './page-view-loading'

export type PageViewProps = {
  asLayout?: boolean
  header?: PageViewHeaderProps
  loading?: boolean
  className?: string
  children?: ReactNode
  scoped?: boolean
}

export const PageView = ({ asLayout, header, loading, scoped = false, className, children }: PageViewProps) => {
  const view = (
    <section className={cn('relative flex-grow flex flex-col')}>
      {header && <PageViewHeader {...header} />}
      <PageViewContent scrollable={header?.sticky} className={cn(className, { 'p-10': !asLayout })}>
        {children}
      </PageViewContent>
      <PageViewLoading active={loading} />
    </section>
  )

  return scoped ? <JotaiProvider>{view}</JotaiProvider> : view
}

PageView.Header = PageViewHeader
PageView.Loading = PageViewLoading
