import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, Select, Space, Switch } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { ChemicalCardTypeAttributes } from './chemical-card-type-attributes'
import { ConfigEditor } from './config-editor'
import { DeliveriesCardTypeSection } from './deliveries-card-type-section'
import { ListingCardTypeAttributes } from './listing-card-type-attributes'
import { MapTypeSelect } from './map-type-select'
import { PicturesArrayAttributes } from './pictures-array-attributes'

export const ModuleTypeTicketsMapFields = () => {
  const form = Form.useFormInstance()
  const data = Form.useWatch('data', form) ?? {}
  const cardType = data.cardType ?? ''
  const [isShowEditor, setIsShowEditor] = useState(false)
  const customFormsQuery = useQuery({ ...customFormApi.list({ fields: 'id,name' }) })
  const customForms = useMemo(() => customFormsQuery.data?.items ?? [], [customFormsQuery.data?.items])
  const [selectedCustomForm, setSelectedCustomForm] = useState<number[]>([])

  const customFormsOptions = useMemo(() => {
    return customForms.map((item) => ({
      label: item.name,
      value: item.id
    }))
  }, [customForms])

  // Set default selected custom form
  useEffect(() => {
    if (customForms.length === 0) {
      return
    }

    setSelectedCustomForm(customForms.map((item) => item.id))
  }, [customForms])

  return (
    <div className="mb-16">
      <div className="flex mb-12">
        <div className="font-bold mr-12">Map Config</div>
        <Space>
          <Switch onChange={setIsShowEditor} />
          <span>Show Editor</span>
        </Space>
      </div>
      <div className="flex gap-x-16">
        <MapTypeSelect />
        {cardType !== 'D' && (
          <Form.Item
            label="Custom Form"
            tooltip="This is not a filter. This is just to limit the property selection for other fields"
            className="w-[200px]"
          >
            <Select
              popupMatchSelectWidth={false}
              loading={customFormsQuery.isLoading}
              disabled={customFormsQuery.isLoading}
              options={[
                {
                  label: 'All Custom Forms',
                  value: 'all'
                },
                ...customFormsOptions
              ]}
              value={selectedCustomForm.length === customForms.length ? 'all' : selectedCustomForm[0]}
              onChange={(value) => {
                if (value === 'all') {
                  setSelectedCustomForm(customForms.map((item) => item.id))
                } else {
                  setSelectedCustomForm([value as number])
                }
              }}
            />
          </Form.Item>
        )}
      </div>
      {isShowEditor && <ConfigEditor />}
      {cardType === 'C' && (
        <ChemicalCardTypeAttributes selectedCustomForm={selectedCustomForm} customFormsOptions={customFormsOptions} />
      )}
      {cardType === 'L' && <ListingCardTypeAttributes selectedCustomForm={selectedCustomForm} />}
      {cardType !== 'D' && <PicturesArrayAttributes selectedCustomForm={selectedCustomForm} />}
      {cardType === 'D' && <DeliveriesCardTypeSection />}
    </div>
  )
}
