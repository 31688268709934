import { CodeEditor } from '@/components/code-editor'
import { Checkbox, Form } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

export const CollapsableFields = () => {
  const form = useFormInstance()
  const collapsableFieldWatch = Form.useWatch('collapsable', form)

  return (
    <div>
      <div className="grid grid-cols-2">
        <Form.Item name="collapsable" valuePropName="checked">
          <Checkbox>Collapsable</Checkbox>
        </Form.Item>
        {collapsableFieldWatch && (
          <Form.Item name="start_collapsed" valuePropName="checked">
            <Checkbox>Start collapsed</Checkbox>
          </Form.Item>
        )}
      </div>
      {collapsableFieldWatch && (
        <Form.Item name="collapse_hide_script" label="Collapse Hide Script">
          <CodeEditor mode="javascript" minLines={4} maxLines={Infinity} isInModal />
        </Form.Item>
      )}
    </div>
  )
}
