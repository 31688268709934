import { FC } from 'react'
import {
  AfeInput,
  CustomerContactInput,
  CustomerOfficeInput,
  JobCodeInput,
  LocationInput,
  StatusInput,
  SupportOfficeInput,
  TimepointDueInput,
  TimepointSubmittedInput
} from '../form/components/meta-fields'

export const TicketInputByKey: Record<any, FC<any>> = {
  afe__user_afe_no: AfeInput,
  computed_status: StatusInput,
  custom_stage: StatusInput,
  customer__first_name: CustomerContactInput,
  customer__last_name: CustomerContactInput,
  customer__name: CustomerContactInput,
  customer_office__name: CustomerOfficeInput,
  job_code__user_job_code_no: JobCodeInput,
  location__name: LocationInput,
  support_office__name: SupportOfficeInput,
  timepoint_due: TimepointDueInput,
  timepoint_submitted: TimepointSubmittedInput
}

export const searchFieldsClassNames = {
  optionGroupClassName: '!text-black !text-opacity-40 !font-medium !text-14',
  optionClassName: 'ml-10'
}
