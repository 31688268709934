import { ApiResource, ListParams, ListReturn, MutationApiFn, RequestConfig } from '@/types/api/core'
import { R, createResourceApi, getParams, makeDetailMutation } from '../core'

export const _ticketImportTemplateApi: ApiResource & {
  fields: <TData = any>(params?: ListParams<TData>, config?: RequestConfig) => ListReturn<TData>
  clone: MutationApiFn
  download: MutationApiFn
} = {
  ...createResourceApi('v2/ticket_import_templates'),
  fields: (params, config) => ({
    queryKey: ['v2/ticket_import_templates', 'fields', getParams(params)],
    queryFn: ({ signal }: RequestConfig) =>
      R(
        'GET',
        '/v2/ticket_import_templates/fields/',
        { signal, params: getParams(params), ...config },
        { isList: true }
      )
  }),
  clone(config, urlAppend?, invalidate = true) {
    return makeDetailMutation({ path: 'clone', meta: this.meta, config, urlAppend, invalidate })
  },
  download(config, urlAppend?, invalidate = true) {
    return makeDetailMutation({ path: 'download', meta: this.meta, config, urlAppend, invalidate })
  }
}

export const _ticketImportLogApi: ApiResource & {
  retry: MutationApiFn
} = {
  ...createResourceApi('v2/ticket_import_logs'),
  retry(config, urlAppend?, invalidate = true) {
    return makeDetailMutation({ path: 'retry', meta: this.meta, config, urlAppend, invalidate })
  }
}
