import { z } from 'zod'

const componentSchema = z.object({
  id: z.number(),
  data: z.record(z.string(), z.any()).nullish(),
  sub_component: z.record(z.string(), z.any()).nullish(),
  is_new: z.boolean().nullish()
})

const baseCategorySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    kind: z.number().nullish(),
    company: z.number()
  })
  .passthrough()

type Category = z.infer<typeof baseCategorySchema> & {
  parent_category: Category | null | undefined
}

const categorySchema: z.ZodType<Category> = baseCategorySchema.extend({
  parent_category: z.lazy(() => categorySchema).nullable()
})

export const equipmentSchema = z
  .object({
    id: z.number(),
    description: z.string().nullish(),
    sku: z.string(),
    unit: z.string().nullish(),
    category: categorySchema.nullish(),
    usage_rate: z.number().nullish(),
    standby_rate: z.number().nullish(),
    hands: z.number().nullish(),
    string_1_source: z.number().nullish(),
    string_2_source: z.number().nullish(),
    string_3_source: z.number().nullish(),
    string_4_source: z.number().nullish(),
    string_5_source: z.number().nullish(),
    string_6_source: z.number().nullish(),
    string_7_source: z.number().nullish(),
    string_8_source: z.number().nullish(),
    user_text_1: z.string().nullish(),
    user_text_2: z.string().nullish(),
    user_text_3: z.string().nullish(),
    user_text_4: z.string().nullish(),
    user_text_5: z.string().nullish(),
    user_text_6: z.string().nullish(),
    user_text_7: z.string().nullish(),
    user_text_8: z.string().nullish(),
    assignee: z.number().nullish(),
    barrels: z.number().nullish(),
    discountable_amount: z.number().nullish(),
    units_standby: z.number().nullish(),
    units_used: z.number().nullish(),
    units_min: z.number().nullish(),
    cost: z.number().nullish(),
    amount: z.number().nullish(),
    discount: z.number().nullish(),
    minimum: z.number().nullish(),
    line_item_format: z.string().nullish(),
    group: z
      .object({
        id: z.number().nullish(),
        subtotal: z.boolean().nullish(),
        sync_datetime: z.boolean().nullish(),
        company: z.number().nullish(),
        equipment: z.array(z.any()).nullish(),
        equipment_ids: z.array(z.number()).nullish()
      })
      .nullish(),
    discount_sku: z.boolean().nullish(),
    tax_sku: z.boolean().nullish(),
    taxable_tax_sku: z.boolean().nullish(),
    skip_tax_calc: z.boolean().nullish(),
    public: z.boolean().nullish(),
    summary_sku: z.boolean().nullish(),
    prinnt_hide: z.boolean().nullish(),
    hide_accounting: z.boolean().nullish(),
    hide_mobile: z.boolean().nullish(),
    inventory: z.boolean().nullish(),
    is_sub_component: z.boolean().nullish(),
    is_finished_good: z.boolean().nullish(),
    non_discountable: z.boolean().nullish(),
    non_taxable: z.boolean().nullish(),
    inactive: z.boolean().nullish(),
    company: z.any().nullish(),
    components: z.array(componentSchema).nullish(),
    components_order_json: z.string().nullish(),
    show_components: z.boolean().nullish()
  })
  .passthrough()

export type Equipment = z.infer<typeof equipmentSchema>
