import { JobRole } from '@/modules/jas/job-manager/types'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'
import { JobRoleStats, StatsByDateItem } from './schemas'

type StatByJobRole = {
  [k: string]: JobRoleStats
}

const extractDatesFromSchedule = (employees: JasResourceUseQueryResponse[]): string[] =>
  Object.keys(employees[0]?.schedules || {})

const getEmptyStatItem = (): StatsByDateItem => ({
  off: {
    total: 0,
    employees_data: []
  },
  available: {
    total: 0,
    employees_data: []
  }
})

const getEmptyStats = (jobRoles: JobRole[], dates: string[]): StatByJobRole => {
  return Object.fromEntries(
    jobRoles.map(({ id, name, job_role_color }) => [
      name,
      {
        id,
        job_role: name,
        job_role_color,
        stats_by_date: Object.fromEntries(dates.map((d) => [d, getEmptyStatItem()]))
      }
    ])
  )
}

export const getStats = (employees: JasResourceUseQueryResponse[], jobRoles: JobRole[]): JobRoleStats[] => {
  if (isEmpty(jobRoles) || isEmpty(employees)) {
    return []
  }

  const dates = extractDatesFromSchedule(employees)
  const stats = getEmptyStats(jobRoles, dates)

  employees.forEach(({ schedules, id, name, job_roles }) => {
    const jobRole = job_roles[0]?.name // take first (primary) job role
    if (jobRole) {
      dates.forEach((date) => {
        const scheduleItem = schedules[date] || {}
        const type = scheduleItem.type
        if (type) {
          const key = type === 'O' || type === 'U' ? 'off' : 'available'
          stats[jobRole].stats_by_date[date][key].total += 1
          if (key === 'off') {
            stats[jobRole].stats_by_date[date][key].employees_data.push({ id, name })
          }
        }
      })
    }
  })

  return Object.values(stats)
}

export const getDateStr = (date: Dayjs): string => date.format('YYYY-MM-DD')
