import { Icon } from '@/ui'
import { Sortable } from '@/ui/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { FC } from 'react'
import { useActions, useActionsHandlers } from '../../hooks'
import { ActionCard } from './ActionCard'

type Props = {
  ruleIndex: number
}

export const ActionsSortable: FC<Props> = ({ ruleIndex }) => {
  const { actions } = useActions(ruleIndex)
  const { onSortingChange } = useActionsHandlers(ruleIndex)

  return (
    <>
      {actions.length === 0 ? (
        <div className="text-center text-text-muted py-16">
          <Icon name="fa:circle-info" className="mr-2" /> No Actions
        </div>
      ) : (
        <Sortable
          items={actions}
          by={'uuid'}
          onChange={onSortingChange}
          modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          render={(action, actionIndex, sortable) => (
            <ActionCard action={action} actionIndex={actionIndex} ruleIndex={ruleIndex} sortable={sortable} />
          )}
        />
      )}
    </>
  )
}
