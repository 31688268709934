import { Form, Input, Switch } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionUpdateLineItem } from '../../schemas'
import { LineItemValues } from './InputSyncLineItem'

type Props = {
  action: BpaActionUpdateLineItem
  onChange: (data: Partial<BpaActionUpdateLineItem>) => void
}

export const InputUpdateLineItem: FC<Props> = (props) => {
  return (
    <div className="flex flex-col">
      <LineItemQueryFilter {...props} />
      <LineItemValues action={props.action as any} onChange={props.onChange as any} />
    </div>
  )
}
export const InputUpdateLineItemMemo = React.memo(InputUpdateLineItem)

// ------------ Action Inputs ------------
export const LineItemQueryFilter: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      <Form.Item label="Query Filter (Text)" tooltip="e.g. sku__icontains|P[SKU]">
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 10 }}
          defaultValue={action.q_filter || ''}
          onChange={(e) => onChangeDebounced({ q_filter: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Include Sub Components?">
        <Switch
          checked={action.include_subcomponents || false}
          onChange={(val) => onChange({ include_subcomponents: val })}
        />
      </Form.Item>
    </div>
  )
}
