export const PROPERTY_TYPE = {
  Attachment: 15,
  Automation: 24,
  Date: 3,
  DateTime: 6,
  Decimal: 7,
  DisplayImage: 20,
  Dropdown: 8,
  Email: 9,
  Image: 17,
  Integer: 10,
  Label: 4,
  Measurement: 14,
  MeasurementFeetInch: 18,
  MultilineString: 2,
  MultivariateDropdown: 19,
  PhoneNumber: 11,
  Pin: 21,
  RecordSelect: 25,
  Script: 12,
  ScriptedButton: 22,
  Signature: 13,
  Spacer: 5,
  String: 1,
  Table: 23,
  Time: 16
}

export const PT = PROPERTY_TYPE // alias
