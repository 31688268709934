import { Icon, IconName, Tabs } from '@/ui'
import { TabsProps } from 'antd'
import { ReactNode, useMemo } from 'react'
import { useTicket, useTicketFlags } from '../../hooks'
import { TabAttachments } from './tab-attachments'
import { TabNotes } from './tab-notes'
import { TabSignatures } from './tab-signatures'
import { TabSubscribers } from './tab-subscribers'
import { TabTeamMembers } from './tab-team-members'

export const FooterTabs = () => {
  const { ticket } = useTicket()
  const { hideAttachments } = useTicketFlags()

  const tabItems: TabsProps['items'] = useMemo(() => {
    const items = []
    items.push(renderItem('Edit Access', 'fa:lock', ticket.contacts.length, <TabTeamMembers />))
    items.push(renderItem('Subscribers', 'fa:user-group', ticket.subscribers.length, <TabSubscribers />))
    if (!hideAttachments) {
      items.push(renderItem('Attachments', 'fa:paperclip', ticket.attachments.length, <TabAttachments />))
    }
    items.push(renderItem('Signatures', 'fa:signature', ticket.signatures.length, <TabSignatures />))
    items.push(renderItem('Notes', 'fa:comments', ticket.notes.length, <TabNotes />))

    return items
  }, [
    ticket.contacts.length,
    ticket.signatures.length,
    ticket.notes.length,
    ticket.attachments.length,
    ticket.subscribers.length,
    hideAttachments
  ])

  return <Tabs type={'line-filled'} items={tabItems} />
}

const renderItem = (title: string, icon: IconName, count: number, children: ReactNode) => {
  return {
    key: title,
    label: (
      <span className={'px-12'}>
        <Icon name={icon} />
        <span className={'ml-6'}>{title}</span>
        {count > 0 && <span className={'ml-6 text-12 text-white bg-primary rounded-full px-6'}>{count}</span>}
      </span>
    ),
    children: <div className="px-10 !min-h-[75px]">{children}</div>
  }
}
