import { useApp } from '@/hooks'
import { REPORTS_PATHS } from '@/modules/reports/constants'
import { navigate } from '@/routing/helpers'
import { scheduledReportApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { FC } from 'react'

type Props = {
  form: FormInstance
}

export const SaveButton: FC<Props> = ({ form }) => {
  const updateScheduledReportMutation = useMutation(scheduledReportApi.patch())
  const createScheduledReportMutation = useMutation(scheduledReportApi.create())
  const { notification } = useApp()

  const handleSave = async () => {
    const values = await form.validateFields()

    const data = {
      ...values,
      company: values.company.id,
      run_as: values.run_as.id,
      programmed_report: values.programmed_report?.id || null,
      report: values.report?.id || null
    }

    if (!data.programmed_report && !data.report) {
      notification.error({ message: 'Report is not selected' })
      return
    }

    if (values.id) {
      await updateScheduledReportMutation.mutateAsync(data)
    } else {
      const scheduledReport = await createScheduledReportMutation.mutateAsync(data)
      setTimeout(() => {
        navigate(REPORTS_PATHS.editScheduledReport(scheduledReport.id))
      }, 100)
    }

    notification.success({ message: 'Report updated' })
  }

  return (
    <Button
      iconName="fa:save"
      type="success"
      onClick={handleSave}
      loading={updateScheduledReportMutation.isLoading || createScheduledReportMutation.isLoading}
    >
      Save
    </Button>
  )
}
