import { CodeEditor } from '@/components/code-editor'
import { useApp } from '@/hooks'
import { propertyGroupApi } from '@/services/api-service'
import { PropertyGroup } from '@/types/property-group'
import { Button } from '@/ui/button'
import { Modal } from '@/ui/modal'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form, Input } from 'antd'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { editingCustomFormSectionAtom } from '../atoms'
import { useCustomFormQuery } from '../hooks'
import { CollapsableFields } from './collapsable-fields'

const formId = 'edit-custom-form-section'

export const EditCustomFormSectionModal = () => {
  const [editingCustomFormSection, setEditinCustomFormSectiom] = useAtom(editingCustomFormSectionAtom)
  const createPropertyGroupMutation = useMutation({ ...propertyGroupApi.create() })
  const updatePropertyGroupMutation = useMutation({ ...propertyGroupApi.update() })
  const deletePropertyGroupMutation = useMutation({ ...propertyGroupApi.delete() })
  const { customFormQuery } = useCustomFormQuery()
  const { notification } = useApp()
  const [isUpsertLoading, setIsUpsertLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const handleSubmit = async (values: PropertyGroup) => {
    setIsUpsertLoading(true)
    const mutation = editingCustomFormSection?.id ? updatePropertyGroupMutation : createPropertyGroupMutation

    await mutation.mutateAsync({
      ...values,
      id: editingCustomFormSection?.id,
      custom_form: customFormQuery.data?.id
    })

    await customFormQuery.refetch()
    setIsUpsertLoading(false)

    notification.success({
      message: 'Custom form section saved successfully'
    })

    setEditinCustomFormSectiom(null)
  }

  const handleDelete = async () => {
    setIsDeleteLoading(true)
    await deletePropertyGroupMutation.mutateAsync({ id: editingCustomFormSection?.id })
    await customFormQuery.refetch()
    setIsDeleteLoading(false)

    notification.success({
      message: 'Custom form section deleted successfully'
    })

    setEditinCustomFormSectiom(null)
  }

  if (!editingCustomFormSection) {
    return null
  }

  return (
    <Modal
      open={!!editingCustomFormSection}
      title={editingCustomFormSection.id ? 'Edit Custom Form Section' : 'Add Custom Form Section'}
      width={840}
      withScreenMaxHeight
      okText="Save"
      okButtonProps={{
        form: formId,
        htmlType: 'submit',
        loading: isUpsertLoading,
        'data-cy': 'save-custom-form-section'
      }}
      footer={(props) => (
        <div className="flex">
          {editingCustomFormSection.id && (
            <Button
              data-cy="delete-custom-form-section"
              danger
              type="primary"
              onClick={handleDelete}
              loading={isDeleteLoading}
            >
              Delete
            </Button>
          )}
          <div className="ml-auto">{props}</div>
        </div>
      )}
      onCancel={() => setEditinCustomFormSectiom(null)}
    >
      <Form layout="vertical" id={formId} initialValues={editingCustomFormSection} onFinish={handleSubmit}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
          <Input data-cy="section-name" />
        </Form.Item>
        <Form.Item label="Sequence" name="sequence">
          <Input data-cy="section-sequence" />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item name="hide_from_customer" valuePropName="checked">
            <Checkbox data-cy="hide-from-customer">Hidden (customer)</Checkbox>
          </Form.Item>
          <Form.Item name="hidden" valuePropName="checked">
            <Checkbox data-cy="hidden">Hidden (mobile)</Checkbox>
          </Form.Item>
          <Form.Item name="hide_on_web" valuePropName="checked">
            <Checkbox data-cy="hide-on-web">Hidden (web)</Checkbox>
          </Form.Item>
          <Form.Item name="hide_on_print" valuePropName="checked">
            <Checkbox data-cy="hide-on-print">Hidden (print)</Checkbox>
          </Form.Item>
          <Form.Item name="internal_use_only" valuePropName="checked">
            <Checkbox data-cy="internal-use-only">Internal use only</Checkbox>
          </Form.Item>
          <Form.Item
            name="ticket_private"
            valuePropName="checked"
            getValueProps={(value) => ({ checked: !value })}
            getValueFromEvent={(e) => !e.target.checked}
          >
            <Checkbox>Copyable</Checkbox>
          </Form.Item>
        </div>
        <Form.Item label="CSS Print Class" name="print_class">
          <Input data-cy="css-print-class" />
        </Form.Item>
        <div className="mb-30">Use `oc-separator-start` to add a separator before this section.</div>
        <Form.Item name="lock_after_finish" valuePropName="checked">
          <Checkbox data-cy="lock-after-finish">Lock After Finish (iOS Apps)</Checkbox>
        </Form.Item>
        <Form.Item name="bordered" valuePropName="checked">
          <Checkbox data-cy="show-section-border">Show Section Border</Checkbox>
        </Form.Item>
        <CollapsableFields />
        <Form.Item label="Section Hide Script" name="section_hide_script">
          <CodeEditor data-cy="section-hide-script" mode="javascript" minLines={5} maxLines={Infinity} isInModal />
        </Form.Item>
        <Form.Item name="visible_name" valuePropName="checked">
          <Checkbox data-cy="show-title-in-web-app">Show title in web app</Checkbox>
        </Form.Item>
        <Form.Item name="mobile_printable" valuePropName="checked">
          <Checkbox data-cy="show-section-when-printing-from-mobile">
            Show this section when printing from mobile
          </Checkbox>
        </Form.Item>
        <Form.Item name="line_item_placeholder" valuePropName="checked">
          <Checkbox data-cy="replace-this-section-with-the-tickets-line-items">
            Replace this section with the ticket&apos;s line items.
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}
