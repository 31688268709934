import { type IconName } from '@/ui/icons'

export const ICON_BY_EXTENSION: Record<string, IconName> = {
  default: 'fa:file',
  pdf: 'fa:file-pdf',
  doc: 'fa:file-word',
  docx: 'fa:file-word',
  xls: 'fa:file-excel',
  xlsx: 'fa:file-excel',
  ppt: 'fa:file-powerpoint',
  pptx: 'fa:file-powerpoint',
  zip: 'fa:file-archive',
  rar: 'fa:file-archive',
  png: 'fa:file-image',
  jpg: 'fa:file-image',
  jpeg: 'fa:file-image',
  gif: 'fa:file-image'
}

export const getIconNameByUrl = (url: string | null | undefined, defaultIcon: IconName = 'fa:file'): IconName => {
  url = url || ''
  if (!url.length) return defaultIcon

  // usually these are images uploaded by mobile apps
  if (!url.includes('.')) return ICON_BY_EXTENSION.jpg

  const extension = (url || '').split('.').pop() || 'default'
  return ICON_BY_EXTENSION[extension] || defaultIcon
}
