import { useViewData } from '@/layouts'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { categoryApi, clientRecordApi, propertyApi } from '@/services/api-service'
import { LinkButton, QueryDeleteButton } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { RecordFormModal } from '../components'
import { RecordTypeOption } from '../constants'
import { Record, RecordSchema } from '../schemas'

type ViewData = RecordTypeOption // from parent layout

export const RecordListView = view<any, RecordTypeOption>(ViewComponent, { name: 'RecordListView' })

/** Components */

function ViewComponent() {
  const { data: recordType } = useViewData<ViewData>()

  return (
    <ListView
      key={recordType.type}
      header={{
        accent: true,
        border: false,
        title: recordType.label,
        actions: [
          <RecordFormModal
            key={'add'}
            type={'primary'}
            shape={'default'}
            iconName={'fa:plus'}
            data-cy={'add-button'}
            recordType={recordType}
          >
            Add {recordType.type}
          </RecordFormModal>
        ]
      }}
      filter={{ search: { searchBy } }}
      table={{
        queryApi: clientRecordApi.list,
        queryParams: { label__eq: recordType.label, order: recordType.order, fields: RecordSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'id', title: 'ID', width: 100 },
          ...recordType.fields.map((field) => ({
            dataIndex: field.key,
            title: field.name,
            render: (value: any, record: Record) => <RecordColumn field={field} value={value} record={record} />
          })),
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Record) => (
              <div>
                <RecordFormModal iconName={'fa:edit'} record={row} recordType={recordType} />
                <QueryDeleteButton id={row.id} api={clientRecordApi.delete} name={recordType.label} />
              </div>
            )
          }
        ]
      }}
    />
  )
}

function RecordColumn({ field, value, record }: { field: RecordTypeOption['fields'][0]; value: any; record: Record }) {
  if (field.input === 'color') {
    return (
      <div className={'flex flex-row gap-6 items-center'}>
        <div
          className={'rounded-full h-20 w-5 border'}
          style={{ borderColor: value, backgroundColor: value || 'transparent' }}
        />
        <span className={'uppercase'}>{value || 'None'}</span>
      </div>
    )
  } else if (field.input === 'property') {
    return <PropertyColumn value={get(record, field.key.replace('__', '.'))} />
  } else if (field.input === 'category') {
    return <CategoryColumn value={get(record, field.key.replace('__', '.'))} />
  }

  return value
}

function PropertyColumn({ value }: { value: any }) {
  const { data: property } = useQuery(
    propertyApi.get<{
      id: number
      name: string
      property_group__custom_form_id: number
      property_group__custom_form__name: string
    }>(value, undefined, { fields: 'id,name,property_group__custom_form_id,property_group__custom_form__name' })
  )

  return (
    <span>
      <small>{property?.property_group__custom_form__name}</small> <br /> {property?.name}
      <LinkButton
        size={'small'}
        type={'link'}
        to={`/account/custom_forms/${property?.property_group__custom_form_id}/layout`}
        target={'_blank'}
        iconName={'fa:external-link'}
      />
    </span>
  )
}

function CategoryColumn({ value }: { value: any }) {
  const { data: category } = useQuery(
    categoryApi.get<{
      id: number
      name: string
      parent_category__name: string
    }>(value, undefined, { fields: 'id,name,parent_category__name' })
  )
  return (
    <span>
      <small>{category?.parent_category__name}</small> <br /> {category?.name}
      <LinkButton
        size={'small'}
        type={'link'}
        to={`/records/categories/${category?.id}/edit`}
        target={'_blank'}
        iconName={'fa:external-link'}
      />
    </span>
  )
}

/** Helpers */

function searchBy(text: string) {
  return text
    ? {
        Q: [
          `char_1__icontains|${text}`,
          `char_2__icontains|${text}`,
          'or',
          `char_3__icontains|${text}`,
          'or',
          `char_4__icontains|${text}`,
          'or'
        ]
      }
    : {}
}

/** Hooks */
