import { TICKET_STATUS } from '@/constants/general'
import { useSession } from '@/hooks'
import { useCallback, useMemo } from 'react'
import { useGlobalCustomForms } from './use-global-custom-forms'

export type StatusMap = Record<string, string>
export type StatusOption = { value: string; label: string }
export type StatusByFormIdMap = Record<number, StatusMap>
export type StatusByFormIdOptions = Record<number, StatusOption[]>

const _makeOption = (statuses: StatusMap) => {
  return Object.entries(statuses)
    .filter((list) => list[1] !== '--hide--')
    .map((list) => ({ value: list[0], label: list[1] }))
}

export const useCustomFormsStatus = () => {
  const { company } = useSession()
  const companyStatus = useMemo(
    () => company.data?.options?.custom_status || {},
    [company.data?.options?.custom_status]
  )
  const [defaultStatusMap, defaultStatusOptions] = useMemo(() => {
    const _defaultStatusMap = { ...TICKET_STATUS, ...companyStatus }
    return [_defaultStatusMap, _makeOption(_defaultStatusMap)]
  }, [companyStatus])

  const { customForms, customFormsQuery } = useGlobalCustomForms()

  const [formsStatusMap, formsStatusOptions] = useMemo(() => {
    const statusByFormId: StatusByFormIdMap = {}
    const statusByFormIdOptions: StatusByFormIdOptions = {}
    customForms.forEach((form) => {
      const { _features } = form
      statusByFormId[form.id] = { ...TICKET_STATUS, ...companyStatus, ..._features.customStatus }
      statusByFormIdOptions[form.id] = _makeOption(statusByFormId[form.id])
    })

    return [statusByFormId, statusByFormIdOptions]
  }, [companyStatus, customForms])

  const getStatusMap = useCallback(
    (formId?: number | null): StatusMap => (formId ? formsStatusMap[formId] : null) || defaultStatusMap,
    [defaultStatusMap, formsStatusMap]
  )

  const getStatusOptions = useCallback(
    (formId?: number | null): StatusOption[] => (formId ? formsStatusOptions[formId] : null) || defaultStatusOptions,
    [defaultStatusOptions, formsStatusOptions]
  )

  return {
    ...customFormsQuery,
    defaultStatusMap,
    defaultStatusOptions,
    formsStatusMap,
    formsStatusOptions,
    getStatusMap,
    getStatusOptions
  }
}
