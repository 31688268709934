import { Image } from '@/ui'
import { mediaUrl } from '@/utils'
import cn from 'classnames'
import dayjs from 'dayjs'
import { FC } from 'react'
import { TicketSignature } from '../../../../../schemas'
import { getSignatureStyleCSS } from '../helpers'

export const SignaturePreview: FC<{ signature: TicketSignature; className?: string }> = ({ signature, className }) => {
  return (
    <div className={cn('relative dark:bg-gray-200 rounded', className)}>
      {signature.image && signature.image.length ? (
        <Image
          background
          src={mediaUrl(signature.image)}
          className={cn('!h-[50px] flex flex-col justify-center !mb-20')}
        />
      ) : (
        <div
          className={cn(
            'h-[50px] flex flex-col mb-20 items-center justify-center break-all',
            'tracking-widest leading-[20px] text-[20px] overflow-hidden',
            getSignatureStyleCSS(signature.style || 'L')
          )}
          style={{ color: signature.color || '#000000' }}
        >
          {signature.name}
        </div>
      )}
      <div className={'absolute text-sm px-6 bottom-0 dark:text-gray-900'}>
        {dayjs.formatLocal(signature.created_at)}
      </div>
    </div>
  )
}
