import { NotificationTemplateStatus, NotificationTemplateType } from '@/constants/general'
import { useApp } from '@/hooks'
import { CustomEmail } from '@/schemas/custom-email'
import { notificationTemplateApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Button } from '@/ui/button'
import { Checkbox, Form, Input, Modal, Select } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCustomEmailsQuery, useUpsertCustomEmail } from '../hooks'

type Props = {
  customEmail?: CustomEmail
  trigger?: ReactElement
}

export const AddEditCustomEmailModal = ({ customEmail, trigger }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const [form] = Form.useForm<CustomEmail & { custom_form_id: number }>()
  const { upsertCustomEmail, createCustomEmailMutation, updateCustomEmailMutation } = useUpsertCustomEmail()
  const { customEmailsQuery } = useCustomEmailsQuery()
  const { notification } = useApp()
  const attachTicketWatch = Form.useWatch(['data', 'attach_ticket'], form)

  const toggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  const handleSave = async () => {
    if (!id) {
      notification.error({
        message: 'Please create a custom form first'
      })
      return
    }

    const values = await form.validateFields()
    await upsertCustomEmail({ ...customEmail, ...values, custom_form_id: Number(id) })
    customEmailsQuery.refetch()
    if (!customEmail) form.resetFields()
    toggleModal()
  }

  return (
    <>
      {trigger ? (
        React.cloneElement(trigger, { onClick: toggleModal })
      ) : (
        <Button type="primary" onClick={toggleModal} data-cy="add-custom-template-button">
          Add new
        </Button>
      )}
      <Modal
        destroyOnClose={true}
        open={isOpen}
        title={customEmail?.id ? 'Edit Custom Email' : 'Add Custom Email'}
        onCancel={toggleModal}
        okText="Save And Close"
        onOk={handleSave}
        okButtonProps={{
          'data-cy': 'add-custom-template-save-button',
          loading: createCustomEmailMutation.isLoading || updateCustomEmailMutation.isLoading
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            name: customEmail?.name,
            email_template_id: customEmail?.email_template_id,
            data: customEmail?.data
          }}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input data-cy="custom-template-name-input" />
          </Form.Item>
          <Form.Item
            label="Email Template"
            name="email_template_id"
            rules={[{ required: true, message: 'Template ID is required' }]}
          >
            <QuerySelect
              apiSearchBy="name"
              placeholder="Select"
              apiEndpoint={notificationTemplateApi.list}
              apiQueryParams={{
                type__eq: 'E' as NotificationTemplateType,
                status__eq: 'P' as NotificationTemplateStatus,
                fields: 'id,name'
              }}
              renderOption={(item) => ({ value: item.id, label: item.name })}
              showSearch
              data-cy="custom-template-sg-template-id-select"
            />
          </Form.Item>
          <Form.Item name={['data', 'attach_ticket']} valuePropName="checked">
            <Checkbox data-cy="attach-ticket-checkbox">Attach Ticket</Checkbox>
          </Form.Item>
          {attachTicketWatch && (
            <Form.Item label="Print Styles" name={['data', 'print_styles']}>
              <Select
                mode="multiple"
                data-cy="print-styles-select"
                options={[
                  {
                    label: 'Default',
                    value: 'Default'
                  }
                ]}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}
