import { Button } from '@/ui/button'

import { useApp, useCompanyFeatures, useSession } from '@/hooks'
import { useTimeZones } from '@/hooks/use-get-timezone-query'
import { useCheckAccess } from '@/modules/auth/hooks'
import { companyApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { filterSelectOption } from '@/utils/filter-select-option'
import { formatPhoneNumber } from '@/utils/formatters'
import { PlusOutlined } from '@ant-design/icons'
import { Checkbox, Form, FormInstance, Input, Modal, Select, Switch, Upload, UploadFile, UploadProps } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { RcFile } from 'antd/es/upload'
import { useAtomValue, useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useState } from 'react'
import { formTypeAtom, sharedDataAtom } from '../../atom'
import { TextForEmail } from './TextForEmail'
import { TextForMobile } from './TextForMobile'

type Props = {
  form: FormInstance
}

export const ProfileForm = ({ form }: Props) => {
  const { notification } = useApp()
  const formValues = Form.useWatch([], form)
  const [modalVisible, setModalVisible] = useState(false)
  const { user: authUser } = useSession()
  const [_accountVisible, setAccountVisible] = useState(true)
  const [emailInstructionVisible, setEmailInstructionVisible] = useState(false)
  const [mobileInstructionVisible, setMobileInstructionVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const sharedData = useAtomValue(sharedDataAtom)
  const setSharedDataAtom = useSetAtom(sharedDataAtom)

  const { data: timezone } = useTimeZones()
  const [timezoneOptions, setTimezoneOptions] = useState<Array<{ key: string; value: string }> | []>([])
  const formType = useAtomValue(formTypeAtom)

  // If Microsoft authentication enabled, username/password are not required
  const { microsoftOpenIdAuth } = useCompanyFeatures()
  const isMsAuthEnabled = microsoftOpenIdAuth.enabled
  // Email, username and password inputs shouldn't be enabled for regular users editing their profile
  const isAdminLike = useCheckAccess(['super_admin', 'support', 'company_admin', 'roles_manager'])
  const disableUsernameEmailInput = isMsAuthEnabled && !isAdminLike

  let i = 1
  const handleModalOkClick = () => {
    setModalVisible(false)
    setAccountVisible(false)
    form.setFieldValue('is_active', false)
  }
  const handleModalCancel = () => {
    form.setFieldValue('is_active', true)
    setAccountVisible(true)
    setModalVisible(false)
  }
  const handleEmailInstructionModal = () => {
    setEmailInstructionVisible((prv) => !prv)
  }
  const handleMobileInstructionModal = () => {
    setMobileInstructionVisible((prv) => !prv)
  }
  const getBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      const fileToUpload = file?.originFileObj || file
      file.preview = await getBase64(fileToUpload as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
  }
  const handleChange: UploadProps['onChange'] = useCallback(
    (data: any) => {
      const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp']
      if (data?.file) {
        if (data?.file?.type && allowedExtension.includes(data?.file?.type)) {
          const uploadedFile = data?.file
          form.setFieldValue('profile', uploadedFile)
          handlePreview(uploadedFile)
        }
      }
    },
    [formValues?.profile]
  )

  const handleShowAllTimezone = (e: CheckboxChangeEvent) => {
    const list = timezone ? Object.entries(timezone.items) : []
    const formattedList = list.map((fl) => ({ key: fl[0], value: fl[0] }))
    if (e.target.checked) {
      setTimezoneOptions(formattedList)
    } else {
      const filteredList = list.filter((zone) => zone[1] === true)
      const formattedList = filteredList.map((fl) => ({ key: fl[0], value: fl[0] }))
      setTimezoneOptions(formattedList)
    }
  }

  const handleAccountChange = (value: boolean) => {
    if (!value) {
      setModalVisible(true)
    }
  }

  const handleBeforeUpload = (file: File) => {
    // Prevent the upload since we're just displaying the image
    const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp']
    if (!allowedExtension.includes(file.type)) {
      notification.error({ message: `${file.name} is not an image` })
      form.setFieldValue('profile', null)
    }
    return false
  }

  useEffect(() => {
    if (timezone) {
      const list = Object.entries(timezone.items)
      const filteredList = list.filter((zone) => zone[1] === true)
      const formattedList = filteredList.map((fl) => ({ key: fl[0], value: fl[0] }))
      setTimezoneOptions(formattedList)
    }
  }, [timezone])

  useEffect(() => {
    setAccountVisible(form.getFieldValue('is_active'))
  }, [form])

  useEffect(() => {
    if (isMsAuthEnabled && formType === 'add' && !form.getFieldValue('new_password')) {
      const rand = nanoid()
      form.setFieldValue('new_password', rand)
      form.setFieldValue('confirm_password', rand)
    } else {
      form.setFieldValue('new_password', '')
      form.setFieldValue('confirm_password', '')
    }
  }, [isMsAuthEnabled, formType])

  useEffect(() => {
    if (formValues?.profile && i == 1) {
      setPreviewImage(formValues.profile)
      i = 2
    }
  }, [formValues?.profile])

  const onCompanyChange = (value: number) =>
    setSharedDataAtom({
      ...sharedData,
      company_id: value
    })
  const first_name = formValues?.first_name
  const last_name = formValues?.last_name
  const title = formValues?.title

  return (
    <Form name="profileForm" form={form} className="lg:w-6/12 mx-auto pb-24" layout="vertical">
      <div className="flex items-center justify-between">
        <div className="flex items-center mb-24">
          <div className="relative">
            <Button
              type="primary"
              size="large"
              shape="circle"
              className="flex items-center justify-center"
              iconName={!first_name && !last_name ? 'mi:person' : undefined}
            >
              {first_name ? first_name.substring(0, 1).toUpperCase() : null}
              {last_name ? last_name.substring(0, 1).toUpperCase() : null}
            </Button>
          </div>
          <div className="ml-12">
            <div className="font-semibold">
              {first_name ? first_name : 'Firstname'} {first_name || last_name ? last_name : 'Lastname'}
            </div>
            <div className="text-xs">{title ? title : 'Title will be displayed here'}</div>
          </div>
        </div>

        <div className="flex items-center text-sm">
          Account Status :
          <Form.Item name="is_active" valuePropName="checked" className="mb-0 ml-2">
            <Switch checked={form.getFieldValue('is_active')} onChange={handleAccountChange} />
          </Form.Item>
        </div>
      </div>
      <Form.Item label="Company" name="company_id" hidden={!authUser?.is_superuser} rules={[{ required: true }]}>
        <QuerySelect
          apiEndpoint={companyApi.list}
          apiSearchBy="name"
          labelInValue
          renderOption={(item) => ({ label: item.name, value: item.id })}
          onChange={({ value }) => onCompanyChange(value.value)}
        />
      </Form.Item>
      <Form.Item label="First Name" name="first_name" rules={[{ required: true }]}>
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" name="last_name" rules={[{ required: true }]}>
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item label="Title" name="title" rules={[{ required: true }]}>
        <Input placeholder="Title" />
      </Form.Item>
      <Form.Item label="External ID" name="external_id">
        <Input placeholder="External ID" />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone_number"
        getValueProps={(value) => ({ value: formatPhoneNumber(value) })}
        getValueFromEvent={(e) => {
          return formatPhoneNumber(e.target.value)
        }}
      >
        <Input placeholder="Mobile Number" className="w-full" inputMode="tel" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="new_email"
        rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
      >
        <Input
          placeholder="Email"
          disabled={disableUsernameEmailInput}
          onChange={(e) => {
            if (isMsAuthEnabled && formType === 'add') {
              form.setFieldValue('username', e.target.value)
            }
          }}
        />
      </Form.Item>
      <Form.Item label="Profile Picture" name="profile">
        <div className="flex gap-8 items-center">
          {previewImage && (
            <div className="p-[52px] inline-block relative border border-dashed border-gray-200 rounded-full overflow-hidden ">
              <img
                src={previewImage}
                className="w-full h-full absolute  inset-0 object-center object-cover rounded-full border-[4px] border-solid border-white"
              />
            </div>
          )}
          <Upload
            maxCount={1}
            listType="picture-circle"
            onChange={handleChange}
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            accept="image/png, image/gif, image/jpeg"
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }} className="text-sm">
                Upload
              </div>
            </div>
          </Upload>
        </div>
      </Form.Item>
      <h5 className="font-bold">Username / Password</h5>
      <Form.Item label="User Name" name="username" rules={[{ required: true }]}>
        <Input placeholder="User Name" disabled={disableUsernameEmailInput} />
      </Form.Item>
      <Form.Item label="Password" name="new_password" rules={[{ required: formType === 'add' && !isMsAuthEnabled }]}>
        <Input.Password placeholder="password" disabled={disableUsernameEmailInput} />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirm_password"
        hasFeedback={formValues?.confirm_password}
        rules={[
          {
            required: formValues?.new_password ? true : false,
            message: 'Please confirm your password'
          },
          ({ getFieldValue }) => {
            return {
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The Password you enter does not match!'))
              }
            }
          }
        ]}
      >
        <Input.Password placeholder="password" disabled={disableUsernameEmailInput} />
      </Form.Item>
      <div className="flex items-center">
        <Form.Item label="Timezone" name="timezone" className="w-6/12 pr-16">
          <Select showSearch options={timezoneOptions} filterOption={filterSelectOption} />
        </Form.Item>
        <Checkbox onChange={handleShowAllTimezone}>Show All Timezones</Checkbox>
      </div>
      <div className="rounded p-14 border border-dashed border-gray-300">
        <div className="flex flex-wrap justify-between gap-8 mb-12">
          <Button
            type="default"
            className="!py-4 !h-auto"
            iconName="mi:content_copy"
            onClick={handleEmailInstructionModal}
          >
            <span className="font-medium">Text for Email Web User Instructions</span>
          </Button>

          <Button
            type="default"
            className="!py-4 !h-auto"
            iconName="mi:content_copy"
            onClick={handleMobileInstructionModal}
          >
            <span className="font-medium">Email Text for New Mobile User</span>
          </Button>
        </div>

        <div className="border border-primary rounded p-8 px-16 text-primary text-tiny">
          These pop up your default email template so you can paste the password in the email.
          <br />
          <em>For security reasons, we don’t store passwords in a retrievable way.</em>
        </div>
      </div>
      <Modal title="Change Account Status" open={modalVisible} onOk={handleModalOkClick} onCancel={handleModalCancel}>
        <strong>Note: Disabling a person, will remove them from contacts tab too.</strong>
        <br />
        Do you want to proceed?
      </Modal>
      <Modal
        open={emailInstructionVisible}
        title="Text for Email Web User Instructions"
        footer={false}
        onCancel={handleEmailInstructionModal}
      >
        <TextForEmail firstName={formValues?.first_name} username={formValues?.username} />
      </Modal>
      <Modal
        open={mobileInstructionVisible}
        title="Email Text for New Mobile User"
        footer={false}
        onCancel={handleMobileInstructionModal}
      >
        <TextForMobile firstName={formValues?.first_name} username={formValues?.username} />
      </Modal>
    </Form>
  )
}
