import { useSession } from '@/hooks'
import { moduleApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Spin, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const ModulesColumn = () => {
  const { company } = useSession()

  const enabledModules =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.enabled_modules), [])) ?? []

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)

  const modulesQuery = useQuery({
    ...moduleApi.list({
      fields: 'id,title',
      company_id__exact: company.id,
      is_active__eq: 1
    })
  })

  const isModuleEnabled = (moduleId: number) => enabledModules.includes(moduleId)

  const handleChange = (moduleId: number, checked: boolean) => {
    const newEnabledModules = checked
      ? [...enabledModules, moduleId]
      : enabledModules.filter((id: number) => id !== moduleId)

    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        enabled_modules: newEnabledModules
      }
    }))
  }

  if (modulesQuery.isLoading) {
    return <Spin spinning />
  }

  return (
    <div>
      {modulesQuery.data?.items.map((module) => (
        <div key={module.id} className="flex w-full justify-between mb-20">
          <div>{module.title}</div>
          <Switch checked={isModuleEnabled(module.id)} onChange={(checked) => handleChange(module.id, checked)} />
        </div>
      ))}
    </div>
  )
}
