import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { LinkButton } from '@/ui'
import { GeneratedReportsSection, ScheduledReportsSection } from '../../components'
import { REPORTS_PATHS } from '../../constants'

export const ScheduledReportsView = view(Component)

function Component() {
  const { l } = useApp()

  return (
    <PageView
      header={{
        title: l('ScheduledReports'),
        actions: [
          <LinkButton
            key={'new-scheduled-report'}
            iconName="fa-solid:plus"
            type="primary"
            to={REPORTS_PATHS.addScheduledReport}
          >
            New Scheduled Report
          </LinkButton>
        ]
      }}
      className="flex flex-col gap-[10px]"
    >
      <ScheduledReportsSection />
      <GeneratedReportsSection Q={['data__extra__schedule_id__isnull|False', 'data__handled_at__isnull|False', 'or']} />
    </PageView>
  )
}
