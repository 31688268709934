import { Form, Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { ChangeEvent, useMemo } from 'react'
import { customFormAtom } from '../../../../atoms'
import { STYLE_DEFS } from '../../../../constants'

export const CustomCssFields = () => {
  const customPrintCss = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.custom_print_css), []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const value = e.target.value ?? ''

    setCustomForm((prev) => ({
      ...prev,
      custom_print_css: {
        otherCss: prev.custom_print_css?.otherCss ?? '',
        originalCustomPrintCss: prev.custom_print_css?.originalCustomPrintCss ?? '',
        fields: {
          ...prev.custom_print_css?.fields,
          [key]: value
        }
      }
    }))
  }

  return (
    <div className="grid grid-cols-3 gap-x-50">
      {STYLE_DEFS.map(({ key, label }) => {
        return (
          <Form.Item label={label} key={key}>
            <Input value={customPrintCss?.fields?.[key] ?? ''} onChange={(e) => handleChange(e, key)} type="number" />
          </Form.Item>
        )
      })}
    </div>
  )
}
