/**
 * Get the frequency of a cron expression
 * todo: improve system wide cron ux
 * @param cronExpression
 */
export const getCronFrequency = (cronExpression: string): 'month' | 'week' | 'day' | any | null => {
  const fields = cronExpression.split(' ')
  const dayOfMonth = fields[2]
  const dayOfWeek = fields[4]
  const month = fields[3]
  const hour = fields[1]
  const minute = fields[0]

  if (minute === '*' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return 'minute'
  }

  if (minute === '0' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return 'hour'
  }

  if (dayOfMonth === '*' && dayOfWeek === '*' && month === '*') {
    return 'day'
  }

  if (month === '*' && dayOfWeek === '*') {
    return 'month'
  }

  if (dayOfMonth === '*' && month === '*' && dayOfWeek !== '*') {
    return 'week'
  }

  return null
}
