import { JobAvatar } from '@/modules/jas/job-manager/components/job-ticket-detail/JobAvatar'
import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { JobResourceSku } from '@/modules/jas/job-manager/types'
import { SCHEDULE_PATHS } from '@/modules/jas/scheduler/constants'
import { ModuleLinkButton } from '@/modules/module/components'
import { JasResourceResponse } from '@/types/jas-resource'
import { AppLink } from '@/ui/AppLink'
import { Icon } from '@/ui/icons'
import { Button, Popover, Tag, Tooltip } from 'antd'
import { groupBy, isEmpty, uniq } from 'lodash'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  resource: JasResourceResponse
}

export const TitleCellPopover: React.FC<Props> = ({ resource }) => {
  const { moduleId } = useParams<{ moduleId: string }>()

  const { getJobRoleName } = useJobRoles()

  const skills = uniq(Object.values(resource.attributes || {}))

  const [_skus, byRole] = useMemo(() => {
    const _skus = resource.skus.filter((e) => e.job_role_id)
    const _byRole: Record<number, JobResourceSku[]> = groupBy(_skus, 'job_role_id')
    return [_skus, _byRole]
  }, [resource.skus])

  const link =
    resource.type === 'Equipment'
      ? SCHEDULE_PATHS.equipment(moduleId, resource.id)
      : SCHEDULE_PATHS.employee(moduleId, resource.id)

  const jobRoleName = resource?.job_roles?.[0]?.name // take first

  return (
    <Popover
      mouseEnterDelay={0.25}
      overlayStyle={{
        width: 350
      }}
      placement="right"
      title={
        <div className="flex flex-row align-middle justify-between py-4 border-b border-b-gray-100">
          <div className="flex flex-row items-center align-middle">
            <JobAvatar resource={resource} />
            <div className="flex flex-col ml-6">
              <span>{resource.name}</span>
              <small className={'font-normal'}>{jobRoleName}</small>
            </div>
          </div>
          <Tooltip title={`Edit ${resource.type}`}>
            <ModuleLinkButton
              by={'type'}
              value={'RM'}
              size={'small'}
              iconName={'fa:external-link'}
              target={'_blank'}
              urlSuffix={`/resources/employees/${resource.id}/edit`}
            >
              Edit
            </ModuleLinkButton>
          </Tooltip>
        </div>
      }
      content={
        <div className="flex flex-col">
          <div className="mb-6">
            <b>Skills:</b>
            <br />{' '}
            <span>
              {skills.map((skill) => (
                <Tag className="mb-4" key={skill}>
                  {skill}
                </Tag>
              ))}
              {skills.length === 0 && <span>No Skills</span>}
            </span>
          </div>
          <div className="mb-6">
            <b>Job Roles SKU:</b>
            <br />
            <div>
              {Object.entries(byRole).map(([roleId, equipments]) => (
                <div key={roleId}>
                  {equipments.map((e) => (
                    <div key={e.id} className="flex flex-row align-middle py-2">
                      <div>
                        <Tooltip title={getJobRoleName(Number(roleId))}>
                          <Tag className="m-0" color="default">
                            {getJobRoleName(Number(roleId))[0]}
                          </Tag>
                        </Tooltip>
                      </div>
                      <div className="px-4">
                        {e.sku} / {e.description}
                      </div>
                      <div>
                        <Tooltip title={'Edit SKU'}>
                          <Button
                            target="_blank"
                            href={`/records/skus/${e.id}/edit`}
                            size="small"
                            icon={<Icon name={'fa:pencil'} />}
                          ></Button>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    >
      <AppLink to={link} className="text-neutral hover:text-neutral" data-cy={`${resource.type}-${resource.name}`}>
        <div className="flex flex-col h-full justify-center pr-10">
          <div className="text-ellipsis overflow-hidden whitespace-nowrap font-bold">{resource.name}</div>
          <div className="text-ellipsis text-gray-500 overflow-hidden whitespace-nowrap font-semibold text-sm min-w-0">
            {isEmpty(skills) ? 'No skills' : skills.join(', ')}
          </div>
        </div>
      </AppLink>
    </Popover>
  )
}
