import { Select } from '@/ui'
import { Form, Input, Switch } from 'antd'
import { useAtomValue } from 'jotai'
import React, { FC, useMemo } from 'react'
import { customFormAtom } from '../../atoms'
import { BpaActionAttachPrintedTicket } from '../../schemas'

type Props = {
  action: BpaActionAttachPrintedTicket
  onChange: (data: Partial<BpaActionAttachPrintedTicket>) => void
}

export const InputAttachPrintedTicket: FC<Props> = ({ action, onChange }) => {
  const customForm = useAtomValue(customFormAtom)
  const printStyles = useMemo(
    () => [
      { label: 'Default', value: 'default' },
      ...(customForm?.print_styles || []).map((ps) => ({ label: ps, value: ps }))
    ],
    [customForm?.print_styles]
  )

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Custom File Name" className="w-1/2">
          <Input value={action.custom_name || ''} onChange={(e) => onChange({ custom_name: e.target.value })} />
        </Form.Item>
        <Form.Item label="File Format">
          <Select
            defaultValue={action.file_format || 'pdf'}
            options={[
              { value: 'pdf', label: 'PDF' },
              { value: 'html', label: 'HTML' }
            ]}
            onChange={(val) => onChange({ file_format: val })}
          />
        </Form.Item>
      </div>
      <Form.Item label="StyleSheets">
        <Select
          defaultValue={action.stylesheets || ['default']}
          options={printStyles}
          onChange={(_, option: any) => onChange({ stylesheets: option })}
        />
      </Form.Item>
      <Form.Item label="Attachments">
        <Switch
          checked={action.include_attachments || false}
          onChange={(val) => onChange({ include_attachments: val })}
        />
      </Form.Item>
      <Form.Item label="Line Item Attachments">
        <Switch
          checked={action.line_item_attachments || false}
          onChange={(val) => onChange({ line_item_attachments: val })}
        />
      </Form.Item>
      <Form.Item label="Show Internal Use Only">
        <Switch
          checked={action.show_internal_use_only || false}
          onChange={(val) => onChange({ show_internal_use_only: val })}
        />
      </Form.Item>
    </div>
  )
}
export const InputAttachPrintedTicketMemo = React.memo(InputAttachPrintedTicket)
