import { TransformationTask } from '@/types/transformation-task'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Item } from '../item'

type Props = {
  transformationTask: TransformationTask
  onDeleteClick: (id: number) => void
  onEditClick: (id: TransformationTask) => void
}

export const SortableItem = ({ transformationTask, onDeleteClick, onEditClick }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: transformationTask.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Item
      ref={setNodeRef}
      style={style}
      transformationTask={transformationTask}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
      {...attributes}
      {...listeners}
    />
  )
}
