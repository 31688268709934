import { useApp, useFormWatch } from '@/hooks'
import { Label } from '@/modules/ticket/form/components/ui'
import { useCustomForm, useTicket, useTicketFormValues } from '@/modules/ticket/form/hooks'
import { programmedReportApi } from '@/services/api-service'
import { Button, Select } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { parsePeriodDates } from '../../helpers'
import { useTemplateRenderer } from '../../hooks'
import { ButtonConfig } from '../../types'
import { PayPeriodPicker } from './pay-period-picker'

export const GenerateReport: FC<{ config: ButtonConfig }> = ({ config }) => {
  const form = Form.useFormInstance()

  const { ticket } = useTicket()
  const { getPropertyValueByKey } = useTicketFormValues()
  const { getPropertyByKey } = useCustomForm()

  // get report details based on selected report index
  const reportIdx = Form.useWatch(['report_idx'])
  const report = useMemo(() => {
    const _report = config?.reports?.[reportIdx]
    if (!_report) return null

    const property = _report.property_key ? getPropertyByKey(_report.property_key) : null
    const value = _report.property_key ? getPropertyValueByKey(_report.property_key) : property?._options?.[0]?.value
    return { ..._report, _payPeriod: { property, value } }
  }, [config?.reports, getPropertyByKey, getPropertyValueByKey, reportIdx])

  // set pay period value on report change or default to ticket created/modified date
  useEffect(() => {
    const periodValue =
      report?._payPeriod?.value ||
      `${dayjs.formatLocalDate(ticket.created_at || dayjs.now())}-${dayjs.formatLocalDate(ticket.modified_at || dayjs.now())}`

    form.setFieldsValue({ pay_period: periodValue })
  }, [form, report, ticket.created_at, ticket.modified_at])

  return (
    <div className={'flex flex-col mb-12'}>
      <div className={'flex flex-row gap-10 w-full'}>
        <Form.Item name={['report_idx']} label={'Report'} rules={[{ required: true }]} className={'w-full'}>
          <Select
            options={config?.reports?.map((r, idx) => ({ value: idx, label: r.label }))}
            placeholder={'Select Report'}
            allowClear={false}
          />
        </Form.Item>

        {/* Bonus Pay Period */}
        {report?.type === 'B' && (
          <Form.Item name={['pay_period']} label={'Pay Period'} rules={[{ required: true }]} className={'w-full'}>
            {report?._payPeriod?.property ? (
              <Select options={report?._payPeriod?.property._options} />
            ) : (
              <PayPeriodPicker className={'w-full'} />
            )}
          </Form.Item>
        )}

        {/* Cycle Count (Fleet) */}
        {report?.type === 'C' && (
          <Form.Item name={'fleet_name'} label={<Label k={'ticket__afe__label'} />} className={'w-full'}>
            <Input disabled />
          </Form.Item>
        )}
      </div>

      <GenerateButton report={report} />
    </div>
  )
}

export const GenerateButton: FC<{ report: any }> = ({ report }) => {
  const form = Form.useFormInstance()

  const { ticket } = useTicket()

  const { notification } = useApp()
  const { renderTemplate } = useTemplateRenderer()
  const generateMutation = useMutation(programmedReportApi.etlGenerate())

  const isGenerating = !isEmpty(useFormWatch(['generating']) || {})

  const handleGenerate = useCallback(async () => {
    if (!report) return notification.warning({ message: 'Validation Error', description: 'Please select a report' })

    try {
      const data = await form.validateFields()

      const customFilter = Object.fromEntries(
        Object.entries(report?.filter || {})?.map(([k, v]) => [k, renderTemplate(v)])
      )

      if (report?.type === 'B') {
        const period = parsePeriodDates(data.pay_period)
        customFilter.pay_period = [period?.start?.startOf('day').formatISO(), period?.end?.endOf('day').formatISO()]
      }

      try {
        const log = await generateMutation.mutateAsync({
          id: report?.programmed_report_id,
          ticket_id: ticket.id,
          custom_filter: customFilter
        })
        form.setFieldsValue({ generating: { [log.id]: true } })

        notification.info({
          message: 'Report Generation Started',
          description: 'Please check recent reports for the generated report'
        })
      } catch (error: any) {
        notification.error({
          message: 'Report Generation Failed',
          description: error?.toString()
        })
      }
    } catch (e) {
      notification.warning({ message: 'Validation Error', description: 'Please check input fields' })
    }
  }, [form, generateMutation, notification, renderTemplate, report, ticket.id])

  return (
    <Button
      loading={isGenerating || generateMutation.isLoading}
      disabled={!report}
      type={'primary'}
      onClick={handleGenerate}
    >
      Generate
    </Button>
  )
}
