import { useTicket, useTicketFlags } from '../../../hooks'
import { AddAttachmentButton, AttachmentCard } from '../../properties/property-form-item/attachment'

export const TabAttachments = () => {
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()

  return (
    <div>
      <div className={'relative flex flex-col gap-10'}>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-16">
          {ticket.attachments.map((attachment) => (
            <AttachmentCard key={attachment.id} attachment={attachment} disabled={viewOnly} />
          ))}
        </div>

        <div className={'flex flex-row items-center w-full h-full'}>
          <AddAttachmentButton disabled={viewOnly} />
        </div>
      </div>
    </div>
  )
}
