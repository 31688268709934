import { Form, FormInstance } from 'antd'
import { useCallback } from 'react'
import { calcAmount, calcEndDatetime, calcUnitsUsed } from '../helpers'
import { LineItemForm } from '../schemas'

export const useFormValues = (formInstance?: FormInstance<LineItemForm>) => {
  const defaultForm = Form.useFormInstance<LineItemForm>()
  const form = formInstance || defaultForm

  const handleValuesChange = useCallback(
    async (changedValues: Partial<LineItemForm>, allValues: Record<string, any>) => {
      // reset category if parentCategory is changed
      if ('parentCategory' in (changedValues?.options || {})) {
        form.setFieldValue(['options', 'category'], null)
      }

      const options = form.getFieldValue(['options'])
      const lineItem = { ...allValues['data'] }

      if (!options?.disableDurationUpdate) {
        // calculate duration (units_used) if start_datetime or end_datetime are changed
        if (changedValues.data?.start_datetime || changedValues.data?.end_datetime) {
          const unitsUsed = calcUnitsUsed(lineItem)
          if (unitsUsed !== lineItem.units_used) {
            form.setFieldValue(['data', 'units_used'], unitsUsed)
            lineItem.units_used = unitsUsed
          }
        }

        // calculate end_datetime if units_used is changed
        if (changedValues.data?.units_used) {
          const endDatetime = calcEndDatetime(lineItem)
          if (endDatetime !== lineItem.end_datetime) {
            form.setFieldValue(['data', 'end_datetime'], endDatetime)
            lineItem.end_datetime = endDatetime
          }
        }
      }

      const amount = calcAmount(lineItem)
      if (amount !== lineItem.amount) form.setFieldValue(['data', 'amount'], amount)
    },
    [form]
  )

  return {
    handleValuesChange
  }
}
