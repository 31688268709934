import { TICKET_STATUS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { ListView } from '@/layouts/views'
import { navigate } from '@/routing/helpers'
import { companyApi, customFormApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Icon } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Table, Tooltip } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { StageInput } from './StageInput'
import { companyStageAtom, customFormAtom, stageTableItemAtom } from './atoms'
import { useCustomForms } from './hooks'

export const TicketStagesView = () => {
  const { l } = useApp()
  const { data: customFormData, isLoading, isRefetching, refetch } = useCustomForms()
  const { company } = useSession()
  const { refreshUserSession } = useBootstrap()
  const {
    data: companyData,
    isLoading: companyDataLoading,
    refetch: companyDataRefetch
  } = useQuery({ ...companyApi.get<Company>(company.id) })
  const customForm = useAtomValue(customFormAtom)
  const companyStage = useAtomValue(companyStageAtom)
  const setStageTableItems = useSetAtom(stageTableItemAtom)
  const stageTableItem = useAtomValue(stageTableItemAtom)
  const companyCustomStatus = companyData?.data?.options?.custom_status
  const { notification } = useApp()
  const ticketStatus = TICKET_STATUS

  const tableData = useMemo(() => {
    const tableHeader: {
      key: string
      title: string | undefined
      dataIndex: string | undefined
      sorter: boolean
      width: number
      render: any
    }[] = []
    tableHeader.push({
      key: 'Company Stage Name',
      title: 'Company Stage Name',
      dataIndex: 'Company Stage Name',
      sorter: false,
      width: 200,
      render: (data: string, record: { key: string }) => {
        return (
          <StageInput key={record.key} name={'Company Stage Name'} data={data} title={record.key} id={company.id} />
        )
      }
    })
    const customFormLists =
      customFormData?.items.map((item) => {
        // const userData = item && item.userdata ? JSON.parse(item?.userdata_json):''
        const userData = item.userdata
        tableHeader.push({
          key: item.id.toString(),
          title: item?.name,
          dataIndex: item.name,
          sorter: false,
          width: 250,
          render: (data: string, record: { key: string }) => {
            return <StageInput name={item.name!} data={data} title={record.key} id={item.id} />
          }
        })
        return {
          name: item.name,
          ...userData?.custom_status
        }
      }) || []

    const companyStages = {
      name: 'Company Stage Name',
      ...companyCustomStatus
    }
    customFormLists?.unshift(companyStages)
    const stages = {
      name: 'Stage',
      ...ticketStatus
    }
    customFormLists?.unshift(stages)
    const tableItems = Object.entries(ticketStatus).map((ticket) => {
      let data = {
        key: ticket[0]
      }

      customFormLists.forEach((list) => {
        data = { ...data, [list.name]: list[ticket[0]] ?? '' }
      })
      return data
    })
    setStageTableItems(tableItems)
    return { tableHeader, tableItems }
  }, [customFormData?.items, companyCustomStatus, ticketStatus, setStageTableItems, company.id])

  const mutationSpec = customFormApi.patch()
  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async (data) => {},
    onError: async (error) => {
      // notification.error({ message: 'Custom Status failed to Save' })
      console.log(error)
    }
  })
  const companyMutationSpec = companyApi.patch()
  const saveCompanyMutation = useMutation({
    ...companyMutationSpec,
    onSuccess: () => {
      companyDataRefetch()
    }
  })

  useEffect(() => {
    refreshUserSession()
  }, [refreshUserSession])

  const handleFormSave = async () => {
    const saveFormPromises = []
    if (companyStage) {
      const formData = {
        id: companyStage?.id,
        data_json: {
          ...company?.data,
          options: {
            ...company?.data?.options,
            custom_status: Object.fromEntries(
              Object.entries({
                ...company?.data?.options?.custom_status,
                ...companyStage?.data?.options?.custom_status
              }).map(([key, value]) => [key, (value?.toString() || '').trim() || undefined])
            )
          }
        }
      }
      saveCompanyMutation.mutateAsync(formData)
    }
    if (customForm.length > 0) {
      const customFormPromises = customForm.map((cf) => {
        if (cf.id !== company.id) {
          const formData = {
            id: cf.id,
            userdata_json: JSON.stringify({
              ...cf.userdata,
              custom_status: Object.fromEntries(
                Object.entries(cf?.userdata?.custom_status).map(([key, value]) => [
                  key,
                  (value?.toString() || '').trim() || undefined
                ])
              )
            })
          }
          return saveFormMutation.mutateAsync(formData)
        }
      })
      saveFormPromises.push(...customFormPromises)
    }

    await Promise.all(saveFormPromises)
      .then((results) => {
        notification.success({ message: 'Custom Status Successfully  Saved' })
        console.log('All API calls completed:', results)
      })
      .catch((error) => {
        notification.error({ message: 'Custom Status failed to Save' })
        console.error('Error in API calls:', error)
      })

    refetch()
  }

  const handleCancelClick = () => {
    navigate('/import')
  }

  const onClearClick = (index: number) => {
    const selectedStage = stageTableItem[index]
    const { key, Stage, ...rest } = selectedStage
    const toEmptyKeys = Object.keys(rest).map((key, i) => {
      return { [key]: '' }
    })
    const arrayToObjectKeys = Object.assign({}, ...toEmptyKeys)
    const keys = { key, Stage, ...arrayToObjectKeys }
    const newStageItem = stageTableItem.map((item) => {
      if (item.key === key) {
        return keys
      }
      return item
    })
    setStageTableItems(newStageItem)
    notification.success({ message: 'Cleared' })
  }
  return (
    <ListView
      loading={isLoading || companyDataLoading}
      header={{
        title: 'Ticket Stages',
        description: (
          <div className="flex items-center pt-4">
            <Icon name="fa:warning" className="text-warning mr-4" />
            <p className="mb-0">
              WARNING! Changing the status name does not change the ticket logic. For example, Draft – Created – Open
              are the only stages available on mobile.
            </p>
          </div>
        ),
        actions: [
          <Button
            key={'save'}
            type="success"
            onClick={handleFormSave}
            loading={saveFormMutation.isLoading || saveCompanyMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            Save
          </Button>
        ]
      }}
      renderTable={() => (
        <Table
          loading={isLoading || isRefetching}
          scroll={{ x: '100%' }}
          dataSource={stageTableItem}
          columns={[
            { key: 'Stage', title: 'Stage', dataIndex: 'Stage', width: 150, fixed: true },
            ...tableData.tableHeader,
            {
              key: 'actions',
              width: 80,
              sorter: false,
              render(value, record, index) {
                return (
                  <Tooltip placement="topRight" title={'Clear All Row'}>
                    <span
                      className="cursor-pointer text-xl text-red-500 font-bold inline-block w-[24px] text-center"
                      onClick={() => onClearClick(index)}
                    >
                      {' '}
                      &times;{' '}
                    </span>
                  </Tooltip>
                )
              }
            }
          ]}
        />
      )}
    />
  )
}
