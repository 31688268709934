import { FIELD_LABELS } from '@/constants/labels'
import { TLabels } from '@/hooks/use-app'
import { EntityColumn } from '@/types/entity-column'
import { DraggableItem } from '../types'

export const processColumn = (column: EntityColumn, labels: TLabels, displayfield?: any): DraggableItem => {
  const _name =
    labels[`report__${column.field_verbose}`] ||
    labels[`lineitem__${column.field_verbose}`] ||
    FIELD_LABELS[`report__${column.field_verbose}`] ||
    FIELD_LABELS[`lineitem__${column.field_verbose}`] ||
    column.name

  return {
    ...column,
    _name,
    id: column.key,
    sort: displayfield?.sort || null
  }
}
