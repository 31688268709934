import { useSession } from '@/hooks'
import { profileApi } from '@/services/api-service'
import { getUserRole } from '@/services/auth-service'
import { QueryTable } from '@/ui'
import { Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { allRolesAtom, searchTextAtom, selectedRoleAtom } from '../../atoms'
import { Profile } from '../../types'
import { showInactiveAccountAtom } from '../atom'
import { EditColumn } from './edit-column'

export const ProfileListTable = () => {
  const allRole = useAtomValue(allRolesAtom)
  const setShowInactiveAccount = useSetAtom(showInactiveAccountAtom)
  const showInactiveAccount = useAtomValue(showInactiveAccountAtom)
  const selectedRole = useAtomValue(selectedRoleAtom)
  const searchText = useAtomValue(searchTextAtom)
  const { user } = useSession()

  const roleById = (id: number | null) => {
    if (allRole.length > 0 && id) {
      const role = allRole.filter((rol) => rol.value === id)
      return role[0]?.label
    }
    return 'Custom Role'
  }

  const onSwitchActiveStatus = (e: boolean) => {
    if (e) setShowInactiveAccount(1)
    else setShowInactiveAccount(0)
  }

  return (
    <div className="mt-16">
      <QueryTable
        queryApi={profileApi.list}
        queryParams={{
          order: '-id',
          user__is_active__eq: showInactiveAccount === 0 ? 1 : 0,
          ...(selectedRole !== -1 && { role_id__eq: selectedRole }),
          ...(searchText.trim() && { search: searchText.trim() })
        }}
        columns={[
          ...(user?.is_superuser
            ? [
                {
                  title: 'Company',
                  dataIndex: 'company__name'
                }
              ]
            : []),
          {
            title: 'Name',
            dataIndex: 'user__first_name',
            render: (_, profile: Profile) => (
              <div>
                <div>
                  {profile.contact?.first_name} {profile.contact?.last_name}
                </div>
                <p className="text-sm text-gray-400 mb-0">{profile?.contact?.title}</p>
              </div>
            )
          },
          {
            title: 'Username',
            dataIndex: 'user__username',
            render: (_, profile: Profile) => <div>{profile.user?.username}</div>
          },
          {
            title: 'Email',
            dataIndex: 'user__email',
            render: (_, profile: Profile) => <div>{profile.user?.email}</div>
          },
          {
            title: 'Contact ID',
            dataIndex: ['contact', 'id'],
            sorter: false
          },
          {
            title: 'Access Rights',
            dataIndex: 'user__is_staff',
            render: (_, profile: Profile) => <div>{getUserRole(profile.user)}</div>
          },
          {
            title: 'Status',
            dataIndex: 'user__is_active',
            render: (_, profile: Profile) => <div>{profile.user?.is_active ? 'Active' : 'Inactive'}</div>
          },
          {
            title: 'Role',
            dataIndex: 'role__name',
            render: (_, profile: Profile) => <div>{roleById(profile.role)}</div>
          },
          {
            dataIndex: 'id',
            align: 'right',
            sorter: false,
            render: (id, profile: Profile) => <EditColumn profileId={id} profile={profile.user?.id || 0} />
          }
        ]}
      />
      <div className="flex items-center mt-16">
        <Switch
          defaultChecked={showInactiveAccount === 1 ? true : false}
          id="toggleInactive"
          onChange={onSwitchActiveStatus}
        />
        <label htmlFor="toggleInactive" className="cursor-pointer ml-10 inline-block">
          Display Inactive Accounts
        </label>
      </div>
    </div>
  )
}
