import { useCanEditCustomFormCallback } from '@/modules/auth/hooks'
import { AppLink, Button } from '@/ui'
import { useCustomForm } from '../../../hooks'

export const SupportButtons = () => {
  const canEditCustomForm = useCanEditCustomFormCallback()
  const { customForm } = useCustomForm()

  if (!canEditCustomForm(customForm)) return null

  return <SupportButtonsInner />
}

const SupportButtonsInner = () => {
  const { customForm } = useCustomForm()

  return (
    <div className={'hidden md:flex flex-row gap-4'}>
      <AppLink to={`/account/custom_forms/${customForm.id}/layout`} v={2} className="ml-2">
        <Button type="dashed" size="small" primary={true} iconName="fa:grid-2-plus" />
      </AppLink>
      <AppLink to={`/account/custom_forms/${customForm.id}/edit`} v={2} className="ml-2">
        <Button type="dashed" size="small" primary={true} iconName="fa:pen" />
      </AppLink>
    </div>
  )
}
