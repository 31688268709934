import { Modal } from 'antd'
import { useAtom } from 'jotai'
import { isUpdateDefaultLiModalOpenAtom } from '../atoms'
import { useDefaultLiFormsQuery, useDefaultLiUpdate } from '../hooks'

export const UpdateDefaultLiModal = () => {
  const [isModalVisible, setIsModalVisible] = useAtom(isUpdateDefaultLiModalOpenAtom)
  const { defaultLiFormsQuery } = useDefaultLiFormsQuery()
  const { defaultLiUpdateMutation, updateDefaultLi } = useDefaultLiUpdate()

  const handleUpdate = async () => {
    await updateDefaultLi()
    setIsModalVisible(false)
  }

  if (defaultLiFormsQuery.data?.items.length === 0) {
    return null
  }

  return (
    <Modal
      open={isModalVisible}
      onOk={handleUpdate}
      okText="Update"
      cancelText="Close"
      onCancel={() => setIsModalVisible(false)}
      closeIcon={null}
      okButtonProps={{ loading: defaultLiUpdateMutation.isLoading }}
    >
      <div className="flex mb-14 gap-x-4">
        <div className="font-bold">SKU Default</div>
        <div>(being used as default line items on custom forms)</div>
      </div>
      <div>
        {defaultLiFormsQuery.data?.items.map((item, index) => (
          <div key={item.id} className="font-bold">
            {index + 1}. {item.name}
          </div>
        ))}
      </div>
    </Modal>
  )
}
