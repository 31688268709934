import { clientRecordApi } from '@/services/api-service'
import { makeMap } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { z } from 'zod'

export function useAllSkills() {
  const skillsQuery = useQuery(
    clientRecordApi.list({
      fields: z.object({
        char_1: z.string(),
        char_3: z.string()
      }),
      label__eq: 'Skills'
    })
  )

  const skillsMap = useMemo(() => makeMap(skillsQuery.data?.items || [], 'char_1'), [skillsQuery.data])

  const getSkillLabel = (skill: string) => skillsMap[skill]?.char_3 || skill

  return { skillsQuery, getSkillLabel }
}
