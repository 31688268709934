import { AddEditTimeView } from './add-edit-time'
import { TIME_PATHS } from './constants'
import { MyTimeView } from './my-time'

export const timeRoutes = [
  {
    path: TIME_PATHS.myTime,
    element: <MyTimeView />
  },
  {
    path: TIME_PATHS.add,
    element: <AddEditTimeView />
  },
  {
    path: TIME_PATHS.edit(':id'),
    element: <AddEditTimeView />
  }
]
