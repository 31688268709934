import { filterSelectOption } from '@/utils/filter-select-option'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { selectedPropertyAtom } from '../../atoms'
import { PropertyList } from '../../hooks'

const { TextArea } = Input
type SectionProps = {
  name: string
  data: PropertyList[]
}
const Section = ({ name, data }: SectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tempSelectedProperty, setTempSelectedProperty] = useState<number[]>([])
  const [selectedProperty, setSelectedProperty] = useState<number[]>([])

  const selectedPropertyList = useAtomValue(selectedPropertyAtom)

  const handleCheckboxChange = (id: number) => {
    if (tempSelectedProperty.includes(id)) {
      const filteredProperty = tempSelectedProperty.filter((fId) => fId !== id)
      setTempSelectedProperty(filteredProperty)
    } else {
      setTempSelectedProperty([...tempSelectedProperty, id])
    }
  }

  const handleAddProperty = () => {
    setSelectedProperty(tempSelectedProperty)
    setIsModalOpen(false)
  }

  useEffect(() => {
    const propertySet = new Set(selectedPropertyList)
    setSelectedProperty([...propertySet])
    setTempSelectedProperty([...propertySet])
  }, [selectedPropertyList])
  const inputProperties = ['Input', 'Label', 'Enter', 'String']
  const dropdownProperties = ['Dropdown', 'MultivariateDropdown']
  const numberProperties = ['Integer', 'Decimal', 'PhoneNumber']
  const disabledProperties = [
    'Pin',
    'Attachment',
    'Signature',
    'Image',
    'DisplayImage',
    'Script',
    'ScriptedButton',
    'Spacer',
    'Automation',
    'Date',
    'DateTime',
    'Time'
  ]

  return (
    <>
      <div className=" py-12 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold text-16 mb-0">
            {name} ({data.length})
          </h4>
          <Button icon={<PlusOutlined />} type="primary" onClick={() => setIsModalOpen(true)}>
            Select Property
          </Button>
        </div>

        {/* Inputs */}
        <div className="flex flex-wrap -mx-10">
          {selectedProperty &&
            selectedProperty.map((sp, i) => {
              const filteredData = data.filter((dt) => dt.id === sp)
              const currentProperty = filteredData[0]
              const options = [] as { value: string; label: string }[]

              if (dropdownProperties.includes(currentProperty?.property_type__name)) {
                const optionValues = currentProperty.values?.split('\n')
                optionValues?.map((opt) => {
                  const splitOpt = opt.split(',')
                  options.push({
                    value: splitOpt[0],
                    label: splitOpt[1]
                  })
                })
              }
              if (disabledProperties.includes(currentProperty?.property_type__name)) {
                return null
              }
              if (selectedProperty.includes(currentProperty?.id)) {
                return (
                  <div className="px-10  w-full lg:w-4/12" key={currentProperty?.id + '-' + i}>
                    <Form.Item name={currentProperty?.id} label={currentProperty?.name} className="w-full">
                      {inputProperties.includes(currentProperty?.property_type__name) ? (
                        <Input placeholder="Enter" className="w-full" />
                      ) : dropdownProperties.includes(currentProperty?.property_type__name) ? (
                        <Select
                          showSearch
                          filterOption={filterSelectOption}
                          placeholder="Enter"
                          options={options}
                          className="w-full"
                        />
                      ) : numberProperties.includes(currentProperty?.property_type__name) ? (
                        <InputNumber placeholder="Enter" stringMode className="w-full" />
                      ) : currentProperty?.property_type__name === 'MultilineString' ? (
                        <TextArea placeholder="Enter Email Address" className="w-full" />
                      ) : currentProperty?.property_type__name === 'Email' ? (
                        <Input placeholder="Enter Email Address" className="w-full" />
                      ) : currentProperty?.property_type__name === 'Date' ? (
                        <DatePicker className="w-full" />
                      ) : currentProperty?.property_type__name === 'Time' ? (
                        <TimePicker className="w-full" />
                      ) : currentProperty?.property_type__name === 'DateTime' ? (
                        <DatePicker
                          placeholder="Selected Date & Time"
                          format="YYYY-MM-DD HH:mm:ss"
                          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                          className="w-full"
                        />
                      ) : null}
                    </Form.Item>
                  </div>
                )
              }
              return null
            })}
        </div>
        {/* Inputs */}
      </div>
      <Modal
        title={`Properties for ${name}`}
        open={isModalOpen}
        okText="Add / Update Property"
        onCancel={() => setIsModalOpen(false)}
        onOk={handleAddProperty}
      >
        <div className="">
          <h5>Title</h5>
          {data.map((property) => {
            return (
              <div className="" key={property.id}>
                <Checkbox
                  checked={tempSelectedProperty.includes(property.id)}
                  onChange={() => handleCheckboxChange(property.id)}
                  disabled={disabledProperties.includes(property.property_type__name)}
                >
                  {property.name}
                </Checkbox>
              </div>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

export default React.memo(Section)
