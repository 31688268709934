import { ApiResource, MutationApiFn } from '@/types/api/core'
import { createResourceApi, makeCustomMutation } from '../core'

export const jasDistrictApi = createResourceApi('jas/districts')
export const jasJobTicketApi = createResourceApi('jas/job_tickets')
export const jasResourceApi = createResourceApi('jas/resources')
export const v2JasResourceApi: ApiResource & { savePatches: MutationApiFn } = {
  ...createResourceApi('v2/jas/resources'),
  savePatches(config, urlAppend?, invalidate = true) {
    return makeCustomMutation({ path: 'save_patches', meta: this.meta, config, urlAppend, invalidate })
  }
}
export const jasResourceGroupApi = createResourceApi('jas/resource_groups')
export const jasScheduleEventApi = createResourceApi('jas/schedule_events', [], ['v2/jas/resources'])
export const jasSchedulePatchApi = createResourceApi('jas/schedule_patches', [], ['v2/jas/resources'])
export const jasScheduleRuleApi = createResourceApi('jas/schedule_rules', [], ['v2/jas/resources'])
export const jasScheduleRuleTemplateApi = createResourceApi('jas/schedule_rule_templates')
export const jasSkillApi = createResourceApi('jas/skills')
