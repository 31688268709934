import { companyApi, programmedReportApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { QuerySelect, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'

export const LoadDataSet = () => {
  const form = Form.useFormInstance()
  const params = Form.useWatch('params') || {}

  const programmedReportsQuery = useQuery({
    ...programmedReportApi.list({ limit: 'None', type__exact: 'B', company__id: params.company_id }),
    enabled: !!params.company_id
  })

  const companyQuery = useQuery({ ...companyApi.get<Company>(params.company_id), enabled: !!params.company_id })

  return (
    <>
      <Form.Item label="Company" rules={[{ required: true }]}>
        <QuerySelect
          apiSearchBy="name"
          apiEndpoint={companyApi.list}
          renderOption={(item) => ({ label: item.name, value: item.id })}
          labelInValue
          value={{
            label: params.company_name || companyQuery.data?.name,
            value: params.company_id || companyQuery.data?.id
          }}
          onChange={({ label, value }) => {
            form.setFieldValue(['params', 'company_id'], value)
            form.setFieldValue(['params', 'company_name'], label)
            form.setFieldValue(['params', 'programmed_report_id'], undefined) // reset when company changes
          }}
        />
      </Form.Item>
      <Form.Item label="Programmed Report" name={['params', 'programmed_report_id']} rules={[{ required: true }]}>
        <Select
          options={programmedReportsQuery.data?.items.map((item) => ({ value: item.id, label: item.report.name }))}
        />
      </Form.Item>
    </>
  )
}
