import { useSetAtom } from 'jotai'
import { cloneDeep } from 'lodash'
import { useEffect } from 'react'
import { customFormAtom } from '../atoms'
import { useCustomFormQuery } from '../hooks'

export const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { customFormQuery } = useCustomFormQuery()
  const setCustomForm = useSetAtom(customFormAtom)

  // Set custom form to atoms when data is fetched
  useEffect(() => {
    if (customFormQuery.data) {
      const customForm = cloneDeep(customFormQuery.data)
      setCustomForm(customForm)
    }
  }, [customFormQuery.data, setCustomForm])

  return <>{children}</>
}
