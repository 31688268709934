import { useApp } from '@/hooks'
import { useJobTicketSave } from '@/modules/jas/job-manager/hooks'
import { JobTicket } from '@/modules/jas/job-manager/types'
import { Button, Popconfirm } from 'antd'
import React from 'react'

type Props = {
  jobTicket: JobTicket
}

export const JobDispatchButton: React.FC<Props> = ({ jobTicket }) => {
  const { notification } = useApp()
  const { saveTicket, isLoading } = useJobTicketSave()

  const onDispatchClick = () => {
    saveTicket({
      action: 'dispatchJob',
      onSuccess: () => {
        // // invalidate cache and refetch
        // queryClient.invalidateQueries({
        //   predicate: (query) => query.queryKey[0] === 'jas/job_tickets'
        // })

        notification.success({
          message: 'Job Dispatched',
          description: `Job #${jobTicket.name} has been dispatched successfully`
        })
      },
      onError: () => {
        notification.error({
          message: 'Job Dispatched Failed',
          description: `Failed to dispatch Job #${jobTicket.name}`
        })
      }
    })
  }

  // only show dispatch button when status is Draft/Unassigned
  if (jobTicket.status !== 'D') return <></>

  return (
    <Popconfirm
      placement="bottom"
      title={`Dispatching Job #${jobTicket.name}`}
      description={'Are you sure to dispatch this job?'}
      onConfirm={onDispatchClick}
      okText="Yes"
      cancelText="No"
    >
      <Button loading={isLoading} type="primary" size="middle" className="mr-10 px-16 text-sm">
        Dispatch
      </Button>
    </Popconfirm>
  )
}
