import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { IconSelect } from '../icon-select'
import { PropertiesSelect } from '../properties-select'

export const ModuleTypeSchedulerFields = () => {
  const form = Form.useFormInstance()
  const customFormsQuery = useQuery(customFormApi.list({ limit: 'None' as any, fields: 'id,name' }))
  const customFormId = Form.useWatch(['config', 'for_jm', 'custom_form_id'], form)

  return (
    <div>
      <h5 className="font-bold">Scheduler and Assigner Config</h5>
      <div className="grid grid-cols-3 gap-16 mb-6">
        <CustomFormSelect
          formItemProps={{ label: 'Custom Form', name: ['config', 'for_jm', 'custom_form_id'] }}
          renderOptions={(options) => [{ label: 'Default', value: '' }, ...options]}
        />
        <HideDefaultNoneView />
      </div>
      <div className="grid grid-cols-3 gap-16">
        {[
          ['attributes', 'Skills and Attributes', false],
          ['requirements', 'Requirements', false],
          ['start_date', 'Start Date/Time', false],
          ['estimated_hours', 'Estimated Hours', false],
          ['line_3', 'Description', false]
        ].map(([key, label, hasIcon], index) => (
          <div key={index} className="rounded shadow p-12">
            <div className="font-bold mb-16">{label}</div>
            <div className="flex flex-row gap-10">
              {hasIcon && (
                <Form.Item label="Icon" name={['config', 'for_jm', `${key}_icon`]}>
                  <IconSelect onSelect={(icon) => form.setFieldValue(['config', 'for_jm', `${key}_icon`], icon)} />
                </Form.Item>
              )}
              <PropertiesSelect
                formItemProps={{
                  label: 'Bind Property',
                  name: ['config', 'for_jm', `${key}_pid`],
                  className: 'w-full'
                }}
                customFormsIds={customFormId ? [customFormId] : (customFormsQuery.data?.items.map((cm) => cm.id) ?? [])}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
