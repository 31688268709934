import { mergeWith } from 'lodash'

/**
 * Deep merge two objects.
 * If the value of the same key is an array, it will be concatenated.
 * Useful for merging query parameters like Q[].
 */
export const deepMerge = <TObject, TSource>(
  object: TObject,
  source: TSource,
  options?: { concat?: boolean }
): TObject & TSource => {
  const { concat: arrayConcat = true } = options || {}

  return mergeWith(object, source, (objValue, srcValue) => {
    if (typeof objValue === 'string') return srcValue
    else if (Array.isArray(objValue)) {
      return arrayConcat ? objValue.concat(srcValue) : srcValue
    }
  })
}
