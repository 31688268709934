import { QUERY_AUTO_REFRESH_OPTIONS } from '@/query'
import { ticketImportLogApi } from '@/services/api-service'
import { ActionButton, EllipsisMiddle, QueryTable, TipButton, TipButtonProps } from '@/ui'
import { mediaUrl } from '@/utils'
import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Card, Spin } from 'antd'
import dayjs from 'dayjs'
import { useMemo, useRef } from 'react'
import { Log, LogSchema } from './schemas'

export function ImportLogsCard() {
  return (
    <Card className={'h-full'} title={'Import Logs'} styles={{ body: { padding: '10px' } }}>
      <QueryTable
        paramsStore={'local'}
        queryApi={ticketImportLogApi.list}
        queryParams={{ fields: LogSchema, order: '-created_at' }}
        rowKey={'id'}
        scroll={{ y: '450px' }}
        className={'min-h-[498px] border-none'}
        columns={[
          {
            key: 'status',
            dataIndex: 'status',
            sorter: true,
            width: 30,
            render: (_, log) => <ImportLogStatus log={log} />
          },
          {
            key: 'file_name',
            dataIndex: 'file_name',
            sorter: true,
            title: 'File Name',
            width: 200,
            render: (value) => <EllipsisMiddle suffixCount={6}>{value}</EllipsisMiddle>
          },
          {
            key: 'created_at',
            dataIndex: 'created_at',
            sorter: true,
            width: 200,
            title: 'Created At',
            render: (value) => dayjs.formatLocal(value) || ''
          },
          {
            key: 'actions',
            width: 30,
            render: (_, row) => (
              <ActionButton
                size={'small'}
                title={'Download File'}
                iconName={'fa:cloud-download'}
                href={mediaUrl(row.file)}
                download
              />
            )
          }
        ]}
      />
    </Card>
  )
}

function ImportLogStatus({ log }: { log: Log }) {
  const logRef = useRef(log)

  const statusQuery = useQuery({
    ...ticketImportLogApi.get(Number(log.id), undefined, { fields: LogSchema }),
    enabled: logRef.current.status === 'P' || logRef.current.status === 'I',
    ...QUERY_AUTO_REFRESH_OPTIONS,
    refetchInterval: 3000
  })

  const currentLog = statusQuery.data || log
  logRef.current = currentLog

  const buttonProps = useMemo(() => {
    const props: TipButtonProps = {
      shape: 'circle',
      size: 'small'
    }

    const currentStatus = currentLog.status

    if (currentStatus === 'P' || currentStatus === 'I') {
      props.primary = true
      props.children = <Spin indicator={<LoadingOutlined spin />} size="small" />
      props.title = 'Processing...'
    } else if (currentStatus === 'F') {
      props.danger = true
      props.iconName = 'fa:exclamation'
      props.title = currentLog.data?.message || 'Some error occurred'
    } else if (currentStatus === 'W') {
      props.primary = true
      props.iconName = 'fa:warning'
      props.title = currentLog.data?.message || 'Some warning occurred'
    } else if (currentStatus === 'S') {
      props.success = true
      props.iconName = 'fa:check'
      props.title = currentLog.data?.message || 'Success'
    }

    return props
  }, [currentLog])

  return <TipButton {...buttonProps} />
}
