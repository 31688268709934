import { CustomEmail } from '@/schemas/custom-email'
import { customEmailApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { SetOptional } from 'type-fest'

export const useUpsertCustomEmail = () => {
  const createCustomEmailMutation = useMutation({
    ...customEmailApi.create<CustomEmail, SetOptional<CustomEmail, 'id'> & { custom_form_id: number }>()
  })

  const updateCustomEmailMutation = useMutation({
    ...customEmailApi.update<CustomEmail, CustomEmail & { custom_form_id: number }>()
  })

  const upsertCustomEmail = async (data: SetOptional<CustomEmail, 'id'> & { custom_form_id: number }) => {
    const { id, ...rest } = data

    if (!id) {
      return await createCustomEmailMutation.mutateAsync(rest)
    }

    return await updateCustomEmailMutation.mutateAsync({
      id,
      ...rest
    })
  }

  return {
    upsertCustomEmail,
    createCustomEmailMutation,
    updateCustomEmailMutation
  }
}
