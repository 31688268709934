import { Icon, Tooltip } from '@/ui'
import { Checkbox, Divider } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'
import { INVETORY_TRACKING_ENABLED_KEY, inventoryOptions, options } from './constants'

export const TicketRules = () => {
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }

  return (
    <div>
      <h5 className="font-bold">Inventory & Accounting</h5>
      {inventoryOptions.map(
        ({ label, key, hidable, info }) =>
          (!hidable || customForm[INVETORY_TRACKING_ENABLED_KEY as keyof typeof customForm]) && (
            <div key={key} className="mb-12 flex">
              <Checkbox
                data-cy={`custom-form-${key}-checkbox`}
                checked={!!customForm[key as keyof typeof customForm]}
                onChange={(e) => handleChange(e, key)}
              >
                {label}
              </Checkbox>
              <Tooltip title={info || ''}>
                <Icon name="fa:info-circle" />
              </Tooltip>
            </div>
          )
      )}
      <Divider className="my-[20px] bg-gray-200" />
      {options.map(({ label, key }) => (
        <div key={key} className="mb-12">
          <Checkbox
            data-cy={`custom-form-${key}-checkbox`}
            checked={!!customForm[key as keyof typeof customForm]}
            onChange={(e) => handleChange(e, key)}
          >
            {label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
