import { FIELD_LABELS } from '@/constants/labels'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { companyFieldAliasesAtom, configurationAtom } from '../../atoms'
import { LineItemField } from '../../types'

export const useLineItemFieldName = () => {
  const fieldAliases = useAtomValue(useMemo(() => selectAtom(configurationAtom, (c) => c.field_aliases ?? {}), []))
  const companyFieldAliases = useAtomValue(companyFieldAliasesAtom)

  const getLineItemFieldName = useCallback(
    (field: LineItemField) => {
      const originalName = FIELD_LABELS[`lineitem__${field}`] ?? field

      if (fieldAliases[field] || Object.keys(fieldAliases).includes(field)) {
        return {
          isAlias: true,
          name: fieldAliases[field] ?? '',
          originalName
        }
      }

      if (companyFieldAliases?.[`lineitem__${field}`]) {
        return {
          isAlias: true,
          name: companyFieldAliases[`lineitem__${field}`],
          originalName
        }
      }

      return {
        isAlias: false,
        name: originalName,
        originalName
      }
    },
    [fieldAliases, companyFieldAliases]
  )

  return {
    getLineItemFieldName
  }
}
