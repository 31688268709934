/**
 * Number to letter.
 * 0 => A, 1 => B, 2 => C, ..., 25 => Z, 26 => AA, 27 => AB, ...
 * @param num
 */
export const numberToLetter = (num: number) => {
  let result = ''
  while (num > 0) {
    num--
    result = String.fromCharCode(65 + (num % 26)) + result
    num = Math.floor(num / 26)
  }
  return result
}
