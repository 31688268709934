import { useApp } from '@/hooks'
import { LINE_ITEM_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_COLUMNS, TICKET_COLUMNS_WITH_PREFIX } from '@/modules/ticket/columns'
import { TableView } from '@/types/table-view'
import { makeMap } from '@/utils'
import { Form } from 'antd'
import { useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { Property, TableViewFormValues } from '../types'
import { availableFieldsAtom, selectedFieldsAtom } from './atoms'
import { CreateYourViewSection } from './create-your-view-section'
import { DragAndDropFieldsSection } from './drag-and-drop-fields-section'
import { FilterYourViewSection } from './filter-your-view-section'
import { SelectCustomFormsSection } from './select-custom-forms-section'
import { processField } from './utils/process-field'
import { processPropertyFields } from './utils/process-property-fields'
import { sortFields } from './utils/sort-fields'

type Props = {
  properties: Property[]
  tableView?: TableView
  className?: string
}

export const ConfigureTab = ({ tableView, properties, className }: Props) => {
  const { l } = useApp()
  const form = Form.useFormInstance<TableViewFormValues>()
  const customForms = Form.useWatch('custom_forms', form) as TableViewFormValues['custom_forms']
  const selectedCustomForms = useMemo(() => customForms?.filter(({ checked }) => !!checked) || [], [customForms])
  const mergeProperties = Form.useWatch('merge_fields', form) as TableViewFormValues['merge_fields']
  const type = Form.useWatch('type', form)
  const setAvailableFields = useSetAtom(availableFieldsAtom)
  const setSelectedFields = useSetAtom(selectedFieldsAtom)

  const propertiesFields = useMemo(() => {
    return processPropertyFields(properties, selectedCustomForms?.map((v) => v.id) || [], mergeProperties)
  }, [selectedCustomForms, mergeProperties, properties])

  const fields = useMemo(
    () => {
      // type I = Line Item
      return [
        ...(type === 'I' ? TICKET_COLUMNS_WITH_PREFIX : TICKET_COLUMNS),
        ...(type === 'I' ? LINE_ITEM_COLUMNS : []),
        ...propertiesFields
      ]
        .map((field) => processField(field, l))
        .sort(sortFields)
    },

    // disable deps for l function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCustomForms, mergeProperties, properties, type]
  )

  useEffect(() => {
    setAvailableFields(fields)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  useEffect(() => {
    const fieldsMap = makeMap(fields, 'id')
    setSelectedFields(
      tableView?.selected_fields?.map((field) => {
        const _f = processField(field, l)
        const _fMap = fieldsMap[_f.id] || {}
        return { ..._fMap, ..._f }
      }) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableView?.selected_fields, fields])

  return (
    <div className={className}>
      <CreateYourViewSection />
      <SelectCustomFormsSection />
      <FilterYourViewSection />
      <DragAndDropFieldsSection />
    </div>
  )
}
