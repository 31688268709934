import { CustomForm } from '@/modules/custom-form/types'
import { TICKET_STATUS } from '@/modules/ticket/constants'

export const getStatusLabels = (customForm?: CustomForm) => {
  return { ...TICKET_STATUS, ...(customForm?.userdata?.custom_status || {}) }
}

export const parseLayoutStr = (layout: string) => {
  const parts = (layout || '').split(',')
  const width = parseInt(parts[0], 10) || 0
  const skip = parseInt(parts[1], 10) || 0
  return { width, skip }
}
