import { Event } from '@/types/event'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import classNames from 'classnames'

type Props = {
  event: Event
  onClick: (id: number) => void
  isCollapsed: boolean
}

export const ActivityItemTrigger = ({ event, onClick, isCollapsed }: Props) => {
  return (
    <div className="absolute right-16 top-16">
      <Button
        size={'small'}
        shape={'circle'}
        icon={
          <Icon
            name="fa:chevron-down"
            className={classNames({
              'transform rotate-180': isCollapsed
            })}
          />
        }
        onClick={() => onClick(event.id)}
      />
    </div>
  )
}
