import { z } from 'zod'

// schemas
export const CustomerSchema = z.object({
  id: z.number(),
  company_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.string(),
  email: z.string()
})

// types
export type Customer = z.infer<typeof CustomerSchema>
