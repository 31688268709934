import { useApp } from '@/hooks'
import { RuleTemplate } from '@/schemas/rule-template'
import { jasScheduleRuleApi } from '@/services/api-service'
import { JasResourceRule, JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import { AddOrEditForm } from './add-or-edit-form'
import { ListItem } from './list-item'

type Props = {
  resource: JasResourceUseQueryResponse
  ruleTemplates: RuleTemplate[]
  onMutationsSuccess: () => void
}

export const ScheduleRuleList = ({ resource, ruleTemplates, onMutationsSuccess }: Props) => {
  const [deletingScheduleRuleId, setDeletingScheduleRuleId] = useState<number | null>(null)
  const [isAddingNewRule, setIsAddingNewRule] = useState(false)
  const rulesQuery = useQuery(jasScheduleRuleApi.list<JasResourceRule>({ resource_id__eq: resource.id }))
  const deleteMutation = useMutation(jasScheduleRuleApi.delete())
  const { notification } = useApp()

  const [editingScheduleRule, setEditingScheduleRule] = useState<{
    scheduleRule: JasResourceUseQueryResponse['rules'][0]
    index: number
  } | null>(null)

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      notification.success({ message: 'Schedule rule deleted' })
    }
  }, [deleteMutation.isSuccess, notification])

  const handleOnAddNewRule = () => {
    rulesQuery.refetch()
    onMutationsSuccess()
    toggleAddNewRule()
  }

  const handleOnEditRule = () => {
    rulesQuery.refetch()
    onMutationsSuccess()
    setEditingScheduleRule(null)
  }

  const handleDeleteRule = async () => {
    await deleteMutation.mutateAsync({
      id: deletingScheduleRuleId
    })

    rulesQuery.refetch()
    setDeletingScheduleRuleId(null)
    onMutationsSuccess()
  }

  const toggleAddNewRule = () => {
    setIsAddingNewRule((prev) => !prev)
  }

  return (
    <>
      {isAddingNewRule && !editingScheduleRule ? (
        <AddOrEditForm
          number={resource.rules.length + 1 || 1}
          resource={resource}
          ruleTemplates={ruleTemplates}
          onAddNewRule={handleOnAddNewRule}
          onCancel={toggleAddNewRule}
        />
      ) : (
        <div className="flex justify-end mb-18">
          <Button icon={<Icon name="mi:add" />} type="primary" ghost size="small" onClick={toggleAddNewRule}>
            New Schedule Rule
          </Button>
        </div>
      )}
      <div>
        {rulesQuery.data?.items.length === 0 && !isAddingNewRule && (
          <div className="flex justify-center items-center">
            <p className="text-gray-400">No schedule rules found</p>
          </div>
        )}
        {rulesQuery.data?.items.map((rule, index) =>
          rule.id === editingScheduleRule?.scheduleRule.id ? (
            <AddOrEditForm
              key={rule.id}
              resource={resource}
              number={index + 1}
              rule={editingScheduleRule.scheduleRule}
              ruleTemplates={ruleTemplates}
              onCancel={() => setEditingScheduleRule(null)}
              onAddNewRule={handleOnEditRule}
            />
          ) : (
            <ListItem
              key={rule.id}
              rule={rule}
              index={index}
              onDeleteClick={() => setDeletingScheduleRuleId(rule.id)}
              onEditClick={() => setEditingScheduleRule({ scheduleRule: rule, index })}
            />
          )
        )}
      </div>
      <Modal
        title="Delete Schedule Rule"
        open={!!deletingScheduleRuleId}
        onCancel={() => setDeletingScheduleRuleId(null)}
        onOk={handleDeleteRule}
      >
        <p>Do you want to remove a schedule rule?</p>
      </Modal>
    </>
  )
}
