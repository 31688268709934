import { Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

export const LineItemsSection = () => {
  return (
    <div>
      <h4 className="font-bold">Line Items</h4>
      <Form.Item label="Item Description" name="equipment_item_description">
        <Input />
      </Form.Item>
      <Form.Item label="Item Code" name="equipment_item_code">
        <Input />
      </Form.Item>
      <Form.Item label="Item Service Date (optional)" name="equipment_item_date">
        <Input />
      </Form.Item>
      <Form.Item label="Line Query Filter (optional)" tooltip="e.g. amount__gt|0" name={['doc', 'line_query_filter']}>
        <TextArea />
      </Form.Item>
    </div>
  )
}
