import {
  isAvailableOnlyAtom,
  isResourceLoadedAtom,
  originalResourcesAtom,
  selectedJobRoleAtom,
  selectedResourcesAtom,
  selectedSkillsAtom,
  skillMatchTypeAtom
} from '@/modules/jas/job-manager/add-resource/atoms'
import { useAddResourceTypeParam, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { JobResource, JobResourceData } from '@/modules/jas/job-manager/types'
import { clientRecordApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import { startCase } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { batch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getSkuId } from './helpers'

export const useAddResource = () => {
  const [searchParams, _] = useSearchParams()
  const isFromTicket = searchParams.get('from') === 'ticket'

  const [type, setType] = useAddResourceTypeParam()
  const [isEmployee, isEquipment, typeLabel] = useMemo(
    () => [type === 'employee', type === 'equipment', startCase(type || '') as 'Equipment' | 'Employee'],
    [type]
  )

  // note: could not use isolated provider because of interdependency with parent
  // these things are super tedious ¯\_(ツ)_/¯
  const resetSkillMatchType = useResetAtom(skillMatchTypeAtom)
  const resetSelectedSkills = useResetAtom(selectedSkillsAtom)
  const resetSelectedJobRoleAtom = useResetAtom(selectedJobRoleAtom)
  const resetIsAvailableOnly = useResetAtom(isAvailableOnlyAtom)
  const resetIsResourceLoaded = useResetAtom(isResourceLoadedAtom)
  const resetsSelectedResources = useResetAtom(selectedResourcesAtom)

  const reset = useCallback(() => {
    resetSkillMatchType()
    resetSelectedSkills()
    resetSelectedJobRoleAtom()
    resetIsAvailableOnly()
    resetIsResourceLoaded()
    resetsSelectedResources()
  }, [
    resetSkillMatchType,
    resetSelectedSkills,
    resetSelectedJobRoleAtom,
    resetIsAvailableOnly,
    resetIsResourceLoaded,
    resetsSelectedResources
  ])

  const close = useCallback(() => {
    if (isFromTicket) {
      window.history.back()
    } else {
      reset()
      setType(null)
    }
  }, [isFromTicket, reset, setType])

  return {
    isEmployee,
    isEquipment,
    isFromTicket,
    type,
    typeLabel,
    setType,
    close
  }
}

export const useJobTicketSkillsQuery = () => {
  const { type } = useAddResource()
  const { selectedJobData: jobData } = useSelectedJobTicket()

  const query = useQuery(
    clientRecordApi.list<{
      id: number
      char_1: string
      char_3: string
    }>({
      fields: ['id', 'char_1', 'char_3'].join(','),
      Q: ['label__iexact|Skills', `char_2__iexact|${type}`, `char_1__in|${(jobData?.attributes || []).join(',')}`]
    })
  )

  const jobSkills = useMemo(
    () => query.data?.items.map((item) => ({ value: item.char_1, label: item.char_3 })) ?? [],
    [query.data]
  )

  // select skills on load
  const setSelectedSkillsAtom = useSetAtom(selectedSkillsAtom)
  useEffect(() => {
    setSelectedSkillsAtom(jobSkills.map((item) => item.value))
  }, [jobSkills, setSelectedSkillsAtom])

  return {
    ...query,
    jobSkills
  }
}

export const useSelectedResources = () => {
  const { type } = useAddResource()
  const { selectedJobData: jobData } = useSelectedJobTicket()

  const setOriginalResources = useSetAtom(originalResourcesAtom)
  const [selectedResources, setSelectedResources] = useAtom(selectedResourcesAtom)
  const [isResourceLoaded, setIsResourceLoaded] = useAtom(isResourceLoadedAtom)
  const selectedSkus = useMemo(() => Object.values(selectedResources).map((r) => r.assigned_sku), [selectedResources])

  // load existing resources as selected
  useEffect(() => {
    if (jobData?.resources && !isResourceLoaded) {
      let resources: JobResourceData
      if (type === 'equipment') {
        resources = jobData.resources.equipments
      } else {
        resources = jobData.resources.employees
      }

      const originalResources = resources.res_items.reduce((acc: Record<number, JobResource>, item: JobResource) => {
        acc[item.id] = item
        return acc
      }, {})

      setOriginalResources(originalResources)
      setSelectedResources(originalResources)
      setIsResourceLoaded(true)
    }
  }, [isResourceLoaded, jobData?.resources, setSelectedResources, setIsResourceLoaded, type, setOriginalResources])

  const addResource = (resource: JobResource) => {
    const _selected = { ...selectedResources }
    _selected[resource.id] = resource
    setSelectedResources(_selected)
  }

  const removeResource = (resource: JobResource) => {
    const _selected = { ...selectedResources }
    delete _selected[resource.id]
    setSelectedResources(_selected)
  }

  return {
    selectedResources,
    selectedSkus,
    addResource,
    removeResource
  }
}

export const useAddResourceCallback = () => {
  const originalResources = useAtomValue(originalResourcesAtom)
  const selectedResources = useAtomValue(selectedResourcesAtom)
  const [isChanged, addedSkuIds, removedSkuIds] = useMemo(() => {
    const originalSkuIds = Object.values(originalResources).map(getSkuId)
    const selectedSkuIds = Object.values(selectedResources).map(getSkuId)

    const added = selectedSkuIds.filter((id) => !originalSkuIds.includes(id))
    const removed = originalSkuIds.filter((id) => !selectedSkuIds.includes(id))
    const isChanged = added.length > 0 || removed.length > 0

    return [isChanged, added, removed]
  }, [originalResources, selectedResources])

  const { type } = useAddResource()
  const { addResource, removeResource } = useSelectedJobTicket()

  const handleAddResource = useCallback(() => {
    if (!type || !isChanged) return

    batch(() => {
      // clear original resources
      Object.values(originalResources).forEach((resource) => {
        removeResource(type, resource)
      })

      // add selected resources
      Object.values(selectedResources).forEach((resource) => {
        addResource(type, resource)
      })
    })
  }, [addResource, isChanged, originalResources, removeResource, selectedResources, type])

  return {
    isChanged,
    addedSkuIds,
    removedSkuIds,
    handleAddResource
  }
}
