import { JobRequirementsByRole } from '../schemas'
import { JobRequirementsByType, JobResource, JobResourceData, JobResources, JobRole, JobTicket } from '../types'
import { parseSkuIds } from './parsers'

const getEmptyData = (id: number | null, name: string): JobResourceData => ({
  id,
  name: name,

  required: 0,
  assigned: 0,
  missing_sku_ids: [],
  sku_ids: [],
  res_items: []
})

const applySku = (resData: JobResourceData, skuId: number, resource: JobResource) => {
  // check if already exists
  if (resData.sku_ids.includes(skuId)) return

  resData.sku_ids.push(skuId)
  resData.res_items.push(resource)
  resData.assigned += 1
}

const preserveSku = (resData: JobResourceData, tpMap: Record<number, string>, jobRole: JobRole) => {
  const skuIds = parseSkuIds(tpMap[jobRole.property_id])

  skuIds.forEach((skuId) => {
    // check if already exists
    if (resData.sku_ids.includes(skuId)) return

    // create shim sku and resource
    const shimSku = {
      id: skuId, // important! required during saving
      sku: 'Test',
      description: '',
      job_role_id: jobRole.id, // important! required during saving
      job_role_name: jobRole.name
    }
    const shimResource: JobResource = {
      id: skuId + 1000000,
      name: `Unknown #${skuId}`,
      short_code: '?',
      type: jobRole.resource_type,
      attributes: {},
      job_roles: [jobRole],
      skus: [shimSku],
      assigned_sku: shimSku, // important! required during saving
      isShim: true
    }

    // set to job data
    resData.sku_ids.push(shimSku.id)
    resData.res_items.push(shimResource)
    resData.assigned += 1
  })
}

export const getResourcesByType = (
  ticket: JobTicket,
  requirementsByType: JobRequirementsByType,
  jobRolesMap: Record<number, JobRole>,
  tpMap: Record<number, string>
): JobResources => {
  const resources: Record<'Employee' | 'Equipment' | string, JobResourceData> = {
    Employee: { ...getEmptyData(null, 'Employees'), required: requirementsByType.employees },
    Equipment: { ...getEmptyData(null, 'Equipments'), required: requirementsByType.equipments }
  }

  // apply skus from events
  ticket.events.forEach((event) => {
    const assignedSku = event.resource.assigned_sku
    if (!assignedSku) return

    const resData = resources[jobRolesMap[assignedSku?.job_role_id]?.resource_type]
    if (resData) applySku(resData, assignedSku.id, event.resource)
  })

  // preserve sku ids even if they miss events
  // create shim resource/events for sku ids missing events
  Object.values(jobRolesMap).forEach((jobRole) => {
    const resData = resources[jobRole.resource_type]
    if (resData) preserveSku(resData, tpMap, jobRole)
  })

  return { employees: resources.Employee, equipments: resources.Equipment }
}

export const getResourcesByRole = (
  ticket: JobTicket,
  requirementsByRole: JobRequirementsByRole,
  jobRolesMap: Record<number, JobRole>,
  tpMap: Record<number, string>
): JobResourceData[] | null => {
  if (!requirementsByRole) return null

  const resources: JobResourceData[] = []

  requirementsByRole.forEach((req) => {
    const jobRole = jobRolesMap[req.role]
    if (!jobRole) return

    const resData = { ...getEmptyData(jobRole.id, jobRole.name), required: req.required || 0 }

    // apply skus from events
    ticket.events.forEach((event) => {
      const assignedSku = event.resource.assigned_sku
      if (!assignedSku || assignedSku.job_role_id !== jobRole.id) return

      applySku(resData, assignedSku.id, event.resource)
    })

    // preserve sku ids even if they miss events
    // create shim resource/events for sku ids missing events
    preserveSku(resData, tpMap, jobRole)

    resources.push(resData)
  })

  return resources
}
