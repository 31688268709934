import { CustomForm } from '@/types/custom-form'
import { Property } from '@/types/property'
import { useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { bpaConfigAtom, bpaPropertyAtom, customFormAtom } from '../atoms'
import { getBpaConfig } from '../helpers'

export const useInitializer = () => {
  const [isInit, setIsInitialized] = useState(false)

  const setBpaProperty = useSetAtom(bpaPropertyAtom)
  const setCustomForm = useSetAtom(customFormAtom)
  const setBpaConfig = useSetAtom(bpaConfigAtom)

  const initialize = useCallback(
    (customForm: CustomForm, property: Property) => {
      setBpaProperty((prev) => (isInit && !!prev && prev.id === property.id ? prev : property))
      setCustomForm((prev) => (isInit && !!prev && prev.id === customForm.id ? prev : customForm))
      setBpaConfig((prev) => (isInit && !!prev && prev.propertyId === property.id ? prev : getBpaConfig(property)))
      setIsInitialized(true)
    },
    [setBpaProperty, setCustomForm, setBpaConfig, isInit]
  )

  return { isInit, initialize }
}
