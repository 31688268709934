import { fetchQuery } from '@/query'
import { locationApi } from '@/services/api-service'
import { apiHttp } from '@/services/api-service/core'
import { cloneDeep, get, isEmpty, pick } from 'lodash'
import type { WellData } from './types'

export const processCustomerAndLocation = (well: WellData, search: any) => {
  const name = (well.name || '').toUpperCase()

  well.displayName = name
  well.customerName = ''
  well.locationName = name // default to name

  if (!isEmpty(search)) {
    let matched = ''

    // find the longest match
    let endPart = search.length
    while (endPart >= 3) {
      const part = search.substring(0, endPart)
      if (name.includes(part)) {
        matched = part
        break
      }
      endPart--
    }
    const matchedIndex = name.indexOf(matched)

    // highlight display name
    well.displayName = name.replace(
      new RegExp(matched, 'gi'),
      '<span class="bg-primary text-white rounded-sm px-[2px] py-[1px]">$&</span>'
    )

    // parse customer name
    well.customerName = name.substring(0, matchedIndex).trim()

    // parse location name
    well.locationName = name.substring(matchedIndex, name.length).trim()
  }

  return well
}

export const processWell = async (well: WellData) => {
  const wellData: WellData & { wsnName?: string; customer?: string | null; state?: string; msg?: string } =
    cloneDeep(well)

  wellData.wsnName = wellData.name
  wellData.name = wellData.locationName || wellData.name
  wellData.customer = wellData.customerName || null

  const latLng = `${wellData.latitude},${wellData.longitude}`

  const addressRes = await apiHttp.get<any[]>('/gmap/reverse_lookup/', {
    params: { latlng: latLng, result_type: 'administrative_area_level_2' }
  })

  const address = formatAddress(addressRes.data)

  wellData.county = address.county
  wellData.state = address.state
  wellData.msg = 'OK'

  // cleanup
  return pick(wellData, [
    'id',
    'wsnName',
    'name',
    'customer',
    'apiNum',
    'county',
    'state',
    'latitude',
    'longitude',
    'layerId',
    'msg',
    'locationName'
  ])
}

export const formatAddress = (result: any[]) => {
  if (!result || !result.length) {
    throw new Error('Address not found')
  }

  const components = result[0]['address_components'].reduce((state: any, item: any) => {
    state[item['types'][0]] = item
    return state
  }, {})

  const data = {
    county: get(components, 'administrative_area_level_2.long_name'),
    state: get(components, 'administrative_area_level_1.long_name')
  }

  if (isEmpty(data.state)) {
    throw new Error('State is missing in google map response')
  }

  return data
}

export const fetchLocationIdByName = async (locationName: string) => {
  try {
    return get(await fetchQuery(locationApi.list({ name__eq: locationName, fields: 'id', limit: 1 })), 'items.0.id')
  } catch {
    return null
  }
}
