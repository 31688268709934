import { FC } from 'react'
import { useCustomForm, useTicketFlags } from '../../../hooks'
import { ReportButtons } from './report-buttons'
import { SummaryAddTicket } from './summary-add-ticket'
import { SummaryRebuild } from './summary-rebuild'
import { SummaryRemoveTicket } from './summary-remove-ticket'

export const MetaButtons: FC = () => {
  const { features } = useCustomForm()
  const { viewOnly, isSummaryTicket } = useTicketFlags()

  if (viewOnly) return null

  return (
    <div className={'[&>*]:mb-8 flex flex-row gap-6 items-center flex-wrap justify-end'}>
      {!!features.reportButtons.length && <ReportButtons />}
      {isSummaryTicket && (
        <>
          <SummaryRebuild />
          <SummaryAddTicket />
          <SummaryRemoveTicket />
        </>
      )}
    </div>
  )
}
