import { formatCurrency } from '@/utils/formatters'
import { FC } from 'react'
import { useCustomForm, useTicket } from '../../../../../hooks'
import { useItemsStats } from '../../../hooks'

export const ItemsSummary: FC = () => {
  const { ticket } = useTicket()
  const { customForm } = useCustomForm()
  const { categories, totalAmount, taxes, discounts } = useItemsStats(ticket.id)

  return (
    <table>
      <tbody className={'[&>tr>td]:px-10 text-lg font-medium'}>
        {customForm.group_line_items &&
          categories
            // Hide categories containing tax skus from the summary
            .filter((cat) => !taxes.find((tax_li) => tax_li.category === cat.id))
            .map((category) => (
              <tr key={category.id} className={'[&:first-child]:border-t border-dashed'}>
                <td className={'text-right'}>{category._label}</td>
                <td className={'text-gray-400'}>{formatCurrency(category._amount)}</td>
              </tr>
            ))}

        {discounts.map((dis_li) => (
          <tr key={dis_li.id} className={'[&:first-child]:border-t border-dashed'}>
            <td className={'text-right'}>
              {dis_li.description} ({dis_li.discount || 0})%
            </td>
            <td className={'text-gray-400'}>{formatCurrency(dis_li.amount)}</td>
          </tr>
        ))}

        {taxes.map((tax_li) => (
          <tr key={tax_li.id} className={'[&:first-child]:border-t border-dashed'}>
            <td className={'text-right'}>
              {tax_li.description} (
              {tax_li.skip_tax_calc ? `${tax_li.usage_rate || 0}${tax_li.unit}` : `${tax_li.tax_rate || 0}%`})
            </td>
            <td className={'text-gray-400'}>{formatCurrency(tax_li.amount)}</td>
          </tr>
        ))}

        <tr className={'border-y border-dashed'}>
          <td colSpan={2}></td>
        </tr>
        <tr>
          <td className={'text-right'}>Total</td>
          <td className={'text-gray-400'}>{formatCurrency(totalAmount)}</td>
        </tr>
      </tbody>
    </table>
  )
}
