import { Select } from '@/ui/select'
import { Input } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { OpenLinkButton } from './OpenLinkButton'

export const OpenLinkButtonConfig: ScriptedButtonConfig = {
  type: 'common_open_link_button',
  iconName: 'fa:external-link-alt',
  label: 'Open Link',
  group: 'Common',
  render: () => <OpenLinkButton />,
  vars: [
    {
      name: 'link',
      label: 'Link',
      render: (customForm, props) => <Input.TextArea className={'break-all'} {...props} />
    },
    {
      name: 'target',
      label: 'Open In (Target)',
      render: (customForm, props) => (
        <Select
          allowClear={true}
          options={[
            { label: 'Self Tab', value: '_self' },
            { label: 'New Tab', value: '_blank' },
            { label: 'Top Tab', value: '_top' }
          ]}
          {...props}
        />
      )
    }
  ]
}
