import { filterSelectOption } from '@/utils/filter-select-option'
import { Form, Select, Typography } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom, requiredFieldsErrorsAtom, setCategoryAtom, setSubCategoryAtom } from '../atoms'
import { useCategoriesQuery } from '../hooks'

const { Text } = Typography

export const CategorySelect = () => {
  const category = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (e) => e.category?.parent_category), []))
  const setCategory = useSetAtom(setCategoryAtom)
  const setSubCategory = useSetAtom(setSubCategoryAtom)
  const { isCategoryError } = useAtomValue(requiredFieldsErrorsAtom)
  const setRequiredFieldsErrors = useSetAtom(requiredFieldsErrorsAtom)
  const { categoriesQuery } = useCategoriesQuery()

  const options = useMemo(
    () => [
      ...(categoriesQuery.data?.items
        .filter((category) => !category.parent_category)
        .map((category) => ({
          label: category.name,
          value: category.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label, 'en')) ?? [])
    ],
    [categoriesQuery.data]
  )

  return (
    <Form.Item label="Category" className="w-full mb-0" required validateStatus={isCategoryError ? 'error' : undefined}>
      <Select
        loading={categoriesQuery.isLoading}
        style={{
          width: '100%'
        }}
        value={category?.id}
        options={options}
        showSearch
        filterOption={filterSelectOption}
        data-cy="sku-category-select"
        onChange={(value) => {
          const category = categoriesQuery.data?.items.find((category) => category.id === value)
          setCategory(category)

          // Reset sub category
          setSubCategory({
            id: 0,
            name: '',
            parent_category: category,
            company: 0
          })

          if (isCategoryError) {
            setRequiredFieldsErrors((prev) => ({
              ...prev,
              isCategoryError: false
            }))
          }
        }}
      />
      {isCategoryError && <Text type="danger">Please select category</Text>}
    </Form.Item>
  )
}
