import { useApp } from '@/hooks'
import { inventoryIndicatorApi } from '@/services/api-service'
import { InventoryIndicator } from '@/types/inventory-indicator'
import { Button } from '@/ui'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Layout } from '../../components/layout'
import { AddEditIndicatorModal } from './add-edit-indicator-modal'
import { GraphCard } from './graph-card'
import { SortableItem } from './sortable-item'

export const GraphsView = () => {
  const { notification } = useApp()
  const [inventoryIndicatorsIds, setInventoryIndicatorIds] = useState<string[]>([])
  const [activeId, setActiveId] = useState<string | null>(null)
  const inventoryIndicatorsQuery = useQuery(inventoryIndicatorApi.list<InventoryIndicator>({ order: 'sequence' }))
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const [isAddIndicatorModalOpen, setIsAddIndicatorModalOpen] = useState(false)
  const updateSequenceMutation = useMutation(inventoryIndicatorApi.patch())

  const inventoryIndicators = useMemo(() => {
    const items = inventoryIndicatorsQuery.data?.items || []
    return items.reduce(
      (acc, item) => {
        acc[String(item.id)] = item
        return acc
      },
      {} as Record<string, InventoryIndicator>
    )
  }, [inventoryIndicatorsQuery.data])

  useEffect(() => {
    setInventoryIndicatorIds(
      inventoryIndicatorsQuery.data?.items
        .sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
        .map((item) => String(item.id)) || []
    )
  }, [inventoryIndicatorsQuery.data])

  // make mutation to update sequence every time inventoryIndicatorsIds changes
  useEffect(() => {
    if (inventoryIndicatorsIds.length === 0) {
      return
    }

    const promises = inventoryIndicatorsIds.reduce((acc, id, index) => {
      const indicator = inventoryIndicators[id]

      if (indicator.sequence !== index) {
        return [...acc, updateSequenceMutation.mutateAsync({ id, sequence: index })]
      }

      return acc
    }, [] as Promise<any>[])

    const updateSequence = async () => {
      if (promises.length === 0) {
        return
      }

      await Promise.all(promises)
      notification.success({ message: 'Sequence updated' })
    }

    updateSequence()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryIndicatorsIds, inventoryIndicators])

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(String(event.active.id))
  }, [])

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (!over) {
      return
    }

    if (active.id !== over?.id) {
      setInventoryIndicatorIds((items) => {
        const oldIndex = items.findIndex((id) => id === active.id)
        const newIndex = items.findIndex((id) => id === over?.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }, [])

  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  return (
    <Layout>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext strategy={rectSortingStrategy} items={inventoryIndicatorsIds}>
          <div className="grid grid-cols-2 2xl:grid-cols-3 gap-16">
            {inventoryIndicatorsIds.map((id) => (
              <SortableItem
                key={id}
                id={id}
                inventoryIndicator={inventoryIndicators[id] ?? {}}
                onDelete={(id: number) => {
                  setInventoryIndicatorIds((items) => items.filter((item) => item !== String(id)))
                }}
              />
            ))}
            <div className="col-span-2">
              <Button iconName="fa:add" type="primary" onClick={() => setIsAddIndicatorModalOpen(true)}>
                New Indicator
              </Button>
            </div>
          </div>
        </SortableContext>
        {createPortal(
          <DragOverlay>
            {activeId ? (
              <GraphCard
                id={activeId}
                inventoryIndicator={inventoryIndicators[activeId]}
                isDragging
                style={{ transformOrigin: '0 0 ' }}
                onDelete={() => {}}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
      {isAddIndicatorModalOpen && (
        <AddEditIndicatorModal
          sequence={inventoryIndicatorsIds.length}
          onCancel={() => setIsAddIndicatorModalOpen(false)}
        />
      )}
    </Layout>
  )
}
