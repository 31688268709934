import { useApp } from '@/hooks'
import { ticketApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { Form } from 'antd'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useTicketSave } from '../../../hooks'
import { transformLineItem } from '../helpers'
import { LineItem } from '../schemas'

export type InlineItemsForm = {
  inLineItems: Record<number, Partial<LineItem>>
}

export const useInlineItemEdit = () => {
  const { notification } = useApp()
  const saveMutation = useMutation(ticketApi.batchPatchLines())
  const { saveTicket } = useTicketSave()

  const FORM_KEY = 'inLineItems'
  const form = Form.useFormInstance<InlineItemsForm>()

  const getItemFormValue = useCallback(
    (item: Partial<LineItem>) => {
      return (
        form.getFieldValue([FORM_KEY, item.id as number]) || {
          id: item.id,
          category_id: item.category_id // important! for Save All and Cancel All
        }
      )
    },
    [form]
  )

  const handleEdit = useCallback(
    (items: Partial<LineItem> | Partial<LineItem>[], fields?: string | string[], hideInlineActions = false) => {
      const _items = Array.isArray(items) ? items : [items]
      const _fields = Array.isArray(fields) ? fields : [fields]

      if (!_items.length || !_fields.length) return

      const formData: Record<number, any> = {}

      _items.forEach((i) => {
        const formValue = getItemFormValue(i)
        const transformedItem = transformLineItem(i)

        const itemValues = _fields.reduce(
          (acc, key) => {
            if (!key) return acc
            acc[key as any] = transformedItem[key as keyof typeof i]
            return acc
          },
          {} as Record<string, any>
        )

        formData[i.id as number] = { ...formValue, ...itemValues, _hide_inline_actions: hideInlineActions }
      })

      form.setFieldsValue({ [FORM_KEY]: formData })
    },
    [form, getItemFormValue]
  )

  const handleCancelEdit = useCallback(
    (item: Partial<LineItem>, key?: string) => {
      if (!item || !key) return
      const formValue = getItemFormValue(item)
      const editingCount = Object.entries(formValue).filter(
        ([k, v]) => v !== undefined && !['id', 'category_id'].includes(k)
      ).length

      if (editingCount === 1) {
        // clear all fields if canceling the last field
        form.setFieldsValue({ [FORM_KEY]: { [item.id as number]: undefined } })
      } else {
        form.setFieldsValue({ [FORM_KEY]: { [item.id as number]: { [key]: undefined } } })
      }
    },
    [form, getItemFormValue]
  )

  const isEditingField = useCallback(
    (item: Partial<LineItem>, key?: string) => {
      if (!item || !key) return

      const formValue = getItemFormValue(item)
      return get(formValue, key) !== undefined
    },
    [getItemFormValue]
  )

  const saveItems = useCallback(
    async (ticketId: number, items: Partial<LineItem>[]) => {
      try {
        await saveMutation.mutateAsync({
          id: ticketId,
          ticket: ticketId,
          items: items.map((v) => ({ ...v, category_id: undefined, _hide_inline_actions: undefined }))
        })
        // Users don't have to wait for this, right?
        saveTicket({ only: ['id'], forceSave: true })
        notification.success({
          message: 'Items Saved',
          description: 'Line items have been updated successfully'
        })
      } catch (e) {
        notification.error({
          message: 'Items Save Failed',
          description: 'Failed to save line items. Please try again.'
        })
      }
    },
    [notification, saveMutation, saveTicket]
  )

  return {
    FORM_KEY,
    form,
    isEditingField,
    handleEdit,
    handleCancelEdit,
    saveItems,
    saveMutation
  }
}
