import { useIsCompanyAdmin } from '@/modules/auth/hooks'
import { LineItem, LineItemComponent } from '@/modules/ticket/form/components/line-items/schemas'
import { useCustomForm, useTicket, useTicketCustomerOffice, useTicketSave } from '@/modules/ticket/form/hooks'
import { FC } from 'react'
import { AddEditButton } from '../../../../../add-edit-button'

type Props = { item: Partial<LineItem>; components: LineItemComponent[] }

export const AddButton: FC<Props> = ({ item, components }) => {
  const { ticket } = useTicket()
  const { features } = useCustomForm()
  const isCompanyAdmin = useIsCompanyAdmin()
  const customerOffice = useTicketCustomerOffice()
  const { saveTicket } = useTicketSave()

  // const { categories } = useItemsStats(ticket.id)
  // const [category, parentCategory] = useMemo(() => {
  //   const category = categories.find((c) => c.id === item.category_id)
  //   if (!category) return [undefined, undefined]
  //
  //   return [
  //     { label: category.name, value: category.id },
  //     { label: category.parent__name, value: category.parent__id }
  //   ]
  // }, [categories, item.category_id])

  return (
    <AddEditButton
      buttonProps={{
        size: 'small',
        iconName: 'fa:plus',
        shape: 'round',
        children: 'Add',
        className: 'bg-transparent'
      }}
      modalProps={{ iconName: 'fa:plus', title: 'Add Component' }}
      onSaved={() => saveTicket()}
      options={{
        type: 'new',
        ticketId: ticket.id,
        customerOfficeId: customerOffice?.id,
        // category: category,
        // parentCategory: parentCategory,
        disabledFields: ['sku', 'description'],
        excludedFields: features.hideItemFields?.enabled ? features.hideItemFields?.fields || [] : undefined,
        limitBackDate: !isCompanyAdmin && features.restrictLineItemEdits,
        preferEmpty: true,
        selectorQueryOverride: { is_sub_component__eq: 1 },
        lastSequenceNoOverride: components.length ? components[components.length - 1].sequence : 0,
        payloadOverride: { finished_good_li: item.id, sku_designation: 'S' }
      }}
    />
  )
}
