import { STYLE_DEFS } from '../constants'
import { CustomForm } from '../types'

export const transformObjToCustomPrintCss = (val?: CustomForm['custom_print_css']) => {
  let cssStart = '/** auto[[<<' + JSON.stringify(val?.fields || {}) + '>>*/\n'
  const cssEnd = '/** ]]end */\n\n'

  cssStart += Object.entries(val?.fields || {}).reduce((acc, [key, value]) => {
    let css = STYLE_DEFS.find((s) => s.key === key)?.css

    if (css && value) {
      css = (css.replace(/\{0\}/g, value) + '\n') as any
      acc += css
    }

    return acc
  }, '')

  return cssStart + cssEnd + (val?.otherCss ? val?.otherCss : '')
}
