import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { categoryKindApi } from '@/services/api-service'
import { Button } from '@/ui'
import { Icon } from '@/ui/icons'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { useQuery } from '@tanstack/react-query'
import { Form, Input, Space, Tooltip } from 'antd'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { memo, useCallback, useMemo, useState } from 'react'
import { DraggableItem } from '../../types'

type Props = {
  item: DraggableItem
  isDragging: boolean
  listeners?: SyntheticListenerMap
}

const Component = ({ item, isDragging, listeners }: Props) => {
  const form = Form.useFormInstance()
  const isMergeLineNames = Form.useWatch(['options', 'merge_li_names'], form) || false
  const options = Form.useWatch('options', form) || {}
  const fieldAliasesJson = options.field_aliases_json || '{}'
  const fieldAliases = JSON.parse(fieldAliasesJson)
  const isLineItemReport = Form.useWatch('is_line_item_report', form) || false
  const categoryKindFieldsQuery = useQuery(categoryKindApi.list({ fields: 'field_aliases_json', limit: 'None' }))
  const isMergeFields = Form.useWatch(['options', 'merge_fields'], form) || false
  const [isEditing, setIsEditing] = useState(false)
  const customName = fieldAliases[item.field_verbose] || ''
  const [editingValue, setEditingValue] = useState(customName || '')

  const handleSelectedFieldNameChange = useCallback(() => {
    const newFieldAliases = {
      ...fieldAliases,
      [(isMergeFields && item._field_verbose) || item.field_verbose]: editingValue
    }
    form.setFieldValue(['options', 'field_aliases_json'], JSON.stringify(newFieldAliases))

    setIsEditing(false)
  }, [editingValue, fieldAliases, form, item.field_verbose, item._field_verbose])

  const mergedLiName = useMemo(() => {
    let name = ''
    const liConfigAlias: Record<string, any> = {}

    categoryKindFieldsQuery.data?.items?.forEach((k) => {
      if (!isEmpty(k.field_aliases)) {
        Object.entries(k.field_aliases).forEach((a) => {
          liConfigAlias[a[0]] = liConfigAlias[a[0]] || []
          liConfigAlias[a[0]].push(a[1])
        })
      }
    })

    if (
      isLineItemReport &&
      isEmpty(item.path) &&
      CATEGORY_KIND_FIELDS.includes(item.field as any) &&
      !isEmpty(liConfigAlias[item.field])
    ) {
      name = [item._name || item.name].concat(liConfigAlias[item.field]).join(' / ')
    } else {
      name = item._name || item.name
    }

    return name
  }, [categoryKindFieldsQuery.data?.items, isLineItemReport, item._name, item.field, item.name, item.path])

  return (
    <div className="relative">
      <div
        key={item.id}
        className={classNames('bg-white', {
          'cursor-grabbing': isDragging,
          'cursor-grab': !isDragging
        })}
        {...listeners}
      >
        <div className="border border-border rounded font-semibold py-8 px-4">
          <div className="flex items-center gap-x-6 pr-30">
            <Icon name="mi:drag_indicator" className="cursor-grab" />
            {!isMergeLineNames ? (
              <div>
                {!(fieldAliases[item._field_verbose || ''] || fieldAliases[item.field_verbose || '']) && (
                  <Tooltip title={<div className="capitalize">{item.name || item._name}</div>}>
                    <div className="itemName">{item._name || item.name}</div>
                  </Tooltip>
                )}
                {(fieldAliases[item._field_verbose || ''] || fieldAliases[item.field_verbose || '']) && (
                  <div className="flex">
                    {/* TODO: It's really frustrating to check & use both `_field_verbose` and `field_verbose` */}
                    <div className="text-blue-500">
                      {fieldAliases[item._field_verbose || ''] || fieldAliases[item.field_verbose || '']}
                    </div>
                    <Tooltip title={<div className="capitalize">{item.name}</div>}>
                      <div className="inline-flex items-center">
                        <Icon name="mi:info" className="cursor-default text-18 ml-6" />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            ) : (
              <div className="mergedLiName">{mergedLiName}</div>
            )}
          </div>
          {isMergeFields && item._forms?.length && (
            <Tooltip title={item._forms.join('\n')}>
              <span className="ml-4 italic">({item._forms?.length} Forms)</span>
            </Tooltip>
          )}
        </div>
      </div>
      {isEditing && (
        <div className="flex h-full items-center absolute top-0 left-0 w-full">
          <Input value={editingValue} className="ml-4" onChange={(e) => setEditingValue(e.target.value)} />
          <Space className="ml-4 mr-4">
            <Button iconName="mi:check" type="text" onClick={handleSelectedFieldNameChange} />
            <Button iconName="mi:close" type="text" onClick={() => setIsEditing(false)} />
          </Space>
        </div>
      )}
      {!isEditing && !isMergeLineNames && (
        <Button
          iconName="svg:edit"
          type="text"
          className="absolute top-4 right-4"
          onClick={(e) => {
            setIsEditing(true)
          }}
        />
      )}
    </div>
  )
}

export const Item = memo(Component)
