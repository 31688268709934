import { TICKETS } from '@/modules/table-view/views/edit-table-view-view/configure-tab/constants'
import { customFormApi, propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormItemProps, Select } from 'antd'
import { useMemo } from 'react'

type Props = {
  formItemProps?: FormItemProps
}

export const EditableFieldsSelect = ({ formItemProps }: Props) => {
  const customFormsQuery = useQuery({
    ...customFormApi.list({ fields: 'id,name' })
  })

  const customFormsIds = useMemo(
    () => customFormsQuery.data?.items.map((v) => v.id) ?? [],
    [customFormsQuery.data?.items]
  )

  const propertiesQuery = useQuery({
    ...propertyApi.list({
      limit: 'None' as any,
      fields: 'id,name,property_group__custom_form__name,key',
      property_group__custom_form_id__in: customFormsIds.join(','),
      property_type__name__in: writableTypes.join(','),
      name__isnull: false
    }),
    enabled: !!customFormsIds.length
  })

  const options = useMemo(() => {
    const staticOptions = TICKETS.filter((t) => !!t.input_type).map((v) => ({
      label: v.name,
      value: `${v.path}${v.field}`
    }))

    const properties = propertiesQuery.data?.items ?? []

    return [
      ...staticOptions,
      ...properties.map((p) => ({
        label: `${p.property_group__custom_form__name} / ${p.id}: ${p.name}`,
        value: `ticket__property__k=${p.key},pid=${p.id},ticket__property__${p.id}` // Note: p.id is used as a fallback value to support stale data
      }))
    ]
  }, [propertiesQuery.data?.items])

  const isLoading =
    propertiesQuery.isInitialLoading ||
    propertiesQuery.isFetching ||
    customFormsQuery.isFetching ||
    customFormsQuery.isInitialLoading

  return (
    <Form.Item label="Editable Fields" name={['data', 'editable_fields']} {...formItemProps}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        options={options}
        mode="multiple"
        optionFilterProp="label"
        disabled={isLoading}
        loading={isLoading}
      />
    </Form.Item>
  )
}

const writableTypes = [
  'Date',
  'DateTime',
  'Decimal',
  'Dropdown',
  'Email',
  'Integer',
  'MultilineString',
  'MultivariateDropdown',
  'PhoneNumber',
  'String',
  'Time'
]
