import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { customFormSchema } from '../schema'
import { CustomForm } from '../types'

export const useCustomFormQuery = () => {
  const { id } = useParams<{ id: string }>()

  const customFormQuery = useQuery({
    ...customFormApi.get<CustomForm>(
      Number(id),
      {},
      {
        intent_to_edit: 1,
        show_all: true
      }
    ),
    select: (data) => customFormSchema.parse(data),
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  return {
    customFormQuery: {
      ...customFormQuery,
      // This is to fix query status being stuck in 'loading' enabled is set to false
      isLoading: customFormQuery.fetchStatus !== 'idle' && customFormQuery.isLoading
    }
  }
}
