import { useModalState } from '@/hooks'
import { backgroundJobApi } from '@/services/api-service'
import { Button, Modal } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Collapse, Divider } from 'antd'
import { FC } from 'react'
import { BackgroundJobStateDetail } from './types'

type JobResultModalProps = {
  jobId: number
}

export const JobResultModal: FC<JobResultModalProps> = ({ jobId }) => {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()
  const jobDetailFetch = useQuery(backgroundJobApi.get<BackgroundJobStateDetail>(jobId))

  const errors = (jobDetailFetch.data?.result?.errors || []).map((errorString, idx) => <p key={idx}>{errorString}</p>)

  const items = [
    {
      key: 'items',
      label: 'Handled Items',
      children: <ItemDetails items={jobDetailFetch.data?.result?.items || []} />
    },
    {
      key: 'errors',
      label: 'Errors',
      children: errors.length ? errors : 'No Errors'
    },
    {
      key: 'metadata',
      label: 'Metadata',
      children: (
        <div className="p-12 border border-border rounded bg-background-accent">
          <code className="whitespace-pre-wrap">{JSON.stringify(jobDetailFetch.data?.metadata, null, 2)}</code>
        </div>
      )
    }
  ]

  return (
    <>
      <Button
        shape={'round'}
        size="small"
        onClick={openModal}
        onFocus={renderModal}
        onMouseEnter={renderModal}
        className={'h-[18px] text-sm'}
      >
        See Results
      </Button>
      {isRender && (
        <Modal
          open={isOpen}
          onCancel={closeModal}
          onOk={closeModal}
          title={jobDetailFetch.data?.name}
          footer={null}
          withScreenMaxHeight
        >
          <Collapse size="small" items={items} defaultActiveKey={['items']} />
        </Modal>
      )}
    </>
  )
}

const ItemDetails: FC<{ items: BackgroundJobStateDetail['result']['items'] }> = ({ items }) => {
  return (
    <ol className="max-h-[400px] pr-8 overflow-y-auto">
      {items?.map((item, idx) => (
        <li key={item.name}>
          <a href={item.link}>{item.name}</a>
          {item.errors?.map((e, idx) => (
            <p key={idx} className="color-red-200">
              {e}
            </p>
          ))}
          <Divider />
        </li>
      ))}
    </ol>
  )
}
