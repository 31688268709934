import { z } from 'zod'
import { BpaActionSchema } from './actions'
import { BpaConditionSchema } from './conditions'

export const BpaRuleSchema = z.object({
  uuid: z.string(),
  label: z.string(),
  expanded: z.boolean(),
  conditions: z.array(BpaConditionSchema),
  actions: z.array(BpaActionSchema)
})

export type BpaRule = z.infer<typeof BpaRuleSchema>
