import { useApp, useSession } from '@/hooks'
import { contactApi, ticketApi } from '@/services/api-service'
import { Button, Popconfirm, QuerySelect } from '@/ui'
import { mediaUrl } from '@/utils/urls'
import { useMutation } from '@tanstack/react-query'
import { isEmpty, uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { refreshQueries } from '../../../helpers'
import { useTicket, useTicketFlags } from '../../../hooks'
import { Contact, ContactSchema, TicketContact } from '../../../schemas'
import { Card } from '../../ui'

const formatOption = (item: Contact) => ({
  value: item.id,
  label: (
    <span>
      {item.first_name} {item.last_name} <span className={'font-medium'}>({item.profile__user__username})</span>
    </span>
  )
})

const searchBy = (text?: string) => ({
  'Q[]': isEmpty(text)
    ? {}
    : [
        `first_name__icontains|${text}`,
        `last_name__icontains|${text}`,
        'or',
        `profile__user__username__icontains|${text}`,
        'or'
      ]
})

export const TabTeamMembers = () => {
  const { notification } = useApp()
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()
  const { company } = useSession()

  const [showSelect, setShowSelect] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const contactIds = useMemo(() => ticket.contacts.map((i) => i.contact__id) || [], [ticket.contacts])

  const mutation = useMutation(ticketApi.setContacts())

  const close = useCallback(() => {
    setShowSelect(false)
    setSelectedIds([])
  }, [])

  const addContacts = useCallback(async () => {
    close()

    if (selectedIds.length === 0) return

    try {
      await mutation.mutateAsync({ id: ticket.id, contact_ids: uniq([...contactIds, ...selectedIds]) })
      await refreshQueries()
      notification.success({
        message: 'Team Members Added',
        description: 'Team members added successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Failed to add team member',
        description: 'An error occurred while adding team member'
      })
    }
  }, [close, contactIds, mutation, notification, selectedIds, ticket.id])

  const removeContact = useCallback(
    async (link: TicketContact) => {
      try {
        await mutation.mutateAsync({ id: ticket.id, contact_ids: contactIds.filter((i) => i !== link.contact__id) })
        await refreshQueries()

        notification.success({
          message: 'Team Member Removed',
          description: (
            <span>
              <b>{link.contact__first_name}</b> removed from the team
            </span>
          )
        })
      } catch (error) {
        notification.error({
          message: 'Failed to remove team member',
          description: 'An error occurred while removing team member'
        })
      }
    },
    [contactIds, mutation, notification, ticket.id]
  )

  return (
    <div className={'relative flex flex-col gap-10'}>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-16">
        {ticket.contacts.map((link) => (
          <Card
            key={link.id}
            src={mediaUrl(link.contact__image)}
            icon={'fa:user-alt'}
            shape={'circle'}
            title={`${link.contact__first_name} ${link.contact__last_name}`}
            description={link.contact__title || 'User'}
            actions={
              <Popconfirm
                title={`Removing ${link.contact__first_name} ${link.contact__last_name}`}
                description={'Are you sure?'}
                onConfirm={() => removeContact(link)}
              >
                <Button
                  size={'small'}
                  shape={'circle'}
                  type={'text'}
                  iconName={'fa:close'}
                  danger
                  disabled={viewOnly}
                />
              </Popconfirm>
            }
          />
        ))}
      </div>

      <div className={'flex flex-row items-center w-[300px] h-full'}>
        {showSelect ? (
          <div className={'flex flex-row gap-2 items-center w-full'}>
            <QuerySelect
              mode={'multiple'}
              placeholder={'Search'}
              className={'w-full h-full'}
              apiEndpoint={contactApi.list}
              apiQueryParams={{
                fields: ContactSchema,
                company_id__eq: company.id,
                profile__user__is_active: 1,
                E: contactIds.length ? [`id__in|${contactIds?.join(',')}`] : [],
                order: 'first_name'
              }}
              apiSearchBy={searchBy}
              renderOption={formatOption}
              onChange={setSelectedIds}
            />
            <Button size={'small'} shape={'circle'} iconName={'fa:check'} onClick={addContacts} type={'primary'} />
            <Button size={'small'} shape={'circle'} iconName={'fa:close'} onClick={close} />
          </div>
        ) : (
          <Button
            shape={'round'}
            iconName={'fa:user-plus'}
            onClick={() => setShowSelect(true)}
            primary
            disabled={viewOnly}
          >
            Add Member
          </Button>
        )}
      </div>
    </div>
  )
}
