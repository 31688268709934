import { transformationApi, transformationTaskApi } from '@/services/api-service'
import { TransformationTask } from '@/types/transformation-task'
import { useMutation } from '@tanstack/react-query'
import { Spin } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { useCallback, useMemo } from 'react'
import { currentEditingTransformTaskAtom, isAddTransformationTaskMutationLoadingAtom } from '../../atoms'
import { useProgrammedReportQuery } from '../../hooks'
import { Card } from '../card'
import { TransformationTasksSortable } from './transformation-tasks-sortable'

export const TransformationTasksCard = () => {
  const isAddTransformationTaskMutationLoading = useAtomValue(isAddTransformationTaskMutationLoadingAtom)
  const updateTransformationOrderMutation = useMutation(transformationApi.patch())
  const deleteTransformTaskMutation = useMutation(transformationTaskApi.delete())
  const [currentEditingTransformTask, setCurrentEditingTransformTask] = useAtom(currentEditingTransformTaskAtom)
  const { programmedReport, programmedReportQuery } = useProgrammedReportQuery()

  const handleUpdateTransformationOrder = async (tasks: TransformationTask[]) => {
    await updateTransformationOrderMutation.mutateAsync({
      id: programmedReport.transformation?.id,
      order: tasks.map((task) => task.id)
    })
  }

  const handleDeleteTransformTask = useCallback(
    async (id: number) => {
      await deleteTransformTaskMutation.mutateAsync({ id })

      if (currentEditingTransformTask?.id === id) {
        setCurrentEditingTransformTask(null)
      }

      await updateTransformationOrderMutation.mutateAsync({
        id: programmedReport.transformation?.id,
        order: programmedReport.transformation?.order?.filter((v) => v !== id) || []
      })

      await programmedReportQuery.refetch()
    },
    [
      deleteTransformTaskMutation,
      programmedReportQuery,
      programmedReport.transformation?.id,
      programmedReport.transformation?.order,
      updateTransformationOrderMutation,
      currentEditingTransformTask?.id
    ]
  )

  const sortedItems = useMemo(() => {
    const orderMap = new Map(programmedReport.transformation?.order?.map((id, index) => [id, index]))

    return (
      [...(programmedReport.transformation?.tasks || [])].sort(
        (a, b) => (orderMap.get(a.id) ?? Number.MAX_SAFE_INTEGER) - (orderMap.get(b.id) ?? Number.MAX_SAFE_INTEGER)
      ) || []
    )
  }, [programmedReport.transformation?.order, programmedReport.transformation?.tasks])

  return (
    <Card
      title={
        <div className="flex justify-between">
          <span>Steps</span>
          <span>Actions</span>
        </div>
      }
    >
      <Spin spinning={isAddTransformationTaskMutationLoading || updateTransformationOrderMutation.isLoading}>
        <TransformationTasksSortable
          key={sortedItems.map((v) => v.id + JSON.stringify(v.params || {})).join(',')} // force re-render on params or length change
          initialItems={sortedItems}
          onSortingChange={handleUpdateTransformationOrder}
          onItemDeleteClick={handleDeleteTransformTask}
          onItemEditClick={(transformationTask) => setCurrentEditingTransformTask(transformationTask)}
        />
      </Spin>
    </Card>
  )
}
