import { EntityColumn } from '@/types/entity-column'
import { range } from 'lodash'

const CAT_ACCT = 'account' as const
const CAT_TICKET = 'ticket' as const

// Note: v1 adds 'ticket__' to path after constant declaration. So I added during declaration.

/**
 * List of ticket columns
 *
 * @type {EntityColumn[]}
 */
export const TICKET_COLUMNS: EntityColumn[] = [
  {
    path: '',
    path_verbose: '',
    field: 'uuid',
    name: 'ID',
    field_verbose: 'uuid',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: 'parent__',
    path_verbose: 'parent',
    field: 'id',
    name: 'Parent ID',
    field_verbose: 'parent__id',
    field_type: 'number',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: 'parent__',
    path_verbose: 'parent',
    field: 'name',
    name: 'Parent Name',
    field_verbose: 'parent__name',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null,
    query_params: ['parent__id', 'parent__name']
  },
  {
    path: 'custom_form__',
    path_verbose: 'custom_form',
    field: 'id',
    name: 'Type ID',
    field_verbose: 'custom_form__id',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: 'custom_form__',
    path_verbose: 'custom_form_id',
    field: 'name',
    name: 'Type',
    field_verbose: 'custom_form__name',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: '',
    path_verbose: '',
    field: 'name',
    name: 'name',
    field_verbose: 'name',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: '',
    path_verbose: '',
    field: 'computed_status',
    name: 'Status',
    field_verbose: 'computed_status',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: 'dropdown',
    source: [],
    api_field: 'status'
  },
  {
    path: '',
    path_verbose: '',
    field: 'custom_stage',
    name: 'Custom Stage',
    field_verbose: '_custom_stage',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: null,
    source: null,
    api_field: 'status',
    query_params: ['computed_status']
  },
  {
    path: '',
    path_verbose: '',
    field: 'accounting_name',
    name: 'Customer Accounting Name',
    field_verbose: '_accounting_name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: null,
    source: null,
    api_field: null,
    query_params: ['customer__accounting_name']
  },
  {
    path: '',
    path_verbose: '',
    field: 'team_members',
    name: 'Team Members',
    field_verbose: '_team_members',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: null,
    source: null,
    api_field: null,
    query_params: ['contacts__contact__first_name', 'contacts__contact__last_name']
  },
  {
    path: 'office__company__',
    path_verbose: 'office',
    field: 'name',
    name: 'Company',
    field_verbose: 'office_company_name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: 'customer_office__',
    path_verbose: 'office',
    field: 'name',
    name: 'Customer Office',
    field_verbose: 'customer_company_name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Office',
    api_field: 'customer_office'
  },
  {
    path: 'customer__',
    path_verbose: 'contact',
    field: 'name',
    name: 'Customer Name',
    field_verbose: 'customer__name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Contact',
    api_field: 'customer',
    query_params: ['customer__first_name', 'customer__last_name']
  },
  {
    path: 'customer__',
    path_verbose: 'contact',
    field: 'first_name',
    name: 'Customer First Name',
    field_verbose: 'first_name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Contact',
    api_field: 'customer'
  },
  {
    path: 'customer__',
    path_verbose: 'contact',
    field: 'last_name',
    name: 'Customer Last Name',
    field_verbose: 'last_name',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Contact',
    api_field: 'customer'
  },
  {
    path: 'customer__',
    path_verbose: 'contact',
    field: 'phone_number',
    name: 'Customer Phone Number',
    field_verbose: 'customer_phone',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Contact',
    api_field: 'customer'
  },
  {
    path: 'support_office__',
    path_verbose: 'office',
    field: 'name',
    name: 'Support Office',
    field_verbose: 'support_office',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'Office',
    api_field: 'support_office'
  },
  {
    path: '',
    path_verbose: '',
    field: 'timepoint_due',
    name: 'Start Date',
    field_verbose: 'timepoint_due',
    field_type: 'date',
    category: CAT_TICKET,
    input_type: 'date',
    source: null,
    api_field: null
  },
  {
    path: '',
    path_verbose: '',
    field: 'timepoint_submitted',
    name: 'Date Submitted',
    field_verbose: 'timepoint_submitted',
    field_type: 'date',
    category: CAT_TICKET,
    input_type: 'date',
    source: null,
    api_field: null
  },
  {
    path: 'location__',
    path_verbose: 'location',
    field: 'name',
    name: 'Location',
    field_verbose: 'location',
    field_type: 'text',
    category: CAT_TICKET,
    input_type: 'query',
    source: 'Location',
    api_field: 'location'
  },
  {
    path: 'afe__',
    path_verbose: 'afe',
    field: 'user_afe_no',
    name: 'AFE Number',
    field_verbose: 'user_afe_no',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'AFE',
    api_field: 'afe'
  },
  {
    path: 'job_code__',
    path_verbose: 'jobcode',
    field: 'user_job_code_no',
    name: 'Job Code Number',
    field_verbose: 'user_job_code_no',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: 'query',
    source: 'JobCode',
    api_field: 'job_code'
  },
  {
    path: '',
    path_verbose: '',
    field: 'total',
    name: 'Total',
    field_verbose: 'total',
    field_type: 'number',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: '',
    path_verbose: '',
    field: 'created_at',
    name: 'Created',
    field_verbose: 'created_at',
    field_type: 'date',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: '',
    path_verbose: '',
    field: 'modified_at',
    name: 'Modified',
    field_verbose: 'modified_at',
    field_type: 'date',
    category: CAT_TICKET,
    input_type: null,
    source: null,
    api_field: null
  },
  {
    path: 'contacts__contact__profile__user__',
    path_verbose: 'member_user',
    field: 'username',
    name: 'Team Member Username',
    field_verbose: 'team_username',
    field_type: 'text',
    category: CAT_ACCT,
    input_type: '',
    source: '',
    api_field: '',
    query_params: ['contacts__contact__profile__user__username']
  }
].map((c) => ({ ...c, key: c.path + c.field }))

/**
 * List of ticket hidden referencs, used only to filter ticket views
 *
 * @type {EntityColumn[]}
 */
export const TICKET_HIDDEN_REFERENCES: EntityColumn[] = [
  ...range(1, 5).map((i) => ({
    path: 'user_data__',
    path_verbose: `user_int_${i}`,
    field: `user_int_${i}`,
    name: `Ticket Integer ${i}`,
    field_verbose: `user_data__user_int_${i}`,
    field_type: 'number',
    category: CAT_TICKET,
    input_type: '',
    source: '',
    api_field: ''
  })),
  ...range(1, 5).map((i) => ({
    path: 'user_data__',
    path_verbose: `user_decimal_${i}`,
    field: `user_decimal_${i}`,
    name: `Ticket Decimal ${i}`,
    field_verbose: `user_data__user_decimal_${i}`,
    field_type: 'number',
    category: CAT_TICKET,
    input_type: '',
    source: '',
    api_field: ''
  })),
  ...range(1, 5).map((i) => ({
    path: 'user_data__',
    path_verbose: `user_text_${i}`,
    field: `user_text_${i}`,
    name: `Ticket Text ${i}`,
    field_verbose: `user_data__user_text_${i}`,
    field_type: 'text',
    category: CAT_TICKET,
    input_type: '',
    source: '',
    api_field: ''
  })),
  ...range(1, 5).map((i) => ({
    path: 'user_data__',
    path_verbose: `user_datetime_${i}`,
    field: `user_datetime_${i}`,
    name: `Ticket Date ${i}`,
    field_verbose: `user_data__user_datetime_${i}`,
    field_type: 'date',
    category: CAT_TICKET,
    input_type: '',
    source: '',
    api_field: ''
  }))
].map((c) => ({ ...c, key: c.path + c.field }))

/**
 * Map of ticket columns by key (path + field)
 * @type {Record<string, EntityColumn>}
 */
export const TICKET_COLUMNS_MAP: Record<string, EntityColumn> = TICKET_COLUMNS.reduce(
  (acc, c) => {
    acc[c.key] = c
    return acc
  },
  {} as Record<string, EntityColumn>
)

/**
 * Ticket columns with prefix 'ticket__' to their paths except computed_status handled differently
 */
export const TICKET_COLUMNS_WITH_PREFIX = TICKET_COLUMNS.map((c) => {
  const copy = { ...c }
  copy.path = 'ticket__' + (copy.path || '')
  copy.path_verbose = 'ticket' + (copy.path_verbose || '')

  if (copy.field === 'computed_status') {
    copy.path = ''
    copy.path_verbose = ''
    copy.field = 'ticket_computed_status'
    copy.name = 'Ticket Status'
  } else if (['custom_stage', 'accounting_name', 'team_members'].includes(copy.field)) {
    copy.path = ''
    copy.path_verbose = ''
  }

  // todo: web app relies very heavily on `field_verbose`, where `path+field` should have been used instead.
  // until this is resolved, differentiate only some conflicting fields (`uuid`, `created_at`, `modified_at`)
  // by prepending the prefix to only this, column.
  if (['uuid', 'created_at', 'modified_at'].includes(copy.field)) {
    copy.field_verbose = 'ticket__' + copy.field
    copy.name = 'Ticket ' + copy.name
  }

  copy.key = copy.path + copy.field
  return copy
}) as EntityColumn[]

export const TICKET_COLUMNS_WITH_PREFIX_MAP: Record<string, EntityColumn> = TICKET_COLUMNS_WITH_PREFIX.reduce(
  (acc, c) => {
    acc[c.key] = c
    return acc
  },
  {} as Record<string, EntityColumn>
)

export const TICKET_FILTER_COLUMNS = [...TICKET_COLUMNS, ...TICKET_HIDDEN_REFERENCES]
