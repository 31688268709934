import { INVENTORY_PATHS } from './constants'
import { AlertsView } from './views/alerts-view'
import { GraphsView } from './views/graphs-view'
import { InventoryView } from './views/inventory-view'
import { LedgerView } from './views/ledger-view'

export const INVENTORY_ROUTES = [
  {
    path: INVENTORY_PATHS.inventory,
    element: <InventoryView />
  },
  {
    path: INVENTORY_PATHS.graphs,
    element: <GraphsView />
  },
  {
    path: INVENTORY_PATHS.ledger,
    element: <LedgerView />
  },
  {
    path: INVENTORY_PATHS.alerts,
    element: <AlertsView />
  }
]
