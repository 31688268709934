import { customFormApi } from '@/services/api-service'
import { CustomForm } from '@/types/custom-form'
import { useQuery } from '@tanstack/react-query'

export const useCustomForms = () => {
  return useQuery({
    ...customFormApi.list<CustomForm>({ limit: 'None', page: 1, show_all: 'True' }),
    select: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...item,
        id: item.id,
        name: item.name,
        userdata: item.userdata
      }))
    })
  })
}
