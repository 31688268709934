export const ACCOUNT_SETTINGS_PATHS = {
  companyInformation: '/account_settings/company_information',
  ticketViews: '/account_settings/ticket_views',
  accountingName: '/account_settings/accounting_name',
  manageRoles: '/account_settings/manage_roles',
  settings: '/account_settings/settings', // is not used??
  companyLocales: '/account_settings/locales',
  advancedSettings: '/account_settings/advanced_settings',
  editTicketView: (id: string | number) => `/account_settings/ticket_views/${id}/edit`,
  configureTicketView: (id: string | number) => `/account_settings/ticket_views/${id}/edit/configure`,
  editTicketViewAdvanced: (id: string | number) => `/account_settings/ticket_views/${id}/edit/advanced`,
  ticketViewTemplates: (id: string | number) => `/account_settings/ticket_views/${id}/edit/templates`
} as const
