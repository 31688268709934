import { Checkbox as AntCheckbox, CheckboxProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import styles from './Checkbox.module.scss'

type Props = CheckboxProps & {
  size?: 'small' | 'middle' | 'large'
}

export const Checkbox: FC<Props> = ({ size, ...restProps }) => {
  return (
    <AntCheckbox
      className={cn({
        [styles.checkboxSmall]: size === 'small',
        [styles.checkboxMiddle]: size === 'middle',
        [styles.checkboxLarge]: size === 'large'
      })}
      {...restProps}
    />
  )
}
