import { useApp } from '@/hooks'
import { view } from '@/routing'
import { navigate } from '@/routing/helpers'
import { authApi, openIdAuthAPI } from '@/services/api-service'
import { clearV1AppCash, setAccessToken } from '@/services/auth-service'
import { LinkButton } from '@/ui'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Divider, Form, Input } from 'antd'
import { flatten } from 'lodash'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const LoginView = view(Component, { title: () => 'Login' })

function Component() {
  const { notification } = useApp()
  const [searchParams] = useSearchParams()
  const [openIdInProgress, setOpenIdInProgress] = useState(false)
  const redirectTo = searchParams.get('to') || '/v2'
  const { mutate: login, isLoading: signInLoading } = useMutation(authApi.login())
  const initOpenIdAuth = useMutation({ ...openIdAuthAPI.initSignIn(), retry: 0 })

  const onFinish = (values: any) => {
    login(values, {
      onError: (error: any) => {
        const errors = flatten(Object.values(error?.response?.data || {}) || []) as string[]

        notification.error({
          message: 'Login Failed',
          description: errors.length
            ? errors.map((e, idx) => <p key={`${idx}-${e}`}>{e}</p>)
            : `${error?.message || 'Unknown Error'}. Please check your network connection or contact support.`
        })
      },
      onSuccess: ({ token }) => {
        clearV1AppCash()
        setAccessToken(`JWT ${token}`)

        // avoid unnecessary re-bootstrap complexity
        // by reloading the page after login
        navigate(redirectTo, { reload: true })
      }
    })
  }

  const onInitOpenIdAuth = (idp: string) => {
    setOpenIdInProgress(true)
    initOpenIdAuth
      .mutateAsync({ idp, next_link: redirectTo })
      .then((response) => {
        const authUri = response['auth_uri']

        // 1. open in the same tab
        window.location.replace(authUri)

        // 2. retry every second
        const intervalId = setInterval(() => {
          window.location.replace(authUri)
        }, 1000)

        // 3. last resorts after 10 seconds - open in new tab
        setTimeout(() => {
          if (intervalId) clearInterval(intervalId)

          window.addEventListener('focus', () => {
            window.location.reload()
          })

          window.open(authUri, '_blank') // new tab
        }, 10000)
      })
      .catch((reason) => {
        notification.error({ message: `Failed to init Microsoft Login: ${reason}` })
        setOpenIdInProgress(false)
      })
  }

  const disableActions = signInLoading || openIdInProgress

  return (
    <div>
      <Form layout="vertical" className="p-0 px-16" initialValues={{ remember: true }} onFinish={onFinish}>
        <div className={'pb-10'}>
          <div className="font-bold text-xl">Login</div>
          <div>Enter your login credentials.</div>
        </div>

        <div>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter your username' }]}
          >
            <Input autoFocus prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter you password' }]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>
        </div>

        <div className={'flex flex-col gap-10'}>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            disabled={disableActions}
            loading={signInLoading}
            data-cy="submit-login"
          >
            Login
          </Button>

          <div className="flex flex-wrap justify-between">
            <LinkButton className={'p-0'} type={'link'} to="/auth/forgot-password" data-cy="goto-forgot-password">
              Forgot Password?
            </LinkButton>
            <LinkButton
              className={'p-0'}
              type={'link'}
              to="https://oilcommand.com/contact/"
              target="_blank"
              data-cy="goto-sign-up"
            >
              Sign Up
            </LinkButton>
          </div>
        </div>

        <div className="flex flex-wrap justify-between">
          <Divider className={'!text-base !font-normal'}>Or</Divider>
        </div>

        <div className={'flex flex-col gap-6'}>
          <Button block disabled={disableActions} loading={openIdInProgress} onClick={() => onInitOpenIdAuth('ms')}>
            <div className={'flex flex-row gap-10 items-center justify-center'}>
              <img width={18} height={18} src="/assets/images/logos_microsoft-icon.png" alt={'microsoft-logo'} />
              <span>Continue with Microsoft</span>
            </div>
          </Button>
        </div>
      </Form>
    </div>
  )
}
