import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { useCustomFormQuery } from '@/modules/jas/job-manager/hooks'
import { Select } from 'antd'
import React, { useMemo } from 'react'

type Props = {
  value: string
  onChange?: (value: string) => void
}

export const StatusSelect: React.FC<Props> = ({ value, onChange }) => {
  const { data: customForm } = useCustomFormQuery()
  const { getStatusOptions } = useCustomFormsStatus()

  const formStatuses = useMemo(
    () => [{ label: 'All', value: '' }, ...getStatusOptions(customForm?.id)],
    [customForm?.id, getStatusOptions]
  )

  const onStatusChange = (statuses: string[]) => {
    // when 'All' is selected, remove all other statuses
    // when other statuses are selected, remove 'All'
    const allIndex = statuses.findIndex((v) => v === '')
    if (allIndex === 0 && statuses.length > 1) {
      statuses = statuses.filter((v) => v.length)
    } else if (allIndex > 0) {
      statuses = ['']
    }

    if (onChange) {
      onChange(statuses.join(','))
    }
  }

  return (
    <Select
      mode="multiple"
      className="w-full text-normal"
      placeholder="Status"
      value={(value || '').split(',') as string[]}
      onChange={onStatusChange}
      optionLabelProp="label"
      options={formStatuses}
      defaultActiveFirstOption={true}
    />
  )
}
