import { Form, Input, Spin } from 'antd'
import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom } from '../atoms'
import { DescriptionField } from '../description-field'
import { useCategoryKindQuery } from '../hooks'
import { SkuField } from '../sku-field'
import { allFieldsMap } from '../utils'
import { SkuDefault } from './sku-default'
import { SkuPropertiesSelect } from './sku-properties-select'

export const SkuProperties = () => {
  const setEquipment = useSetAtom(equipmentAtom)
  const subCategory = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.category), []))
  const { fields, categoryKindQuery } = useCategoryKindQuery(subCategory?.kind)
  const summarySku = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.summary_sku), []))

  const lineItemFormat = useAtomValue(
    useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.line_item_format), [])
  )

  const group = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.group), []))

  // Filter out start_datetime and end_datetime fields
  const filteredFields = useMemo(
    () => fields.filter((field) => field !== 'start_datetime' && field !== 'end_datetime'),
    [fields]
  )

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-30">
        <SkuPropertiesSelect />
        <SkuDefault />
      </div>
      {!group ? (
        <div className="grid grid-cols-2 gap-x-30">
          <SkuField />
          <DescriptionField />
        </div>
      ) : null}
      {!group ? (
        <>
          <Spin spinning={categoryKindQuery.isLoading}>
            <div
              className={classNames('grid grid-cols-2 gap-x-30', {
                'min-h-[80px]': categoryKindQuery.isLoading
              })}
            >
              {/* Other dynamic fields */}
              {filteredFields.map((field) => {
                const component = allFieldsMap[field as keyof typeof allFieldsMap]
                return component && <div key={field}>{component}</div>
              })}
            </div>
          </Spin>
          {summarySku && (
            <Form.Item label="Line Item Format">
              <Input
                value={lineItemFormat ?? ''}
                data-cy="sku-line-item-format-input"
                onChange={(e) =>
                  setEquipment((prev) => ({
                    ...prev,
                    line_item_format: e.target.value
                  }))
                }
              />
            </Form.Item>
          )}
        </>
      ) : null}
    </div>
  )
}
