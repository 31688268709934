import { Icon, Select, Tooltip } from '@/ui'
import { Alert, Checkbox, Form, Input, InputNumber, Space, Switch } from 'antd'
import cn from 'classnames'
import { useAtom } from 'jotai'
import { sortBy } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { propertiesKeysAtom } from '../../../atoms'
import { useCustomFormQuery } from '../../../hooks'
import { checkKeyHasDuplicates } from '../../helpers'
import { usePropertyTypesQuery } from '../../hooks'

type Props = {
  forBpa?: boolean
}

export const DefaultFields: FC<Props> = ({ forBpa }) => {
  const { customFormQuery } = useCustomFormQuery()
  const { typeOptions, typeQuery, getTypeNameById } = usePropertyTypesQuery()
  const form = Form.useFormInstance()
  const [isCustomKey, setIsCustomKey] = useState(false)
  const idFieldWatch = Form.useWatch('id', form)
  const propertyGroupWatch = Form.useWatch('property_group', form)
  const webBindingWatch = Form.useWatch('web_binding', form)
  const scriptWatch = Form.useWatch('script', form)
  const propertyTypeIdWatch = Form.useWatch(['property_type', 'id'], form)
  const propertyKeyWatch = Form.useWatch('key')
  const propertyName = getTypeNameById(propertyTypeIdWatch)
  const [propertiesKeys, setPropertiesKeys] = useAtom(propertiesKeysAtom)
  const propertyKeyAlreadyExists = useMemo(
    () => checkKeyHasDuplicates(idFieldWatch, propertyKeyWatch, propertiesKeys),
    [idFieldWatch, propertiesKeys, propertyKeyWatch]
  )
  const sectionOptions = useMemo(
    () =>
      sortBy(customFormQuery.data?.property_groups || [], 'sequence').map((group: any) => ({
        label: `${group.sequence}: ${group.name}`,
        value: group.id,
        ticket_private: group.ticket_private
      })),
    [customFormQuery.data]
  )
  const section = sectionOptions.find((option) => option.value === propertyGroupWatch)

  // change key based on name
  useEffect(() => {
    if (form.getFieldValue('key') !== form.getFieldValue('name')) {
      setIsCustomKey(true)
    }
  }, [form])

  const setPropertiesKeysDebounced = useDebouncedCallback(
    () => setPropertiesKeys((prev) => ({ ...prev, [idFieldWatch]: propertyKeyWatch })),
    250
  )

  useEffect(() => {
    if (idFieldWatch !== undefined && propertyKeyWatch !== undefined) {
      setPropertiesKeysDebounced()
    }
  }, [idFieldWatch, propertyKeyWatch, setPropertiesKeysDebounced])

  return (
    <>
      {forBpa && (
        <div>
          <h5>
            <Icon name={'fa:distribute-spacing-vertical'} className={'mr-4'} /> Property Settings
          </h5>
        </div>
      )}
      <div className={forBpa ? 'rounded border p-10' : ''}>
        <Form.Item name="id" noStyle>
          <Input hidden />
        </Form.Item>
        <Form.Item label="Section" name="property_group">
          <Select data-cy="property-group-select" options={sectionOptions} />
        </Form.Item>
        <div className={cn('grid gap-x-12', forBpa ? 'grid-cols-1' : 'grid-cols-2')}>
          <Form.Item label="Property Name" name="name" tooltip="Label; friendly display name">
            <Input
              data-cy="property-name-input"
              onChange={(e) => !idFieldWatch && !isCustomKey && form.setFieldValue('key', e.target.value)}
            />
          </Form.Item>
          <Form.Item
            tooltip="Internal name. Script properties and custom features will use this name to find data on a ticket. If it is changed, some script properties and custom features may break."
            help={
              <>
                <Space>
                  <span>Custom</span>
                  <Switch
                    data-cy="property-key-switch"
                    size="small"
                    onChange={(checked) => {
                      setIsCustomKey(checked)

                      // if unchecked, set key to name
                      if (!checked) {
                        form.setFieldValue('key', form.getFieldValue('name'))
                      }
                    }}
                    checked={isCustomKey}
                  />
                </Space>
                {propertyKeyAlreadyExists && (
                  <Alert
                    className="mt-10"
                    showIcon
                    type="warning"
                    message="This key already exists. Please use a unique key"
                  />
                )}
              </>
            }
            label="Property Key"
            name="key"
            rules={[
              {
                required: isCustomKey
              }
            ]}
          >
            <Input data-cy="property-key-input" disabled={!isCustomKey} />
          </Form.Item>
        </div>
        <Form.Item label="Property Type" name={['property_type', 'id']}>
          {typeQuery.isLoading ? (
            <Select placeholder="Loading" loading />
          ) : (
            <Select
              data-cy="property-type-select"
              options={typeOptions}
              onChange={(val) => {
                const newPropertyName = getTypeNameById(val)

                if (newPropertyName === 'Script' && propertyName === 'ScriptedButton') {
                  form.setFieldsValue({
                    script: webBindingWatch
                  })
                }

                if (newPropertyName === 'ScriptedButton' && propertyName === 'Script') {
                  form.setFieldsValue({
                    web_binding: scriptWatch
                  })
                }
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="Note" name="notes">
          <Input.TextArea aria-label="Notes" className="w-full" autoSize />
        </Form.Item>
        <div className="grid grid-cols-3 gap-12">
          <Form.Item label="Sequence" name="sequence">
            <InputNumber data-cy="property-sequence-input" controls={false} className="w-full" />
          </Form.Item>
          <Form.Item
            label="Layout"
            name="layout"
            tooltip="Percentage of the row this property will span. Must be a multiple of 5 or one of 33 and 66"
          >
            <Input data-cy="property-layout-input" />
          </Form.Item>
          <Form.Item
            label="Skip"
            name="skip"
            tooltip="Trailing blank space this property will add as a percentage of the row. Must be a multiple of 5 or one of 33 and 66 "
          >
            <InputNumber data-cy="property-skip-input" className="w-full" />
          </Form.Item>
        </div>
        <div className={cn('grid', forBpa ? 'grid-cols-2 gap-12 pb-24' : 'grid-cols-6')}>
          <Form.Item noStyle name="required" valuePropName="checked">
            <Checkbox data-cy="property-required-checkbox">Required</Checkbox>
          </Form.Item>
          <Form.Item data-cy="readonly-checkbox" noStyle name="read_only" valuePropName="checked">
            <Checkbox>Read-only</Checkbox>
          </Form.Item>
          <Form.Item data-cy="hide-on-web-checkbox" noStyle name="hide_on_web" valuePropName="checked">
            <Checkbox>Hide (Web)</Checkbox>
          </Form.Item>
          <Form.Item data-cy="hide-on-print-checkbox" noStyle name="hide_on_print" valuePropName="checked">
            <Checkbox>Hide (Print)</Checkbox>
          </Form.Item>
          <Form.Item data-cy="hide-on-mobile-checkbox" noStyle name="hide_on_mobile" valuePropName="checked">
            <Checkbox>Hide (Mobile)</Checkbox>
          </Form.Item>
          <Tooltip
            title={
              section?.ticket_private
                ? 'This flag cannot be changed because its section has it unset'
                : 'If this flag is set when a ticket is copied, this value will be transferred to the new ticket'
            }
          >
            <Form.Item
              noStyle
              name="ticket_private"
              valuePropName="checked"
              getValueProps={(value) => ({ checked: !value })}
              getValueFromEvent={(e) => !e.target.checked}
            >
              <Checkbox disabled={!!section?.ticket_private}>Copyable</Checkbox>
            </Form.Item>
          </Tooltip>
        </div>
      </div>
    </>
  )
}
