import { DATE_FORMAT } from '@/constants/date'
import { ALLOWED_OPERATORS_BY_TYPE, API_OPERATORS, DYNAMIC_DATES, TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { LINE_ITEM_FILTER_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_FILTER_COLUMNS } from '@/modules/ticket/columns'
import { Button, Select } from '@/ui'
import { getColumnLabel } from '@/utils/get-column-label'
import { Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

type Props = {
  editingFilter: any
  onClose: () => void
  onSave: (values: any) => void
}

export const EditFilterModal = ({ editingFilter, onClose, onSave }: Props) => {
  const [form] = Form.useForm()
  const parentForm = Form.useFormInstance()
  const [isDynamicDates, setIsDynamicDates] = useState(false)
  const [conditionLogic, setConditionLogic] = useState('Text')
  const fieldVerbose = Form.useWatch('field_verbose', form) ?? ''
  const path = Form.useWatch('path', form) ?? ''
  const filterType = Form.useWatch('type', form) ?? ''
  const type = Form.useWatch('type', parentForm)
  const [isTicketStatusField, setIsTicketStatusField] = useState(editingFilter.field === 'computed_status')
  const value = Form.useWatch('value', form)
  const { labels } = useApp()

  const columns = useMemo(() => (type === 'I' ? LINE_ITEM_FILTER_COLUMNS : TICKET_FILTER_COLUMNS), [type])

  const fieldType = useMemo(
    () =>
      fieldVerbose
        ? (columns.find((item) => item.field_verbose === fieldVerbose)?.field_type ?? 'text')
        : (columns.find((item) => item.field_verbose === editingFilter.field_verbose)?.field_type ?? 'text'),
    [editingFilter.field_verbose, fieldVerbose]
  )

  const apiOperators = ALLOWED_OPERATORS_BY_TYPE[fieldType as keyof typeof ALLOWED_OPERATORS_BY_TYPE]

  const columnOptions = useMemo(() => {
    return columns
      .map((item) => ({
        value: `${item.path || ''}${item.field_verbose || ''}`,
        label: getColumnLabel(item, labels)
      }))
      .sort((a, b) => a.label.localeCompare(b.label, 'en'))
  }, [columns])

  const handleSave = async () => {
    const { path_and_field_verbose, value, value2, ...values } = await form.validateFields()

    onSave({
      ...values,
      value:
        fieldType === 'date' && !isDynamicDates && filterType !== 'isnull' ? dayjs(value).format('YYYY-MM-DD') : value,
      value2:
        fieldType === 'date' && value2 && !isDynamicDates && filterType !== 'isnull'
          ? dayjs(value2).format('YYYY-MM-DD')
          : value2
    })
    onClose()
  }

  const handleColumnSelect = (column: any) => {
    const { field, field_type, field_verbose, path, path_verbose, name } = column
    const allowedOperators = ALLOWED_OPERATORS_BY_TYPE[field_type as keyof typeof ALLOWED_OPERATORS_BY_TYPE]

    const fields = {
      name,
      field,
      field_verbose,
      path,
      path_verbose,
      path_and_field_verbose: `${path || ''}${field_verbose || ''}`
    }

    if (!allowedOperators.includes(filterType)) {
      form.setFieldsValue({ filter_type: allowedOperators[0], ...fields })
    }

    form.setFieldsValue({ value: null, value2: null, ...fields })
  }

  // set condition logic
  useEffect(() => {
    if (filterType === 'range') {
      setConditionLogic('Range')
    } else if (filterType === 'isnull') {
      setConditionLogic('Conditional')
    } else if (fieldType === 'date') {
      setConditionLogic('Date')
    } else if (fieldType === 'number') {
      setConditionLogic('Number')
    } else if (fieldType === 'text') {
      setConditionLogic('Text')
    } else {
      console.error('unhandled lookup in switchCondition!  fieldType=', fieldType, ' filterType=', filterType)
    }
  }, [filterType, fieldType])

  useEffect(() => {
    let _isDynamicDate = false
    if (
      fieldType === 'date' &&
      editingFilter.type === 'range' &&
      Object.keys(DYNAMIC_DATES).includes(editingFilter.value)
    ) {
      setIsDynamicDates(true)
      _isDynamicDate = true
    }

    const initialValues = {
      ...editingFilter,
      value:
        fieldType === 'date' && !_isDynamicDate && editingFilter.type !== 'isnull'
          ? dayjs(editingFilter.value)
          : editingFilter.value,
      value2:
        fieldType === 'date' && editingFilter.value2 && !_isDynamicDate && editingFilter.type !== 'isnull'
          ? dayjs(editingFilter.value2)
          : editingFilter.value2
    }
    form.setFieldsValue(initialValues)
  }, [])

  return (
    <Modal
      open={!!editingFilter}
      title={editingFilter.path ? 'Edit Filter' : 'Add Filter'}
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      width={700}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden />
        <Form.Item name="name" hidden />
        <Form.Item name="field" hidden />
        <Form.Item name="path" hidden />
        <Form.Item name="path_verbose" hidden />
        <Form.Item name="value" hidden />
        <Form.Item name="type" hidden />
        <Form.Item
          name="field_verbose"
          label="Filter By"
          rules={[{ required: true, message: 'Please select a field' }]}
          getValueFromEvent={(val) => val}
          getValueProps={() => {
            return {
              value: `${path}${fieldVerbose}`
            }
          }}
        >
          <Select
            options={columnOptions}
            onChange={(val, option: any) => {
              const item = columns.find((col) => `${col.path}${col.field_verbose}` === val)

              if (!item) {
                console.error('item not found', val)
                return
              }

              handleColumnSelect({ ...item, name: option.label })

              if (item.field_verbose === 'computed_status') {
                setIsTicketStatusField(true)
                form.setFieldsValue({ value: 'D', type: 'in' })
              } else {
                setIsTicketStatusField(false)
                form.setFieldsValue({ value: null, type: null })
              }

              return val
            }}
          />
        </Form.Item>
        {isTicketStatusField ? (
          <div>
            {value &&
              value.split(',').map((item: string, index: number) => (
                <div className="flex gap-x-8" key={item + index}>
                  <Form.Item className="grow" label="Criteria">
                    <Select
                      value={item}
                      options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
                      onChange={(val) => {
                        form.setFieldsValue({
                          value: value
                            .split(',')
                            .map((value: string, i: number) => (value + i === item + index ? val : value))
                            .join(',')
                        })
                      }}
                    />
                  </Form.Item>
                  {value.split(',').length > 1 && (
                    <Form.Item label=" ">
                      <Button
                        type="text"
                        iconName="fa:trash"
                        onClick={() => {
                          form.setFieldsValue({
                            value: value
                              .split(',')
                              .filter((value: string, i: number) => value + i !== item + index)
                              .join(',')
                          })
                        }}
                      />
                    </Form.Item>
                  )}
                </div>
              ))}
            <Button
              type="primary"
              onClick={() => {
                form.setFieldsValue({ value: `${value},D` })
              }}
            >
              Add Another
            </Button>
          </div>
        ) : (
          <div>
            <div className="flex gap-x-16 items-center">
              <Form.Item label=" " name="negate" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <span>Not</span>
              <Form.Item label="Comparison" name="type" className="grow">
                <Select
                  placeholder="Select"
                  options={apiOperators.map((operator) => ({
                    label: API_OPERATORS[operator as keyof typeof API_OPERATORS] || operator,
                    value: operator
                  }))}
                  onChange={() => {
                    form.setFieldsValue({ value: null, value2: null })
                  }}
                />
              </Form.Item>
            </div>
            <div>
              {conditionLogic === 'Text' && (
                <Form.Item label="Criteria" name="value">
                  <Input />
                </Form.Item>
              )}
              {conditionLogic === 'Number' && (
                <Form.Item label="Criteria" name="value">
                  <InputNumber />
                </Form.Item>
              )}
              {conditionLogic === 'Conditional' && (
                <Form.Item name="value">
                  <Radio.Group>
                    <Radio value="True">True</Radio>
                    <Radio value="False">False</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              {conditionLogic === 'Date' && !['range', 'isnull'].includes(filterType) && (
                <Form.Item label="Criteria " name="value">
                  <DatePicker format={DATE_FORMAT} />
                </Form.Item>
              )}
              {conditionLogic === 'Range' && (
                <div>
                  {(fieldType === 'text' || fieldType === 'number') && (
                    <div className="flex gap-x-16">
                      <Form.Item label="Start/Low" name="value">
                        <Input />
                      </Form.Item>
                      <Form.Item label="End/High" name="value2">
                        <Input />
                      </Form.Item>
                    </div>
                  )}
                  {fieldType === 'date' && (
                    <div>
                      <Checkbox
                        className="mb-16"
                        checked={isDynamicDates}
                        onChange={(e) => {
                          setIsDynamicDates(e.target.checked)
                          form.setFieldsValue({ value: null, value2: null })
                        }}
                      >
                        Dynamic Dates
                      </Checkbox>
                      {isDynamicDates ? (
                        <Form.Item label="Criteria" name="value">
                          <Select options={Object.entries(DYNAMIC_DATES).map(([value, label]) => ({ value, label }))} />
                        </Form.Item>
                      ) : (
                        <div className="flex gap-x-16">
                          <Form.Item label="Start date" name="value">
                            <DatePicker format={DATE_FORMAT} />
                          </Form.Item>
                          <Form.Item label="End date" name="value2">
                            <DatePicker format={DATE_FORMAT} />
                          </Form.Item>
                        </div>
                      )}
                      {isDynamicDates && (value === 'ld' || value === 'pm') && (
                        <Form.Item label={value === 'ld' ? 'Days' : 'Months'} name="value2">
                          <InputNumber />
                        </Form.Item>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex gap-x-16 items-center">
              <span>For:</span>
              <Form.Item
                name="not_for_manager"
                noStyle
                valuePropName="checked"
                getValueProps={(value) => ({ checked: !value })}
                getValueFromEvent={(e) => !e.target.checked}
              >
                <Checkbox>Admin or Manager</Checkbox>
              </Form.Item>
              <Form.Item
                name="not_for_user"
                noStyle
                valuePropName="checked"
                getValueProps={(value) => ({ checked: !value })}
                getValueFromEvent={(e) => !e.target.checked}
              >
                <Checkbox>User</Checkbox>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  )
}
