import classNames from 'classnames'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { currentRuleTemplateAtom, currentRuleTemplateCombinedRulesAtom } from '../atoms'
import { constuctPreviewCalendars } from '../utils'
import './styles.modules.scss'

const daysInMonth = 31
const cellsPerCalendar = 35
const daysInWeek = 7

export const Preview = () => {
  const currentRuleTemplate = useAtomValue(currentRuleTemplateAtom)
  const currentRuleTemplateCombinedRules = useAtomValue(currentRuleTemplateCombinedRulesAtom)

  const calendars = useMemo(() => {
    return constuctPreviewCalendars(currentRuleTemplateCombinedRules.length, cellsPerCalendar, daysInMonth)
  }, [currentRuleTemplateCombinedRules.length])

  if (!currentRuleTemplate) {
    return null
  }

  return (
    <div className="h-full overflow-y-auto schedule-rule-templates-wrapper">
      <div className="mb-4">Preview</div>
      <div className="flex flex-row flex-wrap gap-12 items-start">
        {calendars.map((days, index) => (
          <div key={index} className="p-12 rounded-md border border-solid border-gray-300 w-[320px]">
            <div>
              <div className="flex py-4 border-0 border-b border-solid border-gray-300">
                {Array.from({ length: daysInWeek }).map((_, index) => (
                  <div key={index} className="flex-1 text-center font-semibold">
                    {dayjs()
                      .day(index + 1)
                      .format('ddd')}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-7 gap-y-12 mt-12">
                {days.map(({ day, index }) => (
                  <div key={index} className="flex items-center justify-center">
                    <div
                      className={classNames('h-34 w-34 rounded-full flex items-center justify-center', {
                        hidden: !(currentRuleTemplateCombinedRules.length > daysInMonth) && index + 1 > daysInMonth,
                        'bg-green text-white': currentRuleTemplateCombinedRules[index] === 'day_on',
                        'bg-gray-300': currentRuleTemplateCombinedRules[index] === 'day_off'
                      })}
                      data-cy={`rule-${index + 1}-${currentRuleTemplateCombinedRules[index]}-calendar`}
                    >
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              {index === 0 && (
                <div className="pt-14 pb-4 mt-16 border-0 border-t border-solid border-gray-300 flex items-center gap-x-12">
                  <div className="flex items-center gap-x-4">
                    <span className="h-12 w-12 rounded-full bg-green" />
                    <span data-cy="rule-on-count">
                      {currentRuleTemplateCombinedRules.filter((value) => value !== 'day_off').length} Day on
                    </span>
                  </div>
                  <div className="flex items-center gap-x-4">
                    <span className="h-12 w-12 rounded-full bg-gray-300" />
                    <span data-cy="rule-off-count">
                      {currentRuleTemplateCombinedRules.filter((value) => value === 'day_off').length} Day off
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
