import { AppLogo } from '@/components'
import { AppLink } from '@/ui/AppLink'

type Props = {
  url?: string
  iconOnly?: boolean
}

export const Logo = ({ url, iconOnly = false }: Props) => {
  return (
    <AppLink to={url ? url : ''} className={'flex flex-1 overflow-hidden w-full px-[15px]'}>
      <AppLogo iconOnly={iconOnly} className={'max-h-[40px]'} />
    </AppLink>
  )
}
