import { useApp } from '@/hooks'
import { refreshQueries } from '@/modules/ticket/form/helpers'
import { fetchQuery } from '@/query'
import { msNavApi } from '@/services/api-service'
import { Button } from '@/ui'
import { getApiMessage } from '@/utils'
import { FC, useState } from 'react'
import { useButtonContext } from '../../hooks'

export const MsNavCheckButton: FC = () => {
  const { notification } = useApp()
  const { ticket, property, buttonProps } = useButtonContext()
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    try {
      setLoading(true)

      try {
        await fetchQuery(msNavApi.check(ticket.id))
        await refreshQueries(['tickets'])
        notification.success({
          message: 'MS Nav Check Success',
          description: 'Ticket updated with recent MS Nav status'
        })
      } catch (error: any) {
        notification.error({
          message: 'MS Nav Status Check Failed',
          description: error?.response ? getApiMessage(error?.response) : error?.message || 'Unknown error'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button {...buttonProps} loading={loading} onClick={handleClick}>
      {buttonProps?.children ? buttonProps.children : property.name}
    </Button>
  )
}
