import { profileApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { Profile } from '../types'
import { sharedDataAtom } from './atom'

const useSetSharedData = () => {
  const setSharedData = useSetAtom(sharedDataAtom)
  const sharedData = useAtomValue(sharedDataAtom)
  return (data: any) => {
    setSharedData({
      ...sharedData,
      id: data?.user?.pk,
      contact_id: data?.contact?.id,
      first_name: data?.first_name,
      last_name: data?.last_name,
      title: data?.title,
      phone_number: data?.phone_number,
      new_email: data?.new_email,
      is_active: data?.is_active,
      timezone: data?.timezone,
      username: data?.username,
      userdata_json: data?.userdata_json
    })
  }
}

const useFetchProfile = () => {
  const { id } = useParams<{ id: string }>()
  const profileQuery = useQuery({
    ...profileApi.get<Profile>(id as any),
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  return profileQuery
}

export { useFetchProfile, useSetSharedData }
