export const SETTINGS_PATHS = {
  customForms: '/account/custom_forms',
  editCustomForm: (id: number | string) => `/account/custom_forms/${id}/edit`,
  addCustomForm: '/account/custom_forms/add',
  customFormLayout: (id: number | string) => `/account/custom_forms/${id}/layout`,
  stages: '/stages',
  importExport: '/account/import',
  notificationTemplates: '/account/notification_templates',
  addNotificationTemplate: '/account/notification_templates/add',
  editNotificationTemplate: (id: number | string) => `/account/notification_templates/${id}/edit`,
  notificationsActivity: 'notifications_activity'
} as const
