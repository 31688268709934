import { MutationReturn, RequestConfig } from '@/types/api/core'
import { request } from '../core'

export type OpenIdAuthAPI = {
  initSignIn: (config?: RequestConfig) => MutationReturn<{ auth_uri: string }, { idp: string; next_link: string }>
  completeSignIn: (
    config?: RequestConfig
  ) => MutationReturn<{ jwt_token: string; next_link: string }, { auth_response: { [key: string]: any } }>
}

export const _openIdAuthAPI: OpenIdAuthAPI = {
  initSignIn: (config?: RequestConfig) => ({
    mutationFn: (requestData) =>
      request({ ...config, url: 'v2/openid-auth/init_signin', method: 'POST', data: requestData })
  }),
  completeSignIn: (config?: RequestConfig) => ({
    mutationFn: (requestData) =>
      request({ ...config, url: 'v2/openid-auth/complete_signin', method: 'POST', data: requestData })
  })
}
