import { SearchData } from './types'

export const getControlFlagsBySearch = (search: SearchData) => {
  if (search.field === 'computed_status') {
    return { type: false, text: false, yesNo: false, status: true, date: false }
  } else if (search.field === 'timepoint_due') {
    return { type: false, text: false, yesNo: false, status: false, date: true }
  } else if (search.type === 'isnull') {
    return { type: true, text: false, yesNo: true, status: false, date: false }
  } else {
    return { type: true, text: true, yesNo: false, status: false, date: false }
  }
}
