import { useViewData } from '@/layouts'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { clientRecordApi, ticketApiV2 } from '@/services/api-service'
import { zodQueryFields } from '@/utils/zod'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ScheduleViewData } from '../schemas'
import { selectedResourcesAtom } from './atoms'
import { transformJobTicket } from './helpers'
import { JobAssignConfig, JobResource, JobRole, JobSkill, JobTicket, JobTicketSchema } from './schemas'

export const useJobDataProperties = () => {
  const [searchParams] = useSearchParams()
  const { module } = useCurrentModuleQuery()

  const jobDataMap: JobAssignConfig = useMemo(
    () =>
      [...searchParams.entries()]
        .filter(([key]) => key.endsWith('_pid'))
        .reduce(
          (curr, [key, val]) => ({ ...curr, [key]: Number(val) }),
          (module?.data?.for_jm || {}) as JobAssignConfig
        ),
    [searchParams, module?.data]
  )

  return {
    moduleId: module?.id,
    requiredPids: Object.values(jobDataMap),
    jobDataMap
  }
}

export const useJobRoles = () => {
  const { isSuccess, isLoading, data } = useQuery({
    ...clientRecordApi.list<JobRole>({ label__iexact: 'Job Roles' }),
    select: (data) => {
      return {
        ...data,
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.char_1,
          resource_type: item.char_2,
          property_id: Number(item.char_3),
          job_role_color: item.char_4
        }))
      }
    }
  })

  const [jobRolesMap, jobRoleNames, getJobRoleName] = useMemo(() => {
    const _items = data?.items || []

    const _map = _items.reduce((acc: Record<number, JobRole>, item: JobRole) => {
      acc[item.id] = item
      return acc
    }, {})

    const _names = _items.map((item: JobRole) => item.name)
    const _getName = (id: number | null) => {
      if (!id) return 'N/A'

      return jobRolesMap[id]?.name || 'N/A'
    }

    return [_map, _names, _getName]
  }, [data?.items])

  return {
    isSuccess,
    isLoading,
    jobRoles: data?.items || [],
    jobRolesMap,
    jobRoleNames,
    getJobRoleName
  }
}

export const useJobTicket = () => {
  const params = useParams()
  const { requiredPids, jobDataMap } = useJobDataProperties()
  const { jobRolesMap, isSuccess: isJobRolesFetched } = useJobRoles()

  const _transformTicket = useCallback(
    (ticket: JobTicket) => transformJobTicket(ticket, jobDataMap, jobRolesMap),
    [jobDataMap, jobRolesMap]
  )

  const ticketId = Number(params.ticketId)

  const fetchTicket = useQuery({
    ...ticketApiV2.get<JobTicket>(
      ticketId,
      {},
      { fields: zodQueryFields(JobTicketSchema).concat(requiredPids.map((pid) => `property__${pid}`)) }
    ),
    enabled: !!ticketId && !!requiredPids.length && isJobRolesFetched,
    select: (data) => _transformTicket(data)
  })

  return {
    jobTicket: fetchTicket.data,
    isFetched: fetchTicket.isFetched
  }
}

export const useJobSkills = () => {
  const { data } = useViewData<ScheduleViewData>()
  const { jobTicket } = useJobTicket()
  const skillsQuery = useQuery({
    ...clientRecordApi.list({
      label__iexact: 'Skills',
      char_1__in: jobTicket?._jobData.attributes.join(','),
      char_2__eq: data.type
    }),
    enabled: !!jobTicket?._jobData.attributes.length,
    select: (data) => ({
      ...data,
      items: data.items.map(
        (cr): JobSkill => ({
          key: cr.char_1,
          label: cr.char_3,
          type: cr.char_2
        })
      )
    })
  })

  return {
    skills: skillsQuery.data?.items,
    isFetched: skillsQuery.isFetched
  }
}

export const useJobResourceSelection = () => {
  const { data } = useViewData<ScheduleViewData>()
  const { jobTicket, isFetched: isJobTicketFetched } = useJobTicket()
  const [selectedResources, _setSelectedResources] = useAtom(selectedResourcesAtom)

  const originalResourceIds = useMemo(
    () => jobTicket?.schedule_events.filter((ev) => ev.resource__type === data.type).map((ev) => ev.resource__id) || [],
    [jobTicket?.schedule_events, data.label]
  )

  const selectedResourceIds = useMemo(() => selectedResources.map((r) => r.id), [selectedResources])
  const hasResourceSelectionChanges = useMemo(
    () =>
      JSON.stringify([...new Set(selectedResourceIds)].sort()) !==
      JSON.stringify([...new Set(originalResourceIds)].sort()),
    [selectedResourceIds, originalResourceIds]
  )

  useEffect(() => {
    if (!isJobTicketFetched || !jobTicket?.schedule_events) return

    const _selectedResources: JobResource[] = jobTicket?.schedule_events
      .filter((ev) => ev.resource__type === data.type)
      .map((ev) => ({
        id: ev.resource__id,
        name: ev.resource__name,
        type: ev.resource__type,
        short_code: ev.resource__short_code,
        job_role__id: Number((ev.job_role_id || '').split(':')[0]),
        assigned_sku_id: ev.assigned_sku_id,
        attributes: ev.resource__attributes
      }))
    _setSelectedResources(_selectedResources)
  }, [jobTicket?.schedule_events, isJobTicketFetched])

  return {
    selectedResourceIds,
    selectedResources,
    originalResourceIds,
    hasResourceSelectionChanges
  }
}
