import { Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useFetchProfile } from '../../hooks'
import { selectedRoleIdAtom, selectedRolesAtom } from '../atoms'
import { useCustomForms } from '../hooks'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'
import { ToggleSwitch } from './components/ToggleSwitch'
/*
    enabledCategories below refers to inactive ones
*/
const AccessCustomForm = () => {
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const { data: customFormData, isLoading } = useCustomForms()

  const [selectedData, setSelectedData] = useState('')
  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)

  const [customFormList, setCustomFormList] = useState<{ id: number; name: string }[] | []>([])
  const [enabledCategories, setEnabledCategories] = useState<Array<number | null>>([])
  const { data: profileData } = useFetchProfile()
  useEffect(() => {
    if (customFormData && customFormData.items.length > 0) {
      const items = customFormData.items.map((cd) => ({ id: cd.id, name: cd.name }))
      setCustomFormList(items)
    }
    if (selectedRole && selectedRole.data && 'disabled_forms' in selectedRole.data) {
      if (selectedRole?.data.disabled_forms) {
        setEnabledCategories(selectedRole?.data.disabled_forms)
      }
    }
  }, [customFormData, selectedRole])

  const checkIfActive = (id: number) => {
    if (!enabledCategories) return false
    if (enabledCategories && enabledCategories.length === 0) return false
    if (enabledCategories && enabledCategories.includes(id)) return true
  }

  const handleOkClick = () => {
    setSelectedRoleId(0)
    if (selectedRole) {
      let disabledForms = [] as number[]
      const id = Number(selectedData)
      if (profileData?.userdata_json?.disabled_forms) {
        if (profileData?.userdata_json?.disabled_forms.includes(id)) {
          const filteredForms = profileData?.userdata_json?.disabled_forms.filter((group) => group !== id)
          disabledForms = filteredForms
        } else {
          disabledForms = [...(profileData?.userdata_json?.disabled_forms as number[]), id]
        }
      }
      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          enabled_groups: profileData?.userdata_json?.enabled_groups,
          tab_permissions: profileData?.userdata_json?.tab_permissions,
          enabled_modules: profileData?.userdata_json?.enabled_modules,
          enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
          disabled_forms: disabledForms,
          advance_settings: profileData?.advance_settings
        }
      })
    }
    setChangeRoleModalState(false)
  }
  const handleChange = ({ data, state }: { data: string; state: boolean }) => {
    setSelectedData(data)
    if (!data) return

    if (state) {
      const id = Number(data)
      if (!enabledCategories.includes(id)) {
        const categories = [...enabledCategories, id]
        const updatedRole = { ...selectedRole, data: { ...selectedRole?.data, disabled_forms: categories } }
        setSelectedRole(updatedRole)
      } else {
        const filteredId = enabledCategories.filter((cat) => cat !== id)
        const updatedRole = { ...selectedRole, data: { ...selectedRole?.data, disabled_forms: filteredId } }
        setSelectedRole(updatedRole)
      }
    }
  }

  const handleCancelClick = () => {
    setChangeRoleModalState(false)
  }
  const handleRoleOkClick = () => {
    setEnableRoleModalState(false)
  }
  const handleRoleCancelClick = () => {
    setEnableRoleModalState(false)
  }
  const handleChangeRoleModalActiveState = (state: boolean) => {
    setChangeRoleModalState(state)
  }
  const handleEnableRoleModalActiveState = (state: boolean) => {
    setEnableRoleModalState(state)
  }

  return (
    <>
      <div className="w-full lg:w-[33.333%]  xl:w-[20%] mb-32">
        <AccessListItemHeader title="Custom Form" />
        <div className=" px-24   pt-32 border lg:border-0  xl:border-r  border-gray-200 h-full">
          <Spin spinning={isLoading}>
            {customFormList.map((list, i) => {
              const isChecked = checkIfActive(list.id)
              return (
                <div className="w-full flex justify-between items-center mb-24" key={i}>
                  <ToggleSwitch
                    id={`category-${i}`}
                    data={list}
                    checked={!isChecked}
                    onChange={handleChange}
                    onChangeRoleToggleModal={handleChangeRoleModalActiveState}
                    onEnableRoleToggleModal={handleEnableRoleModalActiveState}
                  />
                </div>
              )
            })}
          </Spin>
        </div>
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={handleChangeRoleModalActiveState}
        onOk={handleOkClick}
        onCancel={handleCancelClick}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={handleEnableRoleModalActiveState}
        onOk={handleRoleOkClick}
        onCancel={handleRoleCancelClick}
      />
    </>
  )
}
export default AccessCustomForm
