import { Button } from '@/ui'
import { Form, Input, Space } from 'antd'

export const InvoiceHeaderSection = () => {
  const isQbEnabled = Form.useWatch(['doc', 'accounting', 'qbo_enabled'])
  const isQbwcEnabled = Form.useWatch(['doc', 'accounting', 'qbwc_enabled'])
  const shouldRender = isQbwcEnabled && !isQbEnabled

  return (
    <Form.Item hidden={!shouldRender}>
      <h4 className="font-bold">Invoice Header</h4>
      <div className="grid grid-cols-3 gap-x-24">
        <Form.Item label="Template Name" name={['doc', 'invoice__TemplateName']}>
          <Input />
        </Form.Item>
        <Form.Item label="Date" name={['doc', 'invoice__TxnDate']}>
          <Input />
        </Form.Item>
        <Form.Item label="Invoice Number" name={['doc', 'invoice__RefNumber']}>
          <Input />
        </Form.Item>
        <Form.Item label="REP" name={['doc', 'invoice__SalesRepName']}>
          <Input />
        </Form.Item>
        <Form.Item label="Bill Address" name={['doc', 'invoice__BillAddress']}>
          <Input.TextArea rows={1} />
        </Form.Item>
        <Form.Item label="Ship Address" name={['doc', 'invoice__ShipAddress']}>
          <Input.TextArea rows={1} />
        </Form.Item>
        <Form.Item label="Class Name" name={['doc', 'invoice__ClassName']}>
          <Input />
        </Form.Item>
        <Form.Item label="PO Number" name={['doc', 'invoice__PONumber']}>
          <Input />
        </Form.Item>
        <Form.Item label="Due Date" name={['doc', 'invoice__DueDate']}>
          <Input />
        </Form.Item>
        <Form.Item label="Ship Date" name={['doc', 'invoice__ShipDate']}>
          <Input />
        </Form.Item>
        <Form.Item label="Ship Method" name={['doc', 'invoice__ShipMethodName']}>
          <Input />
        </Form.Item>
        <Form.Item label="FOB" name={['doc', 'invoice__FOB']}>
          <Input />
        </Form.Item>
        <Form.Item label="Other" name={['doc', 'invoice__Other']}>
          <Input />
        </Form.Item>
      </div>
      <Form.List name={['doc', 'custom_fields']}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Form.Item label="Custom Field" name={[field.name, 'name']}>
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item label="Value" name={[field.name, 'value']}>
                  <Input placeholder="Value" />
                </Form.Item>
                <Form.Item label=" ">
                  <Button iconName="fa:trash" onClick={() => remove(field.name)}></Button>
                </Form.Item>
              </Space>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()}>
                Add Custom Field
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form.Item>
  )
}
