import { timeTicketApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

type Props = {
  queryParams: any
}

export const TotalHoursByType = ({ queryParams }: Props) => {
  const timeTicketQuery = useQuery({ ...timeTicketApi.list(queryParams) })

  const totalHoursByType = useMemo(() => {
    if (!timeTicketQuery.data) {
      return {}
    }

    return timeTicketQuery.data?.items.reduce(
      (acc, item) => {
        if (!acc[item.job_type__name]) {
          acc[item.job_type__name] = 0
        }
        acc[item.job_type__name] += item.total_time
        return acc
      },
      {} as Record<string, number>
    )
  }, [timeTicketQuery.data]) as Record<string, number>

  return (
    <div className="font-medium">
      {Object.entries(totalHoursByType).map(([key, value]) => (
        <div key={key}>
          <div>
            {(value / 60).toFixed(2)} hours as {key}
          </div>
        </div>
      ))}
    </div>
  )
}
