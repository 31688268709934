import { useApp } from '@/hooks'
import { gmapApi } from '@/services/api-service'
import { Icon, IconName, Modal, ModalProps, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form, Input, List, Spin } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useButtonContext } from '../../../hooks'
import { processRoute } from '../utils/processRoutes'

type Props = ModalProps & {
  originAddress: { label: string; value: string }
  destinationAddress: { label: string; value: string }
}

export const DirectionsRouteModal = ({ originAddress, destinationAddress, onCancel, ...modalProps }: Props) => {
  const { notification } = useApp()
  const {
    vars: { directions_pid },
    saveTicket,
    isSaving
  } = useButtonContext()

  const directionsQuery = useQuery(
    gmapApi.directions({
      origin: originAddress.value,
      destination: destinationAddress.value
    })
  )

  const [selectedRoute, setSelectedRoute] = useState<ReturnType<typeof processRoute> | undefined>()

  const routes = useMemo(
    () =>
      (directionsQuery.data?.routes || []).map((route) =>
        processRoute(route, originAddress.value, destinationAddress.value)
      ),
    [directionsQuery.data?.routes, originAddress.value, destinationAddress.value]
  )

  // make first route default selected
  useEffect(() => {
    if (routes.length && !selectedRoute) setSelectedRoute(routes[0])
  }, [routes, selectedRoute])

  const handleSave: ModalProps['onOk'] = async (e) => {
    if (!selectedRoute) {
      notification.error({ message: 'No route selected', description: 'Please select a route to save directions.' })
      return
    }
    if (!directions_pid) {
      notification.error({ message: 'Fetch Directions', description: 'Directions property is not set' })
      return
    }

    const stepsText = selectedRoute.steps
      .map((step) => step.instructions)
      .join('\n- ')
      .replaceAll(/<[^>]*>/g, '')
      .replaceAll(/&nbsp;/g, ' ')
      .replaceAll(/&amp;/g, '&')
      .trim()

    const payload =
      `Route: via ${selectedRoute.summary}, Total Distance: ${selectedRoute.distance}\n\n` +
      `- Starting point ${originAddress.value} - ${originAddress.label}\n` +
      `- ${stepsText}\n` +
      `- Your destination ${destinationAddress.value} - ${destinationAddress.label}`

    await saveTicket({ [`p_${directions_pid}`]: payload })

    notification.success({ message: 'Directions saved', description: 'Directions have been saved successfully.' })
    onCancel?.(e)
  }

  return (
    <Modal
      title="Directions Route"
      okText="Save"
      width={720}
      okButtonProps={{ loading: isSaving }}
      onOk={handleSave}
      onCancel={onCancel}
      {...modalProps}
    >
      <Form layout="vertical">
        <div className="grid grid-cols-2 gap-x-16">
          <Form.Item label={`Origin (${originAddress.label})`}>
            <Input readOnly value={originAddress.value} />
          </Form.Item>
          <Form.Item label={`Destination (${destinationAddress.label})`}>
            <Input readOnly value={destinationAddress.value} />
          </Form.Item>
        </div>
        <Spin spinning={directionsQuery.isLoading}>
          <Form.Item label="Available Routes">
            <Select
              options={routes.map((route) => ({
                ...route,
                label: (
                  <span>
                    via <span className="font-bold">{route.summary}</span> ({route.distance})
                  </span>
                ),
                value: route.summary
              }))}
              allowClear={false}
              value={selectedRoute?.summary}
              showSearch={false}
              onChange={(_, option) => setSelectedRoute(option as any)}
            />
          </Form.Item>
          <List
            size="small"
            className="h-[400px] overflow-y-auto"
            bordered
            dataSource={selectedRoute?.steps}
            renderItem={(step) => (
              <List.Item className="!justify-start">
                <span className="flex items-center justify-center bg-primary-50 w-30 h-30 rounded-full">
                  <Icon name={`mi:${step.icon}` as IconName} className="text-18 text-primary-500 font-bold" />
                </span>
                <span className="ml-16" dangerouslySetInnerHTML={{ __html: step.instructions }} />
              </List.Item>
            )}
          />
        </Spin>
      </Form>
    </Modal>
  )
}
