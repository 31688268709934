import { MouseSensor as LibMouseSensor, TouchSensor as LibTouchSensor } from '@dnd-kit/core'
import { MouseEvent, TouchEvent } from 'react'

// Block dnd-kit event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false
    }
    cur = cur.parentElement as HTMLElement
  }

  return true
}

/**
 * An extended "MouseSensor" of dnd-kit that prevents elements
 * with data-no-dnd from firing drag event
 */
export class MouseSensor extends LibMouseSensor {
  static activators = [{ eventName: 'onMouseDown', handler }] as (typeof LibMouseSensor)['activators']
}

/**
 * An extended "TouchSensor" of dnd-kit that prevents elements
 * with data-no-dnd from firing drag event
 */
export class TouchSensor extends LibTouchSensor {
  static activators = [{ eventName: 'onTouchStart', handler }] as (typeof LibTouchSensor)['activators']
}
