import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { clientRecordApi } from '@/services/api-service'
import { NavTabs } from '@/ui'
import { NavTabItem } from '@/ui/tabs/nav-tabs/types'
import { useQuery } from '@tanstack/react-query'
import { Outlet, useParams } from 'react-router-dom'
import { RECORDS_LIST_VIEW_HEADER } from '../constants'
import { ModalButton } from './views/list-view/modal-button'

export const CustomRecordLayout = view(Component, {
  title: () => 'CustomRecords'
})

function Component() {
  const { l } = useApp()
  const { label } = useParams()
  const isAll = !label || label === 'All'

  const statsQuery = useQuery(clientRecordApi.stats<{ key: string; count: number }>())

  const items: NavTabItem[] =
    (statsQuery.data?.items || []).map((item) => ({
      badge: item.count,
      label: item.key || 'All',
      to: `/records/custom_records/${item.key || 'All'}`
    })) || []

  return (
    <PageView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        clearFilter: true,
        title: (
          <div className={'flex items-center gap-10'}>
            <span>{l('CustomRecords')}</span>
            <span className={'text-base relative top-2 text-gray-600/70 dark:text-gray-400/80'}>{label || 'All'}</span>
          </div>
        ),
        actions: [
          <ModalButton
            key={label}
            type={'primary'}
            shape={'default'}
            iconName={'fa:plus'}
            customRecord={{ label: isAll ? '' : label }}
            data-cy={'add-record-button'}
          >
            Add Record
          </ModalButton>
        ]
      }}
    >
      <div className={'flex-1 flex flex-row gap-10'}>
        <div className={'border-r pr-0'}>
          <NavTabs className={'w-[200px]'} orientation={'vertical'} items={items} />
        </div>
        <div className={'flex-grow overflow-hidden -m-10'}>
          <Outlet />
        </div>
      </div>
    </PageView>
  )
}
