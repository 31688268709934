import { customFormApi } from '@/services/api-service'
import { CustomForm } from '@/types/custom-form'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useCustomFormQuery = () => {
  const { id } = useParams()

  const customFormQuery = useQuery({
    ...customFormApi.get<CustomForm>(Number(id), {}, { intent_to_edit: 1, show_all: 'True' }),
    enabled: !!id
  })

  return { customFormQuery, isLoading: customFormQuery.isInitialLoading }
}
