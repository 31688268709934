import { PROGRAMMED_REPORT_TYPES } from '@/constants/general'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { programmedReportApi } from '@/services/api-service'
import { AppLink, Button, QueryDeleteButton, Tooltip } from '@/ui'
import { Space, Tag } from 'antd'
import { useState } from 'react'
import { CopyReportModal } from './components/copy-report-modal'
import { CreateReportModal } from './components/create-report-modal'
import { RenameReportModal } from './components/rename-report-modal'
import { ProgrammedReport, ProgrammedReportSchema } from './schemas'

export const ProgrammedReportsView = view(Component)

function Component() {
  const [renamingProgrammedReport, setRenamingProgrammedReport] = useState<ProgrammedReport | null>()
  const [copyingProgrammedReport, setCopyingProgrammedReport] = useState<ProgrammedReport | null>()
  const [isCreateReport, setIsCreateReport] = useState(false)

  return (
    <>
      <ListView
        header={{
          title: 'Programmed Reports',
          refresh: true,
          actions: (
            <Button type="primary" iconName="fa:add" onClick={() => setIsCreateReport(true)}>
              New Report
            </Button>
          ),
          breadcrumbs: true
        }}
        filter={{ search: { searchBy: 'report__name' } }}
        table={{
          queryApi: programmedReportApi.list,
          queryParams: {
            order: 'report__name',
            fields: ProgrammedReportSchema
          },
          rowKey: 'id',
          fullHeight: true,
          columns: [
            {
              title: 'Name',
              dataIndex: 'report__name'
            },
            {
              title: 'Company',
              dataIndex: 'company__name'
            },
            {
              title: 'Description',
              dataIndex: 'report__description'
            },
            {
              title: 'Type',
              dataIndex: 'type',
              render: (type) => (
                <Tag color={type === 'E' ? 'green' : type === 'B' ? 'blue' : 'default'}>
                  {PROGRAMMED_REPORT_TYPES[type as keyof typeof PROGRAMMED_REPORT_TYPES]}
                </Tag>
              )
            },
            {
              sorter: false,
              align: 'right',
              fixed: 'right',
              render: (_, pr: ProgrammedReport) => (
                <Space>
                  {['E', 'B'].includes(pr.type) && (
                    <Tooltip
                      title={`Copy ${PROGRAMMED_REPORT_TYPES[pr.type as keyof typeof PROGRAMMED_REPORT_TYPES]} Report`}
                    >
                      <Button type="text" iconName="fa:copy" onClick={() => setCopyingProgrammedReport(pr)} />
                    </Tooltip>
                  )}
                  {pr.type !== 'E' && (
                    <AppLink to={`/programmed_reports/${pr.id}/${pr.report__id}/edit`}>
                      <Button type="text" iconName="fa:edit" />
                    </AppLink>
                  )}
                  {pr.type === 'E' && (
                    <AppLink to={`/programmed_reports/${pr.id}/etl`}>
                      <Button type="text" iconName="fa:edit" />
                    </AppLink>
                  )}
                  <Tooltip title="Rename">
                    <Button type="text" iconName="fa:input-text" onClick={() => setRenamingProgrammedReport(pr)} />
                  </Tooltip>
                  <QueryDeleteButton api={programmedReportApi.delete} id={pr.id} />
                </Space>
              )
            }
          ]
        }}
      />
      {renamingProgrammedReport && (
        <RenameReportModal
          programmedReport={renamingProgrammedReport}
          onCancel={() => setRenamingProgrammedReport(null)}
        />
      )}
      {copyingProgrammedReport && (
        <CopyReportModal programmedReport={copyingProgrammedReport} onCancel={() => setCopyingProgrammedReport(null)} />
      )}
      {isCreateReport && <CreateReportModal onCancel={() => setIsCreateReport(false)} />}
    </>
  )
}
