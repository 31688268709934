import { Button } from '@/ui/button'
import classNames from 'classnames'
import { useState } from 'react'

const lettersCount = 26
const asciiCodeOfA = 65
const alphabet = Array.from({ length: lettersCount }, (_, i) => String.fromCharCode(asciiCodeOfA + i))

type Props = {
  onChange: (letter: string | null) => void
  wrapperClassName?: string
}

export const AlphabetFilter = ({ onChange, wrapperClassName }: Props) => {
  const [activeLetter, setActiveLetter] = useState<string | null>(null)

  const handleLetterClick = (letter: string) => {
    if (activeLetter === letter) {
      setActiveLetter(null)
      onChange(null)
      return
    }

    setActiveLetter(letter)
    onChange(letter)
  }

  return (
    <div className={classNames('flex gap-x-6 mb-12 overflow-x-scroll pb-4', wrapperClassName)}>
      {alphabet.map((letter) => {
        return (
          <Button
            key={letter}
            className="uppercase"
            onClick={() => handleLetterClick(letter)}
            type={activeLetter === letter ? 'primary' : 'default'}
          >
            {letter}
          </Button>
        )
      })}
    </div>
  )
}
