import { StatusSelect } from '@/modules/ticket/components'
import { SelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'status'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<SelectProps>
}

export const StatusInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { customForm, isDisabledField, isHiddenField } = useCustomForm()

  if (isHiddenField(KEY)) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__computed_status__label'} />} {...formItemProps}>
      <StatusSelect formId={customForm.id} disabled={isDisabledField(KEY)} {...inputProps} />
    </Form.Item>
  )
}
