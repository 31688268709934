import { APP_SITE } from '@/constants/app'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useAppTheme } from '@/hooks/use-app-theme'
import { noAuthLandingPagesRoutes } from '@/modules/landing-pages/routes'
import { NavigateOptions, navigate } from '@/routing/helpers'
import { authApi } from '@/services/api-service'
import { clearAccessToken, getAccessToken, setAccessToken } from '@/services/auth-service'
import { logRocket } from '@/services/monitoring/log-rocket'
import { sentry } from '@/services/monitoring/sentry'
import { intercom } from '@/services/support/intercom'
import { startApp } from '@/slices/app-slice'
import { startSession } from '@/slices/session-slice'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { batch } from 'react-redux'
import { matchPath } from 'react-router-dom'

/**
 * Checks if the user is authenticated.
 * If not, redirects to the login page.
 */
export const checkAuth = (): boolean => !!getAccessToken()

/**
 * Checks if path is landing page
 * If yes, skips auth
 */
export const isLandingPage = (): boolean =>
  noAuthLandingPagesRoutes.some((route) => matchPath(route.path, window.location.pathname))

/**
 * Application bootstrap hook.
 *
 * - Load user data from the server.
 * - Start the session.
 * - Start the app.
 * - Redirects to the login page if the user is not authenticated.
 * Loads the authenticated user from the server.
 * If the user is authenticated:
 *  - Starts the session.
 *  - Starts the app.
 * Else
 *  - Redirects to the auth page if the user is not authenticated.
 */
export const useBootstrap = () => {
  const { setTheme } = useAppTheme()
  const dispatch = useAppDispatch()
  const [isReady, setIsReady] = useState(false)
  const isAuthenticated = checkAuth()
  const profileQuery = useQuery({
    ...authApi.user(),
    enabled: isAuthenticated,
    retry: true
  })
  const { data: user, isSuccess, isError, error, refetch: refetchUser, failureCount } = profileQuery

  const navigateToLogin = useCallback((to = '/auth/login', options?: NavigateOptions) => {
    navigate({ pathname: to }, options)
    setIsReady(true)
  }, [])

  const startUserSession = useCallback(() => {
    if (user && user.profile.company.use_custom_theme) {
      setTheme({
        name: user.profile.company.page_title,
        iconUrl: user.profile.company.favicon,
        color: user.profile.company.color_primary
      })
    } else {
      setTheme(APP_SITE.theme)
    }

    if (user) {
      sentry.identify(user)
      logRocket.identify(user)
      intercom.identify(user)

      batch(() => {
        dispatch(startSession(user))
        dispatch(startApp(user))
        setIsReady(true)
      })
    }
  }, [dispatch, setTheme, user])

  const refreshUserSession = useCallback(async () => {
    const { data: refreshedUser } = await refetchUser()
    if (refreshedUser) {
      batch(() => {
        dispatch(startApp(refreshedUser))
        dispatch(startSession(refreshedUser))
      })
    }
  }, [dispatch, refetchUser])

  const endUserSession = useCallback(() => {
    intercom.identify(null)
    clearAccessToken()
    setTimeout(() => navigateToLogin(undefined, { reload: true }), 100)
  }, [navigateToLogin])

  const handleError = useCallback(() => {
    console.warn('Bootstrap: Failed to load auth user. Redirecting to login page.', error)
    endUserSession()
  }, [endUserSession, error])

  useEffect(() => {
    if (isLandingPage()) {
      setIsReady(true)
      return
    }

    // useLocation hook is not available outside of router instance
    const urlParams = new URLSearchParams(window.location.search)
    const secureAccessSignature = urlParams.get('signature') || ''
    if (secureAccessSignature.length) {
      setAccessToken(`JWT ${secureAccessSignature}`)
      urlParams.delete('signature')
      window.location.search = urlParams.toString()
    }

    if (isError) {
      if (error.response?.status === 401 || failureCount > 4) {
        handleError() // unauthorized or too many retries
      } else {
        setTimeout(() => refetchUser(), 1000) // retry after 1 second
      }

      return
    }

    if (!isReady) {
      if (!isAuthenticated) {
        if (!location.pathname.startsWith('/auth')) {
          navigateToLogin()
        } else {
          setIsReady(true)
        }
      } else if (isSuccess) {
        startUserSession()
      }
    }
  }, [
    user,
    isSuccess,
    isError,
    isReady,
    isAuthenticated,
    dispatch,
    error,
    navigateToLogin,
    startUserSession,
    handleError,
    refetchUser,
    failureCount
  ])

  return { isReady, startUserSession, refreshUserSession, endUserSession, query: profileQuery }
}
