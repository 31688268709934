import { useAppDispatch, useAppSelector } from '@/hooks'
import { useTableViewsQuery } from '@/modules/jas/job-manager/hooks'
import { setActiveView } from '@/modules/jas/job-manager/slice'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { Module } from '@/modules/module/types'
import { TableView } from '@/modules/table-view/types'
import { Select } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const TableViewSelect: React.FC = () => {
  const dispatch = useAppDispatch()
  const { module } = useCurrentModuleQuery()
  const { data: viewsList, isLoading } = useTableViewsQuery(module as Module)
  const { activeView } = useAppSelector((state) => state.jobManager)
  const [searchParams, setSearchParams] = useSearchParams()

  const moduleViewId = useMemo(() => module?.selected_view, [module])
  const tableViews: TableView[] = useMemo(() => viewsList?.items || [], [viewsList])

  const viewOptions = [
    ...(module?.data?.hide_default_none_view ? [] : [{ label: 'Default', value: 0 }]),
    ...tableViews.map((item) => ({ label: item.name, value: item.id }))
  ]

  const onChange = (viewId: number) => {
    dispatch(setActiveView(tableViews.find((item) => item.id === viewId) || null))

    if (searchParams.get('active_view')) {
      searchParams.set('active_view', String(viewId))
      setSearchParams(searchParams, { replace: true })
    }
  }

  useEffect(() => {
    const activeViewParam = searchParams.get('active_view')
    if (activeViewParam && activeViewParam.length > 0) {
      // use &active_view=0 to use default
      if (!isNaN(Number(activeView))) {
        dispatch(setActiveView(tableViews.find((item) => item.id === Number(activeView)) || null))
      }
      return
    }

    if (moduleViewId && viewsList?.items) {
      dispatch(setActiveView(tableViews.find((item) => item.id === moduleViewId) || null))
    }
  }, [dispatch, moduleViewId, tableViews, viewsList?.items, searchParams])

  useEffect(() => {
    if (!activeView?.id && module?.data?.hide_default_none_view && tableViews?.length) {
      onChange(tableViews[0].id)
    }
  }, [tableViews, activeView?.id, module?.data?.hide_default_none_view])

  return module ? (
    <Select
      loading={isLoading}
      style={{ width: 180 }}
      onChange={onChange}
      className="w-full"
      key={'value'}
      options={viewOptions}
      defaultActiveFirstOption={true}
      value={activeView?.id || 0}
    />
  ) : (
    <></>
  )
}
