import { Table } from 'antd'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { startCase } from 'lodash'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const CustomFeatures = () => {
  const userData = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.userdata), []))

  const dataSource = useMemo(() => {
    const features = userData?.features || {}

    return Object.entries(features).map(([key, value]) => {
      return {
        key,
        name: startCase(key),
        enabled: value && typeof value === 'object' && 'enabled' in value ? `${value.enabled}` : undefined,
        editConfig: 'Todo...'
      }
    })
  }, [userData])

  return (
    <div className="flex-1">
      <div className="font-bold">Custom Features</div>
      <div className="mb-8">Enable and configure customer specific features by using the appropriate JSON.</div>
      <Table dataSource={dataSource}>
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Enabled?" dataIndex="enabled" />
        <Table.Column title="Actions" dataIndex="editConfig" />
      </Table>
    </div>
  )
}
