import dayjs, { Dayjs } from 'dayjs'

type PinObj = {
  dateTime: Dayjs | null
  dateTimeISO: string
  lat: number | null
  lng: number | null
  display: string
}

export const getPinObj = (value: string): PinObj => {
  const pinValues = (value || '').split(',', 4).map((v) => v.trim())

  const pin = {
    dateTime: pinValues[0] ? dayjs.parse(pinValues[0]) : null,
    dateTimeISO: pinValues[0],
    lat: parseFloat(pinValues[1]) || null,
    lng: parseFloat(pinValues[2]) || null,
    display: ''
  }
  pin.display = pin.lat !== null && pin.lng !== null ? `${pin.lat.toFixed(7) || ''}, ${pin.lng.toFixed(7) || ''}` : ''

  return pin
}

export const getPinValue = (pin: PinObj) => {
  return [pin.dateTime?.formatISO(), pin.lat, pin.lng].join(', ')
}

export const parsePinValue = (value: string, option: 'datetime' | 'link') => {
  value = value || ''
  if (value === '') {
    return null
  }

  const pinValues = value.split(',', 4).map((v) => v.trim())
  if (option === 'datetime') {
    const dateTime = pinValues[0]
    if (!dateTime) {
      return null
    }
    try {
      return dayjs.formatLocal(dateTime)
    } catch (e) {
      console.error('Failed to parse pin date-time.', e)
      return null
    }
  } else if (option === 'link') {
    const lat = pinValues[1],
      long = pinValues[2],
      placeId = pinValues[3]
    if (!lat || !long) {
      return null
    }
    let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + long
    if (placeId) {
      url += '&query_place_id=' + placeId
    }
    return url
  }
  return null
}
