import { useApp } from '@/hooks'
import { companyApi, eventApi, officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { Tabs } from '@/ui/tabs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Space, Spin } from 'antd'
import { isAxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AddEditCompanyForm, FormValues } from '../../components/add-edit-company-form'
import { RecentActivitySection } from '../../components/recent-activity-section'
import { useUpsertCompanyMutation } from '../../hooks'
import { useUpdateOfficesMutation } from './hooks'
import { OfficesListCards } from './offices-list-cards'

type Props = {
  isPrivate?: boolean
  company: Company
}

export const EditCompanyView = ({ company, isPrivate }: Props) => {
  const companyQuery = useQuery({ ...companyApi.get<Company>(Number(company.id)) })
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm<FormValues>()
  const { notification } = useApp()
  const { handleUpdateOfficeMutation } = useUpdateOfficesMutation()
  const { handleUpsertCompanyMutation } = useUpsertCompanyMutation()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(location.state?.currentTab ?? 'edit-company')
  const eventsQuery = useQuery({ ...eventApi.list({ type: 'company', target: company.id }) })

  const companyOfficesQuery = useQuery({
    ...officeApi.list<Office>({
      company__eq: company.id,
      order: 'name',
      limit: 'None'
    })
  })

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const { offices, ...companyValues } = await form.validateFields()
      await handleUpdateOfficeMutation(companyOfficesQuery.data?.items || [], offices || [])
      await handleUpsertCompanyMutation(companyValues)
      await queryClient.invalidateQueries({ queryKey: ['companies'] }) // Note: for some reason companyApi.list().queryKey is not working
      await companyOfficesQuery.refetch()
      await eventsQuery.refetch()

      setIsLoading(false)
      notification.success({ message: 'Company updated successfully' })
    } catch (error) {
      setIsLoading(false)
      if (isAxiosError(error)) {
        if (error.response?.data?.detail) {
          notification.error({ message: error.response?.data?.detail })
        } else {
          notification.error({ message: 'Something went wrong' })
        }
      } else {
        notification.error({
          message: 'Please fill in all required fields'
        })
      }
    }
  }

  // setting form initial values, 'initialValues' prop will not update when new office is added
  useEffect(() => {
    const company = companyQuery.data
    const offices = companyOfficesQuery.data?.items || []

    if (!company) {
      return
    }

    form.setFieldsValue({
      ...company,
      icon_path: company?.icon_path ? [{ uid: String(company.id), thumbUrl: company.icon_path }] : undefined,
      offices
    })
  }, [companyOfficesQuery.data?.items, form, companyQuery.data])

  // Reset location state. Because state is persisted between pages and reloads
  useEffect(() => {
    navigate(location.pathname, {
      state: {
        ...location.state,
        currentTab: undefined
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = isPrivate ? 'Edit Private Company' : 'Edit Company'
  const companyData = companyQuery.data
  const offices = companyOfficesQuery.data?.items || []

  return (
    <Spin spinning={!!company.id && (companyQuery.isLoading || companyOfficesQuery.isLoading || isLoading)}>
      <Form form={form} layout="vertical">
        <div className="flex justify-between mb-16 mt-12 items-center">
          <PageTitle>
            {title} {company?.name}
          </PageTitle>
          <Space>
            <Button data-cy="save-company" type="success" iconName="mi:save" loading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        </div>
        <Tabs
          type="solid"
          activeKey={currentTab}
          onChange={setCurrentTab}
          items={[
            {
              label: 'Edit Company',
              key: 'edit-company',
              children: <AddEditCompanyForm company={companyData} />
            },
            {
              label: 'Edit Office',
              key: 'edit-office',
              children: <OfficesListCards offices={offices} company={companyData} isPrivate={isPrivate} />
            }
          ]}
        />
        <RecentActivitySection
          type={'company'}
          target={company.id}
          events={eventsQuery.data?.items}
          isLoading={eventsQuery.isLoading}
        />
      </Form>
    </Spin>
  )
}
