import { Form, Spin } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useAtomValue } from 'jotai'
import { selectedAutofillTabAtom } from '../atoms'
import { useGroupedPropertySection } from '../hooks'
import MetaFields from './MetaFields'
import Sections from './sections'

export const TabBody = ({ form }: { form: FormInstance }) => {
  const selectedAutofillTab = useAtomValue(selectedAutofillTabAtom)

  const property = useGroupedPropertySection()

  return (
    <>
      <Spin spinning={property.isFetching}>
        <Form form={form} className="lg:w-10/12 mx-auto pb-24" layout="vertical">
          <MetaFields />
          <div className="mt-16 border-b border-gray-300"></div>
          <Sections lists={property.sections} />
        </Form>
      </Spin>
    </>
  )
}
