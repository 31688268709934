import { useApp } from '@/hooks'
import { useIsCompanyAdmin } from '@/modules/auth/hooks'
import { Button, Popconfirm } from '@/ui'
import { formatCurrency } from '@/utils/formatters'
import { sumBy } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import {
  useCustomForm,
  useTicket,
  useTicketCustomerOffice,
  useTicketLabel,
  useTicketSave
} from '../../../../../../hooks'
import { useItemDelete } from '../../../../hooks'
import { Category, LineItem } from '../../../../schemas'
import { AddEditButton } from '../../../add-edit-button'

type Props = {
  category: Category
  groupNo: number | null
  groupItems: Partial<LineItem>[]
  noCategoryFilter?: boolean
}

export const TableFoot: FC<Props> = ({ category, noCategoryFilter, groupNo, groupItems }) => {
  const l = useTicketLabel()
  const { notification } = useApp()
  const { ticket } = useTicket()
  const { features } = useCustomForm()
  const isCompanyAdmin = useIsCompanyAdmin()
  const customerOffice = useTicketCustomerOffice()
  const { saveTicket } = useTicketSave()
  const { deleteLineItem } = useItemDelete()

  const deleteGroupLineItems = useCallback(async () => {
    await Promise.all(groupItems.map((li) => deleteLineItem(li.id as number)))
    notification.success({
      message: 'Line Items Deleted',
      description: `(${groupItems.length}) line items deleted successfully`
    })
    await saveTicket()
  }, [deleteLineItem, groupItems, notification, saveTicket])

  const subTotal = useMemo(() => sumBy(groupItems, 'amount'), [groupItems])

  return (
    <div className={'flex flex-row justify-between'}>
      <div className={'flex flex-row gap-10'}>
        <AddEditButton
          buttonProps={{
            size: 'small',
            iconName: 'fa:plus',
            shape: 'round',
            children: 'Add',
            className: 'bg-transparent'
          }}
          modalProps={{ iconName: 'fa:plus', title: 'Add Item' }}
          onSaved={() => saveTicket()}
          options={{
            type: 'new',
            ticketId: ticket.id,
            customerOfficeId: customerOffice?.id,
            category: noCategoryFilter ? undefined : { label: category.name, value: category.id },
            parentCategory: noCategoryFilter ? undefined : { label: category.parent__name, value: category.parent__id },
            groupNo: groupNo,
            disabledFields: ['sku', 'description'],
            excludedFields: features.hideItemFields?.enabled ? features.hideItemFields?.fields || [] : undefined,
            limitBackDate: !isCompanyAdmin && features.restrictLineItemEdits,
            preferEmpty: true
          }}
        />

        {!!groupNo && (
          <>
            <AddEditButton
              lineItemId={groupItems[0].id}
              buttonProps={{
                size: 'small',
                iconName: 'fa:clone',
                shape: 'round',
                children: 'Duplicate',
                className: 'bg-transparent'
              }}
              modalProps={{ iconName: 'fa:plus', title: 'Duplicate Group' }}
              onSaved={() => saveTicket()}
              options={{
                type: 'duplicate',
                ticketId: ticket.id,
                customerOfficeId: customerOffice?.id,
                groupNo: groupNo,
                groupItems: groupItems,
                disabledFields: ['sku', 'description'],
                excludedFields: features.hideItemFields?.enabled ? features.hideItemFields?.fields || [] : undefined,
                limitBackDate: !isCompanyAdmin && features.restrictLineItemEdits,
                preferEmpty: true
              }}
            />

            <Popconfirm
              title={'Delete Group'}
              description={`Are you sure to delete (${groupItems.length}) line items?`}
              onConfirm={deleteGroupLineItems}
            >
              <Button size={'small'} iconName={'fa:trash'} shape={'round'} className={'bg-transparent'}>
                Delete
              </Button>
            </Popconfirm>
          </>
        )}
      </div>

      <div className={'flex flex-row'}>
        {!!groupNo && groupItems[groupItems.length - 1].subtotal_after && (
          <div className={'border-l border-gray-100 pl-10'}>
            <span className={'font-medium mr-10'}>Subtotal:</span>
            <span>{formatCurrency(subTotal)}</span>
          </div>
        )}
      </div>
    </div>
  )
}
