import { R, createResourceApi, getPayloadId } from '@/services/api-service/core'
import { ApiResource, MutationReturn, Params, RequestConfig } from '@/types/api/core'

export type CustomEmailApi = ApiResource & {
  send: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _customEmailApi: CustomEmailApi = {
  ...createResourceApi('v2/custom_emails'),
  send: (params, config) => ({
    mutationFn: (data: any) => R('POST', `/v2/custom_emails/${getPayloadId(data)}/send/`, { params, data, ...config })
  })
}
