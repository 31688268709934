import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { useMutation } from '@tanstack/react-query'
import { UploadFile } from 'antd'
import { FormValues } from '../components/add-edit-company-form'

const isUploadFile = (value: any): value is UploadFile<any>[] => {
  return Array.isArray(value) && value[0] && value[0].thumbUrl
}

export const useUpsertCompanyMutation = () => {
  const updateCompanyMutation = useMutation({ ...companyApi.update<any, Company | FormData>() })
  const createCompanyMutation = useMutation({ ...companyApi.create<any, Company | FormData>() })

  const handleUpsertCompanyMutation = async (formValues: FormValues) => {
    if (!Object.keys(formValues).length) {
      return
    }

    const formData = new FormData()

    Object.entries(formValues).forEach(([key, value]) => {
      // backedn does not accept default_profile with a value of null
      if (key === 'default_profile' && !value) {
        return
      }

      if (key === 'icon_path' && isUploadFile(value)) {
        const fileList = value
        const uploadFile = fileList[0]

        if (!uploadFile.originFileObj && uploadFile.thumbUrl) {
          formData.append(key, uploadFile.thumbUrl)
          return
        }

        if (uploadFile.originFileObj) {
          const file = uploadFile.originFileObj
          formData.append('icon', file)
          formData.append('icon_name', file.name)
          formData.append('icon_path', '')
          return
        }
      }

      if (key === 'public') {
        formData.append(key, value ? 'true' : 'false')
        return
      }

      if (value === undefined || value === null) {
        return
      }

      formData.append(key, value as string)
    })

    if (!formValues.icon_path || !formValues.icon_path[0].originFileObj) {
      if (formValues.id) {
        return updateCompanyMutation.mutateAsync(formData)
      }

      return createCompanyMutation.mutateAsync(formData)
    }

    if (formValues.id) {
      return updateCompanyMutation.mutateAsync(formData)
    }

    return createCompanyMutation.mutateAsync(formData)
  }

  return {
    updateCompanyMutation,
    handleUpsertCompanyMutation,
    createCompanyMutation
  }
}
