import { useSession } from '@/hooks'
import { useAtomSelect } from '@/hooks/atom'
import { Button, Icon, Popconfirm } from '@/ui'
import { uuid4 } from '@/utils'
import { Dropdown, Form, Switch } from 'antd'
import cn from 'classnames'
import { useSetAtom } from 'jotai'
import { set, startCase } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { bpaConfigAtom } from '../../atoms'
import { useSettings } from '../../hooks'
import { BpaTrigger, BpaTriggerEventConfig, BpaTriggerScheduleConfig } from '../../schemas'
import { EventTrigger } from './EventTrigger'
import { ScheduleTrigger } from './ScheduleTrigger'

export const TriggersForm: FC = () => {
  const { isPipeline } = useSettings()

  if (!isPipeline) return null

  return (
    <div className="rounded border">
      <div className="flex flex-row items-center border-b p-10">
        <div className="flex-grow text-16">
          <Icon name={'fa:value-absolute'} className="mr-2" />
          <span className="mx-6">Triggers</span>
        </div>
        <div className="flex-grow" />
        <div className="flex flex-row items-center gap-10">
          <AddTriggerButton />
        </div>
      </div>
      <TriggerList />
    </div>
  )
}

function AddTriggerButton() {
  const { user, timezone } = useSession()
  const triggers = useAtomSelect(bpaConfigAtom, 'triggers')
  const setBpaConfig = useSetAtom(bpaConfigAtom)

  const disableSchedule = useMemo(() => triggers?.some((trigger) => trigger.type === 'schedule'), [triggers])

  const addTrigger = useCallback(
    (type: BpaTrigger['type']) => {
      const configByType: { schedule: BpaTriggerScheduleConfig; event: BpaTriggerEventConfig } = {
        schedule: {
          run_as: user?.id,
          cron_spec: '0 0 * * *', // every day at midnight
          timezone: timezone
        },
        event: { event_name: '' }
      }

      setBpaConfig((config) => {
        config.triggers = config.triggers || []
        config.triggers.push({
          uuid: uuid4(),
          enabled: true,
          type: type,
          name: startCase(type),
          config: configByType[type]
        })
      })
    },
    [setBpaConfig, timezone, user?.id]
  )

  return (
    <Dropdown
      arrow={true}
      menu={{
        items: [
          {
            disabled: disableSchedule,
            title: disableSchedule ? 'Schedule trigger already exists' : undefined,
            key: 'schedule',
            type: 'item',
            label: 'Schedule',
            icon: <Icon name={'fa:clock'} />,
            onClick: () => addTrigger('schedule')
          },
          {
            key: 'event',
            type: 'item',
            label: 'Event',
            icon: <Icon name={'fa:bolt'} />,
            onClick: () => addTrigger('event')
          }
        ]
      }}
      trigger={['click']}
    >
      <div>
        <Button type="primary" size="small" iconName={'fa:plus'}>
          Add Trigger
        </Button>
      </div>
    </Dropdown>
  )
}

function TriggerList() {
  const triggers = useAtomSelect(bpaConfigAtom, 'triggers') || []

  return (
    <div className="flex flex-col [&>div]:p-10">
      {triggers.length === 0 ? (
        <div className="text-center text-text-muted py-16">
          <Icon name="fa:circle-info" className="mr-2" /> No Triggers
        </div>
      ) : (
        triggers.map((trigger, index) => <TriggerItem key={trigger.uuid} trigger={trigger} index={index} />)
      )}
    </div>
  )
}

function TriggerItem({ trigger, index }: { trigger: BpaTrigger; index: number }) {
  const setBpaConfig = useSetAtom(bpaConfigAtom)
  const setTrigger = useCallback(
    <K extends keyof BpaTrigger>(key: K, value: BpaTrigger[K]) => {
      setBpaConfig((config) => {
        set(config, `triggers.${index}.${key}`, value)
      })
    },
    [index, setBpaConfig]
  )

  const Component = TRIGGER_COMPONENT_MAP[trigger.type]
  if (!Component) return <div>Invalid Trigger Type</div>

  return (
    <div className={cn('flex flex-row gap-10 items-center', { 'border-t': index > 0 })}>
      <div className={'w-[75px]'}>
        <Form.Item className={'m-0'} layout={'vertical'} label={trigger.name || startCase(trigger.type)}>
          <Switch checked={trigger.enabled} onChange={(checked) => setTrigger('enabled', checked)} />
        </Form.Item>
      </div>

      <div className={'flex-grow'}>
        <Component
          key={trigger.uuid}
          trigger={trigger}
          config={trigger.config as any}
          setConfig={(key: any, value: any) => setTrigger(`config.${key}` as any, value)}
          index={index}
        />
      </div>

      <div>
        <RemoveTrigger trigger={trigger} index={index} />
      </div>
    </div>
  )
}

function RemoveTrigger({ index }: { trigger: BpaTrigger; index: number }) {
  const setBpaConfig = useSetAtom(bpaConfigAtom)
  const removeTrigger = useCallback(() => {
    setBpaConfig((config) => {
      config.triggers.splice(index, 1)
    })
  }, [index, setBpaConfig])

  return (
    <Popconfirm title="Confirm Delete" description="Deleting a trigger. Are you sure?" onConfirm={removeTrigger}>
      <Button type="text" iconName="fa:trash" size="small" danger={true} />
    </Popconfirm>
  )
}

const TRIGGER_COMPONENT_MAP = {
  schedule: ScheduleTrigger,
  event: EventTrigger
}
