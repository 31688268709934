import { PropertySelect } from '@/components'
import { useUserModulesQuery } from '@/modules/module/hooks'
import { Select, SelectProps } from '@/ui/select'
import { ScriptedButtonConfig } from '../../types'
import { SchedulerAssignerButton } from './SchedulerAssignerButton'

export const SchedulerAssignerButtonConfig: ScriptedButtonConfig = {
  type: 'common_scheduler_assigner_button',
  iconName: 'fa:calendar',
  label: 'Scheduler Assigner',
  group: 'Common',
  render: () => <SchedulerAssignerButton />,
  varsClassName: 'grid grid-cols-3 gap-16',
  vars: [
    {
      name: 'scheduler_module_id',
      label: 'Scheduler Module',
      rules: [{ required: true }],
      render: (_, props) => <SelectSchedulerModule allowClear={true} {...props} />
    },
    {
      name: 'resource_type',
      label: 'Resource Type',
      rules: [{ required: true }],
      render: (_, props) => (
        <Select
          allowClear={true}
          options={[
            { label: 'Employee', value: 'employees' },
            { label: 'Equipment', value: 'equipment' }
          ]}
          {...props}
        />
      )
    },
    {
      name: 'start_date_pid',
      label: 'Start Date/Time',
      rules: [{ required: true }],
      render: (customForm: any, props?: any) => <PropertySelect formId={customForm?.id} {...props} />
    },
    {
      name: 'district_pid',
      label: 'Filter by District',
      render: (customForm: any, props?: any) => <PropertySelect formId={customForm?.id} {...props} />
    },
    {
      name: 'target',
      label: 'Open In (Target)',
      render: (_, props) => (
        <Select
          allowClear={true}
          options={[
            // { label: 'Self Tab', value: '_self' }, // not supported on job-manager
            { label: 'Current Tab', value: '_top' },
            { label: 'New Tab', value: '_blank' }
          ]}
          {...props}
        />
      )
    },
    ...[
      ['attributes_pid', 'Skills and Attributes (Override)'],
      ['requirements_pid', 'Requirements (Override)'],
      ['estimated_hours_pid', 'Estimated Hours (Override)'],
      ['line_3_pid', 'Description (Override)']
    ].map(([name, label]) => ({
      name: name,
      label: label,
      render: (customForm: any, props?: any) => (
        <PropertySelect formId={customForm?.id} {...props} placeholder={'Default From Module'} />
      )
    }))
  ]
}

function SelectSchedulerModule(props: SelectProps) {
  const query = useUserModulesQuery()
  const modules = query.data?.items

  return (
    <Select
      options={modules
        ?.filter((m) => m.is_active && m.view_type === 'SD')
        .map((m) => ({ value: m.id, label: m.title }))}
      {...props}
    />
  )
}
