import { useApp, useSession } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { contactApi, userApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation } from '@tanstack/react-query'
import { Form, Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { formTypeAtom, sharedDataAtom, tabDisabledAtom } from '../atom'
import RecentActivity from '../autofill/recentActivity/RecentActivity'
import { useFetchProfile, useSetSharedData } from '../hooks'
import { ProfileForm } from './form-profile'

export const ProfileTab = () => {
  const [profileForm] = Form.useForm()
  const formValues = Form.useWatch([], profileForm)
  const { notification } = useApp()
  const { user, company } = useSession()
  const formType = useAtomValue(formTypeAtom)
  const sharedData = useAtomValue(sharedDataAtom)
  const setSharedDataAtom = useSetAtom(sharedDataAtom)
  const setTabDisabled = useSetAtom(tabDisabledAtom)
  const params = useParams()

  const { data: profileData, isFetching, refetch } = useFetchProfile()
  const setSharedDataHook = useSetSharedData()
  const uploadPhotoMutation = useMutation({
    ...contactApi.patch(),
    onSuccess: async () => {
      await refetch()
    },
    onError: (error: Error) => {
      console.log(error)
      notification.error({ message: 'Error uploading photo' })
    }
  })
  const handleImageUpload = async (image: File, contactId: string) => {
    const formData = new FormData()
    if (contactId) {
      const contact_id = contactId
      formData.append('id', contact_id as any)
    }
    formData.append('image', image)
    return await uploadPhotoMutation.mutateAsync(formData)
  }
  const mutationSpec = formType === 'add' ? userApi.create() : userApi.update()
  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async (data) => {
      if (typeof formValues?.profile !== 'string' && formValues?.profile) {
        handleImageUpload(formValues.profile?.originFileObj || formValues.profile, data?.contact_id)
      }
      if (data) {
        notification.success({ message: `User ${formType === 'add' ? 'Added' : 'Updated'}` })
        navigate(`/profiles/${data.profile_id}/edit`)
        profileForm.setFieldsValue({ new_password: '', confirm_password: '', is_active: data?.is_active })
        await refetch()
      }

      // setTabDisabled(false)
    },
    onError: (error: Error | any) => {
      if (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors.length > 0) {
        notification.error({ message: error?.response?.data?.non_field_errors[0] })
      } else {
        notification.error({ message: `User failed to ${formType === 'add' ? 'Add' : 'Update'}` })
      }
    }
  })

  const handleFormSubmit = async () => {
    const values = await profileForm.validateFields()
    if (Object.keys(values).length > 0) {
      let data: any = {
        ...sharedData,
        id: profileData?.user?.pk,
        profile_id: Number(params?.id),
        contact_id: profileData?.contact?.id,
        role_id: profileData?.role,
        first_name: values?.first_name,
        last_name: values?.last_name,
        title: values?.title,
        phone_number: values.phone_number ? `+1${values?.phone_number?.replace(/\D/g, '')}` : values?.phone_number,
        new_email: values?.new_email,
        is_active: values?.is_active,
        timezone: values?.timezone,
        username: values?.username,
        userdata_json: profileData ? profileData?.userdata_json : sharedData.userdata_json
      }
      if (formValues?.new_password) {
        const oldData = data
        data = { ...oldData, new_password: values?.new_password }
      }
      setTabDisabled(true)
      saveFormMutation.mutateAsync(data)
    } else {
      setTabDisabled(true)
      notification.error({ message: 'Please fill all the required fields' })
    }

    return
  }

  const handleCancelClick = () => {
    navigate('/profiles')
  }

  useEffect(() => {
    if (profileData) {
      const data = {
        first_name: profileData?.user?.first_name,
        last_name: profileData?.user?.last_name,
        title: profileData?.contact?.title,
        external_id: profileData?.contact?.external_id,
        phone_number: profileData?.contact?.phone_number
          ? profileData?.contact?.phone_number?.replace('+1', '')
          : profileData?.contact?.phone_number,
        new_email: profileData?.contact?.email,
        username: profileData?.user?.username,
        is_active: profileData?.user?.is_active,
        timezone: profileData.timezone,
        profile: profileData?.contact?.image,
        company_id: { label: profileData.company__name, value: profileData.company }
      }
      profileForm.setFieldsValue(data)
      const newData = {
        ...sharedData,
        ...data,
        profile_id: Number(params?.id),
        company_id: profileData.company,
        role_id: profileData?.role,
        userdata_json: profileData?.userdata_json
      }

      setSharedDataAtom(newData)
      setSharedDataHook(newData)
    } else if (!user?.is_superuser) {
      profileForm.setFieldValue('company_id', { label: company?.name, value: company?.id })
    }
  }, [profileData, params])

  useEffect(() => {
    if (formValues) {
      const { new_password, confirm_password, company_id, ...rest } = formValues
      setSharedDataAtom({ ...sharedData, company_id: company_id?.value, ...rest })
    }
  }, [formValues])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Spin spinning={isFetching || saveFormMutation.isLoading}>
      <div className="flex items-center justify-between bg-white">
        <h4>Profile</h4>
        <div className="flex gap-8">
          <Button type="default" className="group hover:!text-gray-500 hover:opacity-75" onClick={handleCancelClick}>
            <Icon name="svg:cancel" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" /> Cancel
          </Button>
          <Button
            type="success"
            onClick={handleFormSubmit}
            disabled={saveFormMutation.isLoading}
            loading={saveFormMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            {formType === 'add' ? 'Save' : 'Update'}
          </Button>
        </div>
      </div>

      <ProfileForm form={profileForm} />

      <RecentActivity />
    </Spin>
  )
}
