import { recordBy } from '@/utils/record-by'
import {
  BpaConditionOperator,
  BpaConfig,
  BpaConfigMode,
  BpaScheduleFilterField,
  BpaScheduleFilterValue1,
  BpaScheduleRule,
  BpaVariableType
} from '../schemas'

export const BPA_MODES_LIST: {
  value: BpaConfigMode
  label: string
}[] = [
  // { value: 'graph', label: 'Graph (v3)' }, // todo: future feature
  { value: 'sync', label: 'Sync' },
  { value: 'pipeline', label: 'Pipeline' },
  { value: 'advanced', label: 'Advanced (Old)' },
  { value: 'simple', label: 'Simple (Old)' }
]

export const BPA_VARIABLE_TYPES_LIST: {
  value: BpaVariableType
  label: string
}[] = [
  { value: 'str', label: 'STR' },
  { value: 'int', label: 'INT' },
  { value: 'float', label: 'FLOAT' },
  { value: 'yaml', label: 'YAML' },
  { value: 'json', label: 'JSON' }
]

export const BPA_CONFIG_TEMPLATE: BpaConfig = {
  mode: 'sync',
  enableBpa: false,
  scheduled: false,
  schedule_rules: null,
  triggers: [],
  variables: [],
  conditions: [],
  rules: []
}

export const BPA_DEFAULT_SCHEDULE_RULE: BpaScheduleRule = {
  scheduled_for: 'automation',
  cron_spec: '0 0 * * *',
  timezone: 'America/Denver',
  run_as: null,
  function_config: null,
  filter_criteria: {
    field: 'created_at',
    exclude: false,
    value1: 'month-to-date',
    value2: 0
  }
}

export const BPA_SCHEDULE_FILTER_FIELD: {
  value: BpaScheduleFilterField
  label: string
}[] = [
  { value: 'timepoint_submitted', label: 'Submitted Date' },
  { value: 'timepoint_due', label: 'Start Date' },
  { value: 'created_at', label: 'Created Date' },
  { value: 'modified_at', label: 'Modified Date' },
  { value: 'user_data__user_datetime_1', label: 'Ticket Date 1' },
  { value: 'user_data__user_datetime_2', label: 'Ticket Date 2' },
  { value: 'user_data__user_datetime_3', label: 'Ticket Date 3' },
  { value: 'user_data__user_datetime_4', label: 'Ticket Date 4' }
]

export const BPA_SCHEDULE_FILTER_VALUE: {
  value: BpaScheduleFilterValue1
  label: string
}[] = [
  { value: 'last-week', label: 'Last Week' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'month-to-date', label: 'Month to Date' },
  { value: 'past-months', label: 'Past Months' },
  { value: 'past-days', label: 'Past Days' },
  { value: 'past-hours', label: 'Past Hours' },
  { value: 'past-minutes', label: 'Past Minutes' }
]

export const BPA_OPERATORS_LIST: {
  value: BpaConditionOperator
  label: string
  showInput: boolean
}[] = [
  { value: 'equals', label: 'Equals', showInput: true },
  { value: 'not_equals', label: 'Not Equals', showInput: true },
  { value: 'contains', label: 'Contains', showInput: true },
  { value: 'is_true', label: 'Is True', showInput: false },
  { value: 'is_false', label: 'Is False', showInput: false },
  { value: 'empty', label: 'Empty', showInput: false },
  { value: 'not_empty', label: 'Not Empty', showInput: false },
  { value: 'gt', label: 'Greater Than', showInput: true },
  { value: 'gte', label: 'Greater Than or Equals', showInput: true },
  { value: 'lt', label: 'Less Than', showInput: true },
  { value: 'lte', label: 'Less Than or Equals', showInput: true },
  { value: 're', label: 'Regex', showInput: true },
  // { value: 'is_changed', label: 'Is Changed', showInput: false }, // todo: implement backend
  { value: 'is_bool', label: 'Is Bool', showInput: false },
  { value: 'is_int', label: 'Is Int', showInput: false },
  { value: 'is_float', label: 'Is Float', showInput: false },
  { value: 'is_str', label: 'Is Str', showInput: false },
  { value: 'is_list', label: 'Is List', showInput: false },
  { value: 'is_map', label: 'Is Map', showInput: false }
]

export const BPA_OPERATORS_MAP = recordBy(BPA_OPERATORS_LIST, 'value')
