import { Checkbox } from '@/ui/checkbox'
import { TableProps } from 'antd'
import { ColumnType } from 'antd/es/table'
import { Key, RowSelectMethod, SorterResult } from 'antd/es/table/interface'
import cn from 'classnames'
import { CSSProperties, ReactNode, useCallback, useState } from 'react'
import { LazyDivProps } from '../../utils'

export type TableLiteColumnProps<RecordType = any> = Pick<
  ColumnType<RecordType>,
  | 'key'
  | 'dataIndex'
  | 'title'
  | 'width'
  | 'minWidth'
  | 'className'
  | 'ellipsis'
  | 'sorter'
  | 'sortIcon'
  // unused: only for compatibility with antd TableProps in QueryTable
  | 'fixed'
  | 'showSorterTooltip'
  | 'sortDirections'
> & {
  sticky?: boolean
  lazyRender?: boolean | LazyDivProps
  render?: (value: any, record: RecordType, rowIndex: number) => ReactNode
}

export type TableLiteProps<RecordType = any> = Pick<
  TableProps<RecordType>,
  | 'dataSource'
  | 'rootClassName'
  | 'className'
  | 'rowHoverable' // todo: implement
  | 'children'
  | 'bordered'
  | 'onChange'
  // unused: only for compatibility with antd TableProps in QueryTable
  | 'scroll'
  | 'loading'
  | 'pagination'
> & {
  style?: CSSProperties
  rowKey: keyof RecordType
  columns?: TableLiteColumnProps<RecordType>[]
  rowSelection?: {
    /** Keep the selection keys in list even the key not exist in `dataSource` anymore */
    preserveSelectedRowKeys?: boolean
    selectedRowKeys?: Key[]
    hideSelectAll?: boolean
    onChange?: (
      selectedRowKeys: Key[],
      // WARNING: selectedRows will include only rows that exists in current dataSource(current page)
      // WHEN preserveSelectedRowKeys is set to true
      selectedRows: RecordType[],
      info: {
        type: RowSelectMethod
      }
    ) => void
  }
}

export function TableLite({
  rootClassName,
  className,
  style,
  rowKey,
  columns,
  rowHoverable,
  dataSource,
  bordered,
  onChange,
  rowSelection
}: TableLiteProps) {
  const [sorter, setSorter] = useState<SorterResult | null>(null)

  const handleSorterChange = useCallback(
    (sorter: SorterResult) => {
      setSorter(sorter)
      onChange?.({}, {}, sorter, { currentDataSource: [], action: 'sort' })
    },
    [onChange]
  )

  const _onRowSelect = (rowId: string | number, toggled: boolean) => {
    let _selectedRowKeys = [...(rowSelection?.selectedRowKeys || []), rowId]
    if (!toggled) {
      _selectedRowKeys = rowSelection?.selectedRowKeys?.filter((key) => key !== rowId) || []
    }

    if (!rowSelection?.preserveSelectedRowKeys) {
      _selectedRowKeys = _selectedRowKeys.filter((rowId) => dataSource?.map((rec) => rec[rowKey]).includes(rowId))
    }

    rowSelection?.onChange?.(
      _selectedRowKeys,
      dataSource?.filter((row) => _selectedRowKeys.includes(row[rowKey])) || [],
      { type: 'single' }
    )
  }

  const _onSelectAll = (toggled: boolean) => {
    if (toggled) {
      let _selectedRowKeys = dataSource?.map((row) => row[rowKey]) || []
      if (rowSelection?.preserveSelectedRowKeys) {
        _selectedRowKeys = [
          ...(rowSelection.selectedRowKeys || []),
          // include all keys from dataSource(current page), which ARE NOT already in
          ..._selectedRowKeys.filter((key) => !(rowSelection?.selectedRowKeys || []).includes(key))
        ]
      }

      rowSelection?.onChange?.(_selectedRowKeys, (dataSource || []) as any[], {
        type: 'all'
      })

      return
    }

    let _selectedRowKeys: any[] = []
    if (rowSelection?.preserveSelectedRowKeys) {
      // Keep keys that are not on this dataSource(current page)
      _selectedRowKeys = (rowSelection?.selectedRowKeys || [])?.filter(
        (key) => !dataSource?.find((row) => row[rowKey] === key)
      )
    }

    rowSelection?.onChange?.(_selectedRowKeys, [], { type: 'none' })
  }

  return (
    <div className={cn(rootClassName, 'w-full h-full')} style={style}>
      <table className={cn('w-full table-fixed border-separate border-spacing-0', className)}>
        <thead className={'sticky top-0 z-20 bg-background-accent'}>
          <tr>
            {rowSelection?.selectedRowKeys ? (
              <th className="ant-table-cell ant-table-selection-column w-[50px]">
                <div className="w-[50px]">
                  {!rowSelection?.hideSelectAll ? (
                    <Checkbox
                      checked={rowSelection.selectedRowKeys?.length === dataSource?.length}
                      onChange={(e) => _onSelectAll(e.target.checked)}
                    />
                  ) : null}
                </div>
              </th>
            ) : null}
            {columns?.map((column, index) => (
              <TableColumn
                key={column.key}
                column={column}
                bordered={bordered}
                sorter={column.sorter ? sorter : null}
                onSorterChange={column.sorter ? handleSorterChange : null}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource?.map((row, rowIdx) => (
            <tr
              key={row[rowKey]}
              className={cn({
                'duration-200 transition-colors bg-background hover:bg-background-accent': rowHoverable
              })}
            >
              {rowSelection?.selectedRowKeys ? (
                <td className="p-0 border-b border-border-secondary">
                  <div className="flex justify-center items-center h-full">
                    <Checkbox
                      checked={rowSelection.selectedRowKeys.includes(row[rowKey])}
                      onChange={(e) => _onRowSelect(row[rowKey], e.target.checked)}
                    />
                  </div>
                </td>
              ) : null}
              {columns?.map((column, colIdx) => (
                <td
                  key={column.key}
                  className={cn(
                    'p-0 border-b border-border-secondary',
                    {
                      'border-r last:border-r-0': bordered,
                      'sticky bg-background left-0 z-10': column.sticky,
                      'overflow-ellipsis': column.ellipsis
                    },
                    column.className
                  )}
                  style={{ width: column.width, minWidth: column.minWidth }}
                >
                  {column.render?.(row[column.dataIndex], row, rowIdx)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function TableColumn({
  column,
  bordered,
  sorter,
  onSorterChange
}: {
  column: TableLiteColumnProps
  bordered: TableLiteProps['bordered']
  sorter: SorterResult | null
  onSorterChange: ((sorter: SorterResult) => void) | null
}) {
  const sortOrder = sorter?.columnKey === column.key ? sorter?.order || null : null

  return (
    <th
      style={{ width: column.width, minWidth: column.minWidth }}
      className={cn(
        'transition-colors duration-200 p-0 border-b border-border-secondary font-medium z-20 top-0',
        {
          'border-r last:border-r-0': bordered,
          'sticky bg-background-accent left-0 z-20': column.sticky,
          'hover:dark:bg-gray-700 hover:bg-gray-100 cursor-pointer': column.sorter
        },
        column.className
      )}
      onClick={() =>
        onSorterChange?.({
          column: column,
          order: sortOrder === 'ascend' ? 'descend' : 'ascend',
          field: column.dataIndex || column.key,
          columnKey: column.key || column.dataIndex
        })
      }
    >
      <div className={'flex flex-row items-center'}>
        <div
          className={cn('flex-grow p-4', {
            'overflow-ellipsis line-clamp-1': column.ellipsis
          })}
        >
          {typeof column.title === 'function' ? column.title({}) : column.title}
        </div>
        {column.sorter && (
          <div className={'px-8'}>{typeof column.sortIcon === 'function' ? column.sortIcon({ sortOrder }) : ''}</div>
        )}
      </div>
    </th>
  )
}
