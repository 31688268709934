import { TICKET_STATUS } from '@/constants/general'
import { Select } from '@/ui'
import { Form, Input } from 'antd'

export const CallbackSection = () => {
  return (
    <div>
      <h4 className="font-bold">Callback</h4>
      <Form.Item label="Return success ticket status" name="return_success_ticket_status">
        <Select options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))} />
      </Form.Item>
      <Form.Item label="Return success note" name="return_success_notes">
        <Input />
      </Form.Item>
    </div>
  )
}
