import { CATEGORY_KIND_FIELDS_ENUM } from '@/constants/general'
import { useApp } from '@/hooks'
import { CategoryKindField } from '@/types/general'
import { getLineItemName } from '@/utils/get-line-item-name'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Checkbox, Space, Table } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom } from '../atoms'
import { useCategoryKindQuery } from '../hooks'
import { AddComponentsModal } from './add-components-modal'
import { SortableRow } from './sortable-row'
import { SubComponent } from './sub-component'
import { Component } from './types'

export const SubComponents = () => {
  const components = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.components), []))
  const displayOnTicket = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.show_components), []))
  const { categoryKindQuery } = useCategoryKindQuery(components?.[0]?.sub_component?.category?.kind)
  const setEquipment = useSetAtom(equipmentAtom)
  const isFinishedGood = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.is_finished_good), []))

  const componentsOrder = JSON.parse(
    useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.components_order_json), [])) ?? '[]'
  ) as number[]

  const { labels } = useApp()
  const { hidden_fields, field_aliases, fields } = categoryKindQuery.data ?? {}

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = componentsOrder.indexOf(active.id as number)
      const overIndex = componentsOrder.indexOf(over?.id as number)
      const newComponentsOrder = arrayMove(componentsOrder, activeIndex, overIndex)

      setEquipment((prev) => ({
        ...prev,
        components_order_json: JSON.stringify(newComponentsOrder)
      }))
    }
  }

  const dataSource = useMemo(() => {
    if (!components || components.length === 0) {
      return []
    }

    return componentsOrder.reduce(
      (acc, id) => {
        const component = components.find((c) => c.sub_component?.id === id)

        if (!component) {
          console.warn(`Component with id ${id} not found in components array`)
          return acc
        }

        return [
          ...acc,
          {
            ...component,
            key: component.sub_component?.id
          }
        ]
      },
      [] as (Component & { key: number })[]
    )
  }, [components, componentsOrder])

  if (!isFinishedGood) {
    return null
  }

  return (
    <div className="mt-30">
      <div className="font-bold">Sub Components</div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext items={dataSource.map((d) => d.key)} strategy={verticalListSortingStrategy}>
          <Table
            className="mb-12 [&_.ant-table-placeholder]:!z-0"
            scroll={{
              x: 'max-content'
            }}
            pagination={false}
            dataSource={dataSource}
            rowKey="key"
            components={{
              body: {
                row: SortableRow
              }
            }}
          >
            {fields?.map((field) => {
              const { name } = getLineItemName({
                field,
                labels,
                fieldAliases: field_aliases ?? {}
              })

              if (hidden_fields?.includes(field)) {
                return null
              }

              return (
                <Table.Column
                  key={field}
                  title={name}
                  render={(_, component: Component) => {
                    // Only sku and description fields are not editable
                    if (field === CATEGORY_KIND_FIELDS_ENUM.sku || field === CATEGORY_KIND_FIELDS_ENUM.description) {
                      return component.data ? component.data[field] : null
                    }

                    return <SubComponent component={component} field={field as CategoryKindField} />
                  }}
                />
              )
            })}
            <Table.Column key="sort" />
          </Table>
          <Space>
            <AddComponentsModal />
            <Checkbox
              checked={!!displayOnTicket}
              onChange={(e) => {
                setEquipment((prev) => ({
                  ...prev,
                  show_components: e.target.checked
                }))
              }}
            >
              Display on ticket
            </Checkbox>
          </Space>
        </SortableContext>
      </DndContext>
    </div>
  )
}
