import { Tooltip as AntTooltip, TooltipProps } from 'antd'
import React from 'react'

type Props = TooltipProps & {
  contentClassNames?: string
}

/**
 * Wrapper for Antd Tooltip component.
 *
 * Fixes the following issues:
 * - Warning: findDOMNode is deprecated in StrictMode
 * - See: https://stackoverflow.com/questions/70684982/adding-tooltip-to-input-causes-finddomnode-is-deprecated-in-strictmode-error
 */
export const Tooltip: React.FC<Props> = ({ children, contentClassNames, ...rest }) => {
  return (
    <AntTooltip {...rest}>
      <div className={contentClassNames}>{children}</div>
    </AntTooltip>
  )
}
