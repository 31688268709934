import { ROLODEX_PATHS } from './constants'
import { AddCompanyView } from './views/add-company-view'
import { CompaniesView } from './views/companies-view'
import { ContactsView } from './views/contacts-view'
import { PrivateCompaniesView } from './views/private-companies-view'

export const ROLODEX_ROUTES = [
  {
    path: ROLODEX_PATHS.contacts,
    element: <ContactsView />
  },
  {
    path: ROLODEX_PATHS.companies,
    element: <CompaniesView />
  },
  {
    path: ROLODEX_PATHS.addCompany,
    element: <AddCompanyView />
  },
  {
    path: ROLODEX_PATHS.privateCompanies,
    element: <PrivateCompaniesView />
  },
  {
    path: ROLODEX_PATHS.addPrivateCompany,
    element: <AddCompanyView isPrivate />
  }
  // {
  //   path: ROLODEX_PATHS.office({ companyId: ':companyId', officeId: ':officeId', isPrivate: false }),
  //   element: <OfficeView />
  // },
  // {
  //   path: ROLODEX_PATHS.office({ companyId: ':companyId', officeId: ':officeId', isPrivate: true }),
  //   element: <OfficeView />
  // }
]
