import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useSetAtom } from 'jotai'
import { lineItemFieldsAtom } from '../../atoms'

export const MoveAllItems = () => {
  const setLineItemFields = useSetAtom(lineItemFieldsAtom)

  const handleMoveAllItems = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setLineItemFields((prev) => ({
        ...prev,
        available: [...prev.available, ...prev.selected],
        selected: []
      }))
    }

    if (direction === 'right') {
      setLineItemFields((prev) => ({
        ...prev,
        available: [],
        selected: [...prev.available, ...prev.selected]
      }))
    }
  }

  return (
    <div className="flex flex-col gap-y-8 justify-center">
      <Button icon={<Icon name="mi:arrow_forward" />} type="primary" onClick={() => handleMoveAllItems('right')} />
      <Button icon={<Icon name="mi:arrow_back" />} type="primary" onClick={() => handleMoveAllItems('left')} />
    </div>
  )
}
