import { CalendarTable, CalendarViewSelect, DistrictSelect } from '@/components'
import { ISO_DATE_FORMAT } from '@/constants/date'
import {
  isAvailableOnlyAtom,
  selectedJobRoleAtom,
  selectedSkillsAtom,
  skillMatchTypeAtom
} from '@/modules/jas/job-manager/add-resource/atoms'
import { CalendarTitleHeadCell } from '@/modules/jas/job-manager/add-resource/components/calendar/CalenderTitleHeadCell'
import { ResourceCalendarDayCellMemo } from '@/modules/jas/job-manager/add-resource/components/calendar/ResourceCalendarDayCell'
import { ResourceCalenderTitleCell } from '@/modules/jas/job-manager/add-resource/components/calendar/ResourceCalenderTitleCell'
import { isScheduleAvailable } from '@/modules/jas/job-manager/add-resource/helpers'
import { useAddResource } from '@/modules/jas/job-manager/add-resource/hooks'
import { useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { EmployeeSearch } from '@/modules/jas/scheduler/employees/employee-search'
import { EquipmentSearch } from '@/modules/jas/scheduler/equipments/equipment-search'
import { useMultipleScheduleView } from '@/modules/jas/scheduler/hooks'
import { ListParams } from '@/types/api/core'
import { SCHEDULE_TYPES } from '@/utils'
import { Checkbox } from 'antd'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import React, { useCallback, useEffect, useMemo } from 'react'

export const ResourceCalendar: React.FC = () => {
  const skillMatchType = useAtomValue(skillMatchTypeAtom)
  const selectedSkills = useAtomValue(selectedSkillsAtom)
  const [selectedJobRole, setSelectedJobRole] = useAtom(selectedJobRoleAtom)
  const [isAvailableOnly, setIsAvailableOnly] = useAtom(isAvailableOnlyAtom)

  const { type, typeLabel } = useAddResource()
  const { selectedJobData: jobData } = useSelectedJobTicket()

  // darken dates that are not in job date range
  const highlightDates: Record<string, boolean> = useMemo(() => {
    if (jobData?.startDate && jobData?.endDate) {
      // default date is today if missing
      let startDate = dayjs(jobData?.startDate)
      if (!startDate.isValid()) startDate = dayjs()
      let endDate = dayjs(jobData?.endDate)
      if (!endDate.isValid()) endDate = dayjs()

      startDate = startDate.startOf('day')
      endDate = endDate.endOf('day')

      const dates: Record<string, boolean> = {}
      let date = startDate
      while (date.isBefore(endDate) || date.isSame(endDate)) {
        dates[date.format(ISO_DATE_FORMAT)] = true
        date = date.add(1, 'day')
      }
      return dates
    }

    return {}
  }, [jobData?.endDate, jobData?.startDate])

  // resources query params
  const params: ListParams = {
    type__iexact: type as string,
    E: []
  }

  // note: requested to remove this filter
  // // exclude already added resources
  // const originalResources = useAtomValue(originalResourcesAtom)
  // const originalResourcesIds = Object.keys(originalResources)
  // if (originalResourcesIds.length > 0) {
  //   const ids = originalResourcesIds.join(',')
  //   params.E?.push(`id__in|${ids}`)
  // }

  // filter by skills
  if (skillMatchType === 'all') {
    params.attributes__has_keys = selectedSkills.join(',')
  } else {
    params.attributes__has_any_keys = selectedSkills.join(',')
  }

  // filter by job roles
  if (selectedJobRole) {
    params.job_roles__in = selectedJobRole.length ? selectedJobRole.join(',') : undefined
    params.distinct = '1'
  }

  // calender state (query, data, resources)
  const { query, state, dispatch } = useMultipleScheduleView(params, {
    // Important! using cache data causes same thread execution causing lag in UI transitions when cache hits
    cacheTime: 0,
    staleTime: 0
  })
  const { queryOrder, date, searchText, selectedDistricts } = state
  const calendarView = useAtomValue(calendarViewAtom)

  // process resources
  const resources = useMemo(() => {
    const items = query.data?.pages?.flat() || []

    // filter by available only
    if (isAvailableOnly) {
      const jobDates = Object.keys(highlightDates)
      return items.filter((item: any) => jobDates.some((d) => isScheduleAvailable(item.schedules[d])))
    }

    return items
  }, [highlightDates, isAvailableOnly, query.data?.pages])

  // resource search
  const handleSearchTextChange = useCallback(
    (value: string) => {
      dispatch({ type: 'setSearchText', payload: value })
    },
    [dispatch]
  )

  // set job start date as default date
  useEffect(() => {
    let start = dayjs(jobData?.startDate)
    if (!start.isValid()) start = dayjs()
    dispatch({ type: 'setDate', payload: dayjs(start) })
  }, [dispatch, jobData?.startDate])

  const scheduleLabels = useMemo(() => {
    return type === 'employee'
      ? Object.values(SCHEDULE_TYPES)
      : Object.values(SCHEDULE_TYPES).filter((type) => type.title !== 'Off day')
  }, [type])

  return (
    <div className="h-[calc(100vh-96px)] relative">
      <div className="flex gap-x-12 items-center mb-12">
        {type === 'employee' && <EmployeeSearch searchText={searchText} onChange={handleSearchTextChange} />}
        {type === 'equipment' && <EquipmentSearch searchText={searchText} onChange={handleSearchTextChange} />}
        <DistrictSelect
          value={selectedDistricts}
          onChange={(value) => dispatch({ type: 'setSelectedDistricts', payload: value })}
        />
        <div className="flex items-center align-middle">
          <Checkbox
            className={'checkbox-lg'}
            checked={isAvailableOnly}
            onChange={() => setIsAvailableOnly(!isAvailableOnly)}
          >
            Available Only
          </Checkbox>
        </div>
        <div className="flex ml-auto">
          <CalendarViewSelect />
        </div>
      </div>

      <CalendarTable
        tableClassName={'!min-h-[calc(100vh-445px)] !max-h-[calc(100vh-445px)]'}
        autoScroll={true}
        withFullHeight={false}
        scheduleLabels={scheduleLabels}
        title={
          <CalendarTitleHeadCell
            order={queryOrder === 'name' ? 'asc' : 'desc'}
            label={`${typeLabel} Name`}
            onClick={() => dispatch({ type: 'toggleQueryOrder' })}
          />
        }
        withFooter
        visibleDays={calendarView}
        data={resources}
        date={date}
        loading={query.isLoading || query.isFetchingNextPage || query.isRefetching}
        onDateChange={(date) => dispatch({ type: 'setDate', payload: date })}
        onLoadMore={() => {
          if (query.hasNextPage) query.fetchNextPage()
        }}
        renderTitleCell={(res) => <ResourceCalenderTitleCell resource={res} />}
        renderDateCell={(date, resource) => (
          <ResourceCalendarDayCellMemo
            name={resource.name}
            schedule={resource.schedules[date.format('YYYY-MM-DD')]}
            darken={!highlightDates[date.format('YYYY-MM-DD')]}
            isShortForm={calendarView === 'month'}
            date={date}
          />
        )}
      />
    </div>
  )
}
