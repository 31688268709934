import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Dayjs } from 'dayjs'
import { getDateRangeByView } from '../helpers'
import { CalendarView } from '../types'
import { EmployeeScheduleType, FilterOps } from './types'

export const getHiddenRowsNumbersByScheduleType = (
  employees: JasResourceUseQueryResponse[],
  selectedScheduleType: EmployeeScheduleType,
  date: Dayjs,
  calendarView: CalendarView,
  filterLogic: FilterOps
): Set<number> => {
  const hiddenRows = new Set<number>()
  const dates = getDateRangeByView(calendarView, date).map((d) => d.format('YYYY-MM-DD'))

  employees.forEach(({ schedules }, i) => {
    const checkEmployee = (dateStr: string) => schedules[dateStr]?.type === selectedScheduleType
    const isStatusMatch = filterLogic === 'all' ? dates.every(checkEmployee) : dates.some(checkEmployee)

    if (!isStatusMatch) {
      hiddenRows.add(i)
    }
  })

  return hiddenRows
}
