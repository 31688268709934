import { DragOverEvent } from '@dnd-kit/core'
import { useAtomValue, useSetAtom } from 'jotai'
import { lineItemFieldsAtom } from '../../atoms'
import { ContainerKey, LineItemField } from '../../types'
import { useFindContainer } from './use-find-container'

export const useHandleDragOver = () => {
  const { findContainer } = useFindContainer()
  const lineItemFields = useAtomValue(lineItemFieldsAtom)
  const setLineItemFields = useSetAtom(lineItemFieldsAtom)

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    const overId = over?.id

    if (overId == null || active.id in lineItemFields) {
      return
    }

    const overContainer = findContainer(overId as ContainerKey | LineItemField)
    const activeContainer = findContainer(active.id as ContainerKey | LineItemField)

    if (!overContainer || !activeContainer) {
      return
    }

    if (activeContainer !== overContainer) {
      setLineItemFields((items) => {
        const activeItems = lineItemFields[activeContainer]
        const overItems = lineItemFields[overContainer]

        const overIndex = overItems.indexOf(overId as LineItemField)
        const activeIndex = activeItems.indexOf(active.id as LineItemField)

        let newIndex: number

        if (overId in items) {
          newIndex = overItems.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height

          const modifier = isBelowOverItem ? 1 : 0

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }

        return {
          ...items,
          [activeContainer]: lineItemFields[activeContainer].filter((item) => item !== active.id),
          [overContainer]: [
            ...lineItemFields[overContainer].slice(0, newIndex),
            lineItemFields[activeContainer][activeIndex],
            ...lineItemFields[overContainer].slice(newIndex, lineItemFields[overContainer].length)
          ]
        }
      })
    }
  }

  return { handleDragOver }
}
