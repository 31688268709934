import { useApp, useSession } from '@/hooks'
import { categoryKindApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { configurationAtom, lineItemFieldsAtom } from '../atoms'
import { Configuration } from '../schema'

export const useUpsertConfiguration = () => {
  const { notification } = useApp()
  const { company } = useSession()

  const configuration = useAtomValue(configurationAtom)
  const lineItemFields = useAtomValue(lineItemFieldsAtom)

  const updateConfigurationMutation = useMutation(categoryKindApi.update())
  const createConfigurationMutation = useMutation(categoryKindApi.create())

  const saveMutation = configuration.id ? updateConfigurationMutation : createConfigurationMutation

  const upsertConfiguration = async (data?: Partial<Configuration>) => {
    // Backend expects a JSON of some fields
    const scriptsJson = configuration.scripts ? JSON.stringify(configuration.scripts, null, 2) : null
    const dataObjJson = configuration?.data ? JSON.stringify(configuration?.data, null, 2) : null
    const hide_print_json = configuration?.hide_print ? JSON.stringify(configuration?.hide_print, null, 2) : null
    const formats_json = configuration?.formats ? JSON.stringify(configuration?.formats, null, 2) : null

    const summary_scripts_json = configuration?.summary_scripts
      ? JSON.stringify(configuration?.summary_scripts, null, 2)
      : null

    const hidden_fields_json = configuration?.hidden_fields
      ? JSON.stringify(configuration?.hidden_fields, null, 2)
      : null

    const exclude_mobile_fields_json = configuration?.exclude_mobile_fields
      ? JSON.stringify(configuration?.exclude_mobile_fields, null, 2)
      : null

    const payload = {
      ...configuration,
      ...data,
      fields: lineItemFields.selected,
      scripts_json: scriptsJson,
      data_json: dataObjJson,
      exclude_mobile_fields_json,
      hidden_fields_json,
      hide_print_json,
      formats_json,
      summary_scripts_json,
      company: company.id
    }

    let redirect = undefined
    await saveMutation
      .mutateAsync(payload)
      .then((result) => {
        notification.success({
          message: 'Configuration Saved',
          description: 'The configuration has been saved successfully'
        })
        redirect = payload.id ? undefined : `../${result.id}/edit`
      })
      .catch(() => {
        notification.error({
          message: 'Failed',
          description: 'The configuration could not be saved'
        })
      })

    return redirect
  }

  return { updateConfigurationMutation, upsertConfiguration, createConfigurationMutation }
}
