import { PropertySelect } from '@/components'
import { Checkbox } from '@/ui'
import { Form } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { MsNavSubmitButton } from './MsNavSubmitButton'
import { VarsType } from './types'

export const MsNavSubmitButtonConfig: ScriptedButtonConfig<VarsType> = {
  type: 'common_ms_nav_submit_button',
  iconName: 'fa:webhook',
  label: 'MS Nav (Submit)',
  group: 'Common',
  render: () => <MsNavSubmitButton />,
  varsClassName: 'grid-cols-2',
  vars: [
    {
      name: 'msNavStatusPid',
      label: 'MS Nav Status Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'msNavMessagePid',
      label: 'MS Nav Message Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'msNavCustomerPid',
      label: 'MS Nav Customer Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'customerSignaturePid',
      label: 'Customer Signature Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'closedDatePid',
      label: 'Closed Date Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'serviceTypePid',
      label: 'Service Type Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'workFieldPid',
      label: 'Work Field Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'allowReSubmit',
      label: 'Allow Re-Submit',
      renderFormItem: (form, props) => (
        <Form.Item {...props} valuePropName={'checked'} label={' '}>
          <Checkbox>{props?.label}</Checkbox>
        </Form.Item>
      )
    }
  ]
}
