import { Select } from '@/ui'
import { Form, Input } from 'antd'
import { FC } from 'react'
import { BPA_CONTEXT_FIELDS, LINE_ITEM_FIELDS } from '../../constants'
import { BpaCondition } from '../../schemas'
import { PropertySelect, TicketFieldSelect } from '../controls'

type Props = {
  condition: BpaCondition
  className?: string
  onChange: (value: any) => void
}

export const FieldInput: FC<Props> = ({ condition, className, onChange }) => {
  let input
  let label

  if (condition.fieldType === 'ticket') {
    input = <TicketFieldSelect data-cy="condition-field-select" defaultValue={condition.field} onChange={onChange} />
    label = 'Ticket Field'
  } else if (condition.fieldType === 'lineItems') {
    input = (
      <Select
        data-cy="condition-field-select"
        defaultValue={condition.field}
        options={LINE_ITEM_FIELDS}
        onChange={onChange}
      />
    )
    label = (
      <span className="line-clamp-1">
        Line Item Field <small className="text-text-muted">(matches any item)</small>
      </span>
    )
  } else if (condition.fieldType === 'properties') {
    input = (
      <PropertySelect
        data-cy="condition-field-select"
        valueField={'key'}
        defaultValue={condition.field}
        onChange={onChange}
      />
    )
    label = 'Ticket Property'
  } else if (condition.fieldType === 'context') {
    input = (
      <Select
        data-cy="condition-field-select"
        defaultValue={condition.field}
        options={BPA_CONTEXT_FIELDS}
        onChange={onChange}
      />
    )
    label = 'Context Field'
  } else if (condition.fieldType === 'custom') {
    input = (
      <Input.TextArea
        data-cy="condition-field-textarea"
        autoSize={{ minRows: 1, maxRows: 10 }}
        defaultValue={condition.field || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
    label = 'Custom Field'
  } else {
    return <></>
  }

  return (
    <Form.Item label={label} className={className}>
      {input}
    </Form.Item>
  )
}
