import { useApp } from '@/hooks'
import { apiHttp } from '@/services/api-service/core'
import { ActionButton } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'

type Props = {
  id: number
  name: string
}

export const CopyCustomFormButton: FC<Props> = ({ id, name }) => {
  const queryClient = useQueryClient()
  const { notification } = useApp()

  // using apiHttp instead of apiService because apiService doesn't support POST method with id in url
  const cloneCustomFormMutation = useMutation({
    mutationFn: ({ id }: any) => apiHttp.post(`/custom_forms/${id}/clone`).then((res) => res.data)
  })

  const handleCloneCustomForm = async (id: number) => {
    await cloneCustomFormMutation.mutateAsync({ id })
    queryClient.invalidateQueries(['custom_forms'])

    notification.success({
      message: 'Custom form created'
    })
  }
  return (
    <ActionButton
      iconName={'fa:copy'}
      loading={cloneCustomFormMutation.isLoading}
      confirm={{
        title: 'Confirm Copy',
        description: `Copying ${name}?`,
        onConfirm: () => handleCloneCustomForm(id),
        placement: 'topRight',
        okText: 'Copy'
      }}
      data-cy="copy-custom-form-button"
    />
  )
}
