import { useApp } from '@/hooks'
import { locationApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { fetchLocationIdByName, processWell } from '../utils'

export const useSaveLocation = () => {
  const { notification } = useApp()
  const patchMutation = useMutation(locationApi.patch())
  const createMutation = useMutation(locationApi.create())

  const saveLocation = async (well: Awaited<ReturnType<typeof processWell>>) => {
    const locationData = {
      id: await fetchLocationIdByName(well.locationName),
      name: well.locationName,
      gps_latitude: well.latitude,
      gps_longitude: well.longitude,
      legal_description: `WSN_ID: ${well.id}, WSN_NAME: ${well.locationName}, FETCHED_AT: ${dayjs().formatLocal()}`,
      virtual_fields: {
        well_data: well,
        lease_number: well.apiNum,
        county: well.county,
        state: well.state,
        legal_location: well.wsnName
      }
    }

    try {
      if (locationData.id) {
        return await patchMutation.mutateAsync(locationData)
      } else {
        return await createMutation.mutateAsync(locationData)
      }
    } catch (e) {
      notification.warning({
        message: 'Save Location',
        description: 'Failed to save location with well data!'
      })
      throw e
    }
  }

  return { saveLocation }
}
