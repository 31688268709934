import { useApp } from '@/hooks'
import { programmedReportApi, reportBuilderApi, transformationApi } from '@/services/api-service'
import { AppLink, Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { FormInstance, Space } from 'antd'

type Props = {
  form: FormInstance<any>
}

export const ActionsButtons = ({ form }: Props) => {
  const updateProgrammedReportMutation = useMutation(programmedReportApi.update())
  const updateTransformationMutation = useMutation(transformationApi.update())
  const updateReportMutation = useMutation(reportBuilderApi.update())
  const { notification } = useApp()

  const handleUpdateProgrammedReport = async () => {
    const { company, base_report, transformation, report, ...values } = await form.validateFields()
    try {
      if (transformation) {
        transformation.name = report.name
        transformation.description = report.description
        await updateTransformationMutation.mutateAsync(transformation)
      }
      await updateProgrammedReportMutation.mutateAsync({
        company: company?.id,
        base_report: base_report?.id,
        report,
        ...values
      })
      await updateReportMutation.mutateAsync(report)

      notification.success({ message: 'Programmed report saved' })
    } catch (error) {
      notification.error({ message: 'Failed to save Programmed report' })
    }
  }

  return (
    <Space>
      <AppLink to="/programmed_reports">
        <Button iconName="fa:close">Cancel</Button>
      </AppLink>
      <Button type="success" iconName="fa:save" onClick={handleUpdateProgrammedReport}>
        Save
      </Button>
    </Space>
  )
}
