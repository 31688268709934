import { UrlStateFormItem } from '@/ui'
import { QueryFilterService } from '@/ui/filter'
import { Checkbox } from 'antd'
import { ReactNode } from 'react'
import { isCustomFiltersEnabled } from '../../helpers'
import { useActiveTableView } from '../hooks'
import { CustomFiltersTicketFilter } from './filters'

type Props = {
  service: QueryFilterService
  hideArchived?: boolean
  childrenPlacement?: 'left' | 'right'
  children?: ReactNode
}

export function TicketFilter({ service, hideArchived, childrenPlacement = 'left', children }: Props) {
  const { activeView } = useActiveTableView()

  return (
    <div className={'flex flex-row gap-8 items-start'}>
      {childrenPlacement === 'left' && children}
      {/* todo: migrate custom filter to use query filter */}
      {isCustomFiltersEnabled(activeView) && <CustomFiltersTicketFilter />}
      <div className={'flex-grow'} />
      {!hideArchived && (
        <UrlStateFormItem
          className={'hidden md:block mt-10'}
          name={[service.filterKey, 'archived']}
          valuePropName={'checked'}
        >
          <Checkbox>Archived</Checkbox>
        </UrlStateFormItem>
      )}
      {childrenPlacement === 'right' && children}
    </div>
  )
}
