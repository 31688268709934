import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { eventApi, userApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Spin, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { formTypeAtom, sharedDataAtom } from '../atom'
import { useFetchProfile } from '../hooks'
import { NotificationForm } from './form-notification'

export const NotificationTab = () => {
  const [notificationForm] = Form.useForm()
  const { notification } = useApp()
  const formType = useAtomValue(formTypeAtom)
  const sharedData = useAtomValue(sharedDataAtom)
  const setSharedData = useSetAtom(sharedDataAtom)
  const formValues = Form.useWatch([], notificationForm)
  const { id } = useParams<{ id: string }>()
  const eventsQuery = useQuery(eventApi.list({ target: id, type: 'profile' }))
  const [showActivity, setShowActivity] = useState(false)
  const params = useParams()

  const { data: profileData, refetch } = useFetchProfile()
  const notification_groups = [
    {
      title: 'Generic Notifications',
      types: [
        { key: 'email_new_ticket', title: 'When a new ticket needs can be dispatched.' }, // (sic)
        { key: 'email_invite_to_ticket_subscribers', title: 'Email Invitation to Ticket Subscribers' },
        { key: 'text_invite_to_ticket_subscribers', title: 'SMS Invitation to Ticket Subscribers' },
        { key: 'email_assigned_to_me', title: 'When a ticket has been assigned to me.' },
        { key: 'email_ticket_submit', title: 'When a ticket is submitted or signed.' },
        { key: 'email_property_change', title: 'When an property is changed on a ticket.' }
      ]
    }
  ]

  const mutationSpec = formType === 'add' ? userApi.create() : userApi.update()
  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async () => {
      notification.success({ message: `Notification ${formType === 'add' ? 'Added' : 'Updated'}` })
      await refetch()
    },
    onError: (error: Error | any) => {
      if (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors.length > 0) {
        notification.error({ message: error?.response?.data?.non_field_errors[0] })
      } else {
        notification.error({ message: `Notification failed to ${formType === 'add' ? 'Add' : 'Update'}` })
      }
      console.log(error)
    }
  })

  const handleFormSubmit = () => {
    const notifications = {
      ...formValues
    }

    const data = {
      ...sharedData,
      id: profileData?.user?.pk,
      profile_id: Number(params?.id),
      contact_id: profileData?.contact?.id,
      role_id: profileData?.role,
      phone_number: sharedData.phone_number
        ? `+1${sharedData?.phone_number?.replace(/\D/g, '')}`
        : sharedData?.phone_number,
      userdata_json: {
        ...profileData?.userdata_json,
        ...sharedData?.userdata_json,
        email_ticket_include_attachments: formValues?.email_ticket_include_attachments
          ? formValues?.email_ticket_include_attachments
          : false,
        notifications
      }
    }
    saveFormMutation.mutateAsync(data)
  }

  const handleCancelClick = () => {
    navigate('/profiles')
  }

  // set initial form values
  useEffect(() => {
    if (profileData) {
      const notifications = profileData?.userdata_json?.notifications
      if (Object.keys(notifications || {}).length > 0) {
        // notification.push([])
        notificationForm.setFieldsValue({ ...notifications })
      } else {
        const notificationkeys: Array<{ key: string; value: boolean }> = []
        notification_groups.map((groups) => {
          groups.types.map((type) => {
            notificationkeys.push({
              key: type.key,
              value: false
            })
          })
        })
        const list = notificationkeys.map((notify) => ({ [notify.key]: notify.value }))
        const { email_ticket_include_attachments, ...listObj } = Object.assign({}, ...list)
        notificationForm.setFieldsValue(listObj)
      }
    }
  }, [])

  useEffect(() => {
    if (formValues) {
      if (sharedData) {
        const { new_password, ...rest } = sharedData
        setSharedData({
          ...rest,
          userdata_json: {
            email_ticket_include_attachments: formValues.email_ticket_include_attachments,
            notifications: {
              ...formValues.notifications
            }
          }
        })
      }
    }
  }, [formValues])

  // set initial sync_notifications_from_role on mount
  useEffect(() => {
    setSharedData({ ...sharedData, sync_notifications_from_role: profileData?.sync_notifications_from_role })
  }, [profileData?.sync_notifications_from_role])

  return (
    <Spin spinning={false}>
      <div className="flex items-center justify-between">
        <h4>Notifications</h4>
        <div className="flex gap-8">
          <Button type="default" className="group hover:!text-gray-500 hover:opacity-75" onClick={handleCancelClick}>
            <Icon name="svg:cancel" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" /> Cancel
          </Button>
          <Button
            type="success"
            onClick={handleFormSubmit}
            disabled={saveFormMutation.isLoading}
            loading={saveFormMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            {formType === 'add' ? 'Save' : 'Update'}
          </Button>
        </div>
      </div>

      <p className="text-gray-500">
        Here you can set up reminders about your scheduled activities. <br />
        These reminders will be sent to you via email to &nbsp;
        <span className="font-bold text-primary">{profileData?.contact?.email}</span>
      </p>
      <Form.Item>
        <Checkbox
          checked={!!sharedData.sync_notifications_from_role}
          onChange={(e) => setSharedData({ ...sharedData, sync_notifications_from_role: e.target.checked })}
        >
          Sync notifications from role
        </Checkbox>
      </Form.Item>
      <div>
        <NotificationForm groups={notification_groups} form={notificationForm} />
      </div>
      <div>
        <div className="flex items-center mb-16">
          <h4 className="mr-10">Recent Activity</h4>
          <div className="relative -top-2">
            <Switch checked={showActivity} onChange={(x) => setShowActivity(x)} />
          </div>
        </div>
        {showActivity && (
          <>
            <ActivityLogsModal query={eventApi.list} queryVariables={{ target: id, type: 'profile' }} />
            <div className="mt-16">
              <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
            </div>
          </>
        )}
      </div>
    </Spin>
  )
}
