import { INVOICE_STATUS, INVOICE_TYPE, TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { ViewTicketLink } from '@/modules/ticket/components'
import { invoiceApi } from '@/services/api-service'
import { AppLink, Button, Popconfirm, QueryTable, SearchInput, Select } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form } from 'antd'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { useState } from 'react'

export const InvoicesAndBillsTab = () => {
  const trackPaymentStatus = Form.useWatch('track_payment_status')
  const deleteInvoiceMutation = useMutation(invoiceApi.delete())
  const { notification, l } = useApp()
  const [selectedSearchField, setSelectedSearchField] = useState('ticket__name')
  const [searchValue, setSearchValue] = useState('')

  const handleDelete = async (id: number) => {
    await deleteInvoiceMutation.mutateAsync({ id })
    notification.success({ message: 'Invoice record deleted' })
  }

  const queryParams = {
    ...(searchValue && { [`${selectedSearchField}__icontains`]: searchValue }),
    order: '-modified_at',
    exclude_fields: 'data'
  }

  return (
    <div>
      <div className="flex gap-x-12 mb-20">
        <Select
          className="w-[200px]"
          popupMatchSelectWidth={false}
          value={selectedSearchField}
          onChange={setSelectedSearchField}
          options={[
            {
              label: l('ticket__name__label'),
              value: 'ticket__name'
            },
            {
              label: 'Ref. Number',
              value: 'ref_number'
            },
            {
              label: 'Sync Detail',
              value: 'detail'
            },
            {
              label: l('ticket__afe__label'),
              value: 'ticket__afe__user_afe_no'
            },
            {
              label: l('ticket__job_code__label'),
              value: 'ticket__job_code__user_job_code_no'
            },
            {
              label: l('ticket__location__label'),
              value: 'ticket__location__name'
            },
            {
              label: 'Team Member',
              value: 'ticket__contacts__contact__name'
            },
            {
              label: l('ticket__office__label'),
              value: 'ticket__customer_office__name'
            },
            {
              label: l('ticket__contact__label'),
              value: 'ticket__customer__first_name'
            },
            {
              label: l('Ticket'),
              value: 'ticket__custom_form__name'
            }
          ]}
        />
        <SearchInput onSearch={setSearchValue} />
      </div>
      <QueryTable
        queryApi={invoiceApi.list}
        queryParams={queryParams}
        columns={[
          {
            title: 'Record',
            dataIndex: 'type',
            render: (type) => <div className="capitalize">{INVOICE_TYPE[type as keyof typeof INVOICE_TYPE]}</div>
          },
          {
            title: 'Ticket',
            dataIndex: 'ticket__name',
            render: (_, record: any) => (
              <ViewTicketLink ticketId={record.ticket} formId={0} className="text-primary-500 hover:text-primary-600">
                <span>
                  {record.ticket__custom_form__name} #{record.ticket__name}
                </span>
              </ViewTicketLink>
            )
          },
          {
            title: 'Customer Office',
            dataIndex: 'ticket__customer_office'
          },
          {
            title: 'Status',
            sorter: false,
            dataIndex: 'ticket__status',
            render: (status) => <div>{TICKET_STATUS[status as keyof typeof TICKET_STATUS]}</div>
          },
          {
            title: 'Updated',
            dataIndex: 'modified_at',
            render: (date) => <div>{dayjs(date).format('MMM DD, YYYY h:mm:ss A')}</div>,
            defaultSortOrder: 'descend'
          },
          {
            title: 'QuickBooks Status',
            dataIndex: 'status',
            render: (status) => <div>{INVOICE_STATUS[status as keyof typeof INVOICE_STATUS]}</div>
          },
          {
            title: '',
            dataIndex: 'detail'
          },
          {
            title: 'Payment Status',
            dataIndex: ['qb_txn', 'payment_status'],
            sorter: false,
            render: (status) => (trackPaymentStatus ? <div>{status}</div> : null),
            hidden: !trackPaymentStatus
          },
          {
            title: 'Ref Number',
            dataIndex: 'ref_number',
            sorter: false,
            render: (ref_number, record) =>
              record.qb_txn?.qb_txn_url ? (
                <AppLink target="_blank" to={record.qb_txn.qb_txn_url}>
                  {ref_number}
                </AppLink>
              ) : (
                <p>{ref_number}</p>
              )
          },
          {
            sorter: false,
            render: (_, record: any) => (
              <Popconfirm
                placement="topLeft"
                title={`Delete ${capitalize(INVOICE_TYPE[record.type as keyof typeof INVOICE_TYPE])}`}
                description={`Deleting this ${capitalize(INVOICE_TYPE[record.type as keyof typeof INVOICE_TYPE])} for ${record.ticket__custom_form__name} #${record.ticket__name} in Oil Command will NOT delete the invoice in QuickBooks`}
                okText="Yes"
                onConfirm={() => handleDelete(record.id)}
              >
                <Button type="text" iconName="fa:trash" danger />
              </Popconfirm>
            )
          }
        ]}
      />
    </div>
  )
}
