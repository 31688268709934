import { view } from '@/routing'
import { Outlet } from 'react-router-dom'
import { RECORD_TYPE_MAP, RecordTypeOption } from './constants'

export const RecordsLayout = view<any, RecordTypeOption>(Outlet, {
  name: 'RecordsLayout',
  title: ({ data }) => data.label,
  loader: ({ params }) => {
    const recordType = params.recordType ? RECORD_TYPE_MAP[params.recordType] : null
    if (!recordType) throw new Error('Invalid record type')
    return recordType
  }
})
