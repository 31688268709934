import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { layout, redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { CustomFormAddEditView, CustomFormLayoutView, CustomFormsView, TicketStagesView } from '.'
import {
  AccessAndAlerts,
  Advanced,
  DefaultTeamMembers,
  Email,
  LineItems,
  Settings,
  Styles,
  Title
} from './custom-forms/custom-form-add-edit-view/tabs'
import { ImportExportView } from './import-export'
import { AddEditNotificationTemplateView } from './notification-templates/add-edit-notification-template-view'
import { NotificationTemplatesListView } from './notification-templates/notification-templates-list-view'
import { NotificationsActivityView } from './notifications-activity'

export const SETTINGS_ROUTES = [
  // {
  //   path: SETTINGS_PATHS.editCustomForm(':id'),
  //   element: <SettingsCustomFormAddEditView />
  // },
  // {
  //   path: SETTINGS_PATHS.addCustomForm,
  //   element: <SettingsCustomFormAddEditView />
  // },
  {
    path: SETTINGS_PATHS.customFormLayout(':id'),
    element: <CustomFormLayoutView />
  },
  {
    path: SETTINGS_PATHS.stages,
    element: <TicketStagesView />
  },
  {
    path: SETTINGS_PATHS.importExport,
    element: <ImportExportView />
  },
  {
    path: SETTINGS_PATHS.stages,
    element: <TicketStagesView />
  },
  {
    path: SETTINGS_PATHS.notificationTemplates,
    element: <NotificationTemplatesListView />
  },
  {
    path: SETTINGS_PATHS.addNotificationTemplate,
    element: <AddEditNotificationTemplateView />
  },
  {
    path: SETTINGS_PATHS.editNotificationTemplate(':id'),
    element: <AddEditNotificationTemplateView />
  },
  {
    path: SETTINGS_PATHS.notificationsActivity,
    element: <NotificationsActivityView />
  }
]

const addEditCustomFormRoutes = [
  route('index', redirect('title')),
  route('title', Title),
  route('settings', Settings),
  route('advanced', Advanced),
  route('access_and_alerts', AccessAndAlerts),
  route('styles', Styles),
  route('default_team_members', DefaultTeamMembers),
  route('email', Email),
  route('line_items', LineItems)
]

export const routes: RouteObject[] = [
  route('account', layout('SettingsLayout'), [
    route('index', redirect('custom_forms')),
    route('custom_forms', layout('CustomForms', 'Custom Forms'), [
      route('index', CustomFormsView),
      route('add', CustomFormAddEditView, [...addEditCustomFormRoutes]),
      route(':id/edit', CustomFormAddEditView, [...addEditCustomFormRoutes]),
      route(':id/layout', CustomFormLayoutView)
    ])
  ])
]
