import { Form } from 'antd'
import { useAtomValue } from 'jotai'
import { currentEditingTransformTaskAtom } from '../../atoms'
import { Card } from '../card'
import { ActionButtons } from './action-buttons'
import { TransformationTask } from './transformation-task'

export const CurrentEditingTransformTaskCard = () => {
  const currentEditingTransformTask = useAtomValue(currentEditingTransformTaskAtom)

  if (!currentEditingTransformTask || !currentEditingTransformTask.type) {
    return null
  }

  return (
    <Form
      key={currentEditingTransformTask.id}
      initialValues={currentEditingTransformTask}
      layout="vertical"
      component="div"
      className="mb-20"
    >
      <Form.Item name="id" hidden />
      <Form.Item name="params" hidden />
      <Card title={currentEditingTransformTask.name} extra={<ActionButtons />}>
        <TransformationTask type={currentEditingTransformTask.type} />
      </Card>
    </Form>
  )
}
