import { RuleTemplate } from '@/schemas/rule-template'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Popconfirm } from 'antd'
import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentRuleTemplateAtom, isDeleteModeAtom, setCurrentRuleTemplateAtom } from '../../atoms'
import { useDeleteRuleTemplate } from '../../hooks'

type Props = {
  ruleTemplate: RuleTemplate
}

export const ListButton = ({ ruleTemplate }: Props) => {
  const currentRuleTemplate = useAtomValue(currentRuleTemplateAtom)
  const isDeleteMode = useAtomValue(isDeleteModeAtom)
  const setCurrentRuleTemplate = useSetAtom(setCurrentRuleTemplateAtom)
  const isActive = currentRuleTemplate?.id === ruleTemplate.id
  const { deleteRuleTemplate } = useDeleteRuleTemplate()

  const handleClick = () => {
    if (isDeleteMode) {
      return
    }

    setCurrentRuleTemplate(ruleTemplate)
  }

  const renderRightElement = () => {
    if (currentRuleTemplate?.id === ruleTemplate.id) {
      return <Icon name="fa:chevron-right" className="absolute right-8" />
    }

    if (isDeleteMode) {
      return (
        <Popconfirm title="Delete rule template?" onConfirm={() => deleteRuleTemplate(ruleTemplate.id)}>
          <Button type="text" size="small" className="absolute right-8 !p-0" data-cy="delete-rule-template">
            <Icon name="fa:trash" />
          </Button>
        </Popconfirm>
      )
    }

    return null
  }

  return (
    <div
      role="button"
      className={classNames(
        'flex relative rounded-md cursor-pointer border border-solid border-gray-300 h-38 items-center justify-center select-none',
        {
          'bg-green !border-green text-white': isActive
        }
      )}
      onClick={handleClick}
      data-cy="rule-template-item"
    >
      <span>{ruleTemplate.name}</span>
      {renderRightElement()}
    </div>
  )
}
