export const ROLODEX_PATHS = {
  main: '/rolodex',
  contacts: '/rolodex/contacts',
  addContact: '/rolodex/contacts/add',
  editContact: (id: number | string) => `/rolodex/contacts/${id}/edit`,
  companies: '/rolodex/companies',
  addCompany: '/rolodex/companies/add',
  editCompany: (id: number | string) => `/rolodex/companies/${id}/edit`,
  privateCompanies: '/rolodex/private_companies',
  addPrivateCompany: '/rolodex/private_companies/add',
  editPrivateCompany: (id: number | string) => `/rolodex/private_companies/${id}/edit`,
  addOffice: ({ isPrivate, companyId }: { isPrivate: boolean; companyId: number | string }) =>
    isPrivate ? `/rolodex/private_companies/${companyId}/add_office` : `/rolodex/companies/${companyId}/add_office`,
  office: ({
    isPrivate,
    companyId,
    officeId
  }: {
    isPrivate: boolean
    companyId: number | string
    officeId: number | string
  }) =>
    isPrivate
      ? `/rolodex/private_companies/${companyId}/offices/${officeId}`
      : `/rolodex/companies/${companyId}/offices/${officeId}`
} as const
