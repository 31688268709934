import { DataSourceManager } from '@/components/data-source-manager'
import { useUserDataQuery } from '@/components/data-source-manager/hooks'
import { Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../../../atoms'
import { useSortableItemContext } from '../../context'

export const DataSource = () => {
  const { lineItemField } = useSortableItemContext()
  const setConfiguration = useSetAtom(configurationAtom)
  const { userDataQuery } = useUserDataQuery()
  const dataField = useAtomValue(useMemo(() => selectAtom(configurationAtom, (c) => c.data ?? {}), []))
  const item = dataField[lineItemField] as any
  const options = userDataQuery.data?.items.map((item) => ({ label: item.name, value: item.id })) ?? []
  const option = options?.find((option) => option.value === item?.stringSource)

  const handleSelect = (value: any) => {
    const dataObject = {
      ...dataField,
      [lineItemField]: {
        ...item,
        stringSource: value
      }
    }

    setConfiguration((configuration) => ({
      ...configuration,
      data: dataObject
    }))
  }

  return (
    <Spin spinning={userDataQuery.isLoading}>
      <DataSourceManager onChange={handleSelect} value={option?.value} />
    </Spin>
  )
}
