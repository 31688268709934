import { categoryKindApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Configuration } from '../schema'

export const useConfigurationQuery = () => {
  const { id } = useParams<{ id: string }>()

  const configurationQuery = useQuery({
    ...categoryKindApi.get<Configuration>(id as any),
    enabled: !!id,
    refetchOnWindowFocus: false
  })

  return {
    ...configurationQuery,
    isLoading: configurationQuery.isLoading && configurationQuery.fetchStatus !== 'idle'
  }
}
