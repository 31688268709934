import { UniqueIdentifier } from '@dnd-kit/core'
import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'

const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true })

type Props = {
  id: UniqueIdentifier
  title: string
  children: React.ReactNode
  items: UniqueIdentifier[]
}

export const DroppableContainer = ({ children, id, items, title }: Props) => {
  const { setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
      children: items
    },
    animateLayoutChanges
  })

  return (
    <div className="flex-1">
      <div className="mb-4">{title}</div>
      <div
        ref={setNodeRef}
        style={{
          height: 'calc(100vh - 380px)'
        }}
        className="border border-border p-10 rounded overflow-y-auto"
      >
        <ul className="p-0 m-0">{children}</ul>
      </div>
    </div>
  )
}
