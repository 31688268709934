import { TabsView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { moduleApi } from '@/services/api-service'
import { Module } from '../module/types'

export const NetSuiteLayout = view<any, Module>(Component, {
  title: ({ data }) => data?.title || 'NetSuite',
  loader: async ({ params }) => {
    const module = await fetchQuery(moduleApi.get<Module>(Number(params.moduleId)))
    return module
  }
})

function Component() {
  return (
    <TabsView
      asLayout
      header={{ breadcrumbs: true }}
      items={[
        { to: 'configs', label: 'Configure' },
        { to: 'invoices', label: 'Invoices' }
      ]}
    />
  )
}
