import { APP_MODE } from '@/constants/app'
import { Button, ButtonProps, Icon, Tooltip } from '@/ui'
import { startCase } from 'lodash'
import { FC } from 'react'

export const ServerSelect: FC<ButtonProps> = (props) => {
  const server = localStorage.ocServer || 'main'
  const isMain = server === 'main'

  const switchServer = () => {
    localStorage.ocServer = isMain ? 'edge' : 'main'
    location.reload()
  }

  return APP_MODE == 'production' ? (
    <Tooltip title={isMain ? 'Switch to Edge Server' : 'Switch to Main Server'}>
      <Button
        size={'small'}
        shape={'round'}
        color={'default'}
        variant={'filled'}
        icon={<Icon name={'fa:server'} />}
        onClick={switchServer}
        {...props}
      >
        {startCase(server)}
      </Button>
    </Tooltip>
  ) : null
}
