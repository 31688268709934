import { useApp } from '@/hooks'
import { reportBuilderApi } from '@/services/api-service'
import { Button, Modal } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import { useState } from 'react'

export const NewCustomReportModal = () => {
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const createCustomReportMutation = useMutation(reportBuilderApi.create())
  const [form] = Form.useForm()
  const { notification } = useApp()

  const handleSave = async () => {
    const values = await form.validateFields()
    await createCustomReportMutation.mutateAsync(values)
    queryClient.invalidateQueries(['custom_reports'])
    notification.success({ message: 'Report Added' })
    form.resetFields()
    toggleModal()
  }

  return (
    <>
      <Button type="primary" onClick={toggleModal}>
        Add New Report
      </Button>
      <Modal title="New Custom Report" open={isOpen} okText="Add Report" onOk={handleSave} onCancel={toggleModal}>
        <Form layout="vertical" form={form}>
          <Form.Item label="Report Model" name="root_model" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: 'Line Item',
                  value: '30'
                },
                {
                  label: 'Ticket',
                  value: '39'
                }
              ]}
            />
          </Form.Item>
          <Form.Item label="Report Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Report Description" name="description">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
