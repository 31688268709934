import { DEFAULT_TIMEZONE } from '@/constants/date'
import { AuthUser } from '@/types/auth-user'

export const formatSessionUser = (user: AuthUser) => {
  return {
    id: user.id,
    username: user.username,
    name: `${user.profile.contact.first_name || ''} ${user.profile.contact.last_name || ''} (${user.username})`.trim(),
    company: user.profile.company.name,
    groups: user.groups.join(', '),
    timezone: user.profile.timezone || user.profile.company.default_timezone || DEFAULT_TIMEZONE
  }
}
