import { ListParams } from '@/types/api/core'
import { AppLink, Select, SelectProps } from '@/ui'
import { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useTableViewsQuery } from '../hooks'
import { TableView } from '../types'

type Props = SelectProps & {
  queryParams?: ListParams
  value?: TableView | null
  onChange: (value: TableView | null) => void
  hideDefault?: boolean
}

export const TableViewSelect: FC<Props> = ({ queryParams, value, onChange, hideDefault, ...selectProps }) => {
  const { data } = useTableViewsQuery(queryParams)

  const handleChange = useDebouncedCallback((viewId: number, option: any) => {
    if (!viewId) return onChange(null)
    else onChange(option.item as TableView | null)
  }, 300)

  return (
    <Select
      showSearch={false}
      rootClassName={'w-[200px]'}
      allowClear={false}
      showCopyText={false}
      options={[
        ...(hideDefault ? [] : [{ value: 0, label: 'Default' }]),
        ...(data?.items.map((item) => ({ item: item, value: item.id, label: item.name })) || [])
      ]}
      value={value?.id || 0}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div className={'flex flex-col gap-4'}>
          <div>{menu}</div>
          <div className={'border-t border-t-border dark:border-t-dark-border'}>
            <AppLink to={'/account_settings/ticket_views'} className={'py-6 block text-center'}>
              Manage Views
            </AppLink>
          </div>
        </div>
      )}
      {...selectProps}
    />
  )
}
