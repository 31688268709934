import { accountRoleApi } from '@/services/api-service'
import { QueryDeleteButton } from '@/ui'
import { useAtom } from 'jotai'
import { selectedAccountRoleAtom } from '../atoms'

export const DeleteButton = () => {
  const [selectedAccountRole, setSelectedAccountRole] = useAtom(selectedAccountRoleAtom)

  return (
    <QueryDeleteButton
      name={'Account Role'}
      api={accountRoleApi.delete}
      id={selectedAccountRole?.id || 0}
      onSuccess={() => setSelectedAccountRole({})}
      data-cy={'delete-role-button'}
      variant="solid"
      shape={'default'}
      type="default"
      disabled={!selectedAccountRole?.id}
    >
      Delete
    </QueryDeleteButton>
  )
}
