import { z } from 'zod'

export const JobRequirementsByRoleSchema = z
  .array(
    z.object({
      role: z.number(),
      required: z.number().default(0)
    })
  )
  .nullable()
export type JobRequirementsByRole = z.infer<typeof JobRequirementsByRoleSchema>
