import { DEFAULT_TIMEZONE } from '@/constants/date'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import weekday from 'dayjs/plugin/weekday'
import localDate from './local-date'

export const loadDayjsPlugins = () => {
  dayjs.locale('en')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(isBetween)
  dayjs.extend(quarterOfYear)

  // required for @/ui/date/LocalDatePicker
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(weekday)
  dayjs.extend(localeData)
  dayjs.extend(weekOfYear)
  dayjs.extend(weekYear)
  dayjs.extend(localDate)
  // ... add more plugins here as needed

  dayjs.tz.setDefault(DEFAULT_TIMEZONE)
}
