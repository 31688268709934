import { ListParams } from '@/types/api/core'
import { Select } from '@/ui/select'
import cn from 'classnames'
import { useMemo } from 'react'
import { FilterFormItem } from './FilterFormItem'
import { QueryField, QueryFilterService } from './types'

export type QueryFilterProps = {
  service: QueryFilterService
  disabled?: boolean
  value?: { field: string; value: any }
  onChange?: (value: { field: string; value: any }, query: ListParams) => void
  onRemove?: (field: QueryField) => void
}

export function QueryFilter({ service }: QueryFilterProps) {
  const { searchEnabled, selectedFields, fieldsOptions, filterKey } = service

  const selectableFields = useMemo(
    () => fieldsOptions.filter((f) => !selectedFields.find((sf) => sf.key === f.value)),
    [fieldsOptions, selectedFields]
  )

  return (
    <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-14 pt-10 pb-2 w-full'}>
      {selectedFields?.map((f, i) => (
        <div key={f.key} className={'w-full'}>
          <FilterFormItem
            key={f.key}
            className={'!w-full'}
            name={[filterKey, 'q', f.key]}
            field={f}
            service={service}
            hideLabel={false}
            hideRemove={!searchEnabled && i === 0}
          />
        </div>
      ))}
      {selectableFields.length > 0 && (
        <div className={'w-full'}>
          <Select
            variant={'filled'}
            rootClassName={cn(
              '[&_.ant-select-selector]:rounded-full',
              '[&_.ant-select-selection-placeholder]:text-neutral [&_.ant-select-selection-placeholder]:text-center'
            )}
            placeholder={'More +'}
            options={selectableFields}
            value={null}
            onChange={(fieldKey) => service.addFilterField(fieldKey)}
            suffixIcon={null}
            showCopyText={false}
            className={'w-full md:w-[75px]'}
            popupMatchSelectWidth={false}
          />
        </div>
      )}
    </div>
  )
}
