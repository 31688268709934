import { ListParams, ListQueryApiFn, ListReturn } from '@/types/api/core'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useQuery } from '@tanstack/react-query'
import { Divider, Spin } from 'antd'
import { DefaultOptionType, SelectValue } from 'antd/es/select'
import { isArray, isEmpty, uniqBy } from 'lodash'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Select, SelectProps } from './Select'
import { getOptionValue, showAddOption } from './helpers'

export type QuerySelectProps<TItem = any> = Omit<SelectProps, 'value' | 'defaultValue' | 'onSelect'> & {
  value?: SelectValue // force value to be LabeledValue
  defaultValue?: SelectValue // force defaultValue to be LabeledValue
  apiEndpoint: ListQueryApiFn
  apiQueryParams?: ListParams
  apiQueryOptions?: ListReturn<TItem>
  apiSearchBy: string | ((searchText: string | undefined) => any)
  apiValueBy?: string
  renderOption?: (item: TItem) => { value: any; label: string | ReactNode }
  mapOption?: [keyof TItem, keyof TItem]
  addOption?: DefaultOptionType & { onAdd: (text: string) => Promise<DefaultOptionType> | DefaultOptionType }
  footer?: ReactNode
  showMoreFooter?: boolean
  prefetch?: boolean
}

export const QuerySelect = <TItem = any>({
  apiEndpoint,
  apiQueryParams,
  apiQueryOptions,
  apiSearchBy,
  apiValueBy,
  renderOption,
  mapOption,
  addOption,
  onClear,
  footer,
  showMoreFooter = true,
  prefetch = true,
  onSearch,
  onChange,
  ...selectProps
}: QuerySelectProps<TItem>) => {
  const [limit, setLimit] = useState(10)
  const [text, setText] = useState('')
  const [enabled, setEnabled] = useState(false)

  const params = {
    limit: limit,
    ...apiQueryParams,
    ...(typeof apiSearchBy === 'string'
      ? { [`${apiSearchBy}__icontains`]: text }
      : apiSearchBy(isEmpty(text) ? undefined : text))
  }

  const query = useQuery({
    ...apiEndpoint<TItem>(params),
    ...apiQueryOptions,
    enabled: prefetch || enabled
  })

  const selectedValue = getOptionValue(selectProps.value ?? selectProps.defaultValue)
  const enableSelectedQuery = !isEmpty(apiValueBy) && !!selectedValue && isEmpty(text)
  const _values = isArray(selectedValue) ? selectedValue : [selectedValue]
  const selectedQuery = useQuery({
    ...apiEndpoint<TItem>({
      ...params,
      [`${apiValueBy}__in`]: _values.join(','),
      limit: _values.length,
      no_count: true
    }),
    ...apiQueryOptions,
    enabled: enableSelectedQuery
  })

  const totalCount = query.data?.total || 0
  const totalItems = query.data?.items.length || 0
  const hasMore = (limit || 0) < totalCount

  const renderOptionFn = useMemo(() => {
    if (renderOption) {
      return renderOption
    } else if (mapOption) {
      return (item: TItem) => {
        return { value: item[mapOption[0]], label: String(item[mapOption[1]]) }
      }
    } else {
      return (item: TItem) => ({ value: item, label: String(item) })
    }
  }, [renderOption, mapOption])

  const options = useMemo(() => {
    const _options = uniqBy(
      [...(enableSelectedQuery ? selectedQuery.data?.items || [] : []), ...(query.data?.items || [])].map(
        renderOptionFn
      ),
      'value'
    )

    if (addOption && showAddOption(text, _options)) {
      return [
        {
          value: '__add__',
          label: (
            <div className={'flex gap-6 items-center'}>
              <Icon name={'fa:plus'} />
              <span className={'overflow-ellipsis overflow-hidden'}>
                Add <strong>{text}</strong>
              </span>
            </div>
          ),
          ...addOption
        },
        ..._options
      ]
    }

    return _options
  }, [addOption, text, enableSelectedQuery, selectedQuery.data?.items, query.data?.items, renderOptionFn])

  const onDebouncedSearch = useDebouncedCallback((text: string) => setText(text), 200)

  const handleClear = useCallback(() => {
    setText('')
    onClear?.()
  }, [onClear])

  const handleSearch = useCallback(
    (text: string) => {
      onDebouncedSearch(text)
      onSearch?.(text)
    },
    [onDebouncedSearch, onSearch]
  )

  const handleChange: QuerySelectProps['onChange'] = (value, option) => {
    if (value === '__add__' && addOption) {
      // call onAdd and update the value
      const result = addOption.onAdd(text)
      if (result instanceof Promise) {
        // fill this while adding a new option
        onChange?.({ value, label: text }, option)
        // update the value once the promise resolves
        result.then((opt: DefaultOptionType) => onChange?.(opt, opt)).catch(handleClear)
      } else onChange?.(result.value, result)
    } else onChange?.(value, option)
  }

  return (
    <Select
      onFocus={() => setEnabled(true)}
      onBlur={() => setEnabled(false)}
      allowClear={true}
      maxTagCount={'responsive'}
      autoClearSearchValue={true}
      onClear={handleClear}
      showSearch={true}
      filterOption={false}
      placeholder="Select"
      options={options}
      onSearch={handleSearch}
      onChange={handleChange}
      disabled={selectProps.value === '__add__'}
      loading={query.isFetching || selectProps.value === '__add__'}
      isOptionAvailable={enableSelectedQuery && selectedQuery.isFetched ? !!selectedQuery.data?.items.length : true}
      dropdownRender={(menu) => (
        <>
          {menu}
          {footer}
          {showMoreFooter && (
            <div>
              <Divider style={{ margin: '2px 0' }} />
              <div className={'px-4 flex flex-row items-center !text-sm'}>
                <div className={'text-left'}>
                  <Button
                    className={'!p-2 !text-sm'}
                    disabled={!hasMore || query.isFetching}
                    size={'small'}
                    type={'link'}
                    onClick={() => setLimit(Math.min(limit * 5, 1000))}
                  >
                    More
                  </Button>
                  {query.isFetching && <Spin className={'ml-4'} spinning={true} size={'small'} />}
                </div>
                <div className={'flex-grow text-right'}>
                  {totalItems} of {totalCount} Records
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {...selectProps}
    />
  )
}
