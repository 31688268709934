import { uuid4 } from '@/utils'
import { recordBy } from '@/utils/record-by'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { bpaConfigAtom, customFormAtom } from '../atoms'

export const useRules = () => {
  const rules = useAtomValue(useMemo(() => selectAtom(bpaConfigAtom, (config) => config.rules), []))

  return {
    rules
  }
}

export const useRulesHandlers = () => {
  const setConfig = useSetAtom(bpaConfigAtom)
  const customFormId = useAtomValue(useMemo(() => selectAtom(customFormAtom, (form) => form?.id), []))

  const setAllRulesExpanded = useCallback(
    (value: boolean) => {
      setConfig((config) => {
        if (config.rules.some((rule) => rule.expanded !== value)) {
          config.rules.forEach((rule) => {
            rule.expanded = value
          })
        }
      })
    },
    [setConfig]
  )

  const onRuleExpandedChange = useCallback(
    (value: boolean, idx: number) => {
      setConfig((config) => {
        config.rules[idx].expanded = value
      })
    },
    [setConfig]
  )

  const onAddRule = useCallback(() => {
    setConfig((config) => {
      config.rules.push({
        uuid: uuid4(),
        label: `New Rule ${config.rules.length + 1}`,
        expanded: true,
        conditions: [],
        actions: []
      })
    })
  }, [setConfig])

  const onLabelChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    setConfig((config) => {
      config.rules[idx].label = e.target.value.trim()
    })
  }, 200)

  const onCloneRule = useCallback(
    (idx: number) => {
      setConfig((config) => {
        const rule = config.rules[idx]
        const clonedRule = {
          ...rule,
          uuid: uuid4(),
          label: `(Clone) ${rule.label}`,
          actions: rule.actions.map((action, i) => ({
            ...action,
            // Generate new action keys
            key: `A-${customFormId}-${i + 1}${Math.round(Math.random() * 1000)}`
          }))
        }
        config.rules.splice(idx + 1, 0, clonedRule)
      })
    },
    [setConfig]
  )

  const onDeleteRule = useCallback(
    (idx: number) => {
      setConfig((config) => {
        config.rules.splice(idx, 1)
      })
    },
    [setConfig]
  )

  const onSortingChange = useCallback(
    (rulesUuid: string[]) => {
      setConfig((config) => {
        const ruleByUuid = recordBy(config.rules, 'uuid')
        config.rules = rulesUuid.map((uuid) => ruleByUuid[uuid])
      })
    },
    [setConfig]
  )

  return {
    setAllRulesExpanded,
    onRuleExpandedChange,
    onAddRule,
    onLabelChange,
    onCloneRule,
    onDeleteRule,
    onSortingChange
  }
}
