import { UserOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

type Props = {
  searchText: string
  onChange: (value: string) => void
}

export const EmployeeSearch = ({ onChange, searchText }: Props) => {
  const [text, setText] = useState(searchText)
  const [debouncedSearchText] = useDebounce(text, 500)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
  }

  useEffect(() => {
    onChange(debouncedSearchText)
  }, [debouncedSearchText, onChange])

  return (
    // Don't remove this div
    <div>
      <Input placeholder="Search Employee" value={text} prefix={<UserOutlined />} onChange={handleChange} />
    </div>
  )
}
