import { useApp } from '@/hooks'
import { useIsCompanyAdmin } from '@/modules/auth/hooks'
import { Button, Icon } from '@/ui'
import { useSortable } from '@dnd-kit/sortable'
import { Dropdown } from 'antd'
import cn from 'classnames'
import { FC, useMemo } from 'react'
import {
  useCustomForm,
  useTicket,
  useTicketCustomerOffice,
  useTicketFlags,
  useTicketSave
} from '../../../../../../hooks'
import { useItemDelete } from '../../../../hooks'
import { LineItem } from '../../../../schemas'
import { AddEditButton } from '../../../add-edit-button'
import { useEditingComponents } from '../item-components/hooks'

type Props = {
  record: LineItem
}

export const ItemActions: FC<Props> = ({ record }) => {
  const { modal } = useApp()
  const { ticket } = useTicket()
  const { viewOnly } = useTicketFlags()
  const { features } = useCustomForm()
  const isCompanyAdmin = useIsCompanyAdmin()
  const customerOffice = useTicketCustomerOffice()
  const { saveTicket } = useTicketSave()
  const { deleteLineItem } = useItemDelete()
  const { openEdit } = useEditingComponents()
  const { isDragging, listeners } = useSortable({ id: record.id as any })

  const handleDelete = async () => {
    await deleteLineItem(record.id)
    saveTicket({ only: ['id'], forceSave: true })
  }
  const menuItems = useMemo(() => {
    const items = []

    if (record.sku_designation === 'F') {
      items.push({
        key: 'e-comp',
        icon: <Icon name={'fa:edit'} />,
        label: 'Edit Components',
        onClick: () => openEdit(record.id)
      })
      items.push({ key: 'u-comp', icon: <Icon name={'fa:sync'} />, label: 'Sync Component' })
    }

    // todo: line items - attachments [unused?]
    // items.push({ key: 'attachments', icon: <Icon name={'fa:paperclip'} />, label: 'Attachments' })

    items.push({
      key: 'delete',
      icon: <Icon name={'fa:trash'} />,
      label: 'Delete',
      danger: true,
      onClick: async () => {
        await modal.confirm({
          title: 'Delete Line Item',
          content: `Are you sure delete line item "${record.sku}?"`,
          maskClosable: true,
          centered: true,
          onOk: handleDelete
        })
      }
    })

    return items
  }, [deleteLineItem, modal, openEdit, record.id, record.sku, record.sku_designation])

  return (
    <div
      className={cn('!w-[55px] relative -right-8 border-l group flex flex-row justify-end bg-background-container', {
        '!bg-primary-200': isDragging
      })}
    >
      <Button
        type={'dashed'}
        iconName={'fa:grip-dots-vertical'}
        size={'small'}
        className={'cursor-grab opacity-0 group-hover:opacity-100 absolute -left-28'}
        {...listeners}
      />
      <AddEditButton
        lineItemId={record.id}
        buttonProps={{ size: 'small', type: 'text', iconName: 'fa:pen' }}
        modalProps={{ iconName: 'fa:edit', title: `Edit ${record.sku}` }}
        onSaved={() => saveTicket({ only: ['id'], forceSave: true })}
        options={{
          type: 'edit',
          ticketId: ticket.id,
          customerOfficeId: customerOffice?.id,
          disabledFields: ['sku', 'description'],
          excludedFields: features.hideItemFields?.enabled ? features.hideItemFields?.fields || [] : undefined,
          limitBackDate: !isCompanyAdmin && features.restrictLineItemEdits,
          preferEmpty: true
        }}
      />
      <Dropdown
        trigger={['click']}
        arrow={true}
        placement="bottomRight"
        menu={{ items: menuItems }}
        disabled={viewOnly}
      >
        <div>
          <Button size={'small'} type={'text'} iconName={'fa:ellipsis-vertical'} />
        </div>
      </Dropdown>
    </div>
  )
}
