import { navigate } from '@/routing/helpers'
import { PageTitle, Tabs } from '@/ui'
import { useLocation } from 'react-router-dom'
import { INVENTORY_PATHS } from '../../constants'

type Props = {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  const location = useLocation()

  const handleTabChange = (pathname: string) => {
    navigate(pathname)
  }

  return (
    <div>
      <PageTitle>Inventory</PageTitle>
      <Tabs
        className="mt-16"
        activeKey={location.pathname.replace(/\/$/, '')} // remove trailing slash
        type="solid"
        onChange={handleTabChange}
        items={[
          {
            key: INVENTORY_PATHS.inventory,
            label: 'Inventory'
          },
          {
            key: INVENTORY_PATHS.graphs,
            label: 'Graphs'
          },
          {
            key: INVENTORY_PATHS.ledger,
            label: 'Ledger'
          },
          {
            key: INVENTORY_PATHS.alerts,
            label: 'Alerts'
          }
        ]}
      />
      {children}
    </div>
  )
}
