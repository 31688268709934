import { ENABLE_REDIRECT } from '@/constants/app'
import { ErrorView } from '@/layouts/views'
import { route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { SecureLayout } from './layouts'
import { SecureTicketAccessView } from './ticket'

export const routes: RouteObject[] = [
  route('secure', SecureLayout, [
    route('tickets/:ticketId', SecureTicketAccessView),
    route('*', ErrorView, undefined, { code: 404, redirect: ENABLE_REDIRECT })
  ])
]
