import { useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export function useFaviconIndicator({ color, text = '' }: { color: string; text?: string }) {
  const faviconsRef = useRef<any>([])

  const loadFavicon = useDebouncedCallback((color: string, text?: string) => {
    if (!color && !text) return

    // backup and remove default favicons
    const favicons = document.querySelectorAll('head>link.site-meta')
    if (favicons.length) {
      faviconsRef.current = favicons
      favicons.forEach((link) => document.head.removeChild(link))
    }

    const existingFavicon = document.querySelector<HTMLLinkElement>('head>link.temp-favicon')
    if (existingFavicon) {
      existingFavicon.href = getColoredFavicon(color, text)
    } else {
      const newFavicon = document.createElement('link')
      newFavicon.className = 'temp-favicon'
      newFavicon.rel = 'icon'
      newFavicon.type = 'image/svg+xml'
      newFavicon.href = getColoredFavicon(color, text)
      document.head.appendChild(newFavicon)
    }
  }, 50)

  useEffect(() => {
    loadFavicon(color, text)

    return () => {
      // remove custom favicon
      const tempFavicon = document.querySelector('head>link.temp-favicon')
      if (tempFavicon) document.head.removeChild(tempFavicon)

      // restore default favicons
      faviconsRef.current.forEach((favicon: any) => document.head.appendChild(favicon))
    }
  }, [color, loadFavicon, text])
}

function getColoredFavicon(color: string, text = '') {
  const svgData =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">' +
    `<circle cx="8" cy="8" r="8" fill="${color}" />` +
    `<text x="8" y="11" font-size="8" text-anchor="middle" fill="white" font-family="system-ui">${text
      .substring(0, 2)
      .toUpperCase()}</text>` +
    '</svg>'
  const svgBase64 = btoa(svgData)
  return `data:image/svg+xml;base64,${svgBase64}`
}
