import dayjs from 'dayjs'

export const getTotalJobDays = (rule: {
  start_date: string
  end_date: string
  template__data: {
    day_on: number
    day_off: number
  }[]
}) => {
  const { start_date, end_date, template__data } = rule
  const diffBetweenDates = dayjs(end_date).diff(dayjs(start_date), 'day')

  if (!rule.template__data) {
    return 0
  }

  const totalDayOn = template__data.reduce((acc, { day_on }) => acc + day_on, 0)
  const totalDayOff = template__data.reduce((acc, { day_off }) => acc + day_off, 0)

  let count = 0
  let dayOn = 0
  let dayOff = 0

  for (let i = 0; i <= diffBetweenDates; i++) {
    if (dayOn < totalDayOn) {
      dayOn++
      count++
    } else if (dayOff < totalDayOff) {
      dayOff++
    }

    if (dayOn === totalDayOn && dayOff === totalDayOff) {
      dayOn = 0
      dayOff = 0
    }
  }

  return count
}
