import { APP_SITE } from '@/constants/app'
import { useAppTheme } from '@/hooks/use-app-theme'
import { SVGProps, useEffect, useRef, useState } from 'react'

export type AppLogoProps = SVGProps<SVGSVGElement> & {
  iconOnly?: boolean
  dark?: boolean
  iconProps?: SVGProps<SVGImageElement>
  nameProps?: SVGProps<SVGTextElement>
}

export function AppLogo({ iconOnly, dark, iconProps, nameProps, ...svgProps }: AppLogoProps) {
  const { theme } = useAppTheme()

  const showPoweredBy = APP_SITE.theme.name !== theme?.name

  const _svgImageProps: SVGProps<SVGImageElement> = {
    width: 50,
    height: 50,
    href: theme?.iconUrl,
    ...theme?.iconProps,
    ...iconProps
  }

  const _svgNameProps: SVGProps<SVGTextElement> = {
    x: 0,
    y: 0,
    fontWeight: '600',
    letterSpacing: '1px',
    fontFamily: 'system-ui',
    fontSize: '28px',
    dominantBaseline: 'middle',
    fill: dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.9)',
    ...theme?.nameProps,
    ...nameProps
  }

  /**
   * Calculate the viewBox width based on the text width
   */
  const [viewBoxWidth, setViewBoxWidth] = useState<number>(0)
  const textRef = useRef<SVGTextElement>(null)
  useEffect(() => {
    if (iconOnly) setViewBoxWidth(50 + Number(_svgImageProps?.x || 0))
    else {
      if (!textRef.current) return
      setViewBoxWidth(textRef.current.getBBox().width + 60)
    }
  }, [_svgImageProps?.x, iconOnly, textRef])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxWidth} 50`}
      className={viewBoxWidth ? '' : 'opacity-0'} // prevent flickering
      // preserveAspectRatio="none"
      {...svgProps}
    >
      <image {..._svgImageProps} />
      {!iconOnly && (
        <g transform="translate(60, 28) scale(1, 0.95)">
          <text ref={textRef} className={'select-none'} {..._svgNameProps}>
            {theme?.name}
          </text>
          {showPoweredBy && (
            <>
              <text
                fill={_svgNameProps.fill}
                x={_svgNameProps.x}
                y={21}
                fontSize={10}
                fillOpacity={0.8}
                className={'select-none'}
              >
                Powered by
              </text>
              <image
                x={Number(_svgNameProps.x || 0) + 60}
                y={10}
                height={14}
                width={14}
                href={`/assets/sites/${APP_SITE.assetsKey}/icon.svg`}
              />
              <text
                fill={_svgNameProps.fill}
                x={Number(_svgNameProps.x || 0) + 78}
                y={21}
                fontSize={10}
                fillOpacity={0.8}
                className={'select-none'}
              >
                {APP_SITE.theme.name}
              </text>
            </>
          )}
        </g>
      )}
    </svg>
  )
}
