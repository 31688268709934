import React, { useCallback, useRef, useState, useEffect } from 'react'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '@/ui'
import { mediaUrl } from '@/utils'
import styles from './ImageCropper.module.scss'
import { CropData, ImageCropperProps } from './types'

export const ImageCropper: React.FC<ImageCropperProps> = ({ attachment, onCropComplete, triggerCrop = false }) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 90,
    height: 90,
    x: 5,
    y: 5
  })
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [rotation, setRotation] = useState(0)
  const [flipHorizontal, setFlipHorizontal] = useState(false)
  const [flipVertical, setFlipVertical] = useState(false)
  const [zoom, setZoom] = useState(1)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const crop_json = JSON.parse(attachment.crop_json)
    if (crop_json) {
      const imageWidth = imageRef.current?.naturalWidth || 0
      const imageHeight = imageRef.current?.naturalHeight || 0

      setCrop({
        unit: '%',
        width: ((crop_json.src_w ?? 0) / imageWidth) * 100,
        height: ((crop_json.src_h ?? 0) / imageHeight) * 100,
        x: ((crop_json.src_x ?? 0) / imageWidth) * 100,
        y: ((crop_json.src_y ?? 0) / imageHeight) * 100
      })

      setRotation(crop_json.rotate ?? 0)
      setFlipHorizontal(crop_json.flip_x ?? false)
      setFlipVertical(crop_json.flip_y ?? false)
      setZoom(1)
    }
  }, [attachment, JSON.stringify(attachment.crop_json)])

  useEffect(() => {
    if (triggerCrop && completedCrop) {
      handleCropComplete()
    }
  }, [triggerCrop])

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      setCompletedCrop({
        x: ((crop.x ?? 0) / crop.x) * 100,
        y: ((crop.y ?? 0) / crop.y) * 100,
        width: ((crop.width ?? 0) / crop.width) * 100,
        height: ((crop.height ?? 0) / crop.height) * 100,
        unit: 'px'
      })
    },
    [crop]
  )

  const handleCropComplete = useCallback(() => {
    if (!completedCrop || !imageRef.current) return

    const scaleX = imageRef.current.naturalWidth / imageRef.current.width
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height

    const adjustedX = scaleX * imageRef.current.naturalWidth * (zoom - 1) + 2 * completedCrop.x
    const adjustedY = scaleY * imageRef.current.naturalHeight * (zoom - 1) + 2 * completedCrop.y
    const adjustedWidth = completedCrop.width / zoom
    const adjustedHeight = completedCrop.height / zoom

    const cropData: CropData = {
      src_x: adjustedX > 0 ? Math.round((adjustedX * 0.5) / zoom) : 0,
      src_y: adjustedY > 0 ? Math.round((adjustedY * 0.5) / zoom) : 0,
      src_w:
        imageRef.current.width > adjustedWidth * scaleX ? Math.round(adjustedWidth * scaleX) : imageRef.current.width,
      src_h:
        imageRef.current.height > adjustedHeight * scaleY
          ? Math.round(adjustedHeight * scaleY)
          : imageRef.current.height,
      rotate: rotation % 360,
      flip_x: flipHorizontal,
      flip_y: flipVertical,
      zoom: zoom
    }

    onCropComplete(cropData)
  }, [completedCrop, rotation, flipHorizontal, flipVertical, zoom, onCropComplete])

  const rotateLeft = () => {
    setRotation((prev) => prev - 90)
    setCrop((prev) => ({
      ...prev,
      width: prev.width,
      height: prev.height
    }))
  }

  const rotateRight = () => {
    setRotation((prev) => prev + 90)
    setCrop((prev) => ({
      ...prev,
      width: prev.width,
      height: prev.height
    }))
  }

  const toggleFlipHorizontal = () => {
    setFlipHorizontal((prev) => !prev)
  }

  const toggleFlipVertical = () => {
    setFlipVertical((prev) => !prev)
  }

  const zoomIn = () => {
    setZoom((prev) => Math.min(prev + 0.1, 4))
  }

  const zoomOut = () => {
    setZoom((prev) => Math.max(prev - 0.1, 0.5))
  }

  return (
    <div className="flex flex-col items-center gap-18 pt-3">
      <div className={styles.ImageCropper}>
        <ReactCrop
          crop={crop}
          onChange={(c: Crop) => setCrop(c)}
          onComplete={(c: PixelCrop) => setCompletedCrop(c)}
          aspect={undefined}
        >
          <img
            ref={imageRef}
            src={mediaUrl(attachment.original ?? attachment.image)}
            onLoad={onImageLoad}
            alt="Cropped"
            className="max-w-full"
            style={{
              transform: `rotate(${rotation}deg) scale(${flipHorizontal ? -1 : 1}, ${flipVertical ? -1 : 1}) scale(${zoom})`,
              transition: 'transform 0.2s ease-in-out'
            }}
          />
        </ReactCrop>
      </div>
      <div className="flex gap-8 mb-4">
        <Button
          onClick={zoomIn}
          size={'small'}
          shape={'default'}
          type={'default'}
          iconName={'fa:magnifying-glass-plus'}
          disabled={zoom >= 4}
        />
        <Button
          onClick={zoomOut}
          size={'small'}
          shape={'default'}
          type={'default'}
          iconName={'fa:magnifying-glass-minus'}
          disabled={zoom <= 0.5}
        />
        <Button onClick={rotateRight} size={'small'} shape={'default'} type={'default'} iconName={'fa:rotate-right'} />
        <Button onClick={rotateLeft} size={'small'} shape={'default'} type={'default'} iconName={'fa:rotate-left'} />
        <Button
          onClick={toggleFlipHorizontal}
          size={'small'}
          shape={'default'}
          type={'default'}
          iconName={'svg:flip-horizontal'}
        />
        <Button
          onClick={toggleFlipVertical}
          size={'small'}
          shape={'default'}
          type={'default'}
          iconName={'svg:flip-vertical'}
        />
      </div>
    </div>
  )
}
