import { z } from 'zod'

export const JobCodeSchema = z.object({
  id: z.string(),
  user_job_code_no: z.string(),
  description: z.string(),
  external_id: z.string(),
  modified_at: z.string()
})

export type JobCode = z.infer<typeof JobCodeSchema>
