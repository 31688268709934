/*
 * This file is used to generate the configuration of the date picker.
 * It is required as Ant Design does not provide any formal method set custom timezone for the date picker.
 * It is copied from (https://github.com/react-component/picker/blob/master/src/generate/dayjs.ts)
 * and modified to use the timezone plugin of dayjs.
 * Important! During update keep in sync with the original file.
 * */

import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { GenerateConfig } from 'rc-picker/es/generate'

const generateConfig: GenerateConfig<Dayjs> = {
  // get
  getNow: () => dayjs().tz(),
  getFixedDate: (string) => dayjs(string, ['YYYY-M-DD', 'YYYY-MM-DD']).tz(),
  getEndDate: (date) => date.endOf('month'),
  getWeekDay: (date) => {
    const clone = date.locale('en')
    return clone.weekday() + clone.localeData().firstDayOfWeek()
  },
  getYear: (date) => date.year(),
  getMonth: (date) => date.month(),
  getDate: (date) => date.date(),
  getHour: (date) => date.hour(),
  getMinute: (date) => date.minute(),
  getSecond: (date) => date.second(),
  getMillisecond: (date) => date.millisecond(),

  // set
  addYear: (date, diff) => date.add(diff, 'year'),
  addMonth: (date, diff) => date.add(diff, 'month'),
  addDate: (date, diff) => date.add(diff, 'day'),
  setYear: (date, year) => date.year(year),
  setMonth: (date, month) => date.month(month),
  setDate: (date, num) => date.date(num),
  setHour: (date, hour) => date.hour(hour),
  setMinute: (date, minute) => date.minute(minute),
  setSecond: (date, second) => date.second(second),
  setMillisecond: (date, milliseconds) => date.millisecond(milliseconds),

  // Compare
  isAfter: (date1, date2) => date1.isAfter(date2),
  isValidate: (date) => date.isValid(),

  locale: {
    getWeekFirstDay: (locale) => dayjs().tz().localeData().firstDayOfWeek(),
    getWeekFirstDate: (locale, date) => date.weekday(0),
    getWeek: (locale, date) => date.week(),
    getShortWeekDays: (locale) => dayjs().tz().localeData().weekdaysMin(),
    getShortMonths: (locale) => dayjs().tz().localeData().monthsShort(),
    format: (locale, date, format) => date.format(format),
    parse: (locale, text, formats) => {
      try {
        for (let i = 0; i < formats.length; i += 1) {
          const date = dayjs(text, formats[i]).tz(dayjs.$defaultTimezone, true)
          if (date.isValid()) {
            return date
          }
        }

        return null
      } catch (err) {
        return null
      }
    }
  }
}

export default generateConfig
