import { customEmailApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export const useDeleteCustomEmail = () => {
  const deleteCustomEmailMutation = useMutation({
    ...customEmailApi.delete()
  })

  const deleteCustomEmail = async (id: number) => {
    await deleteCustomEmailMutation.mutateAsync({ id })
  }

  return {
    deleteCustomEmail,
    deleteCustomEmailMutation
  }
}
