import { Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedRoleIdAtom, selectedRolesAtom } from '../atoms'

import { useFetchProfile } from '../../hooks'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'

export const TicketStatus = () => {
  const ticketList = [
    {
      label: 'Draft',
      value: 0
    },
    {
      label: 'Draft - Created',
      value: 1
    },
    {
      label: 'Draft - Open',
      value: 2
    },
    {
      label: 'Draft - Needs Signature',
      value: 3
    },
    {
      label: 'Draft - Signed',
      value: 4
    },
    {
      label: 'Draft - Disputed',
      value: 5
    },

    {
      label: 'Draft - Reviewed',
      value: 6
    },
    {
      label: 'Draft - Closed',
      value: 7
    },
    {
      label: 'Draft - Archived',
      value: 8
    },
    {
      label: 'Draft - Deleted',
      value: 9
    }
  ]
  const [ticket, setTicket] = useState<number | null | undefined>(null)
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)
  const [selectedData, setSelectedData] = useState<number>()
  const filterOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  const { data: profileData } = useFetchProfile()
  const selectedRoleId = useAtomValue(selectedRoleIdAtom)
  const currentRoleId = profileData?.role
  useEffect(() => {
    if (selectedRole && selectedRole?.data && 'ticket_status_limit' in selectedRole.data) {
      setTicket(selectedRole?.data?.ticket_status_limit)
    }
  }, [selectedRole])
  const checkPermission = () => {
    if (currentRoleId !== selectedRoleId && selectedRoleId !== 0) {
      setEnableRoleModalState(true)
      return false
      // eslint-disable-next-line no-extra-boolean-cast
    } else if (!!selectedRoleId) {
      setChangeRoleModalState(true)
      return false
    } else {
      return true
    }
  }
  const onChange = (val: number) => {
    const pass = checkPermission()

    setSelectedData(val)
    if (pass) {
      setSelectedRole({
        ...selectedRole,
        data: {
          ...selectedRole?.data,
          ticket_status_limit: val
        }
      })
    }
  }

  const handleOkClick = () => {
    setSelectedRoleId(0)
    if (selectedRole) {
      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          enabled_groups: profileData?.userdata_json?.enabled_groups,
          tab_permissions: profileData?.userdata_json?.tab_permissions,
          enabled_modules: profileData?.userdata_json?.enabled_modules,
          enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
          disabled_forms: profileData?.userdata_json?.disabled_forms,
          advance_settings: profileData?.advance_settings,
          ticket_status_limit: selectedData
        }
      })
    }
    setChangeRoleModalState(false)
  }
  const handleCancelClick = () => {
    setChangeRoleModalState(false)
  }
  const handleRoleOkClick = () => {
    setEnableRoleModalState(false)
  }
  const handleRoleCancelClick = () => {
    setEnableRoleModalState(false)
  }
  const handleChangeRoleModalActiveState = (state: boolean) => {
    setChangeRoleModalState(state)
  }
  const handleEnableRoleModalActiveState = (state: boolean) => {
    setEnableRoleModalState(state)
  }
  return (
    <>
      <div className={'w-full lg:w-[50%]  xl:w-[25%]    mb-32'}>
        <AccessListItemHeader title="Ticket Status Limit" />
        <div className=" px-24 pt-32  border lg:border-0 lg:border-r  border-gray-200 h-full">
          <p className="mb-4 text-sm text-warning-500">User cannot disposition a ticket past this stage</p>
          <div>
            <Select
              className="w-full"
              showSearch
              placeholder="Select a ticket status"
              onChange={onChange}
              filterOption={filterOption}
              value={ticket}
              options={ticketList}
            />
          </div>
        </div>
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={handleChangeRoleModalActiveState}
        onOk={handleOkClick}
        onCancel={handleCancelClick}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={handleEnableRoleModalActiveState}
        onOk={handleRoleOkClick}
        onCancel={handleRoleCancelClick}
      />
    </>
  )
}
