import { useApp } from '@/hooks'
import { v2JasResourceApi } from '@/services/api-service'
import { SaveButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { patchChangesAtom } from '../atoms'

export function SaveOverrides() {
  const { notification } = useApp()
  const [patchChanges, setPatchChanges] = useAtom(patchChangesAtom)
  const batchMutation = useMutation(v2JasResourceApi.savePatches())

  const handleSave = useCallback(async () => {
    const changedItems = Object.entries(patchChanges).map(([key, value]) => {
      const [resourceId, date] = key.split('|')
      return { resource: Number(resourceId), start_date: date, end_date: date, value: value }
    })

    await batchMutation
      .mutateAsync({ items: changedItems })
      .then(() => {
        notification.success({ message: 'Overrides Saved', description: 'The changes have been saved successfully.' })
      })
      .catch((e) => {
        notification.error({
          message: 'Overrides Save Failed',
          description: e?.message || 'An error occurred while saving the changes.'
        })
      })

    // reset after delay to prevent flicker
    setTimeout(() => setPatchChanges({}), 500)
  }, [patchChanges])

  return (
    <SaveButton loading={batchMutation.isLoading} showReturn={false} onSave={handleSave}>
      Save Overrides
    </SaveButton>
  )
}
