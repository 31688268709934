import { CalendarTable, CalendarViewSelect, DistrictSelect, SkillSelect } from '@/components'
import { CalendarTableDropdownCell } from '@/components/calendar-table/calendar-table-dropdown-cell'
import { TitleCellPopover } from '@/components/calendar-table/title-cell-popover'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { useAtomValue } from 'jotai/index'
import { useCallback, useEffect, useMemo } from 'react'
import { useDeletePatchMutation, useMultipleScheduleView } from '../hooks'
import { EmployeeSearch } from './employee-search'
import { useCalendarHiddenRows } from './hooks'
import { ScheduleTypeSelect } from './schedule-type-select'
import { TableTitleHeadCell } from './table-title-head-cell'

export const EmployeesView = () => {
  const { query, state, dispatch, handlePatchMutation } = useMultipleScheduleView({
    type__iexact: 'employee'
  })

  const { handleDeletePatchMutation } = useDeletePatchMutation({ onSuccess: query.refetch })
  const { selectedSkills, date, searchText, selectedDistricts } = state
  const calendarView = useAtomValue(calendarViewAtom)
  const employees = useMemo(() => query.data?.pages?.flat() || [], [query.data?.pages])

  const { hiddenRows, resetFilter } = useCalendarHiddenRows(employees, date)

  const handleSearchTextChange = useCallback(
    (value: string) => {
      dispatch({ type: 'setSearchText', payload: value })
    },
    [dispatch]
  )

  useEffect(() => {
    return resetFilter
  }, [resetFilter])

  return (
    <div className="h-[calc(100vh-96px)] relative">
      <div className="flex gap-x-12 items-center mb-12">
        <EmployeeSearch searchText={searchText} onChange={handleSearchTextChange} />
        <DistrictSelect
          value={selectedDistricts}
          onChange={(value) => dispatch({ type: 'setSelectedDistricts', payload: value })}
        />
        <SkillSelect
          type="employee"
          value={selectedSkills}
          onChange={(value) => dispatch({ type: 'setSelectedSkills', payload: value })}
        />
        <div className="flex ml-auto">
          <CalendarViewSelect />
        </div>
      </div>
      <CalendarTable
        title={<TableTitleHeadCell onClick={() => dispatch({ type: 'toggleQueryOrder' })} />}
        withFooter
        visibleDays={calendarView}
        data={employees}
        date={date}
        loading={query.isLoading || query.isFetchingNextPage || query.isRefetching}
        onDateChange={(date) => dispatch({ type: 'setDate', payload: date })}
        onLoadMore={() => {
          if (query.hasNextPage) query.fetchNextPage()
        }}
        renderTitleCell={(employee) => <TitleCellPopover resource={employee} />}
        tableTopRight={<ScheduleTypeSelect />}
        hiddenRows={hiddenRows}
        renderDateCell={(date, employee) => (
          <CalendarTableDropdownCell
            date={date.format('YYYY-MM-DD')}
            data={employee}
            isShortForm={calendarView === 'month'}
            onMenuItemClick={(type, selectedDates) =>
              type === 'DELETE'
                ? handleDeletePatchMutation(employee, selectedDates)
                : handlePatchMutation(employee, selectedDates, type)
            }
          />
        )}
      />
    </div>
  )
}
