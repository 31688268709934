import { contactApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui/select'
import { get, isEmpty } from 'lodash'
import { FC } from 'react'

const statusFilterMap: Record<string, any> = {
  active: 1,
  inactive: 0,
  all: undefined
}

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiSearchBy' | 'mapOption' | 'renderOption' | 'status'> & {
  status?: 'active' | 'inactive' | 'all'
}

const getOptionFormatter = (valueBy = 'profile__user_id') => {
  return (item: any) => ({
    value: get(item, valueBy),
    label: (
      <span>
        {item.first_name} {item.last_name} <span className={'font-medium'}>({item.profile__user__username})</span>
      </span>
    )
  })
}

const searchBy = (text?: string) => ({
  'Q[]': isEmpty(text)
    ? {}
    : [
        `first_name__icontains|${text}`,
        `last_name__icontains|${text}`,
        'or',
        `profile__user__username__icontains|${text}`,
        'or'
      ]
})

export const UserSelect: FC<Props> = ({
  status = 'active',
  apiValueBy = 'profile__user_id',
  apiQueryParams,
  ...props
}) => {
  const formatOption = getOptionFormatter(apiValueBy)
  return (
    <QuerySelect
      className={'w-full h-full'}
      apiEndpoint={contactApi.list}
      apiQueryParams={{
        order: 'first_name',
        fields: `${apiValueBy},profile__user__username,first_name,last_name`,
        profile__user__username__isnull: false,
        profile__user__is_active__eq: statusFilterMap[status],
        registered_only: true,
        ...apiQueryParams
      }}
      apiValueBy={apiValueBy}
      apiSearchBy={searchBy}
      renderOption={formatOption}
      {...props}
    />
  )
}
