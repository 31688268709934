import { customEmailApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { useAtomValue } from 'jotai'
import { FC, useCallback, useMemo } from 'react'
import { customFormAtom } from '../../atoms'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiQueryParams' | 'apiSearchBy' | 'renderOption' | 'mapOption'>

export const CustomEmailSelect: FC<Props> = (props) => {
  const customForm = useAtomValue(customFormAtom)

  const queryParam = useMemo(
    () => ({ custom_form__eq: customForm?.id, fields: 'id,name', order: 'name' }),
    [customForm?.id]
  )

  const renderOption = useCallback(
    (item: any) => ({
      value: item.id,
      label: item.name
    }),
    []
  )

  return (
    <QuerySelect
      apiEndpoint={customEmailApi.list}
      apiQueryParams={queryParam}
      apiSearchBy={'name'}
      renderOption={renderOption}
      {...props}
    />
  )
}
