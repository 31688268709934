import { CodeEditor } from '@/components/code-editor'
import { Button } from '@/ui/button'
import { Form, Modal } from 'antd'
import { useState } from 'react'

export const AdvancedConfigModal = () => {
  const form = Form.useFormInstance()
  const [isOpen, setIsOpen] = useState(false)
  const oldConfig = form.getFieldValue('config_json')
  const oldData = form.getFieldValue('data_json')
  const toggleModal = () => setIsOpen((prev) => !prev)
  const dataJson = Form.useWatch('data_json', form) ?? ''
  const configJson = Form.useWatch('config_json', form) ?? ''

  const handleCancel = () => {
    form.setFieldsValue({ config_json: oldConfig, data_json: oldData })
    toggleModal()
  }

  return (
    <>
      <Button type="primary" onClick={toggleModal}>
        Advanced configurations
      </Button>
      <Modal title="Edit Config and Data" open={isOpen} width={800} onCancel={handleCancel} onOk={toggleModal}>
        <div>Advanced module configuration. Enable, disable, and configure module-specific features.</div>
        <div className="grid grid-cols-2 gap-x-24">
          <Form.Item
            // Force re-render.
            // AceEditor is not updating value, but only when clicked on editor
            key={configJson}
            labelCol={{ span: '24', className: '!p-0' }}
            label="Config JSON"
            name="config_json"
          >
            <CodeEditor height="300px" mode="json" />
          </Form.Item>
          <Form.Item
            // Force re-render.
            // AceEditor is not updating value, but only when clicked on editor
            key={dataJson}
            labelCol={{ span: '24', className: '!p-0' }}
            label="Data JSON"
            name="data_json"
          >
            <CodeEditor height="300px" mode="json" />
          </Form.Item>
        </div>
      </Modal>
    </>
  )
}
