import { InputNumber } from 'antd'
import { useMemo } from 'react'

type Props = {
  lineItem: any
}

export const AmountField = ({ lineItem }: Props) => {
  const calculatedAmount = useMemo(() => {
    let amount = Number(lineItem.amount || 0)
    const unitsStandby = Number(lineItem.units_standby) || 0
    const standbyRate = Number(lineItem.standby_rate) || 0
    const unitsMin = Number(lineItem.units_min) || 0
    const usageRate = Number(lineItem.usage_rate) || 0
    const hands = Number(lineItem.hands) ? lineItem.hands : 1
    const unitsUsed = Math.max(unitsMin, Number(lineItem.units_used) || 0)
    const discount = Number(lineItem.discount) || 0
    const minimum = Number(lineItem.minimum) || 0

    if (!(unitsStandby || standbyRate || unitsUsed || usageRate)) {
      return amount
    }

    amount = (1 - (discount || 0) / 100) * (unitsStandby * standbyRate + hands * unitsUsed * usageRate)

    if (minimum) {
      amount = Math.max(amount, minimum)
    }

    return amount
  }, [lineItem])

  return <InputNumber value={calculatedAmount} className="w-full" data-cy="custom-form-line-item-amount-input" />
}
