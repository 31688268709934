import { Company } from '@/types/company'
import { EditCompanyView } from '../../../edit-company-view'

const EditCompaniesView = ({ company, isPrivate }: { company: Company; isPrivate: boolean }) => {
  return (
    <div className="px-16 py-16">
      <EditCompanyView company={company} isPrivate={isPrivate} />
    </div>
  )
}

export { EditCompaniesView }
