import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { Form, Space, Spin } from 'antd'
import { isAxiosError } from 'axios'
import { AddEditCompanyForm, FormValues } from '../../components/add-edit-company-form'
import { useUpsertCompanyMutation } from '../../hooks'

type Props = {
  isPrivate?: boolean
}

export const AddCompanyView = ({ isPrivate }: Props) => {
  const [form] = Form.useForm<FormValues>()
  const { notification } = useApp()
  const { handleUpsertCompanyMutation, createCompanyMutation } = useUpsertCompanyMutation()

  const handleSave = async () => {
    try {
      const formValues = await form.validateFields()
      const company = await handleUpsertCompanyMutation(formValues)

      notification.success({
        message: 'Company saved successfully'
      })

      // setTimeout(() => {
      //   navigate(isPrivate ? ROLODEX_PATHS.editPrivateCompany(company.id) : ROLODEX_PATHS.editCompany(company.id))
      // }, 1000)
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        if (error.response?.data?.detail) {
          notification.error({ message: error.response?.data?.detail })
        } else {
          notification.error({ message: 'Something went wrong' })
        }
      } else {
        notification.error({
          message: 'Please fill out all required fields'
        })
      }
    }
  }

  const title = isPrivate ? 'Add Private Company' : 'Add Company'

  return (
    <Spin spinning={createCompanyMutation.isLoading}>
      <div className="flex justify-between mb-16 mt-12 items-center">
        <PageTitle>{title}</PageTitle>
        <Space>
          <Button
            data-cy="save-company"
            iconName="mi:save"
            type="success"
            loading={createCompanyMutation.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </Space>
      </div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          public: isPrivate ? false : true
        }}
      >
        <AddEditCompanyForm />
      </Form>
    </Spin>
  )
}
