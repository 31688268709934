import { LocalDateRangePicker, LocalDateRangePickerProps } from '@/ui/date'
import dayjs from 'dayjs'
import { FC, useCallback, useMemo } from 'react'
import { parsePeriodDates } from '../../../helpers'

type Props = Omit<LocalDateRangePickerProps, 'value' | 'onChange'> & {
  value?: string | null
  onChange?: (value?: string | null) => void
}

export const PayPeriodPicker: FC<Props> = ({ value, onChange, ...props }) => {
  const period = useMemo(() => parsePeriodDates(value, dayjs.now()), [value])

  const handleRangeChange = useCallback(
    (_: any, [startStr, endStr]: any) => {
      onChange?.(`${dayjs.formatLocalDate(startStr)}-${dayjs.formatLocal(endStr)}`)
    },
    [onChange]
  )

  return <LocalDateRangePicker value={[period.start, period.end]} onChange={handleRangeChange} {...props} />
}
