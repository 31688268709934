import { useModalState } from '@/hooks'
import { Button, Modal } from '@/ui'
import { FC } from 'react'
import { useCustomForm } from '../../../../hooks'
import { ReportForm } from './report-form'
import { ButtonConfig } from './types'

export const ReportButtons: FC = () => {
  const { features } = useCustomForm()

  return (
    <>
      {features.reportButtons.map((config, idx) => (
        <ReportButton key={idx} config={config} />
      ))}
    </>
  )
}

const ReportButton: FC<{ config: ButtonConfig }> = ({ config }) => {
  const { isOpen, openModal, closeModal } = useModalState()

  const iconName = `mi:${config?.icon || 'description'}` as any
  const label = config?.label || 'Report'

  return (
    <>
      <Button primary size={'small'} iconName={iconName} iconClassName={'!text-[15px]'} onClick={openModal}>
        {label}
      </Button>
      <Modal
        open={isOpen}
        width={'550px'}
        title={`Download ${label}`}
        iconName={'fa:download'}
        onCancel={closeModal}
        footer={null}
      >
        <ReportForm config={config} />
      </Modal>
    </>
  )
}
