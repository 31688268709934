import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { v2JasResourceApi } from '@/services/api-service'
import { useParams } from 'react-router-dom'

export function ResourceActivities() {
  const { resourceId } = useParams()

  if (!resourceId) return null

  return (
    <div className="mt-30">
      <h5 className="font-bold">Recent Activity</h5>
      <ActivityLogsModal query={v2JasResourceApi.events} queryVariables={{ target_id__eq: resourceId }} />
      <div className="mt-16">
        <RecentActivities api={v2JasResourceApi.events} params={{ target_id__eq: resourceId }} />
      </div>
    </div>
  )
}
