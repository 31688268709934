import { LinkButton, LinkButtonProps } from '@/ui'
import { useModuleLink } from '../hooks'

export type ModuleLinkButtonProps = LinkButtonProps & {
  by: 'id' | 'type'
  value: any
  urlSuffix?: string
}

export function ModuleLinkButton({ by, value, urlSuffix, ...props }: ModuleLinkButtonProps) {
  const { getLink } = useModuleLink()
  return <LinkButton to={getLink(by, value, urlSuffix)} {...props} />
}
