import { AppLink } from '@/ui'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { SCHEDULE_PATHS } from '../../constants'

type EmployeeRowProps = {
  id: number
  name: string
}

export const EmployeeRow: FC<EmployeeRowProps> = ({ name, id }) => {
  const { moduleId } = useParams<{ moduleId: string }>()

  return (
    <AppLink to={SCHEDULE_PATHS.employee(moduleId, id)}>
      <p className="text-[#5C5C5C] decoration-solid underline underline-offset-4 decoration-[#5C5C5C] cursor-pointer">
        {name}
      </p>
    </AppLink>
  )
}
