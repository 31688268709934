import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES } from '@/constants/general'
import { ListResponse } from '@/types/api/core'
import { UseQueryResult } from '@tanstack/react-query'
import { atom } from 'jotai'
import { Profile } from './types'

type OrderBy =
  | 'id'
  | 'user__first_name'
  | 'user__username'
  | 'user__email'
  | 'user__is_staff'
  | 'user__is_active'
  | 'role__name'
  | '-id'
  | '-user__first_name'
  | '-user__username'
  | '-user__email'
  | '-user__is_staff'
  | '-role__name'

export const searchTextAtom = atom('')
export const selectedRoleAtom = atom(-1)
export const pageAtom = atom(1)
export const orderByAtom = atom<OrderBy>('-id')
export const limitAtom = atom(TABLE_DEFAULT_PAGE_SIZE)
export const pageSizeAtom = atom(TABLE_PAGE_SIZES)
export const allRolesAtom = atom<{ value: number | null; label: string | null }[] | []>([])

export const profileQueryReferenceAtom = atom<UseQueryResult<ListResponse<Profile>> | null>(null)
