import { ActionButton } from '@/ui'
import cn from 'classnames'
import { FC, useState } from 'react'
import { CalendarNote, CalendarTicket } from '../../../schemas'

type EventsPreviewProps = { items: CalendarTicket[]; notes: CalendarNote[] }

export const EventsPreview: FC<EventsPreviewProps> = ({ items, notes }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  return (
    <div
      className="flex flex-col line-clamp-1 w-full h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {items.slice(0, 3).map((item) => (
        <ItemRow key={item.id} bulletColor={'bg-primary-500'} text={item._jobText || ''} />
      ))}

      {items.length < 3 &&
        notes
          .slice(0, 3 - items.length)
          .map((note) => (
            <ItemRow key={note.id} bulletColor={'bg-green'} text={note.title || note.description || ''} />
          ))}

      {items.length + notes.length > 3 && (
        <div className={'flex flex-row items-center gap-6 px-6 mb-2 mr-2 rounded-[4px] transition-colors'}>
          <span className={'w-fit text-sm line-clamp-1 text-primary'}>+{items.length + notes.length - 3} more...</span>
        </div>
      )}

      {isHovered && (
        <div className="flex justify-end">
          <ActionButton primary iconName={'fa:comment-plus'} className="absolute right-0 bottom-0" onClick={() => {}} />
        </div>
      )}
    </div>
  )
}

const ItemRow: FC<{ bulletColor: string; text: string }> = ({ bulletColor, text }) => {
  return (
    <div
      className={cn(
        // 'bg-primary-100 border-l-2 border-l-primary',
        'flex flex-row items-center gap-6 px-6 mb-2 mr-2 rounded-[4px] transition-colors'
      )}
    >
      <div className={cn('rounded-full h-[4px] w-[4px]', bulletColor)} />
      <span className={'w-fit text-sm line-clamp-1'}>{text}</span>
    </div>
  )
}
