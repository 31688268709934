import { useViewData } from '@/layouts'
import { useAtom } from 'jotai'
import { FC, useEffect, useMemo } from 'react'
import { ScheduleViewData } from '../../schemas'
import { selectedJobSkillsAtom } from '../atoms'
import { useJobResourceSelection, useJobSkills, useJobTicket } from '../hooks'
import { JobRequirement } from './JobRequirement'
import { SkillTag } from './SkillTag'

export const JobRequirementFilters: FC = () => {
  const { data } = useViewData<ScheduleViewData>()
  const { jobTicket } = useJobTicket()
  const { skills: jobSkills } = useJobSkills()
  const { selectedResources } = useJobResourceSelection()
  const [selectedJobSkills, setSelectedJobSkills] = useAtom(selectedJobSkillsAtom)

  const requirementData = useMemo(
    () => ({
      required: jobTicket?._jobData?.requirements?.byType?.[data.type] || 0,
      assigned: selectedResources.length,
      res_items: selectedResources
    }),
    [jobTicket, selectedResources]
  )

  const skills = useMemo(
    () =>
      (jobSkills || []).map((skill) => ({
        skill: {
          label: skill.label,
          value: skill.key
        },
        resources: selectedResources.filter((res) => Object.keys(res.attributes).includes(skill.key))
      })),
    [jobSkills, selectedResources]
  )

  const onSkillsSelectChange = (skill: string, selected: boolean) => {
    if (selected) setSelectedJobSkills([...selectedJobSkills, skill])
    else setSelectedJobSkills(selectedJobSkills.filter((item) => item !== skill))
  }

  useEffect(() => {
    if (jobSkills) {
      setSelectedJobSkills(jobSkills.map((sk) => sk.key))
    }
  }, [jobSkills])

  return (
    <div className="flex flex-row flex-wrap gap-10">
      {skills.map((item) => (
        <SkillTag
          key={item.skill.value}
          isSelected={selectedJobSkills.includes(item.skill.value)}
          skill={item.skill}
          resources={item.resources}
          onSelectChange={onSkillsSelectChange}
        />
      ))}
      <JobRequirement label={data.label} data={requirementData} />
    </div>
  )
}
