import { useApp } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { clientRecordApi } from '@/services/api-service'
import { QueryDeleteButton } from '@/ui'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { ModalButton } from './modal-button'
import { CustomRecord, CustomRecordSchema } from './schemas'

export const CustomRecordListView = view(Component, {
  title: ({ params }) => params?.label || 'All'
})

function Component() {
  const { l } = useApp()
  const { label } = useParams()
  const isAll = !label || label === 'All'

  return (
    <ListView
      hideHeader
      filter={{
        search: {
          searchBy: (text) => ({
            Q: text
              ? [
                  `label__icontains|${text}`,
                  `char_1__icontains|${text}`,
                  'or',
                  `char_2__icontains|${text}`,
                  'or',
                  `char_3__icontains|${text}`,
                  'or',
                  `char_4__icontains|${text}`,
                  'or'
                ]
              : undefined
          })
        }
      }}
      table={{
        queryApi: clientRecordApi.list,
        queryParams: { order: 'label', fields: CustomRecordSchema, label__eq: isAll ? undefined : label },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'id', title: 'ID', width: 80 },
          { dataIndex: 'label', title: 'Type', hidden: !isAll },
          { dataIndex: 'char_1', title: 'Field 1' },
          { dataIndex: 'char_2', title: 'Field 2' },
          { dataIndex: 'char_3', title: 'Field 3' },
          { dataIndex: 'char_4', title: 'Field 4' },
          { dataIndex: 'modified_at', title: 'Last Updated', render: getModified, width: 220 },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: CustomRecord) => (
              <div>
                <ModalButton iconName={'fa:edit'} customRecord={row} data-cy={'edit-record-button'} />
                <QueryDeleteButton id={row.id} api={clientRecordApi.delete} name={l('CustomRecord')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}

const getModified = (value: string) => dayjs.parse(value)?.formatLocal()
