import { useViewData } from '@/layouts'
import { ListView } from '@/layouts/views'
import { useAllSkills } from '@/modules/jas/resource-manager/hooks'
import { view } from '@/routing'
import { v2JasResourceApi } from '@/services/api-service'
import { LinkButton, Popover, QueryDeleteButton, QueryEditButton } from '@/ui'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { uniq } from 'lodash'
import { z } from 'zod'
import { RESOURCE_STATUS_MAP } from '../../constants'
import { ResourceTypeOption } from '../../types'

type ViewData = ResourceTypeOption // from parent layout

export const ResourceListView = view<any, ResourceTypeOption>(ViewComponent, { name: 'ResourceListView' })

function ViewComponent() {
  const { data: resourceType } = useViewData<ViewData>()
  const { getSkillLabel } = useAllSkills()

  return (
    <ListView
      key={resourceType.type}
      header={{
        accent: false,
        border: false,
        title: resourceType.label,
        actions: [
          <LinkButton key={'add'} to={'add'} type={'primary'} iconName={'fa:plus'}>
            Add {resourceType.type}
          </LinkButton>
        ]
      }}
      filter={{ search: { searchBy: 'name' } }}
      table={{
        queryApi: v2JasResourceApi.list,
        queryParams: { type__eq: resourceType.type, order: 'name', fields: ResourceSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'short_code', title: 'Code', width: 60 },
          { dataIndex: 'name', title: `${resourceType.type} Name` },
          {
            dataIndex: 'status',
            title: 'Status',
            render: (status) => (
              <Tag color={RESOURCE_STATUS_MAP[status]?.color}>{RESOURCE_STATUS_MAP[status]?.label || status}</Tag>
            )
          },
          {
            dataIndex: 'job_roles',
            title: 'Job Role',
            render: (roles: Resource['job_roles']) => roles?.[0]?.char_1 || ''
          },
          /*{
            dataIndex: 'equipments',
            title: 'SKU Records',
            render: (skus: Resource['equipments']) => (
              <div>
                {skus?.map((sku) => (
                  <Tag className="mb-4" key={sku.id}>
                    {sku.description}
                  </Tag>
                ))}
              </div>
            )
          },*/
          {
            dataIndex: 'attributes',
            title: 'Skills',
            render: (attrs) => {
              const skills = uniq(Object.keys(attrs || {})).map(getSkillLabel)
              const skillsString = uniq(skills).join(', ')

              return (
                <Popover
                  placement={'top'}
                  content={
                    <div className={'flex flex-wrap gap-6 max-w-[250px]'}>
                      {skills.map((s) => (
                        <Tag key={s} className={'m-0'}>
                          {s}
                        </Tag>
                      ))}
                    </div>
                  }
                >
                  <div className={'line-clamp-1'}>{skillsString}</div>
                </Popover>
              )
            }
          },
          { dataIndex: 'district', title: 'District' },
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Resource) => (
              <div>
                <QueryEditButton to={`${row.id}/edit`} />
                <QueryDeleteButton
                  id={row.id}
                  api={v2JasResourceApi.delete}
                  name={'Resource'}
                  disabled={Boolean(row.contact_id)}
                  title={
                    row.contact_id
                      ? 'Resource linked to a contact. To delete, unlink the contact first; otherwise, set the resource to Inactive or Closed as an alternative.'
                      : undefined
                  }
                />
              </div>
            )
          }
        ]
      }}
    />
  )
}

const ResourceSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
  type: z.string().nullish(),
  status: z.string().nullish(),
  short_code: z.string().nullish(),
  contact_id: z.number().nullish(),
  job_roles: z.array(z.object({ id: z.number(), char_1: z.string() })).nullish(),
  attributes: z.record(z.string(), z.string()).nullish(),
  // equipments: z.array(z.object({ id: z.number(), description: z.string() })).nullish(),
  district: z.string().nullish(),
  modified_at: z.string().nullish()
})

type Resource = z.infer<typeof ResourceSchema>
