import { view } from '@/routing'
import { Outlet } from 'react-router-dom'
import { RESOURCE_TYPE_MAP } from './constants'
import { ResourceTypeOption } from './types'

export const ResourcesLayout = view<any, ResourceTypeOption>(Outlet, {
  name: 'ResourcesLayout',
  title: ({ data }) => data.label,
  loader: ({ params }) => {
    const resourceType = params.resourceType ? RESOURCE_TYPE_MAP[params.resourceType] : null
    if (!resourceType) throw new Error('Invalid resource type')
    return resourceType
  }
})
