import { useSession } from '@/hooks'
import { categoryKindApi } from '@/services/api-service'
import { AppLink, Icon, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Space } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { customFormAtom } from '../../../atoms'
import { ContactSearchField } from './conact-search-field'

const groupItemOptions = [
  {
    label: 'By category',
    value: 'group_line_items'
  },
  {
    label: 'By item configuration',
    value: 'group_by_kind'
  },
  {
    label: 'By other configuration',
    value: 'force_kind'
  }
]

const options1 = [
  {
    label: 'Show "Assignee" field on the ticket',
    key: 'show_assignee',
    docLink: 'https://training.oilcommand.com/books/oil-command-training/page/show-assignee'
  },
  { label: 'Show the date on these tickets', key: 'show_ticket_date' },
  {
    label: 'Allow the ticket date to be blank on the web app Attachments from Customer',
    key: 'allow_empty_date_web'
  },
  { label: 'Make the current day and time the default ticket date', key: 'auto_start_date' },
  { label: 'Show the company on the signatures of these tickets', key: 'show_signature_company' }
]

const options2 = [
  { label: 'Group line items by category', key: 'group_line_items' },
  { label: 'Show totals', key: 'show_totals' },
  { label: 'Restrict line item edits after 48 hours', key: 'restrict_lineitem_edits' },
  { label: 'Show the submission date field on the ticket edit page', key: 'show_submission_date' },
  { label: 'Enable loading line items partially on web', key: 'partial_line_items' },
  { label: 'Enable loading line items manually on web', key: 'lazy_line_items' }
]

export const TicketDisplay = () => {
  const { company } = useSession()
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)
  const categoryKindsQuery = useQuery(categoryKindApi.list({ company__eq: company.id }))
  const [groupMode, setGroupMode] = useState<string | null>(null)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }

  const onGroupModeOptionChange = (value: string) => {
    setGroupMode(value)
    setCustomForm((prev) => ({
      ...prev,
      group_line_items: false,
      group_by_kind: false
    }))

    if (value === 'force_kind') return

    setCustomForm((prev) => ({
      ...prev,
      default_group_kind: null
    }))

    if (!value) return

    setCustomForm((prev) => ({ ...prev, group_line_items: true }))
    if (value === 'group_by_kind')
      setCustomForm((prev) => ({
        ...prev,
        group_by_kind: true
      }))
  }

  useEffect(() => {
    if (customForm.group_by_kind) return setGroupMode('group_by_kind')
    if (customForm.group_line_items) return setGroupMode('group_line_items')
    if (customForm.default_group_kind) return setGroupMode('force_kind')
  }, [customForm])

  return (
    <div className="col-span-2 border-r border-border">
      <h5 className="font-bold">Ticket Display Settings</h5>
      <div className="grid grid-cols-2 gap-30">
        <div className="border-r border-border pr-16">
          {options1.map(({ label, key, docLink }) => (
            <div key={key} className="mb-12">
              <Checkbox
                data-cy={`custom-form-${key}-checkbox`}
                checked={!!customForm[key as keyof typeof customForm]}
                onChange={(e) => handleChange(e, key)}
              >
                <Space>
                  <span>{label}</span>
                  {docLink && (
                    <AppLink to={docLink} target="_blank">
                      <Icon name="fa:info-circle" />
                    </AppLink>
                  )}
                </Space>
              </Checkbox>
            </div>
          ))}
          <ContactSearchField />
        </div>
        <div>
          {options2.map(({ label, key }) => {
            if (key === 'group_line_items') {
              return (
                <div key={key} className="mb-12">
                  <div className="mb-12">
                    <Form.Item label="Group items" className="w-[200px]">
                      <Select
                        placeholder="None"
                        options={groupItemOptions}
                        popupMatchSelectWidth={false}
                        value={groupMode}
                        onChange={onGroupModeOptionChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="ml-24 mr-16">
                    {groupMode === 'force_kind' && (
                      <Form.Item label="Config">
                        <Select
                          placeholder="Select"
                          value={customForm.default_group_kind}
                          onChange={(value) =>
                            setCustomForm((prev) => ({ ...prev, default_group_kind: value || null }))
                          }
                          options={
                            categoryKindsQuery.data?.items.map((kind) => ({
                              label: kind.name,
                              value: kind.id
                            })) || []
                          }
                        />
                      </Form.Item>
                    )}
                  </div>
                </div>
              )
            }

            return (
              <div key={key} className="mb-12">
                <Checkbox
                  data-cy={`custom-form-${key}-checkbox`}
                  checked={!!customForm[key as keyof typeof customForm]}
                  onChange={(e) => handleChange(e, key)}
                >
                  {label}
                </Checkbox>
              </div>
            )
          })}
          <div>
            <Checkbox
              data-cy="custom-form-existing-lines-only-checkbox"
              checked={!!customForm['_existing_lines_only']?.enabled}
              onChange={(e) =>
                setCustomForm((prev) => ({ ...prev, _existing_lines_only: { enabled: e.target.checked } }))
              }
            >
              Show Existing Lines Items Only (Mobile Web)
            </Checkbox>
          </div>
        </div>
      </div>
    </div>
  )
}
