import { TLabels } from '@/hooks/use-app'
import { LINE_ITEM_FILTER_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_FILTER_COLUMNS } from '@/modules/ticket/columns'
import { EntityColumn } from '@/types/entity-column'
import { getColumnLabel } from '@/utils/get-column-label'
import { FilterField } from '../schema'

export const getFilterFieldName = (ff: FilterField, reportFieldAliases: { [key: string]: any }, labels: TLabels) => {
  const isUserData = ff?.path?.startsWith('user_data__')
  const column: EntityColumn | undefined = [...LINE_ITEM_FILTER_COLUMNS, ...TICKET_FILTER_COLUMNS].find(
    (col) => col.field_verbose === ff.field_verbose
  )

  if (!column) {
    return ff.field_verbose
  }

  const reportFieldAlias = reportFieldAliases[ff.field_verbose]
  return (
    (!isUserData && reportFieldAlias && ff.field + ' / ' + reportFieldAlias) ||
    (!reportFieldAlias && getColumnLabel(column, labels))
  )
}
