import { useApp } from '@/hooks'
import { ticketNoteApi } from '@/services/api-service'
import { Button, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { refreshQueries } from '../../../helpers'
import { useTicket } from '../../../hooks'
import { TicketNote } from '../../../schemas'
import { Card } from '../../ui'

export const TabNotes: FC = () => {
  const { ticket } = useTicket()

  return (
    <div className={'relative flex flex-col gap-10'}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
        {ticket.notes.map((note) => (
          <NoteCard key={note.id} note={note} />
        ))}
      </div>

      <div className={'flex flex-row items-center w-full h-full'}>
        <AddNoteButton key={ticket.notes.length} />
      </div>
    </div>
  )
}

const NoteCard: FC<{ note: TicketNote }> = ({ note }) => {
  return (
    <Card
      key={note.id}
      className={'!items-start'}
      content={
        <div className={'flex flex-col'}>
          <div className={'flex flex-row items-center justify-between border-b border-gray-100 pb-2'}>
            <div className={'flex flex-row items-center gap-10'}>
              <span className={'font-medium text-primary'}>
                {note.contact__first_name} {note.contact__last_name}
              </span>
              <div className={'text-sm text-text-muted'}>{dayjs.formatLocal(note.modified_at || note.created_at)}</div>
            </div>

            <div>
              <DeleteNoteButton note={note} />
            </div>
          </div>
          <div className={'py-6 font-sans break-all text-wrap whitespace-pre'}>{note.notes}</div>
        </div>
      }
    />
  )
}

const AddNoteButton: FC = () => {
  const [form] = Form.useForm()
  const { notification } = useApp()
  const { ticket } = useTicket()

  const [open, setOpen] = useState(false)

  const { mutateAsync, isLoading } = useMutation(ticketNoteApi.create())
  const handleAdd = async (values: any) => {
    try {
      const payload = { ...(await form.validateFields()), ticket: ticket.id }

      await mutateAsync(payload)
      await refreshQueries()

      notification.success({
        message: 'Note Added',
        description: 'Note added successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Note Add Failed',
        description: 'An error occurred while adding note'
      })
    }
  }

  return (
    <>
      <Button shape={'round'} iconName={'fa:comment-plus'} onClick={() => setOpen(true)} primary>
        Add Note
      </Button>

      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleAdd}
        okText={'Save'}
        okButtonProps={{ loading: isLoading }}
        iconName={'fa:comment-plus'}
        title={'Add Note'}
      >
        <Form form={form} layout={'vertical'}>
          <div className={'flex flex-row gap-10'}>
            <Form.Item name={'notes'} label={'Notes'} className={'w-full'} rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} maxLength={1024} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}

const DeleteNoteButton: FC<{ note: TicketNote }> = ({ note }) => {
  const { notification } = useApp()

  const { mutateAsync, isLoading } = useMutation(ticketNoteApi.delete())

  const handleDelete = async () => {
    try {
      await mutateAsync({ id: note.id })
      await refreshQueries()

      notification.success({
        message: 'Note Deleted',
        description: 'Note deleted successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Note Delete Failed',
        description: 'An error occurred while deleting note'
      })
    }
  }

  return (
    <Popconfirm
      title={`Deleting Note of ${note.contact__first_name} ${note.contact__last_name}`}
      description={'Are you sure?'}
      onConfirm={handleDelete}
    >
      <Button size={'small'} shape={'circle'} type={'text'} iconName={'fa:trash'} danger loading={isLoading} />
    </Popconfirm>
  )
}
