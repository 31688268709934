import { Form, Select } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { PropertiesSelect } from '../properties-select'
import { TableViewSelect } from '../table-view-select'

export const ModuleTypeLineItemsFields = () => {
  const form = Form.useFormInstance()
  const stagesFormId = Form.useWatch(['data', 'stages_form_id'], form) as number | undefined

  return (
    <div>
      <div className="flex gap-x-16">
        <TableViewSelect />
        <ForceViewCheckbox />
      </div>
      <div className="flex gap-x-16">
        <CustomFormSelect
          formItemProps={{
            label: 'Custom Form (for Labels and Properties Selection)',
            name: ['data', 'stages_form_id']
          }}
        />
        <PropertiesSelect
          customFormsIds={stagesFormId ? [stagesFormId] : []}
          formItemProps={{ label: 'Search Properties', name: ['data', 'search_props'] }}
          selectProps={{ mode: 'multiple' }}
        />
        <Form.Item label="Edit button position" name={['data', 'edit_button_position']}>
          <Select
            placeholder="Select"
            options={[
              {
                label: 'Left',
                value: 'left'
              },
              {
                label: 'Right',
                value: 'right'
              },
              {
                label: 'Hidden',
                value: 'hidden'
              }
            ]}
          />
        </Form.Item>
      </div>
    </div>
  )
}
