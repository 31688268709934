import { useApp } from '@/hooks'
import { signatureApi } from '@/services/api-service'
import { Button, Popconfirm } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { FC, useCallback } from 'react'
import { refreshQueries } from '../../../../../helpers'
import { useTicketSave } from '../../../../../hooks'
import { TicketSignature } from '../../../../../schemas'

export const DeleteSignatureButton: FC<{ signature: TicketSignature; disabled?: boolean }> = ({
  signature,
  disabled
}) => {
  const { notification } = useApp()
  const { saveTicket } = useTicketSave()

  const deleteMutation = useMutation(signatureApi.delete())

  const deleteSignature = useCallback(
    async (signature: TicketSignature) => {
      try {
        await deleteMutation.mutateAsync(signature.id)

        notification.success({
          message: 'Signature Deleted',
          description: (
            <span>
              <b>{signature._title}</b> deleted successfully
            </span>
          )
        })

        // clear property (todo: maybe backend should do this?)
        const pId = signature._propertyId
        if (pId) {
          await saveTicket({
            override: { [`p_${pId}`]: null },
            only: [`p_${pId}`]
          })
          // ^ also refreshes the ticket
        } else {
          await refreshQueries()
        }
      } catch (error) {
        notification.error({
          message: 'Signature Deletion Failed',
          description: 'An error occurred while deleting signature'
        })
      }
    },
    [deleteMutation, notification, saveTicket]
  )

  return (
    <Popconfirm title={'Deleting Signature'} description={'Are you sure?'} onConfirm={() => deleteSignature(signature)}>
      <Button size={'small'} shape={'circle'} type={'text'} iconName={'fa:trash'} danger disabled={disabled} />
    </Popconfirm>
  )
}
