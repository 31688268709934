import { useIsMobile } from '@/hooks/use-is-mobile'
import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { TicketStatus } from '@/modules/ticket/types'
import { QUERY_AUTO_REFRESH_OPTIONS } from '@/query'
import { ticketApiV2 } from '@/services/api-service'
import { Select } from '@/ui'
import { formatNumber } from '@/utils/formatters'
import { useQuery } from '@tanstack/react-query'
import cn from 'classnames'
import { sum } from 'lodash'
import { FC, useMemo } from 'react'
import { useTicketFilter, useTicketModule, useTicketsParam } from '../../hooks'

const LAZY_STATUS = ['X', 'Z']

export const TicketStatusFilter: FC<{
  className?: string
}> = ({ className }) => {
  const { module, moduleFormId } = useTicketModule()
  const { getStatusOptions } = useCustomFormsStatus()
  const { selectedStatus, includeArchived, setSelectedStatus, setFilter } = useTicketFilter()
  const { params } = useTicketsParam({
    // noArchivedFilter: true, // never include archived tickets for stats
    noStatusFilter: true // never include status filter for stats
  })
  const { data, isFetching, isFetched } = useQuery({
    ...ticketApiV2.stats({ ...params, order: undefined, by: 'computed_status', fns: ['count'] }),
    ...QUERY_AUTO_REFRESH_OPTIONS
  })

  const isMobile = useIsMobile()

  const hiddenStatuses = useMemo(() => {
    const hiddenStatuses = module?.data?.hidden_statuses
    return hiddenStatuses && hiddenStatuses.length > 0 ? hiddenStatuses.split(',') : []
  }, [module?.data?.hidden_statuses])

  const statuses = useMemo(
    () =>
      [
        ['all', 'All'],
        ...getStatusOptions(moduleFormId)
          .filter((opt: any) => !hiddenStatuses.includes(opt.value))
          .map((opt: any) => [opt.value, opt.label])
      ] as ['all' | TicketStatus, string][],
    [getStatusOptions, hiddenStatuses, moduleFormId]
  )

  const statsByStatus: Record<'all' | TicketStatus, number> = useMemo(() => {
    if (!data?.items) return { all: 0 }

    const statsByStatus: any = {
      all: sum(data.items.map((item: any) => item.count) || [])
    }

    data.items.forEach((item: any) => {
      statsByStatus[item.key] = item.count
    })

    //  we don't want to account for archived and deleted tickets in 'all' count
    if (!includeArchived && LAZY_STATUS.includes(selectedStatus)) {
      statsByStatus['all'] -= (statsByStatus['Z'] || 0) + (statsByStatus['X'] || 0)
    }

    return statsByStatus
  }, [data?.items, includeArchived, selectedStatus])

  if (isMobile) {
    return (
      <Select
        className="w-full"
        size="large"
        value={selectedStatus}
        loading={isFetching}
        onChange={(value) => setSelectedStatus(value)}
        options={statuses.map(([key, value]) => ({
          value: key,
          label: (
            <div className="flex items-center justify-between">
              <div>{value}</div>
              <div
                className={cn('rounded-full bg-gray-100 dark:bg-gray-700 text-sm px-6 py-2', {
                  'animate-pulse bg-gray-200': isFetching
                })}
              >
                {isFetched && (
                  <span>
                    {key && formatNumber(statsByStatus[key] || 0)}
                    {!key && formatNumber(statsByStatus['all'] || 0)}
                  </span>
                )}
                {!isFetched && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
              </div>
            </div>
          )
        }))}
      />
    )
  }

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {statuses.map(([key, value]) => (
        <div
          key={key}
          className={cn(
            'flex flex-row items-center justify-between gap-4',
            'rounded border dark:border-dark-border px-10 py-8 cursor-pointer transition-all',
            'hover:text-primary hover:bg-primary-50 hover:border-primary-300',
            {
              'text-primary bg-primary-50 border-primary-300': selectedStatus === key
            }
          )}
          onClick={() => {
            setFilter({ status: key, archived: LAZY_STATUS.includes(key) })
          }}
        >
          <div className={'line-clamp-1 flex-grow'}>{value}</div>
          {(includeArchived || !LAZY_STATUS.includes(key)) && (
            <div
              className={cn('rounded-full bg-gray-100 dark:bg-gray-700 text-sm px-6 py-2', {
                'animate-pulse bg-gray-200': isFetching
              })}
            >
              {isFetched && (
                <span>
                  {key && formatNumber(statsByStatus[key] || 0)}
                  {!key && formatNumber(statsByStatus['all'] || 0)}
                </span>
              )}
              {!isFetched && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
