export const STATUS_DISPATCHED = 'A' // Original: Created

export const FILTER_FIELDS = [
  { type: 'text', label: 'ticket__name__label', value: 'name' },
  { type: 'custom', label: 'ticket__computed_status__label', value: 'computed_status' },
  { type: 'text', label: 'ticket__team_member__label', value: 'contacts__contact__first_name' },
  { type: 'text', label: 'ticket__office__label', value: 'customer_office__name' },
  { type: 'text', label: 'ticket__contact__label', value: 'contact__name' },
  { type: 'date', label: 'ticket__timepoint_due__label', value: 'timepoint_due' },
  { type: 'text', label: 'ticket__location__label', value: 'location__name' },
  { type: 'text', label: 'ticket__user_afe_no__label', value: 'afe__user_afe_no' },
  { type: 'text', label: 'ticket__job_code__label', value: 'job_code__user_job_code_no' },
  { type: 'text', label: 'ticket__job_type__label', value: 'job__type' },
  { type: 'date', label: 'ticket__modified_at__label', value: 'modified_at__date' },
  { type: 'date', label: 'ticket__created_at__label', value: 'created_at__date' }
]
