import { wsnSearchApi } from '@/services/api-service'
import { Button, Checkbox, Modal, ModalProps, QueryTable, SearchInput } from '@/ui'
import { Form, Input, Tag } from 'antd'
import { FC, useCallback, useState } from 'react'
import { useButtonContext } from '../../../hooks'
import { FieldCustomerOffice } from './field-customer-office'
import { useHandleSave } from './hooks/use-handle-save'
import { WellData } from './types'
import { processCustomerAndLocation } from './utils'

export const FetchWellDataModal: FC<ModalProps> = (props) => {
  return (
    <Modal withScreenMaxHeight footer={null} {...props}>
      <FetchWellForm {...props} />
    </Modal>
  )
}

const FetchWellForm: FC<ModalProps> = ({ onOk, onCancel }) => {
  const { ticket, vars } = useButtonContext()
  const [search, setSearch] = useState((ticket.location__name || '').trim())
  const [selectedWellData, setSelectedWellData] = useState<WellData | null>(null)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const { isSaving, handleSave } = useHandleSave()

  const handleSearch = useCallback((value: string) => {
    value = (value || '').trim().toUpperCase()
    setSearch(value)
    setSelectedWellData(null)
  }, [])

  return (
    <Form layout="vertical" component="div">
      <Form.Item tooltip={'Min: 3'} label={'Search Name or API#'} className="mb-16">
        <SearchInput defaultValue={search} onSearch={handleSearch} />
      </Form.Item>

      <QueryTable
        fullHeight
        rowHoverable={true}
        rowClassName={'cursor-pointer'}
        scroll={{ y: 350 }}
        queryApi={wsnSearchApi.list}
        pagination={false}
        queryParams={{ name: search, config_key: vars.config_key }}
        queryOptions={{
          enabled: (search || '').length >= 3,
          select: ({ items, total }) => ({
            items: items.map((item) => processCustomerAndLocation(item, search)),
            total
          })
        }}
        onRow={(record) => ({ onClick: () => setSelectedWellData(record) })}
        rowKey="id"
        rowSelection={{
          type: 'radio',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedWellData ? [selectedWellData.id] : [],
          onChange: (_, selectedRows: any[]) => setSelectedWellData(selectedRows[0])
        }}
        columns={[
          { width: 125, title: 'API#', dataIndex: 'apiNum', sorter: false },
          {
            title: 'Name',
            dataIndex: 'displayName',
            sorter: false,
            render: (displayName) => <span dangerouslySetInnerHTML={{ __html: displayName }} />
          },
          { width: 125, title: 'County', dataIndex: 'county', sorter: false },
          { width: 125, title: 'Latitude', dataIndex: 'latitude', sorter: false },
          { width: 125, title: 'Longitude', dataIndex: 'longitude', sorter: false }
        ]}
      />
      <div className="grid grid-cols-2 mt-20 gap-x-16">
        <FieldCustomerOffice selectedWellData={selectedWellData} setSelectedWellData={setSelectedWellData} />
        <Form.Item label="Location">
          <Input
            disabled={!selectedWellData}
            value={selectedWellData?.locationName}
            onChange={(e) =>
              selectedWellData ? setSelectedWellData({ ...selectedWellData, locationName: e.target.value }) : undefined
            }
          />
        </Form.Item>
      </div>
      <div className="mt-24">
        <Checkbox disabled={!selectedWellData} checked={isConfirmed} onChange={(e) => setIsConfirmed(e.target.checked)}>
          Customer and Location names are correct? <span className="text-red-500">*</span>
        </Checkbox>
      </div>

      <div className={'flex justify-end gap-10 mb-10'}>
        {selectedWellData?.locationName && (
          <Tag className="rounded-full py-4 px-12 text-14 m-0" color="success">
            Selected: {selectedWellData.locationName}
          </Tag>
        )}
        <Button onClick={onCancel}>Close</Button>
        <Button
          type={'primary'}
          disabled={!isConfirmed}
          loading={isSaving}
          onClick={async (e: any) => {
            if (!selectedWellData) return
            await handleSave(selectedWellData)
            onOk?.(e)
          }}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}
