import { isEmpty } from 'lodash'

export const getUrlFileName = (path: string | undefined, defaultExtension = 'jpg') => {
  if (isEmpty(path)) return undefined

  const fileName = path?.split('/').pop()

  return fileName?.includes('.') ? fileName : `${fileName}.${defaultExtension}`
}

export const splitFileName = (path: string, defaultExtension = 'jpg') => {
  const fileName = path?.split('/').pop()

  if (fileName?.includes('.')) {
    const [name, ext] = fileName.split('.')
    return { name, ext }
  }

  return { name: fileName || 'Untitled', ext: defaultExtension }
}

export const getFileExtension = (path: string | undefined, defaultExtension = 'jpg') => {
  if (isEmpty(path)) return undefined

  const fileName = path?.split('/').pop()

  return fileName?.includes('.') ? fileName.split('.').pop() : defaultExtension
}
