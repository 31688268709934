import { useApp, useSession } from '@/hooks'
import { ViewTicketLink } from '@/modules/ticket/components'
import { ACCOUNTING_SERVICES } from '@/modules/ticket/form/constants'
import { attachmentApi, ticketApi } from '@/services/api-service'
import { SummaryProgress } from '@/types/summary_progress'
import { Button, Icon } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal, Spin } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { DownloadSummaryTicketButton } from '../tickets-filter-section/download-summary-ticket-button'

type Props = {
  summaryTicket: any
}

export const ResultSection = ({ summaryTicket = {} }: Props) => {
  const { user } = useSession()
  const { notification } = useApp()
  const [submitStatus, setSubmitStatus] = useState('Not Submitted')
  const [modal, contextHolder] = Modal.useModal()
  const createTicketsAccountingMutation = useMutation({ ...ticketApi.create({}, `${summaryTicket.id}/accounting`) })
  const [isSummarizingDone, setIsSummarizingDone] = useState(false)

  const ticketSummaryProgressQuery = useQuery({
    ...ticketApi.get<SummaryProgress>(summaryTicket.id ? summaryTicket.id : 0, {}, { serializer: 'summary_progress' }),
    enabled: !!summaryTicket.id,
    refetchInterval: !!summaryTicket.id && !isSummarizingDone ? 300 : false
  })

  const summaryProgress = ticketSummaryProgressQuery.data

  const attachementQuery = useQuery({
    ...attachmentApi.get<any>(summaryProgress?.data.summary_attachment_id || 0),
    enabled: !!summaryProgress?.data.summary_attachment_id
  })

  const availableServices = useMemo(() => {
    const accountingOptions = user?.profile.company.data?.accounting || {}

    let availableServices = ACCOUNTING_SERVICES.filter((service) => accountingOptions[service.option_key])

    if (!availableServices.length) {
      availableServices = ACCOUNTING_SERVICES.slice(0, 1)
    }

    return availableServices
  }, [user?.profile.company.data?.accounting])

  const handleMutate = async () => {
    if (!summaryTicket.id) {
      console.error('No summary ticket id')
      return
    }

    const service = availableServices[0].key

    try {
      createTicketsAccountingMutation.mutateAsync({ id: summaryTicket.id, service })
      notification.success({ message: 'Summary ticket has been submitted to accounting' })
      setSubmitStatus('Successfully Submitted')
    } catch (error) {
      notification.error({ message: 'Failed to submit summary ticket to accounting' })
      setSubmitStatus('Failed to Submit')
    }
  }

  const handleAccountingSubmit = async () => {
    if (submitStatus === 'Successfully Submitted') {
      const yes = await modal.confirm({
        title: 'Already Submitted',
        content: (
          <div>
            <div>This summary ticket has already been submitted to accounting</div>
            <div>Are you sure you want to submit again?</div>
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No'
      })

      if (yes) {
        handleMutate()
      }

      return
    }

    handleMutate()
  }

  const handleDownloadAttachment = async () => {
    const { data } = await attachementQuery.refetch()
    const pdfLink = data.image

    if (!pdfLink) {
      notification.error({ message: 'No attachment found' })
      return
    }

    window.open(pdfLink, '_blank')
  }

  useEffect(() => {
    if (!summaryTicket.id || isSummarizingDone) {
      return
    }

    if (summaryProgress?.data.summary_status === 'Success') {
      setIsSummarizingDone(true)
    }
  }, [summaryProgress?.data.summary_status, summaryTicket.id, isSummarizingDone])

  return (
    <div className="w-[30%]">
      <Spin
        tip={ticketSummaryProgressQuery.data?.data.summary_message}
        spinning={
          ticketSummaryProgressQuery.isInitialLoading ||
          ticketSummaryProgressQuery.isFetching ||
          ticketSummaryProgressQuery.data?.data.summary_status === 'Running'
        }
      >
        <div>
          {contextHolder}
          <SectionTitle rounded number={3}>
            Result
          </SectionTitle>
          <div className="mt-16">
            <div>Summarizing Status: {summaryProgress?.data.summary_message ?? 'Pending'}</div>
            {summaryTicket.id && (
              <div className="space-y-12 mt-12">
                <div>
                  Summary Ticket:{' '}
                  <ViewTicketLink
                    ticketId={summaryTicket.id}
                    formId={summaryTicket.custom_form?.id || 0}
                    className="font-bold text-primary-500 hover:text-primary-500 hover:underline"
                    target="_blank"
                  >
                    <span>
                      {summaryTicket.name} <Icon name="mi:open_in_new" className="text-14" />
                    </span>
                  </ViewTicketLink>
                </div>
                <div>
                  Total:{' '}
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    summaryTicket.total || 0
                  )}
                </div>
                <div>Accounting Status: {submitStatus}</div>
                <div className="space-y-12">
                  {(availableServices.length || 0 <= 1) && (
                    <Button type="primary" block onClick={handleAccountingSubmit}>
                      Submit to Accounting
                    </Button>
                  )}
                  <DownloadSummaryTicketButton summaryTicketId={summaryTicket.id} />
                  <Button
                    type="primary"
                    block
                    iconName="fa:file-download"
                    onClick={handleDownloadAttachment}
                    disabled={!summaryProgress?.data.summary_attachment_id}
                  >
                    Download Attachment
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </div>
  )
}
