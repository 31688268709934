import { ApiResource, ListParams, ListReturn, RequestConfig } from '@/types/api/core'
import { R, createResourceApi, getParams } from '../core'

export type ClientRecordApi = ApiResource & {
  stats: <TData = any>(params?: ListParams<TData>, config?: RequestConfig) => ListReturn<TData>
}

export const _clientRecordApi: ClientRecordApi = {
  ...createResourceApi('client_records'),
  stats: (params, config) => ({
    queryKey: ['client_records', 'stats', getParams(params)],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', '/client_records/stats/', { signal, params: getParams(params), ...config }, { isList: true })
  })
}
