import { CustomForm } from '@/types/custom-form'
import { Property } from '@/types/property'
import React, { FC, useEffect } from 'react'
import { DefaultFields } from '../default-fields'
import { BpaBuilderForm, SettingsCard } from './components'
import { useInitializer } from './hooks'

type Props = {
  customForm: CustomForm
  property: Property
}

/**
 * BpaBuilder is the main component for the BPA Builder
 * It is responsible for rendering the main layout of the BPA Builder
 * It also initializes the BPA Builder
 */
export const BpaBuilder: FC<Props> = ({ property, customForm }) => {
  const { isInit, initialize } = useInitializer()

  useEffect(() => {
    if (!property || isInit) return
    initialize(customForm, property)
  }, [initialize, customForm, property, isInit])

  return (
    <div className={'flex flex-row h-full'}>
      <div className={'w-2/12 h-full overflow-auto'}>
        <SettingsCard />
        <DefaultFields forBpa={true} />
      </div>
      <div className={'w-10/12 h-full'}>
        <div className={'mx-10 h-full'}>
          <BpaBuilderForm />
        </div>
      </div>
    </div>
  )
}

export const BpaBuilderMemo = React.memo(BpaBuilder)
