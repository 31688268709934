import { programmedReportApi } from '@/services/api-service'
import { Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useMemo } from 'react'

export const BaseReportSelect = () => {
  const form = Form.useFormInstance()
  const companyId = Form.useWatch('company', form)?.id
  const baseReport = Form.useWatch('base_report', form)

  const baseProgrammedReportsQuery = useQuery({
    ...programmedReportApi.list({ company__id: companyId, type__exact: 'B', limit: 'None' }),
    enabled: !!companyId
  })

  const options = useMemo(
    () => baseProgrammedReportsQuery.data?.items.map((pr) => ({ label: pr.report?.name, value: pr.id })) || [],
    [baseProgrammedReportsQuery.data?.items]
  )

  return (
    <Select
      value={baseReport === null ? '' : baseReport?.id}
      loading={baseProgrammedReportsQuery.isInitialLoading}
      options={[{ label: '--None--', value: '' }, ...options]}
      onChange={(value) => form.setFieldValue('base_report', value ? { id: value } : null)}
    />
  )
}
