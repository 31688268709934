import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp, useSession } from '@/hooks'
import { companyApi, eventApi, qbSettingApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { AppLink, Button, PageTitle, Tabs } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Space } from 'antd'
import { useState } from 'react'
import { ConfiguredTab } from './configure-tab'
import { InvoicesAndBillsTab } from './invoices-and-bills-tab'

export const QuickbooksView = () => {
  const { company } = useSession()
  const qbSettingsQuery = useQuery(qbSettingApi.list({ Q: [`company|${company.id}`] }))
  const createQbSettingsMutation = useMutation(qbSettingApi.create())
  const updateQbSettingsMutation = useMutation(qbSettingApi.update())
  const updateCompanyMutation = useMutation(companyApi.patch())
  const companyQuery = useQuery(companyApi.get<Company>(company.id))
  const [form] = Form.useForm()
  const { notification } = useApp()
  const eventsQuery = useQuery(eventApi.list({ type: 'quickbooks', limit: 10 }))
  const [isSaving, setIsSaving] = useState(false)

  const updateCompanyPreference = async () => {
    if (!companyQuery.data) {
      console.error('Company not found')
      return
    }

    const { doc } = await form.validateFields()
    const accounting = companyQuery.data?.data?.accounting || {}

    await updateCompanyMutation.mutateAsync({
      id: company.id,
      data_json: {
        ...companyQuery.data.data,
        accounting: {
          ...accounting,
          ...doc.accounting
        }
      }
    })
  }

  const handleSave = async () => {
    const { doc, customer_accounting_name, equipment_item_code, equipment_item_description, ...values } =
      await form.validateFields()

    const isQboEnabled = doc?.accounting?.qbo_enabled
    const isQbwcEnabled = doc?.accounting?.qbwc_enabled

    if (
      isQboEnabled &&
      isQbwcEnabled &&
      (!customer_accounting_name || !equipment_item_code || !equipment_item_description)
    ) {
      notification.error({ message: 'Please check input fields' })
      return
    }

    setIsSaving(true)
    await updateCompanyPreference()
    form.setFieldsValue({ doc: { ...doc, custom_fields: (doc.custom_fields || []).filter((f: any) => (f || 0).name) } })

    const payload = {
      company: company.id,
      customer_accounting_name,
      equipment_item_code,
      equipment_item_description,
      _doc: { ...qbSettings?.doc, ...doc },
      doc_json: JSON.stringify({ ...qbSettings?.doc, ...doc }),
      ...values
    }

    if (qbSettings) {
      await updateQbSettingsMutation.mutateAsync({ id: qbSettings.id, ...payload })
    } else {
      await createQbSettingsMutation.mutateAsync(payload)
    }

    notification.success({ message: 'Quickbooks settings saved' })
    setIsSaving(false)
  }

  if (qbSettingsQuery.isLoading) {
    return <Spin isCentered spinning />
  }

  const qbSettings = qbSettingsQuery.data?.items[0]

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ ...qbSettings, doc: qbSettings?.doc_json ? JSON.parse(qbSettings.doc_json) : {} }}
      className="p-8"
    >
      <div className="flex justify-between items-center">
        <PageTitle>Quickbooks</PageTitle>
        <Space>
          <AppLink to="/">
            <Button iconName="fa:close">Cancel</Button>
          </AppLink>
          <Button type="success" iconName="fa:save" loading={isSaving} onClick={handleSave}>
            Save
          </Button>
        </Space>
      </div>
      <Tabs
        className="mt-16"
        type="solid"
        items={[
          {
            key: 'configure',
            label: 'Configure',
            children: <ConfiguredTab />
          },
          {
            key: 'invoices-and-bills',
            label: 'Invoices & Bills',
            children: <InvoicesAndBillsTab />
          }
        ]}
      />
      <div className="mt-30">
        <h5 className="font-bold">Recent Activity</h5>
        <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'quickbooks' }} />
        <div className="mt-16">
          <RecentActivities isLoading={eventsQuery.isLoading} events={eventsQuery.data?.items} />
        </div>
      </div>
    </Form>
  )
}
