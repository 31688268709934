import { Form, Input } from 'antd'

export const ModuleTypeDocumentsFields = () => {
  return (
    <div>
      <h5 className="font-bold">Document</h5>
      <Form.Item label="Document Title" name="document__title" className="w-1/2">
        <Input />
      </Form.Item>
    </div>
  )
}
