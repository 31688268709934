import { useState } from 'react'

type Props = {
  initialRender?: boolean
  initialOpen?: boolean
}

export const useModalState = (props?: Props) => {
  const { initialRender, initialOpen } = props || {}

  const [isRender, setIsRender] = useState(initialRender || false)
  const [isOpen, setIsOpen] = useState(initialOpen || false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const renderModal = () => setIsRender(true)

  return {
    isRender,
    renderModal,

    isOpen,
    openModal,
    closeModal,
    toggleModal,
    setIsOpen,
    setIsRender
  }
}
