import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../atoms'

export const IncludeLineItems = () => {
  const includeLineItems = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.data?.include_line_items ?? false), [])
  )

  const setConfiguration = useSetAtom(configurationAtom)

  const onChange = (newValue: boolean) => {
    setConfiguration((configuration) => ({
      ...configuration,
      data: {
        ...configuration.data,
        include_line_items: newValue
      }
    }))
  }

  return (
    <Space>
      <Switch
        checked={!!includeLineItems}
        onChange={(checked) => onChange(checked)}
        data-cy="include-line-items-switch"
      />
      <span>Include Line Items on Script</span>
    </Space>
  )
}
