import { contactApi } from '@/services/api-service'
import { LIST_LIMIT } from '@/services/api-service/core'
import { useQuery } from '@tanstack/react-query'
import { Empty, Select, SelectProps } from 'antd'
import { useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { z } from 'zod'

const contactSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string()
})

export type Contact = z.infer<typeof contactSchema>

type Props = {
  queryVariables?: any
  selectProps?: { onContactChange?: (value?: Contact) => void } & SelectProps
}

export const ContactSelect = ({ queryVariables, selectProps }: Props) => {
  const [search, setSearch] = useState('')
  const { onChange, value, onContactChange, ...props } = selectProps || {}

  const debouncedSetSearch = useDebouncedCallback((value: string) => {
    setSearch(value)
  }, 500)

  const queryVars = {
    limit: LIST_LIMIT,
    compact: 1,
    registered_only: true,
    profile__user__is_active: 1,
    ...(search && { first_name__icontains: search }),
    ...queryVariables
  }

  const contactsQuery = useQuery({
    ...contactApi.list<Contact>(queryVars),
    select: (data) => ({
      ...data,
      items: data.items.map((contact) => contactSchema.parse(contact))
    })
  })

  const contactQuery = useQuery({
    ...contactApi.get<Contact>(value, {}, { compact: 1 }),
    enabled: !!value,
    select: (data) => contactSchema.parse(data)
  })

  const options = useMemo(() => {
    // If there is an initial value and single contact query has data
    // and there is no search, return the single contact. So in dropdown it will show the single contact
    if (value && contactQuery.data && !search) {
      return [
        {
          label: `${contactQuery.data.id}:  ${contactQuery.data.first_name} ${contactQuery.data.last_name}`,
          value: contactQuery.data.id
        }
      ]
    }

    return contactsQuery.data?.items.map((contact) => ({
      label: `${contact.id}: ${contact.first_name} ${contact.last_name}`,
      value: contact.id
    }))
  }, [contactsQuery.data?.items, contactQuery.data, search, value])

  if (contactsQuery.isLoading) {
    return (
      <Select
        loading
        value={{
          label: 'Loading...'
        }}
      />
    )
  }

  return (
    <Select
      showSearch
      onSearch={(value) => debouncedSetSearch(value)}
      onChange={(value, option) => {
        const contact = contactsQuery.data?.items.find((contact) => contact.id === value)
        debouncedSetSearch('')
        onContactChange?.(contact)
        onChange?.(value, option)
      }}
      options={options}
      filterOption={false}
      value={value}
      allowClear
      notFoundContent={
        contactsQuery.isLoading ? 'Loading...' : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results" />
      }
      {...props}
    />
  )
}
