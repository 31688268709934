import { useSession } from '@/hooks'
import { inventoryAlertApi } from '@/services/api-service'
import { InventoryAlert } from '@/types/inventory-alert'
import { Button, QueryDeleteButton, QueryTable } from '@/ui'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Layout } from '../../components/layout'
import { AddEditInventoryModal } from './add-edit-inventory-modal'

export const AlertsView = () => {
  const { company } = useSession()
  const [editingAlert, setEditingAlert] = useState<InventoryAlert>()
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false)

  return (
    <Layout>
      <div className="flex justify-end mb-16">
        <Button iconName="fa:add" type="primary" onClick={() => setIsAddAlertModalOpen(true)}>
          New Alert
        </Button>
      </div>
      <QueryTable
        queryApi={inventoryAlertApi.list}
        queryParams={{ company_id__exact: company.id }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Description',
            dataIndex: 'description'
          },
          {
            title: 'Schedule',
            dataIndex: 'cron_spec',
            render: (cron_spec) => (
              <div>
                <div>{cron_spec}</div>
                <div>{humanizeCronExpr(cron_spec || '')}</div>
              </div>
            )
          },
          {
            title: 'Last Run',
            dataIndex: 'last_run',
            render: (last_run) => (last_run ? dayjs(last_run).format('MMM D, YYYY h:mm:ss A') : '')
          },
          {
            title: 'Next Run',
            dataIndex: 'next_run',
            render: (next_run) => (next_run ? dayjs(next_run).format('MMM D, YYYY h:mm:ss A') : '')
          },
          {
            title: 'Enabled',
            dataIndex: 'enabled',
            render: (enabled) => (enabled ? 'Yes' : 'No')
          },
          {
            dataIndex: 'id',
            sorter: false,
            align: 'right',
            render: (_, inventoryAlert: InventoryAlert) => (
              <Space>
                <Button iconName="fa:edit" type="text" onClick={() => setEditingAlert(inventoryAlert)} />
                <QueryDeleteButton api={inventoryAlertApi.delete} id={String(inventoryAlert.id)}></QueryDeleteButton>
              </Space>
            )
          }
        ]}
      />
      {(editingAlert || isAddAlertModalOpen) && (
        <AddEditInventoryModal
          inventoryAlert={editingAlert}
          onCancel={() => {
            setEditingAlert(undefined)
            setIsAddAlertModalOpen(false)
          }}
        />
      )}
    </Layout>
  )
}
