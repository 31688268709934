import { Icon } from '@/ui'
import { Sortable } from '@/ui/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { FC } from 'react'
import { useVariables, useVariablesHandlers } from '../../hooks'
import { VariableInputs } from './VariableInputs'

export const VariablesSortable: FC = () => {
  const { variables } = useVariables()
  const { onSortingChange } = useVariablesHandlers()

  return (
    <>
      {variables.length === 0 ? (
        <div className="text-center text-text-muted py-16">
          <Icon name="fa:circle-info" className="mr-2" /> No Variables
        </div>
      ) : (
        <Sortable
          items={variables}
          by={'uuid'}
          onChange={onSortingChange}
          modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          render={(variable, variableIndex, sortable) => (
            <VariableInputs key={variable.uuid} variable={variable} variableIndex={variableIndex} sortable={sortable} />
          )}
        />
      )}
    </>
  )
}
