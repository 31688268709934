import { SUMMARY_ATTACHMENT_TYPE_SELECT } from '@/constants/summary'
import { StatusSelect } from '@/modules/ticket/components'
import { customFormApiV2, equipmentApi } from '@/services/api-service'
import { QuerySelect, Select } from '@/ui/select'
import { isEmpty } from 'lodash'
import { ScriptedButtonConfig } from '../../types'
import { SummarizeTicketButton } from './SummarizeTicketButton'

export const equipmentSearchBy = (text?: string) => ({
  'Q[]': isEmpty(text) ? {} : [`sku__icontains|${text}`, `description__icontains|${text}`, 'or']
})

export const SummarizeTicketButtonConfig: ScriptedButtonConfig = {
  type: 'summarize_ticket_button',
  iconName: 'fa:file-alt',
  label: 'Summarize Ticket',
  group: 'Common',
  render: () => <SummarizeTicketButton />,
  vars: [
    {
      name: 'custom_form_id',
      label: 'Summary Custom Form',
      render: (customForm, props) => (
        <QuerySelect
          apiEndpoint={customFormApiV2.list}
          apiSearchBy="name"
          labelInValue
          apiQueryParams={{ fields: 'id,name' }}
          renderOption={(item) => ({ label: item.name, value: item.id })}
          {...props}
        />
      )
    },
    {
      name: 'summary_ticket_status',
      label: 'Summary Ticket Status',
      render: (customForm, props) => <StatusSelect formId={customForm?.id} {...props} />
    },
    {
      name: 'summarized_tickets_status',
      label: 'Summarized Tickets Status',
      render: (customForm, props) => <StatusSelect formId={customForm?.id} {...props} />
    },
    {
      name: 'summary_sku_id',
      label: 'Summary SKU',
      render: (customForm, props) => (
        <QuerySelect
          apiEndpoint={equipmentApi.list}
          apiSearchBy={equipmentSearchBy}
          apiQueryParams={{ fields: 'id,sku,description', summary_sku__eq: '1' }}
          renderOption={(item) => ({ label: `${item.sku} / ${item.description}`, value: item.id })}
          {...props}
        />
      )
    },
    {
      name: 'list_item_conf',
      label: 'List Each Line Item',
      render: (customForm, props) => (
        <Select
          placeholder="Select"
          options={[
            { value: 3, label: 'Yes - Group' },
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' }
          ]}
        />
      )
    },
    {
      name: 'attachment_type',
      label: 'Attachment Type',
      render: (customForm, props) => (
        <Select
          popupMatchSelectWidth={false}
          placeholder="Select"
          options={SUMMARY_ATTACHMENT_TYPE_SELECT}
          {...props}
        />
      )
    }
  ]
}
