import { propertyApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export const useDeletePropertyMutation = () => {
  const deletePropertyMutation = useMutation({ ...propertyApi.delete() })

  const handleDeleteProperty = async (id: number) => {
    return deletePropertyMutation.mutateAsync({ id })
  }

  return {
    deletePropertyMutation,
    handleDeleteProperty
  }
}
