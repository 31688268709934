import { Image as AntImage, ImageProps as AntImageProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'

export const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
const MEDIA_URL = import.meta.env.VITE_MEDIA_URL

export type ImageProps = AntImageProps & {
  src?: string | 'blank' | null
  background?: boolean
  media?: boolean
}

export const Image: FC<ImageProps> = ({ background, media, src, preview, className, ...props }) => {
  if (src === 'blank') src = BLANK_IMAGE
  else if (media) src = `${MEDIA_URL}/${src}`

  props.fallback = props.fallback ?? BLANK_IMAGE

  preview = preview ?? false

  if (background) {
    return (
      <div
        className={cn('bg-contain bg-center bg-no-repeat h-full w-full', className)}
        style={{ backgroundImage: `url(${src})` }}
      />
    )
  }

  return <AntImage src={src} preview={preview} className={className} {...props} />
}
