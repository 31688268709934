import { useApp, useSession } from '@/hooks'
import { Button } from '@/ui'
import { useButtonContext } from '../../../hooks'

const dt = new Date().toLocaleDateString()
const time = new Date().toLocaleTimeString()

const XTO_INSTRUCTION_KEY = 'XTO Instruction'
const WORK_APPROVED_STAMP_KEY = 'Work Approved Stamp'

export const WorkApprovedByXtoButton = () => {
  const { user } = useSession()
  const { notification } = useApp()
  const { ticket, saveTicket, isSaving, buttonProps, property } = useButtonContext()

  const handleClick = async () => {
    const xtoInstructionProp = ticket._tpByPropertyKey[XTO_INSTRUCTION_KEY]
    const workApprovedStampProp = ticket._tpByPropertyKey[WORK_APPROVED_STAMP_KEY]

    if (!xtoInstructionProp || !workApprovedStampProp) {
      notification.error({ message: `${XTO_INSTRUCTION_KEY} or ${WORK_APPROVED_STAMP_KEY} property not found!` })
      return
    }

    if (!user) {
      notification.error({ message: 'User is not authenticated' })
      return
    }

    const payload = {
      status: 'S',
      [`p_${xtoInstructionProp.property_id}`]: 'Work Approved',
      [`p_${workApprovedStampProp.property_id}`]: `Work approved by ${user.first_name} ${user.last_name}, ${dt} ${time}`
    }

    await saveTicket(payload)
    notification.success({ message: 'Ticket saved' })
  }

  return (
    <Button {...buttonProps} loading={isSaving} onClick={handleClick}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
