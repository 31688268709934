import { TICKET_STATUS } from '@/constants/general'
import { Form, FormItemProps, Select } from 'antd'

type Props = {
  formItemProps?: FormItemProps
}

export const HiddenStatusesSelect = ({ formItemProps }: Props) => {
  return (
    <Form.Item label="Hidden Statuses" name={['data', 'hidden_statuses']} {...formItemProps}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        mode="multiple"
        options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
      />
    </Form.Item>
  )
}
