import { lineItemApiV2 } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { editingComponentsAtom } from '../atoms'
import { LineItemComponentSchema } from '../schemas'

type Props = {
  ticketId?: number
  parentId?: number
}

export const useSubItems = ({ ticketId, parentId }: Props) => {
  const setEditingSubItems = useSetAtom(editingComponentsAtom)

  const subItemsQuery = useQuery({
    ...lineItemApiV2.list({
      fields: LineItemComponentSchema,
      inc_components: true,
      ticket_id__eq: ticketId,
      limit: 'None',
      no_count: '1'
    }),
    enabled: !!ticketId
  })

  const getSubItems = useCallback(
    (parentId: number) => {
      return subItemsQuery.data?.items?.filter((item) => item.finished_good_li_id === parentId) || []
    },
    [subItemsQuery.data?.items]
  )

  const editSubItem = useCallback(
    (id: number) => {
      setEditingSubItems((prev) => [...prev, id])
    },
    [setEditingSubItems]
  )

  const subItems = useMemo(
    () => (parentId ? getSubItems(parentId) : subItemsQuery.data?.items || []),
    [parentId, getSubItems, subItemsQuery.data?.items]
  )

  return {
    subItemsQuery,
    getSubItems,
    subItems,
    editSubItem
  }
}
