import { useApp } from '@/hooks'
import { pricingCustomerApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { saveBlob } from '@/utils/blob'
import { useQuery } from '@tanstack/react-query'

export const DownloadButton = () => {
  const { notification } = useApp()

  const downloadQuery = useQuery({
    ...pricingCustomerApi.get('download' as any, { responseType: 'blob' }),
    enabled: false
  })

  const handleDownload = async () => {
    try {
      const { data } = await downloadQuery.refetch()

      await saveBlob(data as any, 'pricing_customers.xlsx')

      notification.success({
        message: 'Download Success',
        description: 'Customer pricing downloaded successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Download Failed',
        description: 'Failed to download customer pricing'
      })
    }
  }

  return (
    <Button
      loading={downloadQuery.fetchStatus !== 'idle' && downloadQuery.isLoading}
      iconName="fa:download"
      onClick={handleDownload}
    >
      Download Pricing
    </Button>
  )
}
