import { CriteriaColumnCustomReport, CriteriaColumnProgrammedReport } from '@/modules/reports/components'

type Props = {
  report: any
  programmedReports: any[]
  customReports: any[]
  setEditingCriteria: (criteria: any) => void
  setEditingAttribute: (attribute: any) => void
  setEditingReport: (report: any) => void
}

export const CriteriaColumn = ({
  report,
  customReports,
  programmedReports,
  setEditingAttribute,
  setEditingCriteria,
  setEditingReport
}: Props) => {
  if (report.programmed_report) {
    const pr = programmedReports.find((pr) => report.programmed_report.id === pr.id)

    if (!pr) {
      return null
    }

    return (
      <CriteriaColumnProgrammedReport
        pr={pr}
        setEditingCriteria={setEditingCriteria}
        setEditingReport={setEditingReport}
        setEditingAttribute={setEditingAttribute}
      />
    )
  }

  const customReport = customReports.find((cr) => report.id === cr.id)

  if (!customReport) {
    return null
  }

  return (
    <CriteriaColumnCustomReport
      customReport={customReport}
      setEditingCriteria={setEditingCriteria}
      setEditingReport={setEditingReport}
    />
  )
}
