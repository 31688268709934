import { CodeEditor } from '@/components/code-editor'
import { API_OPERATORS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { SectionTitle } from '@/ui/section-title'
import { getColumnLabel } from '@/utils/get-column-label'
import { renderCriteria } from '@/utils/render-criteria'
import { Checkbox, Form, Space, Table } from 'antd'
import { useState } from 'react'
import { EditFilterModal } from './edit-filter-modal'

export const FilterYourViewSection = () => {
  const { labels } = useApp()
  const [editingFilter, setEditingFilter] = useState<any>(null)
  const form = Form.useFormInstance()
  const useCustomFiltersWatch = Form.useWatch(['config', 'use_custom_filters'], form)
  const typeWatch = Form.useWatch('type', form)
  const filters = Form.useWatch('filters') || []

  return (
    <div className="pt-24">
      <SectionTitle rounded number={3}>
        Filter Your View
      </SectionTitle>
      <Form.Item name="filters" hidden noStyle />
      <Form.Item name="config" hidden noStyle />
      <Form.List name="filters">
        {(formListFields, { add, remove }) => (
          <>
            <Table
              dataSource={formListFields.map((field) => ({
                ...field,
                ...filters[field.name],
                listFieldName: field.name,
                key: field.name,
                remove: () => remove(field.name)
              }))}
              pagination={false}
              className="mt-12"
            >
              <Table.Column
                title="Filter By"
                render={(_, filter: any) => (
                  <div>
                    {getColumnLabel({ category: typeWatch === 'I' ? 'lineItem' : 'ticket', ...filter }, labels)}
                  </div>
                )}
              />
              <Table.Column
                title="Relationship"
                render={(_, record: any) => (
                  <div>
                    {record.negate && 'Not'} {API_OPERATORS[record.type as keyof typeof API_OPERATORS]}
                  </div>
                )}
              />
              <Table.Column title="Criteria" render={(_, record: any) => renderCriteria(record)} />
              <Table.Column
                align="right"
                width={100}
                render={(_, filter: any) => (
                  <Space>
                    <Button iconName="svg:edit" type="text" onClick={() => setEditingFilter(filter)} />
                    <Button iconName="svg:trash" type="text" onClick={filter.remove} />
                  </Space>
                )}
              />
            </Table>
            <Button iconName="mi:add" type="primary" className="mt-12" onClick={() => setEditingFilter({})}>
              Add Filter
            </Button>
            <Form.Item name="config" hidden noStyle />
            {editingFilter && (
              <EditFilterModal
                editingFilter={editingFilter}
                onClose={() => setEditingFilter(null)}
                onSave={(values) => {
                  if (typeof editingFilter.listFieldName === 'number') {
                    form.setFieldsValue({
                      filters: {
                        ...filters,
                        [editingFilter.listFieldName]: values
                      }
                    })
                  } else {
                    add(values)
                  }
                }}
              />
            )}
          </>
        )}
      </Form.List>
      <Form.Item name={['config', 'use_custom_filters']} valuePropName="checked" className="mt-12">
        <Checkbox className="ml-3">Include Custom Filters</Checkbox>
      </Form.Item>
      <Form.Item name={['config', 'custom_filters']} hidden={!useCustomFiltersWatch} noStyle={!useCustomFiltersWatch}>
        <CodeEditor minLines={5} maxLines={Infinity} mode="json" />
      </Form.Item>
    </div>
  )
}
