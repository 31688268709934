import { view } from '@/routing'
import { Spin } from 'antd'
import { useCustomFormQuery } from '../../hooks'
import { BasicStyleAndFormatting } from './basic-style-and-formatting'
import { PrintingAdvanced } from './printing-advanced'
import { PrintingOptions } from './printing-options'
import { WebAdvanced } from './web-advanced'
import { WebOptions } from './web-options'

export const Styles = view(Component, {
  title: () => 'Styles',
  scoped: false
})

function Component() {
  const { customFormQuery } = useCustomFormQuery()

  return (
    <Spin spinning={customFormQuery.isLoading}>
      <div>
        <BasicStyleAndFormatting />
        <div className="flex mt-30 gap-x-30">
          <PrintingOptions />
          <WebOptions />
        </div>
        <div className="flex mt-30 gap-x-30">
          <PrintingAdvanced />
          <WebAdvanced />
        </div>
      </div>
    </Spin>
  )
}
