import { useApp, useSession } from '@/hooks'
import { calendarEventsApi } from '@/services/api-service'
import { ActionButton, Button, CopyButton, QueryDeleteButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Card, Form, Input } from 'antd'
import { FC, useCallback, useState } from 'react'
import { CalendarNote } from '../../../schemas'

const NOTE_TITLE_MAX_LENGTH = 256
const NOTE_DESCRIPTION_MAX_LENGTH = 1000

type CalendarNoteProps = {
  note?: CalendarNote
  date: string
  onSave?: () => void
  onCancel?: () => void
}

export const CalendarNoteRow: FC<CalendarNoteProps> = ({ note, date, onSave, onCancel }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { company } = useSession()
  const [form] = Form.useForm()
  const { notification } = useApp()

  const saveMutation = useMutation(note?.id ? calendarEventsApi.update() : calendarEventsApi.create())

  const handleSave = useCallback(async () => {
    const formValues = await form.validateFields()
    const data = {
      ...(note?.id ? { id: note?.id } : {}),
      company_id: company.id,
      type: 'N',
      start_datetime: date,
      end_datetime: date,
      ...formValues
    }

    try {
      await saveMutation.mutateAsync(data)
      notification.success({
        message: 'Note saved'
      })
    } catch (error) {
      notification.error({
        message: 'Failed to save note'
      })
    } finally {
      setIsEditing(false)
      onSave?.()
    }
  }, [company.id, date, form, note?.id, notification, onSave, saveMutation])

  const handleCancel = () => {
    setIsEditing(false)
    form.resetFields()
    onCancel?.()
  }

  return (
    <div className="flex items-center w-full gap-10">
      {isEditing || !note?.id ? (
        <>
          <Card className="w-full max-w-[600px] border-2">
            <div className="flex gap-10">
              <Form form={form} initialValues={note} layout="vertical" className="w-full">
                <Form.Item name={'title'} label="Title">
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    onChange={(e) => {
                      const val = e.target.value
                      form.setFieldValue('title', val.slice(0, NOTE_TITLE_MAX_LENGTH))
                    }}
                  />
                </Form.Item>
                <Form.Item name={'description'} label="Description">
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 10 }}
                    onChange={(e) => {
                      const val = e.target.value
                      form.setFieldValue('description', val.slice(0, NOTE_DESCRIPTION_MAX_LENGTH))
                    }}
                  />
                </Form.Item>
              </Form>
              <div className={'flex items-center gap-8'}>
                <Button
                  loading={saveMutation.isLoading}
                  primary
                  shape={'circle'}
                  size={'small'}
                  iconName={'fa:check'}
                  onClick={handleSave}
                />
                <Button
                  disabled={saveMutation.isLoading}
                  shape={'circle'}
                  size={'small'}
                  iconName={'fa:close'}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Card className="w-full max-w-[600px] border-2">
            <div className="flex gap-10">
              <div className="w-full max-w-[460px] flex flex-col gap-10">
                {note?.title && (
                  <p className="px-8 py-4 w-full mb-0 rounded-md break-words font-medium whitespace-pre-wrap">
                    {note?.title}
                  </p>
                )}
                {note?.description && (
                  <>
                    <p className="px-8 py-4 w-full mb-0 rounded-md border-2 border-dashed break-words whitespace-pre-wrap">
                      {note?.description}
                    </p>
                  </>
                )}
              </div>
              <div className="flex mt-5">
                <CopyButton copyText={note.title || note.description || ''} type={'text'} size={'small'} />
                <ActionButton
                  size={'small'}
                  iconName={'fa:edit'}
                  type={'text'}
                  shape={'circle'}
                  onClick={() => setIsEditing(true)}
                />
                <QueryDeleteButton size={'small'} name={'Note'} api={calendarEventsApi.delete} id={note?.id} />
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  )
}
