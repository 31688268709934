import { PROPERTY_TYPE as PT } from '@/modules/custom-form/constants'
import { Form, FormItemProps, Input } from 'antd'
import cn from 'classnames'
import { FC, ReactElement } from 'react'
import { useCustomForm } from '../../../hooks'
import { Property } from '../../../schemas'
import { Label } from '../../ui'
import { NO_LABEL_PT } from './constants'
import { InputRendererMap } from './helpers'

type Props = FormItemProps & {
  property?: Property
  propertyId?: number | null
  propertyKey?: string | null
  fallback?: ReactElement
}

export const PropertyFormItem: FC<Props> = ({ property, propertyId, propertyKey, fallback, ...formItemProps }) => {
  const { isDisabledProperty, getPropertyById, getPropertyByKey } = useCustomForm()

  // use property from props, or get it from propertyId or property
  const prop = property || (propertyId && getPropertyById(propertyId)) || (propertyKey && getPropertyByKey(propertyKey))
  if (!prop) return fallback || null

  const showLabel = !prop.hide_on_web && !NO_LABEL_PT.includes(prop.property_type_id)
  const hideInput = prop.hide_on_web || prop.property_type_id === PT.Spacer

  const inputRenderer = InputRendererMap[prop.property_type_id]

  return (
    <Form.Item
      name={prop._field}
      className={cn('py-2 px-4')}
      required={prop.required}
      label={showLabel ? <Label name={prop._field} k={prop._field} /> : ' '}
      {...formItemProps}
    >
      {hideInput || !inputRenderer ? <Input hidden /> : inputRenderer(prop, isDisabledProperty)}
    </Form.Item>
  )
}
