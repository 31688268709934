import { JOTAI_GLOBAL_STORE } from '@/constants/app'
import { useUrlState } from '@/hooks'
import { queryClient } from '@/query'
import { ListResponse } from '@/types/api/core'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai/react'
import { atomWithStorage } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { getQueryOptions } from './helpers'
import { QueryTableParams, QueryTableProps } from './types'

export const globalPageSizeAtom = atomWithStorage<string | number>('ocPageSize', 25, undefined, { getOnInit: true })

export const useQueryTableQuery = ({
  queryApi,
  queryParams,
  queryOptions,
  queryOptimize = true,
  paramsStore = 'local',
  orderParam = 'order'
}: QueryTableProps) => {
  const [globalPageSize, setGlobalPageSize] = useAtom(globalPageSizeAtom, { store: JOTAI_GLOBAL_STORE })
  const defaultParams = useMemo(
    () => ({
      filter: null,
      page: 1,
      pageSize: paramsStore !== 'local' ? Number(globalPageSize) || 25 : 25,
      order: undefined
    }),
    [globalPageSize, paramsStore]
  )

  const urlParamsState = useUrlState<QueryTableParams>(defaultParams)
  const localParamsState = useState<QueryTableParams>(defaultParams)

  const { params, setParams } = useMemo(() => {
    const [_params, _setParams] = paramsStore === 'url' ? urlParamsState : localParamsState

    return {
      params: {
        ..._params,
        page: Number(_params.page) || defaultParams.page,
        pageSize: Number(_params.pageSize) || defaultParams.pageSize,
        order: _params.order || defaultParams.order
      },
      setParams: (params: Partial<QueryTableParams>) => {
        if (params?.pageSize && paramsStore !== 'local') setGlobalPageSize(params.pageSize || 25)
        _setParams({ ..._params, ...params })
      }
    }
  }, [defaultParams, localParamsState, paramsStore, setGlobalPageSize, urlParamsState])

  queryParams = typeof queryParams === 'function' ? queryParams(params) : queryParams

  const pagedParams = {
    ...queryParams,
    [orderParam]: params.order || queryParams?.order || '-id',
    order: orderParam !== 'order' ? undefined : params.order || queryParams?.order || '-id', // remove if custom order param is provided
    limit: params.pageSize,
    page: params.page
  }

  queryOptions = getQueryOptions(queryOptions)

  const dataQuery = useQuery<ListResponse<any>, any>({
    ...queryApi({ ...pagedParams, ...(queryOptimize ? { no_count: true } : {}) }),
    ...{ retry: 0, ...queryOptions }
  })

  const countQuery = useQuery<ListResponse<any>, any>({
    ...queryApi({ ...pagedParams, no_data: true }),
    ...{ retry: 0, ...queryOptions, enabled: queryOptimize }
  })

  const [items, total] = useMemo(() => {
    const items = dataQuery.data?.items || []
    const total = countQuery.data?.total || dataQuery.data?.total || items.length
    return [items, total]
  }, [dataQuery.data, countQuery.data])

  // handle 404 error
  const _q = queryOptimize ? countQuery : dataQuery
  if (_q.isError && params.page > 1 && _q.error && _q.error.response && _q.error.response.status === 404) {
    // usually caused by wrong page number
    // reset page number to 1
    // important! use setTimeout to avoid infinite re-render
    setTimeout(() => setParams({ page: 1 }), 100)
  }

  const refresh = () => queryClient.refetchQueries({ queryKey: [(queryApi().queryKey || [])[0]], type: 'active' })

  return { params, setParams, items, total, dataQuery, countQuery, refresh }
}
