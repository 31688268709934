import { useApp, useSession } from '@/hooks'
import { equipmentApi, equipmentInventoryStatsApi, officeApi } from '@/services/api-service'
import { Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, DatePicker, Form, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/layout'

const last30Days = dayjs().subtract(30, 'days').toISOString()

export const LedgerView = () => {
  const { l } = useApp()
  const { company } = useSession()
  const { search } = useLocation()
  const skuId = new URLSearchParams(search).get('id')
  const [sku, setSku] = useState<number | null>(skuId ? Number(skuId) : null)
  const [office, setOffice] = useState<string>('')
  const [isCustom, setIsCustom] = useState<boolean>(false)
  const [beginDate, setBeginDate] = useState<string | null>(last30Days)
  const [endDate, setEndDate] = useState<string | null>(null)

  const equipmentsQuery = useQuery(
    equipmentApi.list({ compact: 1, company_id__exact: company.id, inventory__exact: 1, limit: 'None' })
  )

  const officesQuery = useQuery(officeApi.list({ compact: 1, company_id__exact: company.id, limit: 'None' }))

  const inventoryStatsQuery = useQuery({
    ...equipmentInventoryStatsApi.list({
      ['ids[]']: sku,
      company_wide: 1,
      fields: 'transactions',
      current: 0,
      detail: 1,
      limit: 'None',
      ...(office ? { 'offices[]': office } : {}),
      ...(beginDate ? { begin_date: beginDate } : {}),
      ...(endDate ? { end_date: endDate } : {})
    }),
    enabled: !!sku
  })

  const dataSource = useMemo(() => {
    const items = inventoryStatsQuery.data?.items || []
    const transactions = (items[0]?.transactions || []) as any[]

    return transactions.map((item, index) => ({
      key: index,
      ...item
    }))
  }, [inventoryStatsQuery.data])

  return (
    <Layout>
      <Form layout="vertical" className="flex gap-x-16">
        <Form.Item label="Ledger">
          <Select
            popupMatchSelectWidth={false}
            value={sku}
            placeholder="Select"
            loading={equipmentsQuery.isLoading}
            className="min-w-[150px]"
            options={equipmentsQuery.data?.items
              .map((item) => ({ label: item.sku, value: item.id }))
              .sort((a, b) => a.label.localeCompare(b.label, 'en'))}
            onChange={(value) => setSku(value)}
          />
        </Form.Item>
        <Form.Item label="Office">
          <Select
            popupMatchSelectWidth={false}
            value={office}
            placeholder="Select"
            loading={officesQuery.isLoading}
            className="min-w-[150px]"
            options={[
              {
                label: 'All offices',
                value: ''
              },
              ...(officesQuery.data?.items.map((item) => ({ label: item.name, value: item.id })) || [])
            ]}
            onChange={(value) => setOffice(value)}
          />
        </Form.Item>
        <Space>
          <Form.Item label="Window">
            <Select
              value={isCustom ? 'custom' : last30Days}
              placeholder="Select"
              className="min-w-[150px]"
              options={[
                {
                  label: 'Last 30 days',
                  value: last30Days
                },
                {
                  label: 'Custom',
                  value: 'custom'
                }
              ]}
              onChange={(value) => {
                if (value === 'custom') {
                  setIsCustom(true)
                  setBeginDate(null)
                } else {
                  setIsCustom(false)
                  setBeginDate(value)
                  setEndDate(null)
                }
              }}
            />
          </Form.Item>
          {isCustom && (
            <>
              <Form.Item label=" ">
                <Checkbox
                  checked={beginDate === null}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setBeginDate(null)
                    } else {
                      setBeginDate('')
                    }
                  }}
                >
                  Beginning on time
                </Checkbox>
              </Form.Item>
              {typeof beginDate === 'string' && (
                <Form.Item label="Start date">
                  <DatePicker
                    value={beginDate ? dayjs(beginDate) : null}
                    onChange={(date) => {
                      setBeginDate(date.toISOString())
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item label=" ">
                <Checkbox
                  checked={endDate === null}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEndDate(null)
                    } else {
                      setEndDate('')
                    }
                  }}
                >
                  Now
                </Checkbox>
              </Form.Item>
              {typeof endDate === 'string' && (
                <Form.Item label="End date">
                  <DatePicker
                    value={endDate ? dayjs(endDate) : null}
                    disabledDate={(date) => date.isBefore(dayjs(beginDate))}
                    onChange={(date) => {
                      setEndDate(date.toISOString())
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Space>
      </Form>
      <Table
        dataSource={dataSource}
        loading={inventoryStatsQuery.isFetching}
        columns={[
          {
            title: l('Ticket'),
            dataIndex: 'ticket__name'
          },
          {
            title: l('Location'),
            dataIndex: 'location__name'
          },
          {
            title: 'Unit',
            dataIndex: 'unit'
          },
          {
            title: 'Date',
            dataIndex: 'timepoint',
            render: (value: string) => dayjs(value).format('M/D/YYYY'),
            sorter: (a, b) => a.timepoint.localeCompare(b.timepoint, 'en'),
            defaultSortOrder: 'descend'
          },
          {
            title: 'Quantity',
            dataIndex: 'amount',
            align: 'right',
            render: (value: number) => {
              return (value < 0 && -value) || 0
            }
          },
          {
            title: 'Restock',
            dataIndex: 'amount',
            align: 'right',
            render: (value: number) => {
              return (value > 0 && value) || 0
            }
          },
          {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'right'
          }
        ]}
      />
    </Layout>
  )
}
