import { equipmentPriceApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export type UpsertEquipmentPriceVariables = {
  id?: number
  company: number
  customer_office: number
  equipment: number
  usage_rate: number | null
}

export const useUpsertEquipmentPrices = () => {
  const deleteEquipmentPriceMutation = useMutation(equipmentPriceApi.delete())
  const updateEquipmentPriceMutation = useMutation(equipmentPriceApi.update<any, UpsertEquipmentPriceVariables>())
  const createEquipmentPriceMutation = useMutation(equipmentPriceApi.create<any, UpsertEquipmentPriceVariables>())

  const upsertEquipmentPrices = async (items: UpsertEquipmentPriceVariables[]) => {
    return Promise.all(
      items.map((item) => {
        // Backend does not apply customer pricing if usage rate is 0, null or undefined
        if (!item.id && !item.usage_rate) {
          // Nothing to do here
          return
        }

        if (item.id && !item.usage_rate) {
          return deleteEquipmentPriceMutation.mutateAsync({ id: item.id })
        }

        if (item.id) {
          return updateEquipmentPriceMutation.mutateAsync(item)
        }

        return createEquipmentPriceMutation.mutateAsync(item)
      })
    )
  }

  return {
    upsertEquipmentPrices,
    updateEquipmentPriceMutation,
    createEquipmentPriceMutation
  }
}
