import { AuthCompany } from '@/types/auth-user'
import { CustomForm, Ticket } from '../schemas'

export const getNextTicketNo = (customForm: Partial<CustomForm>, company: { ticket_gen_number?: number }) => {
  if (customForm.use_form_sequence) return 1 + (customForm.ticket_gen_number || 0)

  return 1 + (customForm.primary_company__ticket_gen_number || company.ticket_gen_number || 0)
}

export const makeNewTicket = (form: CustomForm, company: AuthCompany, ticketId = 0): Ticket => ({
  id: ticketId,
  custom_form_id: form.id,
  name: !ticketId ? getNextTicketNo(form, company) : null,
  status: !ticketId ? 'D' : null,
  computed_status: !ticketId ? 'D' : null,
  alerts: [],
  attachments: [],
  contacts: [],
  notes: [],
  signatures: [],
  properties: [],
  subscribers: [],
  customer_office_id: !ticketId ? form.default_customer_office_id || null : null,
  customer_office__name: !ticketId ? form.default_customer_office__name || null : null,

  customer_id: !ticketId ? form.default_customer_contact_id || null : null,
  customer__first_name: !ticketId ? form.default_customer_contact__first_name || null : null,
  customer__last_name: !ticketId ? form.default_customer_contact__last_name || null : null,

  _tpByPropertyId: {},
  _tpByPropertyKey: {},
  _isNew: !ticketId,
  _access: form.permissions.find((p) => p.company_id === company.id) || {
    company_id: company.id,
    list: false,
    create: false,
    write: false
  }
})
