import { Popover as AntPopover, PopoverProps } from 'antd'
import React from 'react'

type Props = PopoverProps & {
  contentClassNames?: string
}

/**
 * Wrapper for Antd Popover component.
 *
 * Fixes the following issues:
 * - Warning: findDOMNode is deprecated in StrictMode
 * - See: https://stackoverflow.com/questions/70684982/adding-tooltip-to-input-causes-finddomnode-is-deprecated-in-strictmode-error
 */
export const Popover: React.FC<Props> = ({ children, contentClassNames, ...rest }) => {
  return (
    <AntPopover {...rest}>
      <div className={contentClassNames}>{children}</div>
    </AntPopover>
  )
}
