import { apiHttp } from '@/services/api-service/core'
import { JasResourceResponse, JasResourceUseQueryResponse } from '@/types/jas-resource'
import { createSchedule } from '@/utils/create-schedule'
import { jasRecourcesEndpoint } from '@/utils/endpoints'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

export type Variables = {
  id?: number
  start_date: string
  end_date: string
}

export const getJasResourceQueryKey = (variables: Variables) => ['jas-resource', variables]

export const useGetJasResourceQuery = (
  variables: Variables,
  options?: UseQueryOptions<
    JasResourceUseQueryResponse,
    unknown,
    JasResourceUseQueryResponse,
    ReturnType<typeof getJasResourceQueryKey>
  >
) => {
  return useQuery({
    queryKey: getJasResourceQueryKey(variables),
    queryFn: () => getJasResource(variables),
    ...options
  })
}

const getJasResource = async (variables: Variables): Promise<JasResourceUseQueryResponse> => {
  const { id, ...params } = variables

  const response = await apiHttp.get<JasResourceResponse>(jasRecourcesEndpoint(variables.id), {
    params: {
      status__iexact: 'A', // active only
      for_calendar: true,
      ...params
    }
  })

  const employee = {
    ...response.data,
    schedules: createSchedule({
      data: response.data as any,
      startDate: dayjs(variables.start_date),
      endDate: dayjs(variables.end_date)
    })
  }

  return employee
}
