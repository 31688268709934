import { useViewData } from '@/layouts'
import { PageView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { integrationsApiV2, moduleApi } from '@/services/api-service'
import { safeJsonParse } from '@/utils'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useEffect, useRef } from 'react'

type DashboardViewData = {
  id: number
  title: string
  embed: { id: string; url: string; token: string }
}

export const DashboardView = view<any, DashboardViewData>(Component, {
  title: ({ data }) => data.title,
  loader: async ({ params }) => {
    const module = await fetchQuery(
      moduleApi.get<any>(Number(params?.moduleId), undefined, { fields: 'id,title,data_json' })
    )
    const moduleData = safeJsonParse(module.data_json) || {}

    return {
      moduleId: module.id,
      title: module.title || 'Dashboard',
      embed: await fetchQuery(integrationsApiV2.supersetEmbed({ id: moduleData.dashboard_embed_id }))
    }
  }
})

function Component() {
  const { data: viewData } = useViewData<DashboardViewData>()

  return (
    <PageView header={{ accent: true, border: true, breadcrumbs: true, title: viewData.title }} asLayout={true}>
      <DashboardEmbed config={viewData.embed} />
    </PageView>
  )
}

function DashboardEmbed({ config }: { config: DashboardViewData['embed'] }) {
  const mountElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!mountElRef.current) return
    mountElRef.current.innerHTML = ''

    embedDashboard({
      id: config.id,
      supersetDomain: config.url,
      mountPoint: mountElRef.current as HTMLElement,
      fetchGuestToken: () => Promise.resolve(config.token),
      dashboardUiConfig: {
        hideTitle: true,
        hideTab: false,
        filters: { visible: true, expanded: true }
      }
    })
  }, [config])

  return <div ref={mountElRef} className={'flex flex-1 [&_iframe]:flex-1'} />
}
