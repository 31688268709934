import { z } from 'zod'

// schemas
export const CustomerOfficeSchema = z.object({
  id: z.number(),
  name: z.string(),
  company_id: z.number(),
  company__name: z.string(),
  address_id: z.number(),
  address__line1: z.string(),
  address__city: z.string(),
  address__state: z.string(),
  address__zipcode: z.string()
})

// types
export type CustomerOffice = z.infer<typeof CustomerOfficeSchema>
