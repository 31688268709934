import { Form, Space } from 'antd'
import { DeleteButton } from './delete-button'
import { ModuleSettingsSection } from './module-settings-section'
import { ModuleTypeCreateTicketFields } from './module-type-create-ticket-fields'
import { ModuleTypeDashboardFields } from './module-type-dashboard-fields'
import { ModuleTypeDocumentsFields } from './module-type-documents-fields'
import { ModuleTypeJobManagerFields } from './module-type-job-manager-fields'
import { ModuleTypeJobsFields } from './module-type-jobs-fields'
import { ModuleTypeKanbanBoardFields } from './module-type-kanbard-board-fields'
import { ModuleTypeLineItemsFields } from './module-type-line-items-fields'
import { ModuleTypeLoadsFields } from './module-type-loads-fields'
import { ModuleTypeMyTimeFields } from './module-type-my-time-fields'
import { ModuleTypeReportsFields } from './module-type-reports-fields'
import { ModuleTypeSchedulerFields } from './module-type-scheduler-fields'
import { ModuleTypeSummaryWizardFields } from './module-type-summary-wizard-fields'
import { ModuleTypeTicketsCalendarFields } from './module-type-tickets-calendar-fields'
import { ModuleTypeTicketsFields } from './module-type-tickets-fields'
import { ModuleTypeTicketsMapFields } from './module-type-tickets-map-fields'
import { ModuleTypeTimeManagerFields } from './module-type-time-manager-fields'
import { SaveButton } from './save-button'
import { ViewsTableSection } from './views-table-section'

type Props = {
  module: any
}

export const ModuleBuilderForm = ({ module }: Props) => {
  const [form] = Form.useForm()
  const viewType = Form.useWatch('view_type', form) ?? ''
  const module_data = module.data || {}
  const module_config = module.config || {}

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...module,
        config: module_config,
        data: {
          ...module_data,

          ...(module_data.hidden_statuses !== undefined
            ? {
                hidden_statuses: module_data.hidden_statuses ? module_data.hidden_statuses.split(',') : []
              }
            : {}), // value for select in multiple mode should be array

          ...(module_data.statuses !== undefined
            ? {
                statuses: module_data.statuses ? module_data.statuses.split(',') : []
              }
            : {}), // value for select in multiple mode should be array

          ...(!(module_data.list_line_items === void 0 && module_data.group_items === void 0) && {
            $list_item_conf:
              (Number(module_data.group_items as boolean) * 2) | (Number(module_data.list_line_items as boolean) * 1)
          }),

          layers:
            module_data.layers?.map((layer: any) => ({
              ...layer,
              fields: layer.fields?.map((field: any) => ({
                name: field[0],
                type: field[1],
                property: field[2]
              }))
            })) ?? []
        }
      }}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.data?.stages_form_id) {
          form.setFieldsValue({
            data: {
              ...allValues.data,
              search_props: [],
              thumbnail: null,
              title: null,
              description1: null,
              description2: null,
              searchBy: null
            }
          })
        }
      }}
    >
      <Form.Item name="id" hidden />
      <Form.Item name={['data', 'stages_form_id']} hidden />
      <Form.Item name="selected_view" hidden />
      <Form.Item name="config_json" hidden />
      <Form.Item name="data_json" hidden />
      <Form.Item name={['document', 'id']} hidden />
      <div className="flex justify-end mb-16">
        <Space>
          <DeleteButton />
          <SaveButton />
        </Space>
      </div>
      <ModuleSettingsSection />
      <div className="mb-24">
        {
          {
            J: <ModuleTypeJobsFields />,
            TM: <ModuleTypeTimeManagerFields />,
            T: <ModuleTypeTicketsFields />,
            TC: <ModuleTypeTicketsCalendarFields />,
            M: <ModuleTypeTicketsMapFields />,
            S: <ModuleTypeSummaryWizardFields />,
            R: <ModuleTypeReportsFields />,
            MT: <ModuleTypeMyTimeFields />,
            L: <ModuleTypeLoadsFields />,
            I: <ModuleTypeLineItemsFields />,
            K: <ModuleTypeKanbanBoardFields />,
            JM: <ModuleTypeJobManagerFields />,
            SD: <ModuleTypeSchedulerFields />,
            D: <ModuleTypeDocumentsFields />,
            B: <ModuleTypeDashboardFields />,
            C: <ModuleTypeCreateTicketFields />
          }[viewType as string]
        }
      </div>
      <ViewsTableSection />
    </Form>
  )
}
