import { Button } from '@/ui/button'
import { useSetAtom } from 'jotai'
import { customFormAtom } from '../../../../atoms'
import { Company } from '../../../../types'

type Props = {
  company: Company
  disabled?: boolean
}

export const DeleteButton = ({ company, disabled }: Props) => {
  const setCustomForm = useSetAtom(customFormAtom)

  const handleClick = () => {
    setCustomForm((prev) => ({
      ...prev,
      companies: prev.companies?.filter(({ id }) => id !== company.id),
      access_rules: prev.access_rules?.filter(({ company: companyId }) => companyId !== company.id)
    }))
  }

  return (
    <Button
      disabled={disabled}
      iconName="svg:trash"
      type="text"
      onClick={handleClick}
      data-cy="custom-form-company-delete-button"
    />
  )
}
