import { useApp } from '@/hooks'
import { qbSettingApi } from '@/services/api-service'
import { AppLink, Button } from '@/ui'
import { saveBlob } from '@/utils/blob'
import { useQuery } from '@tanstack/react-query'
import { Form, Space, Switch } from 'antd'
import { ConnectQuickbookOnline } from './connect-quickbook-online'

export const ConnectSection = () => {
  const form = Form.useFormInstance()
  const qbSettingsId = Form.useWatch('id')
  const doc = Form.useWatch('doc') || {}
  const isQboEnabled = doc.accounting?.qbo_enabled
  const isQbwcEnabled = doc.accounting?.qbwc_enabled
  const { notification } = useApp()

  const quickBooksPasswordQuery = useQuery({
    ...qbSettingApi.get<any>(qbSettingsId, {}, {}, 'password'),
    enabled: false
  })

  const downloadQuickBooksApplicationQuery = useQuery({
    ...qbSettingApi.get<any>(
      qbSettingsId,
      {
        transformResponse: (res, header) => {
          return {
            file: res,
            filename: header['content-disposition'].split('filename=')[1]
          }
        }
      },
      {},
      'qbwc'
    ),
    enabled: false
  })

  const handleDownloadQuickBooksWebApplication = async () => {
    if (!qbSettingsId) {
      notification.error({ message: 'Please save QuickBooks settings first' })
      return
    }

    const { data } = await downloadQuickBooksApplicationQuery.refetch()
    const blob = new Blob([data.file], { type: 'text/plain' })
    saveBlob(blob, data.filename)
  }

  return (
    <div>
      <h4 className="font-bold">Connect</h4>
      <div className="mb-16">
        <Space>
          <Form.Item
            noStyle
            name={['doc', 'accounting', 'qbwc_enabled']}
            getValueFromEvent={(checked) => {
              if (checked) {
                form.setFieldsValue({ doc: { ...doc, accounting: { ...doc.accounting, qbo_enabled: false } } })
              }
              return checked
            }}
          >
            <Switch />
          </Form.Item>
          <span>QuickBooks Desktop</span>
        </Space>
      </div>
      {isQbwcEnabled && (
        <Form.Item>
          <Space>
            <Button type="primary" onClick={handleDownloadQuickBooksWebApplication}>
              Get QuickBooks Web Application
            </Button>
            <Button type="primary" onClick={() => quickBooksPasswordQuery.refetch()}>
              Get New Password
            </Button>
            <AppLink to="https://media.oilcommand.com/public/OC_QuickBooks_Bot_Setup.exe">
              <Button type="primary">Download QuickBooks Bot</Button>
            </AppLink>
          </Space>
        </Form.Item>
      )}
      {quickBooksPasswordQuery.data?.password && (
        <div className="mb-16">Your application password is: {quickBooksPasswordQuery.data.password}</div>
      )}
      <div className="mb-16">
        <Space>
          <Form.Item
            noStyle
            name={['doc', 'accounting', 'qbo_enabled']}
            getValueFromEvent={(checked) => {
              if (checked) {
                form.setFieldsValue({ doc: { ...doc, accounting: { ...doc.accounting, qbwc_enabled: false } } })
              }
              return checked
            }}
          >
            <Switch />
          </Form.Item>
          <span>QuickBooks Online</span>
        </Space>
      </div>
      {isQboEnabled && (
        <Form.Item>
          <ConnectQuickbookOnline />
        </Form.Item>
      )}
    </div>
  )
}
