import { useApp, useSession } from '@/hooks'
import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { navigate } from '@/routing/helpers'
import { Button } from '@/ui/button'
import { useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { customFormAtom, customFormIconAtom, isNameErrorAtom, isTypeErrorAtom } from '../atoms'
import {
  useCustomFormQuery,
  useDeleteAccessRules,
  useUploadCustomFormIcon,
  useUpsertAccessRules,
  useUpsertAlerts,
  useUpsertCustomForm
} from '../hooks'
import { transformObjToCustomPrintCss } from '../utils'

export const SaveCustomFormButton = () => {
  const { id } = useParams<{ id: string }>()
  const { company } = useSession()
  const { customFormQuery } = useCustomFormQuery()
  const { upsertCustomForm } = useUpsertCustomForm()
  const { uploadCustomFormIcon } = useUploadCustomFormIcon()
  const { notification } = useApp()
  const { upsertAlerts } = useUpsertAlerts()
  const { upsertAccessRules } = useUpsertAccessRules()
  const { deleteAccessRules } = useDeleteAccessRules()
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const customFormIcon = useAtomValue(customFormIconAtom)
  const setIsNameError = useSetAtom(isNameErrorAtom)
  const setIsTypeError = useSetAtom(isTypeErrorAtom)
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()

  // TODO: Move this to useUpsertCustomForm hook
  const handleSave = async () => {
    if (!customForm.name) {
      setIsNameError(true)

      notification.error({
        message: 'Please enter name'
      })

      return
    }

    if (!customForm.type) {
      setIsTypeError(true)

      notification.error({
        message: 'Please select type'
      })

      return
    }

    setIsLoading(true)

    const {
      _contact_search,
      _existing_lines_only,
      userdata_json,
      _sku_search,
      _print,
      _restrict_print,
      _templates,
      _hide_item_fields,
      default_customer_contact_id,
      default_customer_contact,
      default_customer_office,
      default_team_members,
      custom_print_css,
      userdata,
      ...values
    } = customForm

    // TODO: This doesn't look good. Maybe backend should get only userdata and not userdata_json
    const userdataJsonParsed = JSON.parse(userdata_json || '{}')

    userdataJsonParsed.contact_search = _contact_search // contact_search is not updated unless we send json
    userdataJsonParsed.existing_lines_only = _existing_lines_only // existing_lines_only is not updated unless we send json

    // sku_search is not updated unless we send json
    userdataJsonParsed.sku_search = {
      ...userdataJsonParsed.sku_search,
      ..._sku_search
    }
    userdataJsonParsed.hide_item_fields = _hide_item_fields
    userdataJsonParsed.print = _print // print is not updated unless we send json
    userdataJsonParsed.restrict_print = _restrict_print // restrict_print is not updated unless we send json
    userdataJsonParsed.email_templates = _templates // templates is not updated unless we send json
    const newUserdata_json = JSON.stringify(userdataJsonParsed, null, 2)
    ///

    // send seperate request for alerts
    await upsertAlerts()

    // send seperate request for access rules
    await upsertAccessRules()

    // send seperate request for deleting access rules
    await deleteAccessRules()

    const response = await upsertCustomForm({
      ...values,
      primary_company: values.primary_company || company.id,
      userdata_json: newUserdata_json,
      default_customer_contact: default_customer_contact_id ?? (null as any),
      default_customer_office: default_customer_office?.id ?? (null as any),
      default_team_members: default_team_members?.map((v) => v.id) || [],
      custom_print_css: transformObjToCustomPrintCss(custom_print_css),
      alerts: customForm.alerts?.map((alert) => ({
        ...alert,
        ticket_statuses: alert.ticket_statuses?.join(',')
      }))
    })

    // Send separate request for uploading icon
    // current production does the same. Sep 2, 2023
    await uploadCustomFormIcon(response.id, customFormIcon ? customFormIcon.originFileObj : undefined)
    await customFormQuery.refetch()
    queryClient.refetchQueries(['events'])

    setIsLoading(false)

    notification.success({
      message: 'Custom form saved'
    })

    // If user is creating a new custom form, redirect to edit page
    if (!id && response.id) {
      setTimeout(() => {
        navigate(SETTINGS_PATHS.editCustomForm(response.id))
      }, 1000)
    }
  }

  return (
    <div className="flex justify-end gap-x-8">
      <Button
        type="success"
        iconName="mi:save"
        loading={isLoading}
        onClick={handleSave}
        data-cy="save-custom-form-button"
      >
        Save
      </Button>
    </div>
  )
}
