import { useApp } from '@/hooks'
import { TabsView } from '@/layouts/views'
import { view } from '@/routing'

export const ReportsLayout = view(Component, {
  title: () => 'Reporting'
})

function Component() {
  const { l } = useApp()

  return (
    <TabsView
      asLayout
      header={{ breadcrumbs: true }}
      items={[
        { to: 'custom_reports', label: l('nav__reports_custom') },
        { to: 'programmed_reports', label: l('nav__reports_programmed') },
        { to: 'scheduled_reports', label: l('nav__reports_scheduled') }
      ]}
    ></TabsView>
  )
}
