import { PROGRAMMED_REPORT_TYPES, ROOT_MODEL_NAME } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { navigate } from '@/routing/helpers'
import { companyApi, contentTypeApi, programmedReportApi } from '@/services/api-service'
import { Modal, QuerySelect } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'

type Props = {
  onCancel: () => void
}

export const CreateReportModal = ({ onCancel }: Props) => {
  const isSuperAdmin = useIsSuperAdmin()
  const { company } = useSession()
  const createProgrammedReportMutation = useMutation(programmedReportApi.create())
  const [form] = Form.useForm()
  const type = useWatch('type', form)
  const { notification } = useApp()
  const contentTypesQuery = useQuery(contentTypeApi.list<{ pk: number; name: string }>({ limit: 'None' }))

  const handleCreateProgrammedReport = async () => {
    const { report, company, type } = await form.validateFields()

    const addedReport = await createProgrammedReportMutation.mutateAsync({
      company,
      type,
      report: {
        ...report,
        root_model: contentTypesQuery.data?.items.find((val) => val.name === report?.root_model_name)?.pk
      }
    })

    notification.success({ message: 'Programmed report added' })
    if (type === 'E') {
      navigate(`/programmed_reports/${addedReport.id}/etl`)
    } else {
      navigate(`/programmed_reports/${addedReport.id}/${addedReport.report?.id}/edit`)
    }
  }

  return (
    <Modal
      open
      title="New Programmed Report"
      okText="Add Report"
      onCancel={onCancel}
      onOk={handleCreateProgrammedReport}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          type: 'E',
          company: company.id,
          report: {
            root_model_name: ROOT_MODEL_NAME['line item']
          }
        }}
      >
        <Form.Item label="Report Type" name="type">
          <Select
            options={[
              {
                label: PROGRAMMED_REPORT_TYPES.B,
                value: 'B'
              },
              {
                label: PROGRAMMED_REPORT_TYPES.E,
                value: 'E'
              }
            ]}
          />
        </Form.Item>
        <Form.Item label="Report Model" name={['report', 'root_model_name']} hidden={type !== 'B'}>
          <Select
            options={Object.values(ROOT_MODEL_NAME).map((value) => ({
              label: <div className="capitalize">{value}</div>,
              value
            }))}
          />
        </Form.Item>
        <Form.Item label="Report Name" rules={[{ required: true }]} name={['report', 'name']}>
          <Input />
        </Form.Item>
        <Form.Item label="Report Description" rules={[{ required: true }]} name={['report', 'description']}>
          <Input />
        </Form.Item>
        <Form.Item label="Company" name="company" className="mb-0">
          <QuerySelect
            disabled={!isSuperAdmin}
            apiValueBy={'id'}
            apiSearchBy="name"
            apiEndpoint={companyApi.list}
            apiQueryParams={{ fields: 'id,name' }}
            renderOption={(item) => ({ label: item.name, value: item.id })}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
