import { CrontabEditorModel } from '@/components/crontab-editor-model'
import { UserSelect } from '@/components/user-select'
import { useApp } from '@/hooks'
import { useAtomSelect } from '@/hooks/atom'
import { propertyApi, timezoneApi } from '@/services/api-service'
import { Button, ButtonProps, Select } from '@/ui'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useCallback, useMemo, useState } from 'react'
import { bpaConfigAtom, bpaPropertyAtom } from '../../atoms'
import { BpaTrigger, BpaTriggerScheduleConfig } from '../../schemas'

type Props = {
  trigger: BpaTrigger
  config: BpaTriggerScheduleConfig
  setConfig: <K extends keyof BpaTriggerScheduleConfig>(key: K, value: BpaTriggerScheduleConfig[K]) => void
  index: number
}

export function ScheduleTrigger({ config, setConfig }: Props) {
  const [isCronModalOpen, setCronModalOpen] = useState<boolean>(false)

  const timezonesQuery = useQuery({ ...timezoneApi.list() })
  const timezoneOptions = useMemo(() => {
    return Object.keys(timezonesQuery.data?.items || {})
      .map((timezone) => ({ label: timezone, value: timezone }))
      .filter((timezone) => timezone.label.includes('America'))
  }, [timezonesQuery.data?.items])

  return (
    <div className={'flex flex-row gap-10 items-center justify-between'}>
      <div className="flex flex-row flex-wrap items-center gap-10">
        <Form.Item className={'m-0'} label="Run As" rules={[{ required: true }]} required>
          <UserSelect
            className={'min-w-[200px]'}
            allowClear={false}
            status={'active'}
            value={config?.run_as || undefined}
            onChange={(value) => setConfig('run_as', value)}
          />
        </Form.Item>
        <Form.Item className={'m-0'} label="Timezone">
          <Select
            placeholder={'Company Timezone'}
            className={'min-w-[200px]'}
            defaultValue={config?.timezone}
            onChange={(value) => setConfig('timezone', value)}
            options={timezoneOptions}
          />
        </Form.Item>

        <div className={'flex flex-col gap-8'}>
          {config?.cron_spec && (
            <div>
              Repeats: {config?.cron_spec}, {humanizeCronExpr(config?.cron_spec)}
            </div>
          )}
          <div>
            <Button type="primary" size={'small'} onClick={() => setCronModalOpen(true)} iconName={'fa:gear'}>
              Repeat
            </Button>
            <CrontabEditorModel
              isOpen={isCronModalOpen}
              onChange={(value) => setConfig('cron_spec', value)}
              cronExpression={config?.cron_spec || '0 0 * * *'}
              onClose={() => setCronModalOpen(false)}
            />
          </div>
        </div>
      </div>
      <RunNowButton />
    </div>
  )
}

function RunNowButton(props: ButtonProps) {
  const { notification } = useApp()
  const propertyId = useAtomSelect(bpaPropertyAtom, 'id')
  const bpaConfig = useAtomValue(bpaConfigAtom)
  const enabled = bpaConfig.enableBpa

  const saveMutation = useMutation(propertyApi.patch())
  const triggerMutation = useMutation(propertyApi.triggerPipeline())

  const handleRunNow = useCallback(() => {
    saveMutation
      .mutateAsync({ id: propertyId, bpa_config: bpaConfig })
      .then(() => {
        triggerMutation
          .mutateAsync({
            id: propertyId,
            trigger_type: 'schedule',
            trigger_data: { schedule_at: dayjs.now().formatISO() }
          })
          .then((result) => {
            if (result?.success) {
              notification.success({ message: 'Run Success', description: 'Pipeline triggered successfully' })
            } else {
              notification.error({
                message: 'Run Failed',
                description: result?.message || 'Failed to trigger pipeline'
              })
            }
          })
          .catch(() => {
            notification.error({ message: 'Run Failed', description: 'Failed to trigger pipeline' })
          })
      })
      .catch(() => {
        notification.error({ message: 'Pipeline Save Failed', description: 'Failed to save pipeline configuration' })
      })
  }, [bpaConfig, notification, propertyId, saveMutation, triggerMutation])

  return (
    <Button
      size={'small'}
      type={'success'}
      iconName={'fa:play'}
      onClick={handleRunNow}
      disabled={!enabled}
      loading={saveMutation.isLoading || triggerMutation.isLoading}
      {...props}
    >
      Run Now
    </Button>
  )
}
