import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { ViewTicketLink } from '@/modules/ticket/components'
import { ticketApiV2 } from '@/services/api-service'
import { Button, QueryTable, Tabs } from '@/ui'
import { getQueryColumn } from '@/ui/table/helpers'
import { csv } from '@/utils/csv'
import { Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { isEmpty } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { DisplayColumn } from '../components'
import { useActiveTableView, useTicketFields, useTicketSelection, useTicketsParam } from '../hooks'

type Props = {
  selectedView?: any
  ticketsQueryParams?: any
  onSelectedTicketsChange?: (selectedTickets: any[]) => void
}

export const TicketListForSummaryWizard = forwardRef<any, Props>((props, ref) => {
  const { selectedView, ticketsQueryParams, onSelectedTicketsChange } = props

  const customFormsService = useGlobalCustomForms()

  // filter params
  const { params } = useTicketsParam()
  const [totalFilteredTickets, setTotalFilteredTickets] = useState(0)
  // display fields
  const { displayFields, paramFields } = useTicketFields({
    selectedView,
    defaultFields: ['parent__name']
  })

  params.fields = csv([...paramFields])

  const displayColumns = useMemo(() => {
    if (isEmpty(displayFields)) return []

    const dfs = displayFields.map(
      (df): ColumnType<any> => ({
        key: df.key,
        dataIndex: df.sortField,
        sorter: !!df.sortField,
        fixed: df.fixed,
        width: df.width,
        title: <div className={'line-clamp-1'}>{df.name}</div>,
        render: (value, record) => <DisplayColumn customFormsService={customFormsService} field={df} record={record} />
      })
    )
    dfs.push({
      sorter: false,
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (_, record) => (
        <div className={'text-right'}>
          <ViewTicketLink ticketId={record.id} formId={0}>
            <Button type={'text'} iconName={'fa:chevron-circle-right'} iconClassName={'text-primary text-[18px]'} />
          </ViewTicketLink>
        </div>
      )
    })
    return dfs
  }, [customFormsService, displayFields])

  // selection
  const { selectedTicketsIds, selectedTickets, setSelectedTickets } = useTicketSelection()

  useImperativeHandle(
    ref,
    () => ({
      setSelectedTickets
    }),
    [setSelectedTickets]
  )

  useEffect(() => {
    if (onSelectedTicketsChange) {
      onSelectedTicketsChange(selectedTickets)
    }
  }, [onSelectedTicketsChange, selectedTickets])

  // hack: fixes initial duplicate request
  // by waiting for the displayColumns to be ready
  const { isReady } = useActiveTableView()

  if (!isReady || isEmpty(displayColumns)) return null

  return (
    <Tabs
      className="mt-24"
      type="solid"
      items={[
        {
          label: `Filtered Tickets (${totalFilteredTickets})`,
          key: 'filtered-tickets',
          children: (
            <QueryTable
              scroll={{ x: 'max-content', y: 'auto' }}
              queryApi={ticketApiV2.list}
              queryParams={{ ...params, ...ticketsQueryParams }}
              orderParam="custom_order"
              onQueryChange={(query, total) => setTotalFilteredTickets(total)}
              rowKey="id"
              rowSelection={{
                selectedRowKeys: selectedTicketsIds,
                preserveSelectedRowKeys: true,
                onChange: (_, selectedRows: any[]) => setSelectedTickets(selectedRows)
              }}
              columns={displayColumns}
            />
          )
        },
        {
          label: `Selected Tickets (${selectedTickets.length})`,
          key: 'selected-tickets',
          disabled: selectedTickets.length === 0,
          children: (
            <Table
              scroll={{ x: 'max-content', y: 'auto' }}
              rowKey="id"
              columns={displayColumns.map((c) => getQueryColumn(c))}
              dataSource={selectedTickets}
              rowSelection={{
                selectedRowKeys: selectedTicketsIds,
                preserveSelectedRowKeys: true,
                onChange: (_, selectedRows: any[]) => setSelectedTickets(selectedRows)
              }}
            />
          )
        }
      ]}
    />
  )
})

TicketListForSummaryWizard.displayName = 'TicketListForSummaryWizard'
