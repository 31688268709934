import { Space, Switch } from 'antd'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { equipmentAtom } from '../atoms'
import { useCategoryKindQuery } from '../hooks'
import { allFieldsMap } from '../utils'

export const AllFields = () => {
  const [showAllFields, setShowAllFields] = useState(false)
  const subCategory = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.category), []))
  const group = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.group), []))
  const { fields } = useCategoryKindQuery(subCategory?.kind)

  if (group) {
    return null
  }

  return (
    <div>
      <div>
        <Space>
          <div className="font-bold">Show all fields</div>
          <Switch checked={showAllFields} onChange={(checked) => setShowAllFields(checked)} />
        </Space>
      </div>
      {showAllFields && (
        <div className="grid grid-cols-2 gap-x-30 mt-30">
          {Object.entries(allFieldsMap).map(([key, Component]) => {
            // Show start_datetime and end_datetime fields even fields have them
            // because they are hidden in dynamic fields
            if (key !== 'start_datetime' && key !== 'end_datetime' && fields.includes(key)) {
              return null
            }

            return <div key={key}>{Component}</div>
          })}
        </div>
      )}
    </div>
  )
}
