import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { Button, Select } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { getLineItemName } from '@/utils/get-line-item-name'
import { Form, InputNumber, Space, Switch } from 'antd'
import { TableSelectField } from '../components'
import { useImportExportFileMutation } from '../hooks'

export const StepOne = () => {
  const form = Form.useFormInstance()
  const selectedTable = Form.useWatch('table')
  const { labels } = useApp()
  const { company } = useSession()
  const fieldAliases = JSON.parse(company.field_aliases) ?? {}
  const { handleSubmitImportExportFile, importExportFileMutation } = useImportExportFileMutation({ form, type: 'E' })

  return (
    <div>
      <SectionTitle rounded number={1}>
        Select Record Type & Generate the file
      </SectionTitle>
      <div className="mt-16">
        <div className="flex gap-x-16 items-end">
          <TableSelectField type="E" />
          <Button type="primary" loading={importExportFileMutation.isLoading} onClick={handleSubmitImportExportFile}>
            Generate
          </Button>
        </div>
        {selectedTable === 'S' && (
          <Form.Item label="Filter" className="mt-16">
            <Space>
              <Form.Item name="with_inactive" noStyle valuePropName="checked">
                <Switch />
              </Form.Item>
              <span>With Inactive (Retired)</span>
            </Space>
          </Form.Item>
        )}
        {selectedTable === 'I' && (
          <div className="grow mt-16">
            <Form.Item label="Export Fields" name="fields">
              <Select
                mode="multiple"
                options={CATEGORY_KIND_FIELDS.map((field) => ({
                  label: `${field} (${
                    getLineItemName({
                      field,
                      fieldAliases,
                      labels
                    }).name
                  })`,
                  value: field
                }))}
              />
            </Form.Item>
            <div className="flex gap-x-16 items-center">
              <div className="leading-tight">
                <div>Ticket Name</div>
                <div>(Range)</div>
              </div>
              <Form.Item label="From" name="ticket_name_start">
                <InputNumber />
              </Form.Item>
              <Form.Item label="To" name="ticket_name_end">
                <InputNumber />
              </Form.Item>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
