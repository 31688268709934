import { useApp } from '@/hooks'
import { scriptApi } from '@/services/api-service'
import { Script } from '@/types/script'
import { Button, Popconfirm, Select, Tooltip } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Space } from 'antd'
import { useMemo, useState } from 'react'
import { AddEditScript } from './add-edit-script'

export const CustomScript = () => {
  const form = Form.useFormInstance()
  const params = Form.useWatch('params', form) || {}
  const scriptsQuery = useQuery(scriptApi.list<Script>({ limit: 'None' }))
  const deleteScriptMutation = useMutation(scriptApi.delete())
  const createScriptMutation = useMutation(scriptApi.create())
  const updateScriptMutation = useMutation(scriptApi.update())
  const { notification } = useApp()
  const [editingScript, setEditingScript] = useState<Script | undefined>()
  const [isAddScript, setIsAddScript] = useState(false)
  const [isSaveScriptLoading, setIsSaveScriptLoading] = useState(false)

  const scriptOptions = useMemo(() => {
    return [...(scriptsQuery.data?.items || [])]
      .sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en'))
      .map((script) => ({
        value: script.id,
        label: script.name
      }))
  }, [scriptsQuery.data?.items])

  const handleDeleteScript = async () => {
    await deleteScriptMutation.mutateAsync({ id: params.script_id })
    await scriptsQuery.refetch()
    form.setFieldValue(['params', 'script_id'], null)
    notification.success({ message: 'Script deleted' })

    if (editingScript?.id === params.script_id) {
      setEditingScript(undefined)
    }
  }

  const handleSaveScript = async (values: any) => {
    try {
      setIsSaveScriptLoading(true)
      if (editingScript) {
        await updateScriptMutation.mutateAsync({ id: editingScript.id, ...values })
        setEditingScript(undefined)
      } else {
        const { id } = await createScriptMutation.mutateAsync(values)
        form.setFieldValue(['params', 'script_id'], id)
        setIsAddScript(false)
      }

      await scriptsQuery.refetch()
      setIsSaveScriptLoading(false)
    } catch {
      setIsSaveScriptLoading(false)
    }
  }

  const script = scriptsQuery.data?.items.find((script) => script.id === params.script_id)

  return (
    <>
      <Form.Item name={['params', 'env_vars']} label="Environment Variables">
        <Input />
      </Form.Item>
      <Form.Item label="Transformation Script" rules={[{ required: true }]}>
        <Select
          value={script?.id}
          options={scriptOptions}
          loading={scriptsQuery.isLoading}
          onChange={(value) => form.setFieldValue(['params', 'script_id'], value)}
        />
      </Form.Item>
      <div className="flex items-center">
        <Tooltip title="Add Script">
          <Button
            type="primary"
            onClick={() => {
              if (editingScript) {
                return
              }

              setIsAddScript(true)
            }}
            iconName="fa:add"
          />
        </Tooltip>
        {script && (
          <Space className="ml-auto">
            <Tooltip title={`Edit "${script?.name}"`}>
              <Button
                type="primary"
                iconName="fa:pen"
                onClick={() => {
                  if (isAddScript) {
                    return
                  }

                  setEditingScript(script)
                }}
              />
            </Tooltip>
            <Tooltip title={`Delete "${script?.name}"`}>
              <Popconfirm
                title={`Deleteing script ${script?.name}`}
                okText="Yes"
                description="Are you sure?"
                onConfirm={handleDeleteScript}
              >
                <Button type="primary" iconName="fa:trash" />
              </Popconfirm>
            </Tooltip>
          </Space>
        )}
      </div>
      {(isAddScript || editingScript) && (
        <AddEditScript
          script={editingScript}
          isLoading={isSaveScriptLoading}
          onSave={handleSaveScript}
          onCancel={() => {
            setIsAddScript(false)
            setEditingScript(undefined)
          }}
        />
      )}
    </>
  )
}
