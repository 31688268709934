import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

const options = [
  {
    label: 'Use line item grid layout on iPad',
    key: 'ios_grid_layout'
  },
  {
    label: 'Hide custom form from mobile',
    key: 'hide_mobile'
  },
  {
    label: 'Hide tickets from mobile',
    key: 'hide_mobile_tickets'
  },
  {
    label: 'Prevent mobile users from submitting a ticket with warnings',
    key: 'strict_validation'
  },
  {
    label: 'Allow users who have access to this custom form to add themselves to these tickets',
    key: 'allow_request_access'
  },
  {
    label: 'Allow users to add location on this ticket',
    key: 'allow_add_location'
  },
  {
    label: 'Allow Mobile Users to Add Contacts',
    key: 'allow_add_contact'
  }
]

export const MobileApp = () => {
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }

  return (
    <div className="border-r border-border">
      <h5 className="font-bold">Mobile App Settings</h5>
      {options.map(({ label, key }) => (
        <div key={key} className="mb-12">
          <Checkbox
            data-cy={`custom-form-${key}-checkbox`}
            checked={!!customForm[key as keyof typeof customForm]}
            onChange={(e) => handleChange(e, key)}
          >
            {label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
