import { useSession } from '@/hooks'
import { DatePickerProps, Input, InputProps, TimePickerProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'
import { LocalDatePicker } from '../local-date-picker'

type PickerProps = DatePickerProps | TimePickerProps

export type LocalDateTimePickerProps = Omit<PickerProps, 'value' | 'defaultValue'> & {
  value?: string | DatePickerProps['value']
  defaultValue?: Dayjs | string | null | undefined
  showDate?: boolean
  showTime?: boolean
  onChange?: (date: Dayjs | null, dateString: string) => void
  tz?: 'convert' | 'replace' | 'ignore'
}

/**
 * Generic date time picker for system-wide use.
 *
 * It extends the antd DatePicker and TimePicker components to provide the following features:
 * - Unified date and time picker from single component
 * - User preference for military time input
 * - String value support for date and time
 *
 * @param showDate If true, show date picker, default is true.
 * @param showTime If true, show time picker, default is true.
 * @param value String or Dayjs object for the current value.
 * @param defaultValue String or Dayjs object for the default value.
 * @param onChange Callback function when the value changes.
 * @param format Date format string for the picker.
 * @param tz Timezone handling for the date value.
 * @param props Additional props for the antd DatePicker or TimePicker component.
 */
export const LocalDateTimePicker: FC<LocalDateTimePickerProps> = ({
  showDate = true,
  showTime = true,
  value,
  defaultValue,
  onChange,
  format,
  tz,
  ...props
}) => {
  const valueObj: DatePickerProps['value'] = dayjs.parse(value, { tz }) || undefined
  const defaultValueObj: LocalDateTimePickerProps['defaultValue'] = dayjs.parse(defaultValue, { tz }) || undefined

  const { user } = useSession()

  const [flags, defaultFormat] = useMemo(() => {
    const militaryTime = !!user?.profile?.userdata_json?.preferences?.military_time_input

    const _flags = {
      isNone: !showDate && !showTime,
      isTimeOnly: !showDate && showTime,
      isDateOnly: showDate && !showTime,
      isDateTime: showDate && showTime
    }

    let _format

    if (_flags.isTimeOnly) {
      _format = militaryTime ? dayjs.$localFormat.militaryTime : dayjs.$localFormat.time
    } else if (_flags.isDateOnly) {
      _format = dayjs.$localFormat.date
    } else if (_flags.isNone) {
      _format = dayjs.$localFormat.dateTime
    } else {
      _format = militaryTime ? dayjs.$localFormat.militaryDateTime : dayjs.$localFormat.dateTime
    }

    return [_flags, _format]
  }, [user, showDate, showTime])

  if (flags.isNone) {
    return (
      <Input
        value={valueObj?.formatISO()}
        defaultValue={defaultValueObj?.formatISO()}
        onChange={(e) => {
          const date = dayjs.parse(e.target.value)
          onChange?.(date || null, date?.formatISO() || '')
        }}
        {...(props as InputProps)}
      />
    )
  }

  if (flags.isTimeOnly) {
    return (
      <LocalDatePicker.TimePicker
        value={valueObj}
        defaultValue={defaultValueObj}
        onChange={onChange}
        format={format || defaultFormat}
        {...props}
      />
    )
  }

  return (
    <LocalDatePicker
      value={valueObj}
      defaultValue={defaultValueObj}
      onChange={onChange}
      showTime={showTime}
      format={format || defaultFormat}
      {...props}
    />
  )
}
