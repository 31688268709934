import { clientRecordApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { FC } from 'react'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiSearchBy'>

export const JobRoleSelect: FC<Props> = ({ apiQueryParams, ...props }) => {
  return (
    <QuerySelect
      data-cy="resource-job-roles"
      apiSearchBy="char_1"
      apiEndpoint={clientRecordApi.list}
      apiQueryParams={{ label__eq: 'Job Roles', fields: 'id,char_1,char_4', ...apiQueryParams }}
      renderOption={(role) => {
        const accentColor = role.char_4
        return {
          value: role.id,
          label: (
            <div className={'flex flex-row gap-8 items-center'}>
              <div
                style={{ backgroundColor: accentColor || 'lightgray', borderColor: accentColor || 'lightgray' }}
                className={
                  'opacity-80 dark:opacity-60 rounded border w-4 h-14 group-hover:bg-primary transition-colors'
                }
              />
              {role.char_1}
            </div>
          )
        }
      }}
      // mapOption={['id', 'char_1']}
      {...props}
    />
  )
}
