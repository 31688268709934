import { useLayoutEffect, useState } from 'react'

export const useWindowSize = (): [number, number] => {
  const [size, setSize] = useState<[number, number]>(() => [window.innerHeight, window.innerWidth])

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setSize([window.innerHeight, window.innerWidth])
    })

    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return size
}
