import { getFileExtension } from '@/utils'
import { Avatar, AvatarProps } from 'antd'
import cn from 'classnames'
import { FC, useCallback, useState } from 'react'
import { Icon } from '../icons'
import { Image } from './Image'

const IMG_EXTENSIONS: string[] = ['jpg', 'jpeg', 'png', 'svg', 'gif']

export const FilePreview: FC<AvatarProps> = (props) => {
  const fileExtension = getFileExtension(props.src?.toString?.() || '')
  const isPDF = fileExtension === 'pdf'
  const isImg = IMG_EXTENSIONS.includes(fileExtension || '')
  const enablePreview = isPDF || isImg

  const [previewImage, setPreviewImage] = useState(false)

  const handlePreview = useCallback(() => {
    if (isImg) setPreviewImage(true)
    else if (isPDF) window.open(props.src as string, '_blank')
  }, [])

  return (
    <div
      className={cn('relative cursor-pointer overflow-hidden rounded', {
        'rounded-full': props.shape === 'circle',
        '!cursor-default': !enablePreview
      })}
    >
      <Avatar {...props} />
      {enablePreview && (
        <Image
          className={'!hidden'}
          preview={{ visible: previewImage, onVisibleChange: setPreviewImage }}
          src={props.src as string}
        />
      )}
      <div
        className={cn(
          'transition-all duration-100 ease-in bg-black opacity-0 hover:opacity-50',
          'flex flex-row items-center justify-center absolute top-0 right-0 w-full h-full',
          { '!hidden': !enablePreview }
        )}
        onClick={handlePreview}
      >
        <Icon name={'fa:eye'} className={'text-white'} />
      </div>
    </div>
  )
}
