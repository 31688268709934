import { PropertySelect } from '@/components'
import { CodeEditor } from '@/components/code-editor'
import { ScriptedButtonConfig } from '../../types'
import { FetchDirectionsButton } from './FetchDirectionsButton'

export const FetchDirectionsButtonConfig: ScriptedButtonConfig = {
  type: 'common_fetch_directions_button',
  iconName: 'fa:route',
  label: 'Fetch Directions',
  group: 'Common',
  render: () => <FetchDirectionsButton />,
  vars: [
    {
      name: 'district_pid',
      label: 'District Prop',
      rules: [{ required: true, message: 'District property is required' }],
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'directions_pid',
      label: 'Directions Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'shop_address_pid',
      label: 'Shop Address Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'shop_address_map',
      label: 'Shop Address Map',
      render: (form, props) => <CodeEditor mode="yaml" minLines={5} maxLines={Infinity} {...props} />
    }
  ]
}
