import { Collapse, DeferRender, Icon } from '@/ui'
import cn from 'classnames'
import { FC, useMemo } from 'react'
import { useTicketFlags } from '../../../../hooks'
import { PropertyGroup } from '../../../../schemas'
import styles from './properties.module.scss'
import { SectionProperties } from './section-properties'
import { SectionTitle } from './section-title'

type Props = { propertyGroup: PropertyGroup }

export const PropertySection: FC<Props> = ({ propertyGroup }) => {
  const { lockCollapsePropertyGroup } = useTicketFlags()

  const isLocked = useMemo(() => lockCollapsePropertyGroup(propertyGroup), [propertyGroup, lockCollapsePropertyGroup])

  return (
    <Collapse
      rootClassName={styles.sectionCollapseWrapper}
      ghost={!propertyGroup.bordered}
      destroyInactivePanel={false}
      expandIconPosition="start"
      expandIcon={({ isActive }) =>
        propertyGroup.collapsable ? (
          <Icon
            name={isLocked ? 'fa:lock' : 'fa:angle-right'}
            className={cn('!text-lg !text-neutral scale-110 transition-transform relative -left-2 mr-4', {
              'rotate-90': isActive,
              'left-0 mr-6': !isLocked
            })}
          />
        ) : null
      }
      defaultActiveKey={isLocked || propertyGroup.start_collapsed ? [] : ['1']}
      collapsible={!propertyGroup.collapsable || isLocked ? 'disabled' : 'header'}
      items={[
        {
          key: '1',
          label: <SectionTitle propertyGroup={propertyGroup} />,
          children: (
            <DeferRender time={10} fallback={<div className={'h-[200px]'} />}>
              <SectionProperties propertyGroup={propertyGroup} />
            </DeferRender>
          )
        }
      ]}
    />
  )
}
