import { CodeEditor } from '@/components/code-editor'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../atoms'

export const PrintingAdvanced = () => {
  const customPrintCss = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.custom_print_css), []))
  const customPrintJs = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.custom_print_js), []))
  const setCustomForm = useSetAtom(setCustomFormAtom)

  return (
    <div className="flex-1">
      <h3 className="font-bold">Printing - Advanced</h3>
      <div className="font-semibold">Custom CSS</div>
      <CodeEditor
        mode="css"
        height="400px"
        value={customPrintCss?.otherCss ?? ''}
        onChange={(value) => {
          setCustomForm({
            custom_print_css: {
              ...customPrintCss,
              otherCss: value
            } as any
          })
        }}
      />
      <div className="font-semibold mt-20">Custom JS</div>
      <CodeEditor
        mode="javascript"
        height="400px"
        value={customPrintJs ?? ''}
        onChange={(value) => {
          setCustomForm({
            custom_print_js: value
          })
        }}
      />
    </div>
  )
}
