import { TransformationTaskType } from '@/types/transformation-task'
import { Calculation } from './calculation'
import { CopyDataSet } from './copy-data-set'
import { CreateTab } from './create-tab'
import { CustomScript } from './custom-script'
import { DeleteRow } from './delete-row'
import { DeliverDataSet } from './deliver-data-set'
import { GenerateCustomVariable } from './generate-custom-variable'
import { LoadDataSet } from './load-data-set'
import { LoopScript } from './loop-script'
import { NameTab } from './name-tab'
import { RenameColumns } from './rename-columns'
import { SortBy } from './sort-by'

type Props = {
  type: TransformationTaskType
}

export const TransformationTask = ({ type }: Props) => {
  return <>{TRANSFORMATION_TASK_TO_COMPONENT_MAP[type]}</>
}

const TRANSFORMATION_TASK_TO_COMPONENT_MAP = {
  LoadDataSet: <LoadDataSet />,
  CreateTab: <CreateTab />,
  NameTab: <NameTab />,
  CopyDataSet: <CopyDataSet />,
  SortBy: <SortBy />,
  Calculation: <Calculation />,
  DeleteRow: <DeleteRow />,
  GenerateCustomVariable: <GenerateCustomVariable />,
  RenameColumns: <RenameColumns />,
  LoopScript: <LoopScript />,
  CustomScript: <CustomScript />,
  DeliverDataSet: <DeliverDataSet />
} as Record<TransformationTaskType, JSX.Element>
