import { userDataApi } from '@/services/api-service'
import { ListReturn } from '@/types/api/core'
import { safeJsonParse } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { uniq } from 'lodash'
import { useCallback } from 'react'
import { z } from 'zod'

const DataSourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  content: z.string().nullish(),
  multiselect: z.boolean(),
  _options: z.array(
    z.object({
      label: z.string(),
      value: z.string()
    })
  )
})
type DataSource = z.infer<typeof DataSourceSchema>

export const useDataSources = (queryOptions?: ListReturn<DataSource>) => {
  const dataSourcesQuery = useQuery({
    ...queryOptions,
    ...userDataApi.list({
      fields: DataSourceSchema,
      limit: 1000
    })
    // cacheTime: Infinity,
    // staleTime: Infinity
  })
  const dataSources = dataSourcesQuery.data?.items

  const getDataSource = useCallback(
    (sourceId: number): DataSource | null => {
      const _source = dataSources?.find((s) => s.id === sourceId)
      if (!_source) return null

      const contents: string[] = uniq(safeJsonParse(_source?.content))
      const _options = contents?.map((c) => ({ label: c, value: c })) || []
      return { ..._source, _options }
    },
    [dataSources]
  )

  return { dataSourcesQuery, dataSources, getDataSource }
}
