import { TicketStatus } from './TicketStatus'
import AccessCustomForm from './custom-form'
import AccessCustomNavigation from './custom-navigation'
import AccessModules from './modules'
import AccessOtherOption from './other-option'
import AccessPermissions from './permissions'
import AccessSKU from './sku-access'

export const AccessList = () => {
  return (
    <div className="flex flex-wrap">
      <AccessCustomNavigation />
      <AccessPermissions />
      <AccessCustomForm />
      <AccessModules />
      <AccessSKU />
      <TicketStatus />
      <AccessOtherOption />
    </div>
  )
}
