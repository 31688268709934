import { useSession } from '@/hooks'
import { useMemo } from 'react'
import { useTicket } from './use-ticket'
import { useTicketFormWatch } from './use-ticket-form'
import {
  useTicketAfe,
  useTicketCustomer,
  useTicketCustomerOffice,
  useTicketFullName,
  useTicketJobCode,
  useTicketLocation,
  useTicketOffice
} from './use-ticket-related'

export const useTicketObject = () => {
  const { timezone } = useSession()
  const { ticket, isNew } = useTicket()
  const status = useTicketFormWatch('status') || ticket.status
  const name = useTicketFullName()
  const office = useTicketOffice()
  const customerOffice = useTicketCustomerOffice()
  const customer = useTicketCustomer()
  const location = useTicketLocation()
  const afe = useTicketAfe()
  const jobCode = useTicketJobCode()

  // its structure must comply with json_ticket on backend
  // see: Ticket.update_properties method on backend
  const ticketObject = useMemo(
    () => ({
      ...ticket,
      ...{
        new: isNew,
        status,
        name,
        office,
        customer_office: customerOffice,
        customer,
        location,
        afe,
        job_code: jobCode,
        team_members: (ticket.contacts || []).map((c) => c.id),
        line_items: [], // not-supported in frontend
        timezone: timezone
      }
    }),
    [ticket, isNew, status, name, office, customerOffice, customer, location, afe, jobCode, timezone]
  )

  return { ticketObject }
}
