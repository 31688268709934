import { useApp } from '@/hooks'
import { equipmentApi } from '@/services/api-service'
import { ActionButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Equipment } from '../../schemas'

type Props = {
  equipment: Equipment
}

export const CopyButton = ({ equipment }: Props) => {
  const { notification, l } = useApp()

  const mutation = useMutation(equipmentApi.create({}, `${equipment.id}/copy`))

  const handleCopy = async () => {
    await mutation
      .mutateAsync({ id: equipment.id })
      .then(() => {
        notification.success({
          message: 'Copied',
          description: `${l('SKU')} copied successfully`
        })
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: `Failed to copy ${l('SKU')}`
        })
      })
  }

  return (
    <ActionButton
      type="text"
      iconName={'fa:copy'}
      data-cy={'copy-sku'}
      confirm={{
        title: 'Confirm Copy',
        okText: 'Copy',
        description: (
          <span>
            Copying {l('SKU')} <b>{equipment.sku}</b>, are you sure?
          </span>
        ),
        onConfirm: handleCopy
      }}
    />
  )
}
