import { contactApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { FC } from 'react'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiQueryParams' | 'apiSearchBy' | 'renderOption' | 'mapOption'>

export const AssigneeSelect: FC<Props> = (props) => {
  return (
    <QuerySelect
      apiEndpoint={contactApi.list}
      apiQueryParams={{ fields: 'id,first_name,last_name', profile__user__is_active: 1, registered_only: true }}
      apiSearchBy={(text) => ({ search: text })}
      renderOption={(item) => ({
        value: item.id,
        label: `${item?.first_name || ''} ${item?.last_name || ''}`
      })}
      placeholder={''}
      prefetch={true}
      {...props}
    />
  )
}
