import { TLabels } from '@/hooks/use-app'
import { LINE_ITEM_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_COLUMNS, TICKET_COLUMNS_WITH_PREFIX } from '@/modules/ticket/columns'
import { DraggableItem } from '../types'
import { processColumn } from './process-column'

export const buildAvailableColumn = (
  isLineItemReport: boolean,
  propertiesColumns: DraggableItem[],
  customForms: number[],
  labels: TLabels
): DraggableItem[] => {
  return [
    ...[
      ...(isLineItemReport ? TICKET_COLUMNS_WITH_PREFIX : TICKET_COLUMNS),
      ...(isLineItemReport ? LINE_ITEM_COLUMNS : [])
    ].map((column) => processColumn(column, labels)),
    ...propertiesColumns
  ]
    .sort((a, b) => (a.name || '').localeCompare(b.name, 'en'))
    .sort((a, b) => (a.category || '').localeCompare(b.category, 'en'))
    .filter((field) => (!field.p_id ? true : customForms.includes(Number(field.p_customform))))
}
