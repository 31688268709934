import { uuid4 } from '@/utils'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { isEqual, set } from 'lodash'
import { useCallback, useMemo } from 'react'
import { bpaConfigAtom } from '../atoms'
import { BpaVariable } from '../schemas'

export const useVariables = () => {
  const variables = useAtomValue(useMemo(() => selectAtom(bpaConfigAtom, (config) => config.variables, isEqual), []))
  return { variables }
}

export const useVariablesHandlers = () => {
  const setConfig = useSetAtom(bpaConfigAtom)

  const onAddVariable = useCallback(() => {
    setConfig((config) => {
      config.variables.push({
        uuid: uuid4(),
        name: `Var ${config.variables.length + 1}`,
        value: '',
        type: 'str'
      })
    })
  }, [setConfig])

  const onDeleteVariable = useCallback(
    (idx: number) => {
      setConfig((config) => {
        config.variables.splice(idx, 1)
      })
    },
    [setConfig]
  )

  const onFieldChange = useCallback(
    (key: string, value: string, idx: number) => {
      setConfig((config) => {
        set(config.variables[idx], key, value)
      })
    },
    [setConfig]
  )

  const onSortingChange = useCallback(
    (variables: BpaVariable[]) => {
      setConfig((config) => {
        config.variables = variables
      })
    },
    [setConfig]
  )

  return {
    onAddVariable,
    onDeleteVariable,
    onFieldChange,
    onSortingChange
  }
}
