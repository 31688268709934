import { ScriptedButton, ScriptedButtonShim } from '@/modules/ticket/form/components/buttons/scripted-button'
import { useTicketLoader } from '@/modules/ticket/form/hooks'
import { Property } from '@/modules/ticket/form/schemas'
import { FormLoader } from '@/modules/ticket/form/views/form-view/form-loader'
import { Alert } from 'antd'
import { FC, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type Props = {
  property: Property
  record: any
}
export const ButtonCell: FC<Props> = (props) => {
  const [render, setRender] = useState(false)

  const renderCell = useDebouncedCallback(() => setRender(true), 50)

  return render ? (
    <ScriptedButtonCell {...props} />
  ) : (
    <span onMouseEnter={renderCell} onMouseLeave={renderCell.cancel}>
      <ScriptedButtonShim property={props.property} />
    </span>
  )
}

const ScriptedButtonCell: FC<Props> = ({ property, record }) => {
  const { ticketQuery, apiTicket, apiCustomForm } = useTicketLoader(record.id, record.custom_form_id, true, {
    modifiedCheck: false
  })

  const isLoaded = !!apiTicket && !!apiCustomForm && apiCustomForm?.id === apiTicket?.custom_form_id
  const isError = ticketQuery.isError

  return (
    <>
      {isError && <Alert showIcon type={'error'} message={'Fetch Error'} />}
      {isLoaded ? (
        <FormLoader apiCustomForm={apiCustomForm} apiTicket={apiTicket}>
          <ScriptedButton property={property} />
        </FormLoader>
      ) : (
        <ScriptedButtonShim property={property} loading={true} />
      )}
    </>
  )
}
