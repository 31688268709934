import { Typography } from 'antd'
import { TextProps } from 'antd/es/typography/Text'

const { Text } = Typography

export function EllipsisMiddle({
  suffixCount,
  children,
  style,
  ...props
}: Omit<TextProps, 'children'> & { suffixCount: number; children?: string }) {
  children = children || ''
  const start = children.slice(0, children.length - suffixCount)
  const suffix = children.slice(-suffixCount).trim()
  return (
    <Text {...props} style={{ maxWidth: '100%', ...style }} ellipsis={{ suffix }}>
      {start}
    </Text>
  )
}
