import { useModalState } from '@/hooks'
import { Button, ButtonProps, ModalProps } from '@/ui'
import { FC } from 'react'
import { EditCustomerModal } from './edit-customer-modal'

type Props = ButtonProps & {
  customerId?: string | number
  modalProps?: Omit<ModalProps, 'onCancel'>
}

export const EditCustomerButton: FC<Props> = ({ customerId, modalProps, ...buttonProps }) => {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <Button onClick={openModal} onMouseEnter={renderModal} onFocus={renderModal} {...buttonProps} />
      {isRender && <EditCustomerModal customerId={customerId} open={isOpen} onCancel={closeModal} {...modalProps} />}
    </>
  )
}
