import { customFormApi, propertyApi, tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { PageTitle } from '@/ui/page-title'
import { PageWithStickyFooter } from '@/ui/page-with-sticky-footer'
import { Tabs } from '@/ui/tabs'
import { zodQueryFields } from '@/utils/zod'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { AdvancedTab } from './advanced-tab'
import { ConfigureTab } from './configure-tab'
import { Footer } from './footer'
import { TemplatesTab } from './templates-tab'
import { Property, TableViewFormValues } from './types'

const ZProperty = z.object({
  id: z.number(),
  key: z.string(),
  name: z.string(),
  property_type: z.object({ name: z.string().nullish() }).nullish(),
  property_group: z.object({ custom_form: z.object({ id: z.number(), name: z.string().nullish() }) }).nullish()
})

export const EditTableViewView = () => {
  const { id } = useParams()
  const tableViewQuery = useQuery({ ...tableViewApi.get<TableView>(id as any) })
  const [form] = Form.useForm<TableViewFormValues>()

  const customFormsQuery = useQuery(
    customFormApi.list({
      incl_deleted: 1,
      limit: 'None' as any,
      show_all: true,
      fields: 'id,name'
    })
  )

  const propertiesQuery = useQuery(
    propertyApi.list<Property>({
      property_group__custom_form_id__in: customFormsQuery.data?.items?.map((cf) => cf.id).join(','),
      limit: 'None' as any,
      fields: zodQueryFields(ZProperty)
    })
  )

  const { options, config } = tableViewQuery.data || {}

  const customForms = (customFormsQuery.data?.items || []).map((cf) => ({
    id: cf.id,
    name: cf.name,
    checked: options?.custom_forms?.includes(cf.id)
  }))

  const isLoading = tableViewQuery.isLoading || customFormsQuery.isLoading || propertiesQuery.isLoading

  return (
    <PageWithStickyFooter
      isLoading={isLoading}
      footer={<Footer form={form} tableView={tableViewQuery.data} onMutationSuccess={tableViewQuery.refetch} />}
    >
      <Form
        form={form}
        key={String(isLoading)}
        layout="vertical"
        initialValues={{
          ...tableViewQuery.data,
          custom_forms: customForms,
          config: {
            ...config,
            custom_filters: JSON.stringify(config?.custom_filters || {}, null, 2)
          }
        }}
      >
        <Form.Item name="id" hidden noStyle />
        <PageTitle className="mb-16">Edit View: {tableViewQuery.data?.name}</PageTitle>
        <Tabs
          type="solid"
          items={[
            {
              label: 'Configue',
              key: 'configue',
              children: <ConfigureTab tableView={tableViewQuery.data} properties={propertiesQuery.data?.items || []} />
            },
            {
              label: 'Templates',
              key: 'templates',
              children: <TemplatesTab />
            },
            {
              label: 'Advanced',
              key: 'advanced',
              children: <AdvancedTab />
            }
          ]}
        />
      </Form>
    </PageWithStickyFooter>
  )
}
