import { Button } from '@/ui/button'
import { ColorPicker, Form, Input } from 'antd'

export const MapColors = () => {
  return (
    <div>
      <div className="font-bold mb-12">Map Colors (Global)</div>
      <Form.List name={['data', 'colors']}>
        {(fields, { add, remove }) => {
          return (
            <>
              <div className="grid grid-cols-5 gap-16 mb-12">
                {fields.map((field, index) => (
                  <div key={index} className="flex gap-x-12 items-center border border-border rounded p-8">
                    <Form.Item label="Key" name={[field.name, 'key']} className="mb-0">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Color" name={[field.name, 'color']} getValueFromEvent={(e) => e.toHexString()}>
                      <ColorPicker />
                    </Form.Item>
                    <Form.Item className="mb-0">
                      <Button iconName="svg:trash" type="text" onClick={() => remove(index)} />
                    </Form.Item>
                  </div>
                ))}
              </div>
              <Button iconName="mi:add" onClick={() => add({ key: 'City - State Zip', color: 'red' })}>
                Add Color
              </Button>
            </>
          )
        }}
      </Form.List>
    </div>
  )
}
