import { lineItemApi, lineItemApiV2 } from '@/services/api-service'
import { dig } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useCallback } from 'react'
import { clearEmptyFields, copyFromLineItem } from '../helpers'
import { LineItem, LineItemForm, LineItemSchema } from '../schemas'
import { useEquipmentSelection } from './use-equipment-selection'
import { useFormWatch } from './use-form-watch'
import { useItemsStats } from './use-items-stats'

export const useItemSave = () => {
  const form = Form.useFormInstance<LineItemForm>()
  const ticketId = useFormWatch(['options', 'ticketId'])

  const groupItems = useFormWatch(['options', 'groupItems'])
  const hasGroupItems = groupItems?.length > 0
  const groupItemsQuery = useQuery({
    ...lineItemApiV2.list({
      fields: LineItemSchema,
      id__in: groupItems?.map((item: any) => item.id).join(','),
      order: 'sequence',
      inc_archived: 1,
      no_count: '1'
    }),
    enabled: hasGroupItems
  })

  const { lastGroupNo, lastSequenceNo } = useItemsStats(ticketId)
  const { equipments, equipmentGroupsMap } = useEquipmentSelection()

  const createMutation = useMutation(lineItemApi.create())
  const patchMutation = useMutation(lineItemApi.patch())
  const isSaving = createMutation.isLoading || patchMutation.isLoading || (hasGroupItems && groupItemsQuery.isLoading)

  const saveLineItems = useCallback(async () => {
    const formValues = await form.validateFields()
    const validatedData = dig(formValues, 'data') as Partial<LineItem>
    if (!validatedData) return // no data to save

    const formData: LineItemForm = form.getFieldsValue(true)
    const { type, ticketId, customFormId, groupNo, lastGroupNoOverride, lastSequenceNoOverride, payloadOverride } =
      formData.options || {}

    if (validatedData.id && type !== 'duplicate') {
      //
      // -- save - edit - line item
      //
      return Promise.all([patchMutation.mutateAsync(validatedData)])
    } else {
      if (!ticketId && !customFormId) throw new Error('Ticket or Custom Form is required')

      let nextGroupNo = (lastGroupNoOverride ? lastGroupNoOverride : lastGroupNo) + 1
      let nextSequenceNo = (lastSequenceNoOverride ? lastSequenceNoOverride : lastSequenceNo) + 1

      const payloadItems: any[] = []
      equipments.forEach((equipment) => {
        if (equipment.group_no) {
          //
          // -- save - new - group line items
          //
          const group = equipmentGroupsMap.get(equipment.group_no)
          if (!group || !(group.equipment || []).length) return // skip

          group.equipment.forEach((equipment) => {
            payloadItems.push({
              ...(validatedData as Partial<LineItem>),
              group: nextGroupNo,
              sequence: nextSequenceNo,
              sync_equipment: true, // sync non modified fields from equipment
              sync_datetime: group.sync_datetime,
              equipment: equipment.id,
              category: equipment.category,
              ticket: ticketId || undefined,
              custom_form: ticketId ? undefined : customFormId, // only if ticketId is not present
              ...(payloadOverride || {})
            })
            nextSequenceNo++ // increment sequence no
          })
          nextGroupNo++ // increment group no
          if (group.subtotal) payloadItems[payloadItems.length - 1].subtotal_after = true
        } else {
          //
          // -- save - new - regular line item
          //
          payloadItems.push({
            ...validatedData,
            group: groupNo,
            sequence: nextSequenceNo++,
            sync_equipment: true,
            equipment: equipment.id,
            category: equipment.category_id,
            ticket: ticketId || undefined,
            custom_form: ticketId ? undefined : customFormId, // only if ticketId is not present
            ...(payloadOverride || {})
          })
        }
      })

      //
      // save - duplicate - group line items
      //
      if (type === 'duplicate') {
        groupItemsQuery.data?.items?.forEach((item) => {
          payloadItems.push({
            ...copyFromLineItem(item),
            ...clearEmptyFields(validatedData),
            id: undefined,
            uuid: undefined,
            ticket: item.ticket_id,
            category: item.category_id,
            equipment: item.equipment_id,
            group: nextGroupNo,
            sequence: nextSequenceNo++,
            ...(payloadOverride || {})
          })
        })
      }

      return Promise.all(payloadItems.map((payloadItem) => createMutation.mutateAsync(payloadItem)))
    }
  }, [
    createMutation,
    equipmentGroupsMap,
    equipments,
    form,
    groupItemsQuery.data?.items,
    lastGroupNo,
    lastSequenceNo,
    patchMutation
  ])

  return {
    isSaving,
    saveLineItems
  }
}
