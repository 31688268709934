import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { TableViewFormValues } from '@/modules/table-view/views/edit-table-view-view/types'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { customFormApi, propertyApi, tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { NavTabs } from '@/ui'
import { zodQueryFields } from '@/utils/zod'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { Outlet, useParams } from 'react-router-dom'
import { getEditTicketViewTabs } from './helpers'
import { SaveButton } from './save-button'
import { ZProperty } from './schemas'

export const TicketViewsFormLayout = view<any, any>(Component, {
  loader: async ({ params }) => {
    const id = params.id
    const tableView = await fetchQuery(tableViewApi.get<TableView>(Number(id), undefined, { fields: 'id,name' }))
    return { title: `Edit ${tableView.name || ''}` }
  },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const { id } = useParams()
  const tableViewQuery = useQuery({ ...tableViewApi.get<TableView>(id as any) })
  const [form] = Form.useForm<TableViewFormValues>()
  const { l } = useApp()

  const customFormsQuery = useQuery(
    customFormApi.list({
      incl_deleted: 1,
      limit: 'None' as any,
      show_all: true,
      fields: 'id,name'
    })
  )

  const propertiesQuery = useQuery(
    propertyApi.list({
      property_group__custom_form_id__in: customFormsQuery.data?.items?.map((cf) => cf.id).join(','),
      limit: 'None' as any,
      fields: zodQueryFields(ZProperty)
    })
  )

  const { options, config } = tableViewQuery.data || {}

  const customForms = (customFormsQuery.data?.items || []).map((cf) => ({
    id: cf.id,
    name: cf.name,
    checked: options?.custom_forms?.includes(cf.id)
  }))

  const isLoading = tableViewQuery.isLoading || customFormsQuery.isLoading || propertiesQuery.isLoading

  return (
    <PageView
      header={{
        title: l('TableView'),
        border: false,
        accent: false,
        actions: [<SaveButton key={'save-table-view'} form={form} onMutationSuccess={tableViewQuery.refetch} />]
      }}
      loading={isLoading}
    >
      {!isLoading && (
        <div className={'flex-1 flex flex-row gap-10'}>
          <div className={'border-r pr-0'}>
            <NavTabs className={'w-[200px]'} orientation={'vertical'} items={id ? getEditTicketViewTabs(id) : []} />
          </div>
          <div className={'flex-grow overflow-hidden'}>
            <Form
              form={form}
              key={String(isLoading)}
              layout="vertical"
              initialValues={{
                ...tableViewQuery.data,
                custom_forms: customForms,
                config: {
                  ...config,
                  custom_filters: JSON.stringify(config?.custom_filters || {}, null, 2)
                }
              }}
            >
              <Form.Item name="id" hidden noStyle />
              <Outlet context={{ tableView: tableViewQuery.data, properties: propertiesQuery.data?.items || [] }} />
            </Form>
          </div>
        </div>
      )}
    </PageView>
  )
}
