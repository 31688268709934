import { useAtomValue } from 'jotai'
import { CSSProperties, FC } from 'react'
import { editingComponentsAtom } from '../../../../atoms'
import { ItemColumnType, LineItem } from '../../../../schemas'
import { TableRow } from './table-row'

type Props = {
  columns: ItemColumnType[]
  item: Partial<LineItem>
  style?: CSSProperties
  className?: string
}

export const ItemComponents: FC<Props> = ({ columns, item, style, className }) => {
  const editingComponents = useAtomValue(editingComponentsAtom)
  if (editingComponents.includes(item.id as number)) {
    return <TableRow isEdit={true} columns={columns} item={item} style={style} className={className} />
  }

  if (item.show_components) {
    return <TableRow isEdit={false} columns={columns} item={item} style={style} className={className} />
  }

  return null
}
