import { cn } from '@/utils'
import { Form, Input } from 'antd'
import { useSetAtom } from 'jotai'
import { useDebouncedCallback } from 'use-debounce'
import { customFiltersAtom } from '../../atoms'
import { useActiveTableView } from '../../hooks'

export const CustomFiltersTicketFilter = () => {
  const { activeView } = useActiveTableView()
  const setCustomFilters = useSetAtom(customFiltersAtom)
  const onChange = useDebouncedCallback((key, value) => setCustomFilters((prev) => ({ ...prev, [key]: value })), 500)

  return (
    <Form layout={'vertical'} className="pt-10 mb-2">
      {activeView?.config?.custom_filters?.search?.map(({ key, label }) => (
        <div key={key} className="relative">
          <div className={cn('absolute top-[-12px] left-6 z-10')}>
            <span className={'px-8 text-sm cursor-pointer bg-background border rounded-lg leading-4'}>{label}</span>
          </div>
          <Form.Item className="m-0">
            <Input onChange={(e) => onChange(key, e.target.value)} allowClear></Input>
          </Form.Item>
        </div>
      ))}
    </Form>
  )
}
