import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
export const TextForMobile = ({ firstName, username }: { firstName: string; username: string }) => {
  const { notification } = useApp()
  const [copy, setCopy] = useState(false)
  const handleCopy = () => {
    setCopy(true)
    notification.success({
      message: 'Copied'
    })
  }
  const contentToShow = `Subject: Oil Command Mobile App Login Instructions

Hello ${firstName},
    
We have created an Oil Command user login for you to use on your phone.

To start, please download the Oil Command App on your phone.

If you have an Apple phone please use this link:   <a href="https://apps.apple.com/us/app/oil-command-mobile/id1159420379" target="__blank" class="underline text-gray-500"> iOS Oil Command App</a>
If you have an Android phone, please use this link: <a href="https://play.google.com/store/apps/details?id=com.oilcommand.crudehauling&hl=en_US&gl=US" target="__blank" class="underline text-gray-500"> Android Oil Command App</a>

Once you have installed the app, use the following credentials to get in.


Here is your information. 
Username: <strong>${username}</strong>
Password: XXXX

Additionally, you can use the "forgot password" link on the login page to reset your password. That requires you to have the correct email address in your user profile. 

Thanks!
Oil Command Support
`
  const contentToCopy = `Subject: Oil Command Mobile App Login Instructions

Hello ${firstName},
    
We have created an Oil Command user login for you to use on your phone.

To start, please download the Oil Command App on your phone.

If you have an Apple phone please use this link:   https://apps.apple.com/us/app/oil-command-mobile/id1159420379
If you have an Android phone, please use this link: https://play.google.com/store/apps/details?id=com.oilcommand.crudehauling&hl=en_US&gl=US

Once you have installed the app, use the following credentials to get in.


Here is your information. 
Username: ${username}
Password: XXXX

Additionally, you can use the "forgot password" link on the login page to reset your password. That requires you to have the correct email address in your user profile. 

Thanks!
Oil Command Support
`
  return (
    <div>
      <div className="whitespace-wrap">
        <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: contentToShow }}></div>
      </div>
      <div className="mt-16 flex justify-end">
        <CopyToClipboard text={contentToCopy} onCopy={handleCopy}>
          <Button type="primary" iconName="mi:content_copy">
            {copy ? 'Copied' : 'Copy'}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
