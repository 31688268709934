import { Tooltip } from 'antd'

export function PatchChangeIndicator() {
  return (
    <div className={'absolute right-4 top-4'}>
      <Tooltip title={'Unsaved'}>
        <div className="w-5 h-5 rounded-full bg-primary opacity-75" />
      </Tooltip>
    </div>
  )
}
