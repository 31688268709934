import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { startCase } from 'lodash'
import { CustomFormSelect } from '../custom-form-select'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { IconSelect } from '../icon-select'
import { PropertiesSelect } from '../properties-select'

export const ModuleTypeJobManagerFields = () => {
  const form = Form.useFormInstance()
  const customFormsQuery = useQuery(customFormApi.list({ limit: 'None' as any, fields: 'id,name' }))
  const customFormId = Form.useWatch(['config', 'for_jm', 'custom_form_id'], form)

  return (
    <div>
      <h5 className="font-bold">Job Manager Config</h5>
      <div className="grid grid-cols-3 gap-16 mb-6">
        <CustomFormSelect
          formItemProps={{ label: 'Custom Form', name: ['config', 'for_jm', 'custom_form_id'] }}
          renderOptions={(options) => [{ label: 'Default', value: '' }, ...options]}
        />
        <HideDefaultNoneView />
      </div>
      <div className="grid grid-cols-3 gap-16">
        {['title', 'description', 'line_1', 'line_2', 'line_3', 'notes'].map((item, index) => (
          <div key={index} className="rounded shadow p-12">
            <div className="font-bold mb-16">{startCase(item)}</div>
            <div className="grid grid-cols-3 gap-x-16">
              <Form.Item label="Icon" name={['config', 'for_jm', `${item}_icon`]}>
                <IconSelect onSelect={(icon) => form.setFieldValue(['config', 'for_jm', `${item}_icon`], icon)} />
              </Form.Item>
              <PropertiesSelect
                formItemProps={{
                  label: 'Bind Property',
                  name: ['config', 'for_jm', `${item}_pid`],
                  className: 'col-span-2'
                }}
                customFormsIds={customFormId ? [customFormId] : (customFormsQuery.data?.items.map((cm) => cm.id) ?? [])}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
