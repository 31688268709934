import { accountRoleApi, profileApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { limitAtom, orderByAtom, pageAtom, searchTextAtom, selectedRoleAtom } from '../atoms'
import { ZProfileTableData, roleSchema } from '../schema'
import { showInactiveAccountAtom } from './atom'

export const useRolesFetchQuery = () => {
  const query = useQuery({
    ...accountRoleApi.list({
      page: 1,
      limit: 1000,
      incl_deleted: 1
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((role) => roleSchema.parse(role))
    })
  })

  return query
}
type queryProps = {
  page: number
  limit: number
  order: string
  role_id__eq?: number
  search?: string | null
  user__is_active__eq?: number
}
export const useProfileFetchQuery = () => {
  const page = useAtomValue(pageAtom)
  const searchText = useAtomValue(searchTextAtom)
  const orderBy = useAtomValue(orderByAtom)
  const limit = useAtomValue(limitAtom)
  const selectedRole = useAtomValue(selectedRoleAtom)
  const showInactiveAccount = useAtomValue(showInactiveAccountAtom)

  let payloads: queryProps = {
    page,
    limit,
    order: orderBy,
    user__is_active__eq: showInactiveAccount === 0 ? 1 : 0
  }
  if (selectedRole !== -1) {
    payloads = { ...payloads, role_id__eq: selectedRole }
  } else {
    delete payloads.role_id__eq
  }

  if (searchText.trim()) {
    payloads = { ...payloads, search: searchText.trim() }
  }

  const query = useQuery({
    ...profileApi.list<ZProfileTableData>(payloads),
    refetchOnMount: true,
    refetchOnWindowFocus: true
  })

  return query
}
