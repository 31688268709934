import { TABLE_DEFAULT_PAGE_SIZE } from '@/constants/general'
import { equipmentApi } from '@/services/api-service'
import { Modal, QueryTable } from '@/ui'
import { Button, ButtonProps } from '@/ui/button'
import { SearchInput } from '@/ui/search-input'
import { Checkbox } from 'antd'
import { atom, useAtom } from 'jotai'
import { useState } from 'react'
import { DetailedEquipment } from '../schema'
import { CategoriesSelect } from './categories-select'

const searchAtom = atom('')

type Props = {
  onAdd: (equipments: DetailedEquipment[]) => void
  triggerProps?: ButtonProps
  triggerText?: string
  modalTitle?: string
  queryParams?: Record<string, any>
  extraOnSearchRight?: React.ReactNode
  extraAfterTable?: React.ReactNode
}

export const AddEquipmentsModal = ({
  onAdd,
  extraOnSearchRight,
  queryParams,
  modalTitle,
  triggerProps,
  triggerText
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [category, setCategory] = useState<number>(-1)
  const [subCategory, setSubCategory] = useState<number>(-1)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useAtom(searchAtom)
  const [selectedEquipments, setSelectedEquipments] = useState<DetailedEquipment[]>([])

  const handleAdd = async () => {
    onAdd(selectedEquipments)
    setSelectedEquipments([])
    setPage(1)
    setLimit(TABLE_DEFAULT_PAGE_SIZE)
    setCategory(-1)
    setSubCategory(-1)
    setSearch('')
    setIsOpen(false)
  }

  return (
    <>
      <Button iconName="mi:add" {...triggerProps} onClick={() => setIsOpen(true)}>
        {triggerText ? triggerText : 'Add'}
      </Button>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        title={modalTitle}
        okText="Add"
        width={800}
        className="top-34 z-[1000]"
        onOk={handleAdd}
      >
        <CategoriesSelect
          category={category}
          subCategory={subCategory}
          onChange={(category) => {
            setCategory(category)
            // Reset sub category
            setSubCategory(-1)
          }}
          onSubCategoryChange={(subCategory) => setSubCategory(subCategory)}
          onClear={() => {
            setCategory(-1)
            setSubCategory(-1)
          }}
        />
        <div className="flex items-center gap-x-12 mt-14">
          <SearchInput atom={searchAtom} />
          {extraOnSearchRight}
        </div>
        <QueryTable
          queryApi={equipmentApi.list}
          queryParams={{
            order: 'sku',
            limit,
            page,
            // Add parent category query if sub category is not selected and category is selected
            ...(category !== -1 && subCategory === -1 && { category__parent_category__exact: category }),
            ...(subCategory !== -1 && { category__exact: subCategory }),
            ...(search.trim() !== '' && { search }),
            ...queryParams
          }}
          className="mt-14"
          scroll={{
            y: 'calc(100vh - 460px)'
          }}
          columns={[
            { title: 'SKU', width: 150, dataIndex: 'sku', sorter: false },
            { title: 'Description', dataIndex: 'description', sorter: false },
            {
              sorter: false,
              render: (_, equipment: any) => (
                <div>
                  <Checkbox
                    checked={selectedEquipments.some((item) => item.id === equipment.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedEquipments((prev) => [...prev, equipment])
                      } else {
                        setSelectedEquipments((prev) => prev.filter((item) => item.id !== equipment.id))
                      }
                    }}
                  />
                </div>
              )
            }
          ]}
        />
      </Modal>
    </>
  )
}
