import { useSession } from '@/hooks'
import { officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Card, FormListFieldData, Popconfirm } from 'antd'
import { AddEditOfficeForm } from '../../../../components/add-edit-office-form'

type Props = {
  company: Company
  field: FormListFieldData
  office?: Office
  onDelete?: () => void
}

export const OfficeCard = ({ office, company, field, onDelete }: Props) => {
  const deleteOfficeMutation = useMutation({ ...officeApi.delete() })
  const { permissions, company: userCompany } = useSession()

  const showDelete = () => {
    const companyAdmin = permissions.includes('company_admin')
    return companyAdmin && (userCompany.id == company.owner_company || userCompany.id == office?.creator_company)
  }

  const handleDelete = async () => {
    await deleteOfficeMutation.mutateAsync({ id: office?.id })
    onDelete?.()
  }

  return (
    <Card
      key={office?.id}
      title={office?.name}
      extra={
        showDelete() && (
          <Popconfirm
            title="Delete office"
            description={`Are you sure you want to delete #${office?.id}?`}
            okText="Delete"
            placement="topRight"
            cancelText="Cancel"
            onConfirm={handleDelete}
            okButtonProps={{
              'data-cy': 'delete-office-button-confirm'
            }}
          >
            <Button type="primary" iconName="svg:trash" data-cy="delete-office-button">
              Delete Office
            </Button>
          </Popconfirm>
        )
      }
    >
      <AddEditOfficeForm field={field} />
    </Card>
  )
}
