import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { Select } from '@/ui'
import { SelectProps } from 'antd'
import { useAtomValue } from 'jotai'
import { FC } from 'react'
import { customFormAtom } from '../../atoms'

export const StatusSelect: FC<SelectProps> = (props) => {
  const customForm = useAtomValue(customFormAtom)
  const { getStatusOptions } = useCustomFormsStatus()

  return <Select options={getStatusOptions(customForm?.id)} {...props} />
}
