import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const AdvancedSettings = () => {
  const userDataJson = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.userdata_json), []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (value: string) => {
    setCustomForm((prev) => ({
      ...prev,
      userdata_json: value
    }))
  }

  return (
    <div className="flex-1">
      <div className="font-bold">Advanced Settings</div>
      <div className="mb-8">Enable and configure advanced features and settings by using the appropriate JSON. </div>
      <Form.Item>
        <CodeEditor
          data-cy="advanced-settings-json-editor"
          mode="json"
          minLines={25}
          maxLines={Infinity}
          value={userDataJson || ''}
          onChange={handleChange}
        />
      </Form.Item>
    </div>
  )
}
