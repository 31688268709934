import { useApp } from '@/hooks'
import { Button, Icon } from '@/ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useJobTicketSave, useSelectedJobTicket } from '../../hooks'
import { useAddResource, useAddResourceCallback } from '../hooks'

export const AddResourceButton: React.FC = () => {
  const { notification, l } = useApp()
  const [save, setSave] = useState(false)

  const { isFromTicket, close } = useAddResource()
  const { isChanged, handleAddResource } = useAddResourceCallback()

  const { selectedTicket: jobTicket } = useSelectedJobTicket()
  const { saveTicket, isLoading } = useJobTicketSave()

  const handleSaveResource = useCallback(() => {
    if (!jobTicket) return

    saveTicket({
      force: true,
      action: 'saveResources',
      onSuccess: () => {
        notification.success({
          message: 'Resources Saved',
          description: `Resources for ${l('ticket__name__label')} ${jobTicket.name} has been saved`
        })
        close()
      },
      onError: () => {
        notification.error({
          message: 'Resources Save Failed',
          description: `Failed to save resources for ${l('ticket__name__label')} ${jobTicket.name}`
        })
      }
    })
  }, [close, jobTicket, l, notification, saveTicket])

  const handleAddOrSave = useCallback(() => {
    handleAddResource()
    if (isFromTicket) window.setTimeout(() => setSave(true), 250)
    else close()
  }, [close, handleAddResource, isFromTicket])

  const isSavingRef = useRef(false)
  useEffect(() => {
    if (save && !isSavingRef.current) {
      isSavingRef.current = true
      handleSaveResource()
    }
  }, [save, handleSaveResource])

  return (
    <Button
      loading={isLoading || isSavingRef.current}
      disabled={!isChanged}
      type={'primary'}
      className={'flex items-center justify-center'}
      icon={<Icon name={isFromTicket ? 'fa:save' : 'fa:check'} />}
      onClick={handleAddOrSave}
      success={isFromTicket}
    >
      {isFromTicket ? 'Save' : 'Select'}
    </Button>
  )
}
