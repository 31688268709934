import { propertyApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { DEFAULT_SCRIPT } from '../constants'
import { FormValues } from '../edit-property-modal/save-property/types'
import { usePropertyTypesQuery } from './use-property-types-query'

export const useUpsertPropertyMutation = () => {
  const createPropertyMutation = useMutation({ ...propertyApi.create() })
  const updatePropertyMutation = useMutation({ ...propertyApi.update() })
  const { id: customFormId } = useParams()
  const { getTypeNameById } = usePropertyTypesQuery()

  const handleUpsertProperty = async (property: FormValues) => {
    let bindingJson = property.binding_json
    const typeName = getTypeNameById(property?.property_type?.id)

    if ((property.script || '').replace(/[\t\r\n ]+/g, '') == DEFAULT_SCRIPT) {
      property.script = ''
    }

    if (typeName === 'ScriptedButton' || property.use_web_binding) {
      const newBindingJson = JSON.parse(property.binding_json || '{}') ?? {}
      newBindingJson.web = property.web_binding
      bindingJson = JSON.stringify(newBindingJson, null, 2)
    }

    const payload = {
      ...property,
      binding_json: bindingJson,
      property_type: property.property_type?.id,
      custom_form__id: customFormId
    }

    if (property.id) {
      await updatePropertyMutation.mutateAsync(payload)
    } else {
      await createPropertyMutation.mutateAsync(payload)
    }
  }

  return {
    createPropertyMutation,
    updatePropertyMutation,
    handleUpsertProperty
  }
}
