import { Button } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { Form, Upload } from 'antd'
import { useImportExportFileMutation } from '../hooks'

export const StepFour = () => {
  const form = Form.useFormInstance()
  const selectedFile = Form.useWatch('file')
  const { handleSubmitImportExportFile } = useImportExportFileMutation({ form, type: 'I' })

  return (
    <div className="mt-30">
      <SectionTitle rounded number={4}>
        Import New Dataset for Record Type
      </SectionTitle>
      <div>
        <div className="mb-16 mt-16">
          Updates to your account should take less than 60 seconds. Refresh your screen and get to work.
        </div>
        <div className="flex gap-x-16">
          <Form.Item label="Import File (.xls, .xlsx)" name="file">
            <Upload beforeUpload={() => false}>
              <div className="flex gap-x-16">
                <Button iconName="fa:cloud-upload-alt">Upload Excel File</Button>
                {selectedFile?.file && (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSubmitImportExportFile()
                    }}
                  >
                    Import
                  </Button>
                )}
              </div>
            </Upload>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}
