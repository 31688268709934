import { DeferRender } from '@/ui'
import { FC } from 'react'
import { ConditionsCard } from './conditions'
import { RulesCard } from './rules'
import { TriggersForm } from './triggers'
import { VariablesForm } from './variables'

export const SyncModeForm: FC = () => {
  return (
    <DeferRender>
      <TriggersForm />
      <VariablesForm />
      <ConditionsCard />
      <RulesCard />
    </DeferRender>
  )
}
