import { FormViewProps, ListViewProps, PageViewProps } from '@/layouts/views'

const RECORDS_VIEW_HEADER: PageViewProps['header'] = {
  accent: false,
  border: false
}

export const RECORDS_LIST_VIEW_HEADER: ListViewProps['header'] = {
  ...RECORDS_VIEW_HEADER,
  refresh: true
}

export const RECORDS_FORM_VIEW_HEADER: FormViewProps['header'] = {
  ...RECORDS_VIEW_HEADER,
  backButton: true
}
