import { z } from 'zod'

export const userDataSchema = z.object({
  id: z.number(),
  content: z.array(z.string()),
  name: z.string().nullish(),
  multiselect: z.boolean().nullish()
})

export type UserData = z.infer<typeof userDataSchema>
