import { CodeEditor } from '@/components/code-editor'
import { Form, Input } from 'antd'
import { FC } from 'react'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSyncFromIntakeTicket } from '../../schemas'

type Props = {
  action: BpaActionSyncFromIntakeTicket
  onChange: (data: Partial<BpaActionSyncFromIntakeTicket>) => void
}

export const InputSyncFromIntakeTicket: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div>
      <Form.Item label="Query Filters (Text)" tooltip="e.g. name__eq|P[JOB #]">
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 10 }}
          defaultValue={action.q_filter || ''}
          onChange={(e) => onChangeDebounced({ q_filter: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Order By" tooltip="e.g. name,-modified_at">
        <Input defaultValue={action.order_by || ''} onChange={(e) => onChangeDebounced({ order_by: e.target.value })} />
      </Form.Item>
      <Form.Item label="Update Values (YAML)">
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={action.values || ''}
          onChange={(value) => onChangeDebounced({ values: value })}
          mode="yaml"
        />
      </Form.Item>
    </div>
  )
}

export const InputSyncFromIntakeTicketMemo = React.memo(InputSyncFromIntakeTicket)
