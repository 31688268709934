import { AddOfficeView } from '@/modules/rolodex/views/add-office-view'
import { DisplayCompaniesView } from '@/modules/rolodex/views/companies-view/OffsetContent'
import { companyApi } from '@/services/api-service'
import { AppLink, Button } from '@/ui'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const ViewCompanyView = () => {
  const { id } = useParams()
  const companyQuery = useQuery({ ...companyApi.get<any>(Number(id)), enabled: !!id })
  const [isAddOffice, setIsAddOffice] = useState(false)

  if (companyQuery.isInitialLoading) {
    return <Spin isCentered spinning />
  }

  return (
    <div>
      {isAddOffice ? (
        <AddOfficeView onCancel={() => setIsAddOffice(false)} companyId={companyQuery.data.id} />
      ) : (
        <>
          <DisplayCompaniesView company={companyQuery.data} onAddClick={() => setIsAddOffice(true)} />
          <AppLink to={`/companies/edit/${id}`}>
            <Button iconName="fa:edit">Edit company</Button>
          </AppLink>
        </>
      )}
    </div>
  )
}
