import { JobAvatar } from '@/modules/jas/job-manager/components/job-ticket-detail/JobAvatar'
import { JobResourceData } from '@/modules/jas/job-manager/types'
import { Tag, Tooltip } from 'antd'
import React from 'react'

type Props = {
  label: 'Employees' | 'Equipment' | string
  data: JobResourceData
}

export const JobRequirement: React.FC<Props> = ({ label, data }) => {
  // empty: default, partial: blue, full: green
  let tagColor = 'default'
  if (!data.required) tagColor = 'green'
  else if (data.assigned >= data.required) tagColor = 'green'
  else if (data.assigned !== 0) tagColor = 'blue'

  return (
    <div>
      <div className="mb-6 cursor-pointer">
        <Tooltip placement="top" title={`Assigned: ${data.assigned}, Required: ${data.required}`}>
          <Tag color={tagColor}>
            {label} ({data.assigned}/{data.required || 0})
          </Tag>
        </Tooltip>
      </div>
      <div className="flex">
        {data.res_items.map((res) => (
          <div key={res.id} className="px-2">
            <JobAvatar resource={res} />
          </div>
        ))}
      </div>
    </div>
  )
}
