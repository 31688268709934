import { useAllProperties } from '@/hooks'
import { useMemo } from 'react'

const locMap = {
  'location.virtual_fields.lease_number': 'apiNum',
  'location.virtual_fields.county': 'county',
  'location.virtual_fields.state': 'state',
  'location.virtual_fields.legal_location': 'name',
  'location.gps_latitude': 'latitude',
  'location.gps_longitude': 'longitude'
}

export const useFillProperties = () => {
  const { allProperties } = useAllProperties()

  const fillProperties = useMemo(() => {
    const props = allProperties.filter(
      (p) => p.alias_field && (p.alias_field.startsWith('wsn.') || p.alias_field.startsWith('location.'))
    )

    return props.map((p) => {
      const wellKey = p.alias_field as string
      if (wellKey.startsWith('location.')) {
        return { ...p, wellKey: locMap[wellKey as keyof typeof locMap] }
      } else {
        return { ...p, wellKey: wellKey.replace('wsn.', '') }
      }
    })
  }, [allProperties])

  return { fillProperties }
}
