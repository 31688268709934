import { z } from 'zod'

// schemas
export const LocationSchema = z.object({
  id: z.number(),
  name: z.string()
})

// types
export type Location = z.infer<typeof LocationSchema>
