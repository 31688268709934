import { customReportApi, programmedReportApi } from '@/services/api-service'
import { Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useEffect, useMemo, useState } from 'react'

export const ReportSelectFields = () => {
  const form = Form.useFormInstance()
  const programmedReport = Form.useWatch('programmed_report')
  const report = Form.useWatch('report')
  const [selectedReportType, setSelectedReportType] = useState<'custom_report' | 'programmed_report'>()

  const programmedReportsQuery = useQuery({
    ...programmedReportApi.list({ order: 'report__name', type_in: 'R,E', fields: 'id,report__name', limit: 'None' }),
    enabled: selectedReportType === 'programmed_report'
  })

  const customReportsQuery = useQuery({
    ...customReportApi.list({ order: 'name', type: 'custom', fields: 'id,name', limit: 'None' }),
    enabled: selectedReportType === 'custom_report'
  })

  const customReports = useMemo(
    () => customReportsQuery.data?.items.map((val) => ({ label: val.name, value: val.id })) || [],
    [customReportsQuery.data?.items]
  )

  const programmedReports = useMemo(
    () => programmedReportsQuery.data?.items.map((val) => ({ label: val.report__name, value: val.id })) || [],
    [programmedReportsQuery.data?.items]
  )

  useEffect(() => {
    if (programmedReport) {
      setSelectedReportType('programmed_report')
    } else {
      setSelectedReportType('custom_report')
    }
  }, [programmedReport])

  return (
    <div className="grid grid-cols-2 gap-x-16">
      <Form.Item name="report" hidden noStyle />
      <Form.Item name="programmed_report" hidden noStyle />
      <Form.Item label="Report Type">
        <Select
          value={selectedReportType}
          onChange={(value) => {
            setSelectedReportType(value)
            form.setFieldsValue({
              programmed_report: null,
              report: null
            })
          }}
          options={[
            {
              label: 'Custom Report',
              value: 'custom_report'
            },
            {
              label: 'Programmed Report',
              value: 'programmed_report'
            }
          ]}
        />
      </Form.Item>
      <Form.Item label={selectedReportType === 'custom_report' ? 'Custom Report' : 'Programmed Report'}>
        {selectedReportType === 'custom_report' && (
          <Select
            options={customReports}
            value={report?.id}
            onChange={(value) => {
              form.setFieldsValue({
                programmed_report: null,
                report: {
                  id: value
                }
              })
            }}
          />
        )}
        {selectedReportType === 'programmed_report' && (
          <Select
            options={programmedReports}
            value={programmedReport?.id}
            onChange={(value) => {
              form.setFieldsValue({
                programmed_report: {
                  id: value
                },
                report: null
              })
            }}
          />
        )}
      </Form.Item>
    </div>
  )
}
