import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

const options = [
  {
    label: 'Make the Customer Office required on these tickets',
    key: 'require_customer_office'
  },
  {
    label: 'Make the Customer Contact required on these tickets',
    key: 'require_customer_contact'
  },
  {
    label: 'Make the Expected Date required on these tickets',
    key: 'require_ticket_date'
  },
  {
    label: 'Make the Location required on these tickets',
    key: 'require_location'
  },
  {
    label: 'Make the AFE# required on these tickets',
    key: 'require_afe'
  },
  {
    label: 'Make the Job Code required on these tickets',
    key: 'require_job_code'
  }
]

export const RequiredFieldsOnMobile = () => {
  const customForm = useAtomValue(useMemo(() => customFormAtom, []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent, key: string) => {
    setCustomForm((prev) => ({
      ...prev,
      [key]: e.target.checked
    }))
  }

  return (
    <div className="border-r border-border">
      <h5 className="font-bold">Required Fields On Mobile</h5>
      {options.map(({ label, key }) => (
        <div key={key} className="mb-12">
          <Checkbox
            data-cy={`custom-form-${key}-checkbox`}
            checked={!!customForm[key as keyof typeof customForm]}
            onChange={(e) => handleChange(e, key)}
          >
            {label}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
