import { z } from 'zod'

export const BpaTriggerScheduleSchema = z.object({
  run_as: z.number().nullish(),
  timezone: z.string().nullish(),
  cron_spec: z.string()
})

export const BpaTriggerEventSchema = z.object({
  event_name: z.string().describe('Event Name: EmailReceived, InvoicePosted, BillPosted, etc.')
})

export const BpaTriggerSchema = z.object({
  uuid: z.string(),
  enabled: z.boolean(),
  type: z.enum(['schedule', 'event']),
  name: z.string(),
  config: BpaTriggerScheduleSchema.or(BpaTriggerEventSchema)
})

export type BpaTrigger = z.infer<typeof BpaTriggerSchema>
export type BpaTriggerScheduleConfig = z.infer<typeof BpaTriggerScheduleSchema>
export type BpaTriggerEventConfig = z.infer<typeof BpaTriggerEventSchema>
