import { JotaiProvider } from '@/contexts'
import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { DisplayField } from '@/modules/table-view/helpers'
import { TicketInputByKey } from '@/modules/ticket/list/constants'
import { Button, LazyDiv } from '@/ui'
import cn from 'classnames'
import { FC, ReactNode, useState } from 'react'
import { InputCell } from './input-cell'
import { RegularCell } from './regular-cell'

type Props = {
  customFormsService: ReturnType<typeof useGlobalCustomForms>
  field: DisplayField
  record: any
}

const NON_EDITABLE_PROPERTY_TYPES = ['Automation', 'ScriptedButton', 'Script', 'Table']

export const DisplayColumn: FC<Props> = ({ customFormsService, field, record }) => {
  // editable if it's a property or has an input component defined
  const isEditable =
    (field.isProperty && !NON_EDITABLE_PROPERTY_TYPES.includes(field.fieldType)) || !!TicketInputByKey[field.key]

  const regularCell = <RegularCell customFormsService={customFormsService} field={field} record={record} />

  return (
    <LazyDiv placeholder={regularCell}>
      {isEditable ? (
        <DisplayColumnEditable customFormsService={customFormsService} field={field} record={record}>
          {regularCell}
        </DisplayColumnEditable>
      ) : (
        regularCell
      )}
    </LazyDiv>
  )
}

const DisplayColumnEditable: FC<Props & { children: ReactNode }> = ({ field, record, children }) => {
  const [isEditing, setIsEditing] = useState(false)

  const closeEditing = () => setIsEditing(false)

  return (
    <div>
      {isEditing ? (
        <JotaiProvider>
          <InputCell field={field} record={record} onSave={closeEditing} onCancel={closeEditing} />
        </JotaiProvider>
      ) : (
        <>
          {children}

          <div className={'absolute top-2 right-2'}>
            <Button
              onClick={() => setIsEditing(true)}
              size={'small'}
              iconName={'fa:edit'}
              shape={'circle'}
              className={cn('opacity-0 group-hover:opacity-100 transition-all scale-[0.85]')}
            />
          </div>
        </>
      )}
    </div>
  )
}
