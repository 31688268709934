import { timezoneApi } from '@/services/api-service'
import { ActionButton, ColorInput } from '@/ui'
import { Icon } from '@/ui/icons'
import { SectionTitle } from '@/ui/section-title'
import { filterSelectOption } from '@/utils/filter-select-option'
import { useQuery } from '@tanstack/react-query'
import { Alert, Checkbox, Form, Input, Select, Upload } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useCompanyQuery } from '../hooks'

export const CompanyInformationSection = () => {
  const timezonesQuery = useQuery({ ...timezoneApi.list() })
  const { companyQuery } = useCompanyQuery()
  const form = Form.useFormInstance()
  const iconPathWatch = Form.useWatch('icon_path', form)
  const customThemeWatch = Form.useWatch('use_custom_theme', form)
  const faviconPathWatch = Form.useWatch('favicon_path', form)
  const logoUrl = iconPathWatch?.file ? URL.createObjectURL(iconPathWatch.file) : iconPathWatch
  const faviconUrl = faviconPathWatch?.file ? URL.createObjectURL(faviconPathWatch.file) : faviconPathWatch
  const [isShowAllTimezones, setIsShowAllTimezones] = useState(false)

  const timezoneOptions = useMemo(() => {
    const items = timezonesQuery.data?.items ?? ({} as Record<string, boolean>)

    const timezones = Object.keys(items).map((timezone) => ({
      label: timezone,
      value: timezone
    }))

    return isShowAllTimezones ? timezones : timezones.filter((timezone) => timezone.label.includes('America'))
  }, [isShowAllTimezones, timezonesQuery.data?.items])

  useEffect(() => {
    if (!companyQuery.data) {
      return
    }

    const { default_timezone } = companyQuery.data

    if (!default_timezone?.includes('America')) {
      setIsShowAllTimezones(true)
    }
  }, [companyQuery.data])

  return (
    <div className="pb-16 mb-16 border-b border-b-border">
      <SectionTitle>Company</SectionTitle>
      <div className="grid grid-cols-2 mt-16">
        <div className="pr-16 border-r border-r-border">
          <Form.Item name="id" hidden />
          <Form.Item name="kind" hidden />
          <div className={'relative'}>
            <Form.Item label="Company Logo" name="icon_path">
              <Upload.Dragger fileList={[]} className="relative" beforeUpload={() => false}>
                <div className="flex justify-center items-center">
                  {logoUrl && logoUrl !== 'remove' ? (
                    <img src={logoUrl} width={200} height={80} className="object-contain" />
                  ) : (
                    <Icon name="fa:inbox" className="text-24" />
                  )}
                </div>
                <p className="mt-12">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </Form.Item>
            <ActionButton
              danger
              iconName={'fa:trash'}
              className="absolute right-0 top-0"
              onClick={() => form.setFieldValue('icon_path', 'remove')}
            />
          </div>
          <Form.Item label="Company Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Short Code (e.g. OC)" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="Website" name="website">
            <Input />
          </Form.Item>
          <Form.Item
            label="Timezone"
            name="default_timezone"
            extra={
              <div className="pt-8">
                <Checkbox checked={isShowAllTimezones} onChange={(e) => setIsShowAllTimezones(e.target.checked)}>
                  Show all timezones
                </Checkbox>
              </div>
            }
          >
            <Select placeholder="Select" options={timezoneOptions} showSearch filterOption={filterSelectOption} />
          </Form.Item>
        </div>
        <div className="pl-16">
          <Form.Item name="use_custom_theme" valuePropName="checked">
            <Checkbox>Use Custom Theme</Checkbox>
          </Form.Item>
          {customThemeWatch && (
            <>
              <div>
                <Alert
                  className={'mb-16'}
                  message="Suggestion"
                  description="Use .svg or .png images with a transparent background as the App Logo to ensure optimal compatibility."
                  type="info"
                  showIcon
                />
              </div>

              <Form.Item label="App Name" name="page_title">
                <Input placeholder={'OilCommand'} />
              </Form.Item>

              <div className={'relative'}>
                <Form.Item label="App Icon" name="favicon_path">
                  <Upload.Dragger fileList={[]} className="relative" beforeUpload={() => false}>
                    <div className="flex justify-center items-center">
                      {faviconUrl && faviconUrl !== 'remove' ? (
                        <img src={faviconUrl} width={200} height={80} className="object-contain" alt={'favicon'} />
                      ) : (
                        <Icon name="fa:inbox" className="text-24" />
                      )}
                    </div>
                    <p className="mt-12">Click or drag file to this area to upload</p>
                  </Upload.Dragger>
                </Form.Item>

                <ActionButton
                  danger
                  iconName={'fa:trash'}
                  className="absolute right-0 top-0"
                  onClick={() => form.setFieldValue('favicon_path', 'remove')}
                />
              </div>

              <div className="grid grid-cols-3 gap-x-12">
                <Form.Item label="App Color" name="color_primary" getValueFromEvent={(_, hex) => hex}>
                  <ColorInput showText />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
