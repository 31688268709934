import { Icon } from '@/ui/icons'
import { Form, Upload } from 'antd'

export const IconField = () => {
  return (
    <Form.Item
      label="Company Logo"
      name="icon_path"
      valuePropName="fileList"
      getValueFromEvent={(e) => {
        if (Array.isArray(e)) {
          return e
        }
        return e?.fileList
      }}
    >
      <Upload.Dragger
        maxCount={1}
        name="file"
        listType="picture"
        beforeUpload={() => false}
        data-cy="company-logo-upload"
        itemRender={(_, __, fileList) => (
          <div className="w-[200px] h-[100px] mt-10">
            {fileList.map((file) => (
              <img key={file.uid} src={file.thumbUrl} className="object-contain w-full h-full" />
            ))}
          </div>
        )}
      >
        <p className="ant-upload-drag-icon">
          <Icon name="mi:inbox" className="text-24" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
    </Form.Item>
  )
}
