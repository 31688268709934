const TYPE_REMAP = {
  A: 'T',
  M: 'T',
  S: 'T'
}

export const getTableViewType = (module: { view_type: string; data: { source_type: string } }) => {
  const moduleType = (module.view_type || 'T').toUpperCase()

  // use tickets table view for summary tickets module
  let retype
  if ((retype = TYPE_REMAP[moduleType as keyof typeof TYPE_REMAP])) {
    return retype
  } else if (['K', 'TC'].includes(moduleType)) {
    return module?.data?.source_type || 'T'
  }

  return moduleType
}
