import { useApp, useFormWatch } from '@/hooks'
import { refreshQueries } from '@/query'
import { v2JasResourceApi } from '@/services/api-service'
import { objId } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Form } from 'antd'
import { omit } from 'lodash'
import { useRevalidator } from 'react-router-dom'
import { Resource, ResourceForm } from './schemas'

export function useSaveResource() {
  const { notification } = useApp()
  const { revalidate } = useRevalidator()

  const form = Form.useFormInstance<ResourceForm>()
  const resourceId = useFormWatch(['resource', 'id'])

  const saveResourceMutation = useMutation(resourceId ? v2JasResourceApi.patch() : v2JasResourceApi.create())

  const saveResource = async () => {
    try {
      await form.validateFields({ recursive: true })
      const resource = form.getFieldValue('resource') as Resource

      const data: Record<string, any> = {
        ...omit(resource, ['_jobRole', '_skills', 'contact__first_name', 'contact__last_name']),
        attributes: resource._skills?.reduce((acc: Record<string, any>, skill: string) => {
          acc[skill] = skill
          return acc
        }, {}),
        job_role_id: objId(resource._jobRole),
        equipments: resource._equipmentIds || []
      }

      try {
        const result = await saveResourceMutation.mutateAsync(data)
        notification.success({ message: 'Success', description: 'Resource successfully saved' })
        revalidate()
        refreshQueries(['equipment'])
        return data.id ? undefined : `../${result.id}/edit`
      } catch (error) {
        notification.error({ message: 'Failed', description: 'Failed to save resource' })
      }
    } catch (error) {
      notification.warning({ message: 'Warning', description: 'Please check input with validation error' })
    }
  }

  return { saveResourceMutation, saveResource }
}
