import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { customReportApi } from '@/services/api-service'
import { QueryTable, SearchInput } from '@/ui'
import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import { useState } from 'react'
import { AddEditReportCriteriaModal, CriteriaColumnCustomReport, EncodingSelect, FormatSelect } from '../../components'
import { useUpdateFilterfieldMutation } from '../../hooks'
import { CustomReport } from '../../schema'
import { ActionsColumn } from './actions-column'
import { FilterField } from './hooks'
import { NewCustomReportModal } from './new-custom-report-modal'

export const CustomReportsView = view(Component, {
  title: () => 'CustomReports'
})

function Component() {
  const queryClient = useQueryClient()
  const [editingReport, setEditingReport] = useState<CustomReport | null>(null)
  const [editingCriteria, setEditingCriteria] = useState<FilterField | null>(null)
  const [selectedFormat, setSelectedFormat] = useState('xlsx')
  const [selectedEncoding, setSelectedEncoding] = useState('utf-16')
  const { notification, l } = useApp()
  const [search, setSearch] = useState('')

  const { handleSave } = useUpdateFilterfieldMutation({
    onSuccess: () => {
      notification.success({ message: 'Criteria successfully updated' })
      setEditingCriteria(null)
      setEditingReport(null)
      queryClient.refetchQueries(...(customReportApi.list().queryKey as any))
    }
  })

  return (
    <PageView
      header={{
        actions: [<NewCustomReportModal key={'new-report'} />],
        title: l('CustomReports'),
        accent: false,
        border: false
      }}
    >
      <Form layout="vertical">
        <div className="grid grid-cols-2 gap-x-16">
          <FormatSelect selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} />
          <EncodingSelect
            selectedEncoding={selectedEncoding}
            selectedFormat={selectedFormat}
            setSelectedEncoding={setSelectedEncoding}
          />
        </div>
      </Form>
      <div className="flex items-center justify-between mb-16">
        <SearchInput placeholder="Search" onSearch={setSearch} />
      </div>
      <div>
        <QueryTable
          queryApi={customReportApi.list}
          queryParams={{
            type: 'custom',
            ...(search && { description: search.trim(), name: search.trim() })
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name'
            },
            {
              title: 'Description',
              dataIndex: 'description'
            },
            {
              title: 'Criteria',
              sorter: false,
              render: (_, report: CustomReport) => (
                <CriteriaColumnCustomReport
                  customReport={report}
                  setEditingCriteria={setEditingCriteria}
                  setEditingReport={setEditingReport}
                />
              )
            },
            {
              sorter: false,
              align: 'right',
              render: (_, report: CustomReport) => (
                <ActionsColumn report={report} format={selectedFormat} encoding={selectedEncoding} />
              )
            }
          ]}
        />
      </div>
      {editingReport && editingCriteria && (
        <AddEditReportCriteriaModal
          filterfield={editingCriteria}
          readonlyFilterField
          rootModelName={editingReport?.root_model_name || 'ticket'}
          onSave={(values) => handleSave(values)}
          onClose={() => {
            setEditingCriteria(null)
            setEditingReport(null)
          }}
        />
      )}
    </PageView>
  )
}
