import { RouteObject } from 'react-router-dom'

type RouteModule = {
  routes: RouteObject[]
}

const modules = import.meta.glob('@/modules/**/routes.{ts,tsx}', { eager: true })

export const loadRoutes = (): RouteObject[] => {
  const routes: RouteObject[] = []

  for (const path in modules) {
    const module = modules[path] as RouteModule
    routes.push(...(module.routes || []))
  }

  return routes
}
