import { isEmpty } from 'lodash'

export const LOOKUP_OPERATORS = [
  'eq',
  'exact',
  'iexact',
  'contains',
  'icontains',
  'in',
  'gte',
  'lt',
  'lte',
  'startswith',
  'istartswith',
  'endswith',
  'iendswith',
  'range',
  'year',
  'month',
  'day',
  'week_day',
  'hour',
  'minute',
  'seconds',
  'isnull',
  'regex',
  'iregex'
] as const

export type LookupOperator = (typeof LOOKUP_OPERATORS)[number]

export const CONTACT_SEARCH_BY = (text?: string) => ({
  'Q[]': isEmpty(text)
    ? {}
    : [
        `first_name__icontains|${text}`,
        `last_name__icontains|${text}`,
        'or',
        `profile__user__username__icontains|${text}`,
        'or'
      ]
})
