import { useApp } from '@/hooks'
import { propertyApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form } from 'antd'
import { usePropertiesQuery } from '../../hooks'

export const SavePropertyLinkButton = () => {
  const form = Form.useFormInstance()
  const { properties } = usePropertiesQuery()
  const selectedPropertyId = Form.useWatch('id')
  const selectedLinkPropertyId = Form.useWatch('link')
  const showLinkProperty = Form.useWatch('showLinkProperty')
  const selectedProperty = properties.find((property) => property.id === selectedPropertyId)
  const updatePropertyMutation = useMutation(propertyApi.patch())
  const { notification } = useApp()

  const handleSave = async () => {
    const { id, link } = await form.validateFields()

    updatePropertyMutation.mutateAsync({
      id,
      link,
      update_choices: true
    })

    notification.success({
      message: 'Property saved'
    })
  }

  if (selectedProperty?.link === selectedLinkPropertyId || !showLinkProperty) {
    return null
  }

  return <Button type="primary" iconName="fa:check" loading={updatePropertyMutation.isLoading} onClick={handleSave} />
}
