import { useApp } from '@/hooks'
import { useAccounting } from '@/modules/ticket/form/hooks'
import { Button } from '@/ui'
import { isBlank } from '@/utils'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useButtonContext } from '../../hooks'
import { VarsType } from './types'

export const AccountingSubmitButton = () => {
  const { notification } = useApp()
  const { vars, ticket, setValue, property, buttonProps, saveTicket, getValueById } = useButtonContext<VarsType>()
  const { service, submittedDatePid, emptyPid } = vars
  const { submit: submitToQb } = useAccounting()
  const [submittedIds, setSubmittedIds] = useState<number[]>([])
  const [loading, setLoading] = useState(false)

  const disabled = useMemo(() => {
    if (!emptyPid) return buttonProps.disabled
    return buttonProps.disabled || !isBlank(getValueById(Number(emptyPid)))
  }, [emptyPid, buttonProps.disabled, getValueById])

  const handleClick = async () => {
    try {
      if (submittedIds.includes(ticket.id)) {
        notification.warning({
          message: 'Duplicate Submission',
          description: 'Ticket has already been posted to QuickBooks'
        })
        return
      }

      if (ticket.parent__id) {
        notification.warning({
          message: 'Invalid Submission',
          description: 'You cannot submit summarized child ticket to QuickBooks.'
        })
        return
      }

      setLoading(true)
      if (await submitToQb(service, { triggerSource: 'scripted-button' })) {
        setSubmittedIds((prev) => [...prev, ticket.id])
        if (submittedDatePid) {
          setValue(submittedDatePid, dayjs().toISOString())
          await saveTicket()
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button {...buttonProps} disabled={disabled} loading={loading} onClick={handleClick}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
