import { filterSelectOption } from '@/utils/filter-select-option'
import { Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { allRolesAtom, selectedRoleAtom } from '../../atoms'
import { useRolesFetchQuery } from '../hook'

export const RoleSelect = () => {
  const { data: roleData, isLoading: roleLoading } = useRolesFetchQuery()
  const setSelectedRole = useSetAtom(selectedRoleAtom)
  const selectedRole = useAtomValue(selectedRoleAtom)
  const setAllRole = useSetAtom(allRolesAtom)
  const [roleOptions, setRoleOptions] = useState<{ value: number | null; label: string | null }[]>([
    { label: 'All', value: -1 }
  ])

  const onSelectChange = (value: number) => {
    setSelectedRole(value)
  }

  useEffect(() => {
    if (roleData && roleData?.items?.length > 0) {
      const formattedRoleOptions = roleData.items.map((item) => ({
        value: item.id,
        label: item.name
      }))
      const allOptions = [...roleOptions, ...formattedRoleOptions]
      setRoleOptions(uniqBy(allOptions, 'value'))
      setAllRole(uniqBy(allOptions, 'value'))
    }

    return () => {
      setRoleOptions([])
      setAllRole([])
    }
  }, [roleData])

  return (
    <div>
      <label className="block font-medium text-sm mb-2 text-gray-500">Role:</label>
      <Select
        className="min-w-[200px]"
        placeholder="Select..."
        options={roleOptions}
        loading={roleLoading}
        value={selectedRole}
        showSearch
        filterOption={filterSelectOption}
        onChange={(val) => onSelectChange(val)}
      />
    </div>
  )
}
