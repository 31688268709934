import { APP_MODE } from '@/constants/app'
import { dig } from '@/utils'
import { useRedirect } from './use-redirect'
import { useSession } from './use-session'

export type CompanyFeatures = {
  wiki: {
    enabled: boolean
    wiki_company_id: number
  }
  microsoftOpenIdAuth: {
    enabled: boolean
  }
  forceV2Tickets: {
    enabled: boolean
  }
  forcePrivateCompanies: {
    enabled: boolean
  }
}

export const useCompanyFeatures = (): CompanyFeatures => {
  const { company } = useSession()
  const { isAppOff } = useRedirect()
  const companyFeatures = company.data?.features || {}

  return {
    wiki: {
      enabled: dig(companyFeatures, 'wiki.enabled') || false,
      wiki_company_id: dig(companyFeatures, 'wiki.wiki_company_id')
    },
    microsoftOpenIdAuth: {
      enabled: dig(companyFeatures, 'microsoft_openid_auth.enabled')
    },
    forceV2Tickets: {
      enabled: !isAppOff && (APP_MODE !== 'production' || companyFeatures?.force_v2_tickets?.enabled)
    },
    forcePrivateCompanies: {
      enabled: dig(companyFeatures, 'microsoft_openid_auth.enabled')
    }
  }
}
