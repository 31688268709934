import { useApp } from '@/hooks'
import { TabsView } from '@/layouts/views'
import { view } from '@/routing'

export const RecordsLayout = view(Component, {
  title: () => 'Records'
})

function Component() {
  const { l } = useApp()

  return (
    <TabsView
      asLayout
      header={{ breadcrumbs: true }}
      items={[
        { to: 'locations', label: l('Locations') },
        { to: 'skus', label: l('SKUs') },
        { to: 'categories', label: l('Categories') },
        { to: 'category_kinds', label: l('Configurations') },
        { to: 'afes', label: l('AFEs') },
        { to: 'job_codes', label: l('JobCodes') },
        { to: 'job_types', label: l('JobTypes') },
        { to: 'custom_records', label: l('CustomRecords') },
        { to: 'pricing', label: l('Pricing') }
      ]}
    />
  )
}
