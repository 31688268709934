import { useIsCompanyAdmin } from '@/modules/auth/hooks'
import { FC, ReactNode } from 'react'
import { useCustomForm, useTicket, useTicketCustomerOffice, useTicketFlags, useTicketSave } from '../../../../hooks'
import { PropertyGroup } from '../../../../schemas'
import { AddEditButton } from '../add-edit-button'

type Props = {
  propertyGroup?: PropertyGroup
  children?: ReactNode
}

export const ItemsSectionTitle: FC<Props> = ({ propertyGroup, children }) => {
  const { ticket } = useTicket()
  const { features } = useCustomForm()
  const { saveTicket } = useTicketSave()
  const { viewOnly } = useTicketFlags()
  const isCompanyAdmin = useIsCompanyAdmin()
  const customerOffice = useTicketCustomerOffice()

  return (
    <>
      <div className={'flex flex-row items-center justify-between'}>
        <div>{children}</div>
        <div className={'mr-10'}>
          <AddEditButton
            buttonProps={{
              primary: true,
              iconName: 'fa:plus',
              shape: 'round',
              children: 'Add Item',
              disabled: viewOnly
            }}
            modalProps={{ iconName: 'fa:plus', title: 'Add Item' }}
            onSaved={() => saveTicket({ only: ['id'], forceSave: true })}
            options={{
              type: 'new',
              ticketId: ticket.id,
              customerOfficeId: customerOffice?.id,
              disabledFields: ['sku', 'description'],
              excludedFields: features.hideItemFields?.enabled ? features.hideItemFields?.fields || [] : undefined,
              limitBackDate: !isCompanyAdmin && features.restrictLineItemEdits,
              preferEmpty: true
            }}
          />
        </div>
      </div>
    </>
  )
}
