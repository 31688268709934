import { useApp, useSession } from '@/hooks'
import { useCheckAccess } from '@/modules/auth/hooks'
import { ticketApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { ACCOUNTING_SERVICES } from '../constants'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'

export const useAccounting = () => {
  const { notification, modal } = useApp()

  const allowed = useCheckAccess(['qb_input'])
  const { company } = useSession()
  const { customForm } = useCustomForm()
  const { ticket } = useTicket()
  const config = company.data?.accounting || null

  const accountingMutation = useMutation(ticketApi.accounting(ticket.id))

  const submit = useCallback(
    async (serviceKey: string, options?: { triggerSource?: string; force?: boolean }) => {
      try {
        await accountingMutation.mutateAsync({
          id: ticket.id,
          service: serviceKey,
          override: options?.force || '',
          trigger_source: options?.triggerSource
        })
        notification.success({ message: 'Accounting Submission', description: 'Ticket submitted to accounting' })
        return true
      } catch (error: any) {
        const data = error?.response?.data || {}

        if (!options?.force && data?.id) {
          modal.confirm({
            maskClosable: true,
            centered: true,
            title: 'Duplicate Invoice. Submit Anyway?',
            content:
              'According to our records, a QuickBooks invoice may already exist for this ticket. Are you sure you want to submit it anyway?',
            onOk: async () => await submit(serviceKey, { force: true }),
            okText: 'Yes, Submit Anyway'
          })
        } else {
          const message =
            (error?.response?.status == 400 &&
              typeof data == typeof {} &&
              Object.entries(data)
                .map(([key, value]) => value)
                .join('\n')) ||
            'Failed to submit ticket to accounting'
          notification.error({ message: 'Accounting Submission', description: message })
        }

        return false
      }
    },
    [accountingMutation, modal, notification, ticket.id]
  )

  const services = useMemo(() => {
    if (!config) return []

    return ACCOUNTING_SERVICES.filter((service) => config[service.option_key])
  }, [config])

  const enabled = customForm.enable_accounting && allowed && services.length > 0

  return { enabled, submit, services }
}
