import { TLabels } from '@/hooks/use-app'
import { LINE_ITEM_COLUMNS_MAP } from '@/modules/line-item/columns'
import { TICKET_COLUMNS_MAP, TICKET_COLUMNS_WITH_PREFIX_MAP } from '@/modules/ticket/columns'
import { ReportBuilderReport } from '@/types/report-builder-report'
import { DraggableItem } from '../types'
import { processColumn } from './process-column'

export const processSelectedColumns = (
  report: ReportBuilderReport,
  propertiesColumns: DraggableItem[],
  labels: TLabels
) => {
  const displayfieldSet = report.displayfield_set || []
  const isLineItemReport = report.root_model_name === 'line item'

  return displayfieldSet
    .map((displayfield) => {
      const { field, field_verbose, path } = displayfield

      if (field === '_rpt_property') {
        const property = propertiesColumns.find(
          (property) =>
            (property.field_verbose === field_verbose || property._field_verbose === field_verbose) &&
            property.name === displayfield.name
        )

        if (!property) {
          return displayfield
        }

        return { ...property, sort: displayfield?.sort }
      }

      const id = `${path || ''}${field || ''}`
      const staticColumn =
        (isLineItemReport ? TICKET_COLUMNS_WITH_PREFIX_MAP[id] : TICKET_COLUMNS_MAP[id]) || LINE_ITEM_COLUMNS_MAP[id]

      if (!staticColumn) {
        return displayfield
      }

      return processColumn(staticColumn, labels, displayfield)
    })
    .filter(Boolean) as DraggableItem[]
}
