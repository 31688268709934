import { Button } from '@/ui'
import { Form, Input, Space } from 'antd'

export const InvoiceLineSection = () => {
  const isQbwcEnabled = Form.useWatch(['doc', 'accounting', 'qbwc_enabled'])

  return (
    <Form.Item hidden={!isQbwcEnabled}>
      <h4 className="font-bold">Invoice Line</h4>
      <div className="grid grid-cols-3 gap-x-24">
        <Form.Item label="Service Date" name={['doc', 'invoice_li__ServiceDate']}>
          <Input />
        </Form.Item>
        <Form.Item label="Class Name" name={['doc', 'invoice_li__ClassName']}>
          <Input />
        </Form.Item>
        <Form.Item label="Other 1" name={['doc', 'invoice_li__Other1']}>
          <Input />
        </Form.Item>
        <Form.Item label="Other 2" name={['doc', 'invoice_li__Other2']}>
          <Input />
        </Form.Item>
      </div>
      <Form.List name={['doc', 'line_custom_fields']}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Form.Item label="Custom Field" name={[field.name, 'name']}>
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item label="Value" name={[field.name, 'value']}>
                  <Input placeholder="Value" />
                </Form.Item>
                <Form.Item label=" ">
                  <Button iconName="fa:trash" onClick={() => remove(field.name)}></Button>
                </Form.Item>
              </Space>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()}>
                Add Custom Field
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </Form.Item>
  )
}
