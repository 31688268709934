import { jasDistrictApi } from '@/services/api-service'
import { ListResponse } from '@/types/api/core'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Select } from 'antd'
import { z } from 'zod'

const districtQueryResponseSchema = z.object({
  id: z.number(),
  name: z.string()
})

const districtQueryResponseListSchema = z.array(districtQueryResponseSchema)
type District = z.infer<typeof districtQueryResponseSchema>

type Props = {
  value?: string[]
  onChange?: (value: string[]) => void
}

export const DistrictSelect = ({ onChange, value }: Props) => {
  const districtsQuery: UseQueryResult<ListResponse<District>> = useQuery(jasDistrictApi.list())

  const districts = districtQueryResponseListSchema.parse(districtsQuery.data?.items || [])

  const options = districts.map((district) => ({
    label: district.name,
    value: district.name
  }))

  const handleChange = (value: string[]) => {
    onChange?.(value)
  }

  return (
    <Select
      placeholder="District"
      loading={districtsQuery.isLoading}
      className="min-w-[100px]"
      mode="multiple"
      allowClear
      options={options}
      onChange={handleChange}
      value={value}
      popupMatchSelectWidth={false}
    />
  )
}
