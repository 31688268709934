import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { GraphCard, GraphCardProps } from '../graph-card'

export const SortableItem = (props: GraphCardProps) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition, index } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }

  return (
    <GraphCard
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      attributes={attributes}
      listeners={listeners}
      sequence={index}
      {...props}
    />
  )
}
