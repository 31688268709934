import { z } from 'zod'

export const eventSchema = z
  .object({
    id: z.number(),
    action_text: z.string(),
    target_text: z.string().nullish(),
    contact_name: z.string(),
    contact_title: z.string(),
    contact_company_name: z.string(),
    date: z.string(),
    time: z.string(),
    version_stats_app: z.string(),
    version_stats_version: z.string(),
    version_stats_platform: z.string()
  })
  .passthrough()

export type Event = z.infer<typeof eventSchema>
