import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES, TABLE_VIEW_TYPES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { TABLE_VIEW_PATHS } from '@/modules/table-view/constants'
import { tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { AppLink, Icon, Tooltip } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Popconfirm, Space, Table } from 'antd'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getTableViewType } from '../../../utils/get-table-view-type'
import { AddViewModal } from './add-view-modal'

export const ViewsTableSection = () => {
  const { user, permissions } = useSession()
  const { notification } = useApp()
  const location = useLocation()
  const form = Form.useFormInstance()
  const viewType = Form.useWatch('view_type', form) ?? ''
  const cardType = Form.useWatch(['data', 'cardType'], form) ?? ''
  const sourceType = Form.useWatch(['data', 'source_type'], form) ?? ''
  const internalViews = Form.useWatch('internal_views', form) ?? []
  const hiddenViews = Form.useWatch('hidden_views', form) ?? []
  const selectedView = Form.useWatch('selected_view', form) ?? ''
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const tableViewType = getTableViewType({ view_type: viewType, data: { source_type: sourceType } })

  const tableViewsQuery = useQuery({
    ...tableViewApi.list({ type__eq: tableViewType, limit, list_all: 1, page, deleted_at__isnull: true })
  })

  const createTableViewMutation = useMutation({ ...tableViewApi.create<TableView>() })
  const deleteTableViewMutation = useMutation(tableViewApi.delete())
  const viewTypeLabel = TABLE_VIEW_TYPES[tableViewType as keyof typeof TABLE_VIEW_TYPES]

  const isHidden = (view: { id: number; internal: boolean }) => {
    if (view.internal) return !internalViews.includes(view.id)
    return hiddenViews.includes(view.id)
  }

  const toggleVisibility = async (view: { id: number; internal: boolean }) => {
    if (view.internal) {
      form.setFieldValue(
        'internal_views',
        isHidden(view) ? [...internalViews, view.id] : internalViews.filter((v: any) => v !== view.id)
      )
      return
    }

    form.setFieldsValue({
      hidden_views: isHidden(view) ? hiddenViews.filter((v: any) => v !== view.id) : [...hiddenViews, view.id]
    })
  }

  const handleCopyTableView = async (tableView: TableView) => {
    const {
      id,
      created_by,
      uuid,
      name,
      modified_at,
      modified_by,
      created_at,
      deleted_at,
      deleted_by,
      options,
      ...restTableView
    } = tableView
    try {
      await createTableViewMutation.mutateAsync({
        ...restTableView,
        options: { custom_forms: options?.custom_forms || '[]' },
        name: `${name} - Copy`
      })

      notification.success({
        message: 'Table view copied'
      })
    } catch {
      notification.error({
        message: 'Failed to copy table view'
      })
    }
  }

  if (!viewTypeLabel || cardType === 'D') {
    return null
  }

  return (
    <div className="mb-24">
      <div className="flex justify-between items-center mb-16">
        <div className="flex items-center gap-x-16">
          <h5 className="font-bold m-0">{viewTypeLabel} Views</h5>
          {viewType === 'M' && (
            <Form.Item name="use_selected_view" noStyle valuePropName="checked">
              <Checkbox>Use selected view as default layer</Checkbox>
            </Form.Item>
          )}
        </div>
        <AddViewModal tableViewType={viewTypeLabel} />
      </div>
      <Form.Item name="hidden_views" hidden />
      <Form.Item name="internal_views" hidden />
      <Table
        pagination={{
          total: tableViewsQuery.data?.total ?? 0,
          pageSize: limit,
          current: page,
          onChange: (page) => setPage(page),
          onShowSizeChange: (_, pageSize) => setLimit(pageSize),
          pageSizeOptions: TABLE_PAGE_SIZES
        }}
        loading={tableViewsQuery.isLoading}
        dataSource={
          tableViewsQuery.data?.items.map((item) => ({
            ...item,
            key: item.id
          })) ?? []
        }
      >
        <Table.Column
          title="Default"
          render={(_, view: any) => (
            <Checkbox
              onChange={() => form.setFieldsValue({ selected_view: view.id })}
              checked={view.id === selectedView}
            />
          )}
        />
        <Table.Column
          title="Name"
          render={(_, view: any) => (
            <div className="flex flex-row gap-6 items-center">
              <span>
                {view.name} {view.hidden ? '(Hidden)' : ''}
              </span>{' '}
              {view.internal && (
                <Tooltip title="Internal View">
                  <Icon name="fa:circle-question" />
                </Tooltip>
              )}
            </div>
          )}
        />
        <Table.Column title="Description" dataIndex="description" />
        <Table.Column title="Owner" render={(_, view: any) => (view.company ? 'Company' : 'User')} />
        <Table.Column
          title="Type"
          render={(_, view: any) => TABLE_VIEW_TYPES[view.type as keyof typeof TABLE_VIEW_TYPES]}
        />
        <Table.Column
          align="right"
          render={(_, view: TableView) =>
            (view.user === user!.id || permissions.includes('company_admin')) && (
              <Space>
                <Button iconName={'mi:file_copy'} onClick={() => handleCopyTableView(view)} type="text" />
                <Button
                  iconName={isHidden(view) ? 'mi:visibility_off' : 'mi:visibility'}
                  onClick={() => toggleVisibility(view)}
                  type="text"
                />
                <Popconfirm
                  title="Delete TableView"
                  placement="topLeft"
                  description={`Are you sure you want to delete TableView #${view.id}?`}
                  onConfirm={async () => {
                    await deleteTableViewMutation.mutateAsync({ id: view.id })
                    await tableViewsQuery.refetch()
                  }}
                  okButtonProps={{ loading: deleteTableViewMutation.isLoading }}
                >
                  <Button iconName="svg:trash" type="text" />
                </Popconfirm>
                <AppLink
                  to={TABLE_VIEW_PATHS.editTableView(view.id)}
                  state={{
                    from: location.pathname
                  }}
                >
                  <Button iconName="svg:edit" type="text" />
                </AppLink>
              </Space>
            )
          }
        />
      </Table>
    </div>
  )
}
