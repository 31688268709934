import { useApp } from '@/hooks'
import { pricingCustomerApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Upload } from 'antd'

export const UploadButton = () => {
  const { notification } = useApp()

  const uploadMutation = useMutation(
    pricingCustomerApi.create({ headers: { 'Content-Type': 'multipart/form-data' } }, 'upload')
  )

  const handleUpload = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    await uploadMutation
      .mutateAsync(formData)
      .then(() => {
        notification.success({
          message: 'Upload Success',
          description: 'Customer pricing uploaded successfully'
        })
      })
      .catch(() => {
        notification.error({
          message: 'Upload Failed',
          description: 'Failed to upload customer pricing'
        })
      })
  }

  return (
    <Upload
      showUploadList={false}
      beforeUpload={handleUpload}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    >
      <Button iconName="fa:upload" loading={uploadMutation.isLoading}>
        Upload Pricing
      </Button>
    </Upload>
  )
}
