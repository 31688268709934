import { useApp, useFormWatch, useModalState, useSession } from '@/hooks'
import { ModuleLinkButton } from '@/modules/module'
import { jasScheduleRuleApi, jasScheduleRuleTemplateApi } from '@/services/api-service'
import { Button, ButtonProps, Modal, QueryDeleteButton, QuerySelect } from '@/ui'
import { LocalDateTimePicker } from '@/ui/date'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Card, Form, Input, List } from 'antd'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { Resource, ResourceScheduleRule, ResourceScheduleRuleSchema } from '../schemas'

export function ScheduleRulesCard() {
  const { resourceType: typePath } = useParams()

  const resourceId = useFormWatch<Resource['id']>(['resource', 'id'])
  const resourceName = useFormWatch<Resource['name']>(['resource', 'name'])
  const resourceStatus = useFormWatch<Resource['status']>(['resource', 'status'])
  const resourceType = useFormWatch<Resource['type']>(['resource', 'type'])

  const scheduleRulesQuery = useQuery({
    ...jasScheduleRuleApi.list({
      resource_id__eq: resourceId,
      order: 'start_date',
      fields: ResourceScheduleRuleSchema,
      no_count: true,
      limit: 100
    }),
    enabled: !!resourceId
  })
  const scheduleRules = scheduleRulesQuery.data?.items || []

  return (
    <Card
      title={'Schedule Rules'}
      extra={
        <div className={'flex flex-row gap-6'}>
          <ModuleLinkButton
            disabled={!resourceId || resourceStatus !== 'A'}
            by={'type'}
            value={'SD'}
            size={'small'}
            shape={'round'}
            iconName={'fa:calendar'}
            urlSuffix={`/schedule/${typePath}?filter.q.name=${resourceName}`}
          >
            View Schedule
          </ModuleLinkButton>
          <RuleFormModal size={'small'} iconName={'fa:plus'} shape={'round'} disabled={!resourceId}>
            Assign Rule
          </RuleFormModal>
        </div>
      }
    >
      <div className={'-mt-20 flex flex-col'}>
        {!resourceId ? (
          <Alert
            showIcon
            className={'mt-20'}
            message={`Save the ${resourceType} to enable schedule rule assignment.`}
            type="info"
          />
        ) : (
          <List<ResourceScheduleRule>
            loading={scheduleRulesQuery.isLoading}
            itemLayout="horizontal"
            dataSource={scheduleRules || []}
            renderItem={(r) => (
              <List.Item
                actions={[
                  <RuleFormModal key={'edit'} rule={r} iconName={'fa:edit'} type={'text'} shape={'circle'} />,
                  <QueryDeleteButton key={'delete'} name={'Schedule Rule'} api={jasScheduleRuleApi.delete} id={r.id} />
                ]}
              >
                <List.Item.Meta
                  title={
                    <span>
                      {r.template__name} {r.template__comment && <span>- {r.template__comment}</span>}
                    </span>
                  }
                  description={`From ${dayjs.formatLocalDate(r.start_date)} to ${dayjs.formatLocalDate(r.end_date)}`}
                />
              </List.Item>
            )}
          />
        )}
      </div>
    </Card>
  )
}

export type RuleFormModalProps = ButtonProps & {
  rule?: Pick<ResourceScheduleRule, 'id' | 'name' | 'template' | 'start_date' | 'end_date'>
}

export function RuleFormModal({ rule, ...buttonProps }: RuleFormModalProps) {
  const resourceId = useFormWatch<Resource['id']>(['resource', 'id'])

  const [form] = Form.useForm()
  const { company } = useSession()
  const { notification } = useApp()

  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const saveMutation = useMutation(rule?.id ? jasScheduleRuleApi.patch() : jasScheduleRuleApi.create())

  const handleSave = async () => {
    try {
      await form.validateFields()

      const formData = form.getFieldsValue()
      formData.start_date = dayjs.parse(formData.start_date, { tz: 'replace' })?.formatISODate(false)
      formData.end_date = dayjs.parse(formData.end_date, { tz: 'replace' })?.formatISODate(false)

      if (!rule?.id) {
        formData.company = company.id
        formData.resource = resourceId
      }

      await saveMutation
        .mutateAsync(formData)
        .then(() => {
          closeModal()
          notification.success({ message: 'Rule Saved', description: 'Rule has been saved successfully' })
        })
        .catch(() => {
          notification.error({
            message: 'Save Error',
            description: 'Failed to save rule'
          })
        })
    } catch (error) {
      notification.warning({ message: 'Validation Error', description: 'Please check the form for errors' })
    }
  }

  return (
    <>
      <Button onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          width={550}
          iconName={buttonProps.iconName}
          title={rule?.id ? 'Edit Rule' : 'Assign Rule'}
          onCancel={closeModal}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-button' }}
          okText="Save"
          cancelText="Cancel"
          destroyOnClose
          afterOpenChange={() => form.resetFields()}
        >
          <Form
            className={'rounded pt-10'}
            form={form}
            initialValues={rule}
            onFinish={handleSave}
            layout="horizontal"
            labelCol={{ span: 6 }}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>

            <Form.Item name="template" label={'Schedule Rule'} rules={[{ required: true }]}>
              <QuerySelect
                apiEndpoint={jasScheduleRuleTemplateApi.list}
                apiQueryParams={{ fields: 'id,name' }}
                apiValueBy={'id'}
                apiSearchBy={'name'}
                renderOption={(item) => ({ value: item.id, label: item.name })}
              />
            </Form.Item>
            <Form.Item name="start_date" label={'Start Date'} rules={[{ required: true }]}>
              <LocalDateTimePicker className={'w-full'} showTime={false} />
            </Form.Item>
            <Form.Item
              name="end_date"
              label={'End Date'}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    const start = dayjs.parse(form.getFieldValue('start_date'))
                    const end = dayjs.parse(value)
                    const check = start && end && start.isAfter(end)
                    return check ? Promise.reject() : Promise.resolve()
                  },
                  message: 'End date must be after start date'
                }
              ]}
            >
              <LocalDateTimePicker tz={'replace'} className={'w-full'} showTime={false} />
            </Form.Item>

            <Button type={'primary'} htmlType={'submit'} hidden />
          </Form>
        </Modal>
      )}
    </>
  )
}
