import { RecentActivities } from '@/components'
import { moduleApi } from '@/services/api-service'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Alert } from 'antd'
import { useParams } from 'react-router-dom'
import { ModuleBuilderForm } from './module-builder-form'
import { UserAccessSection } from './user-access-section'

export const ModuleTabsContent = () => {
  const { id } = useParams()

  const moduleQuery = useQuery({
    ...moduleApi.get<{ id: number; contacts: number[]; deleted_at?: string }>(Number(id)),
    enabled: !!id
  })

  if (moduleQuery.isInitialLoading) {
    return <Spin isCentered spinning />
  }

  if (!moduleQuery.data) {
    return <Alert message="Module not found" type="warning" />
  }

  return (
    <div>
      <ModuleBuilderForm module={moduleQuery.data} />
      <UserAccessSection
        contacts={moduleQuery.data.contacts || []}
        key={moduleQuery.data.contacts?.join('')} // force re-render when contacts change
      />
      <RecentActivities isLoading={false} />
    </div>
  )
}
