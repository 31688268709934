import { SUPPORTED_IMPORTS } from '@/constants/general'
import { Select } from '@/ui'
import { Form } from 'antd'

type Props = {
  type: 'E' | 'I'
}

export const TableSelectField = ({ type }: Props) => {
  return (
    <Form.Item
      className="grow mb-0"
      name="table"
      label={type === 'E' ? 'Record Type' : 'Upload data to'}
      rules={[{ required: true }]}
    >
      <Select
        placeholder="Select Model"
        options={Object.entries(SUPPORTED_IMPORTS).map(([value, label]) => ({
          label,
          value
        }))}
      />
    </Form.Item>
  )
}
