import { CodeEditor } from '@/components/code-editor'
import { Tooltip } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import classNames from 'classnames'

type Props = {
  isVisible?: boolean
}

export const AdvancedFields = ({ isVisible }: Props) => {
  const form = Form.useFormInstance()
  const fieldsWatch = Form.useWatch([], form)

  return (
    <div
      className={classNames('mt-16', {
        hidden: !isVisible
      })}
    >
      <Form.Item label="Default Value" name="default_value">
        <Input data-cy="default-value-input" />
      </Form.Item>
      <Tooltip
        placement="bottom"
        title={
          <div className="whitespace-pre-line">{`When a location or asset is added to a ticket, fill this element with information elsewhere on the ticket. \n 
                          Example 1: "assets.0.name" -- pulls the selected asset's name \n
                          Example 2: "location.virtual_fields.county" -- pulls the selected location's custom county field`}</div>
        }
      >
        <Form.Item label="Alias Field" name="alias_field">
          <Input data-cy="alias-field-input" />
        </Form.Item>
      </Tooltip>
      <Form.Item valuePropName="checked" name="reverse_alias">
        <Checkbox data-cy="reverse-alias-checkbox">
          Reverse Alias (Updates ticket related field instead of ticket property)
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="allow_rename">
        <Checkbox data-cy="allow-rename-checkbox">Allow Rename</Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="editable_on_secure_access">
        <Checkbox data-cy="allow-rename-checkbox">Editable on Customer Signature Page</Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="is_managed">
        <Checkbox
          data-cy="is-managed-checkbox"
          onChange={(e) => {
            form.setFieldsValue({ is_managed: e.target.checked })
            if (e.target.checked) {
              form.setFieldsValue({ use_binding: false })
            }
          }}
        >
          Display on Choices Manager
        </Checkbox>
      </Form.Item>
      <Form.Item name="use_binding" valuePropName="checked">
        <Checkbox
          data-cy="use-binding-checkbox"
          onChange={(e) => {
            form.setFieldsValue({ use_binding: e.target.checked })
            if (e.target.checked) {
              form.setFieldsValue({ is_managed: false })
            }
          }}
        >
          Use Data Binding
        </Checkbox>
      </Form.Item>
      {fieldsWatch?.use_binding && (
        <Form.Item label="Data Binding" name="binding_json">
          <CodeEditor data-cy="binding-json-input" mode="json" minLines={5} maxLines={Infinity} isInModal />
        </Form.Item>
      )}
      <Form.Item name="use_web_binding" valuePropName="checked">
        <Checkbox
          data-cy="use-web-binding-checkbox"
          onChange={(e) => {
            form.setFieldsValue({ use_web_binding: e.target.checked })
            if (e.target.checked) {
              form.setFieldsValue({ is_managed: false })
            }
          }}
        >
          Use Web Binding
        </Checkbox>
      </Form.Item>
      <Form.Item name="binding_json">
        <Input hidden />
      </Form.Item>
      {fieldsWatch?.use_web_binding && (
        <Form.Item label="Web Binding (Depreciated)" name="web_binding">
          <CodeEditor data-cy="web-binding-input" mode="javascript" minLines={5} maxLines={Infinity} isInModal />
        </Form.Item>
      )}
      <Form.Item label="CSS Print Class" name="print_class">
        <Input data-cy="css-print-class-input" />
      </Form.Item>
      <Form.Item label="Warning Title" name="warning_title">
        <Input data-cy="warning-title-input" />
      </Form.Item>
      <Form.Item label="Warning Text" name="warning_text">
        <Input data-cy="warning-text-input" />
      </Form.Item>
      <Form.Item label="Value Transform Config JSON" name="transform_json">
        <CodeEditor data-cy="value-transform-config-json-input" mode="json" height="100px" isInModal />
      </Form.Item>
    </div>
  )
}
