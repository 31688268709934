import { AppLogo } from '@/components'
import { TicketSubscriberStatus } from '@/constants/general'
import { useApp } from '@/hooks'
import { ticketApiV2, ticketSubscriberApi } from '@/services/api-service'
import { Button, Icon } from '@/ui'
import { Spin } from '@/ui/spin'
import { mediaUrl } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Form, Input, Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

export const TicketSubscriptionView = () => {
  const { notification } = useApp()
  const { token } = useParams()
  const [selectedStatus, setSelectedStatus] = useState<TicketSubscriberStatus>('I')
  const [notes, setNotes] = useState('')
  const invitationMutation = useMutation(ticketSubscriberApi.respond(token))
  const [respondedStatus, setRespondedStatus] = useState<TicketSubscriberStatus | null>(null)

  const ticketSubscriberQuery = useQuery({
    ...ticketSubscriberApi.getByToken(token, { fields: ticketSubscriberSchema }),
    enabled: !!token
  })

  const ticketInfoQuery = useQuery({
    ...ticketApiV2.subscriberAccess(token, { fields: ticketInfoSchema }),
    enabled: !!token
  })

  const handleSubmit = async () => {
    if (selectedStatus === 'I') {
      notification.error({ message: 'Please select Accept or Reject' })
      return
    }

    const { status } = await invitationMutation.mutateAsync({
      type: selectedStatus === 'A' ? 'accept' : 'reject',
      notes
    })

    setRespondedStatus(status)
    notification.success({ message: selectedStatus === 'A' ? 'Invitation Accepted' : 'Invitation Rejected' })
  }

  if (ticketInfoQuery.isError || ticketInfoQuery.isError) {
    notification.error({ message: 'Invitation not found' })
    setTimeout(() => window.location.replace('/'), 3000)
    return null
  }

  if (ticketInfoQuery.isInitialLoading || ticketInfoQuery.isInitialLoading) {
    return (
      <div className="h-screen">
        <Spin spinning isCentered />
      </div>
    )
  }

  const ticketSubscriber = ticketSubscriberQuery.data
  const ticketInfo = ticketInfoQuery.data

  return (
    <main className="max-w-screen-2xl mx-auto">
      <div className="flex flex-col h-screen">
        <header className="flex py-16 px-20 items-center justify-between">
          <a href="/">
            <AppLogo className="w-[240px]" dark />
          </a>
          <a href="/auth/login">
            <Button type="primary">Login</Button>
          </a>
        </header>
        <section className="h-full flex items-center justify-center">
          <div className="w-[440px] border p-18 border-border rounded shadow-sm text-center">
            {ticketInfo?.office__company__icon && (
              <img
                className="w-[110px]"
                src={mediaUrl(ticketInfo.office__company__icon)}
                alt={ticketInfo.office__company__name || ''}
              />
            )}
            <div className="text-20 font-medium mt-12">
              Hello{' '}
              <span className="text-primary-400 font-semibold">
                {ticketSubscriber?.contact__first_name} {ticketSubscriber?.contact__last_name}
              </span>
            </div>
            <div className="text-16 font-medium mt-12">You have been assigned a new job. </div>
            <div className="text-gray-400">Details have been emailed to you.</div>

            <div className="py-16 px-60 rounded bg-gray-50 mt-16 text-16">
              <div className="px-26 text-left">
                <div className="mb-8">
                  <Icon name="fa:tag" className="mr-12 text-14" />
                  Ticket #: {ticketInfo?.name}
                </div>
                <div>
                  <Icon name="fa:calendar" className="mr-12 text-14" />
                  Job Date: {dayjs.formatLocalDate(ticketInfo?.timepoint_due)}
                </div>
              </div>
              {!respondedStatus && (
                <div className="flex gap-x-12 mt-16">
                  <Button
                    block
                    type={selectedStatus === 'R' ? 'primary' : undefined}
                    danger
                    onClick={() => setSelectedStatus('R')}
                  >
                    Reject
                  </Button>
                  <Button
                    block
                    success
                    type={selectedStatus === 'A' ? 'success' : undefined}
                    onClick={() => setSelectedStatus('A')}
                  >
                    Accept
                  </Button>
                </div>
              )}
            </div>
            {!respondedStatus && (
              <>
                <Form layout="vertical">
                  <Form.Item label="Note (optional)" className="mt-16">
                    <Input.TextArea onChange={(e) => setNotes(e.target.value)} />
                  </Form.Item>
                </Form>
                <div className="flex">
                  <Space className="ml-auto">
                    <Button type="primary" loading={invitationMutation.isLoading} onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Space>
                </div>
              </>
            )}
            {respondedStatus === 'A' && (
              <Alert
                className="text-left mt-16"
                type="success"
                showIcon
                message="Job accepted"
                description="You have accepted the job"
              />
            )}
            {respondedStatus === 'R' && (
              <Alert
                className="text-left mt-16"
                type="error"
                showIcon
                message="Job rejected"
                description="You have rejected the job"
              />
            )}
          </div>
        </section>
        <footer className="flex py-16 px-20 items-center justify-between">
          <div>&copy; {new Date().getFullYear()} Oil Command. All rights reserved.</div>
          <div>
            <a className="mr-16">Terms & Conditions</a>
            <a>Privacy & Policy</a>
          </div>
        </footer>
      </div>
    </main>
  )
}

const ticketSubscriberSchema = z.object({
  id: z.number(),
  contact__first_name: z.string().nullish(),
  contact__last_name: z.string().nullish(),
  contact__id: z.number().nullish(),
  status: z.string()
})

const ticketInfoSchema = z.object({
  name: z.string(),
  office__company__name: z.string().nullish(),
  office__company__icon: z.string().nullish(),
  timepoint_due: z.string().nullish()
})
