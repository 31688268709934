import { ListParams, ListResponse } from '@/types/api/core'
import { ListFilter, ListFilterProps } from '@/ui/filter'
import { getSearchQuery } from '@/ui/input/helpers'
import { QueryTable, QueryTableProps, QueryTableRef } from '@/ui/table'
import { deepMerge } from '@/utils'
import { UseQueryResult } from '@tanstack/react-query'
import cn from 'classnames'
import { cloneDeep } from 'lodash'
import { ReactElement, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { PageView, PageViewProps } from '../page-view'

export type ListViewProps = PageViewProps & {
  header?: PageViewProps['header'] & { refresh?: boolean }
  hideHeader?: boolean
  filter?: ListFilterProps
  table?: QueryTableProps
  renderTable?: (tableElement?: ReactElement) => ReactElement
  className?: string
  onQueryChange?: (query: UseQueryResult<ListResponse<any>, any>, total: number) => void
}

export type ListViewRef = {
  table: QueryTableRef
}

export const ListView = forwardRef<ListViewRef, ListViewProps>(
  ({ className, header, filter, table, renderTable, hideHeader, onQueryChange, ...pageViewProps }, ref) => {
    const tableRef = useRef<any>(null)
    const [filterParams, setFilterParams] = useState<ListParams>({})

    const handleFilterChange = (value: ListParams) => {
      setFilterParams(value)
      filter?.onChange?.(value)
    }

    const handleRefresh = useCallback(() => {
      if (tableRef.current && tableRef.current?.refresh) {
        tableRef.current.refresh()
      }
    }, [])

    // expose to parent component
    useImperativeHandle(ref, () => ({
      table: tableRef.current
    }))

    const tableElement = table && (
      <QueryTable
        fullHeight
        scroll={{ x: 1000 }}
        paramsStore={'url'}
        {...table}
        ref={tableRef}
        onQueryChange={onQueryChange}
        queryParams={(params) => {
          // cleanup params
          const _params = cloneDeep(params)
          if (_params?.filter?.search) _params.filter.search = _params.filter.search.toString().trim()

          // merge all query params
          const tableQueryParams =
            cloneDeep(typeof table?.queryParams === 'function' ? table?.queryParams(_params) : table?.queryParams) || {}
          const searchQueryParams =
            typeof filter?.search === 'object'
              ? getSearchQuery(_params?.filter?.search, filter?.search?.searchBy, filter?.search.searchType)
              : {}
          const tableFilterParams = cloneDeep(filter?.query ? filter?.query(_params) : filter?.query) || {}
          return deepMerge(
            deepMerge(deepMerge(tableQueryParams, tableFilterParams), filterParams || {}),
            searchQueryParams
          ) // important! deep merge to concat Q[] array
        }}
      />
    )

    const headerProps = hideHeader
      ? undefined
      : { ...header, onRefresh: header?.refresh === false ? undefined : handleRefresh, clearFilter: Boolean(filter) }

    return (
      <PageView header={headerProps} {...pageViewProps}>
        <div className={cn('flex-grow flex flex-col gap-10', className)}>
          {filter && <ListFilter {...filter} onChange={handleFilterChange} />}
          {renderTable ? renderTable(tableElement) : tableElement}
        </div>
      </PageView>
    )
  }
)

ListView.displayName = 'ListView'
