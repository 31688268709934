/**
 * Standard endpoints for CRUD operations supported for each resource or entity.
 * Use these endpoints to create react-query hooks.
 *
 * @example:
 * const { data, isLoading, isError, error } = useQuery(ticketApi.list())
 * const { mutate } = useMutation(ticketApi.save())
 *
 * @see https://react-query.tanstack.com/reference/useQuery
 * @see https://react-query.tanstack.com/reference/useQueries
 * @see https://react-query.tanstack.com/reference/useMutation
 */

export * from './api/jas'
import { IdParam } from '@/types/api/core'
import { _attachmentApi } from './api/attachment'
import { _authApi } from './api/auth'
import { _backgroundJobApi } from './api/background-job'
import { _clientRecordApi } from './api/client-record'
import { _customEmailApi } from './api/custom-email'
import { _gmapApi } from './api/gmap'
import { _integrationsApiV2 } from './api/integrations'
import { _lineItemApi, _lineItemApiV2 } from './api/line-item'
import { _msNavApi } from './api/msnav'
import { _openIdAuthAPI } from './api/openid-auth'
import { _programmedReportApi } from './api/programmed-report'
import { _propertyApi } from './api/property'
import { _systemApi } from './api/system'
import { _ticketApi, _ticketApiV2 } from './api/ticket'
import { _ticketImportLogApi, _ticketImportTemplateApi } from './api/ticket-import'
import { _ticketSubscriberApi } from './api/ticket-subscriber'
import { _wikiApi } from './api/wiki'
import { createResourceApi } from './core'

export const systemApi = _systemApi
export const accountRoleApi = createResourceApi('account_roles')
export const addressApi = createResourceApi('addresses')
export const afeApi = createResourceApi('afes')
export const alertApi = createResourceApi('alerts')
export const assetApi = createResourceApi('assets')
export const attachmentApi = _attachmentApi
export const authApi = _authApi
export const openIdAuthAPI = _openIdAuthAPI
export const backgroundJobApi = _backgroundJobApi
export const bundlePdfApi = createResourceApi('bundle_pdf')
export const calendarEventsApi = createResourceApi('v2/calendar_events')
export const categoryApi = createResourceApi('categories')
export const categoryKindApi = createResourceApi('category_kinds')
export const categoryTagApi = createResourceApi('category_tags')
export const clientRecordApi = _clientRecordApi
export const clientRecordStatApi = createResourceApi('client_records/stats')
export const companyAccountApi = createResourceApi('company_accounts')
export const companyApi = createResourceApi('companies')
export const companyIntegrationApi = createResourceApi('v2/integrations/company-integrations')
export const companyOfficeApi = (id: IdParam) => createResourceApi(`companies/${id}/offices`)
export const contactApi = createResourceApi('contacts')
export const contactLinkApi = createResourceApi('contact_links')
export const contactNoteApi = (id: IdParam) => createResourceApi(`contacts/${id}/notes`)
export const contentTypeApi = createResourceApi('content_types')
export const customEmailApi = _customEmailApi
export const customFormAccessApi = createResourceApi('custom_form_access')
export const customFormAccessRuleApi = (id: IdParam) => createResourceApi(`custom_forms/${id}/access`)
export const customFormApi = createResourceApi('custom_forms')
export const customFormApiV2 = createResourceApi('v2/custom_forms', ['custom_forms', 'v2'])
export const customReportApi = createResourceApi('custom_reports')
export const dashboardApi = createResourceApi('dashboards')
export const dataManagerApi = createResourceApi('data_managers')
export const datasetApi = createResourceApi('datasets')
export const documentApi = createResourceApi('documents')
export const equipmentApi = createResourceApi('equipment')
export const equipmentComponentApi = createResourceApi('equipment_components')
export const equipmentDefaultLiFormApi = (id: IdParam) => createResourceApi(`equipment/${id}/default_li_forms`)
export const equipmentInventoryStatsApi = createResourceApi('equipment/inventory_stats')
export const equipmentGroupApi = createResourceApi('equipment_groups')
export const equipmentPriceApi = createResourceApi('equipment_prices')
export const equipmentStatsByCategoryApi = createResourceApi('equipment/stats_by_category')
export const eventApi = createResourceApi('events')
export const filterFieldApi = createResourceApi('filter_fields')
export const impersonateUserApi = (id: IdParam) => createResourceApi(`account/users/${id}/impersonate`)
export const importExportFileApi = createResourceApi('import_export_file')
export const integrationApi = createResourceApi('integrations')
export const integrationsApiV2 = _integrationsApiV2
export const inventoryAlertApi = createResourceApi('inventory_alerts')
export const inventoryIndicatorApi = createResourceApi('inventory_indicators')
export const invoiceApi = createResourceApi('invoices')
export const jobCodeApi = createResourceApi('job_codes')
export const jobTypeApi = createResourceApi('job_types')
export const lineItemApi = _lineItemApi
export const lineItemApiV2 = _lineItemApiV2
export const locationApi = createResourceApi('locations')
export const moduleApi = createResourceApi('modules')
export const noteApi = createResourceApi('notes')
export const notificationApi = createResourceApi('notifications')
export const notificationApiV2 = createResourceApi('v2/notifications')
export const officeApi = createResourceApi('offices')
export const officeRelationApi = createResourceApi('office_relations')
export const permissionApi = createResourceApi('permissions')
export const pricingCustomerApi = createResourceApi('pricing_customers')
export const printStyleApi = createResourceApi('print_styles')
export const profileApi = createResourceApi('profiles')
export const programmedReportApi = _programmedReportApi
export const propertyApi = _propertyApi
export const propertyChoiceApi = createResourceApi('property_choices')
export const propertyEventApi = (id: IdParam) => createResourceApi(`properties/${id}/events`)
export const propertyGroupApi = createResourceApi('property_groups')
export const propertyTypeApi = createResourceApi('property_types')
export const propertyUnitApi = createResourceApi('property_units')
export const qbSettingApi = createResourceApi('qb_settings')
export const qboSessionApi = createResourceApi('qbo/session')
export const ratingApi = createResourceApi('ratings')
export const regionApi = createResourceApi('regions')
export const reportApi = createResourceApi('reports')
export const scheduledReportApi = createResourceApi('scheduled_reports')
export const reportBuilderApi = createResourceApi('report_builder/api/reports')
export const reportBuilderFilterFieldApi = createResourceApi('report_builder/api/filterfields')
export const scriptApi = createResourceApi('scripts')
export const secretApi = createResourceApi('v2/secrets')
export const secureAccessApi = createResourceApi('secure_access')
export const secureAccessWaitListApi = createResourceApi('secure_access/wait_list')
export const sendgridTemplateApi = createResourceApi('sendgrid/templates')
export const settingApi = createResourceApi('settings')
export const signatureApi = createResourceApi('signatures')
export const signatureApiV2 = createResourceApi('v2/signatures')
export const strappingIncrementApi = createResourceApi('strapping_increments')
export const supportRequestApi = createResourceApi('support_requests')
export const supportRequestAttApi = createResourceApi('support_request_atts')
export const tableViewApi = createResourceApi('table_views')
export const tableViewApiV2 = createResourceApi('v2/table_views', ['table_views', 'v2'])
export const tagApi = createResourceApi('tags')
export const ticketAlertApi = createResourceApi('ticket_alerts')
export const ticketApi = _ticketApi
export const ticketApiV2 = _ticketApiV2
export const ticketAssetApi = createResourceApi('ticket_assets')
export const ticketBatchJobApi = createResourceApi('v2/ticket_batch_jobs')
export const ticketImportTemplateApi = _ticketImportTemplateApi
export const ticketImportLogApi = _ticketImportLogApi
export const ticketNoteApi = createResourceApi('ticket_notes')
export const ticketPropertyApi = createResourceApi('ticket_properties')
export const ticketSubscriberApi = _ticketSubscriberApi
export const timeTicketApi = createResourceApi('time_ticket')
export const timeTicketEventsApi = (id: IdParam) => createResourceApi(`time_ticket/${id}/events`)
export const timezoneApi = createResourceApi('timezones')
export const tipApi = createResourceApi('tips')
export const tipPageApi = createResourceApi('tip_pages')
export const transformationApi = createResourceApi('transformations')
export const transformationLogApi = createResourceApi('transformation_logs')
export const transformationTaskApi = createResourceApi('transformation_tasks')
export const userApi = createResourceApi('account/users')
export const userDataApi = createResourceApi('user_data')
export const wikiApi = _wikiApi
export const notificationTemplateApi = createResourceApi('v2/notification-templates')
export const wsnSearchApi = createResourceApi('wsn/search')
export const gmapApi = _gmapApi
export const msNavApi = _msNavApi

/**
 * Custom API Endpoints
 *
 * How to write a custom endpoint?
 * - Create new file in the ./api directory. E.g. ./api/auth.ts
 * - Create endpoint type and object in the ./api/auth.ts file
 * - Refer to standard built-in endpoints for reference
 *
 * todo: support for multipart/form-data requests (file uploads)
 * todo: verify all custom endpoints are included
 */
