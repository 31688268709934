import { AddEditContactView } from '../../../add-edit-contact-view'

const AddContactView = () => {
  return (
    <div className="px-16">
      <AddEditContactView id="" />
    </div>
  )
}

export { AddContactView }
