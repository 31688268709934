import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useAtomValue, useSetAtom } from 'jotai'
import { lineItemFieldsAtom } from '../../atoms'
import { ContainerKey, LineItemField } from '../../types'
import { useFindContainer } from './use-find-container'

type Params = {
  setActiveId: (id: LineItemField | null) => void
}

export const useHandleDragEnd = ({ setActiveId }: Params) => {
  const { findContainer } = useFindContainer()
  const lineItemFields = useAtomValue(lineItemFieldsAtom)
  const setLineItemFields = useSetAtom(lineItemFieldsAtom)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    const activeContainer = findContainer(active.id as ContainerKey | LineItemField)

    if (!activeContainer) {
      setActiveId(null)
      return
    }

    const overId = over?.id

    if (overId == null) {
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId as ContainerKey | LineItemField)

    if (overContainer) {
      const activeIndex = lineItemFields[activeContainer].indexOf(active.id as LineItemField)
      const overIndex = lineItemFields[overContainer].indexOf(overId as LineItemField)

      if (activeIndex !== overIndex) {
        setLineItemFields((items) => ({
          ...items,
          [overContainer]: arrayMove(lineItemFields[overContainer], activeIndex, overIndex)
        }))
      }
    }

    setActiveId(null)
  }

  return { handleDragEnd }
}
