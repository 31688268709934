import { LINE_ITEM_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_COLUMNS } from '@/modules/ticket/columns'

export const TICKETS = TICKET_COLUMNS
export const LINE_ITEMS = LINE_ITEM_COLUMNS

export const COLUMN_KEYS = {
  available: 'available',
  selected: 'selected'
} as const
