import { useAtomValue } from 'jotai'
import { editingEquipmentAtom } from '../../atoms'
import { Equipment } from '../../schemas'
import { DefaultRateEditCell } from './default-rate-edit-cell'

type Props = {
  equipment: Equipment
}

export const DefaultRateCell = ({ equipment }: Props) => {
  const editingEquipment = useAtomValue(editingEquipmentAtom)

  if (editingEquipment && editingEquipment.id === equipment.id) {
    return <DefaultRateEditCell equipment={equipment} />
  }

  return (
    <div>
      {equipment.usage_rate &&
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(equipment.usage_rate)}
    </div>
  )
}
