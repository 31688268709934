import { Form, Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const DescriptionField = () => {
  const description = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.description), []))
  const setCustomForm = useSetAtom(customFormAtom)

  return (
    <Form.Item label="Description" className="col-span-2">
      <Input
        value={description || ''}
        data-cy="custom-form-description-input"
        onChange={(e) =>
          setCustomForm((prev) => ({
            ...prev,
            description: e.target.value
          }))
        }
      />
    </Form.Item>
  )
}
