import { useCompanyFeatures, useSession } from '@/hooks'
import { WIKI_CONFIG_KEY } from '@/modules/wiki/constants'
import { wikiApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { get } from 'lodash'
import { useMemo } from 'react'

export const useWiki = () => {
  const { user } = useSession()

  const wikiUserConfig = get(user?.profile.userdata_json, WIKI_CONFIG_KEY)
  const { wiki: wikiCompanyConfig } = useCompanyFeatures()

  const isEnabled = useMemo(
    () =>
      !!(
        wikiUserConfig?.enabled &&
        wikiUserConfig?.wiki_user_id &&
        wikiCompanyConfig.enabled &&
        wikiCompanyConfig.wiki_company_id
      ),
    [wikiUserConfig, wikiCompanyConfig]
  )

  return {
    isEnabled,
    ...useQuery({
      ...wikiApi.access({}),
      enabled: isEnabled
    })
  }
}
