import { useApp } from '@/hooks'
import { ReportBuilderReport } from '@/types/report-builder-report'
import { Form } from 'antd'
import { useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { columnsAtom } from '../atoms'
import { DragAndDropFieldsSection } from '../drag-and-drop-fields-section'
import { Property } from '../types'
import { buildAvailableColumn } from '../utils/build-available-column'
import { processPropertyColumns } from '../utils/process-property-columns'
import { processSelectedColumns } from '../utils/process-selected-columns'

type Props = {
  report: ReportBuilderReport
  properties: Property[]
}

export const DragAndDropContainer = ({ report, properties }: Props) => {
  const { labels } = useApp()
  const form = Form.useFormInstance<any>()
  const customForms = Form.useWatch(['options', 'custom_forms'], form) || []
  const isLineItemReport = Form.useWatch('is_line_item_report', form)
  const setColumns = useSetAtom(columnsAtom)

  const propertiesColumns = useMemo(() => {
    const propertiesColumns = processPropertyColumns(report, properties)
    return propertiesColumns
  }, [properties, report])

  useEffect(() => {
    const availableColumns = buildAvailableColumn(isLineItemReport, propertiesColumns, customForms, labels)
    // setAvailableColumns(availableColumns)
    setColumns((columns) => ({
      ...columns,
      available: availableColumns.filter((field) => !columns.selected?.find((val) => val.id === field.id))
    }))
  }, [isLineItemReport, propertiesColumns, customForms, labels])

  useEffect(() => {
    const selectedColumns = processSelectedColumns(report, propertiesColumns, labels)
    // setSelectedColumns(selectedColumns)
    setColumns((columns) => ({ ...columns, selected: selectedColumns }))
  }, [report, propertiesColumns, labels])

  return <DragAndDropFieldsSection />
}
