import { PageView } from '@/layouts/views'
import { JobManagerAddResource } from '@/modules/jas/job-manager/add-resource'
import { TopFilter } from '@/modules/jas/job-manager/components/job-filter/TopFilter'
import { JobList } from '@/modules/jas/job-manager/components/job-list/JobList'
import JobTicketDetail from '@/modules/jas/job-manager/components/job-ticket-detail/JobTicketDetail'
import { useAddResourceTypeParam, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import cn from 'classnames'
import React from 'react'
import { useCurrentModuleQuery } from '../../module/hooks'

export const JobManagerIndex: React.FC = () => {
  const [addResourceType] = useAddResourceTypeParam()
  const showAddView = !!addResourceType

  const { module } = useCurrentModuleQuery()

  const { selectedTicket } = useSelectedJobTicket()

  /*
  important! use style to hide and show and page transition not un-mount
  job manager index. which causes page reset
  */

  return (
    <>
      <section className={cn({ hidden: showAddView })}>
        <PageView header={{ title: module?.title, breadcrumbs: true }}>
          <div className={cn('flex flex-col h-full')}>
            <TopFilter />
            <div className="sm:flex-1 xs:flex-1 lg:h-[calc(100vh-250px)] overflow-visible lg:!overflow-hidden">
              <div className="lg:flex lg:h-full">
                <JobList />

                <div className="w-full h-full lg:ml-8 shadow rounded">
                  <JobTicketDetail />
                </div>
              </div>
            </div>
          </div>
        </PageView>
      </section>

      {showAddView && <JobManagerAddResource />}
    </>
  )
}
