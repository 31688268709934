import { useIsMobile } from '@/hooks'
import { FC } from 'react'
import { useTicket } from '../../../../../hooks'
import { PropertyGroup } from '../../../.././../schemas'
import { ItemsSectionMemo } from '../../../../line-items/components'
import { PropertyFormItem } from '../../../property-form-item'

type Props = { propertyGroup: PropertyGroup }

export const SectionProperties: FC<Props> = ({ propertyGroup }) => {
  const isMobile = useIsMobile()
  const { isNew } = useTicket()

  if (propertyGroup.line_item_placeholder) {
    return isNew ? null : <ItemsSectionMemo propertyGroup={propertyGroup} />
  }

  return (
    <div className={'flex flex-row flex-wrap'}>
      {propertyGroup._properties.map((p) => (
        <PropertyFormItem
          key={p.id}
          property={p}
          style={{ width: isMobile ? '100%' : `${p._width}%`, marginRight: `${p._skip}%` }}
        />
      ))}
    </div>
  )
}
