import { Tooltip } from '@/ui'
import { Alert, Form, Input } from 'antd'

export const RenameColumns = () => {
  return (
    <>
      <Form.Item label="Tab">
        <Tooltip title="Leave blank to use the currently selected sheet">
          <Form.Item name={['params', 'tab']} noStyle>
            <Input />
          </Form.Item>
        </Tooltip>
      </Form.Item>
      <Form.Item label="Columns">
        <Tooltip title="Comma separated names">
          <Form.Item name={['params', 'old_names']} noStyle>
            <Input />
          </Form.Item>
        </Tooltip>
      </Form.Item>
      <Form.Item label="New column names">
        <Tooltip title="Comma separated names">
          <Form.Item name={['params', 'new_names']} noStyle>
            <Input />
          </Form.Item>
        </Tooltip>
      </Form.Item>
      <Alert
        type="info"
        message='The reporting engine will try to match the "friendly" column name, if available. For example, both Bonus Rate
        and user_decimal_2 match the same field.'
      ></Alert>
    </>
  )
}
