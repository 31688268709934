import { Button } from '@/ui/button'
import { SectionTitle } from '@/ui/section-title'
import { Form, Input } from 'antd'
import { useCompanyOfficesQuery } from '../hooks'

export const OfficesSection = () => {
  const { companyOfficesQuery } = useCompanyOfficesQuery()

  return (
    <div>
      <Form.List name="offices">
        {(fields, { add }) => (
          <div>
            <SectionTitle
              extra={
                <Button onClick={() => add()} iconName="mi:add" type="primary">
                  Add additional field office
                </Button>
              }
            >
              Offices
            </SectionTitle>

            {fields.map((field, index) => {
              const office = companyOfficesQuery.data?.items[index] ?? {}

              return (
                <div key={field.key} className="mt-16 border-b border-b-border last-of-type:border-b-0">
                  <h5 className="font-bold">{office.name ?? 'Unnamed Office'}</h5>
                  <Form.Item name={[field.name, 'id']} hidden />
                  <Form.Item name={[field.name, 'company']} hidden />
                  <Form.Item
                    name={[field.name, 'name']}
                    rules={[{ required: true, message: 'Please input office name' }]}
                    label="Name"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name={[field.name, 'address', 'line1']} label="Line 1">
                    <Input />
                  </Form.Item>
                  <Form.Item name={[field.name, 'address', 'line2']} label="Line 2">
                    <Input />
                  </Form.Item>
                  <div className="grid grid-cols-4 gap-x-16">
                    <Form.Item name={[field.name, 'address', 'city']} label="City">
                      <Input />
                    </Form.Item>
                    <Form.Item name={[field.name, 'address', 'state']} label="State">
                      <Input />
                    </Form.Item>
                    <Form.Item name={[field.name, 'address', 'zipcode']} label="Zip">
                      <Input />
                    </Form.Item>
                    <Form.Item name={[field.name, 'address', 'country']} label="Country">
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </Form.List>
    </div>
  )
}
