import { useRemainingDimensions } from '@/hooks'
import cn from 'classnames'
import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export type PageViewContentProps = {
  scrollable?: boolean
  className?: string
  children?: ReactNode
}

export const PageViewContent: FC<PageViewContentProps> = ({ scrollable = true, className, children }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const remainingDimension = useRemainingDimensions(containerRef)
  const { pathname } = useLocation()

  const style: CSSProperties = {}
  if (scrollable && remainingDimension.isReady) {
    style.height = `${Math.max(100, remainingDimension.height)}px`
  }

  useEffect(() => {
    // reset scroll when we are switching between subroutes
    containerRef.current?.scrollTo(0, 0)
  }, [pathname])

  return (
    <div
      ref={containerRef}
      className={cn('flex-grow flex flex-col', { 'overflow-auto': scrollable }, className)}
      style={style}
    >
      {children || <Outlet />}
    </div>
  )
}
