import { z } from 'zod'

export const EquipmentSchema = z.object({
  id: z.number(),
  description: z.string().nullish(),
  usage_rate: z.number().nullish(),
  sku: z.string().nullish(),
  unit: z.string().nullish()
})

const PriceSchema = z.object({
  id: z.number(),
  usage_rate: z.number().nullish(),
  equipment: z.number(),
  customer_office: z.number()
})

export const PricingCustomerSchema = z.object({
  id: z.number(),
  customer_office__name: z.string(),
  customer_office: z.number(),
  prices: z.array(PriceSchema).nullish(),
  expiry: z.string().nullish()
})

export type Equipment = z.infer<typeof EquipmentSchema>
export type PricingCustomer = z.infer<typeof PricingCustomerSchema>
export type Price = z.infer<typeof PriceSchema>

export type FormValues = {
  [key: string]: {
    usageRate: number | null
  }
} & {
  defaultUsageRate: {
    usageRate: number | null
  }
}
