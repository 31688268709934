import { JOTAI_GLOBAL_STORE } from '@/constants/app'
import { refreshQueries } from '@/query'
import { backgroundJobApi } from '@/services/api-service'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { backgroundJobIdsAtom } from './atoms'
import { BackgroundJobState, BackgroundJobStateSchema } from './types'

export const useBackgroundJobsHook = () => {
  const [refetch, setRefetch] = useState(false)
  const [backgroundJobIds, setBackgroundJobIds] = useAtom(backgroundJobIdsAtom, { store: JOTAI_GLOBAL_STORE })

  const backgroundJobsQuery = useQuery({
    ...backgroundJobApi.list<BackgroundJobState>({
      fields: BackgroundJobStateSchema,
      id__in: backgroundJobIds.join(','),
      order_by: '-id'
    }),
    enabled: backgroundJobIds.length > 0,
    refetchOnMount: refetch,
    refetchInterval: refetch ? 3000 : false,
    refetchOnWindowFocus: refetch,
    select: (data) => {
      data.items.forEach((bgJob) => {
        if (!['F', 'H', 'E'].includes(bgJob.status)) return

        const queryKeys = getInvalidateQueryMaps()[bgJob.id]
        if (!queryKeys || !queryKeys.length) return

        refreshQueries(queryKeys)
        removeInvalidateQueryKeys(bgJob.id)
      })

      return data
    }
  })
  const backgroundJobs = useMemo(
    () => (backgroundJobIds.length > 0 ? backgroundJobsQuery.data?.items || [] : []),
    [backgroundJobIds, backgroundJobsQuery.data?.items]
  )

  const haltJobMutation = useMutation(backgroundJobApi.abort())

  const trackBackgroundJob = useCallback(
    (bgJobId: number, onFinished?: { invalidateQueryKeys?: string[] }) => {
      setBackgroundJobIds((ids) => [...ids, bgJobId])
      if (onFinished?.invalidateQueryKeys) addInvalidateQueryKeys(bgJobId, onFinished.invalidateQueryKeys)
    },
    [setBackgroundJobIds]
  )
  const removeTrackingBackgroundJob = useCallback(
    (bgJobId: number) => setBackgroundJobIds((ids) => ids.filter((id) => id !== bgJobId)),
    [setBackgroundJobIds]
  )

  const isJobInProgress = (bgJob: BackgroundJobState) => ['D', 'Q', 'I'].includes(bgJob.status)
  const isJobFinished = (bgJob: BackgroundJobState) => ['F', 'H', 'E'].includes(bgJob.status)

  useEffect(() => {
    // enable refetch when there are background jobs that are not finished
    const _refetch =
      Boolean(backgroundJobs.length) && backgroundJobs.some((job) => isJobInProgress(job) || job.status === 'R')
    if (_refetch !== refetch) setRefetch(_refetch)
  }, [backgroundJobs, refetch])

  return {
    backgroundJobs,
    trackBackgroundJob,
    removeTrackingBackgroundJob,
    haltJobMutation,
    isJobInProgress,
    isJobFinished
  }
}

const _KEY = 'ocBgJobInvalidateQueryMaps'
const getInvalidateQueryMaps = (): Record<number, string[]> => {
  return JSON.parse(localStorage.getItem(_KEY) || '{}')
}

const addInvalidateQueryKeys = (bgJobId: number, keys: string[]) => {
  const currentVal = getInvalidateQueryMaps()
  localStorage.setItem(_KEY, JSON.stringify({ ...currentVal, [bgJobId]: keys }))
}

const removeInvalidateQueryKeys = (bgJobId: number) => {
  const currentVal = JSON.parse(localStorage.getItem(_KEY) || '{}')
  localStorage.setItem(_KEY, JSON.stringify({ ...currentVal, [bgJobId]: undefined }))
}
