import { UserSelect } from '@/components/user-select'
import {
  ENTITY_LABELS,
  LINE_ITEM_LABELS,
  LOCATION_LABELS,
  NAV_LABELS,
  REPORT_LABELS,
  TICKET_LABELS,
  TICKET_USER_DATA_LABELS
} from '@/constants/labels'
import { useApp, useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { SaveButton } from '@/ui'
import { safeJsonParse } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Form } from 'antd'
import { useCallback, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { AddLabelModal } from './AddLabelModal'
import { AliasFormItem } from './AliasFormItem'
import { DataJsonCodeEditor } from './data-json-code-editor'

export const AdvancedSettingsView = view<any, any>(Component, {
  title: () => 'Advanced Settings'
})

function Component() {
  const { notification } = useApp()
  const { company } = useSession()
  const [form] = Form.useForm<FormValues>()
  const companyQuery = useQuery({ ...companyApi.get<Company>(company.id) })
  const data = companyQuery.data?.data
  const dataJson = companyQuery.data?.data_json
  const updateCompanyMutation = useMutation({ ...companyApi.patch() })
  const { refreshUserSession } = useBootstrap()

  const companyFieldAliases: Record<string, string> = useMemo(
    () => safeJsonParse(company.field_aliases, {}),
    [company.field_aliases]
  )

  const [fieldAliases, setFieldAliases] = useImmer(() => {
    return Object.entries(companyFieldAliases).reduce(
      (acc, [key, value]) => {
        const sectionKey = key.split('__')[0]
        const hiddenReferenceKey = key.startsWith('ticket__user_data')

        // handle entity field aliases
        if (!(sectionKey in acc)) {
          return {
            ...acc,
            entity: {
              ...acc.entity,
              [key]: value
            }
          }
        }

        // handle hidden reference field aliases
        if (hiddenReferenceKey) {
          return {
            ...acc,
            ticket__user_data: {
              ...acc.ticket__user_data,
              [key]: value
            }
          }
        }

        if (acc[sectionKey]) {
          return {
            ...acc,
            [sectionKey]: {
              ...acc[sectionKey],
              [key]: value
            }
          }
        }

        return acc
      },
      {
        nav: {},
        lineitem: {},
        ticket: {},
        ticket__user_data: {},
        report: {},
        entity: {},
        location: {}
      } as Record<string, Record<string, string>>
    )
  })

  const handleSetFieldAlias = useCallback((sectionKey: string, key: string, value: string) => {
    setFieldAliases((draft) => {
      draft[sectionKey][key] = value
    })
  }, [])

  const handleRemoveFieldAlias = useCallback((sectionKey: string, key: string) => {
    setFieldAliases((draft) => {
      delete draft[sectionKey][key]
    })
  }, [])

  const handleSave = async () => {
    const values = await form.validateFields()
    let dataJsonObj = values.data_json ? safeJsonParse(values.data_json, {}) : {}
    const data = values.data || {}

    dataJsonObj = {
      ...dataJsonObj,
      ...data
    }

    await updateCompanyMutation.mutateAsync({
      id: company.id,
      data_json: JSON.stringify(dataJsonObj, null, 2),
      field_aliases: {
        ...fieldAliases.nav,
        ...fieldAliases.lineitem,
        ...fieldAliases.ticket,
        ...fieldAliases.report,
        ...fieldAliases.entity,
        ...fieldAliases.ticket__user_data,
        ...fieldAliases.location
      }
    })

    await companyQuery.refetch()
    refreshUserSession()

    notification.success({
      message: 'Company settings saved'
    })
  }

  return (
    <PageView
      header={{
        title: 'Advanced Settings',
        border: false,
        accent: false,
        actions: [
          <SaveButton
            key={'save-advanced-settings'}
            loading={updateCompanyMutation.isLoading}
            onSave={handleSave}
            showReturn={false}
          >
            Save Advanced Settings
          </SaveButton>
        ]
      }}
    >
      {!companyQuery.isLoading && (
        <Form
          form={form}
          className="pb-24"
          layout="vertical"
          initialValues={{
            data,
            data_json: dataJson
          }}
        >
          <div className="flex gap-x-24 mt-12 flex-wrap">
            <Form.Item name="field_aliases" hidden />
            <Form.Item name="data" hidden />
            <Form.Item name={['data', 'features', 'force_v2_tickets', 'enabled']} valuePropName="checked">
              <Checkbox>Force V2 Tickets and Loads</Checkbox>
            </Form.Item>
            <Form.Item name={['data', 'features', 'wiki', 'enabled']} valuePropName="checked">
              <Checkbox>Enable OilCommand Training</Checkbox>
            </Form.Item>
            <Form.Item name={['data', 'features', 'force_private_companies', 'enabled']} valuePropName="checked">
              <Checkbox>Force Private Companies</Checkbox>
            </Form.Item>
            <Form.Item name={['data', 'features', 'microsoft_openid_auth', 'enabled']} valuePropName="checked">
              <Checkbox>Enable Microsoft SSO Login</Checkbox>
            </Form.Item>
            <Form.Item name={['data', 'features', 'prevent_mobile_clear', 'enabled']} valuePropName="checked">
              <Checkbox>Prevent Property Clear (Mobile)</Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            name={['data', 'prefered_company_admin_user_id']}
            label="Prefered Company Admin User for Automations"
            tooltip="Used when running custom automations and in BPAs if 'Run As' is specified"
            className="max-w-[400px]"
          >
            <UserSelect apiQueryParams={{ 'Q[]': 'profile__user__groups__name__in|company_admin,support' }} />
          </Form.Item>
          <DataJsonCodeEditor />
          {Object.entries(sections).map(([key, { items, title }], index) => (
            <div key={index} className="border-b pt-10">
              <div className="mb-10 flex justify-between items-center">
                <h5 className="font-bold mb-0">{title}</h5>
                <AddLabelModal
                  title={title}
                  items={items}
                  selectedItems={fieldAliases[key] || {}}
                  onAdd={(selectedItems) => {
                    setFieldAliases((draft) => {
                      draft[key] = {
                        ...draft[key],
                        ...selectedItems
                      }
                    })
                  }}
                />
              </div>
              <div className="grid grid-cols-3 gap-x-24">
                {Object.entries(fieldAliases[key] || {}).map(([aliasKey, value]) => (
                  <AliasFormItem
                    key={aliasKey}
                    label={aliasKey}
                    value={value}
                    onChange={(value) => handleSetFieldAlias(key, aliasKey, value)}
                    onRemove={() => handleRemoveFieldAlias(key, aliasKey)}
                  />
                ))}
              </div>
            </div>
          ))}
        </Form>
      )}
    </PageView>
  )
}

type FormValues = {
  data_json: string
  data: Record<string, any>
}

const sections = {
  nav: {
    title: 'Navigation Labels',
    items: NAV_LABELS
  },
  lineitem: {
    title: 'Line Item Labels',
    items: LINE_ITEM_LABELS
  },
  ticket: {
    title: 'Ticket Labels',
    items: TICKET_LABELS
  },
  ticket__user_data: {
    title: 'Ticket Hidden References',
    items: TICKET_USER_DATA_LABELS
  },
  report: {
    title: 'Report Labels',
    items: REPORT_LABELS
  },
  entity: {
    title: 'Entity Labels',
    items: ENTITY_LABELS
  },
  location: {
    title: 'Location Labels',
    items: LOCATION_LABELS
  }
}
