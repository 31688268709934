import { mapName } from '@/modules/manage-user/utils/mapName'
import { Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { memo, useEffect, useState } from 'react'
import { useFetchProfile } from '../../hooks'
import { selectedRoleIdAtom, selectedRolesAtom } from '../atoms'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'

const AccessOtherOption = () => {
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const [options, setOptions] = useState<{ [key: string]: boolean } | null | undefined>()
  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)
  const [selectedData, setSelectedData] = useState<{ key: string; value: boolean } | undefined>(undefined)

  const [dtr, setDtr] = useState(false)

  const handleOkClick = () => {
    setSelectedRoleId(0)
    if (selectedRole) {
      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          ...profileData?.userdata_json,
          groups: profileData?.user?.groups,
          advance_settings: {
            ...profileData?.advance_settings,
            ...(selectedData && { [selectedData?.key]: selectedData?.value })
          }
        }
      })
    }
  }

  const checkPermission = () => {
    if (currentRoleId !== selectedRoleId && selectedRoleId !== 0) {
      setEnableRoleModalState(true)
      return false
      // eslint-disable-next-line no-extra-boolean-cast
    } else if (!!selectedRoleId) {
      setChangeRoleModalState(true)
      return false
    } else {
      return true
    }
  }
  const { data: profileData, refetch } = useFetchProfile()
  const selectedRoleId = useAtomValue(selectedRoleIdAtom)
  const currentRoleId = profileData?.role

  useEffect(() => {
    if (selectedRole && selectedRole.id === 0) {
      const hasDTR = selectedRole.data.groups?.includes('disable_ticket_reservation')
      if (!hasDTR) {
        setDtr(false)
      } else {
        setDtr(true)
      }
    }

    if (selectedRole && selectedRole.data.advance_settings === null && selectedRole.id === 0) {
      setOptions({ ignore_partial_download: false, use_template: false })
      if (selectedData) {
        setOptions({
          ...options,
          [selectedData?.key]: selectedData?.value
        })
      }
    } else {
      const hasUseTemplate =
        selectedRole &&
        selectedRole?.data.advance_settings &&
        Object.keys(selectedRole?.data.advance_settings).includes('use_template')

      const hasPartialDownloadIgnore =
        selectedRole &&
        selectedRole?.data.advance_settings &&
        Object.keys(selectedRole?.data.advance_settings).includes('ignore_partial_download')
      if (!hasUseTemplate && selectedRole?.id === 0) {
        setOptions({
          ...selectedRole?.data.advance_settings,
          ...options,
          use_template: false,
          ...(selectedData && { [selectedData?.key]: selectedData?.value })
        })
      } else if (!hasPartialDownloadIgnore) {
        setOptions({
          ...selectedRole?.data.advance_settings,
          ...options,
          ignore_partial_download: false,
          ...(selectedData && { [selectedData?.key]: selectedData?.value })
        })
      } else {
        setOptions({ ...selectedRole?.data.advance_settings })
      }
    }
  }, [selectedRole])

  const handleChange = (option: string, e: boolean) => {
    const pass = checkPermission()
    setSelectedData({ key: option, value: e })

    if (pass) {
      setOptions({ ...options, [option]: e })
      if (selectedRole) {
        setSelectedRole({
          ...selectedRole,
          data: {
            ...selectedRole.data,
            advance_settings: {
              ...selectedRole.data.advance_settings,
              [option]: e
            }
          }
        })
      }
    }
  }

  const handleDTRChange = (e: boolean) => {
    setDtr(e)
    let newGroups = selectedRole?.data?.groups ?? []

    if (e) {
      newGroups = [...newGroups, 'disable_ticket_reservation']
    } else {
      const filteredGroups = newGroups?.filter((ng) => ng !== 'disable_ticket_reservation')
      newGroups = filteredGroups
    }
    setSelectedRole({
      ...selectedRole,
      data: {
        ...selectedRole?.data,
        groups: newGroups
      }
    })
  }

  return (
    <>
      <div className="w-full lg:w-[50%]  xl:w-[25%]    mb-32">
        <AccessListItemHeader title="Other Options" />
        <div className="px-24 pt-32 border lg:border-none lg:border-r border-gray-200 h-full">
          {options &&
            Object.entries(options).map((option, i) => {
              return (
                <div className="w-full mb-24" key={i}>
                  <div className=" flex justify-between items-center w-full">
                    <label htmlFor={`other-option-${i}`}>{mapName(option[0])}</label>
                    <Switch checked={option[1]} id={`other-option-${i}`} onChange={(e) => handleChange(option[0], e)} />
                  </div>
                </div>
              )
            })}
          {selectedRole?.id === 0 && (
            <div className="w-full mb-24">
              <div className=" flex justify-between items-center w-full">
                <label htmlFor="other-option-disable_ticket_reservation">{mapName('disable_ticket_reservation')}</label>
                <Switch
                  checked={dtr}
                  id="other-option-disable_ticket_reservation"
                  onChange={(e) => handleDTRChange(e)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={setChangeRoleModalState}
        onOk={handleOkClick}
        onCancel={() => setChangeRoleModalState(false)}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={setEnableRoleModalState}
        onOk={() => setEnableRoleModalState(false)}
        onCancel={() => setEnableRoleModalState(false)}
      />
    </>
  )
}
export default memo(AccessOtherOption)
