import { useSession } from '@/hooks'
import { categoryTagApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Spin, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const SkuAccessColumn = () => {
  const { company } = useSession()

  const enabledCategoryTags =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.enabled_category_tags), [])) ?? []

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)
  const isCategoryTagEnabled = (categoryTagId: number) => enabledCategoryTags.includes(categoryTagId)

  const handleChange = (tagid: number, checked: boolean) => {
    let newEnabledCategoryTags: number[] = []

    if (isCategoryTagEnabled(tagid)) {
      newEnabledCategoryTags = enabledCategoryTags.filter((id: number) => id !== tagid)
    } else {
      if (checked) {
        newEnabledCategoryTags = [...enabledCategoryTags, tagid]
      }
    }

    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        enabled_category_tags: newEnabledCategoryTags
      }
    }))
  }

  const categoryTagsQuery = useQuery({
    ...categoryTagApi.list({
      fields: 'id,name',
      limit: 'None' as any,
      order: 'name',
      company_id__exact: company.id
    })
  })

  if (categoryTagsQuery.isLoading) {
    return <Spin spinning />
  }

  return (
    <div>
      {categoryTagsQuery.data?.items.map((categoryTag) => (
        <div key={categoryTag.id} className="flex w-full justify-between mb-20">
          <div>{categoryTag.name}</div>
          <Switch
            checked={isCategoryTagEnabled(categoryTag.id)}
            onChange={(checked) => handleChange(categoryTag.id, checked)}
          />
        </div>
      ))}
    </div>
  )
}
