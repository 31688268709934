import { useApp, useAppSelector } from '@/hooks'
import { RuleTemplate } from '@/schemas/rule-template'
import { jasScheduleRuleApi } from '@/services/api-service'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation } from '@tanstack/react-query'
import { DatePicker, Form, Select, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

type Props = {
  resource: JasResourceUseQueryResponse
  ruleTemplates: RuleTemplate[]
  number: number
  rule?: JasResourceUseQueryResponse['rules'][0]
  onCancel: () => void
  onAddNewRule?: () => void
}

export const AddOrEditForm = (props: Props) => {
  const { number, resource, rule, ruleTemplates, onAddNewRule = () => {}, onCancel = () => {} } = props
  const { notification } = useApp()
  const createMutation = useMutation(jasScheduleRuleApi.create())
  const updateMutation = useMutation(jasScheduleRuleApi.update())
  const { company } = useAppSelector((state) => state.session)

  const [newRule, setNewRule] = useState({
    start_date: rule ? rule.start_date : dayjs().format('YYYY-MM-DD'),
    end_date: rule ? rule.end_date : dayjs().format('YYYY-MM-DD'),
    ruleTemplate: rule
      ? {
          id: rule.template,
          name: rule.name
        }
      : null
  })

  const handleSaveNewRule = async () => {
    const mutation = rule ? updateMutation : createMutation
    const { start_date, end_date, ruleTemplate } = newRule

    if (!ruleTemplate) {
      notification.error({ message: 'Please select a rule template.' })
      return
    }

    if (!start_date || !end_date) {
      notification.error({ message: 'Please select a start and end date.' })
      return
    }

    if (dayjs(start_date).isAfter(end_date)) {
      notification.error({ message: 'Start date cannot be after end date.' })
      return
    }

    await mutation.mutateAsync({
      start_date,
      end_date,
      resource: resource.id,
      company: company.id,
      name: ruleTemplate.name,
      template: ruleTemplate.id,
      ...{ id: rule?.id } // If rule exists, then it's an update
    })

    setNewRule({
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      ruleTemplate: null
    })

    onAddNewRule()
  }

  const handleDateChange = (key: 'start_date' | 'end_date', value: Dayjs | null) => {
    if (!value) {
      console.warn('handleDateChange: value is null')
      return
    }

    setNewRule((prev) => ({ ...prev, [key]: value.format('YYYY-MM-DD') }))
  }

  const handleRuleTemplateChange = (value: { label: string; value: number }) => {
    setNewRule((prev) => ({
      ...prev,
      ruleTemplate: {
        id: value.value,
        name: value.label
      }
    }))
  }

  const handleCancelAddOrEdit = () => {
    setNewRule({
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      ruleTemplate: null
    })

    onCancel()
  }

  return (
    <Form layout="vertical">
      <div className="flex pl-20 pr-10 py-12 items-center justify-between border-0 border-b border-solid border-gray-300">
        <div>{number}</div>
        <div className="flex gap-x-12">
          <div>
            <label className="block text-sm mb-4">Start Date</label>
            <DatePicker
              value={dayjs(newRule.start_date)}
              format="MMMM D, YYYY"
              onChange={(value) => handleDateChange('start_date', value)}
            />
          </div>
          <div>
            <label className="block text-sm mb-4">End Date</label>
            <DatePicker
              format="MMMM D, YYYY"
              value={dayjs(newRule.end_date)}
              onChange={(value) => handleDateChange('end_date', value)}
              disabledDate={(current) => current.isBefore(dayjs(newRule.start_date))}
            />
          </div>
          <div>
            <label className="block text-sm mb-4">Schedule</label>
            <Select
              className="w-[120px]"
              popupMatchSelectWidth={false}
              onChange={(value) => handleRuleTemplateChange(value as { label: string; value: number })}
              value={
                newRule.ruleTemplate?.id
                  ? {
                      label: newRule.ruleTemplate?.name,
                      value: newRule.ruleTemplate?.id
                    }
                  : undefined
              }
              placeholder="Rule Template"
              labelInValue
              showSearch
              options={
                ruleTemplates.map((rule) => ({
                  label: rule.name,
                  value: rule.id
                })) || []
              }
            />
          </div>
        </div>
        <Space size="middle">
          <Button shape="circle" icon={<Icon name="mi:close" />} onClick={handleCancelAddOrEdit}></Button>
          <Button shape="circle" icon={<Icon name="mi:save" />} onClick={() => handleSaveNewRule()}></Button>
        </Space>
      </div>
    </Form>
  )
}
