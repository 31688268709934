import { useApp, useSession } from '@/hooks'
import { refreshQueries } from '@/query'
import { Button } from '@/ui/button'
import { Form } from 'antd'
import { useAtom } from 'jotai'
import { editingEquipmentAtom } from '../../atoms'
import {
  UpsertEquipmentPriceVariables,
  usePricingCustomerQuery,
  useUpdateEquipmentUsageRate,
  useUpsertEquipmentPrices
} from '../../hooks'
import { Equipment, FormValues } from '../../schemas'

type Props = {
  equipment: Equipment
}

export const EditCell = ({ equipment }: Props) => {
  const { notification } = useApp()
  const { company } = useSession()
  const { pricingCustomerQuery } = usePricingCustomerQuery()
  const [editingEquipment, setEditingEquipment] = useAtom(editingEquipmentAtom)
  const { upsertEquipmentPrices } = useUpsertEquipmentPrices()
  const { updateEquipmentUsageRate } = useUpdateEquipmentUsageRate()
  const form = Form.useFormInstance<FormValues>()

  const handleFinish = async () => {
    const { defaultUsageRate, ...values } = await form.validateFields()

    const items = Object.entries(values).map<UpsertEquipmentPriceVariables>(([key, value]) => {
      const priceObj = pricingCustomerQuery.data?.items
        .find((item) => item.customer_office === Number(key))
        ?.prices?.find((price) => price.equipment === equipment.id)

      return {
        customer_office: Number(key),
        equipment: equipment.id,
        usage_rate: value.usageRate,
        company: company.id,
        ...(priceObj && { id: priceObj.id })
      }
    })

    await Promise.all([
      upsertEquipmentPrices(items),
      updateEquipmentUsageRate(equipment.id, defaultUsageRate.usageRate)
    ])
      .then(() => {
        notification.success({
          message: 'Price Saved',
          description: 'Price has been saved'
        })
      })
      .catch(() => {
        notification.error({
          message: 'Prices Error',
          description: 'Failed to save prices'
        })
      })

    form.resetFields()
    setEditingEquipment(null)

    refreshQueries(['equipments', 'pricing_customers', 'equipment_prices'])
  }

  if (editingEquipment && editingEquipment.id === equipment.id) {
    return (
      <div className={'flex'}>
        <Button iconName="mi:check" type="text" onClick={handleFinish} />
        <Button iconName="mi:close" type="text" onClick={() => setEditingEquipment(null)} />
      </div>
    )
  }

  return (
    <Button
      iconName="fa:edit"
      type="text"
      onClick={() => {
        setEditingEquipment(equipment)
      }}
    />
  )
}
