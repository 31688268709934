export const SUMMARY_ATTACHMENT_TYPE_SELECT = [
  {
    label: 'Zip File - Tickets',
    value: 'Z'
  },
  {
    label: 'PDF File - Tickets',
    value: 'B'
  },
  {
    label: 'Zip File - Summary & Tickets',
    value: 'Z1'
  },
  {
    label: 'PDF File - Summary & Tickets',
    value: 'B1'
  },
  {
    label: 'Disable',
    // Why? Cause the default behavior is Z.
    value: 'none'
  }
]
