import { Icon, Select } from '@/ui'
import { Form } from 'antd'
import { useEffect } from 'react'
import { usePropertiesQuery } from '../../hooks'

export const PropertySelect = () => {
  const { propertiesQuery, properties } = usePropertiesQuery()
  const form = Form.useFormInstance()
  const selectedPropertyId = Form.useWatch('id')

  console.log('selectedPropertyId', selectedPropertyId)

  const options =
    properties.map((property) => ({
      label: (
        <div className="flex justify-start pr-8">
          <div>
            {`${property.property_group__custom_form__name} / ${property.property_group__name} / `}
            <span className="font-semibold mr-8">{property.name}</span>
            {property.link && <Icon name="fa:link-horizontal" />}
          </div>
        </div>
      ),
      value: property.id
    })) ?? []

  // set default value if there is no selected property
  useEffect(() => {
    if (!properties.length) {
      return
    }

    if (selectedPropertyId) {
      return
    }

    form.setFieldsValue({
      id: properties[0].id,
      link: properties[0].link
    })
  }, [properties, form, selectedPropertyId])

  return (
    <Form.Item label="Property" name="id">
      <Select
        className="min-w-[200px]"
        placeholder="Select"
        popupMatchSelectWidth={false}
        options={options}
        loading={propertiesQuery.isLoading}
        disabled={propertiesQuery.isLoading}
        onChange={(value) => {
          const selectedProperty = properties.find((property) => property.id === value)

          form.setFieldsValue({
            showLinkProperty: false,
            link: selectedProperty?.link
          })

          return value
        }}
      />
    </Form.Item>
  )
}
