import { Form, Select } from 'antd'

type Props = {
  selectedFormat: string
  selectedEncoding: string
  setSelectedEncoding: (value: string) => void
}

export const EncodingSelect = ({ selectedEncoding, selectedFormat, setSelectedEncoding }: Props) => {
  if (selectedFormat !== 'csv') {
    return null
  }

  return (
    <Form.Item label="Encoding">
      <Select
        onChange={setSelectedEncoding}
        value={selectedEncoding}
        options={[
          {
            label: 'UTF-16 (recommended for Excel)',
            value: 'utf-16'
          },
          {
            label: 'UTF-8',
            value: 'utf-8'
          },
          {
            label: 'UTF-8 w/ BOM (legacy)',
            value: 'utf-8-bom'
          }
        ]}
      />
    </Form.Item>
  )
}
