import { LINE_ITEM_FIELDS, UNIT_NAME } from '@/constants/general'
import { FIELD_LABELS } from '@/constants/labels'
import { useAppSelector } from '@/hooks'
import { DatePicker, Form, Input, InputNumber, Select } from 'antd'
import dayjs from 'dayjs'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom } from '../atoms'
import { useCategoryKindQuery } from '../hooks'

type FieldItem = (typeof LINE_ITEM_FIELDS)[number]

type Props = {
  fieldItem?: FieldItem
  onChange?: (value: any) => void
  Component?: any
}

const fieldsWithCurrency: FieldItem['field'][] = ['standby_rate', 'usage_rate', 'cost']
const fieldsWithQuantity: FieldItem['field'][] = ['units_standby', 'units_min']
const disabledFields: FieldItem['field'][] = ['discountable_amount', 'taxable_amount']

export const FormItem = ({ fieldItem, onChange, ...props }: Props) => {
  const setEquipment = useSetAtom(equipmentAtom)
  const equipmentUnit = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.unit), [])) || ''
  const { company } = useAppSelector((state) => state.session)
  const companyFieldAliases = JSON.parse(company.field_aliases) ?? {}
  const subCategory = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.category), []))
  const { categoryKindQuery } = useCategoryKindQuery(subCategory?.kind)
  const categoryKindFieldAliases = categoryKindQuery.data?.field_aliases

  const value = useAtomValue(
    useMemo(
      () => selectAtom(equipmentAtom, (equipment) => equipment[fieldItem?.field as keyof typeof equipment]),

      [fieldItem?.field]
    )
  ) as any

  const handleChange = (value: string | number | null | undefined) => {
    if (value === undefined) value = null
    if (onChange) {
      onChange(value)
    } else {
      if (!fieldItem) {
        console.warn(`Trying to set value ${value} to undefined field item`)
        return
      }

      setEquipment((prev) => ({
        ...prev,
        [fieldItem.field]: value
      }))
    }
  }

  if (!fieldItem) {
    console.warn(`Field item ${fieldItem} not found in LINE_ITEM_FIELDS`)
    return null
  }

  const { component, field, label } = fieldItem
  const lineItemLabel = FIELD_LABELS[`lineitem__${field}`] || null
  const companyAssignedLabel = companyFieldAliases[`lineitem__${field}`] || null
  const categoryKindLabel = categoryKindFieldAliases?.[field] || null
  const labelWithCurrency = fieldsWithCurrency.includes(field) ? `${label} ($${equipmentUnit})` : null

  const labelWithQuantity =
    fieldsWithQuantity.includes(field) && equipmentUnit
      ? `${UNIT_NAME[equipmentUnit as keyof typeof UNIT_NAME]} ${label}`
      : null

  const disabled = disabledFields.includes(field)
  const options = 'options' in fieldItem && fieldItem.options ? fieldItem.options : []

  return (
    <Form.Item
      key={field}
      label={
        labelWithCurrency || labelWithQuantity || categoryKindLabel || companyAssignedLabel || lineItemLabel || label
      }
    >
      {(() => {
        if (props.Component) {
          return props.Component
        }

        if (component === 'DatePicker') {
          return (
            <DatePicker
              value={value ? dayjs(value) : null}
              showTime={{
                minuteStep: 5,
                format: 'hh:mm A' // TODO: Use constant time format, but constant is not working with DayJS
              }}
              showNow={false}
              format="MM/DD/YYYY hh:mm A" // TODO: Use constant date/time format, but constant is not working with DayJS
              className="w-full"
              disabled={disabled}
              onOk={(date) => handleChange(date ? date.toISOString() : null)}
              data-cy={`sku-${field}-date-picker`}
            />
          )
        }

        if (component === 'Input') {
          return (
            <Input
              value={value}
              disabled={disabled}
              onChange={(e) => handleChange(e.target.value)}
              data-cy={`sku-${field}-input`}
            />
          )
        }

        if (component === 'NumberInput') {
          return (
            <InputNumber
              value={value}
              className="w-full"
              disabled={disabled}
              onChange={(value) => handleChange(value)}
              type="number"
              data-cy={`sku-${field}-number-input`}
              onWheel={(e) => e.currentTarget.blur()}
            />
          )
        }

        if (component === 'TextArea') {
          return (
            <Input.TextArea
              value={value}
              disabled={disabled}
              onChange={(e) => handleChange(e.target.value)}
              rows={1}
              data-cy={`sku-${field}-textarea`}
            />
          )
        }

        if (component === 'Select') {
          return (
            <Select
              showSearch
              allowClear
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={options}
              value={value}
              onChange={(value) => handleChange(value)}
              disabled={disabled}
              data-cy={`sku-${field}-select`}
            />
          )
        }

        return null
      })()}
    </Form.Item>
  )
}
