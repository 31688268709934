import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { AddRuleTemplate } from './add-rule-template'
import { Preview } from './preview'
import { RuleDisplay } from './rule-display'
import { RulesList } from './rules-list'

export const RuleTemplatesView = view(ViewComponent, { name: 'RuleTemplatesView', title: () => 'Schedule Rules' })

/** Components */

function ViewComponent() {
  return (
    <PageView
      header={{
        accent: false,
        border: false,
        title: 'Schedule Rules',
        backButton: false
        // actions: [<StatusFormItem key={'status'} />, <SaveResourceButton key="save" />]
      }}
    >
      <div className="flex h-full gap-x-12">
        <AddRuleTemplate />
        <RulesList />
        <RuleDisplay />
        <Preview />
      </div>
    </PageView>
  )
}
