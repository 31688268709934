import { formatIdentifier } from '@/utils/formatters'
import { Input, InputProps } from 'antd'
import { ChangeEvent, useCallback, useState } from 'react'

export type IdentifierInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
  strip?: boolean
}

export function IdentifierInput({
  value,
  defaultValue,
  onChange,
  onBlur,
  strip = true,
  ...inputProps
}: IdentifierInputProps) {
  const [cleanValue, setCleanValue] = useState(value || defaultValue)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = formatIdentifier(e.target.value)
      setCleanValue(value)
      onChange?.(value, e)
    },
    [onChange]
  )

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (strip) {
        const value = formatIdentifier(e.target.value, { strip: true })
        setCleanValue(value)
        onChange?.(value, e)
      }
      onBlur?.(e)
    },
    [onBlur, onChange, strip]
  )

  return <Input {...inputProps} value={cleanValue} onChange={handleChange} onBlur={handleBlur} />
}
