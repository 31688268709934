import { useSession } from '@/hooks'
import { moduleApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'

export const useModulesQuery = () => {
  const { company } = useSession()

  const modulesQuery = useQuery({
    ...moduleApi.list<{ id: number; title: string; position: number }>(
      {
        company: company.id,
        fields: 'id,title,position',
        deleted_at__isnull: true,
        limit: 'None' as any
      },
      {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }
    )
  })

  return { modulesQuery }
}
