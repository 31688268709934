import { useApp } from '@/hooks'
import { Button } from '@/ui'
import { useButtonContext } from '../../hooks'

export const SetStatusButton = () => {
  const { notification } = useApp()
  const { ticket, vars, buttonProps, property, isSaving, saveTicket } = useButtonContext()
  const { status, properties_keys_values } = vars

  const handleClick = async () => {
    if (!status) {
      notification.error({ message: 'Status is not provided' })
    }

    const payload: Record<string, string> = {
      status
    }

    if (properties_keys_values && properties_keys_values.length) {
      properties_keys_values.forEach(({ p_key, value }: any) => {
        const property = ticket._tpByPropertyKey[p_key]

        if (!property) {
          console.error(`Property not found for key: ${p_key}`)
        }

        payload[`p_${property.property_id}`] = value
      })
    }

    await saveTicket(payload)
    notification.success({ message: 'Ticket saved' })
  }

  return (
    <Button {...buttonProps} loading={isSaving} onClick={handleClick}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
