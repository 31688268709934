import { DisplayField } from '@/modules/table-view/helpers'
import { SaveButton } from '@/modules/ticket/form/components/buttons/save-button'
import { PropertyFormItem } from '@/modules/ticket/form/components/properties'
import { useTicketLoader } from '@/modules/ticket/form/hooks'
import { FormLoader } from '@/modules/ticket/form/views/form-view/form-loader'
import { TicketInputByKey } from '@/modules/ticket/list/constants'
import { Button } from '@/ui'
import { Spin } from '@/ui/spin'
import { Alert, Input } from 'antd'
import cn from 'classnames'
import { FC } from 'react'

type Props = {
  field: DisplayField
  record: any
  onSave?: () => void
  onCancel?: () => void
}

export const InputCell: FC<Props> = ({ field, record, onSave, onCancel }) => {
  const { ticketQuery, apiTicket, apiCustomForm } = useTicketLoader(record.id, record.custom_form_id, true, {
    modifiedCheck: false
  })

  const isLoaded = !!apiTicket && !!apiCustomForm && apiCustomForm?.id === apiTicket?.custom_form_id
  const isError = ticketQuery.isError

  const FieldInput = TicketInputByKey[field.key]

  return (
    <Spin size={'small'} spinning={!isLoaded}>
      {FieldInput || field.isProperty ? (
        <div>
          {isError && <Alert showIcon type={'error'} message={'Fetch Error'} />}
          {isLoaded ? (
            <FormLoader apiCustomForm={apiCustomForm} apiTicket={apiTicket}>
              <div className={'relative'}>
                {field.isProperty ? (
                  <PropertyFormItem
                    propertyId={field.propertyId}
                    propertyKey={field.propertyKey}
                    fallback={<Alert showIcon type={'warning'} message={'Property Not Found'} />}
                    {...{ label: null, className: 'mb-0' }}
                  />
                ) : (
                  <FieldInput formItemProps={{ label: null, className: 'mb-0' }} />
                )}

                <div className={'absolute -top-16 right-2'}>
                  <SaveButton
                    options={{ refresh: ['tickets'] }}
                    type={'default'}
                    onSave={onSave}
                    size={'small'}
                    iconName={'fa:check'}
                    shape={'circle'}
                    className={cn('transition-all scale-[0.85]')}
                  />
                  <Button
                    onClick={onCancel}
                    size={'small'}
                    iconName={'fa:close'}
                    shape={'circle'}
                    className={cn('transition-all scale-[0.85]')}
                  />
                </div>
              </div>
            </FormLoader>
          ) : (
            <Input readOnly />
          )}
        </div>
      ) : (
        <Alert showIcon type={'error'} message={'Unknown Field'} />
      )}
    </Spin>
  )
}
