import { CodeEditor } from '@/components/code-editor'
import { useCustomFormLabels } from '@/modules/custom-form/hooks'
import { customFormApiV2, propertyApi } from '@/services/api-service'
import { Button, Icon, IdentifierInput, QuerySelect, Select, Sortable } from '@/ui'
import { TQueryField } from '@/ui/data/query-builder'
import { formatIdentifier } from '@/utils/formatters'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useQuery } from '@tanstack/react-query'
import { Dropdown, Form } from 'antd'
import cn from 'classnames'
import React, { FC, useMemo, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { z } from 'zod'
import { BpaEtlExtractDataAction } from '../../schemas'
import { EXTRACT_LINE_ITEM_FIELDS, EXTRACT_TICKET_FIELDS, FIELDS_GROUP_MAP } from './constants'

type Props = {
  action: BpaEtlExtractDataAction
  onChange: (data: Partial<BpaEtlExtractDataAction>) => void
}

export const InputEtlExtractDataAction: FC<Props> = ({ action, onChange }) => {
  const config = action?.data || {}

  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col gap-10">
      <div className={'flex flex-row gap-10 items-center'}>
        <Form.Item label="Source" className={'flex-grow'} required>
          <Select
            placeholder={'Required'}
            value={config.source}
            options={[
              { value: 'ticket', label: 'Ticket' },
              { value: 'line_item', label: 'Line Item' }
            ]}
            onChange={(value) => onChange({ data: { ...action.data, source: value, fields: [] } })}
          />
        </Form.Item>

        <Form.Item label="Forms" className={'flex-grow'}>
          <QuerySelect
            value={config.forms || []}
            onChange={(value) => onChange({ data: { ...action.data, forms: value } })}
            mode={'multiple'}
            apiEndpoint={customFormApiV2.list}
            apiQueryParams={{ fields: 'id,name' }}
            apiSearchBy="name"
            apiValueBy="id"
            renderOption={(cf) => ({ value: cf.id, label: cf.name })}
          />
        </Form.Item>
      </div>
      <div className={'flex flex-row gap-10'}>
        <Form.Item label="Output Name" className={'flex-grow'} required>
          <IdentifierInput
            placeholder={'Required'}
            defaultValue={config.out_name || ''}
            onChange={(value) => onChangeDebounced({ data: { ...action.data, out_name: value } })}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Filter (Text)" className={'flex-grow'} tooltip={'e.g. location__name__icontains|test'}>
          <CodeEditor
            minLines={5}
            maxLines={25}
            defaultValue={config.filter_str || ''}
            onChange={(value) => onChangeDebounced({ data: { ...action.data, filter_str: value } })}
            mode="text"
          />
        </Form.Item>
      </div>
      <div className={'flex flex-col gap-10'}>
        <div>Fields</div>
        <FieldsList action={action} onChange={onChange} />
        <FieldsSelect action={action} onChange={onChange} />
      </div>
    </div>
  )
}
export const InputEtlExtractDataActionMemo = React.memo(InputEtlExtractDataAction)

function FieldRow({
  field,
  editable,
  onChange,
  onTypeChange
}: {
  field: TQueryField
  editable?: boolean
  onChange?: (value: string) => void
  onTypeChange?: (value: string) => void
}) {
  const fieldAs = field.as || field.field

  const onChangeDebounced = useDebouncedCallback((value) => onChange?.(value), 250)

  return (
    <div className={'flex flex-col gap-6'}>
      {editable ? (
        <IdentifierInput
          placeholder={'Column Name (Required)'}
          defaultValue={fieldAs}
          variant={'filled'}
          onChange={onChangeDebounced}
        />
      ) : (
        <div>{fieldAs}</div>
      )}

      <div
        className={
          'flex-wrap px-2 flex flex-row gap-4 [&>span]:px-10 [&>span]:text-[12px] [&>span]:border [&>span]:rounded text-text-muted'
        }
      >
        <span title={'Field Key'}>{field.label}</span> <span title={'Field Key'}>{field.field}</span>
        <Dropdown
          menu={{
            items: ['string', 'integer', 'date', 'datetime'].map((t) => ({
              key: t,
              label: t,
              className: field.data_type === t ? 'bg-primary-100' : '',
              onClick: () => onTypeChange?.(t)
            }))
          }}
          trigger={['click']}
        >
          <span title={'Data Type'} className={'cursor-pointer'}>
            {field.data_type}
          </span>
        </Dropdown>
        <span title={'Group'}>{FIELDS_GROUP_MAP[field.group || ''].label}</span>
      </div>
    </div>
  )
}

function FieldsList({ action, onChange }: Props) {
  const config = action?.data || {}
  const fields = config.fields

  const onSortingChange = useCallback(
    (sortedFields: any) => {
      onChange({ data: { ...action.data, fields: sortedFields } })
    },
    [action.data, onChange]
  )

  const handleRemoveField = useCallback(
    (fieldIndex: number) => {
      onChange({ data: { ...action.data, fields: (fields || []).filter((_, idx) => idx !== fieldIndex) } })
    },
    [action.data, fields, onChange]
  )

  const handleAsChange = useCallback(
    (fieldIndex: number, value: string) => {
      const newFields = (fields || []).map((f, idx) => (idx === fieldIndex ? { ...f, as: value } : f))
      onChange({ data: { ...action.data, fields: newFields } })
    },
    [action.data, fields, onChange]
  )

  const handleTypeChange = useCallback(
    (fieldIndex: number, value: string) => {
      const newFields = (fields || []).map((f, idx) => (idx === fieldIndex ? { ...f, data_type: value } : f))
      onChange({ data: { ...action.data, fields: newFields } })
    },
    [action.data, fields, onChange]
  )

  if (!fields?.length) return null

  return (
    <div className={'flex flex-col gap-6 h-max-[250px] overflow-y-auto'}>
      <Sortable
        items={fields || []}
        by={'field'}
        onChange={onSortingChange}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        render={(f: TQueryField, fieldIndex, sortable) => (
          <div
            key={f.field}
            className={cn('bg-container flex flex-row gap-6 items-center border px-10 py-4 rounded', {
              'border-dashed !border-primary-500': sortable?.isDragging
            })}
            ref={sortable?.setNodeRef}
            style={sortable?.style}
            {...sortable?.attributes}
          >
            <Button
              {...sortable?.listeners}
              type="text"
              iconName="fa:grip-dots-vertical"
              size="small"
              className="cursor-grab"
              data-cy="action-drag-handle"
            />

            <div className="flex-grow">
              <FieldRow
                field={f}
                editable={true}
                onChange={(value) => handleAsChange(fieldIndex, value)}
                onTypeChange={(value) => handleTypeChange(fieldIndex, value)}
              />
            </div>

            <div>
              <Button
                size={'small'}
                type={'text'}
                shape={'circle'}
                danger
                iconName={'fa:close'}
                onClick={() => handleRemoveField(fieldIndex)}
              />
            </div>
          </div>
        )}
      />
    </div>
  )
}

function FieldsSelect({ action, onChange }: Props) {
  const config = action?.data || {}
  const formIds = config.forms || null

  // always use the first form for labels
  const { l } = useCustomFormLabels((formIds || [])[0])

  const selectedFieldsKeys = useMemo(() => (config.fields || []).map((f) => f.field), [config.fields])

  const propertiesQuery = useQuery({
    ...propertyApi.list({
      limit: 'None',
      property_group__custom_form_id__in: (formIds || [])?.join(','),
      fields: z.object({
        id: z.number(),
        key: z.string(),
        name: z.string(),
        property_type__name: z.string(),
        property_group__custom_form__name: z.string()
      })
    }),
    enabled: !!formIds
  })

  const properties: TQueryField[] = useMemo(
    () =>
      (propertiesQuery.data?.items || []).map((prop) => ({
        field: `property__${prop.id}`, // todo: support merged keys
        label: (formIds || []).length > 1 ? `${prop.property_group__custom_form__name} - ${prop.name}` : prop.name,
        data_type: PROPERTY_TYPE_DATA_TYPE_MAP[prop.property_type__name] || 'string',
        group: 'P'
      })),
    [formIds, propertiesQuery.data?.items]
  )

  const baseFields = useMemo(() => {
    let baseFields: TQueryField[] = []

    if (config.source === 'ticket') {
      baseFields = [
        ...EXTRACT_TICKET_FIELDS.map((f) => ({
          ...f,
          label: l(f.label_key as any, f.label || f.field),
          group: 'T'
        })),
        ...properties
      ]
    } else if (config.source === 'line_item') {
      baseFields = [
        ...EXTRACT_LINE_ITEM_FIELDS.map((f) => ({
          ...f,
          label: l(f.label_key as any, f.label || f.field),
          group: 'L'
        })),
        ...EXTRACT_TICKET_FIELDS.map((f) => ({
          ...f,
          field: `ticket__${f.field}`,
          label: 'Ticket ' + l(f.label_key as any, f.label || f.field),
          group: 'T'
        })),
        ...properties.map((f) => ({ ...f, field: `ticket__${f.field}` }))
      ]
    }

    return baseFields.map((f) => ({ ...f, as: formatIdentifier(f.label, { strip: true }) }))
  }, [config.source, l, properties])

  const fields = useMemo(
    () => baseFields.filter((f) => !selectedFieldsKeys.includes(f.field)),
    [baseFields, selectedFieldsKeys]
  )

  const selectOptions = useMemo(
    () =>
      fields.map((f) => ({
        as: formatIdentifier(f.label, { strip: true }),
        value: f.field,
        text: `${f.label} ${f.field} ${f.data_type} ${FIELDS_GROUP_MAP[f.group || ''].label}`.toLowerCase(),
        label: <FieldRow field={f} />,
        field: f
      })),
    [fields]
  )

  const handleAddField = (_: any, option: any) => {
    onChange({
      data: { ...action.data, fields: [...(action.data.fields || []), option.field] }
    })
  }

  return (
    <Select
      className={'w-full'}
      value={null}
      options={selectOptions}
      onChange={handleAddField}
      placeholder={
        <div className={'flex flex-row gap-10 items-center'}>
          <Icon name={'fa:plus'} />
          Add Field
        </div>
      }
    />
  )
}

const PROPERTY_TYPE_DATA_TYPE_MAP: Record<string, string> = {
  Date: 'date',
  DateTime: 'datetime',
  Integer: 'integer',
  Decimal: 'decimal'
  // all other types are string
}
