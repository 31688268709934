import cn from 'classnames'
import { ButtonConfig } from '../../../schemas'

export const getAlignClassName = (align: ButtonConfig['align'] = 'center') => {
  return cn('w-full flex flex-row', {
    'justify-center': align === 'center',
    'justify-end': align === 'right',
    'justify-start': align === 'left',
    'flex-col': align === 'stretch'
  })
}
