import { useSession } from '@/hooks'
import { PT } from '@/modules/custom-form/constants'
import { useAtomValue } from 'jotai'
import { isEqual } from 'lodash'
import { useCallback, useEffect } from 'react'
import { unSavedValuesAtom } from '../atoms'
import { useCustomForm } from './use-custom-form'
import { useScriptEngine } from './use-script-engine'
import { useTicketFormValues } from './use-ticket-form'
import { useTicketObject } from './use-ticket-object'

export const useTicketScript = () => {
  const { user } = useSession()
  const { engine } = useScriptEngine()
  const { ticketObject } = useTicketObject()

  /**
   * Evaluate script and return the result
   * Important!
   * Only use for frontend-specific scripts
   */
  const evaluateScript = useCallback(
    (script: string, context?: any) => {
      try {
        return engine.evaluate(script, ticketObject, user, context)
      } catch (e) {
        console.error('Error Evaluating Script', e)
        return null
      }
    },
    [engine, ticketObject, user]
  )

  /**
   * Evaluate an array of scripts and return the results
   * Important!
   * Only use for frontend-specific scripts
   */
  const evaluateScripts = useCallback(
    (scripts: string[], context?: any) => scripts.map((script) => evaluateScript(script, context)),
    [evaluateScript]
  )

  return { evaluateScript, evaluateScripts }
}

/**
 * Watch for changes in ticket scripts and re-evaluate them
 * This is used to update the form values based on the script.
 * todo: disabled for now (we should rely on backend to handle this, for the shake of consistency)
 * @see PropertyGroups.tsx for usage
 */
export const useTicketScriptWatch = () => {
  const unSavedValues = useAtomValue(unSavedValuesAtom)

  const { getPropertyValueById, setFieldValue } = useTicketFormValues()
  const { customForm } = useCustomForm()
  const { evaluateScripts } = useTicketScript()

  useEffect(() => {
    customForm.properties
      .filter((p) => p.property_type_id === PT.Script)
      .forEach((p) => {
        const oldValue = getPropertyValueById(p.id)
        const newValue = evaluateScripts([p.script || ''])

        if (!isEqual(oldValue, newValue)) {
          setFieldValue(p._field, newValue)
        }
      })
  }, [customForm.properties, evaluateScripts, getPropertyValueById, setFieldValue, unSavedValues])
}
