import { useBlocker } from '@/hooks'
import { PageView } from '@/layouts/views'
import { Form } from 'antd'
import cn from 'classnames'
import { useAtomValue } from 'jotai'
import { FC, ReactElement, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { hasUnSavedValuesAtom } from '../atoms'
import { useTicket, useTicketForm, useTicketFormValues } from '../hooks'
import { MetaButtons, SectionButtons } from './buttons'
import { FooterRibbon } from './footer-ribbon'
import { FooterTabs } from './footer-tabs'
import { TicketActions } from './heading/ticket-actions'
import { TicketInfo } from './heading/ticket-info'
import { TicketTitle } from './heading/ticket-title'
import { CustomerContactInput, CustomerOfficeInput, MetaFields, StatusInput } from './meta-fields'
import { PropertyGroups } from './properties'
import { RaisedAlerts } from './raised-alerts'
import { RecentActivity } from './recent-activity'

type Props = {
  children?: ReactElement
}

export const TicketForm: FC<Props> = ({ children }) => {
  const { ticketForm } = useTicketForm()
  const { trackChanges } = useTicketFormValues()

  const handleValuesChange = useDebouncedCallback((changedValues, allValues) => {
    if (changedValues['lineItems'] || changedValues['inLineItems']) return // skip; handled by line items

    trackChanges()
  }, 250)

  return (
    <Form form={ticketForm} validateTrigger={'onBlur'} layout="vertical" onValuesChange={handleValuesChange}>
      <TicketFormInner>{children}</TicketFormInner>
    </Form>
  )
}

const TicketFormInner: FC<Props> = ({ children }) => {
  const hasUnSavedValues = useAtomValue(hasUnSavedValuesAtom)
  const { ticket, isNew } = useTicket()
  const { loadTicket } = useTicketFormValues()

  // load ticket to form values
  useEffect(() => {
    loadTicket(ticket, false)

    // note: only watch for ticket and form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  // block leaving with unsaved values
  useBlocker({ when: hasUnSavedValues, message: 'You have unsaved changes. Are you sure you want to leave?' })

  if (children) return children

  return (
    <PageView
      className={'overflow-x-hidden'}
      header={{
        breadcrumbs: true,
        backButton: true,
        title: <TicketTitle />,
        actions: <TicketActions />
      }}
    >
      <div className={'flex flex-col gap-20'}>
        {/* Ticket Top Input */}
        <section
          className={cn(
            'section-top-input flex-row gap-14 rounded [&>div]:w-4/12 [&>div]:m-0 p-10 pb-14 flex',
            // /* variant 1 */ 'bg-gray-50 border border-primary-100',
            /* variant 2 */ 'border border-[color-mix(in_srgb,var(--ant-color-primary)_5%,transparent)] bg-[color-mix(in_srgb,var(--ant-color-primary)_5%,transparent)]',
            // /* variant 3 */ 'bg-[color-mix(in_srgb,var(--ant-color-primary)_10%,transparent)] shadow-[0px_0px_100px_color-mix(in_srgb,var(--ant-color-primary)_40%,transparent)]',
            // /* variant 4 */ 'border border-gray-100',
            'dark:border dark:bg-transparent'
          )}
        >
          <CustomerOfficeInput />
          <CustomerContactInput />
          <StatusInput />
        </section>

        {/* Ticket Info and Fields */}
        <section className="section-info-fields flex flex-col md:flex-row p-10 gap-10">
          <div className="w-full md:w-8/12">
            <TicketInfo />
          </div>
          <div className={'w-full md:w-4/12 flex-col'}>
            <MetaButtons />
            <MetaFields />
            <RaisedAlerts />
          </div>
        </section>

        {/* Ticket Property Groups */}
        <section className="section-property-groups">
          <PropertyGroups />
        </section>

        <section className={'section-buttons'}>
          <SectionButtons />
        </section>

        {/* Ticket Tabs */}
        <section className="section-tabs">{!isNew && <FooterTabs />}</section>

        {/* Ticket Activities */}
        <section className="section-activities">{!isNew && <RecentActivity />}</section>
      </div>

      {/* Ticket Footer Ribbon */}
      <section className={'section-footer-ribbon sticky bottom-0 left-0'}>
        <FooterRibbon />
      </section>
    </PageView>
  )
}
