import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { ErrorView, TabsView } from '@/layouts/views'
import { useCanEditCustomFormCallback } from '@/modules/auth/hooks'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { customFormApiV2, eventApi } from '@/services/api-service'
import { BackButton } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { Outlet, useParams } from 'react-router-dom'
import { SETTINGS_PATHS } from '../../constants'
import { useCustomFormQuery } from './hooks'
import { PageTitle } from './page-title'
import { PageWrapper } from './page-wrapper'
import { SaveCustomFormButton } from './save-custom-form-button'

export const CustomFormAddEditView = view<any, any>(Component, {
  loader: async ({ params }) => {
    const customFormId = params.id
    if (!customFormId) return { title: 'Add Custom Form' }

    const customForm = await fetchQuery(
      customFormApiV2.get<any>(Number(customFormId), undefined, { fields: 'id,name' })
    )
    return { title: `Edit ${customForm.name || 'Custom Form'}` }
  },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const { id } = useParams()
  const canEditCustomForm = useCanEditCustomFormCallback()

  const { customFormQuery } = useCustomFormQuery()
  const eventsQuery = useQuery({ ...eventApi.list({ type: 'customform', target: id }), enabled: !!id })

  if (
    !!id &&
    !customFormQuery.isFetching &&
    !canEditCustomForm({ primary_company_id: customFormQuery.data?.primary_company })
  ) {
    return <ErrorView code={403} description="You don't have permissions to edit this page" showHeader></ErrorView>
  }

  return (
    <TabsView
      items={[
        { to: 'title', label: 'Title' },
        { to: 'settings', label: 'Settings' },
        { to: 'advanced', label: 'Advanced' },
        { to: 'access_and_alerts', label: 'Access & Alerts' },
        { to: 'styles', label: 'Styles' },
        { to: 'default_team_members', label: 'Default Team Members' },
        { to: 'email', label: 'Email' },
        { to: 'line_items', label: 'Line Items' }
      ]}
      header={{
        breadcrumbs: true,
        title: <PageTitle />,
        backButton: <BackButton to={SETTINGS_PATHS.customForms} />,
        actions: [<SaveCustomFormButton key={'save-custom-form-button'} />]
      }}
    >
      <PageWrapper>
        <Form layout="vertical" component="div">
          <Outlet />
        </Form>
      </PageWrapper>
      {id && (
        <div className="mt-24">
          <h5 className="font-bold">Recent Activity</h5>
          <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'customform', target: id }} />
          <div className="mt-16">
            <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isInitialLoading} />
          </div>
        </div>
      )}
    </TabsView>
  )
}
