import { useApp } from '@/hooks'
import { refreshQueries } from '@/modules/ticket/form/helpers'
import { useTicketSave } from '@/modules/ticket/form/hooks'
import { lineItemApi } from '@/services/api-service'
import { dig } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { FC, useCallback } from 'react'
import { LineItem, LineItemComponent } from '../../../../../../schemas'
import { useComponentKind, useEditingComponents } from '../../hooks'
import { TicketLineItemsForm } from '../../types'

type Props = { item: Partial<LineItem>; components: LineItemComponent[] }

export const SaveButton: FC<Props> = ({ item, components }) => {
  const form = Form.useFormInstance()

  const { notification } = useApp()

  const { closeEdit } = useEditingComponents()
  const { saveTicket } = useTicketSave()

  const componentKind = useComponentKind(components[0] || item)

  const patchMutation = useMutation(lineItemApi.patch(undefined, undefined, false))
  const deleteMutation = useMutation(lineItemApi.delete(undefined, undefined, false))
  const isLoading = patchMutation.isLoading || deleteMutation.isLoading

  const saveComponents = useCallback(async () => {
    const formData = (await form.validateFields([['lineItems', item.id?.toString()] as any])) || {}
    const data: TicketLineItemsForm = dig(formData, `lineItems.${item.id}`) || {}

    const components = data.components || {}
    const componentsOrder = data.componentsOrder || []
    const deletedIds = data.deletedComponentIds || []

    const patchPayload: any[] = []
    const deletePayload: any[] = []

    Object.entries(components).forEach(([cId, obj]) => {
      if (deletedIds.includes(Number(cId))) {
        deletePayload.push({ id: Number(cId) })
        return
      }

      const newIndex = componentsOrder.indexOf(Number(cId))
      const sequence = newIndex !== -1 ? newIndex + 1 : undefined
      const values = componentKind.visibleFields.reduce((acc, f) => {
        if (f.editable) acc[f.field] = dig(obj, f.field)
        return acc
      }, {} as any)

      patchPayload.push({ ...values, sku_designation: 'S', id: Number(cId), sequence })
    })

    const showComponents = data.showComponents || false
    if (data.showComponents !== item.show_components) {
      patchPayload.push({ id: item.id, show_components: showComponents })
    }

    await Promise.all(patchPayload.map((payload) => patchMutation.mutateAsync(payload)))
    await Promise.all(deletePayload.map((payload) => deleteMutation.mutateAsync(payload)))

    notification.success({
      message: 'Components Saved',
      description: 'Components have been saved successfully.'
    })

    refreshQueries(['line_items'])
    saveTicket()
    closeEdit(item.id as number)
  }, [
    closeEdit,
    componentKind.fieldsConfig,
    deleteMutation,
    form,
    item.id,
    item.show_components,
    notification,
    patchMutation,
    saveTicket
  ])

  return (
    <Button size={'small'} type={'primary'} shape={'round'} loading={isLoading} onClick={saveComponents}>
      Save
    </Button>
  )
}
