import { lineItemApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

export const useItemDelete = () => {
  const deleteMutation = useMutation(lineItemApi.delete())

  const deleteLineItem = useCallback(
    async (lineItemId: number) => {
      return deleteMutation.mutateAsync(lineItemId)
    },
    [deleteMutation]
  )

  return {
    deleteMutation,
    isDeleting: deleteMutation.isLoading,
    deleteLineItem
  }
}
