import { Form, Select } from 'antd'

export const GroupTabsSelect = () => {
  return (
    <Form.Item label="Group Tabs" name={['data', 'group_tabs']}>
      <Select
        placeholder="Select"
        mode="multiple"
        options={Object.entries(timeGroupTabs).map(([value, label]) => ({ value, label }))}
        optionFilterProp="label"
      />
    </Form.Item>
  )
}

const timeGroupTabs = {
  job: 'Jobs',
  employee: 'Employees',
  job_type: 'Job Types',
  day_off: 'Day Off',
  day_on: 'Day On'
} as const
