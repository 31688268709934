import { CustomName } from './custom-name'
import { ExcludeMobileApi } from './exclude-mobile-api'
import { Hide } from './hide'
import { HideOnNew } from './hide-on-new'
import { HidePrint } from './hide-print'
import { IndentInlineComponents } from './indent-inline-components'
import { NumberFormat } from './number-format'
import { Readonly } from './readonly'

export const GeneralSettings = () => {
  return (
    <div>
      <div className="flex">
        <div className="mb-10 flex flex-col gap-y-12 w-1/2">
          <Hide />
          <ExcludeMobileApi />
          <HidePrint />
          <HideOnNew />
          <Readonly />
        </div>
        <div className="mb-16 flex flex-col gap-y-12 w-1/2">
          <IndentInlineComponents />
        </div>
      </div>
      <CustomName />
      <NumberFormat />
    </div>
  )
}
