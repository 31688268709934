import { tableViewApiV2 } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { transformTableView } from './helpers'
import { TableView } from './types'

const TableViewSchema = z.object({
  id: z.number(),
  type: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  selected_fields: z.string().nullish(),
  filters: z.string().nullish(),
  sort_1: z.string().nullish(),
  sort_2: z.string().nullish(),
  hide_ticket_links: z.boolean(),
  merge_fields: z.boolean(),
  config_json: z.string().nullish(),
  company_id: z.number().nullish(),
  company__name: z.string().nullish(),
  options__custom_forms: z.string().nullish(),
  user_id: z.number()
})

export const useTableViewsQuery = (params?: ListParams) => {
  params = {
    ...{
      fields: TableViewSchema,
      limit: 100,
      order: '-name'
    },
    ...params
  }

  return useQuery({
    ...tableViewApiV2.list<TableView>(params),
    select: (data) => ({ ...data, items: data.items.map(transformTableView) })
  })
}
