import { Input } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { ExampleButton } from './ExampleButton'
import { VarsType } from './types'

export const ExampleButtonConfig: ScriptedButtonConfig<VarsType> = {
  type: 'common_example',
  iconName: 'fa:heart',
  label: 'Example',
  group: 'Common',
  render: () => <ExampleButton />,
  vars: [
    {
      name: 'var1',
      label: 'Variable 1',
      rules: [{ required: true, message: 'Variable 1 is required' }],
      render: (customForm, props) => <Input {...props} />
    },
    {
      name: 'var2',
      label: 'Variable 2',
      render: (customForm, props) => <Input {...props} />
    }
  ]
}
