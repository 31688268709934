import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { eventApi } from '@/services/api-service'
import { Event } from '@/types/event'

type Props = {
  type: string
  target: number
  events?: Event[]
  isLoading?: boolean
}

export const RecentActivitySection = ({ type, target, events, isLoading }: Props) => {
  return (
    <div className="mt-30">
      <h5 className="font-bold">Recent Activity</h5>
      <ActivityLogsModal query={eventApi.list} queryVariables={{ type, target }} />
      <div className="mt-16">
        <RecentActivities events={events} isLoading={isLoading} />
      </div>
    </div>
  )
}
