import { atom } from 'jotai'
import { DetailedEquipment } from './schema'

const initialEquipment: DetailedEquipment = {
  id: 0,
  sku: '',
  category: {
    id: 0,
    name: '',
    parent_category: null,
    company: 0
  },
  description: '',
  unit: '',
  usage_rate: null
}

export const equipmentAtom = atom<DetailedEquipment>(initialEquipment)

export const initialEquipmentAtom = atom<DetailedEquipment>(initialEquipment)

export const setCategoryAtom = atom(null, (_, set, category?: DetailedEquipment['category']) => {
  set(equipmentAtom, (prev) => ({
    ...prev,
    category: prev.category ? { ...prev.category, parent_category: category } : null
  }))
})

export const setSubCategoryAtom = atom(null, (_, set, category?: DetailedEquipment['category']) => {
  set(equipmentAtom, (prev) => ({ ...prev, category }))
})

export const isUpdateDefaultLiModalOpenAtom = atom(false)

/**
 * This is the atom that will hold the errors for the required fields.
 * Not using Antd Form validation because of the complexity of the component and
 * some form items coming dynamically from API.
 */
export const requiredFieldsErrorsAtom = atom({
  isSkuError: false,
  isDescriptionError: false,
  isCategoryError: false,
  isSubcategoryError: false
})
