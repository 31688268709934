import { TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { Checkbox, Form, Input, List, Modal, Space } from 'antd'
import { useMemo, useState } from 'react'
import { useCustomFormQuery, useUpdateCustomFormMutation } from '../hooks'
import { PlaceholderBox } from '../placeholder-box'

const STATUS_HIDE = '--hide--'

export const EditTicketStatusModal = () => {
  const { customFormQuery } = useCustomFormQuery()
  const { updateCustomFormMutation } = useUpdateCustomFormMutation()
  const [isOpen, setIsOpen] = useState(false)
  const [form] = Form.useForm<Record<string, string | undefined>>()
  const { notification } = useApp()
  const visibilityWatch = Form.useWatch([], form)
  const userdata = JSON.parse(customFormQuery.data?.userdata_json ?? '{}')

  const dataSource = useMemo(
    () =>
      Object.entries(TICKET_STATUS).map(([key, value]) => ({
        key,
        value
      })),
    []
  )

  const initialValues = useMemo(() => {
    if (!customFormQuery.data) {
      return {}
    }

    if (!userdata.custom_status) {
      return {}
    }

    return Object.entries(userdata.custom_status).reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {} as any)
  }, [customFormQuery.data, userdata])

  const handleSave = async () => {
    const values = await form.validateFields()

    userdata.custom_status = userdata.custom_status || {}
    Object.entries(values).forEach(([key, value]) => {
      // unset if value is empty string
      userdata.custom_status[key] = (value || '').trim() || undefined
    })

    await updateCustomFormMutation.mutateAsync({
      id: customFormQuery.data?.id ?? '',
      userdata_json: JSON.stringify(userdata)
    })

    notification.success({
      message: 'Custom status updated'
    })

    setIsOpen(false)
  }

  return (
    <>
      <PlaceholderBox onClick={() => setIsOpen(true)}>Status</PlaceholderBox>
      <Modal
        title="Edit Ticket Status"
        open={isOpen}
        okText="Save"
        onCancel={() => setIsOpen(false)}
        okButtonProps={{ loading: updateCustomFormMutation.isLoading }}
        onOk={handleSave}
        styles={{
          body: {
            maxHeight: 'calc(100vh - 276px)',
            overflowY: 'auto'
          }
        }}
      >
        <Form initialValues={initialValues} form={form}>
          <Space>
            <Form.Item name="_readonly" valuePropName="checked">
              <Checkbox>Read-only (web)</Checkbox>
            </Form.Item>
            <Form.Item name="_hidden" valuePropName="checked">
              <Checkbox>Hidden (web)</Checkbox>
            </Form.Item>
          </Space>
          <List
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item>
                <Form.Item
                  name={item.key}
                  label={item.value}
                  className="mb-0 w-full"
                  shouldUpdate={true}
                  wrapperCol={{ className: '!flex-initial ml-auto' }}
                >
                  {visibilityWatch[item.key] === STATUS_HIDE ? (
                    <Input className="w-[300px]" disabled />
                  ) : (
                    <Input className="w-[300px]" />
                  )}
                </Form.Item>
                <Button
                  className="!ml-6"
                  iconName={visibilityWatch[item.key] === STATUS_HIDE ? 'mi:visibility_off' : 'mi:visibility'}
                  type="text"
                  onClick={() => {
                    form.setFieldsValue({
                      [item.key]: visibilityWatch[item.key] === STATUS_HIDE ? '' : STATUS_HIDE
                    })
                  }}
                />
              </List.Item>
            )}
          />
        </Form>
      </Modal>
    </>
  )
}
