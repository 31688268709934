import { alertSchema } from '@/modules/settings/custom-forms/custom-form-add-edit-view/schema'
import { Alert } from '@/modules/settings/custom-forms/custom-form-add-edit-view/types'
import { alertApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { SetOptional } from 'type-fest'
import { z } from 'zod'
import { customFormAtom } from '../atoms'

type Payload = SetOptional<z.input<typeof alertSchema>, 'id' | 'companies'> & {
  company_ids: number[]
}

export const useUpsertAlerts = () => {
  const alerts = useAtomValue(useMemo(() => selectAtom(customFormAtom, (customForm) => customForm.alerts), []))

  const createAlertMutation = useMutation({
    ...alertApi.create<Alert, Payload>()
  })

  const updateAlertMutation = useMutation({
    ...alertApi.update<Alert, Payload>()
  })

  const upsertAlerts = async () => {
    alerts?.map((alert) => {
      const company_ids = alert.companies?.map((company) => company.id) ?? []

      const ticket_statuses = alert.ticket_statuses?.join(',')

      if (alert.id) {
        return updateAlertMutation.mutateAsync({
          ...alert,
          company_ids,
          ticket_statuses
        })
      }

      return createAlertMutation.mutateAsync({
        ...alert,
        company_ids,
        ticket_statuses
      })
    })

    await Promise.all([createAlertMutation, updateAlertMutation])
  }

  return {
    upsertAlerts
  }
}
