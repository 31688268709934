import { Checkbox, Form, Input } from 'antd'
import { uniq } from 'lodash'
import { usePropertyTypesQuery } from '../../hooks'

export const DropdownFields = () => {
  const form = Form.useFormInstance()
  const fieldsWatch = Form.useWatch([], form)
  const { getTypeNameById } = usePropertyTypesQuery()
  const propertyTypeId = fieldsWatch?.property_type?.id
  const type = getTypeNameById(propertyTypeId)

  if (type !== 'Dropdown' && type !== 'MultivariateDropdown') {
    return null
  }

  const { is_managed, use_binding } = fieldsWatch || {}

  return (
    <div className="mt-20">
      <Form.Item className="mb-10" name="searchable" valuePropName="checked">
        <Checkbox data-cy="searchable-checkbox">Searchable</Checkbox>
      </Form.Item>
      {!is_managed && !use_binding && (type === 'Dropdown' || type === 'MultivariateDropdown') && (
        <Form.Item
          label="Values"
          name="values"
          rules={[
            {
              validator: (_, value: string) => {
                if (!value) {
                  return Promise.resolve()
                }

                const values = value.split('\n').map((string) => string.split(',')[1])
                const hasDuplicates = uniq(values).length !== values.length

                if (!hasDuplicates) {
                  return Promise.resolve()
                }

                return Promise.reject('Values contain duplicate value')
              }
            }
          ]}
        >
          <Input.TextArea data-cy="values-textarea" autoSize />
        </Form.Item>
      )}
    </div>
  )
}
