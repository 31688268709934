import { parseCsv, safeJsonParse } from '@/utils'
import { isEmpty } from 'lodash'
import { JobRequirementsByRole, JobRequirementsByRoleSchema, JobRequirementsByType, JobRole } from '../schemas'

export const parseSkuIds = (skuIdsStr: string): number[] => {
  return parseCsv(skuIdsStr).map((s) => Number(s.trim()))
}

export const parseRequirementsByType = (input: string): JobRequirementsByType => {
  if (isEmpty(input)) return { Employee: 0, Equipment: 0 }

  const employeesMatch = input.match(/Employees:\s*(\d+)/i)
  const equipmentsMatch = input.match(/Equipment:\s*(\d+)/i)

  return {
    Employee: employeesMatch ? parseInt(employeesMatch[1], 10) : 0,
    Equipment: equipmentsMatch ? parseInt(equipmentsMatch[1], 10) : 0
  }
}

export const parseRequirementsByRole = (input: string): JobRequirementsByRole => {
  if (isEmpty(input)) return null

  try {
    return JobRequirementsByRoleSchema.parse(safeJsonParse(input))
  } catch (error) {
    return null
  }
}

export const parseRequirements = (
  input: string,
  jobRolesMap: Record<number, JobRole>
): { byType: JobRequirementsByType; byRole: JobRequirementsByRole } => {
  const byRole = parseRequirementsByRole(input)
  let byType = { Employee: 0, Equipment: 0 }

  if (isEmpty(byRole)) {
    byType = parseRequirementsByType(input)
  } else {
    byRole?.forEach((req) => {
      const role = jobRolesMap[req.role]
      if (role?.resource_type === 'Employee') byType.Employee += req.required || 0
      else if (role?.resource_type === 'Equipment') byType.Equipment += req.required || 0
    })
  }

  return { byType, byRole }
}

export const parseAttributes = (skillsStr: string): string[] => {
  return parseCsv(skillsStr).map((s) => s.trim())
}
