import { useApp } from '@/hooks'
import { Button } from '@/ui'
import { getIconNameByUrl, getUrlFileName, mediaUrl } from '@/utils'
import { saveBlob } from '@/utils/blob'
import dayjs from 'dayjs'
import { FC, useCallback } from 'react'
import { TicketAttachment } from '../../../../../schemas'
import { Card } from '../../../../ui'
import { CropImageButton } from '../crop-image-button/CropImageButton'
import { DeleteAttachmentButton } from '../delete-attachment-button/DeleteAttachmentButton'

export const AttachmentCard: FC<{ attachment: TicketAttachment; disabled?: boolean }> = ({ attachment, disabled }) => {
  const { notification } = useApp()

  const handleDownload = useCallback(async () => {
    const url = mediaUrl(attachment?.image || '')
    const name = getUrlFileName(url, 'jpg')
    if (!url || !name) return

    try {
      await saveBlob(url, attachment._fileName)
    } catch (error) {
      notification.error({
        message: 'Attachment Download Failed',
        description: 'An error occurred while downloading attachment'
      })
    }
  }, [attachment._fileName, attachment.image, notification])

  const handleCrop = useCallback(async () => {
    console.log('ggg')
  }, [attachment._fileName, attachment.image, notification])

  return (
    <>
      <Card
        key={attachment.id}
        src={mediaUrl(attachment.image)}
        shape={'square'}
        icon={getIconNameByUrl(attachment.image)}
        title={attachment._fileName}
        description={dayjs.formatLocal(attachment.created_at)}
        actions={
          <>
            <CropImageButton onCrop={handleCrop} attachment={attachment} />
            <Button
              onClick={handleDownload}
              size={'small'}
              shape={'circle'}
              type={'text'}
              iconName={'fa:cloud-download'}
              primary
            />
            <DeleteAttachmentButton disabled={disabled} attachment={attachment} />
          </>
        }
      />
    </>
  )
}
