import { useApp } from '@/hooks'
import { Button } from '@/ui'
import { FormInstance } from 'antd'
import { useAtomValue } from 'jotai'
import { ValidateErrorEntity } from 'rc-field-form/es/interface'
import { FC, useState } from 'react'
import { useCustomFormQuery } from '../../../hooks'
import { usePropertyTypesQuery, useUpsertPropertyMutation } from '../../hooks'
import { bpaConfigAtom } from '../bpa-builder/atoms'
import { FormValues } from './types'

type Props = {
  property: FormValues
  form: FormInstance<any>
  onSave?: () => void
}

export const SaveProperty: FC<Props> = ({ property, form, onSave }) => {
  const { notification } = useApp()
  const [isUpsertLoading, setIsUpsertLoading] = useState(false)
  const { handleUpsertProperty } = useUpsertPropertyMutation()
  const { customFormQuery } = useCustomFormQuery()

  const { getTypeNameById } = usePropertyTypesQuery()
  const typeName = getTypeNameById(property?.property_type?.id || property?.property_type?.id)
  const isAutomation = typeName === 'Automation'

  const bpaConfig = useAtomValue(bpaConfigAtom)

  const handleSubmit = async () => {
    setIsUpsertLoading(true)

    try {
      const property = await form.validateFields()

      if (isAutomation) {
        property.bpa_config = bpaConfig
      }

      // merge skip and layout to comma separated values
      if (property.skip) {
        property.layout = `${property.layout},${property.skip}`
      }

      // prevent saving if key is empty
      if (!property.key) {
        notification.error({
          message: 'Error',
          description: 'Property key is required'
        })
        return
      }

      await handleUpsertProperty(property)
      await customFormQuery.refetch()

      if (property.id) {
        notification.success({
          message: 'Success',
          description: 'Property updated successfully'
        })
      } else {
        notification.success({
          message: 'Success',
          description: 'Property created successfully'
        })
      }

      onSave?.()
    } catch (e: any) {
      if ('errorFields' in e) {
        // antd form uses rc-field-form library in its form validation
        const errorInfo = e as ValidateErrorEntity
        errorInfo.errorFields.forEach(({ errors }) => {
          errors.forEach((error) => {
            notification.error({
              message: 'Error saving property',
              description: error
            })
          })
        })
      } else {
        notification.error({
          message: 'Error saving property',
          description: e?.toString()
        })
      }
    } finally {
      setIsUpsertLoading(false)
    }
  }

  return (
    <Button
      type="primary"
      form="edit-property-form"
      htmlType="button"
      loading={isUpsertLoading}
      onClick={handleSubmit}
      data-cy="save-property-button"
    >
      Save
    </Button>
  )
}
