import { refreshQueries } from '@/query'
import { ApiResource, MutationReturn, Params, RequestConfig } from '@/types/api/core'
import { R, createResourceApi, getPayloadId } from '../core'

export type BackgroundJobApi = ApiResource & {
  abort: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _backgroundJobApi: BackgroundJobApi = {
  ...createResourceApi('v2/background_jobs'),
  abort: (params, config) => ({
    mutationFn: (data: { id: number }) =>
      R('POST', `/v2/background_jobs/${getPayloadId(data)}/abort/`, { params, data, ...config }),
    onSettled: () => refreshQueries(['v2/background_jobs'])
  })
}
