import { TransformationTask } from '@/types/transformation-task'
import { Button, Icon } from '@/ui'
import { cn } from '@/utils'
import { Popconfirm, Space } from 'antd'
import { useSetAtom } from 'jotai'
import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { currentEditingTransformTaskAtom } from '../../../../atoms'

type Props = {
  transformationTask: TransformationTask
  isDragging?: boolean
  onDeleteClick?: (id: number) => void
  onEditClick?: (transformationTask: TransformationTask) => void
} & Omit<ComponentPropsWithoutRef<'div'>, 'id'>

export const Item = forwardRef<HTMLDivElement, Props>(
  ({ transformationTask, isDragging, onDeleteClick = () => {}, onEditClick = () => {}, ...props }, ref) => {
    const { id, name } = transformationTask
    const setCurrentEditingTransformTask = useSetAtom(currentEditingTransformTaskAtom)

    return (
      <div ref={ref} {...props}>
        <div
          className={cn('p-6 rounded border flex items-center font-medium', {
            'cursor-grabbing bg-primary-50 border-primary-500': isDragging,
            'cursor-grab bg-white border-gray-200 ': !isDragging
          })}
        >
          <Icon name="mi:drag_indicator" className="text-20" />
          <span className="ml-4">{name}</span>
          <Space className="ml-auto" size="small">
            <Button type="text" iconName="fa:pen" data-no-dnd onClick={() => onEditClick(transformationTask)} />
            <Popconfirm
              title="Delete"
              okText="Yes"
              okButtonProps={{ ['data-no-dnd']: 'true' }}
              cancelButtonProps={{ ['data-no-dnd']: 'true' }}
              description="Are you sure you want to delete?"
              onConfirm={() => onDeleteClick(id)}
            >
              <Button type="text" iconName="fa:trash" data-no-dnd />
            </Popconfirm>
          </Space>
        </div>
      </div>
    )
  }
)

Item.displayName = 'Item'
