import { CodeEditor } from '@/components/code-editor'
import { Form, Input } from 'antd'
import { SCRIPT_FIELDS } from '../../constants'
import { usePropertyTypesQuery } from '../../hooks'

export const ScriptStub = () => {
  const form = Form.useFormInstance()
  const fieldsWatch = Form.useWatch([], form)
  const { getTypeNameById } = usePropertyTypesQuery()
  const propertyTypeId = fieldsWatch?.property_type?.id
  const type = getTypeNameById(propertyTypeId)

  if (type && SCRIPT_FIELDS.includes(type) && (fieldsWatch?.is_managed || fieldsWatch?.use_binding)) {
    return (
      <div className="mt-20">
        <Form.Item label="Script Stub" name="script_stub">
          <CodeEditor data-cy="script-stub-code-editor" mode="javascript" minLines={5} maxLines={Infinity} isInModal />
        </Form.Item>
        <Form.Item name="option_value_key" label="Option Value Property Key">
          <Input data-cy="option-value-key-input" />
        </Form.Item>
      </div>
    )
  }

  return null
}
