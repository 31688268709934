import { Select } from '@/ui'
import { InputNumber } from 'antd'
import cn from 'classnames'
import { FC, useCallback, useMemo } from 'react'
import { breakdownMeasurement, getMeasurementValue } from '../helpers'

type Props = {
  value?: string
  onChange?: (value: string) => void
  showQuarter?: boolean
  disabled?: boolean
}

export const MeasurementInput: FC<Props> = ({ value, onChange, showQuarter, disabled }) => {
  const numberValue = Number(value) || 0

  const { feet, inches, quarter, display } = useMemo(
    () => breakdownMeasurement(numberValue, showQuarter),
    [numberValue, showQuarter]
  )

  const handleChange = useCallback(
    (feet: number, inches: number, quarter: number) => {
      onChange?.(getMeasurementValue(feet, inches, quarter))
    },
    [onChange]
  )

  return (
    <Select
      showSearch={false}
      allowClear={false}
      isOptionAvailable={true}
      className={'w-full'}
      popupClassName={cn('py-8 px-10 min-w-[250px]', { '!w-[310px]': showQuarter })}
      value={display}
      disabled={disabled}
      dropdownRender={() => (
        <div>
          <div className={'flex flex-row items-center gap-6 flex-1 [&>label]:w-full [&>label>div]:w-full'}>
            <label>
              <span className={'line-clamp-1'}>Feet</span>
              <InputNumber value={feet} min={0} onChange={(v) => handleChange(v || 0, inches, quarter)} />
            </label>
            <label>
              <span className={'line-clamp-1'}>Inches</span>
              <InputNumber value={inches} min={0} onChange={(v) => handleChange(feet, v || 0, quarter)} />
            </label>
            {showQuarter && (
              <label>
                <span className={'line-clamp-1'}>1/4 Inches</span>
                <InputNumber value={quarter} min={0} onChange={(v) => handleChange(feet, inches, v || 0)} />
              </label>
            )}
          </div>
          <div className={'text-center mt-10'}>
            <code className={'text-sm text-primary-500 inline-block border border-gray-100 rounded px-10 py-2'}>
              {numberValue} Inches
            </code>
          </div>
        </div>
      )}
    />
  )
}
