import { useApp } from '@/hooks'
import { attachmentApi } from '@/services/api-service'
import { Button, ButtonProps, Tooltip } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Upload } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'
import { FC, useCallback } from 'react'
import { refreshQueries } from '../../../../../helpers'
import { useTicket } from '../../../../../hooks'

type Props = ButtonProps & {
  afterUpload?: (attachment: { id: number; uuid: string }) => void
}

export const AddAttachmentButton: FC<Props> = ({ afterUpload, children, disabled, ...props }) => {
  const { notification } = useApp()
  const { ticket } = useTicket()

  const isDisabled = !ticket.id || disabled

  const { mutateAsync, isLoading } = useMutation(
    attachmentApi.create({ headers: { 'Content-Type': 'multipart/form-data' } })
  )
  const addAttachment = useCallback(
    async (info: UploadChangeParam<UploadFile<any>>) => {
      const formData = new FormData()
      formData.append('ticket', ticket.id.toString())
      formData.append('image', info.file as any)
      formData.append('name', info.file.name)

      try {
        const attachment = await mutateAsync(formData)

        if (afterUpload) {
          afterUpload?.(attachment)
        } else {
          await refreshQueries()
        }

        notification.success({
          message: 'Attachment Added',
          description: (
            <span>
              <b>{info.file.name}</b> added successfully
            </span>
          )
        })
      } catch (error) {
        notification.error({
          message: 'Attachment Upload Failed',
          description: 'An error occurred while uploading attachment'
        })
      }
    },
    [afterUpload, mutateAsync, notification, ticket.id]
  )

  return (
    <Upload showUploadList={false} onChange={addAttachment} beforeUpload={() => false}>
      <Tooltip title={!isDisabled ? '' : 'Will be available after ticket saving'}>
        <Button
          loading={isLoading}
          shape={'round'}
          iconName={'fa:cloud-upload'}
          primary
          {...props}
          disabled={isDisabled}
        >
          {children || 'Add Attachment'}
        </Button>
      </Tooltip>
    </Upload>
  )
}
