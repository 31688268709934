import { ApiResource, MutationReturn, Params, RequestConfig } from '@/types/api/core'
import { R, createResourceApi } from '../core'

export type AttachmentApi = ApiResource & {
  crop: <TData = any>(id?: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
  reset: <TData = any>(id?: number | null, params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _attachmentApi: AttachmentApi = {
  ...createResourceApi('attachments'),
  crop: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('PUT', `/attachments/${id}/crop/`, { params, data, ...config })
  }),
  reset: (id, params, config) => ({
    enabled: !!id,
    mutationFn: (data: any) => R('PUT', `/attachments/${id}/reset_crop/`, { params, data, ...config })
  })
}
