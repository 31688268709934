import { useApp, useFormWatch } from '@/hooks'
import { Button } from '@/ui'
import { getIconNameByUrl, getUrlFileName } from '@/utils'
import { saveBlob } from '@/utils/blob'
import { Form } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { FC, useCallback, useEffect } from 'react'
import { Card } from '../../../../../../ui'
import { ReportFile, ReportLog } from '../../../types'

type Props = {
  reportLog: ReportLog
  file: ReportFile
}

export const FileCard: FC<Props> = ({ reportLog, file }) => {
  const { notification } = useApp()
  const form = Form.useFormInstance()

  const generating = useFormWatch(['generating']) || {}
  const isRequested = !!generating[reportLog.id]
  const isPending = reportLog.status === 'P' || reportLog.status === 'I'
  const isError = reportLog.status === 'F' || reportLog.status === 'A'
  const isSuccess = isRequested && (reportLog.status === 'S' || reportLog.status === 'C')

  useEffect(() => {
    if (isRequested && isSuccess) {
      form.setFieldValue(['generating'], {})

      notification.success({
        message: 'Report Generated',
        description: 'Report has been generated successfully'
      })
    }
  })

  const handleDownload = useCallback(async () => {
    const name = getUrlFileName(file.url, 'xlsx')
    if (!name) return

    try {
      await saveBlob(file.url, name)
    } catch (error) {
      notification.error({
        message: 'Report Download Failed',
        description: 'An error occurred while downloading report'
      })
    }
  }, [file.url, notification])

  return (
    <Card
      key={file.id}
      shape={'square'}
      icon={getIconNameByUrl(file.name)}
      title={getUrlFileName(file.name)}
      className={cn({
        'bg-gray-100': isPending,
        'bg-danger-100 border-danger-200': isError,
        'bg-success-100 border-success-200': isSuccess
      })}
      description={
        <span>
          <span>{dayjs.formatLocal(file.created_at)}</span>
          <span>
            - {reportLog.created_by?.first_name} {reportLog.created_by?.last_name}
          </span>
        </span>
      }
      actions={
        <>
          <Button
            loading={isPending}
            onClick={handleDownload}
            size={'small'}
            shape={'circle'}
            type={'text'}
            iconName={isError ? 'fa:triangle-exclamation' : 'fa:cloud-download'}
            iconClassName={isError ? 'text-danger-500' : 'text-primary-500'}
            disabled={isError}
            primary
          />
        </>
      }
    />
  )
}
