import { atom } from 'jotai'
import { PatchChanges } from './schemas'

/**
 * State for the schedule rule patch changes.
 * Structure: resource_id -> date -> type
 * Example:
 * {
 *   "1": {
 *     "2024-12-12": "W",
 *     "2024-12-13": "W",
 *   }
 * }
 */
export const patchChangesAtom = atom<PatchChanges>({})
