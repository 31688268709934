import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useSetAtom } from 'jotai'
import { customFormAtom } from '../../../../atoms'
import { Alert, Company } from '../../../../types'

type Props = {
  alert: Alert
  company: Company
  disabled?: boolean
}

export const NotificationCheckbox = ({ alert, company, disabled }: Props) => {
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked

    setCustomForm((prev) => {
      const newAlerts = prev.alerts?.map((a) => {
        if (a.id === alert.id) {
          return {
            ...a,
            companies: checked ? [...(a.companies ?? []), company] : a.companies?.filter(({ id }) => id !== company.id)
          }
        }
        return a
      })

      return {
        ...prev,
        alerts: newAlerts
      }
    })
  }

  return (
    <Checkbox
      disabled={disabled}
      checked={alert.companies?.some(({ id }) => company.id === id)}
      onChange={handleChange}
      data-cy="notification-checkbox"
    />
  )
}
