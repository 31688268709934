import { EquipmentStats } from '@/types/equipment-stats'
import { orderBy } from 'lodash'

export const getEquipmentStats = (id: number, equipmentStats: EquipmentStats[]) => {
  const stats = equipmentStats.find((item) => item.equipment === id)

  if (!stats) {
    return null
  }

  const transactions = orderBy(stats.transactions, 'timepoint', 'desc')
  const latest = transactions[0]
  return latest
}
