type FieldLabel = {
  [key: string]: string
}

export const ENTITY_LABELS = {
  AFE: 'AFE',
  AFEs: 'AFEs',
  Asset: 'Asset',
  Assets: 'Assets',
  Category: 'Category',
  Categories: 'Categories',
  CategoryKind: 'Configuration',
  CategoryKinds: 'Configurations',
  ChoicesManager: 'Choices Manager',
  CustomRecord: 'Custom Record',
  CustomRecords: 'Custom Records',
  Contact: 'Contact',
  Contacts: 'Contacts',
  Company: 'Company',
  Companies: 'Companies',
  Office: 'Office',
  Offices: 'Offices',
  Import: 'Import',
  Job: 'Job',
  Jobs: 'Jobs',
  JobCode: 'Job Code',
  JobCodes: 'Job Codes',
  JobTypes: 'Job Types',
  JobType: 'Job Type',
  Loads: 'Loads',
  Location: 'Location',
  Locations: 'Locations',
  Manager: 'Manager',
  Producer: 'Producer',
  Region: 'Region',
  Regions: 'Regions',
  Rig: 'Rig',
  Rigs: 'Rigs',
  Pricing: 'Pricing',
  SKU: 'SKU',
  SKUs: 'SKUs',
  Ticket: 'Ticket',
  Tickets: 'Tickets',
  LineItem: 'Line Item',
  LineItems: 'Line Items',
  TableView: 'Ticket View',
  TableViews: 'Ticket Views',
  CustomerNameReconciliation: 'Customer Name Reconciliation',
  EtlEditorTitle: 'Programmed Report Logic Editor',
  InventoryGraph: 'Inventory Graph',
  QbInputs: 'QuickBooks',
  CustomReports: 'Custom Reports',
  CustomReport: 'Custom Report',
  ScheduledReports: 'Scheduled Reports',
  ScheduledReport: 'Scheduled Report',
  ProgrammedReports: 'Programmed Reports'
}

export const NAV_LABELS = {
  nav__account_settings: 'Settings',
  nav__ast_custom_forms: 'Custom Forms',
  nav__ast_import: 'Import Export',
  nav__ast_integrations: 'Integrations',
  nav__ast_js_lib: 'JS Library',
  nav__ast_secrets: 'Secrets Manager',
  nav__ast_programmed_reports: 'Programmed Reports',
  nav__ast_stages: 'Ticket Stages',
  nav__ast_notifications: 'Notifications',
  nav__ast_notification_templates: 'Notification Templates',
  nav__ast_notifications_activity: 'Notifications Activity',
  nav__choices_manager: 'Choices Manager',
  nav__companies: 'Companies',
  nav__contacts: 'Contacts',
  nav__custom_records: 'Custom Records',
  nav__customers: 'Customers',
  nav__dashboard: 'Dashboard',
  nav__home: 'Home',
  nav__inventory: 'Inventory',
  nav__jobs: 'Jobs',
  nav__jobs__job_codes: 'Job Codes',
  nav__jobs__job_types: 'Job Types',
  nav__jobs__view_jobs: 'View Jobs',
  nav__line_items: 'Line Items',
  nav__loads: 'Load Board',
  nav__module_builder: 'Module Builder',
  nav__my_time: 'My Time',
  nav__private_companies: 'Private Companies',
  nav__qb_inputs: 'QuickBooks',
  nav__records: 'Records',
  nav__records__afes: 'AFEs',
  nav__records__assets: 'Assets',
  nav__records__categories: 'Categories',
  nav__records__import: 'Import',
  nav__records__itemconf: 'Configurations',
  nav__records__locations: 'Locations',
  nav__records__manager: 'Manager',
  nav__records__pricing: 'Pricing',
  nav__records__rigs: 'Rigs',
  nav__records__skus: 'SKUs',
  nav__reporting: 'Reporting',
  nav__reports_custom: 'Custom Reports',
  nav__reports_programmed: 'Programmed Reports',
  nav__reports_scheduled: 'Scheduled Reports',
  nav__sys_account_rules: 'Account Rules',
  nav__sys_custom_features: 'Custom Features',
  nav__sys_kinesis: 'Kinesis Events',
  nav__sys_notifications: 'Notifications',
  nav__sys_password_resets: 'Password Resets',
  nav__sys_support_req: 'Support Requests',
  nav__sys_ticket_seq: 'Ticket Sequence',
  nav__system: 'System',
  nav__tickets: 'Tickets',
  nav__time: 'Time Manager'
}

export const TICKET_LABELS = {
  ticket___custom_stage__label: 'Custom Stage',
  ticket__afe__label: 'AFE #',
  ticket__assignee__label: 'Assignee',
  ticket__computed_status__label: 'Status',
  ticket__contact__label: 'Customer Contact',
  ticket__created_at__label: 'Date Created',
  ticket__custom_form__id__label: 'Type ID',
  ticket__custom_form__name__label: 'Type',
  ticket__customer_company_name__label: 'Customer Office',
  ticket__first_name__label: 'Customer First Name',
  ticket__info_title__label: 'Customer Info',
  ticket__job_code__label: 'Job Code',
  ticket__last_name__label: 'Customer Last Name',
  ticket__location__label: 'Location',
  ticket__name__label: 'Ticket #',
  ticket__office__label: 'Customer Office',
  ticket__parent__name__label: 'Parent Ticket #',
  ticket__services_provided__label: 'Services provided for',
  ticket__services_ordered__label: 'Services ordered by',
  ticket__support_office__label: '3rd Party Support',
  ticket__timepoint_due__label: 'Expected Date',
  ticket__timepoint_submitted__label: 'Date Submitted',
  ticket__total__label: 'Total',
  ticket__user_afe_no__label: 'AFE Number',
  ticket__user_job_code_no__label: 'Job Code No',
  ticket__job_type__label: 'Job Type',
  ticket__team_member__label: 'Team Member',
  ticket__modified_at__label: 'Last Modified'
} as const

export const TICKET_USER_DATA_LABELS = {
  ticket__user_data__user_int_1__label: 'Ticket Integer 1',
  ticket__user_data__user_int_2__label: 'Ticket Integer 2',
  ticket__user_data__user_int_3__label: 'Ticket Integer 3',
  ticket__user_data__user_int_4__label: 'Ticket Integer 4',
  ticket__user_data__user_decimal_1__label: 'Ticket Decimal 1',
  ticket__user_data__user_decimal_2__label: 'Ticket Decimal 2',
  ticket__user_data__user_decimal_3__label: 'Ticket Decimal 3',
  ticket__user_data__user_decimal_4__label: 'Ticket Decimal 4',
  ticket__user_data__user_text_1__label: 'Ticket Text 1',
  ticket__user_data__user_text_2__label: 'Ticket Text 2',
  ticket__user_data__user_text_3__label: 'Ticket Text 3',
  ticket__user_data__user_text_4__label: 'Ticket Text 4',
  ticket__user_data__user_datetime_1__label: 'Ticket Date 1',
  ticket__user_data__user_datetime_2__label: 'Ticket Date 2',
  ticket__user_data__user_datetime_3__label: 'Ticket Date 3',
  ticket__user_data__user_datetime_4__label: 'Ticket Date 4'
} as const

export const LINE_ITEM_LABELS = {
  lineitem__amount: 'Total ($)',
  lineitem__assignee: 'Assignee',
  lineitem__barrels: 'Barrels',
  lineitem__category: 'Category',
  lineitem__cost: 'Cost',
  lineitem__created_at: 'Created',
  lineitem__description: 'Description',
  lineitem__discount: 'Discount (%)',
  lineitem__discountable_amount: 'Discountable Amount',
  lineitem__end_datetime: 'End Date',
  lineitem__hands: 'Hands',
  lineitem__minimum: 'Minimum ($)',
  lineitem__modified_at: 'Modified',
  lineitem__notes: 'Notes',
  lineitem__parent_category: 'Parent Category',
  lineitem__sku: 'SKU',
  lineitem__standby_rate: 'Standby Rate',
  lineitem__start_datetime: 'Start Date',
  lineitem__status: 'Status',
  lineitem__tax_rate: 'Tax Rate',
  lineitem__taxable_amount: 'Taxable Amount',
  lineitem__ticket__created_at: 'Ticket Created',
  lineitem__ticket__modified_at: 'Ticket Modified',
  lineitem__ticket__uuid: 'Ticket ID',
  lineitem__unit: 'Unit',
  lineitem__units_min: 'Units Min',
  lineitem__units_standby: 'Units Standby',
  lineitem__units_used: 'Units Used',
  lineitem__usage_rate: 'Usage Rate',
  lineitem__user_datetime_1: 'User DateTime 1',
  lineitem__user_datetime_2: 'User DateTime 2',
  lineitem__user_decimal_1: 'User Decimal 1',
  lineitem__user_decimal_2: 'User Decimal 2',
  lineitem__user_decimal_3: 'User Decimal 3',
  lineitem__user_integer_1: 'User Integer 1',
  lineitem__user_integer_2: 'User Integer 2',
  lineitem__user_integer_3: 'User Integer 3',
  lineitem__user_integer_4: 'User Integer 4',
  lineitem__user_long_text_1: 'User Long Text 1',
  lineitem__user_text_1: 'User Text 1',
  lineitem__user_text_2: 'User Text 2',
  lineitem__user_text_3: 'User Text 3',
  lineitem__user_text_4: 'User Text 4',
  lineitem__user_text_5: 'User Text 5',
  lineitem__user_text_6: 'User Text 6',
  lineitem__user_text_7: 'User Text 7',
  lineitem__user_text_8: 'User Text 8'
}

export const REPORT_LABELS = {
  report___custom_sage: 'Custom Stage',
  report__computed_status: 'Status',
  report__created_at: 'Date Created',
  report__custom_form__id: 'Type ID',
  report__custom_form__name: 'Type',
  report__customer_company_name: 'Customer Office',
  report__first_name: 'Customer First Name',
  report__group_subtotal: 'Subtotal',
  report__job_code: 'Job Code',
  report__last_name: 'Customer Last Name',
  report__location: 'Location',
  report__name: 'Ticket Name',
  report__support_office: 'Support Office',
  report__team_username: 'Team Member Username',
  report__timepoint_due: 'Expected Date',
  report__timepoint_submitted: 'Date Submitted',
  report__total: 'Total',
  report__user_afe_no: 'AFE Number'
}

export const LOCATION_LABELS = {
  location__user_text_1: 'User Text 1',
  location__user_text_2: 'User Text 2',
  location__user_text_3: 'User Text 3'
}

export const FIELD_LABELS: FieldLabel = {
  ...ENTITY_LABELS,
  ...LINE_ITEM_LABELS,
  ...LOCATION_LABELS,
  ...NAV_LABELS,
  ...REPORT_LABELS,
  ...TICKET_LABELS
}
