import { Sortable } from '@/ui/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { isEqual } from 'lodash'
import React, { FC, useMemo } from 'react'
import { bpaConfigAtom } from '../../atoms'
import { useRulesHandlers } from '../../hooks'
import { RulesCollapse } from './RulesCollapse'

export const RulesSortable: FC = () => {
  const rulesUuid = useAtomValue(
    useMemo(() => selectAtom(bpaConfigAtom, (config) => config.rules.map((r) => r.uuid), isEqual), [])
  )

  const { onSortingChange } = useRulesHandlers()

  return (
    <Sortable
      items={rulesUuid}
      onChange={onSortingChange}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      render={(ruleUuid: string, ruleIndex, sortable) => (
        <RulesCollapseMemo ruleUuid={ruleUuid} ruleIndex={ruleIndex} sortable={sortable} />
      )}
    />
  )
}

const RulesCollapseMemo = React.memo(RulesCollapse)
