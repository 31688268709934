import { apiHttp } from '@/services/api-service/core'
import { ListParams } from '@/types/api/core'
import { JasResourceResponse, JasResourceUseQueryResponse } from '@/types/jas-resource'
import { createSchedule } from '@/utils/create-schedule'
import { jasRecourcesEndpoint } from '@/utils/endpoints'
import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

type Variables = ListParams & {
  start_date: string
  end_date: string
  search?: string
}

export const JAS_RESOURCES_QUERY_KEY = 'jas-resources'

export const getJasResourcesInfiniteQueryKey = (variables: Variables) => [JAS_RESOURCES_QUERY_KEY, variables]

export const invalidateJasResourcesInfiniteQuery = (queryClient: any) => {
  queryClient.invalidateQueries({ queryKey: [JAS_RESOURCES_QUERY_KEY] })
}

export const useGetJasResourcesInfiniteQuery = (
  variables: Variables,
  options?: UseInfiniteQueryOptions<JasResourceUseQueryResponse[], unknown>
) => {
  return useInfiniteQuery<JasResourceUseQueryResponse[], unknown>({
    queryKey: getJasResourcesInfiniteQueryKey(variables),
    queryFn: ({ signal, pageParam }) => getJasResources({ ...variables, page: pageParam }, signal),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length === variables.limit ? allPages.length + 1 : null
    },
    ...options
  })
}

const getJasResources = async (
  variables: Variables & { page?: number },
  signal?: AbortSignal
): Promise<JasResourceUseQueryResponse[]> => {
  const response = await apiHttp.get<JasResourceResponse[]>(jasRecourcesEndpoint(), {
    params: variables,
    signal: signal
  })

  return response.data.map((data) => ({
    ...data,
    schedules: createSchedule({
      data,
      startDate: dayjs(variables.start_date),
      endDate: dayjs(variables.end_date)
    })
  }))
}
