import { mapName } from '@/modules/manage-user/utils/mapName'
import { Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedRolesAtom } from '../atoms'
import { AccessListItemHeader } from './components/Header'

const AccessCustomNavigation = () => {
  const lists = ['work', 'loads', 'the_field', 'tickets', 'lines', 'reporting', 'contacts', 'inventory']

  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({
    work: false,
    loads: false,
    the_field: false,
    tickets: false,
    lines: false,
    reporting: false,
    contacts: false,
    inventory: false
  })

  useEffect(() => {
    if (selectedRole && selectedRole.data && 'tab_permissions' in selectedRole.data) {
      setPermissions({ ...permissions, ...selectedRole.data.tab_permissions })
    }
  }, [selectedRole])

  const handleFieldChange = (list: string, e: boolean) => {
    const permission = { ...permissions, [list]: e }
    const updatedRole = { ...selectedRole, data: { ...selectedRole?.data, tab_permissions: permission } }
    setSelectedRole(updatedRole)
  }

  return (
    <div className="w-full lg:w-[33.333%]  xl:w-[20%]  mb-32">
      <AccessListItemHeader title="Custom Navigation" />
      <div className="px-24 pt-32  border lg:border-0 lg:border-r border-gray-200 h-full">
        {lists.map((list, i) => {
          return (
            <div className="w-full  mb-24" key={i}>
              <div className=" flex justify-between items-center w-full">
                <label htmlFor={`custom-navigation-${i}`}>{mapName(list)}</label>
                <Switch
                  checked={permissions[list]}
                  id={`custom-navigation-${i}`}
                  onChange={(e) => handleFieldChange(list, e)}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AccessCustomNavigation
