import { useApp, useSession } from '@/hooks'
import { equipmentApi, equipmentComponentApi, equipmentGroupApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { equipmentAtom, initialEquipmentAtom, isUpdateDefaultLiModalOpenAtom, requiredFieldsErrorsAtom } from '../atoms'
import { DetailedEquipment } from '../schema'
import { useDefaultLiFormsQuery } from './use-default-li-forms-query'

type MutationVariables = {
  category: number
  company: number
} & Omit<DetailedEquipment, 'category' | 'company'>

export const useUpsertEquipment = () => {
  const { company } = useSession()
  const { notification, l } = useApp()
  const equipment = useAtomValue(equipmentAtom)
  const initialEquipment = useAtomValue(initialEquipmentAtom)
  const setRequiredFieldsErrors = useSetAtom(requiredFieldsErrorsAtom)
  const setIsUpdateDefaultLiModalOpen = useSetAtom(isUpdateDefaultLiModalOpenAtom)
  const createEquipmentGroupMutation = useMutation(equipmentGroupApi.create())
  const updateEquipmentGroupMutation = useMutation(equipmentGroupApi.update())
  const deleteEquipmentComponentsMutation = useMutation(equipmentComponentApi.delete<any, { id: number }>())
  const updateEquipmentComponentMutation = useMutation(equipmentComponentApi.update())
  const createEquipmentComponentMutation = useMutation(equipmentComponentApi.create())
  const [isUpsertEquipmentLoading, setIsUpsertEquipmentLoading] = useState(false)
  const { defaultLiFormsQuery } = useDefaultLiFormsQuery()

  const updateEquipmentMutation = useMutation({
    ...equipmentApi.update<DetailedEquipment, MutationVariables>()
  })

  const createEquipmentMutation = useMutation({
    ...equipmentApi.create<DetailedEquipment, MutationVariables>()
  })

  // Create equipment group in db and get the id before creating new equipment
  // if equipment group is selected
  const createEquipmentGroup = async () => {
    if (!equipment.group) {
      return null
    }

    const payload = {
      ...equipment.group,
      equipment_ids: equipment.group.equipment?.map((item) => item.id)
    }

    if (equipment.group.id) {
      await updateEquipmentGroupMutation.mutateAsync(payload)
      return equipment.group.id
    }

    const group = await createEquipmentGroupMutation.mutateAsync(payload)
    return group.id
  }

  const deleteEquipmentComponents = async () => {
    const deletedEquipmentComponents = initialEquipment.components?.filter(
      (item) => !equipment.components?.find((component) => component.id === item.id)
    )

    if (!deletedEquipmentComponents || deletedEquipmentComponents.length === 0) {
      return
    }

    const mutations = deletedEquipmentComponents.map((item) =>
      deleteEquipmentComponentsMutation.mutateAsync({ id: item.id })
    )

    await Promise.all(mutations)
  }

  const upsertEquipmentComponents = async (equipmentId: number) => {
    if (!equipment.components || equipment.components.length === 0) {
      return
    }

    const mutations = equipment.components.map((component) => {
      const { is_new, ...rest } = component

      const payload = {
        ...rest,
        data_json: JSON.stringify(component.data),
        finished_good: equipmentId,
        sub_component: component.sub_component?.id
      }

      if (is_new) {
        return createEquipmentComponentMutation.mutateAsync({
          ...payload,
          id: undefined
        })
      } else {
        return updateEquipmentComponentMutation.mutateAsync(payload)
      }
    })

    await Promise.all(mutations)
  }

  const upsertEquipmentMutation = equipment.id ? updateEquipmentMutation : createEquipmentMutation

  const upsertEquipment = async () => {
    // TODO: Possible refactor needed to make this cleaner
    const validate = () => {
      let isValid = true

      if (!equipment.category?.id) {
        setRequiredFieldsErrors((prev) => ({ ...prev, isSubcategoryError: true }))
        isValid = false
      }

      if (!equipment.category?.parent_category) {
        setRequiredFieldsErrors((prev) => ({ ...prev, isCategoryError: true }))
        isValid = false
      }

      if (!equipment.sku.trim()) {
        setRequiredFieldsErrors((prev) => ({ ...prev, isSkuError: true }))
        isValid = false
      }

      if (!equipment.description?.trim()) {
        setRequiredFieldsErrors((prev) => ({ ...prev, isDescriptionError: true }))
        isValid = false
      }

      return isValid
    }

    if (!validate()) {
      return
    }

    setIsUpsertEquipmentLoading(true)

    const groupId = await createEquipmentGroup()
    await deleteEquipmentComponents()

    const { id, components, ...rest } = equipment

    const payload = {
      ...rest,
      group_no: groupId,
      category: equipment.category!.id,
      company: company.id,
      components: !equipment.is_finished_good ? [] : components?.map((component) => component.sub_component?.id)
    }

    // TODO: Find a better way to check if equipment is new
    if (equipment.id === 0) {
      const equipment = await createEquipmentMutation.mutateAsync(payload)
      await upsertEquipmentComponents(equipment.id)

      notification.success({
        message: `${l('SKU')} Saved`,
        description: `Successfully saved ${equipment.sku}`
      })

      setIsUpsertEquipmentLoading(false)

      return `../${equipment.id}/edit`
    } else {
      // Order matters. Firstly update components. Once equipment gets updated, on query invalidation
      // it will receive fresh subcomponents' data. See OC-6072
      await upsertEquipmentComponents(equipment.id)
      await updateEquipmentMutation.mutateAsync({
        ...payload,
        id: equipment.id
      })

      notification.success({
        message: `${l('SKU')} Saved`,
        description: `Successfully saved ${equipment.sku}`
      })

      setIsUpsertEquipmentLoading(false)

      // Show modal to update default li forms if there are default li forms
      if (defaultLiFormsQuery.data?.items.length !== 0) {
        setIsUpdateDefaultLiModalOpen(true)
      }
    }
  }

  return { upsertEquipment, upsertEquipmentMutation, isUpsertEquipmentLoading }
}
