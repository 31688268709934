import { CONTACT_SEARCH_BY } from '@/constants/api'
import { ContactSchema } from '@/modules/ticket/form/schemas'
import { contactApi } from '@/services/api-service'
import { Contact } from '@/types/contact'
import { QuerySelect } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionEmailCustomerSignature, BpaActionEmailVendorAccess } from '../../schemas'
import { PropertySelect } from '../controls'

type Props = {
  action: BpaActionEmailCustomerSignature | BpaActionEmailVendorAccess
  onChange: (data: Partial<BpaActionEmailCustomerSignature | BpaActionEmailVendorAccess>) => void
}

export const InputEmailCustomerSignature: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  const onContactSelected = (selectedContacts: { value: number; label: string }[]) => {
    const contactIds = selectedContacts.map((item) => item.value).join(',')
    onChange({ _selectedContacts: selectedContacts })
    onChange({ contact_ids: contactIds })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        {action.asText ? (
          <Form.Item
            label="Contacts (Text)"
            tooltip="Comma separated id or placeholder like P[Key], F[name]"
            className="flex-grow"
          >
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={action.contact_ids}
              onChange={(e) => onChangeDebounced({ contact_ids: e.target.value })}
            />
          </Form.Item>
        ) : (
          <Form.Item label="Contacts" className="flex-grow">
            <QuerySelect
              mode={'multiple'}
              placeholder={'Search'}
              className={'w-full h-full'}
              apiEndpoint={contactApi.list}
              labelInValue
              defaultValue={action._selectedContacts}
              apiQueryParams={{
                fields: ContactSchema,
                order: 'first_name'
              }}
              apiSearchBy={CONTACT_SEARCH_BY}
              renderOption={(item: Contact) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` })}
              onChange={onContactSelected}
            />
          </Form.Item>
        )}
        <Form.Item
          label="As Text"
          tooltip="Using as text will allow using placeholder string in non textual value input"
        >
          <Checkbox checked={action.asText} onChange={(e) => onChange({ asText: e.target.checked as any })} />
        </Form.Item>
      </div>
      <div>
        <Form.Item label="Property" className="flex-grow">
          <PropertySelect
            valueField={'id'}
            defaultValue={action.property_id}
            onChange={(value) => onChange({ property_id: value })}
          />
        </Form.Item>
      </div>
      {action.type === 'email_vendor_access' && (
        <div>
          <Form.Item label="Mail Message for Vendor" className="flex-grow">
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              defaultValue={action.vendor_mail_message || ''}
              onChange={(e) => onChangeDebounced({ vendor_mail_message: e.target.value })}
            />
          </Form.Item>
        </div>
      )}
      <div></div>
    </div>
  )
}
export const InputEmailCustomerSignatureMemo = React.memo(InputEmailCustomerSignature)
