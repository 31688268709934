import { Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const PermissionsColumn = () => {
  const enabledGroups =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.enabled_groups), [])) ?? []

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)
  const isGroupEnabled = (name: string) => enabledGroups.includes(name)

  const handleChange = (name: string, checked: boolean) => {
    const newEnabledGroups = checked
      ? [...enabledGroups, name]
      : enabledGroups.filter((groupName: string) => name !== groupName)

    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        enabled_groups: newEnabledGroups
      }
    }))
  }

  return (
    <div>
      {userGroups.map((userGroup) => {
        return (
          <div key={userGroup.name} className="flex w-full justify-between mb-20">
            <div>{userGroup.label}</div>
            <Switch
              checked={isGroupEnabled(userGroup.name)}
              onChange={(checked) => handleChange(userGroup.name, checked)}
            />
          </div>
        )
      })}
    </div>
  )
}

const userGroups = [
  {
    name: 'ticket_signature',
    label: 'Allow E-Sign Capability'
  },
  {
    name: 'email_vendor',
    label: 'Secure Vendor Link'
  },
  {
    name: 'my_time',
    label: 'Allow Individual Time Tracking (My Time)'
  },
  {
    name: 'time_manager',
    label: 'Allow Time Manager'
  },
  {
    name: 'ticket_manager',
    label: 'Manage company tickets'
  },
  {
    name: 'company_admin',
    label: 'Manage users & company information'
  },
  {
    name: 'choices_manager',
    label: 'Choices Manager'
  },
  {
    name: 'module_builder',
    label: 'Module Builder'
  },
  {
    name: 'qb_input',
    label: 'QB Input Page'
  },
  {
    name: 'ems_user',
    label: 'EMS User'
  },
  {
    name: 'roles_manager',
    label: 'Roles Manager'
  },
  {
    name: 'support',
    label: 'OilCommand Support *',
    su: true
  },
  {
    name: 'disable_ticket_reservation',
    label: 'Restrict from making tickets on mobile'
  }
]
