import { LINE_ITEM_FIELDS } from '@/constants/general'
import { CategoryKindField } from '@/types/general'
import { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { AmountField } from './amount-field'
import { AssigneeField } from './assignee-field'
import { DynamicField } from './dynamic-field'
import { UserTextField } from './user-text-field'

type Props = {
  field: CategoryKindField
  lineItem: any
  onChange: (lineItem: any, value: any) => void
  disabled?: boolean
}

export const LineItemEditField = ({ field, lineItem, onChange, disabled }: Props) => {
  const fieldItem = LINE_ITEM_FIELDS.find((item) => item.field === field)

  // TODO: Using debounce because of performance issues. There might be a better solution.
  const debouncedOnChange = useDebouncedCallback((value: any) => {
    onChange(lineItem, value)
  }, 500)

  const handleChange = useCallback(
    (value: any) => {
      debouncedOnChange(value)
    },
    [debouncedOnChange]
  )

  if (!fieldItem) {
    console.warn(`Field item ${fieldItem} not found in LINE_ITEM_FIELDS`)
    return null
  }

  if (field === 'assignee') {
    return <AssigneeField lineItem={lineItem} onChange={handleChange} disabled={disabled} />
  }

  // user_text_... fields should be Select component with dynamic options fetched from API
  if (field.startsWith('user_text')) {
    return <UserTextField fieldItem={fieldItem} lineItem={lineItem} onChange={handleChange} disabled={disabled} />
  }

  // amount field is calculated based on other fields
  if (field === 'amount') {
    return <AmountField lineItem={lineItem} />
  }

  return <DynamicField fieldItem={fieldItem} lineItem={lineItem} onChange={handleChange} disabled={disabled} />
}
