import { profileApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { useQuery } from '@tanstack/react-query'
import { Form, Select } from 'antd'
import { useState } from 'react'

type Props = {
  company?: Company
}

export const DefaultProfileField = ({ company }: Props) => {
  const [search, setSearch] = useState(company?.default_profile__username ?? '')

  const profilesQuery = useQuery({
    ...profileApi.list({
      compact: 1,
      // ...(company?.id && { company__exact: company?.id }),
      user__username__icontains: search
    })
  })

  const options = profilesQuery.data?.items.map((profile) => ({
    label: profile.user.username,
    value: profile.id
  }))

  return (
    <Form.Item label="Default User" name="default_profile">
      <Select
        showSearch
        filterOption={false}
        searchValue={search}
        onSearch={setSearch}
        options={options}
        data-cy="default-profile-select"
      />
    </Form.Item>
  )
}
