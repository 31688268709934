import { officeApi } from '@/services/api-service'
import { AppLink, Button, PageTitle, QueryDeleteButton, QueryTable, SearchInput } from '@/ui'
import { Space } from 'antd'
import { useState } from 'react'

export const AllOfficesView = () => {
  const [searchText, setSearchText] = useState('')

  return (
    <div>
      <PageTitle>Offices</PageTitle>
      <div className="flex my-16 justify-between">
        <SearchInput onSearch={setSearchText} />
        <AppLink to="/offices/add">
          <Button type="primary">Add Office</Button>
        </AppLink>
      </div>
      <QueryTable
        queryApi={officeApi.list}
        queryParams={{
          order: 'name',
          fields: 'id,name,company__name',
          ...(searchText && { name__icontains: searchText })
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Company',
            dataIndex: 'company__name'
          },
          {
            dataIndex: 'id',
            align: 'right',
            render: (id) => (
              <Space>
                <QueryDeleteButton api={officeApi.delete} id={id} />
                <AppLink to={`/offices/${id}/edit`}>
                  <Button iconName="fa:edit" type="text" />
                </AppLink>
              </Space>
            )
          }
        ]}
      />
    </div>
  )
}
