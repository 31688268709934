import { useApp } from '@/hooks'
import { Button, Popconfirm } from '@/ui'
import { Form, Input } from 'antd'
import cn from 'classnames'
import { Resizable } from 're-resizable'
import { FC, useCallback, useMemo } from 'react'
import { copyFromLineItem, transformLineItem } from '../../helpers'
import { useCustomerPricing, useFormValues, useItemDelete, useItemSave } from '../../hooks'
import { LineItem, LineItemForm, LineItemOptions } from '../../schemas'
import { ItemEditor } from './item-editor'
import { ItemSelector } from './item-selector'

type Props = {
  lineItem?: LineItem
  options?: LineItemOptions
  onSubmit?: (lineItems?: any[]) => void
  onCancel?: () => void
}

export const ItemForm: FC<Props> = (props) => {
  const { lineItem, options } = props
  const [form] = Form.useForm<LineItemForm>()
  const { handleValuesChange } = useFormValues(form)

  const initialValues = useMemo((): LineItemForm => {
    let item = lineItem as Partial<LineItem> | undefined
    if (item && options?.type === 'duplicate') {
      item = copyFromLineItem(item, {}, { noCoreFields: true }) as LineItem
    }

    return {
      initialized: true,
      isNew: !item,
      options: options || {},
      equipmentIds: [],
      data: item ? transformLineItem(item) : undefined
    }
  }, [lineItem, options])

  return (
    <div className={'h-full w-full'}>
      <Form
        form={form}
        name="lineItemForm"
        className={'h-full w-full'}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={initialValues}
      >
        <ItemFormInner {...props} />
      </Form>
    </div>
  )
}

const ItemFormInner: FC<Props> = ({ lineItem, onSubmit, onCancel, options }) => {
  const { notification } = useApp()
  const { saveLineItems, isSaving } = useItemSave()
  const { deleteLineItem, isDeleting } = useItemDelete()

  const { applyPricing } = useCustomerPricing()
  applyPricing()

  const handleSubmit = useCallback(async () => {
    try {
      const lineItems = await saveLineItems()
      notification.success({
        message: 'Item Saved',
        description: 'The item has been updated successfully'
      })
      onSubmit?.(lineItems)
    } catch (e: any) {
      notification.error({ message: 'Error', description: e.toString() })
    }
  }, [notification, onSubmit, saveLineItems])

  const handleDelete = useCallback(async () => {
    if (lineItem?.id === undefined) return

    await deleteLineItem(lineItem?.id)
    notification.success({
      message: 'Item Deleted',
      description: 'The item has been deleted successfully'
    })
    onSubmit?.()
  }, [deleteLineItem, lineItem?.id, notification, onSubmit])

  return (
    <div className={'h-full w-full flex flex-col gap-10'}>
      <Form.Item name={['data', 'id']} hidden>
        <Input />
      </Form.Item>
      <div className={'flex-grow overflow-hidden'}>
        <div className={'h-full flex flex-row gap-10'}>
          {!lineItem && (
            <Resizable
              defaultSize={{ width: '40%', height: '100%' }}
              className={'min-w-[30%] max-w-[70%] !h-full border rounded p-10'}
              handleClasses={{
                top: 'hidden',
                left: 'hidden',
                bottom: 'hidden',
                right: 'bg-primary !h-40 !w-4 !-right-[8px] !top-[calc(50%-20px)] cursor-ew-resize rounded'
              }}
            >
              <ItemSelector />
            </Resizable>
          )}

          <div
            className={cn('h-full w-full overflow-y-auto', {
              'max-h-[calc(100vh-300px)]': lineItem,
              'border rounded p-10': !lineItem
            })}
          >
            <div className={'h-full w-full flex flex-col'}>
              <ItemEditor />
            </div>
          </div>
        </div>
      </div>

      <div className={'flex justify-between gap-10'}>
        <div className={'flex flex-row gap-10'}>
          {!!lineItem && (
            <Popconfirm title={`Deleting (${lineItem.sku})`} description={'Are you sure?'} onConfirm={handleDelete}>
              <Button danger>Delete</Button>
            </Popconfirm>
          )}
        </div>

        <div className={'flex flex-row gap-10'}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" loading={isSaving} onClick={handleSubmit} htmlType={'submit'}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
