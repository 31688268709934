import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { secretApi } from '@/services/api-service'
import { LinkButton, QueryDeleteButton, QueryEditButton } from '@/ui'
import dayjs from 'dayjs'
import { z } from 'zod'
import { SECRET_LIST_VIEW_HEADER } from '../../contstants'

export const SecretListView = view(Component)

function Component() {
  return (
    <ListView
      header={{
        ...SECRET_LIST_VIEW_HEADER,
        title: 'Secrets Manager',
        actions: (
          <LinkButton to="add" type="primary" iconName="fa:plus">
            Add Secret
          </LinkButton>
        )
      }}
      filter={{ search: { searchBy: 'code_name' } }}
      table={{
        queryApi: secretApi.list,
        queryParams: { order: 'code_name', fields: SecretSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'code_name', title: 'Code Name' },
          { dataIndex: 'company__name', title: 'Company' },
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Secret) => (
              <div>
                <QueryEditButton to={`${row.id}/edit`} />
                <QueryDeleteButton id={row.id} api={secretApi.delete} name="Secret" />
              </div>
            )
          }
        ]
      }}
    ></ListView>
  )
}

const SecretSchema = z.object({
  id: z.number(),
  company__name: z.string(),
  code_name: z.string(),
  modified_at: z.string()
})

type Secret = z.infer<typeof SecretSchema>
