import { PageTitle as Title } from '@/ui/page-title'
import { LayoutLink } from '../../components/layout-link/LayoutLink'
import { useCustomFormQuery } from '../hooks'

export const PageTitle = () => {
  const { customFormQuery } = useCustomFormQuery()
  const { id, name } = customFormQuery.data || {}

  if (customFormQuery.isLoading) {
    return <Title>Custom Forms</Title>
  }

  return (
    <div className="flex">
      <Title>{id ? `Edit Custom Form: ${name} (${id})` : 'Add Custom Form'} </Title>
      {id && <LayoutLink formId={id} />}
    </div>
  )
}
