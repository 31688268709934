import { AppLink, Button, Icon, Select } from '@/ui'
import { cn } from '@/utils'
import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { HTMLAttributes, forwardRef, memo, useEffect, useState } from 'react'

import { INDICATOR_DATE_DELTAS } from '@/constants/general'
import { INVENTORY_PATHS } from '@/modules/inventory/constants'
import { equipmentInventoryStatsApi } from '@/services/api-service'
import { InventoryIndicator } from '@/types/inventory-indicator'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import dayjs from 'dayjs'
import { AddEditIndicatorModal } from '../add-edit-indicator-modal'
import { DeleteButton } from './delete-button'
import { TransactionsChart } from './transactions-chart'
import { YMaxMinSelect } from './y-max-min-select'

export type GraphCardProps = HTMLAttributes<HTMLDivElement> & {
  id: string
  inventoryIndicator: InventoryIndicator
  onDelete: (id: number) => void
  sequence?: number
  withOpacity?: boolean
  attributes?: DraggableAttributes
  listeners?: SyntheticListenerMap
  isDragging?: boolean
}

const last30Days = dayjs().subtract(30, 'days').toISOString()

const Component = forwardRef<HTMLDivElement, GraphCardProps>(
  (
    { inventoryIndicator, sequence, withOpacity, isDragging, style, listeners, attributes, onDelete, ...props },
    ref
  ) => {
    const [dateDelta, setDateDelta] = useState(inventoryIndicator.date_delta || INDICATOR_DATE_DELTAS.T)
    const [yMax, setYMax] = useState('')
    const [yMin, setYMin] = useState('')
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const inventoryStatsQuery = useQuery(
      equipmentInventoryStatsApi.list({
        ['ids[]']: inventoryIndicator.equipment?.id,
        detail: 1,
        company_wide: 1,
        ...(dateDelta === 'C'
          ? {
              begin_date: inventoryIndicator.begin_date
                ? dayjs(inventoryIndicator.begin_date).toISOString()
                : undefined,
              end_date: inventoryIndicator.begin_date ? dayjs(inventoryIndicator.end_date).toISOString() : undefined
            }
          : dateDelta === 'M'
            ? { begin_date: last30Days }
            : { current: 1 })
      })
    )

    useEffect(() => {
      setDateDelta(inventoryIndicator.date_delta || INDICATOR_DATE_DELTAS.T)
    }, [inventoryIndicator.date_delta])

    return (
      <Spin spinning={inventoryStatsQuery.isLoading || inventoryStatsQuery.isFetching}>
        <div ref={ref} className="w-full" style={style} {...props}>
          <div className="flex items-center px-20 h-44 rounded-t bg-dark-container">
            <button
              {...listeners}
              {...attributes}
              className="mr-10 cursor-grab text-white bg-transparent inline-flex w-24 h-24 justify-center items-center"
            >
              <Icon name="mi:drag_indicator" />{' '}
            </button>
            <div className="text-white font-bold text-16">{inventoryIndicator.name}</div>
          </div>
          <Form layout="vertical">
            <div className={cn('p-20 bg-gray-100 rounded-b', isDragging && 'shadow-md')}>
              <div className="bg-white px-20 py-10 grid grid-cols-3 gap-x-10 mb-10">
                <Form.Item label="Period" className="mb-0">
                  <Select
                    value={dateDelta}
                    options={Object.entries(INDICATOR_DATE_DELTAS)
                      .filter(([value]) =>
                        value === 'C' && (!inventoryIndicator.begin_date || !inventoryIndicator.end_date) ? false : true
                      )
                      .map(([value, label]) => ({ value, label }))}
                    onChange={(value) => setDateDelta(value)}
                  />
                </Form.Item>
                <Form.Item label="Y Max" className="mb-0">
                  <YMaxMinSelect
                    type="Y Max"
                    options={inventoryIndicator.y_max_options}
                    inventoryIndicator={inventoryIndicator}
                    onChange={(value) => setYMax(value)}
                  />
                </Form.Item>
                <Form.Item label="Y Min" className="mb-0">
                  <YMaxMinSelect
                    type="Y Min"
                    options={inventoryIndicator.y_min_options}
                    inventoryIndicator={inventoryIndicator}
                    onChange={(value) => setYMin(value)}
                  />
                </Form.Item>
              </div>
              <div className="px-20 bg-white mb-10">
                <TransactionsChart
                  inventoryIndicator={inventoryIndicator}
                  equipmentStats={inventoryStatsQuery.data?.items || []}
                  yMax={yMax}
                  yMin={yMin}
                />
              </div>
              <div className="bg-white px-20 py-10 mb-10">
                <div>
                  Alert Level: {inventoryIndicator.alert_level} {inventoryIndicator.equipment?.unit}
                </div>
                <div>Notes: {inventoryIndicator.notes}</div>
              </div>
              <div className="flex bg-white px-20 py-10 gap-x-8">
                <DeleteButton inventoryIndicator={inventoryIndicator} onDelete={onDelete} />
                <AppLink
                  to={`${INVENTORY_PATHS.ledger}/?id=${inventoryIndicator.equipment?.id}`}
                  className="shrink grow"
                >
                  <Button iconName="fa:list" className="w-full">
                    View Ledger
                  </Button>
                </AppLink>
                <Button iconName="fa:edit" onClick={() => setIsEditModalOpen(true)} className="shrink grow">
                  Edit
                </Button>
              </div>
            </div>
          </Form>
        </div>
        {isEditModalOpen && (
          <AddEditIndicatorModal
            inventoryIndicator={inventoryIndicator}
            sequence={sequence}
            onCancel={() => setIsEditModalOpen(false)}
          />
        )}
      </Spin>
    )
  }
)

Component.displayName = 'GraphCard'

export const GraphCard = memo(Component)
