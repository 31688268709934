import { z } from 'zod'

export const BpaVariableTypeSchema = z.enum(['str', 'int', 'float', 'yaml', 'json'])

export const BpaVariableSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  type: BpaVariableTypeSchema,
  value: z.string().optional()
})

export type BpaVariableType = z.infer<typeof BpaVariableTypeSchema>
export type BpaVariable = z.infer<typeof BpaVariableSchema>
