import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { CategoryKind } from './schemas'

export { CATEGORY_KIND_FIELDS } from '@/constants/general'

export const CORE_SKU_FIELDS = [...CATEGORY_KIND_FIELDS, 'category', 'sequence'] as const

export const DEFAULT_KIND: CategoryKind = {
  id: 0,
  name: 'Default',
  kind: 'default',
  fields: ['sku', 'description', 'category', 'usage_rate', 'units_used', 'amount']
}

export const RATE_UNIT = {
  '/ea': 'Each',
  '/bbl': 'Barrel',
  '/ton': 'Ton',
  '/hr': 'Per Hour',
  '/day': 'Per Day',
  '/wk': 'Per Week',
  '/mo': 'Per Month',

  '/mi': 'Per Mile',
  '/ft': 'Per Foot',
  '/yd': 'Per Yard',
  '/qt': 'Per Quart',
  '/gal': 'Per Gallon'
}
export const RATE_NAME = {
  '/ea': 'Units',
  '/bbl': 'Barrels',
  '/ton': 'Tons',
  '/hr': 'Hours',
  '/day': 'Days',
  '/wk': 'Weeks',
  '/mo': 'Months',

  '/mi': 'Miles',
  '/ft': 'Feet',
  '/yd': 'Yards',
  '/qt': 'Quarts',
  '/gal': 'Gallons'
}
export type RateUnit = keyof typeof RATE_UNIT

export const MS_PER_HOUR = 1000 * 60 * 60

export const RATE_IN_MS = {
  '/hr': MS_PER_HOUR,
  '/day': MS_PER_HOUR * 24,
  '/wk': MS_PER_HOUR * 24 * 7,
  '/mo': MS_PER_HOUR * 24 * 30
} as const
export type DurationRateUnit = keyof typeof RATE_IN_MS
