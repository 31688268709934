import { Button, Popconfirm, Select } from '@/ui'
import { UseSortable } from '@/ui/sortable'
import { cn } from '@/utils'
import { Form } from 'antd'
import { FC } from 'react'
import { useConditionsHandlers } from '../../hooks'
import { BpaCondition } from '../../schemas'
import { CastSelect } from './CastSelect'
import { FieldInput } from './FieldInput'
import { OperatorSelect } from './OperatorSelect'
import { ValuesInput } from './ValuesInput'

type Props = {
  condition: BpaCondition
  conditionIndex: number
  ruleIndex?: number
  sortable?: UseSortable
}

export const ConditionInputs: FC<Props> = ({ condition, conditionIndex, ruleIndex, sortable }) => {
  const { onDeleteCondition, onFieldChange, onValuesChange, onFieldChangeDebounce } = useConditionsHandlers(ruleIndex)

  return (
    <div
      className={cn('p-8 pb-0', 'rounded-lg border border-transparent', {
        'border-dashed shadow-primary !border-primary-500 ': sortable?.isDragging
      })}
      ref={sortable?.setNodeRef}
      style={sortable?.style}
      {...sortable?.attributes}
    >
      {conditionIndex > 0 && (
        <div className="flex relative">
          <div className="absolute left-0 top-16 text-14 border-b w-full" />
          <Form.Item>
            <Select
              className="min-w-[65px]"
              showSearch={false}
              showCopyText={false}
              allowClear={false}
              data-cy="condition-logic-select"
              size="small"
              defaultValue={condition.logic || 'and'}
              options={[
                { value: 'and', label: 'AND' },
                { value: 'or', label: 'OR' }
              ]}
              onChange={(value) => onFieldChangeDebounce('logic', value, conditionIndex)}
            />
          </Form.Item>
        </div>
      )}
      <div className="flex flex-row gap-10 items-center">
        <Form.Item label="Field Type" className="w-2/12">
          <Select
            data-cy="condition-field-type-select"
            defaultValue={condition.fieldType}
            options={[
              { value: 'ticket', label: 'Ticket' },
              { value: 'properties', label: 'Property' },
              { value: 'lineItems', label: 'Line Item' },
              { value: 'context', label: 'Context' },
              { value: 'custom', label: 'Custom (as text)' }
            ]}
            className="w-full"
            onChange={(value) => onFieldChangeDebounce('fieldType', value, conditionIndex)}
          />
        </Form.Item>
        <FieldInput
          condition={condition}
          className="w-3/12"
          onChange={(value) => onFieldChangeDebounce('field', value, conditionIndex)}
        />
        <OperatorSelect
          condition={condition}
          className="w-2/12"
          onChange={(value) => onFieldChangeDebounce('operator', value, conditionIndex)}
        />
        <div className="flex-grow">
          <ValuesInput condition={condition} onChange={(value) => onValuesChange(value, condition, conditionIndex)} />
        </div>
        <CastSelect
          condition={condition}
          className="w-1/12"
          onChange={(value) => onFieldChange('cast', value, conditionIndex)}
        />
        <div className="flex flex-row">
          <Popconfirm
            title="Confirm Delete"
            description="Deleting a condition. Are you sure?"
            onConfirm={(e) => onDeleteCondition(conditionIndex)}
            okButtonProps={{
              'data-cy': 'delete-condition-button-confirm'
            }}
          >
            <Button data-cy="delete-condition-button" type="text" iconName="fa:trash" size="small" danger={true} />
          </Popconfirm>
          <Button
            {...sortable?.listeners}
            type={'text'}
            iconName={'fa:grip-dots-vertical'}
            size={'small'}
            className={'cursor-grab'}
            data-cy="drag-condition-button"
          />
        </div>
      </div>
    </div>
  )
}
