import { FC } from 'react'
import { PrintButton } from '../../../print-button'
import { useButtonContext } from '../../hooks'

export const TicketPdfButton: FC = () => {
  const { vars, property, buttonProps } = useButtonContext()
  const { type, mode, ...options } = vars || {}

  return (
    <PrintButton
      type={type}
      mode={mode}
      options={options}
      buttonProps={{ children: buttonProps?.children ? buttonProps.children : property.name, ...buttonProps }}
    />
  )
}
