import { useApp, useModalState } from '@/hooks'
import { equipmentApi } from '@/services/api-service'
import { ActionButton, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form, Input } from 'antd'
import { FC } from 'react'
import { Equipment } from '../../schemas'

type Props = {
  equipment: Equipment
}

export const RenameButton: FC<Props> = ({ equipment }) => {
  const { l, notification } = useApp()
  const [form] = Form.useForm()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const mutation = useMutation(equipmentApi.update({}, 'rename'))

  const handleRename = async () => {
    const { sku, description, id, rename_line_items } = await form.validateFields()

    await mutation
      .mutateAsync({ new_sku: sku, new_description: description, id, rename_line_items: rename_line_items || false })
      .then(() => {
        closeModal()
        notification.success({
          message: 'Renamed',
          description: `${l('SKU')} ${equipment.sku} has been renamed to ${sku}`
        })
      })
      .catch(() => {
        notification.error({ message: 'Error', description: 'Failed to rename ' + equipment.sku })
      })
  }

  return (
    <>
      <ActionButton
        type="text"
        title="Rename"
        iconName={'fa:font'}
        data-cy={'rename-sku'}
        onClick={openModal}
        onFocus={renderModal}
        onMouseEnter={renderModal}
      />
      {isRender && (
        <Modal
          open={isOpen}
          width={500}
          iconName={'fa:font'}
          title={'Rename ' + equipment.sku}
          onOk={handleRename}
          okButtonProps={{ loading: mutation.isLoading }}
          okText="Rename"
          cancelText="Cancel"
          onCancel={closeModal}
        >
          <Form form={form} initialValues={equipment} layout="vertical">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item label="New SKU" name="sku" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="New Description" name="description" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="rename_line_items" valuePropName="checked">
              <Checkbox>Also rename line items</Checkbox>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
