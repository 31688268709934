import { Checkbox, Form, Input } from 'antd'

export const CreateTab = () => {
  return (
    <>
      <Form.Item name={['params', 'name']} label="Name">
        <Input />
      </Form.Item>
      <Form.Item name={['params', 'ignore_duplicate']} valuePropName="checked" noStyle>
        <Checkbox>Ignore Duplicate</Checkbox>
      </Form.Item>
    </>
  )
}
