import { Button, UseSortable } from '@/ui'
import { Card } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { DetailedEquipment } from '../../schema'

type ItemProps = {
  equipment: DetailedEquipment
  onDelete: (item: DetailedEquipment) => void
  sortable: UseSortable
}

export const Item: FC<ItemProps> = ({ equipment, onDelete, sortable }) => {
  return (
    <Card ref={sortable?.setNodeRef} style={sortable?.style} {...sortable?.attributes}>
      <div className="flex w-full items-center">
        <div className="grow">
          {equipment.sku} - {equipment.description}
        </div>
        <div className="shrink-0">
          <Button iconName="fa:trash" type="text" onClick={() => onDelete(equipment)} />
          <Button
            {...sortable?.listeners}
            type={'text'}
            iconName={'fa:grip-dots-vertical'}
            size={'middle'}
            onClick={(e) => e.stopPropagation()}
            className={cn({
              'cursor-grab': !sortable?.isDragging,
              'cursor-grabbing': sortable?.isDragging
            })}
          />
        </div>
      </div>
    </Card>
  )
}
