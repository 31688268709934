import { useWiki } from '@/modules/wiki/hooks'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Tooltip } from '@/ui/tooltip'
import React from 'react'

export const WikiLink: React.FC = () => {
  const { data, isEnabled } = useWiki()

  const toUrl = data?.url || '/wiki'

  if (!isEnabled) {
    return <></>
  }

  return (
    <AppLink to={toUrl}>
      <Tooltip title={'OilCommand Training'}>
        <Button size="large" type="text" icon={<Icon name="fa:book-open" className="text-18" />} />
      </Tooltip>
    </AppLink>
  )
}
