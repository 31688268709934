import { useModalState } from '@/hooks'
import { bundlePdfApi } from '@/services/api-service'
import { Button, Icon, Modal, QueryDeleteButton, QueryTable, QueryTableRef } from '@/ui'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import { FC, ReactElement, useRef } from 'react'

type Props = {
  children?: ReactElement
}

const BUNDLE_STATUS: Record<string, string> = {
  P: 'Pending',
  S: 'Complete'
}

export const TicketListBundles: FC<Props> = ({ children }) => {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()
  const tableRef = useRef<QueryTableRef>(null)

  return (
    <div>
      <div onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal}>
        {children}
      </div>
      {isRender && (
        <Modal
          open={isOpen}
          onCancel={closeModal}
          iconName={'fa:file-pdf'}
          title={
            <div className={'flex items-center gap-10'}>
              <span>Bundled PDFs</span>
              <Button
                size={'small'}
                shape={'circle'}
                type={'text'}
                onClick={() => tableRef.current?.refresh()}
                iconName={'fa:redo'}
              />
            </div>
          }
          width={'calc(100vw - 40%)'}
          withScreenMaxHeight={true}
          styles={{ body: { padding: '0' } }}
          footer={null}
        >
          <QueryTable
            ref={tableRef}
            queryApi={bundlePdfApi.list}
            queryParams={{ order: '-created_at', deleted_at__isnull: true }}
            rowKey={'id'}
            scroll={{ y: '600px' }}
            className={'h-[600px] border-none'}
            columns={[
              {
                key: 'name',
                dataIndex: 'name',
                sorter: true,
                title: 'File Name',
                render: (value, row) => (
                  <Button
                    className={'flex !justify-start gap-8 w-full'}
                    type={'link'}
                    href={row.url}
                    target={'_blank'}
                    download={true}
                    disabled={row.status !== 'S'}
                  >
                    {row.status === 'P' && <Spin size={'small'} className={'mr-2'} />}
                    {row.status === 'S' && <Icon name={'fa:file-pdf'} />}
                    <span className={'line-clamp-1 text-ellipsis'}>{value}</span>
                    {row.status === 'S' && <Icon name={'fa:external-link'} className={'text-[10px]'} />}
                  </Button>
                )
              },
              {
                key: 'created_at',
                dataIndex: 'created_at',
                sorter: true,
                width: 200,
                title: 'Created At',
                render: (value) => dayjs.formatLocal(value) || ''
              },
              {
                key: 'status',
                dataIndex: 'status',
                sorter: true,
                title: 'Status',
                width: 200,
                render: (value) => BUNDLE_STATUS[value] || 'Unknown'
              },
              {
                key: 'actions',
                fixed: 'right',
                width: 80,
                render: (row: any) => (
                  <div>
                    <QueryDeleteButton id={row.id} api={bundlePdfApi.delete} name={'Bundle PDF'} />
                  </div>
                )
              }
            ]}
          />
        </Modal>
      )}
    </div>
  )
}
