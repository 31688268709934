import { z } from 'zod'

export const ContactSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  profile__user__username: z.string()
})

export type Contact = z.infer<typeof ContactSchema>
