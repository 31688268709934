import { CrontabEditorModel } from '@/components/crontab-editor-model'
import { NotificationTemplateStatus, NotificationTemplateType } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { notificationTemplateApi, scheduledReportApi } from '@/services/api-service'
import { Button, QuerySelect, Select } from '@/ui'
import { getCronFrequency } from '@/utils/get-cron-frequency'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { useQuery } from '@tanstack/react-query'
import { Form, Input, Space, Spin, Switch } from 'antd'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RecentActivitySection } from './recent-activity-section'
import { ReportSelectFields } from './report-select-fields'
import { SaveButton } from './save-button'

export const AddEditScheduledReportView = view<any, any>(Component, {
  loader: async ({ params }) => {
    const scheduledReportId = params.id
    if (!scheduledReportId) return { title: 'Add Scheduled Report' }

    const schedulerReport = await fetchQuery(
      scheduledReportApi.get<any>(Number(scheduledReportId), undefined, { fields: 'id,name' })
    )
    return { title: `Edit ${schedulerReport.name || 'Scheduled Report'}` }
  },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const { id } = useParams()
  const [form] = Form.useForm()
  const cronSpec = Form.useWatch('cron_spec', form) as string | undefined
  const { company, user } = useSession()
  const [isFreqModalOpen, setFreqModalOpen] = useState<boolean>(false)
  const { l } = useApp()

  const cronFrequency: 'Monthly' | 'Weekly' | 'Daily' | null = useMemo(() => {
    if (!cronSpec) {
      return null
    }

    const frequency = getCronFrequency(cronSpec)

    if (frequency === 'day') {
      return 'Daily'
    }

    if (frequency === 'week') {
      return 'Weekly'
    }

    return 'Monthly'
  }, [cronSpec])

  const scheduledReportQuery = useQuery({
    ...scheduledReportApi.get<any>(Number(id)),
    enabled: !!id
  })

  if (scheduledReportQuery.isInitialLoading) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <Spin spinning />
      </div>
    )
  }

  if (!scheduledReportQuery.data && id) {
    return null
  }

  const scheduledReport = scheduledReportQuery.data

  return (
    <FormView
      header={{
        actions: [<SaveButton key="save" form={form} />],
        title: `${id ? 'Edit' : 'Add'} ${l('ScheduledReport')}`,
        backButton: true,
        accent: false,
        border: false
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          scheduledReport
            ? scheduledReport
            : {
                cron_spec: '0 0 * * *',
                company: {
                  id: company?.id,
                  name: company?.name
                },
                run_as: {
                  id: user?.id,
                  username: user?.username
                },
                timezone: 'America/Chicago'
              }
        }
      >
        <Form.Item name="id" hidden noStyle />
        <Form.Item name="cron_spec" hidden noStyle />
        <Form.Item name="company" hidden noStyle />
        <Form.Item name="run_as" hidden noStyle />
        <Form.Item>
          <Space>
            <Form.Item noStyle name="enabled" valuePropName="checked">
              <Switch />
            </Form.Item>
            <div>Enabled</div>
          </Space>
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-16">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-3 gap-x-16">
          <Form.Item label="Company" name={['company', 'name']}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Run As" name={['run_as', 'username']}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Timezone" name="timezone">
            <Select disabled />
          </Form.Item>
        </div>
        <div className="mb-16">
          <h5 className="font-bold">Report Frequency</h5>
          <Button type="primary" onClick={() => setFreqModalOpen(true)}>
            Set report frequency
          </Button>
          <CrontabEditorModel
            isOpen={isFreqModalOpen}
            onChange={(value: string) => form.setFieldValue('cron_spec', value)}
            onClose={() => setFreqModalOpen(false)}
            cronExpression={cronSpec || '0 0 * * *'}
          />
          {cronSpec && (
            <div className="mt-8 font-semibold italic">
              Repeats {cronFrequency}, {humanizeCronExpr(cronSpec)}
            </div>
          )}
        </div>
        <Form.Item label="Email Template" name="notification_template" rules={[{ required: false }]}>
          <QuerySelect
            apiSearchBy="name"
            placeholder="Select"
            apiEndpoint={notificationTemplateApi.list}
            apiQueryParams={{
              type__eq: 'E' as NotificationTemplateType,
              status__eq: 'P' as NotificationTemplateStatus,
              fields: 'id,name'
            }}
            renderOption={(item) => ({ value: item.id, label: item.name })}
            showSearch
            data-cy="custom-template-sg-template-id-select"
          />
        </Form.Item>
        <Form.Item label="Email/s (one per line)" name="contacts" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <ReportSelectFields />
        <RecentActivitySection />
      </Form>
    </FormView>
  )
}
