import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'

export const ConfigEditor = () => {
  return (
    <Form.Item name="data_json">
      <CodeEditor mode="json" />
    </Form.Item>
  )
}
