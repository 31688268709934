import { useAtomValue } from 'jotai'
import { editingEquipmentAtom } from '../../atoms'
import { Equipment, PricingCustomer } from '../../schemas'
import { PriceEditCell } from './price-edit-cell'

type Props = {
  pricingCustomer: PricingCustomer
  equipment: Equipment
}

export const PriceCell = ({ pricingCustomer, equipment }: Props) => {
  const price = pricingCustomer.prices?.find((price) => price.equipment === equipment.id)
  const editingEquipment = useAtomValue(editingEquipmentAtom)

  if (editingEquipment && editingEquipment.id === equipment.id) {
    return <PriceEditCell price={price} pricingCustomer={pricingCustomer} />
  }

  return (
    <div>
      {price &&
        price.usage_rate &&
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price.usage_rate)}
    </div>
  )
}
