import { Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedRoleIdAtom, selectedRolesAtom } from '../atoms'
import { useCategoryTag } from '../hooks'

import { useFetchProfile } from '../../hooks'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'
import { ToggleSwitch } from './components/ToggleSwitch'

const AccessSKU = () => {
  const { data: categoryData, isLoading } = useCategoryTag()
  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const [categories, setCategories] = useState<{ id: number; name: string }[] | []>([])
  const [enabledCategories, setEnabledCategories] = useState<(number | null)[] | null | undefined>([])
  const [selectedData, setSelectedData] = useState('')

  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)

  const { data: profileData } = useFetchProfile()

  useEffect(() => {
    if (categoryData && categoryData.items.length > 0) {
      const items = categoryData.items.map((cd) => ({ id: cd.id, name: cd.name }))
      setCategories(items)
    }
    if (selectedRole && selectedRole.data && 'enabled_category_tags' in selectedRole.data) {
      if (selectedRole.data?.enabled_category_tags) {
        setEnabledCategories(selectedRole.data?.enabled_category_tags)
      }
    }
  }, [categoryData, selectedRole])

  const checkIfActive = (id: number) => {
    if (enabledCategories && enabledCategories.includes(id)) return true
    return false
  }

  const handleOkClick = () => {
    setSelectedRoleId(0)
    if (selectedRole) {
      let enabledGroups = [] as number[]
      const id = Number(selectedData)
      if (profileData?.userdata_json?.enabled_category_tags) {
        if (profileData?.userdata_json?.enabled_category_tags.includes(id)) {
          const filteredGroups = profileData?.userdata_json?.enabled_category_tags.filter((group) => group !== id)
          enabledGroups = filteredGroups
        } else {
          enabledGroups = [...(profileData?.userdata_json?.enabled_category_tags as number[]), id]
        }
      }
      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          enabled_groups: profileData?.userdata_json?.enabled_groups,
          tab_permissions: profileData?.userdata_json?.tab_permissions,
          enabled_modules: profileData?.userdata_json?.enabled_modules,
          enabled_category_tags: enabledGroups,
          advance_settings: profileData?.advance_settings
        }
      })
    }
    setChangeRoleModalState(false)
  }

  const handleChange = ({ data, state }: { data: string; state: boolean }) => {
    setSelectedData(data)
    if (!data) return

    const id = Number(data)

    // If disabled
    if (enabledCategories && enabledCategories.length > 0 && enabledCategories.includes(id)) {
      const filteredCategories = enabledCategories && enabledCategories.filter((dc) => dc !== id)
      const updatedCategories = {
        ...selectedRole,
        data: { ...selectedRole?.data, enabled_category_tags: filteredCategories }
      }
      setSelectedRole(updatedCategories)
      return
    }

    // If enabled
    const category = [...(enabledCategories ?? []), id]
    const updatedCategories = {
      ...selectedRole,
      data: { ...selectedRole?.data, enabled_category_tags: category }
    }
    setSelectedRole(updatedCategories)
  }
  const handleRoleOkClick = () => {
    setEnableRoleModalState(false)
  }
  const handleCancelClick = () => {
    setChangeRoleModalState(false)
  }
  const handleRoleCancelClick = () => {
    setEnableRoleModalState(false)
  }

  const handleChangeRoleModalActiveState = (state: boolean) => {
    setChangeRoleModalState(state)
  }
  const handleEnableRoleModalActiveState = (state: boolean) => {
    setEnableRoleModalState(state)
  }

  return (
    <div className="w-full lg:w-[50%]  xl:w-[20%] mb-32">
      <AccessListItemHeader title="SKU Access (By Category Tag)" />
      <div className=" px-24 pt-32 h-full border lg:border-none lg:border-r border-gray-200">
        <Spin spinning={isLoading}>
          {categories.map((category, i) => {
            return (
              <div className="w-full mb-24" key={i}>
                <ToggleSwitch
                  id={`category-${category.id}`}
                  data={category}
                  checked={checkIfActive(category.id)}
                  onChange={handleChange}
                  onChangeRoleToggleModal={handleChangeRoleModalActiveState}
                  onEnableRoleToggleModal={handleEnableRoleModalActiveState}
                />
              </div>
            )
          })}
        </Spin>
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={handleChangeRoleModalActiveState}
        onOk={handleOkClick}
        onCancel={handleCancelClick}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={handleEnableRoleModalActiveState}
        onOk={handleRoleOkClick}
        onCancel={handleRoleCancelClick}
      />
    </div>
  )
}
export default AccessSKU
