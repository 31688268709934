export const LINE_ITEM_FIELDS = [
  'amount',
  'barrels',
  'cost',
  'description',
  'discount',
  'discountable_amount',
  'tax_rate',
  'taxable_amount',
  'end_datetime',
  'minimum',
  'sku',
  'notes',
  'standby_rate',
  'start_datetime',
  'unit',
  'units_min',
  'units_standby',
  'hands',
  'units_used',
  'usage_rate',
  'status',
  'assignee',
  'user_decimal_1',
  'user_decimal_2',
  'user_decimal_3',
  'user_integer_1',
  'user_integer_2',
  'user_integer_3',
  'user_integer_4',
  'user_text_1',
  'user_text_2',
  'user_text_3',
  'user_text_4',
  'user_text_5',
  'user_text_6',
  'user_text_7',
  'user_text_8',
  'user_long_text_1',
  'user_datetime_1',
  'user_datetime_2'
] as const
