import React, { FC } from 'react'
import { BpaActionRemoveLineItem } from '../../schemas'
import { LineItemQueryFilter } from './InputUpdateLineItem'

type Props = {
  action: BpaActionRemoveLineItem
  onChange: (data: Partial<BpaActionRemoveLineItem>) => void
}

export const InputRemoveLineItem: FC<Props> = (props) => {
  return (
    <div className="flex flex-col">
      <LineItemQueryFilter action={props.action as any} onChange={props.onChange as any} />
    </div>
  )
}
export const InputRemoveLineItemMemo = React.memo(InputRemoveLineItem)
