import { AxiosResponse } from 'axios'

export const filterQueryHasField = (filterQuery: any, field: string): boolean => {
  const topFilters = Object.keys(filterQuery || {})
  const qFilters = ((filterQuery || {})['Q[]'] || []).map((line?: string) => line?.split('|')[0])

  return topFilters.concat(qFilters).some((k) => k.indexOf(field) >= 0)
}

export const getApiMessage = (response: AxiosResponse) => {
  let message = `${response.status} ${response.statusText || 'Error'}\n`

  const headers = response.headers || {}
  const contentType = headers['content-type'] || ''
  const isJson = contentType.startsWith('application/json')
  const isHtml = contentType.startsWith('text/html')

  let fragment = ''

  if (isJson) {
    // try "non_field_errors" first; serialize entire response body, otherwise
    const body = response.data
    fragment = ((body || 0).non_field_errors || []).join('\n')
    fragment = fragment || JSON.stringify(body, null, 2)
  } else if (isHtml) {
    fragment = 'Internal server error'
    // const temp = document.createElement('div')
    // temp.innerHTML = response.data
    // fragment = (temp.textContent || '').replace(/[ \t]+/g, ' ').replace(/\n{3,}/g, '\n\n')
  } else {
    fragment = response.data
  }

  if (!isJson) {
    fragment = '' + fragment
    fragment = fragment.substring(0, 497) + (fragment.length >= 497 ? '...' : '')
  }

  message += fragment

  return message
}
