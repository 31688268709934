import { useApp } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { jobTypeApi } from '@/services/api-service'
import { QueryDeleteButton } from '@/ui'
import dayjs from 'dayjs'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'
import { ModalButton } from './modal-button'
import { JobType, JobTypeSchema } from './schemas'

export const JobTypeListView = view(Component, { title: () => 'JobTypes' })

function Component() {
  const { l } = useApp()

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('JobTypes'),
        actions: [
          <ModalButton key={'add'} type={'primary'} shape={'default'} iconName={'fa:plus'} data-cy={'add-button'}>
            Add {l('JobType')}
          </ModalButton>
        ]
      }}
      filter={{ search: { searchBy: 'name' } }}
      table={{
        queryApi: jobTypeApi.list,
        queryParams: { order: 'name', fields: JobTypeSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'name', title: `${l('JobType')} Name` },
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: JobType) => (
              <div>
                <ModalButton iconName={'fa:edit'} jobType={row} data-cy={'edit-job-type-button'} />
                <QueryDeleteButton id={row.id} api={jobTypeApi.delete} name={l('JobType')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}
