import { CalendarTable, ScheduleRuleList } from '@/components'
import { CalendarTableDropdownCell } from '@/components/calendar-table/calendar-table-dropdown-cell'
import { invalidateJasResourcesInfiniteQuery, useRuleTemplatesQuery } from '@/hooks'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { queryClient } from '@/query'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { useAtomValue } from 'jotai'
import { useParams } from 'react-router-dom'
import { SCHEDULE_PATHS } from '../constants'
import { useDeletePatchMutation, useSingleScheduleView } from '../hooks'

export const EmployeeView = () => {
  const { moduleId } = useParams<{ moduleId: string }>()
  const { handlePatchMutation, query, breadcrumbItems, date, setDate } = useSingleScheduleView()
  const { handleDeletePatchMutation } = useDeletePatchMutation({ onSuccess: query.refetch })
  const calendarView = useAtomValue(calendarViewAtom)

  const ruleTemplatesQuery = useRuleTemplatesQuery({
    company_id__isnull: 0
  })

  const onRuleMutationsSuccess = () => {
    query.refetch()
    invalidateJasResourcesInfiniteQuery(queryClient)
  }

  return (
    <div className="h-[calc(100vh-100px)] relative">
      <Breadcrumb items={breadcrumbItems} />
      <div className="mt-12 mb-20 flex">
        <AppLink to={SCHEDULE_PATHS.employees(moduleId)}>
          <Button icon={<ArrowLeftOutlined />} type="primary">
            Back
          </Button>
        </AppLink>
      </div>
      <CalendarTable
        title="Employee"
        titleDataIndex="name"
        visibleDays={calendarView}
        data={query.data ? [query.data] : []}
        date={date}
        onDateChange={(date) => setDate(date)}
        loading={query.isLoading}
        withFullHeight={false}
        renderTitleCell={(employee) => (
          <div className="flex flex-col h-full justify-center pr-10" data-cy={`employee-${employee.name}`}>
            <div className="text-ellipsis overflow-hidden whitespace-nowrap font-bold">{employee.name}</div>
            <div className="text-ellipsis text-gray-500 overflow-hidden whitespace-nowrap font-semibold text-sm min-w-0">
              {Object.values(employee.attributes).join(', ')}
            </div>
          </div>
        )}
        renderDateCell={(date, employee) => (
          <CalendarTableDropdownCell
            date={date.format('YYYY-MM-DD')}
            data={employee}
            isShortForm={calendarView === 'month'}
            onMenuItemClick={(type, selectedDates) =>
              type === 'DELETE'
                ? handleDeletePatchMutation(employee, selectedDates)
                : handlePatchMutation(employee, selectedDates, type)
            }
          />
        )}
      />
      {query.data && (
        <div className="mt-60">
          <ScheduleRuleList
            resource={query.data}
            ruleTemplates={ruleTemplatesQuery.data?.items || []}
            onMutationsSuccess={onRuleMutationsSuccess}
          />
        </div>
      )}
    </div>
  )
}
