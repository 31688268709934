import { useApp } from '@/hooks'
import { view } from '@/routing'
import { navigate } from '@/routing/helpers'
import { authApi } from '@/services/api-service'
import { BackButton } from '@/ui'
import { Button } from '@/ui/button'
import { LockOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { flatten } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const ResetPasswordView = view(Component, { title: () => 'Reset Password' })

function Component() {
  const params = useParams()
  const { notification } = useApp()
  const { mutate: reset, isLoading } = useMutation(authApi.resetPassword())
  const handleFormSubmit = (values: { new_password1: string; new_password2: string; token: string; uid: string }) => {
    reset(values, {
      onError: (error: any) => {
        const errors = flatten(Object.values(error?.response?.data || {}) || []) as string[]
        notification.error({
          message: 'Password Reset Failed',
          description: errors.map((e, idx) => <p key={`${idx}-${e}`}>{e}</p>)
        })
      },
      onSuccess: ({ detail }) => {
        notification.success({
          message: 'Success',
          description: detail
        })

        navigate('/auth/login')
      }
    })
  }
  useEffect(() => {
    if (!params || !params?.uid || !params?.token) {
      navigate('/auth/login')
    }
  }, [params])
  return (
    <div>
      <div className="px-16">
        <div className={'flex flex-row items-center gap-10'}>
          <BackButton to={'/auth/login'} />
          <div className="font-bold text-xl">Forgot Password</div>
        </div>

        <div className={'py-10'}>Enter your username or email to continue.</div>

        <Form
          name="normal_login"
          className="p-0 "
          initialValues={{ token: params.token, uid: params.uid }}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <Form.Item className="mb-16" name="token" label="" rules={[{ required: true }]} hidden>
            <Input autoFocus />
          </Form.Item>
          <Form.Item className="mb-16" name="uid" label="" rules={[{ required: true }]} hidden>
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            className="mb-16"
            name="new_password1"
            label="New Password"
            rules={[{ required: true, message: 'Please enter your password' }]}
            hasFeedback
          >
            <Input
              autoFocus
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              data-cy="password-input"
            />
          </Form.Item>
          <Form.Item
            className="mb-28"
            name="new_password2"
            label="Confirm Password"
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('The Password you enter does not match!'))
                  }
                }
              }
            ]}
          >
            <Input
              autoFocus
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              data-cy="password-input-confirm"
            />
          </Form.Item>

          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={isLoading}
              loading={isLoading}
              data-cy="sumbit-reset-password"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
