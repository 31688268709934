import { getJasResourceQueryKey, useAddPatchMutation, useApp, useAppSelector, useGetJasResourceQuery } from '@/hooks'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { getEndDateByView, getStartDateByView } from '@/modules/jas/scheduler/helpers'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { AppLink } from '@/ui/AppLink'
import { ScheduleType } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import { useAtomValue } from 'jotai'
import { startCase } from 'lodash'
import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const useSingleScheduleView = () => {
  const { notification } = useApp()
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const { company } = useAppSelector((state) => state.session)
  const [date, setDate] = useState<Dayjs>(dayjs())
  const calendarView = useAtomValue(calendarViewAtom)

  const queryVariables = {
    id: Number(params.id),
    start_date: getStartDateByView(calendarView, date).format('YYYY-MM-DD'),
    end_date: getEndDateByView(calendarView, date).format('YYYY-MM-DD')
  }

  const query = useGetJasResourceQuery(queryVariables, {
    enabled: !!params.id
  })

  const addPatchMutation = useAddPatchMutation({
    // Optimistic update
    onMutate: async (payload) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(getJasResourceQueryKey(queryVariables))

      // Snapshot the previous value
      const previousQueryResult = queryClient.getQueryData<JasResourceUseQueryResponse>(
        getJasResourceQueryKey(queryVariables)
      )

      const { start_date, data } = payload

      if (previousQueryResult) {
        const schedules = previousQueryResult.schedules

        queryClient.setQueryData<JasResourceUseQueryResponse>(getJasResourceQueryKey(queryVariables), {
          ...previousQueryResult,
          schedules: {
            ...schedules,
            [start_date]: {
              type: data.type,
              data: data as any // Typing as any during optimistic update. Valid data will be passed after mutation
            }
          }
        })
      }

      notification.success({
        message: 'New patch added'
      })

      return { previousQueryResult }
    },
    onError: (_, __, context: any) => {
      notification.error({
        message: 'Error',
        description: 'Something went wrong'
      })
      queryClient.setQueryData(getJasResourceQueryKey(queryVariables), context.previousQueryResult)
    },
    onSettled: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(getJasResourceQueryKey(queryVariables))
    }
  })

  const handlePatchMutation = async (data: JasResourceUseQueryResponse, dates: Dayjs[], type: ScheduleType) => {
    const promises = dates.map((date) => {
      const schedule = data.schedules[date.format('YYYY-MM-DD')]

      // if schedule already exists and type is the same, do nothing
      if (schedule.data && schedule.type === type) {
        return
      }

      // if schedule already exists and type is different, update
      if (schedule.data && schedule.type !== type && 'id' in schedule.data && !('schedule' in schedule.data)) {
        return addPatchMutation.mutateAsync({
          company: company.id,
          resource: data.id,
          start_date: date.format('YYYY-MM-DD'),
          end_date: date.format('YYYY-MM-DD'),
          data: { type },
          name: `${data.name} patch`,
          id: schedule.data.id
        })
      }

      return addPatchMutation.mutateAsync({
        company: company.id,
        resource: data.id,
        start_date: date.format('YYYY-MM-DD'),
        end_date: date.format('YYYY-MM-DD'),
        data: { type },
        name: `${data.name} patch`
      })
    })

    await Promise.all(promises)
  }

  const pathSegments = location.pathname.split('/')

  const previousPage = {
    title: startCase(pathSegments[pathSegments.length - 2]),
    href: location.pathname.replace(`/${params.id}`, '')
  }

  const breadcrumbItems = [
    {
      title: 'Schedule',
      href: '/schedule'
    },
    {
      title: <AppLink to={previousPage.href}>{previousPage.title}</AppLink>,
      href: previousPage.href
    },
    {
      title: query.data?.name,
      href: location.pathname
    }
  ]

  return {
    query,
    handlePatchMutation,
    breadcrumbItems,
    previousPage,
    date,
    setDate
  }
}
