import { contactApi, contactNoteApi } from '@/services/api-service'
import { Contact } from '@/types/contact'
import { ContactsNote } from '@/types/contacts-note'
import { Button } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

const DisplayContactView = ({ id, onEditClick }: { id: string; onEditClick: () => void }) => {
  const contactQuery = useQuery({
    ...contactApi.get<Contact>(Number(id)),
    enabled: !!id
  })
  const contactNotesQuery = useQuery({
    ...contactNoteApi(id).list<ContactsNote>(),
    enabled: !!id
  })

  const data = useMemo(() => contactQuery.data, [contactQuery.data])

  return (
    <Spin spinning={contactQuery.isLoading}>
      <div className="px-16 py-16">
        <div className="flex justify-between">
          <div>
            <h2 className="font-bold mb-0 text-24 border-l-[6px] border-primary-400 pl-10">{data?.title}</h2>
            <p className="pl-18 font-medium text-18">
              {data?.first_name} {data?.last_name}
            </p>
          </div>
          <div>
            <Button iconName="fa:pen" onClick={() => onEditClick()}>
              Edit
            </Button>
          </div>
        </div>
        <div className="pt-2 mb-10 border-b border-gray-200"></div>

        <div className="border-b border-gray-200 pt-6 pb-6">
          <div className="flex py-2 -mx-10">
            <div className="w-4/12 px-10">
              <span className="font-semibold text-16">Email</span>
            </div>
            <div className="w-8/12 px-10">
              <span className="text-16">{data?.email}</span>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 pt-6 pb-6">
          <div className="flex py-2 -mx-10">
            <div className="w-4/12 px-10">
              <span className="font-semibold text-16">Phone</span>
            </div>
            <div className="w-8/12 px-10">
              <span className="text-16">{data?.phone_number}</span>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 pt-6 pb-6">
          <div className="flex py-2 -mx-10">
            <div className="w-4/12 px-10">
              <span className="font-semibold text-16">Website</span>
            </div>
            <div className="w-8/12 px-10">
              <span className="text-16">{data?.company?.website}</span>
            </div>
          </div>
        </div>

        {contactNotesQuery.data?.items && (
          <Spin spinning={contactNotesQuery.isLoading}>
            <div className="border-b border-gray-200 pt-6 pb-6">
              <div className="">
                <div className="mb-4">
                  <span className="font-semibold text-16">Notes:</span>
                </div>
                <div>
                  {contactNotesQuery.data?.items.map((note) => {
                    return (
                      <div key={note.id}>
                        <div className="font-medium text-14">{note.text_content}</div>
                        <p className="text-sm text-gray-400">
                          Last modified by {note.modified_by__username} at{' '}
                          {dayjs(note?.modified_at).format('MMM DD, YYYY h:mm A')}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Spin>
        )}
      </div>
    </Spin>
  )
}

export { DisplayContactView }
