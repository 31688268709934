import { Form } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { HiddenStatusesSelect } from '../hidden-statuses-select'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { PropertiesSelect } from '../properties-select'
import { TableViewSelect } from '../table-view-select'

export const ModuleTypeJobsFields = () => {
  const form = Form.useFormInstance()
  const stagesFormId = Form.useWatch(['data', 'stages_form_id'], form) as number | undefined

  return (
    <div>
      <div className="grid grid-cols-6 gap-x-16">
        <TableViewSelect formItemProps={{ className: 'col-span-2' }} />
        <ForceViewCheckbox />
        <HideDefaultNoneView />
        <HiddenStatusesSelect formItemProps={{ className: 'col-span-2' }} />
      </div>
      <div className="grid grid-cols-6 gap-x-16">
        <CustomFormSelect
          formItemProps={{
            label: 'Custom Form (for Labels and Selection)',
            name: ['data', 'stages_form_id'],
            className: 'col-span-2'
          }}
          renderOptions={(options) => [{ label: 'Default', value: '' }, ...options]}
        />
        <PropertiesSelect
          formItemProps={{ label: 'Search Properties', name: ['data', 'search_props'], className: 'col-span-2' }}
          selectProps={{ mode: 'multiple' }}
          customFormsIds={stagesFormId ? [stagesFormId] : []}
        />
      </div>
    </div>
  )
}
