import { Spin as AntSpin, SpinProps } from 'antd'

type Props = {
  isCentered?: boolean
} & SpinProps

export const Spin = ({ isCentered, ...props }: Props) => {
  if (isCentered) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <AntSpin {...props} />
      </div>
    )
  }

  return <AntSpin {...props} />
}
