import { z } from 'zod'

export const rolesSchema = z.object({
  id: z.number().nullish(),
  name: z.string().nullish(),

  data: z.object({
    groups: z.array(z.string().nullable()).nullish(),
    enabled_groups: z.array(z.string().nullable()).nullish(),
    enabled_modules: z.array(z.any().nullable()).nullish(),
    enabled_category_tags: z.array(z.number().nullable()).nullish(),
    disabled_forms: z.array(z.number().nullable()).nullish(),
    advance_settings: z.record(z.string(), z.boolean()).nullish(),
    tab_permissions: z.record(z.string(), z.boolean()).nullish(),
    ticket_status_limit: z.number().nullish()
  })
})

export const customFormSchema = z.object({
  id: z.number(),
  name: z.string()
})

export const moduleSchema = z.object({
  id: z.number(),
  title: z.string(),
  contacts: z.array(z.number().nullable()).nullish()
})

export const categoryTagSchema = z.object({
  id: z.number(),
  name: z.string()
})

export type Role = z.infer<typeof rolesSchema>
export type CustomForm = z.infer<typeof customFormSchema>
export type Module = z.infer<typeof moduleSchema>
export type CategoryTag = z.infer<typeof categoryTagSchema>
