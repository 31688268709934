import { Form, Input } from 'antd'

export const BillSection = () => {
  const isQbEnabled = Form.useWatch(['doc', 'accounting', 'qbo_enabled'])
  const isQbwcEnabled = Form.useWatch(['doc', 'accounting', 'qbwc_enabled'])

  return (
    <Form.Item hidden={!isQbEnabled && !isQbwcEnabled}>
      <h4 className="font-bold">Bill</h4>
      <div className="grid grid-cols-3 gap-x-24">
        <Form.Item label="Bill Number Code" name={['doc', 'bill_doc_number']}>
          <Input />
        </Form.Item>
        <Form.Item label="Txn Date" name={['doc', 'bill_txn_date']}>
          <Input />
        </Form.Item>
        <Form.Item label="Expense Lines Class Name (QB Desktop)" name={['doc', 'bill_li_class_name']}>
          <Input />
        </Form.Item>
        <Form.Item label="Expense Line Account" name={['doc', 'account_code']}>
          <Input />
        </Form.Item>
        <Form.Item label="Private Memo" name={['doc', 'bill_private_note']}>
          <Input.TextArea rows={1} />
        </Form.Item>
        <Form.Item label="Department/Location" name={['doc', 'bill_department_name']}>
          <Input />
        </Form.Item>
        <Form.Item label="Expense Lines GL code (QB Desktop)" name={['doc', 'bill_li_gl_code']}>
          <Input />
        </Form.Item>
        <Form.Item label="Due date" name={['doc', 'bill_due_date']}>
          <Input />
        </Form.Item>
        <Form.Item label="Expense Lines memo  (QB Desktop)" name={['doc', 'bill_li_memo']}>
          <Input.TextArea rows={1} />
        </Form.Item>
      </div>
    </Form.Item>
  )
}
