import { ApiResource, MutationReturn, Params, RequestConfig } from '@/types/api/core'
import { R, createResourceApi } from '../core'

export type LineItemApi = Omit<ApiResource, 'batch'> & {
  batch: <TData = any>(params?: Params, config?: RequestConfig) => MutationReturn<TData>
}

export const _lineItemApi: LineItemApi = {
  ...createResourceApi('line_items'),
  batch: (params, config) => ({
    mutationFn: (data: any) => R('PATCH', '/line_items/batch/', { params, data, ...config })
  })
}

export const _lineItemApiV2 = createResourceApi('v2/line_items', ['line_items', 'v2'])
