import { Button, TipButton } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { sidebarCollapsedAtom } from '../atoms'
import { AccountMenu } from './AccountMenu'
import { NewTicket } from './NewTicket'
import { VersionInfo } from './VersionInfo'
import { WikiLink } from './WikiLink'

export const HeaderDesktop: FC = () => {
  const [collapsed, setCollapsed] = useAtom(sidebarCollapsedAtom)

  const goBack = () => {
    // use browser history to go back to support going
    // back to pages of v1 app
    window.history.go(-1)
  }

  return (
    <header className="h-[60px] w-full shadow-sm bg-container dark:bg-dark-container dark:border-b dark:border-dark-border">
      <div className="flex items-center justify-between h-full">
        <div className="px-16 w-4/12 md:hidden">
          <Button iconName={'fa:bars'} onClick={() => setCollapsed(!collapsed)} />
        </div>
        <div className="px-16 flex-1 hidden md:flex items-center"></div>
        <div className="px-16 flex-1">
          <div className="hidden md:flex flex-wrap justify-center -mx-4">
            <div className="px-4 flex items-center">
              <NewTicket />
            </div>
            <div className="px-4 flex items-center">
              <Button type="primary" className="!rounded-lg" icon={<Icon name="svg:undo" />} onClick={goBack} />
            </div>
          </div>
        </div>
        <div className="px-16 flex-1">
          <div className="flex items-center justify-end -mx-16">
            <div className="pt-10 pr-8">
              <TipButton
                size={'large'}
                title={'Customer Support'}
                type={'text'}
                id="intercom-launcher"
                iconName={'fa:headset'}
              />
            </div>
            {/*<div className="pt-10 pr-8">
              <BugLink />
            </div>
            <div className="pt-10 pr-8">
              <SupportRequestLink />
            </div>*/}
            <div className="pt-10 pr-8">
              <WikiLink />
            </div>
            <AccountMenu />
          </div>
        </div>
      </div>
      <VersionInfo />
    </header>
  )
}
