import { Provider } from 'jotai'

export const withJotaiProvider = (Component: React.FC) => {
  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <Provider>
        <Component />
      </Provider>
    )
  }
}
