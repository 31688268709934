import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const ShowOnList = () => {
  const { lineItemField } = useSortableItemContext()
  const setConfiguration = useSetAtom(configurationAtom)

  const dataField = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.data ?? {}), [])
  )

  const item = dataField[lineItemField] ?? ({} as any)
  const isHidden = item.mobileWeb && item.mobileWeb.onList

  const handleChange = (checked: boolean) => {
    if (checked) {
      setConfiguration((prev) => {
        const data = prev.data ?? ({} as any)
        data[lineItemField] = {
          ...data[lineItemField],
          mobileWeb: {
            ...data[lineItemField]?.mobileWeb,
            onList: true
          }
        }
        return {
          ...prev,
          data
        }
      })
    } else {
      setConfiguration((prev) => {
        const data = prev.data ?? ({} as any)
        data[lineItemField] = {
          ...data[lineItemField],
          mobileWeb: {
            ...data[lineItemField]?.mobileWeb,
            onList: false
          }
        }
        return {
          ...prev,
          data
        }
      })
    }
  }

  return (
    <Space>
      <Switch checked={isHidden} onChange={handleChange} data-cy="show-on-list-switch"></Switch>
      <span>Show on List</span>
    </Space>
  )
}
