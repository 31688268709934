import { FC, useCallback } from 'react'
import { BpaAction } from '../../schemas'
import { InputAddTeamMemberMemo } from './InputAddTeamMember'
import { InputAttachPrintedTicketMemo } from './InputAttachPrintedTicket'
import { InputCopyTicketMemo } from './InputCopyTicket'
import { InputEmailCustomerSignatureMemo } from './InputEmailSecureAccess'
import { InputEtlClickhouseCommandActionMemo } from './InputEtlClickhouseCommandAction'
import { InputEtlExtractDataActionMemo } from './InputEtlExtractDataAction'
import { InputEtlLoadDataActionMemo } from './InputEtlLoadDataAction'
import { InputEtlTransformDataActionMemo } from './InputEtlTransformDataAction'
import { InputFunctionCallMemo } from './InputFunctionCall'
import { InputReOrderLineItemsMemo } from './InputReOrderLineItems'
import { InputRemoveLineItemMemo } from './InputRemoveLineItem'
import { InputSendCustomEmailMemo } from './InputSendCustomEmail'
import { InputSetHiddenReferenceMemo } from './InputSetHiddenReference'
import { InputSetPropertyMemo } from './InputSetProperty'
import { InputSetTicketFieldMemo } from './InputSetTicketField'
import { InputSetVariableMemo } from './InputSetVariable'
import { InputSubmitToAccountingMemo } from './InputSubmitToAccounting'
import { InputSyncCustomRecordMemo } from './InputSyncCustomRecord'
import { InputSyncFromIntakeTicketMemo } from './InputSyncFromIntakeTicket'
import { InputSyncHttpMemo } from './InputSyncHttp'
import { InputSyncLineItemMemo } from './InputSyncLineItem'
import { InputSyncTeamMemberMemo } from './InputSyncTeamMember'
import { InputUpdateLineItemMemo } from './InputUpdateLineItem'

export type ActionInputsProps = {
  action: BpaAction
  index: number
  onChange: (data: Partial<BpaAction>, idx: number) => void
}

export const InputShim: FC = () => {
  return (
    <div className="flex items-center justify-center text-warning border w-full h-full rounded">
      <span className="text-center">Action not implemented yet</span>
    </div>
  )
}

export const ActionInputs: FC<ActionInputsProps> = ({ action, index, onChange }) => {
  const handleChange = useCallback(
    (data: Record<string, any>) => {
      onChange(data, index)
    },
    [index, onChange]
  )

  switch (action.type) {
    case 'set_variable':
      return <InputSetVariableMemo action={action} onChange={handleChange} />
    case 'set_ticket_field':
      return <InputSetTicketFieldMemo action={action} onChange={handleChange} />
    case 'set_ticket_property':
      return <InputSetPropertyMemo action={action} onChange={handleChange} />
    case 'set_hidden_reference':
      return <InputSetHiddenReferenceMemo action={action} onChange={handleChange} />
    case 'copy_ticket':
      return <InputCopyTicketMemo action={action} onChange={handleChange} />
    case 'sync_line_item':
      return <InputSyncLineItemMemo action={action as any} onChange={handleChange} />
    case 'add_line_item':
      return <InputSyncLineItemMemo action={action} onChange={handleChange} />
    case 'update_line_item':
      return <InputUpdateLineItemMemo action={action} onChange={handleChange} />
    case 'remove_line_item':
      return <InputRemoveLineItemMemo action={action} onChange={handleChange} />
    case 'reorder_line_items':
      return <InputReOrderLineItemsMemo action={action} onChange={handleChange} />
    case 'sync_team_member':
      return <InputSyncTeamMemberMemo action={action} onChange={handleChange} />
    case 'add_team_member':
      return <InputAddTeamMemberMemo action={action} onChange={handleChange} type="add" />
    case 'remove_team_member':
      return <InputAddTeamMemberMemo action={action as any} onChange={handleChange} type="remove" />
    case 'sync_custom_record':
      return <InputSyncCustomRecordMemo action={action as any} onChange={handleChange} />
    case 'sync_from_intake_ticket':
      return <InputSyncFromIntakeTicketMemo action={action as any} onChange={handleChange} />
    case 'send_custom_email':
      return <InputSendCustomEmailMemo action={action} onChange={handleChange} />
    case 'email_customer_signature':
      return <InputEmailCustomerSignatureMemo action={action} onChange={handleChange} />
    case 'email_vendor_access':
      return <InputEmailCustomerSignatureMemo action={action} onChange={handleChange} />
    case 'submit_to_accounting':
      return <InputSubmitToAccountingMemo action={action} onChange={handleChange} />
    case 'sync_http':
      return <InputSyncHttpMemo action={action} onChange={handleChange} />
    case 'async_http':
      return <InputSyncHttpMemo action={action as any} onChange={handleChange} hideResponse={true} />
    case 'function_call':
      return <InputFunctionCallMemo action={action} onChange={handleChange} />
    case 'add_ticket_subscriber':
      return <InputAddTeamMemberMemo action={action as any} onChange={handleChange} type="add" />
    case 'remove_ticket_subscriber':
      return <InputAddTeamMemberMemo action={action as any} onChange={handleChange} type="remove" />
    case 'sync_ticket_subscribers':
      return <InputSyncTeamMemberMemo action={action as any} onChange={handleChange} />
    case 'attach_printed_ticket':
      return <InputAttachPrintedTicketMemo action={action} onChange={handleChange} />
    case 'etl_extract_data':
      return <InputEtlExtractDataActionMemo action={action} onChange={handleChange} />
    case 'etl_transform_data':
      return <InputEtlTransformDataActionMemo action={action} onChange={handleChange} />
    case 'etl_load_data':
      return <InputEtlLoadDataActionMemo action={action} onChange={handleChange} />
    case 'etl_clickhouse_command':
      return <InputEtlClickhouseCommandActionMemo action={action} onChange={handleChange} />
    default:
      return <InputShim />
  }
}
