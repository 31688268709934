import { Checkbox } from '@/ui'
import { Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSendCustomEmail } from '../../schemas'
import { ContactSelect, CustomEmailSelect } from '../controls'

type Props = {
  action: BpaActionSendCustomEmail
  onChange: (data: Partial<BpaActionSendCustomEmail>) => void
}

export const InputSendCustomEmail: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Custom Email" className="w-1/2">
          <CustomEmailSelect
            defaultValue={{
              value: action.custom_email,
              label: action.customEmailText
            }}
            onChange={(_, option: any) => {
              onChange({ custom_email: option.value, customEmailText: option.label })
            }}
          />
        </Form.Item>
        <Form.Item label="Sender Contact" className="w-1/2">
          <ContactSelect
            defaultValue={{
              value: action.sender,
              label: action.senderText
            }}
            onClear={() => onChange({ sender: undefined, senderText: undefined })}
            onChange={(_, option: any) => {
              onChange({ sender: option.value, senderText: option.label })
            }}
          />
        </Form.Item>
      </div>
      <div className="flex flex-row gap-10">
        {action.asText ? (
          <Form.Item
            label="Recipient Contact IDs (Text)"
            tooltip="Comma separated contact ids or placeholder like P[Key], F[name]"
          >
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={action.recipients || ''}
              onChange={(e) => onChangeDebounced({ recipients: e.target.value })}
            />
          </Form.Item>
        ) : (
          <Form.Item label="Recipient Contact (Select)" className="flex-grow">
            <ContactSelect
              apiValueBy={'id'}
              value={action.recipients}
              onChange={(_, o: any) => onChange({ recipients: o.value })}
            />
          </Form.Item>
        )}

        <Form.Item
          label="As Text"
          tooltip="Using as text will allow using placeholder string in non textual value input"
        >
          <Checkbox checked={action.asText} onChange={(e) => onChange({ asText: e.target.checked as any })} />
        </Form.Item>
      </div>

      <Form.Item label="CC Emails" tooltip="Comma separated email addresses">
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 10 }}
          defaultValue={action.cc_emails || ''}
          onChange={(e) => onChangeDebounced({ cc_emails: e.target.value })}
        />
      </Form.Item>
    </div>
  )
}
export const InputSendCustomEmailMemo = React.memo(InputSendCustomEmail)
