import { Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

const TicketStatusColumn = () => {
  const ticketList = [
    {
      label: 'Draft',
      value: 0
    },
    {
      label: 'Draft - Created',
      value: 1
    },
    {
      label: 'Draft - Open',
      value: 2
    },
    {
      label: 'Draft - Needs Signature',
      value: 3
    },
    {
      label: 'Draft - Signed',
      value: 4
    },
    {
      label: 'Draft - Disputed',
      value: 5
    },

    {
      label: 'Draft - Reviewed',
      value: 6
    },
    {
      label: 'Draft - Closed',
      value: 7
    },
    {
      label: 'Draft - Archived',
      value: 8
    },
    {
      label: 'Draft - Deleted',
      value: 9
    }
  ]

  const selectedTicket =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.ticket_status_limit), [])) ?? null

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)

  const handleChange = (d: number) => {
    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        ticket_status_limit: d
      }
    }))
  }

  return (
    <div>
      <div className="border-l h-full border-r border-b border-secondary">
        <div className="bg-background-accent font-semibold px-[8px] py-[12px] border-b border-secondary">
          Ticket Status
        </div>
        <div className="px-12 py-16">
          <div>
            <Select
              className="w-full"
              showSearch
              placeholder="Select a ticket status"
              value={selectedTicket}
              options={ticketList}
              onChange={(id: number) => handleChange(id)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketStatusColumn
