import { useApp } from '@/hooks'
import { get } from 'lodash'
import { useState } from 'react'

import { useButtonContext } from '../../../../hooks'
import { WellData } from '../types'
import { processWell } from '../utils'
import { useFillProperties } from './use-fill-properties'
import { useSaveLocation } from './use-save-location'

export const useHandleSave = () => {
  const { notification } = useApp()
  const [isSaving, setIsSaving] = useState(false)
  const { saveLocation } = useSaveLocation()
  const { fillProperties } = useFillProperties()
  const { property, ticket, saveTicket } = useButtonContext()

  const handleSave = async (selectedWell: WellData) => {
    const ticketData: Record<string, any> = { property: {} }

    try {
      setIsSaving(true)
      const well = await processWell(selectedWell)

      // store raw well json data
      ticketData.property[property.key] = JSON.stringify(well)

      // populate properties having alias filed `wsn.*` and `location.*`
      fillProperties.forEach((p) => {
        ticketData.property[p.key] = get(well, p.wellKey)
      })

      // set customer office
      if (selectedWell.customerId !== ticket.customer_office_id) {
        ticketData['customer_office'] = selectedWell.customerId
      }

      // set location
      const location = await saveLocation(well)
      ticketData['location'] = location.id
      await saveTicket(ticketData)

      setIsSaving(false)
      notification.success({
        message: 'Save Well Data',
        description: 'Well data has been fetched successfully!'
      })
    } catch (e) {
      notification.error({
        message: 'Save Failed',
        description: 'Failed to save well data!'
      })
      console.error(e)
      setIsSaving(false)
    }
  }

  return { isSaving, handleSave }
}
