import { Icon } from '@/ui/icons'
import { TooltipPlacement } from 'antd/es/tooltip'
import { FC } from 'react'
import { CopyToClipboard, Options } from 'react-copy-to-clipboard'
import { TipButton, TipButtonProps } from '../tip-button'

type Props = TipButtonProps & {
  copyText: string
  onCopy?(text: string, result: boolean): void
  copyOptions?: Options | undefined
  tooltipPlacement?: TooltipPlacement
}

export const CopyButton: FC<Props> = ({ copyText, onCopy, copyOptions, tooltipPlacement, children, ...rest }) => {
  rest.icon = rest.icon ?? <Icon name="fa:copy" />

  return (
    <CopyToClipboard text={copyText} onCopy={onCopy} options={copyOptions}>
      <TipButton {...rest} flashTitle="Copied">
        {children}
      </TipButton>
    </CopyToClipboard>
  )
}
