import { ISO_DATE_FORMAT } from '@/constants/date'
import { getDateRangeByView } from '@/modules/jas/scheduler/helpers'
import { CalendarView } from '@/modules/jas/scheduler/types'
import { SCHEDULE_TYPES, ScheduleType } from '@/utils'
import { Spin } from 'antd'
import { default as classNames, default as cn } from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useRef } from 'react'
import { CalendarTableRow } from './calendar-table-row'
import { HeadDayCell } from './head-day-cell'
import { MonthWeekChanger } from './month-week-changer'
import { TableFooter } from './table-footer'

type Record = {
  id: number
  [key: string]: any
}

type Props<T extends Record> = {
  data: T[]
  totalItemsCount?: number
  title?: React.ReactNode
  titleDataIndex?: string
  date: Dayjs
  loading?: boolean
  visibleDays?: CalendarView
  disabledDays?: Dayjs[]
  pastDaysDisabled?: boolean
  elementBeforeDays?: React.ReactNode
  elementBeforeTitle?: React.ReactNode
  tableTopRight?: React.ReactNode
  withFooter?: boolean
  renderCustomTableFooter?: (dates: Dayjs[]) => React.ReactNode
  scheduleLabels?: (typeof SCHEDULE_TYPES)[ScheduleType][]
  tableClassName?: string
  autoScroll?: boolean
  withFullHeight?: boolean
  onPaginationChange?: (page: number, pageSize?: number) => void
  renderTitleCell?: (record: T) => React.ReactNode
  renderDateCell?: (date: Dayjs, record: T) => React.ReactNode
  onLoadMore?: () => void
  defaultLimit?: number
  onLimitChange?: (value: number) => void
  onDateChange?: (date: Dayjs) => void
  hiddenRows?: Set<number>
}

export const CalendarTable = <T extends Record>(props: Props<T>) => {
  const {
    data,
    date,
    title,
    loading,
    visibleDays = 'month',
    tableClassName,
    autoScroll,
    withFullHeight = true,
    elementBeforeDays,
    elementBeforeTitle,
    tableTopRight,
    withFooter,
    renderCustomTableFooter,
    scheduleLabels,
    renderTitleCell = () => null,
    renderDateCell,
    onLoadMore = () => null,
    onDateChange = () => null,
    hiddenRows
  } = props

  const dateStr = date.format(ISO_DATE_FORMAT)
  const highlightDate = visibleDays === 'month' ? dayjs() : date

  // using dateStr to avoid unnecessary re-render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dateColumns: Dayjs[] = useMemo(() => getDateRangeByView(visibleDays, date), [dateStr, visibleDays])

  // scroll
  const tableRef = useRef<any>(null)

  useEffect(() => {
    if (autoScroll) {
      if (tableRef.current) {
        const dt = date.date()
        const scrollableWidth = tableRef.current.scrollWidth - tableRef.current.offsetWidth
        const cellWidth = scrollableWidth / dateColumns.length
        const scrollValue = dt > 15 ? cellWidth * (dt - 2) : 0
        tableRef.current.scrollTo(scrollValue, 0)
      }
    }

    // using dateStr to avoid unnecessary re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll, dateStr, dateColumns.length])

  return (
    <div className="flex flex-col items-start">
      <div className="flex justify-between w-full items-center">
        <div className="flex">
          <div className="w-[240px] shrink-0">{elementBeforeTitle ? elementBeforeTitle : null}</div>
          <div>
            <div className="py-6 px-6">
              {elementBeforeDays ? (
                elementBeforeDays
              ) : (
                <MonthWeekChanger
                  date={date}
                  view={visibleDays}
                  onChange={(date: Dayjs) => {
                    onDateChange(date)
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {tableTopRight}
      </div>

      <div
        ref={tableRef}
        className={cn('w-full overflow-auto', tableClassName)}
        style={{
          maxHeight: withFullHeight ? 'calc(100vh - 250px)' : '', // TODO: Try not to hardcode this
          minHeight: withFullHeight && !renderCustomTableFooter ? 'calc(100vh - 250px)' : '' // TODO: Try not to hardcode this
        }}
      >
        <div className="flex sticky top-0 z-50">
          <div className="w-[240px] shrink-0 sticky left-0 border-0 border-solid bg-white z-10 border-r border-b border-border">
            {title}
          </div>
          <div className="flex w-full">
            {dateColumns.map((dt, i) => (
              <HeadDayCell key={i} date={dt} index={i} visibleDays={visibleDays} highlightDate={highlightDate} />
            ))}
          </div>
        </div>
        <Spin
          spinning={loading}
          wrapperClassName={classNames({
            'h-[200px]': data.length === 0
          })}
        >
          <div className="w-full">
            {data.map((record, i) => (
              <CalendarTableRow
                key={i}
                record={record}
                dateColumns={dateColumns}
                renderTitleCell={renderTitleCell}
                renderDateCell={renderDateCell}
                visibleDays={visibleDays}
                rowIndex={i}
                hiddenRows={hiddenRows}
              />
            ))}
            {data.length > 0 && renderCustomTableFooter && renderCustomTableFooter(dateColumns)}
          </div>
        </Spin>
      </div>
      {withFooter ? <TableFooter onLoadMore={onLoadMore} scheduleLabels={scheduleLabels} /> : null}
    </div>
  )
}
