import { ViewTicketLink } from '@/modules/ticket/components'
import { useRefetchTickets } from '@/modules/ticket/hooks'
import { useTicketSelection } from '@/modules/ticket/list/hooks'
import { Button, ButtonProps, Modal, ModalProps } from '@/ui'
import { Badge } from 'antd'
import { FC, ReactElement, useCallback, useState } from 'react'

export const TicketBulkAction: FC<{
  button?: ButtonProps
  modal?: Omit<ModalProps, 'onOk'> & {
    onOk?: (closeFn: (isSuccess?: boolean) => void) => any
  }
  optionsTitle?: string
  children?: ReactElement
  onCancel?: (closeFn: () => void) => void
}> = ({ button, modal, onCancel, optionsTitle, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { selectedTickets, selectedTicketsCount, allTicketsSelected, setSelectedTickets } = useTicketSelection()
  const { refetchTickets } = useRefetchTickets()

  const onClose = useCallback(() => {
    if (onCancel) onCancel(() => setIsOpen(false))
    else setIsOpen(false)
  }, [onCancel])

  const onOk = useCallback(() => {
    if (modal?.onOk)
      modal.onOk((isSuccess) => {
        setIsOpen(false)
        if (isSuccess) {
          setSelectedTickets([])
          refetchTickets()
        }
      })
  }, [modal, refetchTickets, setSelectedTickets])

  return (
    <div>
      <Button type={'default'} size="small" iconName={'fa:copy'} onClick={() => setIsOpen(true)} {...button} />
      <Modal
        title={'Bulk Action'}
        open={isOpen}
        onCancel={onClose}
        width={'calc(100vw - 50%)'}
        withScreenMaxHeight={true}
        {...modal}
        onOk={onOk}
      >
        <div className={'flex flex-row gap-10'}>
          <div className={'w-4/12 border dark:border-dark-border rounded'}>
            <Badge.Ribbon color={'gold'} text={selectedTicketsCount} />
            <h5 className={'text-center border-b dark:border-b-dark-border p-10'}>Selected Tickets</h5>
            <div className={'flex flex-row flex-wrap items-center px-12 py-6 gap-12'}>
              {allTicketsSelected && (
                <Button size="small" primary shape="round" type="dashed">
                  Preview is not available
                </Button>
              )}
              {!allTicketsSelected &&
                selectedTickets.map((ticket) => (
                  <div
                    key={ticket.id}
                    className="border border-warning-300 rounded bg-primary-200 flex flex-row justify-between items-center gap-8 pl-6 pr-2"
                  >
                    <ViewTicketLink key={ticket.id} ticketId={ticket.id} target={'_blank'}>
                      <span>#{ticket.name}</span>
                    </ViewTicketLink>
                    <div className="flex flex-row-reverse">
                      <Button
                        size="small"
                        iconName="fa:xmark"
                        type="text"
                        onClick={() => setSelectedTickets(selectedTickets.filter((t) => t.id !== ticket.id))}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={'w-8/12 border dark:border-dark-border rounded'}>
            <h5 className={'text-center border-b dark:border-b-dark-border p-10'}>{optionsTitle || 'Options'}</h5>
            <div className={'flex flex-col gap-16 mt-10 px-12 py-6'}>{children}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
