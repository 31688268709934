import { useApp } from '@/hooks'
import { apiHttp } from '@/services/api-service/core'
import { Button, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Space, Switch } from 'antd'
import { useState } from 'react'

type Props = {
  summaryTicketId?: number
}

export const DownloadSummaryTicketButton = ({ summaryTicketId }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { notification } = useApp()

  const downloadSummaryTicketMutation = useMutation({
    mutationFn: async (variables: any) => {
      const res = await apiHttp.get<any>(`tickets/${summaryTicketId}/print.pdf`, {
        params: variables,
        responseType: 'blob'
      })

      return {
        data: res.data,
        headers: res.headers
      }
    },
    mutationKey: ['tickets', 'print', summaryTicketId]
  })

  const [payload, setPayload] = useState<Record<string, any>>({
    stylesheets: 'default',
    attachments: 0,
    line_item_attachments: 0,
    show_internal_use_only: 0
  })

  const handleDownloadSummaryTicket = async () => {
    if (!payload.stylesheets) {
      notification.error({ message: 'Please select at least one print style' })
      return
    }

    await downloadSummaryTicketMutation.mutateAsync(
      Object.keys(payload).reduce((acc, key) => {
        if (payload[key]) {
          acc[key] = payload[key]
        }
        return acc
      }, {} as any),
      {
        onSuccess: (data: any) => {
          const fileName = data.headers['content-disposition']?.split('filename=')[1]
          const url = window.URL.createObjectURL(data.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      }
    )

    setIsOpen(false)
  }

  return (
    <>
      <Button type="primary" block iconName="fa:file-download" onClick={() => setIsOpen(true)}>
        Download Summary Ticket
      </Button>
      <Modal
        title="Download Ticket"
        open={isOpen}
        okText="Download"
        onOk={handleDownloadSummaryTicket}
        onCancel={() => setIsOpen(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Select styles to print">
            <Space>
              <Switch
                checked={payload.stylesheets === 'default'}
                onChange={(checked) => setPayload((prev) => ({ ...prev, stylesheets: checked ? 'default' : '' }))}
              />
              <span>Default</span>
            </Space>
          </Form.Item>
          <Form.Item label="Select which bottom sections you'd like to include" className="mb-0">
            <Form.Item>
              <Space>
                <Switch onChange={(checked) => setPayload((prev) => ({ ...prev, attachments: checked ? 1 : 0 }))} />
                <span>Attachments</span>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <Switch
                  onChange={(checked) => setPayload((prev) => ({ ...prev, show_internal_use_only: checked ? 1 : 0 }))}
                />
                <span>Include Internal Use Only Sections</span>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <Switch
                  onChange={(checked) => setPayload((prev) => ({ ...prev, line_item_attachments: checked ? 1 : 0 }))}
                />
                <span>Line Item Attachments</span>
              </Space>
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
