import { Image } from '@/ui'
import cn from 'classnames'
import {
  useCustomForm,
  useTicketCustomer,
  useTicketCustomerOffice,
  useTicketFlags,
  useTicketLabel,
  useTicketOffice
} from '../../../hooks'
import { EditCustomerButton } from '../../buttons/customer-buttons'

export const TicketInfo = () => {
  const l = useTicketLabel()
  const { viewOnly } = useTicketFlags()

  const { customForm, isHiddenField } = useCustomForm()
  const office = useTicketOffice()
  const customerOffice = useTicketCustomerOffice()
  const customer = useTicketCustomer()

  return (
    <div className="flex flex-col gap-12">
      <div className={cn('grid grid-cols-2 grid-rows-2 gap-10', '[&>div]:py-6 [&>div]:px-10')}>
        <div>
          {office && (
            <>
              <h5 className={'line-clamp-1'}>{office.company__name}</h5>
              <div>{office.address__line1}</div>
              <div>
                {office.address__city}, {office.address__state} {office.address__zipcode}
              </div>
              <div>{office.phone_number}</div>
            </>
          )}
        </div>
        <div className={'flex items-center justify-center overflow-hidden !p-30'}>
          <Image
            className={'!h-[80px] bg-left'}
            src={customForm.ticket_logo || customForm.primary_company__icon || 'blank'}
            media
            background
          />
        </div>

        <div>
          {!!customerOffice?.id && !isHiddenField('customer_office') && (
            <div>
              <h5 className={'line-clamp-1'}>{l('ticket__services_provided__label')}:</h5>
              <div>{customerOffice?.name || 'N/A'}</div>
              <div>{customerOffice?.address__line1}</div>
              {customerOffice?.address_id ? (
                <div>
                  {customerOffice?.address__city},&nbsp;
                  {customerOffice?.address__state}&nbsp;
                  {customerOffice?.address__zipcode}
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          )}
        </div>

        <div>
          {!!customer?.id && !isHiddenField('customer') && (
            <div>
              <h5 className={'line-clamp-1'}>{l('ticket__services_ordered__label')}:</h5>
              <div>
                {customer?.first_name} {customer?.last_name}
                <EditCustomerButton
                  customerId={customer?.id}
                  primary={true}
                  size={'small'}
                  type={'text'}
                  iconName={'fa:edit'}
                  disabled={viewOnly}
                  modalProps={{
                    iconName: 'fa:edit',
                    title: `Edit ${l('ticket__contact__label')}`
                  }}
                />
              </div>
              <div>{customer?.phone_number}</div>
              <div>{customer?.email}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
