import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../../atoms'
import { Company } from '../../../../types'

type Props = {
  company: Company
  permission: 'write' | 'create' | 'list'
  disabled?: boolean
}

export const PermissionCheckbox = ({ company, permission, disabled }: Props) => {
  const accessRules = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.access_rules), []))
  const setCustomForm = useSetAtom(customFormAtom)

  const handleChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked

    setCustomForm((prev) => {
      const newAccessRules = prev.access_rules?.map((rule) => {
        if (rule.company === company.id) {
          return {
            ...rule,
            [permission]: checked
          }
        }
        return rule
      })

      return {
        ...prev,
        access_rules: newAccessRules
      }
    })
  }

  const checked = accessRules?.some((rule) => rule.company === company.id && rule[permission] === true)

  return <Checkbox disabled={disabled} checked={checked} onChange={handleChange} data-cy="permission-checkbox" />
}
