import { ticketImportTemplateApi } from '@/services/api-service'
import { ActionButton, Button, ButtonProps, Icon, QueryDeleteButton } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Avatar, Card, Form, List } from 'antd'
import { DownloadTemplateButton } from './DownloadTemplateButton'
import { UploadDataButton } from './UploadDataButton'
import { Template, TemplateSchema } from './schemas'

export function ImportTemplatesCard() {
  const form = Form.useFormInstance()
  const templatesQuery = useQuery(ticketImportTemplateApi.list({ fields: TemplateSchema }))
  const templates = templatesQuery.data?.items

  return (
    <Card
      className={'h-full'}
      title={'Import Templates'}
      styles={{ body: { padding: '0' } }}
      extra={
        <Button
          size={'small'}
          iconName={'fa:plus'}
          shape={'round'}
          onClick={() => form.setFieldsValue({ mode: 'template-form', key: null })}
        >
          Create Template
        </Button>
      }
    >
      <div className={'h-[550px] overflow-auto p-12'}>
        <List<Template>
          bordered={false}
          loading={templatesQuery.isLoading}
          itemLayout="vertical"
          dataSource={templates || []}
          renderItem={(t) => (
            <List.Item
              extra={
                <div className={'flex flex-col gap-4 pr-4'}>
                  <ActionButton
                    key={'edit'}
                    size={'small'}
                    iconName={'fa:edit'}
                    onClick={() => form.setFieldsValue({ mode: 'template-form', key: t.id, [`data${t.id}`]: t })}
                  />
                  <ImportTemplateCopyButton template={t} />
                  <QueryDeleteButton
                    key={'delete'}
                    size={'small'}
                    api={ticketImportTemplateApi.delete}
                    id={t.id}
                    name={'Template'}
                  />
                </div>
              }
              actions={[
                <div key={'actions'} className={'flex flex-row flex-wrap gap-5 w-full'}>
                  <DownloadTemplateButton id={t.id} name={t.name} />
                  <UploadDataButton id={t.id} name={t.name} />
                </div>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon={<Icon name={'fa:file-excel'} className={'text-primary'} />}
                    className={'bg-primary-50'}
                  />
                }
                title={t.name}
                description={t.description}
              />
            </List.Item>
          )}
        />
      </div>
    </Card>
  )
}

function ImportTemplateCopyButton({ template, ...props }: ButtonProps & { template: Template }) {
  const cloneMutation = useMutation(ticketImportTemplateApi.clone())

  return (
    <ActionButton
      size={'small'}
      iconName={'fa:copy'}
      confirm={{
        placement: 'topRight',
        title: 'Confirm Template Copy',
        description: `Copying Template #${template.id}, are you sure?`,
        okText: 'Yes, Copy',
        cancelText: 'Cancel',
        onConfirm: () => cloneMutation.mutateAsync({ id: template.id })
      }}
      {...props}
    />
  )
}
