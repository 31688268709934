import { deepMerge, parseQueryString, stringifyQueryString } from '@/utils'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Hook for managing state in the URL query string.
 */
export function useUrlState<T extends Record<string, any>>(
  initialState: T = {} as T
): [T, (newState: Partial<T>) => void] {
  return [useGetUrlState<T>(initialState), useSetUrlState<T>()]
}

/**
 * Hook for getting state from the URL query string.
 */
export function useGetUrlState<T extends Record<string, any>>(initialState: T = {} as T): T {
  const location = useLocation()

  return useMemo(
    () => ({
      ...initialState,
      ...(parseQueryString(location.search) as T)
    }),
    [initialState, location.search]
  )
}

/**
 * Hook for setting state in the URL query string.
 * Useful for setting state without needing to read (re-render) it.
 */
export function useSetUrlState<T extends Record<string, any>>(): (newState: Partial<T>) => void {
  const navigate = useNavigate()

  return useCallback(
    (newState: Partial<T>) => {
      const currentState = parseQueryString(location.search) as T
      const newQueryString = stringifyQueryString(deepMerge(currentState, newState, { concat: false }))
      const newUrl = `${location.pathname}?${newQueryString}`
      navigate(newUrl, { replace: true })
    },
    [navigate]
  )
}

/**
 * Hook for refreshing components when URL state changes.
 * @param callback
 */
export function useUrlStateRefresh(callback?: (event: CustomEvent) => void) {
  const eventName = 'url-state-refresh'

  const refresh = useCallback((delay = 50) => {
    setTimeout(() => dispatchEvent(new CustomEvent(eventName)), delay)
  }, [])

  useEffect(() => {
    if (!callback) return
    addEventListener('url-state-refresh', callback as EventListener)
    return () => removeEventListener('url-state-refresh', callback as EventListener)
  }, [callback, eventName])

  return { refresh }
}

/**
 * Hook for getting state from the URL query string on initial render.
 * This will not cause re-renders more than once.
 */
export function useGetInitialUrlState<T extends Record<string, any>>(initialState: T = {} as T): T {
  const initialUrlState = useRef<T>()
  if (!initialUrlState.current) {
    initialUrlState.current = getUrlState<T>()
  }

  return { ...initialState, ...initialUrlState.current }
}

/**
 * Utility function for getting state from the URL query string.
 */
export function getUrlState<T extends Record<string, any>>(initialState: T = {} as T): T {
  return { ...initialState, ...(parseQueryString(location.search) as T) }
}
