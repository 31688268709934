import { Ticket } from '../schemas'

export const REGULAR_FIELDS: (keyof Ticket)[] = ['name', 'status']
export const DATE_FIELDS: ('timepoint_due' | 'timepoint_submitted')[] = ['timepoint_due', 'timepoint_submitted']
export const RELATED_FIELDS: string[] = [
  'customer_office',
  'customer',
  'location',
  'afe',
  'job_code',
  'support_office',
  'assignee'
]
export const DATA_FIELDS: string[] = ['extra_data__tab_indicator_color']

export const ALL_FIELDS: string[] = REGULAR_FIELDS.concat(DATE_FIELDS)
  .concat(RELATED_FIELDS as any[])
  .concat(DATA_FIELDS as any[])
