import { z } from 'zod'

// schemas
export const ButtonConfigSchema = z.object({
  type: z.string(),
  icon: z.string().nullish(),
  align: z.enum(['left', 'center', 'right', 'stretch']).nullish(),
  variant: z.enum(['default', 'primary', 'success', 'info', 'danger']).nullish(),
  shape: z.enum(['default', 'round', 'circle']).nullish(),
  vars: z.record(z.string(), z.any()).nullish(),
  force_enable: z.boolean().nullish()
})

export const PropertySchema = z.object({
  id: z.number(),
  key: z.string(),
  name: z.string(),
  sequence: z.number().nullable(),
  hide_on_web: z.boolean(),
  required: z.boolean(),
  read_only: z.boolean(),
  values: z.string().nullable(),
  layout: z.string().nullable(),
  property_group_id: z.number(),
  property_type_id: z.number(),
  script: z.string().nullable(),
  button_config: z.record(z.string(), z.any()).nullable(),
  editable_on_secure_access: z.boolean(),

  _field: z.string(), // `p_${id}`

  _width: z.number(),
  _skip: z.number(),

  _options: z.array(z.object({ value: z.string(), label: z.string() })),
  _optionsMap: z.record(z.string(), z.object({ value: z.string(), label: z.string() })),

  // alias for `button_config` with type
  _buttonConfig: ButtonConfigSchema.nullable()
})
const PropertyGroupSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  sequence: z.number().nullable(),

  visible_name: z.boolean(),
  hide_on_web: z.boolean(),
  internal_use_only: z.boolean(),
  hide_from_customer: z.boolean(),
  section_hide_script: z.string().nullable(),

  bordered: z.boolean(),
  print_class: z.string(),

  collapsable: z.boolean(),
  start_collapsed: z.boolean(),
  collapse_hide_script: z.string().nullable(),

  line_item_placeholder: z.boolean(),

  _properties: z.array(PropertySchema)
})

export const CustomFormPermissionSchema = z.object({
  company_id: z.number(),
  create: z.boolean(),
  write: z.boolean(),
  list: z.boolean()
})

export const CustomFormSchema = z.object({
  id: z.number(),
  name: z.string(),
  userdata_json: z.string().nullable(),

  group_line_items: z.boolean().nullable(),
  group_by_kind: z.boolean().nullable(),
  default_group_kind_id: z.number().nullable(),

  allow_empty_date_web: z.boolean(),

  show_totals: z.boolean().nullable(),

  editable_ticket_name: z.boolean(),
  require_ticket_date: z.boolean(),
  show_ticket_date: z.boolean(),
  show_submission_date: z.boolean(),

  show_third_party: z.boolean(),
  show_assignee: z.boolean(),
  hide_attachments: z.boolean(),
  hide_activity_log: z.boolean(),

  view_only_customer: z.boolean(),
  permissions: z.array(CustomFormPermissionSchema),

  default_customer_office_id: z.number().nullable(),
  default_customer_office__name: z.string().nullable(),

  default_customer_contact_id: z.number().nullable(),
  default_customer_contact__first_name: z.string().nullable(),
  default_customer_contact__last_name: z.string().nullable(),

  // remove_wording_suffix: z.boolean(),
  // swap_wording: z.boolean(),

  ticket_logo: z.string().nullable(),

  primary_company_id: z.number().nullable(),
  primary_company__icon: z.string().nullable(),

  property_groups: z.array(PropertyGroupSchema),
  properties: z.array(PropertySchema),

  use_form_sequence: z.boolean(),
  ticket_gen_number: z.number().nullable(),
  primary_company__ticket_gen_number: z.number().nullable(),

  print_styles: z.array(z.object({ id: z.number(), name: z.string() })),

  enable_accounting: z.boolean(),

  _propertyById: z.record(z.number(), PropertySchema),
  _propertyByKey: z.record(z.string(), PropertySchema),
  _propertiesByTypeId: z.record(z.number(), z.array(PropertySchema)),
  _fieldsConfig: z.record(z.string(), z.object({ hide_on_web: z.boolean(), disabled: z.boolean() })),
  _fieldAliases: z.record(z.string(), z.string()),
  _userData: z.any(),

  _features: z.object({
    /** userdata_json.custom_status */
    customStatus: z.record(z.string(), z.string()).nullable(),

    /** userdata_json.contact_search */
    contactSearch: z.object({ enabled: z.boolean(), company: z.number() }).nullable(),

    /** userdata_json.dynamic_form_name */
    dynamicFormName: z.object({ bind_to_pid: z.number(), enabled: z.boolean() }).nullable(),

    /** userdata_json.restrict_print */
    restrictPrint: z
      .object({ enabled: z.boolean(), statuses: z.array(z.string()).nullish(), pid: z.number().nullish() })
      .nullable(),

    restrictEdit: z
      .object({
        enabled: z.boolean(),
        statuses: z.array(z.string()).nullish(),
        pid: z.number().nullish(),
        except_contacts_pid: z.number().nullish(),
        msg: z.string().nullish()
      })
      .nullable(),

    /** userdata_json.print */
    printOptions: z
      .object({
        default_options: z
          .object({
            attachments: z.boolean(),
            show_internal_use_only: z.boolean(),
            line_item_attachments: z.boolean()
          })
          .nullish()
      })
      .nullable(),

    /** userdata_json.sku_search */
    skuSearch: z.object({ enabled: z.boolean(), fields: z.array(z.string()).nullish() }).nullable(),

    /** TODO: userdata_json.custom_sku_selection */
    skuSelection: z
      .object({
        enabled: z.boolean(),
        multiadd: z.boolean(),
        description: z.string().nullish(),
        filter: z.record(z.string(), z.any()).nullish(),
        persistent_filter: z.record(z.string(), z.any()).nullish(),
        display_columns: z
          .array(
            z.object({
              type: z.string(),
              name: z.string(),
              array_expr: z.string(),
              name_expr: z.string(),
              value_expr: z.string(),
              context: z.object({
                datasource: z.object({
                  cache: z.boolean(),
                  key_expr: z.string(),
                  value_expr: z.string(),
                  query: z.object({
                    url: z.string(),
                    params: z.record(z.string(), z.string())
                  })
                })
              })
            })
          )
          .nullish()
      })
      .nullable(),

    /** userdata_json.hide_item_fields */
    hideItemFields: z.object({ enabled: z.boolean(), fields: z.array(z.string()).nullish() }).nullable(),

    /** userdata_json.pricing_tweaks */
    pricingTweaks: z
      .object({
        enabled: z.boolean(),
        show_for_customer: z.boolean(),
        masked_fields: z.array(z.string()).nullish()
      })
      .nullable(),

    /** userdata_json.restrict_lineitem_edits */
    restrictLineItemEdits: z.boolean(),

    /** userdata_json.each_unit_qty_check */
    eachUnitQtyCheck: z.boolean(),

    /** userdata_json.report_buttons */
    reportButtons: z.array(
      z
        .object({
          icon: z.string().nullish(),
          label: z.string().nullish(),
          reports: z
            .array(
              z.object({
                type: z.string().nullish(),
                label: z.string().nullish(),
                programmed_report_id: z.number(),
                property_key: z.string().nullish(),
                filter: z.record(z.string(), z.string()).nullable(),
                // computed fields
                _payPeriod: z
                  .object({
                    property: PropertySchema.nullish(),
                    value: z.any()
                  })
                  .nullish()
              })
            )
            .nullish()
        })
        .nullable()
    )
  })
})

// types
export type ButtonConfig = z.infer<typeof ButtonConfigSchema>
export type Property = z.infer<typeof PropertySchema>
export type PropertyGroup = z.infer<typeof PropertyGroupSchema>
export type CustomForm = z.infer<typeof CustomFormSchema>
export type CustomFormPermission = z.infer<typeof CustomFormPermissionSchema>
