import { useSession } from '@/hooks'
import { impersonateUserApi } from '@/services/api-service'
import { checkAccess, setImpersonatingToken } from '@/services/auth-service'
import { Permission } from '@/types/auth-user'
import { useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'

export const useIsSuperAdmin = (): boolean => {
  const { permissions } = useSession()
  return useMemo(() => checkAccess(permissions, ['super_admin']), [permissions])
}

export const useIsManager = (): boolean => {
  const { permissions } = useSession()
  return useMemo(() => checkAccess(permissions, ['super_admin', 'company_admin', 'ticket_manager']), [permissions])
}

export const useIsCompanyAdmin = (): boolean => {
  const { permissions } = useSession()
  return useMemo(() => checkAccess(permissions, ['super_admin', 'company_admin']), [permissions])
}

export const useIsSupport = (): boolean => {
  const { permissions } = useSession()
  return useMemo(() => checkAccess(permissions, ['super_admin', 'support']), [permissions])
}

export const useCanEditCustomFormCallback = () => {
  const { user, company } = useSession()
  const isSupportUser = useIsSupport()

  return ({ primary_company_id }: { primary_company_id?: number | null }) =>
    user?.is_superuser || (isSupportUser && company.id === primary_company_id)
}

export const useImpersonate = () => {
  const [id, setId] = useState(0)
  const mutationSpec = impersonateUserApi(id).create()
  const impersonateMutation = useMutation({
    ...mutationSpec,
    onSuccess: (res) => {
      setImpersonatingToken(`JWT ${res?.token}`)
      location.reload()
    },
    onError: () => {}
  })
  const create = (id: number) => {
    setId(id)
    impersonateMutation.mutate(null)
  }
  return { create }
}

export const useCheckAccess = (checkPerms: Permission[]) => {
  const { permissions } = useSession()
  return useMemo(() => checkAccess(permissions, checkPerms), [permissions, checkPerms])
}
