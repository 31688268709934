import { Fields } from '@/components/format-picker/types'
import { TICKET_FIELDS } from '@/modules/settings/custom-forms/custom-form-layout-view/property-groups/edit-property-modal/bpa-builder/constants'
import { propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormInstance } from 'antd'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'

export const useAllFields = (form?: FormInstance) => {
  const selectedCustomForms = Form.useWatch('custom_forms', form) || []

  const propertiesQuery = useQuery({
    ...propertyApi.list<{
      id: number
      name: string
      key: string
      property_group__name: string
      property_type__name: string
    }>({
      limit: 'None',
      property_group__custom_form_id__in: selectedCustomForms.join(','),
      fields: 'id,name,key,property_group__name,property_type__name'
    }),
    enabled: !!selectedCustomForms.length
  })

  const properties: Fields[] = useMemo(
    () =>
      (propertiesQuery.data?.items || []).map((item) => ({
        key: nanoid(),
        title: `${item.property_group__name} / ${item.name}`,
        text: `P[${item.key}]`,
        isHeader: false,
        hide: false
      })),
    [propertiesQuery.data?.items]
  )

  const attachmentLinks: Fields[] = useMemo(
    () =>
      (propertiesQuery.data?.items || [])
        .filter((item) => ['Attachment', 'Image'].includes(item.property_type__name))
        .map((item) => ({
          key: nanoid(),
          title: `Attachment Links / ${item.name}`,
          text: `C[attachment_urls.${item.key}]`,
          isHeader: false,
          hide: false
        })),
    [propertiesQuery.data?.items]
  )

  const tickets = useMemo(
    () =>
      TICKET_FIELDS.map((item) => ({
        key: nanoid(),
        title: item.label,
        text: `F[${item.value}]`,
        isHeader: false,
        hide: false
      })),
    []
  )

  const fields: Fields[] = useMemo(
    () => [
      { key: '1', title: 'Ticket Fields', text: '', isHeader: true, hide: false },
      ...tickets,
      { key: '2', title: 'Properties', text: '', isHeader: true, hide: false },
      ...(selectedCustomForms?.length ? properties : []),
      ...(selectedCustomForms?.length ? attachmentLinks : []),
      { key: '3', title: 'Context', text: '', isHeader: true, hide: false },
      ...CONTEXT_FIELDS
    ],
    [properties, tickets, selectedCustomForms?.length]
  )

  const allFields = useMemo(() => [...fields, ...tickets, ...properties], [fields, properties, tickets])

  return { isLoading: propertiesQuery.isFetching, fields, allFields }
}

const CONTEXT_FIELDS = [
  {
    key: 'contact',
    title: 'Contact',
    text: '{{contact}}'
  },
  {
    key: 'contact__first_name',
    title: 'Contact First Name',
    text: '{{contact__first_name}}'
  },
  {
    key: 'contact__last_name',
    title: 'Contact Last Name',
    text: '{{contact__last_name}}'
  },
  {
    key: 'ticket_url',
    title: 'Ticket URL',
    text: '{{ticket_url}}'
  },
  {
    key: 'assignment_url',
    title: 'Assignment URL',
    text: '{{assignment_url}}'
  },
  {
    key: 'unsubscribe_url',
    title: 'Unsubscribe URL',
    text: '{{unsubscribe_url}}'
  },
  {
    key: 'site_name',
    title: 'Site Name',
    text: '{{site_name}}'
  },
  {
    key: 'site_domain',
    title: 'Site Domain',
    text: '{{site_domain}}'
  },
  {
    key: 'webapp_site',
    title: 'Webapp Site',
    text: '{{webapp_site}}'
  }
]
