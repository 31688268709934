import { generateAvatarInitials } from '@/utils'
import { Avatar, Tooltip } from 'antd'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import React from 'react'
import { JobResource } from '../schemas'

type Props = {
  type?: 'default' | 'primary'
  title?: string
  text?: string
  resource?: JobResource
}

export const JobAvatar: React.FC<Props> = ({ type = 'primary', title, text, resource }) => {
  return resource ? (
    <JobAvatarResource resource={resource} type={type} />
  ) : (
    <JobAvatarBase type={type} title={title} text={text} />
  )
}

const JobAvatarResource: React.FC<{
  type: 'default' | 'primary'
  resource: JobResource
}> = ({ type, resource }) => {
  const title = resource.name
  let text = resource.short_code
  if (isEmpty(text)) {
    text = resource.type === 'Equipment' ? resource.name.slice(0, 3) : generateAvatarInitials(resource.name)
  }
  return (
    <Tooltip placement="bottom" title={title}>
      <Avatar
        size={'small'}
        className={cn({
          'bg-primary-100 text-primary-600 dark:bg-gray-600 dark:text-gray-400': type === 'primary',
          'bg-gray-200 text-gray-500': type === 'default'
        })}
      >
        {text}
      </Avatar>
    </Tooltip>
  )
}

const JobAvatarBase: React.FC<Props> = ({ type = 'primary', title, text }) => {
  return (
    <Tooltip placement="bottom" title={title}>
      <Avatar
        size={'small'}
        className={cn({
          'bg-primary-100 text-primary-400 dark:bg-gray-600 dark:text-gray-400': type === 'primary',
          'bg-gray-200 text-gray-500': type === 'default'
        })}
      >
        {text}
      </Avatar>
    </Tooltip>
  )
}
