import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { Checkbox, Form, Table } from 'antd'
import { useAtom } from 'jotai'
import { selectedAccountRoleAtom } from './atoms'
import { CustomFormColumn } from './custom-form-column'
import { CustomNavigationColumn } from './custom-navigation-column'
import { DeleteButton } from './delete-button'
import { useAccountRolesQuery } from './hooks'
import { ModulesColumn } from './modules-column'
import { NotificationsColumns } from './notifications-columns'
import { PermissionsColumn } from './permissions-column'
import { RoleChangeSelect } from './role-change-select'
import { RoleNameField } from './role-name-field'
import { SaveButton } from './save-button'
import { SkuAccessColumn } from './sku-access-column'
import TicketStatusColumn from './ticket-status-column/TicketStatusColumn'
import { ManageRoleForm } from './types'

export const ManageRolesView = view<any, any>(Component, {
  title: () => 'Manage Roles'
})

function Component() {
  const [selectedAccountRole] = useAtom(selectedAccountRoleAtom)
  const { accountRolesQuery, isLoading } = useAccountRolesQuery()
  const [form] = Form.useForm<ManageRoleForm>()

  return (
    <PageView
      header={{
        title: 'Manage Roles',
        border: false,
        accent: false,
        actions: [<SaveButton key={'save-role-button'} form={form} />, <DeleteButton key={'delete-role-button'} />]
      }}
      loading={isLoading}
    >
      {!isLoading && (
        <Form
          form={form}
          layout="vertical"
          key={selectedAccountRole?.id}
          initialValues={{
            name: accountRolesQuery.data?.items[0]?.name,
            sync_notifications: selectedAccountRole?.sync_notifications
          }}
        >
          <div className="flex items-center gap-x-12">
            <RoleChangeSelect />
            <RoleNameField />
            <Form.Item noStyle name="sync_notifications" valuePropName="checked">
              <Checkbox>Sync Notifications</Checkbox>
            </Form.Item>
          </div>
          <Table
            dataSource={selectedAccountRole ? [{ ...selectedAccountRole, key: selectedAccountRole.id }] : []}
            pagination={false}
            bordered
            scroll={{
              x: 'max-content'
            }}
            components={{
              body: {
                row: ({ children, ...props }: any) => {
                  return (
                    <tr {...props} className="hover:bg-transparent">
                      {children}
                    </tr>
                  )
                },
                cell: ({ children, ...props }: any) => {
                  return (
                    <td {...props} className="align-baseline !pr-12">
                      {children}
                    </td>
                  )
                }
              }
            }}
            columns={[
              {
                title: 'Custom Navigation',
                width: 200,
                render: () => <CustomNavigationColumn />
              },
              {
                title: 'Permissions',
                width: 200,
                render: () => <PermissionsColumn />
              },
              {
                title: 'Custom Form',
                width: 200,
                render: () => <CustomFormColumn />
              },
              {
                title: 'Modules',
                width: 200,
                render: () => <ModulesColumn />
              },
              {
                title: 'SKU Access (By Category Tag)',
                width: 200,
                render: () => <SkuAccessColumn />
              }
            ]}
          />

          <div className="grid grid-cols-5">
            <TicketStatusColumn />
            <NotificationsColumns />
          </div>
        </Form>
      )}
    </PageView>
  )
}
