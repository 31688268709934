import { useCompanyFeatures, useSession } from '@/hooks'
import { Select } from '@/ui'
import { Checkbox, Form, FormInstance } from 'antd'

type SettingsFormProps = {
  form: FormInstance
}
export const SettingsForm = ({ form }: SettingsFormProps) => {
  const { user } = useSession()
  const companyFeatures = useCompanyFeatures()
  const wiki = user?.profile.userdata_json?.features?.wiki
  const isWikiEnabled = Form.useWatch(['features', 'wiki', 'enabled'], form)

  return (
    <Form name="settingsForm" form={form} className="lg:w-6/12 mx-auto pb-24" layout="vertical">
      <Form.Item name={'enable_v2_ticket_form'} valuePropName="checked">
        <Checkbox>Enable V2 Ticket Form</Checkbox>
      </Form.Item>

      <Form.Item name="military_time_input" valuePropName="checked">
        <Checkbox checked={form.getFieldValue('military_time_input')}>
          Enter hours and minutes by typing military time instead of using UI tools.
        </Checkbox>
      </Form.Item>
      {companyFeatures.wiki.enabled && (
        <>
          <Form.Item name={['features', 'wiki', 'enabled']} valuePropName="checked" className="mb-0">
            <Checkbox>Enable OilCommand Training.</Checkbox>
          </Form.Item>
          <Form.Item
            hidden={!isWikiEnabled}
            className="ml-24 w-[200px]"
            label="Role"
            name={['features', 'wiki', 'wiki_role_id']}
          >
            <Select
              options={[
                {
                  label: 'Super Admin',
                  value: 1,
                  disabled: !user?.is_superuser || wiki?.wiki_role_id !== 1
                },
                {
                  label: 'Admin',
                  value: 2,
                  disabled: !user?.is_superuser || wiki?.wiki_role_id !== 2
                },
                {
                  label: 'Editor',
                  value: 3
                },
                {
                  label: 'Viewer',
                  value: 4
                },
                {
                  label: 'Public',
                  value: 5
                }
              ]}
            />
          </Form.Item>
        </>
      )}
    </Form>
  )
}
