import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { moduleApi, reportBuilderApi } from '@/services/api-service'
import { QueryTable, SearchInput } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  AddEditReportCriteriaModal,
  EditAttributeModal,
  EncodingSelect,
  FormatSelect,
  GeneratedReportsSection,
  ScheduledReportsSection
} from '../../components'
import { useCustomReportsQuery, useProgrammedReportsQuery, useUpdateFilterfieldMutation } from '../../hooks'
import { Attribute, CustomReport, FilterField, ProgrammedReport, reportModuleSchema } from '../../schema'
import { ActionsColumn } from './actions-column'
import { CriteriaColumn } from './criteria-column'

export const ReportsModuleView = view<any, any>(Component, {
  loader: async ({ params }) => {
    const moduleId = Number(params.moduleId)
    const module = await fetchQuery(moduleApi.get<any>(Number(moduleId), undefined, { fields: 'id,title' }))
    return { title: module?.title || '' }
  },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const { notification } = useApp()
  const { moduleId } = useParams()
  const [format, setFormat] = useState('xlsx')
  const [encoding, setEncoding] = useState('utf-16')
  const [search, setSearch] = useState('')
  const [editingCriteria, setEditingCriteria] = useState<FilterField | null>(null)
  const [editingAttribute, setEditingAttribute] = useState<Attribute | null>(null)
  const [editingReport, setEditingReport] = useState<CustomReport | ProgrammedReport | null>(null)

  const moduleQuery = useQuery({
    ...moduleApi.get(Number(moduleId), {}, { fields: reportModuleSchema }),
    enabled: !!moduleId
  })

  const { customReportsQuery } = useCustomReportsQuery()
  const { programmedReportsQuery } = useProgrammedReportsQuery()

  const { handleSave } = useUpdateFilterfieldMutation({
    onSuccess: () => {
      notification.success({ message: 'Criteria successfully updated' })
      setEditingCriteria(null)
      setEditingReport(null)
      programmedReportsQuery.refetch()
      customReportsQuery.refetch()
    }
  })

  const handleCloseModal = () => {
    setEditingCriteria(null)
    setEditingAttribute(null)
    setEditingReport(null)
  }

  const module = moduleQuery.data
  const data = JSON.parse(module?.data_json || '{}')

  return (
    <PageView
      header={{ title: module?.title, breadcrumbs: true }}
      loading={moduleQuery.isInitialLoading || programmedReportsQuery.isLoading || customReportsQuery.isLoading}
    >
      <h5>Custom and Programmed Reports</h5>
      <Form layout="vertical">
        <div className="grid grid-cols-2 gap-x-16">
          <FormatSelect selectedFormat={format} setSelectedFormat={setFormat} />
          <EncodingSelect selectedEncoding={encoding} selectedFormat={format} setSelectedEncoding={setEncoding} />
        </div>
      </Form>
      <div className="flex items-center justify-between mb-16">
        <SearchInput placeholder="Search" onSearch={setSearch} />
      </div>
      <div>
        <QueryTable
          queryApi={reportBuilderApi.list}
          queryParams={{
            limit: 15,
            order: 'id',
            with_pr: true,
            ...(data.report_desc_contains && { description__icontains: data.report_desc_contains }),
            ...(search && { q: search })
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name'
            },
            {
              title: 'Type',
              dataIndex: 'programmed_report',
              render: (pr) => (pr ? 'Programmed' : 'Custom'),
              sorter: false
            },
            {
              title: 'Description',
              dataIndex: 'description'
            },
            {
              title: 'Criteria',
              sorter: false,
              render: (_, report) => (
                <CriteriaColumn
                  report={report}
                  programmedReports={programmedReportsQuery.data?.items || []}
                  customReports={customReportsQuery.data?.items || []}
                  setEditingAttribute={setEditingAttribute}
                  setEditingCriteria={setEditingCriteria}
                  setEditingReport={setEditingReport}
                />
              )
            },
            {
              sorter: false,
              align: 'right',
              render: (_, report) => <ActionsColumn report={report} encoding={encoding} format={format} />
            }
          ]}
        />
      </div>

      {editingCriteria && editingReport && (
        <AddEditReportCriteriaModal
          filterfield={editingCriteria}
          readonlyFilterField
          // @ts-expect-error - for some weird reason, the report object is not being recognized
          rootModelName={editingReport?.report?.root_model_name || editingReport?.root_model_name || 'ticket'}
          onSave={(values) => handleSave(values)}
          onClose={() => {
            setEditingCriteria(null)
            setEditingReport(null)
          }}
        />
      )}
      {editingAttribute && editingReport && (
        <EditAttributeModal
          attribute={editingAttribute}
          programmedReport={editingReport}
          onCancel={handleCloseModal}
          onSaved={() => {}}
        />
      )}
      <div className="mt-24 mb-24">
        <h5 className="font-bold">Scheduled Reports</h5>
        <ScheduledReportsSection reportModule={module} />
      </div>
      <GeneratedReportsSection />
    </PageView>
  )
}
