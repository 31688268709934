import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const ExcludeMobileApi = () => {
  const { lineItemField } = useSortableItemContext()

  const excludeMobileFields = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.exclude_mobile_fields ?? []), [])
  )

  const setConfiguration = useSetAtom(configurationAtom)
  const isChecked = useMemo(() => excludeMobileFields?.includes(lineItemField), [excludeMobileFields, lineItemField])

  const handleChange = (checked: boolean) => {
    if (checked) {
      setConfiguration((configuration) => ({
        ...configuration,
        exclude_mobile_fields: [...excludeMobileFields, lineItemField]
      }))
    } else {
      setConfiguration((configuration) => ({
        ...configuration,
        exclude_mobile_fields: excludeMobileFields.filter((field) => field !== lineItemField)
      }))
    }
  }

  return (
    <Space>
      <Switch checked={isChecked} onChange={handleChange} data-cy="exclude-mobile-api-switch" />
      <span>Exclude (Mobile API)</span>
    </Space>
  )
}
