import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { UniqueIdentifier } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Form, Input, Space, Tooltip } from 'antd'
import classNames from 'classnames'
import { memo, useState } from 'react'
import { DraggableItem } from '../../../types'

type Props = {
  item: DraggableItem
  isDragging: boolean
  editable?: boolean
  listeners?: SyntheticListenerMap
  onFieldNameChange?: (id: UniqueIdentifier, value: string) => void
}

const Component = ({ item, isDragging, editable, listeners, onFieldNameChange }: Props) => {
  const { l } = useApp()
  const [isEditing, setIsEditing] = useState(false)
  const [editingValue, setEditingValue] = useState(item.custom_name || '')
  const form = Form.useFormInstance()
  const mergeFields = Form.useWatch('merge_fields', form) || false

  const handleSelectedFieldNameChange = () => {
    if (!onFieldNameChange) {
      return
    }

    onFieldNameChange(item.id, editingValue)
    setIsEditing(false)
  }

  return (
    <div className="relative">
      <div
        key={item.id}
        className={classNames('bg-white', {
          'cursor-grabbing': isDragging,
          'cursor-grab': !isDragging
        })}
        {...listeners}
      >
        <div className="border border-border rounded font-semibold h-40 flex items-center px-4">
          <Icon name="mi:drag_indicator" className="cursor-grab" />
          {(item.merge_fields_count || 0) > 1 && mergeFields && (
            <span className="italic">({item.merge_fields_count} Forms),</span>
          )}
          {item.custom_name ? (
            <Tooltip title="Custom Name">
              <span className="ml-8 text-primary font-semibold">{item.custom_name}</span>
            </Tooltip>
          ) : (
            <span className="ml-8">
              {item.name}
              {/* {l('ticket__' + item.field_verbose + '__label', item.name)} */}
            </span>
          )}
          {!isEditing && (
            <Tooltip title={item.name}>
              <div className="inline-flex items-center">
                <Icon name="mi:info" className="cursor-default text-18 ml-6" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {isEditing && (
        <div className="flex h-full items-center absolute top-0 left-0 w-full">
          <Input value={editingValue} className="ml-4" onChange={(e) => setEditingValue(e.target.value)} />
          <Space className="ml-4 mr-4">
            <Button iconName="mi:check" type="text" onClick={handleSelectedFieldNameChange} />
            <Button iconName="mi:close" type="text" onClick={() => setIsEditing(false)} />
          </Space>
        </div>
      )}
      {editable && !isEditing && (
        <Button
          iconName="svg:edit"
          type="text"
          className="absolute top-4 right-4"
          onClick={(e) => {
            setIsEditing(true)
          }}
        />
      )}
    </div>
  )
}

export const Item = memo(Component, (prevProps, nextProps) => {
  return (
    prevProps.item.id === nextProps.item.id &&
    prevProps.isDragging === nextProps.isDragging &&
    prevProps.item.custom_name === nextProps.item.custom_name &&
    prevProps.item.merge_fields_count === nextProps.item.merge_fields_count
  )
})
