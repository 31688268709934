import dayjs, { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'

export const parsePeriodDates = (period?: string | null, defVal?: Dayjs) => {
  if (isEmpty(period)) return { start: defVal, end: defVal }

  const [start, end, ..._] = (period || '').split('-').map((dateStr) => dayjs.parse(dateStr) || defVal) || []

  return { start, end }
}
