import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Spin, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const CustomFormColumn = () => {
  const disabledCustomForms =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.disabled_forms), [])) ?? []

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)

  const customFormsQuery = useQuery({
    ...customFormApi.list({
      fields: 'id,name',
      limit: 'None' as any,
      show_all: true,
      with_disabled: true
    })
  })

  const isCustomFormDisabled = (customFormId: number) => disabledCustomForms.includes(customFormId)

  const handleChange = (customFormId: number, checked: boolean) => {
    const newDisabledCustomForms = checked
      ? disabledCustomForms.filter((id: number) => id !== customFormId)
      : [...disabledCustomForms, customFormId]

    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        disabled_forms: newDisabledCustomForms
      }
    }))
  }

  if (customFormsQuery.isLoading) {
    return <Spin spinning />
  }

  return (
    <div>
      {customFormsQuery.data?.items.map((customForm) => (
        <div key={customForm.id} className="flex w-full justify-between mb-20">
          <div>{customForm.name}</div>
          <Switch
            checked={!isCustomFormDisabled(customForm.id)}
            onChange={(checked) => handleChange(customForm.id, checked)}
          />
        </div>
      ))}
    </div>
  )
}
