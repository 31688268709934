import { CodeEditor } from '@/components/code-editor'
import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-javascript'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../atoms'

export const ListPreviewTemplate = () => {
  const listViewTemplateField = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.list_view_template ?? ''), [])
  )

  const setConfiguration = useSetAtom(configurationAtom)

  const onChange = (newValue: string) => {
    setConfiguration((configuration) => ({
      ...configuration,
      list_view_template: newValue
    }))
  }

  return (
    <div>
      <CodeEditor
        mode="javascript"
        onChange={onChange}
        value={listViewTemplateField}
        height="300px"
        onLoad={(editor) => editor.focus()}
        data-cy="list-view-template-code-editor"
      />
    </div>
  )
}
