import { useApp } from '@/hooks'
import { useViewData } from '@/layouts'
import { ListView } from '@/layouts/views'
import { ViewTicketLink } from '@/modules/ticket/components'
import { view } from '@/routing'
import { v2JasResourceApi } from '@/services/api-service'
import { Button, TableLiteProps, UrlStateFormItem } from '@/ui'
import { cn, makeMap } from '@/utils'
import { Segmented } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { RESOURCE_TYPE_MAP } from '../../resource-manager/resources'
import { MonthWeekChanger, ScheduleCell, ScheduleTitle } from '../components'
import { ResourceScheduleActions } from '../components/ResourceScheduleActions'
import { CALENDAR_VIEWS, SCHEDULE_TYPES } from '../constants'
import { getDateRangeByView, getResourceType } from '../helpers'
import { useResourceScheduleFilter } from '../hooks'
import { selectedJobSkillsAtom } from '../job-assign/atoms'
import { JobHeaders, ResourceSelect } from '../job-assign/components'
import { JobRequirementFilters } from '../job-assign/components'
import { useJobTicket } from '../job-assign/hooks'
import { CalendarViewType, Resource, ResourceSchema, ScheduleViewData } from '../schemas'

export const ResourceScheduleView = view<any, ScheduleViewData>(Component, {
  title: ({ data }) => data.label,
  loader: async ({ request }) => {
    const path = new URL(request.url).pathname
    const resourceType = getResourceType(path)

    let data: ScheduleViewData = RESOURCE_TYPE_MAP['employees']
    if (resourceType === 'equipment') data = RESOURCE_TYPE_MAP['equipment']
    return data
  }
})

export const ResourceScheduleJobAssignerView = view<any, ScheduleViewData>(Component, {
  title: ({ data }) => `Assign ${data.label} to Ticket`,
  loader: async ({ request }) => {
    const path = new URL(request.url).pathname
    const resourceType = getResourceType(path)

    let data: ScheduleViewData = RESOURCE_TYPE_MAP['employees']
    if (resourceType === 'equipment') data = RESOURCE_TYPE_MAP['equipment']

    return data
  }
})

function Component() {
  const { l } = useApp()
  const { data } = useViewData<ScheduleViewData>()
  const { type: resourceType, label: resourceLabel } = data

  const { jobTicket } = useJobTicket()
  const selectedJobSkills = useAtomValue(selectedJobSkillsAtom)

  const { filterService } = useResourceScheduleFilter({ resourceType })
  const view = (filterService.state.view || localStorage.ocScheduleView || 'week') as CalendarViewType
  const refDateStr = filterService.filter.ref_date
  const jobDateOrTodayStr = useMemo(
    () =>
      jobTicket?._jobData.startDate
        ? dayjs.parse(jobTicket._jobData.startDate)?.formatLocalDate()
        : dayjs.now().formatLocalDate(),
    [jobTicket?._jobData.startDate]
  )

  // todo: this will be possibly moved to backend
  const [scheduleTypes, scheduleTypesMap] = useMemo(() => [SCHEDULE_TYPES, makeMap(SCHEDULE_TYPES, 'type')], [])

  // darken dates that are not in job date range
  const highlightDates: Record<string, boolean> = useMemo(() => {
    const jobData = jobTicket?._jobData

    if (jobData?.startDate && jobData?.endDate) {
      // default date is today if missing
      let startDate = dayjs.parse(jobData?.startDate)
      if (!startDate?.isValid()) startDate = dayjs()
      let endDate = dayjs.parse(jobData?.endDate)
      if (!endDate?.isValid()) endDate = dayjs()

      const dates: Record<string, boolean> = {}
      let date = startDate
      while (date.isBefore(endDate)) {
        dates[date.formatLocalDate()] = true
        date = date.add(1, 'day')
        date = date.startOf('day')
      }
      return dates
    }

    return {}
  }, [jobTicket?._jobData])

  const { refDate, columns, startDate, endDate } = useMemo((): {
    refDate: Dayjs
    columns: TableLiteProps['columns']
    startDate: Dayjs
    endDate: Dayjs
  } => {
    const refDate = dayjs.parse(refDateStr) || dayjs.now()
    const dates = getDateRangeByView(view, refDate)

    const columns: TableLiteProps['columns'] = [
      ...(jobTicket?.id
        ? [
            {
              key: '_selection',
              sticky: true,
              title: '',
              width: 40,
              ellipsis: true,
              sorter: true,
              lazyRender: false, // cells are already optimized enough for performance
              render: (_: any, resource: Resource) => <ResourceSelect resource={resource} />
            }
          ]
        : []),
      {
        key: 'name',
        sticky: true,
        title: 'Employee Name',
        dataIndex: 'name',
        width: 200,
        ellipsis: true,
        sorter: true,
        className: 'cursor-pointer hover:bg-background-layout transition-colors',
        lazyRender: false, // cells are already optimized enough for performance
        render: (_: any, resource: Resource) => <ScheduleTitle resource={resource} date={dates[0]} />
      },
      ...dates.map((date, colIdx) => {
        return {
          key: date.format('YYYY-MM-DD'),
          ellipsis: false,
          title: (
            <div
              className={cn('text-center whitespace-pre leading-5', {
                'bg-primary-100 border-x-2 border-primary-400': jobDateOrTodayStr === date.formatLocalDate()
              })}
            >
              {date.format('ddd\nDD')}
            </div>
          ),
          width: dates.length > 10 ? 45 : 'auto',
          minWidth: 36,
          sorter: false,
          lazyRender: false, // cells are already optimized enough for performance
          render: (_: any, row: Resource, rowIdx: number) => (
            <ScheduleCell
              resource={row}
              date={date}
              rowIdx={rowIdx}
              colIdx={colIdx}
              options={scheduleTypes}
              optionsMap={scheduleTypesMap}
              shortLabel={view === 'month'}
              showJobs={view !== 'month'}
              className={cn({
                '!bg-gray-50 border-2': jobTicket?.id && !highlightDates[date.formatLocalDate()]
              })}
            />
          )
        }
      })
    ]

    return { refDate, columns, startDate: dates[0], endDate: dates[dates.length - 1] }
  }, [refDateStr, scheduleTypes, scheduleTypesMap, view, jobTicket?.id, highlightDates, jobDateOrTodayStr])

  const queryParams = useMemo(
    () => ({
      type__eq: resourceType,
      status__in: 'A',
      start_date: startDate.formatISODate(true),
      end_date: endDate.formatISODate(true),
      order: 'name',
      fields: ResourceSchema,
      ...filterService.getFilterQuery(),
      ...(selectedJobSkills.length ? { attributes__has_any_keys: selectedJobSkills.join(',') } : {})
    }),
    [resourceType, startDate, endDate, filterService, selectedJobSkills]
  )

  return (
    <ListView
      key={resourceType}
      header={{
        backButton: true,
        breadcrumbs: true,
        title: jobTicket?.id ? (
          <div className="flex flex-row items-center gap-4 mr-12">
            <span>
              Assign {data.label} to {l('ticket__name__label')} {jobTicket.name}
            </span>
            <ViewTicketLink ticketId={jobTicket?.id || 0} formId={0} className="flex items-start">
              <Button size="small" iconName="fa:arrow-up-right-from-square" type="dashed" primary />
            </ViewTicketLink>
          </div>
        ) : (
          resourceLabel
        ),
        actions: <ResourceScheduleActions />,
        description: jobTicket?._jobData ? <JobHeaders /> : null
      }}
      filter={{
        service: filterService,
        render: (filterEl) => (
          <div>
            {jobTicket?.id ? (
              <div className="mb-10">
                <JobRequirementFilters />
              </div>
            ) : null}
            <div className={'flex flex-col md:flex-row justify-between'}>
              <div className={'[&_.grid]:grid-cols-3'}>{filterEl}</div>
              <div className={'flex flex-col sm:flex-row gap-10 mt-10 justify-between'}>
                <UrlStateFormItem
                  name={[filterService.filterKey, 'ref_date']}
                  defaultValue={dayjs.now().formatISODate(false)}
                >
                  <MonthWeekChanger view={view} />
                </UrlStateFormItem>
                <UrlStateFormItem
                  name={'view'}
                  defaultValue={view}
                  formProps={{
                    onValuesChange: (changedValues) => {
                      localStorage.ocScheduleView = changedValues.view || 'week'
                    }
                  }}
                >
                  <Segmented block={true} className={'min-w-[200px]'} options={CALENDAR_VIEWS} />
                </UrlStateFormItem>
              </div>
            </div>
          </div>
        )
      }}
      table={{
        rootClassName: '[&_.ant-table-thead_th]:!py-4 [&_.ant-table-thead_th]:!font-medium [&_.ant-table-body_td]:!p-0',
        queryApi: v2JasResourceApi.list,
        queryParams: queryParams,
        rowKey: 'id',
        scrollOffset: { x: 0, y: -8 },
        fullHeight: true,
        bordered: true,
        variant: 'lite',
        columns: columns,
        lazyColumns: true,
        renderPagination: (paginationEl) => (
          <div className={'flex flex-row items-center'}>
            <div className={'w-full px-10 flex flex-row gap-6 overflow-auto no-scrollbar'}>
              {SCHEDULE_TYPES.map((st) => (
                <div className={'rounded-lg px-8 text-nowrap'} key={st.type} style={{ backgroundColor: st.color }}>
                  {st.type}: {st.name}
                </div>
              ))}
            </div>
            {paginationEl}
          </div>
        )
      }}
    />
  )
}
