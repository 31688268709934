import { COMPANY_KIND } from '@/constants/general'
import { useSession } from '@/hooks'
import { profileApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { QuerySelect } from '@/ui'
import { Checkbox, ColorPicker, Form, Input, Select, Tooltip, UploadFile } from 'antd'
import { DefaultProfileField } from './default-profile-field'
import { IconField } from './icon-field'

export type FormValues = {
  id?: number
  name?: string
  kind?: string
  website?: string
  notes?: string
  icon_path?: UploadFile<any>[]
  public?: boolean
  default_profile?: number
  offices?: Office[]
}

type Props = {
  company?: Company
}

export const AddEditCompanyForm = ({ company }: Props) => {
  const { company: userCompany, user } = useSession()
  const isUseCustomTheme = Form.useWatch('use_custom_theme')

  return (
    <>
      <Form.Item name="id" hidden />
      <Form.Item name="data" hidden />
      {(!company || company?.owner_company === userCompany.id) && (
        <Form.Item valuePropName="checked" name="public">
          <Checkbox>Public</Checkbox>
        </Form.Item>
      )}
      <Form.Item
        label="Company Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter a company name'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Company Type" name="kind" rules={[{ required: true, message: 'Please select a company type' }]}>
        <Select
          placeholder="Select"
          options={Object.entries(COMPANY_KIND).map(([value, label]) => ({
            label,
            value
          }))}
          data-cy="company-type-select"
        />
      </Form.Item>
      <Form.Item label="Website" name="website">
        <Input />
      </Form.Item>
      <DefaultProfileField company={company} />
      <IconField />
      <Form.Item label="Notes" name="notes">
        <Input.TextArea rows={3} />
      </Form.Item>
      {user?.is_superuser && (
        <div className="flex gap-x-12">
          <Form.Item>
            <Checkbox>Test Company</Checkbox>
          </Form.Item>
          <Form.Item name="use_custom_theme" valuePropName="checked">
            <Checkbox>Use Custom Theme</Checkbox>
          </Form.Item>
          <Tooltip title="If set, users of this company can view (but not change) their OilCommand data">
            <Form.Item name="readonly" valuePropName="checked">
              <Checkbox>Locked</Checkbox>
            </Form.Item>
          </Tooltip>
        </div>
      )}
      {user?.is_superuser && (
        <Form.Item label="Default User" name="default_profile">
          <QuerySelect
            renderOption={(option) => ({
              label: `${option?.contact?.first_name} ${option?.contact?.last_name}`,
              value: option.id
            })}
            apiSearchBy="user__username"
            apiEndpoint={profileApi.list}
            apiQueryParams={{
              compact: 1,
              company__exact: company?.id,
              limit: 20
            }}
          />
        </Form.Item>
      )}
      {isUseCustomTheme && (
        <div className="flex gap-x-12">
          <Form.Item label="Code" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="Primary Color" name="color_primary">
            <ColorPicker showText />
          </Form.Item>
          <Form.Item label="Accent Color" name="color_accent">
            <ColorPicker showText />
          </Form.Item>
          <Form.Item label="Page Title" name="page_title">
            <Input />
          </Form.Item>
        </div>
      )}
      {user?.is_superuser && (
        <>
          <div className="flex gap-x-12">
            <Form.Item label=" " name={['data', 'public', 'allow_signup']} valuePropName="checked">
              <Checkbox>Enable Signup</Checkbox>
            </Form.Item>
            <Form.Item label=" " valuePropName="checked" name={['data', 'public', 'signup_agreement']}>
              <Checkbox>Enable Signup Agreement</Checkbox>
            </Form.Item>
          </div>
          <Form.Item label="Signup Agreement Document Link" name={['data', 'public', 'agreement_document']}>
            <Input />
          </Form.Item>
        </>
      )}
    </>
  )
}
