import { cn } from '@/utils'
import { FC, useEffect, useState } from 'react'

type Props = {
  className?: string
  isLoading: boolean
}

export const ProgressBar: FC<Props> = ({ className, isLoading }) => {
  const [progress, setProgress] = useState(0)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    if (isLoading) {
      let timeout: number

      setOpacity(1) // Show progress bar
      setProgress(30) // Start progress at 30%

      const updateProgress = () => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev))
        timeout = setTimeout(updateProgress, 500)
      }
      timeout = setTimeout(updateProgress, 500)

      return () => clearTimeout(timeout)
    } else {
      setProgress(100)
      setTimeout(() => setOpacity(0), 50)
      setTimeout(() => setProgress(0), 500)
    }
  }, [isLoading])

  return (
    <div className={cn('w-full bg-transparent', className)}>
      <div
        className={cn(
          'h-2 rounded-r-full shadow-sm shadow-primary-700',
          'bg-gradient-to-r transition-all duration-500 ease-in-out',
          'from-primary-800 via-primary-600 to-primary-400'
        )}
        style={{ width: `${progress}%`, opacity }}
      ></div>
    </div>
  )
}
