import { customFormAccessRuleApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { customFormAtom } from '../atoms'
import { useCustomFormQuery } from './use-custom-form-query'

export const useDeleteAccessRules = () => {
  const { id } = useParams<{ id: string }>()
  const { customFormQuery } = useCustomFormQuery()
  const originalAccessRules = customFormQuery.data?.access_rules || []

  const accessRules = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (customForm) => customForm.access_rules), [])
  )

  const deleteAccessRuleMutation = useMutation({
    ...customFormAccessRuleApi(id).delete()
  })

  const deleteAccessRules = async () => {
    const accessRulesToDelete = originalAccessRules.filter((originalAccessRule) => {
      return !accessRules?.find((accessRule) => accessRule.id === originalAccessRule.id)
    })

    if (!accessRulesToDelete.length) {
      return
    }

    await Promise.all(
      accessRulesToDelete.map((accessRule) => {
        return deleteAccessRuleMutation.mutateAsync({ id: accessRule.id })
      })
    )
  }

  return {
    deleteAccessRules,
    deleteAccessRuleMutation
  }
}
