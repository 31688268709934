import { clientRecordApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { FC } from 'react'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiSearchBy' | 'renderOption'>

export const DistrictSelect: FC<Props> = ({ apiQueryParams, ...props }) => {
  return (
    <QuerySelect
      data-cy="resource-district"
      apiSearchBy="char_1"
      apiEndpoint={clientRecordApi.list}
      apiQueryParams={{ label__eq: 'Districts', fields: 'char_1', ...apiQueryParams }}
      mapOption={['char_1', 'char_1']}
      {...props}
    />
  )
}
