import { DisplayField } from '@/modules/table-view/helpers'
import { TICKET_STATUS } from '@/modules/ticket/constants'
import { dig } from '@/utils'
import { formatCurrency } from '@/utils/formatters'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { ReactNode } from 'react'
import { ViewTicketLink } from '../ticket/components'

export type FieldFormatterFn = (obj: any, field: DisplayField) => ReactNode
export type PropertyFormatterFn = (value: string | null, field: DisplayField, property: any) => ReactNode

const statusFormatter: FieldFormatterFn = (t, df) => {
  const formId = dig(t, 'custom_form_id')
  return (
    dig(df, `context.statuses.${formId}.${t.computed_status}`) || TICKET_STATUS[t.computed_status] || t.computed_status
  )
}

/**
 * Common fields formatter
 */
const commonFieldsFormatter: Record<string, FieldFormatterFn> = {
  created_at: (t) => dayjs.formatLocal(t.created_at) || '',
  modified_at: (t) => dayjs.formatLocal(t.modified_at) || ''
}

/**
 * Tickets fields formatter
 */
const ticketFieldsFormatter: Record<string, FieldFormatterFn> = {
  parent__name: (t) =>
    t.parent__id ? (
      <ViewTicketLink ticketId={t.parent__id} formId={0} className={'flex items-center gap-6'}>
        # {t.parent__name}
      </ViewTicketLink>
    ) : (
      'N/A'
    ),
  custom_form__name: (t) => t.custom_form__name,
  status: statusFormatter,
  computed_status: statusFormatter,
  custom_stage: statusFormatter,
  customer__name: (t) => `${t.customer__first_name || ''} ${t.customer__last_name || ''}`.trim(),
  customer__first_name: (t) => t.customer__first_name,
  customer__last_name: (t) => t.customer__last_name,
  team_members: (t) =>
    (t.contacts || [])
      .map((c: any) => `${c.contact__first_name || ''} ${c.contact__last_name || ''}`.trim())
      .join(', '),
  contacts__contact__profile__user__username: (t) =>
    (t.contacts || []).map((c: any) => `${c.contact__profile__user__username || ''}`).join(', '),
  timepoint_due: (t) => dayjs.formatLocalDate(t.timepoint_due) || '',
  timepoint_submitted: (t) => dayjs.formatLocalDate(t.timepoint_submitted) || '',
  job_code__user_job_code_no: (t) => t.job_code__user_job_code_no,
  total: (t) => formatCurrency(t.total),
  office__company__name: (t) => t.office__company__name,
  office__company: (t) => t.office__company__name
}

/**
 * Line items fields formatter
 */
const lineItemFieldsFormatter: Record<string, FieldFormatterFn> = {
  // ... (similar refactoring for line items)
}

/**
 * Line item - ticket fields formatter
 */
const lineItemTicketFieldsFormatter: Record<string, FieldFormatterFn> = {
  ticket_computed_status: ticketFieldsFormatter.computed_status,
  computed_status: ticketFieldsFormatter.custom_stage
}
Object.keys(ticketFieldsFormatter).forEach((key) => {
  lineItemTicketFieldsFormatter['ticket__' + key] = ticketFieldsFormatter[key]
})

/**
 * Fields formatter for table view
 */
export const fieldsFormatter = Object.assign(
  {},
  commonFieldsFormatter,
  lineItemFieldsFormatter,
  ticketFieldsFormatter,
  lineItemTicketFieldsFormatter
)

/**
 * Properties formatter for table view
 */
export const propertiesFormatter: Record<string, PropertyFormatterFn> = {
  Attachment: (value) => (isEmpty(value) ? 'No Attachment' : 'File Attached'),
  Date: (value) => dayjs.formatLocalDate(value) || '',
  DateTime: (value) => dayjs.formatLocal(value) || '',
  Dropdown: (value, field, property) => {
    if (field.column.extra === 'value') {
      return value || ''
    }
    return dig(property, `_optionsMap.${String(value).trim()}.label`, value) || ''
  },
  MultivariateDropdown: (value, field, property) => {
    if (field.column.extra === 'value') {
      return value || ''
    }
    return (
      (value || '')
        .split(',')
        .map((v) => dig(property, `_optionsMap.${String(v).trim()}.label`, ''))
        .join(', ') ||
      value ||
      ''
    )
  },
  RecordSelect: (value) => {
    value = value || ''
    const displays: string[] = []
    value.split(',').forEach((v) => {
      const display = v.substring(v.indexOf('|') + 1)
      if (!isEmpty(display)) displays.push(display)
    })
    return displays.join(', ')
  },
  Time: (value) => dayjs.formatLocalTime(value) || '',
  Signature: (value) => (isEmpty(value) ? 'Unsigned' : 'Signed')
}
