import { ContactSelect } from '@/components/contact-select'

type Props = {
  lineItem: any
  onChange: (value: any) => void
  disabled?: boolean
}

export const AssigneeField = ({ lineItem, onChange, disabled }: Props) => {
  const handleAssigneeChange = (value?: number | null) => {
    onChange({ assignee: value })
  }

  return (
    <ContactSelect
      selectProps={{
        placeholder: 'Select',
        value: lineItem.assignee,
        onChange: handleAssigneeChange,
        disabled: disabled,
        ['data-cy' as any]: 'custom-form-line-item-assignee-select'
      }}
    />
  )
}
