import { JOTAI_GLOBAL_STORE } from '@/constants/app'
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH, isMiniSidebarAtom } from '@/layouts/dashboard-layout'
import { useTicket } from '@/modules/ticket/form/hooks'
import { makeMap } from '@/utils'
import { Form } from 'antd'
import cn from 'classnames'
import { useAtomValue } from 'jotai'
import { sortBy } from 'lodash'
import { CSSProperties, FC, useEffect } from 'react'
import { useSubItems } from '../../../../../hooks'
import { ItemColumnType, LineItem } from '../../../../../schemas'
import { TableBody } from '../table-body'
import { TableEmpty } from '../table-empty'
import { TableFoot } from '../table-foot'
import { TableHead } from '../table-head'
import styles from './styles.module.scss'

type Props = {
  isEdit: boolean
  columns: ItemColumnType[]
  item: Partial<LineItem>
  style?: CSSProperties
  className?: string
}

export const TableRow: FC<Props> = ({ isEdit, columns, item, style, className }) => {
  const { ticket } = useTicket()
  const isMiniSidebar = useAtomValue(isMiniSidebarAtom, { store: JOTAI_GLOBAL_STORE })

  const { subItemsQuery, subItems } = useSubItems({
    ticketId: ticket.id as number,
    parentId: item.id as number
  })
  const components = subItems

  const form = Form.useFormInstance()

  useEffect(() => {
    if (!isEdit) return

    form.setFieldsValue({
      lineItems: {
        [item.id as number]: {
          showComponents: item.show_components,
          components: makeMap(components, 'id'),
          componentsOrder: sortBy(components, 'sequence').map((c) => c.id)
        }
      }
    })

    return () => {
      form.resetFields(['lineItems', item.id])
    }
  }, [components, form, isEdit, item.id, item.show_components])

  const sidebarWidth = isMiniSidebar ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH

  if (subItemsQuery.isLoading) return null

  return (
    <tr style={style} className={cn(styles.componentsRow, className)}>
      <td colSpan={columns.length + 1} className={'!px-30'}>
        <div className={'overflow-x-auto'} style={{ maxWidth: `calc(100vw - ${sidebarWidth + 140}px)` }}>
          <div className={'h-full w-full border border-secondary rounded'}>
            <table className={'w-full'}>
              {components?.length ? (
                <>
                  <TableHead isEdit={isEdit} item={item} components={components} />
                  <TableBody isEdit={isEdit} item={item} components={components} />
                </>
              ) : (
                <TableEmpty />
              )}
              {isEdit && <TableFoot item={item} components={components || []} />}
            </table>
          </div>
        </div>
      </td>
    </tr>
  )
}
