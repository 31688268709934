import {
  FieldType,
  LINE_ITEM_FIELDS,
  TICKET_FIELDS
} from '@/modules/settings/custom-forms/custom-form-layout-view/property-groups/edit-property-modal/bpa-builder/constants'
import { CopyButton, Icon, Popover } from '@/ui'
import { Form } from 'antd'
import { useMemo } from 'react'

export const ReferenceFields = () => {
  const doc = Form.useWatch('doc') || {}
  const isQboEnabled = doc.accounting?.qbo_enabled
  const qbwcDataExtensions = useMemo(() => doc?._qbwc_data_extensions || {}, [doc.accounting])

  const dynamicItems = useMemo(
    () =>
      ['Invoice', 'Bill', 'Item'].reduce(
        (acc, _type) => {
          const fields = qbwcDataExtensions[_type] || []
          if (fields.length > 0) {
            acc.push({
              label: `QB ${_type} Fields`,
              children: fields.map((field: any) => ({
                value: field.name,
                type: 'text',
                label: field.name,
                input: 'TextInput'
              }))
            })
          }
          return acc
        },
        [] as { label: string; children: FieldType[] }[]
      ),
    [qbwcDataExtensions]
  )

  const staticItems = useMemo(
    () => [
      {
        label: 'Meta Data',
        children: [
          {
            value: 'customer_accounting_name',
            type: 'text',
            label: 'Customer Accounting Name',
            input: 'TextInput'
          }
        ] as FieldType[]
      },
      ...(isQboEnabled
        ? [
            {
              label: 'Custom Elements',
              children: [
                {
                  value: '[properties."Reference #"] for custom element "Reference #"',
                  type: 'text',
                  label: '[properties."Reference #"] for custom element "Reference #"',
                  input: 'TextInput'
                }
              ] as FieldType[]
            }
          ]
        : []),
      {
        label: 'Line Item Fields',
        children: LINE_ITEM_FIELDS
      },
      {
        label: 'Ticket Properties',
        children: TICKET_FIELDS
      }
    ],
    [isQboEnabled]
  )

  return (
    <div className="flex-grow overflow-auto border px-10 mt-10 rounded max-h-screen">
      {[...dynamicItems, ...staticItems].map((item, index) => (
        <div key={index}>
          <div className="flex gap-x-8 py-4 items-center border-b">
            <strong>
              <Icon name="fa:hashtag" className="mr-4" /> {item.label}
            </strong>
          </div>
          <div>
            {item.children.map((child, index: number) => (
              <div key={index} className="flex items-center gap-8 py-4 border-b">
                <CopyButton
                  data-cy="format-picker-copy-button"
                  copyText={child.value}
                  size={'small'}
                  shape={'circle'}
                  icon={<Icon name="fa:copy" />}
                />
                <div className="flex-grow">
                  <Popover content={child.label} mouseEnterDelay={0.5}>
                    <small className="line-clamp-1 text-gray-400">{child.label}</small>
                    <span className="line-clamp-1">{child.value}</span>
                  </Popover>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const lineItemFields = [
  'uuid',
  'category',
  'amount',
  'barrels',
  'created_at',
  'created_by',
  'custom_form',
  'deleted_at',
  'deleted_by',
  'description',
  'discount',
  'discountable_amount',
  'end_datetime',
  'group',
  'hands',
  'minimum',
  'modified_at',
  'modified_by',
  'notes',
  'sequence',
  'sku',
  'standby_rate',
  'start_datetime',
  'subtotal_after',
  'summary_line_item',
  'sync_datetime',
  'tax_rate',
  'taxable_amount',
  'ticket',
  'unit',
  'units_min',
  'units_standby',
  'units_used',
  'usage_rate',
  'user_decimal_1',
  'user_decimal_2',
  'user_decimal_3',
  'user_integer_1',
  'user_integer_2',
  'user_integer_3',
  'user_integer_4',
  'user_text_1',
  'user_text_2',
  'user_text_3',
  'user_text_4',
  'user_text_5',
  'user_text_6',
  'user_text_7',
  'user_text_8'
]

const ticketFields = [
  'uuid',
  'afe',
  'created_at',
  'created_by',
  'custom_form',
  'customer',
  'customer_office',
  'description',
  'job_code',
  'location',
  'modified_at',
  'modified_by',
  'name',
  'oc_customer',
  'office',
  'properties',
  'status',
  'subtotal',
  'support_office',
  'tax_rate',
  'team',
  'ticket_name',
  'timepoint_closed',
  'timepoint_due',
  'timepoint_opened',
  'timepoint_submitted',
  'total',
  'type'
]
