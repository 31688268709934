import { Icon } from '@/ui/icons'
import { Input } from 'antd'
import { useSetAtom } from 'jotai'
import { useDebouncedCallback } from 'use-debounce'
import { searchTextAtom } from '../../atoms'

export const SearchInput = () => {
  const setSearchText = useSetAtom(searchTextAtom)

  const debounceSearchText = useDebouncedCallback((value: string) => {
    setSearchText(value.trim())
  }, 500)

  return (
    <div>
      <Input
        prefix={<Icon name="mi:search" />}
        className="max-w-[300px] min-w-[250px] w-full"
        placeholder="Search or type command"
        onChange={(e) => debounceSearchText(e.target.value)}
      />
    </div>
  )
}
