import { officeApi } from '@/services/api-service'
import { Office } from '@/types/office'
import { useMutation } from '@tanstack/react-query'
import { isEqual } from 'lodash'

export const useUpdateOfficesMutation = () => {
  const updateOfficeMutation = useMutation({ ...officeApi.update() })

  const handleUpdateOfficeMutation = async (offices: Office[], updatedOfficesData: Office[]) => {
    if (updatedOfficesData.length === 0) {
      return
    }

    const updatedOffices = updatedOfficesData
      .map((o) => {
        const office = offices.find((item) => item.id === o.id)

        return {
          ...office,
          ...o,
          address: {
            ...office?.address,
            ...o.address
          }
        }
      })
      .filter((o) => {
        const office = offices.find((item) => item.id === o.id)
        return !isEqual(o, office)
      })

    if (updatedOffices.length === 0) {
      return
    }

    const mutations = updatedOffices.map((office) =>
      updateOfficeMutation.mutateAsync({
        ...office,
        company: typeof office?.company !== 'number' ? office?.company?.id : office.company
      })
    )

    return Promise.all(mutations)
  }

  return {
    updateOfficeMutation,
    handleUpdateOfficeMutation
  }
}
