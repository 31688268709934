import { Icon } from '@/ui/icons'
import { Tabs as AntTabs, TabsProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import styles from './Tabs.module.scss'

type Props = {
  type?: TabsProps['type'] | 'solid' | 'line-filled'
} & Omit<TabsProps, 'type'>

export const Tabs: FC<Props> = ({ type = 'line', className, ...rest }) => {
  if (type === 'solid') {
    return <AntTabs {...rest} moreIcon={<Icon name="fa:ellipsis" />} className={cn(styles.wrapper, className)} />
  } else if (type === 'line-filled') {
    return (
      <AntTabs
        {...rest}
        type="line"
        moreIcon={<Icon name="fa:ellipsis" />}
        className={cn(styles.lineFilled, className)}
      />
    )
  }

  return <AntTabs type={type} className={className} {...rest} />
}
