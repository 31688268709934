import { useModalState } from '@/hooks'
import { Modal } from '@/ui'
import { Button } from '@/ui/button'
import { RaisedAlerts } from './raised-alerts'

export const ViewAlerts = () => {
  const { isOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <Button shape={'round'} iconName={'fa:warning'} onClick={openModal}>
        View Alerts
      </Button>
      <Modal footer={null} width={'900px'} open={isOpen} title={'Raised Alerts'} onCancel={closeModal} destroyOnClose>
        <RaisedAlerts />
      </Modal>
    </>
  )
}
