import { NavTabs, NavTabsProps } from '@/ui'
import { FC } from 'react'
import { PageView, PageViewProps } from '../page-view'

export type TabsViewProps = PageViewProps & {
  items: NavTabsProps['items']
}

export const TabsView: FC<TabsViewProps> = ({ items, header, ...pageViewProps }) => {
  return (
    <PageView
      header={{
        ...header,
        render: (header) => (
          <>
            {header}
            <NavTabs items={items} className={'px-10'} />
          </>
        )
      }}
      {...pageViewProps}
    />
  )
}
