import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp, useGetUrlState } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { categoryApi, equipmentApi, eventApi } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { QuerySelect, QueryTableParams, UrlStateFormItem } from '@/ui'
import { Form } from 'antd'
import { ReactNode, useMemo } from 'react'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'
import { AddCustomerPricing } from './components/add-customer-pricing'
import { CompanyColumnTitle } from './components/company-column-title'
import { DefaultRateCell } from './components/default-rate-cell'
import { DownloadButton } from './components/download-button'
import { EditCell } from './components/edit-cell'
import { PriceCell } from './components/price-cell'
import { UploadButton } from './components/upload-button'
import { usePricingCustomerQuery } from './hooks'
import { EquipmentSchema, FormValues } from './schemas'

export const PricingListView = view(Component, {
  title: () => 'Pricing'
})

function Component() {
  const { l } = useApp()
  const [form] = Form.useForm<FormValues>()

  const { pricingCustomerQuery } = usePricingCustomerQuery()

  const pricingCustomers = useMemo(
    () =>
      [...(pricingCustomerQuery.data?.items ?? [])].sort((a, b) =>
        a.customer_office__name.localeCompare(b.customer_office__name, 'en')
      ),
    [pricingCustomerQuery.data?.items]
  )

  return (
    <Form form={form} component={false}>
      <ListView
        header={{
          ...RECORDS_LIST_VIEW_HEADER,
          title: l('Pricing'),
          actions: [
            <ActivityLogsModal key={'logs'} query={eventApi.list} queryVariables={{ type: 'pricing' }} />,
            <DownloadButton key={'download'} />,
            <UploadButton key={'upload'} />,
            <AddCustomerPricing key={'add'} />
          ]
        }}
        filter={{
          search: true,
          query: ({ filter }) => {
            const params: ListParams = {}
            params.search = filter?.search

            if (filter?.subcategory) params.category_id__eq = filter?.subcategory
            else if (filter?.category) params.category__parent_category_id__eq = filter?.category

            return params
          },
          render: (searchEl) => <PricingListFilter searchEl={searchEl} />
        }}
        table={{
          loading: pricingCustomerQuery.isLoading,
          queryApi: equipmentApi.list,
          queryParams: { fields: EquipmentSchema, group_no__isnull: 1, order: 'sku' },
          rowKey: 'id',
          fullHeight: true,
          scrollOffset: { y: -5 },
          scroll: { x: 1000 },
          columns: pricingCustomerQuery.isLoading
            ? []
            : [
                { dataIndex: 'sku', title: l('SKU'), fixed: 'left', width: 100 },
                { dataIndex: 'description', title: 'Description', fixed: 'left', width: 250 },
                { dataIndex: 'unit', title: 'Unit', fixed: 'left', width: 50 },
                {
                  key: 'edit',
                  fixed: 'left',
                  width: 60,
                  align: 'center',
                  render: (_, equipment: any) => <EditCell equipment={equipment} />
                },
                {
                  key: 'default_rate',
                  title: 'Default Rate',
                  width: 150,
                  ellipsis: true,
                  render: (_, equipment: any) => <DefaultRateCell equipment={equipment} />
                },
                ...pricingCustomers.map((pricingCustomer) => ({
                  width: 200,
                  title: <CompanyColumnTitle pricingCustomer={pricingCustomer} />,
                  ellipsis: true,
                  render: (_: any, equipment: any) => (
                    <PriceCell equipment={equipment} pricingCustomer={pricingCustomer} />
                  )
                }))
              ]
        }}
      />
    </Form>
  )
}

function PricingListFilter({ searchEl }: { searchEl: ReactNode }) {
  const { filter } = useGetUrlState<QueryTableParams>()

  return (
    <div className={'flex flex-row items-center gap-10'}>
      {searchEl}
      <UrlStateFormItem name={['filter', 'category']} className={'hidden md:flex'}>
        <QuerySelect
          apiEndpoint={categoryApi.list}
          apiQueryParams={{ fields: 'id,name', parent_category__isnull: true }}
          apiSearchBy="name"
          mapOption={['id', 'name']}
          placeholder={'All Categories'}
          showCopyText={false}
          className={'!w-[200px]'}
        />
      </UrlStateFormItem>
      {filter?.category && (
        <UrlStateFormItem name={['filter', 'subcategory']} className={'hidden md:flex'}>
          <QuerySelect
            apiEndpoint={categoryApi.list}
            apiQueryParams={{ fields: 'id,name', parent_category_id__eq: filter?.category }}
            apiSearchBy={'name'}
            mapOption={['id', 'name']}
            placeholder={'All Subcategories'}
            showCopyText={false}
            className={'!w-[200px]'}
          />
        </UrlStateFormItem>
      )}
    </div>
  )
}
