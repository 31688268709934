import { apiHttp } from '@/services/api-service/core'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

const categoryKindSchema = z.object({
  id: z.number(),
  name: z.string(),
  fields: z.array(z.string()).nullish(),
  hidden_fields: z.array(z.string()).nullish()
})

const categoryKindsAsLookupSchema = z.record(z.string(), categoryKindSchema)

export const useCategoryKindsAsLookupQuery = () => {
  const categoryKindsAsLookupQuery = useQuery({
    queryKey: ['category_kinds_as_lookup'],
    queryFn: async () => {
      const res = await apiHttp.get('/category_kinds/as_lookup/')
      return categoryKindsAsLookupSchema.parse(res.data)
    }
  })

  return {
    categoryKindsAsLookupQuery
  }
}
