import { Tabs } from '@/ui/tabs'
import { useParams } from 'react-router-dom'

type Props = {
  modules: { id: number; title: string }[]
  onTabChange: (id: string) => void
}

export const ModuleTabsHeader = ({ modules, onTabChange }: Props) => {
  const { id } = useParams()

  return (
    <Tabs
      activeKey={id ?? ''}
      onChange={(key) => onTabChange(key)}
      moreIcon={false}
      type="solid"
      items={[
        ...modules.map((module) => ({
          label: module.title,
          key: String(module.id)
        }))
      ]}
    />
  )
}
