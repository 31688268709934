import { useApp } from '@/hooks'
import { MODULE_BUILDER_PATH } from '@/modules/module-builder/constants'
import { moduleApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Popconfirm } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useModulesQuery } from '../../../hooks'

export const DeleteButton = () => {
  const { id } = useParams()
  const { notification } = useApp()
  const deleteModuleMutation = useMutation(moduleApi.delete())
  const navigate = useNavigate()
  const { modulesQuery } = useModulesQuery()

  const handleDelete = async () => {
    await deleteModuleMutation.mutateAsync({ id })
    await modulesQuery.refetch()
    notification.success({ message: 'Module deleted successfully' })

    setTimeout(() => {
      navigate(MODULE_BUILDER_PATH)
    }, 1000)
  }

  return (
    <Popconfirm
      title="Delete Module"
      description="Are you sure you want to delete this module?"
      okText="Yes"
      cancelText="No"
      placement="bottomRight"
      onConfirm={handleDelete}
    >
      <Button iconName="svg:trash">Delete</Button>
    </Popconfirm>
  )
}
