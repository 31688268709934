import { useSession } from '@/hooks'
import { companyApi, officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Tooltip } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import cn from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { selectedTypeAtom } from '../../atoms'
import { Layout } from '../../components/layout'
import { AddOfficeView } from '../add-office-view'
import { AddCompaniesView, DisplayCompaniesView, EditCompaniesView } from '../companies-view/OffsetContent'
import { OfficeView } from '../office-view'

export const PrivateCompaniesView = () => {
  const { permissions, company, user } = useSession()
  const deleteCompanyMutation = useMutation(companyApi.delete())
  const deleteOfficeMutation = useMutation(officeApi.delete())
  const queryClient = useQueryClient()
  const [offsetActive, setOffsetActive] = useState(false)

  const [companyType, setCompanyType] = useState<'add' | 'edit' | 'view' | 'office-edit' | 'office-add'>('add')
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
  const [selectedOffice, setSelectedOffice] = useState<number | null>(null)

  const selectedType = useAtomValue(selectedTypeAtom)
  const setSelectedType = useSetAtom(selectedTypeAtom)

  const handleDelete = async (id: number) => {
    await deleteCompanyMutation.mutateAsync({ id })
    await queryClient.refetchQueries(...(companyApi.list().queryKey as any))
  }

  const handleDeleteOffice = async (id: number) => {
    await deleteOfficeMutation.mutateAsync({ id })
    await queryClient.refetchQueries(...(companyApi.list().queryKey as any))
  }

  const showDelete = (ownerCompany?: number) => {
    const companyAdmin = permissions.includes('company_admin')
    return user?.is_superuser || (companyAdmin && company?.id === ownerCompany)
  }

  const onCancel = async () => {
    setSelectedCompany(null)
    setSelectedOffice(null)
    setSelectedType(null)
    setOffsetActive(false)
  }

  useEffect(() => {
    if (selectedType && selectedType?.title === 'Private Companies') {
      setOffsetActive(true)
      setCompanyType('add')
      setSelectedCompany(null)
    }
  }, [selectedType])

  return (
    <Layout<Company>
      page="/rolodex/private_companies"
      columns={[
        {
          key: 'name',
          title: 'Company Name',
          width: 400,
          sorter: true
        },
        {
          key: 'company_actions',
          align: 'center',
          width: 120,
          render: (_, company) => {
            return (
              <Space>
                <Tooltip title={'View Company'} mouseEnterDelay={0.5}>
                  <Button
                    iconName="fa:chevron-circle-right"
                    className="text-16"
                    type="text"
                    data-cy="view-company-button"
                    onClick={() => {
                      setSelectedCompany(company)
                      setOffsetActive(true)
                      setCompanyType('view')
                      setSelectedType(null)
                      setSelectedOffice(null)
                    }}
                  />
                </Tooltip>
                <Button
                  iconName="fa:edit"
                  type="text"
                  data-cy="edit-company-button"
                  onClick={() => {
                    setSelectedCompany(company)
                    setOffsetActive(true)
                    setCompanyType('edit')
                    setSelectedType(null)
                  }}
                />
                {showDelete(company.owner_company) && (
                  <Popconfirm
                    title="Delete Private Company"
                    description={`Are you sure you want to delete private company #${company.id}?`}
                    onConfirm={() => handleDelete(company.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      loading: deleteCompanyMutation.isLoading
                    }}
                  >
                    <Button iconName="fa:trash" type="text" data-cy="delete-company-button" />
                  </Popconfirm>
                )}
              </Space>
            )
          }
        },
        {
          key: 'company_office',
          title: 'Company Office(s)',
          width: 400,
          render: (_, company) => {
            return (
              <div>
                {company.offices?.map((office: any) => (
                  <div
                    key={office.id}
                    className={cn('flex justify-between items-center group hover:bg-primary-50 px-4 relative', {
                      'bg-primary-50': selectedOffice === office.id
                    })}
                  >
                    <span className="cursor-default line-clamp-1">{office.name}</span>
                    <div className="flex">
                      <Tooltip title={'View Office'} mouseEnterDelay={0.5}>
                        <Button
                          iconName="fa:chevron-circle-right"
                          type="text"
                          data-cy="view-office-button"
                          onClick={() => {
                            setSelectedOffice(office.id)
                            setSelectedCompany(company)
                            setOffsetActive(true)
                            setCompanyType('view')
                            setSelectedType(null)
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={'Edit Office'} mouseEnterDelay={0.5}>
                        <Button
                          iconName="fa:edit"
                          type="text"
                          data-cy="edit-office-button"
                          onClick={() => {
                            setSelectedOffice(office.id)
                            setSelectedCompany(company)
                            setOffsetActive(true)
                            setCompanyType('office-edit')
                            setSelectedType(null)
                          }}
                        />
                      </Tooltip>
                      {showDelete(office.creator_company) && (
                        <Popconfirm
                          title="Delete Office"
                          description={`Are you sure you want to delete office #${office.id}?`}
                          onConfirm={() => handleDeleteOffice(office.id)}
                          okText="Yes"
                          cancelText="No"
                          okButtonProps={{
                            loading: deleteCompanyMutation.isLoading
                          }}
                        >
                          <Button iconName="fa:trash" type="text" data-cy="delete-office-button" />
                        </Popconfirm>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        }
      ]}
      isOffsetBlockActive={offsetActive}
      offsetContent={
        <>
          <Button iconName="mi:close" onClick={() => onCancel()}>
            Cancel
          </Button>

          {!selectedCompany && companyType === 'add' && <AddCompaniesView isPrivate={true} />}
          {selectedCompany && companyType === 'edit' && (
            <EditCompaniesView company={selectedCompany} isPrivate={true} />
          )}
          {selectedCompany && !selectedOffice && companyType === 'view' && (
            <div className="px-16">
              <DisplayCompaniesView company={selectedCompany} onAddClick={() => setCompanyType('office-add')} />
            </div>
          )}
          {/* Offices */}
          {selectedCompany && ((selectedOffice && companyType === 'office-edit') || companyType === 'office-add') && (
            <div className="px-16">
              <AddOfficeView
                officeId={selectedOffice}
                onCancel={() => onCancel()}
                companyId={selectedCompany?.id}
              />{' '}
            </div>
          )}

          {selectedCompany && selectedOffice && companyType === 'view' && (
            <div className="px-16">
              <OfficeView officeId={selectedOffice} onCancel={() => onCancel()} companyId={selectedCompany.id} />
            </div>
          )}
        </>
      }
    />
  )
}
