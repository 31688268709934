import ldmlNumber from 'ldml-number'

export const formatCurrency = (value: any): string => {
  try {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(value))
  } catch (e) {
    return '$0.00'
  }
}

export const formatNumber = (value: any): string => {
  try {
    return new Intl.NumberFormat('en-US').format(Number(value))
  } catch (e) {
    return '0'
  }
}

export const formatPhoneNumber = (value?: string): string => {
  if (!value) return ''

  const usPhoneLength = 10
  const cleaned = value.replace(/\D/g, '').slice(0, usPhoneLength)

  // Match the cleaned number to the desired format
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)

  if (match) {
    // Format the number
    let formattedNumber = ''

    if (match[1]) {
      formattedNumber += '(' + match[1]
    }

    if (match[2]) {
      formattedNumber += ') ' + match[2]
    }

    if (match[3]) {
      formattedNumber += '-' + match[3]
    }

    return formattedNumber
  }

  return value
}

export const ldml = (formatStr: string, options?: any): ((value: any) => string) => {
  return ldmlNumber(formatStr, options)
}

export const formatIdentifier = (value: any, options?: { strip?: boolean }): string => {
  value = (value || '').replace(/[^a-zA-Z0-9_]/g, '_')
  if (options?.strip) value = value.replace(/_{3,}/g, '__').replace(/^_+|_+$/g, '')
  return value.toLowerCase()
}
