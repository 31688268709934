import { useApp } from '@/hooks'
import { refreshQueries } from '@/query'
import { pricingCustomerApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

type CreatePricingCustomerVariables = {
  company: number
  customer_office: number
}

export const useCreatePricingCustomer = () => {
  const { notification } = useApp()

  const createPricingCustomerMutation = useMutation(pricingCustomerApi.create<any, CreatePricingCustomerVariables>())

  const createPricingCustomer = async (variables: CreatePricingCustomerVariables) => {
    await createPricingCustomerMutation.mutateAsync(variables)
    notification.success({
      message: 'Customer Added',
      description: 'Customer has been added to the pricing list'
    })
    await refreshQueries(['pricing_customers', 'equipments'])
  }

  return {
    createPricingCustomer,
    createPricingCustomerMutation
  }
}
