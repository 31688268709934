import { AppLogo } from '@/components'
import { useAppSelector, useAppTheme } from '@/hooks'
import React from 'react'

export const HomeView: React.FC = () => {
  const { darkMode } = useAppTheme()
  const contact = useAppSelector((state) => state.session.contact)

  return (
    <div className="grid place-items-center h-full">
      <div className="md:w-1/3 sm:w-1/2 xs:w-full flex flex-col items-center">
        <AppLogo dark={!darkMode} className={'w-[400px]'} />
        <h3 className="mt-24">
          Welcome, {contact?.first_name} {contact?.last_name}
        </h3>
      </div>
    </div>
  )
}
