import { Button, Icon } from '@/ui'
import { Checkbox, Form, Input, Table } from 'antd'

export const DeliverDataSet = () => {
  return (
    <>
      <div className="grid grid-cols-2 gap-x-16">
        <Form.Item name={['params', 'min_column_width']} label="Min Column Width">
          <Input />
        </Form.Item>
        <Form.Item name={['params', 'max_column_width']} label="Max Column Width">
          <Input />
        </Form.Item>
      </div>
      <Form.Item name={['params', 'freeze_first_row']} valuePropName="checked">
        <Checkbox>Freeze first row of each worksheet</Checkbox>
      </Form.Item>
      <Form.Item name={['params', 'auto_filter']} valuePropName="checked">
        <Checkbox>Add auto-filter to each worksheet</Checkbox>
      </Form.Item>
      <Form.Item name={['params', '_formatting_enabled']} valuePropName="checked">
        <Checkbox>Customize column formatting</Checkbox>
      </Form.Item>
      <Form.List name={['params', 'column_number_format']}>
        {(fields, { add, remove }) => {
          return (
            <Table dataSource={[...fields, { name: 'empty' }]} pagination={false}>
              <Table.Column
                title="Column"
                render={(field) =>
                  field.name !== 'empty' && (
                    <Form.Item noStyle name={[field.name, 'column']}>
                      <Input />
                    </Form.Item>
                  )
                }
              />
              <Table.Column
                title="Format"
                render={(field) =>
                  field.name !== 'empty' && (
                    <Form.Item noStyle name={[field.name, 'format']}>
                      <Input />
                    </Form.Item>
                  )
                }
              />
              <Table.Column
                align="right"
                width={50}
                render={(field) =>
                  field.name === 'empty' ? (
                    <Button
                      type="text"
                      icon={<Icon className="text-16" name="fa:plus-circle" />}
                      onClick={() => add({ column: '', format: '' })}
                    />
                  ) : (
                    <Button type="text" iconName="fa:trash" onClick={() => remove(field.name)} />
                  )
                }
              />
            </Table>
          )
        }}
      </Form.List>
    </>
  )
}
