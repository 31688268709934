import { apiHttp } from '@/services/api-service/core'
import { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types'

export type IntercomUser = IntercomSettings & {
  user_id: string
  user_hash: string
  name: string
  username: string
  user_title: string | null
  email: string
  phone: string | null
  avatar: {
    type: string
    image_url: string | null
  }
  company: {
    company_id: string
    name: string
    website: string | null
    remote_created_at: number
  }
  created_at: number
  api_version: string
}

export const fetchIntercomUser = async (): Promise<IntercomUser | null> => {
  try {
    return apiHttp.get('v2/integrations/intercom/user').then((res) => res.data)
  } catch (e) {
    return null
  }
}
