import { useSession } from '@/hooks'
import { ErrorView, PageView } from '@/layouts/views'
import { FC, useMemo } from 'react'
import { makeNewTicket } from '../../helpers'
import { useTicketLoader } from '../../hooks'
import { FormLoader } from './form-loader'

/**
 * Here is a breakdown on initial render:
 *  1. Fetch ticket and custom form. (TicketFormView)
 *  2. Load them to atom. (TicketFormViewLoader)
 *  3. Render TicketForm. (TicketForm)
 *  4. Load ticket to form values. (useFormValues)
 */
export const FormView: FC = () => {
  // fetch ticket and custom form
  const { ticketId, formId, ticketQuery, apiTicket, apiCustomForm } = useTicketLoader()

  // user company
  const { company } = useSession()

  // define ticket
  const blankTicket = useMemo(() => {
    if (!apiCustomForm) return null
    return makeNewTicket(apiCustomForm, company, Number(ticketId) || 0)
  }, [apiCustomForm, company, ticketId])

  const ticket = apiTicket || blankTicket

  // combined states
  const isLoaded = !!apiCustomForm && !!ticket
  const isError = ticketQuery.isError
  const isLoading = ticketQuery.isInitialLoading

  return (
    <section key={`${formId}-${ticketId}`}>
      {isLoaded && <FormLoader apiCustomForm={apiCustomForm} apiTicket={ticket} />}
      {isError && <ErrorView code={ticketQuery.error?.response?.status || 404} />}
      <PageView.Loading active={isLoading} />
    </section>
  )
}
