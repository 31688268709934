import { FIELD_LABELS } from '@/constants/labels'
import { AuthUser } from '@/types/auth-user'
import { Obj } from '@/types/general'
import { safeJsonParse } from '@/utils'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type AppState = {
  labels: Obj
}

/**
 * Get labels from user profile company field aliases
 * @param user
 */
const getLabels = (user: Obj) => {
  let fieldAliases = user?.profile?.company?.field_aliases || {}
  if (typeof fieldAliases === 'string') {
    fieldAliases = safeJsonParse(fieldAliases, {})
  }
  return { ...FIELD_LABELS, ...(fieldAliases || {}) }
}

const appSlice = createSlice({
  name: 'app',
  initialState: {
    labels: FIELD_LABELS
  } as AppState,
  reducers: {
    /**
     * Start app with user profile
     *
     * @param state
     * @param action
     */
    startApp(state, action: PayloadAction<AuthUser>) {
      state.labels = getLabels(action.payload)
    },

    /**
     * Reset labels to default
     *
     * @param state
     */
    resetLabels(state) {
      state.labels = FIELD_LABELS
    }
  }
})

export const { startApp, resetLabels } = appSlice.actions
export const appReducer = appSlice.reducer
