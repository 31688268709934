import { CodeEditor } from '@/components/code-editor'
import { CLONE_TICKET_SECTIONS } from '@/modules/ticket/constants'
import { Checkbox, Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionCopyTicket } from '../../schemas'

type Props = {
  action: BpaActionCopyTicket
  onChange: (data: Partial<BpaActionCopyTicket> | Record<string, any>) => void
}

export const InputCopyTicket: FC<Props> = (props) => {
  const { action, onChange } = props
  const copyOptions = action.data || {}
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className={'flex flex-col gap-16'}>
      <div className={'flex flex-col gap-16'}>
        <Form.Item label={'Copies'} className={'mb-0'}>
          <Input
            className="full-width"
            defaultValue={copyOptions.copies || 1}
            onChange={(e) => onChange({ ['data.copies']: e.target.value })}
          />
        </Form.Item>

        <div className={'grid grid-cols-2 gap-10'}>
          {CLONE_TICKET_SECTIONS.map((section) => (
            <Checkbox
              key={section.key}
              checked={!!copyOptions[`copy_${section.key}` as keyof typeof copyOptions]}
              onChange={(e) => onChange({ [`data.copy_${section.key}`]: e.target.checked })}
            >
              <span className="line-clamp-1">
                Copy <span className={'font-medium'}>{section.label}</span>
              </span>
            </Checkbox>
          ))}
        </div>

        <div>
          <Form.Item
            label={'Field Overrides (YAML)'}
            className={'mb-10'}
            tooltip={<OverridesInputTip data={{ timepoint_due: 'null # (to clear)', location_id: '123 # (to set)' }} />}
          >
            <CodeEditor
              minLines={2}
              maxLines={25}
              defaultValue={copyOptions.field_overrides_yaml || ''}
              onChange={(value) => onChangeDebounced({ ['data.field_overrides_yaml']: value })}
              mode={'yaml'}
            />
          </Form.Item>

          <Form.Item
            label={'Property Overrides (YAML)'}
            className={'mb-10'}
            tooltip={<OverridesInputTip data={{ 'Prop 1 Key': 'null # (to clear)', 'Prop 2 Key': '123 # (to set)' }} />}
          >
            <CodeEditor
              minLines={2}
              maxLines={25}
              defaultValue={copyOptions.property_overrides_yaml || ''}
              onChange={(value) => onChangeDebounced({ ['data.property_overrides_yaml']: value })}
              mode={'yaml'}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}
export const InputCopyTicketMemo = React.memo(InputCopyTicket)

const OverridesInputTip: FC<{ data: Record<string, string> }> = ({ data }) => (
  <div className={'flex flex-col'}>
    <div className={'border-b border-b-gray-400'}>Examples:</div>
    {Object.entries(data).map(([key, value]) => (
      <div key={key}>
        {key}: {value}
      </div>
    ))}
  </div>
)
