import { Property } from '@/types/property'
import { uuid4 } from '@/utils'
import { cloneDeep } from 'lodash'
import { BPA_CONFIG_TEMPLATE } from '../constants'
import { BpaAction, BpaCondition, BpaConfig, BpaRule, BpaVariable } from '../schemas'

const _tagUuid = (obj: any) => {
  if (!obj.uuid) {
    obj.uuid = uuid4()
  }

  return obj
}

export const processVariable = (variable: BpaVariable) => {
  return _tagUuid(variable)
}

export const processCondition = (condition: BpaCondition) => {
  return _tagUuid(condition)
}

export const processAction = (action: BpaAction) => {
  action.data = action.data || {}
  return _tagUuid(action)
}

export const processRule = (rule: BpaRule) => {
  rule.conditions = rule.conditions.map(processCondition)
  rule.actions = rule.actions.map(processAction)
  return _tagUuid(rule)
}

export const processConfig = (config: BpaConfig) => {
  const _config = { ...BPA_CONFIG_TEMPLATE, ...(config || {}) }

  _config.variables = _config.variables.map(processVariable)
  _config.conditions = _config.conditions.map(processCondition)
  _config.rules = _config.rules.map(processRule)

  return _config
}

export const getBpaConfig = (property: Property) => {
  const config = processConfig(cloneDeep(property?.bpa_config || {}))
  config.propertyId = property?.id
  return config
}
