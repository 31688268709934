import { jasSkillApi } from '@/services/api-service'
import { ListResponse } from '@/types/api/core'
import { filterSelectOption } from '@/utils/filter-select-option'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Select } from 'antd'
import { z } from 'zod'

const skillQueryResponseSchema = z.object({
  id: z.number(),
  name: z.string()
})

const skillQueryResponseListSchema = z.array(skillQueryResponseSchema)
type Skill = z.infer<typeof skillQueryResponseSchema>

type Props = {
  type: 'equipment' | 'employee'
  value?: string[]
  onChange?: (value: string[]) => void
}

export const SkillSelect = ({ onChange = () => {}, value, type }: Props) => {
  const skillsQuery: UseQueryResult<ListResponse<Skill>> = useQuery(
    jasSkillApi.list({
      char_2__iexact: type
    })
  )

  const skills = skillQueryResponseListSchema.parse(skillsQuery.data?.items || [])

  const options = skills.map((skill) => ({
    label: skill.name,
    value: skill.name
  }))

  const handleChange = (value: string[]) => {
    onChange(value)
  }

  return (
    <Select
      placeholder="Skills"
      className="min-w-[100px]"
      mode="multiple"
      allowClear
      options={options}
      showSearch
      filterOption={filterSelectOption}
      value={value}
      onChange={handleChange}
      popupMatchSelectWidth={false}
    />
  )
}
