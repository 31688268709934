import { Property } from '@/types/property'
import { Button } from '@/ui/button'
import { Space } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'
import { editingCustomFormSectionAtom, searchTextAtom } from '../atoms'
import { EditPropertyModal } from './edit-property-modal'
import { usePropertyTypesQuery } from './hooks'
import { usePropertyGroups } from './hooks/use-property-groups'
import { PropertyBox } from './property-box'

export const PropertyGroups = () => {
  const searchText = useAtomValue(searchTextAtom)

  const { propertyGroups } = usePropertyGroups()
  const { getTypeIdByName } = usePropertyTypesQuery()
  const [editingProperty, setEditingProperty] = useState<any>(null)
  const setEditingCustomFormSection = useSetAtom(editingCustomFormSectionAtom)

  const renderProperties = useCallback(
    (properties: Property[]) => {
      let percentage = 0
      const arr = []

      for (const property of properties) {
        if (searchText) {
          const _searchText =
            `${property.id} ${property.name} ${property.key} ${property.property_type.name}`.toLowerCase()
          if (!_searchText.includes(searchText)) {
            continue // skip if property name does not match a search text
          }
        }

        const layoutPercentage =
          typeof property.layout === 'number'
            ? property.layout
            : // If property has a layout of 50,50 for example, it means it has layout and skip
              // so we need to add both of them to percentage
              (property.layout?.split(',').reduce((a, b) => Number(a) + Number(b), 0) ?? 0)

        percentage += layoutPercentage

        if (percentage === 100) {
          arr.push(property)
          percentage = 0
          continue
        }

        if (percentage > 100) {
          percentage = layoutPercentage
          arr.push({ ...property, couldNotFit: true })
          continue
        }

        arr.push(property)
      }

      return arr.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
    },
    [searchText]
  )

  const handleAddProperty = useCallback(
    (propertyGroup: any) => {
      setEditingProperty({
        property_group: propertyGroup.id,
        property_type: {
          id: getTypeIdByName('String') // String is the default property type
        },
        layout: '20',
        sequence: (propertyGroup.properties?.[propertyGroup.properties?.length - 1]?.sequence ?? 0) + 10
      })
    },
    [getTypeIdByName]
  )

  return (
    <>
      <div className="mt-40">
        {propertyGroups.map((propertyGroup) => {
          return (
            <div key={propertyGroup.id} className="mb-40 last-of-type:mb-16">
              <div className="bg-gray-100 p-6 flex items-center justify-between">
                <Space className="group">
                  <h5 className="font-bold mb-0">
                    {propertyGroup.sequence}: {propertyGroup.name}
                  </h5>
                  <Button
                    iconName="svg:edit"
                    type="text"
                    className="opacity-0 group-hover:opacity-100"
                    onClick={() => setEditingCustomFormSection(propertyGroup)}
                    data-cy="edit-property-group-button"
                  />
                </Space>
                {!propertyGroup.line_item_placeholder && (
                  <Button
                    iconName="mi:add"
                    type="primary"
                    onClick={() => handleAddProperty(propertyGroup)}
                    data-cy="add-property-button"
                  >
                    Add Property
                  </Button>
                )}
              </div>
              <div className="flex flex-wrap mt-12">
                {renderProperties(propertyGroup.properties ?? []).map((property) => {
                  return (
                    <PropertyBox
                      key={property.id}
                      property={property}
                      onClick={(property) => setEditingProperty(property)}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      {/* do not render model if not open */}
      {/* it must be re-created every time */}
      {!!editingProperty && <EditPropertyModal onClose={() => setEditingProperty(null)} property={editingProperty} />}
    </>
  )
}
