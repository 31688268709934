import { customFormAccessRuleApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { customFormAtom } from '../atoms'

export const useUpsertAccessRules = () => {
  const { id } = useParams<{ id: string }>()

  const accessRules = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (customForm) => customForm.access_rules), [])
  )

  const createAccessRuleMutation = useMutation({
    ...customFormAccessRuleApi(id).create()
  })

  const updateAccessRuleMutation = useMutation({
    ...customFormAccessRuleApi(id).update()
  })

  const upsertAccessRules = async () => {
    accessRules?.map((accessRule) => {
      if (accessRule.id) {
        return updateAccessRuleMutation.mutateAsync({
          ...accessRule,
          customform: Number(id),
          _changed: true
        })
      }

      return createAccessRuleMutation.mutateAsync({
        ...accessRule,
        customform: Number(id),
        _changed: true
      })
    })

    await Promise.all([createAccessRuleMutation, updateAccessRuleMutation])
  }

  return {
    upsertAccessRules,
    createAccessRuleMutation,
    updateAccessRuleMutation
  }
}
