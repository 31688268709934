import { range } from 'lodash'

export type FieldDataType = 'text' | 'number' | 'date' | 'datetime'

export type FieldInputComponentName =
  | 'TextInput'
  | 'NumberInput'
  | 'DateInput'
  | 'DateTimeInput'
  | 'StatusSelect'
  | 'ContactSelect'
  | 'OfficeSelect'
  | 'LocationSelect'
  | 'JobCodeSelect'
  | 'AfeSelect'

export type FieldType = {
  value: string
  label: string
  type: FieldDataType
  input: FieldInputComponentName | null // null means not writable
}

const _4 = range(1, 5)
export const TICKET_USER_DATA_FIELDS: FieldType[] = [
  ..._4.map<FieldType>((i) => ({
    value: `user_int_${i}`,
    label: `Ticket Integer ${i}`,
    type: 'number',
    input: null
  })),
  ..._4.map<FieldType>((i) => ({
    value: `user_decimal_${i}`,
    label: `Ticket Decimal ${i}`,
    type: 'number',
    input: null
  })),
  ..._4.map<FieldType>((i) => ({
    value: `user_text_${i}`,
    label: `Ticket Text ${i}`,
    type: 'text',
    input: null
  })),
  ..._4.map<FieldType>((i) => ({
    value: `user_datetime_${i}`,
    label: `Ticket Date ${i}`,
    type: 'datetime',
    input: null
  }))
]

export const TICKET_FIELDS: FieldType[] = [
  { value: '_bpa_ctx.is_new', label: 'Is New', type: 'text', input: null },
  { value: 'uuid', label: 'UUID', type: 'text', input: null },
  { value: 'name', label: 'Name', type: 'number', input: null },
  { value: 'local_status', label: 'Local Status', type: 'text', input: 'StatusSelect' },
  { value: 'status', label: 'Status', type: 'text', input: 'StatusSelect' }, // duplicate: local_status
  { value: 'custom_form', label: 'Custom Form ID', type: 'number', input: null },
  { value: 'custom_form__name', label: 'Custom Form Name', type: 'text', input: null },
  { value: 'customer', label: 'Customer ID', type: 'number', input: 'ContactSelect' },
  { value: 'customer__display_name', label: 'Customer Full Name', type: 'text', input: null },
  { value: 'customer.email', label: 'Customer Email', type: 'text', input: null },
  { value: 'customer_office', label: 'Customer Office ID', type: 'number', input: 'OfficeSelect' },
  { value: 'customer_office__name', label: 'Customer Office Name', type: 'text', input: null },
  { value: 'location', label: 'Location ID', type: 'number', input: 'LocationSelect' },
  { value: 'location__name', label: 'Location Name', type: 'text', input: 'TextInput' },
  { value: 'job_code', label: 'Job Code ID', type: 'number', input: 'JobCodeSelect' },
  { value: 'job_code__name', label: 'Job Code Name', type: 'text', input: 'TextInput' },
  { value: 'afe', label: 'AFE ID', type: 'number', input: 'AfeSelect' },
  { value: 'afe__name', label: 'AFE Name', type: 'text', input: 'TextInput' },
  { value: 'support_office', label: 'Support Office ID', type: 'number', input: 'OfficeSelect' },
  { value: 'support_office__name', label: 'Support Office Name', type: 'text', input: null },
  { value: 'timepoint_due', label: 'Start Date', type: 'date', input: 'DateInput' },
  { value: 'timepoint_submitted', label: 'Date Submitted', type: 'date', input: 'DateInput' },
  { value: 'total', label: 'Total', type: 'number', input: null },
  { value: 'created_by__username', label: 'Creator Username', type: 'text', input: null },
  { value: 'parent.name', label: 'Summary Ticket Name', type: 'text', input: null },
  ...TICKET_USER_DATA_FIELDS.map((f) => ({ ...f, value: `user_data__${f.value}` }))
]

export const LINE_ITEM_FIELDS: FieldType[] = [
  { value: 'uuid', label: 'UUID', type: 'text', input: null },
  { value: 'sku', label: 'SKU', type: 'text', input: null },
  { value: 'description', label: 'Description', type: 'text', input: null },
  { value: 'category', label: 'Category ID', type: 'number', input: null },
  { value: 'category__name', label: 'Category Name', type: 'text', input: null },
  { value: 'category__parent_category__id', label: 'Parent Category ID', type: 'number', input: null },
  { value: 'category__parent_category__name', label: 'Parent Category Name', type: 'text', input: null },
  { value: 'equipment', label: 'Equipment ID', type: 'text', input: null },
  { value: 'usage_rate', label: 'Usage Rate', type: 'number', input: 'TextInput' },
  { value: 'standby_rate', label: 'Standby Rate', type: 'number', input: 'TextInput' },
  { value: 'tax_rate', label: 'Tax Rate', type: 'number', input: 'TextInput' },
  { value: 'non_taxable', label: 'Non-Taxable', type: 'number', input: 'TextInput' },
  { value: 'hands', label: 'Hands', type: 'number', input: 'TextInput' },
  { value: 'units_used', label: 'Units Used', type: 'number', input: 'TextInput' },
  { value: 'unit', label: 'Unit', type: 'text', input: 'TextInput' },
  { value: 'units_standby', label: 'Units Standby', type: 'number', input: 'TextInput' },
  { value: 'units_min', label: 'Units Min', type: 'number', input: 'TextInput' },
  { value: 'minimum', label: 'Minimum', type: 'number', input: 'TextInput' },
  { value: 'discount', label: 'Discount', type: 'number', input: 'TextInput' },
  { value: 'discountable_amount', label: 'Discountable Amount', type: 'number', input: 'TextInput' },
  { value: 'taxable_amount', label: 'Taxable Amount', type: 'number', input: 'TextInput' },
  { value: 'barrels', label: 'Barrels', type: 'number', input: 'TextInput' },
  { value: 'notes', label: 'Notes', type: 'text', input: 'TextInput' },
  { value: 'amount', label: 'Amount', type: 'number', input: 'TextInput' },
  { value: 'cost', label: 'Cost', type: 'number', input: 'TextInput' },
  { value: 'start_datetime', label: 'Start Datetime', type: 'datetime', input: 'DateTimeInput' },
  { value: 'end_datetime', label: 'End Datetime', type: 'datetime', input: 'DateTimeInput' },
  { value: 'user_decimal_1', label: 'User Decimal 1', type: 'number', input: 'TextInput' },
  { value: 'user_decimal_2', label: 'User Decimal 2', type: 'number', input: 'TextInput' },
  { value: 'user_decimal_3', label: 'User Decimal 3', type: 'number', input: 'TextInput' },
  { value: 'user_integer_1', label: 'User Integer 1', type: 'number', input: 'TextInput' },
  { value: 'user_integer_2', label: 'User Integer 2', type: 'number', input: 'TextInput' },
  { value: 'user_integer_3', label: 'User Integer 3', type: 'number', input: 'TextInput' },
  { value: 'user_integer_4', label: 'User Integer 4', type: 'number', input: 'TextInput' },
  { value: 'user_text_1', label: 'User Text 1', type: 'text', input: 'TextInput' },
  { value: 'user_text_2', label: 'User Text 2', type: 'text', input: 'TextInput' },
  { value: 'user_text_3', label: 'User Text 3', type: 'text', input: 'TextInput' },
  { value: 'user_text_4', label: 'User Text 4', type: 'text', input: 'TextInput' },
  { value: 'user_text_5', label: 'User Text 5', type: 'text', input: 'TextInput' },
  { value: 'user_text_6', label: 'User Text 6', type: 'text', input: 'TextInput' },
  { value: 'user_text_7', label: 'User Text 7', type: 'text', input: 'TextInput' },
  { value: 'user_text_8', label: 'User Text 8', type: 'text', input: 'TextInput' },
  { value: 'user_long_text_1', label: 'User Long Text 1', type: 'text', input: 'TextInput' },
  { value: 'user_datetime_1', label: 'User Datetime 1', type: 'datetime', input: 'DateTimeInput' },
  { value: 'user_datetime_2', label: 'User Datetime 2', type: 'datetime', input: 'DateTimeInput' },
  { value: 'group_subtotal', label: 'Group Subtotal', type: 'number', input: 'TextInput' },
  { value: 'status', label: 'Status', type: 'text', input: 'TextInput' },
  { value: 'assignee', label: 'Assignee ID', type: 'number', input: 'TextInput' },
  { value: 'equipment', label: 'Equipment ID', type: 'number', input: null },
  { value: 'created_at', label: 'Created At', type: 'datetime', input: null },
  { value: 'created_by', label: 'Created By ID', type: 'number', input: null }
]

export const TICKET_HIDDEN_REFERENCE_LABELS = TICKET_USER_DATA_FIELDS.reduce(
  (acc, field) => {
    acc[`ticket__user_data__${field.value}__label`] = field.label
    return acc
  },
  {} as Record<string, string>
)

export const BPA_CONTEXT_FIELDS: FieldType[] = [
  // client fields
  { value: 'client.app', label: 'App Name (e.g. Oil Command Web)', type: 'text', input: null },
  { value: 'client.version', label: 'App Version (e.g. v2.12.0-4f5cda)', type: 'text', input: null },
  { value: 'client.ip', label: 'Client IP Address', type: 'text', input: null },
  // session fields
  { value: 'user.id', label: 'Session User ID', type: 'number', input: null },
  { value: 'user.username', label: 'Session Username', type: 'text', input: null },
  { value: 'contact.id', label: 'Session Contact ID', type: 'number', input: null },
  { value: 'contact.first_name', label: 'Session Contact First Name', type: 'text', input: null },
  { value: 'contact.last_name', label: 'Session Contact Last Name', type: 'text', input: null },
  { value: 'contact.title', label: 'Session Contact Title', type: 'text', input: null },
  { value: 'company.id', label: 'Session Company ID', type: 'number', input: null },
  { value: 'company.name', label: 'Session Company Name', type: 'number', input: null },
  // date fields
  { value: 'now_datetime', label: 'Current Date (Object)', type: 'text', input: null },
  { value: 'now_isoformat', label: 'Current ISO Date Time (String)', type: 'text', input: null },
  { value: 'now_localtime', label: 'Current Local Date Time (String)', type: 'text', input: null },
  { value: 'local_date', label: 'Current Local Date (String)', type: 'text', input: null },

  // For summary tickets
  { value: 'first_child_ticket.name', label: 'Summarized Ticket(Child) Name', type: 'text', input: null },
  {
    value: 'first_child_ticket.P.<property name>',
    label: 'Summarized Ticket(Child) Property',
    type: 'text',
    input: null
  }
]
