import { contactApi, moduleApi } from '@/services/api-service'
import { Icon } from '@/ui/icons'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Alert, Avatar } from 'antd'
import { useParams } from 'react-router-dom'
import { UserAccessModal } from './user-access-modal'

type Props = {
  contacts: number[]
}

export const UserAccessSection = ({ contacts }: Props) => {
  const { id } = useParams()

  const moduleQuery = useQuery({
    ...moduleApi.get<{ id: number; contacts: number[] }>(Number(id)),
    enabled: !!id
  })

  const contactsQuery = useQuery({
    ...contactApi.list<{
      id: number
      first_name: string
      last_name: string
      image: string | null
      title: string
    }>({
      id__in: contacts?.join(','),
      fields: 'id,first_name,last_name,image,title',
      limit: 'None' as any
    }),
    enabled: !!contacts?.length
  })

  return (
    <div>
      <div className="flex justify-between items-center mb-16">
        <h5 className="font-bold m-0">User Access</h5>
        <UserAccessModal contacts={contacts} onSaved={() => moduleQuery.refetch()} />
      </div>
      {(() => {
        if (contactsQuery.isInitialLoading) {
          return <Spin spinning />
        }

        if (!contactsQuery.data || contactsQuery.data?.items.length === 0) {
          return <Alert message="No users have access to this module" type="info" />
        }

        return (
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-16">
            {contactsQuery.data?.items.map((contact) => (
              <div key={contact.id} className="flex border border-border rounded p-8">
                <Avatar size={32} src={contact.image} icon={<Icon name="fa:user-alt" className="text-16" />} />
                <div className="ml-8">
                  <div className="text-16 font-semibold">
                    {contact.first_name} {contact.last_name}
                  </div>
                  <div className="whitespace-nowrap">{contact.title}</div>
                </div>
              </div>
            ))}
          </div>
        )
      })()}
    </div>
  )
}
