import { Form, Input } from 'antd'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, isNameErrorAtom } from '../../../atoms'

export const NameField = () => {
  const name = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.name), []))
  const setCustomForm = useSetAtom(customFormAtom)
  const [isNameError, setIsNameError] = useAtom(isNameErrorAtom)

  return (
    <Form.Item
      label="Name"
      required
      validateStatus={isNameError ? 'error' : undefined}
      help={isNameError ? 'Please enter name' : undefined}
    >
      <Input
        data-cy="custom-form-name-input"
        value={name || ''}
        onChange={(e) => {
          if (isNameError) {
            setIsNameError(false)
          }

          setCustomForm((prev) => ({
            ...prev,
            name: e.target.value
          }))
        }}
      />
    </Form.Item>
  )
}
