import { useApp } from '@/hooks'
import { ticketImportTemplateApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { slugify } from '@/utils'
import { saveBlob } from '@/utils/blob'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

export function DownloadTemplateButton({ id, name }: { id: number; name: string }) {
  const { notification } = useApp()

  const downloadMutation = useMutation(ticketImportTemplateApi.download({ responseType: 'blob' }))

  const handleDownload = async () => {
    try {
      const data = await downloadMutation.mutateAsync({ id: id })

      await saveBlob(data as any, slugify(name || `ticket-import-${id}`) + `-${dayjs.now().formatTimestamp()}.xlsx`)

      notification.success({
        message: 'Download Success',
        description: 'The template has been downloaded'
      })
    } catch (error: any) {
      notification.error({
        message: 'Download Failed',
        description: error?.message || 'Unable to download the template'
      })
    }
  }

  return (
    <Button
      size={'small'}
      shape={'round'}
      iconName="fa:cloud-download"
      loading={downloadMutation.isLoading}
      onClick={handleDownload}
    >
      Template
    </Button>
  )
}
