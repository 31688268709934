import { PROPERTY_TYPE as PT } from '@/modules/custom-form/constants'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Property, Ticket } from '../schemas'

export const FIELD_PARSERS: Record<string, (t: Ticket) => any> = {
  timepoint_due: (t) => dayjs.parse(t.timepoint_due),
  timepoint_submitted: (t) => dayjs.parse(t.timepoint_submitted),
  customer_office: (t) =>
    t.customer_office_id ? { value: t.customer_office_id, label: t.customer_office__name } : null,
  customer: (t) =>
    t.customer_id
      ? { value: t.customer_id, label: `${t.customer__first_name || ''} ${t.customer__last_name || ''}` }
      : null,
  location: (t) => (t.location_id ? { value: t.location_id, label: t.location__name } : null),
  afe: (t) => (t.afe_id ? { value: t.afe_id, label: t.afe__user_afe_no } : null),
  job_code: (t) => (t.job_code_id ? { value: t.job_code_id, label: t.job_code__user_job_code_no } : null),
  support_office: (t) => (t.support_office_id ? { value: t.support_office_id, label: t.support_office__name } : null),
  assignee: (t) =>
    t.assignee_id
      ? { value: t.assignee_id, label: `${t.assignee__first_name || ''} ${t.assignee__last_name || ''}` }
      : null
}

export const PROPERTY_PARSER: Record<number, (v: string | null) => any> = {
  [PT.MultivariateDropdown]: (v) => (v ? v.split(',') : null),
  [PT.DateTime]: (v) => dayjs.parse(v),
  [PT.Date]: (v) => dayjs.parse(v, { tz: 'replace' }),
  [PT.Time]: (v) => dayjs.parse(v)
}

export const parseTicketProperty = (property: Property, value: any) => {
  const parser = PROPERTY_PARSER[property.property_type_id]
  return parser ? parser(value) : value
}

export const parseTicketField = (ticket: Ticket, key: string) => {
  const parser = FIELD_PARSERS[key]
  return parser ? parser(ticket) : get(ticket, key)
}
