import { useIsMobile } from '@/hooks'
import { useRedirect } from '@/hooks/use-redirect'
import cn from 'classnames'
import { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { HeaderDesktop, HeaderMobile } from './header'
import { SidebarDesktop, SidebarMobile } from './sidebar'
import { StatusBar } from './status-bar'

export const DashboardLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile()

  // if redirecting, do not render anything if redirecting
  const { isRedirecting } = useRedirect()
  if (isRedirecting) return <></>

  return (
    <div className={'h-screen w-screen flex flex-row'}>
      <div className={cn('flex flex-col overflow-hidden')}>{isMobile ? <SidebarMobile /> : <SidebarDesktop />}</div>
      <div className="flex-1 flex flex-col h-full overflow-hidden bg-container">
        {isMobile ? <HeaderMobile /> : <HeaderDesktop />}

        <StatusBar />

        <main className="relative flex-1 flex flex-col mt-2 [&>div]:p-8 rounded overflow-auto">
          {children ? children : <Outlet />}
        </main>
      </div>
    </div>
  )
}
