import { DATE_FORMAT } from '@/constants/date'
import { PAGE_SIZE } from '@/constants/general'
import { useAppDispatch } from '@/hooks'
import { JobTicketCard } from '@/modules/jas/job-manager/components/job-list/JobTicketCard'
import { JobTicket } from '@/modules/jas/job-manager/types'
import { Empty, Pagination, Skeleton, Spin } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { batch } from 'react-redux'
import { useGroupedJobTickets, useJobManager, useSelectedJobTicket } from '../../hooks'
import { setIsEditing, setPagination, setSelectedJobData } from '../../slice'

export const JobList: React.FC = () => {
  const dispatch = useAppDispatch()

  const { pagination } = useJobManager()
  const { groupedTickets, tickets, total, isSuccess, isFetching, isRefetching } = useGroupedJobTickets()
  const { selectedTicket, selectedJobData } = useSelectedJobTicket()

  const onPaginationChange = (page: number, pageSize: number) => {
    dispatch(setPagination({ page, pageSize }))
  }

  const onJobSelect = (ticket: JobTicket) => {
    if (selectedJobData?.id === ticket.id) return

    batch(() => {
      dispatch(setSelectedJobData(ticket.jobData))
      dispatch(setIsEditing(false))
    })
  }

  return (
    <div className="w-full h-full lg:max-w-[250px] xl:max-w-[300px] relative p-2 mb-16">
      {/* md:max-w-[250px] lg:max-w-[300px]  */}
      <div className="p-6 pb-[30px] md:pb-[24px] rounded shadow w-full flex whitespace-nowrap lg:whitespace-normal lg:flex-wrap lg:content-start lg:items-start lg:h-full overflow-auto">
        {isFetching && !isRefetching && (
          <div className="flex lg:flex-wrap lg:w-full">
            {[...Array(3)].map((i) => (
              <div className="px-6 min-w-[275px] lg:min-w-full lg:w-full lg:p-0" key={Math.random()}>
                <div className="shadow p-10 rounded mb-10 w-full">
                  <Skeleton active />
                </div>
              </div>
            ))}
          </div>
        )}
        {isFetching && isRefetching && (
          <div className="absolute top-0 left-0 h-full w-full z-40 bg-container dark:bg-dark-container opacity-80 grid place-items-center">
            <Spin spinning={true} />
          </div>
        )}

        {(!isFetching || isRefetching) &&
          !!tickets?.length &&
          groupedTickets.map((group) => {
            const [gDate, gTickets] = group
            return (
              <div key={gDate} className="flex lg:block lg:w-full">
                <div className="bg-gray-600 text-center py-[3px] rounded-sm text-sm mb-4 text-white w-full hidden lg:block lg:sticky top-0 z-20 shadow-md">
                  {gDate !== '--/--/----' ? dayjs(gDate).format(DATE_FORMAT) : '--/--/----'}
                </div>
                {gTickets.map((ticket) => {
                  return (
                    <div
                      className="pr-6 lg:px-0 min-w-[250px] lg:min-w-full max-w-[250px]  lg:max-w-auto flex-auto"
                      key={ticket.id}
                    >
                      <JobTicketCard
                        selected={ticket.id == selectedTicket?.id}
                        onSelect={onJobSelect}
                        ticket={ticket}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}

        {!isFetching && !tickets?.length && (
          <div className="w-full flex flex-col items-center justify-center h-full">
            <Empty description="No Job Tickets" />
          </div>
        )}
      </div>

      <div className={'w-full absolute z-40 bottom-[16px] lg:bottom-[1px] pr-4'}>
        <Pagination
          size="small"
          defaultPageSize={PAGE_SIZE}
          current={pagination.page}
          onChange={onPaginationChange}
          total={total}
          className={'text-center bg-white dark:bg-dark-container lg:p-8 w-full lg:shadow lg:rounded'}
          showLessItems={true}
          showSizeChanger={false}
        />
      </div>
    </div>
  )
}
