import { jasScheduleRuleTemplateApi } from '@/services/api-service'
import { ListResponse } from '@/types/api/core'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { RuleTemplate, ruleTemplateSchema } from '../schemas/rule-template'

type Variables = {
  [key: string]: string | number | boolean | null | undefined
}

export const useRuleTemplatesQuery = (variables?: Variables) => {
  const ruleTemplatesQuery: UseQueryResult<ListResponse<RuleTemplate>> = useQuery(
    jasScheduleRuleTemplateApi.list(variables, {
      transformResponse: (res) => {
        return JSON.parse(res).map((item: any) => ruleTemplateSchema.parse(item))
      }
    })
  )

  return ruleTemplatesQuery
}
