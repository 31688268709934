import { useSession } from '@/hooks'
import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { Select } from '@/ui/select'
import { SelectProps } from 'antd'
import { FC } from 'react'
import { TICKET_STATUS_LIST } from '../constants'

export type StatusSelectProps = SelectProps & {
  formId?: number | null
}

export const StatusSelect: FC<StatusSelectProps> = ({ formId, ...props }) => {
  const { user } = useSession()

  const { getStatusOptions } = useCustomFormsStatus()
  const allOptions = getStatusOptions(formId)

  // disable statuses based on user's ticket status limit
  const statusLimit = user?.profile?.userdata_json?.ticket_status_limit || 5 // DISPUTED
  const allowedStatusesKeys = TICKET_STATUS_LIST.slice(0, statusLimit + 1)
  const allowedStatuses = allOptions.map((o) => ({ ...o, disabled: allowedStatusesKeys.indexOf(o.value) === -1 }))

  return <Select options={allowedStatuses} {...props} />
}
