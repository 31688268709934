import { companyApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Form } from 'antd'

export const CompanySelect = () => {
  const form = Form.useFormInstance()
  const formValues = Form.useWatch([], form)
  const company = formValues?.company || {}

  return (
    <QuerySelect
      value={{ label: company.name, value: company.id }}
      apiEndpoint={companyApi.list}
      apiSearchBy="name"
      labelInValue
      renderOption={(item) => ({ label: item.name, value: item.id })}
      onChange={({ label, value }) => form.setFieldValue('company', { id: value, name: label })}
    />
  )
}
