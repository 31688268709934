import { JOTAI_GLOBAL_STORE } from '@/constants/app'
import { transformCustomForm } from '@/modules/ticket/form/helpers'
import { CustomForm, CustomFormSchema } from '@/modules/ticket/form/schemas'
import { customFormApiV2 } from '@/services/api-service'
import { zodQueryFields } from '@/utils/zod'
import { useQuery } from '@tanstack/react-query'
import { atom, useAtom } from 'jotai'
import { isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'

const globalCustomFormsAtom = atom<CustomForm[]>([])

const customFormFields = zodQueryFields(CustomFormSchema)

export const useGlobalCustomForms = () => {
  const customFormsQuery = useQuery(customFormApiV2.list<CustomForm>({ fields: customFormFields, limit: 25 }))
  const [globalCustomForms, setGlobalCustomForms] = useAtom(globalCustomFormsAtom, { store: JOTAI_GLOBAL_STORE })

  useEffect(() => {
    if (customFormsQuery.data) {
      setGlobalCustomForms(customFormsQuery?.data?.items?.map((item) => transformCustomForm(item)) || [])
    }
  }, [customFormsQuery.data, setGlobalCustomForms])

  const getCustomForm = useCallback(
    (formId: number) => (Number(formId) ? globalCustomForms.find((form) => Number(form.id) === Number(formId)) : null),
    [globalCustomForms]
  )

  const getPropertyById = useCallback(
    (formId: number, propertyId: number) =>
      Number(formId) && Number(propertyId) ? getCustomForm(formId)?._propertyById?.[propertyId] : null,
    [getCustomForm]
  )

  const getPropertyByKey = useCallback(
    (formId: number, propertyKey: string) =>
      Number(formId) && !isEmpty(propertyKey) ? getCustomForm(formId)?._propertyByKey?.[propertyKey] : null,
    [getCustomForm]
  )

  const getAllPropertiesByKey = useCallback(
    (propertyKey: string) => {
      return globalCustomForms.map((form) => form._propertyByKey[propertyKey]).filter(Boolean)
    },
    [globalCustomForms]
  )

  return {
    customForms: globalCustomForms || [],
    customFormsQuery: customFormsQuery,
    getCustomForm,
    getPropertyById,
    getPropertyByKey,
    getAllPropertiesByKey
  }
}
