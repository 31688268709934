import { CodeEditor } from '@/components/code-editor'
import { Select } from '@/ui'
import { Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BPA_VARIABLE_TYPES_LIST } from '../../constants'
import { BpaActionSetVariable } from '../../schemas'

type Props = {
  action: BpaActionSetVariable
  onChange: (data: Partial<BpaActionSetVariable>) => void
}

export const InputSetVariable: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="fled">
      <div className="flex flex-row gap-10">
        <Form.Item label="Name" className="flex-grow">
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 10 }}
            defaultValue={action.name}
            onChange={(e) => onChangeDebounced({ name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Type">
          <Select
            defaultValue={action.d_type}
            options={BPA_VARIABLE_TYPES_LIST}
            style={{ width: 100 }}
            onChange={(value) => onChange({ d_type: value })}
          />
        </Form.Item>
      </div>
      <div className="flex-grow">
        {action.d_type === 'json' || action.d_type === 'yaml' ? (
          <div className="mb-10">
            <CodeEditor
              minLines={2}
              maxLines={25}
              key={action.d_type} // re-render editor
              defaultValue={action.value}
              onChange={(value) => onChangeDebounced({ value: value })}
              mode={action.d_type}
            />
          </div>
        ) : (
          <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={action.value}
              onChange={(e) => onChangeDebounced({ value: e.target.value })}
            />
          </Form.Item>
        )}
      </div>
    </div>
  )
}

export const InputSetVariableMemo = React.memo(InputSetVariable)
