import { useAppSelector } from '@/hooks'
import { RuleTemplate } from '@/schemas/rule-template'
import { Button } from '@/ui/button'
import { useSetAtom } from 'jotai'
import { isAddModeAtom, isDeleteModeAtom, setNewCurrentRuleTemplateAtom } from '../../atoms'

export const AddNewButton = () => {
  const { user, company } = useAppSelector((state) => state.session)
  const setIsAddMode = useSetAtom(isAddModeAtom)
  const setNewCurrentRuleTemplate = useSetAtom(setNewCurrentRuleTemplateAtom)
  const setIsDeleteMode = useSetAtom(isDeleteModeAtom)

  const handleClick = () => {
    if (!user) {
      throw new Error('User is not logged in')
    }

    if (!company) {
      throw new Error('Company is not selected')
    }

    const newRuleTemplate: Omit<RuleTemplate, 'id'> = {
      comment: '',
      company: company.id,
      name: '',
      data: [],
      end_date: null,
      start_date: null,
      type: 'Employee',
      rules_count: 0
    }

    setIsAddMode(true)
    setIsDeleteMode(false)
    setNewCurrentRuleTemplate(newRuleTemplate)
  }

  return (
    <Button iconName={'fa:add'} type="primary" onClick={handleClick}>
      Add Rule
    </Button>
  )
}
