import { Form } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { EditableFieldsSelect } from '../editable-fields-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { PropertiesSelect } from '../properties-select'
import { TableViewSelect } from '../table-view-select'
import { TicketViewFlags } from '../ticket-view-flags'

export const ModuleTypeTicketsFields = () => {
  const form = Form.useFormInstance()
  const stagesFormId = Form.useWatch(['data', 'stages_form_id'], form) as number | undefined
  const viewType = Form.useWatch(['view_type'], form) as string | undefined

  return (
    <div>
      {viewType === 'T' && <TicketViewFlags />}
      <div className="flex gap-x-16">
        <TableViewSelect formItemProps={{ label: 'Default Table View', name: 'selected_view' }} />
        <ForceViewCheckbox />
        <HideDefaultNoneView />
        <EditableFieldsSelect formItemProps={{ className: 'flex-1' }} />
      </div>
      <div className="flex w-2/3 gap-x-16">
        <CustomFormSelect
          formItemProps={{
            label: 'Custom Form (for Labels and Properties Selection)',
            name: ['data', 'stages_form_id'],
            className: 'grow'
          }}
        />
        <PropertiesSelect
          customFormsIds={stagesFormId ? [stagesFormId] : []}
          formItemProps={{
            label: 'Search Properties',
            name: ['data', 'search_props'],
            className: 'grow'
          }}
          selectProps={{ mode: 'multiple' }}
        />
      </div>
      <h5 className="font-bold">Mobile Web Config</h5>
      <div className="grid grid-cols-5 gap-x-16">
        {formItems.map((item, index) => {
          return (
            <PropertiesSelect
              key={index}
              formItemProps={{
                label: item.label,
                name: item.name
              }}
              customFormsIds={stagesFormId ? [stagesFormId] : []}
            />
          )
        })}
      </div>
    </div>
  )
}

const formItems = [
  {
    label: 'Thumbnail',
    name: ['data', 'thumbnail']
  },
  {
    label: 'Title',
    name: ['data', 'title']
  },
  {
    label: 'Description 1',
    name: ['data', 'description1']
  },
  {
    label: 'Description 2',
    name: ['data', 'description2']
  },
  {
    label: 'Search By',
    name: ['data', 'search_by']
  }
]
