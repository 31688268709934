import { useCompanyFeatures, useSession } from '@/hooks'
import { processModules } from '@/modules/navigation/helpers'
import { moduleApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Module } from './types'

const SLUG_BY_TYPE: Record<string, string> = {
  jm: 'job-manager'
}

export const useUserModulesQuery = () => {
  const { company } = useSession()
  return useQuery(
    moduleApi.list<Module>(
      {
        limit: 'None',
        company_id__eq: company.id,
        deleted_at__isnull: true
      },
      {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }
    )
  )
}

export const useCurrentModuleQuery = () => {
  const { moduleId: moduleIdStr } = useParams<{ moduleId: string }>()
  const moduleId = moduleIdStr ? Number(moduleIdStr) : null

  const query = useUserModulesQuery()

  const module = useMemo(() => {
    if (!moduleId) return null
    if (!query.isSuccess || !query.data) return null

    return query.data?.items?.find((m) => m.id === moduleId)
  }, [query.data, query.isSuccess, moduleId])

  const link = useMemo(() => {
    if (module) {
      const viewType = module.view_type.toLowerCase()
      const slug = SLUG_BY_TYPE[viewType] || viewType || ''
      return `/m2/${module.id}/${slug}`
    } else {
      return `/m2/${moduleId}`
    }
  }, [module, moduleId])

  return {
    ...query,
    isModule: !!moduleId,
    moduleId,
    module,
    link
  }
}

export const useModuleLink = () => {
  const { contact } = useSession()
  const modulesQuery = useUserModulesQuery()
  const modules = modulesQuery.data?.items || []
  const { forceV2Tickets } = useCompanyFeatures()

  const getLink = (by: 'id' | 'type', value: any, suffix?: string) => {
    const moduleLink = processModules(
      modules.filter((m) => {
        if (by === 'id') return m.id === Number(value)
        if (by === 'type') return m.view_type === value
      }) || [],
      contact.id,
      forceV2Tickets?.enabled || false
    )[0]

    if (!moduleLink) return undefined

    return `${moduleLink.to || moduleLink.key}${suffix || ''}`
  }

  return { getLink }
}
