import { DYNAMIC_DATES } from '@/constants/general'
import { TLabelFn } from '@/hooks/use-app'
import { TICKET_COLUMNS_MAP } from '@/modules/ticket/columns'
import { ListParams } from '@/types/api/core'
import { EntityColumn } from '@/types/entity-column'
import { RequiredKeys } from '@/types/general'
import { deepMerge, safeJsonParse } from '@/utils'
import dayjs from 'dayjs'
import { isEmpty, template } from 'lodash'
import { Module } from '../module/schemas'
import { fieldsFormatter, propertiesFormatter } from './formatters'
import { TableView, TableViewFilter } from './types'

const parseFormIds = (customForms: string): string[] => {
  if (customForms) {
    try {
      return JSON.parse(customForms)
    } catch (err) {
      console.log('TableView:', 'Failed to parse table views selected custom forms')
      return []
    }
  }
  return []
}

const compileValue = (value: string, context = {}) => {
  // todo: add logic to compile value based on context
  return value
}

export const getFiltersQuery = (filters: TableViewFilter[], isManager = false, context = {}): string[] => {
  return filters.reduce((queries, filter) => {
    const forManager = !filter.not_for_manager
    const forUser = !filter.not_for_user
    if (!((forManager && isManager) || (forUser && !isManager))) {
      return queries
    }

    const lookupField = `${filter.path || ''}${filter.field}__${filter.type}`
    let query = ''

    if (filter.type === 'range' && DYNAMIC_DATES[(filter.value || '') as keyof typeof DYNAMIC_DATES]) {
      let start: string | null = null
      let end: string | null = null

      if (filter.value === 'mtd') {
        start = dayjs().startOf('month').format()
        end = dayjs().endOf('day').format()
      } else if (filter.value === 'lm') {
        start = dayjs().subtract(1, 'month').startOf('month').format()
        end = dayjs().subtract(1, 'month').endOf('month').format()
      } else if (filter.value === 'lw') {
        start = dayjs().subtract(1, 'week').startOf('week').format()
        end = dayjs().subtract(1, 'week').endOf('week').format()
      } else if (filter.value === 'lww') {
        start = dayjs().subtract(1, 'week').startOf('week').add(1, 'days').format()
        end = dayjs().subtract(1, 'week').endOf('week').subtract(1, 'days').format()
      } else if (filter.value === 'ld') {
        const days = Number(isEmpty(filter.value2) ? 7 : filter.value2) // default to last 5 days
        start = dayjs().subtract(days, 'days').startOf('day').format()
        end = dayjs().endOf('day').format()
      }

      if (start && end) {
        query = `${lookupField}|${start},${end}`
      }
    } else if (filter.type === 'in') {
      const value = String(filter.value)
      if (!isEmpty(value)) {
        query = `${filter.path || ''}${filter.field}__in|${value}`
      }
    } else {
      const value1 = filter.value ? compileValue(filter.value, context) : filter.value
      const value2 = filter.value2 ? compileValue(filter.value2, context) : filter.value2
      const value = filter.type === 'range' ? `${value1},${value2}` : value1
      query = `${lookupField}|${value}`
    }

    if (!isEmpty(query)) {
      query = (filter.negate ? '~' : '') + query
      queries.push(query)
    }

    return queries
  }, [] as string[])
}

export const getTableViewQuery = (tableView: TableView, isManager = false, context = {}) => {
  const config = tableView.config

  let query: RequiredKeys<ListParams, 'Q' | 'E'> = { Q: [], E: [] }

  // craft selected custom forms query
  const selectedForms = parseFormIds(tableView.options?.custom_forms || '')
  if (selectedForms.length && !(selectedForms.length == 1 && selectedForms[0] == '*')) {
    const cfField = tableView.type === 'I' ? 'ticket__custom_form__id__in' : 'custom_form__id__in'
    query.Q.push(`${cfField}|${selectedForms.join(',')}`)
  }

  // craft applied filters query
  query.Q = [...query.Q, ...getFiltersQuery(tableView.filters || [], isManager, context)]

  // craft custom filters query
  if (config?.use_custom_filters && config?.custom_filters?.enabled) {
    try {
      const filters = JSON.parse(template(JSON.stringify(config?.custom_filters?.query || {}))(context))
      query = deepMerge(query, filters)
    } catch (err) {
      console.log('TableView:', 'Failed to parse table views custom filters query')
    }
  }

  // craft order
  const tableViewSort = tableView.sort_1
  if (tableViewSort) {
    if (tableViewSort.includes('ticket__property__')) {
      const orderBy = tableViewSort.replace('ticket__', '')
      if (orderBy) query.order = orderBy
    } else if (tableViewSort.endsWith('custom_stage')) {
      query.order = (tableViewSort[0] === '-' ? '-' : '') + 'computed_status'
    } else {
      query.order = tableViewSort
    }
  }

  return query
}

export const transformTableView = (item: any) => {
  return {
    ...item,
    filters: safeJsonParse(item.filters) || [],
    selected_fields: safeJsonParse(item.selected_fields) || [],
    options: {
      custom_forms: item.options__custom_forms
    },
    config: safeJsonParse(item.config_json) || {}
  }
}

type FieldColumn = EntityColumn & {
  fixed?: 'left' | 'right'
  width?: number
  custom_name?: string
  extra?: string
  type?: string
}

type DisplayFieldContext = {
  statuses?: Record<number, Record<string, string>> | null
  module?: Module | null
}

export class DisplayField {
  entity: 'ticket' | 'line_item' = 'ticket'
  column: FieldColumn
  fixed: 'left' | 'right' | undefined
  width: number

  key: string
  name: string
  pathField: string
  pathField1: string
  field: string
  fieldVerbose: string
  fieldType: string
  sortField: string | undefined
  formatter: any = null
  queryParams: string[] = []
  isProperty = false
  propertyId: number | null = null
  propertyKey: string | null = null
  usePropertyKey = false
  context: DisplayFieldContext
  error: string | null = null

  constructor(column: FieldColumn, labelFn: TLabelFn, context?: DisplayFieldContext) {
    this.context = context || {}
    this.name = isEmpty(column.custom_name)
      ? labelFn('ticket__' + column.field_verbose + '__label', column.name)
      : column.custom_name || ''
    this.pathField = (column.path ? column.path : '') + column.field + (column.extra || '')
    this.pathField1 = (column.path ? column.path : '') + column.field
    this.field = column.field
    this.fieldVerbose = column.field_verbose
    this.fieldType = column.type || ''

    this.cleanup()

    if (this.fieldVerbose === 'property') {
      this.key = `property__${this.field}`
      this.isProperty = true
      this.propertyId = Number(this.field) || null
      this.formatter = propertiesFormatter[this.fieldType] ? propertiesFormatter[this.fieldType] : null
      if ((this.field || '').toString().startsWith('k=')) {
        this.usePropertyKey = true
        this.field = this.field.substring(2)
        this.propertyKey = this.field
      }
      this.queryParams = [this.key]
      this.sortField = this.key
    } else {
      this.key = this.pathField
      column = { ...column, ...(TICKET_COLUMNS_MAP[this.key] || {}) }

      // custom stage is same as status
      if (this.key === 'custom_stage') {
        this.sortField = 'computed_status'
      } else if (this.key === 'customer__name') {
        this.sortField = 'customer__first_name'
      } else if (this.key === 'contacts__contact__profile__user__username') {
        this.sortField = undefined // sorting not supported
      } else if (this.key === 'team_members') {
        this.sortField = undefined // sorting not supported
      } else {
        this.sortField = this.key
      }

      this.queryParams = column.query_params || [this.key]
      this.formatter = fieldsFormatter[this.key]
    }

    this.column = column
    this.width = column.width || Math.max(this.name.length * 12, 150)
    this.fixed = column.fixed
  }

  cleanup() {
    if (this.entity === 'ticket') {
      if (this.pathField.startsWith('ticket__property__')) {
        return // valid
      }

      // invalid, fixable
      if (this.pathField.startsWith('ticket__')) {
        this.pathField = this.pathField.substring(8)
        this.error = 'Invalid Configuration'
      } else if (this.pathField === 'ticket_computed_status') {
        this.pathField = 'computed_status'
        this.error = 'Invalid Configuration'
      }
    }
  }

  format(value: any, property?: any) {
    if (this.isProperty) {
      return this.formatProperty(value, property)
    }

    return this.formatField(value)
  }

  formatField(obj: any) {
    return this.formatter ? this.formatter(obj, this) : obj?.[this.key]
  }

  formatProperty(value: any, property?: any) {
    return this.formatter ? this.formatter(value, this, property) : value
  }
}
