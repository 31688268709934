import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { Property } from '@/modules/ticket/form/schemas'
import { propertyApi } from '@/services/api-service'
import { OptionType, QuerySelect, QuerySelectProps, Select } from '@/ui/select'
import { OptionHelp } from '@/ui/select/option-help'
import { SelectProps } from 'antd'
import { FC, useMemo } from 'react'
import { z } from 'zod'

export type PropertySelectProps = SelectProps & {
  formId?: number | null
  valueField?: 'id' | 'key'
  optionMap?: (property: Property) => OptionType
}

export const PropertySelect: FC<PropertySelectProps> = ({ formId, valueField = 'id', optionMap, ...props }) => {
  const { customForms, getCustomForm } = useGlobalCustomForms()

  const options = useMemo(() => {
    const forms = formId ? [getCustomForm(formId)] : customForms

    return (
      forms
        .map(
          (form) =>
            form?.property_groups?.map((group) => ({
              key: group.id,
              label: formId ? `${group.name}` : `${form.name} / ${group.name}`,
              options: group._properties.map(
                optionMap ??
                  ((property) => ({
                    key: property.id,
                    text: `${property.name} ${property.key} ${group.name} ${property.id}`.toLowerCase(),
                    value: property[valueField],
                    property: property,
                    label: <OptionHelp label={property.name} tipTitle={`Key: ${property.key}`} />
                  }))
              )
            })) || []
        )
        .flat() || []
    )
  }, [customForms, formId, getCustomForm, optionMap, valueField])

  return <Select options={options} filterBy={'text'} {...props} />
}

export type PropertyQuerySelectProps = Omit<QuerySelectProps, 'apiEndpoint' | 'apiSearchBy'> & {
  valueField?: 'id' | 'key'
}

export const PropertyQuerySelect: FC<PropertyQuerySelectProps> = ({ valueField = 'id', ...props }) => {
  return (
    <QuerySelect
      apiEndpoint={propertyApi.list}
      apiQueryParams={{
        fields: z.object({
          id: z.number(),
          name: z.string(),
          key: z.string(),
          property_group__name: z.string(),
          property_group__custom_form__name: z.string()
        }),
        order: 'property_group__custom_form__name,property_group__name,name'
      }}
      apiValueBy={valueField}
      apiSearchBy={(text) => ({
        'Q[]': text
          ? [
              `name__icontains|${text}`,
              `key__icontains|${text}`,
              'or',
              `id__icontains|${text}`,
              'or',
              `property_group__name__icontains|${text}`,
              'or',
              `property_group__custom_form__name__icontains|${text}`,
              'or'
            ]
          : undefined
      })}
      renderOption={(p) => ({
        value: p[valueField],
        label: (
          <OptionHelp
            label={`${p.property_group__custom_form__name} / ${p.property_group__name} / ${p.name}`}
            tipTitle={`Key: ${p.key}`}
          />
        )
      })}
      {...props}
    />
  )
}
