import { z } from 'zod'

export const CustomFormSchema = z.object({
  id: z.number(),
  companies: z.array(z.number()).nullish(),
  description: z.string().nullish(),
  name: z.string().nullish(),
  primary_company_id: z.number().nullish(),
  primary_company__name: z.string().nullish(),
  type: z.string().nullish(),
  public: z.boolean().nullish(),
  permissions: z.array(
    z.object({
      company__name: z.string()
    })
  )
})

export type CustomForm = z.infer<typeof CustomFormSchema>
