import qs, { BooleanOptional, IParseBaseOptions, IParseOptions, IStringifyOptions } from 'qs'

/**
 * Decode primitive values from query string (e.g. boolean, null, number)
 */
const primitiveDecoder: IParseBaseOptions['decoder'] = (strValue: string, defaultDecoder, charset) => {
  // boolean
  if (strValue === 'true') return true
  if (strValue === 'false') return false

  // null
  if (strValue === 'null') return null

  // string
  const numValue = Number(strValue.trim() || NaN)
  if (!isNaN(numValue)) return numValue

  // default
  return defaultDecoder(strValue, defaultDecoder, charset)
}

/**
 * Parse query string
 */
export const parseQueryString = (
  str: string,
  options?: IParseOptions<BooleanOptional> & { decoder?: never | undefined }
): ReturnType<typeof qs.parse> =>
  qs.parse(str, {
    decoder: primitiveDecoder,
    allowDots: true,
    decodeDotInKeys: false,
    ignoreQueryPrefix: true,
    ...options
  })

/**
 * Stringify object to query string
 */
export const stringifyQueryString = (
  obj: any,
  options?: IStringifyOptions<BooleanOptional>
): ReturnType<typeof qs.stringify> =>
  qs.stringify(obj, {
    allowDots: true,
    encodeDotInKeys: false,
    filter: (prefix, value) => {
      const skip =
        value === '' ||
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0)
      return skip ? undefined : value
    },
    ...options
  })
