import { debounce } from 'lodash'
import { RefObject, useEffect, useState } from 'react'
import { NavTabItem } from './types'

export const useNavTabs = (
  items: NavTabItem[],
  containerRef: RefObject<HTMLElement>,
  tabRefs: RefObject<(HTMLElement | null)[]>,
  overflowThreshold = 50
) => {
  const [moreTabs, setMoreTabs] = useState<NavTabItem[]>([])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const buildOverflowTabs = debounce(() => {
      const hiddenTabs: NavTabItem[] = []
      const containerWidth = container.clientWidth
      let accumulatedWidth = 0

      const tabWidths = tabRefs.current?.map((tab) => tab?.offsetWidth || 0) || []

      for (let i = 0; i < tabWidths.length; i++) {
        accumulatedWidth += tabWidths[i]
        if (accumulatedWidth > containerWidth - overflowThreshold) {
          hiddenTabs.push(items[i])
        }
      }

      setMoreTabs(hiddenTabs)
    }, 25)

    // observe resize
    const resizeObserver = new ResizeObserver(buildOverflowTabs)
    resizeObserver.observe(container)
    tabRefs.current?.forEach((tab) => {
      if (tab) resizeObserver.observe(tab)
    })

    // initial build
    buildOverflowTabs()

    // cleanup
    return () => {
      resizeObserver.disconnect()
      buildOverflowTabs.cancel()
    }
  }, [containerRef, items, overflowThreshold, tabRefs])

  const scrollToTab = (tab: NavTabItem) => {
    // skip, if no hidden tabs
    if (moreTabs.length === 0) return

    // skip, if tab not found
    const index = items.indexOf(tab)
    if (index === -1 || !tabRefs.current?.[index]) return

    // scroll to tab
    tabRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  return { moreTabs, scrollToTab }
}
