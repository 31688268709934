import { Button, CopyButton, Popconfirm } from '@/ui'
import { Spin } from '@/ui/spin'
import { cn } from '@/utils'
import { Form, FormItemProps, Input, InputProps, InputRef } from 'antd'
import { useAtom } from 'jotai'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { reloadNextTicketNoAtom } from '../../../atoms'
import {
  useCustomForm,
  useNextTicketName,
  useTicketFormValues,
  useTicketFormWatch,
  useTicketFullName
} from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'name'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<InputProps>
}

export const NameInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const ticketName = useTicketFormWatch('name')
  const { getPropertyValueByKey, setFieldValue } = useTicketFormValues()
  const ticketPrefix = useMemo(() => getPropertyValueByKey('ticket_prefix'), [getPropertyValueByKey])
  const fullName = useTicketFullName()
  const { nextTicketNo, forceRefetch, query } = useNextTicketName()
  const [reloadNextNo, setReloadNextNo] = useAtom(reloadNextTicketNoAtom)
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef<InputRef>(null)

  const { isHiddenField, customForm } = useCustomForm()

  const switchToEdit = () => {
    if (customForm.editable_ticket_name) {
      setEditMode(true)
      setTimeout(() => inputRef.current?.focus(), 0) // Ensure it's after render
    }
  }

  useEffect(() => {
    if (nextTicketNo && ticketName !== nextTicketNo) {
      setFieldValue('name', nextTicketNo)
    }
    // note: Should run only when nextTicketNo is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTicketNo, setFieldValue])

  const applyNextNo = async () => {
    await forceRefetch()
    // Force apply, even if there is a change from user
    setFieldValue('name', nextTicketNo)
  }

  if (isHiddenField(KEY)) return <div></div>

  return (
    <Popconfirm
      title="Re-generate Ticket #?"
      open={reloadNextNo}
      onConfirm={applyNextNo}
      onOpenChange={() => setReloadNextNo(false)}
      placement="leftTop"
    >
      <Form.Item
        name={KEY}
        label={
          <div className="flex flex-row gap-2 items-center">
            <Label name={KEY} k={'ticket__name__label'} />
            {customForm.editable_ticket_name && (
              <Button size="small" type="text" iconName="fa:edit" onClick={switchToEdit} />
            )}
            {query.isFetching && <Spin size="small" />}
          </div>
        }
        className={'group cursor-text'}
        {...formItemProps}
      >
        <div className={cn('flex flex-row items-center gap-2', editMode ? '' : 'hidden')}>
          <Input
            ref={inputRef}
            inputMode="numeric"
            value={ticketName}
            disabled={!customForm.editable_ticket_name}
            {...inputProps}
            prefix={ticketPrefix}
            onBlur={() => setEditMode(false)}
          />
        </div>
        {!editMode && (
          <>
            <div>
              <Input hidden {...inputProps} />
              <div
                className={'rounded border border-dashed flex flex-row gap-4 items-center justify-between py-2 px-10'}
              >
                <span className={'text-md font-bolder w-full'} onClick={switchToEdit}>
                  {fullName}
                </span>
                <CopyButton
                  size={'small'}
                  type={'text'}
                  copyText={fullName}
                  className={'opacity-0 group-hover:opacity-100 transition-opacity !text-gray-300'}
                />
              </div>
            </div>
          </>
        )}
      </Form.Item>
    </Popconfirm>
  )
}
