import classNames from 'classnames'

type Props = {
  className?: string
  children: React.ReactNode
}

export const PageTitle = ({ className, children }: Props) => {
  return <h3 className={classNames('font-medium mb-0 text-24', className)}>{children}</h3>
}
