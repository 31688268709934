import { useAppSelector } from './use-app-selector'

export const useSession = () => {
  const session = useAppSelector((state) => state.session)

  return {
    ...session,
    authenticated: !!session?.user
  }
}
