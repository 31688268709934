import { UseQueryResult } from '@tanstack/react-query'
import React, { FC, useEffect, useState } from 'react'

type Props = {
  query?: UseQueryResult<any, any>
  time?: number
  fallback?: React.ReactNode
  children: React.ReactNode
}

/**
 * Renders children after a specified time.
 * Mimics async rendering of components.
 * Provides snappy UI experience when rendering expensive components.
 *
 * @param query to wait for before rendering children
 * @param fallback to render while waiting for query to finish
 * @param timeout in milliseconds to wait before rendering children. Default is 50ms.
 * @param children to render after timeout or when query is finished
 */
export const DeferRender: FC<Props> = ({ query, time, fallback, children }) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    if (query) {
      if (!query.isLoading && !query.isFetching) {
        setRender(true)
      }
    } else {
      window.setTimeout(() => {
        setRender(true)
      }, time || 50)
    }
  }, [query, time])

  return <>{render ? children : fallback || ''}</>
}
