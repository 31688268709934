import { JasResourceEvent, JasResourcePatch, JasResourceResponse, JasResourceRule } from '@/types/jas-resource'
import { ScheduleType } from '@/utils'
import dayjs, { Dayjs } from 'dayjs'

type Params = {
  data: JasResourceResponse
  startDate: Dayjs
  endDate: Dayjs
}

export type ScheduleItem = {
  type: ScheduleType | ''
  data: JasResourceRule | JasResourcePatch | JasResourceEvent[] | null
}

export const createSchedule = ({ data, startDate, endDate }: Params) => {
  const diffInDays = getDifferenceInDays(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
  const schedules: Record<string, ScheduleItem> = {}

  for (let i = 0; i <= diffInDays; i++) {
    const currentDate = startDate.add(i, 'day').format('YYYY-MM-DD')

    schedules[currentDate] = {
      type: '',
      data: null
    }
  }

  for (const scheduleItem of Object.entries(schedules)) {
    const [scheduleItemDate] = scheduleItem
    // Convert rules to schedule items
    const { rules, patches, events } = data
    if (rules) {
      for (const rule of rules) {
        const diffInDays = getDifferenceInDays(rule.start_date, rule.end_date)

        if (!rule.template__data || !rule.template__data.length) {
          continue
        }

        const dayRules = rule.template__data.reduce<('W' | 'O' | 'U')[]>(
          (accumulator, templateData) => {
            const dayOnCount = Number(templateData.day_on) || 0
            const dayOffCount = Number(templateData.day_off) || 0

            if (dayOnCount === 0 && dayOffCount === 0) {
              accumulator.push('U')
            }

            for (let i = 0; i < dayOnCount; i++) {
              accumulator.push('W')
            }

            for (let i = 0; i < dayOffCount; i++) {
              accumulator.push('O')
            }

            return accumulator
          },
          [] as ('W' | 'O' | 'U')[]
        )

        let dayRulesIndex = 0

        // Checking for <= because same day will be 0
        for (let i = 0; i <= diffInDays; i++) {
          if (!rule.template__data || !rule.template__data.length) {
            continue
          }

          const date = dayjs(rule.start_date).add(i, 'day')
          const dayRule = dayRules[dayRulesIndex]

          if (date.isSame(scheduleItemDate, 'day')) {
            schedules[date.format('YYYY-MM-DD')] = {
              type: dayRule,
              data: rule
            }
          }

          // Reset dayRulesIndex if it's the last day rule
          if (dayRulesIndex + 1 >= dayRules.length) {
            dayRulesIndex = 0
          } else {
            dayRulesIndex++
          }

          // Break if rule's date is after end date of given range
          if (date.isAfter(endDate, 'day')) {
            break
          }
        }
      }
    }
    // Convert patches to schedule items
    if (patches) {
      for (const patch of patches) {
        const diffInDays = getDifferenceInDays(patch.start_date, patch.end_date)

        // Checking for <= because same day will be 0
        for (let i = 0; i <= diffInDays; i++) {
          const date = dayjs(patch.start_date).add(i, 'day')

          if (date.isSame(scheduleItemDate, 'day')) {
            schedules[date.format('YYYY-MM-DD')] = {
              type: patch.data.type as ScheduleType,
              data: patch
            }
          }

          // Break if patch's date is after end date of given range
          if (date.isAfter(endDate, 'day')) {
            break
          }
        }
      }
    }

    // Convert events to schedule items
    if (events) {
      for (const event of events) {
        const diffInDays = getDifferenceInDays(event.start_datetime, event.end_datetime)

        // Checking for <= because same day will be 0
        for (let i = 0; i <= diffInDays; i++) {
          const date = dayjs(event.start_datetime).add(i, 'day')

          if (date.isSame(scheduleItemDate, 'day')) {
            const schedule = schedules[date.format('YYYY-MM-DD')]

            if (schedule && schedule.type === 'J' && Array.isArray(schedule.data)) {
              schedule.data.push(event)
            } else {
              schedules[date.format('YYYY-MM-DD')] = {
                type: 'J',
                data: [event]
              }
            }
          }

          // Break if event's date is after end date of given range
          if (date.isAfter(endDate, 'day')) {
            break
          }
        }
      }
    }
  }

  return schedules
}

const getDifferenceInDays = (start: string, end: string) => {
  return dayjs(end).endOf('day').diff(dayjs(start).startOf('day'), 'day')
}
