import { useApp, useSession } from '@/hooks'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { ticketApi } from '@/services/api-service'
import { Modal, TipButton, TipButtonProps } from '@/ui'
import { printBlob, saveBlob } from '@/utils/blob'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form } from 'antd'
import { FC, useCallback, useMemo, useState } from 'react'
import { useCustomForm, usePrintRestrict, useTicket, useTicketFlags } from '../../../hooks'

type Props = {
  type?: 'print' | 'download'
  mode?: 'modal' | 'direct'
  options?: any
  buttonProps?: TipButtonProps
}

export const PrintButton: FC<Props> = (props) => {
  const { ticket } = useTicket()

  if (!ticket?.id) return null

  return <PrintButtonInner {...props} />
}

export const PrintButtonInner: FC<Props> = ({ type = 'print', mode = 'modal', options = {}, buttonProps }) => {
  const forModal = mode === 'modal'
  const { notification } = useApp()
  const [isOpen, setIsOpen] = useState(false)
  const [form] = Form.useForm()

  const { restrictPrint, restrictMessage } = usePrintRestrict()

  const title = type === 'download' ? 'Download PDF' : 'Print Ticket'
  const iconName = type === 'download' ? 'fa:cloud-download' : 'fa:print'

  const isSuperAdmin = useIsSuperAdmin()
  const { company } = useSession()
  const { ticket } = useTicket()
  const { customForm, features } = useCustomForm()
  const { hideAttachments } = useTicketFlags()

  const printMutation = useMutation(ticketApi.print(ticket.id))

  const showInternal = isSuperAdmin || company.id === customForm.primary_company_id

  const printOptions = useMemo(
    () => ({ ...((features?.printOptions || {}).default_options || {}), ...options }),
    [features?.printOptions, options]
  )

  const initialValues = useMemo(
    () => ({
      stylesheets: printOptions.stylesheets ?? ['default'],
      attachments: printOptions.attachments ?? false,
      show_internal_use_only: printOptions.show_internal_use_only ?? false,
      line_item_attachments: printOptions.line_item_attachments ?? false
    }),
    [printOptions]
  )

  const printStyles = useMemo(
    () => [
      { label: 'Default', value: 'default' },
      ...customForm.print_styles.map((s) => ({ label: s.name, value: s.name }))
    ],
    [customForm.print_styles]
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSubmit = useCallback(async () => {
    const values = forModal ? form.getFieldsValue() : initialValues

    if (!values.stylesheets.length) {
      notification.error({
        message: 'Print Error',
        description: 'Please select at least one stylesheet to print'
      })
      return
    }

    const payload: any = { format: 'pdf', stylesheets: values.stylesheets }

    // only send keys with true values
    if (values.attachments) payload.attachments = 1
    if (values.show_internal_use_only) payload.show_internal_use_only = 1
    if (values.line_item_attachments) payload.line_item_attachments = 1

    try {
      const { blob: pdfBlob, fileName } = await printMutation.mutateAsync(payload)

      if (type === 'download') {
        await saveBlob(pdfBlob, fileName || `ticket-${ticket.name}.pdf`)
      } else {
        await printBlob(pdfBlob)
      }
      handleClose()
    } catch (e: any) {
      notification.error({ message: 'Print Error', description: e.toString() })
    }
  }, [forModal, form, handleClose, initialValues, notification, printMutation, ticket.name, type])

  const handleClick = useCallback(async () => {
    if (forModal) setIsOpen(true)
    else await handleSubmit()
  }, [forModal, handleSubmit])

  return (
    <>
      <TipButton
        loading={!forModal && printMutation.isLoading}
        disabled={restrictPrint}
        onClick={handleClick}
        color={restrictPrint ? 'red' : undefined}
        type={'primary'}
        iconName={iconName}
        title={title + (restrictPrint ? ` - ${restrictMessage}` : '')}
        {...buttonProps}
      />
      <Modal
        width={400}
        iconName={iconName}
        title={title}
        open={isOpen}
        onCancel={handleClose}
        okButtonProps={{ loading: printMutation.isLoading }}
        onOk={handleSubmit}
        okText={type === 'download' ? 'Download' : 'Print'}
        cancelText="Close"
      >
        <Form form={form} className={'flex flex-col gap-10'} initialValues={initialValues}>
          <div>
            <p>Select styles to print:</p>
            <div className="flex flex-col">
              <Form.Item name={'stylesheets'}>
                <Checkbox.Group rootClassName={'flex flex-col gap-20'} options={printStyles} />
              </Form.Item>
            </div>
          </div>

          <div>
            <p>Select which bottom sections you’d like to include:</p>
            <div className="flex flex-col [&>div]:mb-6 px-10">
              {!hideAttachments && (
                <Form.Item name={'attachments'} valuePropName={'checked'}>
                  <Checkbox>Attachments</Checkbox>
                </Form.Item>
              )}
              {showInternal && (
                <Form.Item name={'show_internal_use_only'} valuePropName={'checked'}>
                  <Checkbox>Include Internal Use Only Sections</Checkbox>
                </Form.Item>
              )}
              {!hideAttachments && (
                <Form.Item name={'line_item_attachments'} valuePropName={'checked'}>
                  <Checkbox>Line item attachments</Checkbox>
                </Form.Item>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}
