import { UniqueIdentifier, useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Input } from 'antd'
import { useState } from 'react'
import { DraggableItem } from '../../../types'
import { Item } from '../item'
import { SortableItem } from '../sortable-item'

const CATEGORY_DISPLAYNAME = {
  lineItem: 'Line item',
  parentLineItem: 'Parent item'
}

type ColumnKey = 'available' | 'selected'

type Props = {
  id: ColumnKey
  title: React.ReactNode
  items: DraggableItem[]
  extra?: React.ReactNode
  onSearch?: (value: string) => void
  onFieldNameChange?: (id: UniqueIdentifier, value: string) => void
}

export const DroppableColumn = ({ id, items, title, extra, onSearch, onFieldNameChange }: Props) => {
  const [searchText, setSearchText] = useState('')
  const { setNodeRef } = useDroppable({ id })

  const handleSearch = (value: string) => {
    setSearchText(value)
    onSearch?.(value)
  }

  return (
    <div>
      <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
        {title}
        {extra}
        <div className="border border-border rounded p-12 mt-12 h-[calc(100vh-220px)] overflow-auto">
          <Input
            placeholder="Search"
            className="mb-16"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div ref={setNodeRef}>
            {items.map((item: any, index) => {
              return (
                <div key={item.id} className="mb-8 last-of-type:mb-0">
                  {id === 'available' && item.category !== items[index - 1 || 0]?.category && (
                    <h5 className="capitalize font-bold my-16">
                      {CATEGORY_DISPLAYNAME[item.category as keyof typeof CATEGORY_DISPLAYNAME] ||
                        item.category ||
                        'uncategorized'}
                    </h5>
                  )}
                  <SortableItem id={item.id}>
                    <Item
                      item={item}
                      isDragging={false}
                      editable={id === 'selected'}
                      onFieldNameChange={onFieldNameChange}
                    />
                  </SortableItem>
                </div>
              )
            })}
          </div>
        </div>
      </SortableContext>
    </div>
  )
}
