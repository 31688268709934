import { DownloadOutlined } from '@ant-design/icons'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Image, Popconfirm, Spin, Tag } from 'antd'
import dayjs from 'dayjs'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { API_URL } from '@/constants/app'
import { useApp, useIsMobile, useModalState } from '@/hooks'
import { ticketApiV2 } from '@/services/api-service'
import { Button } from '@/ui'
import { cn, mediaUrl } from '@/utils'
import { saveBlob } from '@/utils/blob'
import { PT } from '../../custom-form/constants'
import { InputRendererMap } from '../../ticket/form/components/properties/property-form-item/helpers'
import { transformProperty } from '../../ticket/form/helpers'
import { SignTicketModal } from './SignTicketModal'
import { SecureAccessWaitListView } from './WaitList'
import { SECURE_ACCESS_STATUS, SecureTicketAccess, SecureTicketAccessSchema } from './schemas'

const StatusDisplayMap: { [key: string]: string } = {
  null: 'Unsigned',
  D: 'Disputed',
  S: 'Signed'
}

const StatusColorMap: { [key: string]: string } = {
  null: 'default',
  D: 'warning',
  S: 'success'
}

type TP = {
  property: number
  value: string | null | undefined
}
const DISABLED_PROPERTY_TYPES = [
  PT.Attachment,
  PT.ScriptedButton,
  PT.Script,
  PT.Image,
  PT.DisplayImage,
  PT.Table,
  PT.Signature,
  PT.Spacer,
  PT.RecordSelect,
  PT.Label
]

type Parsers = {
  [key: number]: (value: string | null | undefined) => any
}
type Dumpers = {
  [key: number]: (value: any) => string | null
}

const PROPERTY_PARSERS: Parsers = {
  [PT.MultivariateDropdown]: (value) => (value && value.split(',')) || []
}

const PROPERTY_DUMPERS: Dumpers = {
  [PT.MultivariateDropdown]: (value: string[]): any => value.join(',')
}

export const SecureTicketAccessView = () => {
  const isMobile = useIsMobile()
  const { notification } = useApp()
  const [form] = Form.useForm()
  const { ticketId } = useParams<{ ticketId: string }>()
  const [showDisputeTicketConfirm, setShowDisputeTicketConfirm] = useState<boolean>(false)
  const [waitListVisible, setWaitListVisible] = useState(true)
  const signTicketModalState = useModalState()
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string>('')

  const signTicketMutation = useMutation(ticketApiV2.signTicket(Number(ticketId)))
  const disputeTicketMutation = useMutation(ticketApiV2.dispute(Number(ticketId)))
  const secureTicketAccessFetch = useQuery(
    ticketApiV2.secureDetail<SecureTicketAccess>(Number(ticketId) || null, { fields: SecureTicketAccessSchema })
  )

  const secureTicket = secureTicketAccessFetch.data as SecureTicketAccess
  const secureAccess = secureTicket?.secure_access

  const editableProperties = useMemo(
    () =>
      (secureTicket?.custom_form_properties || [])
        .filter((prop) => prop.editable_on_secure_access && !DISABLED_PROPERTY_TYPES.includes(prop.property_type_id))
        .map((prop) => transformProperty(prop)),
    [secureTicket?.custom_form_properties]
  )

  const parsePropertyValue = (tp: TP) => {
    const prop = editableProperties.find((prop) => prop.id === tp.property)
    if (!prop || !PROPERTY_PARSERS[prop.property_type_id]) return tp.value

    return PROPERTY_PARSERS[prop.property_type_id](tp.value)
  }

  const dumpPropertyValue = (tp: TP) => {
    const prop = editableProperties.find((prop) => prop.id === tp.property)
    if (!prop || !PROPERTY_PARSERS[prop.property_type_id]) return tp.value

    return PROPERTY_DUMPERS[prop.property_type_id](tp.value)
  }

  const initialPropertyValues: TP[] = useMemo(
    () =>
      (editableProperties || [])
        .map((prop) => ({
          ...((secureTicket?.properties || []).find((tp) => tp.property_id === prop.id) || {
            property_id: prop.id,
            value: null
          })
        }))
        .map((tp) => ({ property: tp?.property_id as number, value: tp?.value }))
        .filter((tp) => tp.property)
        .map((tp) => ({ ...tp, value: parsePropertyValue(tp) })),
    [editableProperties]
  )

  const publicPrintUrl = `${API_URL}/tickets/${ticketId}/public_print?signature=${secureAccess?.print_signature}`

  useEffect(() => {
    if (secureAccess?.print_signature) {
      fetch(publicPrintUrl)
        .then((response) => response.blob())
        .then((blob) => setPdfBlobUrl(window.URL.createObjectURL(blob)))
    }
  }, [secureAccess?.print_signature])

  if (secureTicketAccessFetch.isLoading || !secureTicketAccessFetch.data) {
    return (
      <div className="flex justify-center">
        <Spin />
      </div>
    )
  }

  const company = secureTicket.company
  const initialValues = {
    ticket_properties: initialPropertyValues,
    ticket_id: secureTicket.id,
    contact_id: secureAccess.contact__id,
    property_id: secureAccess.property__id
  }

  const signTicket = async (signature: { [k: string]: any }) => {
    const formData = form.getFieldsValue()
    formData['ticket_properties'] = formData['ticket_properties']
      .map((tp: TP) => ({ ...tp, value: dumpPropertyValue(tp) }))
      .filter((tp: TP) => tp.value)

    await signTicketMutation.mutateAsync({ ...signature, ...formData })
    secureTicketAccessFetch.refetch()
    setWaitListVisible(true)
    setPdfBlobUrl('')
  }

  const disputeTicket = async () => {
    const formData = form.getFieldsValue()
    formData['ticket_properties'] = formData['ticket_properties']
      .map((tp: TP) => ({ ...tp, value: dumpPropertyValue(tp) }))
      .filter((tp: TP) => tp.value)

    try {
      await disputeTicketMutation.mutateAsync({ ...formData, id: ticketId })
      notification.success({ message: 'Ticket is disputed' })
    } catch (e) {
      notification.error({ message: 'Failed to dispute ticket: ${e}' })
    }
    setShowDisputeTicketConfirm(false)
    setWaitListVisible(true)
    secureTicketAccessFetch.refetch()
    setPdfBlobUrl('')
  }

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      <Form.Item name="ticket_id" hidden />
      <Form.Item name="contact_id" hidden />
      <Form.Item name="property_id" hidden />
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className="m-4 mb-10 relative">
          <div className="flex justify-center">
            <div className={cn('md:w-[786px] border p-18 border-border rounded shadow-sm text-center bg-white')}>
              {/* // header */}
              <div className={cn('flex items-center justify-between mb-12 gap-8 flex-col md:flex-row')}>
                <div>
                  <Image className="max-h-[54px]" src={mediaUrl(company.icon)} alt={company.name} />
                </div>
                <div className="flex flex-col items-center mb-12">
                  <span>Secure View for: {secureTicket.customer_office__name}</span>
                  <span>
                    Email: <a href={`mailto: ${secureAccess.email}`}>{secureAccess.email}</a>
                  </span>
                </div>
                <div>
                  <Tag bordered={false} color={StatusColorMap[secureAccess.status || 'null']}>
                    {StatusDisplayMap[secureAccess.status || 'null']}
                  </Tag>
                </div>
              </div>

              <SecureAccessWaitListView
                secureAccess={secureAccess}
                visible={waitListVisible}
                setVisible={setWaitListVisible}
              />

              {/* Alerts and actions */}
              {secureAccess.status === SECURE_ACCESS_STATUS.DISPUTED && (
                <div className="border border-red-500 rounded bg-red-100 mb-12 p-8 flex flex-col md:flex-row justify-between items-center">
                  <div className="text-left min-w-[200px]">
                    <span>Disputed at</span>
                    <br />
                    <b>{dayjs.formatLocal(secureAccess.data?.timestamp)}</b>
                  </div>
                  <div className="text-center">
                    <span>Disputed By</span>
                    <br />
                    <b>{secureAccess.data?.action_by}</b>
                  </div>
                  <div className="flex flex-row-reverse min-w-[200px]">
                    <Button
                      danger
                      size="small"
                      onClick={signTicketModalState.openModal}
                      onFocus={signTicketModalState.renderModal}
                      onMouseEnter={signTicketModalState.renderModal}
                    >
                      Sign Ticket
                    </Button>
                  </div>
                </div>
              )}
              {secureAccess.status === SECURE_ACCESS_STATUS.SIGNED && (
                <div className="border border-green-500 rounded bg-green-100 mb-12 p-8 flex flex-col md:flex-row justify-between items-center">
                  <div className="text-center md:text-left min-w-[200px]">
                    <span>Signed at</span>
                    <br />
                    <b>{dayjs.formatLocal(secureAccess.data?.timestamp)}</b>
                  </div>
                  <div className="text-center">
                    <span>Signed By</span>
                    <br />
                    <b>{secureAccess.data?.action_by}</b>
                  </div>
                  <div className="min-w-[200px]"></div>
                </div>
              )}
              {/* // ticket details */}
              <div className="w-full bg-gray-100 p-12 rounded mb-12">
                <div className="flex flex-row justify-between items-center mb-8">
                  <div>
                    <b className="font-bold">
                      {secureTicket.custom_form__name}: #{secureTicket.name}
                    </b>
                  </div>
                  <Button
                    disabled={secureAccess.status !== 'S' || !pdfBlobUrl}
                    onClick={() => saveBlob(pdfBlobUrl, `${secureTicket.custom_form__name}_${secureTicket.name}`)}
                    loading={!pdfBlobUrl}
                    type="primary"
                    icon={<DownloadOutlined />}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="text-left">
                    <b>{secureTicket.office__name}</b>
                    <br />
                    <div>{secureTicket.office__address__line1}</div>
                    <div>
                      {secureTicket.office__address__city}, {secureTicket.office__address__state}{' '}
                      {secureTicket.office__address__zipcode}
                    </div>
                    <div>{secureTicket.office__phone_number}</div>
                  </div>
                  <div className="flex flex-col items-end">
                    <b className="font-bold">Total</b>
                    <span>${secureTicket.total}</span>
                  </div>
                </div>
              </div>

              {/* PDF Ticket */}
              <div className="min-h-[500px] mb-16">{!pdfBlobUrl ? <Spin /> : <Viewer fileUrl={pdfBlobUrl} />}</div>

              {/* Ticket Properties */}
              <Form.List name="ticket_properties">
                {(fields, _ops) => (
                  <div className="flex flex-row flex-wrap gap-5 text-left">
                    {fields.map((field) => {
                      const prop = editableProperties.find((p) => p.id === initialPropertyValues[field.name]?.property)
                      if (!prop) return null

                      return (
                        <Fragment key={field.name}>
                          <Form.Item name={[field.name, 'property']} hidden />
                          <Form.Item
                            name={[field.name, 'value']}
                            className="py-2 px-4"
                            required={prop.required}
                            label={prop.name}
                            // ${p._width + 10}%, Because it's much smaller space on this layout
                            style={{ width: isMobile ? '100%' : `${prop._width + 10}%` }}
                          >
                            {InputRendererMap[prop.property_type_id](prop, (p) => false)}
                          </Form.Item>
                        </Fragment>
                      )
                    })}
                  </div>
                )}
              </Form.List>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="flex flex-col md:flex-row mb-[48px] py-16 px-20 items-center justify-between">
          <div>&copy; {new Date().getFullYear()} Oil Command. All rights reserved.</div>
          <div>
            <a className="mr-16" href="https://oilcommand.com/term-conditions/">
              Terms & Conditions
            </a>
            <a href="https://oilcommand.com/privacy-policy/">Privacy & Policy</a>
          </div>
        </footer>
        <footer className="fixed bottom-0 w-full">
          <div className="mx-auto border border-border bg-white">
            <div className="h-[48px] mx-auto p-8 flex flex-row items-center justify-center gap-10">
              <Popconfirm
                title="Confirm"
                description="Are you sure you want to dispute this ticket?"
                open={showDisputeTicketConfirm}
                onConfirm={disputeTicket}
                okButtonProps={{ loading: disputeTicketMutation.isLoading }}
                onCancel={() => setShowDisputeTicketConfirm(false)}
              >
                <Button danger disabled={!!secureAccess.status} onClick={() => setShowDisputeTicketConfirm(true)}>
                  Dispute
                </Button>
              </Popconfirm>
              <SignTicketModal secureTicket={secureTicket} onSubmit={signTicket} modalState={signTicketModalState} />
            </div>
          </div>
        </footer>
      </Worker>
    </Form>
  )
}
