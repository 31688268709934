import { APP_URL_V1 } from '@/constants/app'
import { AppVersion } from '@/types/general'
import { To } from 'react-router-dom'

export type NavigateOptions = {
  v?: AppVersion
  reload?: boolean
}

/**
 * Update or append version to an url.
 * @param url
 * @param v
 */
const _updateOrAppendVersion = (url: string, v: AppVersion) => {
  const [baseUrl, query] = url.split('?')
  if (query && query.length) {
    const params = new URLSearchParams(query)
    params.set('_v', String(v))
    return `${baseUrl}?${params.toString()}`
  }
  return `${url}?_v=${v}`
}

/**
 * Attach version to an url.
 * If v=1, attach _v=1 to the query string.
 * Otherwise, attach _v=2 to the query string.
 * @param to
 * @param v
 */
export const attachVersion = (to: To, v: AppVersion): string => {
  if (typeof to === 'string') {
    return _updateOrAppendVersion(to, v)
  }

  const { pathname = '', search = '', hash = '' } = to
  return `${pathname}${_updateOrAppendVersion(search, v)}${hash}`
}

/**
 * Get the link to a route.
 * If v=1, get the link to the old app. Otherwise, get the link to the current app.
 * @param to
 * @param options
 */
export const url = (to: To, options?: NavigateOptions) => {
  const version = options?.v ?? 2
  if (version === 1) {
    to = `${APP_URL_V1}${to}`
  }
  return attachVersion(to, version)
}

/**
 * Navigate to a route.
 * If v=1, navigate to the old app. Otherwise, navigate to the current app.
 * @param to
 * @param options
 */
export const navigate = (to: To, options?: NavigateOptions) => {
  to = url(to, options)

  if (options?.v === 1) location.href = to
  else {
    if (options?.reload) location.href = to
    else {
      // hack: using appRouter here causes circular dependency
      // instead dispatch an event and listener
      dispatchEvent(new CustomEvent('app-navigate', { detail: { to } }))
    }
  }
}
