import { AppMode } from '@/types/general'
import { createStore } from 'jotai'

export const APP_MODE: AppMode = import.meta.env.MODE as AppMode
export const APP_DEV = import.meta.env.DEV
export const APP_PROD = import.meta.env.PROD

export const APP_DEBUG = import.meta.env.VITE_APP_DEBUG === 'true'

export const APP_VERSION = import.meta.env.VITE_APP_VERSION
export const APP_BUILD = import.meta.env.VITE_APP_BUILD
export const APP_RELEASE = import.meta.env.VITE_APP_RELEASE
export const CI_BUILD = import.meta.env.VITE_CI_BUILD

export const APP_SITE = window.site.getCurrentSite()
export const APP_URL = APP_SITE.appUrl
export const APP_URL_V1 = APP_SITE.appUrlV1 || APP_SITE.appUrl
export const API_URL = APP_SITE.apiUrl

export const ENABLE_REDIRECT = import.meta.env.VITE_ENABLE_REDIRECT === 'true'

export const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS

export const LOG_ROCKET_APP_ID = import.meta.env.VITE_LOG_ROCKET_APP_ID

export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export const JOTAI_GLOBAL_STORE = createStore()
