import { useApp } from '@/hooks'
import { lineItemApiV2, ticketApi } from '@/services/api-service'
import { Icon } from '@/ui'
import { makeMap, safeJsonParse } from '@/utils'
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Skeleton, Tag } from 'antd'
import { cloneDeep, groupBy, sortBy } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useCustomForm, useTicket } from '../../../../../hooks'
import { useCurrentKind, useItemsStats } from '../../../hooks'
import { Category, LineItem } from '../../../schemas'
import { ItemsTable } from '../items-table'
import { InlineEditButtons } from '../items-table/table-foot/inline-buttons'

type Props = {
  category: Category
  title?: string
  queryFilters?: Record<string, any>
  noCategoryFilter?: boolean
  showCount?: boolean
}

export const ItemsCategory: FC<Props> = ({
  category,
  title,
  queryFilters: categoryFilters,
  noCategoryFilter,
  showCount
}) => {
  const { ticket } = useTicket()
  const { notification } = useApp()
  const { lastSequenceNo } = useItemsStats(ticket.id)
  const { features } = useCustomForm()

  const currentKind = useCurrentKind({
    type: 'table',
    kind: category._kind,
    category: {
      kindId: category.kind,
      parentKindId: category.parent__kind
    },
    options: {
      // hide: since notes and unit are rendered separately in table
      hiddenFields: ['notes', 'unit', ...((features.hideItemFields?.enabled && features.hideItemFields?.fields) || [])]
    }
  })

  const { data, isLoading } = useQuery({
    ...lineItemApiV2.list<Partial<LineItem>>({
      ...currentKind.fieldsQueryParam,
      ...(categoryFilters || {}),
      ticket_id__eq: ticket.id,
      inc_archived: 1,
      no_count: '1',
      limit: 1000
    }),
    retry: 1
  })

  const [localItems, setLocalItems] = useState(data?.items || [])
  useEffect(() => {
    if (data?.items) setLocalItems(data.items)
  }, [data?.items])

  const groupedItems: Record<number, Partial<LineItem>[]> = useMemo(() => {
    if (!localItems) return {}
    const items = localItems.map((item) => ({
      ...item,
      _has_notes: !!item.notes && item.notes.length > 0,
      _data: safeJsonParse(item.data_json)
    }))
    return groupBy(sortBy(items, 'sequence'), 'group')
  }, [localItems])

  const hasGroups = Object.keys(groupedItems).length > 1

  const reorderMutation = useMutation(ticketApi.reorderLines())

  const handleDragEnd = async (event?: DragEndEvent) => {
    const { active, over } = event || {}
    if (!(active && over && active.id !== over.id)) return

    const localItemsMap = makeMap(localItems, 'id')

    const updatedItems = (() => {
      let items = sortBy(cloneDeep(localItems), 'sequence')

      const activeIndex = items.findIndex((item) => item.id === active.id)
      const overIndex = items.findIndex((item) => item.id === over.id)

      // adjust sequence
      let nextSequenceNo = lastSequenceNo + 1
      const sequences = items.map((item) => item.sequence || nextSequenceNo++)
      items = arrayMove(items, activeIndex, overIndex).map((item, index) => ({
        ...item,
        sequence: sequences[index]
      }))

      // adjust group no and subtotal_after
      const newActiveIndex = items.findIndex((item) => item.id === active.id)
      const newOverIndex = items.findIndex((item) => item.id === over.id)
      const activeItem = items[newActiveIndex]
      const overItem = items[newOverIndex]
      const overGroup = overItem.group ?? null
      const activeGroup = activeItem.group ?? null
      activeItem.group = overItem.group // adjust group

      const refreshSubtotalAfter = (groupNo: number | null) => {
        if (!groupNo) return

        const hasSubtotalAfter = localItems.findIndex((item) => item.group === groupNo && item.subtotal_after) !== -1
        const groupItems = items.filter((item) => item.group === groupNo)
        groupItems.forEach((item, index) => {
          if (hasSubtotalAfter) item.subtotal_after = index === groupItems.length - 1
          else item.subtotal_after = false
        }) // adjust subtotal_after
      }

      refreshSubtotalAfter(activeGroup)
      if (activeGroup !== overGroup) refreshSubtotalAfter(overGroup)

      return items
    })()

    // detect changed and mark sorting
    updatedItems.forEach((item) => {
      const oldItem = localItemsMap[item.id?.toString() || '']
      if (
        oldItem.sequence !== item.sequence ||
        oldItem.group !== item.group ||
        oldItem.subtotal_after !== item.subtotal_after
      ) {
        item._sorting = true
      }
    })

    setLocalItems(updatedItems)

    const sortedItems = updatedItems
      .filter((item) => item._sorting)
      .map((item) => ({
        id: item.id,
        group: item.group,
        sequence: item.sequence,
        subtotal_after: item.subtotal_after
      }))

    if (sortedItems.length) {
      await reorderMutation.mutateAsync({
        id: ticket.id,
        line_items: sortedItems
      })

      notification.success({
        message: 'Line Items Reordered',
        description: 'Line items reordered successfully'
      })
    }
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={hasGroups ? [restrictToVerticalAxis] : [restrictToVerticalAxis, restrictToParentElement]}
    >
      <div className={'mb-20'}>
        <div className={'py-6 flex flex-row justify-between items-center'}>
          {title && (
            <div className={'flex flex-row items-center gap-10'}>
              <h5 className={'m-0 px-6'}>{title}</h5>
              {showCount && <Tag className={'rounded-full !text-xs'}>{localItems?.length}</Tag>}
            </div>
          )}
          <div className={'flex flex-row gap-10 items-center'}>
            <InlineEditButtons category={category} currentKind={currentKind} localItems={localItems} />
          </div>
        </div>

        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className={'flex flex-col gap-10'}>
            {currentKind ? (
              Object.entries(groupedItems).map(([groupNo, groupItems]) => (
                <SortableContext
                  key={groupNo}
                  items={groupItems.map((r) => r.id as number)}
                  strategy={verticalListSortingStrategy}
                >
                  <ItemsTable
                    category={category}
                    kind={currentKind}
                    groupNo={isNaN(Number(groupNo)) ? null : Number(groupNo)}
                    groupItems={groupItems}
                    noCategoryFilter={noCategoryFilter}
                  />
                </SortableContext>
              ))
            ) : (
              <Alert
                showIcon
                icon={<Icon name={'fa:warning'} />}
                type={'warning'}
                message={'Kind not configured for this category'}
              />
            )}
          </div>
        )}
      </div>
    </DndContext>
  )
}
