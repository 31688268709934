import { Checkbox, Form } from 'antd'
import { FC } from 'react'

export const TicketViewFlags: FC = () => {
  return (
    <div className={'flex flex-row gap-10'}>
      <Form.Item label={' '} name={['data', 'show_status_filter']} valuePropName="checked">
        <Checkbox>Show Vertical Status Filter</Checkbox>
      </Form.Item>
      <Form.Item label={' '} name={['data', 'show_views_vertically']} valuePropName="checked">
        <Checkbox>Show Table Views Vertically</Checkbox>
      </Form.Item>
    </div>
  )
}
