import { useApp } from '@/hooks'
import { view } from '@/routing'
import { navigate } from '@/routing/helpers'
import { authApi } from '@/services/api-service'
import { BackButton, Button } from '@/ui/button'
import { MailOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { flatten } from 'lodash'
import { useSearchParams } from 'react-router-dom'

export const ForgotPasswordView = view(Component, { title: () => 'Forgot Password' })

function Component() {
  const { notification } = useApp()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('to') || '/v2'
  const { mutate: forgotPassword, isLoading } = useMutation(authApi.forgotPassword())
  let navigateTimer: any
  const onFinish = (values: any) => {
    clearTimeout(navigateTimer)
    forgotPassword(values, {
      onError: (error: any) => {
        const errors = flatten(Object.values(error?.response?.data || {}) || []) as string[]
        notification.error({
          message: 'Reset Failed',
          description: errors.map((e, idx) => <p key={`${idx}-${e}`}>{e}</p>)
        })
      },
      onSuccess: ({ detail }) => {
        notification.success({ message: 'Success', description: detail })
        navigateTimer = setTimeout(() => {
          navigate(redirectTo, { reload: true })
        }, 4000)
      }
    })
  }
  return (
    <div>
      <div className={'flex flex-row items-center gap-10'}>
        <BackButton to={'/auth/login'} />
        <div className="font-bold text-xl">Forgot Password</div>
      </div>

      <div className={'py-10'}>Enter your email to continue.</div>

      <Form
        name="normal_login"
        className="p-0 "
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          className="mb-28"
          name="email"
          label="Email Address"
          rules={[
            { required: true, message: 'Please enter your email address' },
            { type: 'email', message: 'Please enter a valid email address' }
          ]}
        >
          <Input
            autoFocus
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email Address"
            data-cy="forgot-password-email-input"
          />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            disabled={isLoading}
            loading={isLoading}
            data-cy="submit-reset-password"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
