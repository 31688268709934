import { SIGNATURE_STYLES } from '@/constants/general'
import { useApp } from '@/hooks'
import { cn } from '@/utils'
import { ClearOutlined } from '@ant-design/icons'
import { Button, Checkbox, ColorPicker, Form, Input, Modal, Select, Tabs } from 'antd'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { ReactSketchCanvas, ReactSketchCanvasRef } from 'react-sketch-canvas'
import { getSignatureStyleCSS } from '../../ticket/form/components/properties/property-form-item/signature/helpers'
import { ImageSignatureUpload } from './ImageSignatureUpload'
import { SecureTicketAccess } from './schemas'

type SignTicketModalProps = {
  secureTicket: SecureTicketAccess
  onSubmit: (values: FormData) => Promise<void>
  modalState: {
    isRender: boolean
    renderModal: () => void

    isOpen: boolean
    openModal: () => void
    closeModal: () => void
    toggleModal: (val: boolean) => void
  }
}

export const SignTicketModal = ({ secureTicket, onSubmit, modalState }: SignTicketModalProps) => {
  const app = useApp()
  const [form] = Form.useForm()
  const canvasRef = useRef<ReactSketchCanvasRef>(null)
  const iName = Form.useWatch('name', { form })
  const iStyle = Form.useWatch('style', { form })
  const iColor = Form.useWatch('color', { form })
  const iConsent = Form.useWatch('consent', { form })

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [signatureType, setSignatureType] = useState<string>('Text')

  const onOK = async () => {
    setInProgress(true)

    try {
      const formData = await form.validateFields()
      if (signatureType === 'Text') formData.image_data = undefined

      if (signatureType === 'Draw' && canvasRef.current)
        formData.image_data = await canvasRef.current.exportImage('png')

      await onSubmit(formData)
      modalState.closeModal()
      app.notification.success({ message: 'Ticket has been successfully signed' })
    } catch (e) {
      app.notification.error({ message: `Failed to sign ticket: ${e}` })
    }

    setInProgress(false)
  }

  const initialValues = {
    name: secureTicket.secure_access.name,
    email: secureTicket.secure_access.email,
    style: 'L',
    color: '#494949',
    ticket_id: secureTicket.id
  }

  const tabs = [
    {
      key: 'Text',
      label: 'Text',
      children: (
        <>
          <div className="flex flex-row gap-10">
            <Form.Item name="style" label="Signature Style" className="w-full">
              <Select
                options={Object.entries(SIGNATURE_STYLES).map(([k, v]) => ({
                  value: k,
                  label: <span className={getSignatureStyleCSS(k)}>{v}</span>
                }))}
              />
            </Form.Item>

            <Form.Item name={'color'} label={'Signature Color'} className={'w-full'}>
              <ColorPicker
                className={'w-full justify-start'}
                showText
                format="hex"
                onChange={(val) => form.setFieldValue('color', '#' + val.toHex())}
              />
            </Form.Item>
          </div>
          <div className="relative border rounded p-8">
            <div
              className={cn(
                'h-[128px] flex flex-col items-center justify-center break-all',
                'tracking-widest leading-[20px] text-[32px] overflow-hidden',
                getSignatureStyleCSS(iStyle || 'L')
              )}
              style={{ color: iColor || '#494949' }}
            >
              {iName}
            </div>
            <div className="absolute text-sm bg-gray-100 border rounded-b bottom-0 left-0 w-full p-4 flex flex-row justify-between items-center">
              <div>{dayjs.formatLocal(dayjs.now())}</div>
            </div>
          </div>
        </>
      )
    },
    {
      key: 'Draw',
      label: 'Draw',
      children: (
        <>
          <Form.Item name={'color'} label={'Signature Color'} className={'w-full'}>
            <ColorPicker
              className={'w-full justify-start'}
              showText
              format="hex"
              onChange={(val) => form.setFieldValue('color', '#' + val.toHex())}
            />
          </Form.Item>
          <Form.Item>
            <div className="relative border rounded p-2">
              <ReactSketchCanvas style={{}} ref={canvasRef} height="150px" strokeWidth={3} strokeColor={iColor} />
              <div className="absolute text-sm bg-gray-100 border rounded-b bottom-0 left-0 w-full p-4 flex flex-row justify-between items-center">
                <div>{dayjs.formatLocal(dayjs.now())}</div>
                <Button
                  color="primary"
                  type="link"
                  className="absolute bottom-0 right-0"
                  size="small"
                  onClick={() => canvasRef.current?.clearCanvas()}
                  icon={<ClearOutlined />}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Form.Item>
        </>
      )
    },
    {
      key: 'Image',
      label: 'Image',
      children: (
        <Form.Item label="Upload Signature">
          <ImageSignatureUpload form={form} />
        </Form.Item>
      )
    }
  ]

  return (
    <>
      <Button
        type="primary"
        onClick={modalState.openModal}
        disabled={!!secureTicket.secure_access.status}
        onFocus={modalState.renderModal}
        onMouseEnter={modalState.renderModal}
      >
        Sign Ticket
      </Button>
      {modalState.isRender && (
        <Modal
          centered
          open={modalState.isOpen}
          onCancel={modalState.closeModal}
          onOk={onOK}
          cancelButtonProps={{ disabled: inProgress }}
          okButtonProps={{ loading: inProgress, disabled: !iConsent }}
          title="Add Signature"
          okText="Sign"
        >
          <Form form={form} initialValues={initialValues} layout="vertical">
            <Form.Item name="image_data" hidden />
            <div className="flex flex-col md:flex-row items-center gap-10 mb-16">
              <Form.Item label="Your name" name="name" required={true} className="w-full">
                <Input />
              </Form.Item>
              <Form.Item label="Your email" name="email" className="w-full">
                <Input />
              </Form.Item>
            </div>
            <Tabs defaultActiveKey="Text" items={tabs} onChange={(t: string) => setSignatureType(t)} />
            <div className={'mt-24'}>
              <Form.Item
                name={'consent'}
                label={'Consent'}
                rules={[
                  {
                    validator: (_, v) => (v === true ? Promise.resolve() : Promise.reject()),
                    message: 'You must give consent before signing.'
                  }
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  <div className={'text-justify text-sm'}>
                    By clicking <b>"Sign"</b>, I agree that the signature and initial will be the electronic
                    representation of my signature for all purposes when I use them on the document number:
                    <b> #{secureTicket.name}</b>. This action is the same as a pen-and-paper signature.
                  </div>
                </Checkbox>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      )}
    </>
  )
}
