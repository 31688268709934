import { getUrlState, useSetUrlState, useUrlStateRefresh } from '@/hooks'
import { Form, FormItemProps, FormProps } from 'antd'
import cn from 'classnames'
import { get, set } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export type UrlStateFormItemProps = FormItemProps & { formProps?: FormProps; debounce?: number; defaultValue?: any }

/**
 * Form item that syncs with URL state.
 * It uses `useUrlState` to sync form values with URL.
 * It also resets form fields when URL state is cleared.
 */
export function UrlStateFormItem({
  defaultValue,
  className,
  debounce = 50,
  formProps,
  ...props
}: UrlStateFormItemProps) {
  const [form] = Form.useForm()
  const setUrlState = useSetUrlState()

  /**
   * Get form and URL values for a field.
   */
  const getValues = useCallback(
    (inputValue = undefined, urlValue = undefined) => {
      inputValue = inputValue ?? form.getFieldValue(props.name)
      urlValue = urlValue ?? get(getUrlState(), props.name)

      return { inputValue, urlValue }
    },
    [form, props.name]
  )

  /**
   * Sync form values to URL state when form values change.
   */
  const fromFormToUrl = useDebouncedCallback((changedValues, values) => {
    formProps?.onValuesChange?.(changedValues, values)
    setUrlState(changedValues)
  }, debounce)

  /**
   * Watch for explicit URL state changes by external components.
   */
  useUrlStateRefresh(() => {
    const { urlValue } = getValues()
    form.setFieldsValue(set({}, props.name, urlValue))
  })

  /**
   * Initialize form values with URL state and default value.
   */
  const initialValue = useMemo(() => {
    return set({}, props.name, get(getUrlState(), props.name) || defaultValue)
    // note: run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form form={form} component={false} initialValues={initialValue} {...formProps} onValuesChange={fromFormToUrl}>
      <Form.Item className={cn('m-0', className)} {...props} />
    </Form>
  )
}
