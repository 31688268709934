import { useApp, useSession } from '@/hooks'
import { accountRoleApi } from '@/services/api-service'
import { AccountRole } from '@/types/account-role'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { useAtom } from 'jotai'
import { FC, useState } from 'react'
import { selectedAccountRoleAtom } from '../atoms'
import { useAccountRolesQuery } from '../hooks'
import { ManageRoleForm } from '../types'

type Props = {
  form: FormInstance<ManageRoleForm>
}

export const SaveButton: FC<Props> = ({ form }) => {
  const { company } = useSession()
  const { notification } = useApp()
  const createAccountRoleMutation = useMutation({ ...accountRoleApi.create<AccountRole>() })
  const updateAccountRoleMutation = useMutation({ ...accountRoleApi.update() })
  const [selectedAccountRole, setSelectedAccountRole] = useAtom(selectedAccountRoleAtom)
  const { accountRolesQuery } = useAccountRolesQuery()
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const handleSave = async () => {
    try {
      const { name, sync_notifications } = await form.validateFields()

      if (!selectedAccountRole) {
        console.error('selectedAccountRole is undefined')
        return
      }

      setIsSaveLoading(true)
      const mutation = selectedAccountRole.id ? updateAccountRoleMutation : createAccountRoleMutation

      const accountRole = await mutation.mutateAsync({
        ...selectedAccountRole,
        name,
        company: company.id,
        sync_notifications
      })

      await accountRolesQuery.refetch()
      setIsSaveLoading(false)

      notification.success({
        message: 'Role saved successfully'
      })

      if (!selectedAccountRole.id) {
        setSelectedAccountRole(accountRole)
      }
    } catch (error) {
      setIsSaveLoading(false)
      console.error(error)
    }
  }

  return (
    <Button
      type="success"
      iconName="mi:save"
      onClick={handleSave}
      loading={isSaveLoading}
      data-cy={'save-role-buttons'}
    >
      Save
    </Button>
  )
}
