import { Form, Select } from 'antd'
import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'
import { useAccountRolesQuery } from '../hooks'
import { ManageRoleForm } from '../types'

const newRoleOption = {
  label: 'Add New Role',
  value: 'add-new-role'
} as const

export const RoleChangeSelect = () => {
  const { accountRolesQuery } = useAccountRolesQuery()
  const [selectedAccountRole, setSelectedAccountRole] = useAtom(selectedAccountRoleAtom)
  const form = Form.useFormInstance<ManageRoleForm>()

  const rolesListOptions = useMemo(() => {
    const options =
      accountRolesQuery.data?.items.map((role) => ({
        label: role.name,
        value: role.id
      })) || []

    return [newRoleOption, ...options]
  }, [accountRolesQuery.data?.items])

  const handleRoleChange = async (value: number | (typeof newRoleOption)['value']) => {
    if (value === 'add-new-role') {
      form.setFieldValue('name', '')
      setSelectedAccountRole({})
      return
    }

    const selectedRole = accountRolesQuery.data?.items.find((role) => role.id === value)
    setSelectedAccountRole(selectedRole || {})
    form.setFieldValue('name', selectedRole?.name || '')
  }

  // when component is mounted, by default select the first role from the query or set a new role as selected
  useEffect(() => {
    if (accountRolesQuery.isLoading || selectedAccountRole) {
      return
    }

    const firstRole = accountRolesQuery.data?.items[0]
    setSelectedAccountRole(firstRole || {})
  }, [accountRolesQuery.isLoading, accountRolesQuery.data?.items, selectedAccountRole, setSelectedAccountRole])

  return (
    <Form.Item label="Roles List">
      <Select
        placeholder="Select"
        options={rolesListOptions}
        popupMatchSelectWidth={false}
        value={selectedAccountRole?.id || newRoleOption.value}
        onChange={handleRoleChange}
        style={{ width: 200 }}
      />
    </Form.Item>
  )
}
