import { ViewTicketLink } from '@/modules/ticket/components'
import { ActionButton, Button, Popover } from '@/ui'
import { Timeline } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { groupBy, sortBy, sumBy } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { CalendarNote, CalendarTicket } from '../../../schemas'
import { CalendarNoteRow } from '../calendar-note-row'
import { EventsPreview } from '../events-preview'

type CalendarDayCellProps = { date: Dayjs; items: CalendarTicket[]; notes: CalendarNote[] }

export const CalendarDayCell: FC<CalendarDayCellProps> = ({ date, items, notes }) => {
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false)
  const groupByStartTime = useMemo(() => {
    return sortBy(
      Object.entries(groupBy(items, '_localIsoDateTime')).map(([dateTimeStr, gItems]) => ({
        dateTimeStr: dateTimeStr,
        dateTime: dayjs.parse(dateTimeStr, { tz: 'replace' }),
        items: sortBy(gItems, ['_jobText', 'name'])
      })),
      'dateTimeStr'
    )
  }, [items])

  const closeEditingNote = () => setIsAddingNote(false)

  return (
    <div className={'h-full w-full'}>
      <Popover
        className={'h-full flex flex-grow flex-1'}
        trigger={['click']}
        overlayClassName={'min-w-[600px] max-h-[800px] overflow-y-auto overflow-x-hidden border rounded'}
        placement={'left'}
        content={
          <div className={'flex flex-col'}>
            <div className={'border-b border-b-gray-100'}>
              <h5>{date.format('MMMM DD, YYYY')}</h5>
              <div>
                <span>
                  # of Jobs: <span className={'text-primary'}>{items.length}</span> /
                </span>
                <span> Headcount: {sumBy(items, '_headcount')}</span>
              </div>
            </div>
            {items.length > 0 && (
              <div className={'mt-20'}>
                <Timeline
                  className={'max-h-[400px] overflow-y-auto py-10'}
                  mode={'left'}
                  items={groupByStartTime.map(({ dateTimeStr, dateTime, items }) => ({
                    children: (
                      <div className={'flex flex-col'}>
                        <div className={'font-medium'}>{dateTime?.formatLocalTime()}</div>
                        <div className={'flex flex-col'}>
                          {items.map((i) => (
                            <div key={i._key} className={'flex flex-row items-center gap-6 rounded-[4px]'}>
                              <div className={'rounded-full h-[4px] w-[4px] bg-primary-500'} />
                              <div>{i._jobText}</div>
                              <ViewTicketLink ticketId={i.id} formId={i.custom_form_id} target="_blank">
                                <Button
                                  iconPosition={'end'}
                                  type={'text'}
                                  primary
                                  size="small"
                                  iconName={'fa:external-link'}
                                >
                                  <span>#{i.name}</span>
                                </Button>
                              </ViewTicketLink>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  }))}
                />
              </div>
            )}
            <p className="border-b border-b-gray-100 mt-20">Calendar Notes</p>
            <div className="mt-20 flex flex-col gap-10">
              {notes.map((note) => (
                <CalendarNoteRow key={note.id} note={note} date={date.toISOString()} />
              ))}
            </div>
            <div className={'mt-20 flex justify-center'}>
              {isAddingNote ? (
                <CalendarNoteRow date={date.toISOString()} onSave={closeEditingNote} onCancel={closeEditingNote} />
              ) : (
                <ActionButton
                  primary
                  type="default"
                  shape="round"
                  variant="outlined"
                  iconName={'fa:comment-plus'}
                  className="max-w-[150px]"
                  onClick={() => setIsAddingNote(true)}
                >
                  Add Note
                </ActionButton>
              )}
            </div>
          </div>
        }
      >
        <EventsPreview items={items} notes={notes} />
      </Popover>
    </div>
  )
}
