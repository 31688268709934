import { SEARCH_DEBOUNCE_TIME } from '@/constants'
import { ListParams } from '@/types/api/core'
import { Button } from '@/ui/button'
import { UrlStateFormItem } from '@/ui/form'
import { SearchInput, SearchInputProps } from '@/ui/input'
import { QueryTableParams } from '@/ui/table'
import cn from 'classnames'
import { FC, ReactElement, useCallback } from 'react'
import { QueryFilter } from './QueryFilter'
import { SearchFilter } from './SearchFilter'
import { FILTER_KEY, FILTER_SEARCH_KEY } from './constants'
import { QueryFilterService } from './types'

export type ListFilterProps = {
  service?: QueryFilterService
  query?: (params: QueryTableParams) => ListParams
  search?: boolean | Pick<SearchInputProps, 'searchBy' | 'searchType'>
  render?: (searchInput: ReactElement, onChange: (value: any) => void) => ReactElement
  onChange?: (query: ListParams) => void
}

export const ListFilter: FC<ListFilterProps> = ({ service, search, render, onChange }) => {
  const onChangeCallback = useCallback(
    (query: ListParams) => {
      onChange?.(query)
    },
    [onChange]
  )

  if (service) {
    const { searchEnabled, filterEnabled, showQueryFilter, toggleQueryFilter } = service

    const searchEl = (
      <div className={'flex flex-row gap-8 w-full align-middle'}>
        {searchEnabled ? (
          <>
            {filterEnabled && (
              <Button iconName={showQueryFilter ? 'fa:filter-slash' : 'fa:filter'} onClick={toggleQueryFilter} />
            )}
            <SearchFilter service={service} />
          </>
        ) : filterEnabled ? (
          <QueryFilter service={service} />
        ) : (
          <></>
        )}
      </div>
    )

    return (
      <div className={cn('flex flex-col gap-8 w-full justify-center')}>
        {render ? render(searchEl, onChangeCallback) : searchEl}
        {filterEnabled && showQueryFilter && searchEnabled && <QueryFilter service={service} />}
      </div>
    )
  }

  // note: do not pass search props to SearchInput
  // because we are handling other props in ListView component
  const searchEl = search ? (
    <UrlStateFormItem name={[FILTER_KEY, FILTER_SEARCH_KEY]} debounce={SEARCH_DEBOUNCE_TIME}>
      <SearchInput debounce={0} data-cy={'filter-search-input'} />
    </UrlStateFormItem>
  ) : (
    <></>
  )

  return render ? render(searchEl, onChangeCallback) : searchEl
}
