import { view } from '@/routing'
import { CustomEmailsTable } from './custom-emails-table'
import { EmailTemplates } from './email-templates'

export const Email = view(Component, {
  title: () => 'Email',
  scoped: false
})

function Component() {
  return (
    <div className="flex gap-x-30">
      <EmailTemplates />
      <CustomEmailsTable />
    </div>
  )
}
