import { usePropertiesSelectOptions } from '@/modules/module-builder/hooks'
import { Form, Input, Select } from 'antd'
import { startCase } from 'lodash'

type Props = {
  selectedCustomForm: number[]
}

export const ListingCardTypeAttributes = ({ selectedCustomForm }: Props) => {
  const { propertiesOptions, isLoading, isFetching } = usePropertiesSelectOptions({
    customFormsIds: selectedCustomForm
  })

  return (
    <div className="grid grid-cols-3 gap-x-16">
      {[
        'position',
        'title',
        'description',
        'address',
        'rateAmount',
        'rateUnit',
        'rateOptions',
        'status',
        'postedTerms'
      ].map((field) => (
        <Form.Item key={field} label={startCase(field)} name={['data', field]}>
          <Select
            placeholder="Select"
            showSearch
            options={propertiesOptions}
            disabled={isLoading || isFetching}
            loading={isLoading || isFetching}
            optionFilterProp="label"
          />
        </Form.Item>
      ))}
      <Form.Item label="Button Title" name={['data', 'buttonLabel']}>
        <Input />
      </Form.Item>
    </div>
  )
}
