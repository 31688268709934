import { Form, Input } from 'antd'

export const RoleNameField = () => {
  return (
    <Form.Item
      label="Role Name"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input a role name'
        }
      ]}
    >
      <Input />
    </Form.Item>
  )
}
