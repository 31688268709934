import { z } from 'zod'

export const BpaConditionOperatorSchema = z.enum([
  'equals',
  'not_equals',
  'empty',
  'not_empty',
  'is_true',
  'is_false',
  'contains',
  'gt',
  'gte',
  'lt',
  'lte',
  're',
  'is_changed',
  'is_bool',
  'is_int',
  'is_float',
  'is_str',
  'is_list',
  'is_map'
])

export const BpaConditionFieldTypeSchema = z.enum(['ticket', 'properties', 'lineItems', 'context', 'custom'])

export const BpaConditionLogicSchema = z.enum(['and', 'or'])

export const BpaScheduleFilterFieldSchema = z.enum([
  'created_at',
  'timepoint_submitted',
  'timepoint_due',
  'modified_at',
  'user_data__user_datetime_1',
  'user_data__user_datetime_2',
  'user_data__user_datetime_3',
  'user_data__user_datetime_4'
])

export const BpaScheduleFilterValue1Schema = z.enum([
  'last-week',
  'last-month',
  'month-to-date',
  'past-days',
  'past-months',
  'past-hours',
  'past-minutes'
])

export const BpaConditionSchema = z.object({
  uuid: z.string(),
  logic: BpaConditionLogicSchema,
  fieldType: BpaConditionFieldTypeSchema,
  field: z.string().nullable(),
  operator: BpaConditionOperatorSchema,
  value1: z.any().optional(),
  value2: z.any().optional(),
  cast: z.enum(['str', 'int', 'float'])
})

export type BpaConditionLogic = z.infer<typeof BpaConditionLogicSchema>
export type BpaConditionFieldType = z.infer<typeof BpaConditionFieldTypeSchema>
export type BpaConditionOperator = z.infer<typeof BpaConditionOperatorSchema>
export type BpaCondition = z.infer<typeof BpaConditionSchema>
export type BpaScheduleFilterField = z.infer<typeof BpaScheduleFilterFieldSchema>
export type BpaScheduleFilterValue1 = z.infer<typeof BpaScheduleFilterValue1Schema>
