import { Dayjs } from 'dayjs'

/**
 * Function to calculate offset and width for progress bar
 * @param startDate - Start date of the range
 * @param endDate - End date of the range
 * @param forDate - Date for which the offset and width are calculated
 * @returns An object with `offset` and `width` (both as percentages)
 */
export const calculateProgress = (
  startDate: Dayjs,
  endDate: Dayjs,
  forDate: Dayjs
): { offset: number; width: number } => {
  const startOfDay = forDate.startOf('day').valueOf() // 00:00 of `forDate`
  const endOfDay = forDate.add(1, 'day').startOf('day').valueOf() // 23:59 of `forDate`

  const start = Math.max(startDate.valueOf(), startOfDay) // start of overlap
  const end = Math.min(endDate.valueOf(), endOfDay) // end of overlap

  // total duration of the day in milliseconds
  const totalDayDuration = endOfDay - startOfDay

  // calculate offset and width in percentages
  const offset = ((start - startOfDay) / totalDayDuration) * 100
  const width = ((end - start) / totalDayDuration) * 100

  // ensure the width and offset are within valid bounds
  return {
    offset: Math.max(0, offset),
    width: Math.max(0, Math.min(100, width)) // ensure width does not exceed 100%
  }
}
