import { CodeEditor } from '@/components/code-editor'
import { Icon, Popover, Tabs } from '@/ui'
import { Table, Tag } from 'antd'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { unSavedValuesAtom } from '../../../atoms'
import { useTicketLabel } from '../../../hooks'

export const ChangesPreview = () => {
  const l = useTicketLabel()
  const unSavedValues = useAtomValue(unSavedValuesAtom)
  const unSavedItems = useMemo(() => Object.keys(unSavedValues), [unSavedValues])

  if (unSavedItems.length === 0) {
    return null
  }

  return (
    <Popover
      placement={'bottomLeft'}
      content={
        <Tabs
          size={'small'}
          className={'w-[500px] h-[500px] overflow-auto'}
          items={[
            {
              key: 'table',
              label: <Icon name={'fa:table'} className={'mx-10'} />,
              children: (
                <Table
                  pagination={false}
                  dataSource={unSavedItems}
                  columns={[
                    { title: 'Field or Property', render: (key) => l(key) },
                    {
                      title: 'Change',
                      render: (key) => (
                        <div>
                          <Tag className={'text-wrap max-w-[250px] break-all line-through'} color={'red'}>
                            {unSavedValues?.[key]?.old || 'null'}
                          </Tag>
                          <Tag className={'text-wrap max-w-[250px] break-all'} color={'green'}>
                            {unSavedValues?.[key]?.new || 'null'}
                          </Tag>
                        </div>
                      )
                    }
                  ]}
                />
              )
            },
            {
              key: 'json',
              label: <Icon name={'fa:brackets-curly'} className={'mx-10'} />,
              children: (
                <CodeEditor
                  value={JSON.stringify(unSavedValues, null, 2)}
                  mode={'json'}
                  readOnly
                  hideToolbar
                  height={'440px'}
                  showGutter={false}
                />
              )
            }
          ]}
        />
      }
    >
      <Tag color={'orange'}>{unSavedItems.length} Changes</Tag>
    </Popover>
  )
}
