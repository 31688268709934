import { useSession } from '@/hooks'
import { contactApi, eventApi } from '@/services/api-service'
import { Contact } from '@/types/contact'
import { Button } from '@/ui/button'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { selectedTypeAtom } from '../../atoms'
import { Layout } from '../../components/layout'
import { RecentActivitySection } from '../../components/recent-activity-section'
import { AddContactView, DisplayContactView, EditContactView } from './OffsetContent/contact-view-form'

type Props = {
  showTabs?: boolean
}

export const ContactsView = ({ showTabs }: Props) => {
  const { permissions, company } = useSession()
  const deleteContactMutation = useMutation(contactApi.delete())
  const queryClient = useQueryClient()
  const [selectedId, setSelectedId] = useState<string>('')
  const eventsQuery = useQuery({
    ...eventApi.list({ type: 'contact', target: selectedId }),
    enabled: !isEmpty(selectedId)
  })
  const [contactType, setContactType] = useState<'add' | 'edit' | 'view'>('add')
  const [offsetActive, setOffsetActive] = useState(false)
  const selectedType = useAtomValue(selectedTypeAtom)
  const setSelectedType = useSetAtom(selectedTypeAtom)

  const handleDelete = async (id: number) => {
    await deleteContactMutation.mutateAsync({ id })
    await queryClient.invalidateQueries(contactApi.list().queryKey)
  }

  const showDelete = (privateCompany?: number, hasProfile?: boolean) => {
    const superUser = permissions.includes('super_admin')
    const companyAdmin = permissions.includes('company_admin')
    return (superUser || (companyAdmin && company?.id === privateCompany)) && !hasProfile
  }

  const showEdit = (privateCompany?: number, isPublic?: boolean) => {
    const superUser = permissions.includes('super_admin')
    const companyAdmin = permissions.includes('company_admin')
    return superUser || (companyAdmin && company?.id === privateCompany) || !!isPublic
  }

  const onCancel = () => {
    setSelectedId('')
    setOffsetActive(false)
    setSelectedType(null)
  }
  const onEdit = () => {
    setContactType('edit')
    setSelectedType(null)
  }

  useEffect(() => {
    if (selectedType && selectedType?.title === 'Contacts') {
      setSelectedId('')
      setOffsetActive(true)
      setContactType('add')
    }
  }, [selectedType])

  return (
    <Layout<Contact>
      page="/rolodex/contacts"
      columns={[
        {
          key: 'first_name',
          title: 'First Name',
          width: 150,
          sorter: true
        },
        {
          key: 'last_name',
          title: 'Last Name',
          width: 150,
          sorter: true
        },
        {
          key: 'company__name',
          title: 'Company',
          width: 200,
          sorter: 'company'
        },
        {
          key: 'actions',
          align: 'right',
          fixed: 'right',
          width: 100,
          render: (_, contact) => {
            return (
              <Space>
                {showEdit(contact.private_company, contact.company?.public) && (
                  <Button
                    data-cy="edit-contact"
                    iconName="fa:edit"
                    type="text"
                    onClick={() => {
                      setSelectedId(contact.id.toString())
                      setOffsetActive(true)
                      setContactType('view')
                    }}
                  />
                )}
                {showDelete(contact.private_company, contact.has_profile) && (
                  <Popconfirm
                    title="Delete Contact"
                    placement="topLeft"
                    description={`Are you sure you want to delete contact #${contact.id}?`}
                    onConfirm={() => handleDelete(contact.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                      loading: deleteContactMutation.isLoading,
                      'data-cy': 'confirm-delete-contact-button'
                    }}
                  >
                    <Button iconName="fa:trash" type="text" data-cy="delete-contact-button" />
                  </Popconfirm>
                )}
              </Space>
            )
          }
        }
      ]}
      isOffsetBlockActive={offsetActive}
      offsetContent={
        <>
          <Button iconName="mi:close" onClick={() => onCancel()}>
            Cancel
          </Button>
          {selectedId && contactType === 'view' && <DisplayContactView id={selectedId} onEditClick={onEdit} />}
          {!selectedId && contactType === 'add' && <AddContactView />}
          {selectedId && contactType === 'edit' && <EditContactView id={selectedId} />}
          {selectedId && ['view', 'edit'].includes(contactType) && (
            <RecentActivitySection
              type={'contact'}
              target={Number(selectedId)}
              events={eventsQuery.data?.items}
              isLoading={eventsQuery.isLoading}
            />
          )}
        </>
      }
      showTabs={showTabs}
    />
  )
}
