import { useApp } from '@/hooks'
import { equipmentApi } from '@/services/api-service'
import { Button, QuerySelect, Select } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import { sortBy } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { LINE_ITEM_FIELDS } from '../../constants'
import { BpaActionAddLineItem } from '../../schemas'

const WRITABLE_LI_FIELDS = sortBy(
  LINE_ITEM_FIELDS.filter((f) => !!f.input),
  'label'
)

type Props = {
  action: BpaActionAddLineItem
  onChange: (data: Partial<BpaActionAddLineItem>) => void
}

export const InputSyncLineItem: FC<Props> = (props) => {
  return (
    <div className="flex flex-col">
      <LineItemEquipmentSelect {...props} />
      {props.action.type === 'add_line_item' && (
        <Form.Item label="Number of Line Items">
          <Input
            defaultValue={props.action.number_of_lis || '1'}
            onChange={(e) => props.onChange({ number_of_lis: e.target.value })}
          ></Input>
        </Form.Item>
      )}
      <LineItemValues {...props} />
    </div>
  )
}
export const InputSyncLineItemMemo = React.memo(InputSyncLineItem)

// ------------ Action Inputs ------------
export const LineItemValues: FC<Props> = ({ action, onChange }) => {
  const { l } = useApp()

  const fieldsOptions = useMemo(
    () =>
      WRITABLE_LI_FIELDS.map((field) => ({
        ...field,
        label: l(`lineitem__${field.value}`, field.label),
        disabled: !!action.values?.find((v) => v[0] === field.value)
      })),
    [action.values]
  )

  const onAddItemValue = () => {
    const nextField = fieldsOptions.find((f) => !f.disabled)?.value
    if (!nextField) {
      return
    }

    onChange({
      values: [...(action?.values || ([] as any)), [nextField, null]]
    })
  }

  const onRemoveItemValue = (valueIdx: number) => {
    const values = [...(action?.values || [])]
    values.splice(valueIdx, 1)
    onChange({ values })
  }

  const onItemChange = (fieldIdx: 0 | 1, value: string, valuesIdx: number) => {
    onChange({ [`values.${valuesIdx}.${fieldIdx}`]: value })
  }

  const onItemChangeDebounced = useDebouncedCallback(onItemChange, 250)

  return (
    <div>
      <Button type={'dashed'} primary={true} size="small" iconName="fa:add" className="mb-10" onClick={onAddItemValue}>
        Set Item Field
      </Button>
      {action.values?.map((v, valuesIdx) => (
        <div key={v[0]} className="flex flex-row items-center gap-10">
          <Form.Item label="Field" className="w-4/12">
            <Select
              defaultValue={v[0]}
              options={fieldsOptions}
              onChange={(value) => onItemChange(0, value, valuesIdx)}
            />
          </Form.Item>
          <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]" className="flex-grow">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={v[1] || ''}
              onChange={(e) => onItemChangeDebounced(1, e.target.value, valuesIdx)}
            />
          </Form.Item>
          <Button
            type="text"
            size="small"
            danger={true}
            iconName="fa:close"
            onClick={() => onRemoveItemValue(valuesIdx)}
          />
        </div>
      ))}
    </div>
  )
}

export const LineItemEquipmentSelect: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-row gap-10 place-content-between">
      {action.asText ? (
        <Form.Item label="Equipment ID (Text)" className="w-9/12" tooltip="Supports placeholder like P[Key], F[name]">
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 10 }}
            defaultValue={action.equipment || ''}
            onChange={(e) => onChangeDebounced({ equipment: e.target.value })}
          />
        </Form.Item>
      ) : (
        <Form.Item label="Equipment (SKU / Description)" className="w-9/12">
          <QuerySelect
            apiEndpoint={equipmentApi.list}
            apiQueryParams={{
              fields: 'id,sku,description,category__name',
              order: 'category__name,sku'
            }}
            apiSearchBy={(text) => ({
              'Q[]':
                text && text.length
                  ? [
                      `sku__icontains|${text}`,
                      `description__icontains|${text}`,
                      'or',
                      `category__name__icontains|${text}`,
                      'or'
                    ]
                  : []
            })}
            renderOption={(item) => ({
              value: item.id,
              label: `${item.sku || 'None'} / ${item.description || 'None'}`
            })}
            defaultValue={{
              value: action.equipment || '',
              label: action.equipmentText
            }}
            onChange={(_, o: any) => onChange({ equipment: o.value, equipmentText: o.label })}
          />
        </Form.Item>
      )}

      <Form.Item label="As Text" tooltip="Using as text will allow using placeholder string in non textual value input">
        <Checkbox
          checked={action.asText}
          onChange={(e: any) => {
            onChange({ asText: e.target.checked, equipment: null, equipmentText: null })
          }}
        />
      </Form.Item>
    </div>
  )
}
