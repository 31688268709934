import { useSession } from '@/hooks'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { PropertyGroup } from '../schemas'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'
import { useTicketScript } from './use-ticket-script'

export const useTicketFlags = () => {
  const isSuperAdmin = useIsSuperAdmin()
  const { company } = useSession()
  const { customForm, features } = useCustomForm()
  const { evaluateScripts } = useTicketScript()
  const { ticket } = useTicket()

  const hidePropertyGroup = useCallback(
    (pg: PropertyGroup) => {
      if (pg.hide_on_web) return true
      if (isSuperAdmin) return false
      if (pg.internal_use_only && customForm.primary_company_id !== company.id) return true
      if (pg.hide_from_customer && ticket._is_customer) return true

      if (!isEmpty(pg.section_hide_script)) {
        try {
          const result = evaluateScripts([pg.section_hide_script || ''])
          if (result[0] === true || result[0] === 'true') {
            return true
          }
        } catch (e) {
          console.error('PG: Section hide script evaluation failed', e)
        }
      }
    },
    [company.id, customForm.primary_company_id, evaluateScripts, isSuperAdmin, ticket._is_customer]
  )

  const lockCollapsePropertyGroup = useCallback(
    (pg: PropertyGroup) => {
      if (!isEmpty(pg.collapse_hide_script)) {
        try {
          const result = evaluateScripts([pg.collapse_hide_script || ''])
          if (result[0] === true || result[0] === 'true') {
            return true
          }
        } catch (e) {
          console.error('PG: Section hide script evaluation failed', e)
        }
      }

      return false
    },
    [evaluateScripts]
  )

  const hideActivityLog = useMemo(
    () => !isSuperAdmin && ticket._is_customer && customForm.hide_activity_log,
    [customForm.hide_activity_log, isSuperAdmin, ticket._is_customer]
  )

  const hideAttachments = useMemo(
    () => !isSuperAdmin && ticket._is_customer && customForm.hide_attachments,
    [customForm.hide_attachments, isSuperAdmin, ticket._is_customer]
  )

  const maskedItemFields = useMemo(() => {
    const config = features.pricingTweaks
    if (!config?.enabled) return []
    if (ticket._is_staff || (config.show_for_customer && ticket._is_customer)) return []

    return (
      config.masked_fields || [
        'usage_rate',
        'standby_rate',
        'amount',
        'cost',
        'taxable_amount',
        'discountable_amount',
        'subtotal',
        'tax_rate',
        'total'
      ]
    )
  }, [features.pricingTweaks, ticket._is_customer, ticket._is_staff])

  const isSummaryTicket = useMemo(
    () => (ticket._data?.summary_config?.selected_tickets?.length || 0) > 0,
    [ticket._data?.summary_config?.selected_tickets?.length]
  )

  return {
    viewOnly: ticket._viewOnly,
    features,
    hidePropertyGroup,
    lockCollapsePropertyGroup,
    hideActivityLog,
    hideAttachments,
    maskedFields: maskedItemFields,
    isSummaryTicket
  }
}
