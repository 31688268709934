import { useApp } from '@/hooks'
import { contentTypeApi, reportBuilderApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FormInstance, Space } from 'antd'
import { useAtomValue } from 'jotai'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useDownloadReportMutation } from '../../../hooks'
import { columnsAtom } from '../atoms'

type Props = {
  form: FormInstance
}

export const SaveAndDownloadSection: FC<Props> = ({ form }) => {
  const { id: reportId } = useParams()
  const { downloadReportMutation } = useDownloadReportMutation()
  const columns = useAtomValue(columnsAtom)
  const contentTypesQuery = useQuery(contentTypeApi.list())
  const updateReportMutation = useMutation(reportBuilderApi.patch())
  const { notification } = useApp()

  const handleSave = async () => {
    const contentTypes = contentTypesQuery.data?.items || []
    const { is_line_item_report, options, ...formValues } = await form.validateFields()

    // exclude sending report to backend
    const { report, ...restOptions } = options

    const ticketContentType = contentTypes.find((val) => val.name === 'ticket')
    const lineItemContentType = contentTypes.find((val) => val.name === 'line item')

    const root_model = is_line_item_report ? lineItemContentType.pk : ticketContentType.pk
    const root_model_name = is_line_item_report ? lineItemContentType.name : ticketContentType.name

    const isMergeFields = options?.merge_fields

    const data = {
      ...formValues,
      id: Number(reportId),
      options: restOptions,
      root_model,
      root_model_name,
      displayfield_set: columns.selected.map(({ id, p_id, path, path_verbose, field_verbose, ...item }, index) => ({
        ...item,
        p_id,
        path: !p_id ? path : is_line_item_report ? 'ticket__' : '',
        path_verbose: !p_id ? path_verbose : is_line_item_report ? 'ticket' : '',
        field_verbose: !p_id ? field_verbose : isMergeFields ? item._field_verbose : field_verbose,
        position: index,
        report: Number(reportId),
        ...(item.options && {
          options: {
            properties: item.options.properties || [],
            properties_json: JSON.stringify(item.options.properties || [])
          }
        })
      }))
    }

    const updatedReport = await updateReportMutation.mutateAsync(data)
    form.setFieldsValue(updatedReport)
    notification.success({ message: 'Report updated' })
  }

  const handleDownload = async () => {
    if (!reportId) {
      console.error('Report Id not found')
      return
    }

    await handleSave()

    await downloadReportMutation.mutateAsync({
      id: reportId,
      encoding: 'utf-16',
      format: 'xlsx'
    })
  }

  return (
    <div className="flex gap-8">
      <Button iconName="fa:file-download" onClick={() => handleDownload()}>
        Download
      </Button>
      <Space>
        <Button iconName="fa:save" type="success" onClick={() => handleSave()}>
          Save
        </Button>
      </Space>
    </div>
  )
}
