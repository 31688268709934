import { useSession } from '@/hooks'
import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { useQuery } from '@tanstack/react-query'

export const useCompanyQuery = () => {
  const { company } = useSession()
  const companyQuery = useQuery({ ...companyApi.get<Company>(company.id) })

  return {
    companyQuery
  }
}
