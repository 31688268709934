import { FC } from 'react'
import { FieldConfig, LineItem } from '../../../../schemas'

type Props = {
  lineItem: Partial<LineItem>
  config: FieldConfig
}

const FIELD_STYLES: Record<string, string> = {
  description: 'font-semibold'
}

const getObjValue = (obj: any, key: any) => obj[key]

export const ItemCell: FC<Props> = ({ lineItem, config }) => {
  return (
    <div className={FIELD_STYLES[config.field]}>{config.format?.(lineItem) || getObjValue(lineItem, config.field)}</div>
  )
}
