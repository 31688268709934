import { useApp } from '@/hooks'
import { apiHttp } from '@/services/api-service/core'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'

export const useDownloadReportMutation = () => {
  const { notification } = useApp()

  const downloadReportMutation = useMutation({
    mutationFn: async ({ id, format, encoding }: { id: number | string; format: string; encoding: string }) => {
      const res = await apiHttp.get(`/report_builder/api/reports/${id}/download/${format}/`, {
        responseType: 'blob',
        params: {
          encoding,
          local_tz: false
        }
      })

      const filename = res.headers['content-disposition']?.split('filename=')[1] ?? 'download.xlsx'
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      window.URL.revokeObjectURL(url)
      return true
    }
  })

  // not using onError because of typescript error
  useEffect(() => {
    if (downloadReportMutation.error) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong'
      })
    }
  }, [downloadReportMutation.error, notification])

  return {
    downloadReportMutation
  }
}
