import { CategoryTreeSelect } from '@/components'
import { LINE_ITEM_FIELDS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Button, Select } from '@/ui'
import { Checkbox, Divider, Form, Switch } from 'antd'
import React, { FC, useMemo } from 'react'
import { BpaActionReOrderLineItems } from '../../schemas'

type Props = {
  action: BpaActionReOrderLineItems
  onChange: (data: Partial<BpaActionReOrderLineItems>) => void
}

const FILTER_TYPE: Record<string, 'O' | 'E'> = {
  only: 'O',
  except: 'E'
}

export const InputReOrderLineItems: FC<Props> = ({ action, onChange }) => {
  const { l } = useApp()
  const fieldsOptions = useMemo(
    () =>
      LINE_ITEM_FIELDS.map((field) => ({
        value: field.field,
        label: l(`lineitem__${field.field}`, field.label),
        disabled: !!action.order_by?.find((v) => v.field === field.field)
      })),
    [action.order_by]
  )

  const onAddItem = () => {
    const nextField = fieldsOptions.find((f) => !f.disabled)?.value
    if (!nextField) {
      return
    }

    onChange({
      order_by: [...(action?.order_by || ([] as any)), { field: nextField, direction: 'asc', nulls_last: false }]
    })
  }

  const onRemoveItem = (itemIdx: number) => {
    const orderByList = [...(action?.order_by || [])]
    orderByList.splice(itemIdx, 1)
    onChange({ order_by: orderByList })
  }

  const onItemChange = (field: 'field' | 'direction' | 'nulls_last', value: string | boolean, itemIdx: number) => {
    onChange({ [`order_by.${itemIdx}.${field}`]: value })
  }

  return (
    <div className="flex flex-col">
      <div className="flex gap-10">
        <Switch checked={action.filter_by_cat_enabled} onClick={(v) => onChange({ filter_by_cat_enabled: v })} />
        <p>Filter by Categories</p>
      </div>
      {action.filter_by_cat_enabled && (
        <div className="flex gap-10">
          <Form.Item label={'Filter Type'} className="w-1/2 mb-0">
            <Select
              showCopyText={false}
              allowClear={false}
              showSearch={false}
              defaultValue={action.filter_type || FILTER_TYPE.only}
              onChange={(v) => onChange({ filter_type: v })}
              options={[
                { label: 'Only Selected', value: FILTER_TYPE.only },
                { label: 'Except Selected', value: FILTER_TYPE.except }
              ]}
            />
          </Form.Item>
          <Form.Item label={'Categories'} className="w-1/2 mb-0">
            <CategoryTreeSelect value={action.categories} onChange={(v) => onChange({ categories: v })} />
          </Form.Item>
        </div>
      )}
      <Divider className="my-[16px] bg-gray-200" />
      {action.order_by?.map((f, itemIdx) => (
        <div key={f.field} className="flex flex-row items-center gap-12">
          <Form.Item label="Field" className="w-4/12">
            <Select
              defaultValue={f.field}
              options={fieldsOptions}
              onChange={(value) => onItemChange('field', value, itemIdx)}
            />
          </Form.Item>
          <Form.Item label="Direction">
            <Select
              defaultValue={f.direction}
              options={[
                { value: 'asc', label: 'Ascending' },
                { value: 'desc', label: 'Descending' }
              ]}
              onChange={(value) => onItemChange('direction', value, itemIdx)}
            />
          </Form.Item>
          <Form.Item label="Nulls Last" className="w-4/12 ml-[8px]">
            <Checkbox
              checked={f.nulls_last}
              onChange={(e: any) => {
                onItemChange('nulls_last', e.target.checked, itemIdx)
              }}
            />
          </Form.Item>
          <Button
            type="text"
            size="small"
            danger={true}
            iconName="fa:close"
            className="flex-grow"
            onClick={() => onRemoveItem(itemIdx)}
          />
        </div>
      ))}
      <Button type={'dashed'} primary={true} size="small" iconName="fa:add" className="mb-10" onClick={onAddItem}>
        Add Sorting Field
      </Button>
    </div>
  )
}

export const InputReOrderLineItemsMemo = React.memo(InputReOrderLineItems)
