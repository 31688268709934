export const SCHEDULE_TYPES = {
  U: {
    title: 'Unavailable',
    titleShort: 'U',
    color: '#c0cbd9',
    textColor: ''
  },
  O: {
    title: 'Off day',
    titleShort: 'O',
    color: '#aeb9c7',
    textColor: ''
  },
  J: {
    title: 'Job',
    titleShort: 'J',
    color: '#8b98a8',
    textColor: ''
  },
  W: {
    title: 'Work',
    titleShort: 'W',
    color: '#fff5e6',
    textColor: '#ec9c00'
  }
} as const

export type ScheduleType = keyof typeof SCHEDULE_TYPES
