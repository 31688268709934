import { Modal } from 'antd'

type ChangeRoleModalType = {
  active: boolean
  onOk: () => void
  onCancel: () => void
  setActive: (x: boolean) => void
}

const ChangeRoleModal = ({ active, onOk, onCancel, setActive }: ChangeRoleModalType) => {
  const handleOk = () => {
    setActive(false)
    onOk()
  }
  const handleCancel = () => {
    setActive(false)
    onCancel()
  }
  return (
    <Modal open={active} title="Change Role" okText="Yes" cancelText="No" onOk={handleOk} onCancel={handleCancel}>
      <p>
        Profile will be set to 'Customized Role' and will not be associated with the previously assigned role. Do you
        want to proceed?
      </p>
    </Modal>
  )
}

export default ChangeRoleModal
