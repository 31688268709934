import { PropertyEngine } from '../helpers/script-engine'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'

export const useScriptEngine = () => {
  const { getValueByKey } = useTicket()
  const { customForm } = useCustomForm()

  const getPropertyDisplay = (pKey: string, defVal?: string) => {
    const value = getValueByKey(pKey)
    const lookup = (customForm._propertyByKey[pKey] || 0)._options || []
    return (lookup.find((opt) => opt.value == value) || {}).label || defVal || ''
  }

  const getPropertyDisplayList = (pKey: string, defVal?: string) => {
    const values = (getValueByKey(pKey) || '').split(',')
    const lookup = (customForm._propertyByKey[pKey] || 0)._options || []
    return values.map((v: string) => (lookup.find((opt) => opt.value == v) || {}).label || defVal || '').sort()
  }

  const getPropertyChoicesMap = (pKey: string, defVal?: string) => {
    const lookup = (customForm._propertyByKey[pKey] || 0)._options || []
    return lookup.reduce((obj: any, i: any) => {
      obj[i.value] = i.label
      return obj
    }, {})
  }

  const getAssets = (): any => null

  const engine = new PropertyEngine(
    getValueByKey,
    getPropertyDisplay,
    getPropertyDisplayList,
    getPropertyChoicesMap,
    getAssets, // @deprecated
    {} // todo: js_lib unused?
  )

  return { engine }
}
