import { Icon } from '@/ui/icons'
import { defVal } from '@/utils'
import dayjs from 'dayjs'
import React from 'react'
import { useSelectedJobTicket } from '../../hooks'

export const TopHeader: React.FC = () => {
  const { selectedJobData: jobData } = useSelectedJobTicket()

  return (
    <div className="flex flex-wrap items-center text-sm">
      <div className="flex items-center pr-16 mb-2">
        <Icon name={'fa:user'} className={'text-lg'} />{' '}
        <span className="inline-block ml-10 font-medium">{defVal(jobData?.line1, 'N/A')}</span>
      </div>

      <div className="flex items-center px-16 mb-2 text-sm">
        <Icon name="fa:location-dot" className={'text-lg'} />{' '}
        <span className="inline-block ml-10 font-medium">{defVal(jobData?.line2, 'N/A')}</span>
      </div>

      <div className="flex items-center px-16 mb-2 text-sm">
        <Icon name="fa:note" className={'text-lg'} />{' '}
        <span className="inline-block ml-10 font-medium ">{defVal(jobData?.line3, 'N/A')}</span>
      </div>

      <div className="lg:ml-auto flex items-center">
        <div className="flex items-center px-16 mb-2 text-sm">
          <Icon name="fa:calendar" className={'text-lg'} />
          <span className="inline-block ml-10 font-medium ">
            <span>{dayjs.formatLocalDate(jobData?.startDate) || '--/--/----'} </span>
            <span className="text-gray-400">{dayjs.formatLocalTime(jobData?.startDate) || '--:--'}</span>
          </span>
          &nbsp;
          <span className="inline-block ml-10 font-medium text-sm">
            <span>{dayjs.formatLocalDate(jobData?.endDate) || '--/--/----'} </span>
            <span className="text-gray-400">{dayjs.formatLocalTime(jobData?.endDate) || '--:--'}</span>
          </span>
        </div>

        <div className="flex items-center px-16 mb-2 text-sm">
          <Icon name="fa:clock" className={'text-lg'} />{' '}
          <span className="inline-block ml-10 font-medium ">{jobData?.elapsedTime || 0} hours</span>
        </div>
      </div>
    </div>
  )
}
