import { UploadFile } from 'antd'
import { atom } from 'jotai'
import { CustomForm } from './types'

export const customFormAtom = atom<CustomForm>({} as CustomForm)

export const setCustomFormAtom = atom(null, (_, set, update: Partial<CustomForm>) => {
  set(customFormAtom, (prev) => ({ ...prev, ...update }))
})

export const customFormIconAtom = atom<UploadFile<any> | null>(null)
export const isTypeErrorAtom = atom(false)
export const isNameErrorAtom = atom(false)
