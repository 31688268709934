import { CodeEditor } from '@/components/code-editor'
import { useApp } from '@/hooks'
import { FormView } from '@/layouts/views'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { companyApi, secretApi } from '@/services/api-service'
import { QuerySelect, SaveButton } from '@/ui'
import { yamlParseSafe } from '@/utils/yaml'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { startCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { SECRET_FORM_VIEW_HEADER } from '../../contstants'
import { Secret, SecretForm } from './schema'

export const SecretFormView = view<any, SecretForm>(Component, {
  title: ({ data }) => data?.title || '',
  loader: async ({ params }) => {
    const secretId = Number(params.id)

    if (!secretId) return { mode: 'add', title: 'Add New Secret' }

    const secret = await fetchQuery(secretApi.get<Secret>(secretId))
    return { mode: 'edit', title: `Edit ${secret.code_name}`, secret }
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { id: secretId } = useParams()
  const { notification } = useApp()
  const isSuperAdmin = useIsSuperAdmin()

  const form = Form.useFormInstance<SecretForm>()

  const mutation = useMutation(secretId ? secretApi.patch() : secretApi.create())

  const save = async () => {
    const data = await form.validateFields()
    const secret = data.secret
    if (!yamlParseSafe(secret?.values || '')) {
      notification.error({ message: 'Invalid YAML text' })
      return
    }

    try {
      const result = await mutation.mutateAsync(secret)
      notification.success({ message: 'Success', description: 'Secret successfully saved' })
      if (!secretId) return `../${result.id}/edit`
    } catch (e) {
      notification.error({ message: 'Failed', description: 'Failed to save Secret' })
    }
  }

  return (
    <FormView
      header={{
        ...SECRET_FORM_VIEW_HEADER,
        title: `${startCase(form.getFieldValue('mode'))} Secret`,
        actions: [<SaveButton key={'save'} onSave={save} loading={mutation.isLoading} />]
      }}
    >
      <Form.Item name={['secret', 'id']} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={['secret', 'code_name']} label="Code Name" rules={[{ required: true }]}>
        <Input className="max-w-[320px]" />
      </Form.Item>
      {isSuperAdmin && (
        <Form.Item label="Company" name={['secret', 'company_id']} rules={[{ required: true }]}>
          <QuerySelect
            apiEndpoint={companyApi.list}
            apiQueryParams={{ fields: 'id,name' }}
            apiValueBy={'id'}
            apiSearchBy="name"
            className="max-w-[320px]"
            placeholder="Select"
            popupMatchSelectWidth={false}
            renderOption={(company) => ({ value: company.id, label: company.name })}
          />
        </Form.Item>
      )}
      <Form.Item name={['secret', 'values']} label="Values (YAML)" rules={[{ required: true }]}>
        <CodeEditor minLines={24} maxLines={Infinity} mode="yaml" />
      </Form.Item>
    </FormView>
  )
}
