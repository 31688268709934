type Asset = {
  strapping_increments: StrappingIncrement[]
  increment_offset?: number
}

type StrappingIncrement = {
  increments: string
  num_quarters: string
}

type PropertyEngineLibs = {
  [key: string]: any
}

export class PropertyEngine {
  private getPropertyValue: (pKey: string) => any
  private getPropertyDisplay: (pKey: string) => any
  private getPropertyDisplayList: (pKey: string) => any
  private getPropertyChoicesMap: (pKey: string) => any
  private getAsset: () => Asset
  private jsLib: PropertyEngineLibs

  constructor(
    fnGetPropertyValue: (pKey: string) => any,
    fnGetPropertyDisplay: (pKey: string) => any,
    fnGetPropertyDisplayList: (pKey: string) => any,
    fnGetPropertyChoicesMap: (pKey: string) => any,
    fnGetAsset: () => Asset,
    jsLib: PropertyEngineLibs
  ) {
    this.getPropertyValue = fnGetPropertyValue
    this.getPropertyDisplay = fnGetPropertyDisplay
    this.getPropertyDisplayList = fnGetPropertyDisplayList
    this.getPropertyChoicesMap = fnGetPropertyChoicesMap
    this.getAsset = fnGetAsset
    this.jsLib = jsLib
  }

  iget(pKey: string): number {
    return parseInt(this.getPropertyValue(pKey)) || 0
  }

  fget(pKey: string): number {
    return parseFloat(this.getPropertyValue(pKey)) || 0.0
  }

  get(pKey: string): any {
    const value = this.getPropertyValue(pKey) || ''

    // todo: support date formatting?
    // if (value instanceof Date) {
    //   return DateUtil.formatWithOffset(value)
    // }

    return value
  }

  getBBL(size: number): string | number {
    if (size < 0) {
      return 0
    }

    let prevInc = 0
    const tank = this.get('Assign Standard')
    const asset = this.getAsset()

    if (asset && (!tank || tank === 'asset')) {
      let total = 0
      let prevQuart = 0
      for (let i = 0; i < asset.strapping_increments.length; i++) {
        const si = asset.strapping_increments[i]
        const increments = parseFloat(si.increments)
        const quarters = Math.max(0, parseInt(si.num_quarters))

        if (quarters > size) {
          const count = size - prevQuart
          total += count * increments
          prevInc = increments
          prevQuart = size
          break
        }

        total += (quarters - prevQuart) * increments
        prevInc = increments
        prevQuart = quarters
      }

      if (prevQuart !== size) {
        total += (size - prevQuart) * prevInc
      }

      return total + (asset.increment_offset ? asset.increment_offset : 0)
    }

    return STANDARD_INCREMENTS[tank] ? STANDARD_INCREMENTS[tank] * size : ''
  }

  evaluate(script: string, ticket?: any, user?: any, context?: any): any {
    const params: string[] = []
    const args: any[] = []

    Object.entries({
      env: 'web',
      get: this.get.bind(this),
      iget: this.iget.bind(this),
      fget: this.fget.bind(this),
      getDisplay: this.getPropertyDisplay.bind(this),
      getDisplayList: this.getPropertyDisplayList.bind(this),
      getChoicesMap: this.getPropertyChoicesMap.bind(this),
      getBBL: this.getBBL.bind(this),
      OilCommand: this.jsLib,
      getObjId: (obj: any) => obj?.id || obj,
      ticket: ticket || null,
      user: user || null,
      script: script,
      ...context
    }).forEach(([key, value]) => {
      params.push(key)
      args.push(value)
    })

    return new Function(...params, 'return eval(script)')(...args)
  }
}

const STANDARD_INCREMENTS: { [key: string]: number } = {
  '300': 0.41665,
  '400': 0.41665,
  '500': 0.5265,
  '1000': 0.6944375,
  '2000': 2.605,
  '100.08': 0.26,
  '100.12': 0.175,
  '150.12': 0.26,
  '200.06': 0.6925,
  '200.1': 0.4175,
  '200.14': 0.2975,
  '210.14': 0.29,
  '250.08': 0.6525,
  '250.12': 0.435,
  '250.15': 0.3475,
  '280.14': 0.4175,
  '300.19': 0.3375,
  '300.15': 0.4175,
  '400.2': 0.4175,
  '410.15': 0.5675,
  '436.16': 0.5675,
  '500.08': 1.3025,
  '500.16': 0.69,
  '500.2': 0.5225,
  '550.2': 0.5725,
  '500.25': 1.67,
  '750.24': 1.3025,
  '900.16': 1.17,
  '1000.16': 1.3025,
  '5000.24': 4.3425
}
