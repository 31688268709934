import { Contact, ContactSelect } from '@/components/contact-select'
import { view } from '@/routing'
import { Button } from '@/ui/button'
import { Form, Table } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../atoms'

export const DefaultTeamMembers = view(Component, {
  title: () => 'Default Team Members',
  scoped: false
})

function Component() {
  const defaultTeamMembers =
    useAtomValue(useMemo(() => selectAtom(customFormAtom, (c) => c.default_team_members), [])) || []

  const setCustomFrom = useSetAtom(setCustomFormAtom)

  const handleAddContact = (val?: Contact) => {
    if (!val) {
      return
    }

    if (defaultTeamMembers?.find((v) => v.id === val.id)) {
      return
    }

    if (val) {
      setCustomFrom({
        default_team_members: [...defaultTeamMembers, val]
      })
    }
  }

  const handleRemoveContact = (val: Contact) => {
    setCustomFrom({
      default_team_members: defaultTeamMembers?.filter((v) => v.id !== val.id)
    })
  }

  return (
    <div>
      <div className="font-bold mb-12">Select the user(s) to pin their contacts to the ticket</div>
      <Form layout="vertical">
        <Form.Item label="Team Members" className="w-[300px]">
          <ContactSelect
            selectProps={{
              value: null,
              onContactChange: handleAddContact,
              ['data-cy' as any]: 'default-team-members-select'
            }}
          />
        </Form.Item>
      </Form>
      <Table dataSource={defaultTeamMembers?.map((v) => ({ ...v, key: v.id }))} pagination={false}>
        <Table.Column title="#" width={100} render={(_, __, index) => index + 1} />
        <Table.Column
          title="Users"
          render={(_, val: (typeof defaultTeamMembers)[0]) => (
            <>
              <div className="font-semibold">
                {val.first_name} {val.last_name}
              </div>
              <div className="text-12">{val.title}</div>
            </>
          )}
        />
        <Table.Column
          title="Edit"
          dataIndex="role"
          width={1200}
          render={(_, val: (typeof defaultTeamMembers)[0]) => (
            <Button
              iconName="svg:trash"
              type="text"
              onClick={() => handleRemoveContact(val)}
              data-cy="delete-default-team-member-button"
            ></Button>
          )}
        />
      </Table>
    </div>
  )
}
