import { eventApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Button, PageTitle } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Space } from 'antd'
import { RecentActivitySection } from '../../../../components/recent-activity-section'

const DisplayCompaniesView = ({ company, onAddClick }: { company: Company; onAddClick: () => void }) => {
  const iconUrl = company?.icon || company?.icon_path
  const eventsQuery = useQuery({ ...eventApi.list({ type: 'company', target: company.id }) })

  return (
    <div>
      <div className="py-16 flex flex-col items-center justify-between w-full">
        {iconUrl && <img src={iconUrl} className="max-w-[200px] block mb-10" />}
        <div className="flex justify-between w-full items-center">
          <PageTitle className="mb-8">{company?.name}</PageTitle>
          <Space>
            <Button data-cy="add-office-button" type="primary" iconName="fa:add" onClick={onAddClick}>
              Add Office
            </Button>
          </Space>
        </div>
        {company?.website && (
          <div className="border-b border-gray-200 pt-6 pb-6 w-full">
            <div className="flex py-2 -mx-10">
              <div className="w-4/12 px-10">
                <span className="font-semibold text-16">Website</span>
              </div>
              <div className="w-8/12 px-10">
                <span className="text-16">{company?.website}</span>
              </div>
            </div>
          </div>
        )}
        {company?.notes && (
          <div className="border-b border-gray-200 pt-6 pb-6 w-full">
            <div className="flex py-2 -mx-10">
              <div className="w-4/12 px-10">
                <span className="font-semibold text-16">Notes</span>
              </div>
              <div className="w-8/12 px-10">
                <span className="text-16">{company?.notes}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <RecentActivitySection
        type={'company'}
        target={company.id}
        events={eventsQuery.data?.items}
        isLoading={eventsQuery.isLoading}
      />
    </div>
  )
}

export { DisplayCompaniesView }
