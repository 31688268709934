export const PAGE_SIZE = 25

export const TABLE_PAGE_SIZES = [10, 25, 50, 100]

// ???
export const TABLE_DEFAULT_PAGE_SIZE = PAGE_SIZE

export const DYNAMIC_DATES = {
  mtd: 'Month to Date',
  lm: 'Last Month',
  lw: 'Last Week (Sunday - Saturday)',
  lww: 'Last Work Week (Monday - Sunday)',
  ld: 'Past Days',
  pm: 'Past Months'
} as const

export const UNIT = {
  '/ea': 'Each',
  '/bbl': 'Barrel',
  '/ton': 'Ton',
  '/hr': 'Per Hour',
  '/day': 'Per Day',
  '/wk': 'Per Week',
  '/mo': 'Per Month',
  '/mi': 'Per Mile',
  '/ft': 'Per Foot',
  '/yd': 'Per Yard',
  '/gal': 'Per Gallon',
  '/qt': 'Per Quart'
} as const

export const UNIT_NAME: Record<keyof typeof UNIT, string> = {
  '/ea': 'Unit',
  '/bbl': 'Barrel',
  '/ton': 'Ton',
  '/hr': 'Hours',
  '/day': 'Days',
  '/wk': 'Weeks',
  '/mo': 'Months',
  '/mi': 'Miles',
  '/ft': 'Feet',
  '/yd': 'Yards',
  '/gal': 'Gallons',
  '/qt': 'Quarts'
}

export const TICKET_STATUS = {
  D: 'Draft',
  A: 'Created',
  O: 'Open',
  N: 'Needs Signature',
  S: 'Signed',
  B: 'Disputed',
  R: 'Reviewed',
  C: 'Closed',
  Z: 'Archived',
  X: 'Deleted'
} as const

export const LINE_ITEM_FIELDS = [
  {
    label: 'UUID',
    field: 'uuid',
    component: 'Input'
  },
  {
    label: 'SKU',
    field: 'sku',
    component: 'Input'
  },
  {
    label: 'Description',
    field: 'description',
    component: 'TextArea'
  },
  {
    label: 'Category ID',
    field: 'category',
    component: 'Input'
  },
  {
    label: 'Category Name',
    field: 'category__name',
    component: 'Input'
  },
  {
    label: 'Parent Category ID',
    field: 'category__parent_category__id',
    component: 'Input'
  },
  {
    label: 'Parent Category Name',
    field: 'category__parent_category__name',
    component: 'Input'
  },
  {
    label: 'Equipment ID',
    field: 'equipment',
    component: 'Input'
  },
  {
    label: 'Usage Rate',
    field: 'usage_rate',
    component: 'NumberInput'
  },
  {
    label: 'Standby Rate',
    field: 'standby_rate',
    component: 'NumberInput'
  },
  {
    label: 'Hands',
    field: 'hands',
    component: 'NumberInput'
  },
  {
    label: 'Units Used',
    field: 'units_used',
    component: 'NumberInput'
  },
  {
    label: 'Unit',
    field: 'unit',
    component: 'Select',
    options: Object.entries(UNIT).map(([value, label]) => ({
      value,
      label: `${value}: ${label}`
    }))
  },
  {
    label: 'Units Standby',
    field: 'units_standby',
    component: 'NumberInput'
  },
  {
    label: 'Units Min',
    field: 'units_min',
    component: 'NumberInput'
  },
  {
    label: 'Minimum',
    field: 'minimum',
    component: 'NumberInput'
  },
  {
    label: 'Discount',
    field: 'discount',
    component: 'NumberInput'
  },
  {
    label: 'Discountable Amount',
    field: 'discountable_amount',
    component: 'NumberInput'
  },
  {
    label: 'Taxable Amount',
    field: 'taxable_amount',
    component: 'NumberInput'
  },
  {
    label: 'Tax Rate',
    field: 'tax_rate',
    component: 'NumberInput'
  },
  {
    label: 'Barrels',
    field: 'barrels',
    component: 'NumberInput'
  },
  {
    label: 'Notes',
    field: 'notes',
    component: 'TextArea'
  },
  {
    label: 'Amount',
    field: 'amount',
    component: 'NumberInput'
  },
  {
    label: 'Cost',
    field: 'cost',
    component: 'NumberInput'
  },
  {
    label: 'Start Datetime',
    field: 'start_datetime',
    component: 'DatePicker'
  },
  {
    label: 'End Datetime',
    field: 'end_datetime',
    component: 'DatePicker'
  },
  {
    label: 'User Decimal 1',
    field: 'user_decimal_1',
    component: 'NumberInput'
  },
  {
    label: 'User Decimal 2',
    field: 'user_decimal_2',
    component: 'NumberInput'
  },
  {
    label: 'User Decimal 3',
    field: 'user_decimal_3',
    component: 'NumberInput'
  },
  {
    label: 'User Integer 1',
    field: 'user_integer_1',
    component: 'NumberInput'
  },
  {
    label: 'User Integer 2',
    field: 'user_integer_2',
    component: 'NumberInput'
  },
  {
    label: 'User Integer 3',
    field: 'user_integer_3',
    component: 'NumberInput'
  },
  {
    label: 'User Integer 4',
    field: 'user_integer_4',
    component: 'NumberInput'
  },
  {
    label: 'User Text 1',
    field: 'user_text_1',
    component: 'Input'
  },
  {
    label: 'User Text 2',
    field: 'user_text_2',
    component: 'Input'
  },
  {
    label: 'User Text 3',
    field: 'user_text_3',
    component: 'Input'
  },
  {
    label: 'User Text 4',
    field: 'user_text_4',
    component: 'Input'
  },
  {
    label: 'User Text 5',
    field: 'user_text_5',
    component: 'Input'
  },
  {
    label: 'User Text 6',
    field: 'user_text_6',
    component: 'Input'
  },
  {
    label: 'User Text 7',
    field: 'user_text_7',
    component: 'Input'
  },
  {
    label: 'User Text 8',
    field: 'user_text_8',
    component: 'Input'
  },
  {
    label: 'User Long Text 1',
    field: 'user_long_text_1',
    component: 'TextArea'
  },
  {
    label: 'User Datetime 1',
    field: 'user_datetime_1',
    component: 'DatePicker'
  },
  {
    label: 'User Datetime 2',
    field: 'user_datetime_2',
    component: 'DatePicker'
  },
  {
    label: 'Group Subtotal',
    field: 'group_subtotal',
    component: 'Input'
  },
  {
    label: 'Status',
    field: 'status',
    component: 'Select',
    options: Object.entries(TICKET_STATUS).map(([value, label]) => ({
      value,
      label
    }))
  },
  {
    label: 'Assignee ID',
    field: 'assignee',
    component: 'Input'
  },
  {
    label: 'Created At',
    field: 'created_at',
    component: 'Input'
  },
  {
    label: 'Created By ID',
    field: 'created_by',
    component: 'Input'
  }
] as const

export const CATEGORY_KIND_FIELDS = [
  'amount',
  'barrels',
  'cost',
  'description',
  'discount',
  'discountable_amount',
  'tax_rate',
  'taxable_amount',
  'end_datetime',
  'minimum',
  'sku',
  'notes',
  'standby_rate',
  'start_datetime',
  'unit',
  'units_min',
  'units_standby',
  'hands',
  'units_used',
  'usage_rate',
  'status',
  'assignee',
  'user_decimal_1',
  'user_decimal_2',
  'user_decimal_3',
  'user_integer_1',
  'user_integer_2',
  'user_integer_3',
  'user_integer_4',
  'user_text_1',
  'user_text_2',
  'user_text_3',
  'user_text_4',
  'user_text_5',
  'user_text_6',
  'user_text_7',
  'user_text_8',
  'user_long_text_1',
  'user_datetime_1',
  'user_datetime_2'
] as const

type CategoryKindField = (typeof CATEGORY_KIND_FIELDS)[number]

export const CATEGORY_KIND_FIELDS_ENUM = CATEGORY_KIND_FIELDS.reduce((acc: any, field: any) => {
  acc[field] = field
  return acc
}, {}) as Record<CategoryKindField, CategoryKindField>

export const CONTACT_ROLE = {
  FAR: 'Finance / AR',
  FAP: 'Finance / AP',
  S: 'Sales',
  O: 'Operations',
  P: 'President',
  '-': 'Other'
} as const

export const COMPANY_KIND = {
  S: 'Service',
  O: 'Operator',
  V: 'Vendor',
  '-': 'Other'
} as const

export const MODULE_TYPES = {
  C: 'Create Ticket',
  B: 'Dashboard',
  D: 'Documents',
  RM: 'Resource Manager',
  JM: 'Job Manager',
  SD: 'Scheduler',
  J: 'Jobs',
  K: 'Kanban Board',
  I: 'Line Items',
  L: 'Tickets By Stage',
  TC: 'Tickets Calendar',
  MT: 'My Time',
  P: 'Payment Management',
  R: 'Reports',
  S: 'Summary Wizard',
  M: 'Tickets Map',
  T: 'Tickets',
  TM: 'Time Manager',
  NS: 'NetSuite'
} as const

export const TABLE_VIEW_TYPES = {
  J: 'Jobs',
  I: 'Line Items',
  L: 'Tickets by Stage',
  T: 'Tickets',
  M: 'Tickets Map',
  JM: 'Job Manager'
} as const

export const API_OPERATORS = {
  iexact: 'Equals',
  icontains: 'Contains',
  in: 'in (comma separated 1,2,3)',
  gt: 'Greater than',
  gte: 'Greater than equals',
  lt: 'Less than',
  lte: 'Less than equals',
  istartswith: 'Starts with',
  iendswith: 'Ends with',
  range: 'Range',
  isnull: 'Is null'
} as const

export const DEFAULT_TABLE_VIEW_FIELDS = [
  'customer_office__name',
  'location__name',
  'computed_status',
  'timepoint_due',
  'support_office__name',
  'modified_at'
] as const

export const TRANSFORMATION_LOG_STATUS = {
  P: 'Pending',
  I: 'In Progress',
  S: 'Success',
  F: 'Failed',
  A: 'Aborted',
  C: 'Ready'
} as const

export const ALLOWED_OPERATORS_BY_TYPE = {
  text: ['iexact', 'icontains', 'in', 'istartswith', 'iendswith', 'isnull'],
  number: ['iexact', 'in', 'gt', 'gte', 'lt', 'lte', 'range', 'isnull'],
  date: ['gt', 'gte', 'lt', 'lte', 'range', 'isnull']
} as const

export const SUPPORTED_IMPORTS = {
  A: 'Assets',
  AD: 'Assets - Delivery Location',
  C: 'Categories',
  M: 'Company',
  N: 'Contact',
  P: 'Customer Pricing',
  O: 'Customer Offices',
  CR: 'Custom Records',
  S: 'SKUs',
  I: 'Line Items',
  L: 'Locations'
} as const

export const INDICATOR_DATE_DELTAS = {
  T: 'Today',
  M: 'Last 30 Days',
  C: 'Custom'
} as const

export const INVOICE_TYPE = {
  B: 'bill',
  I: 'invoice'
} as const

export const INVOICE_STATUS = {
  E: 'Error',
  e: 'Error',
  O: 'OK',
  o: 'OK',
  s: 'OK'
} as const

export const NOTIFICATION_TEMPLATE_TYPE = {
  E: 'Email',
  S: 'SMS'
} as const

export const NOTIFICATION_TEMPLATE_CONTENT_BASE = {
  P: 'Plain Text',
  B: 'Branding'
} as const

export type NotificationTemplateType = keyof typeof NOTIFICATION_TEMPLATE_TYPE

export const NOTIFICATION_TEMPLATE_STATUS = {
  D: 'Draft',
  P: 'Published'
} as const

export type NotificationTemplateStatus = keyof typeof NOTIFICATION_TEMPLATE_STATUS

export const ALERT_TYPE = {
  I: 'Info',
  W: 'Warning',
  E: 'Error',
  S: 'Success'
} as const

export type AlertType = keyof typeof ALERT_TYPE

export const ALERT_TRIGGER_TYPE = {
  S: 'Script',
  B: 'BPA Condition'
} as const

export type AlertTriggerType = keyof typeof ALERT_TRIGGER_TYPE

export const PROGRAMMED_REPORT_TYPES = {
  R: 'Legacy',
  B: 'Base',
  E: 'ETL'
} as const

export const ROOT_MODEL_NAME = {
  'line item': 'line item',
  ticket: 'ticket'
} as const

export type RootModelNameType = keyof typeof ROOT_MODEL_NAME

export const TICKET_SUBSCRIBER_STATUS = {
  I: 'Invited',
  A: 'Accepted',
  R: 'Rejected',
  D: 'Added'
} as const

export type TicketSubscriberStatus = keyof typeof TICKET_SUBSCRIBER_STATUS

export const SIGNATURE_STYLES = {
  K: 'Kristi',
  C: 'Calligraffitti',
  I: 'Indie Flower',
  L: 'La Belle Aurore'
} as const
