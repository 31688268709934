import { SortAscendingOutlined } from '@ant-design/icons'

type Props = {
  onClick?: () => void
}

export const TableTitleHeadCell = ({ onClick }: Props) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="cursor-pointer flex gap-x-6" onClick={onClick}>
        <span>Employees</span>
        <SortAscendingOutlined />
      </div>
    </div>
  )
}
