import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { Select } from 'antd'

type Props = {
  type?: 'Employee' | 'Equipment'
  value: number[]
  onChange?: (value: number[]) => void
}

export const JobRoleSelect = ({ type, onChange, value }: Props) => {
  const { isLoading, jobRoles } = useJobRoles()

  const options = jobRoles
    .filter((role) => !type || role.resource_type === type)
    .map((role) => ({ label: role.name, value: role.id }))

  const handleChange = (value: number[]) => {
    onChange?.(value)
  }

  return (
    <div>
      <Select
        mode={'multiple'}
        placeholder="Job Role"
        loading={isLoading}
        className="min-w-[100px]"
        allowClear
        options={options}
        onChange={handleChange}
        value={value as any}
        popupMatchSelectWidth={false}
      />
    </div>
  )
}
