import { Form, Input } from 'antd'

export const ModuleTypeDashboardFields = () => {
  return (
    <div>
      <h5 className="font-bold">Dashboard Config</h5>
      <Form.Item label="Dashboard Embed ID" name={['data', 'dashboard_embed_id']}>
        <Input placeholder={'Enter a dashboard embed id provided by the developer or data team.'} />
      </Form.Item>
    </div>
  )
}
