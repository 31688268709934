import { useApp, useSession } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { categoryKindApi } from '@/services/api-service'
import { BackButton, SaveButton } from '@/ui'
import { safeJsonParse } from '@/utils'
import { Form, Space, Switch } from 'antd'
import { useSetAtom } from 'jotai'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RECORDS_FORM_VIEW_HEADER } from '../../../constants'
import { companyFieldAliasesAtom, configurationAtom, initialConfigurationAtom, lineItemFieldsAtom } from './atoms'
import { CodeBehind } from './code-behind'
import { ConfigurationForm } from './configuration-form'
import { LINE_ITEM_FIELDS } from './constants'
import { DragAndDropFields } from './drag-and-drop-fields'
import { useConfigurationQuery, useUpsertConfiguration } from './hooks'
import { IncludeLineItems } from './include-line-items'
import { SearchInput } from './search-input'

export const CategoryKindFormView = view<any, any>(Component, {
  title: ({ data }) => data?.title,
  loader: async ({ params }) => {
    const categoryKindId = Number(params.id)

    // add mode
    if (!categoryKindId) return { mode: 'add', title: 'Add', categoryKind: {} }

    // edit mode
    const categoryKind = await fetchQuery(categoryKindApi.get<any>(categoryKindId, undefined, { fields: 'id,name' }))
    return { mode: 'edit', title: `Edit ${categoryKind.name}`, categoryKind }
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { l } = useApp()
  const form = Form.useFormInstance()

  const { id } = useParams<{ id: string }>()
  const { company } = useSession()
  const configurationQuery = useConfigurationQuery()
  const setInitialConfiguration = useSetAtom(initialConfigurationAtom)
  const setConfiguration = useSetAtom(configurationAtom)
  const [isCodeBehind, setIsCodeBehind] = useState(false)
  const setLineItemFields = useSetAtom(lineItemFieldsAtom)
  const { upsertConfiguration, updateConfigurationMutation, createConfigurationMutation } = useUpsertConfiguration()
  const setCompanyFieldAliases = useSetAtom(companyFieldAliasesAtom)

  const handleSave = async () => {
    const { kind, name, type } = await form.validateFields()
    return await upsertConfiguration({ name, kind, type })
  }

  // Set company field aliases
  useEffect(() => {
    setCompanyFieldAliases(
      typeof company?.field_aliases === 'string' ? safeJsonParse(company.field_aliases, {}) : company?.field_aliases
    )
  }, [company.field_aliases, setCompanyFieldAliases])

  // Set initial configuration and configuration to the initially fetched configuration
  useEffect(() => {
    if (configurationQuery.data) {
      const configuration = cloneDeep(configurationQuery.data)
      setConfiguration(configuration)
      setInitialConfiguration(configuration)
      // Set line item fields to drag and drop fields
      setLineItemFields({
        available: LINE_ITEM_FIELDS.filter((field) => !configuration.fields.includes(field)),
        selected: configuration.fields
      })
    }
  }, [configurationQuery.data, setConfiguration, setInitialConfiguration, setLineItemFields])

  return (
    <FormView
      loading={configurationQuery.isLoading}
      header={{
        ...RECORDS_FORM_VIEW_HEADER,
        title: id ? `Edit ${l('CategoryKind')}` : `Add ${l('CategoryKind')}`,
        backButton: <BackButton data-cy={'configuration-back-button'} />,
        accent: false,
        border: false,
        actions: (
          <SaveButton
            loading={updateConfigurationMutation.isLoading || createConfigurationMutation.isLoading}
            onSave={handleSave}
            data-cy="configuration-save-button"
            returnButtonProps={{ 'data-cy': 'configuration-save-and-return-button' }}
          />
        )
      }}
    >
      <div>
        <ConfigurationForm form={form} />
      </div>
      <div className="flex justify-between mb-10">
        <SearchInput />
        <Space>
          <IncludeLineItems />
          <Space>
            <Switch onChange={(checked) => setIsCodeBehind(checked)} data-cy="configuration-code-behind-switch" />
            <span>Code behind</span>
          </Space>
        </Space>
      </div>
      <div>{isCodeBehind ? <CodeBehind /> : <DragAndDropFields />}</div>
    </FormView>
  )
}
