import { isEqualWith } from 'lodash'

/**
 * Checks if a value is blank (empty string, undefined, or null).
 */
export function isBlank(value: unknown): boolean {
  return value === '' || value === undefined || value === null
}

/**
 * Checks if a value is falsy, considering blank values as falsy.
 */
export function isFalsy(value: unknown): boolean {
  return isBlank(value) || value === false || value === 0 || value === '0' || value === 'false'
}

/**
 * Checks if a value is truthy, negating the result of isFalsy.
 */
export function isTruthy(value: unknown): boolean {
  return !isFalsy(value)
}

/**
 * Custom equality check with special handling for blank values.
 * Blank values are treated as undefined for comparison.
 */
export function isEqual(value1: unknown, value2: unknown): boolean {
  return isEqualWith(value1, value2, (v1, v2) => {
    // important! treat blank values as undefined for comparison
    // so that Number(undefined) give NaN
    const normalizedV1 = isBlank(v1) ? undefined : v1
    const normalizedV2 = isBlank(v2) ? undefined : v2
    if (Number(normalizedV1) === Number(normalizedV2)) return true

    // fallback to default lodash comparison
    return undefined
  })
}
