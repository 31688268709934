import { categoryKindApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { CategoryKind } from '../schema/category-kind-schema'

export const useCategoryKindQuery = (kind?: number | null) => {
  const categoryKindQuery = useQuery({
    ...categoryKindApi.get<CategoryKind>(kind || NaN),
    enabled: !!kind
  })

  const { fields = [] } = (categoryKindQuery.data ?? {}) as { fields: string[] }

  return {
    fields,
    categoryKindQuery: {
      ...categoryKindQuery,
      isLoading: categoryKindQuery.isLoading && categoryKindQuery.fetchStatus !== 'idle'
    }
  }
}
