import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { StateSelect } from '@/components/state-select'
import { useApp, useSession } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery, refreshQueries } from '@/query'
import { view } from '@/routing'
import { eventApi, locationApi } from '@/services/api-service'
import { BackButton, SaveButton } from '@/ui'
import { safeJsonParse } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { omit, startCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { RECORDS_FORM_VIEW_HEADER } from '../../../constants'
import { RelationCompanySelect } from '../../components'
import { LocationForm, LocationSchema } from './schemas'
import { validatedLat, validatedLng } from './validation'

export const LocationFormView = view<any, LocationForm>(Component, {
  title: ({ data }) => data?.title || '',
  loader: async ({ params }) => {
    const locationId = Number(params.id)

    // add mode
    if (!locationId) return { mode: 'add', title: 'Add', location: {} }

    // edit mode
    const location = await fetchQuery(locationApi.get(locationId, undefined, { fields: LocationSchema }))
    let _virtualFields = safeJsonParse(location.virtual_fields) || {}
    _virtualFields = typeof _virtualFields === 'object' ? _virtualFields : {}
    return { mode: 'edit', title: `Edit ${location.name}`, location: { ...location, _virtualFields } }
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { id: locationId } = useParams()
  const { l, notification } = useApp()
  const { company } = useSession()

  const form = Form.useFormInstance<LocationForm>()

  const mutation = useMutation(locationId ? locationApi.patch() : locationApi.create())

  const save = async () => {
    try {
      const { location } = await form.validateFields()
      const data = {
        ...omit(location, ['_virtualFields', 'company_id', 'producer_id', 'region_id']),
        company: location.company_id || company.id,
        producer: location?.producer_id,
        virtual_fields: location._virtualFields
      }

      try {
        const result = await mutation.mutateAsync(data)
        notification.success({ message: 'Success', description: 'Location successfully saved' })
        refreshQueries(['events'])
        return data.id ? undefined : `../${result.id}/edit`
      } catch (error) {
        notification.error({ message: 'Failed', description: 'Failed to save location' })
      }
    } catch (error) {
      notification.warning({ message: 'Warning', description: 'Please check input with validation error' })
    }
  }

  return (
    <FormView
      header={{
        ...RECORDS_FORM_VIEW_HEADER,
        backButton: <BackButton data-cy={'location-back-button'} />,
        title: `${startCase(form.getFieldValue('mode'))} ${l('Location')}`,
        actions: [
          <SaveButton
            key="save"
            loading={mutation.isLoading}
            onSave={save}
            data-cy={'save-location-button'}
            returnButtonProps={{ 'data-cy': 'save-and-return-location-button' }}
          />
        ]
      }}
    >
      <Form.Item name={['location', 'id']} label={`${l('Location')} Name`} hidden>
        <Input />
      </Form.Item>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
        <Form.Item name={['location', 'name']} label={`${l('Location')} Name`} rules={[{ required: true }]}>
          <Input data-cy="location-name" />
        </Form.Item>
        <Form.Item name={['location', 'company_id']} label="Owner">
          <RelationCompanySelect data-cy="location-owner" />
        </Form.Item>
        <Form.Item name={['location', 'producer_id']} label="Producer">
          <RelationCompanySelect data-cy="location-producer" />
        </Form.Item>
      </div>

      <div className={'px-4'}>
        <h5>Doc Information</h5>
        <div className={'grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10'}>
          <Form.Item name={['location', '_virtualFields', 'lease_number']} label="Lease Number">
            <Input data-cy="location-lease-number" />
          </Form.Item>
          <Form.Item name={['location', '_virtualFields', 'field']} label="Field">
            <Input data-cy="location-field" />
          </Form.Item>
          <Form.Item name={['location', '_virtualFields', 'field_operator']} label="Field Operator">
            <Input data-cy="location-field-operator" />
          </Form.Item>
        </div>

        <h5>Location</h5>
        <div className={'grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10'}>
          <Form.Item name={['location', '_virtualFields', 'legal_location']} label="Legal Location">
            <Input data-cy="location-legal-location" />
          </Form.Item>
          <Form.Item name={['location', '_virtualFields', 'county']} label="County">
            <Input data-cy="location-county" />
          </Form.Item>
          <Form.Item name={['location', '_virtualFields', 'state']} label="State">
            <StateSelect valueKey={'name'} data-cy="location-state" />
          </Form.Item>
          <Form.Item name={['location', 'external_id']} label="External ID">
            <Input data-cy="location-external-id" />
          </Form.Item>
        </div>

        <h5>Coordinates</h5>
        <div className={'grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10'}>
          <Form.Item name={['location', '_virtualFields', 'federal_indian_lsc_number']} label="Federal/Indian Lsc.">
            <Input data-cy="location-federal-indian-lsc-number" />
          </Form.Item>
          <Form.Item name={['location', 'gps_latitude']} label="Latitude" rules={[{ validator: validatedLat }]}>
            <Input data-cy="location-latitude" />
          </Form.Item>
          <Form.Item name={['location', 'gps_longitude']} label="Longitude" rules={[{ validator: validatedLng }]}>
            <Input data-cy="location-longitude" />
          </Form.Item>
        </div>

        <h5>Notes</h5>
        <div>
          <Form.Item name={['location', 'legal_description']} label="Description">
            <Input.TextArea data-cy="location-description" />
          </Form.Item>

          <Form.Item name={['location', 'driving_directions']} label="Driving Directions">
            <Input.TextArea data-cy="location-driving-directions" />
          </Form.Item>
        </div>

        <h5>Custom Fields</h5>
        <div className={'grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10'}>
          <Form.Item name={['location', 'user_text_1']} label={l('location__user_text_1')}>
            <Input data-cy="location-user-text-1" />
          </Form.Item>
          <Form.Item name={['location', 'user_text_2']} label={l('location__user_text_2')}>
            <Input data-cy="location-user-text-2" />
          </Form.Item>
          <Form.Item name={['location', 'user_text_3']} label={l('location__user_text_3')}>
            <Input data-cy="location-user-text-3" />
          </Form.Item>
        </div>
      </div>

      {locationId && (
        <div className="mt-30">
          <h5 className="font-bold">Recent Activity</h5>
          <ActivityLogsModal query={eventApi.list} queryVariables={{ type: 'location', target: locationId }} />
          <div className="mt-16">
            <RecentActivities params={{ type: 'location', target: locationId }} />
          </div>
        </div>
      )}
    </FormView>
  )
}
