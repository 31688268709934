import { Select } from '@/ui'
import { Form, Input } from 'antd'
import { useTransformationTaskTabsOptions } from '../../../../hooks'

export const NameTab = () => {
  const tabsOptions = useTransformationTaskTabsOptions()

  return (
    <>
      <Form.Item name={['params', 'tab_name']} label="Tab">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item name={['params', 'new_name']} label="Name" className="mb-0">
        <Input />
      </Form.Item>
    </>
  )
}
