import { SearchInput } from '@/ui'
import { CopyButton } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Collapse, Popover, Skeleton } from 'antd'
import cn from 'classnames'
import { memo, useMemo, useState } from 'react'
import styles from './FormatPicker.module.scss'
import { Fields, FormatPickerProps } from './types'

export const FormatPicker = ({ title, searchedFields, isLoading, isCollapsable }: FormatPickerProps) => {
  const [searchText, setSearchText] = useState('')

  const allFields = useMemo(() => {
    if (!searchedFields || searchedFields?.length === 0) return []
    if (!searchText) return searchedFields
    const searchTextTrimmed = searchText.trim().toLowerCase()

    return searchedFields.map((item) => ({
      ...item,
      // hiding using css is efficient than filtering
      // the array and re-rendering the whole list
      hide: !(
        item.isHeader ||
        item.title.toLowerCase().includes(searchTextTrimmed) ||
        item.text.toLowerCase().includes(searchTextTrimmed)
      )
    }))
  }, [searchText, searchedFields])

  const collapseItems = useMemo(() => {
    if (!isCollapsable) {
      return []
    }

    const result: any[] = []
    let currentChildren: any[] = []

    allFields.forEach((item, index) => {
      if (item.hide) {
        return
      }

      if (item.isHeader) {
        currentChildren = []

        result.push({
          key: index.toString(),
          label: item.title,
          children: []
        })
      } else {
        currentChildren.push({
          key: item.key,
          title: item.title,
          text: item.text
        })

        const lastItem = result[result.length - 1]
        lastItem.children = currentChildren
      }
    })

    return result
  }, [isCollapsable, allFields])

  return (
    <div
      className={cn('px-10 h-full flex flex-col', {
        [styles.FormatPicker]: isCollapsable
      })}
    >
      <h5>
        <Icon name={'fa:brackets'} className={'mr-4'} /> {title || 'Format Picker'}
      </h5>
      <SearchInput
        data-cy="format-picker-search-input"
        className="!min-w-0 md:!min-w-0"
        placeholder={'Search'}
        onChange={(e) => setSearchText(e.target.value.trim())}
      />
      {isLoading ? (
        <Skeleton />
      ) : isCollapsable ? (
        <Collapse className="mt-16" expandIconPosition="end">
          {collapseItems.map((item) => (
            <Collapse.Panel
              key={item.key}
              header={
                <strong>
                  <Icon name={'fa:hashtag'} className={'mr-4'} /> {item.label}
                </strong>
              }
            >
              {item?.children?.map((child: any) => (
                <MemoFormatPickerItem key={child.key} {...child} />
              ))}
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        <div className={'flex-grow overflow-x-auto border px-10 mt-10 rounded'}>
          {allFields.map((item) => (
            <MemoFormatPickerItem {...item} key={item.key} />
          ))}
        </div>
      )}
    </div>
  )
}

const MemoFormatPickerItem = memo(({ hide, title, text, isHeader }: Fields) => {
  return (
    <div
      className={cn('flex flex-row items-center gap-x-8 border-b py-4', {
        hidden: hide
      })}
    >
      {isHeader ? (
        <>
          <strong>
            <Icon name={'fa:hashtag'} className={'mr-4'} /> {title}
          </strong>
        </>
      ) : (
        <>
          <CopyButton
            data-cy="format-picker-copy-button"
            copyText={text}
            size={'small'}
            shape={'circle'}
            icon={<Icon name={'fa:copy'} />}
          />
          <div className={'flex-grow'}>
            <Popover content={title} mouseEnterDelay={0.5}>
              <small className={'line-clamp-1 text-gray-400'}>{title}</small>
              <span className={'line-clamp-1'}>{text}</span>
            </Popover>
          </div>
        </>
      )}
    </div>
  )
})

MemoFormatPickerItem.displayName = 'MemoFormatPickerItem'
