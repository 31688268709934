type Props = {
  children: React.ReactNode
  extra?: React.ReactNode
  rounded?: boolean
  number?: number
}

export const SectionTitle = ({ children, extra, rounded, number }: Props) => {
  if (rounded) {
    return (
      <div className="flex items-center gap-x-12">
        <div className="inline-flex w-34 h-34 rounded-full justify-center items-center bg-black text-white">
          {number}
        </div>
        <div className="font-bold">{children}</div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-between w-full bg-background-accent h-44 px-10">
      <span className="text-16 font-bold">{children}</span>
      <div>{extra}</div>
    </div>
  )
}
