import { Collapse } from '@/ui'
import { UseSortable } from '@/ui/sortable'
import { cn } from '@/utils'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import React, { FC, useMemo } from 'react'
import { bpaConfigAtom } from '../../atoms'
import { useRulesHandlers } from '../../hooks'
import { RuleForm } from './RuleForm'
import { RuleHeader } from './RuleHeader'

type Props = {
  ruleUuid: string
  ruleIndex: number
  sortable?: UseSortable
}

export const RulesCollapse: FC<Props> = ({ ruleUuid, ruleIndex, sortable }) => {
  const rule = useAtomValue(
    useMemo(() => selectAtom(bpaConfigAtom, (config) => config.rules.find((r) => r.uuid === ruleUuid)), [ruleUuid])
  )

  const { onRuleExpandedChange } = useRulesHandlers()

  if (!rule) return <></>

  return (
    <div
      className={cn('rounded-lg border border-transparent', {
        'border-dashed shadow-primary !border-primary-500': sortable?.isDragging
      })}
      ref={sortable?.setNodeRef}
      style={sortable?.style}
      {...sortable?.attributes}
    >
      <Collapse
        destroyInactivePanel={true}
        expandIconPosition="start"
        data-cy="rule-collapse"
        activeKey={rule.expanded ? ['1'] : []}
        onChange={(keys) => onRuleExpandedChange(keys.length > 0, ruleIndex)}
        items={[
          {
            key: '1',
            label: <RuleHeader ruleIndex={ruleIndex} rule={rule} sortable={sortable} />,
            children: <RuleFormMemo ruleIndex={ruleIndex} />
          }
        ]}
      />
    </div>
  )
}

const RuleFormMemo = React.memo(RuleForm)
