import { useCustomFormLabels } from '@/modules/custom-form/hooks'
import { Select } from '@/ui'
import { OptionHelp } from '@/ui/select/option-help'
import { Checkbox, Form, Input } from 'antd'
import { useAtomValue } from 'jotai'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { customFormAtom } from '../../atoms'
import { TICKET_USER_DATA_FIELDS } from '../../constants'
import { BpaActionSetHiddenReference } from '../../schemas'

type Props = {
  action: BpaActionSetHiddenReference
  onChange: (data: Partial<BpaActionSetHiddenReference>) => void
}

export const InputSetHiddenReference: FC<Props> = (props) => {
  const customForm = useAtomValue(customFormAtom)
  const { l } = useCustomFormLabels(customForm?.id)

  const { action, onChange } = props

  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Type" className="flex-grow">
          <Select
            data-cy="condition-field-select"
            defaultValue={action.field}
            filterBy={'text'}
            options={TICKET_USER_DATA_FIELDS.map((f) => ({
              value: f.value,
              text: l(`ticket__user_data__${f.value}__label`) + ' ' + f.label,
              label: (
                <OptionHelp
                  label={l(`ticket__user_data__${f.value}__label`, f.label)}
                  tipTitle={`Default: ${f.label}`}
                />
              )
            }))}
            onChange={(value) => onChange({ field: value, value: null, searchText: '' })}
          />
        </Form.Item>
        <div className="flex flex-row justify-center items-center">
          <Form.Item label="Clear" tooltip="Same as set null or remove value.">
            <Checkbox
              checked={action.setNull}
              onChange={(e: any) => {
                onChange({ value: null, setNull: e.target.checked })
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]">
          {action.setNull ? (
            <Input disabled />
          ) : (
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={action.value || ''}
              onChange={(e) => onChangeDebounced({ value: e.target.value })}
            />
          )}
        </Form.Item>
      </div>
    </div>
  )
}
export const InputSetHiddenReferenceMemo = React.memo(InputSetHiddenReference)
