import { CodeEditor } from '@/components/code-editor'
import { ALERT_TRIGGER_TYPE, ALERT_TYPE, type NotificationTemplateType } from '@/constants/general'
import { useApp } from '@/hooks'
// Refactor: Move to a global components folder
import { ConditionsCard } from '@/modules/settings/custom-forms/custom-form-layout-view/property-groups/edit-property-modal/bpa-builder/components'
import { TICKET_STATUS } from '@/modules/ticket/constants'
import { notificationTemplateApi } from '@/services/api-service'
import { Modal, Select } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Input, ModalProps } from 'antd'
import { Provider, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../../atoms'
import { useCustomFormQuery } from '../../../../hooks'
import { Alert } from '../../../../types'
import { useUpsertAlert } from '../hooks'

type Props = {
  alert: Alert | null
} & ModalProps

export const AddEditModal = ({ alert, onOk, onCancel, ...props }: Props) => {
  const [form] = Form.useForm()

  const notificationTemplatesQuery = useQuery(
    notificationTemplateApi.list({
      status__eq: 'P'
    })
  )

  const { upsertAlert } = useUpsertAlert()
  const customFormId = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.id), []))
  const printStyles = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.print_styles), [])) || []
  const primaryCompanyId = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (customForm) => customForm.primary_company), [])
  )
  const { notification } = useApp()
  const setCustomForm = useSetAtom(setCustomFormAtom)
  const alerts = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.alerts), [])) || []
  const triggerType = Form.useWatch('trigger_type', form)
  const attachTicket = Form.useWatch(['data', 'attach_ticket'], form)
  const { customFormQuery } = useCustomFormQuery()

  const notificationTemplates = useMemo(
    () =>
      notificationTemplatesQuery.data?.items.reduce(
        (acc, { type, id, name }: { id: number; name: string; type: NotificationTemplateType }) => {
          return {
            ...acc,
            [type]: [...(acc[type] || []), { label: name, value: id }]
          }
        },
        {} as Record<string, any[]>
      ) || {},
    [notificationTemplatesQuery.data?.items]
  ) as Record<NotificationTemplateType, any[]>

  const handleSave = async () => {
    try {
      if (!customFormId) {
        notification.error({ message: 'Custom form not found', description: 'Please create a custom form first' })
        return
      }

      const { ticket_statuses, ...formValues } = await form.validateFields()

      // Subscribe primary company to alert by default
      if (!alert?.id && primaryCompanyId) {
        formValues['company_ids'] = [primaryCompanyId]
      }

      const result = await upsertAlert({
        ...formValues,
        ticket_statuses: ticket_statuses?.join(',')
      })

      if (!alert) {
        setCustomForm({
          alerts: [
            ...alerts,
            {
              ...result,
              ticket_statuses: result.ticket_statuses?.split(',')
            }
          ]
        })
      } else {
        setCustomForm({
          alerts: alerts.map((a) => {
            if (a.id === alert.id) {
              return {
                ...a,
                ...result,
                ticket_statuses: result.ticket_statuses?.split(',')
              }
            }

            return a
          })
        })
      }

      notification.success({ message: !alert ? 'Notification created' : 'Notification updated' })
      customFormQuery.refetch()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      open
      title={alert ? 'Edit Notification Rule' : 'Add Notification Rule'}
      okText="Save"
      withScreenMaxHeight
      width={800}
      onOk={async (e) => {
        await handleSave()
        onCancel?.(e)
      }}
      onCancel={onCancel}
      {...props}
    >
      <Provider>
        <Form
          layout="vertical"
          form={form}
          initialValues={alert ? alert : { ...initialFormValues, custom_form: customFormId }}
        >
          <div className="flex gap-x-16">
            <Form.Item hidden name="id" />
            <Form.Item hidden name="custom_form" />
            <Form.Item name="name" label="Rule Name" rules={[{ required: true }]} className="grow">
              <Input />
            </Form.Item>
          </div>
          <Form.Item label="Alert Type" name="alert_type">
            <Select
              options={Object.entries(ALERT_TYPE).map(([value, label]) => ({
                value,
                label
              }))}
            />
          </Form.Item>
          <div className="flex gap-x-16">
            <Form.Item label="Email Template" className="grow" name="email_notification_template">
              <Select options={notificationTemplates.E} />
            </Form.Item>
            <Form.Item label="SMS Template" className="grow" name="sms_template">
              <Select options={notificationTemplates.S} />
            </Form.Item>
          </div>
          <Form.Item label="Ticket Status" name="ticket_statuses">
            <Select
              mode="multiple"
              options={Object.entries(TICKET_STATUS).map(([value, label]) => ({
                value,
                label
              }))}
            />
          </Form.Item>
          <Form.Item name="available_to_subscribers" valuePropName="checked">
            <Checkbox>Available to Subscribers</Checkbox>
          </Form.Item>
          <div className="flex gap-x-12">
            <Form.Item name={['data', 'attach_ticket']} valuePropName="checked">
              <Checkbox>Attach Ticket</Checkbox>
            </Form.Item>
            {attachTicket && (
              <Form.Item name={['data', 'print_styles']}>
                <Select
                  placeholder="Print Styles"
                  defaultValue="Default"
                  options={printStyles?.map((value: any) => ({
                    value,
                    label: value.slug
                  }))}
                />
              </Form.Item>
            )}
          </div>

          <Form.Item label="Trigger Type" name="trigger_type">
            <Select
              options={Object.entries(ALERT_TRIGGER_TYPE).map(([value, label]) => ({
                value,
                label
              }))}
            />
          </Form.Item>
          {triggerType === 'S' && (
            <Form.Item name="script" label="Script">
              <CodeEditor mode="javascript" />
            </Form.Item>
          )}
          {triggerType === 'B' && (
            <Form.Item name="conditions" label="Conditions">
              <ConditionsCard
                initialConditions={alert?.conditions}
                onChange={(conditions) => {
                  form.setFieldsValue({
                    conditions
                  })
                }}
              />
            </Form.Item>
          )}
        </Form>
      </Provider>
    </Modal>
  )
}

const initialFormValues = {
  name: '',
  description: '',
  trigger_type: 'B',
  script: '// Default script \n\ntrue;',
  alert_type: 'I'
}
