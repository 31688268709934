import { makeMap } from '@/utils'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { DEFAULT_KIND } from '../constants'
import { Category, CategoryKind, LineItem, LineItemData, LineItemsStats } from '../schemas'
import { formatCategoryLabel, getCategoryKindId } from './category'

const _categoriesCache: { input?: LineItemsStats; output?: Category[] } = {}

export const transformCategories = (stats: LineItemsStats) => {
  // check cache
  if (_categoriesCache.input === stats) {
    return _categoriesCache.output || []
  }

  // process kinds
  const categoryKindsMap: Record<number, CategoryKind> = makeMap(stats.category_kinds, 'id')
  categoryKindsMap[0] = DEFAULT_KIND

  // process amounts
  const amountByCategory: Record<number, { amount: number }> = makeMap(stats.amount_by_category, 'category_id')

  // process counts
  const countByCategory: Record<number, { count: number }> = makeMap(stats.count_by_category, 'category_id')

  // process categories
  const categories: Category[] = []
  if (!isEmpty(stats.categories)) {
    // craft additional properties
    stats.categories.forEach((c) => {
      categories.push({
        ...c,
        _label: formatCategoryLabel(c),
        _kind: categoryKindsMap[getCategoryKindId(c)] || DEFAULT_KIND,
        _amount: amountByCategory[c.id]?.amount || 0,
        _count: countByCategory[c.id]?.count || 0
      })
    })
    // sort by label
    categories.sort((a, b) => a._label.localeCompare(b._label))
  }

  // set cache
  _categoriesCache.input = stats
  _categoriesCache.output = categories

  // return
  return categories
}

export const transformLineItem = (lineItem: Partial<LineItem>) => {
  return {
    ...lineItem,
    start_datetime: dayjs.parse(lineItem.start_datetime),
    end_datetime: dayjs.parse(lineItem.end_datetime),
    user_datetime_1: dayjs.parse(lineItem.user_datetime_1),
    user_datetime_2: dayjs.parse(lineItem.user_datetime_2)
  } as LineItemData
}
