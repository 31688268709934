import { Select } from '@/ui'
import { Form, Input } from 'antd'
import { useBaseReportDisplayfieldSetOptions, useTransformationTaskTabsOptions } from '../../../../hooks'

export const Calculation = () => {
  const tabsOptions = useTransformationTaskTabsOptions()
  const displayfieldSetOptions = useBaseReportDisplayfieldSetOptions()

  return (
    <>
      <Form.Item name={['params', 'base_ds']} label="Base DataSet">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item name={['params', 'field1']} label="Field 1" rules={[{ required: true }]}>
        <Select options={displayfieldSetOptions} />
      </Form.Item>
      <Form.Item name={['params', 'func']} label="Function" rules={[{ required: true }]}>
        <Select
          options={Object.entries(MATH_FUNCTIONS).map(([value, label]) => ({
            label,
            value
          }))}
        />
      </Form.Item>
      <Form.Item name={['params', 'field2']} label="Field 2" rules={[{ required: true }]}>
        <Select options={displayfieldSetOptions} />
      </Form.Item>
      <Form.Item name={['params', 'name']} label="Column Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={['params', 'insert_before']} label="Insert Column Before" rules={[{ required: true }]}>
        <Select options={[{ label: 'End of the list', value: null }, ...displayfieldSetOptions]} />
      </Form.Item>
    </>
  )
}

const MATH_FUNCTIONS = {
  sum: 'SUM',
  sub: 'SUBTRACT',
  div: 'DIVIDE',
  mul: 'MULTIPLY',
  avg: 'AVERAGE',
  med: 'MEDIAN',
  min: 'MIN',
  max: 'MAX'
} as const
