import { useAppDispatch, useAppSelector } from '@/hooks'
import { useIsManager } from '@/modules/auth/hooks'
import { getStatusLabels } from '@/modules/custom-form/helpers'
import { CustomForm } from '@/modules/custom-form/types'
import { STATUS_DISPATCHED } from '@/modules/jas/job-manager/constants'
import { processJobTicket } from '@/modules/jas/job-manager/helpers'
import {
  addResourceToJob,
  loadSelectedJobResources,
  refreshAssignedPercent,
  removeResourceFromJob,
  resetSelectedJobData,
  setIsEditing,
  setSelectedJobData
} from '@/modules/jas/job-manager/slice'
import { JobResource, JobResourceSku, JobRole, JobTicket } from '@/modules/jas/job-manager/types'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { JmConfig, Module } from '@/modules/module/types'
import { getTableViewQuery } from '@/modules/table-view/helpers'
import { TableView } from '@/modules/table-view/types'
import { getAdvanceSearchFilterQuery, getSearchFilterQuery } from '@/modules/ticket/helpers'
import { queryClient } from '@/query'
import {
  clientRecordApi,
  customFormApi,
  equipmentApi,
  jasJobTicketApi,
  jasResourceApi,
  tableViewApi,
  ticketApi
} from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { deepMerge, generateAvatarInitials } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { groupBy, isEqual, orderBy, sortBy, toPairs } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { batch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

export const useJobManager = () => {
  return useAppSelector((state) => state.jobManager)
}

export const useJmConfig = (): JmConfig => {
  const { module } = useCurrentModuleQuery()
  const [searchParams, _] = useSearchParams()

  return useMemo(() => {
    const moduleDefault = module?.config?.for_jm || {}
    const urlOverride = Object.fromEntries(
      Array.from(searchParams.entries())
        .filter(([key, value]) => key.endsWith('_pid'))
        .map(([key, value]) => [key, Number(value)])
    )

    return { ...moduleDefault, ...urlOverride }
  }, [module?.config?.for_jm, searchParams]) as JmConfig
}

export const useTableViewsQuery = (module?: Module) => {
  return useQuery({
    ...tableViewApi.list<TableView>({
      'E[]': module?.hidden_views?.length ? [`id__in|${module.hidden_views.join(',')}`] : [],
      include_internal_view_ids: module?.internal_views,
      type__eq: 'jm'
    }),
    enabled: !!module?.id
  })
}

export const useJobTicketProcessor = () => {
  const { jobRoles, jobRolesMap } = useJobRoles()
  const { data: customForm } = useCustomFormQuery()
  const jmConfig = useJmConfig()

  const processor = useCallback(
    (ticket: JobTicket) => processJobTicket(ticket, jobRolesMap, jmConfig, getStatusLabels(customForm)),
    [customForm, jmConfig, jobRolesMap]
  )

  return { processor, jobRoles, jobRolesMap }
}

export const useJobTicketResources = (jobTicket: JobTicket | null) => {
  const _resAll = [
    ...(jobTicket?.jobData.resources.employees.res_items || []),
    ...(jobTicket?.jobData.resources.equipments.res_items || [])
  ]

  return {
    resAll: _resAll,
    resEmployees: jobTicket?.jobData.resources.employees.res_items,
    resEquipments: jobTicket?.jobData.resources.equipments.res_items,
    resSuccess: true,
    resFetching: false
  }
}

/**
 * Fetch all resources for a job ticket
 * @deprecated use useJobTicketResources instead
 * @param jobTicket
 */
export const useFetchJobTicketResources = (jobTicket: JobTicket | null) => {
  // fetch all resources
  let allResSkuIds = [0] // hack: to prevent returning all data when id__in is empty
  if (jobTicket) {
    allResSkuIds = [
      ...allResSkuIds,
      ...jobTicket.jobData.resources.employees.sku_ids,
      ...jobTicket.jobData.resources.equipments.sku_ids
    ]
  }

  const { data, isSuccess, isFetching } = useQuery({
    ...jasResourceApi.list<JobResource>({ equipments__in: allResSkuIds.join(','), distinct: '1' }),
    select: (data) => ({ ...data, items: sortBy(data.items, 'name') }),
    enabled: !!jobTicket
  })

  const [resAll, resEmployees, resEquipments] = useMemo(() => {
    const allRes = isFetching ? [] : data?.items || []

    return [allRes, allRes.filter((res) => res.type === 'Employee'), allRes.filter((res) => res.type === 'Equipment')]
  }, [data?.items, isFetching])

  return {
    resAll,
    resEmployees,
    resEquipments,
    resSuccess: isSuccess,
    resFetching: isFetching
  }
}

export const useCustomFormQuery = () => {
  const config = useJmConfig()
  const formId = config?.custom_form_id
  return useQuery({
    ...customFormApi.get<CustomForm>(Number(formId)),
    enabled: !!formId
  })
}

export const useJobTicketsQuery = () => {
  const isManager = useIsManager()
  const { module } = useCurrentModuleQuery()
  const { isLoading } = useTableViewsQuery(module as Module)
  const { pagination, activeView, search, advanceSearch } = useJobManager()
  const { processor } = useJobTicketProcessor()

  const jmConfig = useJmConfig()
  const requiredPIDs = useMemo(() => {
    return Object.entries(jmConfig)
      .filter(([key, value]) => value && key.endsWith('_pid'))
      .map(([key, value]) => value)
  }, [jmConfig])

  // table view query
  // todo: provide context to support custom filter compilation
  const context = {}
  let query: ListParams = {
    ...{ custom_form_id__eq: jmConfig.custom_form_id, Q: [], E: [] },
    ...(activeView ? getTableViewQuery(activeView, isManager, context) : {})
  }

  // basic search filter query
  query = deepMerge(query, getSearchFilterQuery(search))

  // advance search filter query
  if (advanceSearch) query = deepMerge(query, getAdvanceSearchFilterQuery(advanceSearch))

  // pagination query
  query.page = pagination.page || 1
  query.limit = pagination.pageSize || 50

  // always sort by start date
  query.timepoint_due__isnull = 0 // exclude unscheduled jobs
  query.order = '-timepoint_due'

  // filter nested properties
  query.property_ids = requiredPIDs.join(',')

  // include events
  query.include_events = 1

  // make query spec
  const querySpec = jasJobTicketApi.list<JobTicket>(query)

  // fetch data
  return {
    ...useQuery({
      ...querySpec,
      staleTime: Infinity, // important! since some mutation happens in cache while saving
      cacheTime: Infinity, // important! since some mutation happens in cache while saving
      select: (data) => ({
        items: data.items.map(processor),
        total: data.total,
        query: query
      }),
      enabled: !isLoading
    }),
    key: querySpec.queryKey || [] // useful for mutating local cache,
  }
}

export const useGroupedJobTickets = () => {
  const {
    data: { items: tickets = [], total = 0 } = {},
    isFetching,
    isSuccess,
    isFetched,
    isRefetching
  } = useJobTicketsQuery()

  const groupedTickets = useMemo(() => {
    return sortBy(
      toPairs(groupBy(tickets || [], (ticket) => ticket.jobData.startDateStr)),
      (t) => t[0] && t[0] !== '--/--/----'
    )
  }, [tickets])

  return { groupedTickets, tickets, total, isFetching, isSuccess, isFetched, isRefetching }
}

export const useSelectedJobTicket = () => {
  const dispatch = useAppDispatch()
  const { originalSelectedJobData, selectedJobData, isEditing, lastJobId } = useJobManager()
  const { groupedTickets, tickets, isSuccess, isFetching, isFetched, isRefetching } = useGroupedJobTickets()

  const selectedTicket = useMemo(() => {
    let ticket = tickets.find((ticket) => ticket.id === selectedJobData?.id)

    // if ticket is not found, select the first ticket
    if (!ticket && isSuccess && groupedTickets?.length) {
      ticket = groupedTickets[0][1][0]
    }
    return ticket
  }, [tickets, isSuccess, groupedTickets, selectedJobData?.id])

  // sync redux-state selectedJobData when selected ticket changes
  useEffect(() => {
    if (
      selectedTicket &&
      (selectedTicket?.id !== selectedJobData?.id || selectedTicket?.modified_at !== selectedJobData?.modified_at)
    ) {
      dispatch(setSelectedJobData(selectedTicket.jobData))
    }
  }, [dispatch, selectedTicket, selectedJobData])

  // set selected job ticket data when selected ticket changed
  useEffect(() => {
    if (selectedTicket?.id && lastJobId !== selectedTicket.id) {
      dispatch(loadSelectedJobResources(selectedTicket.jobData.resources))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTicket?.id, lastJobId])

  const setEditing = useCallback(
    (value: boolean) => {
      dispatch(setIsEditing(value))
    },
    [dispatch]
  )

  // resource management
  const addResource = useCallback(
    (type: 'employee' | 'equipment', resource: JobResource) => {
      batch(() => {
        dispatch(addResourceToJob({ type, resource }))
        dispatch(refreshAssignedPercent())
      })
    },
    [dispatch]
  )
  const removeResource = useCallback(
    (type: 'employee' | 'equipment', resource: JobResource) => {
      batch(() => {
        dispatch(removeResourceFromJob({ type, resource }))
        dispatch(refreshAssignedPercent())
      })
    },
    [dispatch]
  )
  const resetJobData = useCallback(() => {
    batch(() => {
      dispatch(resetSelectedJobData())
      dispatch(setIsEditing(false))
    })
  }, [dispatch])

  // change detection
  const isModified: boolean = useMemo(() => {
    if (!originalSelectedJobData?.resources || !selectedJobData?.resources) return false

    const isSameTicket = lastJobId === selectedTicket?.id

    const oEmployeeIds = orderBy(originalSelectedJobData?.resources.employees.sku_ids)
    const cEmployeeIds = orderBy(selectedJobData?.resources.employees.sku_ids)
    const isEmployeeModified = !isEqual(oEmployeeIds, cEmployeeIds)

    const oEquipmentIds = orderBy(originalSelectedJobData?.resources.equipments.sku_ids)
    const cEquipmentIds = orderBy(selectedJobData?.resources.equipments.sku_ids)
    const isEquipmentModified = !isEqual(oEquipmentIds, cEquipmentIds)

    return isSameTicket && (isEmployeeModified || isEquipmentModified)
  }, [lastJobId, selectedTicket?.id, originalSelectedJobData?.resources, selectedJobData?.resources])

  return {
    selectedTicket,
    selectedJobData,
    isSuccess,
    isFetched,
    isFetching,
    isRefetching,
    isEditing: isEditing || false,
    setEditing,
    isModified,
    addResource,
    removeResource,
    resetJobData
  }
}

export const useJobTicketCacheSync = () => {
  const dispatch = useAppDispatch()
  const { processor } = useJobTicketProcessor()
  const { selectedJobData } = useSelectedJobTicket()
  const ticketsQuery = useJobTicketsQuery()

  const sync = useCallback(
    (updatedTicket: JobTicket, refreshJobData = true): JobTicket => {
      // process updated ticket
      const processedTicket = processor(updatedTicket)

      // update cache if there is already a job data
      if (selectedJobData) {
        // preserve local resources
        processedTicket.jobData.resources = selectedJobData?.resources

        queryClient.setQueryData(ticketsQuery.key, (oldData: any) => {
          if (!oldData) return oldData

          const items = (oldData?.items || []).map((t: JobTicket) => {
            if (t.id === updatedTicket.id) return processedTicket
            return t
          })

          return { ...oldData, items }
        })

        // important! refreshing query data clears resources from state
        // it will also update old job data with new job data
        if (refreshJobData) {
          dispatch(setSelectedJobData(processedTicket.jobData))
        }
      }

      return processedTicket
    },
    [dispatch, processor, selectedJobData, ticketsQuery.key]
  )

  return { sync }
}

export const useJobTicketSave = () => {
  const { mutate: patchTicket, isLoading } = useMutation(ticketApi.patch(undefined, undefined, false))
  const { sync: syncTicketCache } = useJobTicketCacheSync()
  const { jobRoles } = useJobRoles()

  const { selectedTicket: jobTicket, selectedJobData: jobData, isModified } = useSelectedJobTicket()

  const saveTicket = useCallback(
    ({
      action,
      onSuccess,
      onError,
      force = false
    }: {
      action: 'dispatchJob' | 'saveResources'
      onSuccess?: () => void
      onError?: () => void
      force?: boolean
    }) => {
      if (!jobTicket || !jobData) return

      const payload: {
        id: number
        status?: string
        ticket_properties?: { property: number; value: string }[]
      } = { id: jobTicket.id }

      if (action === 'dispatchJob') {
        payload.status = STATUS_DISPATCHED
      }

      // save resources when dispatching job if there are changes
      if ((force || isModified) && (action === 'dispatchJob' || action === 'saveResources')) {
        // fixme: assigned percent is always 100%
        // payload.ticket_properties = [{ property: jmConfig.assigned_pct_pid, value: `${jobData.assignedPct}%` }]

        payload.ticket_properties = []

        const allAssignedSkus = groupBy(
          [
            ...jobData.resources.employees.res_items.map((r) => r.assigned_sku),
            ...jobData.resources.equipments.res_items.map((r) => r.assigned_sku)
          ],
          'job_role_id'
        )

        jobRoles.forEach((role) => {
          payload.ticket_properties?.push({
            property: role.property_id,
            value: orderBy(allAssignedSkus[role.id]?.map((sku) => sku?.id).filter((skuId) => skuId)).join(',')
          })
        })
      }

      patchTicket(payload, {
        onSuccess: (newTicket) => {
          syncTicketCache(newTicket, true)

          if (onSuccess) onSuccess()
        },
        onError: onError
      })
    },
    [isModified, jobData, jobRoles, jobTicket, patchTicket, syncTicketCache]
  )

  return { saveTicket, isLoading }
}

export const useJobRoles = () => {
  const { isSuccess, isLoading, data } = useQuery({
    ...clientRecordApi.list<JobRole>({ label__iexact: 'Job Roles' }),
    select: (data) => {
      return {
        ...data,
        items: data.items.map((item: any) => ({
          id: item.id,
          name: item.char_1,
          resource_type: item.char_2,
          property_id: Number(item.char_3),
          job_role_color: item.char_4
        }))
      }
    }
  })

  const [jobRolesMap, jobRoleNames, getJobRoleName] = useMemo(() => {
    const _items = data?.items || []

    const _map = _items.reduce((acc: Record<number, JobRole>, item: JobRole) => {
      acc[item.id] = item
      return acc
    }, {})

    const _names = _items.map((item: JobRole) => item.name)

    const _getName = (id: number | null) => {
      if (!id) return 'N/A'

      return jobRolesMap[id]?.name || 'N/A'
    }

    return [_map, _names, _getName]
  }, [data?.items])

  return {
    isSuccess,
    isLoading,
    jobRoles: data?.items || [],
    jobRolesMap,
    jobRoleNames,
    getJobRoleName
  }
}

export const useEnrichShimResource = (resource: JobResource) => {
  const sku = resource.assigned_sku
  const skuId = sku?.id
  const shouldEnrich = !(resource.isEnriched || false) && (resource.isShim || false) && !!skuId

  const { data, isSuccess, isFetching } = useQuery({
    ...equipmentApi.list<JobResourceSku>({
      with_inactive: true,
      id__exact: skuId,
      fields: 'id,description'
    }),
    enabled: shouldEnrich
  })

  let eResource = resource
  if (shouldEnrich && isSuccess && data?.items?.length) {
    const assignedSku = data.items[0]
    assignedSku.job_role_id = sku?.job_role_id
    assignedSku.job_role_name = sku?.job_role_name

    eResource = {
      ...resource,
      name: assignedSku.description,
      short_code: generateAvatarInitials(assignedSku.description),
      skus: [assignedSku],
      assigned_sku: assignedSku,
      isEnriched: true
    }
  }

  return {
    shouldEnrich,
    eResource,
    isSuccess,
    isFetching
  }
}

type resourceType = 'employee' | 'equipment' | null

export const useAddResourceTypeParam = (): [resourceType, (type: resourceType) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const type = useMemo(() => searchParams.get('add_resource_type') as resourceType, [searchParams])

  const setType = (new_type: resourceType) => {
    if (new_type) searchParams.set('add_resource_type', new_type)
    else searchParams.delete('add_resource_type')

    setSearchParams(searchParams, { replace: true, preventScrollReset: true })
  }

  return [type, setType]
}
