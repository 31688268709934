import { APP_URL } from '@/constants/app'
import { Icon, IconName } from '@/ui/icons'
import { Modal } from '@/ui/modal'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Alert, Input } from 'antd'
import axios from 'axios'
import classNames from 'classnames'
import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import styles from './IconSelect.module.scss'

type Props = {
  value?: string
  onSelect: (icon: string) => void
}

export const IconSelect = ({ onSelect, value }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(value)
  const [searchText, setSearchText] = useState('')

  const iconsQuery = useQuery({
    queryFn: async () => axios.get(`${APP_URL}/data/icons.json`).then((res) => sortBy(res.data || [], (i) => -i[2])), // sort by popularity
    enabled: isOpen,
    queryKey: ['icons']
  })

  const handleSelect = (icon: string) => {
    setSelectedIcon(icon)
    onSelect(icon)
    toggleModal()
  }

  const filteredIcons = useMemo(() => {
    if (!iconsQuery.data) {
      return []
    }

    if (!searchText.trim()) {
      return iconsQuery.data
    }

    return iconsQuery.data.filter((i) => i[3].includes(searchText.trim()))
  }, [iconsQuery.data, searchText])

  return (
    <>
      <div className="cursor-pointer" onClick={toggleModal}>
        <Input
          className={styles.wrapper}
          prefix={selectedIcon ? <Icon name={`mi:${selectedIcon}` as IconName} /> : undefined}
          addonAfter={<Icon name="mi:unfold_more" />}
          readOnly
        />
      </div>
      <Modal
        bodyStyle={{
          overflowX: 'hidden'
        }}
        withScreenMaxHeight
        title="Select Icon"
        open={isOpen}
        width={900}
        onCancel={toggleModal}
      >
        {(() => {
          if (iconsQuery.isLoading) {
            return <Spin isCentered spinning />
          }

          if (!iconsQuery.data) {
            return <Alert message="Icons not found" type="warning" />
          }

          return (
            <div>
              <Input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
              <div className="grid grid-cols-5 gap-x-24 gap-y-16 mt-16">
                {filteredIcons.map((icon, i) => (
                  <div
                    key={i}
                    className={classNames(
                      'flex flex-col gap-y-8 items-center justify-center p-24 border border-border rounded hover:border-primary cursor-pointer transition-colors duration-200',
                      {
                        'border-success hover:border-success': selectedIcon === icon[0]
                      }
                    )}
                    onClick={() => handleSelect(icon[0])}
                  >
                    <Icon name={`mi:${icon[0]}` as IconName} />
                    <div className="font-medium">{icon[0]}</div>
                  </div>
                ))}
              </div>
            </div>
          )
        })()}
      </Modal>
    </>
  )
}
