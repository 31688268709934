import { DEFAULT_TIMEZONE } from '@/constants/date'
import { getUserPermissions } from '@/services/auth-service'
import { AuthUser, Permission } from '@/types/auth-user'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

type SessionState = {
  isLoggedIn: boolean
  timezone?: string
  user?: AuthUser | null
  company?: any
  contact?: any
  permissions: Permission[]
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isLoggedIn: false,
    token: null,
    user: null,
    company: null,
    contact: null,
    permissions: []
  } as SessionState,
  reducers: {
    /**
     * This action is called during app initialization or after successful login to start the session.
     * @param state
     * @param action
     */
    startSession(state, action: PayloadAction<AuthUser>) {
      const user = action.payload
      state.user = user
      state.company = user.profile.company
      state.contact = user.profile.contact
      state.isLoggedIn = true
      state.permissions = [...getUserPermissions(user)]
      state.timezone = dayjs.setTimeZone(
        user.profile.timezone || user.profile.company.default_timezone || DEFAULT_TIMEZONE
      )
    },

    /**
     * Upon logout action, clear the session. This will also clear the token from local storage.
     * In general, logout action clears the session and redirects to login page.
     * @param state
     */
    clearSession(state) {
      state.isLoggedIn = false
      state.user = null
      state.company = null
      state.contact = null
    }
  }
})

export const { startSession, clearSession } = sessionSlice.actions
export const sessionReducer = sessionSlice.reducer
