import { CustomFormSelect } from '../custom-form-select'
import { DefaultTimeFilterSelect } from '../default-time-filter-select'
import { GroupTabsSelect } from '../group-tabs-select'

export const ModuleTypeTimeManagerFields = () => {
  return (
    <div>
      <div className="grid grid-cols-4 items-center gap-x-16">
        <CustomFormSelect formItemProps={{ label: 'Custom Form', name: ['data', 'custom_form_id'] }} />
        <GroupTabsSelect />
        <DefaultTimeFilterSelect />
      </div>
    </div>
  )
}
