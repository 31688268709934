import { CustomEmail } from '@/schemas/custom-email'
import { Button } from '@/ui/button'
import { Popconfirm, Space, Table } from 'antd'
import { AddEditCustomEmailModal } from './add-edit-custom-email-modal'
import { useCustomEmailsQuery, useDeleteCustomEmail } from './hooks'

export const CustomEmailsTable = () => {
  const { customEmailsQuery } = useCustomEmailsQuery()
  const { deleteCustomEmail, deleteCustomEmailMutation } = useDeleteCustomEmail()

  const dataSource =
    customEmailsQuery.data?.items.map((item) => ({
      ...item,
      key: item.id
    })) || []

  return (
    <div className="flex-1">
      <div className="flex justify-between mb-16">
        <h4 className="font-bold mb-0 items-center">Custom Emails</h4>
        <AddEditCustomEmailModal />
      </div>
      <Table
        loading={customEmailsQuery.isInitialLoading || customEmailsQuery.isRefetching}
        dataSource={dataSource}
        pagination={false}
      >
        <Table.Column title="ID" width={60} dataIndex="id" />
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Email Template" dataIndex="email_template__name" />
        <Table.Column
          title="Action"
          width={100}
          align="center"
          render={(_, customEmail: CustomEmail) => (
            <Space>
              <Popconfirm
                title="Delete custom email"
                description={`Are you sure you want to delete #${customEmail.id}?`}
                okText="Yes"
                okButtonProps={{
                  loading: deleteCustomEmailMutation.isLoading,
                  'data-cy': 'delete-custom-email-confirm-button'
                }}
                onConfirm={async () => {
                  await deleteCustomEmail(customEmail.id)
                  await customEmailsQuery.refetch()
                }}
              >
                <Button iconName="svg:trash" type="text" data-cy="delete-custom-email-button" />
              </Popconfirm>
              <AddEditCustomEmailModal
                customEmail={customEmail}
                trigger={<Button iconName="svg:edit" type="text" data-cy="edit-custom-email-button" />}
              />
            </Space>
          )}
        />
      </Table>
    </div>
  )
}
