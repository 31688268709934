import { MODULE_TYPES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { moduleApi } from '@/services/api-service'
import { Select } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, Modal } from 'antd'
import { isAxiosError } from 'axios'
import { useState } from 'react'

type Props = {
  onSaved: (id: number) => Promise<any>
}

export const AddModuleModal = ({ onSaved }: Props) => {
  const [form] = Form.useForm<{ title: string; view_type: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const addModuleMutation = useMutation({ ...moduleApi.create() })
  const { contact } = useSession()
  const { notification } = useApp()
  const toggleModal = () => setIsOpen((prev) => !prev)

  const handleSave = async () => {
    try {
      const { title, view_type } = await form.validateFields()

      const module = await addModuleMutation.mutateAsync({
        title,
        view_type,
        position: 2,
        config_json: '{}',
        contacts_json: `[${contact.id}]`, // add current by default
        // Note: This is not required. But V1 has this value as default in Data JSON code editor
        data_json: '{"editable_fields":[]}'
      })

      await onSaved(module.id)

      notification.success({
        message: 'Module added'
      })

      toggleModal()
    } catch (error) {
      if (isAxiosError(error)) {
        notification.error({
          message: 'Something went wrong'
        })
      }
    }
  }

  return (
    <>
      <Button type="primary" iconName="mi:add" onClick={toggleModal}>
        New Module
      </Button>
      <Modal
        title="Add Module"
        open={isOpen}
        okText="Add Module"
        okButtonProps={{
          loading: addModuleMutation.isLoading
        }}
        onOk={handleSave}
        onCancel={toggleModal}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            view_type: 'T'
          }}
        >
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Module Type" name="view_type" rules={[{ required: true }]}>
            <Select
              showSearch
              allowClear={false}
              options={Object.entries(MODULE_TYPES).map(([key, value]) => ({ label: value, value: key }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
