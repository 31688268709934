import { DetailedEquipment } from '@/modules/records/skus/views/form-view/schema'
import { CategoryKind } from '@/modules/records/skus/views/form-view/schema/category-kind-schema'
import { userDataApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

export const userDataSchema = z.object({
  id: z.number(),
  content: z.array(z.string()),
  name: z.string().nullish(),
  multiselect: z.boolean().nullish()
})

export type UserData = z.infer<typeof userDataSchema>

type Params = {
  equipment: DetailedEquipment
  kind?: CategoryKind
}

export const useUserDataQuery = ({ equipment, kind }: Params) => {
  const stringSources = Object.entries(equipment)
    .filter(([key, val]) => !!val && key.startsWith('string_') && key.endsWith('_source'))
    .map(([key, val]) => val)
    .concat(
      Object.entries(kind?.data || {})
        .filter(([key, val]) => !!val?.stringSource)
        .map(([key, val]) => val.stringSource)
    )

  const id__in = stringSources.join(',')

  const userDataQuery = useQuery({
    ...userDataApi.list<UserData>({ id__in }),
    enabled: !!id__in
  })

  return userDataQuery
}
