import { url } from '@/routing/helpers'
import { AppVersion } from '@/types/general'
import React from 'react'
import { Link, LinkProps, To } from 'react-router-dom'

export type AppLinkProps = Omit<LinkProps, 'to'> & {
  v?: AppVersion
  to?: To
}

export const AppLink: React.FC<AppLinkProps> = ({ v, to, children, ...props }) => {
  if (!to) return <a {...props}>{children}</a>

  to = url(to, { v })

  if (v === 1)
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}
