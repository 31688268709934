import { z } from 'zod'

export const CustomRecordSchema = z.object({
  id: z.string(),
  label: z.string(),
  char_1: z.string().nullish(),
  char_2: z.string().nullish(),
  char_3: z.string().nullish(),
  char_4: z.string().nullish(),
  data: z.any().nullish(),
  modified_at: z.string().nullish()
})

export type CustomRecord = z.infer<typeof CustomRecordSchema>
