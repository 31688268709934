import { programmedReportApi, reportBuilderApi } from '@/services/api-service'
import { ReportBuilderReport } from '@/types/report-builder-report'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

export const useReport = () => {
  const params = useParams()

  const reportId = Number(params.id) || null
  const programmedReportId = Number(params.prId) || null

  const reportQuery = useQuery(reportBuilderApi.get<ReportBuilderReport>(reportId))
  const programmedReportQuery = useQuery(
    programmedReportApi.get(programmedReportId, undefined, {
      fields: z.object({ id: z.number(), company_id: z.number(), report_id: z.number() })
    })
  )

  return {
    report: reportQuery.data,
    reportQuery,
    programmedReport: programmedReportQuery.data,
    programmedReportQuery
  }
}
