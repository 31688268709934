import { useBootstrap } from '@/hooks/use-bootstrap'
import { view } from '@/routing'
import { useEffect } from 'react'

export const LogoutView = view(Component, { title: () => 'Logout' })

function Component() {
  const { endUserSession } = useBootstrap()

  useEffect(() => {
    endUserSession()
  }, [endUserSession])

  return <div />
}
