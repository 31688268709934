import { IdentifierInput, Select } from '@/ui'
import { Form } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaEtlLoadDataAction } from '../../schemas'

type Props = {
  action: BpaEtlLoadDataAction
  onChange: (data: Partial<BpaEtlLoadDataAction>) => void
}

export const InputEtlLoadDataAction: FC<Props> = ({ action, onChange }) => {
  const config = action?.data || {}
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col flex-wrap gap-10">
      <Form.Item label="Destination Type" className={'flex-grow'} required>
        <Select
          placeholder={'Required'}
          value={config.dst_type}
          onChange={(value) => onChange({ data: { ...action.data, dst_type: value } })}
          options={[
            { value: 'clickhouse', label: 'ClickHouse' }
            // { value: 'excel', label: 'Excel' } // todo: not supported yet
          ]}
        />
      </Form.Item>

      <div className={'flex flex-row gap-10 items-center'}>
        <Form.Item label="Source DataFrame" className={'flex-grow'} required>
          <IdentifierInput
            placeholder={'Required'}
            defaultValue={config.src_dataframe || ''}
            onChange={(value) => onChangeDebounced({ data: { ...action.data, src_dataframe: value } })}
          />
        </Form.Item>
        <Form.Item label="Destination Table Name" className={'flex-grow'} required>
          <IdentifierInput
            placeholder={'Required'}
            defaultValue={config.dst_name || ''}
            onChange={(value) => onChangeDebounced({ data: { ...action.data, dst_name: value } })}
          />
        </Form.Item>
      </div>
    </div>
  )
}
export const InputEtlLoadDataActionMemo = React.memo(InputEtlLoadDataAction)
