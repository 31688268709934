import { APP_URL } from '@/constants/app'
import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES } from '@/constants/general'
import { ApiResource, ListParams, ListQueryApiFn } from '@/types/api/core'
import { Event } from '@/types/event'
import { Button, ButtonProps } from '@/ui/button'
import { Modal } from '@/ui/modal'
import { Tabs } from '@/ui/tabs'
import { useQuery } from '@tanstack/react-query'
import { Select, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs, { ManipulateType } from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  triggerProps?: ButtonProps
  triggerText?: string
  apiEndpoint?: ListQueryApiFn
  apiQueryParams?: ListParams

  /**
   * @deprecated Use `apiEndpoint` instead
   * @todo Implement `apiQueryParams` and remove this
   */
  query: ApiResource['list'] | ApiResource['events']

  /**
   * @deprecated Use `apiQueryParams` instead
   * @todo Implement `apiQueryParams` and remove this
   */
  queryVariables?: Record<string, any>
}

export const ActivityLogsModal = ({ query, triggerProps, triggerText, queryVariables }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const allEventsQuery = useQuery({ ...query({ ...queryVariables, limit, page }), enabled: isOpen })
  const [period, setPeriod] = useState('all')
  const [type, setType] = useState(7)
  const tableRef = useRef<HTMLDivElement>(null)
  const [isPrinting, setIsPrinting] = useState(false)

  const events = useMemo(() => {
    let events = allEventsQuery.data?.items || []

    if (period !== 'all') {
      events = events.filter((event) => {
        const periodNumber = Number(period.slice(0, -1))
        const periodUnit = period.slice(-1)

        return dayjs(event.timepoint).isAfter(
          dayjs().subtract(periodNumber, periodUnit as ManipulateType),
          periodUnit as ManipulateType
        )
      })
    }

    return events.filter((event) => (Number(event.category) & type) === Number(event.category))
  }, [allEventsQuery.data, period, type])

  useEffect(() => {
    if (!isPrinting) {
      return
    }

    window.print()
    setIsPrinting(false)
  }, [isPrinting])

  const renderTable = useCallback(() => {
    return (
      <Table
        loading={allEventsQuery.isLoading}
        pagination={
          isPrinting
            ? false
            : {
                pageSize: limit,
                total: allEventsQuery.data?.total || 0,
                onChange: (page) => setPage(page),
                onShowSizeChange: (_, limit) => setLimit(limit),
                pageSizeOptions: TABLE_PAGE_SIZES
              }
        }
        expandable={
          isPrinting
            ? undefined
            : {
                expandedRowRender: (event) => (
                  <div style={{ margin: 0 }}>
                    <div className="font-bold">Meta</div>
                    <div className="p-12 border border-border rounded bg-background-accent">
                      <code className="whitespace-pre">{JSON.stringify(event.data, null, 2)}</code>
                    </div>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <Button
                    type={expanded ? 'primary' : 'default'}
                    iconName={`fa:${expanded ? 'chevron-up' : 'chevron-down'}`}
                    onClick={(e) => onExpand(record, e)}
                  />
                )
              }
        }
        dataSource={events.map((item) => ({
          ...item,
          key: item.id
        }))}
        columns={columns}
      />
    )
  }, [allEventsQuery.data?.total, limit, allEventsQuery.isLoading, events, isPrinting])

  if (!query) {
    console.warn('ActivityLogModal is rendered without query passed')
    return null
  }

  if (isPrinting) {
    return createPortal(
      <div className="fixed top-0 left-0 right-0 bottom-0 z-10000 bg-white">
        <div className="flex justify-end mb-16">
          <img
            src={`${APP_URL}/images/oc_verified_logo.png`}
            className="w-[240px] object-contain"
            alt={'oc-verified'}
          />
        </div>
        <div ref={tableRef}>{renderTable()}</div>
        <div className="fixed w-full bottom-0 flex justify-end mt-16">
          <div>© {dayjs().format('YYYY')} Oil Command. All rights reserved.</div>
        </div>
      </div>,
      document.body
    )
  }

  return (
    <>
      <Button shape={'round'} iconName="fa:info-circle" onClick={() => setIsOpen(true)} {...triggerProps}>
        {triggerText ?? 'View All Activity'}
      </Button>
      <Modal
        open={isOpen}
        title="Activity Logs"
        width={1200}
        withFullscreenOption
        withScreenMaxHeight
        footer={
          <Space>
            <Button onClick={() => setIsPrinting(true)}>Print</Button>
            <Button type="primary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </Space>
        }
        onCancel={() => setIsOpen(false)}
      >
        <div className="flex items-start justify-between">
          <Tabs type="solid" onChange={setPeriod} items={tabItems} />
          <Select<number> defaultValue={7} onChange={setType} popupMatchSelectWidth={false} options={typeOptions} />
        </div>
        {renderTable()}
      </Modal>
    </>
  )
}

const tabItems = [
  {
    key: 'all',
    label: 'All'
  },
  {
    key: '24h',
    label: 'Last 24 hours'
  },
  {
    key: '3d',
    label: 'Last 3 days'
  },
  {
    key: '7d',
    label: 'Last 7 days'
  },
  {
    key: '30d',
    label: 'Last 30 days'
  }
]

const typeOptions = [
  {
    label: 'All Activity',
    value: 7
  },
  {
    label: 'Billable Information',
    value: 1
  },
  {
    label: 'Ticket Handling',
    value: 2
  },
  {
    label: 'Notes',
    value: 4
  }
]

const columns: ColumnsType<any> = [
  {
    title: (
      <div>
        <div>Date</div>
        <div className="font-normal">Time</div>
      </div>
    ),
    render: (_, event: Event) => (
      <div>
        <div className="font-semibold">{event.date}</div>
        <div className="font-normal">{event.time}</div>
      </div>
    )
  },
  {
    title: (
      <div>
        <div className="font-semibold">Person</div>
        <div className="font-normal">Company</div>
      </div>
    ),
    render: (_, event: Event) => (
      <div>
        <div className="font-semibold">{event.contact_name}</div>
        <div className="font-normal">{event.contact_company_name}</div>
      </div>
    )
  },
  {
    title: (
      <div>
        <div className="font-semibold">Event (Action)</div>
        <div className="font-normal">Title</div>
      </div>
    ),
    render: (_, event: Event) => (
      <div>
        <div className="font-semibold">{event.action_text}</div>
      </div>
    )
  },
  {
    title: (
      <div>
        <div className="font-semibold">Event (Action)</div>
        <div className="font-normal">Detail</div>
      </div>
    ),
    render: (_, event: Event) => (
      <div>
        <div
          className="font-semibold"
          dangerouslySetInnerHTML={{
            __html: event.target_text || ''
          }}
        />
      </div>
    )
  },
  {
    title: (
      <div>
        <div className="font-semibold">Platform</div>
        <div className="font-normal">Version, Platform</div>
      </div>
    ),
    render: (_, event: Event) => (
      <div>
        <div className="font-semibold">{event.version_stats_app}</div>
        <div className="font-normal">v {event.version_stats_version}</div>
        <div className="font-normal">{event.version_stats_platform}</div>
      </div>
    )
  },
  Table.EXPAND_COLUMN
]
