import { PropertySelect } from '@/components'
import { StatusSelect } from '@/modules/ticket/components'
import { ScriptedButtonConfig } from '../../../types'
import { DispatchButton } from './DispatchButton'

export const RcrDispatchButtonConfig: ScriptedButtonConfig = {
  type: 'rcr_dispatch_button',
  iconName: 'fa:share',
  label: 'Dispatch',
  group: 'RCR',
  render: () => <DispatchButton />,
  vars: [
    {
      name: 'dispatch_status',
      label: 'Dispatch Status',
      render: (customForm, props) => <StatusSelect formId={customForm?.id} {...props} />
    },
    {
      name: 'contact_ids_pid',
      label: 'Contact IDs Property',
      render: (customForm, props) => <PropertySelect formId={customForm?.id} {...props} />
    },
    {
      name: 'clear_property_ids',
      label: 'Clear Property',
      render: (customForm, props) => <PropertySelect mode={'multiple'} formId={customForm?.id} {...props} />
    }
  ]
}
