import { useSession } from '@/hooks'
import { useCheckAccess } from '@/modules/auth/hooks'
import { ACCOUNTING_SERVICES } from '@/modules/ticket/form/constants'
import { Select } from '@/ui'
import { Form } from 'antd'
import { FC, useMemo } from 'react'
import { useTicketBatchJob } from '../../hooks/use-ticket-batch-job'
import { TicketBulkAction } from './TicketBulkAction'

export const TicketSubmitToAccountingButton: FC = () => {
  const { company } = useSession()
  const allowed = useCheckAccess(['qb_input'])
  const [form] = Form.useForm<{ service: string }>()

  const { submit, mutation } = useTicketBatchJob()
  const config = company.data?.accounting || null
  const services = useMemo(() => {
    if (!config) return []

    return ACCOUNTING_SERVICES.filter((service) => config[service.option_key])
  }, [config])

  const onOk = async (closeFn: any) => {
    try {
      await form.validateFields()
    } catch (error) {
      return
    }

    return submit(
      {
        job_name: 'Submit Tickets To Accounting',
        actions: [{ action: 'submit_to_accounting', service: form.getFieldValue('service') }]
      },
      () => closeFn(true)
    )
  }

  if (!allowed || services.length === 0) return <></>

  const initialValues = {
    service: services[0].key
  }

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:dollar', children: 'Submit To Accounting' }}
      modal={{
        title: 'Submit To Accouning',
        okText: 'Submit',
        onOk: onOk,
        okButtonProps: { loading: mutation.isLoading }
      }}
      optionsTitle={'Options'}
    >
      <Form form={form} initialValues={initialValues} layout={'vertical'} validateTrigger={'onBlur'}>
        <Form.Item label={'Accounting Service'} name={'service'} rules={[{ required: true, message: 'Required' }]}>
          <Select options={services.map((service) => ({ value: service.key, label: service.label }))} />
        </Form.Item>
      </Form>
    </TicketBulkAction>
  )
}
