import { StatusSelect } from '@/modules/ticket/components'
import { LocalDateTimePicker } from '@/ui/date'
import { FormItemProps, Input } from 'antd'
import { FC, ReactElement } from 'react'
import { AssigneeSelect } from '../../../../../ui'
import { FieldConfig } from '../../../../schemas'
import { NumberInput } from './number'
import { UnitSelect } from './unit'
import { UsageRateWrapper } from './usage-rate'
import { UserTextInput } from './user-text'

const FIELDS_INPUT: Record<
  string,
  {
    className?: string
    render: (p: { disabled?: boolean }, c: FieldConfig) => ReactElement
    wrapper?: FC<FormItemProps>
  }
> = {
  sku: { className: 'w-6/12', render: (p) => <Input className={'w-full'} {...p} /> },
  description: { className: 'w-6/12', render: (p) => <Input className={'w-full'} {...p} /> },

  amount: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  barrels: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  cost: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  discount: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  tax_rate: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  minimum: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  standby_rate: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  unit: { render: (p) => <UnitSelect className={'w-full'} {...p} /> },
  units_min: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  units_standby: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  hands: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  units_used: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  usage_rate: { render: (p) => <NumberInput className={'w-full'} {...p} />, wrapper: UsageRateWrapper },

  discountable_amount: { render: (p) => <NumberInput className={'w-full'} {...p} disabled /> },
  taxable_amount: { render: (p) => <NumberInput className={'w-full'} {...p} disabled /> },

  status: { render: (p) => <StatusSelect className={'w-full'} {...p} /> },
  assignee: { render: (p) => <AssigneeSelect className={'w-full'} {...p} /> },

  user_decimal_1: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  user_decimal_2: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  user_decimal_3: { render: (p) => <NumberInput className={'w-full'} {...p} /> },

  user_integer_1: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  user_integer_2: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  user_integer_3: { render: (p) => <NumberInput className={'w-full'} {...p} /> },
  user_integer_4: { render: (p) => <NumberInput className={'w-full'} {...p} /> },

  user_text_1: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_2: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_3: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_4: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_5: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_6: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_7: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },
  user_text_8: { render: (p, c) => <UserTextInput config={c} className={'w-full'} {...p} /> },

  start_datetime: {
    render: (p, c) => <LocalDateTimePicker showDate={!c.hideDate} showTime={!c.hideTime} className={'w-full'} {...p} />
  },
  end_datetime: {
    render: (p, c) => <LocalDateTimePicker showDate={!c.hideDate} showTime={!c.hideTime} className={'w-full'} {...p} />
  },
  user_datetime_1: {
    render: (p, c) => <LocalDateTimePicker showDate={!c.hideDate} showTime={!c.hideTime} className={'w-full'} {...p} />
  },
  user_datetime_2: {
    render: (p, c) => <LocalDateTimePicker showDate={!c.hideDate} showTime={!c.hideTime} className={'w-full'} {...p} />
  },

  notes: { className: 'w-full', render: (p) => <Input.TextArea autoSize={{ minRows: 2, maxRows: 10 }} {...p} /> },
  user_long_text_1: {
    className: 'w-full',
    render: (p) => <Input.TextArea autoSize={{ minRows: 2, maxRows: 10 }} {...p} />
  }
}

export const getFieldInput = (field: string) => {
  return FIELDS_INPUT[field]
}
