import { layout, redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { AccountSettingsLayout } from './layout'
import {
  AdvancedSettingsView,
  CompanyInformationView,
  CompanyLocalesView,
  CustomerNameReconciliationView,
  ManageRolesView,
  TicketViewsFormLayout,
  TicketViewsListView
} from './views'
import { AdvancedTab, ConfigureTab, TemplatesTab } from './views/ticket-views-view/form-view/tabs'

export const routes: RouteObject[] = [
  route('account_settings', AccountSettingsLayout, [
    route('index', redirect('company_information')),
    route('company_information', CompanyInformationView),
    route('ticket_views', layout('TicketViews', 'TableViews'), [
      route('index', TicketViewsListView),
      route(':id/edit', TicketViewsFormLayout, [
        route('index', redirect('configure')),
        route('configure', ConfigureTab),
        route('templates', TemplatesTab),
        route('advanced', AdvancedTab)
      ])
    ]),
    route('accounting_name', CustomerNameReconciliationView),
    route('manage_roles', ManageRolesView),
    route('locales', CompanyLocalesView),
    route('advanced_settings', AdvancedSettingsView)
  ])
]
