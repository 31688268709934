import { propertyApi, propertyGroupApi } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

/**
 * Returns the options for the select component with property group name and property name
 * as label (PropertyGroupName / PropertyName) and property id as value sorted by label
 */
export const usePropertiesSelectOptions = ({
  customFormsIds,
  propertiesQueryParams,
  valueKey = 'id'
}: {
  customFormsIds?: number[]
  propertiesQueryParams?: ListParams
  /** Default: id */
  valueKey?: string
}) => {
  const propertiesQuery = useQuery({
    ...propertyApi.list<{ id: number; name: string; property_group: number }>({
      limit: 'None' as any,
      fields: 'id,name,property_group',
      property_group__custom_form_id__in: customFormsIds?.join(','),
      ...propertiesQueryParams
    }),
    enabled: !!customFormsIds?.length
  })

  const propertyGroupsQuery = useQuery({
    ...propertyGroupApi.list<{ id: number; name: string }>({
      limit: 'None' as any,
      fields: 'id,name',
      custom_form__in: customFormsIds?.join(','),
      deleted_by__isnull: true
    }),
    enabled: !!customFormsIds?.length
  })

  const propertiesOptions = useMemo(() => {
    if (!customFormsIds?.length) {
      return []
    }

    const properties = propertiesQuery.data?.items ?? []
    const propertyGroups = propertyGroupsQuery.data?.items ?? []

    return properties
      .map((p) => {
        const propertyGroup = propertyGroups.find((pg) => pg.id === p.property_group)

        if (!propertyGroup) {
          return null
        }

        return {
          label: `${propertyGroup?.name ?? ''} / ${p.name}`,
          value: p[valueKey as keyof typeof p]
        }
      })
      .filter((v) => !!v)
      .sort((a, b) => a!.label.localeCompare(b!.label, 'en')) as { label: string; value: number }[]
  }, [propertiesQuery.data?.items, customFormsIds, propertyGroupsQuery.data?.items, valueKey])

  return {
    propertiesOptions,
    isLoading: propertiesQuery.isInitialLoading || propertyGroupsQuery.isInitialLoading,
    isFetching: propertiesQuery.isFetching || propertyGroupsQuery.isFetching,
    propertiesQuery,
    propertyGroupsQuery
  }
}
