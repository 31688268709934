import { AuthUser } from '@/types/auth-user'
import { CustomForm, Ticket } from '../schemas'

export const autoFillTicket = (ticket: Ticket, customForm: CustomForm, user: AuthUser) => {
  const autoFills = user.profile?.userdata_json?.auto_fills || []
  const fillData = autoFills.find((f) => f.custom_form_id === customForm.id)

  if (!fillData) return

  const fillFields = fillData.fields || {}

  if (fillFields.afe) {
    ticket.afe_id = fillFields.afe.id
    ticket.afe__user_afe_no = fillFields.afe.user_afe_no
  }

  if (fillFields.customer) {
    ticket.customer_id = fillFields.customer.id
    ticket.customer__first_name = fillFields.customer.first_name
    ticket.customer__last_name = fillFields.customer.last_name
  }

  if (fillFields.customer_office) {
    ticket.customer_office_id = fillFields.customer_office.id
    ticket.customer_office__name = fillFields.customer_office.name
  }

  if (fillFields.job_code) {
    ticket.job_code_id = fillFields.job_code.id
    ticket.job_code__user_job_code_no = fillFields.job_code.user_job_code_no
  }

  if (fillFields.location) {
    ticket.location_id = fillFields.location.id
    ticket.location__name = fillFields.location.name
  }

  const fillProperties = fillData.properties || []
  fillProperties.forEach((p) => {
    if (p?.id) {
      ticket.properties.push({
        id: 0,
        ticket_id: 0,
        property_id: p.id as any,
        value: p.value as any
      })
    }
  })
}
