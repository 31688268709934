import { QUERY_AUTO_REFRESH_OPTIONS } from '@/query'
import { Icon } from '@/ui/icons'
import { Empty } from 'antd'
import { SortOrder } from 'antd/es/table/interface'
import cn from 'classnames'
import { nanoid } from 'nanoid'
import { LazyDiv } from '../utils'
import { QueryColumnProps, QueryTableQueryOptions } from './types'

/**
 * A wrapper for antd Column component.
 * @todo: indicator for default/initial sort column
 */
export const getQueryColumn = ({
  key,
  className,
  ellipsis,
  sorter,
  showSorterTooltip,
  sortDirections,
  sortIcon,
  render,
  lazyRender = true,
  ...rest
}: QueryColumnProps) => {
  className = cn('group', className)
  ellipsis = ellipsis !== undefined ? ellipsis : false
  sorter = sorter !== undefined ? sorter : rest.dataIndex !== undefined
  showSorterTooltip = showSorterTooltip !== undefined ? showSorterTooltip : false
  sortDirections = sortDirections !== undefined ? sortDirections : ['ascend', 'descend', 'ascend']
  sortIcon =
    sortIcon ||
    (({ sortOrder }: { sortOrder: SortOrder }) => (
      <Icon
        name={'fa:arrow-up'}
        className={cn('opacity-0 text-[12px] transition-all group-hover:opacity-50 font-bold', {
          '!opacity-100 text-primary': !!sortOrder,
          'rotate-0': sortOrder === 'ascend',
          'rotate-180': sortOrder === 'descend'
        })}
      />
    ))

  const renderFn: QueryColumnProps['render'] = lazyRender
    ? (value, record, index) => {
        const result = render ? render(value, record, index) : value

        return (
          <LazyDiv
            fadeIn={true}
            behaviour={'viewport'}
            placeholder={<div className={'h-[32px]'} />}
            {...(typeof lazyRender === 'boolean' ? {} : lazyRender)}
          >
            {result}
          </LazyDiv>
        )
      }
    : render

  return {
    key: key || rest.dataIndex || nanoid(),
    className,
    ellipsis,
    sorter,
    showSorterTooltip,
    sortDirections,
    sortIcon,
    render: renderFn,
    ...rest
  }
}

export const renderEmpty = (componentName?: string, isReady?: boolean) => {
  if (!isReady) return <div className={'min-h-[150px]'}></div>
  if (componentName === 'Table') {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
  }
}

export const getQueryOptions = (options?: QueryTableQueryOptions) => {
  const { autoRefresh, ...queryOptions } = options || {}
  if (autoRefresh === false) return queryOptions
  if (autoRefresh === 0) return { ...QUERY_AUTO_REFRESH_OPTIONS, ...queryOptions }

  return {
    ...QUERY_AUTO_REFRESH_OPTIONS,
    refetchInterval: Number(autoRefresh || 0) * 1000 || QUERY_AUTO_REFRESH_OPTIONS.refetchInterval,
    ...queryOptions
  }
}
