import { Event } from '@/types/event'

type Props = {
  event: Event
}

export const ActivityItemHeader = ({ event }: Props) => {
  const { days, time, contact_name, contact_title, action_text, target_text } = event

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-2">
        <div className="font-bold">{days}</div>
        <div className="font-medium">{time}</div>
      </div>
      <div className="col-span-3">
        <div className="font-bold">{contact_name}</div>
        <div className="font-medium">{contact_title}</div>
      </div>
      <div className="col-span-7">
        <div className="font-bold">{action_text}</div>
        <div className="font-medium" dangerouslySetInnerHTML={{ __html: target_text || '' }}></div>
      </div>
    </div>
  )
}
