import { Form } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { cloneElement, useMemo } from 'react'
import { customFormAtom } from '../../../atoms'
import { CustomForm } from '../../../types'

type Props = {
  children: any
  name: keyof CustomForm
  label?: string
  onChange?: (e: any) => void
  noStyle?: boolean
  isCheckbox?: boolean
  className?: string
}

/**
 * This component is used to render a form item with a label and a value.
 * It sets the value of the form item to the value of the custom form atom and vice versa when the value changes.
 */
export const FormItem = ({ name, label, onChange, noStyle, children, isCheckbox, className }: Props) => {
  const value = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s[name]), [name]))
  const setCustomForm = useSetAtom(customFormAtom)

  const onValueChange = (e: any) => {
    setCustomForm((s) => ({ ...s, [name]: isCheckbox ? e.target.checked : e.target.value || null }))
  }

  return (
    <Form.Item label={label} noStyle={noStyle} className={className}>
      {cloneElement(children, {
        value,
        onChange: onChange || onValueChange,
        ...(isCheckbox && { checked: !!value })
      })}
    </Form.Item>
  )
}
