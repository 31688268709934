import { useIsManager } from '@/modules/auth/hooks'
import { getTableViewQuery } from '@/modules/table-view/helpers'
import { ListParams } from '@/types/api/core'
import { deepMerge } from '@/utils'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { getCustomFiltersQuery, isCustomFiltersEnabled } from '../../helpers'
import { customFiltersAtom } from '../atoms'
import { useActiveTableView } from './use-active-table-view'
import { useTicketFilter } from './use-ticket-filter'

export const useTicketsParam = ({
  extraParams,
  transformParams,
  noStatusFilter,
  noArchivedFilter,
  noActiveView
}: {
  extraParams?: ListParams
  transformParams?: (params: ListParams) => ListParams
  noStatusFilter?: boolean
  noArchivedFilter?: boolean
  noActiveView?: boolean
} = {}) => {
  // filter fields
  const ticketFilter = useTicketFilter()
  const { selectedStatus, includeArchived, getFilterParams } = ticketFilter

  const isManager = useIsManager()
  const customFilters = useAtomValue(customFiltersAtom)
  const { activeView } = useActiveTableView()

  // 1. table view params
  const viewParams = useMemo(
    () => (!noActiveView && activeView ? getTableViewQuery(activeView, isManager, {}) : {}),
    [noActiveView, activeView, isManager]
  )
  const params = useMemo(() => {
    // params for query
    let params_: ListParams = { Q: [], E: [], order: '-name' }
    params_ = deepMerge(params_, viewParams)

    // 2. search and filter params
    params_ = deepMerge(params_, getFilterParams())

    // 3. custom filters params
    if (isCustomFiltersEnabled(activeView)) {
      params_ = deepMerge(params_, getCustomFiltersQuery(customFilters))
    }

    // 4. include archived params
    if (!noArchivedFilter && includeArchived) params_.archived = true

    // 5. selected status params
    if (!noStatusFilter && selectedStatus !== 'all') {
      params_.computed_status__exact = selectedStatus
    }

    // 6. extra params, set at last
    params_ = deepMerge(params_, extraParams || {})

    // 7. transform params
    if (transformParams) params_ = transformParams(params_)

    return params_
  }, [
    viewParams,
    activeView,
    selectedStatus,
    customFilters,
    includeArchived,
    transformParams,
    getFilterParams,
    noArchivedFilter,
    extraParams
  ])

  return { params, ticketFilter }
}
