import { Icon, IconName } from '@/ui/icons'
import { Tabs as AntdTabs } from 'antd'
import { useSortableItemContext } from '../context'
import { DataSource } from './data-source'
import { GeneralSettings } from './general-settings'
import { MobileSettings } from './mobile-settings'
import { Script } from './script'
import { SumScript } from './sum-script'

export const Tabs = () => {
  const { lineItemField } = useSortableItemContext()
  const shouldShowDataSource = lineItemField.startsWith('user_text_')

  return (
    <AntdTabs
      defaultActiveKey="general"
      type="card"
      items={[
        {
          key: 'general',
          label: <Label iconName="mi:settings">General</Label>,
          children: <GeneralSettings />
        },
        {
          key: 'mobile',
          label: <Label iconName="mi:smartphone">Mobile Web / Mobile App</Label>,
          children: <MobileSettings />
        },
        {
          key: 'script',
          label: <Label iconName="mi:code">Script</Label>,
          children: <Script />
        },
        {
          key: 'sum-script',
          label: <Label iconName="mi:functions">Sum Script</Label>,
          children: <SumScript />
        },
        ...(shouldShowDataSource
          ? [
              {
                key: 'data-source',
                label: <Label iconName="mi:database">Data Source</Label>,
                children: <DataSource />
              }
            ]
          : [])
      ]}
    />
  )
}

const Label = ({ children, iconName }: { children: React.ReactNode; iconName: IconName }) => (
  <div className="flex items-center gap-x-6">
    <Icon name={iconName} className="text-16" />
    {children}
  </div>
)
