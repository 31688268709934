import { atom } from 'jotai'

export const selectedAutofillTabAtom = atom<number | undefined>(undefined)

export const metafieldCustomerContactAtom = atom<string>('')
export const metafieldJobCodeAtom = atom<string>('')
export const metafieldOfficeAtom = atom<string>('')
export const metafieldLocationAtom = atom<string>('')
export const metafieldAfeAtom = atom<string>('')

export const selectedMetaOfficerAtom = atom<number | undefined>(undefined)
export const selectedMetaLocationAtom = atom<number>(0)
export const selectedMetaJobCodeAtom = atom<number>(0)
export const selectedMetaAfeAtom = atom<number>(0)
export const selectedMetaCustomerAtom = atom<number>(0)

export const selectedPropertyAtom = atom<number[]>([])
