import { equipmentPriceApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'
import { LineItemData, LineItemForm } from '../schemas'
import { useFormValues } from './use-form-values'
import { useFormWatch } from './use-form-watch'

const EquipmentPriceSchema = z.object({
  id: z.number(),
  equipment_id: z.number(),
  usage_rate: z.number()
})

export const useCustomerPricing = () => {
  const form = Form.useFormInstance<LineItemForm>()
  const { handleValuesChange } = useFormValues()

  const isNew = useFormWatch(['isNew'])
  const pricingAppliedId = useFormWatch(['options', 'pricingAppliedId'])
  const customerOfficeId = useFormWatch(['options', 'customerOfficeId'])
  const formEquipmentIds = useFormWatch(['equipmentIds'])
  const lineItem = useFormWatch<LineItemData | undefined>(['data'])

  const equipmentIds = useMemo(() => {
    if (isNew) return formEquipmentIds || []
    if (lineItem?.equipment_id) return [lineItem?.equipment_id]
    return []
  }, [isNew, formEquipmentIds, lineItem?.equipment_id])

  const equipmentPricesQuery = useQuery({
    ...equipmentPriceApi.list({
      fields: EquipmentPriceSchema,
      customer_office_id__eq: customerOfficeId,
      equipment_id__in: equipmentIds.length ? equipmentIds?.join(',') : undefined,
      limit: 1000
    }),
    enabled: !!customerOfficeId && equipmentIds?.length > 0
  })

  const customerPricing = equipmentPricesQuery.data?.items

  const getEquipmentPricing = useCallback(
    (equipmentId?: number | null) => {
      if (!equipmentId) return null
      return customerPricing?.find((p) => p.equipment_id === equipmentId)
    },
    [customerPricing]
  )

  const applyPricing = useCallback(() => {
    if (pricingAppliedId === lineItem?.equipment_id) return // skip; pricing already applied
    if (equipmentIds.length > 1) return // skip; multiple equipment selected

    const pricing = getEquipmentPricing(lineItem?.equipment_id)
    if (!pricing || pricing.usage_rate === null) return // skip; no valid pricing found
    if (pricing.usage_rate === lineItem?.usage_rate) return // skip; pricing already applied

    // only apply if the rate is not deviated from the default rate
    if (!lineItem?.usage_rate || lineItem?.usage_rate === lineItem?.equipment__usage_rate) {
      const values = {
        data: { usage_rate: pricing.usage_rate, _old_usage_rate: pricing.usage_rate },
        options: { pricingAppliedId: lineItem?.equipment_id }
      } as Partial<LineItemForm>
      form.setFieldsValue(values)
      handleValuesChange(values, form.getFieldsValue(true))
    }
  }, [equipmentIds.length, form, getEquipmentPricing, handleValuesChange, lineItem, pricingAppliedId])

  return {
    equipmentPricesQuery,
    customerPricing,
    getEquipmentPricing,
    applyPricing
  }
}
