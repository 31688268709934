import { useAtomSelect } from '@/hooks/atom'
import { Button, Icon, Popconfirm } from '@/ui'
import { cn } from '@/utils'
import { Dropdown } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ticketsQueryResultAtom } from '../atoms'
import { useTicketSelection, useTicketsParam } from '../hooks'
import {
  TicketBatchJobButton,
  TicketBundleButton,
  TicketChangeStatusButton,
  TicketCopyButton,
  TicketImports,
  TicketListBundles,
  TicketSummaryButton,
  TicketUseOldVersionButton,
  TicketViewSelect
} from './actions'
import { TicketSubmitToAccountingButton } from './actions/TicketSubmitToAccountingButton'

export const TicketActions: FC = () => {
  const params = useTicketsParam()
  const [isResetPopconfirmOpen, setResetPopconfirmOpen] = useState<boolean>(false)
  const [resetSelectionsOnFilterChange, setResetSelectionsOnFilterChange] = useState<boolean | null>(null)
  const { selectedTickets, selectedTicketsCount, allTicketsSelected, setAllTicketsSelected, resetSelection } =
    useTicketSelection()
  const ticketsTotal = useAtomSelect(ticketsQueryResultAtom, 'total')
  const jsonStringParams = useMemo(() => JSON.stringify(params), [params])

  const confirmResetSelection = useCallback(() => {
    setResetSelectionsOnFilterChange(true)
    resetSelection()
    setResetPopconfirmOpen(false)
  }, [resetSelection, setResetSelectionsOnFilterChange, setResetPopconfirmOpen])

  const cancelResetSelection = useCallback(() => {
    setResetSelectionsOnFilterChange(false)
    setResetPopconfirmOpen(false)
  }, [setResetSelectionsOnFilterChange, setResetPopconfirmOpen])

  useEffect(() => {
    if (resetSelectionsOnFilterChange === null && selectedTicketsCount > 0) {
      setResetPopconfirmOpen(true)
    }
  }, [jsonStringParams])

  useEffect(() => {
    // Reset selection whenever filter criteria changes
    if (selectedTicketsCount && resetSelectionsOnFilterChange) resetSelection()
  }, [jsonStringParams])

  return (
    <div className="flex flex-col gap-8 items-end">
      <div className={'flex flex-row items-center gap-8'}>
        {selectedTickets.length > 0 && (
          <>
            <span>
              <b>{selectedTicketsCount}</b> out of <b>{ticketsTotal}</b> tickets selected
            </span>
            {!allTicketsSelected && selectedTicketsCount !== ticketsTotal && (
              <Button size="small" type="primary" primary onClick={setAllTicketsSelected}>
                Select all
              </Button>
            )}
            <Button type="dashed" size="small" iconName="fa:xmark" danger onClick={resetSelection}>
              Reset
            </Button>
            <Popconfirm
              title="Reset Selections"
              description="Filter criteria are changed. Reset selections?"
              placement="bottomRight"
              okText="Reset"
              cancelText="Keep"
              open={isResetPopconfirmOpen}
              onConfirm={confirmResetSelection}
              onCancel={cancelResetSelection}
            ></Popconfirm>
          </>
        )}
        <div
          className={cn('flex flex-row items-center gap-4', {
            hidden: !!selectedTickets.length
          })}
        >
          <TicketUseOldVersionButton />
          <TicketViewSelect />
          <Dropdown
            menu={{
              items: [
                {
                  key: 'view-bundled-pdfs',
                  icon: <Icon name={'fa:file-pdf'} />,
                  label: (
                    <TicketListBundles>
                      <span>Bundled PDFs</span>
                    </TicketListBundles>
                  )
                },
                {
                  key: 'imports',
                  icon: <Icon name={'fa:file-spreadsheet'} />,
                  label: (
                    <TicketImports>
                      <span>Bulk Imports</span>
                    </TicketImports>
                  )
                }
              ]
            }}
            trigger={['click']}
          >
            <div>
              <Button type={'default'} iconName={'fa:ellipsis-vertical'} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div
        className={cn('flex flex-row items-center gap-8', {
          hidden: selectedTicketsCount === 0
        })}
      >
        <div className={cn('flex flex-row items-center gap-8', { hidden: allTicketsSelected })}>
          <TicketCopyButton />
          <TicketSummaryButton />
          <TicketBundleButton />
        </div>
        <TicketSubmitToAccountingButton />
        <TicketChangeStatusButton />
        <TicketBatchJobButton />
      </div>
    </div>
  )
}
