export const PERMISSION_KEYS = {
  ticket_signature: [0],
  email_vendor: [0],
  my_time: [0],
  time_manager: [0],
  ticket_manager: [0],
  company_admin: [0],
  choices_manager: [0],
  module_builder: [0],
  ems_user: [],
  qb_input: [0],
  roles_manager: [0],
  support: [],
  disable_ticket_reservation: []
}
