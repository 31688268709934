import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { FC, useMemo } from 'react'
import { selectedDateAtom, selectedJobRoleIdAtom } from '../atoms'
import { JobRoleEmployeesList } from '../job-role-employees-list'
import { JobRoleStats } from '../schemas'

type EmployeesListProps = {
  stats: JobRoleStats[]
}

export const EmployeesList: FC<EmployeesListProps> = ({ stats }) => {
  const selectedDate = useAtomValue(selectedDateAtom)
  const selectedJobRoleId = useAtomValue(selectedJobRoleIdAtom)

  const displayedStats = useMemo(
    () => (selectedJobRoleId ? stats.filter(({ id }) => id === selectedJobRoleId) : stats),
    [stats, selectedJobRoleId]
  )

  return (
    <div className="px-20 pb-20 pt-10">
      <h5 className="text-black font-semibold text-16 mb-20">
        Employees Day Off: {selectedDate && dayjs(selectedDate).format('MM/DD/YYYY')}
      </h5>
      <div className="flex flex-col gap-20 pl-20">
        {displayedStats.map((jobRoleStats) => (
          <JobRoleEmployeesList key={jobRoleStats.id} job_role_stats={jobRoleStats} date={selectedDate} />
        ))}
      </div>
    </div>
  )
}
