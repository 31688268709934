import { CSSProperties } from 'react'
import { z } from 'zod'
import { ResourceTypeOption } from '../resource-manager/resources'

export type ScheduleViewData = ResourceTypeOption & {
  jobTicketId?: number
}

export const ResourceCalendarEventSchema = z.object({
  id: z.number(),
  start_datetime: z.string(),
  end_datetime: z.string()
})

export const ResourceCalendarDataSchema = z.object({
  type: z.string(),
  patch_id: z.number().nullish(),
  rule_id: z.number().nullish(),
  events: z.array(ResourceCalendarEventSchema).nullish()
})

export const ResourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  short_code: z.string(),
  type: z.string(),
  job_role__id: z.number(),
  job_role__char_1: z.string().describe('Name'),
  job_role__char_4: z.string().describe('Accent Color'),
  equipments: z.array(
    z.object({
      id: z.number(),
      sku: z.string(),
      description: z.string(),
      user_text_7: z.string().nullish()
    })
  ),
  calendar: z.record(z.string(), ResourceCalendarDataSchema.nullish()),
  attributes: z.record(z.string())
})

export type Resource = z.infer<typeof ResourceSchema>
export type ResourceCalendarData = z.infer<typeof ResourceCalendarDataSchema>
export type ResourceCalendarEvent = z.infer<typeof ResourceCalendarEventSchema>

export type CalendarViewType = 'month' | 'week' | 'three_days'
export type PatchChanges = Record<string, string | null>

export type ScheduleType = 'U' | 'O' | 'J' | 'W'
export type ScheduleTypeItem = {
  name: string
  type: ScheduleType
  color: CSSProperties['backgroundColor']
  allowPatch?: boolean
}
