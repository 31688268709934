import { useApp } from '@/hooks'
import { useAtomSelect } from '@/hooks/atom'
import { customFormApiV2 } from '@/services/api-service'
import { ActionButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, InputNumber } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { customFormAtom } from '../../../atoms'

export const Admin = () => {
  return (
    <div className="border-r border-border">
      <h5 className="font-bold">Admin</h5>
      <div className={'flex flex-col gap-10'}>
        <EnabledCheckbox />
        <TicketNumbering />
      </div>
    </div>
  )
}

const EnabledCheckbox = () => {
  const isPublic = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.public), []))
  const setCustomForm = useSetAtom(customFormAtom)

  return (
    <Checkbox
      data-cy="custom-form-superadmin-checkbox"
      checked={!!isPublic}
      onChange={(e) => setCustomForm((prev) => ({ ...prev, public: e.target.checked }))}
    >
      Enabled. If unset, visible to super admins only
    </Checkbox>
  )
}

const TicketNumbering = () => {
  const { notification } = useApp()
  const customFormId = useAtomSelect(customFormAtom, 'id')
  const editableTicketName = useAtomSelect(customFormAtom, 'editable_ticket_name')
  const enableFormSequence = useAtomSelect(customFormAtom, 'use_form_sequence')
  const ticketGenNumber = Number(useAtomSelect(customFormAtom, 'ticket_gen_number_ro')) || 0
  const setCustomForm = useSetAtom(customFormAtom)
  const [edit, setEdit] = useState(false)

  const mutation = useMutation(customFormApiV2.patch())

  const handleSave = async () => {
    try {
      await mutation.mutateAsync({
        id: customFormId,
        use_form_sequence: enableFormSequence,
        ticket_gen_number: ticketGenNumber
      })
      setEdit(false)
      notification.success({ message: 'Success', description: 'Ticket number saved' })
    } catch (e) {
      notification.error({ message: 'Error', description: 'Failed to save ticket number' })
    }
  }

  if (!customFormId) return null

  return (
    <div>
      <div>
        <Checkbox
          data-cy="custom-form-editable-ticket-name"
          checked={!!editableTicketName}
          onChange={(e) =>
            setCustomForm((prev) => ({ ...prev, use_form_sequence: true, editable_ticket_name: e.target.checked }))
          }
          className="mb-6"
        >
          Editable Ticket Number
        </Checkbox>
      </div>
      <div>
        <Checkbox
          data-cy="custom-form-enable-form-sequence-checkbox"
          disabled={!!editableTicketName}
          checked={!!enableFormSequence}
          onChange={(e) => setCustomForm((prev) => ({ ...prev, use_form_sequence: e.target.checked }))}
        >
          Use Form Specific Ticket Numbering
        </Checkbox>
      </div>
      {enableFormSequence && (
        <div className={'flex flex-row gap-10 items-center px-24 mt-4'}>
          <span>Last Ticket Number:</span>
          <div className={'flex gap-6 items-center'}>
            <InputNumber
              disabled={!edit}
              readOnly={!edit}
              onChange={(value) => setCustomForm((prev) => ({ ...prev, ticket_gen_number_ro: value }))}
              value={ticketGenNumber}
              className={'min-w-[150px]'}
              data-cy="custom-form-last-ticket-number-input"
            />
            <ActionButton hidden={edit} size={'small'} iconName={'fa:edit'} onClick={() => setEdit(true)} />
            <ActionButton
              hidden={!edit}
              loading={mutation.isLoading}
              type={'primary'}
              size={'small'}
              iconName={'fa:check'}
              onClick={handleSave}
            />
            <ActionButton
              hidden={!edit}
              type={'default'}
              size={'small'}
              iconName={'fa:close'}
              onClick={() => setEdit(false)}
            />
          </div>
        </div>
      )}
    </div>
  )
}
