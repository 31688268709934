import { companyApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Form } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const PrimaryCompanyField = () => {
  const primaryCompany = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (v) => v.primary_company || undefined), [])
  )
  const setCustomForm = useSetAtom(customFormAtom)

  const handlePrimaryCompanyChange = (value?: number) => {
    setCustomForm((prev) => ({
      ...prev,
      primary_company: value
    }))
  }

  return (
    <Form.Item label="Primary Company">
      <QuerySelect
        apiEndpoint={companyApi.list}
        apiSearchBy={(text) => ({ search: text })}
        mapOption={['id', 'name']}
        apiQueryParams={{
          compact: 1
        }}
        apiValueBy="id"
        onChange={handlePrimaryCompanyChange}
        value={primaryCompany}
        showCopyText={false}
      />
    </Form.Item>
  )
}
