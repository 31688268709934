import { getTableViewType } from '@/modules/module-builder/utils/get-table-view-type'
import { tableViewApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormItemProps, Select, SelectProps } from 'antd'
import { useEffect, useMemo } from 'react'

type Props = {
  formItemProps?: FormItemProps
  selectProps?: SelectProps<any>
}

export const TableViewSelect = ({ formItemProps, selectProps }: Props) => {
  const form = Form.useFormInstance()
  const moduleType = Form.useWatch('view_type', form) ?? ''
  const cardType = Form.useWatch(['data', 'cardType'], form) ?? ''
  const sourceType = Form.useWatch(['data', 'source_type'], form)
  const selectedView = Form.useWatch('selected_view', form)

  const allTableViewsQuery = useQuery(
    tableViewApi.list({
      fields: 'id,name',
      list_all: 1,
      limit: 'None' as any,
      ...(moduleType === 'M' && cardType === 'D' && { type__in: 'T,L,I' }),
      ...(moduleType !== 'M' &&
        cardType !== 'D' && {
          type__eq: getTableViewType({ view_type: moduleType, data: { source_type: sourceType } })
        })
    })
  )

  const options = useMemo(() => {
    const tableViews = allTableViewsQuery.data?.items ?? []
    return tableViews
      .filter((t) => t.type !== 'I' && t.type !== 'M')
      .sort((a, b) => a.name.localeCompare(b.name, 'en'))
      .map((item) => ({
        label: item.name,
        value: item.id
      }))
  }, [allTableViewsQuery.data?.items])

  // reset selected view if it's not in the options
  useEffect(() => {
    if (options.length === 0) {
      return
    }

    if (selectedView && !options.find((o) => o.value === selectedView)) {
      form.setFieldsValue({ selected_view: null })
    }
  }, [selectedView, form, options])

  return (
    <Form.Item label="Default Table View" name="selected_view" {...formItemProps}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        options={[{ label: 'Default', value: null }, ...options]}
        disabled={allTableViewsQuery.isLoading || allTableViewsQuery.isFetching}
        loading={allTableViewsQuery.isLoading || allTableViewsQuery.isFetching}
        showSearch
        optionFilterProp="label"
        {...selectProps}
      />
    </Form.Item>
  )
}
