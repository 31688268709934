import { useApp } from '@/hooks'
import { FormView } from '@/layouts/views'
import { useIsSuperAdmin } from '@/modules/auth/hooks'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { customFormApi, propertyApi, reportBuilderApi } from '@/services/api-service'
import { ReportBuilderReport } from '@/types/report-builder-report'
import { SectionTitle } from '@/ui/section-title'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Input, Spin } from 'antd'
import { useLocation } from 'react-router-dom'
import { DragAndDropContainer } from './drag-and-drop-container'
import { FilterYourDataSection } from './filter-your-data-section'
import { useReport } from './hooks'
import { IncludeLineItemCheckbox } from './include-line-item-checkbox'
import { SaveAndDownloadSection } from './save-and-download-setion'
import { SelectCustomFormsSection } from './select-custom-forms-section'
import { Property } from './types'

export const EditReportView = view<any, any>(Component, {
  title: ({ data }) => data?.title || '',
  loader: async ({ params }) => {
    const reportId = Number(params.id)
    const report = await fetchQuery(
      reportBuilderApi.get<ReportBuilderReport>(reportId, undefined, { fields: 'id,name' })
    )
    return { title: `Edit ${report.name || 'Report'}` }
  }
})

function Component() {
  const isSuperAdmin = useIsSuperAdmin()
  const [form] = Form.useForm()
  const { l } = useApp()
  const { pathname } = useLocation()

  const { programmedReport, reportQuery } = useReport()

  const customFormsQuery = useQuery({
    ...customFormApi.list({
      incl_deleted: 1,
      limit: 'None' as any,
      show_all: true,
      fields: 'id,name',
      primary_company_id__eq: isSuperAdmin && programmedReport ? programmedReport?.company_id : undefined
    })
  })

  const propertiesQuery = useQuery({
    ...propertyApi.list<Property>({
      property_group__custom_form_id__in: customFormsQuery.data?.items?.map((cf) => cf.id).join(','),
      limit: 'None' as any,
      fields:
        'id,name,property_type__name,property_group__custom_form__name,property_group__custom_form,key,property_group__name'
    })
  })

  const isLoading = reportQuery.isLoading || customFormsQuery.isLoading || propertiesQuery.isLoading

  if (isLoading) {
    return (
      <div className="w-full flex h-full items-center justify-center">
        <Spin spinning />
      </div>
    )
  }

  if (!reportQuery.data) {
    return null
  }

  return (
    <FormView
      header={{
        actions: [<SaveAndDownloadSection key="save-and-download" form={form} />],
        backButton: true,
        title: `Edit ${l('CustomReport')}`,
        breadcrumbs: pathname.includes('programmed_reports') // currently showing only for base programmed reports,
        // because for this route current view is not wrapped to layout with breadcrumbs (for now)
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...reportQuery.data,
          is_line_item_report: reportQuery.data.root_model_name === 'line item'
        }}
      >
        <Form.Item name="displayfield_set" hidden noStyle />
        <div>
          <SectionTitle rounded number={1}>
            Start your report
          </SectionTitle>
          <div className="flex gap-x-20 mt-16">
            <Form.Item label="Report Name" name="name" rules={[{ required: true }]} className="w-4/12">
              <Input />
            </Form.Item>
            <Form.Item label="Report Description" name="description" className="w-8/12">
              <Input />
            </Form.Item>
          </div>
          <div className="flex gap-x-40">
            <IncludeLineItemCheckbox />
          </div>
        </div>
        <div className="grid grid-cols-2">
          <SelectCustomFormsSection customForms={customFormsQuery.data?.items || []} />
          <FilterYourDataSection />
        </div>
        <DragAndDropContainer report={reportQuery.data} properties={propertiesQuery.data?.items || []} />
        <div className="mt-16">
          <Form.Item name="options" hidden noStyle />
          <Form.Item name={['options', 'merge_fields']} valuePropName="checked">
            <Checkbox>Merge Identically Named Fields</Checkbox>
          </Form.Item>
          <Form.Item name={['options', 'simple_header']} valuePropName="checked">
            <Checkbox>Simple Header</Checkbox>
          </Form.Item>
          <Form.Item name={['options', 'merge_li_names']} valuePropName="checked">
            <Checkbox>Merge Line Item Names</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </FormView>
  )
}
