import { StatusSelect } from '@/modules/ticket/components'
import { useTicketModule } from '@/modules/ticket/list/hooks'
import { Form } from 'antd'
import { FC } from 'react'
import { useTicketBatchJob } from '../../hooks/use-ticket-batch-job'
import { TicketBulkAction } from './TicketBulkAction'

export const TicketChangeStatusButton: FC = () => {
  const [form] = Form.useForm<{ new_status: number }>()

  const { moduleFormId } = useTicketModule()
  const { submit, mutation } = useTicketBatchJob()

  const onOk = async (closeFn: any) => {
    try {
      await form.validateFields()
    } catch (error) {
      return
    }

    return submit(
      {
        job_name: 'Change Tickets Status',
        actions: [{ action: 'change_status', new_status: form.getFieldValue('new_status'), _enable: true }]
      },
      () => closeFn(true)
    )
  }

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:diagram-nested', children: 'Change Status' }}
      modal={{
        title: 'Change Tickets Status',
        okText: 'Change Status',
        onOk: onOk,
        okButtonProps: { loading: mutation.isLoading }
      }}
      optionsTitle={'Update Options'}
    >
      <Form form={form} layout={'vertical'} validateTrigger={'onBlur'}>
        <Form.Item label={'New Status'} name={'new_status'} rules={[{ required: true, message: 'Required' }]}>
          <StatusSelect formId={moduleFormId} />
        </Form.Item>
      </Form>
    </TicketBulkAction>
  )
}
