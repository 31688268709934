import { useApp } from '@/hooks'
import { userDataApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useUserDataQuery } from './use-user-data-query'

export const useUpsertUserData = () => {
  const { notification } = useApp()
  const { userDataQuery } = useUserDataQuery()

  const updateUserDataMutation = useMutation({
    ...userDataApi.update(),
    onSuccess: async () => {
      await userDataQuery.refetch()
      notification.success({ message: 'Configuration updated successfully' })
    }
  })

  const createUserDataMutation = useMutation({
    ...userDataApi.create(),
    onSuccess: async () => {
      await userDataQuery.refetch()
      notification.success({ message: 'Configuration created successfully' })
    }
  })

  const upsertUserData = async (data: any) => {
    if (data.id) {
      await updateUserDataMutation.mutateAsync(data)
    } else {
      await createUserDataMutation.mutateAsync(data)
    }
  }

  return {
    mutation: updateUserDataMutation || createUserDataMutation,
    upsertUserData
  }
}
