import { RecentActivities } from '@/components'
import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { useApp, useSession } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { eventApi, userApi } from '@/services/api-service'
import { Button, Icon } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Spin, Switch } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { formTypeAtom, sharedDataAtom } from '../atom'
import { useFetchProfile } from '../hooks'
import { SettingsForm } from './form-settings'

export const SettingsTab = () => {
  const [settingsForm] = useForm()
  const { notification } = useApp()
  const [showActivity, setShowActivity] = useState(false)
  const formType = useAtomValue(formTypeAtom)
  const sharedData = useAtomValue(sharedDataAtom)
  const formValues = Form.useWatch([], settingsForm)
  const { id } = useParams<{ id: string }>()
  const { company } = useSession()
  const eventsQuery = useQuery(eventApi.list({ target: id, type: 'profile' }))
  const { data: profileData, refetch, isLoading } = useFetchProfile()
  const mutationSpec = formType === 'add' ? userApi.create() : userApi.update()

  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async () => {
      notification.success({ message: `Settings ${formType === 'add' ? 'Added' : 'Updated'}` })
      await refetch()
    },
    onError: (error: Error | any) => {
      if (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors.length > 0) {
        notification.error({ message: error?.response?.data?.non_field_errors[0] })
      } else {
        notification.error({ message: `Settings failed to ${formType === 'add' ? 'Add' : 'Update'}` })
      }
      console.log(error)
    }
  })

  const handleFormSubmit = () => {
    const data = {
      ...sharedData,
      id: profileData?.user?.pk,
      company_id: company?.id,
      profile_id: Number(id),
      contact_id: profileData?.contact?.id,
      role_id: profileData?.role,
      phone_number: sharedData.phone_number
        ? `+1${sharedData?.phone_number?.replace(/\D/g, '')}`
        : sharedData?.phone_number,
      userdata_json: {
        ...profileData?.userdata_json,
        ...sharedData?.userdata_json,
        features: {
          ...(sharedData?.userdata_json?.features || {}),
          ...(formValues?.features || {})
        },
        preferences: {
          military_time_input: formValues?.military_time_input,
          enable_v2_ticket_form: formValues?.enable_v2_ticket_form
        }
      }
    }
    saveFormMutation.mutateAsync(data)
  }

  const handleCancelClick = () => {
    navigate('/profiles')
  }

  useEffect(() => {
    if (profileData) {
      const settings = profileData?.userdata_json?.preferences
      const features = profileData?.userdata_json?.features
      if (settings && Object.keys(settings).length > 0) {
        // notification.push([])
        settingsForm.setFieldsValue({ ...settings })
      }

      if (settings && Object.keys(features || {}).length > 0) {
        settingsForm.setFieldsValue({ features })
      }
    }
  }, [profileData, id])
  return (
    <Spin spinning={isLoading || saveFormMutation.isLoading}>
      <div className="flex items-center justify-between bg-white">
        <h4>Settings</h4>
        <div className="flex gap-8">
          <Button type="default" className="group hover:!text-gray-500 hover:opacity-75" onClick={handleCancelClick}>
            <Icon name="svg:cancel" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" /> Cancel
          </Button>
          <Button
            type="success"
            onClick={handleFormSubmit}
            disabled={saveFormMutation.isLoading}
            loading={saveFormMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            {formType === 'add' ? 'Save' : 'Update'}
          </Button>
        </div>
      </div>
      <SettingsForm form={settingsForm} />

      <div>
        <div className="flex items-center mb-16">
          <h4 className="mr-10">Recent Activity</h4>
          <div className="relative -top-2">
            <Switch checked={showActivity} onChange={(x) => setShowActivity(x)} />
          </div>
        </div>
        {showActivity && (
          <>
            <ActivityLogsModal query={eventApi.list} queryVariables={{ target: id, type: 'profile' }} />
            <div className="mt-16">
              <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
            </div>
          </>
        )}
      </div>
    </Spin>
  )
}
