import { useSession } from '@/hooks'
import { Button } from '@/ui'
import { Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { ChangeEvent, useEffect, useState } from 'react'
import { companyStageAtom, customFormAtom, stageTableItemAtom } from './atoms'
import { useCustomForms } from './hooks'

export const StageInput = ({ name, data, id, title }: { name: string; data: string; id: number; title: string }) => {
  const [active, setActive] = useState(true)
  const [input, setInput] = useState('')
  const setCustomForm = useSetAtom(customFormAtom)
  const customForm = useAtomValue(customFormAtom)
  const companyStage = useAtomValue(companyStageAtom)
  const setCompanyStage = useSetAtom(companyStageAtom)

  const stageTableItem = useAtomValue(stageTableItemAtom)
  const setStageTableItem = useSetAtom(stageTableItemAtom)

  const { company } = useSession()
  const { data: customFormData } = useCustomForms()

  const setCustomFormChange = (id: number, title: string, e: string) => {
    if (customForm.length === 0) {
      const csForm = customFormData?.items.find((cf) => cf.id === id)
      if (csForm && csForm.id) {
        const newCsForm = {
          id: csForm.id,
          userdata: {
            ...csForm.userdata,
            custom_status: {
              ...csForm?.userdata?.custom_status,
              [title]: e
            }
          }
        }
        setCustomForm([newCsForm])
      }
    } else {
      const selectedCustomFormIndex = customForm.findIndex((cf) => cf.id === id)
      if (selectedCustomFormIndex !== -1) {
        setCustomForm((prev) =>
          prev.map((cf) =>
            cf.id === id
              ? {
                  ...cf,
                  userdata: {
                    ...cf.userdata,
                    custom_status: {
                      ...cf?.userdata?.custom_status,
                      [title]: e
                    }
                  }
                }
              : cf
          )
        )
      } else {
        const csForm = customFormData?.items.find((cf) => cf.id === id)
        const newCsForm = {
          id,
          userdata: {
            ...csForm?.userdata,
            custom_status: {
              ...csForm?.userdata?.custom_status,
              [title]: e
            }
          }
        }
        // setCustomForm([...customForm,newCsForm])
        setCustomForm((prev) => [...prev, newCsForm])
      }
    }
  }

  const setCompanyStageChange = (id: number, title: string, e: string) => {
    const cs = {
      id,
      data: {
        ...companyStage?.data,
        options: {
          ...companyStage?.data?.options,
          custom_status: {
            ...company?.data?.options?.custom_status,
            ...companyStage?.data?.options?.custom_status,
            [title]: e
          }
        }
      }
    }

    setCompanyStage({
      id: cs.id,
      data: cs.data
    })
  }

  const updateStage = (text: string) => {
    const newStageTableItem = [...stageTableItem]
    const selectedTableItemIndex = newStageTableItem.findIndex((sti) => sti.key === title)
    newStageTableItem[selectedTableItemIndex][name] = text
    setStageTableItem(newStageTableItem)
  }
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateStage(e.target.value)
    if (company.id !== id) {
      setCustomFormChange(id, title, e.target.value)
    } else {
      setCompanyStageChange(id, title, e.target.value)
    }
  }

  const onEyeClick = () => {
    if (active) {
      setInput(data)
      setActive(false)
      updateStage('--hide--')
      // onStageChange(id, title, '--hide--')
      setCustomFormChange(id, title, '--hide--')
    } else {
      setActive(true)
      updateStage(input)
      setCustomFormChange(id, title, '')
    }
  }

  useEffect(() => {
    if (data !== '--hide--') {
      setActive(true)
    } else {
      setActive(false)
    }
    if (company.id !== id) {
      setCustomFormChange(id, title, data)
    } else {
      setCompanyStageChange(id, title, data)
    }
  }, [data])

  return (
    <div className="relative">
      <Input
        defaultValue={data}
        value={data}
        onChange={(e) => onInputChange(e)}
        className={!active ? 'border-dashed opacity-60' : ''}
        addonAfter={
          <Button size={'small'} type={'text'} iconName={active ? 'fa:eye' : 'fa:eye-slash'} onClick={onEyeClick} />
        }
      />
    </div>
  )
}
