import { useSession } from '@/hooks'
import { useRedirect } from '@/hooks/use-redirect'
import { DashboardLayout } from '@/layouts/dashboard-layout'
import { navigate } from '@/routing/helpers'
import { BackButton, LinkButton } from '@/ui/button'
import { HttpStatusCode, getHttpStatus } from '@/utils/http'
import * as Sentry from '@sentry/react'
import { Result } from 'antd'
import { AxiosError } from 'axios'
import { FC, useEffect, useMemo } from 'react'
import { useLocation, useRouteError } from 'react-router-dom'
import { PageView } from '../page-view'

export type ErrorViewProps = {
  code?: HttpStatusCode | number
  title?: string
  description?: string
  redirect?: boolean
  dashboard?: boolean
  showHeader?: boolean
}

export const ErrorView: FC<ErrorViewProps> = ({ code, title, description, redirect, dashboard, showHeader }) => {
  const error = useRouteError() as AxiosError
  const errorCode = code || error?.response?.status || 500

  const { isBelongsToV1 } = useRedirect()
  const { pathname } = useLocation()
  const { authenticated } = useSession()

  const [rStatus, rTitle, rDescription] = useMemo(() => {
    const status = getHttpStatus(errorCode as HttpStatusCode)

    let resultStatus: 403 | 404 | 500 = 404 // AntD's 404 Result By Default
    if (errorCode >= 500) resultStatus = 500
    else if (errorCode === 403 || errorCode === 401) resultStatus = 403

    return [resultStatus, title || status.title, description || status.message]
  }, [errorCode, description, title])

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  if (redirect && isBelongsToV1(pathname)) {
    navigate(pathname, { v: 1 })
    return <></>
  }

  const header = showHeader && authenticated ? { breadcrumbs: true, title: rTitle, backButton: true } : undefined

  const pageView = (
    <PageView header={header} className="flex items-center justify-center h-full w-full">
      <Result
        className={'flex flex-col items-center justify-center'}
        status={rStatus as any}
        title={rTitle}
        subTitle={rDescription}
        extra={
          <div className={'flex flex-row gap-10'}>
            <BackButton shape={'round'} iconName={'fa:arrow-left'}>
              Back
            </BackButton>
            <LinkButton shape={'round'} to={'/v2'} iconName={'fa:home'}>
              Home
            </LinkButton>
          </div>
        }
      />
    </PageView>
  )

  if (dashboard && authenticated) return <DashboardLayout>{pageView}</DashboardLayout>

  return pageView
}
