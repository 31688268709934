import { useApp } from '@/hooks'
import { url } from '@/routing/helpers'
import { equipmentApi } from '@/services/api-service'
import { QueryDeleteButton, QueryEditButton } from '@/ui'
import { Equipment } from '../schemas'
import { CopyButton } from './copy-button'
import { RenameButton } from './rename-button'

type Props = {
  equipment: Equipment
}

export const Actions = ({ equipment }: Props) => {
  const { l } = useApp()

  return (
    <div className={'flex flex-row'}>
      <QueryEditButton to={url(`${equipment.id}/edit`)} />
      <QueryDeleteButton id={equipment.id} api={equipmentApi.delete} name={l('SKU')} />
      <CopyButton equipment={equipment} />
      <RenameButton equipment={equipment} />
    </div>
  )
}
