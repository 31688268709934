import { Input } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { FetchWellDataButton } from './FetchWellDataButton'

export const FetchWellDataButtonConfig: ScriptedButtonConfig = {
  type: 'common_fetch_well_data_button',
  iconName: 'fa:oil-well',
  label: 'Fetch Well Data',
  group: 'Common',
  render: () => <FetchWellDataButton />,
  vars: [
    {
      name: 'config_key',
      label: 'Config Key',
      render: (_, props) => <Input {...props} />
    }
  ]
}
