import { queryLimitAtom } from '@/modules/jas/scheduler/atoms'
import { Select } from 'antd'
import { useAtom } from 'jotai/index'

type Props = {
  onChange?: (value: number) => void
}

// TODO: Think about moving this to a global file
const options = [
  {
    label: '25',
    value: 25
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '250',
    value: 250
  },
  {
    label: '500',
    value: 500
  }
]

export const LimitSelect = ({ onChange }: Props) => {
  const [limit, setLimit] = useAtom(queryLimitAtom)

  const handleChange = (value: number) => {
    setLimit(value)
    onChange?.(value)
  }

  return (
    <div className="flex items-center gap-x-10">
      <div className="font-bold">Display</div>
      <Select onChange={handleChange} className="w-[100px]" value={limit} options={options} />
    </div>
  )
}
