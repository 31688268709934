import { useSession } from '@/hooks'
import { ErrorView, PageView } from '@/layouts/views'
import { useCanEditCustomFormCallback } from '@/modules/auth/hooks'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { customFormApiV2 } from '@/services/api-service'
import { LinkButton } from '@/ui/button'
import { useNavigate, useParams } from 'react-router-dom'
import { SETTINGS_PATHS } from '../../constants'
import { AddSectionButton } from './add-section-button'
import { CompanyIconUpload } from './company-icon-upload'
import { CustomFormLayoutFilter } from './custom-form-layout-filter'
import { EditCustomFormSectionModal } from './edit-custom-form-section-modal'
import { EditHiddenReferencesLabelsModal } from './edit-hidden-references-labels-modal'
import { EditRelatedLabelModal } from './edit-related-label-modal'
import { EditTicketStatusModal } from './edit-ticket-status-modal'
import { useCustomFormQuery } from './hooks'
import { PlaceholderBox } from './placeholder-box'
import { PropertyGroups } from './property-groups'

export const CustomFormLayoutView = view<any, any>(Component, {
  loader: async ({ params }) => {
    const customFormId = params.id
    if (!customFormId) return

    const customForm = await fetchQuery(
      customFormApiV2.get<any>(Number(customFormId), undefined, { fields: 'id,name' })
    )
    return { title: `Layout Editor: ${customForm.name || 'Custom Form'}` }
  },
  title: ({ data }) => data?.title || ''
})

function Component() {
  const { id } = useParams()
  const { company } = useSession()
  const navigate = useNavigate()
  const canEditCustomForm = useCanEditCustomFormCallback()

  const { customFormQuery, isLoading } = useCustomFormQuery()

  if (!id) {
    navigate(SETTINGS_PATHS.customForms)
    return null
  }

  if (
    !customFormQuery.isFetching &&
    !canEditCustomForm({ primary_company_id: customFormQuery.data?.primary_company })
  ) {
    return <ErrorView code={403} description="You don't have permissions to edit this page" showHeader></ErrorView>
  }

  return (
    <PageView
      loading={isLoading}
      header={{
        breadcrumbs: true,
        title:
          'Custom Form Layout Editor' +
          (customFormQuery.data
            ? `: ${customFormQuery.data?.name} (${company.name}) (ID: ${customFormQuery.data?.id})`
            : ''),
        backButton: true,
        actions: [
          <AddSectionButton key="add-section-button" />,
          <LinkButton
            key={'custom-form-settings-link'}
            to={SETTINGS_PATHS.editCustomForm(id)}
            iconName="fa:gears"
            type="primary"
            data-cy="goto-custom-form-settings"
          >
            Custom Form Settings
          </LinkButton>
        ]
      }}
      className="pt-0"
    >
      {!isLoading && (
        <>
          <div>
            <div>
              <CustomFormLayoutFilter />
              <div className="grid grid-cols-3 gap-x-30 mb-16">
                <EditRelatedLabelModal
                  ticketLabelKey="ticket__office__label"
                  labelFilter="Customer Office"
                  staticLabel="Customer"
                  fieldsConfigKey="customer_office"
                />
                <EditRelatedLabelModal
                  ticketLabelKey="ticket__contact__label"
                  labelFilter="Contact"
                  staticLabel="Contact"
                  fieldsConfigKey="customer"
                />
                <EditTicketStatusModal />
              </div>
              <div className="grid grid-cols-2 gap-x-30">
                <div className="flex flex-col justify-start gap-y-16">
                  <CompanyIconUpload />
                  <PlaceholderBox disabled>Ticket Office</PlaceholderBox>
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__services_provided__label"
                    labelFilter="Services provided"
                    staticLabel="Services provided for"
                    fieldsConfigKey="customer_office"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__services_ordered__label"
                    labelFilter="Services ordered"
                    staticLabel="Services ordered by"
                    fieldsConfigKey="customer"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__info_title__label"
                    labelFilter="Customer Info"
                    staticLabel="Customer Info"
                    fieldsConfigKey="customer"
                    hideFieldsConfig
                  />
                </div>
                <div className="flex flex-col justify-start gap-y-16">
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__name__label"
                    labelFilter="Ticket"
                    staticLabel="Ticket Name"
                    fieldsConfigKey="name"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__timepoint_due__label"
                    labelFilter="Expected Date"
                    staticLabel="Timepoint Due"
                    fieldsConfigKey="timepoint_due"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__timepoint_submitted__label"
                    labelFilter="Date Submitted"
                    staticLabel="Timepoint Submitted"
                    fieldsConfigKey="timepoint_submitted"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__location__label"
                    labelFilter="Location"
                    staticLabel="Location"
                    fieldsConfigKey="location"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__afe__label"
                    labelFilter="AFE"
                    staticLabel="AFE"
                    fieldsConfigKey="afe"
                  />
                  <EditRelatedLabelModal
                    ticketLabelKey="ticket__job_code__label"
                    labelFilter="Job Code"
                    staticLabel="Job Code"
                    fieldsConfigKey="job_code"
                  />
                  <EditHiddenReferencesLabelsModal />
                </div>
              </div>
              <PropertyGroups />
            </div>
          </div>
          <EditCustomFormSectionModal />
        </>
      )}
    </PageView>
  )
}
