import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { refreshQueries } from '@/modules/ticket/form/helpers'
import { ticketApiV2 } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import { Ticket, TicketSchema } from '../schemas'

export const useTicketLoader = (
  ticketId?: string | number | null,
  formId?: string | number | null,
  enabled = true,
  options: {
    modifiedCheck?: boolean
  } = {}
) => {
  const params = useParams<{ formId: string; ticketId: string }>()

  // prepare ticketId and formId from params or props
  ticketId = ticketId || params.ticketId || null
  formId = formId || params.formId || null

  // fetch ticket
  const _ticketId = Number(ticketId)
  const ticketQuery = useQuery({
    ...ticketApiV2.get<Ticket>(_ticketId, {}, { fields: TicketSchema }),
    retry: 1,
    // refetchOnMount: true,
    // refetchInterval: REFETCH_INTERVAL,
    // refetchOnWindowFocus: true,
    enabled: enabled && !!_ticketId // only fetch if ticketId is present
  })
  const apiTicket = ticketQuery.data

  // fetch custom form
  const _formId = apiTicket?.custom_form_id || Number(formId) || 0
  const { customFormsQuery: formsQuery, getCustomForm } = useGlobalCustomForms()
  const apiCustomForm = getCustomForm(_formId)

  // ticket auto-refresh (polling)
  // todo: use websocket
  const modifiedCheck = options.modifiedCheck ?? true
  const ticketModifiedQuery = useQuery({
    ...ticketApiV2.get<Ticket>(_ticketId, {}, { fields: 'modified_at' }),
    retry: 1,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    enabled: modifiedCheck && enabled && !!_ticketId // only fetch if ticketId is present
  })
  useEffect(() => {
    if (ticketQuery.isFetching || !ticketModifiedQuery.data || !apiTicket) return
    if (dayjs(ticketModifiedQuery.data?.modified_at).isAfter(apiTicket?.modified_at)) {
      refreshQueries()
    }
  }, [apiTicket, apiTicket?.modified_at, ticketModifiedQuery.data, ticketQuery.isFetching])

  return {
    ticketId,
    formId,
    ticketQuery,
    ticketModifiedQuery,
    formQuery: formsQuery,
    apiTicket,
    apiCustomForm,
    isLoading: ticketQuery.isLoading || formsQuery.isLoading,
    isFetching: ticketQuery.isFetching || formsQuery.isFetching
  }
}
