import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CATEGORY_NAMES } from '../../constants'
import { Item } from '../item'
import { SortableItem } from '../sortable-item'

type ColumnKey = 'available' | 'selected'

type Props = {
  id: ColumnKey
  items: any[]
  extra?: React.ReactNode
  onSearch?: (value: string) => void
}

export const DroppableColumn = ({ id, items, extra }: Props) => {
  const { setNodeRef } = useDroppable({ id })

  return (
    <div>
      <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
        <div className="border border-border rounded p-12 mt-12 h-[calc(100vh-220px)] overflow-auto">
          {extra}
          <div ref={setNodeRef}>
            {items.map((item: any, index) => {
              return (
                <div key={item.id} className="mb-8 last-of-type:mb-0">
                  {id === 'available' && item.category !== items[index - 1 || 0]?.category && (
                    <h5 className="capitalize font-bold my-16">
                      {CATEGORY_NAMES[item.category as keyof typeof CATEGORY_NAMES] || item.category || 'uncategorized'}{' '}
                      fields
                    </h5>
                  )}
                  <SortableItem id={item.id}>
                    <Item item={item} isDragging={false} />
                  </SortableItem>
                </div>
              )
            })}
          </div>
        </div>
      </SortableContext>
    </div>
  )
}
