import { Filters } from '@/modules/jas/job-manager/types'
import { ListParams } from '@/types/api/core'
import { RequiredKeys } from '@/types/general'
import { SearchData } from '@/types/search'
import { isEmpty } from 'lodash'
import { TableView } from '../table-view/types'

export const getSearchFilterQuery = (search: SearchData): RequiredKeys<ListParams, 'Q'> => {
  const { field, type } = search
  const value = (search.value || '').trim()

  // empty search
  if (isEmpty(value)) {
    return { Q: [] }
  }

  const query: RequiredKeys<ListParams, 'Q'> = { Q: [] }

  // note: `contacts__contact__name` is a virtual field for first_name and last_name of contact
  if (field === 'contacts__contact__name') {
    query.team_members = value
  } else if (field.startsWith('p:')) {
    // search by ticket properties
    query.q = value
    query.q_pkey = field.slice(2)
    query.q_type = type
  } else if (['afe', 'job_code', 'location'].some((f) => field.startsWith(f))) {
    const f = field.split('__')[0]
    query.Q.push(`${f}_str__${type}|${value}`)
    query.Q.push(`${field}__${type}|${value}`)
    query.Q.push('or')
  } else if (field === 'contact__name') {
    query.Q.push(`customer__first_name__${type}|${value}`)
    query.Q.push(`customer__last_name__${type}|${value}`)
    query.Q.push('or_c')
  } else {
    query.Q.push(`${field}__${type}|${value}`)
  }

  return query
}

export const getAdvanceSearchFilterQuery = (search: Filters) => {
  if (isEmpty(search)) {
    return { Q: [] }
  }
  const query: string[] = []
  let type
  const inFilters = ['contacts__contact_id', 'computed_status']
  const rangeFilters = ['timepoint_due', 'modified_at__date', 'created_at__date']
  const regexFilters = ['custom_form__id']

  Object.entries(search)
    .filter(([_, value]) => !isEmpty(value))
    .map(([filter, value], i) => {
      if (inFilters.includes(filter)) type = 'in'
      else if (rangeFilters.includes(filter)) type = 'range'
      else if (regexFilters.includes(filter)) type = 'regex'
      else type = 'icontains'

      if (filter === 'contact__name' && value) {
        query.push(`customer__first_name__${type}|${value}`)
        query.push(`customer__last_name__${type}|${value}`)
        query.push('or_c')
      } else {
        query.push(`${filter}__${type}|${value}`)
      }
      if (i !== 0) {
        query.push('and_c')
      }
    })
  return { Q: query }
}

export const getCustomFiltersQuery = (customFilters: { [n: string]: string }) =>
  Object.fromEntries(Object.entries(customFilters).filter(([_, val]) => val))

export const isCustomFiltersEnabled = (activeView: TableView | null) =>
  Boolean(activeView?.config?.use_custom_filters && activeView.config.custom_filters?.enabled)
