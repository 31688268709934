import { useApp, useModalState, useSession } from '@/hooks'
import { afeApi } from '@/services/api-service'
import { ActionButton, ButtonProps, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { Afe } from '../schemas'

type Props = ButtonProps & {
  afe?: Afe
}

export const ModalButton: FC<Props> = ({ afe, ...buttonProps }) => {
  const { company } = useSession()
  const { l, notification } = useApp()
  const [form] = Form.useForm()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const saveMutation = useMutation(afe?.id ? afeApi.patch() : afeApi.create())

  const handleSave = async () => {
    const data = await form.validateFields()

    if (!afe?.id) data.company = company.id

    await saveMutation
      .mutateAsync(data)
      .then(() => {
        closeModal()
        notification.success({
          message: `${l('AFE')} Saved`,
          description: `${l('AFE')} ${data.user_afe_no} has been saved`
        })
      })
      .catch(() => {
        notification.error({
          message: `${l('AFE')} Error`,
          description: `Failed to save ${l('AFE')} ${data.user_afe_no}`
        })
      })

    if (!afe?.id) form.resetFields()
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          width={500}
          title={afe?.id ? 'Update ' + afe?.user_afe_no : `Add ${l('AFE')}`}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-button' }}
          okText="Save"
          cancelText="Cancel"
          onCancel={closeModal}
        >
          <Form form={form} initialValues={afe} layout="vertical">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item
              label={`${l('AFE')} Number`}
              name="user_afe_no"
              rules={[{ required: true }]}
              data-cy="user-afe-no-input"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Budget" name="budget">
              <InputNumber className={'w-full'} prefix="$" data-cy="afe-budget-input" />
            </Form.Item>
            <Form.Item label="External ID" name="external_id" data-cy="user-external-id">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
