import { useAppTheme } from '@/hooks/use-app-theme'
import { SCHEDULE_TYPES, ScheduleType } from '@/utils'
import classNames from 'classnames'

type Props = {
  labels?: (typeof SCHEDULE_TYPES)[ScheduleType][]
}

export const ScheduleLabels = ({ labels = Object.values(SCHEDULE_TYPES).map((type) => type) }: Props) => {
  const { token } = useAppTheme()

  return (
    <div className="flex items-center justify-center gap-x-20">
      {labels.map((label, index) => (
        <div key={index} className="flex items-center gap-x-12">
          <div
            style={{
              backgroundColor: label.color,
              color: 'textColor' in label ? label.textColor : token.colorTextBase
            }}
            className={classNames('w-32 h-32 rounded-full flex items-center justify-center')}
          >
            {label.titleShort}
          </div>
          <div>{label.title}</div>
        </div>
      ))}
    </div>
  )
}
