import { TABLE_DEFAULT_PAGE_SIZE } from '@/constants/general'
import { FIELD_LABELS } from '@/constants/labels'
import { programmedReportApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { ProgrammedReport } from '../schema'

export const useProgrammedReportsQuery = () => {
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('id')

  const programmedReportsQuery = useQuery({
    ...programmedReportApi.list<ProgrammedReport>({
      type__in: 'R,E',
      limit,
      page,
      order,
      ...(search && { description: search.trim(), name: search.trim() })
    }),
    select: (data) => ({
      ...data,
      items: data.items.map(
        (item) =>
          ({
            ...item,
            report: {
              ...item.report,
              filterfield_set: item.report?.filterfield_set?.map((filterField) => ({
                ...filterField,
                _name:
                  FIELD_LABELS[`report__${filterField.field_verbose}`] ||
                  FIELD_LABELS[`lineitem__${filterField.field_verbose}`]
              }))
            },
            base_custom_report: {
              ...item.base_custom_report,
              filterfield_set: item.base_custom_report?.filterfield_set?.map((filterField) => ({
                ...filterField,
                _name:
                  FIELD_LABELS[`report__${filterField.field_verbose}`] ||
                  FIELD_LABELS[`lineitem__${filterField.field_verbose}`]
              }))
            }
          }) as ProgrammedReport
      )
    })
  })

  return {
    programmedReportsQuery,
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    order,
    setOrder
  }
}
