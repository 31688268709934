import { useSession } from '@/hooks'
import { officeRelationApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { FC } from 'react'

type Props = Omit<QuerySelectProps, 'apiEndpoint' | 'apiSearchBy' | 'renderOption'> & {
  manager?: boolean
  toOfficeId?: boolean
}

export const RelationCompanySelect: FC<Props> = ({ ...props }) => {
  const { user } = useSession()

  return (
    <QuerySelect
      apiEndpoint={officeRelationApi.list}
      apiQueryParams={{
        fields: 'id,to_office__company__id,to_office__company__name',
        manager__eq: 'True',
        to_office__company__id__eq: user?.profile?.company?.id
      }}
      apiValueBy={'to_office__company__id'}
      apiSearchBy={'to_office__company__name'}
      mapOption={['to_office__company__id', 'to_office__company__name']}
      {...props}
    />
  )
}
