import { useApp } from '@/hooks'
import { AddEditContactView } from '@/modules/rolodex/views/add-edit-contact-view'
import { Modal, ModalProps } from '@/ui'
import { isEmpty } from 'lodash'
import { FC, useCallback } from 'react'
import { useTicketCustomerOffice, useTicketFormValues, useTicketLabel } from '../../../../../hooks'

type Props = ModalProps & {
  customerId?: string | number
  onCancel?: () => void
}

export const EditCustomerModal: FC<Props> = ({ customerId, ...modalProps }) => {
  const l = useTicketLabel()

  const { notification } = useApp()
  const { setFieldValue } = useTicketFormValues()
  const customerOffice = useTicketCustomerOffice()

  const defaultCompany = customerOffice?.company_id
    ? { value: customerOffice?.company_id, label: customerOffice?.company__name }
    : undefined

  const handleSave = useCallback(
    (savedCustomer: any) => {
      if (isEmpty(customerId)) {
        // set customer for new contact
        setFieldValue('customer', {
          value: savedCustomer.id,
          label: `${savedCustomer.first_name} ${savedCustomer.last_name}`
        })
        notification.success({
          message: `${l('ticket__contact__label')} Saved`,
          description: `${l('ticket__contact__label')} saved successfully`
        })
        modalProps?.onCancel?.()
      }
    },
    [customerId, l, modalProps, notification, setFieldValue]
  )

  return (
    <Modal width={500} footer={null} {...modalProps}>
      <div className={'-ml-10 -mr-24'}>
        <AddEditContactView
          inModal
          id={customerId}
          defaultCompany={defaultCompany}
          onSave={handleSave}
          onCancel={modalProps?.onCancel}
        />
      </div>
    </Modal>
  )
}
