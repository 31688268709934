import { FC } from 'react'
import { ActionsForm } from '../actions'
import { ConditionsCard } from '../conditions'

type Props = {
  ruleIndex: number
}

export const RuleForm: FC<Props> = ({ ruleIndex }) => {
  return (
    <div className="flex flex-col gap-10">
      <ConditionsCard ruleIndex={ruleIndex} />
      <ActionsForm ruleIndex={ruleIndex} />
    </div>
  )
}
