import { clientRecordApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { z } from 'zod'

// Resourse can be employee or job role
type Resourse = {
  id: number
  job_roles?: { id: number; name: string }[]
  job_role_color?: string
}

type Props = {
  children: React.ReactNode
  resource?: Resourse
}

const JobRoleSchema = z.object({
  id: z.number(),
  char_4: z.string()
})

export const TitleColCell = ({ children, resource }: Props) => {
  const jobRoleId = resource?.job_roles?.[0]?.id || null // take first
  const jobRoleColor = resource?.job_role_color

  const jobRoleQuery = useQuery({
    ...clientRecordApi.get(jobRoleId, undefined, { fields: JobRoleSchema, label__iexact: 'Job Roles' }),
    enabled: !!jobRoleId && !jobRoleColor,
    cacheTime: Infinity,
    staleTime: Infinity
  })
  const accentColor = jobRoleColor || jobRoleQuery.data?.char_4 || undefined

  return (
    <div className="w-[240px] border-0 border-solid pl-12 border-b border-r border-border h-56 sticky left-0 shrink-0 bg-white z-10">
      <div
        style={{ backgroundColor: accentColor }}
        className={'rounded absolute left-0 top-0 w-6 h-full group-hover:bg-primary transition-colors'}
      ></div>
      {children}
    </div>
  )
}
