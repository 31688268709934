import { useAppSelector } from '@/hooks'
import { MenuItem } from '@/modules/navigation/constants'
import { getActiveKeys } from '@/modules/navigation/helpers'
import { Obj } from '@/types/general'
import { AppLink } from '@/ui/AppLink'
import { Icon } from '@/ui/icons'
import { getLabel } from '@/utils'
import { Menu, MenuProps } from 'antd'
import cn from 'classnames'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useMenus } from './hooks'
import './navigation.scss'

type AntdMenuItem = Required<MenuProps>['items'][number] & { 'data-cy'?: string }

/**
 * Transform the menu items into Antd Menu items
 * @param menu
 * @param labels
 */
const getDisplayMenuItem = (menu: MenuItem, labels: Obj): AntdMenuItem => {
  let children: AntdMenuItem[] = []
  if (menu.items && menu.items.length > 0) {
    children = menu.items.map((child) => {
      const label = getLabel(child.label, labels)
      return {
        key: child.to,
        label: (
          <AppLink v={child.v} to={child.to} data-cy={`goto-${(label || '').toLowerCase()}-page`}>
            {label}
          </AppLink>
        )
      }
    })
  }

  return {
    key: (menu.key || menu.to) as string,
    label: (
      <AppLink className={'flex flex-row items-center gap-8'} v={menu.v} to={menu.to}>
        <Icon className={`menu-item-icon icon-${(menu.icon || '').split(':')[0]}`} name={menu.icon} />
        <span className={'menu-item-label'}>{getLabel(menu.label, labels)}</span>
      </AppLink>
    ),
    children: children.length > 0 ? children : undefined
  }
}

type Props = {
  collapsed?: boolean
}

/**
 * Navigation Menu Component. This component is used to render the sidebar menu.
 * It supports static menu items and dynamic menu items from modules.
 *
 * @todo Improve support for active menu items highlighting
 * @todo build custom navigation using tailwind and NavLink
 *
 * @see src/modules/navigation/constants.ts
 * @see src/modules/navigation/helpers.ts
 * @see src/modules/navigation/AppLink.tsx
 * @see src/modules/navigation/types.ts
 * @constructor
 */
export const Navigation: React.FC<Props> = ({ collapsed = false }) => {
  // change active menu item on location change
  const location = useLocation()
  const labels = useAppSelector((state) => state.app.labels)
  const { menus } = useMenus()

  // transform nav menu items to antd menu items
  const menuItems: AntdMenuItem[] = useMemo(
    () =>
      menus.map((menu) => ({
        ...getDisplayMenuItem(menu, labels),
        'data-cy': `goto-${(menu.label || '').replace(/\s/g, '-').toLowerCase()}` // for cypress testing
      })),
    [labels, menus]
  )

  // highlight active menu item
  const [selectedKeys, openKeys] = getActiveKeys(location.pathname)

  return (
    <Menu
      className={cn('oc-nav-menu px-16 pb-[60px]')}
      theme="dark"
      mode={'inline'}
      inlineCollapsed={collapsed}
      defaultOpenKeys={openKeys}
      selectedKeys={selectedKeys}
      items={menuItems}
    />
  )
}
