import dayjs, { Dayjs } from 'dayjs'
import { CalendarView } from './types'

export const getStartDateByView = (view: CalendarView, date: Dayjs) => {
  switch (view) {
    case 'month':
      return date.startOf('month')
    case 'week':
      return date.subtract(3, 'day')
    case 'three_days':
      return date.subtract(1, 'day')
  }
}

export const getEndDateByView = (view: CalendarView, date: Dayjs) => {
  switch (view) {
    case 'month':
      return date.endOf('month')
    case 'week':
      return date.add(3, 'day')
    case 'three_days':
      return date.add(1, 'day')
  }
}

export const getDateRangeByView = (view: CalendarView, date: Dayjs): Dayjs[] => {
  const start = getStartDateByView(view, date)
  const end = getEndDateByView(view, date)
  return dayjs.range(start, end, 'day')
}
