import { PropertySelect } from '@/components'
import { Form } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { PropertiesSelect } from '../properties-select'
import { TableViewSelect } from '../table-view-select'

export const ModuleTypeTicketsCalendarFields = () => {
  const form = Form.useFormInstance()
  const customFormId = Form.useWatch(['data', 'custom_form_id'], form) as number | undefined

  const propertyInputs = [
    { key: 'requirements_pid', label: 'Requirements Property' },
    { key: 'start_datetime_pid', label: 'Start Date/Time Property' },
    { key: 'job_length_pid', label: 'Job Length Property' },
    { key: 'calendar_description_pid', label: 'Calendar Description Property' }
  ]

  return (
    <div>
      <div className="flex w-2/3 gap-x-16">
        <TableViewSelect
          formItemProps={{ label: 'Default Table View', name: 'selected_view', className: 'grow shrink-0' }}
        />
        <ForceViewCheckbox />
        <HideDefaultNoneView />
      </div>
      <div className="flex w-2/3 gap-x-16">
        <CustomFormSelect
          formItemProps={{
            label: 'Custom Form',
            name: ['data', 'custom_form_id'],
            className: 'grow'
          }}
        />
        <PropertiesSelect
          customFormsIds={customFormId ? [customFormId] : []}
          formItemProps={{
            label: 'Search Properties',
            name: ['data', 'search_props'],
            className: 'grow'
          }}
          selectProps={{ mode: 'multiple' }}
        />
      </div>
      <div className={'border p-10 rounded'}>
        <h5>Calendar Config</h5>
        <div className="grid grid-cols-4 gap-10">
          {propertyInputs.map((input) => (
            <Form.Item key={input.key} name={['data', 'calendar', input.key]} label={input.label}>
              <PropertySelect formId={customFormId} />
            </Form.Item>
          ))}
        </div>
      </div>
    </div>
  )
}
