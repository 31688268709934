import { CodeEditor } from '@/components/code-editor'
import { Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionFunctionCall } from '../../schemas'

type Props = {
  action: BpaActionFunctionCall
  onChange: (data: Partial<BpaActionFunctionCall>) => void
}

export const InputFunctionCall: FC<Props> = ({ action, onChange }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      <Form.Item
        label="Function Module"
        help={<small>e.g. common.resource.save</small>}
        tooltip="Function module to call"
        className="flex-grow"
      >
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 10 }}
          defaultValue={action.function || ''}
          onChange={(e) => onChangeDebounced({ function: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Keyword Arguments">
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={action.kwargs || ''}
          onChange={(value) => onChangeDebounced({ kwargs: value })}
          mode="yaml"
        />
      </Form.Item>
    </div>
  )
}
export const InputFunctionCallMemo = React.memo(InputFunctionCall)
