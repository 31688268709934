import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Input, InputNumber } from 'antd'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { nanoid } from 'nanoid'
import {
  addRuleToCurrentRuleTemplateAtom,
  currentRuleTemplateAtom,
  isAddModeAtom,
  removeRuleFromCurrentRuleTemplateAtom,
  setCurrentRuleTemplateCommentAtom,
  setCurrentRuleTemplateNameAtom,
  setCurrentRuleTemplateRulesAtom,
  setEmptyCurrentRuleTemplateAtom
} from '../atoms'
import { useAddRuleTemplateMutation } from '../hooks'

export const AddRuleTemplate = () => {
  const { addRuleTemplate, addRuleTemplateMutation } = useAddRuleTemplateMutation()
  const [isAddMode] = useAtom(isAddModeAtom)
  const currentRuleTemplate = useAtomValue(currentRuleTemplateAtom)
  const setIsAddMode = useSetAtom(isAddModeAtom)
  const setEmptyCurrentRuleTemplate = useSetAtom(setEmptyCurrentRuleTemplateAtom)
  const setCurrentRuleTemplateName = useSetAtom(setCurrentRuleTemplateNameAtom)
  const setCurrentRuleTemplateComment = useSetAtom(setCurrentRuleTemplateCommentAtom)
  const removeRuleFromCurrentRuleTemplate = useSetAtom(removeRuleFromCurrentRuleTemplateAtom)
  const setCurrentRuleTemplateRules = useSetAtom(setCurrentRuleTemplateRulesAtom)
  const addRuleToCurrentRuleTemplate = useSetAtom(addRuleToCurrentRuleTemplateAtom)

  if (!isAddMode || !currentRuleTemplate) {
    return null
  }

  const handleCancel = () => {
    setIsAddMode(false)
    setEmptyCurrentRuleTemplate()
  }

  return (
    <div className="w-[320px] flex flex-col items-start">
      <div className="mb-4">Create a new rule</div>
      <div className="w-full rounded-md border border-solid border-gray-300 h-add-rule-template-column p-12 overflow-auto">
        <div>
          <div className="mb-2">Name *</div>
          <Input
            value={currentRuleTemplate.name}
            onChange={(e) => setCurrentRuleTemplateName(e.target.value)}
            data-cy="rule-template-name-input"
          />
        </div>
        <div className="mt-12">
          <Input.TextArea
            rows={3}
            value={currentRuleTemplate.comment}
            onChange={(e) => setCurrentRuleTemplateComment(e.target.value)}
            data-cy="rule-template-comment-input"
          />
        </div>
        <ul className="p-0 m-0 space-y-8 mt-12">
          {currentRuleTemplate.data.map((rule, index) => (
            <div key={rule.id} className="flex gap-x-6">
              <InputNumber
                className="flex-1"
                placeholder="Day on"
                value={rule.day_on}
                min={0}
                onChange={(val) => {
                  setCurrentRuleTemplateRules({ index, val: val || 0, type: 'day_on' })
                }}
                data-cy="rule-template-day-on-input"
              />
              <InputNumber
                className="flex-1"
                placeholder="Day off"
                value={rule.day_off}
                min={0}
                onChange={(val) => {
                  setCurrentRuleTemplateRules({
                    index,
                    val: val || 0,
                    type: 'day_off'
                  })
                }}
                data-cy="rule-template-day-off-input"
              />
              <Button
                className="shrink-0"
                icon={<Icon name="fa:trash" />}
                onClick={() => removeRuleFromCurrentRuleTemplate(rule.id || '')}
                data-cy="remove-rule-template-rule"
              />
            </div>
          ))}
        </ul>
        <div className="flex justify-center mt-12">
          <Button
            icon={<Icon name="fa:add" />}
            type="text"
            size="small"
            onClick={() =>
              addRuleToCurrentRuleTemplate({
                id: nanoid(),
                day_on: NaN, // Provide NaN to make input placeholder visible in new rule
                day_off: NaN // Provide NaN to make input placeholder visible in new rule
              })
            }
            data-cy="add-rule-template-rule"
          >
            Add rule
          </Button>
        </div>
        <div className="flex justify-end gap-x-6 mt-24">
          <Button onClick={handleCancel} data-cy="cancel-adding-rule-template">
            Cancel
          </Button>
          <Button
            type="success"
            onClick={() => addRuleTemplate()}
            loading={addRuleTemplateMutation.isLoading}
            data-cy="save-adding-rule-template"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
