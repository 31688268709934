import { Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetchProfile } from '../../hooks'
import { enabledModulesAtom, selectedRoleIdAtom, selectedRolesAtom } from '../atoms'
import { useModules } from '../hooks'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'
import { ToggleSwitch } from './components/ToggleSwitch'

const AccessModules = () => {
  const { data: modulesData, isLoading } = useModules()
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const selectedModules = useAtomValue(enabledModulesAtom)
  const setSelectedModules = useSetAtom(enabledModulesAtom)
  const [selectedData, setSelectedData] = useState('')

  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)
  const { data: profileData, refetch, isLoading: profileLoading } = useFetchProfile()

  const getSelectedModule = useCallback(() => {
    const profileContactId = profileData?.contact?.id
    const allModule = modulesData?.items.filter((md) => {
      return md.contacts && profileContactId && md.contacts.includes(profileContactId)
    })
    const selectedModuleIds = allModule && allModule.map((sm) => sm.id)
    return { allModule, selectedModuleIds }
  }, [modulesData?.items, profileData?.contact?.id])

  const modules = useMemo<{ id: number; title: string }[] | []>(() => {
    if (modulesData && modulesData.items.length > 0) {
      return modulesData.items
    }
    return []
  }, [modulesData])

  const checkIfActive = (id: number) => {
    return selectedModules.includes(id)
  }

  const handleChange = ({ data, state }: { data: string; state: boolean }) => {
    const id = Number(data)
    const updatedEnabledModules =
      state && selectedModules.includes(id) ? selectedModules.filter((sm) => sm !== id) : [...selectedModules, id]
    const updatedRole = {
      ...selectedRole,
      data: { ...selectedRole?.data, enabled_modules: [...updatedEnabledModules] }
    }
    setSelectedModules(updatedEnabledModules)
    setSelectedRole(updatedRole) // update selectedModules data in selectedRole too
  }

  const handleOkClick = async () => {
    setSelectedRoleId(0)
    if (selectedRole?.id !== 0 || selectedRole?.id !== null) {
      const enabledModules = profileData?.userdata_json?.enabled_modules

      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          enabled_groups: profileData?.userdata_json?.enabled_groups,
          tab_permissions: profileData?.userdata_json?.tab_permissions,
          enabled_modules: enabledModules,
          enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
          disabled_forms: profileData?.userdata_json?.disabled_forms,
          advance_settings: profileData?.advance_settings
        }
      })
    }

    setChangeRoleModalState(false)
    refetch()
  }

  const handleCancelClick = () => {
    setChangeRoleModalState(false)
  }
  const handleRoleOkClick = () => {
    setEnableRoleModalState(false)
  }
  const handleRoleCancelClick = () => {
    setEnableRoleModalState(false)
  }
  const handleChangeRoleModalActiveState = (state: boolean) => {
    setChangeRoleModalState(state)
  }
  const handleEnableRoleModalActiveState = (state: boolean) => {
    setEnableRoleModalState(state)
  }

  useEffect(() => {
    if (selectedRole?.id === 0) {
      // refresh enabled modules if user changed role to user specific one
      const { selectedModuleIds } = getSelectedModule()
      setSelectedModules(selectedModuleIds ?? [])
    }
  }, [getSelectedModule, selectedRole?.id, setSelectedModules])

  useEffect(() => {
    if (selectedRole?.id !== 0) {
      // we change selectedRole?.data?.enabled_modules only for
      // user specific role (id=0), so next line runs only if user switched role
      setSelectedModules(selectedRole?.data?.enabled_modules ?? [])
    }
  }, [getSelectedModule, selectedRole?.data?.enabled_modules, selectedRole?.id, setSelectedModules])

  return (
    <>
      <div className="w-full lg:w-[50%]  xl:w-[20%] mb-32">
        <AccessListItemHeader title="Modules" />
        <div className="px-24 pt-32 border lg:border-0 lg:border-r  border-gray-200 h-full">
          <Spin spinning={isLoading || profileLoading}>
            {modules.map((module, i) => {
              const isChecked = checkIfActive(module.id)
              return (
                <div className="w-full flex justify-between items-center mb-24" key={i}>
                  <ToggleSwitch
                    id={`category-${i}`}
                    data={module}
                    checked={isChecked}
                    onChange={handleChange}
                    onChangeRoleToggleModal={handleChangeRoleModalActiveState}
                    onEnableRoleToggleModal={handleEnableRoleModalActiveState}
                  />
                </div>
              )
            })}
          </Spin>
        </div>
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={handleChangeRoleModalActiveState}
        onOk={handleOkClick}
        onCancel={handleCancelClick}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={handleEnableRoleModalActiveState}
        onOk={handleRoleOkClick}
        onCancel={handleRoleCancelClick}
      />
    </>
  )
}
export default React.memo(AccessModules)
