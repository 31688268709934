import { useSession } from '@/hooks'
import { Form, FormInstance, Input, Select } from 'antd'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useEffect, useMemo } from 'react'
import { configurationAtom } from '../atoms'

type Configuration = {
  kind: string
  code: string
}

type Props = {
  form: FormInstance<{
    kind: string
    name: string
    type: string
  }>
}

export const ConfigurationForm = ({ form }: Props) => {
  const { company } = useSession()
  const kind = useAtomValue(useMemo(() => selectAtom(configurationAtom, (c) => c.kind ?? ''), []))
  const name = useAtomValue(useMemo(() => selectAtom(configurationAtom, (c) => c.name ?? ''), []))
  const type = useAtomValue(useMemo(() => selectAtom(configurationAtom, (c) => c.type ?? ''), []))

  useEffect(() => {
    // This is a hack to set the initial values of the form
    form.resetFields(['kind', 'name', 'type'])
  }, [form, kind, name, type])

  return (
    <div>
      <div className="flex gap-x-16">
        <Form.Item
          label="Code"
          name="kind"
          className="flex-1"
          initialValue={kind}
          rules={[{ required: true, message: 'Please enter code!' }]}
        >
          <Input data-cy="configuration-code-input" />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          className="flex-1"
          initialValue={name}
          rules={[{ required: true, message: 'Please enter name!' }]}
        >
          <Input data-cy="configuration-name-input" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          className="flex-1"
          initialValue={type}
          rules={[{ required: true, message: 'Please select type!' }]}
        >
          <Select
            options={[
              { label: 'Generic', value: 'G' },
              { label: 'Event', value: 'E' }
            ]}
            data-cy="configuration-type-select"
          />
        </Form.Item>
        <Form.Item label="Company" className="flex-1">
          <Input disabled value={company.name} data-cy="configuration-company-name-input" />
        </Form.Item>
      </div>
    </div>
  )
}
