import { navigate } from '@/routing/helpers'
import { Dropdown } from 'antd'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { Button, ButtonProps } from '../button'

export type SaveButtonProps = Omit<ButtonProps, 'onClick'> & {
  showReturn?: boolean
  onSave: () => Promise<To | void>
  returnTo?: To
  returnButtonProps?: Record<string, string>
}

export const SaveButton: FC<SaveButtonProps> = ({
  children,
  onSave,
  showReturn = true,
  returnTo,
  returnButtonProps,
  disabled,
  ...props
}) => {
  const handleSave = async (andReturn: boolean) => {
    const to = await onSave()

    if (andReturn) {
      if (returnTo) navigate(returnTo)
      else {
        const hasPrevPage = window.history.state?.idx > 0
        if (hasPrevPage) window.history.back()
        else navigate('..') // fall to React Router navigation
      }
    } else if (to) navigate(to)
  }

  const saveButton = (
    <Button type={'success'} iconName={'fa:save'} onClick={() => handleSave(false)} disabled={disabled} {...props}>
      {children ?? 'Save'}
    </Button>
  )

  if (!showReturn || disabled) return saveButton

  return (
    <Dropdown
      mouseEnterDelay={0.25}
      placement="bottomRight"
      trigger={['hover']}
      data-cy="save-button"
      menu={{
        items: [
          {
            key: 'save_and_return',
            label: <span {...returnButtonProps}>{children ?? 'Save'} & Return</span>,
            onClick: () => handleSave(true)
          }
        ]
      }}
    >
      <div>{saveButton}</div>
    </Dropdown>
  )
}
