export type RecordTypeOption = {
  type: string
  label: string
  order: string
  fields: {
    key: string
    name: string
    input: 'text' | 'type' | 'color' | 'property' | 'category'
    required: boolean
    tooltip?: string
  }[]
  transform?: (record: any) => any
}

export const RECORD_TYPE_MAP: Record<string, RecordTypeOption> = {
  job_roles: {
    type: 'Job Role',
    label: 'Job Roles',
    order: 'char_1',
    fields: [
      { key: 'char_2', name: 'Resource Type', input: 'type', required: true },
      { key: 'char_1', name: 'Role Name', input: 'text', required: true },
      { key: 'char_4', name: 'Accent Color', input: 'color', required: false },
      {
        key: 'char_3',
        name: 'Ticket Property',
        input: 'property',
        required: true,
        tooltip: 'Linked ticket/form property where the value is set by the job manager.'
      },
      {
        key: 'data__category_id',
        name: 'SKU Category',
        input: 'category',
        required: true,
        tooltip: 'Linked category for resource skus.'
      }
    ]
  },
  skills: {
    type: 'Skill',
    label: 'Skills',
    order: 'char_3',
    fields: [
      { key: 'char_2', name: 'Resource Type', input: 'type', required: true },
      { key: 'char_1', name: 'Skill Key', input: 'text', required: true },
      { key: 'char_3', name: 'Skill Label', input: 'text', required: true }
    ]
  },
  districts: {
    type: 'District',
    label: 'Districts',
    order: 'char_1',
    fields: [{ key: 'char_1', name: 'District Name', input: 'text', required: true }]
  }
}
