import { Icon } from '@/ui'
import cn from 'classnames'
import { Dayjs } from 'dayjs'
import { useSetAtom, useStore } from 'jotai'
import { KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { patchChangesAtom } from '../../atoms'
import { Resource, ScheduleTypeItem } from '../../schemas'
import { JobsCellOverlay } from './JobsCellOverlay'
import { PatchChangeIndicator } from './PatchChangeIndicator'

type Props = {
  resource: Resource
  date: Dayjs
  rowIdx: number
  colIdx: number
  className?: string
  options: ScheduleTypeItem[]
  optionsMap: Record<ScheduleTypeItem['type'], ScheduleTypeItem>
  shortLabel?: boolean
  showJobs?: boolean
}

export function ScheduleCell({ resource, date, className, options, optionsMap, shortLabel, showJobs }: Props) {
  const patchChanges = useStore().get(patchChangesAtom) // important! get value without watching for performance
  const setPatchChanges = useSetAtom(patchChangesAtom)

  const dateKey = date.format('YYYY-MM-DD')
  const scheduleData = resource?.calendar?.[dateKey]

  const currentValue = scheduleData?.type
  const patchKey = `${resource.id}|${dateKey}`
  const patchValue = patchChanges[patchKey]
  const value = patchValue ?? currentValue
  const valueOption: ScheduleTypeItem | undefined = optionsMap[value as keyof typeof optionsMap]
  const valueChanged = value != currentValue
  const valueDeleted = patchValue === null && Boolean(scheduleData?.patch_id)

  const enablePatch = valueOption?.allowPatch !== false // important! anything other than false

  const [render, setRender] = useState(false)
  const [open, setOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLButtonElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const panelTopRef = useRef<number | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSelect = useCallback(
    (val: string | null) => {
      setPatchChanges((prev) => {
        const newValue = { ...prev }

        if (val === currentValue || (val === null && !scheduleData?.patch_id)) delete newValue[patchKey]
        else newValue[patchKey] = val

        return newValue
      })
      setOpen(false)
      triggerRef.current?.focus()
    },
    [currentValue, patchKey, scheduleData?.patch_id, setPatchChanges]
  )

  const toggleDropdown = useCallback(
    (e: MouseEvent<any> | KeyboardEvent<any>) => {
      e?.preventDefault()
      e.stopPropagation()

      if (open) {
        setOpen(false)
      } else {
        if (triggerRef.current && panelRef.current) {
          const { height: tHeight, bottom: tBottom } = triggerRef.current.getBoundingClientRect()
          const { height: pHeight } = panelRef.current.getBoundingClientRect()

          const spaceRemaining = window.innerHeight - tBottom
          const panelHeight = pHeight + tHeight

          panelTopRef.current = spaceRemaining > panelHeight ? tHeight : spaceRemaining - pHeight - tHeight
        }

        setOpen(true)
      }
    },
    [open]
  )

  /**
   * todo: there are still some nooks and crannies to work out with this function
   */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLButtonElement>) => {
      const currentEl = document.activeElement as HTMLDivElement
      if (!currentEl) return

      const tableEl = currentEl.closest('table')
      if (!tableEl) return

      const { key } = e

      const allCells: HTMLDivElement[] = Array.from(tableEl.querySelectorAll('td button.cell-button'))
      const currentIndex = allCells.indexOf(currentEl)

      let nextIndex
      const columnsCount = tableEl.querySelector('tr')?.children?.length || 0

      switch (key) {
        case 'ArrowUp':
          nextIndex = currentIndex - (columnsCount - 1) // -1 to offset first column
          break
        case 'ArrowDown':
          nextIndex = currentIndex + (columnsCount - 1) // -1 to offset first column
          break
        case 'ArrowLeft':
          nextIndex = currentIndex - 1
          break
        case 'ArrowRight':
          nextIndex = currentIndex + 1
          break
        case 'Tab':
          nextIndex = e.shiftKey ? currentIndex - 1 : currentIndex + 1
          break
        case 'Enter':
          toggleDropdown(e)
          break
        default:
          return true
      }

      if (!nextIndex) return

      if (nextIndex >= 0 && nextIndex < allCells.length) {
        allCells[nextIndex]?.focus?.()
      }

      // prevent ant default table scrolling behavior
      e.preventDefault()
      e.stopPropagation()
      return false
    },
    [toggleDropdown]
  )

  return (
    <div className="relative flex flex-col flex-1 !min-w-[36px] !min-h-[36px]" ref={dropdownRef}>
      {valueChanged && <PatchChangeIndicator />}
      <button
        ref={triggerRef}
        onDoubleClick={toggleDropdown}
        onContextMenu={toggleDropdown}
        onMouseEnter={() => setRender(true)}
        onFocus={() => setRender(true)}
        className={cn(
          'relative cell-button flex-1 bg-transparent p-0  text-center outline-none border-2 border-transparent focus:border-primary text-text-secondary',
          className,
          enablePatch ? 'cursor-pointer' : 'cursor-default !border-transparent',
          valueDeleted ? '!line-through' : ''
        )}
        style={{ backgroundColor: valueOption?.color }}
        onKeyDown={handleKeyDown}
      >
        {shortLabel ? valueOption?.type : valueOption?.name}
        {showJobs && scheduleData?.events && (
          <JobsCellOverlay title={resource.name} schedule={scheduleData} date={date} />
        )}
      </button>
      {render && enablePatch && (
        <div
          ref={panelRef}
          className="transition-opacity duration-200 ease-in-out left-0 w-full mt-2 bg-background-elevated border rounded-lg shadow-lg z-50"
          style={{
            top: panelTopRef.current || 0,
            zIndex: 1000,
            ...(open
              ? { position: 'absolute', opacity: 1, height: 'auto' }
              : { opacity: 0, pointerEvents: 'none', position: 'fixed', top: -500 })
          }}
        >
          <div className={'flex flex-col p-4 gap-2'}>
            {options.map((option) =>
              option.allowPatch !== false && (option.type !== value || valueDeleted) ? (
                <button
                  key={option.type}
                  onClick={() => handleSelect(option.type)}
                  className="outline-primary bg-background text-center rounded p-2 hover:bg-background-layout cursor-pointer transition-all duration-200 ease-in-out"
                  style={{ backgroundColor: option.color }}
                >
                  {shortLabel ? option.type : option.name}
                </button>
              ) : null
            )}

            {!valueDeleted && (Boolean(scheduleData?.patch_id) || valueChanged) && (
              <button
                key={'clear'}
                onClick={() => handleSelect(null)}
                className="outline-primary bg-background text-center rounded p-2 hover:bg-background-layout cursor-pointer transition-all duration-200 ease-in-out"
              >
                <Icon name={'fa:trash'} className={'text-danger'} />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
