import { alertApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { Alert } from '../../../../types'

export const useDeleteAlert = () => {
  const deleteAlertMutation = useMutation({
    ...alertApi.delete<Alert, { id: number }>()
  })

  const deleteAlert = async (payload: { id: number }) => {
    return deleteAlertMutation.mutateAsync(payload)
  }

  return {
    deleteAlert,
    deleteAlertMutation
  }
}
