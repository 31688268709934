import { PERMISSIONS } from '@/constants/auth'
import { Permission } from '@/types/auth-user'
import { AppVersion } from '@/types/general'
import { IconName } from '@/ui/icons'
import { To } from 'react-router-dom'
import { INVENTORY_PATHS } from '../inventory/constants'
import { MODULE_BUILDER_PATH } from '../module-builder/constants'
import { REPORTS_PATHS } from '../reports/constants'
import { ROLODEX_PATHS } from '../rolodex/constants'

export type MenuItem = {
  v: AppVersion
  label: string
  icon: IconName
  key?: string
  to?: To
  items: SubMenu[]
  perms?: Permission[]
}

export type SubMenu = {
  v: AppVersion
  label: string
  to: string
}

export type TopMenuKey =
  'home' |
  // 'jobs' | // @deprecated
  'loads' |
  'records' |
  'tickets' |
  'lineItems' |
  'reports' |
  'contacts' |
  'inventory' |
  'qbInputs' |
  'myTime' |
  'timeManager' |
  'choicesManager' |
  'moduleBuilder' |
  'account' |
  'system' |
  'admin'

const A = PERMISSIONS

const home =        { v: 2, label: 'nav__home',      icon: 'fa:home',              to: '/',                       items: [], perms: []              } as MenuItem
const myTime =      { v: 2, label: 'nav__my_time',   icon: 'fa:clock',             to: '/time/u',                 items: [], perms: [A.myTime]      } as MenuItem
const timeManager = { v: 1, label: 'nav__time',      icon: 'fa:calendar-pen',      to: '/time_manager/',          items: [], perms: [A.timeManager] } as MenuItem
const tickets =     { v: 1, label: 'nav__tickets',   icon: 'fa:receipt',           to: '/tickets/',               items: [], perms: [A.tickets]     } as MenuItem
const inventory =   { v: 2, label: 'nav__inventory', icon: 'fa:check-to-slot',     to: INVENTORY_PATHS.inventory, items: [], perms: [A.inventory]   } as MenuItem

/**
 * IMPORTANT!
 * Update `src/data/redirects.json` when adding/updating menu items or routes.
 * - It updates links and implements interceptor in v1 app to transition to v2 and vice-versa.
 */

// top level menu items
const TOP_MENU: Record<TopMenuKey, MenuItem> = {
  home,
  // jobs:            { v: 1, label: 'nav__jobs__view_jobs',  icon: 'fa:wrench',                   to:  '/jobs/',                    items: [], perms: [A.jobs] },
  records:         { v: 2, label: 'nav__records',          icon: 'fa:database',                 key: '/records/',                 items: [], perms: [A.records] },
  loads:           { v: 1, label: 'nav__loads',            icon: 'fa:truck',                    to:  '/loads/',                   items: [], perms: [A.loads] },
  tickets,
  lineItems:       { v: 1, label: 'nav__line_items',       icon: 'fa:scroll',                   to:  '/lines/',                   items: [], perms: [A.lines] },
  reports:         { v: 2, label: 'nav__reporting',        icon: 'fa:table-list',               to:  REPORTS_PATHS.customReports, items: [], perms: [A.reporting] },
  contacts:        { v: 2, label: 'nav__contacts',         icon: 'fa:users',                    to:  ROLODEX_PATHS.contacts,      items: [], perms: [A.contacts] },
  inventory,
  qbInputs:        { v: 2, label: 'nav__qb_inputs',        icon: 'fa:dollar-circle',            to:  '/qb_inputs',                items: [], perms: [A.qbInput] },
  myTime,
  timeManager,
  choicesManager:  { v: 2, label: 'nav__choices_manager',  icon: 'fa:list-dropdown',            to:  '/choices_manager',          items: [], perms: [A.choicesManager] },
  moduleBuilder:   { v: 2, label: 'nav__module_builder',   icon: 'fa:list-tree',                to:  MODULE_BUILDER_PATH,         items: [], perms: [A.moduleBuilder] },
  admin:           { v: 2, label: 'Admin',                 icon: 'fa:user-shield',              key: '/admin/',                   items: [], perms: [A.superAdmin] },
  account:         { v: 1, label: 'nav__account_settings', icon: 'fa:gears',                    key: '/account/',                 items: [], perms: [A.support, A.superAdmin] },
  system:          { v: 1, label: 'nav__system',           icon: 'fa:server',                   key: '/system/',                  items: [], perms: [A.superAdmin] }
}

// records sub-menu items
TOP_MENU.records.items =  [
  { v: 2, label: 'nav__records__locations',  to: '/records/locations' },
  { v: 2, label: 'nav__records__skus',       to: '/records/skus' },
  { v: 2, label: 'nav__records__categories', to: '/records/categories' },
  { v: 2, label: 'nav__records__itemconf',   to: '/records/category_kinds' },
  { v: 2, label: 'nav__records__afes',       to: '/records/afes' },
  { v: 2, label: 'nav__jobs__job_codes',     to: '/records/job_codes' },
  { v: 2, label: 'nav__jobs__job_types',     to: '/records/job_types' },
  { v: 2, label: 'nav__custom_records',      to: '/records/custom_records' },
  { v: 2, label: 'nav__records__pricing',    to: '/records/pricing' }
]


// account settings sub-menu items
TOP_MENU.account.items = [
    { v: 2, label: 'nav__ast_custom_forms',             to: '/account/custom_forms' },
    { v: 2, label: 'nav__reports_programmed',           to: '/programmed_reports' },
    { v: 2, label: 'nav__ast_stages',                   to: '/stages/' },
    { v: 2, label: 'nav__ast_import',                   to: '/account/import' },
    { v: 2, label: 'nav__ast_notification_templates',   to: '/account/notification_templates' },
    { v: 2, label: 'nav__ast_notifications_activity',   to: '/notifications_activity' },
    { v: 2, label: 'nav__ast_secrets',                  to: '/account/secrets-manager' },
    { v: 1, label: 'nav__ast_integrations',             to: '/integrations/' },
    { v: 1, label: 'nav__ast_js_lib',                   to: '/settings/' },
]

// system sub-menu items
TOP_MENU.system.items = [
  { v: 1, label: 'nav__sys_notifications',   to: '/notifications' },
  { v: 1, label: 'nav__sys_ticket_seq',      to: '/ticket_seq/' },
  { v: 1, label: 'nav__sys_password_resets', to: '/reset_event/' },
  { v: 1, label: 'nav__sys_custom_features', to: '/custom_features/' },
  { v: 1, label: 'nav__sys_account_rules',   to: '/account_rules/' },
  { v: 1, label: 'nav__sys_kinesis',         to: '/kinesis_events/' },
  { v: 1, label: 'nav__sys_support_req',     to: '/support_requests/' }
]

// admin sub-menu items
TOP_MENU.admin.items = [
  { v: 2, label: 'All AFEs',            to: '/afes' },
  { v: 2, label: 'All Categories',      to: '/categories' },
  { v: 2, label: 'All Category Kinds',  to: '/category_kinds' },
  { v: 2, label: 'All Contacts',        to: '/contacts' },
  { v: 2, label: 'All Companies',       to: '/companies' },
  { v: 2, label: 'All Equipment',       to: '/equipment/' },
  { v: 2, label: 'All Jobs',            to: '/jobs/' },
  { v: 2, label: 'All Job Codes',       to: '/job_codes/' },
  { v: 2, label: 'All Job Types',       to: '/job_types/' },
  { v: 2, label: 'All Loads',           to: '/loads/' },
  { v: 2, label: 'All Locations',       to: '/locations/' },
  { v: 2, label: 'All Offices',         to: '/offices/' },
  { v: 2, label: 'All Regions',         to: '/regions/' },
  { v: 2, label: 'All Tickets',         to: '/tickets/' },
  { v: 2, label: 'All Users',           to: '/profiles/' },
  { v: 2, label: 'Programmed Reports',  to: '/programmed_reports/' },
]

// main menu items - used in sidebar - ordered
export const MENU_ITEMS: MenuItem[] = [
  TOP_MENU.home,
  // TOP_MENU.jobs,
  TOP_MENU.loads,
  TOP_MENU.records,
  TOP_MENU.tickets,
  TOP_MENU.lineItems,
  TOP_MENU.reports,
  TOP_MENU.contacts,
  TOP_MENU.inventory,
  TOP_MENU.qbInputs,
  TOP_MENU.myTime,
  TOP_MENU.timeManager,
  TOP_MENU.choicesManager,
  TOP_MENU.moduleBuilder,
  TOP_MENU.account,
  TOP_MENU.admin,
  TOP_MENU.system
]

export const MENU_ITEMS_MOBILE: MenuItem[] = [
  home,
  myTime,
  timeManager,
  tickets,
  inventory,
]
