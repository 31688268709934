import { TICKET_STATUS } from '@/constants/general'
import { Form, Select } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { PropertiesSelect } from '../properties-select'
import { TableViewSelect } from '../table-view-select'

export const ModuleTypeKanbanBoardFields = () => {
  const form = Form.useFormInstance()
  const stagesFormId = Form.useWatch(['data', 'stages_form_id'], form) as number | undefined
  const sourceType = Form.useWatch(['data', 'source_type'], form) as string | undefined

  return (
    <div>
      <h5 className="font-bold">Kanban Board Config</h5>
      <div className="grid grid-cols-4 gap-x-16">
        <CustomFormSelect formItemProps={{ name: ['data', 'stages_form_id'] }} />
        <Form.Item
          label="Source Type"
          name={['data', 'source_type']}
          rules={[{ required: true, message: 'Please select source type' }]}
        >
          <Select
            placeholder="Select"
            options={[
              { label: 'Tickets', value: 'T' },
              { label: 'Line Items', value: 'I' }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Board View"
          name={['data', 'board_view']}
          rules={[{ required: true, message: 'Please select board view' }]}
        >
          <Select
            placeholder="Select"
            options={[
              { label: 'Status Column', value: 1 },
              { label: 'Weekly Column', value: 2 }
            ]}
          />
        </Form.Item>
        <Form.Item label="Swimlane" name={['data', 'swimlane']}>
          <Select
            placeholder="Select"
            options={[
              { label: 'None', value: null },
              { label: 'Assignee', value: 1 },
              { label: 'Customer', value: 2 }
            ]}
          />
        </Form.Item>
      </div>
      <div className="grid grid-cols-4 gap-x-16">
        <Form.Item label="Statuses" name={['data', 'statuses']}>
          <Select
            placeholder="Select"
            mode="multiple"
            options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
          />
        </Form.Item>
      </div>
      <div className="grid grid-cols-4 gap-x-16">
        <PropertiesSelect
          formItemProps={{ label: 'Title', name: ['data', 'title'] }}
          customFormsIds={stagesFormId ? [stagesFormId] : []}
        />
        <PropertiesSelect
          formItemProps={{ label: 'Description', name: ['data', 'description'] }}
          customFormsIds={stagesFormId ? [stagesFormId] : []}
        />
        {sourceType === 'I' && (
          <PropertiesSelect
            formItemProps={{ label: 'Task Type', name: ['data', 'task_type'] }}
            customFormsIds={stagesFormId ? [stagesFormId] : []}
          />
        )}
      </div>
      <div className="grid grid-cols-4 gap-x-16">
        <TableViewSelect />
        <ForceViewCheckbox />
      </div>
    </div>
  )
}
