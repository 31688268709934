import { Icon, IconName } from '@/ui/icons'
import { Tooltip } from '@/ui/tooltip'
import { FC, ReactNode } from 'react'

type Props = {
  label: ReactNode
  tipIcon?: IconName
  tipTitle?: ReactNode
}

export const OptionHelp: FC<Props> = ({ label, tipIcon, tipTitle }) => {
  return (
    <div className={'w-full flex items-center justify-between'}>
      <span className={'text-ellipsis overflow-hidden'}>{label}</span>
      <Tooltip className={'help-btn'} title={tipTitle}>
        <Icon name={tipIcon ?? 'fa:info-circle'} className={'text-text-muted mx-2'} />
      </Tooltip>
    </div>
  )
}
