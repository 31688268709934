import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const Readonly = () => {
  const { lineItemField } = useSortableItemContext()

  const dataField = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.data ?? {}), [])
  )

  const setConfiguration = useSetAtom(configurationAtom)

  const item = dataField[lineItemField as keyof typeof dataField] as any

  const isChecked = item?.readonly ?? false

  const handleChange = (checked: boolean) => {
    if (checked) {
      setConfiguration((configuration) => ({
        ...configuration,
        data: {
          ...configuration.data,
          [lineItemField]: {
            ...item,
            readonly: true
          }
        }
      }))
    } else {
      setConfiguration((configuration) => ({
        ...configuration,
        data: {
          ...configuration.data,
          [lineItemField]: {
            ...item,
            readonly: false
          }
        }
      }))
    }
  }

  return (
    <Space>
      <Switch checked={isChecked} onChange={handleChange} data-cy="readonly-switch" />
      <span>Readonly</span>
    </Space>
  )
}
