import { PageView } from '@/layouts/views'
import { MonthWeekChanger } from '@/modules/jas/schedule/components'
import { CalendarViewType } from '@/modules/jas/schedule/schemas'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { UrlStateFormItem } from '@/ui'
import { ListFilter } from '@/ui/filter'
import dayjs from 'dayjs'
import { FC } from 'react'
import { TicketViewSelect } from '../list/components/actions'
import { useActiveTableView, useTicketFilter } from '../list/hooks'
import { TicketsCalendar } from './components'

export const TicketCalendarView: FC = () => {
  const { module } = useCurrentModuleQuery()
  const moduleTitle = module?.title || 'Tickets Calendar'

  // prevents initial duplicate request
  const { isReady } = useActiveTableView()
  if (!isReady) return null

  return (
    <PageView header={{ title: moduleTitle, breadcrumbs: true, actions: <TicketViewSelect /> }}>
      <div className={'h-full flex flex-col gap-10'}>
        <CalendarFilter />

        <div className={'flex-grow overflow-auto border rounded p-10'}>
          <TicketsCalendar />
        </div>
      </div>
    </PageView>
  )
}

function CalendarFilter() {
  const { filterService } = useTicketFilter()
  const view = (filterService.state.view || 'month') as CalendarViewType

  return (
    <div className={'flex flex-col md:flex-row justify-between'}>
      <div className={'flex-grow [&_.grid]:grid-cols-3'}>
        <ListFilter service={filterService} />
      </div>
      <div className={'flex flex-col sm:flex-row gap-10 mt-10 justify-between'}>
        <UrlStateFormItem name={[filterService.filterKey, 'ref_date']} defaultValue={dayjs.now().formatISODate(false)}>
          <MonthWeekChanger view={view} />
        </UrlStateFormItem>

        {/*
         todo: we only support month view for now ux for other types are in progress
          see: https://oilcommand.atlassian.net/browse/OC-6130

        <UrlStateFormItem name={'view'} defaultValue={view}>
          <Segmented block={true} className={'min-w-[200px]'} options={CALENDAR_VIEWS} />
        </UrlStateFormItem>
        */}
      </div>
    </div>
  )
}
