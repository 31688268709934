import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { bpaConfigAtom } from '../atoms'

export const AdvancedModeForm: FC = () => {
  const [config, setConfig] = useAtom(bpaConfigAtom)

  return (
    <div>
      <Form.Item label={'Name'}>
        <CodeEditor data-cy="bpa-config-editor" minLines={50} defaultValue={config.rules_yml} mode={'yaml'} />
      </Form.Item>
    </div>
  )
}
