import { useApp, useIsMobile } from '@/hooks'
import { ListView } from '@/layouts/views'
import { useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { ticketApiV2 } from '@/services/api-service'
import { ActionButton, Icon, LazyDiv, Tooltip } from '@/ui'
import { csv } from '@/utils'
import { ColumnType } from 'antd/es/table'
import cn from 'classnames'
import { useSetAtom } from 'jotai'
import { isEmpty } from 'lodash'
import { FC, useMemo } from 'react'
import { ViewTicketLink } from '../../components'
import { ticketsQueryResultAtom } from '../atoms'
import { DisplayColumn, TicketActions, TicketFilter } from '../components'
import { TicketStatusFilter, TicketVerticalViewFilter } from '../components/filters'
import {
  useActiveTableView,
  useTicketFields,
  useTicketFilter,
  useTicketModule,
  useTicketSelection,
  useTicketsParam
} from '../hooks'

export const TicketListView: FC = () => {
  const { l } = useApp()
  const isMobile = useIsMobile()

  const customFormsService = useGlobalCustomForms()

  const setTicketsQueryResult = useSetAtom(ticketsQueryResultAtom)
  const { isLoadsView, viewType, module, showStatusFilter, isVerticalViews } = useTicketModule()
  const moduleTitle = module?.title || (viewType === 'L' ? l('Loads') : l('Tickets'))

  // filter fields
  const { filterService } = useTicketFilter()

  // filter params
  const { params } = useTicketsParam()

  // display fields
  const { displayFields, paramFields } = useTicketFields()
  params.fields = csv(paramFields) // fetch fields
  const displayColumns = useMemo(() => {
    if (isEmpty(displayFields)) return []

    const dfs = displayFields.map(
      (df): ColumnType<any> => ({
        key: df.key,
        dataIndex: df.sortField,
        sorter: !!df.sortField,
        fixed: !isMobile && df.fixed,
        width: df.width,
        title: (
          <div className={'flex flex-row items-center gap-10'}>
            <div className="line-clamp-1">{df.name}</div>
            {df.error && (
              <Tooltip title={df.error}>
                <Icon name={'fa:warning'} className={'text-warning'} />
              </Tooltip>
            )}
          </div>
        ),
        render: (value, record, index) => (
          <DisplayColumn customFormsService={customFormsService} field={df} record={record} />
        )
      })
    )
    dfs.push({
      sorter: false,
      key: 'actions',
      fixed: 'right',
      align: 'end',
      width: 40,
      render: (_, record) => (
        <LazyDiv
          placeholder={
            <ActionButton size={'small'} primary shape={'circle'} type={'default'} iconName={'fa:chevron-right'} />
          }
        >
          <ViewTicketLink ticketId={record.id} formId={record.custom_form_id}>
            <ActionButton size={'small'} primary shape={'circle'} type={'default'} iconName={'fa:chevron-right'} />
          </ViewTicketLink>
        </LazyDiv>
      )
    })
    return dfs
  }, [customFormsService, displayFields, isMobile])

  // selection
  const { selectedTicketsIds, setSelectedTickets } = useTicketSelection()

  // hack: fixes initial duplicate request
  // by waiting for the displayColumns to be ready
  const { isReady } = useActiveTableView()

  if (!isReady || isEmpty(displayColumns)) return null

  return (
    <ListView
      header={{
        title: moduleTitle,
        breadcrumbs: true,
        actions: <TicketActions />
      }}
      filter={{
        service: filterService,
        render: (filterEl) => <TicketFilter service={filterService}>{filterEl}</TicketFilter>
      }}
      onQueryChange={(query, total) => setTicketsQueryResult({ query, total })}
      table={{
        scroll: { x: 1000 },
        queryOptimize: true,
        queryApi: ticketApiV2.list,
        queryParams: params,
        orderParam: 'custom_order',
        rowKey: 'id',
        rowSelection: {
          selectedRowKeys: selectedTicketsIds,
          preserveSelectedRowKeys: true,
          onChange: (_, selectedRows: any[]) => setSelectedTickets(selectedRows)
        },
        fullHeight: true,
        columns: displayColumns
      }}
      renderTable={(tableElement) => (
        <div className={'flex-grow flex flex-col gap-8 w-full md:flex-row'}>
          {!isMobile && (
            <>
              {isVerticalViews && (
                <div className={'hidden md:block flex-shrink-0 md:w-[200px]'}>
                  <TicketVerticalViewFilter />
                </div>
              )}
              {(showStatusFilter || isLoadsView) && (
                <div className={'hidden md:block flex-shrink-0 md:w-[200px]'}>
                  <TicketStatusFilter />
                </div>
              )}
            </>
          )}
          <div className={cn('flex flex-grow overflow-hidden')}>{tableElement}</div>
        </div>
      )}
    />
  )
}
