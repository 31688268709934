import { INVOICE_STATUS, INVOICE_TYPE, TICKET_STATUS } from '@/constants/general'
import { useApp } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { invoiceApi } from '@/services/api-service'
import { AppLink, Button, Popconfirm } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { notification } from 'antd'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { ViewTicketLink } from '../../../ticket/components'
import { NETSUITE_INTEGRATION_CODE } from '../../constants'
import { Filter } from './Filter'

export const NetSuiteInvoiceListView = view(Component, {
  title: () => 'Invoices'
})

function Component() {
  const { l } = useApp()

  const deleteInvoiceMutation = useMutation(invoiceApi.delete())
  const handleDelete = async (id: number) => {
    await deleteInvoiceMutation.mutateAsync({ id })
    notification.success({ message: 'Invoice record deleted' })
  }

  return (
    <ListView
      header={{
        title: l('Invoices'),
        refresh: true
      }}
      filter={{ render: (_, onChange) => <Filter onChange={onChange} /> }}
      table={{
        queryApi: invoiceApi.list,
        queryParams: {
          order: '-modified_at',
          data__accounting_service: NETSUITE_INTEGRATION_CODE,
          exclude_fields: 'data'
        },
        columns: [
          {
            title: 'Record',
            dataIndex: 'type',
            render: (type) => <div className="capitalize">{INVOICE_TYPE[type as keyof typeof INVOICE_TYPE]}</div>
          },
          {
            title: 'Ticket',
            dataIndex: 'ticket__name',
            render: (_, record: any) => (
              <ViewTicketLink ticketId={record.ticket} formId={0} className="text-primary-500 hover:text-primary-600">
                <span>
                  {record.ticket__custom_form__name} #{record.ticket__name}
                </span>
              </ViewTicketLink>
            )
          },
          {
            title: 'Customer Office',
            dataIndex: 'ticket__customer_office'
          },
          {
            title: 'Status',
            sorter: false,
            dataIndex: 'ticket__status',
            render: (status) => <div>{TICKET_STATUS[status as keyof typeof TICKET_STATUS]}</div>
          },
          {
            title: 'Updated',
            dataIndex: 'modified_at',
            render: (date) => <div>{dayjs(date).format('MMM DD, YYYY h:mm:ss A')}</div>,
            defaultSortOrder: 'descend'
          },
          {
            title: 'NetSuite Status',
            dataIndex: 'status',
            render: (status) => <div>{INVOICE_STATUS[status as keyof typeof INVOICE_STATUS]}</div>
          },
          {
            title: '',
            dataIndex: 'detail'
          },
          {
            title: 'Ref Number',
            dataIndex: 'ref_number',
            sorter: false,
            render: (ref_number, record) =>
              record.qb_txn?.qb_txn_url ? (
                <AppLink target="_blank" to={record.qb_txn.qb_txn_url}>
                  {ref_number}
                </AppLink>
              ) : (
                <p>{ref_number}</p>
              )
          },
          {
            sorter: false,
            render: (_, record: any) => (
              <Popconfirm
                placement="topLeft"
                title={`Delete ${capitalize(INVOICE_TYPE[record.type as keyof typeof INVOICE_TYPE])}`}
                description={`Deleting this ${capitalize(INVOICE_TYPE[record.type as keyof typeof INVOICE_TYPE])} for ${record.ticket__custom_form__name} #${record.ticket__name} in Oil Command will NOT delete the invoice in QuickBooks`}
                okText="Yes"
                onConfirm={() => handleDelete(record.id)}
              >
                <Button type="text" iconName="fa:trash" danger />
              </Popconfirm>
            )
          }
        ]
      }}
    ></ListView>
  )
}
