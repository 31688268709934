import { HTMLProps, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

export type LazyDivProps = Omit<HTMLProps<HTMLDivElement>, 'placeholder'> & {
  placeholder?: ReactNode
  behaviour?: 'render' | 'viewport' | 'intent'
  fadeIn?: boolean
  rootMargin?: string
  minHeight?: string
}

export function LazyDiv({
  placeholder,
  behaviour = 'intent',
  fadeIn,
  rootMargin,
  minHeight = '20px',
  children,
  ...props
}: LazyDivProps) {
  const [render, setRender] = useState(behaviour === 'render')
  const wrapperRef = useRef<HTMLDivElement>(null)

  const show = useCallback(() => setRender(true), [])

  useEffect(() => {
    if (behaviour !== 'viewport') return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!render && entry.isIntersecting) {
          show()
        }
      },
      { root: null, rootMargin: rootMargin || '0px', threshold: 0.1 }
    )

    const wrapperEl = wrapperRef.current

    if (wrapperEl) observer.observe(wrapperEl)

    return () => {
      if (wrapperEl) observer.unobserve(wrapperEl)
    }
  }, [behaviour, render, rootMargin, show])

  return (
    <div
      {...props}
      style={{ minHeight, ...props?.style }}
      ref={wrapperRef}
      {...(behaviour === 'intent' ? { onMouseEnter: show, onFocus: show } : {})}
    >
      {render ? fadeIn ? <div className={'h-full w-full animate-fade-in'}>{children}</div> : children : placeholder}
    </div>
  )
}
