import { useApp } from '@/hooks'
import { TableViewFormValues } from '@/modules/table-view/views/edit-table-view-view/types'
import { tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { FormInstance } from 'antd'
import { isAxiosError } from 'axios'

type Props = {
  form: FormInstance<TableViewFormValues>
  tableView?: TableView
  onMutationSuccess?: () => Promise<any>
}

export const SaveButton = ({ form, onMutationSuccess }: Props) => {
  const updateTableViewMutation = useMutation({ ...tableViewApi.patch() })
  const { notification } = useApp()

  const handleSave = async () => {
    try {
      const { custom_forms, selected_fields, config, ...values } = await form.validateFields()

      await updateTableViewMutation.mutateAsync({
        ...values,
        config: {
          ...config,
          custom_filters: JSON.parse(config?.custom_filters || '{}')
        },
        options: {
          custom_forms: custom_forms?.filter(({ checked }) => checked).map(({ id }) => id)
        },
        selected_fields: selected_fields
      })

      await (onMutationSuccess && onMutationSuccess())

      notification.success({
        message: 'View updated'
      })
    } catch (err) {
      if (isAxiosError(err)) {
        notification.error({
          message: 'Something went wrong'
        })
      }
      console.error(err)
    }
  }

  return (
    <Button iconName="mi:save" type="success" onClick={handleSave} loading={updateTableViewMutation.isLoading}>
      Save
    </Button>
  )
}
