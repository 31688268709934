import { officeApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui/select'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'support_office'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const SupportOfficeInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { customForm, isDisabledField, isHiddenField } = useCustomForm()

  if (isHiddenField(KEY) || !customForm.show_third_party) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__support_office__label'} />} {...formItemProps}>
      <QuerySelect
        apiEndpoint={officeApi.list}
        apiQueryParams={{ fields: 'id,name' }}
        apiSearchBy={'name'}
        mapOption={['id', 'name']}
        disabled={isDisabledField(KEY)}
        prefetch={true}
        showCopyText={true}
        {...(inputProps as any)}
      />
    </Form.Item>
  )
}
