import { categoryKindApi } from '@/services/api-service'
import { ListReturn } from '@/types/api/core'
import { safeJsonParse } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { CategoryKind } from '../schemas'

export const useCategoryKinds = (queryOptions?: ListReturn<CategoryKind>) => {
  const categoryKindsQuery = useQuery({
    ...queryOptions,
    ...categoryKindApi.list<CategoryKind>({
      fields:
        'id,name,fields,field_aliases_json,data_json,hidden_fields_json,formats_json,readonly_fields,scripts_json',
      limit: 1000
    })
    // cacheTime: Infinity,
    // staleTime: Infinity
  })

  const getCategoryKindById = useCallback(
    (id: number): CategoryKind | null => {
      const _kind = categoryKindsQuery.data?.items?.find((c) => c.id === id)
      if (!_kind) return null

      return {
        ..._kind,
        fields: safeJsonParse(_kind.fields),
        field_aliases: safeJsonParse(_kind.field_aliases_json),
        hidden_fields: safeJsonParse(_kind.hidden_fields_json),
        formats: safeJsonParse(_kind.formats_json),
        readonly_fields: safeJsonParse(_kind.readonly_fields),
        scripts: safeJsonParse(_kind.scripts_json),
        data: safeJsonParse(_kind.data_json)
      }
    },
    [categoryKindsQuery.data]
  )

  return {
    categoryKindsQuery,
    categoryKinds: categoryKindsQuery?.data?.items,
    getCategoryKindById
  }
}
