import { Progress as AntProgress, ProgressProps } from 'antd'
import styles from './Progress.module.scss'

type Props = {
  /**
   * @description Progress bar start from position
   */
  startFrom?: number
  mode?: 'determinate' | 'indeterminate'
  loading?: boolean
} & ProgressProps

export const Progress = ({ startFrom, mode, loading, ...props }: Props) => {
  if (mode === 'indeterminate') {
    return (
      <div className="relative overflow-hidden h-2 rounded-full opacity-50">
        {loading && <div className="absolute w-full h-full bg-primary rounded-full origin-left animate-progress"></div>}
      </div>
    )
  }

  return (
    <div
      className={styles.wrapper}
      // @ts-expect-error --start-from is a CSS variable
      style={startFrom ? { '--start-from': `${startFrom}%` } : {}}
    >
      <AntProgress {...props} />
    </div>
  )
}
