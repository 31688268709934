import { clientRecordApi } from '@/services/api-service'
import { useQueryFilterService } from '@/ui/filter'
import { QueryFieldOptionalKey } from '@/ui/filter/types'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { z } from 'zod'
import { DistrictSelect, JobRoleSelect, SkillSelect } from '../resource-manager/components'
import { ResourceType } from '../resource-manager/resources'

export const useResourceScheduleFilter = ({ resourceType }: { resourceType?: ResourceType }) => {
  const filterFields: QueryFieldOptionalKey[] = useMemo(
    () => [
      {
        inputType: 'text',
        label: 'Search Name',
        field: 'name',
        lookup: 'icontains'
      },
      {
        inputType: 'render',
        label: 'Job Role',
        field: 'job_roles',
        lookup: 'in',
        inputRender: (props: any) => (
          <JobRoleSelect
            mode={'multiple'}
            apiQueryParams={resourceType ? { char_2__eq: resourceType } : {}}
            {...props}
          />
        )
      },
      {
        inputType: 'render',
        label: 'Skill',
        field: 'attributes',
        lookup: 'has_any_keys',
        inputRender: (props: any) => (
          <SkillSelect mode={'multiple'} apiQueryParams={resourceType ? { char_2__eq: resourceType } : {}} {...props} />
        )
      },
      {
        inputType: 'render',
        label: 'District',
        field: 'district',
        lookup: 'in',
        inputRender: (props: any) => <DistrictSelect mode={'multiple'} {...props} />
      }
    ],
    [resourceType]
  )

  const filterService = useQueryFilterService({ fields: filterFields, search: false, filter: true })

  return {
    filterFields,
    filterService,
    setFilter: filterService.setFilter,
    getFilterParams: filterService.getFilterQuery
  }
}

export const useSkillsQuery = ({ skills }: { skills: string[] }) => {
  return useQuery({
    ...clientRecordApi.list({
      fields: z.object({ id: z.number(), char_1: z.string(), char_2: z.string(), char_3: z.string() }),
      order: 'char_1',
      char_1__in: skills.join(',')
    }),
    enabled: !!skills.length
  })
}
