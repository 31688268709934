import { Modal } from 'antd'

type EnableRoleModalType = {
  onOk: () => void
  onCancel: () => void
  active: boolean
  setActive: (x: boolean) => void
}
const EnableRoleModal = ({ active, onOk, onCancel, setActive }: EnableRoleModalType) => {
  const handleOk = () => {
    setActive(false)
    onOk()
  }
  const handleCancel = () => {
    setActive(false)
    onCancel()
  }
  return (
    <Modal open={active} title="Unsaved Profile" okText="Ok" onOk={handleOk} onCancel={handleCancel}>
      <p>Role has been changed. Please save profile before customizing it.</p>
    </Modal>
  )
}

export default EnableRoleModal
