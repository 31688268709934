import { equipmentDefaultLiFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

const defaultLiFormSchema = z.object({
  id: z.number(),
  name: z.string()
})

export const useDefaultLiFormsQuery = () => {
  const { id } = useParams<{ id: string }>()

  const defaultLiFormsQuery = useQuery({
    ...equipmentDefaultLiFormApi(id).list({
      limit: undefined,
      page: undefined
    }),
    select: (data) => ({
      ...data,
      items: defaultLiFormSchema.array().parse(data.items)
    }),
    enabled: !!id
  })

  return {
    defaultLiFormsQuery
  }
}
