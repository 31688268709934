import { atom } from 'jotai'

type selectedTypeAtomType = {
  title: string
  addText: string
  addLink: string
  selectOptions: {
    label: string
    value: string
  }[]
}
export const selectedTypeAtom = atom<selectedTypeAtomType | null>(null)
