import { Checkbox } from '@/ui'
import { Dropdown } from 'antd'
import { MenuItemGroupType } from 'antd/es/menu/interface'
import { useAtom } from 'jotai'
import { FC, useCallback, useMemo } from 'react'
import { Resource } from '../../schemas'
import { selectedResourcesAtom } from '../atoms'
import { useJobRoles } from '../hooks'
import { JobResource } from '../schemas'

type _Props = {
  resource: Resource
}

export const ResourceSelect: FC<_Props> = ({ resource }) => {
  const { jobRoles } = useJobRoles()
  const [selectedResources, _setSelectedResources] = useAtom(selectedResourcesAtom)
  const selectedResource = selectedResources.find((res) => res.id === resource.id)
  const isResourceSelected = useMemo(() => !!selectedResource, [selectedResource])

  const addResource = useCallback(
    (resource: JobResource) => _setSelectedResources([...selectedResources, resource]),
    [selectedResources, _setSelectedResources]
  )

  const removeResource = useCallback(
    (resourceId: number) => _setSelectedResources(selectedResources.filter((r) => r.id !== resourceId)),
    [selectedResources, _setSelectedResources]
  )

  const multipleSkus = resource.equipments.length > 1
  const missingSku = resource.equipments.length === 0

  const toggleResource = (skuId: number | null, checked: boolean) => {
    if (missingSku) return

    if (!checked) return removeResource(resource.id)

    let assignedSku = resource.equipments.find((eq) => eq.id === skuId)
    if (!assignedSku && !multipleSkus) assignedSku = resource.equipments[0]

    if (assignedSku && checked)
      addResource({
        id: resource.id,
        name: resource.name,
        type: resource.type,
        short_code: resource.short_code,
        job_role__id: Number((assignedSku.user_text_7 || '').split(':')[0]),
        assigned_sku_id: assignedSku.id,
        attributes: resource.attributes
      })
  }

  const dropdownValues: MenuItemGroupType[] = jobRoles
    ?.filter((role) => resource.job_role__id === role.id)
    .map((role) => ({
      key: role.id,
      type: 'group' as any,
      label: `${role.name} SKUs`,
      children: resource.equipments.map((e) => ({
        key: `${role.id}-${e.id}`,
        label: (
          <Checkbox
            key={e.id}
            checked={e.id === selectedResource?.assigned_sku_id}
            onChange={(ev) => toggleResource(e.id, ev.target.checked)}
          >
            {e.sku} / {e.description}
          </Checkbox>
        )
      }))
    }))

  return (
    <div className="flex justify-center items-center">
      <Dropdown
        arrow={true}
        menu={{ items: dropdownValues }}
        trigger={multipleSkus && !isResourceSelected ? ['click'] : []}
        disabled={missingSku}
      >
        <div>
          <Checkbox
            checked={isResourceSelected}
            onChange={(ev) => toggleResource(null, ev.target.checked)}
            disabled={missingSku}
          />
        </div>
      </Dropdown>
    </div>
  )
}
