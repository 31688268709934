import { useApp } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { locationApi } from '@/services/api-service'
import { LinkButton, QueryDeleteButton, QueryEditButton } from '@/ui'
import dayjs from 'dayjs'
import { z } from 'zod'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'

export const LocationListView = view(Component)

function Component() {
  const { l } = useApp()

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('Locations'),
        actions: [
          <LinkButton key={'add'} to={'add'} type={'primary'} iconName={'fa:plus'} data-cy={'add-location-button'}>
            Add {l('Location')}
          </LinkButton>
        ]
      }}
      filter={{ search: { searchBy: 'name' } }}
      table={{
        queryApi: locationApi.list,
        queryParams: { order: 'name', fields: LocationSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'company__name', title: 'Company' },
          { dataIndex: 'name', title: `${l('Location')} Name` },
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Location) => (
              <div>
                <QueryEditButton to={`${row.id}/edit`} />
                <QueryDeleteButton id={row.id} api={locationApi.delete} name={l('Location')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}

const LocationSchema = z.object({
  id: z.string(),
  company__name: z.string(),
  name: z.string(),
  modified_at: z.string()
})

type Location = z.infer<typeof LocationSchema>
