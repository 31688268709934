import { userDataApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'

export const useUserDataQuery = () => {
  const userDataQuery = useQuery({
    ...userDataApi.list()
  })

  return {
    userDataQuery
  }
}
