import { TICKET_STATUS } from '@/constants/general'
import { SUMMARY_ATTACHMENT_TYPE_SELECT } from '@/constants/summary'
import { useApp } from '@/hooks'
import { Module } from '@/modules/module/types'
import { customFormApi, equipmentApi, ticketApi } from '@/services/api-service'
import { CustomForm } from '@/types/custom-form'
import { Button, Select } from '@/ui'
import { SectionTitle } from '@/ui/section-title'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Modal, Spin } from 'antd'
import React from 'react'

type Props = {
  module: Module
  onFinish: (summary: any) => void
}

export const SummarySettingsSection = ({ module, onFinish }: Props) => {
  const form = Form.useFormInstance()
  const selectedTickets = Form.useWatch('selected_tickets', form) || []
  const attachmentType = Form.useWatch('attachment_type', form)
  const data = module.data || {}
  const { notification } = useApp()
  const {
    summarized_tickets_status,
    summary_sku_id,
    list_line_items,
    group_items,
    summary_ticket_status,
    attachment_type
  } = data
  const [modal, contextHolder] = Modal.useModal()
  const createTicketsSummaryMutation = useMutation(ticketApi.create({}, 'summary'))
  const [item_conf, setItemConf] = React.useState<number>(
    (Number(group_items as boolean) * 2) | (Number(list_line_items as boolean) * 1)
  )

  const customFormQuery = useQuery({
    ...customFormApi.get<CustomForm>(Number(module.data?.custom_form_id)),
    enabled: !!module.data?.custom_form_id
  })

  const summarySkuQuery = useQuery({ ...equipmentApi.get<any>(Number(summary_sku_id)), enabled: !!summary_sku_id })
  const ticketsWithParent = selectedTickets.filter((ticket: any) => !!ticket.parent__id)

  const handleMutate = async () => {
    const dispositionSummarized = ![null, undefined, false, 'None'].includes(summarized_tickets_status)

    const payload = {
      selected_tickets: selectedTickets.map((ticket: any) => ticket.id),
      attachment_type: attachmentType,
      summary_sku: summary_sku_id,
      custom_form: customFormQuery.data?.id,
      disposition_status: summary_ticket_status,
      disposition_tickets: dispositionSummarized,
      disposition_status_summarized: dispositionSummarized ? summarized_tickets_status : null,
      list_line_items: Boolean(item_conf & 1),
      group_items: Boolean(item_conf & 2),
      build_source: {
        type: 'summary_wizard_module',
        module_id: module.id
      }
    }

    const summary = await createTicketsSummaryMutation.mutateAsync(payload)
    notification.success({ message: 'Ticket summarizing process initiated.' })
    form.setFieldsValue({ selected_tickets: [] })
    onFinish(summary)
  }

  const handleSubmit = async () => {
    if (ticketsWithParent.length > 0) {
      const yes = await modal.confirm({
        title: 'Incorrect Ticket Selected',
        content: (
          <div>
            <div>Following tickets already have parent ticket.</div>
            <div>Continuing will set a new parent to these tickets.</div>
            <div className="font-bold">{ticketsWithParent.map((item: any) => item.name).join(', ')}</div>
            <div>Are you sure you want to continue?</div>
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No'
      })

      if (yes) {
        handleMutate()
      }

      return
    }

    handleMutate()
  }

  return (
    <div className="w-[30%]">
      <Spin spinning={summarySkuQuery.isInitialLoading || customFormQuery.isInitialLoading}>
        {contextHolder}
        <div>
          <SectionTitle rounded number={2}>
            Summary Settings
          </SectionTitle>
          <div className="mt-16">
            <Form.Item label="Summary Form">
              <Select
                className="pointer-events-none"
                value={customFormQuery.data?.id}
                options={[
                  {
                    label: customFormQuery.data?.name,
                    value: customFormQuery.data?.id
                  }
                ]}
              />
            </Form.Item>
            <Form.Item label="Summary Ticket Status">
              <Select
                className="pointer-events-none"
                value={summary_ticket_status}
                options={Object.entries(TICKET_STATUS).map(([key, value]) => ({
                  label: value,
                  value: key
                }))}
              />
            </Form.Item>
            <Form.Item label="Summarized Ticket Status">
              <Select
                className="pointer-events-none"
                value={summarized_tickets_status}
                options={Object.entries(TICKET_STATUS).map(([key, value]) => ({
                  label: value,
                  value: key
                }))}
              />
            </Form.Item>
            <Form.Item label="Summary SKU">
              <Select
                className="pointer-events-none"
                value={summarySkuQuery.data?.sku}
                options={[
                  {
                    label: summarySkuQuery.data?.sku,
                    value: summarySkuQuery.data?.sku
                  }
                ]}
              />
            </Form.Item>
            <Form.Item label="List Each Line Item Individually">
              <Select
                className="pointer-events-none"
                value={item_conf}
                options={[
                  {
                    label: 'Yes - Group',
                    value: 3 as any
                  },
                  {
                    label: 'Yes',
                    value: 1 as any
                  },
                  {
                    label: 'No',
                    value: 0 as any
                  }
                ]}
              />
            </Form.Item>
            <Form.Item label="Attachment Type" name="attachment_type" initialValue={attachment_type}>
              <Select options={SUMMARY_ATTACHMENT_TYPE_SELECT} />
            </Form.Item>
            <Button
              type="primary"
              block
              loading={createTicketsSummaryMutation.isLoading}
              disabled={selectedTickets.length === 0}
              onClick={handleSubmit}
            >
              Generate Summary Ticket
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  )
}
