import { useApp, useSession } from '@/hooks'
import { Form, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { equipmentAtom } from '../../atoms'

export const SkuPropertiesSelect = () => {
  const { company } = useSession()
  const { l } = useApp()
  const equipment = useAtomValue(equipmentAtom)
  const setEquipment = useSetAtom(equipmentAtom)

  const options = useMemo(() => {
    const options = [
      {
        label: 'Public',
        value: 'public',
        hidden: false
      },
      {
        label: `${l('SKU')} Group`,
        value: 'group',
        hidden: false
      },
      {
        label: 'Discount SKU',
        value: 'discount_sku',
        hidden: !!equipment.group || !!equipment.tax_sku
      },
      {
        label: 'Tax SKU',
        value: 'tax_sku',
        hidden: !!equipment.group || !!equipment.discount_sku
      },
      {
        label: 'Include Subtotals',
        value: 'group.subtotal',
        hidden: !equipment.group
      },
      {
        label: 'Keep all times synced on group line',
        value: 'group.sync_datetime',
        hidden: !equipment.group
      },
      {
        label: 'Skip Tax Calculation',
        value: 'skip_tax_calc',
        hidden: !!equipment.group
      },
      {
        label: 'Summary SKU',
        value: 'summary_sku',
        hidden: !!equipment.group
      },
      {
        label: 'Remove from printing and totals',
        value: 'print_hide',
        hidden: !!equipment.group
      },
      {
        label: 'Remove from accounting invoices',
        value: 'hide_accounting',
        hidden: !!equipment.group
      },
      {
        label: 'Hide from mobile app',
        value: 'hide_mobile',
        hidden: !!equipment.group
      },
      {
        label: 'Inventory Tracking',
        value: 'inventory',
        hidden: !!equipment.group
      },
      {
        label: 'Finished Good',
        value: 'is_finished_good',
        hidden: !!equipment.group
      },
      {
        label: 'Sub-Component',
        value: 'is_sub_component',
        hidden: !!equipment.group
      },
      {
        label: 'Non-Discountable',
        value: 'non_discountable',
        hidden: !!equipment.group
      },
      {
        label: 'Non-Taxable',
        value: 'non_taxable',
        hidden: !!equipment.group
      },
      {
        label: 'Accounting: Description Only',
        value: 'accounting_description_only',
        hidden: false
      },
      {
        label: 'Retire SKU',
        value: 'inactive',
        hidden: false
      }
    ] as const

    return options.reduce((options, option) => {
      if (option.hidden) {
        return options
      }

      return [...options, { label: option.label, value: option.value }]
    }, [] as any[])
  }, [equipment.discount_sku, equipment.group, equipment.tax_sku, l])

  const handleSelect = (option: (typeof options)[number]) => {
    if (option.value === 'group') {
      const tempGroup: typeof equipment.group = {
        subtotal: false,
        sync_datetime: false,
        company: company.id,
        equipment: [],
        equipment_ids: []
      }

      setEquipment((prev) => ({
        ...prev,
        group: tempGroup
      }))
      return
    }

    if (option.value === 'group.subtotal') {
      setEquipment((prev) => ({
        ...prev,
        group: {
          ...prev.group,
          subtotal: !prev.group?.subtotal
        }
      }))
      return
    }

    if (option.value === 'group.sync_datetime') {
      setEquipment((prev) => ({
        ...prev,
        group: {
          ...prev.group,
          sync_datetime: !prev.group?.sync_datetime
        }
      }))
      return
    }

    setEquipment((prev) => ({
      ...prev,
      [option.value]: !equipment[option.value]
    }))
  }

  const handleDeselect = (option: (typeof options)[number]) => {
    if (option.value === 'group') {
      setEquipment((prev) => ({
        ...prev,
        group: null
      }))
      return
    }

    setEquipment((prev) => ({
      ...prev,
      [option.value]: !equipment[option.value]
    }))
  }

  const values = options
    .filter((option) =>
      // Some options are nested in equipment.group
      option.value.startsWith('group.')
        ? equipment.group && !!equipment.group[option.value.replace('group.', '') as keyof typeof equipment.group]
        : !!equipment[option.value]
    )
    .filter((option) => !option.hidden)
    .map((option) => option.value) as any

  return (
    <div>
      <div className="font-bold mb-14">SKU Properties</div>
      <Form.Item label="SKU Properties" className="w-full">
        <Select
          mode="multiple"
          onSelect={(_, option) => handleSelect(option as any)}
          onDeselect={(_, option) => handleDeselect(option as any)}
          value={values}
          placeholder="Select SKU Properties"
          data-cy="sku-properties-select"
          optionFilterProp="label"
          options={options}
        />
      </Form.Item>
    </div>
  )
}
