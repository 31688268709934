import { Button, ButtonProps } from '@/ui/button/button'
import { Icon, IconName } from '@/ui/icons'
import { Tooltip } from '@/ui/tooltip'
import { TooltipProps } from 'antd'
import { PresetColorType } from 'antd/es/_util/colors'
import { LiteralUnion } from 'antd/es/_util/type'
import { FC, ReactNode, useState } from 'react'

type ButtonTooltip = {
  title?: ReactNode
  color?: LiteralUnion<PresetColorType>
  flashIcon?: IconName
  flashTitle?: ReactNode
  flashColor?: LiteralUnion<PresetColorType>
  flashDuration?: number
  tooltip?: Omit<TooltipProps, 'title' | 'color'>
}

export type TipButtonProps = Omit<ButtonProps, 'color'> & ButtonTooltip

/**
 * Extends a Button component with tooltip functionality.
 *
 *  ### ButtonTooltip ###
 *  Supports tooltip with extended flashing on click.
 *    - Use `flashIcon`, `flashTitle` and `flashColor` props to set the flash icon, title and color.
 */
export const TipButton: FC<TipButtonProps> = ({
  title,
  color,
  flashIcon,
  flashTitle,
  flashColor,
  flashDuration,
  tooltip,
  onClick,
  children,
  ...rest
}) => {
  const [isFlashed, setIsFlashed] = useState(false)

  flashColor = flashColor || 'green'
  flashIcon = flashIcon || 'fa:check-circle'
  flashDuration = flashDuration || 1000

  const tipTitle = isFlashed ? flashTitle : title
  const tipColor = isFlashed ? flashColor || color : color

  const handleOnClick = (e: any) => {
    onClick?.(e)

    if (flashTitle) {
      setIsFlashed(true)
      setTimeout(() => setIsFlashed(false), flashDuration)
    }
  }

  const button = (
    <Button onClick={handleOnClick} {...rest}>
      {children}
    </Button>
  )

  return title || flashTitle ? (
    <Tooltip
      {...tooltip}
      open={isFlashed || undefined}
      title={
        tipTitle ? (
          <span>
            {isFlashed && flashIcon ? <Icon name={flashIcon} className={'mr-4'} /> : null}
            {tipTitle}
          </span>
        ) : null
      }
      color={tipColor}
    >
      {button}
    </Tooltip>
  ) : (
    button
  )
}
