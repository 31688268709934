import { useApp } from '@/hooks'
import { useJobTicketSave, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { Button } from '@/ui/button'
import React from 'react'

export const JobSaveResourcesButton: React.FC = () => {
  const { notification, l } = useApp()
  const { selectedTicket: jobTicket, isModified, setEditing } = useSelectedJobTicket()
  const { saveTicket, isLoading } = useJobTicketSave()

  const onSaveClick = () => {
    if (!jobTicket) return

    saveTicket({
      action: 'saveResources',
      onSuccess: () => {
        setEditing(false)

        // // invalidate cache and refetch
        // queryClient.invalidateQueries({
        //   predicate: (query) => query.queryKey[0] === 'jas/job_tickets'
        // })

        notification.success({
          message: 'Resources Saved',
          description: `Resources for ${l('ticket__name__label')} ${jobTicket.name} has been saved`
        })
      },
      onError: () => {
        notification.error({
          message: 'Resources Save Failed',
          description: `Failed to save resources for ${l('ticket__name__label')} ${jobTicket.name}`
        })
      }
    })
  }
  return (
    <Button disabled={!isModified} type="success" loading={isLoading} onClick={onSaveClick}>
      Save
    </Button>
  )
}
