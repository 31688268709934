import dayjs from 'dayjs'
import { JobAssignConfig, JobRole, JobTicket } from '../schemas'
import { getEndDate } from './formatters'
import { parseAttributes, parseRequirements } from './parsers'

export const transformJobTicket = (
  ticket: JobTicket,
  jobDataMap: JobAssignConfig,
  jobRolesMap: Record<number, JobRole>
): JobTicket => {
  // parse start date
  const tpMap = ticket.property
  const startDate = dayjs(tpMap[jobDataMap.start_date_pid || 0] || ticket.timepoint_due)

  // parse estimated/elapsed hours
  let estimatedHours = Number(tpMap[jobDataMap.estimated_hours_pid || 0])
  if (isNaN(estimatedHours)) estimatedHours = 0

  // calculate end date
  const endDate = getEndDate(startDate, estimatedHours)
  const endDateIsoStr = endDate.isValid() ? endDate.toISOString() : ''

  // enrich ticket with job data
  ticket._jobData = {
    id: ticket.id,
    name: ticket.name,
    modified_at: ticket.modified_at || '',
    startDate: ticket.timepoint_due,
    endDate: endDateIsoStr,
    elapsedTime: estimatedHours,
    title: tpMap[jobDataMap.title_pid || 0],
    jobType: tpMap[jobDataMap.job_type_pid || 0],
    description: tpMap[jobDataMap.description_pid || 0],
    line1: tpMap[jobDataMap.line_1_pid || 0] || ticket.customer_office__name,
    line2: tpMap[jobDataMap.line_2_pid || 0] || ticket.location__name,
    line3: tpMap[jobDataMap.line_3_pid || 0],
    notes: tpMap[jobDataMap.notes_pid || 0],
    attributes: parseAttributes(tpMap[jobDataMap.attributes_pid || 0]),
    requirements: parseRequirements(tpMap[jobDataMap.requirements_pid || 0], jobRolesMap)
  }

  return ticket
}
