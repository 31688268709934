import { url } from '@/routing/helpers'
import { Button } from '@/ui/button'
import { Dropdown } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { NavTabItem } from '../types'

type Props = {
  items: NavTabItem[]
  onTabSelect: (tab: NavTabItem) => void
}

export const MoreDropdown: FC<Props> = ({ items, onTabSelect }) => {
  if (items.length === 0) return null

  return (
    <Dropdown
      arrow={true}
      placement="bottomRight"
      trigger={['click', 'hover']}
      menu={{
        items: items.map((tab) => ({
          key: tab.to,
          className: '!p-0 !m-1',
          label: (
            <NavLink to={url(tab.to)} onClick={() => onTabSelect(tab)}>
              {({ isActive }) => (
                <div className={cn('-mx-2 px-8 py-4 rounded ', { 'bg-primary-50 text-primary': isActive })}>
                  {tab.label}
                </div>
              )}
            </NavLink>
          )
        }))
      }}
    >
      <Button iconName={'fa:ellipsis-h'} className={'!bg-transparent min-w-[35px]'} />
    </Dropdown>
  )
}
