import { useApp } from '@/hooks'
import { CLONE_TICKET_SECTIONS } from '@/modules/ticket/constants'
import { useTicketSelection } from '@/modules/ticket/list/hooks'
import { ticketApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form, Input } from 'antd'
import { FC, useState } from 'react'
import { TicketBulkAction } from './TicketBulkAction'

const DEFAULT_SECTIONS = CLONE_TICKET_SECTIONS.filter((section) => section.key !== 'members').map(
  (section) => section.key
)

export const TicketCopyButton: FC = () => {
  const [copies, setCopies] = useState(1)
  const [copySections, setCopySections] = useState<string[]>(DEFAULT_SECTIONS)

  const { selectedTickets } = useTicketSelection()

  const { notification } = useApp()
  const mutation = useMutation(ticketApi.clone())

  const onSectionSelect = (key: string) => {
    if (copySections.includes(key)) {
      setCopySections(copySections.filter((k) => k !== key))
    } else {
      setCopySections([...copySections, key])
    }
  }

  const onOk = async (closeFn: any) => {
    try {
      // call api
      await Promise.all(
        selectedTickets.map((ticket: any) =>
          mutation.mutateAsync({
            id: ticket.id,
            ...{
              copies: copies,
              ...copySections.reduce((obj: any, key: any) => ({ ...obj, [key]: 'True' }), {})
            }
          })
        )
      )

      // notify and close
      notification.success({ message: 'Success', description: 'Tickets copied' })
      closeFn(true)
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: (
          <div>
            <div>Failed to copy tickets.</div>
            <div>{e.toString()}</div>
          </div>
        )
      })
    }
  }

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:copy', children: 'Copy' }}
      modal={{
        title: 'Copy Tickets',
        okText: 'Copy Tickets',
        onOk: onOk,
        okButtonProps: { loading: mutation.isLoading }
      }}
      optionsTitle={'Copy Options'}
    >
      <div className={'flex flex-col gap-16'}>
        {CLONE_TICKET_SECTIONS.map((section) => (
          <Checkbox
            key={section.key}
            checked={copySections.includes(section.key)}
            onChange={() => onSectionSelect(section.key)}
          >
            Copy <span className={'font-medium'}>{section.label}</span>
          </Checkbox>
        ))}
        <Form.Item label={'Copies'}>
          <Input
            type={'number'}
            defaultValue={copies}
            onChange={(e) => setCopies(Number(e.target.value))}
            min={1}
            max={20}
          />
        </Form.Item>
      </div>
    </TicketBulkAction>
  )
}
