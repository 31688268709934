import { SEARCH_DEBOUNCE_TIME } from '@/constants'
import { LookupOperator } from '@/constants/api'
import { useIsMobile } from '@/hooks'
import { ListParams } from '@/types/api/core'
import { Icon } from '@/ui/icons'
import { isTouchDevice } from '@/utils'
import { Input, InputProps } from 'antd'
import cn from 'classnames'
import { ChangeEvent, FC, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { getSearchQuery } from './helpers'

export type SearchInputProps = InputProps & {
  searchBy?: string | ((searchText: string) => ListParams)
  searchType?: LookupOperator
  onSearch?: (text: string, query?: ListParams, event?: ChangeEvent<HTMLInputElement>) => void

  /**
   * Debounce time in milliseconds
   *
   * Note: Set it to 0 when using with Form.Item to avoid double debounce glitch
   *
   *  @default GLOBAL_DEBOUNCE_TIME
   */
  debounce?: number

  block?: boolean
}

export const SearchInput: FC<SearchInputProps> = ({
  searchBy,
  searchType,
  onSearch,
  onChange,
  debounce = SEARCH_DEBOUNCE_TIME,
  className,
  block = false,
  value,
  ...inputProps
}) => {
  const isMobile = useIsMobile()

  const handleSearch = useCallback(
    (searchText: string, event?: ChangeEvent<HTMLInputElement>) => {
      searchText = (searchText || '').trim()
      onSearch?.(searchText, getSearchQuery(searchText, searchBy, searchType), event)
    },
    [onSearch, searchBy, searchType]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleSearch(event.target.value, event)
      onChange?.(event)
    },
    [handleSearch, onChange]
  )

  const debouncedHandleChange = useDebouncedCallback(handleChange, debounce)

  return (
    <Input
      autoFocus={!isMobile && !isTouchDevice()} // no autofocus on touch devices, keyboard pops up automatically
      type={'search'}
      prefix={<Icon name="fa:search" className={'text-gray-400/30 dark:text-gray-400/50'} />}
      allowClear={true}
      className={cn(block ? 'w-full' : 'w-full md:w-[300px] max-w-full', className)}
      placeholder="Search"
      value={value}
      onChange={debounce ? debouncedHandleChange : handleChange}
      onClear={() => handleSearch('')}
      {...inputProps}
    />
  )
}
