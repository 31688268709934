import { layout } from '@/routing'
import { redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { JobManagerView } from './job-manager'
import { ResourceManagerLayout } from './resource-manager/layout'
import { RecordListView, RecordsLayout } from './resource-manager/records'
import { ResourceFormView, ResourceListView, ResourcesLayout } from './resource-manager/resources'
import { RuleTemplatesView } from './resource-manager/rules'
import { SchedulerLayout } from './schedule/layout'
import { ResourceScheduleJobAssignerView, ResourceScheduleView } from './schedule/views'
import {
  ScheduleCoverageView,
  ScheduleEmployeeView,
  ScheduleEmployeesView,
  ScheduleEquipmentView,
  ScheduleEquipmentsView
} from './scheduler'

export const routes: RouteObject[] = [
  route('m2/:moduleId/job-manager', layout('JobManagerLayout', 'Job Manager'), [route('index', JobManagerView)]),
  route('resource_manager/:moduleId', ResourceManagerLayout, [
    route('index', redirect('resources/employees')),
    route('resources/:resourceType', ResourcesLayout, [
      route('index', ResourceListView),
      route('add', ResourceFormView),
      route(':resourceId/edit', ResourceFormView)
    ]),
    route('records/:recordType', RecordsLayout, [route('index', RecordListView)]),
    route('rules', RuleTemplatesView)
  ]),

  route('m2/:moduleId/schedule', SchedulerLayout, [
    route('index', redirect('employees')),
    route('employees', ResourceScheduleView),
    route('equipment', ResourceScheduleView),
    route('employees/assign/ticket/:ticketId', ResourceScheduleJobAssignerView),
    route('equipment/assign/ticket/:ticketId', ResourceScheduleJobAssignerView),
    route('coverage', ScheduleCoverageView)
  ]),

  // @todo depreciated: remove after migrating job-manager
  route('m2/:moduleId/schedule-old', layout('SchedulerLayout', 'Scheduler'), [
    route('index', redirect('employees')),
    route('employees', ScheduleEmployeesView),
    route('employees/:id', ScheduleEmployeeView),
    route('equipment', ScheduleEquipmentsView),
    route('equipment/:id', ScheduleEquipmentView),
    route('coverage', ScheduleCoverageView)
  ])
]
