import { useModalState } from '@/hooks'
import { useTicketFlags } from '@/modules/ticket/form/hooks'
import { lineItemApiV2 } from '@/services/api-service'
import { ButtonProps, Modal, ModalProps } from '@/ui'
import { Button } from '@/ui/button'
import { useQuery } from '@tanstack/react-query'
import { FC, useCallback, useState } from 'react'
import { useCategoryKinds } from '../../hooks'
import { LineItemOptions, LineItemSchema } from '../../schemas'
import { ItemForm } from '../item-form'

type Props = {
  lineItemId?: number | null
  buttonProps?: ButtonProps
  modalProps?: ModalProps
  options?: LineItemOptions
  onSaved?: (lineItems?: any[]) => void
}

export const AddEditButton: FC<Props> = ({ lineItemId, buttonProps, modalProps, options, onSaved }) => {
  const { viewOnly } = useTicketFlags()
  const { isOpen, openModal, closeModal } = useModalState()

  const [enableQuery, setEnableQuery] = useState(false)

  // preloading category kinds and line item on button hover
  // for flicker-free modal opening
  const { categoryKindsQuery } = useCategoryKinds({ enabled: enableQuery })

  const lineItemQuery = useQuery({
    ...lineItemApiV2.list({ id__eq: lineItemId, fields: LineItemSchema, inc_archived: 1, no_count: '1' }),
    enabled: enableQuery && !!lineItemId
  })
  const lineItem = lineItemId ? lineItemQuery.data?.items?.[0] : undefined

  const isFetching = lineItemQuery.isFetching || categoryKindsQuery.isFetching

  const handleOpen = useCallback(() => {
    if (lineItemId) setEnableQuery(true)
    openModal()
  }, [lineItemId, openModal])

  const handleSubmit = useCallback(
    (savedItems?: any[]) => {
      onSaved?.(savedItems)
      closeModal()
    },
    [closeModal, onSaved]
  )

  return (
    <div className={'flex flex-col gap-4'}>
      <Button
        loading={isOpen && isFetching}
        onClick={handleOpen}
        onMouseEnter={() => setEnableQuery(true)}
        onFocus={() => setEnableQuery(true)}
        disabled={viewOnly}
        {...buttonProps}
      />

      <Modal
        open={!isFetching && (lineItemId ? isOpen && !!lineItem : isOpen)}
        onCancel={closeModal}
        footer={null}
        width={lineItemId ? '800px' : '100%'}
        styles={
          lineItemId
            ? { body: { maxHeight: 'calc(100vh - 100px)', overflowY: 'hidden' } }
            : { body: { height: 'calc(100vh - 100px)', overflowY: 'hidden' } }
        }
        {...modalProps}
      >
        <ItemForm lineItem={lineItem} options={options} onSubmit={handleSubmit} onCancel={closeModal} />
      </Modal>
    </div>
  )
}
