import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { Button } from '@/ui/button'
import { useQueryClient } from '@tanstack/react-query'
import { Form } from 'antd'
import { useState } from 'react'
import { AddOfficeView } from '../../add-office-view'
import { OfficeCard } from './office-card'

type Props = {
  offices: Office[]
  company?: Company
  isPrivate?: boolean
}

export const OfficesListCards = ({ offices, company, isPrivate }: Props) => {
  const queryClient = useQueryClient()
  const [officeAddActive, setOfficeAddActive] = useState(false)
  if (!company) {
    return null
  }

  const onAddActiveClose = async () => {
    setOfficeAddActive(false)
    await queryClient.invalidateQueries({ queryKey: companyApi.list().queryKey })
  }

  return (
    <div>
      <div className="flex justify-end mb-12">
        {/* <AppLink to={ROLODEX_PATHS.addOffice({ companyId: company.id, isPrivate: !!isPrivate })}> */}
        {!officeAddActive && (
          <Button iconName="mi:add" type="primary" onClick={() => setOfficeAddActive(true)}>
            Add Office
          </Button>
        )}
        {/* </AppLink> */}
      </div>

      {officeAddActive && <AddOfficeView onCancel={() => onAddActiveClose()} companyId={company.id} />}

      {!officeAddActive && (
        <Form.List name="offices">
          {(fields, { remove }) => (
            <div className="flex flex-col justify-start gap-y-24 w-full">
              {fields.map((field, index) => (
                <OfficeCard
                  key={field.key}
                  field={field}
                  company={company}
                  onDelete={() => remove(index)}
                  office={offices[index]}
                />
              ))}
            </div>
          )}
        </Form.List>
      )}
    </div>
  )
}
