import { eventApi } from '@/services/api-service'
import { ListParams, ListQueryApiFn } from '@/types/api/core'
import { Event } from '@/types/event'
import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'
import { useState } from 'react'
import { ActivityItem } from './activity-item'

type Props = {
  api?: ListQueryApiFn
  params?: ListParams

  /**
   * List of events to display
   * @deprecated Use `queryApi` and `queryParams` instead
   */
  events?: Event[]

  /**
   * Loading state
   * @deprecated Use `queryApi` and `queryParams` instead
   */
  isLoading?: boolean
}

export const RecentActivities = ({ api, params, events: _events, isLoading: _isLoading }: Props) => {
  api = api || eventApi.list

  const eventsQuery = useQuery({ ...api({ ...params, limit: params?.limit || 8 }), enabled: !_events })

  const events = _events ?? eventsQuery.data?.items
  const isLoading = _isLoading ?? eventsQuery.isLoading

  const [collapsed, setCollapsed] = useState<number[]>([])

  const isCollapsed = (id: number) => {
    return collapsed.includes(id)
  }

  const handleSetCollapsed = (id: number) => {
    if (isCollapsed(id)) {
      setCollapsed(collapsed.filter((item) => item !== id))
    } else {
      setCollapsed((collapsed) => [...collapsed, id])
    }
  }

  if (isLoading) return <Skeleton />

  return (
    <div>
      {events?.map((event) => (
        <ActivityItem
          key={event.id}
          isCollapsed={isCollapsed(event.id)}
          event={event}
          onTriggerClick={handleSetCollapsed}
        />
      ))}
    </div>
  )
}
