import { isEmpty } from 'lodash'
import { FC, useCallback } from 'react'
import { useTicket, useTicketSave } from '../../../../../hooks'
import { Property } from '../../../../../schemas'
import { AddAttachmentButton } from '../add-attachment-button'
import { AttachmentCard } from '../attachment-card'

type Props = {
  property: Property
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

export const AttachmentInput: FC<Props> = ({ property, value, onChange, disabled }) => {
  const { ticket } = useTicket()
  const { saveTicket } = useTicketSave()

  const attachment = !isEmpty(value) ? ticket.attachments?.find((a) => a.uuid === value) : null

  const handleAfterUpload = useCallback(
    async (attachment: { id: number; uuid: string }) => {
      onChange?.(attachment.uuid)
      await saveTicket({ only: [property._field], override: { [property._field]: attachment.uuid } })
    },
    [onChange, property._field, saveTicket]
  )

  return (
    <div>
      {attachment ? (
        <AttachmentCard attachment={attachment} disabled={disabled} />
      ) : (
        <AddAttachmentButton disabled={disabled} afterUpload={handleAfterUpload}>
          Upload
        </AddAttachmentButton>
      )}
    </div>
  )
}
