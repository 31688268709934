import UsStatesData from '@/data/us-states.json'
import { Select, SelectProps } from '@/ui/select'
import { FC } from 'react'

type UsState = {
  abbreviation: string
  name: string
}

type Props = SelectProps & {
  valueKey?: keyof UsState
}

export const StateSelect: FC<Props> = ({ valueKey = 'abbreviation', ...props }) => {
  const options = UsStatesData.map((state: UsState) => ({
    value: state[valueKey],
    label: state.name
  }))

  return <Select options={options} {...props} />
}
