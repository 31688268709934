import { PROPERTY_TYPE as PT } from '@/modules/custom-form/constants'
import { parseLayoutStr } from '@/modules/custom-form/helpers'
import { AuthUser } from '@/types/auth-user'
import { dig, getFileExtension, makeMap, parseCsvOptions, safeJsonParse, slugify, splitFileName } from '@/utils'
import dayjs from 'dayjs'
import { cloneDeep, groupBy, sortBy, uniqBy } from 'lodash'
import { DATE_FIELDS } from '../constants'
import { autoFillTicket } from '../helpers/auto-fill'
import { CustomForm, Property, Ticket } from '../schemas'

export const transformProperty = (property: Property) => {
  property._field = `p_${property.id}`

  const layout = parseLayoutStr(property.layout || '')

  property._options = []
  if (property.property_type_id === PT.Dropdown || property.property_type_id === PT.MultivariateDropdown) {
    property._options = uniqBy(parseCsvOptions(property.values || '') || [], 'value')
  }
  property._optionsMap = makeMap(property._options, 'value')

  property._width = layout.width
  property._skip = layout.skip

  property._buttonConfig = (property.button_config || null) as any

  return property
}

export const transformCustomForm = (form: CustomForm) => {
  // clone to avoid mutation
  form = cloneDeep(form)

  // process properties
  const _propByGroupId = new Map<number, Property[]>()
  form.properties.forEach((p) => {
    p = transformProperty(p)

    const items = _propByGroupId.get(p.property_group_id) || []
    items.push(p)
    _propByGroupId.set(p.property_group_id, items)
  })

  // process property groups
  form.property_groups.forEach((pg) => {
    pg._properties = sortBy(_propByGroupId.get(pg.id) || [], (p) => p.sequence || 0)
  })
  form.property_groups = sortBy(form.property_groups, (pg) => pg.sequence || 0)

  // process user data
  const userData = safeJsonParse(form.userdata_json) || {}
  form._userData = userData

  // some useful lookups
  form._fieldAliases = dig(userData, 'field_aliases') || {}
  form._fieldsConfig = dig(userData, 'fields_config') || {}

  // custom features
  form._features = {
    customStatus: dig(userData, 'custom_status') || null,
    contactSearch: dig(userData, 'contact_search') || null,
    dynamicFormName: dig(userData, 'dynamic_form_name') || null,
    restrictEdit: dig(userData, 'features.restrict_edit'),
    restrictPrint: dig(userData, 'restrict_print') || null,
    printOptions: dig(userData, 'print') || null,
    skuSearch: dig(userData, 'sku_search') || null,
    skuSelection: dig(userData, 'custom_sku_selection') || null, // TODO: implement this
    hideItemFields: dig(userData, 'hide_item_fields') || null,
    pricingTweaks: dig(userData, 'pricing_tweaks') || null,
    restrictLineItemEdits: dig(userData, 'restrict_lineitem_edits') || false,
    eachUnitQtyCheck: dig(userData, 'each_unit_qty_check') || false,
    reportButtons: dig(userData, 'report_buttons') || []
  }

  // some useful lookups
  form._propertyById = makeMap(form.properties, 'id')
  form._propertyByKey = makeMap(form.properties, 'key')
  form._propertiesByTypeId = groupBy(form.properties, 'property_type_id')

  // return processed form
  return form
}

export const transformTicket = (ticket: Ticket, customForm?: CustomForm, user?: AuthUser) => {
  // clone ticket to avoid mutation
  ticket = cloneDeep(ticket)

  // apply autofill if ticket is new
  if (!ticket.id && customForm && user) autoFillTicket(ticket, customForm, user)

  // process status
  ticket.status = ticket.computed_status || ticket.status || 'D'

  // re-process date fields to iso string
  // to have consistent value format
  DATE_FIELDS.forEach((field) => {
    const value = dayjs.parse(ticket[field])
    if (!value) ticket[field] = null
    else {
      ticket[field] = value.formatISO()
    }
  })

  // re-process ticket properties (date and datetime)
  ticket.properties.forEach((tp) => {
    if (!customForm) return

    const property = customForm._propertyById[tp.property_id]
    const typeId = property?.property_type_id
    if (!typeId) return

    // sanitize date and datetime
    if ([PT.Time, PT.DateTime].includes(typeId)) {
      tp.value = dayjs.parse(tp.value)?.formatISO() || null
    } else if ([PT.Date].includes(typeId)) {
      tp.value = dayjs.parse(tp.value, { tz: 'replace' })?.formatISODate(false) || null
    }
  })

  // process attachments and signatures names
  ticket.attachments.forEach((a) => {
    if (!customForm) return

    const aTp = ticket.properties.find((tp) => tp.value === a.uuid)
    a._propertyId = aTp?.property_id || null

    const title = customForm._propertyById[a._propertyId || 0]?.name || a.name || 'Untitled'
    const fileExtension = getFileExtension(a.image || '')
    const fileNamePart = splitFileName(title, 'jpg')

    a._fileName = `${slugify(fileNamePart.name)}.${fileExtension}`
  })

  // process signatures names
  ticket.signatures.forEach((s) => {
    if (!customForm) return

    const sTp = ticket.properties.find((tp) => tp.value === s.uuid)
    s._propertyId = sTp?.property_id || null
    s._title = customForm._propertyById[sTp?.property_id || 0]?.name || s.name || 'Untitled'
  })

  // process assets_json (maybe depreciate?)
  // ticket._assets = safeJsonParse(ticket.assets_json) || []

  // process data json
  ticket._data = safeJsonParse(ticket.data_json) || {}

  // check if user is customer
  ticket._is_customer =
    !!ticket.office_id &&
    ticket.office__company_id !== user?.profile.company.id &&
    ((ticket.customer_office_id && ticket.customer_office__company_id === user?.profile.company.id) ||
      (!!ticket.customer_id && ticket.customer__company_id === user?.profile.company.id))

  // check if user is staff
  ticket._is_staff = user?.is_superuser || user?.profile.company.id == customForm?.primary_company_id
  ticket._access = (customForm?.permissions || []).find((cp) => cp.company_id === user?.profile.company.id) || {
    company_id: user?.profile.company.id || 0,
    create: false,
    write: false,
    list: false
  }

  // some useful lookups
  ticket._tpByPropertyId = makeMap(ticket.properties, 'property_id')
  ticket._tpByPropertyKey = makeMap(ticket.properties, 'property__key')

  const [_restrictEdit, msg] = _checkRestrictEditFeature(ticket, customForm, user)
  ticket._viewOnly = Boolean(
    !ticket._access.write || (ticket._is_customer && customForm?.view_only_customer) || _restrictEdit
  )
  ticket._editRestrictedMsg = msg

  return ticket
}

const _checkRestrictEditFeature = (ticket: Ticket, customForm?: CustomForm, user?: AuthUser): [boolean, string] => {
  const _conf = customForm?._features.restrictEdit
  const [_pidRestricted, _msg] = _parseRestrictEditProp(ticket, _conf?.pid)
  const allowedEditContactIds = _parseAllowEditContactsProp(ticket, _conf?.except_contacts_pid)
  const msg = _conf?.msg || _msg

  const restrict = Boolean(
    _conf?.enabled &&
      ((_conf?.statuses || []).includes(ticket.computed_status as string) ||
        _pidRestricted ||
        (allowedEditContactIds.length && !allowedEditContactIds.includes(user?.profile.contact.id || 0)))
  )

  return [restrict, msg]
}

const _parseRestrictEditProp = (ticket: Ticket, pid?: number | null): [boolean, string] => {
  if (!pid) return [false, '']

  const val = (ticket._tpByPropertyId[pid]?.value || '').split(',')
  const [_restrict, msg] = (val.length === 1 ? val.concat(['']) : val).map((v) => v.trim())
  return [['1', 'true', 'yes'].includes(_restrict.toLowerCase()), msg]
}

const _parseAllowEditContactsProp = (ticket: Ticket, pid?: number | null): number[] => {
  if (!pid) return []

  return (ticket._tpByPropertyId[pid]?.value || '')
    .split(',')
    .map((v) => Number(v))
    .filter((v) => v)
}
