import { notificationTemplateApi } from '@/services/api-service'
import { filterSelectOption } from '@/utils/filter-select-option'
import { useQuery } from '@tanstack/react-query'
import { Form, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../atoms'

export const EmailTemplates = () => {
  const notificationTemplatesQuery = useQuery(notificationTemplateApi.list({ limit: 0, type: 'E', status: 'P' }))
  const emailTemplates = useAtomValue(useMemo(() => selectAtom(customFormAtom, (c) => c._templates), []))
  const setCustomForm = useSetAtom(setCustomFormAtom)

  const options = [
    {
      label: 'Use system default',
      value: ''
    },
    ...(notificationTemplatesQuery.data?.items.map((template) => ({
      label: template.name,
      value: template.uuid
    })) || [])
  ]

  const setSecureAccessVendor = (value: string) => {
    setCustomForm({
      _templates: {
        ...emailTemplates,
        secure_access_vendor: value
      }
    })
  }

  const setSecureAccessSignature = (value: string) => {
    setCustomForm({
      _templates: {
        ...emailTemplates,
        secure_access_signature: value
      }
    })
  }

  return (
    <div className="flex-1">
      <h4 className="font-bold">Email Templates</h4>
      <Form layout="vertical">
        <Form.Item label="Vendor Access Request">
          <div className="flex gap-x-10">
            <Select
              placeholder="Select"
              options={options}
              defaultValue=""
              value={emailTemplates?.secure_access_vendor || ''}
              showSearch
              filterOption={filterSelectOption}
              onChange={setSecureAccessVendor}
              data-cy="selelect-template-vendor-access-request"
            />
          </div>
        </Form.Item>
        <Form.Item label="Signature Request">
          <div className="flex gap-x-10">
            <Select
              placeholder="Select"
              options={options}
              defaultValue=""
              value={emailTemplates?.secure_access_signature || ''}
              showSearch
              filterOption={filterSelectOption}
              onChange={setSecureAccessSignature}
              data-cy="select-templates-signature-request"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}
