/**
 * Convert an array of strings to a comma separated string
 * @param data
 */
export const csv = (data: string[]) => {
  return data.join(',')
}

/**
 * Convert single line string to an array of strings by splitting by comma
 * @param str
 */
export const parseCsv = (str: string | undefined | null): string[] => {
  return (str || '')
    .split(',')
    .map((x) => x.trim())
    .filter((x) => !!x)
}

/**
 * Convert a string to an array of objects with value and label properties
 * @param str
 */
export const parseCsvOptions = (str: string | undefined | null) => {
  const lines = []
  const items = (str || '').trim().split('\n')
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const values = item.split(',')
    if (values.length > 0) {
      const value = values.splice(0, 1)[0].trim()
      const label = values.join(',').trim() || value

      if (value || label) {
        lines.push({ value, label })
      }
    }
  }
  return lines
}
