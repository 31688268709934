import { DATE_FORMAT, ISO_DATE_FORMAT } from '@/constants/date'
import { useApp } from '@/hooks'
import { programmedReportApi } from '@/services/api-service'
import { Modal, ModalProps } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { DatePicker, Form, Input } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Attribute, ProgrammedReport } from '../../schema'

type Props = {
  attribute: Attribute
  programmedReport: ProgrammedReport
  onSaved: () => void
} & ModalProps

export const EditAttributeModal = ({ attribute, programmedReport, onSaved, ...props }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const updateAttributeMutation = useMutation(programmedReportApi.patch())
  const [form] = Form.useForm()
  const { notification } = useApp()

  const handleSave = async () => {
    const { values } = form.getFieldsValue()

    const attributes = programmedReport.attributes?.map((attr) => {
      if (attr.name === attribute.name) {
        return {
          ...attr,
          values: values.map((value: any) => (attribute.type === 'Date' ? dayjs(value).format(ISO_DATE_FORMAT) : value))
        }
      }

      return attr
    })

    await updateAttributeMutation.mutateAsync({
      id: programmedReport.id,
      attributes
    })

    notification.success({ message: 'Attribute saved' })
    setIsOpen(false)
  }

  if (!attribute) {
    return null
  }

  return (
    <Modal title="Edit Attribute" open okText="Save" width={500} onOk={handleSave} {...props}>
      <Form
        form={form}
        layout="vertical"
        key={programmedReport.id}
        initialValues={{
          ...attribute,
          values: attribute.values.map((value) => (attribute.type === 'Date' ? dayjs(value) : value))
        }}
      >
        <Form.Item label="Name" name="name">
          <Input disabled />
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-16">
          <Form.List name="values">
            {(fields) => {
              return fields.map((field, index) => (
                <Form.Item key={index} label={`Value ${index + 1}`} name={field.name}>
                  {attribute.type === 'Text' && <Input className="w-full" />}
                  {attribute.type === 'Date' && (
                    <DatePicker allowClear={false} format={DATE_FORMAT} className="w-full" />
                  )}
                </Form.Item>
              ))
            }}
          </Form.List>
        </div>
      </Form>
    </Modal>
  )
}
