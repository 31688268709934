import { Select } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import { useBaseReportDisplayfieldSetOptions, useTransformationTaskTabsOptions } from '../../../../hooks'

export const LoopScript = () => {
  const tabsOptions = useTransformationTaskTabsOptions()
  const displayfieldSetOptions = useBaseReportDisplayfieldSetOptions()

  return (
    <>
      <Form.Item name={['params', 'base_ds']} label="Base DataSet" rules={[{ required: true }]}>
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.Item label="Sequence Variable" name={['params', 'loop_variable']} rules={[{ required: true }]}>
        <Select options={displayfieldSetOptions} />
      </Form.Item>
      <Form.Item label="Loop Variable Name" name={['params', 'sequence_field']}>
        <Input />
      </Form.Item>
      <Form.Item name={['params', 'unique_only']} noStyle valuePropName="checked">
        <Checkbox>Unique Only</Checkbox>
      </Form.Item>
    </>
  )
}
