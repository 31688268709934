import { Form, Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom, requiredFieldsErrorsAtom } from '../atoms'

export const DescriptionField = () => {
  const description = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.description), []))
  const setEquipment = useSetAtom(equipmentAtom)
  const { isDescriptionError } = useAtomValue(requiredFieldsErrorsAtom)
  const setRequiredFieldsErrors = useSetAtom(requiredFieldsErrorsAtom)

  return (
    <Form.Item
      label="Description"
      className="w-full"
      required
      validateStatus={isDescriptionError ? 'error' : undefined}
      help={isDescriptionError ? 'Please enter description' : undefined}
    >
      <Input
        data-cy="sku-description-input"
        value={description || ''}
        onChange={(e) => {
          setEquipment((prev) => ({
            ...prev,
            description: e.target.value
          }))

          if (isDescriptionError) {
            setRequiredFieldsErrors((prev) => ({
              ...prev,
              isDescriptionError: false
            }))
          }
        }}
      />
    </Form.Item>
  )
}
