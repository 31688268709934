import { CLONE_TICKET_SECTIONS } from '@/modules/ticket/constants'
import { customFormApiV2 } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Checkbox, Form, Input } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { TicketCopyButton } from './CopyTicketButton'
import { LineItemsSection } from './line-items-section'

const CheckBoxFormItem = (props: any) => (
  <Form.Item {...props} valuePropName={'checked'} label="">
    <Checkbox>{props?.label}</Checkbox>
  </Form.Item>
)

export const CopyTicketConfig: ScriptedButtonConfig = {
  type: 'copy_ticket',
  iconName: 'fa:copy',
  label: 'Copy Ticket',
  group: 'Common',
  render: () => <TicketCopyButton />,
  vars: [
    {
      name: 'copies',
      label: 'Copies',
      render: (customForm, props) => <Input {...props} type="number" defaultValue={1} />
    },
    ...CLONE_TICKET_SECTIONS.map((sec) => ({
      name: sec.key,
      label: sec.label,
      renderFormItem: (customForm: any, props: any) => <CheckBoxFormItem key={sec.key} {...props} />
    })),
    {
      name: 'line_items_filter_options',
      label: 'Line Items',
      renderFormItem: (_, props) => <LineItemsSection {...props} />
    },
    {
      name: 'new_custom_form_id',
      label: 'Switch Custom Form',
      render: (customForm, props) => (
        <QuerySelect
          apiEndpoint={customFormApiV2.list}
          apiQueryParams={{ fields: 'id,name' }}
          apiSearchBy="name"
          apiValueBy="id"
          renderOption={(cf) => ({ value: cf.id, label: cf.name })}
        />
      )
    },
    {
      name: 'success_message',
      label: 'Success Message',
      render: (customForm, props) => <Input {...props} defaultValue="Ticket has been copied successfully" />
    },
    {
      name: 'fail_message',
      label: 'Error Message',
      render: (customForm, props) => <Input {...props} defaultValue="Failed to copy ticket" />
    }
  ]
}
