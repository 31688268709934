import { NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { Button, Icon, Modal, Select } from '@/ui'
import { DatePicker, Form, Input } from 'antd'
import { Dayjs } from 'dayjs'
import { isEmpty, isNil, omitBy } from 'lodash'
import { useState } from 'react'
import { FilterNames, Filters, filters } from '../constants'
import { AppliedFilters } from './applied-filters'

type Props = {
  onApplyFilters: (filters: Filters) => void
}

export const FiltersModal = ({ onApplyFilters }: Props) => {
  const [form] = Form.useForm<Record<FilterNames, any>>()
  const [isOpen, setIsOpen] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState<Record<FilterNames, any>>()

  const handleApplyFilters = async () => {
    const { date_range, ...filters } = await form.validateFields()

    let modified_at__gte = ''
    let modified_at__lte = ''

    if (date_range && Array.isArray(date_range)) {
      modified_at__gte = (date_range[0] as Dayjs)?.toISOString()
      modified_at__lte = (date_range[1] as Dayjs)?.toISOString()
    }

    setAppliedFilters({ date_range, ...filters })
    onApplyFilters({ ...filters, modified_at__gte, modified_at__lte })
    setIsOpen(false)
  }

  const handleClearFilter = (name: FilterNames) => {
    form.setFieldValue(name, undefined)
    handleApplyFilters()
  }

  const handleClearAll = () => {
    form.resetFields()
    handleApplyFilters()
  }

  return (
    <div>
      <div className="flex gap-x-12 items-center">
        <Button iconName="fa:filter" onClick={() => setIsOpen(true)}>
          Filters
        </Button>
        {!isEmpty(omitBy(appliedFilters, isNil)) && (
          <div className="text-sm text-primary-400 cursor-pointer" onClick={handleClearAll}>
            Clear All
          </div>
        )}
        <AppliedFilters appliedFilters={appliedFilters} onClear={handleClearFilter} />
      </div>
      <Modal
        destroyOnClose={false}
        title={
          <div className="flex items-center gap-10">
            <Icon name="fa:filter" className="mr-2" />
            <span>Filters</span>
          </div>
        }
        open={isOpen}
        width={800}
        okText="Search"
        onOk={handleApplyFilters}
        onCancel={() => setIsOpen(false)}
      >
        <Form layout="vertical" form={form}>
          <div className="grid grid-cols-3 gap-x-16">
            {filters.map(({ name, label }) => {
              if (label === 'Date Range') {
                return (
                  <Form.Item key={name} name={name} label={label}>
                    <DatePicker.RangePicker />
                  </Form.Item>
                )
              }

              if (label === 'Channel') {
                return (
                  <Form.Item key={name} name={name} label={label}>
                    <Select
                      showSearch={false}
                      options={Object.entries(NOTIFICATION_TEMPLATE_TYPE).map(([value, label]) => ({
                        label,
                        value
                      }))}
                    />
                  </Form.Item>
                )
              }

              return (
                <Form.Item key={name} name={name} label={label}>
                  <Input />
                </Form.Item>
              )
            })}
          </div>
        </Form>
      </Modal>
    </div>
  )
}
