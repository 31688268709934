import { JobAssignedResources } from '@/modules/jas/job-manager/components/job-ticket-detail/JobAssignedResources'
import { JobAvatar } from '@/modules/jas/job-manager/components/job-ticket-detail/JobAvatar'
import { JobDispatchButton } from '@/modules/jas/job-manager/components/job-ticket-detail/JobDispatchButton'
import { JobSaveResourcesButton } from '@/modules/jas/job-manager/components/job-ticket-detail/JobSaveResourcesButton'
import { useAddResourceTypeParam, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { JobResource } from '@/modules/jas/job-manager/types'
import { ModuleLinkButton } from '@/modules/module'
import { ViewTicketLink } from '@/modules/ticket/components'
import { Icon } from '@/ui/icons'
import { EditOutlined } from '@ant-design/icons'
import { Button, Empty, Popconfirm, Skeleton, Tag } from 'antd'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { JobRequirementsSection } from './JobRequirementsSection'

const JobTicketDetail: React.FC = () => {
  const {
    selectedTicket: jobTicket,
    selectedJobData: jobData,
    isFetching,
    isRefetching,
    isModified,
    isEditing,
    setEditing,
    resetJobData
  } = useSelectedJobTicket()

  const [_addResourceType, setAddResourceType] = useAddResourceTypeParam()

  const scheduleAssignerQueryParams = useMemo(() => {
    const params = new URLSearchParams({
      _v: '2',
      from: 'job-manager',
      'filter.ref_date': jobData?.startDateStr || ''
    })

    return params.toString()
  }, [jobData])

  if (isFetching && !isRefetching)
    return (
      <div className="h-full w-full p-12">
        <Skeleton active paragraph={{ rows: 5 }} />
      </div>
    )

  if (!jobTicket)
    return (
      <div className="h-full w-full p-12 grid place-items-center">
        <Empty className="pb-30" description="No Job Ticket Selected" />
      </div>
    )

  if (!jobData)
    return (
      <div className="h-full w-full p-12 grid place-items-center">
        <Empty className="pb-30" description="Ticket needs to be processed before showing it's details" />
      </div>
    )

  const { employees, equipments } = jobData.resources

  const getResourcesForSkill = (skill: string): JobResource[] => {
    const allRes = employees.res_items.concat(equipments.res_items)
    const matchedResources: JobResource[] = []

    allRes.forEach((e) => {
      if (e.attributes[skill]) {
        matchedResources.push(e)
      }
    })

    return matchedResources
  }

  return (
    <div className="h-full w-full p-12 pb-[56px] overflow-auto">
      <div className="flex items-center flex-wrap mb-8 relative pt-[50px] sm:pt-0">
        <div className="font-bold"># {jobTicket.name}</div>
        <div className="ml-auto sm:ml-16 lg:ml-24">
          <Tag color={jobData.statusColor}>{jobData.statusLabel}</Tag>
          <Tag color={jobData.assignedPctColor}>{jobData.assignedPct || 0}%</Tag>
        </div>
        <div className="absolute top-0 left-0 right-0 sm:relative flex justify-center ml-auto sm:border-b-0 pb-10 sm:pb-0">
          <JobDispatchButton jobTicket={jobTicket} />

          <ViewTicketLink ticketId={jobTicket.id} formId={jobTicket.custom_form_id} target="_blank">
            <Button
              type="text"
              size="middle"
              className="bg-primary-50 dark:bg-gray-600 text-primary-400 text-sm hover:!bg-primary-100 dark:hover:!bg-gray-700 hover:!text-primary-400"
            >
              <Icon name="mi:open_in_new" className="text-base text-primary align-middle mr-4" /> View Ticket
            </Button>
          </ViewTicketLink>
        </div>
      </div>
      <div className="border-0 border-b border-solid border-gray-200 dark:border-gray-700 pb-8 mb-8">
        <div className="flex flex-wrap items-start sm:items-center">
          <div className="flex mr-24">
            <Icon name="mi:calendar_today" className="text-base text-neutral dark:text-dark-neutral mr-6" />
            <div className="mr-24">
              <span>{dayjs.formatLocalDate(jobData.startDate) || '--/--/----'}</span>
              <span className="text-gray-400 sm:mx-8 block sm:inline text-sm">
                {dayjs.formatLocalTime(jobData.startDate) || '--:--'}
              </span>
            </div>
            <div className="">
              <span>
                <span>{dayjs.formatLocalDate(jobData.endDate) || '--/--/----'}</span>
              </span>
              <span className="text-gray-400 sm:mx-8 block sm:inline text-xs">
                {dayjs.formatLocalTime(jobData.endDate) || '--:--'}
              </span>
            </div>
          </div>
          <div className="flex items-center flex-wrap">
            <Icon name="mi:schedule" className="text-base text-neutral dark:text-dark-neutral mr-6" />
            <span>{jobData.elapsedTime} hours</span>
          </div>
        </div>
      </div>

      <div className="mb-24">
        <h5 className="mb-8">Requirements </h5>
        <div className="flex flex-wrap gap-12">
          <JobRequirementsSection />
        </div>
      </div>

      <div className="mb-24 border-0 border-b border-solid border-gray-200 dark:border-gray-700 pb-8">
        <h5 className=" mb-8">Skills</h5>
        <div className="flex flex-wrap -mx-8">
          {jobData.attributes?.map((skill) => (
            <div key={skill} className="px-8">
              <div className="mb-6">
                <Tag bordered={false} className="text-gray-500 bg-gray-200 dark:bg-gray-600">
                  {skill}
                </Tag>
              </div>
              <div className="flex flex-wrap -mx-4">
                {getResourcesForSkill(skill).map((resource) => (
                  <div key={nanoid()} className="px-4">
                    <JobAvatar resource={resource} />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {jobData.attributes?.length === 0 && (
            <div className="min-h-[29px] px-10 text-sm text-gray-400">No Required Skills</div>
          )}
        </div>
      </div>

      <div className="mb-24">
        <div className="flex flex-wrap -mx-8 justify-center">
          <div className="px-8 w-full sm:w-auto mb-12">
            <ModuleLinkButton
              by="type"
              value="SD"
              iconName="fa:plus"
              className="border w-full border-primary-400 text-primary-400"
              urlSuffix={`/schedule/employees/assign/ticket/${jobTicket.id}/?${scheduleAssignerQueryParams}`}
            >
              Add Employee
            </ModuleLinkButton>
          </div>
          <div className="px-8  w-full sm:w-auto mb-12">
            <ModuleLinkButton
              by="type"
              value="SD"
              iconName="fa:plus"
              className="border w-full border-primary-400 text-primary-400"
              urlSuffix={`/schedule/equipment/assign/ticket/${jobTicket.id}/?${scheduleAssignerQueryParams}`}
            >
              Add Equipment
            </ModuleLinkButton>
          </div>
          {/*<div className="px-8  w-full sm:w-auto mb-12">
            <Button size="middle" className="border  w-full border-primary-400 text-primary-400" icon={<PlusOutlined />}>
              Add Crew
            </Button>
          </div>*/}
        </div>
      </div>

      <div className="">
        <div className="flex items-center justify-between flex-wrap mb-24">
          <h5 className="font-semibold">
            <span className="mr-8">Assigned Resources</span>
            {isModified && <Tag color="orange-inverse">Modified</Tag>}
          </h5>
          <div>
            {!isEditing && (
              <Button size="middle" icon={<EditOutlined />} onClick={() => setEditing(!isEditing)}>
                Edit
              </Button>
            )}
            {isEditing && (
              <div>
                <Popconfirm
                  placement={'topRight'}
                  title={'Confirm Cancel'}
                  description={'You have unsaved changes. Are you sure?'}
                  okText={'Yes'}
                  onConfirm={resetJobData}
                  disabled={!isModified}
                >
                  <Button className="mr-4" onClick={() => !isModified && setEditing(false)}>
                    Cancel
                  </Button>
                </Popconfirm>

                <JobSaveResourcesButton />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-12">
          <JobAssignedResources key={'employees'} type="employee" data={employees} className={'w-full md:w-6/12'} />
          <JobAssignedResources key={'equipments'} type="equipment" data={equipments} className={'w-full md:w-6/12'} />
        </div>
      </div>
    </div>
  )
}
export default JobTicketDetail
