import { CalendarTableVisibleDays } from '@/components'
import { DayColCell } from '@/components/calendar-table/day-col-cell'
import { FC } from 'react'
import { FooterCell } from '../footer-cell'

type TableFooterProps = {
  dates: string[]
  visibleDays: CalendarTableVisibleDays
  onCellClick: (date: string) => void
}

export const TableFooter: FC<TableFooterProps> = ({ dates, visibleDays, onCellClick }) => {
  return (
    <div className="flex w-full sticky bottom-0 bg-white">
      <div className="w-[240px] shrink-0 sticky left-0 z-10 bg-white"></div>
      <div className="h-[40px] bg-white flex w-full">
        {dates.map((item, colIndex) => (
          <DayColCell key={colIndex} colIndex={colIndex} visibleDays={visibleDays} withBorder={false}>
            <FooterCell date={item} onClick={onCellClick} />
          </DayColCell>
        ))}
      </div>
    </div>
  )
}
