import { useApp, useModalState } from '@/hooks'
import { locationApi } from '@/services/api-service'
import { Button } from '@/ui'
import { yamlParseSafe } from '@/utils/yaml'
import { useQuery } from '@tanstack/react-query'
import { get, isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import { useButtonContext } from '../../hooks'
import { DirectionsRouteModal } from './directions-route-modal'

export const FetchDirectionsButton = () => {
  const { notification } = useApp()
  const { ticket, getValueById, vars, buttonProps, property } = useButtonContext()
  const { district_pid, shop_address_pid, shop_address_map } = vars

  const { isOpen, openModal, closeModal } = useModalState()

  const [destinationAddress, setDestinationAddress] = useState({ label: '', value: '' })

  const locationQuery = useQuery({ ...locationApi.get<any>(ticket.location_id as number), enabled: false })

  const shopAddressMap = useMemo(
    () => (isEmpty(shop_address_map) ? {} : yamlParseSafe(shop_address_map)),
    [shop_address_map]
  )

  const originAddress = useMemo(() => {
    const district = getValueById(district_pid) || ''
    return {
      label: district,
      value: get(shopAddressMap, district) || getValueById(shop_address_pid) || ''
    }
  }, [district_pid, getValueById, shopAddressMap, shop_address_pid])

  const handleClick = async () => {
    const district = getValueById(district_pid)

    if (!originAddress.value) {
      notification.error({
        message: 'Fetch Directions',
        description: `Address not found for district ${district}`
      })
      return
    }

    const location = await (await locationQuery.refetch()).data

    if (location.gps_latitude && location.gps_longitude) {
      setDestinationAddress({
        label: ticket.location__name || '',
        value: `${location.gps_latitude},${location.gps_longitude}`
      })
    } else {
      notification.error({
        message: 'Fetch Directions',
        description: `GPS coordinates not found for location ${ticket.location__name}`
      })
      return
    }

    openModal()
  }

  return (
    <>
      <Button loading={locationQuery.isFetching} onClick={handleClick} {...buttonProps}>
        {buttonProps?.children ? buttonProps.children : property.name}
      </Button>
      {isOpen && (
        <DirectionsRouteModal
          open={isOpen}
          iconName={buttonProps?.iconName}
          onCancel={closeModal}
          originAddress={originAddress}
          destinationAddress={destinationAddress}
        />
      )}
    </>
  )
}
