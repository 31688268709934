import { z } from 'zod'

export const ZOffice = z.object({
  id: z.number().nullable(),
  name: z.string().nullable(),
  company: z.number().nullable()
})

export const ZContact = z.object({
  id: z.number().nullable() || z.string().nullable(),
  company__name: z.string().nullable(),
  created_at: z.string().nullable(),
  modified_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  title: z.string().nullable(),
  external_id: z.string().nullable(),
  phone_number: z.string().nullable(),
  email: z.string().nullable(),
  image: z.string().nullable(),
  oc_directory: z.boolean().nullable(),
  created_by: z.number().nullable(),
  modified_by: z.number().nullable(),
  deleted_by: z.number().nullable(),
  company: z.number().nullable(),
  primary_office: z.number().nullable(),
  private_company: z.number().nullable(),
  offices: z.array(z.number().nullable()).nullable()
})

export const ZUserData = z.object({
  active_lines_view: z.number().nullable(),
  notifications: z.any(),
  active_table_view: z.number().nullable(),
  ticket_status_limit: z.number().nullable(),
  active_loads_view: z.number().nullable(),
  disabled_forms: z.array(z.any()).nullable(),
  enabled_category_tags: z.array(z.number()).nullish(),
  enabled_groups: z.array(z.string().nullable()).nullish(),
  enabled_modules: z.array(z.number().nullable()).nullish(),
  tab_permissions: z.record(z.string(), z.boolean()).nullish(),
  auto_fills: z.array(
    z.object({
      custom_form_id: z.number(),
      fields: z.record(
        z.string(),
        z.object({
          id: z.number(),
          name: z.string(),
          user_afe_no: z.string().optional(),
          user_job_code_no: z.string().optional(),
          company__name: z.string().optional(),
          first_name: z.string().optional(),
          last_name: z.string().optional()
        })
      ),
      properties: z
        .array(
          z.object({
            id: z.number(),
            key: z.string().optional(),
            value: z.string()
          })
        )
        .optional()
    })
  ),
  preferences: z.record(z.string(), z.boolean()).nullable(),
  features: z.object({
    wiki: z
      .object({
        enabled: z.boolean(),
        wiki_user_id: z.number().nullish(),
        wiki_role_id: z.number().nullish()
      })
      .nullish()
  })
})

export const ZUser = z.object({
  pk: z.number().nullable(),
  username: z.string().nullable(),
  email: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  id: z.number().nullable(),
  groups: z.array(z.string().nullable()).nullable(),
  user_permissions: z.array(z.string().nullable()).nullable(),
  profile: z.number().nullable(),
  is_superuser: z.boolean().nullable(),
  is_active: z.boolean().nullable()
})

export const ZProfile = z
  .object({
    id: z.number().nullable(),
    userdata_json: ZUserData.nullable(),
    rules: z.array(z.any()).nullable(),
    office: ZOffice.nullable(),
    advance_settings: z.record(z.string(), z.boolean()).nullable(),
    contact: ZContact.nullable(),
    user: ZUser.nullable(),
    company__name: z.string().nullable(),
    company__is_default: z.boolean().nullable(),
    created_at: z.string().nullable(),
    modified_at: z.string().nullable(),
    deleted_at: z.number().nullable(),
    timezone: z.string().nullable(),
    rules_json: z.string().nullable(),
    advance_settings_json: z.string().nullable(),
    sisense_name: z.string().nullable(),
    sisense_id: z.string().nullable(),
    sisense_rule_id: z.number().nullable(),
    created_by: z.number().nullable(),
    modified_by: z.number().nullable(),
    deleted_by: z.number().nullable(),
    company: z.number().nullable(),
    role: z.number().nullable(),
    sync_notifications_from_role: z.boolean().nullable()
  })
  .passthrough()

export const roleSchema = z.object({
  name: z.string().nullable(),
  id: z.number()
})

export const zFormTypes = z.object({
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  title: z.string().nullable(),
  phone_number: z.string().nullable(),
  new_email: z.string().nullable(),
  username: z.string().nullable(),
  is_active: z.boolean(),
  timezone: z.string().nullable(),
  profile: z.string().nullable(),
  new_password: z.string().nullable(),
  userdata_json: z.object({
    email_ticket_include_attachments: z.boolean().nullable(),
    notifications: z.any().nullable()
  }),
  company_admin: z.any().nullable(),
  ticket_manager: z.any().nullable(),
  support: z.any().nullable(),
  ticket_signature: z.any().nullable(),
  time_manager: z.any().nullable(),
  my_time: z.any().nullable(),
  qb_input: z.any().nullable(),
  choices_manager: z.any().nullable(),
  module_builder: z.any().nullable(),
  ems_user: z.any().nullable(),
  email_vendor: z.any().nullable(),
  roles_manager: z.any().nullable(),
  role_id: z.any().nullable(),
  extra_json: z.object({
    module_changes: z.object({
      desc: z.string().nullable(),
      added: z.array(z.any()).nullable(),
      removed: z.array(z.any()).nullable()
    })
  })
})

export type ZForm = z.infer<typeof zFormTypes>
export type ZRole = z.infer<typeof roleSchema>
export type ZProfileTableData = z.infer<typeof ZProfile>
