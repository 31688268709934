import { JobResource } from '@/modules/jas/job-manager/types'
import { atomWithReset } from 'jotai/utils'

export const skillMatchTypeAtom = atomWithReset<string>('any')
export const selectedSkillsAtom = atomWithReset<string[]>([])
export const selectedJobRoleAtom = atomWithReset<number[]>([])
export const isAvailableOnlyAtom = atomWithReset<boolean>(false)
export const isResourceLoadedAtom = atomWithReset<boolean>(false)
export const originalResourcesAtom = atomWithReset<Record<number, JobResource>>({})
export const selectedResourcesAtom = atomWithReset<Record<number, JobResource>>({})
