import { SEARCH_DEBOUNCE_TIME } from '@/constants'
import { Button, QuerySelect, SearchInput, Select, UrlStateFormItem, UrlStateFormItemProps } from '@/ui'
import {
  LocalDateRangePicker,
  LocalDateRangePickerProps,
  LocalDateTimePicker,
  LocalDateTimePickerProps
} from '@/ui/date'
import { getDateRange } from '@/utils'
import { FormItemProps, InputNumber } from 'antd'
import cn from 'classnames'
import { useMemo } from 'react'
import { QueryField, QueryFilterService } from './types'

export type FilterFormItemProps = (FormItemProps | UrlStateFormItemProps) & {
  service: QueryFilterService
  field: QueryField
  hideRemove?: boolean
  hideLabel?: boolean
}

export function FilterFormItem({ field, hideLabel, hideRemove, service, ...props }: FilterFormItemProps) {
  const filterInput = useMemo(
    () =>
      (field.inputType === 'render' ? field.inputRender : INPUT_RENDER[field.inputType] || INPUT_RENDER.search)({
        ...field.inputProps,
        autoFocus: true,
        placeholder: 'All',
        className: cn('w-full', field.inputProps?.className)
      }),
    [field]
  )

  const formItemDebounce = useMemo(
    () => (['date', 'date-range', 'select'].includes(field.inputType) ? 0 : SEARCH_DEBOUNCE_TIME),
    [field.inputType]
  )

  return (
    <div className={'relative'}>
      {!hideLabel && (
        <div className={cn('absolute top-[-12px] left-6 z-10')}>
          <span className={'px-8 text-sm cursor-pointer bg-background border rounded-lg leading-4'}>{field.label}</span>
        </div>
      )}
      <UrlStateFormItem name={['filter', field.key]} debounce={formItemDebounce} {...props} {...field.formItemProps}>
        {filterInput}
      </UrlStateFormItem>
      {!hideRemove && (
        <div className={'absolute top-[-12px] right-[-4px] z-10'}>
          <Button
            shape={'circle'}
            size={'small'}
            iconName={'fa:close'}
            className={'scale-75'}
            onClick={() => service.removeFilterField(field.key)}
          />
        </div>
      )}
    </div>
  )
}

type DatePickerProps = Omit<LocalDateTimePickerProps, 'onChange'> & { onChange: (dateStr: string | null) => void }

function DatePicker({ onChange, ...props }: DatePickerProps) {
  return (
    <LocalDateTimePicker
      showTime={false}
      {...props}
      onChange={(date) => {
        // todo: check timezone logic for filter
        const value = date?.formatISODate(false) || null
        return onChange?.(value)
      }}
    />
  )
}

type DateRangePickerProps = Omit<LocalDateRangePickerProps, 'onChange'> & {
  onChange: (datesStr: string | null) => void
}

function DateRangePicker({ onChange, ...props }: DateRangePickerProps) {
  return (
    <LocalDateRangePicker
      showTime={false}
      presets={getDateRange()}
      {...props}
      onChange={(dates) => {
        if (!dates) onChange?.(null)
        else {
          // todo: check timezone logic for filter
          const startStr = dates[0]?.formatISODate(false)
          const endStr = dates[1]?.formatISODate(false)
          return onChange?.(`${startStr},${endStr}`)
        }
      }}
    />
  )
}

const INPUT_RENDER = {
  search: (props: any) => <SearchInput debounce={0} {...props} />,
  text: (props: any) => <SearchInput debounce={0} block {...props} />,
  number: (props: any) => <InputNumber {...props} />,
  'number-range': (props: any) => <InputNumber {...props} />,
  date: (props: any) => <DatePicker {...props} />,
  'date-range': (props: any) => <DateRangePicker {...props} />,
  select: (props: any) => <Select {...props} />,
  'query-select': (props: any) => <QuerySelect {...props} />
}
