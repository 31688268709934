import { get } from 'lodash'
import { useContext } from 'react'
import { SaveTicketOptions, useCustomForm, useTicketFormValues, useTicketObject, useTicketSave } from '../../../hooks'
import { ScriptedButtonContext } from './context'

export const useButtonContext = <TVars = Record<any, any>>() => {
  const { property, buttonProps } = useContext(ScriptedButtonContext)

  const { ticketObject: ticket } = useTicketObject()
  const { getPropertyById, getPropertyByKey } = useCustomForm()
  const { isSaving, saveTicket: _saveTicket } = useTicketSave()
  const {
    ticketForm: { setFieldValue },
    applyChanges,
    getPropertyValueById: getValueById,
    getPropertyValueByKey: getValueByKey
  } = useTicketFormValues()

  const getValue = (key: any) => {
    return get(ticket, key) ?? getValueByKey(key) ?? getValueById(key)
  }

  const setValue = (key: any, value: any) => {
    const prop = getPropertyByKey(key) || getPropertyById(key)
    setFieldValue(prop ? `p_${prop.id}` : key, value)
    applyChanges()
  }

  const saveTicket = async (payload: Record<string, any> = {}, options: SaveTicketOptions = {}) => {
    if (ticket && ticket.id) return _saveTicket({ override: payload, ...options })
  }

  return {
    property,
    buttonProps,
    iconName: buttonProps?.iconName,
    vars: (property?._buttonConfig?.vars || {}) as TVars, // todo: apply lodash template interpolation?
    ticket,
    getValue,
    setValue,
    getValueById,
    getValueByKey,
    saveTicket,
    isSaving
  }
}
