import { APP_RELEASE, LOG_ROCKET_APP_ID } from '@/constants/app'
import { formatSessionUser } from '@/services/monitoring/helpers'
import { AuthUser } from '@/types/auth-user'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

export const logRocket = {
  appID: LOG_ROCKET_APP_ID,
  appRelease: APP_RELEASE,
  currentID: null as number | null,
  enabled() {
    return Boolean(this.appID)
  },
  init() {
    if (!this.enabled()) return false // disabled

    try {
      LogRocket.init(this.appID, {
        release: this.appRelease,
        mergeIframes: true,
        console: {
          isEnabled: {
            log: false,
            debug: false
          }
        },
        browser: {
          urlSanitizer: (url) => {
            return url
              .replace(/secret_key=([^&]*)/, 'secret_key=***')
              .replace(/signature=([^&]*)/, 'signature=***')
              .replace(/token=([^&]*)/, 'token=***')
          }
        },
        network: {
          requestSanitizer: (request) => {
            if (request.headers['Authorization']) {
              request.headers['Authorization'] = 'JWT ***'
            }

            return request
          }
        }
      })

      // for React
      setupLogRocketReact(LogRocket)

      return true
    } catch (err) {
      console.error('LogRocket: Failed to initialize', err)
      return false
    }
  },
  identify(user: AuthUser) {
    if (!this.enabled() || this.currentID == user.id) return false // disabled or already identified

    try {
      LogRocket.identify(user.id.toString(), formatSessionUser(user))

      this.currentID = user.id
      return true
    } catch (err) {
      console.error('LogRocket: Failed to identify user', err)
      return false
    }
  }
}
