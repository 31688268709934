import { Button } from '@/ui/button'
import { useAtomValue, useSetAtom } from 'jotai'
import { isMobileMenuOpenAtom, mobileHeaderElementAtom } from '../atoms'
import { VersionInfo } from './VersionInfo'

export const HeaderMobile = () => {
  const setIsMenuOpen = useSetAtom(isMobileMenuOpenAtom)
  const mobileHeaderElement = useAtomValue(mobileHeaderElementAtom)

  return (
    <div className="pt-6 px-6">
      <div className="bg-white flex items-center p-8 rounded gap-x-8">
        <Button iconName="fa:bars" onClick={() => setIsMenuOpen(true)} />
        {mobileHeaderElement}
      </div>
      <VersionInfo />
    </div>
  )
}
