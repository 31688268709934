import { useCustomFormLabels, useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { DisplayField } from '@/modules/table-view/helpers'
import { TICKET_COLUMNS_MAP } from '@/modules/ticket/columns'
import { TableView } from '@/types/table-view'
import { uniq } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useActiveTableView } from './use-active-table-view'
import { useTicketModule } from './use-ticket-module'

type Params = {
  selectedView?: TableView
  defaultFields?: string[]
}

export const useTicketFields = (params?: Params) => {
  const { module, moduleFormId } = useTicketModule()
  const { l } = useCustomFormLabels(moduleFormId || null)
  const [selectedView, setSelectedView] = useState(params?.selectedView)
  const { activeView } = useActiveTableView()

  const { formsStatusMap, isFetched: statusFetched } = useCustomFormsStatus()

  const [displayFields, paramFields] = useMemo(() => {
    if (!statusFetched) {
      return [[], []]
    }

    const dfCtx = { module, statuses: formsStatusMap }

    let _displayFields: DisplayField[] = []
    if (activeView || selectedView) {
      // selected fields (active table view)
      const selectedFields = (selectedView ? selectedView.selected_fields : activeView?.selected_fields) || []
      _displayFields = selectedFields.map((f: any) => new DisplayField(f, l, dfCtx)).filter((f) => f.key !== 'name')
    } else {
      // default fields
      _displayFields = [
        new DisplayField(TICKET_COLUMNS_MAP['computed_status'], l, dfCtx),
        new DisplayField(TICKET_COLUMNS_MAP['customer_office__name'], l, dfCtx),
        new DisplayField(TICKET_COLUMNS_MAP['location__name'], l, dfCtx),
        new DisplayField(TICKET_COLUMNS_MAP['timepoint_due'], l, dfCtx),
        new DisplayField(TICKET_COLUMNS_MAP['modified_at'], l, dfCtx)
      ]
    }

    // always include name field if not included
    const nameField = new DisplayField(TICKET_COLUMNS_MAP['name'], l, dfCtx)
    nameField.fixed = 'left'
    nameField.width = 100
    _displayFields = [nameField].concat(_displayFields)

    // include default fields
    const defaultFields = (params?.defaultFields || [])
      .filter((f) => !_displayFields.map((df) => df.key).includes(f))
      .map((f) => new DisplayField(TICKET_COLUMNS_MAP[f], l, dfCtx))
    _displayFields = [..._displayFields, ...defaultFields]

    // query params for selected fields
    // always include id field
    const _paramFields: string[] = uniq(
      ['id', 'alerts__alert__name', 'alerts__alert__alert_type', 'custom_form_id'].concat(
        _displayFields
          .map((f) => f.queryParams)
          .flat()
          .filter((f) => f)
      )
    )

    return [_displayFields, _paramFields]
  }, [statusFetched, module, formsStatusMap, activeView, selectedView, l, params?.defaultFields])

  useEffect(() => {
    setSelectedView(params?.selectedView)
  }, [params?.selectedView])

  return {
    displayFields: displayFields,
    paramFields: paramFields
  }
}
