import { CopyButton } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Tooltip } from '@/ui/tooltip'
import { cn, findParentWithClass, makeMap } from '@/utils'
import { SelectProps as AntSelectProps, Select as AntdSelect } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FC, useCallback, useMemo, useState } from 'react'
import { getFilterBy, getOptionValue } from './helpers'

export type OptionType = DefaultOptionType

export type SelectProps = Omit<AntSelectProps, 'onSelect'> & {
  showCopyText?: boolean
  filterBy?: string
  clearValue?: any
  isOptionAvailable?: boolean
}

/**
 * Extends Antd Select component with default functionality.
 * - showSearch: true
 * - allowClear: true
 * - clearValue: null (custom feature)
 * - filterOption: search by label
 * - maxTagCount: responsive
 */
export const Select: FC<SelectProps> = ({
  showSearch,
  showCopyText,
  allowClear,
  clearValue,
  filterOption,
  filterBy,
  maxTagCount,
  onChange,
  labelRender,
  rootClassName,
  isOptionAvailable: isOptionAvailableOverride,
  ...rest
}) => {
  showCopyText = showCopyText ?? true // default: show copy text
  showSearch = showSearch ?? true // default: show search
  allowClear = allowClear ?? true // default: allow clear
  clearValue = clearValue ?? null // default: clear value
  filterOption = filterOption ?? getFilterBy(filterBy) // default: search by label
  maxTagCount = maxTagCount ?? 'responsive' // default: max tag count

  const handleChange = useCallback(
    (value: any, option: any) => {
      onChange?.(value === undefined ? clearValue : value, option)
    },
    [clearValue, onChange]
  )

  const optionsMap = useMemo(
    () => makeMap((rest.options || []).map((option) => option.options ?? [option]).flat(), 'value'),
    [rest.options]
  )

  const isOptionAvailable = useCallback(
    (optionValue: SelectProps['value']) => {
      if (isOptionAvailableOverride !== undefined) return isOptionAvailableOverride

      const value = getOptionValue(optionValue)
      if (!value) return true

      return optionsMap[value] !== undefined
    },
    [isOptionAvailableOverride, optionsMap]
  )

  const missingLabelRender: SelectProps['labelRender'] | undefined = (option) => {
    if (!isOptionAvailable(option)) {
      return (
        <div className={'flex items-center gap-4'}>
          <Tooltip title={`No option found for selected value '${option.value}'`} rootClassName={'pointer-events-none'}>
            <Icon name={'fa:warning'} className={'cursor-pointer text-sm text-warning opacity-50 ml-1'} />
          </Tooltip>
          {labelRender ? labelRender(option) : option.label || option.value}
        </div>
      )
    }

    return labelRender?.(option) || option.label || option.value
  }

  const selectElement = (
    <AntdSelect
      rootClassName={cn('[&_.ant-select-selection-item_.help-btn]:hidden', rootClassName)}
      showSearch={showSearch}
      allowClear={allowClear}
      filterOption={filterOption}
      maxTagCount={maxTagCount}
      onChange={handleChange}
      labelRender={missingLabelRender}
      {...rest}
    />
  )

  if (!showCopyText) return selectElement

  return (
    <div className={'group select-with-copy relative grow flex flex-row items-center'}>
      {selectElement}

      <div
        className={cn(
          'absolute right-24 transition-opacity duration-200 ease-in-out opacity-0 group-hover:opacity-100'
        )}
      >
        <CopyTextButton />
      </div>
    </div>
  )
}

const CopyTextButton = () => {
  const [copyText, setCopyText] = useState<string>('')

  const findCopyText = useCallback((e: any) => {
    const valuesEl = findParentWithClass(e.currentTarget, 'select-with-copy')?.querySelectorAll(
      '.ant-select-selection-item'
    )

    const searchText = Array.from(valuesEl || [])
      .map((el) => el.textContent)
      .join(', ')

    setCopyText((searchText || '').trim())
  }, [])

  return (
    <CopyButton
      tabIndex={-1}
      onMouseEnter={findCopyText}
      shape={'circle'}
      size={'small'}
      type={'text'}
      copyText={copyText}
      className={'!text-sm !text-gray-300'}
    />
  )
}
