import { redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { NetSuiteLayout } from './layouts'
import { NetSuiteConfigureFormView, NetSuiteInvoiceListView } from './views'

export const routes: RouteObject[] = [
  route('m/:moduleId/ns/:moduleSlug', NetSuiteLayout, [
    route('index', redirect('configs')),
    route('configs', NetSuiteConfigureFormView),
    route('invoices', NetSuiteInvoiceListView)
  ])
]
