export const HTTP_STATUS = {
  400: {
    name: 'Bad Request',
    message: 'The request could not be processed due to incorrect syntax.'
  },
  401: {
    name: 'Unauthorized',
    message: 'You need to log in to view this content.'
  },
  402: {
    name: 'Payment Required',
    message: 'Payment is required to access this content.'
  },
  403: {
    name: 'Forbidden',
    message: 'You do not have permission to access this resource.'
  },
  404: {
    name: 'Not Found',
    message: 'The requested resource was not found.'
  },
  405: {
    name: 'Method Not Allowed',
    message: 'This method is not allowed. Please check the URL or contact support.'
  },
  406: {
    name: 'Not Acceptable',
    message: 'Cannot generate requested content. Adjust your request and try again.'
  },
  407: {
    name: 'Proxy Authentication Required',
    message: 'You need to authenticate with your proxy to access this content.'
  },
  408: {
    name: 'Request Timeout',
    message: 'The request timed out. Please try again later.'
  },
  409: {
    name: 'Conflict',
    message: 'A conflict occurred. Please refresh or try again later.'
  },
  410: {
    name: 'Gone',
    message: 'The resource is no longer available.'
  },
  411: {
    name: 'Length Required',
    message: 'Content length must be defined in the request.'
  },
  412: {
    name: 'Precondition Failed',
    message: 'Required conditions were not met. Please verify and try again.'
  },
  413: {
    name: 'Payload Too Large',
    message: 'The request is too large to process.'
  },
  414: {
    name: 'URI Too Long',
    message: 'The URL is too long. Please shorten it and try again.'
  },
  415: {
    name: 'Unsupported Media Type',
    message: 'The media type is not supported.'
  },
  416: {
    name: 'Range Not Satisfiable',
    message: 'Cannot serve the requested range.'
  },
  417: {
    name: 'Expectation Failed',
    message: 'Expectations in the request cannot be met.'
  },
  418: {
    name: 'I am a teapot',
    message: 'I am a teapot, not a coffee machine.'
  },
  421: {
    name: 'Misdirected Request',
    message: 'The request was misdirected. Please try again.'
  },
  422: {
    name: 'Unprocessable Entity',
    message: 'The request was well-formed but could not be followed due to semantic errors.'
  },
  423: {
    name: 'Locked',
    message: 'The resource is currently locked.'
  },
  424: {
    name: 'Failed Dependency',
    message: 'The request failed due to a previous failure.'
  },
  425: {
    name: 'Too Early',
    message: 'The request was sent too early and cannot be processed at this time.'
  },
  426: {
    name: 'Upgrade Required',
    message: 'Please upgrade your browser or app to access this content.'
  },
  428: {
    name: 'Precondition Required',
    message: 'Certain conditions must be met to access this content.'
  },
  429: {
    name: 'Too Many Requests',
    message: 'Too many requests. Please slow down.'
  },
  431: {
    name: 'Request Header Fields Too Large',
    message: 'Header fields are too large. Please adjust and retry.'
  },
  451: {
    name: 'Unavailable For Legal Reasons',
    message: 'This content is unavailable for legal reasons.'
  },
  500: {
    name: 'Internal Error',
    message: 'An error occurred. We are working to fix it.'
  },
  501: {
    name: 'Not Implemented',
    message: 'The request method is not supported.'
  },
  502: {
    name: 'Bad Gateway',
    message: 'Received an invalid response from the upstream server.'
  },
  503: {
    name: 'Service Unavailable',
    message: 'Our service is temporarily unavailable. Please try again later.'
  },
  504: {
    name: 'Gateway Timeout',
    message: 'Timed out waiting for a response from our servers.'
  },
  505: {
    name: 'HTTP Version Not Supported',
    message: 'The HTTP version used is not supported.'
  },
  506: {
    name: 'Variant Also Negotiates',
    message: 'There is a circular negotiation request error.'
  },
  507: {
    name: 'Insufficient Storage',
    message: 'The server is unable to store the representation needed to complete the request.'
  },
  508: {
    name: 'Loop Detected',
    message: 'A loop has been detected while processing your request.'
  },
  510: {
    name: 'Not Extended',
    message: 'Further extensions to the request are required for it to be fulfilled.'
  },
  511: {
    name: 'Network Authentication Required',
    message: 'Network authentication is required to access this content.'
  }
}

export type HttpStatusCode = keyof typeof HTTP_STATUS

export const getHttpStatus = (code: HttpStatusCode) => {
  const status = HTTP_STATUS[code] || { name: 'Unknown Error', message: 'An unknown error occurred.' }
  return {
    ...status,
    title: `${code} ${status.name}`
  }
}

export const getHttpStatusTitle = (code: HttpStatusCode) => getHttpStatus(code).title
export const getHttpStatusName = (code: HttpStatusCode) => getHttpStatus(code).name
export const getHttpStatusMessage = (code: HttpStatusCode) => getHttpStatus(code).message
