import { ScheduleLabels } from '@/components/schedule-labels'
import { SCHEDULE_TYPES, ScheduleType } from '@/utils'
import { LimitSelect } from './limit-select'
import { LoadMoreButton } from './load-more-button'

type Props = {
  onLimitChange?: (value: number) => void
  onLoadMore: () => void
  scheduleLabels?: (typeof SCHEDULE_TYPES)[ScheduleType][]
}

export const TableFooter = ({ onLimitChange, onLoadMore, scheduleLabels }: Props) => {
  return (
    <div className="absolute -bottom-16 left-0 w-full h-[70px] py-12 px-12 flex items-center justify-between">
      <LimitSelect onChange={onLimitChange} />
      <ScheduleLabels labels={scheduleLabels} />
      <LoadMoreButton onClick={onLoadMore} />
    </div>
  )
}
