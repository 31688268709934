import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Space } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { LabelValue } from './label-value'
import { getTotalJobDays } from './utils'

type Props = {
  rule: JasResourceUseQueryResponse['rules'][0]
  index: number
  onEditClick: () => void
  onDeleteClick: () => void
}

export const ListItem = ({ rule, index, onDeleteClick, onEditClick }: Props) => {
  return (
    <div
      className={classNames(
        'flex pl-20 pr-10 py-12 items-center justify-between border-0 border-b border-solid border-gray-300'
      )}
      key={rule.id}
    >
      <div>{index + 1}</div>
      <div className="flex gap-x-42 ml-50">
        <div className="w-[200px]">
          <LabelValue
            icon={<Icon name="mi:calendar_today" />}
            label="Start Date"
            value={dayjs(rule.start_date).format('MMMM D, YYYY')}
          />
        </div>
        <div className="w-[200px]">
          <LabelValue
            icon={<Icon name="mi:calendar_today" />}
            label="End Date"
            value={dayjs(rule.end_date).format('MMMM D, YYYY')}
          />
        </div>
        <div className="w-[200px]">
          <LabelValue label="Schedule" value={rule.name} />
        </div>
        <div className="w-[200px]">
          <LabelValue label="Total job days" value={getTotalJobDays(rule)} />
        </div>
      </div>
      <Space size="middle">
        <Button shape="circle" icon={<Icon name="mi:edit" />} type="success" onClick={onEditClick} />
        <Button shape="circle" icon={<Icon name="mi:delete" />} onClick={onDeleteClick} />
      </Space>
    </div>
  )
}
