import { Checkbox, Form, Input, Modal, Select } from 'antd'
import { useState } from 'react'
import { BPA_SCHEDULE_FILTER_FIELD, BPA_SCHEDULE_FILTER_VALUE } from '../../constants'
import { BpaScheduleFilterCriteria } from '../../schemas'

type ScheduleFilterProps = {
  isOpen: boolean
  onClose: (formData: BpaScheduleFilterCriteria, save: boolean) => void
  value: BpaScheduleFilterCriteria
}

const SCHEDULE_FILTER_VALUES_WITH_VALUE2 = ['past-days', 'past-months', 'past-hours', 'past-minutes']

export const ScheduleFilterCriteriaModal = ({ value, isOpen, onClose }: ScheduleFilterProps) => {
  const [formData, setFormData] = useState<BpaScheduleFilterCriteria>(value)
  const setFieldValue = (name: string, value: string | number | boolean) =>
    setFormData({
      ...formData,
      [name]: value
    })

  return (
    <Modal
      title="Edit Filter Criteria"
      width={600}
      open={isOpen}
      onCancel={() => onClose(formData, false)}
      onOk={() => onClose(formData, true)}
    >
      <Form layout="vertical">
        <Form.Item label="Filter By">
          <Select
            onChange={(value: string) => setFieldValue('field', value)}
            options={BPA_SCHEDULE_FILTER_FIELD}
            value={formData.field}
          />
        </Form.Item>
        <div>
          <Form.Item label="Value">
            <Select
              value={formData.value1}
              onChange={(value: string) => setFieldValue('value1', value)}
              options={BPA_SCHEDULE_FILTER_VALUE}
            />
          </Form.Item>
        </div>
        {SCHEDULE_FILTER_VALUES_WITH_VALUE2.includes(formData.value1) && (
          <div className="flex gap-x-24 items-center">
            <Form.Item
              label={BPA_SCHEDULE_FILTER_VALUE.find(({ value }) => value === formData.value1)?.label || ''}
              className="grow"
            >
              <Input
                type="number"
                defaultValue={formData.value2}
                onChange={(e) => setFieldValue('value2', e.target.value)}
              />
            </Form.Item>
          </div>
        )}
        <Form.Item label="Not">
          <Checkbox checked={formData.exclude} onChange={(e) => setFieldValue('exclude', e.target.checked)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
