const timezoneRegex = /Z|([+-])(\d{2}):?(\d{2})$/

/**
 * Check if the value has a timezone.
 * It can be either Z, +HH:mm, or -HH:mm.
 * @param value - value to check
 */
export const hasTimezone = (value: string) => {
  return timezoneRegex.test(value)
}
