import { Select } from '@/ui'
import { Form } from 'antd'
import { useEffect, useMemo } from 'react'
import { usePropertiesQuery } from '../../hooks'

export const LinkedPropertySelect = () => {
  const form = Form.useFormInstance()
  const showLinkProperty = Form.useWatch('showLinkProperty')
  const { propertiesQuery, properties } = usePropertiesQuery()
  const selectedPropertyId = Form.useWatch('id')
  const linkedPropertyId = Form.useWatch('link')

  const options = useMemo(
    () =>
      properties
        .filter((p) => p.id != selectedPropertyId && !p.link)
        .map((property) => ({
          label: (
            <div className="flex justify-start pr-8">
              <div>
                {`${property.property_group__custom_form__name} / ${property.property_group__name} / `}
                <span className="font-semibold mr-8">{property.name}</span>
              </div>
            </div>
          ),
          value: property.id
        })) ?? [],
    [properties, selectedPropertyId]
  )

  useEffect(() => {
    if (linkedPropertyId && !options.find((o) => o.value === linkedPropertyId)) {
      form.setFieldsValue({
        link: null
      })
    }
  }, [form, linkedPropertyId, options])

  if (!showLinkProperty) {
    return null
  }

  return (
    <Form.Item label="Linked" name="link">
      <Select
        className="min-w-[200px]"
        placeholder="Select"
        popupMatchSelectWidth={false}
        options={[
          {
            label: 'None',
            value: null
          },
          ...options
        ]}
        loading={propertiesQuery.isLoading}
        disabled={propertiesQuery.isLoading}
      />
    </Form.Item>
  )
}
