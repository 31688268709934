import { ENABLE_REDIRECT } from '@/constants/app'
import { ErrorView } from '@/layouts/views'
import { route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { AuthLayout } from './layouts'
import { ForgotPasswordView, LoginView, LogoutView, OpenIdRedirectView, ResetPasswordView } from './views'

export const routes: RouteObject[] = [
  route('auth', AuthLayout, [
    route('login', LoginView),
    route('logout', LogoutView),
    route('forgot-password', ForgotPasswordView),
    route('forgot/:uid/:token', ResetPasswordView),
    route('openid/redirect/web', OpenIdRedirectView),
    route('*', ErrorView, undefined, { code: 404, redirect: ENABLE_REDIRECT })
  ])
]
