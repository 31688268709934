import { LINE_ITEM_FIELDS } from '@/constants/general'

type LineItemFieldName = (typeof LINE_ITEM_FIELDS)[number]['field']

type Params = {
  fieldAliases: Record<LineItemFieldName, string>
  field: LineItemFieldName | string
  labels: any
}

// TODO: Find better name for this function
// TODO: Replace: modules\records\configurations\configuration-view\drag-and-drop-fields\hooks\use-line-item-field-name.ts
// to use this function
export const getLineItemName = ({ field, fieldAliases, labels }: Params) => {
  let isAlias = false
  let name: string = field
  let originalName: string = field

  const label = labels[`lineitem__${field}`]
  const fieldAlias = fieldAliases[field as LineItemFieldName]

  if (fieldAlias || Object.keys(fieldAliases).includes(field)) {
    isAlias = true
    name = fieldAlias
    originalName = label
  } else if (label) {
    name = label
    originalName = label
  }

  return {
    isAlias,
    name,
    originalName
  }
}
