import { Form } from 'antd'
import { FC, ReactElement } from 'react'
import { TicketForm } from '../../../components'
import { TicketFormProvider } from '../../../context'
import { CustomForm, Ticket } from '../../../schemas'

type Props = {
  apiCustomForm: CustomForm
  apiTicket: Ticket
  children?: ReactElement
}

export const FormLoader: FC<Props> = ({ apiCustomForm, apiTicket, children }) => {
  /**
   * This component is responsible for loading processed custom form and ticket to atom.
   * Why? In Summary: To prevent unnecessary re-renders. I mean 100s of re-renders.
   *  - Ticket and custom form are being referenced in many child components.
   *  - Also, we need to transform custom form and ticket before using them.
   *  - Just using query data adds unnecessary re-renders related to fetch status and transform run every time.
   *  - So, we load them to atom and only load if the response query data is actually changed.
   */
  // load ticket form instance
  const [form] = Form.useForm()

  return (
    <TicketFormProvider
      ticketForm={form}
      ticketId={apiTicket.id}
      customFormId={apiCustomForm.id}
      customForm={apiCustomForm}
      ticket={apiTicket}
    >
      <TicketForm>{children}</TicketForm>
    </TicketFormProvider>
  )
}
