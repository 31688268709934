import { RuleObject } from 'antd/es/form'

const latPattern = /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?))$/ //Regex for latitude
const lonPattern = /^([-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/ // Regex for longitude

export const validatedLat = (rule: RuleObject, value: string | undefined) => {
  if (value && !latPattern.test(value)) {
    return Promise.reject('Invalid Latitude')
  }
  return Promise.resolve()
}

export const validatedLng = (rule: RuleObject, value: string | undefined) => {
  if (value && !lonPattern.test(value)) {
    return Promise.reject('Invalid Longitude')
  }
  return Promise.resolve()
}
