import { TabsView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { moduleApi } from '@/services/api-service'
import { z } from 'zod'
import { RECORD_TYPE_MAP } from './records'
import { RESOURCE_TYPE_MAP } from './resources'

const ModuleSchema = z.object({ id: z.number(), title: z.string() })
type Module = z.infer<typeof ModuleSchema>

export const ResourceManagerLayout = view<any, Module>(Component, {
  title: ({ data }) => data?.title || 'Resource Manager',
  loader: async ({ params }) => {
    return fetchQuery(moduleApi.get(Number(params.moduleId), undefined, { fields: ModuleSchema }))
  }
})

function Component() {
  return (
    <TabsView
      asLayout
      header={{ breadcrumbs: true }}
      items={[
        ...Object.entries(RESOURCE_TYPE_MAP).map(([key, value]) => ({
          to: `resources/${key}`,
          label: value.label
        })),
        ...Object.entries(RECORD_TYPE_MAP).map(([key, value]) => ({
          to: `records/${key}`,
          label: value.label
        })),
        { to: 'rules', label: 'Schedule Rules' }
      ]}
    />
  )
}
