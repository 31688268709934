import { useApp } from '@/hooks'
import { Button, Icon } from '@/ui'
import { FC, useEffect } from 'react'

type Props = unknown

export const DebugTools: FC<Props> = () => {
  const { modal } = useApp()
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === 'd') {
        event.preventDefault()

        modal.info({
          icon: null,
          maskClosable: true,
          okText: 'Close',
          centered: true,
          title: (
            <div className={'flex flex-row items-center gap-10'}>
              <Icon name="fa:tools" />
              <span>Debug Tools</span>
            </div>
          ),
          className: '!w-[50%]',
          content: <DebugToolsContent />
        })
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [modal])

  return null
}

const DebugToolsContent = () => {
  const reactScanEnabled = localStorage.ocReactScan === '1'

  return (
    <div className={'flex flex-row flex-wrap p-10 gap-10'}>
      <Button
        onClick={() => {
          throw new Error('This is a test error!')
        }}
      >
        Sentry Test Error
      </Button>

      <Button
        onClick={() => {
          localStorage.ocReactScan = reactScanEnabled ? '0' : '1'
          window.location.reload()
        }}
      >
        {reactScanEnabled ? 'Disable' : 'Enable'} React Scan
      </Button>
    </div>
  )
}
