import { z } from 'zod'

// schemas
export const JobCodeSchema = z.object({
  id: z.number(),
  user_job_code_no: z.string()
})

// types
export type JobCode = z.infer<typeof JobCodeSchema>
