import { API_URL, APP_BUILD, APP_VERSION } from '@/constants/app'
import { refreshQueries } from '@/query'
import { getAccessToken } from '@/services/auth-service'
import { ApiResource, BlobResponse, ListParams, ListResponse, RequestConfig } from '@/types/api/core'
import { csv, safeParseInt } from '@/utils'
import { parseFileName } from '@/utils/blob'
import { zodQueryFields } from '@/utils/zod'
import axios, { AxiosResponse, Method } from 'axios'
import { get } from 'lodash'
import { z } from 'zod'

/**
 * Default list limit is used when no limit is specified in the list params.
 */
export const LIST_LIMIT = 20

/**
 * Default list params.
 */
export const LIST_PARAMS = {
  limit: LIST_LIMIT,
  page: 1
}

/**
 * Axios HTTP client for making request to external APIs.
 * Just a syntax sugar for axios.
 */
export const http = axios

/**
 * API HTTP client is a wrapper around axios to add some default.
 */
export const apiHttp = axios.create({ baseURL: API_URL })
apiHttp.interceptors.request.use((config) => {
  config.headers = config.headers || {}

  // application info
  config.headers['X-Application'] = `Oil Command Web; ${APP_VERSION}-${APP_BUILD}`

  // never use cache for API data
  config.headers['Cache-Control'] = 'no-store, no-cache, must-revalidate, max-age=0'

  // override browser default accept language to en-US
  config.headers['Accept-Language'] = 'en-US'

  // access token
  const accessToken = getAccessToken()
  if (accessToken) config.headers.Authorization = accessToken

  return { ...config }
})
apiHttp.interceptors.response.use(
  (response) => response,
  (error) => {
    // change error name from 'AxiosError' to 'API Error'
    error.name = 'API Error'
    return Promise.reject(error)
  }
)

/**
 * Processes params for the request.
 *
 * @param params
 * @param isList
 */
export const getParams = (params: ListParams | undefined, isList = false) => {
  if (isList) params = { ...LIST_PARAMS, ...params } // add default list params

  if (!params) return {} // no params to process

  if (!params.fields) return params // no fields to process

  // clone params to avoid mutation
  params = { ...params }

  // process zod fields
  if (params.fields instanceof z.ZodObject) params.fields = zodQueryFields(params.fields)

  // convert fields array to csv
  if (Array.isArray(params.fields)) params.fields = csv(params.fields)

  return params
}

/**
 * Gets the payload id from the payload object.
 * Supports Object, Map, and FormData.
 * @param payload
 */
export const getPayloadId = (payload: any) => {
  if (Number.isInteger(Number(payload))) return payload

  if (payload instanceof FormData) return payload.get('id')

  try {
    return get(payload, 'id')
  } catch (e) {
    console.error('Invalid Payload ID', e)
  }
}

/**
 * Extracts the object data from the response.
 * @param response
 */
export const getObjData = (response: AxiosResponse) => response.data

/**
 * Extracts the data from the response.
 * @param response
 */
export const getListData = (response: AxiosResponse): ListResponse => ({
  items: response.data,
  total: safeParseInt(response.headers['total-items'], 0)
})

/**
 * Extracts the blob data from the response.
 * @param response
 */
export const getBlobData = (response: AxiosResponse): BlobResponse => ({
  blob: response.data,
  fileName: parseFileName(response.headers['content-disposition'])
})

/**
 * Transforms the data from the response. This is used to transform the data before it is passed to the schema.
 * @param config
 * @param options
 */
export const request = async (
  config: RequestConfig,
  options: { isList?: boolean; isBlob?: boolean; isRaw?: boolean } = {}
) => {
  const response = await apiHttp.request(config)

  let data
  if (options.isBlob) {
    data = getBlobData(response)
  } else if (options.isList) {
    data = getListData(response)
  } else if (options.isRaw) {
    data = response
  } else {
    data = getObjData(response)
  }

  // data.headers = toPlainObject(response.headers)

  return data
}

/**
 * A shortcut helper function to make an API request.
 * Alias for `request` function.
 *
 * @param method
 * @param url
 * @param config
 * @param options
 * @constructor
 */
export const R = <D = any>(
  method: Method,
  url: string,
  config?: RequestConfig,
  options: { isList?: boolean; isBlob?: boolean; isRaw?: boolean } = {}
) => {
  return request({ method, url, ...(config || {}) }, options)
}

/**
 * Creates an API endpoint config object that can be used with react-query.
 * @param path
 * @param customKey
 * @param otherKeys
 */
export const createResourceApi = (path: string, customKey: string[] = [], otherKeys?: string[]): ApiResource => {
  const key = customKey.length ? customKey : [path]
  const url = `/${path}/`

  const invalidateKeys = [...[key[0]], ...(otherKeys || [])]

  return {
    meta: { key, url, invalidateKeys },

    /**
     * It will fetch a single record using GET request.
     * @param id
     * @param config
     * @param params
     * @param urlAppend
     */
    get: (id: number | null | undefined, config?: RequestConfig, params?: { [key: string]: any }, urlAppend?) => ({
      enabled: !!id,
      queryKey: [...key, 'get', id, getParams(params)],
      queryFn: ({ signal }) =>
        request({
          ...config,
          method: 'GET',
          url: `${url}${id}/${urlAppend ?? ''}`,
          params: getParams(params),
          signal: signal
        })
    }),

    /**
     * It will fetch a list of paginated records using GET request.
     * @param params
     * @param config
     */
    list: (params?: ListParams, config?: RequestConfig) => ({
      queryKey: [...key, 'list', getParams(params, true)],
      queryFn: ({ signal }) =>
        request(
          { ...config, method: 'GET', url: url, params: getParams(params, true), signal: signal },
          { isList: true }
        ),
      keepPreviousData: true
    }),

    /**
     * It will fetch a list of paginated events using GET request.
     * Important! Only supported for V2 endpoints
     * @param params
     * @param config
     */
    events: (params?: ListParams, config?: RequestConfig) => ({
      queryKey: [...key, 'events', getParams(params, true)],
      queryFn: ({ signal }) =>
        request(
          { ...config, method: 'GET', url: `${url}events`, params: getParams(params, true), signal: signal },
          { isList: true }
        ),
      keepPreviousData: true
    }),

    /**
     * It will create a new record using POST request.
     * @param config
     * @param urlAppend
     * @param invalidate
     */
    create: (config?: RequestConfig, urlAppend?, invalidate = true) => ({
      mutationKey: [...key, 'create'],
      mutationFn: (payload: any) =>
        request({ ...config, method: 'POST', url: urlAppend ? `${url}${urlAppend}` : url, data: payload }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    }),

    /**
     * It will update a record using PUT request.
     * @param config
     * @param urlAppend
     * @param invalidate
     * @param params
     */
    update: (config?: RequestConfig, urlAppend?, invalidate = true, params?: { [key: string]: any }) => ({
      mutationKey: [...key, 'update'],
      mutationFn: (payload: any) =>
        request({
          ...config,
          method: 'PUT',
          url: urlAppend ? `${url}${getPayloadId(payload)}/${urlAppend}/` : `${url}${getPayloadId(payload)}/`,
          data: payload,
          ...(params && { params })
        }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    }),

    /**
     * It will partially update a record using PATCH request.
     * @param config
     * @param urlAppend
     * @param invalidate
     */
    patch: (config?: RequestConfig, urlAppend?, invalidate = true) => ({
      mutationKey: [...key, 'patch'],
      mutationFn: (payload: any) =>
        request({
          ...config,
          method: 'PATCH',
          url: `${url}${getPayloadId(payload)}/${urlAppend ?? ''}`,
          data: payload
        }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    }),

    /**
     * Save is a wrapper around update and create.
     * If the payload has an id, it will update, otherwise it will create.
     * @param config
     * @param urlAppend
     * @param invalidate
     */
    save: (config?: RequestConfig, urlAppend?: string, invalidate = true) => ({
      mutationKey: [...key, 'save'],
      mutationFn: (payload: any) =>
        request({
          ...config,
          method: getPayloadId(payload) ? 'PATCH' : 'POST',
          url: getPayloadId(payload) ? `${url}${getPayloadId(payload)}/${urlAppend ? urlAppend : ''}` : url,
          data: payload
        }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    }),

    /**
     * It will batch update records using PATCH request.
     * @param config
     * @param urlAppend
     * @param invalidate
     */
    batch: (config?: RequestConfig, urlAppend?, invalidate = true) => ({
      mutationKey: [...key, 'batch'],
      mutationFn: (payload: any) =>
        request({ ...config, method: 'POST', url: urlAppend ? `${url}${urlAppend}` : url, data: payload }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    }),

    /**
     * It will delete a record using DELETE request.
     * @param config
     * @param urlAppend
     * @param invalidate
     */
    delete: (config?: RequestConfig, urlAppend?, invalidate = true) => ({
      mutationKey: [...key, 'delete'],
      mutationFn: (payload: any) => request({ ...config, method: 'DELETE', url: `${url}${getPayloadId(payload)}/` }),
      onSettled: () => {
        if (!invalidate) return
        refreshQueries(invalidateKeys)
      }
    })
  }
}

/**
 * Creates a custom mutation endpoint config object that can be used with react-query.
 */
export const makeCustomMutation = ({
  path,
  meta,
  config,
  urlAppend,
  invalidate
}: {
  path: string
  meta: ApiResource['meta']
  config?: RequestConfig
  urlAppend?: string
  invalidate?: boolean
}) => {
  const _url = `${meta.url}${path}/`

  return {
    mutationKey: [...meta.key, path],
    mutationFn: (payload: any) =>
      request({
        method: 'POST',
        url: urlAppend ? `${_url}${urlAppend}` : _url,
        data: payload,
        ...config
      }),
    onSettled: () => {
      if (!invalidate) return
      refreshQueries(meta.invalidateKeys)
    }
  }
}

/**
 * Creates a detail mutation endpoint config object that can be used with react-query.
 */
export const makeDetailMutation = ({
  path,
  meta,
  config,
  urlAppend,
  invalidate,
  params
}: {
  path: string
  meta: ApiResource['meta']
  config?: RequestConfig
  urlAppend?: string
  invalidate?: boolean
  params?: { [key: string]: any }
}) => {
  return {
    mutationKey: [...meta.key, path],
    mutationFn: (payload: any) =>
      request({
        ...config,
        method: 'POST',
        url: urlAppend
          ? `${meta.url}/${getPayloadId(payload)}/${path}/${urlAppend}/`
          : `${meta.url}${getPayloadId(payload)}/${path}/`,
        data: payload,
        ...(params && { params })
      }),
    onSettled: () => {
      if (!invalidate) return
      refreshQueries(meta.invalidateKeys)
    }
  }
}
