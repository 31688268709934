import { useTicket } from '@/modules/ticket/form/hooks'
import { Form } from 'antd'
import { FC } from 'react'
import { ButtonConfig } from '../types'
import { GenerateReport } from './generate-report'
import { RecentReports } from './recent-reports'

type Props = {
  config: ButtonConfig
}

export const ReportForm: FC<Props> = ({ config }) => {
  const [form] = Form.useForm()
  const { ticket } = useTicket()

  const initialValues = {
    report_idx: config?.reports?.length ? 0 : undefined,
    fleet_id: ticket.afe_id,
    fleet_name: ticket.afe__user_afe_no,
    generating: {}
  }

  return (
    <Form form={form} initialValues={initialValues} layout={'vertical'}>
      <GenerateReport config={config} />
      <RecentReports />
    </Form>
  )
}
