import { useApp, useAppDispatch } from '@/hooks'
import { FILTER_FIELDS } from '@/modules/jas/job-manager/constants'
import { useCustomFormQuery, useJobManager } from '@/modules/jas/job-manager/hooks'
import { setAdvanceSearch } from '@/modules/jas/job-manager/slice'
import { TICKET_STATUS } from '@/modules/ticket/constants'
import { getLabel } from '@/utils'
import { FormInstance, Space, Tag } from 'antd'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

const LABEL_BY_FIELD = FILTER_FIELDS.reduce((acc: Record<string, string>, field) => {
  acc[field.value] = field.label
  return acc
}, {})

type Props = {
  onClear(): void
  form: FormInstance<any>
}

export const FilterTags = ({ onClear, form }: Props) => {
  const dispatch = useAppDispatch()
  const { labels } = useApp()
  const { advanceSearch } = useJobManager()
  const { data: customForm } = useCustomFormQuery()
  const formStatuses = customForm?.userdata?.custom_status

  const filterTags = useMemo(() => {
    const statusesLabels = { ...TICKET_STATUS, ...formStatuses }

    return Object.entries(advanceSearch)
      .filter(([_, value]) => !isEmpty(value))
      .map(([key, value]) => {
        let label = `${getLabel(LABEL_BY_FIELD[key], labels)}: `
        if (key === 'computed_status') {
          label += `${value
            .split(',')
            .map((s) => statusesLabels[s])
            .join(', ')}`
        } else {
          label += value
        }
        return { field: key, label: label }
      })
  }, [advanceSearch, labels, formStatuses])

  const removeFilter = (field: string) => {
    dispatch(
      setAdvanceSearch({
        ...advanceSearch,
        [field]: ''
      })
    )
    form.setFieldsValue({ [field]: '' })
  }

  return (
    <Space className={cn({ hidden: !filterTags.length })} size={[0, 'middle']} wrap>
      <div className="text-sm text-primary mr-10 cursor-pointer" onClick={onClear}>
        Clear
      </div>
      {filterTags.map((tag) => (
        <Tag
          key={tag.field}
          bordered={true}
          className="px-10  py-6 text-normal text-gray-400 rounded-full"
          closable
          onClose={() => removeFilter(tag.field)}
        >
          <span className="pr-6">{tag.label}</span>
        </Tag>
      ))}
    </Space>
  )
}
