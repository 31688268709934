import { useApp } from '@/hooks'
import { ListParams } from '@/types/api/core'
import { SearchInput, Select } from '@/ui'
import { Form } from 'antd'
import { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type Props = {
  onChange: (value: any) => void
}

type SearchForm = {
  searchText: string
  searchBy: string
}

export const Filter: FC<Props> = ({ onChange }) => {
  const { l } = useApp()
  const [form] = Form.useForm<SearchForm>()

  const handleSearch = useDebouncedCallback(() => {
    const search = form.getFieldsValue()
    const text = search.searchText.trim()

    const params: ListParams = {}

    if (text) {
      params[search.searchBy + '__icontains'] = text
    }

    onChange(params)
  }, 250)

  return (
    <Form form={form} initialValues={{ searchText: '', searchBy: 'ticket__name' }} onValuesChange={handleSearch}>
      <div className={'flex flex-row items-center gap-10'}>
        <Form.Item name={'searchBy'} className={'hidden md:flex  m-0'}>
          <Select
            showCopyText={false}
            allowClear={false}
            showSearch={false}
            className={'!w-[200px]'}
            options={[
              {
                label: l('ticket__name__label'),
                value: 'ticket__name'
              },
              {
                label: 'Ref Number',
                value: 'ref_number'
              },
              {
                label: 'Sync Detail',
                value: 'detail'
              },
              {
                label: l('ticket__afe__label'),
                value: 'ticket__afe__user_afe_no'
              },
              {
                label: l('ticket__job_code__label'),
                value: 'ticket__job_code__user_job_code_no'
              },
              {
                label: l('ticket__location__label'),
                value: 'ticket__location__name'
              },
              {
                label: 'Team Member',
                value: 'ticket__contacts__contact__name'
              },
              {
                label: l('ticket__office__label'),
                value: 'ticket__customer_office__name'
              },
              {
                label: l('ticket__contact__label'),
                value: 'ticket__customer__first_name'
              },
              {
                label: l('Ticket'),
                value: 'ticket__custom_form__name'
              }
            ]}
          />
        </Form.Item>
        <Form.Item name={'searchText'} className={'flex-grow md:flex-grow-0 m-0'}>
          <SearchInput debounce={0} />
        </Form.Item>
      </div>
    </Form>
  )
}
