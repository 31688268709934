import { AddEquipmentsModal } from '@/components'
import { TABLE_PAGE_SIZES } from '@/constants/general'
import { useApp } from '@/hooks'
import { lineItemApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { customFormAtom } from '../../../atoms'
import { LineItemEditFields } from '../line-item-edit-fields'

type Props = {
  onAddFinished: (lineItem: any) => void
  lastSequence: number
}

export const AddLineItemModal = ({ onAddFinished, lastSequence }: Props) => {
  const customForm = useAtomValue(customFormAtom)
  const { id } = useParams()
  const { notification } = useApp()
  const addLineItemMutation = useMutation(lineItemApi.create())
  const [selectedLineItem, setSelectedLineItem] = useState<any>(null)

  const handleAdd = async () => {
    const {
      id: equipmentId,
      is_finished_good,
      is_sub_component,
      uuid,
      company,
      group,
      group_no,
      ...rest
    } = selectedLineItem

    const lineItem = await addLineItemMutation.mutateAsync({
      ...rest,
      category: selectedLineItem.category?.id,
      custom_form: Number(id),
      equipment: equipmentId,
      sequence: lastSequence
    })

    onAddFinished(lineItem)

    notification.success({
      message: 'Line Item added successfully'
    })

    setSelectedLineItem(null)
  }

  return (
    <>
      <AddEquipmentsModal
        onAdd={async () => handleAdd()}
        onSelect={(lineItem) => {
          setSelectedLineItem(lineItem)
        }}
        triggerProps={{ iconName: undefined, type: 'primary' }}
        modalTitle="Add Line Item"
        selectMode="single"
        isFullScreen
        customForm={customForm}
        modalProps={{
          okButtonProps: {
            loading: addLineItemMutation.isLoading,
            'data-cy': 'custom-form-line-item-add-save-button',
            disabled: !selectedLineItem
          }
        }}
        tableProps={{
          scroll: undefined,
          pagination: {
            pageSize: TABLE_PAGE_SIZES[0]
          }
        }}
        extraAfterTable={
          selectedLineItem && (
            <LineItemEditFields
              lineItem={selectedLineItem}
              onChange={(_, value) => {
                setSelectedLineItem((prev: any) => ({ ...prev, ...value }))
              }}
            />
          )
        }
      />
    </>
  )
}
