import { useSession } from '@/hooks'
import { executeMutation } from '@/query'
import { jobCodeApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm, useTicketFormValues } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'job_code'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const JobCodeInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { company } = useSession()
  const { isDisabledField, isHiddenField } = useCustomForm()
  const { setFieldValue, getPropertyValueByKey } = useTicketFormValues()
  const prefix = getPropertyValueByKey('job_code_prefix') || ''

  if (isHiddenField(KEY)) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__job_code__label'} />} {...formItemProps}>
      <QuerySelect
        apiEndpoint={jobCodeApi.list}
        apiQueryParams={{ fields: 'id,user_job_code_no', order: 'user_job_code_no' }}
        apiSearchBy={'user_job_code_no'}
        renderOption={(item) => ({ value: item.id, label: item.user_job_code_no })}
        labelRender={(option) => `${prefix}${option.label}`}
        disabled={isDisabledField(KEY)}
        prefetch={true}
        showCopyText={true}
        addOption={{
          onAdd: async (text) => {
            const o: any = await executeMutation(jobCodeApi.create(), { user_job_code_no: text, company: company.id })
            return { value: o.id, label: o.user_job_code_no }
          }
        }}
        {...(inputProps as any)}
      />
    </Form.Item>
  )
}
