import { Button } from '@/ui'
import { useSetAtom } from 'jotai'
import { editingCustomFormSectionAtom } from '../atoms'
import { useCustomFormQuery } from '../hooks'

export const AddSectionButton = () => {
  const { customFormQuery } = useCustomFormQuery()
  const setEditingCustomFormSection = useSetAtom(editingCustomFormSectionAtom)

  const handleAddSection = () => {
    const propertyGroups = customFormQuery.data?.property_groups || []

    setEditingCustomFormSection({
      name: '',
      sequence: (propertyGroups[propertyGroups.length - 1]?.sequence || 0) + 10,
      hide_from_customer: false,
      hidden: false,
      hide_on_web: false,
      hide_on_print: false,
      internal_use_only: false,
      print_class: ''
    })
  }

  return (
    <Button type="primary" iconName="fa:add" onClick={handleAddSection} data-cy="add-section">
      Add Section
    </Button>
  )
}
