import { useApp, useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { companyApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Icon, Modal } from '@/ui'
import { cn, safeJsonParse } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Spin, Table } from 'antd'
import { cloneDeep, isEqual, isNil, omitBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCustomFormQuery, useUpdateCustomFormMutation } from '../hooks'
import { PlaceholderBox } from '../placeholder-box'
import { TICKET_HIDDEN_REFERENCE_LABELS } from '../property-groups/edit-property-modal/bpa-builder/constants'
import { filterEntriesByPrefix, getCompanyHiddenFieldsLabels } from './helpers'

export const EditHiddenReferencesLabelsModal = () => {
  const { id } = useParams()
  const { notification } = useApp()
  const [form] = Form.useForm()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const { customFormQuery } = useCustomFormQuery()
  const { updateCustomFormMutation } = useUpdateCustomFormMutation()
  const customFormUserdata = useMemo(() => customFormQuery.data?.userdata || {}, [customFormQuery.data?.userdata])
  const customFormFieldAliases = useMemo(() => customFormUserdata.field_aliases || {}, [customFormUserdata])

  const { company } = useSession()
  const { refreshUserSession } = useBootstrap()
  const companyQuery = useQuery({ ...companyApi.get<Company>(company.id) })
  const updateCompanyMutation = useMutation({
    ...companyApi.patch(),
    onSuccess: async () => {
      refreshUserSession()
    }
  })
  const companyFieldAliases = useMemo(() => {
    const allAlises = safeJsonParse(companyQuery.data?.field_aliases, {})
    return getCompanyHiddenFieldsLabels(allAlises)
  }, [companyQuery.data?.field_aliases])

  const initialValues = useMemo(() => {
    return Object.keys(TICKET_HIDDEN_REFERENCE_LABELS).reduce(
      (prev, current) => {
        return {
          ...prev,
          [`custom_form__${current}`]: customFormFieldAliases[current],
          [`company__${current}`]: companyFieldAliases[current]
        }
      },
      {} as Record<string, string>
    )
  }, [companyFieldAliases, customFormFieldAliases])

  const handleSave = useCallback(async () => {
    const formValues = await form.validateFields()
    const prevCustomFormFieldAliases = cloneDeep(customFormFieldAliases)
    const prevCompanyFieldAlises = safeJsonParse(companyQuery.data?.field_aliases, {}) // should put to both requests not only ticket__user_data fields

    const mapping = [
      { prefix: 'custom_form__', prev: prevCustomFormFieldAliases },
      { prefix: 'company__', prev: prevCompanyFieldAlises }
    ]

    const [newCustomFormFieldAliases, newCompanyFieldAlises] = mapping.map(({ prefix, prev }) => {
      const updated = filterEntriesByPrefix(formValues, prefix)
      const newValues = {
        ...prev,
        ...updated
      }
      return omitBy(newValues, (v) => isNil(v) || v === '')
    })

    const requests = []
    const requestLabels: string[] = []

    if (!isEqual(prevCustomFormFieldAliases, newCustomFormFieldAliases)) {
      const newUserData = {
        ...customFormUserdata,
        field_aliases: newCustomFormFieldAliases
      }
      const userDataJson = JSON.stringify(newUserData)
      const req = updateCustomFormMutation.mutateAsync({ id, userdata_json: userDataJson })
      requests.push(req)
      requestLabels.push('Custom Form')
    }

    if (!isEqual(prevCompanyFieldAlises, newCompanyFieldAlises)) {
      const req = updateCompanyMutation.mutateAsync({ id: company.id, field_aliases: newCompanyFieldAlises })
      requests.push(req)
      requestLabels.push('Company')
    }

    const results = await Promise.allSettled(requests)
    if (results.length) {
      results.forEach(({ status }, i) => {
        if (status === 'fulfilled') {
          notification.success({ message: `${requestLabels[i]} labels updated` })
        } else {
          notification.error({ message: `Failed to update ${requestLabels[i]} labels` })
        }
      })
    } else {
      // nothing was changed, just show something that all is okay
      notification.success({ message: 'Labels saved' })
    }

    setSearch('')
    setIsOpen(false)
  }, [
    company.id,
    companyQuery.data?.field_aliases,
    customFormFieldAliases,
    customFormUserdata,
    form,
    id,
    notification,
    updateCompanyMutation,
    updateCustomFormMutation
  ])

  return (
    <>
      <PlaceholderBox onClick={() => setIsOpen(true)} data-cy="edit-hidden-references-modal">
        <div>Hidden References Labels</div>
      </PlaceholderBox>
      <Modal
        title="Hidden References Labels"
        withScreenMaxHeight
        open={isOpen}
        okText="Save"
        onCancel={() => {
          form.resetFields()
          setSearch('')
          setIsOpen(false)
        }}
        onOk={handleSave}
        okButtonProps={{ loading: updateCustomFormMutation.isLoading || updateCompanyMutation.isLoading }}
      >
        {customFormQuery.isLoading || companyQuery.isLoading ? (
          <Spin />
        ) : (
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Input
              className="mb-16"
              type="search"
              prefix={<Icon name="fa:search" />}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Table
              dataSource={Object.entries(TICKET_HIDDEN_REFERENCE_LABELS)
                .filter(([_, label]) =>
                  !search.trim() ? true : label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                )
                .map(([key, label]) => ({ label, key }))}
              pagination={false}
              sortDirections={['ascend', 'descend', 'ascend']}
              showSorterTooltip={false}
              className="[&_td]:!bg-transparent [&_th]:!bg-[#fbfbfb]" // override sorting highlight
            >
              <Table.Column
                dataIndex="label"
                defaultSortOrder="ascend"
                sorter={(a: any, b: any) => a.label.localeCompare(b.label, 'en')}
                width={100}
                title="Field"
                sortIcon={({ sortOrder }) => (
                  <Icon
                    name={'fa:arrow-up'}
                    className={cn('opacity-0 text-[12px] transition-all group-hover:opacity-50 font-bold', {
                      '!opacity-100 text-primary': !!sortOrder,
                      'rotate-0': sortOrder === 'ascend',
                      'rotate-180': sortOrder === 'descend'
                    })}
                  />
                )}
              />
              <Table.Column
                width={100}
                title="Company Labels"
                dataIndex="key"
                render={(key) => (
                  <Form.Item name={`company__${key}`}>
                    <Input />
                  </Form.Item>
                )}
              />
              <Table.Column
                width={100}
                title="Custom Form Labels"
                dataIndex="key"
                render={(key) => (
                  <Form.Item name={`custom_form__${key}`}>
                    <Input />
                  </Form.Item>
                )}
              />
            </Table>
          </Form>
        )}
      </Modal>
    </>
  )
}
