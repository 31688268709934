import { Form, InputNumber } from 'antd'
import { useEffect } from 'react'
import { Equipment, FormValues } from '../../../schemas'

type Props = {
  equipment: Equipment
}

export const DefaultRateEditCell = ({ equipment }: Props) => {
  const form = Form.useFormInstance<FormValues>()

  useEffect(() => {
    form.setFieldsValue({
      defaultUsageRate: {
        usageRate: equipment.usage_rate
      }
    })
  }, [form, equipment])

  return (
    <Form.Item noStyle name={['defaultUsageRate', 'usageRate']}>
      <InputNumber type="number" className="w-full" />
    </Form.Item>
  )
}
