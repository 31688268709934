import { useApp } from '@/hooks'
import { alertApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { cloneDeep } from 'lodash'
import React, { useMemo } from 'react'
import { selectedAccountRoleAtom } from '../atoms'

export const NotificationsColumns = () => {
  const alertsQuery = useQuery(alertApi.list())
  const isSyncNotifications = Form.useWatch('sync_notifications')
  const { notification } = useApp()

  const enabledNotifications =
    useAtomValue(useMemo(() => selectAtom(selectedAccountRoleAtom, (s) => s?.data?.notifications), [])) ?? {}

  const setSelectedAccountRole = useSetAtom(selectedAccountRoleAtom)

  const isNotificationEnabled = (notificationId: number, type: 'email_alerts' | 'sms_alerts') =>
    !!enabledNotifications[type]?.[notificationId]

  const alerts = useMemo(() => {
    const result = {
      email: [],
      sms: []
    } as Record<string, any[]>

    alertsQuery.data?.items.forEach((alert: any) => {
      if (
        alert.email_notification_template ||
        // for old versions of the alert
        alert.email_template ||
        (!alert.email_notification_template && !alert.email_template && !alert.sms_template)
      ) {
        result.email.push(alert)
      }

      if (alert.sms_template) {
        result.sms.push(alert)
      }
    }, result)

    return result
  }, [alertsQuery.data])

  const handleChange = (notificationId: number, type: 'email_alerts' | 'sms_alerts', checked: boolean) => {
    let newEnabledNotifications = cloneDeep(enabledNotifications)

    if (checked) {
      if (!isSyncNotifications) {
        notification.error({
          message: 'Please enable sync notifications!'
        })

        return
      }

      newEnabledNotifications = {
        ...enabledNotifications,
        [type]: {
          ...newEnabledNotifications[type],
          [notificationId]: checked
        }
      }
    } else {
      delete newEnabledNotifications[type][notificationId]
    }

    setSelectedAccountRole((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        notifications: newEnabledNotifications
      }
    }))
  }

  return (
    <div className="col-span-2 flex h-full">
      <Column title="Email Notifications">
        {alerts.email.map((alert: any, index) => (
          <div key={index} className="flex w-full justify-between mb-20">
            <div>{alert.name}</div>
            <Switch
              checked={isNotificationEnabled(alert.id, 'email_alerts')}
              onChange={(checked) => handleChange(alert.id, 'email_alerts', checked)}
            />
          </div>
        ))}
      </Column>
      <Column title="SMS Notifications">
        {alerts.sms.map((alert: any, index) => (
          <div key={index} className="flex w-full justify-between mb-20">
            <div>{alert.name}</div>
            <Switch
              checked={isNotificationEnabled(alert.id, 'sms_alerts')}
              onChange={(checked) => handleChange(alert.id, 'sms_alerts', checked)}
            />
          </div>
        ))}
      </Column>
    </div>
  )
}

type ColumnProps = {
  title: string
  children?: React.ReactNode
}

const Column: React.FC<ColumnProps> = ({ title, children }) => {
  return (
    <div className="flex-1 border-b border-r border-secondary">
      <div className="bg-background-accent font-semibold px-8 py-12 border-b border-secondary">{title}</div>
      <div className="px-12 py-16">{children}</div>
    </div>
  )
}
