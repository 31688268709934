// seperate mutation for permission. https://staging-api.oilcommand.com/custom_forms/519/access/

import { useSession } from '@/hooks'
import { Table, Tooltip } from 'antd'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'
import { Company } from '../../../types'
import { CompanyName } from './company-name'
import { CompanySelect } from './company-select'
import { DeleteButton } from './delete-button'
import { NotificationCheckbox } from './notification-checkbox'
import { PermissionCheckbox } from './permission-checkbox'

const { Column } = Table

export const AccessAndSubscriptions = () => {
  const { user } = useSession()
  const alerts = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.alerts), []))
  const primaryCompanyId = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.primary_company), []))
  const companies = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.companies), []))

  const permissionEditEnabled = (company?: Company, permission?: 'write' | 'create' | 'list'): boolean =>
    user?.is_superuser ||
    (user?.profile.company.id === primaryCompanyId &&
      (!company || company.id !== primaryCompanyId || permission === 'list'))

  const notificationEditEnabled = (company: Company) =>
    user?.is_superuser || user?.profile.company.id === primaryCompanyId || user?.profile.company.id === company.id

  const dataSource = useMemo(
    () =>
      companies?.map((company) => ({
        key: company.id,
        ...company
      })) || [],
    [companies]
  )

  return (
    <div className="pt-4">
      <h5 className="font-bold mb-10">Company access & subscriptions</h5>
      <Table dataSource={dataSource} pagination={false}>
        <Column title="Company" render={(_, company: Company) => <CompanyName company={company} />} />
        <Table.ColumnGroup title="Permissions">
          <Column
            title={<Tooltip title="Allow a user of the listed company to create and edit a ticket">Create</Tooltip>}
            width={100}
            align="center"
            render={(_, company: Company) => (
              <PermissionCheckbox
                company={company}
                permission="create"
                disabled={!permissionEditEnabled(company, 'create')}
              />
            )}
          />
          <Column
            title={<Tooltip title="Allow a user of the listed company to edit existing tickets">Edit</Tooltip>}
            width={100}
            align="center"
            render={(_, company: Company) => (
              <PermissionCheckbox
                company={company}
                permission="write"
                disabled={!permissionEditEnabled(company, 'write')}
              />
            )}
          />
          <Column
            title={
              <Tooltip title="Allow a ticket manager of the listed company to see all of these tickets">
                List All
              </Tooltip>
            }
            width={100}
            align="center"
            render={(_, company: Company) => (
              <PermissionCheckbox
                company={company}
                permission="list"
                disabled={!permissionEditEnabled(company, 'list')}
              />
            )}
          />
        </Table.ColumnGroup>
        {alerts?.length ? (
          <Table.ColumnGroup title="Notifications">
            {alerts?.map((alert) => (
              <Column
                key={alert.id}
                title={<Tooltip title={alert.name}>{alert.id}</Tooltip>}
                width={100}
                align="center"
                render={(_, company: Company) => {
                  return (
                    <NotificationCheckbox
                      alert={alert}
                      company={company}
                      disabled={!notificationEditEnabled(company)}
                    />
                  )
                }}
              />
            )) ?? []}
          </Table.ColumnGroup>
        ) : null}
        <Column
          width={100}
          align="center"
          render={(_, company: Company) => (
            <DeleteButton company={company} disabled={!permissionEditEnabled(company)} />
          )}
        />
      </Table>
      {permissionEditEnabled() && <CompanySelect />}
    </div>
  )
}
