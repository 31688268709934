import { useSortable } from '@/ui/sortable'
import cn from 'classnames'
import { FC } from 'react'
import { CurrentKind } from '../../../../hooks'
import { ItemColumnType, LineItem } from '../../../../schemas'
import { ItemComponents } from '../item-components'
import { ItemEditable } from '../item-editable'
import { ItemNotes } from '../item-notes'
import styles from './styles.module.scss'

type Props = {
  item: Partial<LineItem>
  columns: ItemColumnType[]
  kind: CurrentKind
}

export const ItemRow: FC<Props> = ({ item, columns, kind }) => {
  const { attributes, setNodeRef, isDragging, style } = useSortable({ id: item.id as any })

  return (
    <>
      {/* selected columns row */}
      <tr
        ref={setNodeRef}
        style={style}
        className={cn('', {
          '[&>td]:rounded-none [&>td]:bg-primary-200 overflow-hidden !bg-primary-200': isDragging
        })}
        {...attributes}
      >
        <td className={'!m-0 !p-0'}>
          {item.sku_designation === 'F' && <div className={styles.componentsCount}>{item.components?.length}</div>}
        </td>
        {columns.map((c, idx) => (
          <ItemEditable key={c.key} item={item} column={c} index={idx} />
        ))}
      </tr>

      {/* notes row */}
      <ItemNotes
        key={`notes-${item.id}`}
        columns={columns}
        item={item}
        style={style}
        className={cn('', { 'overflow-hidden !bg-primary-200': isDragging })}
        kind={kind}
        {...attributes}
      />

      {/* item components row */}
      {item.sku_designation === 'F' && (
        <ItemComponents
          key={`components-${item.id}`}
          columns={columns}
          item={item}
          style={style}
          className={cn('', { 'overflow-hidden !bg-primary-200': isDragging })}
          {...attributes}
        />
      )}
    </>
  )
}
