import { SortableColumnTitle } from '@/components'
import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { companyAccountApi, companyApi, officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { SearchInput } from '@/ui'
import { AlphabetFilter } from '@/ui/alphabet-filter'
import { Button } from '@/ui/button'
import { objId } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Input, Space, Table } from 'antd'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

export const CustomerNameReconciliationView = view<any, any>(Component, {
  title: () => 'CustomerNameReconciliation'
})

function Component() {
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('company__name')
  const [letter, setLetter] = useState<string | null>(null)
  const [search, setSearch] = useState<string>('')
  const [editingRow, setEditingRow] = useState<(typeof dataSource)[number] | null>(null)
  const [editingAccountName, setEditingAccountName] = useState<string>('')
  const { notification, l } = useApp()
  const { company } = useSession()

  const officesQuery = useQuery({
    ...officeApi.list({
      limit,
      page,
      order,

      ...(letter || search ? { company__name__icontains: search || letter } : {})
    })
  })

  const companiesQuery = useQuery({
    ...companyApi.list<Company>({
      limit,
      id__in: officesQuery.data?.items.map((item) => item.company.id).join(',') ?? ''
    }),
    enabled: !!officesQuery.data?.items.length
  })

  const companyAccountsQuery = useQuery({ ...companyAccountApi.list({ limit: 'None' as any }) })
  const updateCompanyAccountMutation = useMutation({ ...companyAccountApi.update() })
  const createCompanyAccountMutation = useMutation({ ...companyAccountApi.create() })

  const dataSource = useMemo(() => {
    if (officesQuery.isLoading || companiesQuery.isInitialLoading) {
      return []
    }

    const offices = officesQuery.data?.items ?? []
    const companies = companiesQuery.data?.items ?? []

    return offices.reduce(
      (
        acc: (Office & {
          company: Company | null
          rowSpan: number
          key: string
        })[],
        office: Office
      ) => {
        const company = companies.find((company) => company.id === objId(office.company))
        const hasCurrentCompany = acc.some((item) => item.company?.id === company?.id)
        const rowSpan = hasCurrentCompany ? 0 : (company?.offices?.length ?? 0)

        if (!company) {
          return acc
        }

        return [
          ...acc,
          {
            ...office,
            rowSpan,
            company,
            key: office.id
          }
        ]
      },
      []
    )
  }, [officesQuery, companiesQuery])

  const handleSaveCompanyAccount = async () => {
    if (!editingRow) {
      return
    }

    if (editingAccountName === '') {
      notification.error({ message: 'Accounting name is required' })
      return
    }

    const companyAccount = companyAccountsQuery.data?.items.find((item) => item.accounting_office === editingRow.id)
    const mutation = companyAccount ? updateCompanyAccountMutation : createCompanyAccountMutation

    await mutation.mutateAsync({
      id: companyAccount?.id,
      name: editingAccountName,
      accounting_office: editingRow.id,
      company: company.id
    })

    await companyAccountsQuery.refetch()
    setEditingRow(null)
    setEditingAccountName('')
    notification.success({ message: 'Accounting name saved' })
  }

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setPage(1)
  }, [])

  return (
    <PageView
      header={{
        title: l('CustomerNameReconciliation'),
        border: false,
        accent: false
      }}
    >
      <SearchInput onChange={handleChange} />
      <AlphabetFilter onChange={setLetter} wrapperClassName="mt-16" />
      <Table
        loading={companiesQuery.isInitialLoading || officesQuery.isLoading}
        dataSource={dataSource}
        pagination={{
          current: page,
          pageSize: limit,
          total: officesQuery.data?.total ?? 0,
          pageSizeOptions: TABLE_PAGE_SIZES,
          onChange: (page, limit) => {
            setPage(page)
            setLimit(limit ?? TABLE_DEFAULT_PAGE_SIZE)
          }
        }}
        scroll={{
          y: 'calc(100vh - 454px)'
        }}
        columns={[
          {
            title: (
              <SortableColumnTitle name="company__name" order={order} onChange={(key) => setOrder(key)}>
                Company
              </SortableColumnTitle>
            ),
            dataIndex: ['company', 'name'],
            onCell: (record: any) => ({
              rowSpan: record
            })
          },
          {
            title: 'Company Office',
            dataIndex: 'name'
          },
          {
            title: 'Accounting Company Name',
            render: (_, record: any) => {
              const name = companyAccountsQuery.data?.items.find((item) => item.accounting_office === record.id)?.name

              if (editingRow?.id === record.id) {
                return <Input defaultValue={name} onChange={(e) => setEditingAccountName(e.target.value)} />
              }

              return name
            }
          },
          {
            title: null,
            align: 'right',
            render: (_, record: any) => {
              if (editingRow?.id === record.id) {
                return (
                  <Space>
                    <Button iconName="mi:close" type="text" onClick={() => setEditingRow(null)} />
                    <Button iconName="mi:check" type="success" onClick={handleSaveCompanyAccount} />
                  </Space>
                )
              }

              return <Button iconName="svg:edit" type="text" onClick={() => setEditingRow(record)} />
            }
          }
        ]}
      />
    </PageView>
  )
}
