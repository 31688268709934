import { SelectValue } from 'antd/es/select'
import { ColumnType } from 'antd/es/table'
import { Dayjs } from 'dayjs'
import { z } from 'zod'

// schemas
export const CategoryKindSchema = z.object({
  id: z.number(),
  uuid: z.string().optional(),
  name: z.string(),
  kind: z.string(),

  fields: z.array(z.string()),
  hidden_fields: z.array(z.string()).nullish(),
  field_aliases: z.record(z.string(), z.string()).nullish(),
  formats: z.record(z.string(), z.string()).nullish(),
  summary_scripts: z.record(z.string(), z.string()).nullish(),

  hide_print: z.array(z.string()).nullish(), // unused: on frontend
  exclude_mobile_fields: z.array(z.string()).nullish(), // unused: on frontend
  readonly_fields: z.array(z.string()).nullish(), // unused: deprecated
  scripts: z.record(z.string(), z.string()).nullish(), // unused: on frontend

  list_view_template: z.string().optional(), // only: mobile web view

  data: z
    .record(
      z.string(),
      z
        .object({
          stringSource: z.number().nullable(),
          hideDate: z.boolean(),
          hideTime: z.boolean(),
          hideOnNew: z.boolean(),
          readonly: z.boolean(),
          mobileWeb: z.object({
            hide: z.boolean(),
            readonly: z.boolean(),
            onList: z.boolean()
          }) // only: mobile web view
        })
        .nullish()
    )
    .nullish(),

  // json fields (from values endpoint)
  field_aliases_json: z.string().nullish(),
  hidden_fields_json: z.string().nullish(),
  formats_json: z.string().nullish(),
  readonly_fields_json: z.string().nullish(),
  scripts_json: z.string().nullish(),
  data_json: z.string().nullish()
})
export const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  kind: z.number().nullish(),
  parent__id: z.number(),
  parent__name: z.string(),
  parent__kind: z.number().nullish(),
  format_qty_as_duration: z.boolean().nullish(),

  _label: z.string(),
  _kind: CategoryKindSchema.nullish(),
  _amount: z.number(),
  _count: z.number()
})
export const LineItemSchema = z.object({
  id: z.number(),
  uuid: z.string(),

  category_id: z.number(),
  category__kind_id: z.number(),

  category__parent_category_id: z.number(),
  category__parent_category__kind_id: z.number().nullable(),

  ticket_id: z.number(),
  custom_form_id: z.number().nullable(),

  equipment_id: z.number(),
  equipment__usage_rate: z.number(),

  sku: z.string(),
  description: z.string(),
  usage_rate: z.number().nullable(),
  standby_rate: z.number().nullable(),
  hands: z.number().nullable(),
  unit: z.string(),
  units_used: z.number(),
  units_standby: z.number().nullable(),
  units_min: z.number().nullable(),
  minimum: z.number().nullable(),
  discount: z.number().nullable(),
  discountable_amount: z.number().nullable(),
  tax_rate: z.number().nullable(),
  taxable_amount: z.number().nullable(),
  barrels: z.number().nullable(),
  cost: z.number().nullable(),
  amount: z.number().nullable(),

  discount_sku: z.boolean(),
  non_discountable: z.boolean(),
  tax_sku: z.boolean(),
  non_taxable: z.boolean(),
  taxable_tax_sku: z.boolean(),
  skip_tax_calc: z.boolean(),
  skip_calc: z.boolean(),

  start_datetime: z.string(),
  end_datetime: z.string(),
  user_datetime_1: z.string().nullable(),
  user_datetime_2: z.string().nullable(),

  user_decimal_1: z.number().nullable(),
  user_decimal_2: z.number().nullable(),
  user_decimal_3: z.number().nullable(),

  user_integer_1: z.number().nullable(),
  user_integer_2: z.number().nullable(),
  user_integer_3: z.number().nullable(),
  user_integer_4: z.number().nullable(),

  user_text_1: z.string().nullable(),
  user_text_2: z.string().nullable(),
  user_text_3: z.string().nullable(),
  user_text_4: z.string().nullable(),
  user_text_5: z.string().nullable(),
  user_text_6: z.string().nullable(),
  user_text_7: z.string().nullable(),
  user_text_8: z.string().nullable(),

  equipment__string_1_source: z.number().nullable(),
  equipment__string_2_source: z.number().nullable(),
  equipment__string_3_source: z.number().nullable(),
  equipment__string_4_source: z.number().nullable(),
  equipment__string_5_source: z.number().nullable(),
  equipment__string_6_source: z.number().nullable(),
  equipment__string_7_source: z.number().nullable(),
  equipment__string_8_source: z.number().nullable(),

  notes: z.string().nullable(),
  user_long_text_1: z.string().nullable(),

  assignee_id: z.number().nullable(),
  assignee__first_name: z.string().nullable(),
  assignee__last_name: z.string().nullable(),

  status: z.string().nullable(),

  finished_good_li_id: z.number().nullable(),
  sku_designation: z.string().nullable(),
  show_components: z.boolean(),
  components: z.array(z.object({ id: z.number() })),

  sequence: z.number(),
  group: z.number(),
  subtotal_after: z.boolean(),
  sync_datetime: z.boolean(),

  summary_line_item_id: z.number().nullable(),

  data_json: z.any().nullable(),

  _sorting: z.boolean().nullish(),
  _old_usage_rate: z.number().nullish(),
  _has_notes: z.boolean().nullish(),
  _hide_inline_actions: z.boolean().nullish(),
  _data: z
    .object({
      summarizes_ticket_id: z.number().nullish(),
      summarizes_tickets: z
        .array(
          z.object({
            id: z.number(),
            name: z.number(),
            custom_form_id: z.number(),
            custom_form: z.string()
          })
        )
        .optional()
    })
    .nullish()

  // note: available but not used
  // created_by_id: z.number(),
  // created_at: z.string(),
  // modified_by_id: z.number(),
  // modified_at: z.string(),
  // deleted_at: z.string().nullable(),
  // deleted_by_id: z.number().nullable(),
  // inner_seq: z.number().nullable(),
  // ticket_computed_status: z.string(),
})
export const LineItemComponentSchema = LineItemSchema.omit({
  discount_sku: true,
  non_discountable: true,
  tax_sku: true,
  non_taxable: true,
  taxable_tax_sku: true,
  skip_tax_calc: true,
  skip_calc: true,

  sku_designation: true,
  components: true,
  show_components: true,

  group: true,
  subtotal_after: true,
  sync_datetime: true,
  data_json: true
})

// types
export type CategoryKind = z.infer<typeof CategoryKindSchema>
export type Category = z.infer<typeof CategorySchema>
export type LineItem = z.infer<typeof LineItemSchema>
export type LineItemComponent = z.infer<typeof LineItemComponentSchema>

export type LineItemsStats = {
  count_by_category: {
    category_id: number
    count: number
  }[]
  amount_by_category: {
    category_id: number
    amount: number
  }[]
  discounts: any[]
  taxes: any[]
  discountable_amount: number
  taxable_amount: number
  total_amount: number
  categories: Category[]
  category_kinds: CategoryKind[]
  sequence: {
    sequence__max: number
  }
  group: {
    group__max: number
  }
}

export type LineItemData = Omit<LineItem, 'start_datetime' | 'end_datetime' | 'user_datetime_1' | 'user_datetime_2'> & {
  start_datetime: Dayjs | null
  end_datetime: Dayjs | null
  user_datetime_1: Dayjs | null
  user_datetime_2: Dayjs | null
}

export type LineItemOptions = {
  type?: 'duplicate' | 'edit' | 'new'
  ticketId?: number | null
  customFormId?: number | null
  customerOfficeId?: number | null
  parentCategory?: SelectValue
  category?: SelectValue
  groupNo?: number | null
  groupItems?: Partial<LineItem>[]

  disableDurationUpdate?: boolean
  disabledFields?: string[]
  hiddenFields?: string[]
  excludedFields?: string[]
  ignoreHiddenFields?: boolean
  showAllFields?: boolean
  limitBackDate?: boolean
  preferEmpty?: boolean
  pricingAppliedId?: number

  selectorQueryOverride?: Record<string, any>
  sourcesOverride?: Record<string, number | null>
  lastGroupNoOverride?: number
  lastSequenceNoOverride?: number
  payloadOverride?: Record<string, any>
}

export type LineItemForm = {
  initialized?: boolean
  isNew?: boolean
  equipmentIds?: number[]
  options?: LineItemOptions
  data?: LineItemData
}

export type FieldConfig = {
  field: string
  label: string
  hidden: boolean
  editable: boolean
  disabled: boolean
  hideDate: boolean
  hideTime: boolean
  stringSource: number | null
  format: (line: Partial<LineItem>, options?: { category?: Category; kind?: CategoryKind }) => string
}

export type ItemColumnType = ColumnType<Partial<LineItem>> & {
  config?: FieldConfig
}
