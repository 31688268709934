import { UserSelect } from '@/components/user-select'
import { useApp } from '@/hooks'
import { useCustomFormLabels, useGlobalCustomForms } from '@/modules/custom-form/hooks'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { useQueryFilterService } from '@/ui/filter'
import { QueryFieldOptionalKey } from '@/ui/filter/types'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { TICKET_HIDDEN_REFERENCES } from '../../columns'
import { StatusSelect } from '../../components'
import { useTicketModule } from './use-ticket-module'

export const useTicketFilter = () => {
  const { moduleFormId } = useTicketModule()
  const { customForms, getAllPropertiesByKey } = useGlobalCustomForms()
  const { module } = useCurrentModuleQuery()
  const { labels: companyLabels } = useApp()
  const { l } = useCustomFormLabels(moduleFormId || null)

  const defaultFields: QueryFieldOptionalKey[] = useMemo(
    () => [
      {
        inputType: 'text',
        label: l('ticket__name__label'),
        field: 'name',
        lookup: 'icontains'
      },
      {
        inputType: 'select',
        label: 'Type (Form)',
        field: 'custom_form_id',
        lookup: 'in',
        inputProps: {
          placeholder: 'All',
          mode: 'multiple',
          options: customForms.map((cf) => ({ label: cf.name, value: cf.id }))
        }
      },
      {
        inputType: 'render',
        label: l('ticket__computed_status__label'),
        field: 'computed_status',
        lookup: 'in',
        inputRender: (props: any) => (
          <StatusSelect placeholder={'All'} mode={'multiple'} {...props} formId={moduleFormId} />
        )
      },
      {
        inputType: 'text',
        label: l('ticket__office__label'),
        field: 'customer_office__name',
        lookup: 'icontains'
      },
      {
        inputType: 'text',
        label: l('ticket__contact__label'),
        field: 'customer__name',
        getQuery: (value, field) =>
          value ? [`customer__first_name__icontains|${value}`, `customer__last_name__icontains|${value}`, 'or_c'] : []
      },
      {
        inputType: 'text',
        label: l('ticket__location__label'),
        field: 'location__name',
        lookup: 'icontains'
      },
      {
        inputType: 'text',
        label: l('ticket__afe__label'),
        field: 'afe__user_afe_no',
        lookup: 'icontains'
      },
      {
        inputType: 'text',
        label: l('ticket__job_code__label'),
        field: 'job_code__user_job_code_no',
        lookup: 'icontains'
      },
      {
        inputType: 'date-range',
        label: l('ticket__timepoint_due__label'),
        field: 'timepoint_due__date',
        lookup: 'range'
      },
      {
        inputType: 'date-range',
        label: l('ticket__modified_at__label'),
        field: 'modified_at__date',
        lookup: 'range'
      },
      {
        inputType: 'date-range',
        label: l('ticket__created_at__label'),
        field: 'created_at__date',
        lookup: 'range'
      },
      {
        inputType: 'render',
        inputRender: (props: any) => (
          <UserSelect placeholder={'All'} mode={'multiple'} apiValueBy={'id'} {...(props as any)} />
        ),
        label: 'Team Member',
        field: 'contacts__contact_id',
        lookup: 'in'
      },
      // Add hidden reference fields, if it has company level label
      ...TICKET_HIDDEN_REFERENCES.filter((hr) =>
        Object.keys(companyLabels).includes(`ticket__user_data__${hr.field}__label`)
      ).map(
        (hr): QueryFieldOptionalKey =>
          hr.field.startsWith('user_datetime')
            ? {
                inputType: 'date-range',
                label: l(`ticket__user_data__${hr.field}__label`),
                field: hr.field_verbose + '__date',
                lookup: 'range'
              }
            : {
                inputType: 'text',
                label: l(`ticket__user_data__${hr.field}__label`),
                field: hr.field_verbose,
                lookup: 'icontains'
              }
      )
    ],
    [companyLabels, customForms, l, moduleFormId]
  )

  const filterFields: QueryFieldOptionalKey[] = useMemo(() => {
    const searchProps = module?.data?.search_props
    const propFields: QueryFieldOptionalKey[] = isEmpty(searchProps)
      ? []
      : customForms
          .map(({ properties }) => properties)
          .flat()
          .filter(({ id }) => searchProps?.includes(id))
          .map(({ id, name, key }) => {
            // support for cross custom form ticket properties search
            const relatedPropertyIds = getAllPropertiesByKey(key)
              .map(({ id }) => id)
              .join(',')

            return {
              inputType: 'text',
              label: `P: ${name}`,
              field: `p${id}`,
              lookup: 'icontains',
              getQuery: (value, field) => {
                return value
                  ? [
                      // fixme: filter by multiple properties at once doesn't work
                      `ticket_properties__property_id__in|${relatedPropertyIds}`,
                      `ticket_properties__value__${field.lookup || 'icontains'}|${value}`,
                      'and'

                      // NOTE: adapted from angular code not sure if this is needed
                      // disabled for now
                      // HACK: to make pair query work on second place
                      // 'id__isnull|0',
                      // 'and_c' // getFilterQuery adds it at the end
                    ]
                  : []
              }
            }
          })

    return [...defaultFields, ...propFields]
  }, [customForms, defaultFields, getAllPropertiesByKey, module?.data?.search_props])

  const filterService = useQueryFilterService({ fields: filterFields, search: false, filter: true })
  const filter = filterService.filter

  const selectedStatus = filter.status || null
  const setSelectedStatus = (value: string) => filterService.setFilter({ status: value })

  const includeArchived = Boolean(filterService.filter?.archived)
  const setIncludeArchived = (value: boolean) => filterService.setFilter({ archived: value })

  return {
    filterFields,
    filterService,
    selectedStatus,
    setSelectedStatus,
    includeArchived,
    setIncludeArchived,
    setFilter: filterService.setFilter,
    getFilterParams: filterService.getFilterQuery
  }
}
