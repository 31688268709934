import { LINE_ITEM_FIELDS } from '@/constants/general'
import { useUserDataQuery } from '@/hooks'
import { Input, Select } from 'antd'

type Props = {
  fieldItem: (typeof LINE_ITEM_FIELDS)[number]
  lineItem: any
  onChange: (value: any) => void
  disabled?: boolean
}

// Component for user_text_... fields. With dynamic options fetched from API for Select component.
export const UserTextField = ({ fieldItem, lineItem, onChange, disabled }: Props) => {
  const userDataQuery = useUserDataQuery({ equipment: lineItem })
  const value = lineItem[fieldItem.field]
  const fieldItemNo = fieldItem.field.split('_')[2] // user_text_1 -> 1
  const stringSourceKey = `string_${fieldItemNo}_source` // string_{number}_source
  const stringSource = lineItem[stringSourceKey]

  const userData = userDataQuery.data?.items.find((item) => item.id === stringSource)
  const options = userData ? userData.content.map((item) => ({ label: item, value: item })) : []

  const handleUserTextValueChange = (value: string | string[]) => {
    if (Array.isArray(value)) {
      value = value.join(',')
    }

    onChange({ [fieldItem.field]: value })
  }

  if (!userData) {
    return <Input value={value} onChange={(e) => handleUserTextValueChange(e.target.value)} />
  }

  return (
    <Select
      loading={userDataQuery.isLoading}
      options={options}
      value={userData?.multiselect ? value?.split(',') : value}
      onChange={handleUserTextValueChange}
      placeholder="Select"
      mode={userData?.multiselect ? 'multiple' : undefined}
      popupMatchSelectWidth={false}
      disabled={disabled}
      data-cy="custom-form-line-item-user-text-select"
    />
  )
}
