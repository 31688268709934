import { Contact } from '@/components/contact-select'
import { useApp } from '@/hooks'
import { PT } from '@/modules/custom-form/constants'
import { contactApi, secureAccessApi } from '@/services/api-service'
import { Button, QuerySelect, Select } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { FC, useCallback, useMemo } from 'react'
import { SECURE_ACCESS_TYPES } from '../../../../../constants'
import {
  useCustomForm,
  useTicket,
  useTicketCustomer,
  useTicketCustomerOffice,
  useTicketFormValues
} from '../../../../../hooks'
import { ContactSchema, Property } from '../../../../../schemas'
import { Label } from '../../../../ui'

type Props = {
  type: keyof typeof SECURE_ACCESS_TYPES
  onSubmit?: (access: any) => void
  onCancel?: () => void
}

const formatOption = (item: Contact) => ({
  value: item.id,
  label: `${item.first_name} ${item.last_name}`
})

export const EmailSecureAccessForm: FC<Props> = ({ type, onSubmit, onCancel }) => {
  const isSignature = type === 'S'
  const title = isSignature ? 'Email For Signature' : 'Sent To Vendor'

  const [form] = Form.useForm()

  const { notification } = useApp()

  const { ticket } = useTicket()
  const customer = useTicketCustomer()
  const { getPropertyValueById } = useTicketFormValues()

  const { customForm } = useCustomForm()
  const customerOffice = useTicketCustomerOffice()
  const { _propertiesByTypeId: byType, _propertyByKey: byKey } = customForm

  const secureAccessMutation = useMutation(secureAccessApi.create())

  const searchQuery = (text?: string) => {
    // custom contact search
    const config = customForm._features.contactSearch
    const q = [...((config?.enabled && config?.company && ['company|' + config.company]) || [])]

    // customer office search
    const customerCompanyId = customerOffice?.company_id
    if (customerCompanyId) {
      q.push('company|' + customerCompanyId)
      if (q.length > 1) q.push('or')
    }

    return { 'Q[]': q, search: text }
  }

  const [defaultProperty, propertiesOptions] = useMemo(() => {
    let _properties = []
    let _default: Property | null = null

    if (isSignature) {
      _properties = byType[PT.Signature] || []
      _default = byKey['Customer Signature'] || _properties[0]
    } else {
      _properties = [...(byType[PT.String] || []), ...(byType[PT.MultilineString] || [])]
      _default = byKey['Instructions'] || _properties[0]
    }

    return [_default?.id || null, _properties.map((p) => ({ label: p.name, value: p.id }))]
  }, [isSignature, byType, byKey])

  const defaultMessage = useMemo(
    () => ticket._tpByPropertyId[defaultProperty || 0]?.value || '',
    [defaultProperty, ticket._tpByPropertyId]
  )

  const sendEmail = useCallback(async () => {
    const { property, mail_message, add_contact_ids } = await form.validateFields()

    try {
      const access = await Promise.all(
        [customer?.id, ...(add_contact_ids || [])]
          .filter((contactId) => contactId)
          .map((contactId) =>
            secureAccessMutation.mutateAsync({
              type,
              property,
              contact: contactId,
              ticket: ticket.id,
              mail_message: mail_message || ''
            })
          )
      )

      notification.success({
        message: `${title} Sent`,
        description: 'The email has been sent.'
      })
      onSubmit?.(access)
    } catch (error: any) {
      const errorDescription = error?.response?.data?.non_field_errors[0] || error.toString()

      notification.error({
        message: `${title} Error`,
        description: errorDescription
      })
    }
  }, [customer?.id, form, notification, onSubmit, secureAccessMutation, ticket.id, title, type])

  const onValuesChange = useCallback(
    (changedValues: any) => {
      if ('property' in changedValues) {
        const property = customForm._propertyById[changedValues.property]
        const value = getPropertyValueById(property?.id) || ''

        form.setFieldsValue({ mail_message: value })
      }
    },
    [customForm._propertyById, form, getPropertyValueById]
  )

  return (
    <Form
      className={'mt-10'}
      form={form}
      layout="vertical"
      onValuesChange={onValuesChange}
      initialValues={{
        property: defaultProperty,
        mail_message: defaultMessage
      }}
    >
      <Form.Item label={<Label k={'ticket__contact__label'} />}>
        <Input value={`${customer?.first_name} ${customer?.last_name} (${customer?.email})`} disabled />
      </Form.Item>

      <Form.Item label="Email Additional Contacts" name="add_contact_ids">
        <QuerySelect
          mode={'multiple'}
          placeholder={'Search'}
          className={'w-full h-full'}
          apiEndpoint={contactApi.list}
          apiQueryParams={{
            fields: ContactSchema,
            order: 'first_name',
            E: [`id__in|${customer?.id}`]
          }}
          apiSearchBy={searchQuery}
          renderOption={formatOption}
          onChange={(value) => form.setFieldValue('add_contact_ids', value)}
        />
      </Form.Item>

      <Form.Item label="Signature Property" name="property" rules={[{ required: true }]}>
        <Select options={propertiesOptions} />
      </Form.Item>

      {!isSignature && (
        <Form.Item label="Message" name="mail_message">
          <Input.TextArea />
        </Form.Item>
      )}

      <div className={'flex justify-end gap-10'}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={sendEmail} loading={secureAccessMutation.isLoading}>
          Send Email
        </Button>
      </div>
    </Form>
  )
}
