import { APP_RELEASE, SENTRY_DNS } from '@/constants/app'
import { AuthUser } from '@/types/auth-user'
import * as Sentry from '@sentry/react'
import { formatSessionUser } from './helpers'

export const sentry = {
  dns: SENTRY_DNS,
  appRelease: APP_RELEASE,
  currentID: null as number | null,
  enabled() {
    return Boolean(this.dns)
  },
  init() {
    if (!this.enabled()) return false // disabled

    try {
      Sentry.init({ dsn: this.dns, release: this.appRelease, integrations: [] })

      return true
    } catch (err) {
      console.error('Sentry: Failed to initialize', err)
      return false
    }
  },
  identify(user: AuthUser) {
    if (!this.enabled() || this.currentID == user.id) return false // disabled or already identified

    try {
      Sentry.setUser(formatSessionUser(user))
      this.currentID = user.id
      return true
    } catch (err) {
      console.error('Sentry: Failed to identify user', err)
      return false
    }
  }
}
