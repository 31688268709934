import { DATE_TIME_FORMAT } from '@/constants/date'
import { useApp } from '@/hooks'
import { regionApi } from '@/services/api-service'
import { Button, Modal, PageTitle, QueryDeleteButton, QueryTable, SearchInput } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, Space } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export const AllRegionsView = () => {
  const [editingRegion, setEditingRegion] = useState<any>(null)
  const updateRegionMutation = useMutation(regionApi.update())
  const createRegionMutation = useMutation(regionApi.create())
  const [form] = Form.useForm()
  const { notification } = useApp()

  useEffect(() => {
    form.setFieldValue('name', editingRegion?.name || '')
  }, [editingRegion, form])

  const handleSave = async () => {
    const { name } = await form.validateFields()
    if (editingRegion.id) {
      await updateRegionMutation.mutateAsync({ name, id: editingRegion.id })
    } else {
      await createRegionMutation.mutateAsync({ name })
    }

    notification.success({ message: 'Region saved' })
    setEditingRegion(null)
  }

  return (
    <div>
      <PageTitle>Regions</PageTitle>
      <div className="flex items-center justify-between my-16">
        <SearchInput />
        <Button type="primary" onClick={() => setEditingRegion({})}>
          Add Region
        </Button>
      </div>
      <QueryTable
        queryApi={regionApi.list}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Last Modified',
            dataIndex: 'modifed_at',
            render: (date) => dayjs(date).format(DATE_TIME_FORMAT)
          },
          {
            dataIndex: 'id',
            align: 'right',
            render: (id, region) => (
              <Space>
                <QueryDeleteButton api={regionApi.delete} id={id} />
                <Button type="text" iconName="fa:edit" onClick={() => setEditingRegion(region)} />
              </Space>
            )
          }
        ]}
      />
      <Modal
        open={!!editingRegion}
        title={editingRegion?.id ? 'Edit Region' : 'Add Region'}
        onCancel={() => setEditingRegion(null)}
        okText="Save"
        onOk={handleSave}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="Region Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
