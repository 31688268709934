import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Input } from 'antd'
import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import { currentRuleTemplateAtom, currentRuleTemplateCombinedRulesAtom, isAddModeAtom } from '../atoms'

export const RuleDisplay = () => {
  const isAddMode = useAtomValue(isAddModeAtom)
  const currentRuleTemplate = useAtomValue(currentRuleTemplateAtom)
  const currentRuleTemplateCombinedRules = useAtomValue(currentRuleTemplateCombinedRulesAtom)
  const setIsAddMode = useSetAtom(isAddModeAtom)

  if (!currentRuleTemplate) {
    return null
  }

  const handleEditClick = () => {
    setIsAddMode(true)
  }

  const { comment, name } = currentRuleTemplate

  if (isAddMode) {
    return null
  }

  return (
    <div className="h-full flex flex-col w-[300px]">
      <div className="mb-4">Rule display</div>
      <div className="h-full p-12 rounded-md border border-solid border-gray-300 h-rule-template-column overflow-auto">
        <div className="flex">
          <Button
            className="ml-auto"
            icon={<Icon name="fa:edit" />}
            onClick={handleEditClick}
            data-cy="edit-rule-template"
          >
            Edit
          </Button>
        </div>
        <div className="mb-2 font-semibold">Name</div>
        <Input value={name} disabled />
        {comment && <div className="my-8">{comment}</div>}
        <ul className="p-0 m-0 mt-16 space-y-6">
          {currentRuleTemplateCombinedRules.map((rule, index) => (
            <li
              key={index}
              className={classNames('rounded-md flex justify-center items-center h-30 list-none', {
                'bg-green text-white': rule === 'day_on',
                'bg-gray-200': rule === 'day_off'
              })}
              data-cy={`rule-${index + 1}-${rule === 'day_off' ? 'day_off' : 'day_on'}`}
            >
              {index + 1} {rule === 'day_off' ? 'Day Off' : 'Day On'}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
