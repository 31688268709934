import { useCallback } from 'react'
import { useTicketFormContext } from '../context'

export const useTicket = () => {
  const { ticket } = useTicketFormContext()

  const getValueById = useCallback((propertyId: number) => ticket._tpByPropertyId[propertyId]?.value, [ticket])
  const getValueByKey = useCallback((propertyKey: string) => ticket._tpByPropertyKey[propertyKey]?.value, [ticket])

  return { ticket, isNew: ticket._isNew, getValueById, getValueByKey }
}
