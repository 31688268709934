import { useApp } from '@/hooks'
import { jasSchedulePatchApi } from '@/services/api-service'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { useMutation } from '@tanstack/react-query'
import { Dayjs } from 'dayjs'

type Params = {
  onSuccess?: () => Promise<any>
}

export const useDeletePatchMutation = ({ onSuccess }: Params) => {
  const { notification } = useApp()
  const deletePatchMutation = useMutation(jasSchedulePatchApi.delete())

  const handleDeletePatchMutation = async (data: JasResourceUseQueryResponse, dates: Dayjs[]) => {
    const promises = dates.map((date) => {
      const patch = data.patches.find((patch) => patch.start_date === date.format('YYYY-MM-DD'))

      if (!patch) {
        notification.error({
          message: 'Only patches can be deleted'
        })
        return
      }

      return deletePatchMutation.mutateAsync({
        id: patch.id
      })
    })

    await Promise.all(promises)
    await (onSuccess && onSuccess())
    notification.success({
      message: dates.length > 1 ? 'Patches deleted' : 'Patch deleted'
    })
  }

  return {
    deletePatchMutation,
    handleDeletePatchMutation
  }
}
