import { AppLogo, ServerSelect } from '@/components'
import { DarkModeToggle } from '@/components/dark-mode-toggle'
import { useSession } from '@/hooks'
import { useRedirect } from '@/hooks/use-redirect'
import { navigate } from '@/routing/helpers'
import { FC, ReactNode } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

type Props = {
  children?: ReactNode
}

export const AuthLayout: FC<Props> = ({ children }) => {
  const { isRedirecting } = useRedirect()
  const { isLoggedIn } = useSession()
  const [searchParams] = useSearchParams()

  // do not render anything if redirecting
  if (isRedirecting) return <></>

  // redirect (reload) to home if user is already logged in
  if (isLoggedIn) {
    navigate(searchParams.get('to') || '/v2', { reload: true })
    return <></>
  }

  return (
    <div className="h-screen w-screen grid place-items-center bg-dark-container">
      <div className="flex-1 flex flex-col gap-10 xl:w-3/12 lg:w-4/12 md:w-5/12 sm:w-8/12 xs:w-10/12 ">
        <div className="flex justify-center mb-16">
          <AppLogo height={'50'} />
        </div>
        <div className="relative bg-container dark:bg-transparent border dark:border-dark-border p-28 rounded-lg shadow">
          <div className={'absolute right-10 top-10'}>
            <div className={'flex items-center gap-6'}>
              <ServerSelect />
              <DarkModeToggle />
            </div>
          </div>
          {children ? children : <Outlet />}
        </div>
      </div>
    </div>
  )
}
