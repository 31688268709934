import { ActivityLogsModal } from '@/components/activity-logs-modal'
import { RecentActivities } from '@/components/recent-activities'
import { ViewAlerts } from '@/modules/ticket/form/components/recent-activity/view-alerts/ViewAlerts'
import { eventApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useTicket, useTicketFlags } from '../../hooks'

export const RecentActivity = () => {
  const { ticket } = useTicket()
  const { hideActivityLog } = useTicketFlags()

  const eventsParams = { type: 'ticket', target: ticket.id }
  const eventsQuery = useQuery({
    ...eventApi.list({ ...eventsParams, limit: 10 }),
    enabled: !hideActivityLog
  })

  if (hideActivityLog) return null

  return (
    <div>
      <h4>Recent Activity</h4>
      <div className={'flex flex-row items-center gap-10'}>
        <ActivityLogsModal query={eventApi.list} queryVariables={eventsParams} />
        <ViewAlerts />
      </div>

      <div className="mt-16">
        <RecentActivities events={eventsQuery.data?.items} isLoading={eventsQuery.isLoading} />
      </div>
    </div>
  )
}
