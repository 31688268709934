import { NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { ListView } from '@/layouts/views'
import { ViewTicketLinkV2 } from '@/modules/ticket/components'
import { notificationApiV2 } from '@/services/api-service'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { useState } from 'react'
import { Filters } from './constants'
import { FiltersModal } from './filters-modal'
import { getMoreInfo } from './utils/getMoreInfo'

export const NotificationsActivityView = () => {
  const [filters, setFilters] = useState<Partial<Filters>>({})

  return (
    <ListView
      header={{ refresh: true, title: 'Notifications' }}
      filter={{
        render: () => <FiltersModal onApplyFilters={setFilters} />
      }}
      table={{
        queryApi: notificationApiV2.list,
        queryParams: {
          order: '-modified_at',
          ...filters
        },
        expandable: {
          expandedRowRender: (n) => {
            const items = getMoreInfo(n)
            return (
              <div className="pl-12">
                {items
                  .filter((item) => item.renderIf.includes(n.medium) && !item.hide)
                  .map((item, index) => (
                    <div key={index} className="flex border-b border-gray-100 px-2 py-4">
                      <div className="font-bold w-[140px]">{item.label}:</div>
                      <div>{item.data}</div>
                    </div>
                  ))}

                <div className="flex border-b border-gray-100 px-2 py-4">
                  <div className="font-bold w-[140px]">Content:</div>
                  {n.medium === 'S' && (
                    <div className="whitespace-pre-wrap p-12 m-8 max-w-[400px] bg-white border border-dashed">
                      {n.content}
                    </div>
                  )}
                  {n.medium === 'E' && (
                    <div
                      className="max-w-[700px] p-12 m-8 bg-white border border-dashed"
                      dangerouslySetInnerHTML={{ __html: n.content || '' }}
                    />
                  )}
                </div>
              </div>
            )
          }
        },
        rowKey: 'id',
        columns: [
          {
            title: 'ID',
            render: (n) =>
              `${NOTIFICATION_TEMPLATE_TYPE[n.medium as keyof typeof NOTIFICATION_TEMPLATE_TYPE]} / ${n.id}`
          },
          {
            dataIndex: 'modified_at',
            title: 'Processed',
            render: (date) => dayjs.formatLocal(date)
          },
          {
            dataIndex: 'data',
            title: 'Type',
            render: (data) => {
              if (data.class === 'SystemNotificationService' || data.class === 'SecureAccessService') {
                return startCase(data.type)
              }

              if (data.class === 'AlertService') {
                return (
                  <div className="flex flex-col">
                    <span>Ticket Alert /</span>
                    <span>{data.alert_name}</span>
                  </div>
                )
              }

              if (data.class === 'CustomEmailService') {
                return (
                  <div className="flex flex-col">
                    <span>Custom Email /</span>
                    <span>{data.custom_email__name}</span>
                  </div>
                )
              }

              if (data.class === 'ScheduledReportService') {
                return 'Scheduled Report'
              }
            }
          },
          {
            title: 'Ticket',
            sorter: false,
            render: (_, n) => {
              if (n.target_type__model !== 'ticket') {
                return null
              }

              return (
                <div>
                  <ViewTicketLinkV2 ticketId={n.target.id} formId={0}>
                    {n.target.id}:<br />
                    {n.target.custom_form__name}
                    <br />#{n.target.name}
                  </ViewTicketLinkV2>
                </div>
              )
            }
          },
          {
            title: 'Contact',
            sorter: false,
            render: (_, n) =>
              n.contact?.display_name ? (
                <div className="flex flex-col">
                  <span>{n.contact.display_name}</span>
                  <small className="color-gray">{n.contact.company__name}</small>
                </div>
              ) : null,
            dataIndex: ['contact', 'display_name']
          },
          {
            title: 'Email / Phone Number',
            render: (_, n) =>
              n.medium === 'S'
                ? n.contact?.phone_number
                : (n.data.EmailTo || '').split(',').map((email: string) => (
                    <span key={email}>
                      {email} <b />
                    </span>
                  ))
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
              if (status === 'E') {
                return 'Error'
              }

              if (status === 'Q') {
                return 'Queued'
              }

              return 'Success'
            }
          }
        ]
      }}
    />
  )
}
