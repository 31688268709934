import { useModalState } from '@/hooks'
import { Button, Modal } from '@/ui'
import { FC } from 'react'
import { useButtonContext } from '../../hooks'
import { VarsType } from './types'

export const ExampleButton: FC = () => {
  const { vars, property, buttonProps } = useButtonContext<VarsType>()
  const { var1, var2 } = vars
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <Button onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps}>
        {buttonProps?.children ? buttonProps.children : property.name}
      </Button>
      {isRender && (
        <Modal
          open={isOpen}
          width={500}
          title={'Example Modal'}
          iconName={'fa:info-circle'}
          onCancel={closeModal}
          onOk={closeModal}
        >
          <p className={'p-10 text-center'}>
            This is an example modal opened using scripted button. <br /> This can be used as a base for new buttons.
            <div>
              {var1 && <div>Var1: {var1}</div>}
              {var2 && <div>Var2: {var2}</div>}
            </div>
          </p>
        </Modal>
      )}
    </>
  )
}
