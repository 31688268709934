import { useFormWatch, useModalState } from '@/hooks'
import { refreshQueries } from '@/query'
import { Modal } from '@/ui'
import { Form, Splitter } from 'antd'
import { ReactElement } from 'react'
import { ImportLogsCard } from './ImportLogsCard'
import { ImportTemplatesCard } from './ImportTemplatesCard'
import { TemplateForm } from './TemplateForm'

type Props = {
  children?: ReactElement
}

export function TicketImports({ children }: Props) {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  return (
    <div>
      <div onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal}>
        {children}
      </div>

      {isRender && (
        <Modal
          iconName={'fa:file-spreadsheet'}
          open={isOpen}
          title="Bulk Ticket Imports"
          onCancel={closeModal}
          width={'calc(100vw - 20%)'}
          withScreenMaxHeight={true}
          styles={{ body: { padding: '0' } }}
          footer={null}
          afterClose={() => {
            refreshQueries(['v2/tickets', 'tickets'])
          }}
        >
          <TicketImportsModalContent />
        </Modal>
      )}
    </div>
  )
}

function TicketImportsModalContent() {
  const [form] = Form.useForm()
  const mode = useFormWatch('mode', form)
  const key = useFormWatch('key', form)

  return (
    <Form form={form} initialValues={{ mode: 'logs-list', key: null }} layout={'vertical'} component={'div'}>
      <Splitter style={{ height: 600 }} className={'flex flex-row gap-10'}>
        <Splitter.Panel defaultSize="40%" min="30%" max="60%">
          <ImportTemplatesCard />
        </Splitter.Panel>
        <Splitter.Panel>
          {mode === 'logs-list' && <ImportLogsCard />}
          {mode === 'template-form' && (
            <TemplateForm
              key={key}
              onClose={() => form.resetFields()}
              initialValues={form.getFieldValue(`data${key || 'New'}`)}
            />
          )}
        </Splitter.Panel>
      </Splitter>
    </Form>
  )
}
