import { LINE_ITEM_FIELDS } from '@/constants/general'
import { CategoryKindField } from '@/types/general'
import { Component } from '../types'
import { AmountField } from './amount-field'
import { AssigneeField } from './assignee-field'
import { DynamicField } from './dynamic-field'
import { UserTextField } from './user-text-field'

type Props = {
  field: CategoryKindField
  component: Component
}

export const SubComponent = ({ field, component }: Props) => {
  const fieldItem = LINE_ITEM_FIELDS.find((item) => item.field === field)

  if (!fieldItem) {
    console.warn(`Field item ${fieldItem} not found in LINE_ITEM_FIELDS`)
    return null
  }

  if (field === 'assignee') {
    return <AssigneeField fieldItem={fieldItem} component={component} />
  }

  // user_text_... fields should be Select component with dynamic options fetched from API
  if (field.startsWith('user_text')) {
    return <UserTextField fieldItem={fieldItem} component={component} />
  }

  // amount field is calculated based on other fields
  if (field === 'amount') {
    return <AmountField component={component} />
  }

  return <DynamicField fieldItem={fieldItem} component={component} />
}
