import { view } from '@/routing'
import { Alert, Tabs, TabsProps } from 'antd'
import { useParams } from 'react-router-dom'
import { AccessAndSubscriptions } from './access-and-subscriptions'
import { Defaults } from './defaults'
import { Notification } from './notification'

const items: TabsProps['items'] = [
  {
    key: 'notification',
    label: 'Notification',
    children: <Notification />
  },
  {
    key: 'access-and-subscriptions',
    label: 'Access and Subscriptions',
    children: <AccessAndSubscriptions />
  },
  {
    key: 'defaults',
    label: 'Defaults',
    children: <Defaults />
  }
]

export const AccessAndAlerts = view(Component, {
  title: () => 'Access & Alerts',
  scoped: false
})

function Component() {
  const { id: customFormId } = useParams()

  if (!customFormId) {
    return <Alert description="Please save new custom form to manage access and alerts" type="warning" showIcon />
  }

  return (
    <div>
      <Tabs items={items} />
    </div>
  )
}
