import { FC, useMemo } from 'react'
import { CurrentKind } from '../../../hooks'
import { Category, ItemColumnType, LineItem } from '../../../schemas'
import { CustomTable } from './custom-table'
import { ItemActions } from './item-actions'
import { ItemCell } from './item-cell'

type Props = {
  category: Category
  noCategoryFilter?: boolean
  kind: CurrentKind
  groupNo: number | null
  groupItems: Partial<LineItem>[]
}

export const ItemsTable: FC<Props> = (props) => {
  const { groupItems, kind } = props

  const columns = useMemo(() => {
    const _columns: ItemColumnType[] = kind.fieldsConfig
      .filter((f) => !f.hidden)
      .map((f, idx) => ({
        config: f,
        key: f.field,
        title: f.label,
        className: 'max-w-[200px]',
        render: (_, record) => <ItemCell lineItem={record} config={f} />
      }))

    _columns.push({
      key: 'actions',
      fixed: 'right',
      className: 'sticky right-0 !w-[55px]',
      render: (record) => <ItemActions record={record} />
    })

    return _columns
  }, [kind.fieldsConfig])

  return <CustomTable columns={columns} groupItems={groupItems} props={props} />
}
