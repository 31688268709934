import { alertApi } from '@/services/api-service'
import { Select } from '@/ui/select'
import { useQuery } from '@tanstack/react-query'
import { SelectProps } from 'antd'
import { FC, useMemo } from 'react'
import { z } from 'zod'

export type AlertSelectProps = SelectProps & {
  formId?: number | null
}

const AlertSchema = z.object({
  id: z.number(),
  name: z.string()
})

export const AlertSelect: FC<AlertSelectProps> = ({ formId, ...props }) => {
  const query = useQuery(
    alertApi.list({
      limit: 1000,
      fields: AlertSchema,
      custom_form_id: formId
    })
  )

  const options = useMemo(
    () =>
      query.data?.items.map((property) => ({
        value: property.id,
        label: property.name
      })),
    [query.data?.items]
  )

  return <Select options={options} {...props} />
}
