import { useApp } from '@/hooks'
import { jasScheduleRuleTemplateApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { toggleDeleteModeAtom } from '../atoms'
import { useSortedRuleTemplates } from './use-sorted-rule-templates'

export const useDeleteRuleTemplate = () => {
  const { notification } = useApp()
  const { ruleTemplatesQuery } = useSortedRuleTemplates()
  const toggleDeleteMode = useSetAtom(toggleDeleteModeAtom)

  const deleteRuleTemplateMutation = useMutation({
    mutationFn: jasScheduleRuleTemplateApi.delete().mutationFn,
    onSuccess: async () => {
      await ruleTemplatesQuery.refetch()
      toggleDeleteMode()

      notification.success({
        message: 'Rule template deleted successfully'
      })
    },
    onError: () => {
      notification.error({
        message: 'Failed to delete rule template'
      })
    }
  })

  const deleteRuleTemplate = async (id: number) => {
    await deleteRuleTemplateMutation.mutateAsync({ id })
  }

  return {
    deleteRuleTemplate,
    deleteRuleTemplateMutation
  }
}
