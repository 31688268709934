import { PROGRAMMED_REPORT_TYPES } from '@/constants/general'
import { useApp } from '@/hooks'
import { companyApi, programmedReportApi } from '@/services/api-service'
import { Modal, QuerySelect } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Alert, Checkbox, Form, Input } from 'antd'
import { useState } from 'react'
import { ProgrammedReport } from '../../schemas'

type Props = {
  programmedReport: ProgrammedReport
  onCancel: () => void
}

export const CopyReportModal = ({ programmedReport, onCancel }: Props) => {
  const [form] = Form.useForm()
  const [isChangeCompany, setIsChangeCompany] = useState(false)
  const copyProgrammedReportMutation = useMutation(programmedReportApi.create(undefined, `${programmedReport.id}/copy`))
  const { notification } = useApp()
  const { id, report__description, report__name, type } = programmedReport

  const handleCopyProgrammedReport = async () => {
    const values = await form.validateFields()
    await copyProgrammedReportMutation.mutateAsync({ ...values, id })
    notification.success({ message: 'Report copied' })
    onCancel()
  }

  return (
    <Modal
      open
      title={`Copy ${PROGRAMMED_REPORT_TYPES[type as keyof typeof PROGRAMMED_REPORT_TYPES]} Report`}
      okText="Copy"
      onCancel={onCancel}
      onOk={handleCopyProgrammedReport}
    >
      <Form
        layout="vertical"
        initialValues={{
          new_name: report__name,
          new_description: report__description,
          copy_base_reports: true,
          copy_scripts: true,
          new_company: null
        }}
      >
        <Form.Item name="new_name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="new_description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item noStyle>
          <Checkbox value={isChangeCompany} onChange={(e) => setIsChangeCompany(e.target.checked)}>
            Change Company
          </Checkbox>
        </Form.Item>
        {type === 'E' && (
          <div className="flex gap-x-18 mt-24">
            <Form.Item noStyle name="copy_base_reports" valuePropName="checked">
              <Checkbox>Copy Base Reports</Checkbox>
            </Form.Item>
            <Form.Item noStyle name="copy_scripts" valuePropName="checked">
              <Checkbox>Copy Scripts</Checkbox>
            </Form.Item>
          </div>
        )}
        {isChangeCompany && (
          <>
            <Form.Item label="New Company" name="new_company" className="mt-24">
              <QuerySelect
                apiSearchBy="name"
                apiEndpoint={companyApi.list}
                renderOption={(item) => ({ label: item.name, value: item.id })}
              />
            </Form.Item>
            <Alert
              type="warning"
              message="Note: Be sure to manually adjust custom forms and field selections of base report according to changed company"
            />
          </>
        )}
      </Form>
    </Modal>
  )
}
