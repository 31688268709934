import { Button, Icon } from '@/ui'
import { Space, Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  isAddModeAtom,
  isDeleteModeAtom,
  sortDirectionAtom,
  toggleDeleteModeAtom,
  toggleSortDirectionAtom
} from '../atoms'
import { useSortedRuleTemplates } from '../hooks'
import { AddNewButton } from './add-new-button'
import { ListButton } from './list-button'
import { RuleSearch } from './rule-search'

export const RulesList = () => {
  const { ruleTemplatesQuery, sortedRuleTemplates } = useSortedRuleTemplates()
  const isDeleteMode = useAtomValue(isDeleteModeAtom)
  const isAddMode = useAtomValue(isAddModeAtom)
  const sortDirection = useAtomValue(sortDirectionAtom)
  const toggleDeleteMode = useSetAtom(toggleDeleteModeAtom)
  const toggleSortDirection = useSetAtom(toggleSortDirectionAtom)

  if (isAddMode) {
    return null
  }

  return (
    <div className="w-[320px] flex flex-col items-start">
      <div className="mb-4">All created rules</div>
      <div className="w-full rounded-md border border-solid h-rule-template-column overflow-auto border-gray-300">
        <div className="p-12">
          <RuleSearch />
        </div>
        <div className="border-0 border-b border-solid border-gray-300" />
        <div className="p-12">
          <div className="flex mb-12 justify-between">
            <Button
              iconName={sortDirection === 'asc' ? 'fa:sort-alpha-up' : 'fa:sort-alpha-down'}
              onClick={() => toggleSortDirection()}
              data-cy="sort-direction-rule-templates-list"
            />
            <Space>
              {isDeleteMode ? (
                <Button onClick={() => toggleDeleteMode()} data-cy="cancel-delete-rule-template">
                  Cancel
                </Button>
              ) : (
                <Button icon={<Icon name="fa:edit" />} onClick={() => toggleDeleteMode()} data-cy="edit-rule-templates">
                  Edit
                </Button>
              )}
              <AddNewButton />
            </Space>
          </div>
          <Spin spinning={ruleTemplatesQuery.isLoading}>
            <ul className="space-y-12 p-0 m-0 min-h-[200px]">
              {sortedRuleTemplates.map((ruleTemplate) => (
                <li key={ruleTemplate.id} className="list-none">
                  <ListButton ruleTemplate={ruleTemplate} />
                </li>
              ))}
            </ul>
          </Spin>
        </div>
      </div>
    </div>
  )
}
