import { CodeEditor } from '@/components/code-editor'
import { view } from '@/routing'
import { Form } from 'antd'

export const AdvancedTab = view(Component, {
  title: () => 'Advanced'
})

function Component() {
  return (
    <Form.Item name="config_json">
      <CodeEditor mode="json" />
    </Form.Item>
  )
}
