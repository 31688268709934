import { CodeEditor } from '@/components/code-editor'
import { Button, Popconfirm, Select } from '@/ui'
import { UseSortable } from '@/ui/sortable'
import { cn } from '@/utils'
import { Form, Input } from 'antd'
import { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BPA_VARIABLE_TYPES_LIST } from '../../constants'
import { useVariablesHandlers } from '../../hooks'
import { BpaVariable } from '../../schemas'

type Props = {
  variable: BpaVariable
  variableIndex: number
  sortable?: UseSortable
}

export const VariableInputs: FC<Props> = ({ variable, variableIndex, sortable }) => {
  const { onDeleteVariable, onFieldChange } = useVariablesHandlers()

  const onFieldChangeDebounced = useDebouncedCallback(onFieldChange, 250)

  return (
    <div
      className={cn('flex flex-row gap-10 items-center p-8 pb-0', 'rounded-lg border border-transparent', {
        'border-dashed shadow-primary !border-primary-500 ': sortable?.isDragging
      })}
      ref={sortable?.setNodeRef}
      style={sortable?.style}
      {...sortable?.attributes}
    >
      <Form.Item label="Name">
        <Input.TextArea
          data-cy="variable-name-textarea"
          autoSize={{ minRows: 1, maxRows: 10 }}
          defaultValue={variable.name}
          onChange={(e) => onFieldChangeDebounced('name', e.target.value, variableIndex)}
        />
      </Form.Item>
      <Form.Item label="Type">
        <Select
          defaultValue={variable.type}
          options={BPA_VARIABLE_TYPES_LIST}
          data-cy="variable-type-select"
          style={{ width: 100 }}
          onChange={(value) => onFieldChangeDebounced('type', value, variableIndex)}
        />
      </Form.Item>
      <div className="flex-grow">
        {['yaml', 'json'].includes(variable.type) ? (
          <div className="mb-10">
            <CodeEditor
              minLines={3}
              maxLines={25}
              data-cy="variable-value-code-editor"
              key={variable.type} // re-render editor
              defaultValue={variable.value}
              onChange={(value) => onFieldChangeDebounced('value', value, variableIndex)}
              mode={variable.type as 'yaml' | 'json'}
            />
          </div>
        ) : (
          <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]">
            <Input.TextArea
              data-cy="variable-value-textarea"
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={variable.value}
              onChange={(e) => onFieldChangeDebounced('value', e.target.value, variableIndex)}
            />
          </Form.Item>
        )}
      </div>
      <div className="flex flex-row gap-2">
        <Popconfirm
          title="Confirm Delete"
          description="Deleting a variable. Are you sure?"
          onConfirm={(e) => onDeleteVariable(variableIndex)}
          okButtonProps={{
            'data-cy': 'delete-variable-button-confirm'
          }}
        >
          <Button data-cy="delete-variable-button" type="text" size="small" iconName="fa:trash" danger={true} />
        </Popconfirm>
        <Button
          {...sortable?.listeners}
          type={'text'}
          iconName={'fa:grip-dots-vertical'}
          size={'small'}
          className={'cursor-grab'}
          data-cy="drag-variable-button"
        />
      </div>
    </div>
  )
}
