import { z } from 'zod'
import { transformCustomPrintCss } from './utils/transform-custom-print-css'

export const alertSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string().nullish(),
    email_template: z.any().nullish(),
    script: z.string().nullish(),
    custom_form: z.number(),
    conditions: z.any().nullish(),
    email_notification_template: z.number().nullish(),
    sms_notification_template: z.number().nullish(),
    email_notification_template__name: z.string().nullish(),
    sms_notification_template__name: z.string().nullish(),
    alert_type: z.string().nullish(),
    companies: z
      .array(
        z
          .object({
            id: z.number(),
            name: z.string().nullish(),
            icon: z.string().nullish()
          })
          .passthrough()
      )
      .nullish(),
    ticket_statuses: z
      .string()
      .nullish()
      .transform((val) => (val ? val.split(',') : undefined))
  })
  .passthrough()

export const companySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    icon: z.string().nullish(),
    is_preffered: z.boolean().nullish()
  })
  .passthrough()

export const accessRuleSchema = z.object({
  id: z.number(),
  read: z.boolean(),
  write: z.boolean(),
  create: z.boolean(),
  list: z.boolean(),
  company: z.number()
})

const propertiesSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
  key: z.string().nullish()
})

export const customFormSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    type: z.string().nullish(),
    primary_company__name: z.string().nullish(),
    primary_company: z.number().nullish(),
    description: z.string().nullish(),
    icon_url: z.string().nullish(),
    ticket_logo_url: z.string().nullish(),
    companies: z.array(companySchema).nullish(),
    access_rules: z.array(accessRuleSchema).nullish(),
    public: z.boolean().nullish(),
    print_dpi_aware: z.boolean().nullish(),
    line_items: z.array(z.record(z.string(), z.any())).nullish(),
    print_styles: z.any().nullish(),
    default_customer_office: z
      .object({
        id: z.number().nullish(),
        name: z.string().nullish(),
        company: z.number().nullish()
      })
      .nullish(),
    group_line_items: z.boolean(),
    group_by_kind: z.boolean(),
    default_customer_contact: z
      .object({
        id: z.number().nullish(),
        first_name: z.string().nullish(),
        last_name: z.string().nullish(),
        company: z.number().nullish()
      })
      .nullish(),
    default_customer_contact_id: z.number().nullish(),
    default_team_members: z
      .array(z.object({ id: z.number(), first_name: z.string(), last_name: z.string(), title: z.string().nullish() }))
      .nullish(),
    userdata: z
      .object({
        contact_search: z.object({ enabled: z.boolean().nullish(), company: z.number().nullish() }).nullish(),
        existing_lines_only: z
          .object({
            enabled: z.boolean().nullish()
          })
          .nullish(),
        hide_item_fields: z
          .object({
            enabled: z.boolean().nullish(),
            fields: z.array(z.string()).nullish()
          })
          .nullish(),
        restrict_print: z
          .object({
            enabled: z.boolean().nullish(),
            pid: z.number().nullish(),
            statuses: z.array(z.string()).nullish()
          })
          .nullish(),
        sku_search: z
          .object({
            enabled: z.boolean().nullish(),
            fields: z.array(z.string()).nullish()
          })
          .nullish(),
        features: z.record(z.any()).nullish(),
        email_templates: z
          .object({ secure_access_signature: z.string().nullish(), secure_access_vendor: z.string().nullish() })
          .nullish(),
        print: z
          .object({
            enabled: z.boolean().nullish(),
            group_mode: z.string().nullish(),
            default_kind: z.number().nullish(),
            default_options: z
              .object({
                show_internal_use_only: z.boolean()
              })
              .nullish()
          })
          .nullish()
      })
      .passthrough()
      .nullish(),
    userdata_json: z.string().nullish(),
    alerts: z.array(alertSchema).nullish(),
    print_hide_empty_subtotals: z.boolean().nullish(),
    print_landscape: z.boolean().nullish(),
    font_size_pt: z.number().nullish(),
    signature_scale: z.number().nullish(),
    custom_print_css: z.string().nullish().transform(transformCustomPrintCss),
    provider_markup: z.string().nullish(),
    custom_web_css: z.string().nullish(),
    custom_print_js: z.string().nullish(),
    default_group_kind: z.number().nullish(),
    use_form_sequence: z.boolean().nullish(),
    ticket_gen_number_ro: z.number().nullish(),
    property_groups: z
      .array(
        z.object({
          id: z.number(),
          properties: z.array(propertiesSchema).nullish(),
          name: z.string().nullish()
        })
      )
      .nullish()
  })
  .passthrough()
  .transform((val) => {
    return {
      ...val,
      default_customer_contact_id: val.default_customer_contact?.id,
      _contact_search: {
        enabled: val?.userdata?.contact_search?.enabled,
        company: val?.userdata?.contact_search?.company
      },
      _existing_lines_only: {
        enabled: val?.userdata?.existing_lines_only?.enabled
      },
      _sku_search: {
        enabled: val?.userdata?.sku_search?.enabled,
        fields: val?.userdata?.sku_search?.fields
      },
      _print: {
        enabled: val?.userdata?.print?.enabled,
        group_mode: val?.userdata?.print?.group_mode,
        default_kind: val?.userdata?.print?.default_kind,
        default_options: val?.userdata?.print?.default_options
      },
      _restrict_print: {
        enabled: val?.userdata?.restrict_print?.enabled,
        pid: val?.userdata?.restrict_print?.pid,
        statuses: val?.userdata?.restrict_print?.statuses
      },
      _hide_item_fields: {
        enabled: val?.userdata?.hide_item_fields?.enabled,
        fields: val?.userdata?.hide_item_fields?.fields
      },
      _templates: {
        secure_access_signature: val?.userdata?.email_templates?.secure_access_signature,
        secure_access_vendor: val?.userdata?.email_templates?.secure_access_vendor
      }
    }
  })
