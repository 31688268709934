import { useFormWatch } from '@/hooks'
import { useTicket } from '@/modules/ticket/form/hooks'
import { Button } from '@/ui'
import { FC, useCallback, useMemo } from 'react'
import { CurrentKind, useInlineItemEdit } from '../../../../../hooks'
import { Category, LineItem } from '../../../../../schemas'

type Props = {
  category: Category
  currentKind?: CurrentKind
  localItems?: Partial<LineItem>[]
}

export const InlineEditButtons: FC<Props> = ({ category, currentKind, localItems }) => {
  const { ticket } = useTicket()

  const { FORM_KEY, form, handleEdit, saveItems, saveMutation } = useInlineItemEdit()

  const allItemFormValue = useFormWatch([FORM_KEY], form) as Record<number, Partial<LineItem>>

  const editingItems = useMemo(
    () => Object.values(allItemFormValue || {}).filter((v) => v !== undefined && v?.category_id === category.id),
    [allItemFormValue, category.id]
  )

  const handleEditAll = useCallback(() => {
    if (!currentKind || !currentKind?.editableFields?.length || !localItems?.length) return
    handleEdit(
      localItems,
      currentKind.editableFields.map((fc) => fc.field),
      true
    )
  }, [currentKind, localItems, handleEdit])

  const handleCancelAll = useCallback(() => {
    form.setFieldsValue({
      [FORM_KEY]: Object.values(allItemFormValue)
        .filter((v) => v?.category_id === category.id)
        .reduce(
          (acc, v) => {
            acc[v.id as number] = undefined
            return acc
          },
          {} as Record<number, Partial<LineItem> | undefined>
        )
    })
  }, [FORM_KEY, allItemFormValue, category.id, form])

  const handleSaveAll = useCallback(async () => {
    await saveItems(ticket.id, editingItems)
    handleCancelAll()
  }, [editingItems, handleCancelAll, saveItems, ticket.id])

  return editingItems.length ? (
    <>
      <Button
        className={''}
        disabled={saveMutation.isLoading}
        shape={'round'}
        size={'small'}
        iconName={'fa:close'}
        onClick={handleCancelAll}
      >
        Cancel All
      </Button>
      <Button
        loading={saveMutation.isLoading}
        type={'primary'}
        className={''}
        shape={'round'}
        size={'small'}
        iconName={'fa:check'}
        onClick={handleSaveAll}
      >
        Save All
      </Button>
    </>
  ) : (
    <Button className={''} shape={'round'} size={'small'} iconName={'fa:edit'} onClick={handleEditAll}>
      Edit All
    </Button>
  )
}
