import { makeMap } from '@/utils'
import { DesCleanLineItemsButtonConfig } from './clients/des/clean-line-items-button'
import { IpsmSubmitToAccountingButtonConfig } from './clients/ipsm/submit-to-accounting-button'
import { IpsmWorkApprovedByXtoButtonConfig } from './clients/ipsm/work-approved-by-xto-button/config'
import { RcrDispatchButtonConfig } from './clients/rcr/dispatch-button'
import { RcrReDispatchButtonConfig } from './clients/rcr/re-dispatch-button'
import { AccountingSubmitButtonConfig } from './common/accounting-submit-button'
import { CopyTicketConfig } from './common/copy-ticket'
import { CustomScriptButtonConfig } from './common/custom-script-button'
import { EmailSignatureButtonConfig } from './common/email-signature-button'
import { ExampleButtonConfig } from './common/example-button'
import { FetchDirectionsButtonConfig } from './common/fetch-directions-button'
import { FetchWellDataButtonConfig } from './common/fetch-well-data-button'
import { MsNavCheckButtonConfig } from './common/ms-nav-check-button'
import { MsNavSubmitButtonConfig } from './common/ms-nav-submit-button'
import { OpenLinkButtonConfig } from './common/open-link-button'
import { SchedulerAssignerButtonConfig } from './common/scheduler-assigner-button'
import { SetStatusButtonConfig } from './common/set-status-button/config'
import { SummarizeTicketButtonConfig } from './common/summarize-ticket-button'
import { TicketPdfButtonConfig } from './common/ticket-pdf-button'
import { ScriptedButtonConfig } from './types'

export const SCRIPTED_BUTTONS_CONFIG: ScriptedButtonConfig[] = [
  ExampleButtonConfig,
  CustomScriptButtonConfig,
  EmailSignatureButtonConfig,
  OpenLinkButtonConfig,
  SummarizeTicketButtonConfig,
  FetchWellDataButtonConfig,
  FetchDirectionsButtonConfig,
  TicketPdfButtonConfig,
  CopyTicketConfig,
  SetStatusButtonConfig,
  MsNavCheckButtonConfig,
  MsNavSubmitButtonConfig,
  AccountingSubmitButtonConfig,
  SchedulerAssignerButtonConfig,

  DesCleanLineItemsButtonConfig,

  RcrDispatchButtonConfig,
  RcrReDispatchButtonConfig,

  IpsmWorkApprovedByXtoButtonConfig,
  IpsmSubmitToAccountingButtonConfig
  // ...add more buttons here
]

export const SCRIPTED_BUTTONS_CONFIG_MAP: Record<string, ScriptedButtonConfig> = makeMap(
  SCRIPTED_BUTTONS_CONFIG,
  'type'
)
