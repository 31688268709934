import { TICKET_STATUS } from '@/constants/general'
import { SUMMARY_ATTACHMENT_TYPE_SELECT } from '@/constants/summary'
import { useSession } from '@/hooks'
import { equipmentApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, Select } from 'antd'
import { CustomFormSelect } from '../custom-form-select'
import { EditableFieldsSelect } from '../editable-fields-select'
import { ForceViewCheckbox } from '../force-view-checkbox'
import { HideDefaultNoneView } from '../hide-default-none-view'
import { TableViewSelect } from '../table-view-select'

export const ModuleTypeSummaryWizardFields = () => {
  const { company } = useSession()

  const equipmentQuery = useQuery({
    ...equipmentApi.list({ fields: 'id,sku', summary_sku__exact: 1, company_id__exact: company.id })
  })

  return (
    <div>
      <div className="grid grid-cols-6 gap-x-16">
        <CustomFormSelect formItemProps={{ label: 'Custom Form', name: ['data', 'custom_form_id'] }} />
        <Form.Item label="Summary Ticket" name={['data', 'summary_ticket_status']}>
          <Select
            placeholder="Select"
            popupMatchSelectWidth={false}
            options={Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))}
          />
        </Form.Item>
        <Form.Item label="Summarized Tickets" name={['data', 'summarized_tickets_status']}>
          <Select
            placeholder="Select"
            popupMatchSelectWidth={false}
            options={[
              { label: 'Do Not Change', value: 'None' },
              ...Object.entries(TICKET_STATUS).map(([value, label]) => ({ value, label }))
            ]}
          />
        </Form.Item>
        <Form.Item label="Summary SKU" name={['data', 'summary_sku_id']}>
          <Select
            placeholder="Select"
            popupMatchSelectWidth={false}
            disabled={equipmentQuery.isLoading}
            loading={equipmentQuery.isLoading}
            options={equipmentQuery.data?.items?.map((item) => ({
              label: item.sku,
              value: item.id
            }))}
          />
        </Form.Item>
        <Form.Item label="List Each Line Item" name={['data', '$list_item_conf']}>
          <Select
            placeholder="Select"
            options={[
              { value: 3, label: 'Yes - Group' },
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' }
            ]}
          />
        </Form.Item>
        <Form.Item label="Attachment Type" name={['data', 'attachment_type']}>
          <Select popupMatchSelectWidth={false} placeholder="Select" options={SUMMARY_ATTACHMENT_TYPE_SELECT} />
        </Form.Item>
      </div>
      <div className="flex gap-x-16">
        <TableViewSelect formItemProps={{ label: 'Default Table View', name: 'selected_view' }} />
        <ForceViewCheckbox />
        <HideDefaultNoneView />
        <EditableFieldsSelect formItemProps={{ className: 'flex-1' }} />
      </div>
    </div>
  )
}
