import { useCompanyFeatures, useSession } from '@/hooks'
import { AppLink, AppLinkProps } from '@/ui/AppLink'
import { FC, ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTicketLoader } from '../form/hooks'

type Props = Omit<AppLinkProps, 'prefetch'> & {
  ticketId: string | number | null
  formId?: string | number
  children?: ReactNode
  v?: 1 | 2
  prefetch?: boolean
  params?: Record<string, any>
}

export const ViewTicketLink: FC<Props> = (props) => {
  const { ticketId, formId, children, v, params, prefetch, ...linkProps } = props
  const { user } = useSession()
  const { forceV2Tickets } = useCompanyFeatures()

  const userVersion =
    v || (forceV2Tickets.enabled ? 2 : 0) || (user?.profile.userdata_json?.preferences?.enable_v2_ticket_form ? 2 : 1)

  if (userVersion === 1) {
    let to = ticketId ? `/tickets/view/${ticketId}/${formId}/edit` : `/tickets/view/new/${formId}/edit`
    if (params) to += '?' + new URLSearchParams(params).toString()

    return (
      <AppLink v={1} to={to} {...linkProps}>
        {children}
      </AppLink>
    )
  }

  return <ViewTicketLinkV2 prefetch={prefetch} {...props} />
}

export const ViewTicketLinkV2: FC<Props> = ({ ticketId, formId, prefetch, children, ...linkProps }) => {
  const routeParams = useParams()
  const moduleId = Number(routeParams?.moduleId)

  prefetch = prefetch ?? linkProps.target !== '_blank'
  const [startPrefetch, setStartPrefetch] = useState(false)

  // pre-fetch ticket and custom form (for performance)
  useTicketLoader(ticketId, formId, prefetch && startPrefetch)

  return (
    <AppLink
      v={2}
      to={getTicketUrl(moduleId, ticketId, formId)}
      onFocus={() => setStartPrefetch(true)}
      onMouseEnter={() => setStartPrefetch(true)}
      {...linkProps}
    >
      {children}
    </AppLink>
  )
}

const getTicketUrl = (moduleId: any, ticketId: any, formId: any) => {
  const path = window.location.pathname

  if (!ticketId) return `/tickets/v2/${formId}/add`
  if (!moduleId) {
    return path.startsWith('/loads') ? `/loads/v2/${formId}/${ticketId}/edit` : `/tickets/v2/${formId}/${ticketId}/edit`
  }

  const isTicketModule = path.includes('/t/')
  const isLoadModule = path.includes('/l/')
  const isModuleForm = (isTicketModule || isLoadModule) && path.includes('/v2/')

  if ((!isTicketModule && !isLoadModule) || isModuleForm) {
    return `/tickets/v2/${formId}/${ticketId}/edit`
  }

  return `v2/${formId}/${ticketId}/edit` // relative path
}
