import { Permission } from '@/types/auth-user'

/**
 * Access token key in local storage.
 */
export const ACCESS_TOKEN_KEY = 'token'
export const OLD_ACCESS_TOKEN_KEY = 'old_token'

/**
 * User permissions constants.
 * todo: include backend specific permissions too
 */
export const PERMISSIONS = {
  // user roles (user.groups)
  qbInput: 'qb_input' as Permission, // qb input access
  myTime: 'my_time' as Permission, // my time access
  timeManager: 'time_manager' as Permission, // time manager access
  choicesManager: 'choices_manager' as Permission, // choices manager access
  moduleBuilder: 'module_builder' as Permission, // module builder access
  support: 'support' as Permission, // support access
  superAdmin: 'super_admin' as Permission, // super admin access

  // menu item flags (user.profile.userdata_json.tab_permissions)
  jobs: 'work' as Permission, // jobs access
  loads: 'loads' as Permission, // loads access
  records: 'the_field' as Permission, // records access
  tickets: 'tickets' as Permission, // tickets access
  lines: 'lines' as Permission, // line items access
  reporting: 'reporting' as Permission, // reporting access
  contacts: 'contacts' as Permission, // contacts access
  inventory: 'inventory' as Permission // inventory access
}
