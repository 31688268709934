import { Icon } from '@/ui'
import { Sortable } from '@/ui/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { FC } from 'react'
import { useConditions, useConditionsHandlers } from '../../hooks'
import { ConditionInputs } from './ConditionInputs'

type Props = {
  ruleIndex?: number
}

export const ConditionsSortable: FC<Props> = ({ ruleIndex }) => {
  const { conditions } = useConditions(ruleIndex)
  const { onSortingChange } = useConditionsHandlers(ruleIndex)

  return (
    <>
      {conditions.length === 0 ? (
        <div className="text-center text-text-muted py-16">
          <Icon name="fa:circle-info" className="mr-2" /> No Conditions
        </div>
      ) : (
        <Sortable
          items={conditions}
          by={'uuid'}
          onChange={onSortingChange}
          modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          render={(condition, conditionIndex, sortable) => (
            <ConditionInputs
              condition={condition}
              conditionIndex={conditionIndex}
              ruleIndex={ruleIndex}
              sortable={sortable}
            />
          )}
        />
      )}
    </>
  )
}
