import { useModalState } from '@/hooks'
import { useCheckAccess } from '@/modules/auth/hooks'
import { hasUnSavedValuesAtom } from '@/modules/ticket/form/atoms'
import { Permission } from '@/types/auth-user'
import { ButtonProps, Modal, ModalProps } from '@/ui'
import { Button } from '@/ui/button'
import { Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { FC, useState } from 'react'
import { SECURE_ACCESS_TYPES } from '../../../../constants'
import { useTicket, useTicketCustomer, useTicketSave } from '../../../../hooks'
import { EmailSecureAccessForm } from './secure-access-form'

type Props = {
  type: keyof typeof SECURE_ACCESS_TYPES
  checkAccess?: boolean
  buttonProps?: ButtonProps
  modalProps?: ModalProps
}

export const SecureAccess: FC<Props> = (props) => {
  const perms: Permission[] = props.type === 'S' ? ['ticket_signature'] : ['email_vendor']
  const allowed = useCheckAccess(perms)

  if (props.checkAccess && !allowed) return null

  return <EmailSecureAccessInner {...props} />
}

export const EmailSecureAccessInner: FC<Props> = ({ type, buttonProps, modalProps }) => {
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()
  const [popConfirmOpen, setPopConfirmOpen] = useState<boolean>(false)
  const { saveTicket, isSaving: isTicketSaving } = useTicketSave()
  const hasUnSavedValues = useAtomValue(hasUnSavedValuesAtom)
  const { ticket } = useTicket()
  const customer = useTicketCustomer()

  const isSignature = type === 'S'
  const title = isSignature ? 'Email For Signature' : 'Send To Vendor'
  const iconName = isSignature ? 'fa:pen-swirl' : 'fa:envelope'
  const sentAt = isSignature ? ticket?._data?.e_sign_emailed_at : ticket?._data?.vendor_access_emailed_at

  const saveTicketChanges = async () => {
    await saveTicket()
    setPopConfirmOpen(false)
    openModal()
  }

  const openSignatureModal = () => {
    if (hasUnSavedValues) {
      setPopConfirmOpen(true)
      return
    }
    openModal()
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div>
        <Popconfirm
          title="Unsaved Changes"
          description="Changes must be saved before continuing"
          okText="Save Changes"
          okButtonProps={{ onClick: saveTicketChanges, loading: isTicketSaving }}
          cancelButtonProps={{ onClick: () => setPopConfirmOpen(false), loading: isTicketSaving }}
          open={popConfirmOpen}
        />
        <Button
          disabled={!customer}
          type="primary"
          onClick={openSignatureModal}
          iconName={iconName}
          onFocus={renderModal}
          onMouseEnter={renderModal}
          {...buttonProps}
        >
          {buttonProps?.children || title}
        </Button>
      </div>
      {sentAt && <span className={'text-sm text-gray-500'}>Send: {dayjs.formatLocal(sentAt)}</span>}

      {isRender && (
        <Modal open={isOpen} iconName={iconName} title={title} onCancel={closeModal} footer={null} {...modalProps}>
          <EmailSecureAccessForm type={type} onSubmit={closeModal} onCancel={closeModal} />
        </Modal>
      )}
    </div>
  )
}
