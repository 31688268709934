import { useFormWatch } from '@/hooks'
import { ViewTicketLink } from '@/modules/ticket/components'
import { useTicket } from '@/modules/ticket/form/hooks'
import { Button } from '@/ui'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { CSSProperties, FC, useCallback } from 'react'
import { CurrentKind, useInlineItemEdit } from '../../../../hooks'
import { ItemColumnType, LineItem } from '../../../../schemas'
import { ItemField } from '../../../item-form/item-editor/item-field'
import styles from './styles.module.scss'

type Props = {
  columns: ItemColumnType[]
  item: Partial<LineItem>
  style?: CSSProperties
  className?: string
  kind: CurrentKind
}

type SubTicket = {
  ticketId: number
  formId: number
  note: string
}

export const ItemNotes: FC<Props> = ({ columns, item, style, className, kind }) => {
  const field = kind?.fieldsConfig?.find((f) => f.field === 'notes')

  const { ticket } = useTicket()
  const { FORM_KEY, isEditingField, handleEdit, handleCancelEdit, saveItems, saveMutation } = useInlineItemEdit()
  const itemFormValue = useFormWatch([FORM_KEY, item.id as number]) as Partial<LineItem> // watch it for re-render

  const handleSave = useCallback(
    async (item: Partial<LineItem>, key?: string) => {
      if (!key) return

      await saveItems(ticket.id, [{ id: item.id, [key]: itemFormValue?.[key as keyof typeof item] }])
      handleCancelEdit(item, key)
    },
    [handleCancelEdit, itemFormValue, saveItems, ticket.id]
  )

  if (
    !field ||
    (!item._has_notes && !item._data?.summarizes_ticket_id && isEmpty(item._data?.summarizes_tickets || []))
  ) {
    return null
  }

  const subTickets: SubTicket[] = (item._data?.summarizes_tickets || []).map((t) => ({
    ticketId: t.id,
    formId: t.custom_form_id || 0,
    note: `${t.custom_form || 'Ticket'} #${t.name}`
  }))
  if (item._data?.summarizes_ticket_id) {
    subTickets.push({ ticketId: item._data.summarizes_ticket_id, formId: 0, note: 'View Ticket' })
  }

  return (
    <tr style={style} className={cn(styles.notesRow, className)}>
      <td></td>
      <td colSpan={columns.length} className={'font-light'}>
        <div className={'mr-4 flex flex-col gap-8'}>
          <div>
            <div
              className={cn(
                'group relative -mx-4 mt-2 block break-all rounded-sm px-4 pr-30 text-[13px] text-text-muted outline-1 outline-border',
                {
                  'inline-block hover:outline-dashed':
                    field && field.editable && !isEditingField(item, field?.field || '')
                }
              )}
              onDoubleClick={() => field && field.editable && handleEdit(item, field.field)}
            >
              <div className={cn({ '!h-0 !opacity-0': isEditingField(item, field?.field || '') })}>
                <pre className={'p-0 m-0 text-wrap'}>{item.notes}</pre>
              </div>

              {field && field.editable && (
                <>
                  {isEditingField(item, field.field) ? (
                    <div className={'flex items-center gap-4'}>
                      <ItemField
                        forceShow={true}
                        label={''}
                        className={'!m-0 w-full !min-w-[150px]'}
                        name={[FORM_KEY, item.id?.toString(), field.field]}
                        fieldConfig={field}
                        inputProps={{ rows: 2 }}
                      />
                      <div className={'-m-4 flex flex-col items-center'}>
                        <Button
                          loading={saveMutation.isLoading}
                          className={'scale-[0.7]'}
                          primary
                          shape={'circle'}
                          size={'small'}
                          iconName={'fa:check'}
                          onClick={() => handleSave(item, field?.field)}
                        />
                        <Button
                          disabled={saveMutation.isLoading}
                          className={'scale-[0.7]'}
                          shape={'circle'}
                          size={'small'}
                          iconName={'fa:close'}
                          onClick={() => handleCancelEdit(item, field?.field)}
                        />
                      </div>
                    </div>
                  ) : (
                    <Button
                      className={
                        'absolute -right-8 -top-8 z-50 scale-[0.7] opacity-0 transition-none group-hover:opacity-100'
                      }
                      type={'default'}
                      shape={'circle'}
                      size={'small'}
                      iconName={'fa:edit'}
                      onClick={() => handleEdit(item, field?.field)}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          {!!subTickets.length && (
            <div className={'flex flex-row flex-wrap gap-6 opacity-80'}>
              {subTickets.map(({ ticketId, formId, note }) => (
                <div key={ticketId}>
                  <ViewTicketLink ticketId={ticketId} formId={formId} target={'_blank'}>
                    <Button
                      primary
                      shape={'round'}
                      type={'dashed'}
                      size={'small'}
                      iconName={'fa:external-link'}
                      iconClassName={'text-xs'}
                      className={'h-[22px] !px-8 text-sm'}
                    >
                      {note}
                    </Button>
                  </ViewTicketLink>
                </div>
              ))}
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}
