import { Button } from '@/ui/button'
import { Form, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, customFormIconAtom } from '../../../atoms'

export const FormIconField = () => {
  const iconUrl = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.icon_url), []))
  const [customFormIcon, setCustomFormIcon] = useAtom(customFormIconAtom)
  const setCustomForm = useSetAtom(customFormAtom)

  const fileList = customFormIcon
    ? [customFormIcon]
    : iconUrl
      ? [{ uid: '-1', name: 'image.png', status: 'done', thumbUrl: iconUrl ?? '' }]
      : []

  const handleRemove = () => {
    setCustomFormIcon(null)
    setCustomForm((prev) => ({
      ...prev,
      icon_url: null
    }))
  }

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file) {
      setCustomFormIcon(info.fileList[0] as any)
    }
  }

  return (
    <Form.Item label="Form Icon (.png, .svg, .jpg, .jpeg)">
      <Upload
        className="w-full block [&>*]:!block"
        maxCount={1}
        listType="picture"
        onRemove={handleRemove}
        fileList={fileList as any}
        beforeUpload={() => {
          return false
        }}
        onChange={handleChange}
      >
        <Button className="w-full" data-cy="custom-form-icon-upload-button">
          Upload Image File
        </Button>
      </Upload>
    </Form.Item>
  )
}
