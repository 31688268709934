import { Sortable } from '@/ui'
import { Button } from '@/ui/button'
import { Tooltip } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { AddEquipmentsModal } from '../add-equipments-modal'
import { equipmentAtom } from '../atoms'
import { DescriptionField } from '../description-field'
import { DetailedEquipment } from '../schema'
import { SkuField } from '../sku-field'
import { Item } from './item'

export const SkuGroup = () => {
  const [isExcludeComponents, setIsExcludeComponents] = useState(true)
  const group = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.group), []))
  const equipments = group?.equipment
  const setEquipment = useSetAtom(equipmentAtom)

  const handleDelete = (equipment: DetailedEquipment) => {
    setEquipment((prev) => ({
      ...prev,
      group: {
        ...prev.group,
        equipment: prev.group?.equipment?.filter((item) => item.id !== equipment.id)
      }
    }))
  }

  const handleSortingChange = (reorderedItems: DetailedEquipment[]) => {
    setEquipment((prev) => ({
      ...prev,
      group: {
        ...prev.group,
        equipment: [...reorderedItems]
      }
    }))
  }

  if (!group) {
    return null
  }

  return (
    <div>
      <div className="font-bold mb-14">SKU Items</div>
      <div className="w-1/2 mb-24">
        <SkuField />
        <DescriptionField />
        {equipments && equipments.length > 0 && (
          <div className="border rounded">
            <Sortable
              items={equipments}
              by={'id'}
              onChange={handleSortingChange}
              render={(equipment, index, sortable) => (
                <Item
                  key={`${equipment.id}-${index}`}
                  equipment={equipment}
                  sortable={sortable}
                  onDelete={handleDelete}
                />
              )}
            />
          </div>
        )}
      </div>
      <AddEquipmentsModal
        onAdd={(equipments) => {
          setEquipment((prev) => ({
            ...prev,
            group: {
              ...prev.group,
              equipment: [...(prev.group?.equipment || []), ...equipments]
            }
          }))
        }}
        modalTitle="Add SKU"
        triggerProps={{
          iconName: undefined,
          type: 'primary'
        }}
        queryParams={{
          ...(isExcludeComponents && { is_sub_component__exact: 0 })
        }}
        extraOnSearchRight={
          <Tooltip title="Exclude components">
            <Button
              iconName="mi:filter_alt"
              type={isExcludeComponents ? 'primary' : 'default'}
              onClick={() => setIsExcludeComponents((prev) => !prev)}
            />
          </Tooltip>
        }
      />
    </div>
  )
}
