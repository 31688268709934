import { useApp } from '@/hooks'
import { ListParams } from '@/types/api/core'
import { CategoryKindField } from '@/types/general'
import { dig } from '@/utils'
import { isEmpty, uniq } from 'lodash'
import { useCallback, useMemo } from 'react'
import { CategoryKind, FieldConfig, LineItemOptions } from '../schemas'
import { lineFieldFormatters } from './../helpers'
import { useCategoryKinds } from './use-category-kinds'
import { useFormWatch } from './use-form-watch'

type Props = {
  type: 'table' | 'form'
  kind?: CategoryKind | null
  category?: {
    kindId?: number | null
    parentKindId?: number | null
  }
  options?: LineItemOptions
}

export type CurrentKind = {
  currentKind: CategoryKind | undefined | null
  isScript: (field: CategoryKindField) => boolean
  isExcluded: (field: CategoryKindField) => boolean
  isHidden: (field: CategoryKindField) => boolean
  isDisabled: (field: CategoryKindField) => boolean
  fieldsConfig: FieldConfig[]
  fieldsQueryParam: ListParams
  visibleFields: FieldConfig[]
  editableFields: FieldConfig[]
}

export const useCurrentKind = ({ type, kind, category, options }: Props): CurrentKind => {
  const { l } = useApp()
  const isNew = useFormWatch<boolean>(['isNew'])

  const { showAllFields, hiddenFields, disabledFields, excludedFields, ignoreHiddenFields, sourcesOverride } =
    options || {}
  const { getCategoryKindById } = useCategoryKinds({
    enabled: !kind && !!category
  })

  const { kindId, parentKindId } = category || {}
  const currentKind = useMemo(() => {
    if (kind) return kind
    if (kindId) return getCategoryKindById(kindId)
    if (parentKindId) return getCategoryKindById(parentKindId)
  }, [kind, kindId, getCategoryKindById, parentKindId])

  const selectedFields = useMemo(() => {
    if (!currentKind?.fields) return []

    return type === 'form'
      ? uniq(['sku', 'description', ...currentKind.fields]) // sku and description are always shown at the top for form
      : uniq([...currentKind.fields, 'notes']) // notes are always shown at the bottom for table
  }, [currentKind, type])

  const isScript = useCallback((field: CategoryKindField) => !isEmpty(currentKind?.scripts?.[field]), [currentKind])

  const isExcluded = useCallback(
    (field: CategoryKindField) => excludedFields?.includes(field) || false,
    [excludedFields]
  )

  const isHidden = useCallback(
    (field: CategoryKindField) => {
      if (isExcluded(field)) return true
      if (showAllFields || ignoreHiddenFields) return false
      if (isNew && currentKind?.data?.[field]?.hideOnNew) return true

      const _hiddenFields = (hiddenFields || []).concat(currentKind?.hidden_fields || [])
      return _hiddenFields.includes(field)
    },
    [currentKind?.data, currentKind?.hidden_fields, hiddenFields, ignoreHiddenFields, isExcluded, isNew, showAllFields]
  )

  const isDisabled = useCallback(
    (field: CategoryKindField) => {
      if (currentKind?.data?.[field]?.readonly) return true
      if (isScript(field)) return true

      const _disabledFields = (disabledFields || []).concat(currentKind?.readonly_fields || [])
      return _disabledFields.includes(field)
    },
    [currentKind, disabledFields, isScript]
  )

  const isEditable = useCallback(
    (field: CategoryKindField) => {
      if (type === 'table') return !['sku', 'description'].includes(field) && !isHidden(field) && !isDisabled(field)
      else return true
    },
    [type, isDisabled, isHidden]
  )

  const fieldsConfig: FieldConfig[] = useMemo(() => {
    if (!currentKind || !selectedFields.length) return []

    return (
      selectedFields.map(
        (f): FieldConfig => ({
          field: f,
          label: currentKind.field_aliases?.[f] || l(`lineitem__${f}`),
          hidden: isHidden(f),
          editable: isEditable(f),
          disabled: isDisabled(f),
          hideDate: !!currentKind.data?.[f]?.hideDate,
          hideTime: !!currentKind.data?.[f]?.hideTime,
          stringSource: sourcesOverride?.[f] || currentKind.data?.[f]?.stringSource || null,
          format: lineFieldFormatters[f] || ((line, o) => dig(line, f) || '')
        })
      ) || []
    )
  }, [currentKind, isDisabled, isEditable, isHidden, l, selectedFields, sourcesOverride])

  const visibleFields = useMemo(() => fieldsConfig.filter((f) => !f.hidden), [fieldsConfig])

  const editableFields = useMemo(() => fieldsConfig.filter((f) => f.editable), [fieldsConfig])

  const fieldsQueryParam = useMemo(() => {
    if (!currentKind || !selectedFields.length) return {}

    const REQUIRED_FIELDS = [
      'id',
      'sku',
      'group',
      'sequence',
      'subtotal_after',
      'equipment_id',
      'category_id',
      'sku_designation',
      'show_components',
      'components__id',
      'data_json'
    ]

    const fields = [...REQUIRED_FIELDS, ...selectedFields]

    const cleanFields: string[] = []
    fields.forEach((field) => {
      if (field === 'assignee' || field === 'assignee_id') {
        cleanFields.push('assignee_id')
        cleanFields.push('assignee__first_name')
        cleanFields.push('assignee__last_name')
        return
      }

      cleanFields.push(field)
    })

    return { fields: uniq(cleanFields).join(',') }
  }, [currentKind, selectedFields])

  return {
    currentKind,
    isScript,
    isExcluded,
    isHidden,
    isDisabled,
    fieldsConfig,
    fieldsQueryParam,
    visibleFields,
    editableFields
  }
}
