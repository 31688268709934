import { ContactsNote } from '@/types/contacts-note'
import { Button } from '@/ui/button'
import { Form, Input, List } from 'antd'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'

export type EditingNote = Omit<ContactsNote, 'id'> & { isTemp: boolean; id: string | number }

type Props = {
  notes: EditingNote[]
  onNoteAdded: (note: EditingNote) => void
  onNoteEdited: (note: EditingNote) => void
  onNoteDeleted: (note: EditingNote) => void
}

export const ContactsNotesList = ({ notes, onNoteAdded, onNoteDeleted, onNoteEdited }: Props) => {
  const [editingNote, setEditingNote] = useState<EditingNote | null>(null)
  const [form] = Form.useForm()

  const handleAddNote = () => {
    const newNote: EditingNote = {
      id: nanoid(),
      text_content: '',
      isTemp: true
    }

    onNoteAdded(newNote)
    setEditingNote(newNote)
  }

  return (
    <div>
      <h5 className="font-bold">Notes</h5>
      <List
        dataSource={notes}
        renderItem={(note) => (
          <List.Item
            className="!items-start"
            actions={[
              <React.Fragment key="1">
                {editingNote?.id === note.id ? (
                  <Button
                    iconName="mi:check"
                    className="shrink-0"
                    type="text"
                    data-cy="save-note-button"
                    onClick={async () => {
                      const values = await form.getFieldsValue()
                      onNoteEdited({ ...note, ...values })
                      setEditingNote(null)
                      form.resetFields()
                    }}
                  />
                ) : (
                  <Button
                    iconName="svg:edit"
                    className="shrink-0"
                    type="text"
                    data-cy="edit-note-button"
                    onClick={() => {
                      setEditingNote(note)
                      form.setFieldsValue(note)
                    }}
                  />
                )}
              </React.Fragment>,
              <Button
                key="2"
                iconName="svg:trash"
                className="shrink-0"
                type="text"
                onClick={() => onNoteDeleted(note)}
                data-cy="delete-note-button"
              />
            ]}
          >
            <List.Item.Meta
              title={
                editingNote?.id === note.id ? (
                  <Form layout="vertical" form={form}>
                    <Form.Item name="text_content" className="mb-0">
                      <Input.TextArea rows={2} defaultValue={note.text_content} data-cy="note-text-input" />
                    </Form.Item>
                  </Form>
                ) : (
                  <div className="whitespace-pre-line">{note.text_content}</div>
                )
              }
              description={
                note?.modified_by__username && (
                  <div className="text-12">
                    Last modified by {note?.modified_by__username} at{' '}
                    {dayjs(note?.modified_at).format('MMM DD, YYYY h:mm A')}
                  </div>
                )
              }
            />
          </List.Item>
        )}
      />
      <Button
        disabled={editingNote !== null}
        block
        className="mt-8"
        data-cy="add-note-button"
        onClick={() => {
          handleAddNote()
        }}
      >
        Add Note
      </Button>
    </div>
  )
}
