import { companyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { z } from 'zod'
import { customFormAtom } from '../../../../atoms'

const companySchema = z.object({
  id: z.number(),
  name: z.string(),
  icon: z.string().nullish()
})

export const CompanySelect = () => {
  const [searchText, setSearchText] = useState('')
  const companies = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.companies), []))
  const setCustomForm = useSetAtom(customFormAtom)

  const companiesQuery = useQuery({
    ...companyApi.list({
      // Not using fields because it's not returning correct icon url
      // fields: 'id,name,icon',
      search: searchText
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((item) => companySchema.parse(item))
    })
  })

  const options = useMemo(
    () =>
      companiesQuery.data?.items.map((company) => ({
        label: company.name,
        value: company.id
      })),
    [companiesQuery.data?.items]
  )

  const handleChange = (value: number) => {
    const company = companiesQuery.data?.items.find((c) => c.id === value)
    const exists = companies?.some((c) => c.id === value)

    if (exists) {
      return
    }

    if (company) {
      setCustomForm((prev) => ({
        ...prev,
        companies: [...(prev.companies ?? []), company],
        // Add default access rules for the new company
        access_rules: [
          ...(prev.access_rules ?? []),
          {
            company: company.id,
            create: false,
            write: false,
            list: false,
            id: 0,
            read: true
          }
        ]
      }))
    }
  }

  return (
    <div className="w-full mt-20">
      <Form layout="vertical" component={false}>
        <Form.Item label="Add company">
          <Select
            placeholder="Select a company"
            showSearch
            popupMatchSelectWidth={false}
            searchValue={searchText}
            onSearch={setSearchText}
            filterOption={false}
            value={null}
            options={options}
            onChange={handleChange}
            data-cy="select-company"
          />
        </Form.Item>
      </Form>
    </div>
  )
}
