import { z } from 'zod'

// schemas
export const AfeSchema = z.object({
  id: z.number(),
  user_afe_no: z.string()
})

// types
export type Afe = z.infer<typeof AfeSchema>
