import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_TIMEZONE,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  ISO_DATE_TIME_NO_Z_FORMAT,
  ISO_TIME_FORMAT,
  ISO_TIME_NO_Z_FORMAT,
  MILITARY_DATE_TIME_FORMAT,
  MILITARY_TIME_FORMAT,
  TIMESTAMP_FORMAT,
  TIME_FORMAT
} from '@/constants/date'
import dayjs, { PluginFunc, isDayjs } from 'dayjs'
import 'dayjs/locale/en'
import { isEmpty } from 'lodash'
import { hasTimezone } from './helpers'

declare module 'dayjs' {
  /** Global store for the default timezone. */
  let $defaultTimezone: string

  /** Global store for local date-time formats. */
  let $localFormat: {
    date: string
    time: string
    dateTime: string
    militaryTime: string
    militaryDateTime: string
    isoDate: string
    isoTime: string
    isoDateTime: string
    isoTimeNoZ: string
    isoDateTimeNoZ: string
    timestamp: string
  }

  /** Set the default date and time format. */
  let setDefaultFormat: (options: { date?: string; time?: string }) => void

  /** Check if the timezone is valid. */
  let isValidTimeZone: (timezone: string) => boolean

  /**
   * Set the default timezone for the application.
   *
   * If the timezone is not provided, the default timezone is used.
   * If it fails to set the timezone, the default timezone is used.
   *
   * @param timezone - timezone string to set
   * @returns current timezone string
   */
  let setTimeZone: (timezone: string) => string

  /** Get the current date-time in the local timezone. */
  let now: () => Dayjs

  /** Parse a date-time string into a Day.js date. */
  let parse: (
    date?: ConfigType,
    options?: { format?: OptionType; tz?: 'convert' | 'replace' | 'ignore' }
  ) => Dayjs | null

  /** Create a range of Day.js dates between a start and end date. */
  let range: (start: Dayjs, end: Dayjs, unit: ManipulateType) => Dayjs[]

  /** Format a date in the timestamp format. */
  let formatTimestamp: (value: any, dash?: boolean) => string | null

  /** Format a date in the local date-time format and timezone. */
  let formatLocal: (value: any) => string | null

  /** Format a date in the local date format and timezone. */
  let formatLocalDate: (value: any) => string | null

  /** Format a date in the local time format and timezone. */
  let formatLocalTime: (value: any) => string | null

  /** Format a date in the ISO date-time format and UTC timezone. */
  let formatISO: (value: any, utc?: boolean) => string | null

  /** Format a date in the ISO date format and UTC timezone. */
  let formatISODate: (value: any, utc?: boolean) => string | null

  /** Format a date in the ISO time format and UTC timezone. */
  let formatISOTime: (value: any, utc?: boolean) => string | null

  interface Dayjs {
    /** Format a date in the timestamp format. */
    formatTimestamp(dash?: boolean): string

    /** Format a date in the local date-time format and timezone. */
    formatLocal(): string

    /** Format a date in the local date format and timezone. */
    formatLocalDate(): string

    /** Format a date in the local time format and timezone. */
    formatLocalTime(): string

    /** Format a date in the ISO date-time format and UTC timezone. */
    formatISO(utc?: boolean): string

    /** Format a date in the ISO date format and UTC timezone. */
    formatISODate(utc?: boolean): string

    /** Format a date in the ISO time format and UTC timezone. */
    formatISOTime(utc?: boolean): string

    /** Clear the seconds from the date. */
    clearSeconds(): Dayjs
  }
}

const localDate: PluginFunc = (_, dayjsClass, dayjsFactory) => {
  /**
   * extends dayjs module
   */

  dayjsFactory.$defaultTimezone = DEFAULT_TIMEZONE

  dayjsFactory.$localFormat = {
    date: DATE_FORMAT,
    time: TIME_FORMAT,
    dateTime: DATE_TIME_FORMAT,
    militaryTime: MILITARY_TIME_FORMAT,
    militaryDateTime: MILITARY_DATE_TIME_FORMAT,
    isoDate: ISO_DATE_FORMAT,
    isoTime: ISO_TIME_FORMAT,
    isoDateTime: ISO_DATE_TIME_FORMAT,
    isoTimeNoZ: ISO_TIME_NO_Z_FORMAT,
    isoDateTimeNoZ: ISO_DATE_TIME_NO_Z_FORMAT,
    timestamp: TIMESTAMP_FORMAT
  }
  dayjsFactory.setDefaultFormat = function ({ date, time }) {
    dayjsFactory.$localFormat.date = date || dayjsFactory.$localFormat.date
    dayjsFactory.$localFormat.time = time || dayjsFactory.$localFormat.time
    dayjsFactory.$localFormat.dateTime = `${dayjsFactory.$localFormat.date} ${dayjsFactory.$localFormat.time}`
    dayjsFactory.$localFormat.militaryDateTime = `${dayjsFactory.$localFormat.date} ${dayjsFactory.$localFormat.militaryTime}`
  }

  dayjsFactory.isValidTimeZone = function (timezone) {
    try {
      return dayjs().tz(timezone).isValid()
    } catch (error) {
      return false
    }
  }
  dayjsFactory.setTimeZone = function (timezone) {
    if (!dayjsFactory.isValidTimeZone(timezone)) {
      console.error(`Invalid timezone: ${timezone} - using default timezone: ${DEFAULT_TIMEZONE}`)
      timezone = DEFAULT_TIMEZONE
    }

    dayjs.tz.setDefault(timezone)
    dayjsFactory.$defaultTimezone = timezone

    return timezone
  }

  dayjsFactory.now = function () {
    return dayjs.tz()
  }
  dayjsFactory.parse = function (value, { format, tz } = {}) {
    if (isEmpty(value)) return null
    const date = isDayjs(value) ? value : dayjs(value, format)
    if (!date.isValid()) return null

    if (tz === 'convert') return date.tz(dayjsFactory.$defaultTimezone)
    else if (tz === 'replace') return date.tz(dayjsFactory.$defaultTimezone, true)
    else if (tz === 'ignore') return date
    else {
      if (typeof value === 'string' && hasTimezone(value)) {
        // convert date to default timezone
        return date.tz(dayjsFactory.$defaultTimezone)
      } else {
        // replace to local timezone.
        return date.tz(dayjsFactory.$defaultTimezone, true)
      }
    }
  }
  dayjsFactory.range = function (start, end, unit) {
    const range = []
    let current = start
    while (!current.isAfter(end)) {
      range.push(current)
      current = current.add(1, unit)
    }
    return range
  }

  dayjsFactory.formatTimestamp = function (value, dash) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatTimestamp(dash) : null
  }
  dayjsFactory.formatLocalDate = function (value) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatLocalDate() : null
  }
  dayjsFactory.formatLocalTime = function (value) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatLocalTime() : null
  }
  dayjsFactory.formatLocal = function (value) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatLocal() : null
  }

  dayjsFactory.formatISODate = function (value, utc) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatISODate(utc) : null
  }
  dayjsFactory.formatISOTime = function (value, utc) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatISOTime(utc) : null
  }
  dayjsFactory.formatISO = function (value, utc) {
    const date = dayjsFactory.parse(value)
    return date?.isValid() ? date.formatISO(utc) : null
  }

  /**
   * extends Dayjs class
   */

  dayjsClass.prototype.formatTimestamp = function (dash = true): string {
    const value = this.tz().format(dayjsFactory.$localFormat.timestamp)
    return dash ? value : value.replace(/-/g, '')
  }

  dayjsClass.prototype.formatLocalDate = function () {
    return this.tz().format(dayjsFactory.$localFormat.date)
  }

  dayjsClass.prototype.formatLocalTime = function () {
    return this.tz().format(dayjsFactory.$localFormat.time)
  }

  dayjsClass.prototype.formatLocal = function () {
    return this.tz().format(dayjsFactory.$localFormat.dateTime)
  }

  dayjsClass.prototype.formatISODate = function (utc = true): string {
    return (utc ? this.utc() : this.tz()).format(dayjsFactory.$localFormat.isoDate)
  }

  dayjsClass.prototype.formatISOTime = function (utc = true): string {
    if (utc) return this.utc().format(dayjsFactory.$localFormat.isoTime)
    return this.tz().format(dayjsFactory.$localFormat.isoTimeNoZ)
  }

  dayjsClass.prototype.formatISO = function (utc = true): string {
    if (utc) return this.utc().format(dayjsFactory.$localFormat.isoDateTime)
    return this.tz().format(dayjsFactory.$localFormat.isoDateTimeNoZ)
  }

  dayjsClass.prototype.clearSeconds = function () {
    return this.set('second', 0).set('millisecond', 0)
  }
}

export default localDate
