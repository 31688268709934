import { ReportBuilderReport } from '@/types/report-builder-report'
import { groupBy } from 'lodash'
import { DraggableItem, Property } from '../types'

export const processPropertyColumns = (report: ReportBuilderReport, properties: Property[]) => {
  let propertiesColumns: DraggableItem[] = properties.map((p) => {
    const name = `* ${p.property_group__custom_form__name}, ${p.property_group__name}, ${p.name}`
    const field_verbose = '**p=' + p.id
    const _field_verbose = '**k=' + p.key

    return {
      _type: p.property_type__name,
      path: '',
      path_verbose: '',
      field: '_rpt_property',
      field_verbose,
      _field_verbose,
      name,
      _name: name,
      report: report.id,
      custom_form__name: p.property_group__custom_form__name,
      pg_name: p.property_group__name,
      p_id: p.id,
      p_name: p.name,
      p_key: p.key,
      p_customform: p.property_group__custom_form,
      category: p.property_group__custom_form__name,
      id: `${field_verbose}${name}`
    }
  })

  const propertyKeys = groupBy(propertiesColumns, (p) => p.p_key)

  propertiesColumns = propertiesColumns.reduce((arr, col) => {
    if (!(col._type === 'Dropdown' || col._type === 'MultivariateDropdown')) {
      return arr.concat([col])
    }

    const dfValue = col
    const dfDisplay = { ...col }

    dfValue.name += ' - Value'
    dfValue._name = dfValue.name
    dfValue.field_verbose += '|value'
    dfValue._field_verbose += '|value'
    dfValue.id += '|value'

    dfDisplay.name += ' - Display'
    dfDisplay._name = dfDisplay.name
    dfDisplay.field_verbose += '|display'
    dfDisplay._field_verbose += '|display'
    dfDisplay.id += '|display'

    return arr.concat([dfValue, dfDisplay])
  }, [] as DraggableItem[])

  propertiesColumns = propertiesColumns.map((p) => {
    if (!p.p_key) {
      return p
    }

    const propertyKeyLength = propertyKeys[p.p_key].length

    if (propertyKeyLength <= 1) {
      return p
    }

    return {
      ...p,
      __name: p._name,
      options: {
        properties: [...new Set(propertyKeys[p.p_key].map((p) => p.p_id))]
      },
      _forms: [...new Set(propertyKeys[p.p_key].map((p) => p.custom_form__name || ''))]
    }
  })

  return propertiesColumns
}
