import { z } from 'zod'

export const TemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  custom_form_id: z.number(),
  fields: z.any()
})

export const LogSchema = z.object({
  id: z.string(),
  status: z.string(),
  file_name: z.string(),
  file: z.string(),
  data: z.record(z.string(), z.any()),
  created_at: z.string()
})

export type ImportTicketField = {
  field: string
  name: string
  label: string
  type: 'string' | 'integer' | 'decimal' | 'date' | 'datetime' | 'dropdown'
  source?: string | null
}

export type Template = z.infer<typeof TemplateSchema>
export type Log = z.infer<typeof LogSchema>

export type SelectedImportField = {
  field: string
  name: string
}
