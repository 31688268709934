import { Button } from '@/ui/button'
import { useDefaultLiFormsQuery, useDefaultLiUpdate } from '../../hooks'

export const SkuDefault = () => {
  const { defaultLiFormsQuery } = useDefaultLiFormsQuery()
  const { updateDefaultLi, defaultLiUpdateMutation } = useDefaultLiUpdate()

  if (defaultLiFormsQuery.data?.items.length === 0) {
    return null
  }

  return (
    <div>
      <div className="flex mb-14 gap-x-4">
        <div className="font-bold">SKU Default</div>
        <div>(being used as default line items on custom forms)</div>
      </div>
      <div>
        {defaultLiFormsQuery.data?.items.map((item, index) => (
          <div key={item.id} className="font-bold">
            {index + 1}. {item.name}
          </div>
        ))}
      </div>
      <Button
        iconName="mi:refresh"
        size="small"
        className="mt-10"
        onClick={() => updateDefaultLi()}
        data-cy="sku-update-default-li-button"
        loading={defaultLiUpdateMutation.isLoading}
      >
        Update Default Line Items
      </Button>
    </div>
  )
}
