import { useSession } from '@/hooks'
import { executeMutation } from '@/query'
import { afeApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm, useTicketFormValues } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'afe'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const AfeInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { company } = useSession()
  const { isDisabledField, isHiddenField } = useCustomForm()
  const { setFieldValue, getPropertyValueByKey } = useTicketFormValues()
  const prefix = getPropertyValueByKey('afe_prefix') || ''

  if (isHiddenField(KEY)) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__afe__label'} />} {...formItemProps}>
      <QuerySelect
        apiEndpoint={afeApi.list}
        apiQueryParams={{ fields: 'id,user_afe_no', order: 'user_afe_no' }}
        apiSearchBy={'user_afe_no'}
        renderOption={(item) => ({ value: item.id, label: item.user_afe_no })}
        labelRender={(option) => `${prefix}${option.label}`}
        disabled={isDisabledField(KEY)}
        prefetch={true}
        showCopyText={true}
        addOption={{
          onAdd: async (text) => {
            const o: any = await executeMutation(afeApi.create(), { user_afe_no: text, company: company.id })
            return { value: o.id, label: o.user_afe_no }
          }
        }}
        {...(inputProps as any)}
      />
    </Form.Item>
  )
}
