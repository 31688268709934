import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { SkillFilter } from '@/modules/jas/job-manager/add-resource/components/SkillFilter'
import { useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { BackButton } from '@/ui'
import cn from 'classnames'
import React from 'react'
import { AddResourceButton } from './components/AddResourceButton'
import { TopHeader } from './components/TopHeader'
import { ResourceCalendar } from './components/calendar/ResourceCalendar'
import { useAddResource } from './hooks'

export const JobManagerAddResource: React.FC = () => {
  const { l } = useApp()
  const { typeLabel, close } = useAddResource()
  const { selectedJobData: jobData } = useSelectedJobTicket()

  return (
    <PageView
      header={{
        backButton: <BackButton onClick={close} />,
        breadcrumbs: true,
        title: (
          <div>
            Add {typeLabel} to {l('ticket__name__label')} {jobData?.name}
          </div>
        ),
        actions: [<AddResourceButton key={'select'} />],
        description: <TopHeader />
      }}
    >
      <div className={cn('flex flex-col h-[calc(100vh-215px)]')}>
        <SkillFilter />
        <ResourceCalendar />
      </div>
    </PageView>
  )
}
