import { useAtomSelect } from '@/hooks/atom'
import { Button, Popconfirm, Select } from '@/ui'
import { UseSortable } from '@/ui/sortable'
import { cn } from '@/utils'
import { Form, Input } from 'antd'
import { FC, useMemo } from 'react'
import { bpaConfigAtom } from '../../atoms'
import { useActionsHandlers } from '../../hooks'
import { BPA_ACTIONS_LIST, BpaAction } from '../../schemas'
import { ActionInputs } from './ActionInputs'

export const ActionCard: FC<{
  action: BpaAction
  actionIndex: number
  ruleIndex: number
  sortable?: UseSortable
}> = ({ action, actionIndex, ruleIndex, sortable }) => {
  const mode = useAtomSelect(bpaConfigAtom, 'mode')
  const { deleteAction, onChange, onChangeDebounced } = useActionsHandlers(ruleIndex)

  const bpaActions = useMemo(() => BPA_ACTIONS_LIST.filter((l) => l.modes.includes(mode as any)), [mode])

  return (
    <div
      key={action.uuid}
      className={cn('flex flex-row border py-10 rounded mb-10', {
        'border-dashed !border-primary-500': sortable?.isDragging
      })}
      ref={sortable?.setNodeRef}
      style={sortable?.style}
      {...sortable?.attributes}
    >
      <div className="px-10">
        <Form.Item label="Action Type" className="w-[175px]">
          <Select
            defaultValue={action.type}
            options={bpaActions}
            data-cy="action-type-select"
            onChange={(value) => onChangeDebounced({ type: value }, actionIndex)}
          />
        </Form.Item>

        <Form.Item label="Action Key" className="w-[175px]">
          <Input
            defaultValue={action.key}
            data-cy="action-key-input"
            onChange={(e) => onChangeDebounced({ key: e.target.value }, actionIndex)}
          />
        </Form.Item>
      </div>
      <div className="flex-grow border-x px-10 overflow-auto">
        <ActionInputs action={action} index={actionIndex} onChange={onChange} />
      </div>
      <div className="flex flex-col items-center justify-center relative p-2">
        <Popconfirm
          className="absolute top-0 lef-0"
          title="Confirm Delete"
          description="Deleting a action. Are you sure?"
          onConfirm={(e) => deleteAction(actionIndex)}
        >
          <Button data-cy="delete-action-button" type="text" iconName="fa:trash" size="small" danger={true} />
        </Popconfirm>
        <Button
          {...sortable?.listeners}
          type="text"
          iconName="fa:grip-dots-vertical"
          size="small"
          className="cursor-grab"
          data-cy="action-drag-handle"
        />
      </div>
    </div>
  )
}
