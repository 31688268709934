import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { moduleApi } from '@/services/api-service'
import { Outlet } from 'react-router-dom'

type Module = {
  id: number
  title: string
}

export const SchedulerLayout = view<any, Module>(Outlet, {
  title: ({ data }) => data?.title || 'Scheduler',
  loader: async ({ params }) => {
    return await fetchQuery(moduleApi.get(Number(params?.moduleId), undefined, { fields: 'id,title' }))
  }
})
