import { useFormWatch } from '@/hooks'
import {
  SCRIPTED_BUTTONS_CONFIG,
  SCRIPTED_BUTTONS_CONFIG_MAP
} from '@/modules/ticket/form/components/buttons/scripted-button'
import { Obj } from '@/types/general'
import { Icon, Select, Tooltip } from '@/ui'
import { Form, Input, Switch } from 'antd'
import cn from 'classnames'
import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { useCustomFormQuery } from '../../../hooks'

export const ScriptedButtonFields = () => {
  const { customFormQuery } = useCustomFormQuery()
  const customForm = customFormQuery.data

  const configValue = useFormWatch<Obj | undefined>(['button_config'])

  const buttonTypeOptions = useMemo(() => {
    return Object.entries(groupBy(SCRIPTED_BUTTONS_CONFIG, 'group')).map(([group, buttons]) => ({
      label: group,
      title: group,
      options: buttons.map((button) => ({
        text: `${group} ${button.label}`,
        label: (
          <div className={'flex items-center align-middle gap-10'}>
            <Icon
              className={'block ml-2 min-w-[18px] text-center'}
              name={button.listIconName || button.iconName || 'blank'}
            />
            {button.label}
          </div>
        ),
        value: button.type
      }))
    }))
  }, [])

  const buttonConfig = SCRIPTED_BUTTONS_CONFIG_MAP[configValue?.type]

  const iconName = (configValue?.icon ? `fa:${configValue.icon}` : null) || buttonConfig?.iconName || undefined

  return (
    <div className="mt-20 w-full rounded border p-10">
      <h5>Button Config</h5>

      <Form.Item name={['button_config', 'type']} label="Button Type">
        <Select placeholder={'Select'} options={buttonTypeOptions} filterBy={'text'} />
      </Form.Item>

      {buttonConfig && (
        <div className={'grid grid-cols-5 gap-8'}>
          <Form.Item
            name={['button_config', 'icon']}
            label="Button Icon Name"
            help={
              <Tooltip placement={'bottom'} title={'Copy the icon name and paste'}>
                <a href="https://fontawesome.com/search?o=r&s=regular&f=classic" target={'_blank'} rel="noreferrer">
                  <small>Search Icon</small>
                  <Icon name={'fa:external-link'} className={'ml-5 text-[10px]'} />
                </a>
              </Tooltip>
            }
          >
            <Input
              addonBefore={
                <div className={'text-center min-w-[16px]'}>{iconName && <Icon name={iconName as any} />}</div>
              }
              placeholder={'Default'}
            />
          </Form.Item>
          <Form.Item name={['button_config', 'align']} label="Button Align">
            <Select
              placeholder={'Center'}
              options={[
                { value: 'left', label: 'Left' },
                { value: 'center', label: 'Center' },
                { value: 'right', label: 'Right' },
                { value: 'stretch', label: 'Stretch' }
              ]}
            />
          </Form.Item>
          <Form.Item name={['button_config', 'variant']} label="Button Variant">
            <Select
              placeholder={'Primary'}
              options={[
                { value: 'default', label: 'Plain' },
                { value: 'primary', label: 'Primary' },
                { value: 'success', label: 'Success' },
                { value: 'info', label: 'Info' },
                { value: 'danger', label: 'Danger' }
              ]}
            />
          </Form.Item>
          <Form.Item name={['button_config', 'shape']} label="Button Shape">
            <Select
              placeholder={'Default'}
              options={[
                { value: 'default', label: 'Default' },
                { value: 'round', label: 'Round' },
                { value: 'circle', label: 'Circle' }
              ]}
            />
          </Form.Item>
          <Form.Item
            name={['button_config', 'force_enable']}
            label="Force Enable"
            tooltip="Will be active even on read-only mode"
          >
            <Switch />
          </Form.Item>
        </div>
      )}

      {(buttonConfig?.vars || []).length > 0 && (
        <div className={'mb-10'}>
          <span>Button Variables</span>
          <div className={cn('border rounded p-10 grid gap-10', buttonConfig?.varsClassName)}>
            {buttonConfig?.vars?.map((variable) => {
              const namePath = ['button_config', 'vars']

              if (variable.renderFormItem) {
                return variable.renderFormItem(
                  customForm,
                  {
                    key: variable.name,
                    rules: variable.rules,
                    name: [...namePath, ...(variable.name ? [variable.name] : [])],
                    label: variable.label,
                    className: 'mb-0'
                  },
                  namePath
                )
              }

              return (
                <Form.Item
                  key={variable.name.toString()}
                  rules={variable.rules}
                  name={[...namePath, ...(variable.name ? [variable.name.toString()] : [])]}
                  label={variable.label}
                  className={'mb-0'}
                >
                  {variable.render ? variable.render(customForm, undefined, namePath) : <Input />}
                </Form.Item>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
