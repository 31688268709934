import { CalendarTable, CalendarViewSelect } from '@/components'
import { PageView } from '@/layouts/views'
import { calendarViewAtom } from '@/modules/jas/scheduler/atoms'
import { view } from '@/routing'
import { Modal } from '@/ui'
import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { selectedDateAtom, selectedJobRoleIdAtom } from './atoms'
import { CoverageDateCell } from './coverage-date-cell'
import { EmployeesList } from './employees-list'
import { getDateStr } from './helpers'
import { useStatsByJobRoles } from './hooks'
import { JobRoleStats } from './schemas'
import { TableFooter } from './table-footer'
import { TableTitle } from './table-title'
import { TitleCell } from './title-cell'

export const CoverageView = view(Component, { title: () => 'Coverage' })

function Component() {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
  const calendarView = useAtomValue(calendarViewAtom)
  const { date, dispatch, stats, isLoading } = useStatsByJobRoles()
  const setSelectedDate = useSetAtom(selectedDateAtom)
  const setSelectedJobRoleId = useSetAtom(selectedJobRoleIdAtom)

  const openModal = () => {
    setIsModalOpened(true)
  }

  const handleCloseModal = () => {
    setIsModalOpened(false)
    setSelectedDate('')
    setSelectedJobRoleId(null)
  }

  const handleFooterCellClick = (date: string) => {
    openModal()
    setSelectedDate(date)
  }

  const handleDateCellClick = (date: string, jobRoleId: number) => {
    openModal()
    setSelectedDate(date)
    setSelectedJobRoleId(jobRoleId)
  }

  return (
    <PageView header={{ title: 'Coverage by Job Title', breadcrumbs: true }}>
      <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
        <div>
          <CalendarTable<JobRoleStats>
            visibleDays={calendarView}
            data={stats}
            date={date}
            tableTopRight={
              <div>
                <CalendarViewSelect />
              </div>
            }
            title={<TableTitle />}
            renderCustomTableFooter={(dates) => (
              <TableFooter
                onCellClick={handleFooterCellClick}
                dates={dates.map((d) => getDateStr(d))}
                visibleDays={calendarView}
              />
            )}
            loading={isLoading}
            onDateChange={(date) => dispatch({ type: 'setDate', payload: date })}
            renderTitleCell={({ job_role }) => <TitleCell jobRoleName={job_role} />}
            renderDateCell={(date, record) => (
              <CoverageDateCell
                date={getDateStr(date)}
                jobRoleId={record.id}
                dayOffEployeesQuantity={record.stats_by_date[getDateStr(date)].off.total}
                availableEmployeesQuantity={record.stats_by_date[getDateStr(date)].available.total}
                onClick={handleDateCellClick}
              />
            )}
          />
        </div>
      </div>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        open={isModalOpened}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <EmployeesList stats={stats} />
      </Modal>
    </PageView>
  )
}
