import { useApp } from '@/hooks'
import { view } from '@/routing'
import { navigate } from '@/routing/helpers'
import { openIdAuthAPI } from '@/services/api-service'
import { clearV1AppCash, setAccessToken } from '@/services/auth-service'
import { useMutation } from '@tanstack/react-query'
import { Spin } from 'antd'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

export const OpenIdRedirectView = view(Component, { title: () => 'SSO Verify' })

function Component() {
  const { notification } = useApp()
  const lockAPICallRef = useRef(false)
  const [searchParams] = useSearchParams()
  const completeOpenIdAuth = useMutation({ ...openIdAuthAPI.completeSignIn(), retry: 0 })

  useEffect(() => {
    // Prevent multiple API calls
    // When using StrictMode, useEffect runs twice on development
    // https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react
    if (lockAPICallRef.current) return
    lockAPICallRef.current = true

    const authResponse = Object.fromEntries(searchParams.entries())
    completeOpenIdAuth
      .mutateAsync({ auth_response: authResponse })
      .then(({ jwt_token, next_link }) => {
        clearV1AppCash()
        setAccessToken(`JWT ${jwt_token}`)
        navigate(next_link || '/', { reload: true })
      })
      .catch((reason) => {
        const errorMessage = reason?.response?.data[0] || reason.message || 'Failed to signin with Microsoft'
        notification.error({ message: errorMessage })
        navigate('/auth/login')
      })
  }, [completeOpenIdAuth, notification, searchParams])

  return (
    <div>
      <Spin size="large" />
      <h5 className="mt-7">Verifying your Microsoft account...</h5>
    </div>
  )
}
