import { TransformationTask } from '@/types/transformation-task'
import { MouseSensor, TouchSensor } from '@/utils/dnd-extended-sensors'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useState } from 'react'
import { Item } from './item'
import { SortableItem } from './sortable-item'

type Props = {
  initialItems: TransformationTask[]
  onSortingChange: (tasks: TransformationTask[]) => void
  onItemDeleteClick: (id: number) => void
  onItemEditClick: (id: TransformationTask) => void
}

export const TransformationTasksSortable = ({
  initialItems,
  onSortingChange,
  onItemDeleteClick,
  onItemEditClick
}: Props) => {
  const [items, setItems] = useState<TransformationTask[]>(initialItems)
  const [activeItem, setActiveItem] = useState<TransformationTask | null>(null)
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over?.id)
        const newItems = arrayMove(items, oldIndex, newIndex)
        onSortingChange(newItems)
        return newItems
      })
    }

    setActiveItem(null)
  }

  const handleDragStart = (event: DragStartEvent) => {
    const item = items.find((item) => item.id === event?.active?.id)
    setActiveItem(item || null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <div key={item.id} className="mb-8">
            <SortableItem transformationTask={item} onDeleteClick={onItemDeleteClick} onEditClick={onItemEditClick} />
          </div>
        ))}
        <DragOverlay>{activeItem ? <Item transformationTask={activeItem} isDragging /> : null}</DragOverlay>
      </SortableContext>
    </DndContext>
  )
}
