import dayjs from 'dayjs'
import { RATE_IN_MS } from '../constants'
import { LineItemData } from '../schemas'

export const calcRegularAmount = (lineItem: LineItemData) => {
  const unitsUsed = Math.max(Number(lineItem.units_min) || 0, calcUnitsUsed(lineItem))

  if (!(lineItem.usage_rate || lineItem.standby_rate || unitsUsed || lineItem.units_standby)) {
    return Number(lineItem.amount) || 0
  }

  const unitsStandby = Number(lineItem.units_standby) || 0
  const hands = isNaN(parseFloat(lineItem?.hands?.toString() || '')) ? 1 : Number(lineItem.hands)

  let amount =
    (1 - (Number(lineItem.discount) || 0) / 100) *
    (unitsStandby * (Number(lineItem.standby_rate) || 0) + hands * unitsUsed * (Number(lineItem.usage_rate) || 0))

  if (!isNaN(Number(lineItem.minimum))) {
    amount = Math.max(Number(lineItem.minimum) || 0, amount)
  }

  return amount
}

export const calcDiscountAmount = (lineItem: LineItemData) => {
  return -(Number(lineItem.discountable_amount) || 0) * ((Number(lineItem.discount) || 0) / 100)
}

export const calcTaxAmount = (lineItem: LineItemData) => {
  return (Number(lineItem.taxable_amount) || 0) * ((Number(lineItem.tax_rate) || 0) / 100)
}

export const calcUnitsUsed = (lineItem: LineItemData) => {
  const calcDuration = () => {
    const startDate = dayjs.parse(lineItem.start_datetime)
    const endDate = dayjs.parse(lineItem.end_datetime)

    if (!(startDate?.isValid() && endDate?.isValid())) return lineItem.units_used // invalid dates

    const durationMs = endDate?.diff(startDate, 'milliseconds')
    const factor = RATE_IN_MS[lineItem.unit as keyof typeof RATE_IN_MS]
    if (!factor) return lineItem.units_used // invalid unit

    const duration = Math.max(0, durationMs / factor || 0)

    return Number(duration.toFixed(2))
  }

  return Number(calcDuration()) || 0
}

export const calcEndDatetime = (lineItem: LineItemData) => {
  const startDate = dayjs.parse(lineItem.start_datetime)
  const unitsUsed = Number(lineItem.units_used)
  const factor = RATE_IN_MS[lineItem.unit as keyof typeof RATE_IN_MS]

  if (!(startDate?.isValid() && unitsUsed && factor)) return lineItem.end_datetime // invalid data

  const durationMs = unitsUsed * factor
  return startDate.add(durationMs, 'milliseconds')
}

export const calcAmount = (lineItem: LineItemData, preferEmpty = true): number | null => {
  if (lineItem.skip_calc) {
    return lineItem.amount
  }

  let amount: number | null = 0

  if (lineItem.discount_sku) {
    amount = calcDiscountAmount(lineItem)
  } else if (lineItem.tax_sku && !lineItem.skip_tax_calc) {
    amount = calcTaxAmount(lineItem)
  } else {
    amount = calcRegularAmount(lineItem)
  }

  if (preferEmpty && !amount) {
    amount = null
  } else {
    // strongly hint rounding to the nearest cent is intended.  `(7310 * 2.1075)` is equivalent to `15405.82499...`;
    // `x.toFixed(2)` gives "15405.82" and `(Math.round(x*100)/100).toFixed(2)` gives "15405.83"
    // ... which may simply be due to `*100` adding noise to the mantissa.
    // without using decimal types, we may encounter similar rounding issues in the future.
    amount = Number((Math.round((amount || 0) * 100) / 100).toFixed(2))
  }

  return amount
}
