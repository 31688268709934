import { reportBuilderFilterFieldApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

type Params = {
  onSuccess: () => void
}

export const useUpdateFilterfieldMutation = ({ onSuccess }: Params) => {
  const updateFilterFieldMutation = useMutation(reportBuilderFilterFieldApi.patch())

  const handleSave = async (values: any) => {
    await updateFilterFieldMutation.mutateAsync(values)

    onSuccess()
  }

  return { updateFilterFieldMutation, handleSave }
}
