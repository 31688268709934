import { useModulesQuery, usePropertiesSelectOptions } from '@/modules/module-builder/hooks'
import { Form, Select } from 'antd'
import { startCase } from 'lodash'
import { useMemo } from 'react'

type Props = {
  selectedCustomForm: number[]
  customFormsOptions: { label: string; value: number }[]
}

export const ChemicalCardTypeAttributes = ({ selectedCustomForm, customFormsOptions }: Props) => {
  const { propertiesOptions, isLoading, isFetching } = usePropertiesSelectOptions({
    customFormsIds: selectedCustomForm
  })

  const { modulesQuery } = useModulesQuery()

  const modulesOptions = useMemo(() => {
    return (
      modulesQuery.data?.items.map((item) => ({
        label: item.title,
        value: item.id
      })) ?? []
    )
  }, [modulesQuery.data?.items])

  return (
    <div className="grid grid-cols-3 gap-x-16">
      {[
        'position',
        'title',
        'subTitle',
        'maxVolume',
        'volume',
        'vendor',
        'setupDate',
        'notes',
        'iconCode',
        'safetyDataSheet',
        'name',
        'causes',
        'health',
        'reactivity',
        'specific',
        'fire'
      ].map((field) => (
        <Form.Item key={field} label={startCase(field)} name={['data', field]}>
          <Select
            placeholder="Select"
            showSearch
            options={propertiesOptions}
            disabled={isLoading || isFetching}
            loading={isLoading || isFetching}
            optionFilterProp="label"
          />
        </Form.Item>
      ))}
      <Form.Item label="Delivery Ticket - Custom Form" name={['data', 'deliveryTicket', 'formId']}>
        <Select
          placeholder="Select"
          options={[
            {
              label: 'Not Set',
              value: null
            },
            ...customFormsOptions
          ]}
        />
      </Form.Item>
      <Form.Item label="Delivery Ticket - Module" name={['data', 'deliveryTicket', 'moduleId']}>
        <Select
          options={[
            {
              label: 'Not Set',
              value: null
            },
            ...modulesOptions
          ]}
        />
      </Form.Item>
    </div>
  )
}
