import { useApp } from '@/hooks'
import { useViewData } from '@/layouts'
import { refreshQueries } from '@/query'
import { ticketApi } from '@/services/api-service'
import { SaveButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import { FC, useCallback } from 'react'
import { ScheduleViewData } from '../../schemas'
import { useJobResourceSelection, useJobRoles, useJobTicket } from '../hooks'

export const SaveJobTicketResources: FC = () => {
  const { data } = useViewData<ScheduleViewData>()
  const { notification, l } = useApp()
  const { jobTicket } = useJobTicket()
  const { hasResourceSelectionChanges, selectedResources } = useJobResourceSelection()
  const { jobRoles } = useJobRoles()
  const patchTicketMutation = useMutation(ticketApi.patch({ params: { fields: 'id' } }))

  const onSave = useCallback(async () => {
    const payload: {
      id: number
      status?: string
      ticket_properties?: { property: number; value: string }[]
    } = {
      id: jobTicket?.id || 0,
      ticket_properties: []
    }
    jobRoles
      .filter((role) => role.resource_type === data.type)
      .forEach((role) => {
        payload.ticket_properties?.push({
          property: role.property_id,
          value: orderBy(
            selectedResources.filter((res) => res.job_role__id === role.id).map((res) => res.assigned_sku_id)
          )
            .filter((skuId) => skuId)
            .join(',')
        })
      })

    try {
      await patchTicketMutation.mutateAsync(payload)
      notification.success({
        message: 'Resources Saved',
        description: `Resources for ${l('ticket__name__label')} ${jobTicket?.name} has been saved`
      })
      refreshQueries(['jas/job_tickets', 'v2/jas/resources'])
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Resources Save Failed',
        description: `Failed to save resources for ${l('ticket__name__label')} ${jobTicket?.name}`
      })
    }
  }, [jobRoles, jobTicket, patchTicketMutation, selectedResources, notification])

  return (
    <SaveButton onSave={onSave} disabled={!hasResourceSelectionChanges} loading={patchTicketMutation.isLoading}>
      Save Assignees
    </SaveButton>
  )
}
