import { PropertyGroup } from '@/types/property-group'
import { useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { propertiesKeysAtom } from '../../atoms'
import { useCustomFormQuery } from '../../hooks'

export const usePropertyGroups = () => {
  const { customFormQuery } = useCustomFormQuery()
  const setPropertiesKeys = useSetAtom(propertiesKeysAtom)

  const propertyGroups = useMemo(
    () => [...(customFormQuery.data?.property_groups ?? [])].sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0)),
    [customFormQuery.data?.property_groups]
  )

  useEffect(() => {
    setPropertiesKeys(extractPropertiesKeys(propertyGroups))
  }, [propertyGroups, setPropertiesKeys])

  return { propertyGroups }
}

const extractPropertiesKeys = (propertyGroups: PropertyGroup[]) => {
  const propertiesKeysPool: { [n: string]: string } = {}
  propertyGroups.forEach(({ properties }) =>
    properties?.forEach(({ id, key }) => {
      propertiesKeysPool[id] = key
    })
  )
  return propertiesKeysPool
}
