import { useApp, useAppDispatch } from '@/hooks'
import JobManagerFilterModal from '@/modules/jas/job-manager/components/job-filter/FilterModal'
import { FilterTags } from '@/modules/jas/job-manager/components/job-filter/FilterTags'
import { StatusSelect } from '@/modules/jas/job-manager/components/job-filter/StatusSelect'
import { SEARCH_FIELDS, SEARCH_TYPES } from '@/modules/ticket/constants'
import { getControlFlagsBySearch } from '@/modules/ticket/list/helpers'
import { queryClient } from '@/query'
import { Icon } from '@/ui/icons'
import { getDateRange, getLabel } from '@/utils'
import { filterSelectOption } from '@/utils/filter-select-option'
import { Button, DatePicker, Form, Input, Select, Tooltip } from 'antd'
import cn from 'classnames'
import { Dayjs } from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useJobManager, useJobTicketsQuery } from '../../hooks'
import { setAdvanceSearch, setSearch } from '../../slice'
import { Filters } from '../../types'
import { TableViewSelect } from './TableViewSelect'

export const TopFilter: React.FC = () => {
  const dispatch = useAppDispatch()
  const { labels } = useApp()
  const { search, advanceSearch } = useJobManager()
  const { isFetching } = useJobTicketsQuery()
  const [filterOpen, setFilterOpen] = useState(false)
  const [form] = Form.useForm()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchFields = useMemo(
    () => SEARCH_FIELDS.map((s) => ({ label: getLabel(s.label, labels), value: s.value })),
    [labels]
  )

  const controlFlags = getControlFlagsBySearch(search)

  const onFieldChange = (value: string) => {
    let type = 'icontains'
    if (value === 'computed_status') type = 'in'
    else if (value === 'timepoint_due') type = 'range'

    dispatch(
      setSearch({
        field: value,
        type: type,
        value: ''
      })
    )
  }
  const onTypeChange = (value: string) => {
    dispatch(
      setSearch({
        ...search,
        type: value,
        value: value === 'isnull' ? '0' : ''
      })
    )
  }
  const onValueChange = (value: string) => {
    dispatch(
      setSearch({
        ...search,
        value: (value || '').trim()
      })
    )
  }

  const onTextChange: any = useDebouncedCallback((e) => {
    onValueChange((e.target.value || '').trim())
  }, 500)
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    onValueChange(dates ? `${dateStrings[0]},${dateStrings[1]}` : '')
  }
  const handleAdvFilterSubmit = (filters: Filters) => {
    dispatch(setAdvanceSearch(filters))
    setFilterOpen(!filterOpen)
  }
  const handleFilterModalToggle = () => {
    setFilterOpen(!filterOpen)
  }

  const refreshJobTickets = () => {
    // invalidate cache and refetch
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'jas/job_tickets'
    })
  }

  const clearAllFilters = () => {
    dispatch(
      setAdvanceSearch(
        Object.keys(advanceSearch).reduce((acc: Record<string, any>, key) => {
          acc[key] = ''
          return acc
        }, {})
      )
    )

    // clear form items
    form.resetFields()
    Object.keys(advanceSearch).forEach((key) => {
      form.setFieldsValue({ [key]: '' })
    })
  }

  useEffect(() => {
    // TODO: Use query parameters for filters
    const ticketName = searchParams.get('name')
    if (ticketName && ticketName.length > 0 && form) {
      form.setFieldValue('name', ticketName)
      dispatch(setAdvanceSearch({ ...advanceSearch, name: ticketName }))

      // Delete the query param to avoid this block code from repeated execution
      searchParams.delete('name')
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, form])

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap items-center -mx-8 mb-16 md:overflow-x-auto md:min-h-[60px] pb-10">
        <div className="hidden md:block md:order-1 px-8">
          <Tooltip placement="top" title={'Filter'}>
            <Button
              type="primary"
              className="flex items-center justify-center"
              icon={<Icon name="mi:filter_alt" />}
              onClick={handleFilterModalToggle}
            />
          </Tooltip>
        </div>
        <div className="md:order-2 flex flex-wrap lg:flex-nowrap justify-center items-center w-full md:w-auto mt-10 md:mt-0 pb-10 md:pb-0">
          <div className="px-8 flex-1 sm:w-[200px]">
            <Select
              className="w-full"
              placeholder="Search Field"
              value={search.field}
              onChange={onFieldChange}
              optionLabelProp="label"
              key="value"
              defaultActiveFirstOption={true}
              options={searchFields}
              showSearch
              filterOption={filterSelectOption}
            />
          </div>

          <div className={cn('px-8 flex-1 lg:min-w-[200px]', { hidden: !controlFlags.type })}>
            <Select
              className="w-full"
              placeholder="Search Type"
              value={search.type}
              onChange={onTypeChange}
              optionLabelProp="label"
              options={SEARCH_TYPES}
              defaultActiveFirstOption={true}
            />
          </div>

          {controlFlags.text && (
            <div className="px-8 w-full mt-8 lg:mt-0 lg:w-[350px]">
              <Input.Search
                autoFocus
                placeholder="Search"
                className="border-l-0 rounded w-full"
                onChange={onTextChange}
              />
            </div>
          )}

          {controlFlags.yesNo && (
            <div className="px-8 flex-1 lg:min-w-[200px]">
              <Select
                className="w-full"
                placeholder="Filter Type"
                value={search.value}
                onChange={onValueChange}
                optionLabelProp="label"
                options={[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' }
                ]}
                defaultActiveFirstOption={true}
              />
            </div>
          )}

          {controlFlags.status && (
            <div className="px-8 flex-1 lg:min-w-[150px]">
              <StatusSelect value={search.value} onChange={onValueChange} />
            </div>
          )}

          {controlFlags.date && (
            <div className="px-8 w-full mt-8 lg:mt-0 lg:w-[350px]">
              <DatePicker.RangePicker className={'w-full'} presets={getDateRange()} onChange={onRangeChange} />
            </div>
          )}
        </div>

        <div className="md:order-3 px-8">
          <Tooltip placement="top" title={isFetching ? 'Refresh...' : 'Refresh'}>
            <Button disabled={isFetching} onClick={refreshJobTickets}>
              <Icon name="mi:refresh" className={cn({ 'animate-spin': isFetching })} />
            </Button>
          </Tooltip>
        </div>

        <div className="md:order-4 ml-auto px-8">
          <TableViewSelect />
        </div>
      </div>

      <JobManagerFilterModal
        open={filterOpen}
        onCancel={handleFilterModalToggle}
        onSubmit={handleAdvFilterSubmit}
        form={form}
        onClear={clearAllFilters}
      />

      <div className="hidden lg:block mb-16 -mt-20">
        <FilterTags onClear={clearAllFilters} form={form} />
      </div>
    </>
  )
}
