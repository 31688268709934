import { Select } from 'antd'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { filterLogicAtom, selectedScheduleTypeAtom } from '../atoms'
import { EMPLOYEES_SCHEDULE_OPTIONS, FILTER_LOGIC_OPTIONS } from '../constants'

export const ScheduleTypeSelect: FC = () => {
  const [selectedScheduleType, setSelectedScheduleType] = useAtom(selectedScheduleTypeAtom)
  const [filterLogic, setFilterLogic] = useAtom(filterLogicAtom)

  return (
    <div className="flex gap-[15px] items-center">
      <p className="mb-0">Employees Status</p>
      <Select
        className="min-w-[150px]"
        options={EMPLOYEES_SCHEDULE_OPTIONS}
        value={selectedScheduleType}
        onChange={(v) => setSelectedScheduleType(v)}
      />
      <Select
        className="min-w-[100px]"
        options={FILTER_LOGIC_OPTIONS}
        value={filterLogic}
        onChange={(v) => setFilterLogic(v)}
      />
    </div>
  )
}
