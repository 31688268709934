import { useApp } from '@/hooks'
import { view } from '@/routing'
import { lineItemApi } from '@/services/api-service'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMutation } from '@tanstack/react-query'
import { Spin, Table } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../atoms'
import { useCustomFormQuery } from '../../hooks'
import { AddLineItemModal } from './add-line-item-modal'
import { EditLineItemModal } from './edit-line-item-modal'
import { SortableRow } from './sortable-row'

export const LineItems = view(Component, {
  title: () => 'Line Items',
  scoped: false
})

function Component() {
  const { customFormQuery } = useCustomFormQuery()
  const setCustomForm = useSetAtom(setCustomFormAtom)
  const { notification } = useApp()
  const [editingLineItem, setEditingLineItem] = useState<any>(null)

  const deleteLineItemMutation = useMutation({
    ...lineItemApi.delete()
  })

  const updateCustomFormMutation = useMutation({
    ...lineItemApi.update()
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineItems = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v?.line_items), [])) ?? ([] as any[])

  const [lineItemsOrder, setLineItemsOrder] = useState(
    lineItems.map((item) => ({ id: item.id, sequence: item.sequence })).sort((a, b) => a.sequence - b.sequence)
  )

  const dataSource = useMemo(() => {
    return lineItemsOrder.map(({ id }) => ({ ...lineItems.find((item) => item.id === id), key: id })).filter(Boolean)
  }, [lineItemsOrder, lineItems])

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setLineItemsOrder((prev) => {
        const activeIndex = prev.findIndex(({ id }) => id === active.id)
        const overIndex = prev.findIndex(({ id }) => id === over?.id)
        const newOrder = arrayMove(prev, activeIndex, overIndex)
        const items = newOrder.map((item, index) => ({ ...item, sequence: index + 1 }))

        const updateLineItemsOrder = async (lineItems: any[]) => {
          const mutations = items.map((item) => updateCustomFormMutation.mutateAsync(item))
          await Promise.all(mutations)

          notification.success({
            message: 'Reordered default line items'
          })
        }

        updateLineItemsOrder(newOrder)
        return items
      })
    }
  }

  const handleDelete = async (id: number) => {
    await deleteLineItemMutation.mutateAsync({ id })
    setLineItemsOrder((prev) => prev.filter((item) => item.id !== id))

    setCustomForm({
      line_items: lineItems.filter((item) => item.id !== id)
    })

    notification.success({
      message: 'Line Item deleted successfully'
    })
  }

  const handleAddLineItemFinished = (addedLineItem: any) => {
    setCustomForm({
      line_items: [...lineItems, addedLineItem]
    })

    setLineItemsOrder((prev) => [
      ...prev,
      {
        id: addedLineItem.id,
        sequence: addedLineItem.sequence
      }
    ])
  }

  return (
    <Spin spinning={customFormQuery.isLoading}>
      <div>
        <div className="flex justify-between mb-14 items-center">
          <h4 className="font-bold mb-0">Default Line Items</h4>
          <AddLineItemModal
            onAddFinished={handleAddLineItemFinished}
            lastSequence={lineItemsOrder.length > 0 ? lineItemsOrder[lineItemsOrder.length - 1].sequence + 1 : 1}
          />
        </div>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
          <SortableContext items={lineItemsOrder} strategy={verticalListSortingStrategy}>
            <Table
              loading={deleteLineItemMutation.isLoading || updateCustomFormMutation.isLoading}
              pagination={false}
              dataSource={dataSource}
              rowKey="key"
              components={{
                body: {
                  row: (props: any) => (
                    <SortableRow
                      {...props}
                      onDelete={handleDelete}
                      onEdit={(lineItem: any) => setEditingLineItem(lineItem)}
                    />
                  )
                }
              }}
            >
              <Table.Column title="SKU" width={100} dataIndex="sku" />
              <Table.Column title="Description" dataIndex="description" />
              <Table.Column key="sort" width={120} align="right" />
            </Table>
          </SortableContext>
        </DndContext>
        <EditLineItemModal
          lineItem={editingLineItem}
          open={!!editingLineItem}
          onCancel={() => setEditingLineItem(null)}
          onSaveFinished={() => setEditingLineItem(null)}
        />
      </div>
    </Spin>
  )
}
