import { Button } from '@/ui/button'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Popconfirm, RowProps, Space } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import React, { useMemo } from 'react'
import { equipmentAtom } from '../../atoms'
import { Component } from '../types'

export const SortableRow = ({ children, ...props }: RowProps) => {
  const setEquipment = useSetAtom(equipmentAtom)

  const componentsOrder = JSON.parse(
    useAtomValue(useMemo(() => selectAtom(equipmentAtom, (v) => v?.components_order_json), [])) ?? '[]'
  ) as number[]

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key' as keyof typeof props]
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  }

  const handleDelete = (component?: Component) => {
    if (!component) {
      console.warn('Component not found in sortable row', props['data-row-key' as keyof typeof props])
      return
    }

    setEquipment((prev) => ({
      ...prev,
      components: prev.components?.filter((c) => c.id !== component.id),
      components_order_json: JSON.stringify(componentsOrder.filter((id) => id !== component.sub_component?.id))
    }))
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          const component = (child as Record<string, any>)?.props?.record as Component | undefined
          const sku = component?.data?.sku

          return React.cloneElement(child as React.ReactElement, {
            children: (
              <Space>
                <Popconfirm
                  title="Remove Component?"
                  description={`Are you sure you want to remove ${sku}?`}
                  onConfirm={() => handleDelete(component)}
                >
                  <Button iconName="fa:trash" type="text" />
                </Popconfirm>
                <div ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners}>
                  <Button iconName="mi:drag_indicator" type="text" className="cursor-move" />
                </div>
              </Space>
            )
          })
        }
        return child
      })}
    </tr>
  )
}
