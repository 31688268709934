import { officeApi } from '@/services/api-service'
import { Icon, QuerySelect } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { z } from 'zod'
import { useButtonContext } from '../../../../hooks'
import { WellData } from '../types'

type Props = {
  selectedWellData: WellData | null
  setSelectedWellData: Dispatch<SetStateAction<WellData | null>>
}

export const FieldCustomerOffice = ({ selectedWellData, setSelectedWellData }: Props) => {
  const { ticket } = useButtonContext()
  const enableOffice = !ticket.customer_office_id

  const customerQuery = useQuery({
    ...officeApi.list({
      name__iexact: selectedWellData?.customerName,
      fields: z.object({ id: z.number(), name: z.string() })
    }),
    enabled: !!selectedWellData
  })

  const handleCustomerOfficeChange = useCallback(
    (value: { label: string; id: number }) => {
      setSelectedWellData((prev) => (prev ? { ...prev, customerName: value.label, customerId: value.id } : prev))
    },
    [setSelectedWellData]
  )

  // set customer office if found
  useEffect(() => {
    const foundCustomer = customerQuery.data?.items[0]

    if (foundCustomer) {
      handleCustomerOfficeChange({ label: foundCustomer.name, id: foundCustomer.id })
    }
  }, [customerQuery.data?.items, handleCustomerOfficeChange])

  if (!enableOffice) {
    return (
      <Form.Item
        label="Customer Office"
        help={
          <div className="text-blue-400 pl-2 font-medium mt-4">
            <Icon name="fa:info-circle" className="mr-4" />
            Ticket's customer office
          </div>
        }
      >
        <Input disabled value={ticket.customer_office__name || ''} />
      </Form.Item>
    )
  }

  return (
    <Form.Item
      label="Customer Office"
      help={
        selectedWellData &&
        (customerQuery.data?.items.length === 0 ? (
          <div className="text-warning pl-2 font-medium mt-4">
            <Icon name="fa:exclamation-triangle" className="mr-4" />
            Customer office not found in Oil Command.
          </div>
        ) : (
          <div className="text-success pl-2 font-medium mt-4">
            <Icon name="fa:check-circle" className="mr-4" />
            Customer office found in Oil Command.
          </div>
        ))
      }
    >
      <QuerySelect
        disabled={!selectedWellData}
        loading={customerQuery.isFetching}
        apiEndpoint={officeApi.list}
        apiSearchBy={(text) => ({ name__icontains: text })}
        value={selectedWellData?.customerName}
        labelInValue
        onChange={handleCustomerOfficeChange}
        renderOption={(item) => ({ value: item.id, label: item.name })}
      />
    </Form.Item>
  )
}
