import { z } from 'zod'

// Enum for job statuses
const JobStatus = z.enum(['D', 'Q', 'I', 'F', 'E', 'R', 'H'])

// ResultType schema
const ResultSchema = z.object({
  success: z.boolean().nullable(),
  message: z.string().nullable(),
  result_uri: z.string().nullable(),
  items: z
    .array(
      z.object({
        name: z.string(),
        link: z.string(),
        errors: z.array(z.string()).nullable()
      })
    )
    .nullable(),
  errors: z.array(z.string()).nullable(),
  updated_ticket_ids: z.array(z.any()).nullable(), // Generic `list` in Python mapped to `any` in TypeScript
  updated_line_item_ids: z.array(z.any()).nullable()
})

// BackgroundJobState schema
const BackgroundJobStateSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: JobStatus,
  handled_count: z.number().int(),
  total_count: z.number().int(),
  progress: z.number().min(0).max(1), // Progress from 0.00 to 1.00
  started_at: z.string().datetime().nullable(), // ISO datetime string
  finished_at: z.string().datetime().nullable() // ISO datetime string
})

const BackgroundJobStateDetailSchema = BackgroundJobStateSchema.extend({
  result: ResultSchema,
  metadata: z.record(z.any())
})

export { BackgroundJobStateSchema, BackgroundJobStateDetailSchema, ResultSchema as ResultTypeSchema }
export type BackgroundJobState = z.infer<typeof BackgroundJobStateSchema>
export type BackgroundJobStateDetail = z.infer<typeof BackgroundJobStateDetailSchema>
