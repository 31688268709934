import { DATE_FORMAT, ISO_DATE_FORMAT } from '@/constants/date'
import { useApp } from '@/hooks'
import { pricingCustomerApi } from '@/services/api-service'
import { QueryDeleteButton, Tooltip } from '@/ui'
import { DatePicker } from 'antd'
import cn from 'classnames'
import dayjs from 'dayjs'
import { useUpdatePricingExpiry } from '../../hooks'
import { PricingCustomer } from '../../schemas'

type Props = {
  pricingCustomer: PricingCustomer
}

export const CompanyColumnTitle = ({ pricingCustomer }: Props) => {
  const { l } = useApp()
  const { updatePricingExpiry } = useUpdatePricingExpiry()
  const { customer_office__name, id, expiry } = pricingCustomer

  return (
    <div className="relative flex flex-col">
      <div className={'flex flex-row items-center relative -top-10'}>
        <Tooltip className="flex-grow text-nowrap overflow-hidden overflow-ellipsis" title={customer_office__name}>
          {customer_office__name}
        </Tooltip>

        <QueryDeleteButton
          size={'small'}
          id={pricingCustomer.id}
          api={pricingCustomerApi.delete}
          name={l('Pricing Customer')}
        />
      </div>
      <div className={'absolute -bottom-8 w-full pr-20'}>
        <DatePicker
          variant={'borderless'}
          suffixIcon={null}
          size="small"
          placeholder="No Expiry"
          format={DATE_FORMAT}
          defaultValue={expiry ? dayjs(expiry) : undefined}
          onChange={(date) => updatePricingExpiry(id, date!.format(ISO_DATE_FORMAT))}
          allowClear={false}
          className={cn('p-0 [&_input]:cursor-pointer w-full', {
            // Text color is red if expiry is before today
            '!text-danger': expiry && dayjs(expiry).isBefore(dayjs()),
            // Text color is yellow if expiry is within 1 week
            '!text-warning': expiry && dayjs(expiry).isBetween(dayjs(), dayjs().add(1, 'week'), 'day', '[]'),
            // Text color is green if expiry is after today
            '!text-success': expiry && dayjs(expiry).isAfter(dayjs())
          })}
        />
      </div>
    </div>
  )
}
