import { ResourceTypeOption } from './types'

export const RESOURCE_TYPE_MAP: Record<string, ResourceTypeOption> = {
  employees: {
    path: 'employees',
    type: 'Employee',
    label: 'Employees'
  },
  equipment: {
    path: 'equipment',
    type: 'Equipment',
    label: 'Equipment'
  }
}

export const RESOURCE_STATUS_MAP: Record<string, { label: string; color: string }> = {
  D: { label: 'Draft', color: 'gray' },
  A: { label: 'Active', color: 'green' },
  I: { label: 'Inactive', color: 'blue' },
  C: { label: 'Closed', color: 'orange' }
}
