import { filterSelectOption } from '@/utils/filter-select-option'
import { Form, Select } from 'antd'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { CUSTOM_FORM_TYPES } from '../../../../constants'
import { customFormAtom, isTypeErrorAtom } from '../../../atoms'

export const TypeField = () => {
  const type = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v.type), []))
  const setCustomForm = useSetAtom(customFormAtom)
  const [isTypeError, setIsTypeError] = useAtom(isTypeErrorAtom)

  return (
    <Form.Item
      label="Custom Form Type"
      required
      validateStatus={isTypeError ? 'error' : undefined}
      help={isTypeError ? 'Please select type' : undefined}
    >
      <Select
        options={Object.entries(CUSTOM_FORM_TYPES).map(([value, key]) => ({
          label: key,
          value
        }))}
        value={type}
        onChange={(value) => {
          if (isTypeError) {
            setIsTypeError(false)
          }

          setCustomForm((prev) => ({
            ...prev,
            type: value
          }))
        }}
        showSearch
        filterOption={filterSelectOption}
        data-cy="custom-form-type-select"
      />
    </Form.Item>
  )
}
