import { ListResponse } from '@/types/api/core'
import { UseQueryResult } from '@tanstack/react-query'
import { atomWithReset } from 'jotai/utils'

export const ticketsQueryResultAtom = atomWithReset<{ query?: UseQueryResult<ListResponse<any>, any>; total: number }>({
  total: 0
})
export const activeTableViewIdAtom = atomWithReset<number | null>(null)
export const allTicketsSelectedAtom = atomWithReset<boolean>(false)
export const selectedTicketsAtom = atomWithReset<Record<string, any>[]>([])

// todo: migrate custom filter to use query filter
export const customFiltersAtom = atomWithReset<{ [n: string]: string }>({})
