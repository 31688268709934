import { Event } from '@/types/event'
import { ActivityContent } from './activity-item-content'
import { ActivityItemHeader } from './activity-item-header'
import { ActivityItemTimeline } from './activity-item-timeline'
import { ActivityItemTrigger } from './activity-item-trigger'

type Props = {
  event: Event
  isCollapsed: boolean
  onTriggerClick: (id: number) => void
}

export const ActivityItem = ({ event, isCollapsed, onTriggerClick }: Props) => {
  return (
    <div className="relative pl-24 pr-16 py-16 odd:bg-background-accent">
      <ActivityItemTimeline />
      <ActivityItemHeader event={event} />
      <ActivityItemTrigger event={event} isCollapsed={isCollapsed} onClick={onTriggerClick} />
      <ActivityContent isCollapsed={isCollapsed} event={event} />
    </div>
  )
}
