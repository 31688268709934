import { ContactSelect } from '@/components/contact-select'
import { useAtom } from 'jotai'
import { equipmentAtom } from '../atoms'

export const AssigneeField = () => {
  const [equipment, setEquipment] = useAtom(equipmentAtom)

  return (
    <ContactSelect
      selectProps={{
        placeholder: 'Select',
        value: equipment.assignee,
        onChange: (value) => setEquipment({ ...equipment, assignee: value ?? null }),
        ['data-cy' as any]: 'sku-assignee-select'
      }}
    />
  )
}
