import { companyApi, propertyApi } from '@/services/api-service'
import { Button, Modal, Select } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Modal as AntModal, Form, List } from 'antd'
import { useState } from 'react'
import { usePropertiesQuery } from '../../hooks'

export const ShareModal = () => {
  const form = Form.useFormInstance()
  const propertyId = Form.useWatch('id', form) as number | undefined
  const [modal, contextHolder] = AntModal.useModal()
  const { properties } = usePropertiesQuery()
  const property = properties.find((p) => p.id === propertyId)
  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedCompanies, setSelectedCompanies] = useState<{ id: number; name: string }[]>([])

  const updatePropertyMutation = useMutation(propertyApi.create({}, `${property?.id || ''}/shared_companies`))

  const companiesQuery = useQuery({
    ...companyApi.list<{ id: number; name: string }>({ compact: true, name__icontains: searchText }),
    enabled: isOpen && !!searchText.trim(),
    select: (data) => {
      if (!searchText.trim()) {
        return {
          items: [],
          total: 0
        }
      }

      return data
    }
  })

  const handleSave = async () => {
    if (!property) {
      return
    }

    await updatePropertyMutation.mutateAsync({
      id: property.id,
      company_ids: selectedCompanies.map((c) => c.id)
    })
    setIsOpen(false)
  }

  return (
    <>
      {contextHolder}
      <Button
        iconName="fa:share-alt"
        onClick={() => {
          if (!property) {
            return
          }

          if (property.link) {
            modal.error({
              title: 'Cannot Share Linked Property',
              content: 'Please share a property which is not linked to another property.'
            })
            return
          }

          setIsOpen(true)
        }}
      >
        Share
      </Button>
      <Modal
        title="Share Property Choices"
        okText="Save"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={handleSave}
      >
        <Form layout="vertical">
          <Form.Item label="Search Company">
            <Select
              placeholder="Start typing to search"
              value={[]}
              showSearch
              onSearch={setSearchText}
              options={companiesQuery.data?.items.map((c) => ({ value: c.id, label: c.name })) || []}
              labelInValue
              onChange={(val) => {
                setSelectedCompanies((prev) => [...prev, { id: val.value, name: val.label }])
                setSearchText('')
              }}
              onBlur={() => {
                setSearchText('')
                companiesQuery.refetch()
              }}
            />
          </Form.Item>
        </Form>
        {selectedCompanies.length > 0 && (
          <List
            itemLayout="horizontal"
            className="mb-24"
            dataSource={selectedCompanies}
            renderItem={(item) => (
              <List.Item
                extra={
                  <Button
                    type="text"
                    danger
                    iconName="fa:trash"
                    onClick={() => setSelectedCompanies((prev) => prev.filter((c) => c.id !== item.id))}
                  />
                }
              >
                {item.name}
              </List.Item>
            )}
          />
        )}
      </Modal>
    </>
  )
}
