import { userApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation } from '@tanstack/react-query'
import { Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { formTypeAtom, sharedDataAtom } from '../atom'
import { useFetchProfile } from '../hooks'
import {
  selectedAutofillTabAtom,
  selectedMetaAfeAtom,
  selectedMetaCustomerAtom,
  selectedMetaJobCodeAtom,
  selectedMetaLocationAtom,
  selectedMetaOfficerAtom,
  selectedPropertyAtom
} from './atoms'
import {
  PropertyList,
  useProperty,
  useSelectedAfe,
  useSelectedCustomer,
  useSelectedJobCode,
  useSelectedLocation,
  useSelectedOffice
} from './hooks'
// import RecentActivity from './recentActivity/RecentActivity'
import RecentActivity from './recentActivity/RecentActivity'
import { TabBody } from './tabBody'
import { TabHeader } from './tabHeader'

export const AutofillTab = () => {
  const { data: profileData, refetch } = useFetchProfile()
  const formType = useAtomValue(formTypeAtom)
  const setSelectedPropertyList = useSetAtom(selectedPropertyAtom)

  const [autofillForm] = useForm()
  const formValues = Form.useWatch([], autofillForm)
  const sharedData = useAtomValue(sharedDataAtom)
  const selectedAutofill = useAtomValue(selectedAutofillTabAtom)

  const setSelectedMetaAfe = useSetAtom(selectedMetaAfeAtom)
  const setSelectedMetaCustomer = useSetAtom(selectedMetaCustomerAtom)
  const setSelectedMetaOffice = useSetAtom(selectedMetaOfficerAtom)
  const setSelectedMetaJobCode = useSetAtom(selectedMetaJobCodeAtom)
  const setSelectedMetaLocation = useSetAtom(selectedMetaLocationAtom)
  const params = useParams()
  // selected meta filed
  const selectedMetaOffice = useSelectedOffice()
  const selectedMetaLocation = useSelectedLocation()
  const selectedMetaJobCode = useSelectedJobCode()
  const selectedMetaAfe = useSelectedAfe()
  const selectedMetaCustomer = useSelectedCustomer()
  const {
    data: { items: propertyList } = {}
  } = useProperty()
  const propertyListByIdMap = useMemo(() => {
    const result: { [key: string]: PropertyList } = {}
    propertyList?.forEach((p) => {
      result[p.id] = p
    })
    return result
  }, [propertyList])

  const mutationSpec = formType === 'add' ? userApi.create() : userApi.update()
  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async () => {
      notification.success({ message: `Autofill ${formType === 'add' ? 'Added' : 'Updated'}` })
      await refetch()
    },
    onError: (error: Error | any) => {
      if (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors.length > 0) {
        notification.error({ message: error?.response?.data?.non_field_errors[0] })
      } else {
        notification.error({ message: `Autofill failed to ${formType === 'add' ? 'Add' : 'Update'}` })
      }
      console.log(error)
    }
  })

  const handleFormSubmit = async () => {
    let fields = {}
    // let properties = {}
    const metaFields = ['afe', 'customer_office', 'location', 'job_code', 'customer']
    if (selectedMetaAfe.data) {
      const afeItem = selectedMetaAfe.data
      const afeValues = {
        id: afeItem?.id,
        user_afe_no: afeItem?.user_afe_no,
        selectedAFE: afeItem
      }
      fields = { ...fields, afe: afeValues }
    }
    if (selectedMetaOffice.data?.items) {
      const officeItem = selectedMetaOffice.data?.items
      const customerOfficeValues = {
        id: officeItem?.id,
        name: officeItem?.name,
        company: officeItem?.company.id
      }
      fields = { ...fields, customer_office: customerOfficeValues }
    }
    if (selectedMetaLocation.data) {
      const locations = selectedMetaLocation.data
      const locationValues = {
        id: locations?.id,
        name: locations?.name,
        selectedLocation: locations
      }
      fields = { ...fields, location: locationValues }
    }
    if (selectedMetaJobCode.data) {
      const jobcodes = selectedMetaJobCode.data

      const jobCodeValues = {
        id: jobcodes?.id,
        user_job_code_no: jobcodes?.user_job_code_no,
        selectedJob: jobcodes
      }

      fields = { ...fields, job_code: jobCodeValues }
    }
    if (selectedMetaCustomer.data) {
      const customers = selectedMetaCustomer.data
      fields = { ...fields, customer: customers }
    }

    const addedProperties = Object.entries(formValues).filter(([key]) => !metaFields.includes(key))
    const properties = addedProperties.map((prop) => {
      return {
        id: Number(prop[0]),
        key: propertyListByIdMap[Number(prop[0])].key,
        value: prop[1] as string
      }
    })

    let profileAutofills = profileData?.userdata_json?.auto_fills ?? []
    if (profileAutofills.length > 0) {
      const currentAutofillIndex = profileAutofills.findIndex((af) => af.custom_form_id === selectedAutofill)
      if (currentAutofillIndex !== -1) {
        profileAutofills[currentAutofillIndex] = {
          ...profileAutofills[currentAutofillIndex],
          fields,
          properties
        }
      } else {
        if (selectedAutofill && fields) {
          profileAutofills = [
            ...profileAutofills,
            {
              custom_form_id: selectedAutofill,
              fields,
              properties
            }
          ]
        }
      }
    } else {
      if (selectedAutofill && fields) {
        profileAutofills = [
          ...profileAutofills,
          {
            custom_form_id: selectedAutofill,
            fields,
            properties
          }
        ]
      }
    }

    const data = {
      ...sharedData,
      id: profileData?.user?.pk,
      contact_id: profileData?.contact?.id,
      company_id: sharedData.company_id,
      profile_id: Number(params?.id),
      role_id: profileData?.role,
      phone_number: sharedData.phone_number
        ? `+1${sharedData?.phone_number?.replace(/\D/g, '')}`
        : sharedData?.phone_number,
      userdata_json: {
        ...profileData?.userdata_json,
        ...sharedData?.userdata_json,
        auto_fills: profileAutofills
      }
    }
    saveFormMutation.mutateAsync(data)
  }
  useEffect(() => {
    if (profileData && selectedAutofill) {
      autofillForm.resetFields()
      setSelectedPropertyList([])
      const autofills = profileData?.userdata_json?.auto_fills
      const currentAutofillData = autofills?.find((autofill) => autofill.custom_form_id === selectedAutofill)
      const selectedMetaFields = currentAutofillData?.fields
      const selectedProperties = currentAutofillData?.properties
      if (selectedMetaFields) {
        Object.entries(selectedMetaFields).map((data) => {
          const key = data[0]
          let values = {
            label: data[1].name,
            value: data[1].id
          }

          if (key === 'afe') {
            values = {
              label: data[1].user_afe_no as string,
              value: data[1].id
            }
            setSelectedMetaAfe(data[1].id)
          }
          if (key === 'customer') {
            values = {
              label: `${data[1].company__name}, ${data[1].first_name} ${data[1].last_name}` as string,
              value: data[1].id
            }
            setSelectedMetaCustomer(data[1].id)
          }
          if (key === 'job_code') {
            values = {
              label: data[1].user_job_code_no as string,
              value: data[1].id
            }
            setSelectedMetaJobCode(data[1].id)
          }
          if (key === 'location') {
            setSelectedMetaLocation(data[1].id)
          }
          if (key === 'customer_office') {
            setSelectedMetaOffice(data[1].id)
          }
          autofillForm.setFieldValue(key, values)
        })
      }

      if (selectedProperties) {
        const ids = selectedProperties?.map((data) => {
          autofillForm.setFieldValue([data.id], data.value)
          return data.id
        })
        const idsSet = new Set(ids)
        // atom
        setSelectedPropertyList([...idsSet])
      }
    }
  }, [profileData, autofillForm, selectedAutofill])

  return (
    <>
      <div className="flex items-center justify-between bg-white mb-4">
        <h4>Autofill</h4>

        <div className="flex gap-8">
          <Button type="default" className="group hover:!text-gray-500 hover:opacity-75">
            <Icon name="svg:cancel" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" /> Cancel
          </Button>
          <Button
            type="success"
            onClick={handleFormSubmit}
            disabled={saveFormMutation.isLoading}
            loading={saveFormMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            {formType === 'add' ? 'Save' : 'Update'}
          </Button>
        </div>
      </div>
      <TabHeader />
      <TabBody form={autofillForm} />
      <RecentActivity />
    </>
  )
}
