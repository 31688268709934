import { NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { TICKET_STATUS } from '@/modules/ticket/constants'
import { Button, Select } from '@/ui'
import { Space, Table, Tag } from 'antd'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { customFormAtom } from '../../../atoms'
import { Alert } from '../../../types'
import { AddEditModal } from './add-edit-modal'
import { DeleteButton } from './delete-button'

const { Column } = Table

export const Notification = () => {
  const alerts = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.alerts), [])) || []
  const [editingAlert, setEditingAlert] = useState<Alert | null>(null)
  const [isAddingAlert, setIsAddingAlert] = useState<boolean>(false)

  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h5 className="font-bold mb-0">Ticket Notifications</h5>
        <Space>
          <Select
            className="min-w-[200px]"
            defaultValue="all"
            options={[
              {
                label: 'All',
                value: 'all'
              },
              ...Object.entries(NOTIFICATION_TEMPLATE_TYPE).map(([value, label]) => ({
                label: `Show only ${label}`,
                value
              }))
            ]}
          />
          <Button iconName="mi:add" data-cy="add-new-notification-button" onClick={() => setIsAddingAlert(true)}>
            Add new notification
          </Button>
        </Space>
      </div>
      <Table dataSource={alerts} rowKey="id" pagination={false}>
        <Column title="# ID" width={60} align="center" dataIndex="id" />
        <Column title="Rule Name" width={300} dataIndex="name" />
        <Column
          title="Ticket Statuses"
          width={300}
          dataIndex="type"
          render={(_, alert: Alert) => (
            <>
              {alert.ticket_statuses?.map((status: string) => (
                <Tag key={status}>{TICKET_STATUS[status as keyof typeof TICKET_STATUS]}</Tag>
              ))}
            </>
          )}
        />
        <Column title="Email Template" width={300} dataIndex="email_notification_template__name" />
        <Column title="SMS Template" width={300} dataIndex="sms_template__name" />
        <Column
          width={100}
          align="right"
          render={(_, alert: Alert) => (
            <Space>
              <Button
                type="text"
                iconName="fa:edit"
                data-cy="custom-form-notification-edit-button"
                onClick={() => setEditingAlert(alert)}
              />
              <DeleteButton alert={alert} />
            </Space>
          )}
        />
      </Table>
      {(editingAlert || isAddingAlert) && (
        <AddEditModal
          alert={editingAlert}
          onCancel={() => {
            setEditingAlert(null)
            setIsAddingAlert(false)
          }}
        />
      )}
    </div>
  )
}
