import { useSession } from '@/hooks'
import { PageView } from '@/layouts/views'
import type { TabsProps } from 'antd'
import { Tabs } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styles from './TabsProfile.module.scss'
import { AccessRightsTab } from './access-rights/AccessRightsTab'
import { TabType, formTypeAtom, selectedTabAtom, tabDisabledAtom } from './atom'
import { AutofillTab } from './autofill'
import { useFetchProfile } from './hooks'
import { NotificationTab } from './notifications'
import { ProfileTab } from './profile/ProfileTab'
import { SettingsTab } from './settings'

export const UserManagerTabsView = () => {
  const { permissions, user } = useSession()
  const { data: profileData } = useFetchProfile()
  const selectedTabItem = useAtomValue(selectedTabAtom)
  const setSelectedTabItem = useSetAtom(selectedTabAtom)
  const tabDisabled = useAtomValue(tabDisabledAtom)
  const setTabDisabled = useSetAtom(tabDisabledAtom)
  const setFormType = useSetAtom(formTypeAtom)
  const params = useParams()

  const items: TabsProps['items'] = [
    {
      key: 'profile',
      label: 'Profile',
      children: <ProfileTab />,
      disabled: tabDisabled
    },
    {
      key: 'notifications',
      label: 'Notifications',
      children: <NotificationTab />,
      disabled: tabDisabled
    },
    {
      key: 'autofill',
      label: 'Autofill',
      children: <AutofillTab />,
      disabled: tabDisabled
    },
    ...(permissions.includes('company_admin')
      ? [
          {
            key: 'access-rights',
            label: 'Access rights',
            children: <AccessRightsTab />,
            disabled: tabDisabled
          },
          {
            key: 'settings',
            label: 'Settings',
            children: <SettingsTab />,
            disabled: tabDisabled
          }
        ]
      : [])
  ]

  const onChange = (key: string) => {
    const tabType = key as TabType
    setSelectedTabItem(tabType)
  }

  useEffect(() => {
    return () => {
      setSelectedTabItem('profile')
    }
  }, [setSelectedTabItem])

  useEffect(() => {
    if ('id' in params) {
      setTabDisabled(false)
      setFormType('edit')
    } else {
      setTabDisabled(true)
      setFormType('add')
    }
  }, [params, setFormType, setTabDisabled])

  return (
    <PageView
      header={{
        title: 'Manage User',
        description: (
          <div>
            {profileData && profileData.user && (
              <>
                <span> {profileData.user.username}</span>
                <span> (ID: {profileData?.contact?.id})</span>
              </>
            )}
          </div>
        )
      }}
    >
      <Tabs className={styles.wrapper} defaultActiveKey={selectedTabItem} items={items} onChange={onChange} />
    </PageView>
  )
}
