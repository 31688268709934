import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { useMemo } from 'react'
import { useMultipleScheduleView } from '../hooks'
import { getStats } from './helpers'

export const useStatsByJobRoles = () => {
  const { query, state, dispatch } = useMultipleScheduleView({
    type__iexact: 'employee',
    limit: 'None'
  })
  const { date } = state

  const employees = useMemo(() => query.data?.pages.flat() || [], [query.data?.pages])
  const { jobRoles, isLoading: isJobRoleLoading } = useJobRoles()
  const employeesJobRoles = jobRoles.filter(({ resource_type }) => resource_type.toLowerCase() === 'employee')

  const stats = useMemo(() => getStats(employees, employeesJobRoles), [employees, employeesJobRoles])

  return {
    date,
    dispatch,
    stats,
    isLoading: query.isLoading || isJobRoleLoading
  }
}
