import { useApp, useFormWatch, useModalState } from '@/hooks'
import { fetchQuery } from '@/query'
import { clientRecordApi, equipmentApi } from '@/services/api-service'
import { ActionButton, Button, ButtonProps, Icon, Popover, QueryDeleteButton, QueryEditButton, QuerySelect } from '@/ui'
import { getOptionValue } from '@/ui/select/helpers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Alert, Card, Form, Input, List } from 'antd'
import { z } from 'zod'
import { JobRoleSelect } from '../../../../components'
import { useSaveResource } from '../hooks'
import { Resource, ResourceEquipment, ResourceEquipmentSchema, ResourceForm } from '../schemas'

export function RelatedSkusCard() {
  const form = Form.useFormInstance<ResourceForm>()
  const resourceType = useFormWatch<Resource['type']>(['resource', 'type'])
  const resourceId = useFormWatch<Resource['id']>(['resource', 'id'])

  const _equipmentIds = useFormWatch<Resource['_equipmentIds']>(['resource', '_equipmentIds']) || []
  const equipmentsQuery = useQuery({
    ...equipmentApi.list({
      id__in: _equipmentIds.join(',') || '0',
      order: 'description',
      fields: ResourceEquipmentSchema,
      no_count: true
    }),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: true
  })
  const equipments = equipmentsQuery.data?.items || []

  return (
    <Card
      title={'Related SKUs'}
      extra={
        <AddNewSkuPopover button={{ iconName: 'fa:plus', size: 'small', shape: 'round', children: 'Add New SKU' }} />
      }
    >
      <Form.Item name={['resource', '_equipmentIds']} hidden>
        <Input />
      </Form.Item>
      <div className={'-mt-20 flex flex-col'}>
        {!resourceId && (
          <Alert
            showIcon
            className={'mt-20'}
            message={`Save the ${resourceType} to enable SKU assignment.`}
            type="info"
          />
        )}
        {!!resourceId && (
          <div>
            <List<ResourceEquipment>
              loading={equipmentsQuery.isLoading}
              itemLayout="horizontal"
              dataSource={equipments || []}
              renderItem={(e) => (
                <List.Item
                  actions={[
                    <AddNewSkuPopover
                      key="eee"
                      sku={e}
                      button={{ size: 'small', iconName: 'fa:edit', shape: 'round', children: 'Edit' }}
                    />,
                    <QueryDeleteButton
                      title={'Delete SKU'}
                      key={'delete'}
                      api={equipmentApi.delete}
                      id={e.id}
                      name={'SKU'}
                    />,
                    <ActionButton
                      title={'Unassign SKU'}
                      key={'remove'}
                      iconName={'fa:remove'}
                      onClick={() => {
                        form.setFieldsValue({ resource: { _equipmentIds: _equipmentIds.filter((id) => id !== e.id) } })
                      }}
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={`${e.sku || 'None'} / ${e.description || 'None'}`}
                    description={
                      <div>
                        <div className={'text-sm'}>
                          Category ({e.category__parent_category__name} - {e.category__name})
                        </div>
                        {e.user_text_7 ? (
                          <div className={'text-sm text-text-muted'}>Job Role ({e.user_text_7})</div>
                        ) : (
                          <div className={'text-sm text-danger align-middle'}>
                            <Icon name={'fa:exclamation-circle'} /> &nbsp;
                            <span>Job Role Not Set</span>
                          </div>
                        )}
                        {resourceType === 'Employee' && !e.assignee_id && (
                          <div className={'text-sm text-warning align-middle'}>
                            <Icon name={'fa:warning'} /> &nbsp;
                            <span>Assignee Not Set</span>
                          </div>
                        )}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
            <QuerySelect
              placeholder={'Assign Existing SKU'}
              className={'w-full'}
              apiEndpoint={equipmentApi.list}
              apiQueryParams={{
                fields: ResourceEquipmentSchema,
                order: 'category__name,sku'
              }}
              value={null as any}
              apiSearchBy={(text) => ({
                'Q[]': [
                  ...(_equipmentIds.length ? [`~id__in|${_equipmentIds.join(',')}`] : []),
                  ...(text && text.length
                    ? [
                        `sku__icontains|${text}`,
                        `description__icontains|${text}`,
                        'or',
                        `category__name__icontains|${text}`,
                        'or',
                        `category__parent_category__name__icontains|${text}`,
                        'or'
                      ]
                    : [])
                ]
              })}
              renderOption={(item) => ({
                value: item.id,
                label: (
                  <div className={'flex flex-col'}>
                    <span>
                      {item.sku || 'None'} / {item.description || 'None'}
                    </span>
                    <small className={'text-text-muted'}>
                      {item.category__parent_category__name} - {item.category__name}
                    </small>
                  </div>
                )
              })}
              onChange={(equipmentId) => {
                form.setFieldsValue({ resource: { _equipmentIds: [..._equipmentIds, equipmentId] } })
              }}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

type FormSkuObject = { id?: number; sku?: string; description?: string; user_text_7?: string }

function AddNewSkuPopover({ sku, button }: { sku?: FormSkuObject; button?: ButtonProps }) {
  const { isOpen, setIsOpen, closeModal } = useModalState()
  const resourceId = useFormWatch<Resource['id']>(['resource', 'id'])

  return (
    <Popover
      title={`${sku?.id ? `Edit SKU #${sku?.id}` : 'Add New SKU'}`}
      content={<AddNewSkuForm sku={sku} onSuccess={closeModal} />}
      trigger={['click']}
      placement={'left'}
      destroyTooltipOnHide
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Button disabled={!resourceId} {...button} />
    </Popover>
  )
}

function AddNewSkuForm({ sku, onSuccess }: { sku?: FormSkuObject; onSuccess?: () => void }) {
  const { notification } = useApp()
  const resource = useFormWatch<Resource>('resource')
  const resourceForm = Form.useFormInstance()
  const [skuForm] = Form.useForm()
  const { saveResourceMutation, saveResource } = useSaveResource()

  const defaultJobRoleId = getOptionValue(resource._jobRole)
  const defaultJobRoleQuery = useQuery(
    clientRecordApi.get(defaultJobRoleId, undefined, {
      fields: z.object({
        id: z.number(),
        char_1: z.string(),
        data__category_id: z.number().optional()
      })
    })
  )

  const skuSaveMutation = useMutation(equipmentApi.save())

  const saveSku = async () => {
    try {
      const skuValues = await skuForm.validateFields({ recursive: true })
      const jobRole = await fetchQuery(
        clientRecordApi.get(parseJobRoleId(skuValues.user_text_7), undefined, {
          fields: z.object({
            id: z.number(),
            char_1: z.string(),
            data__category_id: z.number().optional()
          })
        })
      )
      const sku = await skuSaveMutation.mutateAsync({
        ...skuValues,
        category: skuValues.category_id || jobRole.data__category_id,
        data: { resource_id: resource.id, ...(skuValues.data || {}) }
      })
      resourceForm.setFieldValue(['resource', '_equipmentIds'], [...(resource._equipmentIds || []), sku.id])
      await saveResource()
      notification.success({ message: 'SKU Added', description: 'SKU successfully added to the resource.' })
      onSuccess?.()
    } catch (e: any) {
      notification.error({ message: 'Failed to Add SKU', description: e?.message })
    }
  }

  return (
    <div className={'mt-20 h-[270px] w-[450px]'}>
      {!(defaultJobRoleId && defaultJobRoleQuery.isLoading) && (
        <Form
          form={skuForm}
          initialValues={
            sku || {
              id: undefined,
              sku: resource.contact_id || resource.short_code,
              description: resource.name,
              user_text_7: defaultJobRoleQuery.data
                ? `${defaultJobRoleQuery.data?.id}: ${defaultJobRoleQuery.data?.char_1}`
                : undefined
            }
          }
          labelCol={{ span: 6 }}
          onFinish={saveSku}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'data'} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={'user_text_7'}
            label={'Job Role'}
            rules={[{ required: true }]}
            tooltip={'User Text 7'}
            extra={<small>Changing a Job Role automatically updates the SKU category</small>}
          >
            <JobRoleSelect
              apiQueryParams={{ char_2__eq: resource.type }}
              allowClear={false}
              renderOption={(role) => {
                const accentColor = role.char_4
                return {
                  value: `${role.id}: ${role.char_1}`,
                  label: (
                    <div className={'flex flex-row gap-8 items-center'}>
                      <div
                        style={{ backgroundColor: accentColor || 'lightgray', borderColor: accentColor || 'lightgray' }}
                        className={
                          'opacity-80 dark:opacity-60 rounded border w-4 h-14 group-hover:bg-primary transition-colors'
                        }
                      />
                      {role.id}: {role.char_1}
                    </div>
                  )
                }
              }}
            />
          </Form.Item>
          {/*
        <Form.Item name={'category'} label={'Category'} rules={[{ required: true }]}>
          <QuerySelect
            apiEndpoint={categoryApi.list}
            apiQueryParams={{
              fields: 'id,parent_category__name,name',
              order: 'parent_category__name,name',
              parent_category__isnull: false
            }}
            apiValueBy={'id'}
            apiSearchBy={(text) => ({
              'Q[]': text ? [`parent_category__name__icontains|${text}`, `name__icontains|${text}`, 'or'] : undefined
            })}
            renderOption={(c) => ({
              value: c.id,
              label: `${c.parent_category__name} - ${c.name}`
            })}
          />
        </Form.Item>
        */}
          <Form.Item name={'sku'} label={'SKU'} rules={[{ required: true }]}>
            <Input autoFocus />
          </Form.Item>
          <Form.Item name={'description'} label={'Description'}>
            <Input />
          </Form.Item>

          <div className={'flex justify-center'}>
            {sku?.id ? (
              <QueryEditButton
                key={'edit'}
                size={'small'}
                type={'default'}
                shape={'round'}
                to={`/records/skus/${sku.id}/edit`}
                iconName={'fa:edit'}
              >
                Full SKU Edit
              </QueryEditButton>
            ) : (
              <Alert message="Save here, then add more details on the SKU edit page." type="info" />
            )}
          </div>

          <div className={'mt-10 w-full flex justify-end'}>
            <Form.Item>
              <Button
                size={'small'}
                type={'primary'}
                htmlType={'submit'}
                iconName={'fa:save'}
                loading={skuSaveMutation.isLoading || saveResourceMutation.isLoading}
              >
                Save SKU
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  )
}

const parseJobRoleId = (jobRole: any) => {
  return jobRole?.split(':')[0]
}
