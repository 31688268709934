import {
  selectedJobRoleAtom,
  selectedSkillsAtom,
  skillMatchTypeAtom
} from '@/modules/jas/job-manager/add-resource/atoms'
import { SkillTag } from '@/modules/jas/job-manager/add-resource/components/SkillTag'
import {
  useAddResource,
  useJobTicketSkillsQuery,
  useSelectedResources
} from '@/modules/jas/job-manager/add-resource/hooks'
import { JobResource } from '@/modules/jas/job-manager/types'
import { Select, Skeleton } from 'antd'
import { useAtom, useSetAtom } from 'jotai'
import React, { useMemo } from 'react'
import { JobRequirementsSection } from '../../components/job-ticket-detail/JobRequirementsSection'
import { JobRoleSelect } from './JobRoleSelect'

export const SkillFilter: React.FC = () => {
  const { type, close, typeLabel } = useAddResource()
  const [selectedJobRole, setSelectedJobRole] = useAtom(selectedJobRoleAtom)

  const setSkillMatchType = useSetAtom(skillMatchTypeAtom)
  const [selectedSkills, setSelectedSkills] = useAtom(selectedSkillsAtom)

  const selectedSkillsMap = useMemo(() => {
    return selectedSkills.reduce(
      (acc, skill) => {
        acc[skill] = true
        return acc
      },
      {} as Record<string, boolean>
    )
  }, [selectedSkills])

  const { isFetching: isSkillsFetching, jobSkills } = useJobTicketSkillsQuery()
  const { selectedResources } = useSelectedResources()

  const onSkillSelect = (skill: string) => {
    if (selectedSkillsMap[skill]) {
      setSelectedSkills(selectedSkills.filter((s) => s !== skill))
    } else {
      setSelectedSkills([...selectedSkills, skill])
    }
  }

  const skillResources: Record<string, JobResource[]> = useMemo(() => {
    if (jobSkills) {
      const allRes = Object.values(selectedResources)
      const skillResourceMap: Record<string, JobResource[]> = {}

      allRes.forEach((res) => {
        Object.keys(res.attributes).forEach((skill) => {
          skillResourceMap[skill] = skillResourceMap[skill] || []
          skillResourceMap[skill].push(res)
        })
      })

      return skillResourceMap
    }

    return {}
  }, [jobSkills, selectedResources])

  return (
    <div>
      <div className="flex items-center mb-8">
        <div>
          <Select
            defaultValue="any"
            options={[
              { label: 'Match Any', value: 'any' },
              { label: 'Match All', value: 'all' }
            ]}
            onChange={(value) => setSkillMatchType(value)}
          />
        </div>
        <div className="px-12 flex flex-row gap-20">
          <div className={'flex flex-col'}>
            <div className="font-medium mb-2">Skills & Roles</div>
            <div className="flex flex-wrap gap-10">
              <JobRoleSelect type={typeLabel} value={selectedJobRole} onChange={(value) => setSelectedJobRole(value)} />

              {isSkillsFetching && (
                <div>
                  <Skeleton.Button key={'b'} size={'small'} active={true} block={true} />
                  <Skeleton.Avatar key={'a'} size={'small'} active={true} className="my-4" />
                </div>
              )}
              {!isSkillsFetching &&
                jobSkills?.map((skill) => (
                  <SkillTag
                    key={skill.value}
                    skill={skill}
                    resources={skillResources[skill.value] || []}
                    isSelected={selectedSkillsMap[skill.value]}
                    onSelectChange={onSkillSelect}
                  />
                ))}
              {/*{!isSkillsFetching && jobSkills?.length === 0 && (
                <div className="flex align-middle text-sm">
                  <Tag bordered={false} className="text-sm bg-gray-200 text-gray-500 px-12">
                    No Required Skills
                  </Tag>
                </div>
              )}*/}
            </div>
          </div>
          <div>
            <div className="font-medium mb-2">Requirements</div>
            <div className={'flex flex-wrap'}>
              <JobRequirementsSection type={type as any} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
