import { APP_SITE } from '@/constants/app'
import { SiteTheme } from '@/site'
import { RequiredKeys } from '@/types/general'
import { safeJsonParse } from '@/utils'
import { generate } from '@ant-design/colors'
import { ConfigProvider, ConfigProviderProps, ThemeConfig, theme } from 'antd'
import { useAtomValue } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { set } from 'lodash'
import { useMemo } from 'react'

const { darkAlgorithm, defaultAlgorithm } = theme

export const ocToken = {
  fontFamily:
    'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Inter, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  borderRadius: 8,
  lineWidth: 1,
  fontSize: 14
}

export const ocLight: RequiredKeys<ThemeConfig, 'token'> = {
  cssVar: true,
  algorithm: [defaultAlgorithm],
  token: {
    ...ocToken,
    colorTextBase: '#4a4a4a'
  },
  components: {
    Layout: {
      siderBg: '#191919',
      lightSiderBg: '#191919'
    },
    Card: {
      headerHeight: 45,
      paddingLG: 18
    }
  }
}

export const ocDark: ThemeConfig = {
  cssVar: true,
  algorithm: [darkAlgorithm],
  token: {
    ...ocToken,
    colorTextBase: '#dddddd',
    colorBgBase: '#050505',
    colorBgContainer: '#191919'
  },
  components: {
    Layout: {
      siderBg: '#191919',
      lightSiderBg: '#191919'
    },
    Card: {
      headerHeight: 45,
      paddingLG: 18
    }
  }
}

export const motionAtom = atomWithStorage('motion', true, undefined, { getOnInit: true })
export const darkModeAtom = atomWithStorage('darkMode', false, undefined, { getOnInit: true })
export const siteThemeAtom = atomWithStorage<SiteTheme | null>(
  window.site.themeStoreKey,
  safeJsonParse(localStorage.getItem(window.site.themeStoreKey) || null)
)

export function AppThemeProvider(props: ConfigProviderProps) {
  const motion = useAtomValue(motionAtom)
  const darkMode = useAtomValue(darkModeAtom)
  const siteTheme = useAtomValue(siteThemeAtom)

  const themeConfig = useMemo(() => {
    const theme = (siteTheme || APP_SITE.theme) as SiteTheme
    const primaryColor = generate(theme.color, darkMode ? { theme: 'dark', backgroundColor: '#050505' } : undefined)

    const _theme = darkMode ? ocDark : ocLight
    set(_theme, 'token.colorPrimary', primaryColor[5])
    set(_theme, 'token.colorLink', primaryColor[5])
    set(_theme, 'token.motion', Boolean(motion))

    const root = document.documentElement
    if (darkMode) root.classList.add('dark-mode')
    else root.classList.remove('dark-mode')
    primaryColor.map((color, index) => root.style.setProperty(`--ant-primary-${index + 1}`, color))

    return _theme
  }, [siteTheme, darkMode, motion])

  return <ConfigProvider componentSize="middle" theme={themeConfig} {...props} />
}
