import { Dayjs } from 'dayjs'
import { Resource } from '../schemas'
import { ResourcePopover } from './ResourcePopover'

type Props = {
  resource: Resource
  date: Dayjs
}

export function ScheduleTitle({ resource, date }: Props) {
  const roleName = resource.job_role__char_1 || 'Unknown'
  const roleColor = resource.job_role__char_4 || 'lightgray'

  const titleEl = (
    <div className={'px-18 py-2'}>
      <div
        style={{ backgroundColor: roleColor, borderColor: roleColor }}
        className={'opacity-80 dark:opacity-60 border absolute left-0 top-0 w-6 h-full'}
      />
      <div>
        <div className={'line-clamp-1 leading-4'}>{resource.name}</div>
        <div className={'line-clamp-1 leading-4 text-[10px] text-text-muted'}>{roleName}</div>
      </div>
    </div>
  )

  return <ResourcePopover resource={resource}>{titleEl}</ResourcePopover>
}
