import { useSession } from '@/hooks'
import { officeApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Button } from '@/ui/button'
import { Form, Modal } from 'antd'
import { useMemo, useState } from 'react'
import { usePricingCustomerQuery } from '../../hooks'
import { useCreatePricingCustomer } from './hooks'

export const AddCustomerPricing = () => {
  const { company } = useSession()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const { pricingCustomerQuery } = usePricingCustomerQuery()
  const { createPricingCustomer, createPricingCustomerMutation } = useCreatePricingCustomer()

  const existingPricingOffices = useMemo(
    () => (pricingCustomerQuery.data?.items || []).map((pc) => pc.customer_office),
    [pricingCustomerQuery.data]
  )

  const handleSubmit = async () => {
    try {
      const { customer_office } = await form.validateFields()

      await createPricingCustomer({
        customer_office,
        company: company.id
      })

      setIsModalOpen(false)
      form.resetFields()
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
    form.resetFields()
  }

  return (
    <>
      <Button iconName="mi:add" type="primary" onClick={() => setIsModalOpen(true)}>
        Add Customer Pricing
      </Button>
      <Modal
        title="Add Customer Pricing"
        open={isModalOpen}
        okText="Save and close"
        okButtonProps={{
          loading: createPricingCustomerMutation.isLoading
        }}
        onCancel={handleClose}
        onOk={handleSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Customer"
            name="customer_office"
            rules={[
              {
                required: true,
                message: 'Please select a customer'
              }
            ]}
          >
            <QuerySelect
              apiEndpoint={officeApi.list}
              apiSearchBy={'name'}
              apiQueryParams={{
                fields: 'id,name',
                relations__from_office__company_id: company.id,
                ...(existingPricingOffices.length ? { 'E[]': 'id__in|' + existingPricingOffices.join(',') } : {})
              }}
              renderOption={(item) => ({
                value: item.id,
                label: item.name
              })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
