import { Checkbox, Form, Radio } from 'antd'
import { CheckboxGroupProps } from 'antd/es/checkbox'
import { FC, useMemo } from 'react'
import { ScriptedButtonConfig } from '../../types'
import { TicketPdfButton } from './TicketPdfButton'

const CheckBoxFormItem = (props: any) => (
  <Form.Item {...props} valuePropName={'checked'} label={''}>
    <Checkbox>{props?.label}</Checkbox>
  </Form.Item>
)

const PrintStyleSelect: FC<CheckboxGroupProps & { customForm: any }> = ({ customForm, ...props }) => {
  const printStyles = useMemo(
    () => [
      { label: 'Default', value: 'default' },
      ...customForm.print_styles.map((s: any) => ({ label: s.name, value: s.name }))
    ],
    [customForm.print_styles]
  )
  return <Checkbox.Group options={printStyles} {...props} />
}

export const TicketPdfButtonConfig: ScriptedButtonConfig = {
  type: 'common_ticket_pdf',
  iconName: 'fa:file-pdf',
  label: 'Ticket Print PDF',
  group: 'Common',
  render: () => <TicketPdfButton />,
  vars: [
    {
      name: 'mode',
      label: 'Toggle Mode',
      rules: [{ required: true }],
      render: (customForm, props) => (
        <Radio.Group
          options={[
            { value: 'modal', label: 'Modal' },
            { value: 'direct', label: 'Direct' }
          ]}
          {...props}
        />
      )
    },
    {
      name: 'type',
      label: 'Result Type',
      rules: [{ required: true }],
      render: (customForm, props) => (
        <Radio.Group
          options={[
            { value: 'print', label: 'Print' },
            { value: 'download', label: 'Download' }
          ]}
          {...props}
        />
      )
    },
    {
      name: 'stylesheets',
      label: 'Style Sheets',
      rules: [{ required: true }],
      render: (customForm, props) => <PrintStyleSelect customForm={customForm} {...props} />
    },
    {
      name: 'attachments',
      label: 'Attachments',
      renderFormItem: (customForm, props) => <CheckBoxFormItem {...props} />
    },
    {
      name: 'show_internal_use_only',
      label: 'Include Internal Use Only Sections',
      renderFormItem: (customForm, props) => <CheckBoxFormItem {...props} />
    },
    {
      name: 'line_item_attachments',
      label: 'Line item attachments',
      renderFormItem: (customForm, props) => <CheckBoxFormItem {...props} />
    }
  ]
}
