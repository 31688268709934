import { Checkbox, Form, Input } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSetTicketProperty } from '../../schemas'
import { PropertySelect } from '../controls'

type Props = {
  action: BpaActionSetTicketProperty
  onChange: (data: Partial<BpaActionSetTicketProperty>) => void
}

export const InputSetProperty: FC<Props> = (props) => {
  const { action, onChange } = props

  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Type" className="flex-grow">
          <PropertySelect
            valueField={'key'}
            defaultValue={action.field}
            onChange={(value) => onChange({ field: value, value: null })}
          />
        </Form.Item>
        <div className="flex flex-row justify-center items-center">
          <Form.Item label="Clear" tooltip="Same as set null or remove value.">
            <Checkbox
              checked={action.setNull}
              onChange={(e: any) => {
                onChange({ value: null, setNull: e.target.checked })
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <Form.Item label="Value (Text)" tooltip="Supports placeholder like P[Key], F[name]">
          {action.setNull ? (
            <Input disabled />
          ) : (
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              defaultValue={action.value || ''}
              onChange={(e) => onChangeDebounced({ value: e.target.value })}
            />
          )}
        </Form.Item>
      </div>
    </div>
  )
}
export const InputSetPropertyMemo = React.memo(InputSetProperty)
