type Url = string

export const saveBlob = async (blobOrUrl: Blob | Url, fileName: string, noClick?: boolean) => {
  const blobData = typeof blobOrUrl === 'string' ? await fetchBlob(blobOrUrl) : blobOrUrl

  const blobUrl = window.URL.createObjectURL(blobData)

  const fileAnchor = document.createElement('a')
  fileAnchor.href = blobUrl
  fileAnchor.style.display = 'none'
  fileAnchor.download = fileName

  if (!noClick) {
    document.body.appendChild(fileAnchor)
    fileAnchor.click()
  }

  return [fileAnchor, blobUrl]
}

export const printBlob = async (blobOrUrl: Blob | Url) => {
  const blobData = typeof blobOrUrl === 'string' ? await fetchBlob(blobOrUrl) : blobOrUrl
  const blobUrl = window.URL.createObjectURL(blobData)

  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  iframe.onload = function () {
    if (!iframe.contentWindow) throw new Error('Content window is not available')

    iframe.contentWindow.focus() // necessary for some browsers
    iframe.contentWindow.print()
    iframe.onload = null
  }

  iframe.src = blobUrl
}

export const fetchBlob = async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) throw new Error('Failed to fetch blob')
  return await response.blob()
}

export const parseFileName = (contentDisposition: string) => {
  const filenameRegex = /filename=([^;]+)/i
  const matches = filenameRegex.exec(contentDisposition)

  if (matches && matches[1]) return matches[1].trim()

  return null
}
