import { Form, Input } from 'antd'

export const NotificationsTab = () => {
  return (
    <Form layout="vertical">
      <h5>Email</h5>
      <Form.Item label="Label for Contact Assigned to Ticket">
        <Input />
      </Form.Item>
      <Form.Item label="Template for Ticket submitted or signed">
        <Input />
      </Form.Item>
      <Form.Item label="Template for 3rd Party Assignment">
        <Input />
      </Form.Item>
      <Form.Item label="Template for Ticket alert">
        <Input />
      </Form.Item>
      <h5>Text</h5>
      <Form.Item label="Text for Contact Assigned to Ticket">
        <Input />
      </Form.Item>
      <Form.Item label="Text for Ticket submitted or signed">
        <Input />
      </Form.Item>
      <Form.Item label="Text for 3rd Party Assignment">
        <Input />
      </Form.Item>
      <Form.Item label="Text for Ticket alert">
        <Input />
      </Form.Item>
    </Form>
  )
}
