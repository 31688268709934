export const filters = [
  {
    label: 'Date Range',
    name: 'date_range'
  },
  {
    label: 'Channel',
    name: 'medium__eq'
  },
  {
    label: 'Contact',
    name: 'contact_full_name__icontains'
  },
  {
    label: 'Ticket',
    name: 'ticket__name__icontains'
  },
  {
    label: 'To Email',
    name: 'contact__email__icontains'
  },
  {
    label: 'To Phone Number',
    name: 'contact__phone_number__icontains'
  },
  {
    label: 'Subject',
    name: 'title__icontains'
  }
] as const

export type FilterNames = (typeof filters)[number]['name']
export type Filters = Record<Exclude<FilterNames, 'date_range'> | 'modified_at__gte' | 'modified_at__lte', any>
