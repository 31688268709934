import { getDateRangeByView } from '@/modules/jas/scheduler/helpers'
import { CalendarView } from '@/modules/jas/scheduler/types'
import { Button } from '@/ui'
import { LocalDateTimePicker } from '@/ui/date'
import { Icon } from '@/ui/icons'
import { DatePicker, Space } from 'antd'
import cn from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

type Props = {
  date: Dayjs
  view: CalendarView
  onChange: (date: Dayjs) => void
}

export const MonthWeekChanger = ({ date, onChange, view }: Props) => {
  const [hoveredDate, setHoveredDate] = useState(date)
  const coveredRange = useMemo(
    () => getDateRangeByView(view, hoveredDate).map((d) => d.formatISODate(false)),
    [view, hoveredDate]
  )

  const startDateIso = coveredRange[0]
  const endDateIso = coveredRange[coveredRange.length - 1]
  const todayIsoDate = dayjs().formatISODate(false)
  const dateIsoDate = date?.formatISODate(false)

  const handlePrev = () => {
    if (view === 'week') onChange(date.subtract(1, 'week'))
    else if (view === 'three_days') onChange(date.subtract(3, 'days'))
    else onChange(date.subtract(1, 'month'))
  }

  const handleNext = () => {
    if (view === 'week') onChange(date.add(1, 'week'))
    else if (view === 'three_days') onChange(date.add(3, 'days'))
    else onChange(date.add(1, 'month'))
  }

  const formatSelected = (dt?: Dayjs) => {
    const selectedRange = getDateRangeByView(view, dt || dayjs())
    const startDate = selectedRange[0]
    const endDate = selectedRange[selectedRange.length - 1]

    return `${startDate?.formatLocalDate()} - ${endDate.formatLocalDate()}`
  }

  const cellRender: any = (current: Dayjs | number, info: any) => {
    if (typeof current === 'number') return info.originNode
    if (info.type !== 'date') return info.originNode

    const currentIsoDate = current.formatISODate(false)

    return (
      <div
        onMouseOver={() => setHoveredDate(current)}
        className={cn(
          `C:${currentIsoDate} T:${todayIsoDate} D:${dateIsoDate}`,
          'ant-picker-cell-inner !rounded-none !w-full',
          coveredRange.includes(currentIsoDate) ? '!bg-primary !text-primary-50' : '!bg-transparent',
          {
            '!text-neutral': currentIsoDate === todayIsoDate || currentIsoDate === dateIsoDate,
            '!rounded-l': currentIsoDate === startDateIso,
            '!rounded-r': currentIsoDate === endDateIso
          }
        )}
      >
        {current.date()}
      </div>
    )
  }

  return (
    <div className="flex [&_.ant-picker-cell-selected>.ant-picker-cell-inner]:!text-neutral">
      <Space>
        <Button shape={'circle'} icon={<Icon name={'mi:chevron_left'} />} type="text" onClick={handlePrev} />
        <Button shape={'circle'} icon={<Icon name={'mi:chevron_right'} />} type="text" onClick={handleNext} />
        {view === 'month' && (
          <DatePicker.MonthPicker
            value={date}
            format="MMM YYYY"
            allowClear={false}
            onChange={(date) => {
              if (date) {
                onChange(date)
              }
            }}
          />
        )}
        {(view === 'week' || view === 'three_days') && (
          <LocalDateTimePicker
            showTime={false}
            showToday={false}
            value={date}
            format={formatSelected}
            allowClear={false}
            onChange={(date) => {
              if (date) {
                onChange(date)
                setHoveredDate(date)
              }
            }}
            onPanelChange={() => setHoveredDate(date)}
            cellRender={cellRender}
          />
        )}
      </Space>
    </div>
  )
}
