import { Button } from '@/ui'
import { Form } from 'antd'
import { usePropertiesQuery } from '../../hooks'

export const ShowPropertyLinkButton = () => {
  const form = Form.useFormInstance()
  const { properties } = usePropertiesQuery()
  const selectedPropertyId = Form.useWatch('id')
  const showLinkProperty = Form.useWatch('showLinkProperty')
  const selectedProperty = properties.find((property) => property.id === selectedPropertyId)

  if (!selectedProperty || selectedProperty.links) {
    return null
  }

  return (
    <Button
      type={selectedProperty.link ? 'success' : 'default'}
      iconName="fa:link-horizontal"
      onClick={() => {
        form.setFieldsValue({
          showLinkProperty: !showLinkProperty
        })
      }}
    />
  )
}
