import { useApp, useModalState, useSession } from '@/hooks'
import { jobTypeApi } from '@/services/api-service'
import { ActionButton, ButtonProps, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { FC } from 'react'
import { JobType } from '../schemas'

type Props = ButtonProps & {
  jobType?: JobType
}

export const ModalButton: FC<Props> = ({ jobType, ...buttonProps }) => {
  const { company } = useSession()
  const { l, notification } = useApp()
  const [form] = Form.useForm()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const saveMutation = useMutation(jobType?.id ? jobTypeApi.patch() : jobTypeApi.create())

  const handleSave = async () => {
    const data = await form.validateFields()

    if (!jobType?.id) data.company = company.id

    await saveMutation
      .mutateAsync(data)
      .then(() => {
        closeModal()
        notification.success({
          message: `${l('JobType')} Saved`,
          description: `${l('JobType')} ${data.name} has been saved`
        })
      })
      .catch(() => {
        notification.error({
          message: `${l('JobType')} Error`,
          description: `Failed to save ${l('JobType')} ${data.name}`
        })
      })

    if (!jobType?.id) form.resetFields()
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          width={500}
          title={jobType?.id ? 'Update ' + jobType?.name : `Add ${l('JobType')}`}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-button' }}
          okText="Save"
          cancelText="Cancel"
          onCancel={closeModal}
        >
          <Form form={form} initialValues={jobType} layout="vertical">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item
              label={`${l('JobType')} Name`}
              name="name"
              rules={[{ required: true }]}
              data-cy="user-job-type-name-input"
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
