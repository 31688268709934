import { useApp } from '@/hooks'
import { categoryApi, equipmentApi } from '@/services/api-service'
import { Icon, QuerySelect, QueryTable, SearchInput } from '@/ui'
import { objId } from '@/utils'
import { Checkbox, Divider, Form, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SelectValue } from 'antd/es/select'
import { isEmpty } from 'lodash'
import { FC, useCallback, useState } from 'react'
import { useCustomForm } from '../../../../../hooks'
import { useEquipmentSelection, useFormWatch } from '../../../hooks'

export const ItemSelector: FC = () => {
  const { features } = useCustomForm()
  const { skuSelection } = features
  const { l } = useApp()

  const { equipmentIds, toggleEquipmentId, setEquipmentIds } = useEquipmentSelection()

  const parentCategory = useFormWatch<SelectValue>(['options', 'parentCategory'])
  const category = useFormWatch<SelectValue>(['options', 'category'])
  const selectorQueryOverride = useFormWatch(['options', 'selectorQueryOverride'])

  const [search, setSearch] = useState<string>('')
  const [withExtendedSearch, setWithExtendedSearch] = useState<boolean>(false)

  // todo: custom sku filter
  // const [enableCustomFilter, setEnableCustomFilter] = useState<boolean>(skuSelection?.enabled || false)

  const parentCategoryId = objId(parentCategory)
  const categoryId = objId(category)

  const extendedSearchEnabled = features.skuSearch?.enabled
  const extendedSearchFields = features.skuSearch?.fields || []

  const toggleWithExtendedSearch = useCallback((e: CheckboxChangeEvent) => setWithExtendedSearch(e.target.checked), [])

  // note: needs review, unnecessary disable?
  // const isCategoryDisabled = useMemo(
  //   () => enableCustomFilter && filterQueryHasField(skuSelection?.filter || {}, 'category'),
  //   [enableCustomFilter, skuSelection?.filter]
  // )

  return (
    <div>
      <div className={'grid grid-cols-2 gap-10'}>
        <Form.Item name={['options', 'parentCategory']} label="Category" className={'mb-12'}>
          <QuerySelect
            // disabled={isCategoryDisabled}
            placeholder={'All Categories'}
            apiEndpoint={categoryApi.list}
            apiQueryParams={{ fields: 'id,name', order: 'name' }}
            apiSearchBy={(text) => ({ name__icontains: text, parent_category__isnull: 1 })}
            renderOption={(item) => ({ value: item.id, label: item.name })}
          />
        </Form.Item>

        <Form.Item name={['options', 'category']} label="Sub-Category" className={'mb-12'}>
          <QuerySelect
            // disabled={!parentCategoryId || isCategoryDisabled}
            disabled={!parentCategoryId}
            placeholder={'All Categories'}
            apiEndpoint={categoryApi.list}
            apiQueryParams={{ fields: 'id,name', order: 'name' }}
            apiQueryOptions={{ enabled: !!parentCategoryId }}
            apiSearchBy={(text) => ({ name__icontains: text, parent_category_id__eq: parentCategoryId })}
            renderOption={(item) => ({ value: item.id, label: item.name })}
          />
        </Form.Item>
      </div>

      <Form.Item className={'mb-10'}>
        <SearchInput debounce={200} onSearch={setSearch} className={'!w-full !max-w-full !min-w-full'} />
      </Form.Item>

      <div className="flex items-center mb-10">
        <div className={'ml-8 inline-block min-h-full'}>
          <Checkbox disabled={!extendedSearchEnabled} checked={withExtendedSearch} onChange={toggleWithExtendedSearch}>
            Extended Search
          </Checkbox>
        </div>
        {!extendedSearchEnabled && (
          <Tooltip placement="top" title="Extended search disabled in custom form settings">
            <div>
              <Icon name="fa:circle-info" className="mr-2" />
            </div>
          </Tooltip>
        )}
        {extendedSearchEnabled &&
          (isEmpty(extendedSearchFields) ? (
            <p className="text-primary text-12 mb-0 font-bold">
              (No fields selected for extended search. Please set search fields from custom form settings.)
            </p>
          ) : (
            <p className="mb-0">(Includes: {extendedSearchFields.map((f) => l(`lineitem__${f}`) || '').join(', ')})</p>
          ))}
      </div>
      <Divider className="my-5" />
      <div>
        <Form.Item name={'equipmentIds'} hidden>
          <input type="hidden" />
        </Form.Item>

        <QueryTable
          fullHeight
          scrollOffset={{ y: -100 }}
          className={'!border-none'}
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: equipmentIds,
            preserveSelectedRowKeys: true,
            onChange: setEquipmentIds as any
          }}
          rowHoverable={true}
          rowClassName={'cursor-pointer'}
          onRow={(record) => ({ onClick: () => toggleEquipmentId(record.id) })}
          queryApi={equipmentApi.list}
          queryParams={{
            fields: 'id,sku,description,category__name,category__parent_category__name',
            search: search,
            category_id__eq: categoryId || undefined,
            category__parent_category_id__eq: parentCategoryId || undefined,
            ...(features.skuSearch?.enabled &&
              withExtendedSearch && { search_fields: (features.skuSearch?.fields || []).join(',') }),
            ...(selectorQueryOverride || {})
          }}
          columns={[
            { title: 'SKU', dataIndex: 'sku', key: 'sku', width: 150 },
            { title: 'Category', dataIndex: 'category__parent_category__name', key: 'category' },
            { title: 'Sub-Category', dataIndex: 'category__name', key: 'sub-category' },
            { title: 'Description', dataIndex: 'description', key: 'description' }
          ]}
        />
      </div>
    </div>
  )
}
