import { useApp, useIsMobile, useSession } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { categoryKindApi } from '@/services/api-service'
import { LinkButton, QueryDeleteButton, QueryEditButton } from '@/ui'
import { safeJsonParse } from '@/utils'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { uniq } from 'lodash'
import { z } from 'zod'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'

export const CategoryKindListView = view(Component)

function Component() {
  const { l } = useApp()
  const { company } = useSession()
  const isMobile = useIsMobile()

  const getFields = (_: any, kind: CategoryKind) => {
    const fields = uniq(safeJsonParse(kind.fields) || [])
    const aliases = safeJsonParse(kind.field_aliases_json) || {}
    return fields.map((field: any) => (
      <Tag className={'mb-6'} key={field}>
        {aliases[field] || l(`lineitem__${field}`)}
      </Tag>
    ))
  }

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('CategoryKinds'),
        actions: (
          <LinkButton to={'add'} type={'primary'} iconName={'fa:plus'} data-cy={'add-configuration'}>
            Add {l('CategoryKind')}
          </LinkButton>
        )
      }}
      filter={{ search: { searchBy } }}
      table={{
        queryApi: categoryKindApi.list,
        queryParams: {
          order: 'name',
          fields: CategoryKindSchema,
          company_id__eq: company.id
        },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'kind', title: `${l('CategoryKind')} Code`, hidden: isMobile, width: 200 },
          { dataIndex: 'name', title: `${l('CategoryKind')} Name`, width: 200 },
          { dataIndex: 'fields', title: 'Fields', hidden: isMobile, render: getFields },
          { dataIndex: 'modified_at', title: 'Last Updated', hidden: isMobile, width: 200, render: getModified },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: CategoryKind) => (
              <div>
                <QueryEditButton to={`${row.id}/edit`} />
                <QueryDeleteButton id={row.id} api={categoryKindApi.delete} name={l('CategoryKind')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}

const CategoryKindSchema = z.object({
  id: z.string(),
  kind: z.string(),
  name: z.string(),
  fields: z.string(),
  field_aliases_json: z.string(),
  modified_at: z.string()
})

type CategoryKind = z.infer<typeof CategoryKindSchema>

const getModified = (value: string) => dayjs.parse(value)?.formatLocal()

function searchBy(text: string) {
  return text ? { Q: [`kind__icontains|${text}`, `name__icontains|${text}`, 'or'] } : {}
}
