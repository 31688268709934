import { TableViewSelect } from '@/modules/table-view/components'
import { useActiveTableView, useModuleTableViewsQuery, useTicketModule } from '../../hooks'

export function TicketViewSelect() {
  const { module, isVerticalViews } = useTicketModule()
  const { activeView, setActiveView } = useActiveTableView()
  const { queryParams: viewsQueryParams } = useModuleTableViewsQuery()

  return (
    <TableViewSelect
      className={'hidden md:block'}
      queryParams={viewsQueryParams}
      value={activeView}
      onChange={setActiveView}
      disabled={module?.data?.force_view || isVerticalViews}
      hideDefault={module?.data?.hide_default_none_view}
    />
  )
}
