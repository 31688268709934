import { DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, FormInstance, GetProp, Upload, UploadFile, UploadProps } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

type Props = {
  form: FormInstance
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64URL = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const ImageSignatureUpload = ({ form }: Props) => {
  const [file, setFile] = useState<UploadFile | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()

  const handlePreview: UploadProps['onPreview'] = async (file) => {
    file.preview = await getBase64URL(file.originFileObj as FileType)
    form.setFieldValue('image_data', file.preview)
    setPreviewUrl(file.url || (file.preview as string))
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const newFile = newFileList[0]
    setFile(newFile)
    if (newFile) handlePreview(newFile)
    else setPreviewUrl('')
  }

  return (
    <Upload.Dragger
      fileList={[]}
      className="relative"
      disabled={!!file}
      onChange={handleChange}
      beforeUpload={() => false}
      multiple={false}
    >
      <div className="flex justify-center items-center pb-26 min-h-[110px]">
        {!file && (
          <div className="flex flex-col justify-center items-center">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="mt-12">Click or drag file to this area to upload</p>
          </div>
        )}
        {file && <img src={previewUrl} height={110} className="object-contain" />}
        <div className="absolute text-sm bg-gray-100 border rounded-b bottom-0 left-0 w-full p-4 flex flex-row justify-between items-center">
          <div>{dayjs.formatLocal(dayjs.now())}</div>
          {file && (
            <Button
              icon={<DeleteOutlined />}
              type="link"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setFile(null)
                form.setFieldValue('image_data', undefined)
              }}
            >
              Remove
            </Button>
          )}
        </div>
      </div>
    </Upload.Dragger>
  )
}
