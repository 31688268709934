import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { ticketApiV2 } from '@/services/api-service'
import { FormView } from './form-view'

export const TicketFormView = view<any, { id: number; name: number; custom_form__name: string }>(FormView, {
  title: ({ data }) => (data?.name ? `#${data.name} ${data.custom_form__name}` : 'Create'),
  loader: async ({ params }) => {
    const ticketId = Number(params?.ticketId)
    if (!ticketId) return null
    return fetchQuery(ticketApiV2.get(ticketId, undefined, { fields: 'id,name,custom_form__name' }))
  }
})
