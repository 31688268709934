import { Button, Select } from '@/ui'
import { Form, Input, Space, Switch } from 'antd'
import { useState } from 'react'
import { ConfigureTicketModal } from './configue-ticket-modal'

export const QBOInvoiceSection = () => {
  const isQBOEnabled = Form.useWatch(['doc', 'accounting', 'qbo_enabled'])
  const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false)
  const isPrintEnabled = Form.useWatch(['doc', 'print', 'enabled'])

  return (
    <Form.Item hidden={!isQBOEnabled}>
      <h4 className="font-bold">Invoice</h4>
      <div className="grid grid-cols-3 gap-x-24">
        <div className="col-span-2 grid grid-cols-2 gap-x-24">
          <Form.Item label="Invoice Number Code" name="docnumber_code">
            <Input />
          </Form.Item>
          <Form.Item label="Ship Via" name={['doc', 'ship_via']}>
            <Input />
          </Form.Item>
          <Form.Item label="Tracking no" name={['doc', 'invoice_tracking_number']}>
            <Input />
          </Form.Item>
          <Form.Item label="Customer Memo" name={['doc', 'customer_memo']}>
            <Input.TextArea rows={1} />
          </Form.Item>
          <Form.Item label="Transaction Date (optional)" name={['doc', 'txn_date']}>
            <Select
              options={[
                {
                  label: 'Not set',
                  value: ''
                },
                {
                  label: '[timepoint_closed]',
                  value: '[timepoint_closed]'
                },
                {
                  label: '[timepoint_due]',
                  value: '[timepoint_due]'
                },
                {
                  label: '[timepoint_opened]',
                  value: '[timepoint_opened]'
                },
                {
                  label: '[timepoint_submitted]',
                  value: '[timepoint_shipped]'
                }
              ]}
            />
          </Form.Item>
          <Form.Item label="Private Memo" name={['doc', 'private_note']}>
            <Input.TextArea rows={1} />
          </Form.Item>
          <Form.Item label="Department/Location" name={['doc', 'invoice_department_name']}>
            <Input />
          </Form.Item>
        </div>
        <div>
          <div className="font-bold mb-16">Print and attach ticket</div>
          <div className="mb-16">
            <Space>
              <Form.Item noStyle name={['doc', 'print', 'enabled']}>
                <Switch />
              </Form.Item>
              <span>Print and attach ticket</span>
            </Space>
          </div>
          <Button block type="primary" disabled={!isPrintEnabled} onClick={() => setIsConfigureModalOpen(true)}>
            Options
          </Button>
        </div>
      </div>
      <Form.List name={['doc', 'custom_fields']}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field) => (
              <Space key={field.key}>
                <Form.Item label="Custom Field" name={[field.name, 'name']}>
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item label="Value" name={[field.name, 'value']}>
                  <Input placeholder="Value" />
                </Form.Item>
                <Form.Item label=" ">
                  <Button iconName="fa:trash" onClick={() => remove(field.name)}></Button>
                </Form.Item>
              </Space>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()}>
                Add Custom Field
              </Button>
            </Form.Item>
          </div>
        )}
      </Form.List>
      <ConfigureTicketModal open={isConfigureModalOpen} onCancel={() => setIsConfigureModalOpen(false)} />
    </Form.Item>
  )
}
