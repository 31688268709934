import { isEmpty } from 'lodash'
import { FC, useCallback } from 'react'
import { useTicket, useTicketSave } from '../../../../../hooks'
import { Property } from '../../../../../schemas'
import { AddSignatureButton } from '../add-signature-button/AddSignatureButton'
import { SignatureCard } from '../signature-card/SignatureCard'

type Props = {
  property: Property
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

export const SignatureInput: FC<Props> = ({ property, value, onChange, disabled }) => {
  const { ticket } = useTicket()
  const { saveTicket } = useTicketSave()

  const signature = !isEmpty(value) ? ticket.signatures?.find((a) => a.uuid === value) : null

  const handleAfterSign = useCallback(
    async (signature: { id: number; uuid: string }) => {
      onChange?.(signature.uuid)
      await saveTicket({ only: [property._field], override: { [property._field]: signature.uuid } })
    },
    [onChange, property._field, saveTicket]
  )

  return (
    <div>
      {signature ? (
        <SignatureCard signature={signature} disabled={disabled} />
      ) : (
        <AddSignatureButton property={property} afterSign={handleAfterSign} disabled={disabled}>
          Sign
        </AddSignatureButton>
      )}
    </div>
  )
}
