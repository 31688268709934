import { MODULE_TYPES } from '@/constants/general'
import { Select } from '@/ui'
import { Button } from '@/ui/button'
import { Form, Input, InputNumber, Space, Switch, Upload } from 'antd'
import { IconSelect } from '../icon-select'
import { AdvancedConfigModal } from './advanced-config-modal'

export const ModuleSettingsSection = () => {
  const form = Form.useFormInstance()
  const showOnMobileApp = Form.useWatch('for_mobile_app', form) ?? false
  const iconFile = Form.useWatch('icon_file', form) as { file: File } | undefined
  const iconUrl = Form.useWatch('icon_url', form) as string | undefined

  return (
    <div className="mb-40">
      <div className="flex mb-16 items-center justify-between">
        <h5 className="font-bold m-0">Module Settings</h5>
        <AdvancedConfigModal />
      </div>
      <div className="grid grid-cols-2 gap-x-24">
        <div className="grid grid-cols-3 gap-16">
          <Form.Item name="icon_url" hidden />
          <Form.Item label="Module Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Module Type" name="view_type">
            <Select
              showSearch
              allowClear={false}
              options={Object.entries(MODULE_TYPES).map(([key, value]) => ({ label: value, value: key }))}
            />
          </Form.Item>
          <Form.Item label="Module Position" name="position">
            <InputNumber className="w-full" />
          </Form.Item>
          {showOnMobileApp && (
            <Form.Item
              label="Custom Title (for Mobile App)"
              name={['data', 'title_for_mobile_app']}
              className="col-span-3"
            >
              <Input />
            </Form.Item>
          )}
        </div>
        <div className="pl-24 border-l border-l-border grid grid-cols-2">
          <div className="flex flex-col gap-y-16">
            <Space>
              <Form.Item noStyle name="for_desktop" valuePropName="checked">
                <Switch />
              </Form.Item>
              <span>For Desktop</span>
            </Space>
            <Space>
              <Form.Item noStyle name="for_mobile" valuePropName="checked">
                <Switch />
              </Form.Item>
              <span>For Mobile</span>
            </Space>
            <Space>
              <Form.Item noStyle name="for_mobile_app" valuePropName="checked">
                <Switch />
              </Form.Item>
              <span>Show on mobile app</span>
            </Space>
            <Space>
              <Form.Item noStyle name="is_active" valuePropName="checked">
                <Switch />
              </Form.Item>
              <span>Active</span>
            </Space>
          </div>
          <div>
            <div>
              <Space>
                <Form.Item label="Select Icon" name="font_icon">
                  <IconSelect onSelect={(icon) => form.setFieldValue('font_icon', icon)} />
                </Form.Item>
                <span className="w-32 h-32 rounded-full justify-center items-center bg-gray-200 flex ">or</span>
                <Form.Item label=" " name="icon_file">
                  <Upload showUploadList={false} beforeUpload={() => false}>
                    <Button>Upload icon file</Button>
                  </Upload>
                </Form.Item>
              </Space>
            </div>
            {(iconFile || iconUrl) && (
              <div>
                <div className="mb-10">Preview</div>
                <div className=" ">
                  <img
                    src={iconFile ? URL.createObjectURL(iconFile.file) : iconUrl}
                    className="w-40 object-contain"
                    alt="icon"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
