import { propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormItemProps, Select, SelectProps } from 'antd'
import { useMemo } from 'react'

type Props = {
  customFormsIds: number[]
  formItemProps?: FormItemProps
  propertiesQueryParams?: Record<string, any>
  valueKey?: string
  selectProps?: SelectProps<any>
}

export const PropertiesSelect = ({
  customFormsIds = [],
  propertiesQueryParams = {},
  valueKey = 'id',
  formItemProps,
  selectProps
}: Props) => {
  const propertiesQuery = useQuery({
    ...propertyApi.list<{ id: number; name: string; property_group__name: string }>({
      limit: 'None' as any,
      fields: 'id,name,property_group__name',
      property_group__custom_form_id__in: customFormsIds?.join(','),
      ...propertiesQueryParams
    }),
    enabled: !!customFormsIds?.length
  })

  const propertiesOptions = useMemo(() => {
    if (!customFormsIds?.length) {
      return []
    }

    const properties = propertiesQuery.data?.items ?? []

    return properties
      .map((p) => {
        return {
          label: `${p.property_group__name} / ${p.name}`,
          value: p[valueKey as keyof typeof p]
        }
      })
      .filter((v) => !!v)
      .sort((a, b) => a!.label.localeCompare(b!.label, 'en')) as { label: string; value: number }[]
  }, [propertiesQuery.data?.items, customFormsIds, valueKey])

  const isLoading = propertiesQuery.isInitialLoading || propertiesQuery.isFetching

  return (
    <Form.Item {...formItemProps}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        showSearch
        optionFilterProp="label"
        options={propertiesOptions}
        loading={isLoading}
        disabled={isLoading}
        {...selectProps}
      />
    </Form.Item>
  )
}
