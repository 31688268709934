import { isEmpty, lowerCase } from 'lodash'
import { useMemo } from 'react'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'
import { useTicketFormValues } from './use-ticket-form'
import { useTicketLabel } from './use-ticket-label'

export const usePrintRestrict = () => {
  const l = useTicketLabel()
  const { ticket } = useTicket()
  const { features } = useCustomForm()
  const { getPropertyValueById } = useTicketFormValues()

  const [restrictPrint, restrictMessage] = useMemo(() => {
    const restrictPrintConf = features.restrictPrint
    if (!restrictPrintConf?.enabled) return [false, null]

    if (restrictPrintConf?.pid) {
      const values = getPropertyValueById(restrictPrintConf.pid)?.split('|') || []
      const restrict = ['yes', '1', 'true'].includes(lowerCase(values[0]))

      if (!restrict) {
        return [true, isEmpty(values[1]) ? 'Restricted' : values[1]]
      }
    }

    if (ticket.status && (restrictPrintConf?.statuses || []).includes(ticket.status)) {
      return [true, `Restricted when ticket is in '${l('status_' + ticket.status)}' status`]
    }

    return [false, null]
  }, [features, getPropertyValueById, l, ticket.status])

  return { restrictPrint, restrictMessage }
}
