import { useSession } from '@/hooks'
import { companyApi, regionApi } from '@/services/api-service'
import { Office } from '@/types/office'
import { QuerySelect } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Form, FormListFieldData, Input, Select } from 'antd'

type Props = {
  field: FormListFieldData
  office?: Office
}

export const AddEditOfficeForm = ({ field, office }: Props) => {
  const regionsQuery = useQuery({ ...regionApi.list({ limit: 'None' as any }) })
  const { user } = useSession()

  return (
    <>
      <div className="">
        <Form.Item noStyle name={[field.name, 'id']} />
        {user?.is_superuser && (
          <Form.Item
            name={[field.name, 'company']}
            label="Company"
            rules={[{ required: true }]}
            hidden={!!office?.company}
          >
            <QuerySelect
              apiEndpoint={companyApi.list}
              apiSearchBy="name"
              renderOption={(item) => ({
                value: item.id,
                label: item.name
              })}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Office Name"
          name={[field.name, 'name']}
          rules={[
            {
              required: true,
              message: 'Please enter office name'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Region" name={[field.name, 'region']}>
          <Select
            options={
              regionsQuery.data?.items.map((region) => ({
                label: region.name,
                value: region.id
              })) ?? []
            }
          />
        </Form.Item>
        <Form.Item label="Phone Number" name={[field.name, 'phone_number']}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name={[field.name, 'email']}>
          <Input />
        </Form.Item>
        <Form.Item label="External ID" name={[field.name, 'external_id']}>
          <Input />
        </Form.Item>

        <Form.Item label="Company Name/ATTN" name={[field.name, 'address', 'attn_line']}>
          <Input />
        </Form.Item>
        <Form.Item label="Line 1" name={[field.name, 'address', 'line1']}>
          <Input />
        </Form.Item>
        <Form.Item label="Line 2" name={[field.name, 'address', 'line2']}>
          <Input />
        </Form.Item>

        <Form.Item label="City" name={[field.name, 'address', 'city']}>
          <Input />
        </Form.Item>
        <Form.Item label="State/Province" name={[field.name, 'address', 'state']}>
          <Input />
        </Form.Item>
        <Form.Item label="Zip/Postal Code" name={[field.name, 'address', 'zipcode']}>
          <Input />
        </Form.Item>
        <Form.Item label="Country" name={[field.name, 'address', 'country']}>
          <Input />
        </Form.Item>
      </div>
    </>
  )
}
