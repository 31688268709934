import { useApp } from '@/hooks'
import { companyApi, officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { Office } from '@/types/office'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Popconfirm, Space, Spin } from 'antd'
import { isAxiosError } from 'axios'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AddEditOfficeForm } from '../../components/add-edit-office-form'
import { ROLODEX_PATHS } from '../../constants'

export const AddOfficeView = ({
  companyId,
  officeId,
  onCancel
}: {
  companyId: number
  officeId?: number | null
  onCancel: () => void
}) => {
  // const { companyId, officeId } = useParams()
  const [form] = Form.useForm<{ offices: Office[] }>()

  const createOfficeMutation = useMutation({ ...officeApi.create() })
  const updateIndividualOfficeMutation = useMutation({ ...officeApi.update() })
  const deleteOfficeMutation = useMutation({ ...officeApi.delete() })

  const location = useLocation()
  const queryClient = useQueryClient()
  const { notification } = useApp()
  const companyQuery = useQuery({ ...companyApi.get<Company>(Number(companyId)) })
  const officeQuery = useQuery({ ...officeApi.get<Office>(Number(officeId)), enabled: !!officeId })
  const isPrivate = location.pathname.includes(ROLODEX_PATHS.privateCompanies)

  const handleSave = async () => {
    if (!companyId) {
      console.error('No company id')
      return
    }

    try {
      const formValues = await form.validateFields()
      const office = formValues.offices[0]
      if (!officeId) {
        await createOfficeMutation.mutateAsync({ ...office, company: companyId })
        onCancel()
      } else {
        await updateIndividualOfficeMutation.mutateAsync({ ...office, id: officeId, company: companyId })
      }
      await queryClient.invalidateQueries({ queryKey: ['companies'] })
      notification.success({
        message: 'Office saved successfully'
      })
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        if (error.response?.data?.detail) {
          notification.error({ message: error.response?.data?.detail })
        } else {
          notification.error({ message: 'Something went wrong' })
        }
      } else {
        notification.error({
          message: 'Please fill out all required fields'
        })
      }
    }
  }

  const handleDelete = async () => {
    await deleteOfficeMutation.mutateAsync({ id: officeId })
    await queryClient.invalidateQueries({ queryKey: ['companies'] })
    notification.success({
      message: 'Office deleted successfully'
    })
    onCancel()
  }

  useEffect(() => {
    if (officeQuery.data) {
      form.setFieldsValue({
        offices: [{ ...officeQuery.data }]
      })
    }
  }, [officeQuery])

  return (
    <Spin spinning={companyQuery.isLoading || officeQuery.isInitialLoading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          offices: [
            {
              name: '',
              region: '',
              phone_number: '',
              email: '',
              address: {
                attn_line: '',
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip_code: '',
                country: ''
              }
            }
          ]
        }}
      >
        <div className="flex justify-between mb-16 mt-12 items-center">
          {officeId ? <PageTitle>Edit Office {officeQuery.data?.name}</PageTitle> : <PageTitle>Add Office: </PageTitle>}
          <Space>
            <Button data-cy="cancel-office" iconName="fa:close" onClick={onCancel}>
              Cancel
            </Button>
            {officeId && (
              <Popconfirm
                title="Delete office"
                description={`Are you sure you want to delete #${officeId}?`}
                okText="Delete"
                placement="topRight"
                cancelText="Cancel"
                onConfirm={handleDelete}
              >
                <Button data-cy="delete-office" iconName="svg:trash">
                  Delete
                </Button>
              </Popconfirm>
            )}
            <Button
              data-cy="save-office"
              type="success"
              iconName="mi:save"
              loading={createOfficeMutation.isLoading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Space>
        </div>
        <Form.List name="offices">
          {(fields) => fields.map((field, index) => <AddEditOfficeForm key={index} field={field} />)}
        </Form.List>
      </Form>
    </Spin>
  )
}
