import { Form, FormItemProps, Input } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { FieldConfig } from '../../../../schemas'
import { getFieldInput } from './helpers'

type Props = FormItemProps & {
  fieldConfig?: FieldConfig
  className?: string
  inputProps?: any
  forceShow?: boolean
}

export const ItemField: FC<Props> = ({ fieldConfig, className, inputProps, forceShow = false, ...formItemProps }) => {
  if (!fieldConfig || (fieldConfig.hidden && !forceShow)) return null

  const fieldInput = getFieldInput(fieldConfig.field) || { className: 'w-4/12', render: (p) => <Input /> }
  const FormItem = fieldInput.wrapper || Form.Item

  return (
    <FormItem
      className={cn('mb-8 px-4', fieldInput.className || 'w-4/12', className)}
      name={['data', fieldConfig.field]}
      label={<span className={'line-clamp-1'}>{fieldConfig.label}</span>}
      {...formItemProps}
    >
      {fieldInput.render({ disabled: fieldConfig.disabled, ...inputProps }, fieldConfig)}
    </FormItem>
  )
}
