import { CustomFormSelect } from '../custom-form-select'
import { DefaultTimeFilterSelect } from '../default-time-filter-select'

export const ModuleTypeMyTimeFields = () => {
  return (
    <div className="flex gap-x-16">
      <CustomFormSelect formItemProps={{ name: ['data', 'custom_form_id'] }} />
      <DefaultTimeFilterSelect />
    </div>
  )
}
