import { Button, Modal } from '@/ui'
import { getCronFrequency } from '@/utils/get-cron-frequency'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { Form, Input, Select, Space, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'

type CrontabEditorModelProps = {
  isOpen: boolean
  onChange: (value: string) => void
  cronExpression: string
  onClose: () => void
}

type CronFrequency = 'month' | 'week' | 'day' | 'hour' | 'minute'

export const CrontabEditorModel = ({ isOpen, cronExpression, onChange, onClose }: CrontabEditorModelProps) => {
  const cronFrequency: CronFrequency | null = useMemo(() => {
    return getCronFrequency(cronExpression)
  }, [cronExpression])

  const cronDaysOfMonth = useMemo(() => {
    const fields = cronExpression.split(' ')
    const dayOfMonth = fields[2]
    return dayOfMonth.split(',').map((day) => parseInt(day))
  }, [cronExpression])

  const handleDayOfWeekClick = (day: number) => {
    const fields = cronExpression.split(' ')
    const daysOfWeek = fields[4].split(',')
    const index = daysOfWeek.indexOf(day.toString())

    // select at least one day
    if (index !== -1 && daysOfWeek.length === 1) {
      return
    }

    if (index === -1) {
      daysOfWeek.push(day.toString())
    } else {
      daysOfWeek.splice(index, 1)
    }

    onChange(`0 0 * * ${daysOfWeek.join(',')}`)
  }

  const handleTimeChange = (value: dayjs.Dayjs | null) => {
    if (!value) {
      return
    }

    const fields = cronExpression.split(' ')
    fields[0] = value?.minute().toString() || '0'
    fields[1] = value?.hour().toString() || '0'

    onChange(fields.join(' '))
  }

  const handleFrequencyChange = (value: CronFrequency) => {
    if (value === 'minute') {
      onChange('* * * * *')
    } else if (value === 'hour') {
      onChange('0 * * * *')
    } else if (value === 'day') {
      onChange('0 0 * * *')
    } else if (value === 'week') {
      onChange('0 0 * * 1')
    } else if (value === 'month') {
      onChange('0 0 1 * *')
    }
  }

  const handleSave = () => {
    onChange(cronExpression)
    onClose()
  }

  return (
    <>
      <Modal
        destroyOnClose
        open={isOpen}
        title="Repeat Frequency"
        okText="Save"
        withScreenMaxHeight
        onCancel={onClose}
        onOk={handleSave}
      >
        <Form layout="vertical">
          <Form.Item label="Repeat every">
            <Select
              value={cronFrequency}
              options={[
                {
                  label: 'Minute',
                  value: 'minute'
                },
                {
                  label: 'Hour',
                  value: 'hour'
                },
                {
                  label: 'Day',
                  value: 'day'
                },
                {
                  label: 'Week',
                  value: 'week'
                },
                {
                  label: 'Month',
                  value: 'month'
                }
              ]}
              onChange={handleFrequencyChange}
            />
          </Form.Item>
          {cronFrequency === 'week' && (
            <Form.Item label="Repeat on">
              <Space>
                {Array.from({ length: 7 }, (_, i) => i + 1).map((day) => (
                  <Button
                    shape="circle"
                    key={day}
                    value={day}
                    type={cronExpression.split(' ')[4].split(',').includes(day.toString()) ? 'primary' : 'default'}
                    onClick={() => handleDayOfWeekClick(day)}
                  >
                    {dayjs().day(day).format('dd')}
                  </Button>
                ))}
              </Space>
            </Form.Item>
          )}
          {cronFrequency === 'month' && (
            <Form.Item label="Monthly on day">
              <Select<number[]>
                value={cronDaysOfMonth}
                mode="multiple"
                options={Array.from({ length: 31 }, (_, i) => i + 1).map((day) => ({
                  label: dayjs('01-01-2024').date(day).format('Do'), // 01-01-2024 for always rendering 31 days in options
                  value: day
                }))}
                onChange={(value) => {
                  // select at least one day
                  if (value.length === 0) {
                    return
                  }

                  onChange(`0 0 ${value.join(',')} * *`)
                }}
              />
            </Form.Item>
          )}
          {!['hour', 'minute'].includes(cronFrequency as string) && (
            <Form.Item label="At Hour / Minute">
              <TimePicker
                format="hh:mm A"
                showNow={false}
                value={dayjs()
                  .hour(parseInt(cronExpression.split(' ')[1]))
                  .minute(parseInt(cronExpression.split(' ')[0]))}
                onChange={handleTimeChange}
              />
            </Form.Item>
          )}
          <div className={'border border-border rounded p-10 pb-2 mb-10'}>
            <Form.Item label={'Cron Expression'} help={humanizeCronExpr(cronExpression)}>
              <Input
                value={cronExpression}
                onChange={(e) => {
                  onChange(e.target.value || '')
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}
