import { StatusMap } from '@/modules/custom-form/hooks'
import { parseRequirements } from '@/modules/jas/job-manager/helpers'
import { JobRole } from '@/modules/jas/job-manager/types'
import { TicketsCalendarConfig } from '@/modules/module/schemas'
import { generateNumberRange } from '@/utils'
import dayjs from 'dayjs'
import { CalendarTicket } from './schemas'

export const transformTicket = (
  ticket: CalendarTicket,
  config: TicketsCalendarConfig | null | undefined,
  jobRolesMap: Record<number, JobRole>,
  formStatuses: StatusMap
): CalendarTicket[] => {
  const requirements = parseRequirements(ticket.property?.[config?.requirements_pid || ''] || '', jobRolesMap)

  const startDateTime = dayjs.parse(ticket.timepoint_due || '')?.clearSeconds() || null
  if (!startDateTime) return []

  const endDateTime = startDateTime
    .add(Number(ticket.property?.[config?.job_length_pid || '']) || 0, 'hour')
    .clearSeconds()

  const daysSpan = endDateTime.diff(startDateTime, 'days')

  return generateNumberRange(0, daysSpan).map((span, idx) => {
    let _startDateTime, _endDateTime

    if (idx === 0) {
      _startDateTime = startDateTime
      _endDateTime = daysSpan === 0 ? endDateTime : startDateTime.endOf('day')
    } else {
      _startDateTime = startDateTime.add(span, 'days').startOf('day')
      _endDateTime = span === daysSpan ? endDateTime : _startDateTime.endOf('day')
    }

    return {
      ...ticket,
      _key: `${ticket.id}-${span}`,
      _jobText: `${ticket.customer_office__name || 'NA'} / ${
        ticket.property?.[config?.calendar_description_pid || ''] || 'NA'
      } / (${formStatuses[ticket.computed_status]})`,
      _headcount: (requirements.byType.employees || 0) + (requirements.byType.equipments || 0),
      _startDateTime: _startDateTime,
      _endDateTime: _endDateTime,
      _localIsoDate: _startDateTime.formatISODate(false),
      _localIsoDateTime: _startDateTime.formatISO(false)
    }
  })
}
