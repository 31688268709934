import { useWiki } from '@/modules/wiki/hooks'
import { Icon } from '@/ui/icons'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

export const WikiView: React.FC = () => {
  const { data, isEnabled, isError } = useWiki()
  const [searchParams] = useSearchParams()

  const refStatus = !isEnabled || isError ? 'failed' : searchParams.get('status') || 'pending'
  if (refStatus !== 'failed' && data && data?.url) {
    window.location.href = data?.url
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {refStatus === 'pending' && (
        <div>
          <h3 className="text-center text-primary">
            <Icon name={'fa:diamond-turn-right'} /> Redirecting...
          </h3>
          <p className="text-lg">Please wait while we transfer you to OilCommand Training</p>
        </div>
      )}
      {refStatus === 'failed' && (
        <div className="text-center">
          <h3 className="text-danger">
            <Icon name={'fa:warning'} /> Access Denied!
          </h3>
          <p className="text-lg">Failed to open OilCommand Training</p>
        </div>
      )}
    </div>
  )
}
