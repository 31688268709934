import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'
import { SCRIPT_FIELDS } from '../../constants'
import { usePropertyTypesQuery } from '../../hooks'

export const ScriptField = () => {
  const form = Form.useFormInstance()
  const fieldsWatch = Form.useWatch([], form)
  const { getTypeNameById } = usePropertyTypesQuery()
  const propertyTypeId = fieldsWatch?.property_type?.id
  const type = getTypeNameById(propertyTypeId)

  // only show a binding script for ScriptedButton from advanced fields
  if (type === 'ScriptedButton') return null

  if (type && SCRIPT_FIELDS.includes(type) && !(fieldsWatch?.is_managed || fieldsWatch?.use_binding)) {
    return (
      <div className="mt-20">
        <Form.Item
          name={type === 'Script' || type === 'RecordSelect' || type === 'Table' ? 'script' : 'web_binding'}
          label="Script"
        >
          <CodeEditor
            data-cy="script-field"
            placeholder="// Write script here"
            mode="javascript"
            minLines={5}
            maxLines={Infinity}
            isInModal
          />
        </Form.Item>
      </div>
    )
  }

  return null
}
