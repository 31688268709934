import { Collapse } from '@/ui/collapse'
import { Typography } from 'antd'
import { ListPreviewTemplate } from './list-preview-template'
import { Script } from './script'

export const CodeBehind = () => {
  return (
    <div className="mt-20">
      <Collapse
        items={[
          {
            label: (
              <Typography.Title level={5} className="!mb-0">
                Script
              </Typography.Title>
            ),
            children: <Script />
          },
          {
            label: (
              <Typography.Title level={5} className="!mb-0">
                List Preview Template (Mobile Web)
              </Typography.Title>
            ),
            children: <ListPreviewTemplate />
          }
        ]}
      />
    </div>
  )
}
