import { AddEditCompanyForm } from '@/modules/rolodex/components/add-edit-company-form'
import { Company } from '@/types/company'

type Props = {
  company: Company
}

export const CompanyInfoTab = ({ company }: Props) => {
  return <AddEditCompanyForm company={company} />
}
