import { CodeEditor } from '@/components/code-editor'
import { Checkbox, Form, Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'
import { FormItem } from '../form-item'
import { CustomCssFields } from './custom-css-fields'

export const BasicStyleAndFormatting = () => {
  const setCustomForm = useSetAtom(customFormAtom)
  const providerMarkup = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.provider_markup), []))

  return (
    <div>
      <h3 className="font-bold">Basic Style And Formatting</h3>
      <Form layout="vertical">
        <div className="flex gap-x-50">
          <FormItem name="print_hide_empty_subtotals" isCheckbox>
            <Checkbox>When printing, hide subcategory subtotals of $0.00.</Checkbox>
          </FormItem>
          <FormItem name="print_landscape" isCheckbox>
            <Checkbox>Landscape print orientation</Checkbox>
          </FormItem>
        </div>
        <div className="flex gap-50">
          <FormItem label="Custom print font size" name="font_size_pt" className="flex-1">
            <Input type="number" />
          </FormItem>
          <FormItem label="Custom signature height scaling multiplier" name="signature_scale" className="flex-1">
            <Input type="number" />
          </FormItem>
        </div>
        <CustomCssFields />
        <Form.Item label="Provider Format Markup (web & print)">
          <CodeEditor
            mode="html"
            height="200px"
            value={providerMarkup || ''}
            onChange={(value) => setCustomForm((prev) => ({ ...prev, provider_markup: value }))}
          />
        </Form.Item>
      </Form>
    </div>
  )
}
