import { TABLE_VIEW_TYPES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { TABLE_VIEW_PATHS } from '@/modules/table-view/constants'
import { tableViewApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Form, Input, Modal, Select } from 'antd'
import { isAxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
  tableViewType: string
}

export const AddViewModal = ({ tableViewType }: Props) => {
  const [form] = Form.useForm<{ name: string; type: string; description?: string }>()
  const type = Form.useWatch('type', form) ?? ''
  const [isOpen, setIsOpen] = useState(false)
  const { notification } = useApp()
  const { company } = useSession()
  const addTableViewMutation = useMutation({ ...tableViewApi.create<{ id: number }>() })
  const typeValue = TABLE_VIEW_TYPES[type as keyof typeof TABLE_VIEW_TYPES]
  const toggleModal = () => setIsOpen((prev) => !prev)
  const navigate = useNavigate()
  const location = useLocation()

  const handleCancel = () => {
    form.resetFields()
    toggleModal()
  }

  const handleSave = async () => {
    try {
      const { type, name, description } = await form.validateFields()

      const tableView = await addTableViewMutation.mutateAsync({
        type,
        name,
        description,
        company: company.id
      })

      notification.success({
        message: 'View added successfully'
      })

      setTimeout(() => {
        navigate(TABLE_VIEW_PATHS.editTableView(tableView.id), {
          state: {
            from: location.pathname
          }
        })
      }, 1000)
    } catch (error) {
      if (isAxiosError(error)) {
        notification.error({
          message: 'Something went wrong'
        })
      }
    }
  }

  useEffect(() => {
    if (!isOpen) {
      return
    }

    form.setFieldsValue({
      type: Object.keys(TABLE_VIEW_TYPES).find(
        (key) => TABLE_VIEW_TYPES[key as keyof typeof TABLE_VIEW_TYPES] === tableViewType
      )
    })
  }, [isOpen, form, tableViewType])

  return (
    <>
      <Button iconName="mi:add" type="primary" onClick={toggleModal}>
        Add View
      </Button>
      <Modal
        open={isOpen}
        okText={`Add ${typeValue} View`}
        title={`New ${typeValue} View`}
        onOk={handleSave}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select options={Object.entries(TABLE_VIEW_TYPES).map(([key, value]) => ({ label: value, value: key }))} />
          </Form.Item>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
