import { z } from 'zod'

export const RecordSchema = z.object({
  id: z.string(),
  char_1: z.string(),
  char_2: z.number(),
  char_3: z.string(),
  char_4: z.string(),
  data: z.record(z.string(), z.any()),
  modified_at: z.string()
})

export type Record = z.infer<typeof RecordSchema>
