import { useApp } from '@/hooks'
import { useBackgroundJobsHook } from '@/layouts/dashboard-layout/status-bar/hooks'
import { ticketBatchJobApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { TicketBatchJobFormType } from '../types'
import { useTicketsParam } from './use-ticket-params'
import { useTicketSelection } from './use-ticket-selection'

export const useTicketBatchJob = () => {
  const { notification } = useApp()
  const { params } = useTicketsParam()
  const { trackBackgroundJob } = useBackgroundJobsHook()
  const { selectedTicketsIds, allTicketsSelected } = useTicketSelection()
  const mutation = useMutation(ticketBatchJobApi.create<TicketBatchJobFormType>())

  const submit = async (jobConf: TicketBatchJobFormType, onSuccess: () => void) => {
    let queryFilters = params
    if (!allTicketsSelected) {
      queryFilters = { ...queryFilters, 'Q[]': ['id__in|' + selectedTicketsIds.join(',')] }
    }
    jobConf.query = queryFilters
    jobConf.actions = jobConf.actions.filter((a) => a._enable)

    try {
      const response = await mutation.mutateAsync(jobConf)
      if (response.bg_job) {
        trackBackgroundJob(response.bg_job.id, { invalidateQueryKeys: ['tickets'] })
      }

      notification.info({
        message: 'Batch Job Created...',
        description: 'You can track the progress in the background jobs section'
      })
      onSuccess()
    } catch (e: any) {
      notification.error({
        message: 'Batch Job Failed',
        description: e?.message || 'Unknown error'
      })
    }
  }

  return {
    submit,
    mutation
  }
}
