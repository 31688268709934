import { loadDayjsPlugins } from '@/lib/dayjs'
import { logRocket } from '@/services/monitoring/log-rocket'
import { sentry } from '@/services/monitoring/sentry'
import { intercom } from '@/services/support/intercom'
import { safeJsonParse } from '@/utils'

export const beforeRootRender = () => {
  loadDayjsPlugins()
  logRocket.init()
  intercom.init()

  try {
    const user = safeJsonParse(localStorage.user)
    if (localStorage.token && user) {
      sentry.identify(user)
      logRocket.identify(user)
      intercom.identify(user)
    }
  } catch (err) {
    // ... ignore
  }
}

export const afterRootRender = () => {
  // ...
}
