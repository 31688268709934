import { SIGNATURE_STYLES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { signatureApi } from '@/services/api-service'
import { Button, ButtonProps, Modal, Select, Tooltip } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, ColorPicker, Form, Input } from 'antd'
import dayjs from 'dayjs'
import { FC, useCallback, useState } from 'react'
import { refreshQueries } from '../../../../../helpers'
import { useTicket } from '../../../../../hooks'
import { Property } from '../../../../../schemas'
import { getSignatureStyleCSS } from '../helpers'
import { SignaturePreview } from '../signature-preview'

type Props = Omit<ButtonProps, 'property'> & {
  property?: Property
  afterSign?: (attachment: { id: number; uuid: string }) => void
}

export const AddSignatureButton: FC<Props> = ({ children, property, afterSign, disabled, ...props }) => {
  const [form] = Form.useForm()
  const { contact } = useSession()
  const { notification } = useApp()
  const { ticket } = useTicket()

  const isDisabled = !ticket.id || disabled

  const [open, setOpen] = useState(false)
  const initialValues = {
    name: `${contact?.first_name || ''} ${contact?.last_name || ''}`.trim(),
    email: contact?.email || '',
    style: 'L',
    color: '#000000'
  }

  // watch name, style, color form fields
  const iName = Form.useWatch('name', { form })
  const iStyle = Form.useWatch('style', { form })
  const iColor = Form.useWatch('color', { form })

  const { mutateAsync, isLoading } = useMutation(signatureApi.create())
  const handleSign = useCallback(
    async (values: any) => {
      try {
        const payload = { ...(await form.validateFields()), ticket: ticket.id, property_id: property?.id || null }

        const signature = await mutateAsync(payload)

        if (afterSign) {
          afterSign?.(signature)
        } else {
          await refreshQueries()
        }

        await refreshQueries()

        notification.success({
          message: 'Signature Added',
          description: (
            <span>
              <b>{payload.name}</b> signed successfully
            </span>
          )
        })
      } catch (error) {
        notification.error({
          message: 'Signature Add Failed',
          description: 'An error occurred while adding signature'
        })
      }
    },
    [afterSign, form, mutateAsync, notification, property?.id, ticket.id]
  )

  return (
    <>
      <Tooltip title={!isDisabled ? '' : 'Will be available after ticket saving'}>
        <Button
          shape={'round'}
          iconName={'fa:pen-swirl'}
          onClick={() => setOpen(true)}
          primary
          {...props}
          disabled={isDisabled}
        >
          {children || 'Add Signature'}
        </Button>
      </Tooltip>
      <Modal
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleSign}
        okText={'Sign'}
        okButtonProps={{ loading: isLoading }}
        iconName={'fa:pen-swirl'}
        title="Add Signature"
      >
        <Form form={form} layout={'vertical'} initialValues={initialValues} validateTrigger={false}>
          <div className={'flex flex-row gap-10'}>
            <Form.Item name={'name'} label={'Your Name'} className={'w-full'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={'email'} label={'Your Email'} className={'w-full'}>
              <Input />
            </Form.Item>
          </div>
          <div className={'flex flex-row gap-10'}>
            <Form.Item name={'style'} label={'Signature Style'} className={'w-full'}>
              <Select
                options={Object.entries(SIGNATURE_STYLES).map(([k, v]) => ({
                  value: k,
                  label: <span className={getSignatureStyleCSS(k)}>{v}</span>
                }))}
              />
            </Form.Item>
            <Form.Item name={'color'} label={'Signature Color'} className={'w-full'}>
              <ColorPicker
                className={'w-full justify-start'}
                showText
                format="hex"
                onChange={(agg) => form.setFieldValue('color', '#' + agg.toHex())}
              />
            </Form.Item>
          </div>
          <div className={'flex flex-row justify-center'}>
            <SignaturePreview
              className={'border rounded w-[275px]'}
              signature={{ name: iName, style: iStyle, color: iColor, created_at: dayjs.now().formatISO() }}
            />
          </div>
          <div className={'mt-24'}>
            <Form.Item
              name={'consent'}
              label={'Consent'}
              rules={[
                {
                  validator: (_, v) => (v === true ? Promise.resolve() : Promise.reject()),
                  message: 'You must give consent before signing.'
                }
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                <div className={'text-justify text-sm'}>
                  By clicking <b>"Sign"</b>, I agree that the signature and initial will be the electronic
                  representation of my signature for all purposes when I use them on the document number:
                  <b> #{ticket.name}</b>. This action is the same as a pen-and-paper signature.
                </div>
              </Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}
