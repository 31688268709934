import { Fields } from '@/components/format-picker/types'
import {
  LINE_ITEM_FIELDS,
  TICKET_FIELDS
} from '@/modules/settings/custom-forms/custom-form-layout-view/property-groups/edit-property-modal/bpa-builder/constants'
import { propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormInstance } from 'antd'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'
import { z } from 'zod'

export const useAllFields = (form?: FormInstance) => {
  const selectedCustomForms = Form.useWatch(['_ns_configs', 'data', 'custom_forms'], form) || []

  const propertiesQuery = useQuery({
    ...propertyApi.list<Property>({
      limit: 'None',
      property_group__custom_form_id__in: selectedCustomForms.join(','),
      fields: PropertySchema
    }),
    enabled: !!selectedCustomForms.length
  })

  const properties: Fields[] = useMemo(
    () =>
      (propertiesQuery.data?.items || []).map((item) => ({
        key: nanoid(),
        title: `${item.property_group__name} / ${item.name}`,
        text: `P[${item.key}]`,
        isHeader: false,
        hide: false
      })),
    [propertiesQuery.data?.items]
  )

  const ticketFields = useMemo(
    () =>
      TICKET_FIELDS.map((item) => ({
        key: nanoid(),
        title: item.label,
        text: `F[${item.value}]`,
        isHeader: false,
        hide: false
      })),
    []
  )

  const lineItemFields = useMemo(
    () =>
      LINE_ITEM_FIELDS.map((item) => ({
        key: nanoid(),
        title: item.label,
        text: `L[${item.value}]`,
        isHeader: false,
        hide: false
      })),
    []
  )

  const fields: Fields[] = useMemo(
    () => [
      { key: '1', title: 'Ticket Fields', text: '', isHeader: true, hide: false },
      ...ticketFields,
      { key: '2', title: 'Line Item Fields', text: '', isHeader: true, hide: false },
      ...lineItemFields,
      { key: '3', title: 'Properties', text: '', isHeader: true, hide: false },
      ...(selectedCustomForms?.length ? properties : [])
    ],
    [properties, ticketFields, lineItemFields, selectedCustomForms?.length]
  )

  const allFields = useMemo(() => [...fields, ...ticketFields, ...properties], [fields, properties, ticketFields])

  return { isLoading: propertiesQuery.isFetching, fields, allFields }
}

const PropertySchema = z.object({
  id: z.number(),
  name: z.string(),
  key: z.string(),
  property_group__name: z.string(),
  property_type__name: z.string()
})
type Property = z.infer<typeof PropertySchema> // optional
