import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { useApp } from '@/hooks'
import { Alert, Checkbox, Form, Select } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const Configuration = () => {
  const { labels } = useApp()
  const skuSearch = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v._sku_search), []))
  const [customForm, setCustomForm] = useAtom(customFormAtom)

  const options = CATEGORY_KIND_FIELDS.map((item) => ({
    label: `${labels[`lineitem__${item}`]} (${item})`,
    value: item
  }))

  return (
    <div>
      <h5 className="font-bold">Configs</h5>
      <Form.Item className="mb-12">
        <Checkbox
          checked={!!skuSearch?.enabled}
          data-cy="custom-form-sku-search-checkbox"
          onChange={(e) =>
            setCustomForm((prev) => ({
              ...prev,
              _sku_search: {
                ...prev._sku_search,
                enabled: e.target.checked
              }
            }))
          }
        >
          Search SKU using extended fields by default
        </Checkbox>
      </Form.Item>
      <Form.Item label="SKU Search Extended Fields">
        <div className="relative">
          <Select
            data-cy="custom-form-sku-search-select"
            options={options}
            mode="multiple"
            placeholder="Select"
            value={skuSearch?.fields}
            onChange={(value) =>
              setCustomForm((prev) => ({
                ...prev,
                _sku_search: {
                  ...prev._sku_search,
                  fields: value
                }
              }))
            }
          />
          {skuSearch?.enabled && isEmpty(skuSearch?.fields) && (
            <Alert
              className="absolute bottom-0 left-0 translate-y-[48px]"
              showIcon
              type="warning"
              message="Select one or more fields for SKU extended search"
            />
          )}
        </div>
      </Form.Item>
    </div>
  )
}
