import { useApp } from '@/hooks'
import { useTicketSelection } from '@/modules/ticket/list/hooks'
import { ticketApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { Checkbox, Form } from 'antd'
import { FC } from 'react'
import { TicketBulkAction } from './TicketBulkAction'

type BundleOptions = {
  include_attachments: boolean
  selected_ticket_ids: number[]
}

export const TicketBundleButton: FC = () => {
  const [form] = Form.useForm<BundleOptions>()

  const { selectedTickets } = useTicketSelection()

  const { notification } = useApp()
  const bundleMutation = useMutation(ticketApi.bundle())

  const onOk = async (closeFn: any) => {
    try {
      await form.validateFields()
    } catch (error) {
      return
    }

    try {
      // call api
      await bundleMutation.mutateAsync({
        ...{
          ...form.getFieldsValue(),
          selected_ticket_ids: selectedTickets.map((ticket) => ticket.id)
        }
      })

      // notify and close
      notification.info({
        message: 'In Progress',
        description: (
          <div>
            <div>Tickets bundle is being built on background.</div>
            <div>Please check on View Bundle PDFs dialog.</div>
          </div>
        )
      })
      closeFn(true)
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: (
          <div>
            <div>Failed to build tickets bundle.</div>
            <div>{e.toString()}</div>
          </div>
        )
      })
    }
  }

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:file-pdf', children: 'Bundle PDF' }}
      modal={{
        title: 'Bundle Tickets PDF',
        okText: 'Start Bundle',
        onOk: onOk,
        okButtonProps: { loading: bundleMutation.isLoading }
      }}
      optionsTitle={'Bundle Options'}
    >
      <Form form={form} layout={'vertical'} validateTrigger={'onBlur'}>
        <Form.Item name={'include_attachments'} valuePropName="checked">
          <Checkbox defaultChecked={false}>Include Attachments?</Checkbox>
        </Form.Item>
      </Form>
    </TicketBulkAction>
  )
}
