import { dig } from '@/utils/dig'
import { PrimitiveAtom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

type PathValue<T, P extends string> = P extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? PathValue<T[Key], Rest>
    : never
  : P extends keyof T
    ? T[P]
    : never

export const useAtomSelect = <T = any, P extends string = ''>(atom: PrimitiveAtom<T>, path: P) => {
  return useAtomValue(useMemo(() => selectAtom(atom, (s) => dig(s, path) as PathValue<T, P>), [atom, path]))
}
