import { Icon, TipButton } from '@/ui'
import { useRulesHandlers } from '../../hooks'

export const RulesHeaderButtons = () => {
  const { onAddRule, setAllRulesExpanded } = useRulesHandlers()

  return (
    <>
      <TipButton
        title="Expand"
        iconName={'fa:arrows-from-line'}
        size={'small'}
        onClick={() => setAllRulesExpanded(true)}
        data-cy="expand-button"
      ></TipButton>
      <TipButton
        title="Collapse"
        iconName={'fa:arrows-to-line'}
        size={'small'}
        onClick={() => setAllRulesExpanded(false)}
        data-cy="collapse-button"
      />
      <TipButton
        data-cy="add-rule-button"
        type={'primary'}
        icon={<Icon name={'fa:add'} />}
        size={'small'}
        onClick={onAddRule}
        flashTitle="Added"
      >
        Add Rule
      </TipButton>
    </>
  )
}
