import { ViewTicketLink } from '@/modules/ticket/components'
import { jasScheduleEventApi } from '@/services/api-service'
import { Icon, Popover, QueryTable } from '@/ui'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { Resource } from '../../schemas'
import { JobEventBar } from './JobEventBar'

type Props = { title: string; schedule?: Resource['calendar'][string]; date: Dayjs }

export function JobsCellOverlay({ title, schedule, date }: Props) {
  const [renderPopover, setRenderPopover] = useState(false)

  const popoverChildren = (
    <button className={'px-8 rounded bg-transparent hover:bg-background-accent transition cursor-pointer'}>
      <Icon name={'fa:ellipsis-vertical'} />
    </button>
  )

  return (
    <>
      <div
        className={'absolute right-0 top-0'}
        onFocus={() => setRenderPopover(true)}
        onMouseEnter={() => setRenderPopover(true)}
      >
        {renderPopover ? (
          <Popover
            trigger={['click', 'contextMenu']}
            title={title}
            contentClassNames={'!p-0'}
            content={
              <div>
                <QueryTable
                  size={'small'}
                  className={'border-none'}
                  queryParams={{
                    id__in: schedule?.events?.map((event) => event.id).join(','),
                    fields:
                      'id,job_ticket_id,job_ticket__custom_form_id,job_ticket__name,job_ticket__location__name,start_datetime,end_datetime',
                    order: 'start_datetime'
                  }}
                  queryApi={jasScheduleEventApi.list}
                  queryOptimize={false}
                  pagination={false}
                  columns={[
                    {
                      title: 'Ticket #',
                      dataIndex: 'job_ticket__name',
                      key: 'job_ticket__name',
                      width: 80,
                      render: (_, row) => (
                        <ViewTicketLink ticketId={row.job_ticket_id} formId={row.job_ticket__custom_form_id}>
                          {row.job_ticket__name}
                        </ViewTicketLink>
                      )
                    },
                    {
                      title: 'Location',
                      dataIndex: 'job_ticket__location__name',
                      key: 'job_ticket__location__name',
                      width: 200
                    },
                    {
                      title: 'Period',
                      dataIndex: 'start_datetime',
                      key: 'start_datetime',
                      width: 225,
                      render: (_, row) => (
                        <div className={'text-sm'}>
                          <span>{dayjs.formatLocal(row.start_datetime)}</span>
                          <br />
                          <span>{dayjs.formatLocal(row.end_datetime)}</span>
                        </div>
                      )
                    },
                    {
                      title: 'Duration',
                      key: 'duration',
                      render: (_, row) =>
                        (dayjs.parse(row.end_datetime)?.diff?.(dayjs.parse(row.start_datetime), 'hour') || 0) + 'h'
                    }
                  ]}
                />
              </div>
            }
          >
            {popoverChildren}
          </Popover>
        ) : (
          popoverChildren
        )}
      </div>

      <div className={'w-full absolute left-0 bottom-2 px-10'}>
        <div className={'relative w-full h-[5px] rounded overflow-hidden bg-background opacity-75'}>
          {schedule?.events?.map((event) => (
            <JobEventBar key={event.id} event={event} date={date} />
          ))}
        </div>
      </div>
    </>
  )
}
