import { useApp } from '@/hooks'
import { customFormApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { Spin, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { useParams } from 'react-router-dom'
import { useCustomFormQuery } from '../hooks'

export const CompanyIconUpload = () => {
  const { id } = useParams()
  const { notification } = useApp()

  const updateTicketLogoMutation = useMutation({
    ...customFormApi.update({}, 'icon', undefined, {
      show_all: 'True'
    })
  })

  const { customFormQuery } = useCustomFormQuery()
  const logoUrl = customFormQuery.data?.ticket_logo_url || customFormQuery.data?.primary_company__icon

  const handleDeleteTicketLogo = async () => {
    const formData = new FormData()
    formData.append('id', id || '')
    formData.append('key', 'ticket_logo')
    await updateTicketLogoMutation.mutateAsync(formData)
    await customFormQuery.refetch()
    notification.success({
      message: 'Ticket logo deleted'
    })
  }

  const handleUploadTicketLogo = async (file: RcFile) => {
    const formData = new FormData()
    formData.append('id', id || '')
    formData.append('key', 'ticket_logo')
    formData.append('ticket_logo', file)
    await updateTicketLogoMutation.mutateAsync(formData)
    await customFormQuery.refetch()
    notification.success({
      message: 'Ticket logo saved'
    })
    return false // prevent antd from uploading the file
  }

  return (
    <Spin spinning={updateTicketLogoMutation.isLoading}>
      <Upload.Dragger
        fileList={[]}
        className="relative"
        beforeUpload={handleUploadTicketLogo}
        data-cy="company-icon-upload"
      >
        <div className="flex justify-center items-center">
          <img src={logoUrl} width={200} height={80} className="object-contain" />
          {customFormQuery.data?.ticket_logo_url && (
            <Button
              data-cy="delete-ticket-logo"
              iconName="svg:trash"
              className="absolute right-16 top-16"
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteTicketLogo()
              }}
            />
          )}
        </div>
        <p className="mt-12">Click or drag file to this area to upload</p>
      </Upload.Dragger>
    </Spin>
  )
}
