import { AppLink, Button } from '@/ui'
import { template } from 'lodash'
import { FC, useMemo } from 'react'
import { useButtonContext } from '../../hooks'

export const OpenLinkButton: FC = () => {
  const { ticket, property, buttonProps } = useButtonContext()
  const { link, target } = property._buttonConfig?.vars || {}

  const compiledLink = useMemo(() => {
    const compiled = template(link || '')
    return compiled({ ticket })
  }, [link, ticket])

  return (
    <AppLink to={compiledLink} target={target || '_self'}>
      <Button {...buttonProps}>{buttonProps?.children ? buttonProps.children : property.name}</Button>
    </AppLink>
  )
}
