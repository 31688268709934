import { RuleTemplate } from '@/schemas/rule-template'

export const combineRules = (data: RuleTemplate['data']) => {
  const combinedRules = data.reduce(
    (acc, rule) => {
      const { day_off, day_on } = rule
      const dayOffs = Array.from({ length: day_off }).map(() => 'day_off')
      const dayOns = Array.from({ length: day_on }).map(() => 'day_on')
      return [...acc, ...dayOns, ...dayOffs] as ('day_on' | 'day_off')[]
    },
    [] as ('day_on' | 'day_off')[]
  )

  return combinedRules
}
