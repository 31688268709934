import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { chain } from 'lodash'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import {
  afeApi,
  contactApi,
  eventApi,
  jobCodeApi,
  locationApi,
  officeApi,
  propertyApi
} from '../../../../services/api-service'
import { useFetchProfile } from '../hooks'
import {
  metafieldAfeAtom,
  metafieldCustomerContactAtom,
  metafieldJobCodeAtom,
  metafieldLocationAtom,
  metafieldOfficeAtom,
  selectedAutofillTabAtom,
  selectedMetaAfeAtom,
  selectedMetaCustomerAtom,
  selectedMetaJobCodeAtom,
  selectedMetaLocationAtom,
  selectedMetaOfficerAtom
} from './atoms'

const customFormSchema = z.object({
  id: z.number(),
  name: z.string()
})
const propertyListSchema = z.object({
  custom_form__id: z.number(),
  custom_form__name: z.string(),
  id: z.number(),
  key: z.string(),
  name: z.string(),
  property_group__name: z.string(),
  property_type__name: z.string(),
  values: z.string().nullish()
})

const customerOfficeSchema = z.object({
  id: z.number(),
  name: z.string(),
  company: z.object({
    id: z.number()
  })
})
const locationSchema = z.object({
  id: z.number(),
  name: z.string()
})
type CustomForm = z.infer<typeof customFormSchema>
export type PropertyList = z.infer<typeof propertyListSchema>
type CustomerOffice = z.infer<typeof customerOfficeSchema>
export const useCustomForms = () => {
  const { data: profileData } = useFetchProfile()
  const Q = [`companies__id__exact|${profileData?.company}`, `primary_company_id__exact|${profileData?.company}`, 'or']
  const customFormQuery = useQuery({
    ...customFormApi.list<CustomForm>({ Q, page: 1, show_all: 'True', with_disabled: true }),
    select: (data) => ({
      ...data,
      items: data.items.map((cf) => customFormSchema.parse(cf))
    }),
    enabled: !!profileData?.company
  })

  return customFormQuery
}

export const useProperty = () => {
  const { id } = useParams()
  const Q = { compact: 1, profile: id, limit: -1 }
  const propertyQuery = useQuery({
    ...propertyApi.list<PropertyList>(Q),
    enabled: !!id
  })
  return propertyQuery
}

export const useGroupedProperty = () => {
  const {
    data: { items = [], total = 0 } = {},
    isFetching,
    isSuccess,
    isFetched,
    isRefetching
  } = useProperty()
  const groupProperty = chain(items)
    .groupBy('custom_form__id')
    .map((props, key) => ({ custom_form__id: key, data: props }))
    .value()
  return { groupProperty, isFetching, isSuccess, isFetched, isRefetching }
}

export const useGroupedPropertySection = () => {
  const selectedAutofillTab = useAtomValue(selectedAutofillTabAtom)
  const { groupProperty, isFetching, isSuccess, isFetched, isRefetching } = useGroupedProperty()
  let sections: { section: string; data: PropertyList[] }[] = []
  if (groupProperty.length > 0 && selectedAutofillTab) {
    const selectedProperty = groupProperty.filter((gp) => gp.custom_form__id === selectedAutofillTab.toString())
    if (selectedProperty.length > 0) {
      const { data } = selectedProperty[0]
      const groupPropertySection = chain(data)
        .groupBy('property_group__name')
        .map((props, key) => ({ section: key, data: props }))
        .value()
      sections = groupPropertySection
    }
  }

  return { sections, isFetching, isSuccess, isFetched, isRefetching }
}

export const useLocation = () => {
  const locationText = useAtomValue(metafieldLocationAtom)
  const profile = useFetchProfile()
  const locationQuery = useQuery({
    ...locationApi.list<{ id: number; name: string }>({
      compact: 1,
      company__eq: profile.data?.company,
      limit: 20,
      name__icontains: locationText
    })
  })

  return locationQuery
}

export const useAfes = () => {
  const afeText = useAtomValue(metafieldAfeAtom)
  const afesQuery = useQuery({
    ...afeApi.list<{ id: number; user_afe_no: string }>({
      compact: 1,
      limit: 20,
      user_afe_no__icontains: afeText
    }),
    select: (data) => ({
      ...data,
      items: data.items.filter((dt) => dt.user_afe_no !== null || dt.user_afe_no !== 'N/A')
    })
  })
  return afesQuery
}

export const useContact = () => {
  const customerContact = useAtomValue(metafieldCustomerContactAtom)
  const profile = useFetchProfile()
  const afesQuery = useQuery({
    ...contactApi.list<{ id: number; first_name: string; last_name: string; company__name: string }>({
      compact: 1,
      limit: 10,
      search: customerContact,
      'exclude.company_id': profile.data?.company
    })
  })
  return afesQuery
}

export const useJobCode = () => {
  const jobCode = useAtomValue(metafieldJobCodeAtom)
  const jobCodeQuery = useQuery({
    ...jobCodeApi.list<{ id: number; user_job_code_no: string }>({
      compact: 1,
      limit: 20,
      user_job_code_no__icontains: jobCode
    })
  })
  return jobCodeQuery
}

export const useOffice = () => {
  const officeSearchText = useAtomValue(metafieldOfficeAtom)
  const profile = useFetchProfile()
  const officeQuery = useQuery({
    ...officeApi.list<{ id: number; name: string }>({
      compact: 1,
      limit: 20,
      'exclude.company_id': profile.data?.company,
      name__icontains: officeSearchText
    })
  })
  return officeQuery
}

export const useSelectedOffice = () => {
  const selectedMetaOfficer = useAtomValue(selectedMetaOfficerAtom)
  const officeQuery = useQuery({
    ...officeApi.get<CustomerOffice>(selectedMetaOfficer ?? 0),
    enabled: !!selectedMetaOfficer,
    select: (data) => ({
      ...data,
      items: customerOfficeSchema.parse(data)
    })
  })
  return officeQuery
}

export const useSelectedLocation = () => {
  const selectedMetaLocation = useAtomValue(selectedMetaLocationAtom)
  const locationQuery = useQuery({
    ...locationApi.get<{ id: number; name: string }>(selectedMetaLocation),
    enabled: !!selectedMetaLocation,
    select: (data) => data
  })
  return locationQuery
}

export const useSelectedJobCode = () => {
  const selectedMetaJobCode = useAtomValue(selectedMetaJobCodeAtom)
  const jobCodeQuery = useQuery({
    ...jobCodeApi.get<{ id: number; user_job_code_no: string }>(selectedMetaJobCode),
    enabled: !!selectedMetaJobCode,
    select: (data) => data
  })
  return jobCodeQuery
}

export const useSelectedAfe = () => {
  const selectedMetaAfe = useAtomValue(selectedMetaAfeAtom)
  const afeQuery = useQuery({
    ...afeApi.get<{ id: number; user_afe_no: string }>(selectedMetaAfe),
    enabled: !!selectedMetaAfe,
    select: (data) => data
  })
  return afeQuery
}

export const useSelectedCustomer = () => {
  const selectedMetaCustomer = useAtomValue(selectedMetaCustomerAtom)
  const contactQuery = useQuery({
    ...contactApi.get<{
      company__name: string
      company_name: string
      email: string
      first_name: string
      has_profile: boolean
      id: number
      last_name: string
      modified_at: string
      title: string
    }>(selectedMetaCustomer),
    enabled: !!selectedMetaCustomer,
    select: (data) => data
  })
  return contactQuery
}

export const useRecentActivity = () => {
  const params = useParams()
  const id = params.id

  const recentActivityQuery = useQuery({
    ...eventApi.list({
      target: id,
      type: 'profile'
    })
  })
  return recentActivityQuery
}
