import { CodeEditor } from '@/components/code-editor'
import { view } from '@/routing'
import { Form, Input, Select, Table } from 'antd'
import { useState } from 'react'
import { TableViewFormValues } from '../types'

export const TemplatesTab = view(Component, {
  title: () => 'Templates'
})

function Component() {
  const form = Form.useFormInstance<TableViewFormValues>()
  const selectedFields = (form.getFieldValue('selected_fields') || []) as TableViewFormValues['selected_fields']
  const [selectedTemplate, setSelectedTemplate] = useState('default')

  const dataSource =
    selectedFields?.map((field) => ({
      ...field,
      key: field.id
    })) || []

  return (
    <div className="grid grid-cols-2 gap-x-24">
      <div>
        <Form.Item label="Templates">
          <Select
            value={selectedTemplate}
            onChange={(val) => {
              setSelectedTemplate(val)
            }}
            options={[
              {
                label: 'Select One',
                value: 'default'
              },
              {
                label: 'Card',
                value: 'card'
              }
            ]}
          />
        </Form.Item>
        <Table dataSource={dataSource} pagination={false}>
          <Table.Column title="Columns" render={(_, field: (typeof dataSource)[0]) => <div>{field.name}</div>} />
          <Table.Column
            title="Description"
            render={(_, field: (typeof dataSource)[0]) => <div>context.object.{field.id}</div>}
          />
        </Table>
      </div>
      {selectedTemplate === 'card' && (
        <div>
          <Form.Item label="Imports" name={['config', 'templates', 'card', 'imports']}>
            <Input />
          </Form.Item>
          <Form.Item name={['config', 'templates', 'card', 'body']}>
            <CodeEditor />
          </Form.Item>
        </div>
      )}
    </div>
  )
}
