import { useViewData } from '@/layouts'
import { useModuleLink } from '@/modules/module/hooks'
import { LinkButton } from '@/ui'
import { Tag } from 'antd'
import { useAtomValue } from 'jotai'
import { patchChangesAtom } from '../atoms'
import { SaveJobTicketResources } from '../job-assign/components/SaveJobTicketAssignees'
import { useJobTicket } from '../job-assign/hooks'
import { ScheduleViewData } from '../schemas'
import { SaveOverrides } from './SaveOverrides'

export const ResourceScheduleActions: React.FC = () => {
  const {
    data: { type: resourceType, path: resourcePath }
  } = useViewData<ScheduleViewData>()
  const { getLink } = useModuleLink()
  const { jobTicket } = useJobTicket()
  const addLink = getLink('type', 'RM', `/resources/${resourcePath}/add`)

  const patchChanges = useAtomValue(patchChangesAtom)
  const changesCount = Object.keys(patchChanges).length
  const hasPatchChanges = changesCount > 0

  return (
    <div className={'flex flex-row items-center gap-10'}>
      {hasPatchChanges ? (
        <div className="flex flex-row items-center">
          <Tag color={'orange'}>{changesCount} Changes</Tag>
          <SaveOverrides key={'save'} />
        </div>
      ) : null}
      {jobTicket?.id ? (
        <SaveJobTicketResources />
      ) : (
        <LinkButton type={'primary'} className={addLink ? '' : 'hidden'} key={'add'} iconName={'fa:add'} to={addLink}>
          Add {resourceType}
        </LinkButton>
      )}
    </div>
  )
}
