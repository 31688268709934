import { DragOverEvent, useDndMonitor } from '@dnd-kit/core'
import cn from 'classnames'
import { FC, useState } from 'react'
import { CurrentKind } from '../../../../hooks'
import { Category, ItemColumnType, LineItem } from '../../../../schemas'
import { ItemRow } from '../item-row'
import { TableFoot } from '../table-foot'
import styles from './styles.module.scss'

type Props = {
  columns: ItemColumnType[]
  groupItems: Partial<LineItem>[]
  props: {
    category: Category
    kind: CurrentKind
    groupNo: number | null
    groupItems: Partial<LineItem>[]
    noCategoryFilter?: boolean
  }
}

export const CustomTable: FC<Props> = ({ groupItems, columns, props }) => {
  const { groupNo, kind } = props || {}
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [overGroup, setOverGroup] = useState<number | null>(null)

  useDndMonitor({
    onDragStart(event) {
      setIsDraggingOver(true)
    },
    onDragOver(event: DragOverEvent) {
      const newOverGroup = groupItems.find((i) => i.id === event.over?.id)?.group ?? null
      if (newOverGroup !== overGroup) setOverGroup(newOverGroup)
    },
    onDragEnd(event) {
      setIsDraggingOver(false)
    }
  })

  return (
    <div
      className={cn('mb-10 rounded border border-secondary', {
        'overflow-hidden': !isDraggingOver,
        'outline-dashed outline-offset-2 outline-primary': isDraggingOver && overGroup === groupNo
      })}
    >
      <div className={cn('w-full', isDraggingOver ? 'overflow-hidden' : 'overflow-auto')}>
        <table className={styles.customTable}>
          <thead className={'bg-background-accent'}>
            <tr>
              <th className={'opacity-0'}></th>
              {columns.map((c) => (
                <th key={c.key} scope="col" className={cn(c.className)}>
                  {c.title as any}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {groupItems.map((item) => (
              <ItemRow key={item.id} item={item} columns={columns} kind={kind as CurrentKind} />
            ))}
          </tbody>
        </table>
      </div>
      <div className={'border-t border-secondary bg-background-accent p-6'}>
        <TableFoot {...props} />
      </div>
    </div>
  )
}
