import { useApp } from '@/hooks'
import { importExportFileApi } from '@/services/api-service'
import { Button, Popconfirm } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

export const UndoImportSection = () => {
  const rollbackImportMutation = useMutation(importExportFileApi.create({}, 'rollback'))
  const { notification } = useApp()

  const handleRollbackImport = async () => {
    try {
      await rollbackImportMutation.mutateAsync({})

      notification.success({
        message: 'Successfully reverted latest import'
      })
    } catch (error) {
      if (isAxiosError(error)) {
        notification.error({
          message: error.response?.data?.[0]
        })
      }
    }
  }

  return (
    <div className="my-40">
      <h5 className="font-bold mb-20">Undo Import</h5>
      <div className="flex">
        <Popconfirm
          title="Revert Latest Change"
          description="Are you sure you want to revert the latest change?"
          okText="Yes"
          onConfirm={handleRollbackImport}
        >
          <Button iconName="fa-solid:backward" type="primary">
            Revert Latest Changes
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}
