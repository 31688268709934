import { Checkbox, Form, Modal } from 'antd'
import { useAtom } from 'jotai'
import { columnsAtom } from '../atoms'

export const IncludeLineItemCheckbox = () => {
  const [columns, setColumns] = useAtom(columnsAtom)
  const form = Form.useFormInstance()
  const isLineItemReport = Form.useWatch('is_line_item_report', form) || false
  const filterfieldSet = Form.useWatch('filterfield_set', form) || []
  const [modal, contextHolder] = Modal.useModal()

  const handleOk = () => {
    form.setFieldsValue({ is_line_item_report: !isLineItemReport, filterfield_set: [], displayfield_set: [] })
    setColumns((columns) => ({ ...columns, selected: [] }))
  }

  return (
    <>
      {contextHolder}
      <Form.Item
        name="is_line_item_report"
        valuePropName="checked"
        getValueFromEvent={(e) => {
          if (columns.selected.length > 0 || filterfieldSet.length > 0) {
            modal.confirm({
              title: 'Change View Type',
              content:
                'Changing the report type will remove the columns and criteria from the report. Do you wish to continue?',
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => {
                handleOk()
              }
            })

            return isLineItemReport
          }
          return e.target.checked
        }}
      >
        <Checkbox checked={isLineItemReport}>Include Line Items</Checkbox>
      </Form.Item>
      {isLineItemReport && (
        <Form.Item name={['options', 'include_sub_components']} valuePropName="checked">
          <Checkbox>Include Line Items as Sub-components</Checkbox>
        </Form.Item>
      )}
    </>
  )
}
