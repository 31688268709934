import { Spin } from 'antd'

type Props = {
  children?: React.ReactNode
  footer?: React.ReactNode
  isLoading?: boolean
}

/**
 * @deprecated use Page component with sticky={ footer: true } prop
 * @description Page wrapper with sticky footer.
 */
export const PageWithStickyFooter = ({ children, footer, isLoading }: Props) => {
  return (
    <div className="flex flex-col absolute top-0 left-0 h-full w-full">
      <div className="h-full overflow-auto">
        <div className="p-8">{isLoading ? <Spin spinning /> : children}</div>
      </div>
      {!isLoading && (
        <div className="h-[60px] min-h-[60px] p-8 w-full bg-white border-t border-t-border bottom-0 left-0">
          <div className="flex h-full items-center">{footer}</div>
        </div>
      )}
    </div>
  )
}
