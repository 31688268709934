export const inventoryOptions = [
  {
    label: 'Enable Inventory Tracking',
    key: 'modify_respective_inventory',
    hidable: false,
    info: 'Line items on these custom form will modify their respective inventory'
  },
  {
    label: 'Use for Purchase Order',
    key: 'purchase_order',
    hidable: true,
    info: 'Line items on this custom form will add to their respective inventory, rather than take from their inventory'
  },
  {
    label: 'Use Ticket Date for Inventory',
    key: 'ticket_date_for_inventory',
    hidable: true,
    // eslint-disable-next-line quotes
    info: "Use ticket date rather than line item's created date for inventory"
  }
]

export const INVETORY_TRACKING_ENABLED_KEY = 'modify_respective_inventory'

export const options = [
  {
    label: 'Enable accounting integration',
    key: 'enable_accounting'
  },
  {
    label: 'Submit bill for accounting instead of invoice',
    key: 'submit_bill'
  }
]
