import { CodeEditor } from '@/components/code-editor'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../atoms'

export const WebAdvanced = () => {
  const customWebCss = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.custom_web_css), []))
  const setCustomForm = useSetAtom(setCustomFormAtom)

  return (
    <div className="flex-1">
      <h3 className="font-bold">Web - Advanced</h3>
      <div className="font-semibold">Custom CSS</div>
      <CodeEditor
        mode="css"
        height="400px"
        value={customWebCss ?? ''}
        onChange={(value) => {
          setCustomForm({
            custom_web_css: value
          })
        }}
      />
    </div>
  )
}
