import dayjs, { Dayjs } from 'dayjs'

export const getDateRange = (): {
  label: string
  value: [Dayjs, Dayjs]
}[] => {
  const today = dayjs()
  const todayEod = dayjs().endOf('day')
  const lastMonth = today.subtract(1, 'month')
  return [
    { label: 'Last 7 Days', value: [today.subtract(7, 'days').startOf('day'), todayEod] },
    { label: 'Last 30 Days', value: [today.subtract(30, 'days').startOf('day'), todayEod] },
    { label: 'Last 90 Days', value: [today.subtract(90, 'days').startOf('day'), todayEod] },
    { label: 'Today', value: [today.startOf('day'), todayEod] },
    { label: 'This Week', value: [today.startOf('week'), today.endOf('week')] },
    { label: 'This Month', value: [today.startOf('month'), today.endOf('month')] },
    { label: 'Last Month', value: [lastMonth.startOf('month'), lastMonth.endOf('month')] }
  ]
}
