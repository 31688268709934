import {
  DndContext,
  DndContextProps,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  SortableContextProps,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { FC, ReactElement } from 'react'
import { UseSortable } from './types'

type ItemRenderFn = (item: any, index: number, sortable: UseSortable) => ReactElement<any, any> | null

export const SortableItem: FC<{
  id: string | number
  item: any
  index: number
  render: ItemRenderFn
}> = ({ id, item, index, render }) => {
  const sortable: UseSortable = useSortable({ id: id })

  // prevent scaling on drag
  const transform = sortable.transform ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)` : ''

  sortable.style = {
    transform: transform,
    transition: sortable.transition || '',
    zIndex: sortable.isDragging ? 100 : 0
  }

  return render(item, index, sortable)
}

type Props = DndContextProps & {
  items: any[]
  by?: string | number
  render?: ItemRenderFn
  children?: ReactElement
  onChange?: (items: any[], event?: DragEndEvent) => void
  sortableContextProps?: SortableContextProps
}

export const Sortable: FC<Props> = ({
  items,
  by,
  onChange,
  children,
  render,
  sortableContextProps,
  ...dndContextProps
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((i) => (by === undefined ? i : i[by]) === active.id)
      const newIndex = items.findIndex((i) => (by === undefined ? i : i[by]) === over.id)
      const changedItems = arrayMove(items, oldIndex, newIndex)
      onChange?.(changedItems, event)
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} {...dndContextProps}>
      <SortableContext
        items={items.map((r) => (by === undefined ? r : r[by]))}
        strategy={verticalListSortingStrategy}
        {...sortableContextProps}
      >
        {children ||
          (render
            ? items.map((i, idx) => (
                <SortableItem
                  key={by === undefined ? i : i[by]}
                  id={by === undefined ? i : i[by]}
                  item={i}
                  index={idx}
                  render={render}
                />
              ))
            : null)}
      </SortableContext>
    </DndContext>
  )
}

export const SortableInput: FC<Omit<Props, 'items'> & { value?: any[] }> = ({ value, ...props }) => {
  return <Sortable items={value || []} {...props} />
}
