import { Collapse as AntCollapse, CollapseProps } from 'antd'
import cn from 'classnames'
import { FC } from 'react'
import { Button } from '../button'
import { Icon } from '../icons'
import styles from './Collapse.module.scss'

type Props = CollapseProps & {
  expandIconClassName?: string
}

export const Collapse: FC<Props> = ({ items, expandIconClassName, ...restProps }) => {
  return (
    <AntCollapse
      expandIconPosition="end"
      rootClassName={styles.collapseWrapper}
      items={items}
      expandIcon={({ isActive }) => (
        <Button
          type="text"
          icon={
            <Icon
              name="fa:angle-right"
              className={cn(expandIconClassName, 'scale-110 transition-transform', isActive && 'rotate-90')}
            />
          }
        />
      )}
      {...restProps}
    />
  )
}
