import { layout, redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { AfeListView } from './afes'
import { CategoryKindFormView, CategoryKindListView } from './category-kinds'

import { CategoryFormView, CategoryListView } from './categories'
import { CustomRecordLayout, CustomRecordListView } from './custom-records'

import { JobCodeListView } from './job-codes'
import { JobTypeListView } from './job-types'
import { RecordsLayout } from './layout'
import { LocationFormView, LocationListView } from './locations'
import { PricingListView } from './pricing'
import { SkuFormView, SkuListView } from './skus'

export const routes: RouteObject[] = [
  route('records', RecordsLayout, [
    route('index', redirect('locations')),
    route('locations', layout('LocationLayout', 'Locations'), [
      route('index', LocationListView),
      route('add', LocationFormView),
      route(':id/edit', LocationFormView)
    ]),
    route('skus', layout('SkuLayout', 'SKUs'), [
      route('index', SkuListView),
      route('add', SkuFormView),
      route(':id/edit', SkuFormView)
    ]),
    route('categories', layout('CategoryLayout', 'Categories'), [
      route('index', CategoryListView),
      route('add', CategoryFormView),
      route(':id/edit', CategoryFormView)
    ]),
    route('category_kinds', layout('CategoryKindLayout', 'Configurations'), [
      route('index', CategoryKindListView),
      route('add', CategoryKindFormView),
      route(':id/edit', CategoryKindFormView)
    ]),
    route('afes', AfeListView),
    route('job_codes', JobCodeListView),
    route('job_types', JobTypeListView),
    route('custom_records', CustomRecordLayout, [
      route('index', redirect('All')),
      route(':label', CustomRecordListView)
    ]),
    route('pricing', PricingListView)
  ])
]
