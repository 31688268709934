import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { QueryEditButton } from '@/ui'
import { FC } from 'react'

type Props = {
  formId: number
  disabled?: boolean
}

export const LayoutLink: FC<Props> = ({ formId, disabled }) => {
  return (
    <QueryEditButton
      iconName="fa:grid-2-plus"
      to={SETTINGS_PATHS.customFormLayout(formId)}
      data-cy="goto-custom-form-layout"
      disabled={disabled}
    />
  )
}
