import { SectionTitle } from '@/ui/section-title'
import { TableSelectField } from '../components/'

export const StepThree = () => {
  return (
    <div>
      <SectionTitle rounded number={3}>
        Import New Dataset
      </SectionTitle>
      <div className="my-16">
        Update the data set using excel and save file. Be sure to preserve the first column of excel document.
      </div>
      <TableSelectField type="I" />
    </div>
  )
}
