import { CodeEditor } from '@/components/code-editor'
import { useSession } from '@/hooks'
import { equipmentApi, inventoryAlertApi } from '@/services/api-service'
import { InventoryAlert } from '@/types/inventory-alert'
import { Modal, Select } from '@/ui'
import { getCronFrequency } from '@/utils/get-cron-frequency'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Space, Switch } from 'antd'
import { useMemo, useState } from 'react'
import { SetReportFrequencySection } from './set-report-frequency-section'

type Props = {
  inventoryAlert?: InventoryAlert
  onCancel: () => void
}

export const AddEditInventoryModal = ({ inventoryAlert, onCancel }: Props) => {
  const [form] = Form.useForm()
  const cronSpec = Form.useWatch('cron_spec', form) as string | undefined
  const { company, user } = useSession()
  const [skuSearch, setSkuSearch] = useState('')
  const createInventoryAlertMutation = useMutation(inventoryAlertApi.create())
  const updateInventoryAlertMutation = useMutation(inventoryAlertApi.update())

  const handleSave = async () => {
    const { id, equipment, ...values } = await form.validateFields()

    // checking for string because initial value of equipment is sku of equipment in a Select
    const equipmentId = typeof equipment === 'string' ? inventoryAlert?.equipment?.id : equipment

    const payload = {
      ...values,
      equipment: equipmentId,
      company: company.id,
      run_as: user?.id
    }

    if (id) {
      await updateInventoryAlertMutation.mutateAsync({ id, ...payload })
    } else {
      await createInventoryAlertMutation.mutateAsync(payload)
    }

    onCancel()
  }

  const equipmentsQuery = useQuery(
    equipmentApi.list({
      compact: 1,
      company_id__exact: company.id,
      inventory__exact: 1,
      ...(skuSearch ? { search: skuSearch, limit: 'None' } : {})
    })
  )

  const cronFrequency: 'Monthly' | 'Weekly' | 'Daily' | null = useMemo(() => {
    if (!cronSpec) {
      return null
    }

    const frequency = getCronFrequency(cronSpec)

    if (frequency === 'day') {
      return 'Daily'
    }

    if (frequency === 'week') {
      return 'Weekly'
    }

    return 'Monthly'
  }, [cronSpec])

  return (
    <Modal
      open
      onCancel={onCancel}
      title={inventoryAlert ? 'Edit Inventory Alert' : 'Add Inventory Alert'}
      withScreenMaxHeight
      width={700}
      okText="Save"
      onOk={handleSave}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          inventoryAlert
            ? {
                ...inventoryAlert,
                equipment: inventoryAlert.equipment ? inventoryAlert.equipment.sku : undefined
              }
            : {
                cron_spec: '0 0 * * *',
                company: {
                  id: company?.id,
                  name: company?.name
                },
                run_as: {
                  id: user?.id,
                  username: user?.username
                },
                timezone: 'America/Chicago'
              }
        }
      >
        <Form.Item name="id" hidden />
        <Form.Item name="cron_spec" hidden />
        <Form.Item name="company" hidden />
        <Form.Item name="run_as" hidden />
        <Form.Item>
          <Space>
            <Form.Item noStyle name="enabled" valuePropName="checked">
              <Switch />
            </Form.Item>
            <div>Enabled</div>
          </Space>
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-16">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </div>
        <div className="grid grid-cols-3 gap-x-16">
          <Form.Item label="Company" name={['company', 'name']}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Run As" name={['run_as', 'username']}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="Timezone" name="timezone">
            <Select disabled />
          </Form.Item>
        </div>
        <div className="mb-16 border-y border-border py-16">
          <SetReportFrequencySection />
          {cronSpec && (
            <div className="mt-8 font-semibold italic">
              Repeats {cronFrequency}, {humanizeCronExpr(cronSpec)}
            </div>
          )}
        </div>
        <Form.Item label="Contact Emails (one email per line)" name="contacts">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="SKU" name="equipment">
          <Select
            onSearch={setSkuSearch}
            filterOption={false}
            options={equipmentsQuery.data?.items.map((equipment) => ({
              label: equipment.sku,
              value: equipment.id
            }))}
          />
        </Form.Item>
        <Form.Item label="Alert Script" name="alert_script">
          <CodeEditor mode="javascript" />
        </Form.Item>
        <Form.Item label="Alert Message" name="message">
          <CodeEditor mode="html" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
