import { CodeEditor } from '@/components/code-editor'
import { Select } from '@/ui'
import { Form, Input } from 'antd'
import React, { FC, useMemo } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaActionSyncHttp } from '../../schemas'

type Props = {
  action: BpaActionSyncHttp
  onChange: (data: Partial<BpaActionSyncHttp>) => void
  hideResponse?: boolean
}

const HTTP_METHOD_OPTIONS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map((method) => ({
  value: method,
  label: method
}))

export const InputSyncHttp: FC<Props> = ({ action, onChange, hideResponse }) => {
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  const hideContent = useMemo(() => ['GET', 'DELETE'].includes(action.data.method), [action.data.method])

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10">
        <Form.Item label="Method" className="w-2/12">
          <Select
            defaultValue={action.data.method}
            options={HTTP_METHOD_OPTIONS}
            onChange={(_, option: any) => {
              onChange({ data: { ...action.data, method: option.value } })
            }}
          />
        </Form.Item>
        <Form.Item label="URL (Text)" tooltip="Supports placeholder like P[API_URL]" className="flex-grow">
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 10 }}
            defaultValue={action.data.url || ''}
            onChange={(e) => onChangeDebounced({ data: { ...action.data, url: e.target.value } })}
          />
        </Form.Item>
      </div>
      <Form.Item label="Headers">
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={action.data.headers || ''}
          onChange={(value) => onChangeDebounced({ data: { ...action.data, headers: value } })}
          mode="yaml"
        />
      </Form.Item>
      {!hideContent && (
        <Form.Item label="Content">
          <CodeEditor
            minLines={5}
            maxLines={25}
            defaultValue={action.data.content || ''}
            onChange={(value) => onChangeDebounced({ data: { ...action.data, content: value } })}
            mode="json"
          />
        </Form.Item>
      )}
      {!hideResponse && (
        <div className="flex flex-row gap-10">
          <Form.Item label="Response Type" className="w-3/12">
            <Select
              defaultValue={action.data.resp_type || ''}
              options={[
                { value: 'str', label: 'TEXT (STR)' },
                { value: 'json', label: 'JSON' }
              ]}
              onChange={(value) => onChangeDebounced({ data: { ...action.data, resp_type: value } })}
            />
          </Form.Item>
          <Form.Item
            label="Response Variable Name"
            tooltip={'Sets response data to the specified variable name'}
            className="w-9/12"
          >
            <Input
              defaultValue={action.data.resp_var || ''}
              onChange={(e) => onChangeDebounced({ data: { ...action.data, resp_var: e.target.value } })}
            />
          </Form.Item>
        </div>
      )}
    </div>
  )
}
export const InputSyncHttpMemo = React.memo(InputSyncHttp)
