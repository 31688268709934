import { useSession } from '@/hooks'
import { secureAccessWaitListApi } from '@/services/api-service'
import { ActionButton, AppLink, Button } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { Divider, List } from 'antd'
import { useState } from 'react'
import { SECURE_ACCESS_TYPE, SecureAccess, SecureAccessWaitList, SecureAccessWaitListSchema } from './schemas'

const DEFAULT_LIST_LIMIT = 2

type SecureWaitListProps = {
  secureAccess: SecureAccess
  visible: boolean
  setVisible: (val: boolean) => void
}

export const SecureAccessWaitListView = ({ secureAccess, visible, setVisible }: SecureWaitListProps) => {
  const { user } = useSession()
  const [limit, setLimit] = useState<number | undefined>(DEFAULT_LIST_LIMIT)

  const secureAccessList = useQuery(
    secureAccessWaitListApi.list<SecureAccessWaitList>({
      fields: SecureAccessWaitListSchema,
      limit: limit,
      'Q[]': `type__exact|${SECURE_ACCESS_TYPE.SIGNATURE}`,
      'E[]': `id__exact|${secureAccess.id}`
    })
  )

  const total = secureAccessList.data?.total || 0
  const itemsList = secureAccessList.data?.items || []

  if (!total || !visible) {
    return <span />
  }

  return (
    <div className="border border-primary-500 rounded bg-primary-50 mb-12 p-8 py-0">
      <div className="flex justify-between items-center w-full">
        <div>
          {user?.first_name}, <span className="text-primary">{total}</span> other tickets needing your signature.
        </div>
        <Button iconName="fa:close" type="text" onClick={() => setVisible(false)} />
      </div>
      <Divider className="my-0" />
      <List
        className="max-h-[400px] overflow-auto"
        dataSource={itemsList}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            className="hover:bg-primary-100 py-8"
            actions={[
              <AppLink to={item.signed_url} reloadDocument key={item.signed_url}>
                <ActionButton size="small" primary iconName="fa:chevron-right" shape="circle" type="default" />
              </AppLink>
            ]}
          >
            <div className="flex flex-col text-left pl-8">
              <p className="mb-4 font-bold">
                {item.ticket__custom_form__name} #{item.ticket__name}
              </p>
              <p className="mb-4">Location: {item.ticket__location__name}</p>
            </div>
          </List.Item>
        )}
      ></List>
      <Divider className="my-0" />
      <div className="flex justify-between items-center w-full my-2">
        <small>
          Showing <span className="text-primary">{itemsList.length}</span> out of{' '}
          <span className="text-primary">{total}</span> remaining tickets
        </small>
        {limit ? (
          <Button
            size="small"
            primary
            type="text"
            onClick={() => setLimit(undefined)}
            loading={secureAccessList.isLoading}
          >
            Show All
          </Button>
        ) : (
          <Button
            size="small"
            primary
            type="text"
            onClick={() => setLimit(DEFAULT_LIST_LIMIT)}
            loading={secureAccessList.isLoading}
          >
            Show Less
          </Button>
        )}
      </div>
    </div>
  )
}
