import { ACCOUNT_SETTINGS_PATHS } from '../../../constants'

export const getEditTicketViewTabs = (id: string | number) => [
  {
    to: ACCOUNT_SETTINGS_PATHS.configureTicketView(id),
    label: 'Configure'
  },
  {
    to: ACCOUNT_SETTINGS_PATHS.ticketViewTemplates(id),
    label: 'Templates'
  },
  {
    to: ACCOUNT_SETTINGS_PATHS.editTicketViewAdvanced(id),
    label: 'Advanced'
  }
]
