import { Button, Icon, Modal } from '@/ui'
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps'
import { Input, Space } from 'antd'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { getPinObj } from '../helpers'

type Props = {
  value?: string
  onChange?: (value?: string) => void
  disabled?: boolean
}

const DEFAULT_LOCATION = { lat: 40.7860819, lng: -111.9975198 }

export const PinInput: FC<Props> = ({ value, onChange, disabled }) => {
  const valuePin = useMemo(() => getPinObj(value || ''), [value])

  const [open, setOpen] = useState(false)
  const [newValue, setNewValue] = useState<string | undefined>(value)
  const newValuePin = useMemo(() => getPinObj(newValue || ''), [newValue])

  const handleSubmit = useCallback(() => {
    if (newValue !== value) onChange?.(newValue)
    setOpen(false)
  }, [newValue, onChange, value])

  return (
    <>
      <Input
        readOnly
        value={valuePin.display}
        onClick={() => setOpen(true)}
        disabled={disabled}
        suffix={
          <Space>
            <span onClick={() => setOpen(true)} className={'flex flex-row items-center justify-center'}>
              <Icon className={'text-sm'} name={'fa:map-location'} />
            </span>
            <span
              onClick={() => {
                onChange?.('')
              }}
              className={'flex flex-row items-center justify-center'}
            >
              <Icon className={'text-sm'} name={'fa:trash'} />
            </span>
          </Space>
        }
      />

      <Modal
        styles={{ body: { padding: 0 } }}
        withScreenMaxHeight
        destroyOnClose
        width={'80%'}
        open={open}
        iconName={'fa:map'}
        title="Location Picker"
        onCancel={() => setOpen(false)}
        footer={
          <div className="flex items-center">
            <div>
              <span className="font-semibold">Latitute: </span>
              <span>{newValuePin.lat}</span>
              <span className="font-semibold ml-8">Longitude: </span>
              <span>{newValuePin?.lng}</span>
            </div>
            <div className="flex ml-auto items-center gap-10">
              <Button onClick={() => setOpen(false)}>Close</Button>
              <Button type="primary" onClick={handleSubmit}>
                Select
              </Button>
            </div>
          </div>
        }
      >
        <div className={'h-[calc(100vh-400px)] w-full rounded overflow-hidden'}>
          <MapViewPicker value={value} onChange={setNewValue} />
        </div>
      </Modal>
    </>
  )
}

export const MapViewPicker: FC<Props> = ({ value, onChange }) => {
  const pinObj = getPinObj(value || '')

  const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>(
    pinObj.lat && pinObj.lng ? { lat: pinObj.lat, lng: pinObj.lng } : DEFAULT_LOCATION
  )

  // set new value on first render
  useEffect(() => {
    onChange?.([dayjs.now().formatISO(), location?.lat, location?.lng].join(','))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(value) || !navigator.geolocation) return

    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords
      setLocation({ lat: latitude, lng: longitude })
    })
  }, [value])

  const handleMapClick = (event: any) => {
    const latLng = event.detail.latLng
    setLocation(latLng)
    onChange?.([dayjs.now().formatISO(), latLng.lat, latLng.lng].join(','))
  }

  const handleMarkerDragEnd = (event: any) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setLocation({ lat, lng })
    onChange?.([dayjs.now().formatISO(), lat, lng].join(','))
  }

  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
      <Map
        defaultCenter={location}
        defaultZoom={18}
        onClick={handleMapClick}
        mapId={import.meta.env.VITE_MAP_ID_PICKER}
      >
        <AdvancedMarker position={location} draggable={true} onDragEnd={handleMarkerDragEnd} />
      </Map>
    </APIProvider>
  )
}
