import { useApp, useSession } from '@/hooks'
import { useCustomForm } from '@/modules/ticket/form/hooks'
import { executeMutation, fetchQuery } from '@/query'
import { apiHttp } from '@/services/api-service/core'
import * as endpoints from '@/services/api-service/endpoints'
import * as utils from '@/utils'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import { useButtonContext } from '../../hooks'

const safeEvalFunction = (script: string) => {
  if (isEmpty(script)) return {}

  try {
    return new Function(script)() || {}
  } catch (e) {
    console.error('Custom Script Button:', 'Props Script Error:', e)
    return {}
  }
}

export const useCustomScriptButtonProps = () => {
  const app = useApp()
  const session = useSession()
  const { customForm, getPropertyIdByKey, getPropertyByKey, getPropertyById } = useCustomForm()
  const {
    vars,
    ticket,
    property,
    isSaving,
    setValue,
    getValue,
    getValueById,
    getValueByKey,
    saveTicket,
    buttonProps: defaultButtonProps
  } = useButtonContext()

  const [overrideButtonProps, setOverrideButtonProps] = useState({})

  const scriptContext = useMemo(
    () => ({
      // Default
      property,

      // State
      isSaving,
      buttonProps: overrideButtonProps,
      setButtonProps: (props: any) => setOverrideButtonProps({ ...overrideButtonProps, ...props }),

      // Global
      app,
      session,

      // API
      ...endpoints,
      http: apiHttp,
      query: fetchQuery,
      mutate: executeMutation,

      // Custom Form
      customForm, // shorthand
      pid: getPropertyIdByKey, // shorthand
      getPropertyById,
      getPropertyByKey,
      getPropertyIdByKey,

      // Ticket
      ticket,
      setValue,
      get: getValue, // shorthand and alias
      getValue,
      getValueById,
      getValueByKey,
      saveTicket,

      // utils
      dayjs,
      utils
    }),
    [
      app,
      customForm,
      getPropertyById,
      getPropertyByKey,
      getPropertyIdByKey,
      getValue,
      getValueById,
      getValueByKey,
      isSaving,
      overrideButtonProps,
      property,
      saveTicket,
      session,
      setValue,
      ticket
    ]
  )

  const compiledPropsFn = useMemo(() => safeEvalFunction(vars.propsScript), [vars.propsScript])

  const compiledButtonProps = useMemo(() => {
    const dynamicButtonProps = Object.keys(compiledPropsFn).reduce((acc: any, key) => {
      try {
        const handler = compiledPropsFn[key]
        if (typeof handler === 'function') acc[key] = handler(scriptContext)
      } catch (e) {
        console.error('Custom Script Button:', 'Handler Error:', key, e)
      }
      return acc
    }, {})

    return {
      children: property.name,
      ...defaultButtonProps,
      ...dynamicButtonProps,
      ...overrideButtonProps
    }
  }, [compiledPropsFn, defaultButtonProps, overrideButtonProps, property.name, scriptContext])

  return { vars, property, scriptContext, buttonProps: compiledButtonProps }
}
