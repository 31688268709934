import { TQueryField } from '@/ui/data/query-builder'

// biome-ignore format: <explanation>
const EXTRACT_TICKET_FIELDS_CSV = [
  ['id',                         'ID',                  'ticket__id__label',                   'integer'],
  ['uuid',                       'UUID',                'ticket__uuid__label',                 'string'],
  ['custom_form_id',             'Custom Form ID',      'ticket__custom_form_id__label',       'integer'],
  ['custom_form__name',          'Custom Form',         'ticket__custom_form__name__label',    'string'],
  ['name',                       'Name',                'ticket__name__label',                 'integer'],
  ['parent__name',               'Parent Ticket Name',  'ticket__parent__name__label',         'integer'],
  ['computed_status',            'Status',              'ticket__computed_status__label',      'string'],
  ['customer_office_id',         'Customer Office ID',  'ticket__customer_office_id__label',   'integer'],
  ['customer_office__name',      'Customer Office',     'ticket__office__label',               'string'],
  ['customer_id',                'Customer ID',         'ticket__customer_id__label',          'integer'],
  ['customer__first_name',       'Customer First Name', 'ticket__customer__first_name__label', 'string'],
  ['customer__last_name',        'Customer Last Name', 'ticket__customer__first_name__label',  'string'],
  ['location_id',                'Location ID',         'ticket__location_id__label',          'integer'],
  ['location__name',             'Location Name',       'ticket__location__label',             'string'],
  ['job_code_id',                'Job Code ID',         'ticket__job_code_id__label',          'integer'],
  ['job_code__user_job_code_no', 'Job Code Name',       'ticket__job_code__label',             'string'],
  ['afe_id',                     'AFE ID',              'ticket__job_code_id__label',          'integer'],
  ['afe__user_afe_no',           'AFE Name',            'ticket__user_afe_no__label',          'string'],
  ['support_office_id',          'Support Office ID',   'ticket__support_office_id__label',    'integer'],
  ['support_office__name',       'Support Office Name', 'ticket__support_office__label',       'string'],
  ['timepoint_due',              'Start Date',          'ticket__timepoint_due__label',        'datetime'],
  ['timepoint_submitted',        'Date Submitted',      'ticket__timepoint_submitted__label',  'datetime'],
  ['modified_at',                'Modified At',         'ticket__modified_at__label',          'datetime'],
  ['created_at',                 'Created At',          'ticket__created_at__label',           'datetime'],
  ['total',                      'Total',               'ticket__total__label',                'decimal'],

  ['user_data__user_text_1',  'User Text 1',  'ticket__user_data__user_text_1__label',  'string'],
  ['user_data__user_text_2',  'User Text 2',  'ticket__user_data__user_text_2__label',  'string'],
  ['user_data__user_text_3',  'User Text 3',  'ticket__user_data__user_text_3__label',  'string'],
  ['user_data__user_text_4',  'User Text 4',  'ticket__user_data__user_text_4__label',  'string'],

  ['user_data__user_int_1',  'User Integer 1',  'ticket__user_data__user_int_1__label',  'integer'],
  ['user_data__user_int_2',  'User Integer 2',  'ticket__user_data__user_int_2__label',  'integer'],
  ['user_data__user_int_3',  'User Integer 3',  'ticket__user_data__user_int_3__label',  'integer'],
  ['user_data__user_int_4',  'User Integer 4',  'ticket__user_data__user_int_4__label',  'integer'],

  ['user_data__user_decimal_1',  'User Decimal 1',  'ticket__user_data__user_decimal_1__label',  'decimal'],
  ['user_data__user_decimal_2',  'User Decimal 2',  'ticket__user_data__user_decimal_2__label',  'decimal'],
  ['user_data__user_decimal_3',  'User Decimal 3',  'ticket__user_data__user_decimal_3__label',  'decimal'],
  ['user_data__user_decimal_4',  'User Decimal 4',  'ticket__user_data__user_decimal_4__label',  'decimal'],

  ['user_data__user_datetime_1',  'User Date 1',  'ticket__user_data__user_datetime_1__label',  'datetime'],
  ['user_data__user_datetime_2',  'User Date 2',  'ticket__user_data__user_datetime_2__label',  'datetime'],
  ['user_data__user_datetime_3',  'User Date 3',  'ticket__user_data__user_datetime_3__label',  'datetime'],
  ['user_data__user_datetime_4',  'User Date 4',  'ticket__user_data__user_datetime_4__label',  'datetime'],
]

// biome-ignore format: <explanation>
const EXTRACT_LINE_ITEM_FIELDS_CSV = [
  ['uuid',                            'UUID',                 'lineitem__uuid',                            'string'],
  ['sku',                             'SKU',                  'lineitem__sku',                             'string'],
  ['description',                     'Description',          'lineitem__description',                     'string'],
  ['category_id',                     'Category ID',          'lineitem__category',                        'integer'],
  ['category__name',                  'Category Name',        'lineitem__category__name',                  'string'],
  ['category__parent_category__id',   'Parent Category ID',   'lineitem__category__parent_category__id',   'integer'],
  ['category__parent_category__name', 'Parent Category Name', 'lineitem__category__parent_category__name', 'string'],
  ['equipment_id',                    'Equipment ID',         'lineitem__equipment',                       'integer'],
  ['usage_rate',                      'Usage Rate',           'lineitem__usage_rate',                      'decimal'],
  ['standby_rate',                    'Standby Rate',         'lineitem__standby_rate',                    'decimal'],
  ['tax_rate',                        'Tax Rate',             'lineitem__tax_rate',                        'decimal'],
  ['non_taxable',                     'Non-Taxable',          'lineitem__non_taxable',                     'decimal'],
  ['hands',                           'Hands',                'lineitem__hands',                           'decimal'],
  ['units_used',                      'Units Used',           'lineitem__units_used',                      'decimal'],
  ['unit',                            'Unit',                 'lineitem__unit',                            'string'],
  ['units_standby',                   'Units Standby',        'lineitem__units_standby',                   'decimal'],
  ['units_min',                       'Units Min',            'lineitem__units_min',                       'decimal'],
  ['minimum',                         'Minimum',              'lineitem__minimum',                         'decimal'],
  ['discount',                        'Discount',             'lineitem__discount',                        'decimal'],
  ['discountable_amount',             'Discountable Amount',  'lineitem__discountable_amount',             'decimal'],
  ['taxable_amount',                  'Taxable Amount',       'lineitem__taxable_amount',                  'decimal'],
  ['barrels',                         'Barrels',              'lineitem__barrels',                         'decimal'],
  ['notes',                           'Notes',                'lineitem__notes',                           'string'],
  ['amount',                          'Amount',               'lineitem__amount',                          'decimal'],
  ['cost',                            'Cost',                 'lineitem__cost',                            'decimal'],
  ['start_datetime',                  'Start Datetime',       'lineitem__start_datetime',                  'datetime'],
  ['end_datetime',                    'End Datetime',         'lineitem__end_datetime',                    'datetime'],
  ['user_decimal_1',                  'User Decimal 1',       'lineitem__user_decimal_1',                  'decimal'],
  ['user_decimal_2',                  'User Decimal 2',       'lineitem__user_decimal_2',                  'decimal'],
  ['user_decimal_3',                  'User Decimal 3',       'lineitem__user_decimal_3',                  'decimal'],
  ['user_integer_1',                  'User Integer 1',       'lineitem__user_integer_1',                  'integer'],
  ['user_integer_2',                  'User Integer 2',       'lineitem__user_integer_2',                  'integer'],
  ['user_integer_3',                  'User Integer 3',       'lineitem__user_integer_3',                  'integer'],
  ['user_integer_4',                  'User Integer 4',       'lineitem__user_integer_4',                  'integer'],
  ['user_text_1',                     'User Text 1',          'lineitem__user_text_1',                     'string'],
  ['user_text_2',                     'User Text 2',          'lineitem__user_text_2',                     'string'],
  ['user_text_3',                     'User Text 3',          'lineitem__user_text_3',                     'string'],
  ['user_text_4',                     'User Text 4',          'lineitem__user_text_4',                     'string'],
  ['user_text_5',                     'User Text 5',          'lineitem__user_text_5',                     'string'],
  ['user_text_6',                     'User Text 6',          'lineitem__user_text_6',                     'string'],
  ['user_text_7',                     'User Text 7',          'lineitem__user_text_7',                     'string'],
  ['user_text_8',                     'User Text 8',          'lineitem__user_text_8',                     'string'],
  ['user_long_text_1',                'User Long Text 1',     'lineitem__user_long_text_1',                'string'],
  ['user_datetime_1',                 'User Datetime 1',      'lineitem__user_datetime_1',                 'datetime'],
  ['user_datetime_2',                 'User Datetime 2',      'lineitem__user_datetime_2',                 'datetime'],
  ['group_subtotal',                  'Group Subtotal',       'lineitem__group_subtotal',                  'decimal'],
  ['status',                          'Status',               'lineitem__status',                          'string'],
  ['assignee_id',                     'Assignee ID',          'lineitem__assignee',                        'integer'],
  ['modified_at',                     'Modified At',          'lineitem__modified_at',                     'datetime'],
  ['created_at',                      'Created At',           'lineitem__created_at',                      'datetime'],
  ['created_by_id',                   'Created By ID',        'lineitem__created_by',                      'integer'],
]

export const EXTRACT_TICKET_FIELDS: TQueryField[] = EXTRACT_TICKET_FIELDS_CSV.map(
  ([field, label, label_key, data_type]) => ({
    field,
    label,
    label_key,
    data_type
  })
)

export const EXTRACT_LINE_ITEM_FIELDS: TQueryField[] = EXTRACT_LINE_ITEM_FIELDS_CSV.map(
  ([field, label, label_key, data_type]) => ({
    field,
    label,
    label_key,
    data_type
  })
)

export const FIELDS_GROUP_MAP: Record<string, { label: string }> = {
  '': { label: 'General' },
  T: { label: 'Ticket' },
  L: { label: 'Line' },
  P: { label: 'Property' }
}
