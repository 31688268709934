import { DATE_FORMAT, ISO_DATE_FORMAT } from '@/constants/date'
import { Attribute } from '@/types/programmed-report'
import { Button, Checkbox, Modal, Select } from '@/ui'
import { cn } from '@/utils'
import { DatePicker, Form, Input, InputNumber, Space } from 'antd'
import dayjs from 'dayjs'

type Props = {
  attribute: Attribute & { index: number }
  isLoading: boolean
  onSave: (attribute: Attribute & { index: number }) => void
  onCancel: () => void
}

export const AddEditAttributeModal = ({ attribute, isLoading, onSave, onCancel }: Props) => {
  const [form] = Form.useForm<Attribute>()
  const type = Form.useWatch('type', form)
  const _attribute = {
    ...attribute,
    values: attribute.values.map((value) => (attribute.type === 'Date' ? dayjs(value) : value))
  }

  return (
    <Modal
      title={attribute.name ? 'Edit Attribute' : 'Add Attribute'}
      open
      okText="Save"
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      onOk={async () => {
        const formData = await form.validateFields()
        onSave({
          ...formData,
          index: attribute.index,
          values: formData.values.map((value: any) =>
            attribute.type === 'Date' ? dayjs(value).format(ISO_DATE_FORMAT) : value
          )
        })
      }}
    >
      <Form layout="vertical" form={form} initialValues={_attribute}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <div className="grid grid-cols-3 gap-x-16">
          <Form.Item name="key" label="Key" rules={[{ required: true }]} className="col-span-2">
            <Input />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Select
              options={[
                { label: 'Text', value: 'Text' },
                { label: 'Number', value: 'Number' },
                { label: 'Date', value: 'Date' }
              ]}
            />
          </Form.Item>
        </div>
        <Form.List name="values">
          {(fields, { add, remove }) => (
            <div>
              <div className="grid grid-cols-2 gap-x-16">
                {fields.map((field) => (
                  <Form.Item key={field.name} label={`Value ${field.name + 1}`}>
                    <Space>
                      <Form.Item name={[field.name]} noStyle>
                        {type === 'Date' ? (
                          <DatePicker format={DATE_FORMAT} className="w-full" />
                        ) : type === 'Number' ? (
                          <InputNumber className="w-full" />
                        ) : (
                          <Input className="w-full" />
                        )}
                      </Form.Item>

                      <Button
                        className={cn(field.name === 0 && 'opacity-0 pointer-events-none')}
                        iconName="fa:trash"
                        type="text"
                        onClick={() => remove(field.name)}
                      />
                    </Space>
                  </Form.Item>
                ))}
              </div>
              <Form.Item>
                <Button type="primary" iconName="fa:add" onClick={() => add('')}>
                  Add Value
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
        <Form.Item name="editable" valuePropName="checked">
          <Checkbox>Make Field Available For Update</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}
