export const COMMAS_USAGE_WARNING_MESSAGE =
  'Please avoid using commas to ensure compatibility with dropdown functionality'

export const SPECIAL_CHARACTERS_USAGE_WARNING_MESSAGE =
  'Please avoid using special characters to ensure compatibility with dropdown functionality'

export const TYPE_VALIDATION_RULE = {
  pattern: /^[A-Za-z0-9\-_ ]+$/g,
  warningOnly: true,
  message: SPECIAL_CHARACTERS_USAGE_WARNING_MESSAGE
}

export const COMMAS_VALIDATION_RULE = {
  pattern: /^([^,]+|)$/g,
  warningOnly: true,
  message: COMMAS_USAGE_WARNING_MESSAGE
}

export const CUSTOM_RECORDS_FIELDS = [
  {
    label: 'Field 1',
    name: 'char_1',
    'data-cy': 'char-1-input',
    rules: [{ ...COMMAS_VALIDATION_RULE }]
  },
  {
    label: 'Field 2',
    name: 'char_2',
    'data-cy': 'char-2-input',
    rules: [{ ...COMMAS_VALIDATION_RULE }]
  },
  {
    label: 'Field 3',
    name: 'char_3',
    'data-cy': 'char-3-input',
    rules: [{ ...COMMAS_VALIDATION_RULE }]
  },
  {
    label: 'Field 4',
    name: 'char_4',
    'data-cy': 'char-4-input',
    rules: [{ ...COMMAS_VALIDATION_RULE }]
  }
]
