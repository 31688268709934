import { useEnrichShimResource, useJobRoles, useSelectedJobTicket } from '@/modules/jas/job-manager/hooks'
import { JobResource, JobResourceData } from '@/modules/jas/job-manager/types'
import { Icon } from '@/ui/icons'
import { Button, Empty, Popover, Skeleton, Tag } from 'antd'
import cn from 'classnames'
import { sortBy } from 'lodash'
import { nanoid } from 'nanoid'
import React from 'react'

type Props = {
  type: 'employee' | 'equipment'
  data: JobResourceData
  className?: string
}

export const JobAssignedResources: React.FC<Props> = ({ type, data, className }) => {
  const label = type === 'employee' ? 'Employees' : 'Equipment'

  return (
    <div className={cn('px-12', className)}>
      <div className="border-b dark:border-dark-border pb-2 mb-12">
        <h5 className="font-semibold mb-2">{label}</h5>
      </div>

      {sortBy(data.res_items, 'name').map((res) => (
        <ResourceItem resource={res} key={nanoid()} type={type} />
      ))}

      {data.res_items.length === 0 && (
        <div className="border-0 border-b border-solid border-gray-200 dark:border-gray-700 pb-2 mb-12">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No Assigned ${label}`} />
        </div>
      )}
    </div>
  )
}

const ResourceItem: React.FC<{
  type: 'employee' | 'equipment'
  resource: JobResource
}> = ({ type, resource }) => {
  const { getJobRoleName } = useJobRoles()
  const { removeResource, isEditing } = useSelectedJobTicket()
  const { eResource, isFetching } = useEnrichShimResource(resource)
  const isLoading = resource.isShim && isFetching

  return (
    <div key={eResource.id} className="border-b dark:border-dark-border pb-2 mb-12 relative flex justify-between">
      {!isLoading ? (
        <div>
          <span className="font-semibold mb-2 text-gray-500">{eResource.name}</span>
          <Tag className={'ml-6'}>{getJobRoleName(eResource.assigned_sku?.job_role_id || null)}</Tag>
          <div className="text-sm text-gray-400 min-h-[24px]">
            {!resource.isShim && Object.keys(eResource.attributes).join(', ')}
            {resource.isShim && (
              <Popover
                placement="bottom"
                overlayStyle={{
                  width: 300
                }}
                content={
                  <div>
                    <span className={'text-warning'}>
                      <Icon name="fa:warning" /> Missing Event
                    </span>
                    <div>
                      <p>
                        This assigned resource SKU #{eResource.assigned_sku?.id} is missing schedule event for the job.
                      </p>
                      <p>
                        <span>Reason could be:</span> <br />
                        <span>
                          - Improperly configured resource doc. <br />- Resource SKU missing job role. <br />- It is a
                          old/legacy job ticket. <br />
                        </span>
                      </p>
                    </div>
                  </div>
                }
              >
                <span className={'text-warning cursor-pointer'}>
                  <Icon name="fa:warning" /> Missing schedule event
                </span>
              </Popover>
            )}
          </div>
        </div>
      ) : (
        <div className={'w-full'}>
          <Skeleton.Button key={'b'} active={true} block={true} />
        </div>
      )}

      {isEditing && (
        <span>
          <Button type={'text'} icon={<Icon name="svg:trash" />} onClick={() => removeResource(type, eResource)} />
        </span>
      )}
    </div>
  )
}
