import { CodeEditor } from '@/components/code-editor'
import { Form } from 'antd'
import React, { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BpaEtlClickhouseCommandAction } from '../../schemas'

type Props = {
  action: BpaEtlClickhouseCommandAction
  onChange: (data: Partial<BpaEtlClickhouseCommandAction>) => void
}

export const InputEtlClickhouseCommandAction: FC<Props> = ({ action, onChange }) => {
  const config = action?.data || {}
  const onChangeDebounced = useDebouncedCallback(onChange, 250)

  return (
    <div className="flex flex-col gap-10">
      <Form.Item label="Command SQL" className={'flex-grow'}>
        <CodeEditor
          minLines={5}
          maxLines={25}
          defaultValue={config.command_sql || ''}
          onChange={(value) => onChangeDebounced({ data: { ...action.data, command_sql: value } })}
          mode="sql"
        />
      </Form.Item>
    </div>
  )
}
export const InputEtlClickhouseCommandActionMemo = React.memo(InputEtlClickhouseCommandAction)
