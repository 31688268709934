import { AlertSelect, PropertySelect } from '@/components'
import { StatusSelect } from '@/modules/ticket/components'
import { ScriptedButtonConfig } from '../../../types'
import { ReDispatchButton } from './ReDispatchButton'

export const RcrReDispatchButtonConfig: ScriptedButtonConfig = {
  type: 'rcr_re_dispatch_button',
  iconName: 'fa:share-all',
  label: 'Re-Dispatch',
  group: 'RCR',
  render: () => <ReDispatchButton />,
  vars: [
    {
      name: 'dispatch_status',
      label: 'Dispatch Status',
      render: (customForm, props) => <StatusSelect formId={customForm?.id} {...props} />
    },
    {
      name: 'clear_property_ids',
      label: 'Clear Property',
      render: (customForm, props) => <PropertySelect mode={'multiple'} formId={customForm?.id} {...props} />
    },
    {
      name: 'clear_alert_ids',
      label: 'Clear Alerts',
      render: (customForm, props) => <AlertSelect mode={'multiple'} formId={customForm?.id} {...props} />
    },
    {
      name: 'is_re_dispatched_pid',
      label: 'Is Redispatched Property',
      render: (customForm, props) => <PropertySelect formId={customForm?.id} {...props} />
    }
  ]
}
