import { queryClient } from '@/query'

const DEFAULT_REFRESH_KEYS: string[] = ['tickets', 'line_items', 'contact_links', 'events']

export const refreshQueries = async (keys?: boolean | string[]) => {
  if (keys === false) return // skip refresh
  if (keys === true || keys === undefined) keys = DEFAULT_REFRESH_KEYS

  return Promise.all(keys.map((key) => queryClient.invalidateQueries([key])))
}
