import { COMPANY_KIND } from '@/constants/general'
import { companyApi } from '@/services/api-service'
import { AppLink, Button, PageTitle, QueryDeleteButton, QueryTable, SearchInput } from '@/ui'
import { Space } from 'antd'
import { useState } from 'react'

export const AllCompaniesView = () => {
  const [searchText, setSearchText] = useState('')

  return (
    <div>
      <PageTitle>Companies</PageTitle>
      <div className="flex justify-between my-16">
        <SearchInput onSearch={setSearchText} />
        <AppLink to="/companies/add">
          <Button type="primary">Add Company</Button>
        </AppLink>
      </div>
      <QueryTable
        queryApi={companyApi.list}
        queryParams={{
          order: 'name',
          public_only: true,
          ...(searchText && { name__icontains: searchText })
        }}
        columns={[
          { title: 'Name', dataIndex: 'name' },
          {
            title: 'Type',
            dataIndex: 'kind',
            render: (kind) => COMPANY_KIND[kind as keyof typeof COMPANY_KIND]
          },
          {
            dataIndex: 'id',
            align: 'right',
            sorter: false,
            render: (id) => (
              <Space>
                <QueryDeleteButton api={companyApi.delete} id={id} />
                <AppLink to={`/companies/${id}/edit`}>
                  <Button type="text" iconName="fa:edit"></Button>
                </AppLink>
                <AppLink to={`/companies/${id}/view`}>
                  <Button type="text" iconName="fa:arrow-circle-right"></Button>
                </AppLink>
              </Space>
            )
          }
        ]}
      />
    </div>
  )
}
