import { useApp } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { afeApi } from '@/services/api-service'
import { QueryDeleteButton } from '@/ui'
import dayjs from 'dayjs'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'
import { ModalButton } from './modal-button'
import { Afe, AfeSchema } from './schemas'

export const AfeListView = view(Component, { title: () => 'AFEs' })

function Component() {
  const { l } = useApp()

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('AFEs'),
        actions: [
          <ModalButton key={'add'} type={'primary'} shape={'default'} iconName={'fa:plus'} data-cy={'add-afe-button'}>
            Add {l('AFE')}
          </ModalButton>
        ]
      }}
      filter={{ search: { searchBy: 'user_afe_no' } }}
      table={{
        queryApi: afeApi.list,
        queryParams: { order: 'user_afe_no', fields: AfeSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'user_afe_no', title: `${l('AFE')} Number` },
          { dataIndex: 'external_id', title: 'External ID' },
          { dataIndex: 'budget', title: 'Budget' },
          { dataIndex: 'modified_at', title: 'Last Updated', render: (value) => dayjs.parse(value)?.formatLocal() },
          {
            key: 'actions',
            fixed: 'right',
            width: 80,
            render: (row: Afe) => (
              <div>
                <ModalButton iconName={'fa:edit'} afe={row} data-cy={'edit-afe-button'} />
                <QueryDeleteButton id={row.id} api={afeApi.delete} name={l('AFE')} />
              </div>
            )
          }
        ]
      }}
    />
  )
}
