import { useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { useSaveUserData } from '@/modules/profile/hooks'
import { useTableViewsQuery } from '@/modules/table-view/hooks'
import { TableView } from '@/modules/table-view/types'
import { ListParams } from '@/types/api/core'
import { deepMerge, dig } from '@/utils'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { activeTableViewIdAtom } from '../atoms'
import { useTicketModule } from './use-ticket-module'

export const useModuleTableViewsQuery = (params?: ListParams) => {
  const { module, isLoadsView, isTicketsView, isSchedulerView, isTicketsCalendarView } = useTicketModule()
  const queryParams = useMemo(() => {
    let viewType
    if (isLoadsView) viewType = 'L'
    else if (isTicketsView || isSchedulerView || isTicketsCalendarView) viewType = 'T'
    else viewType = module?.view_type

    return deepMerge(
      {
        ...(viewType ? { type__iexact: viewType } : {}),
        'E[]': module?.hidden_views?.length ? [`id__in|${module.hidden_views.join(',')}`] : [],
        include_internal_view_ids: module?.internal_views,
        order: 'name'
      },
      params
    )
  }, [
    isLoadsView,
    isSchedulerView,
    isTicketsCalendarView,
    isTicketsView,
    module?.hidden_views,
    module?.internal_views,
    module?.view_type,
    params
  ])
  return {
    ...useTableViewsQuery(queryParams),
    queryParams
  }
}

export const useActiveTableView = () => {
  const { userDataViewKey, module } = useTicketModule()

  const [activeViewId, setActiveViewId] = useAtom(activeTableViewIdAtom)
  const { refreshUserSession } = useBootstrap()
  const { saveUserData } = useSaveUserData({
    onSuccess: () => {
      refreshUserSession()
    }
  })

  const query = useModuleTableViewsQuery()
  const { data } = query

  const activeView: TableView | null = useMemo(() => {
    if (!data) return null
    return data.items.find((v) => v.id === activeViewId) || null
  }, [activeViewId, data])

  const { user } = useSession()

  const setActiveView = useCallback(
    async (view: { id: number } | null) => {
      setActiveViewId(view ? view.id : null)
      await saveUserData({
        id: user?.profile.id,
        userdata_json: { ...user?.profile.userdata_json, [userDataViewKey]: view?.id }
      })
    },
    [setActiveViewId, saveUserData, user, userDataViewKey]
  )

  useEffect(() => {
    const moduleViewId = module?.selected_view || (module?.data?.hide_default_none_view && data?.items[0]?.id) || []
    const userViewId = dig<number | null>(user?.profile?.userdata_json, userDataViewKey) || null

    if (moduleViewId && module?.data?.force_view) {
      setActiveViewId(moduleViewId)
    } else {
      setActiveViewId(userViewId || moduleViewId)
    }
  }, [userDataViewKey, setActiveViewId, data?.items])

  return {
    ...query,
    isReady: query.isFetched,
    activeViewId,
    activeView,
    setActiveView
  }
}
