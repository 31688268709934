import { useApp } from '@/hooks'
import { propertyChoiceApi } from '@/services/api-service'
import { PropertyChoice } from '@/types/property-choice'
import { PageTitle, QueryTable, SearchInput } from '@/ui'
import { Form, Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import {
  ActionButtonsColumn,
  AddOptionModal,
  EditableTableColumn,
  LinkedPropertySelect,
  PropertySelect,
  SavePropertyLinkButton,
  ShareModal,
  ShowPropertyLinkButton
} from './components'
import { usePropertiesQuery } from './hooks'

export const ChoicesManagerView = () => {
  const { l } = useApp()
  const [form] = Form.useForm()
  const propertyId = Form.useWatch('id', form) as number | undefined
  const { properties } = usePropertiesQuery()
  const property = properties.find((p) => p.id === propertyId)
  const [searchText, setSearchText] = useState('')

  return (
    <div>
      <Form layout="vertical" form={form}>
        <Form.Item name="showLinkProperty" hidden initialValue={false} />
        <Form.Item name="editingPropertyChoice" hidden />
        <PageTitle>{l('Choices Manager')}</PageTitle>
        <div className="flex">
          <Space>
            <PropertySelect />
            <ShowPropertyLinkButton />
            <LinkedPropertySelect />
            <SavePropertyLinkButton />
          </Space>
          <Space className="ml-auto">
            <ShareModal />
            <AddOptionModal />
          </Space>
        </div>
        {property && (
          <div>
            <SearchInput onSearch={(text) => setSearchText(text)} className="mb-16" />
            <QueryTable
              rowKey="id"
              queryApi={propertyChoiceApi.list}
              queryParams={{ property__eq: propertyId, search: searchText }}
              scroll={{ y: 'calc(100vh - 362px)' }}
              columns={[
                {
                  title: 'Display Name',
                  dataIndex: 'name',
                  key: 'name',
                  render: (value: string, propertyChoice: PropertyChoice) => (
                    <EditableTableColumn field="name" value={value} propertyChoice={propertyChoice} />
                  )
                },
                {
                  title: 'Value',
                  dataIndex: 'value',
                  key: 'value',
                  render: (value: string, propertyChoice: PropertyChoice) => (
                    <EditableTableColumn field="value" value={value} propertyChoice={propertyChoice} />
                  )
                },
                {
                  title: 'Last Updated',
                  dataIndex: 'modified_at',
                  key: 'modified_at',
                  render: (value: string) => dayjs(value).format('MMM D, YYYY h:mm:ss A')
                },
                {
                  dataIndex: 'id',
                  align: 'right',
                  key: 'id',
                  sorter: false,
                  render: (_, propertyChoice: PropertyChoice) => <ActionButtonsColumn propertyChoice={propertyChoice} />
                }
              ]}
            />
          </div>
        )}
      </Form>
    </div>
  )
}
