import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-javascript'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import AceEditor from 'react-ace'
import { configurationAtom } from '../../../../atoms'
import { useSortableItemContext } from '../../context'

export const SumScript = () => {
  const { lineItemField } = useSortableItemContext()
  const setConfiguration = useSetAtom(configurationAtom)

  const summaryScriptsField = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.summary_scripts ?? {}), [])
  )

  const onChange = (newValue: string) => {
    setConfiguration((configuration) => ({
      ...configuration,
      summary_scripts: {
        ...summaryScriptsField,
        [lineItemField]: newValue
      }
    }))
  }

  return (
    <AceEditor
      mode="javascript"
      onChange={onChange}
      name="ace-editor"
      width="100%"
      height="300px"
      data-cy="sum-script-code-editor"
      showPrintMargin={false}
      value={summaryScriptsField[lineItemField] ?? ''}
      onLoad={(editor) => editor.focus()}
      setOptions={{
        useWorker: false
      }}
    />
  )
}
