import { equipmentApi, equipmentGroupApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { copyFromEquipment, transformLineItem } from '../helpers'
import { Equipment, EquipmentGroup, EquipmentSchema, LineItemForm } from '../schemas'

const FIELD_KEY = 'equipmentIds'

export const useEquipmentSelection = () => {
  const form = Form.useFormInstance<LineItemForm>()

  // equipments
  const equipmentIds = Form.useWatch<number[] | undefined>([FIELD_KEY])
  const hasEquipments = !!equipmentIds?.length
  const hasMultiple = hasEquipments && equipmentIds.length > 1
  const equipmentsQuery = useQuery({
    ...equipmentApi.list({ fields: EquipmentSchema, id__in: equipmentIds?.join(','), limit: 1000 }),
    enabled: hasEquipments
    // cacheTime: Infinity
  })
  const [equipments, baseEquipment] = useMemo(() => {
    if (!equipmentIds || !equipmentIds.length || equipmentsQuery.isLoading) return [[], null]
    const equipments = equipmentsQuery.data?.items || []
    const sortedEquipments = (equipmentIds || [])
      .map((id) => equipments.find((e) => e.id === id))
      .filter((e) => e) as Equipment[] // sorted by equipmentIds
    return [sortedEquipments, sortedEquipments[0] || null]
  }, [equipmentIds, equipmentsQuery.data?.items, equipmentsQuery.isLoading])

  // equipment groups
  const groupIds = equipments.map((e) => e.group_no).filter((e) => e)
  const hasGroups = !!groupIds.length
  const equipmentGroupsQuery = useQuery({
    ...equipmentGroupApi.list({ id__in: groupIds.join(','), limit: 1000 }),
    enabled: hasEquipments && !!groupIds.length
  })
  const equipmentGroups = useMemo(() => equipmentGroupsQuery.data?.items || [], [equipmentGroupsQuery.data?.items])
  const equipmentGroupsMap: Map<number, EquipmentGroup> = useMemo(
    () => new Map(equipmentGroups.map((group) => [group.id, group])),
    [equipmentGroups]
  )

  // equipments - callbacks
  const setEquipmentIds = useCallback((ids: number[]) => form.setFieldValue(FIELD_KEY, ids), [form])
  const removeEquipmentId = useCallback(
    (equipmentId: number) => {
      form.setFieldValue(
        FIELD_KEY,
        equipmentIds?.filter((eId: number) => eId !== equipmentId)
      )
    },
    [equipmentIds, form]
  )
  const toggleEquipmentId = useCallback(
    (equipmentId: number) => {
      const index = equipmentIds?.findIndex((eId: number) => eId === equipmentId)
      if (index === -1) {
        form.setFieldsValue({ [FIELD_KEY]: [...(equipmentIds || []), equipmentId] })
      } else {
        form.setFieldsValue({ [FIELD_KEY]: equipmentIds?.filter((eId: number) => eId !== equipmentId) })
      }
    },
    [equipmentIds, form]
  )

  // fill form [data] with equipment
  useEffect(() => {
    const isNew = form.getFieldValue(['isNew'])
    if (!baseEquipment || !isNew || !equipmentIds || equipmentsQuery.isLoading) return

    const data =
      equipments.length === 1
        ? transformLineItem(copyFromEquipment(baseEquipment))
        : transformLineItem(copyFromEquipment(baseEquipment, {}, { noCoreFields: true }))

    form.resetFields(['data'])
    form.setFieldValue(['data'], data)
  }, [baseEquipment, equipmentIds, equipments, equipmentsQuery.isLoading, form])

  return {
    equipmentIds,
    setEquipmentIds,
    toggleEquipmentId,
    removeEquipmentId,
    hasEquipments,
    hasMultiple,
    equipmentsQuery,
    equipments,
    baseEquipment,
    hasGroups,
    equipmentGroupsQuery,
    equipmentGroups,
    equipmentGroupsMap
  }
}
