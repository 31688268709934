import { TABLE_DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZES } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { contactApi, moduleApi } from '@/services/api-service'
import { Contact } from '@/types/contact'
import { Modal, SearchInput } from '@/ui'
import { Button } from '@/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Checkbox, Table } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  contacts: number[]
  onSaved?: () => Promise<any>
}

export const UserAccessModal = ({ contacts, onSaved }: Props) => {
  const { id } = useParams()
  const { company } = useSession()
  const { notification } = useApp()
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [selectedContacts, setSelectedContacts] = useState<number[]>(contacts)
  const isSelected = (id: number) => selectedContacts.includes(id)

  const updateModuleMutation = useMutation({
    ...moduleApi.patch()
  })

  const contactsQuery = useQuery({
    ...contactApi.list<{
      id: number
      first_name: string
      last_name: string
      title: string | null
      email: string | null
      phone_number: string | null
    }>({
      companyId: company.id,
      profile__user__is_active: 1,
      registered_only: true,
      fields: 'id,first_name,last_name,title,email,phone_number',
      order: 'first_name',
      limit,
      page,
      ...(search && { Q: [`first_name__icontains|${search.trim()}`, `last_name__icontains|${search.trim()}`, 'or'] })
    })
  })

  const handleSave = async () => {
    await updateModuleMutation.mutateAsync({
      id,
      contacts_json: JSON.stringify(selectedContacts)
    })

    await onSaved?.()
    notification.success({ message: 'User access updated' })
    toggleModal()
  }

  return (
    <>
      <Button iconName="mi:add" type="primary" onClick={toggleModal}>
        Add Member
      </Button>
      <Modal
        title="User Access"
        withScreenMaxHeight
        open={isOpen}
        width={900}
        okText="Save"
        okButtonProps={{
          loading: updateModuleMutation.isLoading
        }}
        onCancel={toggleModal}
        onOk={handleSave}
      >
        <SearchInput onSearch={setSearch} className="mb-12" />
        <Table
          pagination={{
            current: page,
            pageSize: limit,
            total: contactsQuery.data?.total,
            pageSizeOptions: TABLE_PAGE_SIZES,
            onChange: (page, limit) => {
              setPage(page)
              setLimit(limit)
            }
          }}
          dataSource={contactsQuery.data?.items.map((contact) => ({
            ...contact,
            key: contact.id
          }))}
        >
          <Table.Column
            render={(_, contact: Contact) => (
              <Checkbox
                checked={isSelected(contact.id)}
                onChange={(e) =>
                  setSelectedContacts(
                    e.target.checked
                      ? [...selectedContacts, contact.id]
                      : selectedContacts.filter((id) => id !== contact.id)
                  )
                }
              />
            )}
          />
          <Table.Column
            title="Name"
            render={(_, contact: Contact) => (
              <div>
                {contact.first_name} {contact.last_name}
              </div>
            )}
          />
          <Table.Column title="Title" dataIndex="title" />
          <Table.Column title="Email" dataIndex="email" />
          <Table.Column title="Phone Number" dataIndex="phone_number" />
        </Table>
      </Modal>
    </>
  )
}
