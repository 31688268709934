import { afeApi, contactApi, customFormApiV2, jobCodeApi, locationApi, officeApi } from '@/services/api-service'
import { objId } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { reloadNextTicketNoAtom } from '../atoms'
import { useTicketFormContext } from '../context'
import { getNextTicketNo } from '../helpers'
import {
  AfeSchema,
  CustomFormNextSequenceSchema,
  CustomerOfficeSchema,
  CustomerSchema,
  JobCodeSchema,
  LocationSchema,
  TicketOfficeSchema
} from '../schemas'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'
import { useTicketFormValues, useTicketFormWatch } from './use-ticket-form'

export const useTicketFormName = () => {
  const { customForm } = useCustomForm()

  const bindToPid = customForm._features.dynamicFormName?.bind_to_pid
  const isEnabled = customForm._features.dynamicFormName?.enabled && !!bindToPid

  const boundName = useTicketFormWatch(isEnabled ? [`p_${bindToPid}`] : [])

  return (isEnabled ? boundName : '') || customForm.name || 'Service Ticket'
}

export const useNextTicketName = () => {
  const { ticket, customForm } = useTicketFormContext()
  const { unSavedValues } = useTicketFormValues()
  const setReloadNextTicketNo = useSetAtom(reloadNextTicketNoAtom)

  const customFormQuery = useQuery({
    ...customFormApiV2.get(customForm.id, undefined, {
      fields: CustomFormNextSequenceSchema
    }),
    // fetch only on new ticket and if custom name is not entered
    enabled: !ticket.id && !unSavedValues.name,
    refetchOnWindowFocus: 'always',
    refetchInterval: 10000
  })

  const forceRefetch = () => customFormQuery.refetch()

  const nextTicketNo = useMemo(() => {
    if (ticket.id) return undefined

    if (customFormQuery.data)
      return getNextTicketNo(
        { ...(customFormQuery.data || {}) },
        { ticket_gen_number: customFormQuery.data?.primary_company__ticket_gen_number }
      )
  }, [ticket.id, customFormQuery.data])

  const onDuplicateError = useCallback(() => {
    if (ticket.id) return

    setReloadNextTicketNo(true)
  }, [setReloadNextTicketNo, ticket.id])

  return { nextTicketNo, forceRefetch, query: customFormQuery, onDuplicateError }
}

export const useTicketFullName = () => {
  const name = useTicketFormWatch('name') || ''
  const { getPropertyValueByKey } = useTicketFormValues()

  return useMemo(() => {
    const prefix = getPropertyValueByKey('ticket_prefix')
    return prefix ? `${prefix}${name}` : name
  }, [getPropertyValueByKey, name])
}

export const useTicketOffice = () => {
  const { ticket } = useTicket()

  const officeId = ticket.office_id || null
  const officeQuery = useQuery(officeApi.get(officeId, {}, { fields: TicketOfficeSchema }))

  return officeQuery.data
}

export const useTicketCustomerOffice = () => {
  const customerOfficeId = objId(useTicketFormWatch(['customer_office']))
  const customerOfficeQuery = useQuery(officeApi.get(customerOfficeId, {}, { fields: CustomerOfficeSchema }))

  return customerOfficeQuery.data
}

export const useTicketCustomer = () => {
  const customerId = objId(useTicketFormWatch(['customer']))
  const customerQuery = useQuery(contactApi.get(customerId, {}, { fields: CustomerSchema }))

  return customerQuery.data
}

export const useTicketLocation = () => {
  const locationId = objId(useTicketFormWatch(['location']))
  const locationQuery = useQuery(locationApi.get(locationId, {}, { fields: LocationSchema }))

  return locationQuery.data
}

export const useTicketAfe = () => {
  const afeId = objId(useTicketFormWatch(['afe']))
  const afeQuery = useQuery(afeApi.get(afeId, {}, { fields: AfeSchema }))

  return afeQuery.data
}

export const useTicketJobCode = () => {
  const jobCodeId = objId(useTicketFormWatch(['job_code']))
  const afeQuery = useQuery(jobCodeApi.get(jobCodeId, {}, { fields: JobCodeSchema, limit: 1 }))

  return afeQuery.data
}
