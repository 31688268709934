import { MutationReturn, ObjectReturn, RequestConfig } from '@/types/api/core'
import { AuthUser } from '@/types/auth-user'
import { request } from '../core'

export type AuthApi = {
  login: (config?: RequestConfig) => MutationReturn<{ token: string }, { username: string; password: string }>
  user: (config?: RequestConfig) => ObjectReturn<AuthUser>
  forgotPassword: (config?: RequestConfig) => MutationReturn<{ detail: string }, { email: string }>
  resetPassword: (
    config?: RequestConfig
  ) => MutationReturn<{ detail: string }, { new_password1: string; new_password2: string; token: string; uid: string }>
}

export const _authApi: AuthApi = {
  login: (config?: RequestConfig) => ({
    mutationFn: ({ username, password }) =>
      request({ ...config, url: '/jwt/auth/', method: 'POST', data: { username, password } })
  }),
  user: (config?: RequestConfig) => ({
    queryKey: ['auth', 'user'],
    queryFn: ({ signal }) => request({ ...config, method: 'GET', url: '/rest-auth/user/', signal: signal })
  }),
  forgotPassword: (config?: RequestConfig) => ({
    mutationFn: ({ email }) =>
      request({ ...config, url: '/rest-auth/password/reset/', method: 'POST', data: { email } })
  }),
  resetPassword: (config?: RequestConfig) => ({
    mutationFn: ({ new_password1, new_password2, token, uid }) =>
      request({
        ...config,
        url: '/rest-auth/password/reset/confirm/',
        method: 'POST',
        data: { new_password1, new_password2, token, uid }
      })
  })
}
