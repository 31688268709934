import { FC } from 'react'
import { PageView, PageViewProps } from '../page-view'

export type FormViewProps = PageViewProps

/**
 * Just a wrapper for PageView
 * Currently, it's only for a semantic purpose.
 */
export const FormView: FC<FormViewProps> = ({ header, ...props }) => {
  return <PageView header={{ backButton: true, ...header }} {...props} />
}
