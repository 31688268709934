import { CodeEditor } from '@/components/code-editor'
import { ScriptedButtonConfig } from '../../types'
import { CustomScriptButton } from './CustomScriptButton'

export const CustomScriptButtonConfig: ScriptedButtonConfig = {
  type: 'common_custom_script',
  listIconName: 'fa:function',
  label: 'Custom Script',
  group: 'Common',
  render: () => <CustomScriptButton />,
  vars: [
    {
      name: 'propsScript',
      label: 'Props Script',
      render: (customForm, props) => <CodeEditor mode="javascript" minLines={10} maxLines={Infinity} isInModal />
    }
  ]
}
