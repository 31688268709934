import { ListView } from '@/layouts/views'
import { useCanEditCustomFormCallback, useIsSupport } from '@/modules/auth/hooks'
import { SETTINGS_PATHS } from '@/modules/settings/constants'
import { view } from '@/routing'
import { customFormApiV2 } from '@/services/api-service'
import { AppLink, Button, QueryDeleteButton, QueryEditButton } from '@/ui'
import { Tooltip } from 'antd'
import { LayoutLink } from '../components/layout-link'
import { CUSTOM_FORM_TYPES } from '../constants'
import { CopyCustomFormButton } from './copy-custom-form-button'
import { CustomForm, CustomFormSchema } from './schema'

export const CustomFormsView = view(Component)

function Component() {
  const canEditCustomForm = useCanEditCustomFormCallback()
  const isSupport = useIsSupport()

  return (
    <ListView
      header={{
        title: 'Custom Forms',
        breadcrumbs: true,
        refresh: true,
        actions: [
          <AppLink key={'add-custom-form-button'} to={SETTINGS_PATHS.addCustomForm}>
            <Button iconName="mi:add" type="primary" data-cy="add-custom-form-button">
              Add Custom Form
            </Button>
          </AppLink>
        ]
      }}
      filter={{ search: { searchBy: 'name' } }}
      table={{
        queryApi: customFormApiV2.list,
        queryParams: {
          fields: CustomFormSchema,
          include_time: true,
          show_all: true,
          ...(isSupport ? { with_hidden: true } : {}),
          order: 'primary_company__name'
        },
        queryOptions: {
          select: (data) => ({
            ...data,
            items: CustomFormSchema.array().parse(data.items)
          })
        },
        columns: [
          {
            title: 'Primary Company',
            dataIndex: 'primary_company__name',
            sorter: false
          },
          {
            title: 'Available to',
            sorter: false,
            render: (_, customForm: CustomForm) => (
              <div className="flex flex-col">
                {customForm.permissions.map((p) => (
                  <span className="mt-4" key={p.company__name}>
                    {p.company__name}
                  </span>
                ))}
              </div>
            )
          },
          {
            title: 'Name',
            dataIndex: 'name',
            sorter: false
          },
          {
            title: 'Type',
            dataIndex: 'type',
            render: (type) => CUSTOM_FORM_TYPES[type as keyof typeof CUSTOM_FORM_TYPES]
          },
          {
            title: 'Description',
            dataIndex: 'description',
            sorter: false
          },
          {
            title: 'Status',
            dataIndex: 'public',
            sorter: true,
            render: (isPublic) => (isPublic ? 'Enabled' : 'Disabled')
          },
          {
            sorter: false,
            align: 'right',
            fixed: 'right',
            width: 150,
            render: (_, customForm) => (
              <div className={'flex flex-row justify-end'}>
                {isSupport && canEditCustomForm(customForm) && (
                  <div>
                    <CopyCustomFormButton id={customForm.id} name={customForm.name} />
                    <QueryDeleteButton
                      name={'Custom Form'}
                      api={customFormApiV2.delete}
                      id={customForm.id}
                      data-cy="delete-custom-form-button"
                    />
                  </div>
                )}
                <Tooltip title="Layout">
                  <LayoutLink formId={customForm.id} disabled={!canEditCustomForm(customForm)} />
                </Tooltip>
                <QueryEditButton
                  to={SETTINGS_PATHS.editCustomForm(customForm.id)}
                  disabled={!canEditCustomForm(customForm)}
                  data-cy="edit-custom-form-button"
                />
              </div>
            )
          }
        ]
      }}
    />
  )
}
