import { TICKET_STATUS } from '@/constants/general'
import { PropertyGroup } from '@/types/property-group'
import { Button, Select } from '@/ui'
import { Form, Input } from 'antd'
import { ScriptedButtonConfig } from '../../types'
import { SetStatusButton } from './SetStatusButton'

export const SetStatusButtonConfig: ScriptedButtonConfig = {
  type: 'common_set_status_button',
  iconName: 'fa:check-circle',
  label: 'Set Status',
  group: 'Common',
  render: () => <SetStatusButton />,
  vars: [
    {
      name: 'status',
      label: 'Status',
      rules: [{ required: true }],
      render: (_, props) => (
        <Select
          options={Object.entries(TICKET_STATUS).map(([value, label]) => ({
            label,
            value
          }))}
          {...props}
        />
      )
    },
    {
      name: '',
      label: 'Properties',
      render: (customForm, props, namePath) => (
        <Form.List {...props} name={[...namePath, 'properties_keys_values']}>
          {(fields, { add }) => {
            return (
              <>
                {fields.map((field) => (
                  <div key={field.name} className="grid grid-cols-2 gap-x-16">
                    <Form.Item name={[field.name, 'p_key']} label="Property">
                      <Select
                        options={customForm.property_groups?.map((pg: PropertyGroup) => ({
                          label: pg.name,
                          title: pg.name,
                          options: pg.properties?.map((p) => ({ label: p.key, value: p.key }))
                        }))}
                      />
                    </Form.Item>
                    <Form.Item name={[field.name, 'value']} label="Value">
                      <Input />
                    </Form.Item>
                  </div>
                ))}
                <Button onClick={() => add()}>Add</Button>
              </>
            )
          }}
        </Form.List>
      )
    }
  ]
}
