import { ListParams } from '@/types/api/core'
import { UrlStateFormItem } from '@/ui/form'
import { Select } from '@/ui/select'
import { set } from 'lodash'
import { FilterFormItem } from './FilterFormItem'
import { FILTER_SEARCH_BY_KEY, FILTER_SEARCH_KEY } from './constants'
import { QueryFilterService } from './types'

export type SearchFilterProps = {
  service: QueryFilterService
  disabled?: boolean
  hideFieldSelect?: boolean
  value?: { field: string; value: any }
  onChange?: (value: { field: string; value: any }, query: ListParams) => void
}

export function SearchFilter({ service, hideFieldSelect = false }: SearchFilterProps) {
  const { filterKey, fieldsOptions, getSearchData } = service

  const { defaultFieldKey, selectedFieldKey, selectedField } = getSearchData()

  return (
    <div className={'flex flex-row gap-8'}>
      <UrlStateFormItem
        formProps={{
          onValuesChange: (changedValues) => set(changedValues, `${filterKey}.${FILTER_SEARCH_BY_KEY}`, null)
        }}
        defaultValue={defaultFieldKey}
        name={[filterKey, FILTER_SEARCH_BY_KEY]}
        className={'w-[160px]'}
        hidden={hideFieldSelect}
      >
        <Select options={fieldsOptions} />
      </UrlStateFormItem>
      <FilterFormItem
        key={selectedFieldKey}
        name={[filterKey, FILTER_SEARCH_KEY]}
        service={service}
        field={
          {
            ...selectedField,
            inputType: selectedField?.inputType === 'text' ? 'search' : selectedField?.inputType
          } as any
        }
        hideLabel={true}
        hideRemove={true}
      />
    </div>
  )
}
