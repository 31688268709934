import { useSession } from '@/hooks'
import { DashboardLayout } from '@/layouts/dashboard-layout'
import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { NavigationSync } from './navigation-sync'

export const RootLayout: FC = () => {
  const { authenticated } = useSession()
  const location = useLocation()
  const isSecureView = location.pathname.startsWith('/secure')

  return (
    <>
      <NavigationSync className="fixed top-0 left-0 z-[2147483647]" />
      {authenticated && !isSecureView ? <DashboardLayout /> : <Outlet />}
    </>
  )
}
