import { Icon, Tooltip } from '@/ui'
import cn from 'classnames'
import { useAtomValue } from 'jotai'
import { FC, ReactNode } from 'react'
import { unSavedValuesAtom } from '../../../atoms'
import { useTicketLabel } from '../../../hooks'

type Props = {
  k?: string
  name?: string
  className?: string
  children?: ReactNode
}

export const Label: FC<Props> = ({ k, name, className, children }) => {
  const l = useTicketLabel()

  return (
    <span className={cn('flex flex-row items-center gap-4', className)}>
      <span className={'line-clamp-1'}>{k ? l(k) : children}</span>
      {name && <UnsavedChangeIndicator name={name} />}
    </span>
  )
}

const UnsavedChangeIndicator: FC<{ name: string }> = ({ name }) => {
  const unSavedValues = useAtomValue(unSavedValuesAtom)

  return unSavedValues[name] ? (
    <Tooltip title={'Unsaved Change'}>
      <Icon name={'fa-solid:circle-small'} className={'cursor-pointer text-primary-300 text-[10px]'} />
    </Tooltip>
  ) : null
}
