import { z } from 'zod'
import { BpaConditionSchema, BpaScheduleFilterValue1Schema } from './conditions'
import { BpaRuleSchema } from './rules'
import { BpaTriggerSchema } from './triggers'
import { BpaVariableSchema } from './variables'

export const BpaConfigModeSchema = z.enum(['graph', 'pipeline', 'sync', 'advanced', 'simple'])

export const BpaScheduleFilterCriteriaScheme = z.object({
  field: z.string(), // "timepoint_submitted",
  exclude: z.boolean(), // "not",
  value1: BpaScheduleFilterValue1Schema,
  value2: z.number() //14,
})

export const BpaScheduleRuleScheme = z.object({
  scheduled_for: z.enum(['function', 'automation']),
  cron_spec: z.string(),
  timezone: z.string(),
  run_as: z.number().nullish(),
  filter_criteria: BpaScheduleFilterCriteriaScheme,
  function_config: z
    .object({
      function: z.string(),
      kwargs: z.string()
    })
    .nullish()
})

export const BpaConfigSchema = z.object({
  /**
   * This is used to identify the property that the BPA is attached to.
   */
  propertyId: z.number().optional(),

  /**
   * Mode of BPA (sync, advanced, simple)
   *
   * options:
   * - sync
   * - advanced (@deprecated)
   * - simple (@deprecated)
   */
  mode: BpaConfigModeSchema,

  /**
   * Enable BPA (true, false)
   */
  enableBpa: z.boolean(),

  /**
   * Schedule BPA on a background (true, false, null)
   */
  scheduled: z.boolean().optional(),

  /**
   * Schedule rules
   */
  schedule_rules: BpaScheduleRuleScheme.nullable(),

  /**
   * Execution limit (Unlimited, or Once)
   * @deprecated only applicable for sync mode which is deprecated
   */
  execLimit: z.enum(['null', '1']).optional(),

  /**
   * Expand BPA (true, false)
   * Use???
   */
  expanded: z.boolean().optional(),

  /**
   * Global triggers (pipeline)
   */
  triggers: z.array(BpaTriggerSchema),

  /**
   * Global variables
   */
  variables: z.array(BpaVariableSchema),

  /**
   * Global conditions
   */
  conditions: z.array(BpaConditionSchema),

  /**
   * Rules containing conditions and actions
   */
  rules: z.array(BpaRuleSchema),

  /**
   * Advanced mode rules in YAML format
   * @deprecated use sync mode instead
   */
  rules_yml: z.string().optional()
})

export type BpaConfigMode = z.infer<typeof BpaConfigModeSchema>
export type BpaConfig = z.infer<typeof BpaConfigSchema>
export type BpaScheduleFilterCriteria = z.infer<typeof BpaScheduleFilterCriteriaScheme>
export type BpaScheduleRule = z.infer<typeof BpaScheduleRuleScheme>
