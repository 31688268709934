import { R, getParams } from '@/services/api-service/core'
import { MutationReturn, ObjectReturn, Params, RequestConfig } from '@/types/api/core'
import { AxiosResponse } from 'axios'

export type MsNavApi = {
  submit: (id: number | null, config?: RequestConfig) => MutationReturn<AxiosResponse, any>
  check: <TData = any>(id: number | null, params?: Params, config?: RequestConfig) => ObjectReturn<TData>
}

export const _msNavApi: MsNavApi = {
  submit: (id, config) => ({
    mutationKey: ['tickets', 'msnav', id, 'submit'],
    mutationFn: () => R('POST', `/msnav/tickets/${id}/integration/msnav/`, { ...config }, { isRaw: true })
  }),
  check: (id, params, config) => ({
    enabled: !!id,
    queryKey: ['tickets', 'msnav', id, 'check'],
    queryFn: ({ signal }: RequestConfig) =>
      R('GET', `/msnav/tickets/${id}/integration/msnav/poll/`, { ...config, signal, params: getParams(params, true) })
  })
}
