import { refreshQueries } from '../helpers'

import { useApp } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { ticketApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { cloneDeep, isEmpty, set, uniqBy } from 'lodash'
import { useCallback } from 'react'
import { unSavedValuesAtom } from '../atoms'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'

export type UseTicketSaveProps = {
  refresh?: boolean | string[]
}

export type SaveTicketOptions = {
  override?: Record<string, any>
  only?: string[]
  refresh?: boolean
  waitRefresh?: boolean
  message?: string
  description?: string
  forceSave?: boolean
}

const parsePropertyId = (key: string) => Number(key) || Number(key.replace('p_', '')) || null

export const useTicketSave = ({ refresh }: UseTicketSaveProps = {}) => {
  const _refresh = refresh ?? true

  const { notification } = useApp()

  const { ticket, isNew } = useTicket()
  const { getPropertyById, getPropertyIdByKey } = useCustomForm()

  const setUnSavedValues = useSetAtom(unSavedValuesAtom)

  const createTicketMutation = useMutation(ticketApi.create({ params: { fields: 'id' } }, undefined, false))
  const updateTicketMutation = useMutation(ticketApi.patch({ params: { fields: 'id' } }, undefined, false))

  const saveTicket = useCallback(
    async (options?: SaveTicketOptions) => {
      // hack: get unsaved value using set function
      // todo: find a better way to get unsaved values from atom
      //  without causing re-render
      let unSavedValues: Record<string, { old: any; new: any }> = {}
      setUnSavedValues((prev) => {
        unSavedValues = { ...prev }
        return prev
      })

      const { override, only } = options || {}

      const data: Record<string, any> = {}
      const values = [
        ...Object.entries(unSavedValues).map(([key, value]) => [key, value.new]),
        ...Object.entries(override || {})
      ]

      values.forEach(([key, value]) => {
        // skip if only is defined and key is not in only
        if (only && !only.includes(key)) return

        if (key === 'property' || key.startsWith('p_')) {
          const propsValues: [any, any][] = key === 'property' ? Object.entries(value) : [[key, value]]

          const items: { id?: number; property: number; value: string }[] = []
          propsValues.forEach(([k, v]) => {
            const property = getPropertyById(parsePropertyId(k) || getPropertyIdByKey(k))
            if (!property) return

            const tp = ticket._tpByPropertyId[property.id]
            if (tp) items.push({ id: tp.id, property: property.id, value: v })
            else items.push({ property: property.id, value: v })
          })

          if (items.length) data['ticket_properties'] = [...(data['ticket_properties'] || []), ...items]
        } else if (key.startsWith('extra_data__')) {
          set(data, key.replace('__', '.'), value)
        } else data[key] = value
      })

      // de-duplicate ticket_properties by property id
      if (data['ticket_properties']?.length) {
        // reverse to keep the latest value in de-duplication
        const reversedValues = data['ticket_properties'].reverse()
        data['ticket_properties'] = uniqBy(reversedValues, 'property')
      }

      if (isNew) {
        // create new ticket
        const createdTicket = await createTicketMutation.mutateAsync({
          custom_form: ticket.custom_form_id,
          name: ticket.name,
          status: ticket.status,
          ...data
        })
        if (options?.message || options?.description) {
          notification.success({ message: options?.message || 'Ticket Saved', description: options?.description })
        }

        setUnSavedValues({})
        setTimeout(() => navigate(`/tickets/v2/${ticket.custom_form_id}/${createdTicket.id}/edit`), 100)
        return createdTicket // exit early
      } else {
        let updatedTicket = ticket

        // update existing ticket
        if (options?.forceSave || !isEmpty(data)) {
          updatedTicket = await updateTicketMutation.mutateAsync({ id: ticket.id, ...data })

          if (options?.message || options?.description) {
            notification.success({ message: options?.message || 'Ticket Saved', description: options?.description })
          }

          if (only) {
            setUnSavedValues((prev) => {
              const next = cloneDeep(prev)
              only.forEach((key) => delete next[key])
              return next
            })
          } else setUnSavedValues({})
        }

        const _rOpt = options?.refresh ?? _refresh
        if (_rOpt) {
          if (options?.waitRefresh) await refreshQueries(_rOpt)
          else refreshQueries(_rOpt)
        }

        return updatedTicket
      }
    },
    [
      _refresh,
      createTicketMutation,
      getPropertyById,
      getPropertyIdByKey,
      isNew,
      notification,
      setUnSavedValues,
      ticket,
      updateTicketMutation
    ]
  )

  return {
    saveTicket,
    isSaving: updateTicketMutation.isLoading || createTicketMutation.isLoading
  }
}
