export const constuctPreviewCalendars = (totalRules: number, cellsPerCalendar: number, daysInMonth: number) => {
  const calendarsCount = Math.ceil(totalRules / cellsPerCalendar) || 1
  const calendarDays = []
  let day = 1

  for (let i = 0; i < calendarsCount * cellsPerCalendar; i++) {
    calendarDays.push({ day, index: i })

    if (day === daysInMonth) {
      day = 1
    } else {
      day++
    }
  }

  const calendars = []

  for (let i = 0; i < calendarsCount; i++) {
    calendars.push(calendarDays.slice(i * cellsPerCalendar, (i + 1) * cellsPerCalendar))
  }

  return calendars
}
