import { CATEGORY_KIND_FIELDS } from '@/constants/general'
import { useApp, useCategoryKindsAsLookupQuery, useSession } from '@/hooks'
import { getLineItemName } from '@/utils/get-line-item-name'
import { Checkbox, Form, Spin } from 'antd'
import { useMemo, useState } from 'react'
import { LineItemEditField } from './line-item-edit-field'

type Props = {
  lineItem: any
  onChange: (lineItem: any, value: any) => void
}

export const LineItemEditFields = ({ lineItem, onChange }: Props) => {
  const [isShowAll, setIsShowAll] = useState(false)
  const { categoryKindsAsLookupQuery } = useCategoryKindsAsLookupQuery()
  const { labels } = useApp()
  const { company } = useSession()
  const fieldAliases = JSON.parse(company.field_aliases) ?? {}

  const { fields, hiddenFields } = useMemo(() => {
    const categoryKind = lineItem?.category?.kind

    if (!categoryKindsAsLookupQuery.data || !categoryKind || !categoryKindsAsLookupQuery.data[categoryKind]) {
      return {
        fields: [],
        hiddenFields: []
      }
    }

    const data = categoryKindsAsLookupQuery.data[categoryKind]

    return {
      fields: data.fields ?? [],
      hiddenFields: data.hidden_fields ?? []
    }
  }, [categoryKindsAsLookupQuery.data, lineItem?.category?.kind])

  if (categoryKindsAsLookupQuery.isLoading) {
    return <Spin spinning={categoryKindsAsLookupQuery.isLoading} />
  }

  return (
    <Form layout="vertical" component="div">
      <Form.Item>
        <Checkbox
          data-cy="custom-form-line-item-show-all-fields-checkbox"
          checked={isShowAll}
          onChange={(e) => setIsShowAll(e.target.checked)}
        >
          Show all fields
        </Checkbox>
      </Form.Item>
      <div className="grid grid-cols-3 gap-x-12">
        <Form.Item
          label={
            getLineItemName({
              field: 'sku',
              fieldAliases,
              labels
            }).name
          }
        >
          <LineItemEditField field="sku" lineItem={lineItem} onChange={() => {}} disabled />
        </Form.Item>
        <Form.Item
          label={
            getLineItemName({
              field: 'description',
              fieldAliases,
              labels
            }).name
          }
        >
          <LineItemEditField field="description" lineItem={lineItem} onChange={() => {}} disabled />
        </Form.Item>
        {fields?.map((field) => {
          if (field === 'sku' || field === 'description' || hiddenFields.includes(field)) {
            return null
          }

          return (
            <Form.Item
              label={
                getLineItemName({
                  field: field,
                  fieldAliases,
                  labels
                }).name
              }
              key={field}
            >
              <LineItemEditField key={field} field={field as any} lineItem={lineItem} onChange={onChange} />
            </Form.Item>
          )
        })}
      </div>
      {isShowAll && (
        <div className="grid grid-cols-3 gap-x-12">
          {CATEGORY_KIND_FIELDS.map((key) => {
            if (fields.includes(key) || key === 'sku' || key === 'description' || hiddenFields.includes(key)) {
              return null
            }

            return (
              <Form.Item
                label={
                  getLineItemName({
                    field: key,
                    fieldAliases,
                    labels
                  }).name
                }
                key={key}
              >
                <LineItemEditField field={key} lineItem={lineItem} onChange={onChange} />
              </Form.Item>
            )
          })}
        </div>
      )}
    </Form>
  )
}
