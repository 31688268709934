import { Fields } from '@/components/format-picker/types'
import { propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { sortBy } from 'lodash'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { z } from 'zod'
import { customFormAtom } from '../atoms'
import { BPA_CONTEXT_FIELDS, TICKET_FIELDS } from '../constants/fields'
import { useVariables } from '../hooks/use-variables'

export const useFormatPicker = () => {
  const customForm = useAtomValue(customFormAtom)
  const { variables: bpaVariables } = useVariables()

  const [search, setSearch] = useState('')

  const propertiesQuery = useQuery({
    ...propertyApi.list({
      limit: 'None',
      property_group__custom_form_id__eq: customForm?.id,
      fields: z.object({
        id: z.number(),
        name: z.string(),
        key: z.string(),
        property_group__name: z.string()
      })
    }),
    enabled: !!customForm
  })

  const context: Fields[] = useMemo(
    () =>
      BPA_CONTEXT_FIELDS.map((item) => ({
        key: nanoid(),
        title: item.label,
        text: `C[${item.value}]`,
        isHeader: false,
        hide: false
      })),
    []
  )

  const variables: Fields[] = useMemo(
    () =>
      bpaVariables.map((v) => ({
        key: v.uuid,
        title: v.name,
        text: `V[${v.name}]`,
        isHeader: false,
        hide: false
      })),
    [bpaVariables]
  )

  const fields: Fields[] = useMemo(
    () =>
      TICKET_FIELDS.map((item) => ({
        key: nanoid(),
        title: item.label,
        text: `F[${item.value}]`,
        isHeader: false,
        hide: false
      })),
    []
  )

  const properties: Fields[] = useMemo(
    () =>
      (propertiesQuery.data?.items || []).map((item) => ({
        key: nanoid(),
        title: `${item.property_group__name} / ${item.name}`,
        text: `P[${item.key}]`,
        isHeader: false,
        hide: false
      })),
    [propertiesQuery.data?.items]
  )
  const allFields: Fields[] = useMemo(
    () => [
      { key: 'context', title: 'Context', text: '', isHeader: true, hide: false },
      ...sortBy(context, 'title'),
      { key: 'variables', title: 'Variables', text: '', isHeader: true, hide: false },
      ...sortBy(variables, 'title'),
      { key: 'ticket_fields', title: 'Ticket Fields', text: '', isHeader: true, hide: false },
      ...sortBy(fields, 'title'),
      { key: 'properties', title: 'Properties', text: '', isHeader: true, hide: false },
      ...sortBy(properties, 'title')
    ],
    [context, fields, properties, variables]
  )

  const onSearchDebounced = useDebouncedCallback(
    (e) => {
      setSearch(e.target.value.trim())
    },
    200,
    { leading: false, trailing: true }
  )

  return {
    search,
    isLoading: propertiesQuery.isLoading,
    searchedFields: allFields,
    onSearchDebounced
  }
}
