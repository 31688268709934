import { Form, Input } from 'antd'
import { default as cn } from 'classnames'
import { FC } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { BPA_OPERATORS_MAP } from '../../constants'
import { BpaCondition } from '../../schemas'
import { StatusSelect } from '../controls'

type Props = {
  condition: BpaCondition
  className?: string
  onChange: (value: any) => void
}

export const ValuesInput: FC<Props> = ({ condition, className, onChange }) => {
  const onChangeDebounce = useDebouncedCallback(onChange, 250)

  const operatorSpec = BPA_OPERATORS_MAP[condition.operator]
  if (!operatorSpec.showInput) return <></>

  let labelSmall = ''
  if (condition.fieldType === 'lineItems') {
    labelSmall = 'matches with any item of a ticket'
  } else if (condition.fieldType === 'ticket' && (condition.field === 'local_status' || condition.field === 'status')) {
    return (
      <div className={cn('flex flex-col gap-10', className)}>
        <Form.Item
          label={
            <span className="line-clamp-1">
              Value <small className="text-text-muted">(select)</small>
            </span>
          }
        >
          <StatusSelect
            data-cy="condition-value-select"
            mode="multiple"
            defaultValue={condition.value2 || []}
            onChange={onChangeDebounce}
            maxTagCount="responsive"
          />
        </Form.Item>
      </div>
    )
  }

  const label = (
    <span className="line-clamp-1">
      Value {labelSmall && <small className="text-text-muted">(matches with any item of a ticket)</small>}
    </span>
  )

  return (
    <Form.Item label={label} className={className}>
      <Input.TextArea
        data-cy="condition-value-textarea"
        autoSize={{ minRows: 1, maxRows: 10 }}
        defaultValue={condition.value1 || ''}
        onChange={(e) => onChangeDebounce(e.target.value)}
      />
    </Form.Item>
  )
}
