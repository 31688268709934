import { useApp, useIsMobile } from '@/hooks'
import { ListView } from '@/layouts/views'
import { view } from '@/routing'
import { equipmentApi } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { Checkbox, LinkButton, Select, UrlStateFormItem } from '@/ui'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { RECORDS_LIST_VIEW_HEADER } from '../../../constants'
import { Actions } from './actions'
import { Equipment, EquipmentSchema } from './schemas'

export const SkuListView = view(Component)

function Component() {
  const { l } = useApp()
  const isMobile = useIsMobile()

  return (
    <ListView
      header={{
        ...RECORDS_LIST_VIEW_HEADER,
        title: l('SKUs'),
        actions: [
          <LinkButton key={'add'} to={'add'} type={'primary'} iconName={'fa:plus'} data-cy={'add-sku'}>
            Add {l('SKU')}
          </LinkButton>
        ]
      }}
      filter={{
        search: true,
        query: ({ filter }) => {
          const text = filter?.search

          const params: ListParams = {}

          if (filter?.with_inactive) params.with_inactive = 1

          if (text) {
            if (filter?.search_by == 'category') params.category__parent_category__name__icontains = text
            else if (filter?.search_by == 'subcategory') params.category__name__icontains = text
            else if (filter?.search_by == 'configuration')
              params.Q = [
                `category__kind__name__icontains|${text}`,
                `category__parent_category__kind__name__icontains|${text}`,
                'or'
              ]
            else params.search = text
          }

          return params
        },
        render: (searchEl) => (
          <div className={'flex flex-row items-center gap-10'}>
            {searchEl}
            <UrlStateFormItem name={['filter', 'search_by']} className={'hidden md:flex'}>
              <Select
                data-cy={'search-by-sku'}
                showCopyText={false}
                allowClear={false}
                showSearch={false}
                className={'!w-[200px]'}
                defaultValue={'default'}
                options={[
                  { value: 'default', label: 'Default' },
                  { value: 'category', label: 'Category' },
                  { value: 'subcategory', label: 'Subcategory' },
                  { value: 'configuration', label: 'Configuration' }
                ]}
              />
            </UrlStateFormItem>
            <UrlStateFormItem name={['filter', 'with_inactive']} valuePropName={'checked'}>
              <Checkbox defaultChecked={false}>Include Retired</Checkbox>
            </UrlStateFormItem>
          </div>
        )
      }}
      table={{
        queryApi: equipmentApi.list,
        queryParams: { order: 'sku', fields: EquipmentSchema },
        rowKey: 'id',
        fullHeight: true,
        columns: [
          { dataIndex: 'category__parent_category__name', title: 'Category', hidden: isMobile },
          { dataIndex: 'category__name', title: 'Subcategory', hidden: isMobile },
          { dataIndex: 'category__kind__name', title: 'Configuration', hidden: isMobile, render: getKindName },
          { dataIndex: 'sku', title: 'SKU', render: getSku },
          { dataIndex: 'description', title: 'Description' },
          { dataIndex: 'modified_at', title: 'Last Updated', hidden: isMobile, render: parseDate },
          { key: 'actions', fixed: 'right', width: 140, render: (row: Equipment) => <Actions equipment={row} /> }
        ]
      }}
    />
  )
}

const getKindName = (_: any, row: Equipment) =>
  row.category__kind__name || row.category__parent_category__kind__name || 'N/A'

const parseDate = (value: string) => dayjs.parse(value)?.formatLocal()

const getSku = (value: any, row: Equipment) => (
  <div>
    {row.inactive && <Tag color={'warning'}>Retired</Tag>}
    {value}
  </div>
)
