import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { moduleApi } from '@/services/api-service'
import { Spin } from '@/ui/spin'
import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Module } from '../module/types'
import { TicketListForSummaryWizard } from '../ticket/list/views/TicketListForSummaryWizard'
import { ResultSection } from './result-section'
import { SummarySettingsSection } from './summary-settings-section'
import { TicketsFilterSection } from './tickets-filter-section'

export const SummaryWizardView = view(Component, { title: () => 'Summary Wizard' })

function Component() {
  const [form] = Form.useForm()
  const { moduleId } = useParams()
  const moduleQuery = useQuery({ ...moduleApi.get<Module>(Number(moduleId)), enabled: !!moduleId })
  const [ticketsQueryVariables, setTicketsQueryVariables] = useState<Record<string, any>>({})
  const [selectedView, setSelectedView] = useState<any>()
  const ticketListRef = useRef<any>(null)
  const { setSelectedTickets } = ticketListRef.current || {}
  const [summaryTicket, setSummaryTicket] = useState<any>()

  if (moduleQuery.isInitialLoading) {
    return <Spin spinning isCentered />
  }

  const module = moduleQuery.data

  if (!module) {
    return null
  }

  return (
    <PageView header={{ title: module.title }}>
      <Form layout="vertical" form={form}>
        <Form.Item name="selected_tickets" hidden />
        <div className="flex gap-x-24 mt-16">
          <TicketsFilterSection
            module={module}
            selectedView={selectedView}
            onSelectedViewChange={(selectedView) => setSelectedView(selectedView)}
            onSearchQueryFiltersChange={(arrayQueryFilters, order) =>
              setTicketsQueryVariables((prev) => {
                const { Q, ...rest } = prev
                return {
                  ...rest,
                  ...(arrayQueryFilters.length ? { Q: arrayQueryFilters } : {}),
                  order
                }
              })
            }
          />
          <SummarySettingsSection
            module={module}
            onFinish={(summaryTicket) => {
              setSelectedTickets([])
              setSummaryTicket(summaryTicket)
            }}
          />
          <ResultSection key={summaryTicket?.id} summaryTicket={summaryTicket} />
        </div>
        <TicketListForSummaryWizard
          ref={ticketListRef}
          selectedView={selectedView}
          ticketsQueryParams={ticketsQueryVariables}
          onSelectedTicketsChange={(selectedTickets) => {
            form.setFieldsValue({ selected_tickets: selectedTickets })
          }}
        />
      </Form>
    </PageView>
  )
}
