import { InputNumber } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useEffect, useMemo, useRef } from 'react'
import { equipmentAtom } from '../atoms'

export const AmountField = () => {
  const isInitialRender = useRef(true)
  const setEquipment = useSetAtom(equipmentAtom)

  const unitsStandby =
    useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.units_standby), [])) || 0

  const standbyRate =
    useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.standby_rate), [])) || 0

  const unitsMin = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.units_min), [])) || 0
  const usageRate = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.usage_rate), [])) || 0
  const hands = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.hands), [])) || 1
  const unitsUsed = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.units_used), [])) || 0
  const discount = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.discount), [])) || 0
  const minimum = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.minimum), [])) || 0
  const amount = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.amount), [])) || 0

  useEffect(() => {
    // skip update on initial render
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    let newAmount = amount
    const newUnitsUsed = Math.max(unitsMin, unitsUsed)

    if (!(unitsStandby || standbyRate || unitsUsed || usageRate)) {
      setEquipment((prev) => ({
        ...prev,
        amount: amount
      }))
      return
    }

    newAmount = (1 - (discount || 0) / 100) * (unitsStandby * standbyRate + hands * newUnitsUsed * usageRate)

    if (minimum) {
      newAmount = Math.max(newAmount, minimum)
    }

    setEquipment((prev) => ({
      ...prev,
      amount: newAmount
    }))
  }, [discount, hands, minimum, standbyRate, unitsMin, unitsStandby, unitsUsed, usageRate, setEquipment, amount])

  return <InputNumber value={amount} className="w-full" data-cy="sku-amount" />
}
