export const DEFAULT_TIMEZONE = 'America/Denver'

export const DATE_FORMAT = 'MM/DD/YYYY'
export const TIME_FORMAT = 'hh:mm A z'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

export const MILITARY_TIME_FORMAT = 'HH:mm z'
export const MILITARY_DATE_TIME_FORMAT = `${DATE_FORMAT} ${MILITARY_TIME_FORMAT}`

export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const ISO_TIME_FORMAT = 'HH:mm:ss[Z]'
export const ISO_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT}T${ISO_TIME_FORMAT}`
export const ISO_TIME_NO_Z_FORMAT = 'HH:mm:ss'
export const ISO_DATE_TIME_NO_Z_FORMAT = `${ISO_DATE_FORMAT}T${ISO_TIME_NO_Z_FORMAT}`

export const TIMESTAMP_FORMAT = 'YYYY-MM-DD-HHmmss'

export const TIME = {
  W: 'Week',
  M: 'Month',
  Q: 'Quarter',
  Y: 'Year',
  A: 'All Time',
  B: 'Work week',
  T: 'Today (with Week Summary)',
  C: 'Custom'
} as const
