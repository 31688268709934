import { useModalState } from '@/hooks'
import { Button } from '@/ui'
import { useButtonContext } from '../../hooks'
import { FetchWellDataModal } from './fetch-well-data-modal'

export const FetchWellDataButton = () => {
  const { property, buttonProps } = useButtonContext()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <Button onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps}>
        {buttonProps?.children ? buttonProps.children : property.name}
      </Button>
      {isRender && (
        <FetchWellDataModal
          open={isOpen}
          width={'75%'}
          title={'Fetch Well Data'}
          iconName={buttonProps?.iconName}
          onCancel={closeModal}
          onOk={closeModal}
        />
      )}
    </>
  )
}
