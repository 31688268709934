import { useSession } from '@/hooks'
import { contactApi, officeApi } from '@/services/api-service'
import { QuerySelect } from '@/ui'
import { Form } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom } from '../../../atoms'

export const Defaults = () => {
  const setCustomForm = useSetAtom(customFormAtom)
  const { company } = useSession()

  const defaultCustomerOffice = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (v) => v.default_customer_office), [])
  )

  const defaultCustomerContactId = useAtomValue(
    useMemo(() => selectAtom(customFormAtom, (v) => v.default_customer_contact_id), [])
  )

  const handleOfficeChange = (value: number | null) => {
    setCustomForm((prev) => ({
      ...prev,
      default_customer_office: value ? { id: value } : null
    }))
  }

  const handleContactChange = (value?: number) => {
    setCustomForm((prev) => ({
      ...prev,
      default_customer_contact_id: (value as any) ?? null // TODO: Fix type
    }))
  }

  return (
    <div className="pt-4">
      <h5 className="font-bold mb-10">Defaults information</h5>
      <Form component={false} layout="vertical">
        <div className="flex gap-x-12">
          <Form.Item label="Default Customer Office" className="flex-1">
            <QuerySelect
              apiEndpoint={officeApi.list}
              apiSearchBy="name"
              apiValueBy="id"
              mapOption={['id', 'name']}
              apiQueryParams={{ compact: 1 }}
              onChange={handleOfficeChange}
              showCopyText={false}
              placeholder="Select Customer Office"
              value={defaultCustomerOffice?.id || undefined}
              data-cy="default-customer-office-select"
            />
          </Form.Item>
          <Form.Item label="Default Customer Contact" className="flex-1">
            <QuerySelect
              apiEndpoint={contactApi.list}
              apiSearchBy={(text: string | undefined) => (text ? { search: text } : {})}
              apiValueBy="id"
              apiQueryParams={{ compact: 1, company_id__eq: company?.id }}
              onChange={handleContactChange}
              showCopyText={false}
              placeholder="Select Customer Contact"
              renderOption={(c) => ({ value: c.id, label: `${c.id}:  ${c.first_name} ${c.last_name}` })}
              value={defaultCustomerContactId || undefined}
              data-cy="default-customer-contact-select"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
