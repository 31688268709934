import { useSession } from '@/hooks'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useFetchProfile } from '../../hooks'
import { selectedRoleIdAtom, selectedRolesAtom } from '../atoms'
import { PERMISSION_KEYS } from '../constant'
import { useAccountRoles } from '../hooks'
import ChangeRoleModal from './components/ChangeRoleModal'
import EnableRoleModal from './components/EnableRoleModal'
import { AccessListItemHeader } from './components/Header'
import { ToggleSwitch } from './components/ToggleSwitch'

const AccessPermissions = () => {
  const { user: authUser } = useSession()
  const [changeRoleModalState, setChangeRoleModalState] = useState(false)
  const [enableRoleModalState, setEnableRoleModalState] = useState(false)
  const selectedRole = useAtomValue(selectedRolesAtom)
  const setSelectedRole = useSetAtom(selectedRolesAtom)
  const [permissions, setPermissions] = useState<string[]>([])
  const [selectedData, setSelectedData] = useState<string>('')
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const { data: profileData } = useFetchProfile()
  const { data: accountRoleData } = useAccountRoles()

  const checkIfActive = (title: string) => {
    if (!permissions) return false
    if (permissions && permissions.length === 0) return false
    if (permissions && permissions.includes(title)) return true
  }

  const handleChange = ({ data, state }: { data: string; state: boolean }) => {
    setSelectedData(data)
    if (state) {
      if (!permissions.includes(data as string)) {
        const updatedRole = { ...selectedRole, data: { ...selectedRole?.data, enabled_groups: [...permissions, data] } }
        setSelectedRole(updatedRole)
      } else {
        const filteredPermission = permissions.filter((per) => per !== data)
        const updatedRole = { ...selectedRole, data: { ...selectedRole?.data, enabled_groups: filteredPermission } }
        setSelectedRole(updatedRole)
      }
    }
  }
  const handleOkClick = () => {
    setSelectedRoleId(0)
    if (selectedRole) {
      let enabledGroups = [] as string[]
      if (profileData?.userdata_json?.enabled_groups) {
        if (profileData?.userdata_json?.enabled_groups.includes(selectedData as string)) {
          const filteredPermissions = profileData?.userdata_json?.enabled_groups.filter(
            (group) => group !== selectedData
          )
          enabledGroups = filteredPermissions as string[]
        } else {
          enabledGroups = [...(profileData?.userdata_json?.enabled_groups as string[]), selectedData as string]
        }
      }
      setSelectedRole({
        ...selectedRole,
        id: 0,
        name: 'Custom Role (User Specific)',
        data: {
          enabled_groups: enabledGroups,
          tab_permissions: profileData?.userdata_json?.tab_permissions,
          enabled_modules: profileData?.userdata_json?.enabled_modules,
          enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
          advance_settings: profileData?.advance_settings
        }
      })
    }
    setChangeRoleModalState(false)
  }
  const handleRoleOkClick = () => {
    if (selectedRole && selectedRole.id !== 0) {
      const selectedRoleIndex = accountRoleData?.items.findIndex((role) => role.id === Number(selectedRole.id))
      if (selectedRoleIndex && selectedRoleIndex > -1) {
        const selected = accountRoleData?.items.filter((role) => role.id === Number(selectedRole.id))
        let enabledGroups = [] as string[]
        if (selected && selected?.length > 0 && selected[0]?.data.enabled_groups) {
          const hasSelectedData = selected[0]?.data.enabled_groups.includes(selectedData)
          if (hasSelectedData) {
            const filteredPermissions = selected[0]?.data.enabled_groups.filter((group) => group !== selectedData)
            enabledGroups = filteredPermissions as string[]
          } else {
            const selectedEnabledGroups = selected[0]?.data.enabled_groups as string[]
            enabledGroups = [...selectedEnabledGroups, selectedData]
          }

          const updatedData = {
            ...selected[0],
            data: {
              ...selected[0].data,
              enabled_groups: enabledGroups
            }
          }

          setSelectedRole({ ...selectedRole, ...updatedData })
        }
        const selectedRoleData = selected && selected[0] ? selected[0] : null
        setSelectedRole(selectedRoleData)
        setSelectedData('')
      }
    }
  }
  const handleCancelClick = () => {
    setChangeRoleModalState(false)
  }
  const handleRoleCancelClick = () => {
    setEnableRoleModalState(false)
  }

  const handleChangeRoleModalActiveState = (state: boolean) => {
    setChangeRoleModalState(state)
  }
  const handleEnableRoleModalActiveState = (state: boolean) => {
    setEnableRoleModalState(state)
  }

  useEffect(() => {
    if (selectedRole !== null && selectedRole.data && 'enabled_groups' in selectedRole.data) {
      if (selectedRole?.data.enabled_groups) {
        const groups = selectedRole?.data.enabled_groups as string[]
        setPermissions(groups)
      }
    }
  }, [selectedRole])

  const permissionsBasedOnRoles = useMemo(() => {
    if (authUser?.is_superuser) return PERMISSION_KEYS

    if (selectedRole?.id === 0) {
      const _permissions = Object.entries(PERMISSION_KEYS).filter((item) => {
        const itemValue: number[] | [] = item[1]
        if (itemValue && itemValue.length > 0 && itemValue.includes(0)) {
          return item
        }
      })
      return Object.fromEntries(_permissions)
    }
    return PERMISSION_KEYS
  }, [selectedRole])

  return (
    <div className="w-full lg:w-[33.333%]  xl:w-[20%]    mb-32">
      <AccessListItemHeader title="Permissions" />
      <div className="px-24   pt-32  border lg:border-0 lg:border-r  border-gray-200 h-full">
        {Object.entries(permissionsBasedOnRoles).map((list, i) => {
          const isChecked = checkIfActive(list[0])
          return (
            <div className="w-full mb-24" key={i}>
              <ToggleSwitch
                id={`permission-${i}`}
                data={list[0]}
                checked={isChecked ?? false}
                onChange={handleChange}
                onChangeRoleToggleModal={handleChangeRoleModalActiveState}
                onEnableRoleToggleModal={handleEnableRoleModalActiveState}
              />
            </div>
          )
        })}
      </div>
      <ChangeRoleModal
        active={changeRoleModalState}
        setActive={handleChangeRoleModalActiveState}
        onOk={handleOkClick}
        onCancel={handleCancelClick}
      />
      <EnableRoleModal
        active={enableRoleModalState}
        setActive={handleEnableRoleModalActiveState}
        onOk={handleRoleOkClick}
        onCancel={handleRoleCancelClick}
      />
    </div>
  )
}

export default AccessPermissions
