import { FC } from 'react'
import { useTicket } from '../../../../../hooks'
import { PropertyGroup } from '../../../../../schemas'
import { ItemsSectionTitle } from '../../../../line-items/components'

type Props = { propertyGroup: PropertyGroup }

export const SectionTitle: FC<Props> = ({ propertyGroup }) => {
  const { isNew } = useTicket()

  const title = (
    <h4 className={'!m-0 !p-0 !text-neutral'}>
      {propertyGroup.visible_name ? propertyGroup.name || 'Unnamed' : <span>&nbsp;</span>}
    </h4>
  )

  if (propertyGroup.line_item_placeholder) {
    return isNew ? null : <ItemsSectionTitle propertyGroup={propertyGroup}>{title}</ItemsSectionTitle>
  }

  return title
}
