import { userDataApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom } from '../atoms'
import { UserData, userDataSchema } from '../schema'
import { useCategoryKindQuery } from './use-category-kind-query'

export const useUserDataQuery = () => {
  const subCategory = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.category), []))
  const { categoryKindQuery } = useCategoryKindQuery(subCategory?.kind)

  const stringSources = Object.entries(categoryKindQuery.data?.data || {}).map(([key, value]) => ({
    value: value.stringSource,
    target: key
  }))

  const id__in = stringSources.map(({ value }) => value).join(',')

  const userDataQuery = useQuery({
    ...userDataApi.list<
      UserData & {
        target: string
      }
    >({ id__in }),
    select: (data) => ({
      ...data,
      items: data.items.map((item) => ({
        ...userDataSchema.parse(item),
        target: stringSources.find(({ value }) => value === item.id)?.target as string
      }))
    }),
    enabled: !!id__in
  })

  return userDataQuery
}
