import { navigate } from '@/routing/helpers'
import cn from 'classnames'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { ButtonProps } from '../button'
import { LinkButton } from '../link-button'

export type BackButtonProps = ButtonProps & {
  to?: To
}

export const BackButton: FC<BackButtonProps> = ({ to, className, ...props }) => {
  const handleClick = () => {
    if (to) navigate(to)
    else {
      const hasPrevPage = window.history.state?.idx > 0
      if (hasPrevPage) window.history.back()
      else navigate('..') // fall to React Router navigation
    }
  }

  return (
    <LinkButton
      className={cn('bg-transparent', className)}
      shape={'circle'}
      onClick={handleClick}
      iconName={'fa:arrow-left'}
      {...props}
    />
  )
}
