import { FormViewProps, ListViewProps, PageViewProps } from '@/layouts/views'

const SECRET_VIEW_HEADER: PageViewProps['header'] = {
  breadcrumbs: true
}

export const SECRET_LIST_VIEW_HEADER: ListViewProps['header'] = {
  ...SECRET_VIEW_HEADER,
  refresh: true
}

export const SECRET_FORM_VIEW_HEADER: FormViewProps['header'] = {
  ...SECRET_VIEW_HEADER,
  backButton: true,
  breadcrumbs: true
}
