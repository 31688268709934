import { companyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, Select } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { z } from 'zod'
import { customFormAtom } from '../../../../atoms'

// TODO: Make this a reusable schema
const companySchema = z.object({
  id: z.number(),
  name: z.string()
})

type Company = z.infer<typeof companySchema>

export const ContactSearchField = () => {
  const [searchText, setSearchText] = useState('')
  const contactSearch = useAtomValue(useMemo(() => selectAtom(customFormAtom, (v) => v._contact_search), []))
  const setCustomForm = useSetAtom(customFormAtom)

  const companyQuery = useQuery({
    ...companyApi.get<Company>(contactSearch?.company || 0),
    enabled: !!contactSearch?.company,
    select: (data) => companySchema.parse(data)
  })

  // TODO: Make this a reusable hook
  const companiesQuery = useQuery({
    ...companyApi.list({
      fields: 'id,name',
      search: searchText
    }),
    enabled: searchText.trim() !== '',
    select: (data) => ({
      ...data,
      items: data.items.map((item) => companySchema.parse(item))
    })
  })

  return (
    <div>
      <div>
        <Checkbox
          checked={!!contactSearch?.enabled}
          data-cy="custom-form-contact-search-checkbox"
          onChange={(e) =>
            setCustomForm((prev) => ({
              ...prev,
              _contact_search: {
                ...prev._contact_search,
                enabled: e.target.checked
              }
            }))
          }
        >
          Search additional companies when selecting a customer contact
        </Checkbox>
      </div>
      {contactSearch?.enabled && (
        <Form.Item extra="New contacts added from a ticket will use this company as a default." className="mb-0 mt-2">
          <Select
            data-cy="custom-form-contact-search-select"
            showSearch
            searchValue={searchText}
            onSearch={setSearchText}
            value={contactSearch?.company || undefined}
            filterOption={false}
            onChange={(value) =>
              setCustomForm((prev) => ({
                ...prev,
                _contact_search: {
                  ...prev._contact_search,
                  company: value
                }
              }))
            }
            options={
              searchText.trim()
                ? companiesQuery.data?.items.map((company) => ({
                    label: company.name,
                    value: company.id
                  }))
                : companyQuery.data
                  ? [
                      {
                        label: companyQuery.data?.name,
                        value: companyQuery.data?.id
                      }
                    ]
                  : []
            }
          />
        </Form.Item>
      )}
    </div>
  )
}
