import { useApp } from '@/hooks'
import { ticketApi } from '@/services/api-service'
import { Button } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { FC } from 'react'
import { useButtonContext } from '../../hooks'

export const TicketCopyButton: FC = () => {
  const { notification } = useApp()
  const { ticket, property, buttonProps, vars } = useButtonContext()
  const copyOptions = vars || {}

  const cloneTicketMutation = useMutation(ticketApi.clone())

  const handleClick = async () => {
    try {
      if (!ticket.id) return
      const response = await cloneTicketMutation.mutateAsync({
        id: ticket.id,
        ...{
          ...copyOptions,
          copies: copyOptions.copies || 1
        }
      })

      notification.success({
        message: vars.success_message || 'Ticket Copied',
        description: 'Created Tickets: ' + (response?.items || []).map((t: any) => t.name || '').join(', ')
      })
    } catch (e) {
      notification.error({ message: vars.fail_message || 'Failed to copy ticket' })
    }
  }

  return (
    <Button {...buttonProps} disabled={!ticket.id} loading={cloneTicketMutation.isLoading} onClick={handleClick}>
      {buttonProps.children ? buttonProps.children : property.name}
    </Button>
  )
}
