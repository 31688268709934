import { useApp } from '@/hooks'
import { AddEditOfficeForm } from '@/modules/rolodex/components/add-edit-office-form'
import { navigate } from '@/routing/helpers'
import { officeApi } from '@/services/api-service'
import { Office } from '@/types/office'
import { AppLink, Button, PageTitle } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Space } from 'antd'
import { useParams } from 'react-router-dom'

export const AddEditOfficeView = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const officeQuery = useQuery({ ...officeApi.get<Office>(Number(id)), enabled: !!id })
  const createOfficeMutation = useMutation(officeApi.create())
  const updateOfficeMutation = useMutation(officeApi.update())
  const { notification } = useApp()

  const handleSave = async () => {
    const values = await form.validateFields()
    const data = values[0]

    if (id) {
      await updateOfficeMutation.mutateAsync({ id, ...data })
    } else {
      await createOfficeMutation.mutateAsync(data)
    }

    notification.success({ message: 'Office saved' })
    navigate('/offices')
  }

  if (officeQuery.isInitialLoading) {
    return <Spin isCentered spinning />
  }

  const office = officeQuery.data

  return (
    <Form layout="vertical" initialValues={[{ ...office, company: (office?.company as any)?.id }]} form={form}>
      <div className="flex justify-between items-center mb-16">
        <PageTitle>Office</PageTitle>
        <Space>
          <AppLink to="/offices">
            <Button iconName="fa:close">Cancel</Button>
          </AppLink>
          <Button type="success" iconName="fa:save" onClick={handleSave}>
            Save
          </Button>
        </Space>
      </div>
      <AddEditOfficeForm field={{ key: 0, name: 0 }} office={office} />
    </Form>
  )
}
