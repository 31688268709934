import { safeJsonParse } from '@/utils'
import { Table } from 'antd'
import { head, slice } from 'lodash'
import { FC, useMemo } from 'react'

type Props = {
  value?: string
}

export const JsonTable: FC<Props> = ({ value }) => {
  const { columns, items } = useMemo(() => {
    const data = safeJsonParse(value) || []
    const columns: string[] = head(data) || []
    const items: string[][] = slice(data, 1) || []
    return { columns, items }
  }, [value])

  return (
    <Table
      columns={columns.map((c, idx) => ({ title: c, dataIndex: idx, key: c }))}
      dataSource={items.map((item, index) => ({ key: index, ...item }))}
      pagination={false}
    />
  )
}
