import { z } from 'zod'
import { CustomFormPermissionSchema } from './custom-form'

// schemas
export const TicketAlertSchema = z.object({
  id: z.number(),
  alert__id: z.number(),
  alert__name: z.string(),
  alert__alert_type: z.string()
})
export const TicketAttachmentSchema = z.object({
  id: z.number().nullable().optional(),
  uuid: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
  original: z.string().nullable().optional(),
  crop_json: z.any().nullable().optional(),
  created_at: z.string().nullable().optional(),

  _propertyId: z.number().nullable(),
  _fileName: z.string()
})
export const TicketContactSchema = z.object({
  id: z.string(),
  contact__id: z.number(),
  contact__first_name: z.string(),
  contact__last_name: z.string(),
  contact__title: z.string().nullable(),
  contact__image: z.string().nullable()
})
export const TicketNoteSchema = z.object({
  id: z.number(),
  notes: z.string(),
  contact__first_name: z.string(),
  contact__last_name: z.string(),
  created_at: z.string(),
  modified_at: z.string()
})
export const TicketOfficeSchema = z.object({
  id: z.number(),
  company__name: z.string().nullish(),
  name: z.string().nullish(),
  phone_number: z.string().nullish(),
  address__line1: z.string().nullish(),
  address__city: z.string().nullish(),
  address__state: z.string().nullish(),
  address__zipcode: z.string().nullish()
})
export const TicketSignatureSchema = z.object({
  id: z.number().nullable().optional(),
  uuid: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  style: z.string().nullable().optional(),
  color: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
  created_at: z.string().nullable().optional(),

  _propertyId: z.number().nullable().optional(),
  _title: z.string().nullable().optional()
})
export const TicketPropertySchema = z.object({
  id: z.number(),
  ticket_id: z.number(),
  property_id: z.number(),
  property__key: z.string().nullish(),
  value: z.string().nullable()
})

export const TicketSubscriberSchema = z.object({
  modified_at: z.string().nullish(),
  status: z.enum(['I', 'A', 'R', 'D']),
  id: z.number(),
  contact__last_name: z.string(),
  contact__id: z.number(),
  contact__first_name: z.string(),
  contact__company__id: z.string(),
  contact__company__name: z.string(),
  notes: z.string().nullish()
})

export const TicketSchema = z.object({
  id: z.number(),

  created_at: z.string().nullish(),
  modified_at: z.string().nullish(),

  parent__id: z.number().nullish(),
  parent__name: z.string().nullish(),
  parent__custom_form_id: z.string().nullish(),
  parent__custom_form__name: z.string().nullish(),

  custom_form_id: z.number(),

  name: z.number().nullable(),
  status: z.string().nullable(),
  computed_status: z.string().nullable(),

  timepoint_due: z.string().nullish(),
  timepoint_submitted: z.string().nullish(),

  customer_office_id: z.number().nullish(),
  customer_office__name: z.string().nullish(),
  customer_office__company_id: z.number().nullish(),

  customer_id: z.number().nullish(),
  customer__first_name: z.string().nullish(),
  customer__last_name: z.string().nullish(),
  customer__company_id: z.number().nullish(),

  location_id: z.number().nullish(),
  location__name: z.string().nullish(),

  afe_id: z.number().nullish(),
  afe__user_afe_no: z.string().nullish(),

  job_code_id: z.number().nullish(),
  job_code__user_job_code_no: z.string().nullish(),

  support_office_id: z.number().nullish(),
  support_office__name: z.string().nullish(),

  assignee_id: z.number().nullish(),
  assignee__first_name: z.string().nullish(),
  assignee__last_name: z.string().nullish(),

  office_id: z.number().nullish(),
  office__company_id: z.number().nullish(),

  alerts: z.array(TicketAlertSchema),
  attachments: z.array(TicketAttachmentSchema),
  contacts: z.array(TicketContactSchema),
  notes: z.array(TicketNoteSchema),
  signatures: z.array(TicketSignatureSchema),
  properties: z.array(TicketPropertySchema),
  subscribers: z.array(TicketSubscriberSchema),

  extra_data__tab_indicator_color: z.string().nullish(),

  data_json: z.string().nullish(),
  _data: z
    .object({
      // ticket specific property names
      prop_names: z.record(z.number(), z.string()).nullish(),

      // secure access
      e_sign_emailed_at: z.string().nullish(),
      vendor_access_emailed_at: z.string().nullish(),

      // summary ticket
      summary_attachment_id: z.number().nullish(),
      summary_config: z
        .object({
          task_id: z.string(),

          // ...

          build_source: z.discriminatedUnion('type', [
            z.object({
              type: z.literal('summary_wizard_module'),
              module_id: z.number()
            }),
            z.object({
              type: z.literal('scripted_button'),
              property_id: z.number()
            })
          ]),
          selected_tickets: z.array(z.number()).nullish()
        })
        .nullish()
    })
    .nullish(),

  _isNew: z.boolean().nullish(),
  _tpByPropertyId: z.record(z.number(), TicketPropertySchema),
  _tpByPropertyKey: z.record(z.string(), TicketPropertySchema),
  _is_customer: z.boolean().nullish(),
  _is_staff: z.boolean().nullish(),
  _access: CustomFormPermissionSchema,
  _viewOnly: z.boolean().optional(),
  _editRestrictedMsg: z.string().nullish()
})

export const CustomFormNextSequenceSchema = z.object({
  id: z.number(),
  ticket_gen_number: z.number(),
  use_form_sequence: z.boolean(),
  primary_company__ticket_gen_number: z.number()
})

// types
export type TicketAlert = z.infer<typeof TicketAlertSchema>
export type TicketAttachment = z.infer<typeof TicketAttachmentSchema>
export type TicketContact = z.infer<typeof TicketContactSchema>
export type TicketNote = z.infer<typeof TicketNoteSchema>
export type TicketOffice = z.infer<typeof TicketOfficeSchema>
export type TicketSignature = z.infer<typeof TicketSignatureSchema>
export type TicketProperty = z.infer<typeof TicketPropertySchema>
export type TicketSubscriber = z.infer<typeof TicketSubscriberSchema>
export type Ticket = z.infer<typeof TicketSchema>
