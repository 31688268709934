import { filterSelectOption } from '@/utils/filter-select-option'
import { Form, Select, Typography } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom, requiredFieldsErrorsAtom, setSubCategoryAtom } from '../atoms'
import { useCategoriesQuery } from '../hooks'

const { Text } = Typography

export const SubCategorySelect = () => {
  const category = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (e) => e.category?.parent_category), []))
  const subCategory = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (e) => e.category), []))
  const setSubCategory = useSetAtom(setSubCategoryAtom)
  const { isSubcategoryError } = useAtomValue(requiredFieldsErrorsAtom)
  const setRequiredFieldsErrors = useSetAtom(requiredFieldsErrorsAtom)
  const { categoriesQuery } = useCategoriesQuery()

  const options = useMemo(() => {
    if (!category?.id) {
      return []
    }

    const filteredCategories =
      categoriesQuery.data?.items.filter(({ parent_category }) => parent_category?.id === category.id) ?? []

    const subCategories = filteredCategories.map((category) => ({
      label: category.name,
      value: category.id
    }))

    return [...subCategories.sort((a, b) => a.label.localeCompare(b.label, 'en'))]
  }, [category?.id, categoriesQuery.data?.items])

  return (
    <Form.Item
      label="Sub Category"
      className="w-full mb-0"
      required
      validateStatus={isSubcategoryError ? 'error' : undefined}
    >
      <Select
        style={{
          width: '100%'
        }}
        loading={categoriesQuery.isLoading}
        value={subCategory?.id === 0 ? undefined : subCategory?.id}
        options={options}
        showSearch
        data-cy="sku-sub-category-select"
        filterOption={filterSelectOption}
        onChange={(value) => {
          const subCategory = categoriesQuery.data?.items.find((category) => category.id === value)
          const parentCategory = categoriesQuery.data?.items.find(({ id }) => category?.id === id)

          if (!subCategory) {
            return
          }

          setSubCategory({
            ...subCategory,
            parent_category: parentCategory
          })

          if (isSubcategoryError) {
            setRequiredFieldsErrors((prev) => ({
              ...prev,
              isSubcategoryError: false
            }))
          }
        }}
      />
      {isSubcategoryError && <Text type="danger">Please select sub category</Text>}
    </Form.Item>
  )
}
