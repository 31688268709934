import { TipButton, TipButtonProps } from '@/ui/button'
import { Popconfirm } from '@/ui/popconfirm'
import { PopconfirmProps } from 'antd'
import { FC } from 'react'

export type ActionButtonProps = TipButtonProps & {
  confirm?: PopconfirmProps
}

export const ActionButton: FC<ActionButtonProps> = ({ confirm, ...buttonProps }) => {
  const button = <TipButton shape={'circle'} type={'text'} {...buttonProps} />

  if (confirm) {
    return (
      <Popconfirm className={'inline-flex'} {...confirm}>
        {button}
      </Popconfirm>
    )
  }

  return button
}
