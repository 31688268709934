import { Icon } from '@/ui/icons'
import { Company } from '../../../../types'

type Props = {
  company: Company
}

export const CompanyName = ({ company }: Props) => {
  return (
    <div className="flex items-center">
      <div className="w-40 h-40">
        {company.icon ? (
          <img src={company.icon} alt="company logo" className="w-full h-full object-contain" />
        ) : (
          <div className="w-full h-full bg-gray-200 rounded flex items-center justify-center">
            <Icon name="mi:image" className="text-gray-400" />
          </div>
        )}
      </div>
      <div className="ml-8">{company.name}</div>
    </div>
  )
}
