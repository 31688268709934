import { RefObject, useLayoutEffect, useState } from 'react'

export const useRemainingDimensions = (ref: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<[number, number]>([0, 0])

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    const updateDimensions = () => {
      const boundingRect = element.getBoundingClientRect()
      const rHeight = window.innerHeight - boundingRect.top
      const rWidth = window.innerWidth - boundingRect.left

      setDimensions([rHeight, rWidth])
    }

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions()
    })

    resizeObserver.observe(element)

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [ref])

  return {
    isReady: dimensions[0] > 0 && dimensions[1] > 0,
    height: dimensions[0],
    width: dimensions[1]
  }
}
