import { Button, Checkbox } from '@/ui'
import { Form } from 'antd'
import { FC } from 'react'
import { LineItem, LineItemComponent } from '../../../../../schemas'
import { useComponentKind, useEditingComponents } from '../hooks'
import { AddButton } from './add-button'
import { SaveButton } from './save-button'

type Props = { item: Partial<LineItem>; components: LineItemComponent[] }

export const TableFoot: FC<Props> = ({ item, components }) => {
  const componentKind = useComponentKind(components[0] || item)

  const { closeEdit } = useEditingComponents()

  return (
    <tfoot className={'border-t border-gray-100'}>
      <tr>
        <td colSpan={componentKind.visibleFields.length + 1}>
          <div className={'flex flex-row items-center justify-between gap-10 px-10 py-4'}>
            <div className={'flex flex-row items-center gap-10'}>
              <AddButton item={item} components={components} />
              {/* todo: copy sub-components button */}
              {/*<Button size={'small'} iconName={'fa:clone'} shape={'round'}>
                Copy
              </Button>*/}
              <div>
                <Form.Item
                  className={'mb-0'}
                  name={['lineItems', item.id?.toString(), 'showComponents'] as any}
                  valuePropName={'checked'}
                >
                  <Checkbox size={'small'}>Display On Ticket</Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className={'flex flex-row gap-10 sticky right-10'}>
              <Button size={'small'} shape={'round'} onClick={() => closeEdit(item.id as number)}>
                Cancel
              </Button>
              <SaveButton item={item} components={components} />
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  )
}
