import { Icon } from '@/ui/icons'
import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  name: string
  order: string
  onChange: (key: string) => void
}

/**
 *
 * Shows arrow up or down depending on the sort order.
 * If the column is sortable but not being sorted, shows arrow up on hover to indicate it can be sorted
 * todo: Convert to generic component for typing name and onChange
 */
export const SortableColumnTitle = ({ children, name, onChange, order }: Props) => {
  const handleChange = () => {
    onChange(order === name ? `-${name}` : name)
  }

  return (
    <div className="flex select-none cursor-pointer group w-full" onClick={handleChange}>
      <span>{children}</span>
      <Icon
        name={order.startsWith('-') ? 'mi:arrow_downward_alt' : 'mi:arrow_upward_alt'}
        className={classNames('ml-2', {
          hidden: order !== name && order !== `-${name}`
        })}
      />
      <Icon
        name="mi:arrow_upward_alt"
        className={classNames('ml-2 opacity-0 group-hover:opacity-100', {
          hidden: order === name || order === `-${name}`
        })}
      />
    </div>
  )
}
