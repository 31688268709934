import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'

type Props = {
  label: string
  order: 'asc' | 'desc'
  onClick?: () => void
}

export const CalendarTitleHeadCell = ({ label, order, onClick }: Props) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="cursor-pointer flex gap-x-6" onClick={onClick}>
        <span>{label}</span>
        {order === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
      </div>
    </div>
  )
}
