import { LINE_ITEM_FIELDS } from '@/constants/general'
import { LocalDateTimePicker } from '@/ui/date'
import { Input, InputNumber, Select } from 'antd'
import { useSetAtom } from 'jotai'
import { equipmentAtom } from '../../../atoms'
import { Component } from '../../types'

type FieldItem = (typeof LINE_ITEM_FIELDS)[number]

type Props = {
  fieldItem: FieldItem
  component: Component
}

const disabledFields: FieldItem['field'][] = ['discountable_amount', 'taxable_amount']

export const DynamicField = ({ fieldItem, component }: Props) => {
  const setEquipment = useSetAtom(equipmentAtom)
  const { data } = component ?? {}
  const value = data?.[fieldItem.field]

  const handleChange = (value: string | number | null) => {
    setEquipment((prev) => ({
      ...prev,
      components: prev.components?.map((c) => {
        if (c.id === component.id) {
          return {
            ...c,
            data: {
              ...c.data,
              [fieldItem.field]: value
            }
          }
        }

        return c
      })
    }))
  }

  const componentName = fieldItem.component
  const disabled = disabledFields.includes(fieldItem.field)
  const options = 'options' in fieldItem && fieldItem.options ? fieldItem.options : []

  if (componentName === 'DatePicker') {
    return (
      <LocalDateTimePicker
        value={value}
        showNow={false}
        className="w-full"
        disabled={disabled}
        onOk={(date) => handleChange(date ? date.toISOString() : null)}
      />
    )
  }

  if (componentName === 'Input') {
    return <Input value={value} disabled={disabled} onChange={(e) => handleChange(e.target.value)} />
  }

  if (componentName === 'NumberInput') {
    return (
      <InputNumber
        value={value}
        className="w-full"
        disabled={disabled}
        onChange={(value) => handleChange(value)}
        type="number"
      />
    )
  }

  if (componentName === 'TextArea') {
    return <Input.TextArea value={value} disabled={disabled} onChange={(e) => handleChange(e.target.value)} rows={1} />
  }

  if (componentName === 'Select') {
    return (
      <Select
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={options}
        value={value}
        onChange={(value) => handleChange(value)}
        disabled={disabled}
      />
    )
  }

  return null
}
