import { useApp } from '@/hooks'
import { notificationTemplateApi } from '@/services/api-service'
import { Modal } from '@/ui'
import { Spin } from '@/ui/spin'
import { cn } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Editor } from '@tinymce/tinymce-react'
import { Form } from 'antd'
import DOMPurify from 'dompurify'
import { useMemo, useState } from 'react'

export const ContentEditor = () => {
  const type = Form.useWatch('type')
  const form = Form.useFormInstance()
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)
  const [preview, setPreview] = useState<any | null>(null)
  const [isPreview, setIsPreview] = useState(false)
  const previewMutation = useMutation(notificationTemplateApi.create({}, 'preview'))
  const { notification } = useApp()

  const handlePreview = async () => {
    try {
      setIsPreviewLoading(true)
      setIsPreview(true)

      const preview = await previewMutation.mutateAsync({
        ticket_id: form.getFieldValue('preview_ticket'),
        content: form.getFieldValue('content'),
        title: form.getFieldValue('title'),
        type: form.getFieldValue('type'),
        content_base: form.getFieldValue('content_base')
      })

      setIsPreviewLoading(false)
      setPreview(preview)
    } catch (error) {
      notification.error({ message: 'Failed to get preview' })
      setIsPreviewLoading(false)
    }
  }

  const toolbar = useMemo(
    () =>
      type === 'S'
        ? 'undo redo preview'
        : 'blocks undo redo | styleselect | bold italic | alignleft aligncenter alignright | code | preview',
    [type]
  )

  return (
    <div className="col-span-2">
      <h5>Editor</h5>
      <Form.Item hidden name="content" />
      <Spin spinning={!isEditorReady}>
        <div className={cn(isEditorReady ? 'block' : 'hidden')}>
          <Editor
            key={type}
            apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
            plugins={['preview', 'code']}
            init={{
              menubar: type === 'S' ? false : true,
              newline_behavior: 'linebreak'
            }}
            initialValue={
              type === 'S'
                ? DOMPurify.sanitize(form.getFieldValue('content'), {
                    ALLOWED_TAGS: ['br'],
                    ALLOWED_ATTR: []
                  })
                : form.getFieldValue('content')
            }
            onInit={(_, editor) => {
              setIsEditorReady(true)
              editor.ui.registry.addButton('preview', {
                text: 'Preview',
                icon: 'preview',
                tooltip: 'Preview',
                onAction: () => {
                  handlePreview()
                }
              })
            }}
            onEditorChange={(_, editor) => {
              const content = editor.getContent()
              form.setFieldsValue({
                content
              })
            }}
            toolbar={toolbar}
          />
        </div>
      </Spin>
      {isPreview && (
        <Modal
          open={isPreview}
          footer={null}
          width={800}
          onCancel={() => {
            setIsPreview(false)
          }}
        >
          <div className="pt-16">
            {isPreviewLoading ? (
              <Spin isCentered spinning />
            ) : (
              <div>
                <h5>{preview?.title}</h5>
                <div dangerouslySetInnerHTML={{ __html: preview?.content || '' }} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}
