import { PROPERTY_TYPE as PT } from '@/modules/custom-form/constants'
import { Select } from '@/ui'
import { LocalDateTimePicker } from '@/ui/date'
import { Input, InputNumber } from 'antd'
import { ReactElement } from 'react'
import { Property } from '../../../schemas'
import { JsonTable, Label } from '../../ui'
import { AttachmentInput } from '../property-form-item/attachment'
import { MeasurementInput } from '../property-form-item/measurement'
import { PinInput } from '../property-form-item/pin'
import { SignatureInput } from '../property-form-item/signature'
import { FormButton } from './form-button'

export const InputRendererMap: Record<number, (p: Property, d: (property: Property) => boolean) => ReactElement> = {
  [PT.Attachment]: (p, d) => <AttachmentInput property={p} disabled={d(p)} />,
  [PT.Automation]: (p, d) => <Input disabled={d(p)} />,
  [PT.Date]: (p, d) => <LocalDateTimePicker showTime={false} disabled={d(p)} className={'w-full'} />,
  [PT.DateTime]: (p, d) => <LocalDateTimePicker disabled={d(p)} className={'w-full'} />,
  [PT.Decimal]: (p, d) => <InputNumber disabled={d(p)} className={'w-full'} />,
  [PT.DisplayImage]: (p, d) => <Input disabled />, // todo: DisplayImage unused?
  [PT.Dropdown]: (p, d) => <Select options={p._options} disabled={d(p)} showCopyText />,
  [PT.Email]: (p, d) => <Input type={'email'} disabled={d(p)} />,
  [PT.Image]: (p, d) => <AttachmentInput property={p} disabled={d(p)} />,
  [PT.Integer]: (p, d) => <InputNumber className={'w-full'} disabled={d(p)} />,
  [PT.Label]: (p, d) => <Label k={p._field} className={'flex items-center justify-center'} />,
  [PT.Measurement]: (p, d) => <MeasurementInput showQuarter disabled={d(p)} />,
  [PT.MeasurementFeetInch]: (p, d) => <MeasurementInput disabled={d(p)} />,
  [PT.MultilineString]: (p, d) => <Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} disabled={d(p)} />,
  [PT.MultivariateDropdown]: (p, d) => <Select mode={'multiple'} options={p._options} disabled={d(p)} showCopyText />,
  [PT.PhoneNumber]: (p, d) => <Input disabled={d(p)} />,
  [PT.Pin]: (p, d) => <PinInput disabled={d(p)} />,
  [PT.RecordSelect]: (p, d) => <Input disabled={d(p)} />, // todo: RecordSelect unused?
  [PT.Script]: (p, d) => <Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} disabled />,
  [PT.ScriptedButton]: (p, d) => <FormButton property={p} disabled={d(p)} />,
  [PT.Signature]: (p, d) => <SignatureInput property={p} disabled={d(p)} />,
  [PT.Spacer]: (p, d) => <div />,
  [PT.String]: (p, d) => <Input disabled={d(p)} />,
  [PT.Table]: (p, d) => <JsonTable />,
  [PT.Time]: (p, d) => <LocalDateTimePicker showDate={false} disabled={d(p)} />
}
