import { FIELD_LABELS } from '@/constants/labels'
import { useApp } from '@/hooks'
import { PageView } from '@/layouts/views'
import { view } from '@/routing'
import { programmedReportApi } from '@/services/api-service'
import { Button, QueryTable, SearchInput } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { CriteriaColumnProgrammedReport, EditAttributeModal } from '../../components'
import { AddEditReportCriteriaModal } from '../../components/add-edit-report-criteria-modal'
import { GeneratedReportsSection } from '../../components/generated-reports-section'
import { useUpdateFilterfieldMutation } from '../../hooks'
import { Attribute, FilterField, ProgrammedReport } from '../../schema'

export const ProgrammedReportsView = view(Component, {
  title: () => 'ProgrammedReports'
})

function Component() {
  const queryClient = useQueryClient()
  const { notification, l } = useApp()
  const [editingCriteria, setEditingCriteria] = useState<FilterField | null>(null)
  const [editingAttribute, setEditingAttribute] = useState<Attribute | null>(null)
  const [editingReport, setEditingReport] = useState<ProgrammedReport | null>(null)
  const generateReportMutation = useMutation(programmedReportApi.etlGenerate())
  const [search, setSearch] = useState('')

  const handleGenerateReport = async (id: number) => {
    await generateReportMutation.mutateAsync({ id })
    notification.success({ message: 'Programmed report is pending' })
  }

  const { handleSave } = useUpdateFilterfieldMutation({
    onSuccess: () => {
      notification.success({ message: 'Criteria successfully updated' })
      handleCloseModal()
      queryClient.refetchQueries(...(programmedReportApi.list().queryKey as any))
    }
  })

  const handleCloseModal = () => {
    setEditingCriteria(null)
    setEditingAttribute(null)
    setEditingReport(null)
  }

  return (
    <PageView header={{ title: l('ProgrammedReports'), accent: false, border: false }}>
      <div className="mb-24">
        <SearchInput onSearch={setSearch} />
        <QueryTable
          className="mt-16"
          queryApi={programmedReportApi.list}
          queryParams={{
            type__in: 'R,E',
            ...(search && { description: search.trim(), name: search.trim() })
          }}
          queryOptions={{
            select: (data) => ({
              ...data,
              items: data.items.map((item) => ({
                ...item,
                report: {
                  ...item.report,
                  filterfield_set: item.report?.filterfield_set?.map((filterField: any) => ({
                    ...filterField,
                    _name:
                      FIELD_LABELS[`report__${filterField.field_verbose}`] ||
                      FIELD_LABELS[`lineitem__${filterField.field_verbose}`]
                  }))
                },
                base_custom_report: {
                  ...item.base_custom_report,
                  filterfield_set: item.base_custom_report?.filterfield_set?.map((filterField: any) => ({
                    ...filterField,
                    _name:
                      FIELD_LABELS[`report__${filterField.field_verbose}`] ||
                      FIELD_LABELS[`lineitem__${filterField.field_verbose}`]
                  }))
                }
              }))
            })
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'report__name',
              width: 250,
              render: (_, pr) => pr.report?.name
            },
            {
              title: 'Description',
              dataIndex: 'report__description',
              width: 250,
              render: (_, pr) => pr.report?.description
            },
            {
              title: 'Criteria',
              width: 400,
              sorter: false,
              render: (_, pr) => (
                <CriteriaColumnProgrammedReport
                  pr={pr}
                  setEditingCriteria={setEditingCriteria}
                  setEditingAttribute={setEditingAttribute}
                  setEditingReport={setEditingReport}
                />
              )
            },
            {
              width: 50,
              align: 'right',
              render: (_, pr) =>
                pr.type === 'E' && (
                  <Tooltip title="Generate">
                    <Button
                      type="text"
                      iconName="fa:arrow-right-to-bracket"
                      onClick={() => handleGenerateReport(pr.id)}
                    />
                  </Tooltip>
                )
            }
          ]}
        />

        {editingCriteria && editingReport && (
          <AddEditReportCriteriaModal
            filterfield={editingCriteria}
            readonlyFilterField
            rootModelName={editingReport?.base_custom_report?.root_model_name || 'ticket'}
            onSave={(values) => handleSave(values)}
            onClose={() => {
              setEditingCriteria(null)
              setEditingReport(null)
            }}
          />
        )}
        {editingAttribute && editingReport && (
          <EditAttributeModal
            attribute={editingAttribute}
            programmedReport={editingReport}
            onCancel={handleCloseModal}
            onSaved={() => {}}
          />
        )}
      </div>
      <GeneratedReportsSection Q={['data__extra__schedule_id__isnull|true', 'data__handled_at__isnull|true', 'and']} />
    </PageView>
  )
}
