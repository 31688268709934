import { useApp } from '@/hooks'
import { navigate } from '@/routing/helpers'
import { moduleApi, userApi } from '@/services/api-service'
import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { useMutation } from '@tanstack/react-query'
import { Select, Spin } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { formTypeAtom, sharedDataAtom } from '../atom'
import RecentActivity from '../autofill/recentActivity/RecentActivity'
import { useFetchProfile } from '../hooks'
import { AccessList } from './accessList'
import { enabledModulesAtom, selectedRoleIdAtom, selectedRolesAtom } from './atoms'
import { PERMISSION_KEYS } from './constant'
import { useAccountRoles, useModules } from './hooks'

export const AccessRightsTab = () => {
  const { notification } = useApp()
  const formType = useAtomValue(formTypeAtom)
  const setSelectedRoleId = useSetAtom(selectedRoleIdAtom)
  const selectedRoleId = useAtomValue(selectedRoleIdAtom)
  const setSelectedRoles = useSetAtom(selectedRolesAtom)
  const selectedRole = useAtomValue(selectedRolesAtom)
  const sharedData = useAtomValue(sharedDataAtom)
  const selectedModules = useAtomValue(enabledModulesAtom)
  // Change to Customize Role Confirmation Modal
  const params = useParams()
  const [accountRoles, setAccountRoles] = useState<
    Array<{ label: string | null | undefined; value: number | null | undefined }> | []
  >([])
  const { data: accountRoleData, isLoading: accountRoleLoading } = useAccountRoles()
  const { data: profileData, refetch } = useFetchProfile()
  const { data: modulesData } = useModules()
  const mutationSpec = formType === 'add' ? userApi.create() : userApi.update()
  const updateModuleMutation = useMutation({ ...moduleApi.patch() })
  const saveFormMutation = useMutation({
    ...mutationSpec,
    onSuccess: async () => {
      notification.success({ message: `Access Rights ${formType === 'add' ? 'Added' : 'Updated'}` })
      // setCustomEnabledModules([])
      await refetch()
    },
    onError: (error: Error | any) => {
      if (error?.response?.data?.non_field_errors && error?.response?.data?.non_field_errors.length > 0) {
        notification.error({ message: error?.response?.data?.non_field_errors[0] })
      } else {
        notification.error({ message: `Access Rights failed to ${formType === 'add' ? 'Add' : 'Update'}` })
      }
      console.log(error)
    }
  })
  const [key, setKey] = useState(0)
  const handleChange = (value: number) => {
    console.log('VALUE', value, profileData)
    setSelectedRoleId(value)
    if (!accountRoleData) {
      return false
    }
    if (value === 0) {
      if (selectedRole) {
        setSelectedRoles({
          id: 0,
          name: 'Custom Role (User Specific)',
          data: {
            groups: profileData?.user?.groups,
            enabled_groups: profileData?.userdata_json?.enabled_groups,
            tab_permissions: profileData?.userdata_json?.tab_permissions,
            enabled_modules: profileData?.userdata_json?.enabled_modules,
            enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
            advance_settings: profileData?.advance_settings
          }
        })
      }
    }
    if (Number(value) !== 0 || !value) {
      const selectedRoleIndex = accountRoleData?.items.findIndex((role) => role.id === Number(value))
      if (selectedRoleIndex > -1) {
        const selected = accountRoleData?.items.filter((role) => role.id === Number(value))
        setSelectedRoles(selected[0])
      }
    }
  }

  const handleCancelClick = () => {
    navigate('/profiles')
  }
  const handleFormSubmit = () => {
    let permissions = {}
    Object.entries(PERMISSION_KEYS).map((pk) => {
      if (selectedRole?.data.enabled_groups?.includes(pk[0])) {
        permissions = { ...permissions, [pk[0]]: true }
      } else {
        permissions = { ...permissions, [pk[0]]: false }
      }
    })

    const hasDisabledTicketReservation = selectedRole?.data.groups?.includes('disable_ticket_reservation')
    let data = {
      ...sharedData,
      id: profileData?.user?.pk,
      profile_id: Number(params?.id),
      contact_id: profileData?.contact?.id,
      role_id: selectedRole?.id === 0 ? null : selectedRole?.id,
      phone_number: sharedData.phone_number
        ? `+1${sharedData?.phone_number?.replace(/\D/g, '')}`
        : sharedData?.phone_number,
      advance_settings_json: JSON.stringify(selectedRole?.data.advance_settings),
      userdata_json: {
        ...profileData?.userdata_json,
        ...sharedData?.userdata_json,
        enabled_category_tags: selectedRole?.data.enabled_category_tags,
        tab_permissions: selectedRole?.data?.tab_permissions,
        ticket_status_limit: selectedRole?.data?.ticket_status_limit,
        enabled_modules: selectedRole?.data?.enabled_modules,
        enabled_groups: selectedRole?.data?.enabled_groups,
        disabled_forms: selectedRole?.data?.disabled_forms
      },

      ...permissions
    }
    if (selectedRole?.id === 0) {
      data = {
        ...data,
        disable_ticket_reservation: hasDisabledTicketReservation
      }
    } else {
      delete data?.disable_ticket_reservation
    }
    if (selectedRole?.id !== 0) {
      data = {
        ...data,
        userdata_json: {
          ...data.userdata_json,
          enabled_modules: selectedRole?.data?.enabled_modules
        }
      }
    }

    if (selectedRole?.id === 0) {
      const profileContactId = profileData?.contact?.id
      const enabledModules = modulesData?.items?.filter((am) => selectedModules?.includes(am.id)) ?? []
      const disabledModules = modulesData?.items?.filter((am) => !selectedModules?.includes(am.id)) ?? []
      const selectedLists = enabledModules?.map((sm) => {
        const contacts = sm.contacts as number[]
        const arr = [...contacts, profileContactId]

        const updatedContacts = Array.from(new Set(arr))

        return {
          contacts_json: JSON.stringify(updatedContacts),
          id: sm.id
        }
      })
      const deselectedLists = disabledModules?.map((dm) => {
        const contacts = dm.contacts as number[]
        const arr = contacts?.filter((contact) => contact !== profileContactId) || []
        return {
          contacts_json: JSON.stringify(arr),
          id: dm.id
        }
      })

      selectedLists.map(async (li) => {
        await updateModuleMutation.mutateAsync(li)
      })
      deselectedLists.map(async (li) => {
        await updateModuleMutation.mutateAsync(li)
      })
    }
    saveFormMutation.mutateAsync(data)
  }
  useEffect(() => {
    const customRole = { label: 'Custom Role (User Specific)', value: 0 }
    if (accountRoleData && accountRoleData.items.length > 0) {
      const roles = accountRoleData.items.map((ar) => ({
        value: ar.id,
        label: ar.name
      }))

      setAccountRoles([customRole, ...roles])
    } else {
      setAccountRoles([customRole])
    }
    setKey((prev) => prev + 1)
  }, [accountRoleData])

  useEffect(() => {
    if (profileData) {
      const currentRole = profileData?.role ?? 0
      setSelectedRoleId(currentRole)
      if (profileData?.role === null) {
        const roles = {
          id: 0,
          name: 'Custom Role (User Specific)',
          data: {
            groups: profileData?.user?.groups,
            enabled_groups: profileData?.user?.groups,
            tab_permissions: profileData?.userdata_json?.tab_permissions,
            enabled_modules: profileData?.userdata_json?.enabled_modules,
            enabled_category_tags: profileData?.userdata_json?.enabled_category_tags,
            advance_settings: profileData?.advance_settings,
            disabled_forms: profileData?.userdata_json?.disabled_forms,
            ticket_status_limit: profileData?.userdata_json?.ticket_status_limit
          }
        }
        setSelectedRoles(roles)
      } else {
        if (accountRoleData) {
          const selectedRoleIndex = accountRoleData?.items.findIndex((role) => role.id === profileData?.role)
          if (selectedRoleIndex > -1) {
            const selected = accountRoleData?.items.filter((role) => role.id === profileData?.role)
            setSelectedRoles(selected[0])
          }
        }
      }
    }
  }, [profileData, accountRoleData])

  return (
    <Spin spinning={accountRoleLoading || updateModuleMutation.isLoading}>
      <div className="flex items-end justify-between bg-white">
        {/* <h4>Access Rights</h4> */}

        <div className="">
          <label className="block mb-2">Select a Role</label>

          <Select
            className="block w-[300px]"
            onChange={handleChange}
            loading={accountRoleLoading}
            value={selectedRoleId as any}
            options={accountRoles}
          >
            {accountRoles.map((role, i) => {
              return (
                <Select.Option key={i.toString()} value={role.value}>
                  {role.label}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <div className="flex gap-8">
          <Button type="default" className="group hover:!text-gray-500 hover:opacity-75" onClick={handleCancelClick}>
            <Icon name="svg:cancel" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" /> Cancel
          </Button>
          <Button
            type="success"
            onClick={handleFormSubmit}
            disabled={saveFormMutation.isLoading}
            loading={saveFormMutation.isLoading}
          >
            <Icon name="svg:save" className="max-w-[18px] !flex items-center mr-4 group-hover:opacity-75" />
            {formType === 'add' ? 'Save' : 'Update'}
          </Button>
        </div>
      </div>

      <div className="lg:w-full mx-auto py-10">
        <AccessList key={key} />
      </div>
      <RecentActivity />
    </Spin>
  )
}
