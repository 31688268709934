import { Button } from '@/ui/button'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Popconfirm, RowProps, Space } from 'antd'
import React from 'react'

type Props = {
  onDelete: (id: number) => void
  onEdit: (lineItem: any) => void
} & RowProps

export const SortableRow = ({ children, onDelete, onEdit, ...props }: Props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key' as keyof typeof props]
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  }

  //   const handleDelete = (component?: Component) => {
  //     if (!component) {
  //       console.warn('Component not found in sortable row', props['data-row-key' as keyof typeof props])
  //       return
  //     }

  //     setEquipment((prev) => ({
  //       ...prev,
  //       components: prev.components?.filter((c) => c.id !== component.id),
  //       components_order_json: JSON.stringify(componentsOrder.filter((id) => id !== component.sub_component?.id))
  //     }))
  //   }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes} data-cy="custom-form-line-item-row">
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          const lineItem = (child as Record<string, any>)?.props?.record
          const sku = lineItem?.sku

          return React.cloneElement(child as React.ReactElement, {
            children: (
              <Space>
                <Button
                  iconName="svg:edit"
                  type="text"
                  onClick={() => onEdit(lineItem)}
                  data-cy="custom-form-line-item-edit-button"
                />
                {/* <EditLineItemModal lineItem={lineItem} /> */}
                <Popconfirm
                  title="Delete Line Item?"
                  description={`Are you sure you want to remove ${sku}?`}
                  onConfirm={() => onDelete(lineItem.id)}
                  okButtonProps={{
                    'data-cy': 'custom-form-line-item-delete-button-confirm'
                  }}
                >
                  <Button iconName="svg:trash" type="text" data-cy="custom-form-line-item-delete-button" />
                </Popconfirm>
                <div ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners}>
                  <Button
                    iconName="mi:drag_indicator"
                    type="text"
                    className="cursor-move"
                    data-cy="custom-form-line-item-drag-button"
                  />
                </div>
              </Space>
            )
          })
        }
        return child
      })}
    </tr>
  )
}
