import { useViewData } from '@/layouts'
import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { useModuleLink } from '@/modules/module/hooks'
import { jasScheduleRuleApi, v2JasResourceApi } from '@/services/api-service'
import { FilePreview, LinkButton, QueryDeleteButton, QueryEditButton } from '@/ui'
import { generateAvatarInitials } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { Form, Popover, Tag, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { groupBy, isEmpty, uniq } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { z } from 'zod'
import { RuleFormModal, RuleFormModalProps } from '../../resource-manager/resources/views/form-view/components'
import { ResourceScheduleRuleSchema } from '../../resource-manager/resources/views/form-view/schemas'
import { useSkillsQuery } from '../hooks'
import { Resource, ScheduleViewData } from '../schemas'

type Props = {
  resource: Resource
  children?: ReactNode
}

export function ResourcePopover({ children, resource }: Props) {
  return (
    <Popover
      destroyTooltipOnHide={true}
      trigger={['click', 'contextMenu']}
      mouseEnterDelay={0.2}
      overlayStyle={{ width: 350 }}
      placement="right"
      rootClassName={'[&_.ant-popover-inner]:p-0'}
      content={() => <PopoverContent resource={resource} />}
    >
      {children}
    </Popover>
  )
}

function PopoverContent({ resource }: { resource: Resource }) {
  const { data } = useViewData<ScheduleViewData>()
  const { path: resourcePath } = data

  const { data: resourceInfo, isLoading } = useQuery(
    v2JasResourceApi.get<ResourceOther>(resource.id, undefined, {
      fields: ResourceOtherSchema
    })
  )

  const { getJobRoleName } = useJobRoles()

  const skillsQuery = useSkillsQuery({ skills: uniq(Object.values(resourceInfo?.attributes || {})) })
  const skills = skillsQuery.data?.items || []

  const [_skus, byRole] = useMemo(() => {
    const _skus = resourceInfo?.equipments?.filter((e) => e.user_text_7)
    const _byRole = groupBy(_skus, 'user_text_7')
    return [_skus, _byRole]
  }, [resourceInfo?.equipments])

  const scheduleRulesQuery = useQuery({
    ...jasScheduleRuleApi.list({
      resource_id__eq: resource.id,
      order: 'start_date',
      fields: ResourceScheduleRuleSchema,
      no_count: true,
      limit: 100
    })
  })
  const scheduleRules = scheduleRulesQuery.data?.items || []

  const { getLink } = useModuleLink()
  const editLink = getLink('type', 'RM', `/resources/${resourcePath}/${resource.id}/edit`)

  return (
    <div className="flex flex-col">
      <div className="p-8 flex flex-row align-middle justify-between border-b">
        <div className="flex flex-row items-center align-middle gap-10">
          <FilePreview className={'bg-primary-100 text-primary'}>
            {resource.short_code || generateAvatarInitials(resource.name)}
          </FilePreview>

          <div>
            <div className={'font-medium'}>{resource.name}</div>
            <div className={'inline text-xs'}>{resource.job_role__char_1 || 'Unknown'}</div>
          </div>
        </div>
        {editLink && (
          <LinkButton shape={'round'} size={'small'} to={editLink} iconName={'fa:edit'}>
            Edit
          </LinkButton>
        )}
      </div>

      <div className={'p-12 flex flex-col gap-6'}>
        <div>
          <div className={'font-medium'}>Skills:</div>
          <div>
            {skills.map((skill) => (
              <Tag className="mb-4" key={skill.id}>
                {skill.char_3}
              </Tag>
            ))}
            {skills.length === 0 && <small className={'text-text-muted'}>No Skills</small>}
          </div>
        </div>

        <div>
          <div className={'font-medium'}>Related SKUs:</div>
          <div>
            {Object.entries(byRole).map(([roleId, equipments]) => (
              <div key={roleId}>
                {equipments.map((e) => (
                  <div key={e.id} className="flex flex-row align-middle py-2">
                    <div>
                      <Tooltip title={getJobRoleName(Number(roleId))}>
                        <Tag className="m-0" color="default">
                          {getJobRoleName(Number(roleId))[0]}
                        </Tag>
                      </Tooltip>
                    </div>
                    <div className="px-4">
                      {e.sku} / {e.description}
                    </div>
                    <div>
                      <QueryEditButton to={`/records/skus/${e.id}/edit`} size="small" iconName={'fa:edit'} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {isEmpty(byRole) && <small className={'text-text-muted'}>No SKUs</small>}
          </div>
        </div>

        <div>
          <div className={'font-medium'}>Schedule Rules:</div>

          <table className={'w-full'}>
            {scheduleRules?.map((r) => (
              <tr key={r.id}>
                <td>
                  <span>{r.template__name}</span>
                </td>
                <td>
                  <span className={'text-xs text-text-muted'}>
                    {dayjs.parse(r.start_date)?.formatLocalDate() || 'N/A'}
                    <span> to </span>
                    {dayjs.parse(r.end_date)?.formatLocalDate() || 'N/A'}
                  </span>
                </td>
                <td>
                  <div className={'w-full flex flex-row justify-end'}>
                    <AssignRuleButton
                      resource={resource}
                      rule={r}
                      size={'small'}
                      iconName={'fa:edit'}
                      type={'text'}
                      shape={'circle'}
                    />
                    <QueryDeleteButton
                      size={'small'}
                      name={'Schedule Rule'}
                      api={jasScheduleRuleApi.delete}
                      id={r.id}
                    />
                  </div>
                </td>
              </tr>
            ))}

            {isEmpty(scheduleRules) && (
              <tr>
                <td colSpan={2}>
                  <small className={'text-text-muted'}>No Rules</small>
                </td>
              </tr>
            )}
          </table>
        </div>

        <div className={'flex justify-center'}>
          <AssignRuleButton resource={resource} size={'small'} iconName={'fa:plus'} shape={'round'}>
            Assign Rule
          </AssignRuleButton>
        </div>
      </div>
    </div>
  )
}

function AssignRuleButton({ resource, ...props }: Omit<RuleFormModalProps, 'resource'> & { resource: Resource }) {
  const [form] = Form.useForm()

  return (
    <Form form={form} initialValues={{ resource }}>
      <RuleFormModal {...props} />
    </Form>
  )
}

const ResourceOtherSchema = z.object({
  id: z.number(),
  equipments: z.array(
    z.object({
      id: z.number(),
      sku: z.string(),
      description: z.string(),
      user_text_7: z.string().describe('Job Role ID: Job Role Name')
    })
  ),
  attributes: z.record(z.string(), z.string())
})

type ResourceOther = z.infer<typeof ResourceOtherSchema>
