import { Space, Switch } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { FC, useMemo } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useSortableItemContext } from '../../../context'

export const IndentInlineComponents: FC = () => {
  const { lineItemField } = useSortableItemContext()

  const indentInlineComponentsFields = useAtomValue(
    useMemo(() => selectAtom(configurationAtom, (configuration) => configuration.indent_inline_components ?? []), [])
  )

  const setConfiguration = useSetAtom(configurationAtom)

  const isChecked = useMemo(
    () => indentInlineComponentsFields?.includes(lineItemField),
    [indentInlineComponentsFields, lineItemField]
  )

  const handleChange = (checked: boolean) => {
    setConfiguration((configuration) => ({
      ...configuration,
      indent_inline_components: checked
        ? [...indentInlineComponentsFields, lineItemField]
        : indentInlineComponentsFields?.filter((f) => f !== lineItemField)
    }))
  }
  return (
    <Space>
      <Switch checked={isChecked} onChange={handleChange} />
      <span>Indent Inline Components (Print)</span>
    </Space>
  )
}
