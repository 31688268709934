import { useCheckAccess } from '@/modules/auth/hooks'
import { StatusSelect } from '@/modules/ticket/components'
import { Alert, Checkbox, Form } from 'antd'
import { cloneDeep } from 'lodash'
import { FC, useState } from 'react'
import { useTicketModule } from '../../hooks'
import { useTicketBatchJob } from '../../hooks/use-ticket-batch-job'
import { ChangeStatusAction, TicketBatchActionType, TicketBatchJobFormType } from '../../types'
import { TicketBulkAction } from './TicketBulkAction'

const ACTION_LABELS: Record<TicketBatchActionType['action'], string> = {
  change_status: 'Change Status',
  property_scripts: 'Run Property Scripts',
  bpas: 'Run BPAs',
  line_item_scripts: 'Run Line Item Scripts',
  submit_to_accounting: 'Submit To Accounting'
}

const INITIAL_VALUES: TicketBatchJobFormType = {
  query: {},
  actions: [
    {
      action: 'change_status',
      new_status: 'C',
      _enable: false
    },
    {
      action: 'property_scripts',
      _enable: true
    },
    {
      action: 'bpas',
      _enable: true
    },
    {
      action: 'line_item_scripts',
      _enable: false
    }
  ]
}

export const TicketBatchJobButton: FC = () => {
  const allowed = useCheckAccess(['company_admin', 'support'])
  const [jobConf, setJobConf] = useState(INITIAL_VALUES)
  const { moduleFormId } = useTicketModule()
  const { submit, mutation } = useTicketBatchJob()

  const onActionChange = (idx: number, action: TicketBatchActionType) => {
    const _newJobConf = cloneDeep(jobConf)
    _newJobConf.actions[idx] = action
    setJobConf(_newJobConf)
  }
  const onActionEnableChange = (idx: number, val: boolean) =>
    onActionChange(idx, {
      ...jobConf.actions[idx],
      _enable: val
    })

  const onOk = async (closeFn: any) => submit(cloneDeep(jobConf), () => closeFn(true))

  if (!allowed) return <></>

  return (
    <TicketBulkAction
      button={{ iconName: 'fa:timeline-arrow', children: 'Batch Update' }}
      modal={{
        title: 'Batch Update',
        okText: 'Execute',
        onOk: onOk,
        okButtonProps: { loading: mutation.isLoading }
      }}
      optionsTitle="Actions"
    >
      <div>
        <Form>
          {jobConf.actions.map((actionConf, idx) => (
            <div key={actionConf.action}>
              <Form.Item>
                <Checkbox
                  disabled={['property_scripts', 'bpas'].includes(actionConf.action) && jobConf.actions[0]._enable}
                  checked={actionConf._enable}
                  onChange={(e) => onActionEnableChange(idx, e.target.checked)}
                >
                  {ACTION_LABELS[actionConf.action]}
                </Checkbox>
              </Form.Item>
              {actionConf.action === 'change_status' && actionConf._enable && (
                <Form.Item>
                  <StatusSelect
                    formId={moduleFormId}
                    onChange={(v) => onActionChange(idx, { ...actionConf, new_status: v } as ChangeStatusAction)}
                  />
                </Form.Item>
              )}
            </div>
          ))}
        </Form>
        <Alert
          className="p-12"
          showIcon={true}
          description={
            <p className="p-0 m-0">
              Proceed with caution!
              <br />
              Ensure filters are set correctly.
              <br />
              Changes applied cannot be reversed!
            </p>
          }
          type="warning"
        />
      </div>
    </TicketBulkAction>
  )
}
