import { customFormApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { CustomForm } from '../types'

export const useUploadCustomFormIcon = () => {
  const uploadCustomFormIconMutation = useMutation({
    ...customFormApi.update<CustomForm, any>({}, 'icon', undefined, {
      showAll: true
    })
  })

  const uploadCustomFormIcon = async (customFormId: number, file?: File | string) => {
    // If file is string, it means that the icon is not changed
    if (typeof file === 'string') {
      return
    }

    if (!file) {
      return uploadCustomFormIconMutation.mutateAsync({
        id: customFormId,
        icon: null
      })
    }

    const formData = new FormData()
    formData.append('icon', file)
    formData.append('id', customFormId.toString())
    return uploadCustomFormIconMutation.mutateAsync(formData)
  }

  return {
    uploadCustomFormIconMutation,
    uploadCustomFormIcon
  }
}
