import { useApp } from '@/hooks'
import { programmedReportApi, reportBuilderApi } from '@/services/api-service'
import { Modal } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { ProgrammedReport } from '../../schemas'

type Props = {
  programmedReport: ProgrammedReport
  onCancel: () => void
}

export const RenameReportModal = ({ programmedReport, onCancel }: Props) => {
  const [form] = Form.useForm()
  const updateReportNameMutation = useMutation(reportBuilderApi.patch())
  const { notification } = useApp()
  const queryClient = useQueryClient()
  const { report__id, report__description, report__name } = programmedReport

  const handleUpdateReportName = async () => {
    const values = await form.validateFields()
    await updateReportNameMutation.mutateAsync(values)
    notification.success({ message: 'Report renamed' })
    onCancel()
    queryClient.refetchQueries(...(programmedReportApi.list().queryKey as any[]))
  }

  return (
    <Modal open title={report__name} width={700} okText="Save" onCancel={onCancel} onOk={handleUpdateReportName}>
      <Form
        form={form}
        initialValues={{
          id: report__id,
          name: report__name,
          description: report__description
        }}
        layout="vertical"
      >
        <Form.Item name="id" hidden />
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
