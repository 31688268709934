import { Event } from '@/types/event'

type Props = {
  event: Event
  isCollapsed: boolean
}

export const ActivityContent = ({ event, isCollapsed }: Props) => {
  if (!isCollapsed) {
    return null
  }

  const { version_stats_app, version_stats_version, version_stats_platform, date, contact_company_name, target_text } =
    event

  const items = [
    {
      title: 'Date:',
      value: date
    },

    {
      title: 'Company:',
      value: contact_company_name
    },
    {
      title: 'Event Description:',
      value: target_text
    },
    {
      title: 'Platform / Version',
      value: version_stats_app + ' / ' + version_stats_version + ' / ' + version_stats_platform
    }
  ]

  return (
    <div className="flex flex-col gap-y-10 mt-16">
      {items.map((item, index) => (
        <div key={index} className="grid grid-cols-12">
          <div className="font-bold col-span-2">{item.title}</div>
          <div
            className="font-medium col-span-10"
            dangerouslySetInnerHTML={{
              __html: item.value || ''
            }}
          ></div>
        </div>
      ))}
    </div>
  )
}
