import { companyApi, officeApi } from '@/services/api-service'
import { Company } from '@/types/company'
import { useMutation } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { FormValues } from '../types'

export const useUpdateCompanyMutation = () => {
  const updateCompanyMutation = useMutation({ ...companyApi.patch() })
  const updateOfficeMutation = useMutation({ ...officeApi.update() })
  const createOfficeMutation = useMutation({ ...officeApi.create() })

  const updateCompany = async ({ offices, ...values }: FormValues, company: Company) => {
    const updatedOffices = offices.filter((office) => office.id)
    const createdOffices = offices.filter((office) => !office.id)
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]) => {
      if (value === null) return // skip, null value in multipart as it will be treated as string

      if (key === 'icon_path') {
        if (value && value.file) {
          formData.append('icon', value.file)
          formData.append('icon_name', value.file.name)
        } else if (value === 'remove') {
          formData.append('icon', '')
        }
        return
      }

      if (key === 'favicon_path') {
        if (value && value.file) {
          formData.append('favicon', value.file)
          formData.append('favicon_name', value.file.name)
        } else if (value === 'remove') {
          formData.append('favicon', '')
        }
        return
      }

      if (key === 'default_timezone' && (isEmpty(value) || value === 'null')) {
        return // skip, invalid value
      }

      formData.append(key, value)
    })

    return Promise.all([
      ...updatedOffices.map((office) =>
        updateOfficeMutation.mutateAsync({
          ...office,
          company: company.id
        })
      ),
      ...createdOffices.map((office) =>
        createOfficeMutation.mutateAsync({
          ...office,
          company: company.id
        })
      ),
      updateCompanyMutation.mutateAsync(formData)
    ])
  }

  return {
    updateCompanyMutation,
    updateCompany
  }
}
