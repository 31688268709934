import { TABLE_VIEW_TYPES } from '@/constants/general'
import { useSession } from '@/hooks'
import { SectionTitle } from '@/ui/section-title'
import { Form, Input, Modal, Select, Switch } from 'antd'
import { useAtom, useSetAtom } from 'jotai'
import { availableFieldsAtom, selectedFieldsAtom } from '../atoms'

export const CreateYourViewSection = () => {
  const form = Form.useFormInstance()
  const [availableFields, setAvailableFields] = useAtom(availableFieldsAtom)
  const setSelectedFields = useSetAtom(selectedFieldsAtom)
  const [modal, contextHolder] = Modal.useModal()
  const type = Form.useWatch('type', form)
  const { company } = useSession()

  return (
    <div>
      {contextHolder}
      <SectionTitle rounded number={1}>
        Create Your View
      </SectionTitle>
      <div className="flex gap-x-30 mt-12">
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          // getValueProps={(val) => console.log(val)}
          getValueFromEvent={(val) => {
            modal.confirm({
              title: 'Change View Type',
              content: 'Are you sure? This will reset filter and selected columns.',
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => {
                // reset columns
                setAvailableFields(availableFields)
                setSelectedFields([])

                form.setFieldsValue({
                  type: val,
                  filters: [] // reset filters
                })
              }
            })

            return type
          }}
        >
          <Select
            className="min-w-[200px]"
            options={Object.entries(TABLE_VIEW_TYPES).map(([value, label]) => ({ value, label }))}
          />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input />
        </Form.Item>
        <Form.Item
          label="Owner"
          name="company"
          getValueFromEvent={(val) => {
            if (val === 'company') {
              return company.id
            }
            return null
          }}
          getValueProps={(val) => {
            return {
              value: val ? 'company' : 'user'
            }
          }}
        >
          <Select
            placeholder="Select"
            popupMatchSelectWidth={false}
            options={[
              {
                label: 'User',
                value: 'user'
              },
              {
                label: 'Company',
                value: 'company'
              }
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Internal"
          name="internal"
          tooltip={
            'If enabled, this view will be hidden in Modules by default. ' +
            'You can manually unhide it in specific Module.'
          }
        >
          <Switch />
        </Form.Item>
      </div>
    </div>
  )
}
