import { Button, Icon } from '@/ui'
import { cn } from '@/utils'
import { FC, useEffect } from 'react'
import { useConditions, useConditionsHandlers } from '../../hooks'
import { BpaCondition } from '../../schemas'
import { ConditionsSortable } from './ConditionsSortable'

type Props = {
  ruleIndex?: number
  initialConditions?: BpaCondition[]
  onChange?: (conditions: BpaCondition[]) => void
}

export const ConditionsCard: FC<Props> = ({ ruleIndex, initialConditions, onChange }) => {
  const { onAddCondition } = useConditionsHandlers(ruleIndex)
  const { conditions } = useConditions(ruleIndex, initialConditions)

  useEffect(() => {
    onChange?.(conditions)
  }, [conditions])

  return (
    <div className={ruleIndex === undefined ? 'rounded border' : ''}>
      <div className="flex flex-row items-center border-b p-10">
        <div
          className={cn('flex-grow', {
            'text-16': ruleIndex === undefined
          })}
        >
          <Icon name={'fa:pipe-valve'} />
          <span className="mx-8">Conditions</span>
          <span className="text-text-muted text-10">({ruleIndex === undefined ? 'Global' : 'Local'})</span>
        </div>
        <div className="flex-grow" />
        <Button
          data-cy="add-condition-button"
          type="primary"
          size="small"
          iconName="fa:add"
          onClick={() => onAddCondition()}
        >
          Add Condition
        </Button>
      </div>
      <div className="flex flex-col p-10">
        <ConditionsSortable ruleIndex={ruleIndex} />
      </div>
    </div>
  )
}
