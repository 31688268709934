import { Form, Select } from 'antd'

import { useSetAtom } from 'jotai'
import { useDebouncedCallback } from 'use-debounce'
import {
  metafieldAfeAtom,
  metafieldCustomerContactAtom,
  metafieldJobCodeAtom,
  metafieldLocationAtom,
  metafieldOfficeAtom,
  selectedMetaAfeAtom,
  selectedMetaCustomerAtom,
  selectedMetaJobCodeAtom,
  selectedMetaLocationAtom,
  selectedMetaOfficerAtom
} from '../atoms'
import { useAfes, useContact, useJobCode, useLocation, useOffice } from '../hooks'

const MetaFields = () => {
  const location = useLocation()
  const afes = useAfes()
  const contacts = useContact()
  const jobCodes = useJobCode()
  const offices = useOffice()
  const setCustomerContact = useSetAtom(metafieldCustomerContactAtom)
  const setJobCode = useSetAtom(metafieldJobCodeAtom)
  const setOfficeSearchText = useSetAtom(metafieldOfficeAtom)
  const setLocationSearchText = useSetAtom(metafieldLocationAtom)
  const setAfeSearchText = useSetAtom(metafieldAfeAtom)

  const setSelectedOfficerId = useSetAtom(selectedMetaOfficerAtom)
  const setLocationId = useSetAtom(selectedMetaLocationAtom)
  const setJobCodeId = useSetAtom(selectedMetaJobCodeAtom)
  const setAfeId = useSetAtom(selectedMetaAfeAtom)
  const setCustomerId = useSetAtom(selectedMetaCustomerAtom)

  const locationOptions = location.data?.items.map((loc) => ({
    value: loc.id,
    label: loc.name
  }))
  const afeOptions = afes.data?.items.map((afe) => ({
    value: afe.id,
    label: afe.user_afe_no
  }))
  const contactOptions = contacts.data?.items.map((contact) => ({
    value: contact.id,
    label: `${contact.company__name}, ${contact.first_name} ${contact.last_name}`
  }))
  const jobCodeOptions = jobCodes.data?.items.map((job) => ({
    value: job.id,
    label: job.user_job_code_no
  }))
  const officeOptions = offices.data?.items.map((office) => ({
    value: office.id,
    label: office.name
  }))
  const debounceContactSearchText = useDebouncedCallback((value: string) => {
    setCustomerContact(value.trim())
  }, 500)
  const debounceJobCodeSearchText = useDebouncedCallback((value: string) => {
    setJobCode(value.trim())
  }, 500)

  const debounceOfficeSearchText = useDebouncedCallback((value: string) => {
    setOfficeSearchText(value.trim())
  }, 500)

  const debounceLocationSearchText = useDebouncedCallback((value: string) => {
    setLocationSearchText(value.trim())
  }, 500)

  const debounceAfeSearchText = useDebouncedCallback((value: string) => {
    setAfeSearchText(value.trim())
  }, 500)

  const onOfficeSelect = (value: number) => {
    setSelectedOfficerId(value)
  }
  const onLocationSelect = (value: number) => {
    setLocationId(value)
  }

  const onJobCodeSelect = (value: number) => {
    setJobCodeId(value)
  }
  const onAfeSelect = (value: number) => {
    setAfeId(value)
  }
  const onCustomerSelect = (value: number) => {
    setCustomerId(value)
  }

  return (
    <div>
      <h4 className="mb-16">Meta Fields</h4>
      <div className="flex flex-wrap -mx-16">
        <div className="w-4/12 px-16">
          <Form.Item label="Customer Office" name="customer_office">
            <Select
              placeholder="Select"
              showSearch
              onSearch={(e) => debounceOfficeSearchText(e)}
              loading={offices.isLoading || offices.isFetching}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              options={officeOptions}
              onSelect={onOfficeSelect}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-4/12 px-16">
          <Form.Item label="Customer Contact" name="customer">
            <Select
              placeholder="Select"
              showSearch
              onSearch={(e) => debounceContactSearchText(e)}
              loading={contacts.isLoading || contacts.isFetching}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              options={contactOptions}
              onSelect={onCustomerSelect}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-4/12 px-16">
          <Form.Item label="Location" name="location">
            <Select
              placeholder="Select"
              showSearch
              onSearch={(e) => debounceLocationSearchText(e)}
              loading={location.isLoading || location.isFetching}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              options={locationOptions}
              onSelect={onLocationSelect}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-4/12 px-16">
          <Form.Item label="AFE#" name="afe">
            <Select
              placeholder="Select"
              onSearch={(e) => debounceAfeSearchText(e)}
              showSearch
              loading={afes.isLoading || afes.isFetching}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              options={afeOptions}
              onSelect={onAfeSelect}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-4/12 px-16">
          <Form.Item label="Job Code" name="job_code">
            <Select
              placeholder="Select"
              showSearch
              onSearch={(e) => debounceJobCodeSearchText(e)}
              loading={jobCodes.isLoading || jobCodes.isFetching}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              options={jobCodeOptions}
              onSelect={onJobCodeSelect}
            ></Select>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default MetaFields
