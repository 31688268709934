import { useUrlState } from '@/hooks'
import { makeMap } from '@/utils'
import { get } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FILTER_KEY, FILTER_SEARCH_BY_KEY, FILTER_SEARCH_KEY } from './constants'
import { QueryField, QueryFieldOptionalKey, QueryFilterService } from './types'

export type UseQueryFilterServiceProps = {
  fields: QueryFieldOptionalKey[]
  search: boolean
  filter: boolean
}

export function useQueryFilterService({
  fields,
  search: searchEnabled,
  filter: filterEnabled
}: UseQueryFilterServiceProps): QueryFilterService {
  const [urlState, setUrlState] = useUrlState()

  const [filterFields, fieldsMap, fieldsOptions] = useMemo(() => {
    const _fields = (fields?.map((f) => ({ ...f, key: f.key || f.field })) || []) as QueryField[]
    const _fieldsMap = makeMap(_fields, 'key')
    const _fieldsOptions = _fields.map((f) => ({ label: f.label, value: f.field }))

    return [_fields, _fieldsMap, _fieldsOptions]
  }, [fields])

  const filter: QueryFilterService['filter'] = useMemo(() => {
    const _filter = {
      [FILTER_SEARCH_KEY]: '',
      [FILTER_SEARCH_BY_KEY]: '',
      query: false,
      f: [],
      q: {},
      ...urlState[FILTER_KEY]
    }
    if (!searchEnabled) {
      _filter.query = true

      // set default filter field if not set
      if (!_filter.f?.length) _filter.f = filterFields[0]?.key ? [filterFields[0]?.key] : []
    }

    return _filter
  }, [filterFields, searchEnabled, urlState])

  const selectedKeys: QueryFilterService['filter']['f'] = useMemo(() => filter.f || [], [filter.f])

  const selectedFields: QueryFilterService['selectedFields'] = useMemo(
    () =>
      selectedKeys
        .filter((key) => fieldsMap[key])
        .map((key) => ({ ...fieldsMap[key], value: filter.q?.[key] })) as QueryField[],
    [fieldsMap, filter.q, selectedKeys]
  )

  const showQueryFilter: QueryFilterService['showQueryFilter'] = Boolean(filter.query)

  const toggleQueryFilter: QueryFilterService['toggleQueryFilter'] = useCallback(() => {
    if (showQueryFilter) setUrlState({ filter: { query: null, f: null, q: null } })
    else setUrlState({ filter: { query: true } })
  }, [setUrlState, showQueryFilter])

  const setFilter = useCallback((filter: Record<string, any>) => setUrlState({ [FILTER_KEY]: filter }), [setUrlState])

  const addFilterField: QueryFilterService['addFilterField'] = useCallback(
    (fieldKey, value) =>
      setFilter({
        f: [...(selectedFields?.map((f) => f.key) || []), fieldKey],
        q: { ...filter.q, [fieldKey]: value }
      }),
    [filter.q, selectedFields, setFilter]
  )

  const removeFilterField: QueryFilterService['removeFilterField'] = useCallback(
    (fieldKey) =>
      setFilter({ f: selectedKeys.filter((key) => key !== fieldKey), q: { ...filter.q, [fieldKey]: null } }),
    [filter.q, selectedKeys, setFilter]
  )

  const getSearchData: QueryFilterService['getSearchData'] = useCallback(() => {
    if (!searchEnabled) return { searchValue: '', defaultFieldKey: null, selectedFieldKey: null, selectedField: null }

    const defaultFieldKey = filterFields[0]?.key || null
    const selectedFieldKey = get(filter, FILTER_SEARCH_BY_KEY, defaultFieldKey) || null
    const selectedField = fieldsMap[selectedFieldKey || ''] || filterFields[0] || null
    const searchValue = (filter?.[FILTER_SEARCH_KEY] || '').toString().trim()

    return { searchValue, defaultFieldKey, selectedFieldKey, selectedField }
  }, [searchEnabled, filterFields, filter, fieldsMap])

  const getFilterQuery: QueryFilterService['getFilterQuery'] = useCallback(() => {
    const query: string[] = []

    // apply search query
    const searchData = getSearchData()
    const searchField = searchData.selectedField
    const searchValue = searchData.searchValue
    if (searchField && searchValue) {
      query.push(`${searchField.field}__${searchField.lookup || 'icontains'}|${searchValue}`)
    }

    // apply filter query
    selectedFields
      .map((f) => ({ ...f, value: f.value?.toString().trim() }))
      .filter((f) => !(f.value === null || f.value === undefined || f.value === ''))
      .forEach((f, idx) => {
        const fieldQuery: string[] = []

        if (f.getQuery) fieldQuery.push(...(f.getQuery(f.value, f) || []))
        else fieldQuery.push(`${f.field}__${f.lookup || 'icontains'}|${f.value}`)

        if (fieldQuery.length) {
          query.push(...fieldQuery)

          // chain 'and_c' at last except the first
          // todo: allow to change the chain operator (and/or)
          if (idx > 0) query.push('and_c')
        }
      })

    return { 'Q[]': query }
  }, [getSearchData, selectedFields])

  return {
    state: urlState,
    setState: setUrlState,
    searchEnabled,
    filterEnabled,
    filterKey: FILTER_KEY,
    filterFields,
    fieldsMap,
    fieldsOptions,
    selectedKeys,
    selectedFields,
    showQueryFilter,
    toggleQueryFilter,
    filter,
    setFilter,
    addFilterField,
    removeFilterField,
    getSearchData,
    getFilterQuery
  }
}
