import { Button, Icon } from '@/ui'
import { FC } from 'react'
import { useVariablesHandlers } from '../../hooks'
import { VariablesSortable } from './VariablesSortable'

export const VariablesForm: FC = () => {
  const { onAddVariable } = useVariablesHandlers()

  return (
    <div className="rounded border">
      <div className="flex flex-row items-center border-b p-10">
        <div className="flex-grow text-16">
          <Icon name={'fa:value-absolute'} className="mr-2" />
          <span className="mx-6">Variables</span>
          <span className="text-text-muted text-10">(Global)</span>
        </div>
        <div className="flex-grow" />
        <Button
          data-cy="add-variable-button"
          type="primary"
          size="small"
          iconName="fa:add"
          onClick={() => onAddVariable()}
        >
          Add Variable
        </Button>
      </div>
      <div className="flex flex-col p-10">
        <VariablesSortable />
      </div>
    </div>
  )
}
