import { useApp, useAppDispatch, useSession } from '@/hooks'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { mobileHeaderElementAtom } from '@/layouts/dashboard-layout'
import { authApi, profileApi, userApi } from '@/services/api-service'
import { setAccessToken } from '@/services/auth-service'
import { clearSession } from '@/slices/session-slice'
import { Button } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Space, Upload } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { sharedDataAtom } from '../tabs-profile/atom'
import { Profile } from '../types'

export const MobileMyProfile = () => {
  const { refreshUserSession, startUserSession } = useBootstrap()
  const { user } = useSession()
  const { notification } = useApp()
  const [isSetNewPassword, setIsSetNewPassword] = useState(false)
  const setMobileHeaderElement = useSetAtom(mobileHeaderElementAtom)
  const profileQuery = useQuery(profileApi.get<Profile>(Number(user?.profile.id) || 0))
  const [isEdit, setIsEdit] = useState(false)
  const [newProfileImage, setNewProfileImage] = useState<File | null>(null)
  const newProfileImageUrl = newProfileImage ? URL.createObjectURL(newProfileImage) : null
  const updateProfileMutation = useMutation(userApi.patch())
  const loginMutation = useMutation(authApi.login())
  const dispatch = useAppDispatch()
  const sharedData = useAtomValue(sharedDataAtom)

  const handleSave = async ({ confirm_new_password, ...values }: any) => {
    const { contact, userdata_json, role } = user?.profile || ({} as any)

    await updateProfileMutation.mutateAsync({
      ...values,
      role_id: role,
      userdata_json: userdata_json,
      company_id: sharedData.company_id,
      profile_id: user?.profile.id,
      contact_id: contact?.id,
      id: user?.id
    })

    setIsEdit(false)
    setIsSetNewPassword(false)
    setNewProfileImage(null)

    if (confirm_new_password) {
      dispatch(clearSession())
      const { token } = await loginMutation.mutateAsync({ username: values.username, password: values.new_password })
      setAccessToken(`JWT ${token}`)
      startUserSession()
    }

    refreshUserSession()
    notification.success({ message: 'Profile updated successfully' })
  }

  useEffect(() => {
    setMobileHeaderElement(<div className="font-semibold">My Profile</div>)
    return () => setMobileHeaderElement(null)
  }, [])

  if (profileQuery.isLoading) {
    return <Spin spinning isCentered />
  }

  const initialValues = { ...profileQuery.data?.contact, username: profileQuery.data?.user?.username }
  const profileImageUrl = profileQuery.data?.contact?.image

  return (
    <Form size="large" layout="vertical" initialValues={initialValues} onFinish={(values) => handleSave(values)}>
      <div className="flex justify-end">
        <Form.Item>
          {isEdit ? (
            <Space>
              <Button
                iconName="fa:close"
                onClick={() => {
                  setIsEdit(false)
                  setIsSetNewPassword(false)
                  setNewProfileImage(null)
                }}
              >
                Cancel
              </Button>
              <Button type="success" iconName="fa:save" htmlType="submit">
                Save
              </Button>
            </Space>
          ) : (
            <Button iconName="fa:edit" onClick={() => setIsEdit(true)}>
              Edit
            </Button>
          )}
        </Form.Item>
      </div>
      {isEdit ? (
        <>
          <div className="flex flex-col items-center gap-12 justify-center mb-24">
            {newProfileImageUrl && (
              <img src={newProfileImageUrl} alt="avatar" className="w-60 h-60 rounded-full object-contain" />
            )}
            <Upload
              beforeUpload={(file) => {
                setNewProfileImage(file)
                return false
              }}
              showUploadList={false}
            >
              <div className="text-primary-500 text-16 cursor-pointer">
                {newProfileImageUrl ? 'Edit photo' : 'Add photo'}
              </div>
            </Upload>
          </div>
          {editableFields.map((field) => (
            <Form.Item key={field.name} label={field.label} name={field.name}>
              <Input />
            </Form.Item>
          ))}
        </>
      ) : (
        <>
          {(profileImageUrl || newProfileImageUrl) && (
            <div className="flex justify-center mb-24">
              <img
                src={newProfileImageUrl || profileImageUrl || undefined}
                alt="avatar"
                className="w-60 h-60 rounded-full object-contain"
              />
            </div>
          )}
          {editableFields.map((field) => (
            <Form.Item key={field.name} label={field.label}>
              <div className="text-16 font-medium">
                {initialValues[field.name as keyof typeof initialValues] || 'N/A'}
              </div>
            </Form.Item>
          ))}
        </>
      )}
      {isSetNewPassword && isEdit && (
        <>
          <Form.Item label="New Password" name="new_password" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Passwords do not match!'))
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {!isSetNewPassword && isEdit && (
        <Form.Item>
          <Button type="primary" ghost block onClick={() => setIsSetNewPassword(true)}>
            Set new password
          </Button>
        </Form.Item>
      )}
    </Form>
  )
}

const editableFields = [
  { label: 'First Name', name: 'first_name' },
  { label: 'Last Name', name: 'last_name' },
  { label: 'Title', name: 'title' },
  { label: 'Phone Number', name: 'phone_number' },
  { label: 'Email', name: 'email' },
  { label: 'Username', name: 'username' }
]
