import { accountRoleApi, categoryTagApi, customFormApi, moduleApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { useFetchProfile } from '../hooks'
import { changeRoleModalAtom, enableRoleModalAtom, selectedRoleIdAtom } from './atoms'
import {
  CategoryTag,
  CustomForm,
  Module,
  Role,
  categoryTagSchema,
  customFormSchema,
  moduleSchema,
  rolesSchema
} from './schemas'

export const useAccountRoles = () => {
  const { data: profileData } = useFetchProfile()
  return useQuery({
    ...accountRoleApi.list<Role>({ company_id__exact: profileData?.company }),
    select: (data) => ({
      ...data,
      items: data.items.map((role) => {
        return rolesSchema.parse(role)
      })
    }),
    enabled: !!profileData?.company
  })
}

export const useCustomForms = () => {
  const { data: profileData } = useFetchProfile()
  const Q = [`companies__id__exact|${profileData?.company}`, `primary_company_id__exact|${profileData?.company}`, 'or']
  return useQuery({
    ...customFormApi.list<CustomForm>({ Q, page: 1, show_all: 'True', with_disabled: true, limit: 'None' }),
    select: (data) => ({
      ...data,
      items: data.items.map((cf) => customFormSchema.parse(cf))
    }),
    enabled: !!profileData?.company
  })
}

export const useModules = () => {
  const { data: profileData } = useFetchProfile()

  return useQuery({
    ...moduleApi.list<Module>({
      // company_id__exact: profileData?.company,
      is_active__eq: 1
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((module) => moduleSchema.parse(module))
    }),
    enabled: !!profileData?.company
  })
}

export const useCategoryTag = () => {
  const { data: profileData } = useFetchProfile()

  return useQuery({
    ...categoryTagApi.list<CategoryTag>({
      company_id__exact: profileData?.company,
      limit: -1,
      order: 'name'
    }),
    select: (data) => ({
      ...data,
      items: data.items.map((module) => categoryTagSchema.parse(module))
    }),
    enabled: !!profileData?.company
  })
}

export const usePermissionChange = () => {
  const { data: profileData } = useFetchProfile()
  const selectedRoleId = useAtomValue(selectedRoleIdAtom)
  const currentRoleId = profileData?.role
  const enableRoleModal = useSetAtom(enableRoleModalAtom)
  const changeRoleModal = useSetAtom(changeRoleModalAtom)

  const checkPermission = () => {
    if (currentRoleId !== selectedRoleId && selectedRoleId !== 0) {
      enableRoleModal(true)
      // eslint-disable-next-line no-extra-boolean-cast
    } else if (!!selectedRoleId) {
      changeRoleModal(true)
    }
  }
  return { checkPermission }
}
