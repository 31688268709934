import { Button } from '@/ui/button'
import { Icon } from '@/ui/icons'
import { Dropdown } from 'antd'
import { useCallback } from 'react'
import { useAccounting } from '../../../../hooks'

export const AccountingButton = () => {
  const { enabled } = useAccounting()

  return enabled ? <SubmitToAccountingInner /> : null
}

const SubmitToAccountingInner = () => {
  const { submit, services } = useAccounting()

  const handleSubmit = useCallback(
    async (serviceKey: string) => {
      await submit(serviceKey)
    },
    [submit]
  )

  const servicesItems = services.map((service) => ({ ...service, onClick: () => handleSubmit(service.key) }))

  if (servicesItems.length === 1) {
    return (
      <Button type="primary" onClick={servicesItems[0].onClick}>
        Submit To Accounting
      </Button>
    )
  }

  return (
    <div>
      <Dropdown trigger={['click']} arrow menu={{ items: servicesItems }} placement="bottomRight">
        <div>
          <Button type={'primary'} iconName={'fa:dollar'}>
            <span className={'mr-8'}>Submit To Accounting</span>
            <Icon name={'fa:chevron-down'} />
          </Button>
        </div>
      </Dropdown>
    </div>
  )
}
