import { LocalDateTimePicker, LocalDateTimePickerProps } from '@/ui/date'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'timepoint_due'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<LocalDateTimePickerProps>
}

export const TimepointDueInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { customForm, isDisabledField, isHiddenField } = useCustomForm()

  if (isHiddenField(KEY) || !customForm.show_ticket_date) return <div></div>

  const required = customForm.require_ticket_date && !customForm.allow_empty_date_web

  return (
    <Form.Item
      name={KEY}
      label={<Label name={KEY} k={'ticket__timepoint_due__label'} />}
      required={required}
      {...formItemProps}
    >
      <LocalDateTimePicker showTime={false} className="w-full" disabled={isDisabledField(KEY)} {...inputProps} />
    </Form.Item>
  )
}
