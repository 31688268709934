import { Obj } from '@/types/general'
import { getLabel } from '@/utils'
import { App as AntApp } from 'antd'
import { useAppProps } from 'antd/es/app/context'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'
import { useAppSelector } from './use-app-selector'

export type TLabelFn = (key: string, defaultValue?: string, extra?: Obj) => string
export type TLabels = Record<string, string>

export type IAppProps = useAppProps & {
  labels: TLabels
  l: TLabelFn
}

/**
 * Global custom app hook for convenient and extensibility of Antd's App.useApp hook.
 */
export const useApp = (): IAppProps => {
  const { labels } = useAppSelector((state) => state.app)

  const _getLabel = useCallback(
    (key: string, defaultValue?: string, extra?: Obj): string => {
      let _labels = labels
      if (!isEmpty(extra)) {
        _labels = { ...labels, ...extra }
      }

      return getLabel(key, _labels, defaultValue)
    },
    [labels]
  )

  return {
    ...AntApp.useApp(),
    labels: labels,
    l: _getLabel
  }
}
