import { Dayjs } from 'dayjs'
import { z } from 'zod'

export const CalendarTicketSchema = z.object({
  id: z.string(),
  custom_form_id: z.number(),
  name: z.number(),
  computed_status: z.string(),
  timepoint_due: z.string(),
  customer_office__name: z.string(),
  property: z.record(z.string()).nullish(),

  _key: z.string(),
  _headcount: z.number().nullish(),
  _jobText: z.string().nullish(),
  _startDateTime: z.custom<Dayjs>(),
  _endDateTime: z.custom<Dayjs>(),
  _localIsoDate: z.string().nullish(),
  _localIsoDateTime: z.string().nullish()
})
export type CalendarTicket = z.infer<typeof CalendarTicketSchema>

export const CalendarNoteSchema = z.object({
  id: z.number(),
  type: z.literal('N'),
  company_id: z.number(),
  title: z.string(),
  description: z.string().nullish(),
  start_datetime: z.string(),
  end_datetime: z.string(),

  _isoStartDate: z.string().nullish()
})
export type CalendarNote = z.infer<typeof CalendarNoteSchema>
