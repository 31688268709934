import { useAppSelector, useCompanyFeatures, useIsMobile, useRedirectHelper } from '@/hooks'
import { safeJsonParse } from '@/utils'
import { useMemo } from 'react'
import { useUserModulesQuery } from '../module/hooks'
import { MenuItem } from './constants'
import { getAccessibleMenuItems, processModules } from './helpers'

export const useMenus = () => {
  const isMobile = useIsMobile()
  const { forceV2Tickets } = useCompanyFeatures()
  const modulesQuery = useUserModulesQuery()
  const _forceV2Tickets = forceV2Tickets?.enabled || false

  // prepare data
  const authContact = useAppSelector((state) => state.session.contact)
  const permissions = useAppSelector((state) => state.session.permissions)
  const { getV2Url } = useRedirectHelper()

  // craft menu items
  const menus: MenuItem[] = useMemo(() => {
    // use cache during initial load
    if (!modulesQuery.data?.items) return safeJsonParse(localStorage.ocV2MenuItems) || []

    const staticMenus = getAccessibleMenuItems(permissions, isMobile)
    const modules = modulesQuery.data.items || []

    const moduleMenus = (
      !isMobile
        ? processModules(modules, authContact.id, _forceV2Tickets)
        : processModules(modules.filter((item) => item.for_mobile) || [], authContact.id, _forceV2Tickets)
    ) as MenuItem[]

    const menus = staticMenus

    menus.splice(1, 0, ...moduleMenus) // insert modules after Home

    if (forceV2Tickets.enabled) {
      menus
        .filter((m) => m.to === '/tickets/' || m.to === '/loads/')
        .forEach((m) => {
          m.v = 2
          m.to = `${m.to}v2/`
        })
    }

    const tagRedirect = (i: any) => {
      if (!i.to || i.v === 2) return

      const v2Url = getV2Url(i.to || null)
      if (!v2Url) return

      i.to = v2Url
      i.v = 2
    }

    menus.forEach((m) => {
      tagRedirect(m)
      m.items.forEach((i) => {
        tagRedirect(i)
      })
    })

    // cache menus to local storage for faster load in full page refresh
    try {
      localStorage.setItem('ocV2MenuItems', JSON.stringify(menus))
      localStorage.setItem('ocMobileNavLinks', JSON.stringify(menus))
    } catch (e) {
      console.error('Error saving menu items to local storage', e)
    }

    return menus
  }, [authContact.id, getV2Url, modulesQuery.data?.items, permissions, isMobile])

  return { menus }
}
