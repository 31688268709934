import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { Popconfirm } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { customFormAtom, setCustomFormAtom } from '../../../../atoms'
import { Alert } from '../../../../types'
import { useDeleteAlert } from '../hooks'

type Props = {
  alert: Alert
}

export const DeleteButton = ({ alert }: Props) => {
  const { notification } = useApp()
  const { deleteAlert, deleteAlertMutation } = useDeleteAlert()
  const setCustomForm = useSetAtom(setCustomFormAtom)
  const alerts = useAtomValue(useMemo(() => selectAtom(customFormAtom, (s) => s.alerts), [])) || []

  const handleDelete = async () => {
    try {
      await deleteAlert({ id: alert.id })

      setCustomForm({
        alerts: alerts.filter((a) => a.id !== alert.id)
      })

      notification.success({ message: 'Alert deleted' })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Popconfirm
      title="Are you sure you want to delete this alert?"
      onConfirm={handleDelete}
      okText="Yes"
      cancelText="No"
      okButtonProps={{
        loading: deleteAlertMutation.isLoading,
        'data-cy': 'custom-form-alert-delete-button-confirm'
      }}
    >
      <Button iconName="fa:trash" type="text" data-cy="custom-form-alert-delete-button" />
    </Popconfirm>
  )
}
