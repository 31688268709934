import { useAppSelector } from '@/hooks'
import { useAppTheme } from '@/hooks/use-app-theme'
import { useBootstrap } from '@/hooks/use-bootstrap'
import { useRedirect } from '@/hooks/use-redirect'
import { navigate } from '@/routing/helpers'
import { clearImpersonating, isImpersonating } from '@/services/auth-service'
import { AppLink } from '@/ui/AppLink'
import { Icon } from '@/ui/icons'
import { Avatar, Dropdown, MenuProps, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useState } from 'react'

export const AccountMenu: React.FC = () => {
  const { endUserSession } = useBootstrap()
  const { isAppOff, toggleIsAppOff } = useRedirect()
  const { darkMode, setDarkMode, motion, setMotion } = useAppTheme()
  const user = useAppSelector((state) => state.session.user)
  const profile = user?.profile
  const contact = profile?.contact
  const company = profile?.company

  const [isOpen, setIsOpen] = useState(false)
  const [isImpersonateOpen, setIsImpersonateOpen] = useState(false)

  const items: MenuProps['items'] = [
    {
      key: 'company_name',
      label: (
        <AppLink to={'/account_settings/company_information'}>
          <span className="text-sm text-gray-400 mb-0">Company Name</span>
          <p className="mb-0">{company?.name}</p>
        </AppLink>
      ),
      icon: <Icon name="mi:apartment" className={'!text-[20px] pr-2'} />
    },
    {
      key: 'full_name',
      label: (
        <AppLink to={`/profiles/${profile?.id}/edit`}>
          <h5 className="text-sm text-gray-400 mb-0">Full Name</h5>
          <p className="mb-0">
            {contact?.first_name} {contact?.last_name}
          </p>
        </AppLink>
      ),
      icon: <Icon name="mi:account_circle" className={'!text-[20px] pr-2'} />
    },
    { type: 'divider' },
    {
      key: 'company_information',
      label: <AppLink to={'/account_settings/company_information'}>Company Information</AppLink>,
      icon: <Icon name="mi:info" className={'!text-[20px] pr-2'} />
    },
    {
      key: 'manage_users',
      label: <AppLink to={'/profiles/'}>Manage Users</AppLink>,
      icon: <Icon name="mi:group" className={'!text-[20px] pr-2'} />
    },
    {
      key: 'settings',
      label: <AppLink to={`/profiles/${profile?.id}/edit`}>Settings</AppLink>,
      icon: <Icon name="mi:settings" className={'!text-[20px] pr-2'} />
    },
    { type: 'divider' },
    {
      key: 'off_v2',
      label: <span>{isAppOff ? 'Enable V2 App' : 'Disable V2 App'}</span>,
      icon: <Icon name="fa-brands:react" className={'!text-[20px] pr-2'} />,
      onClick: toggleIsAppOff
    },
    {
      key: 'mode',
      label: <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>,
      icon: <Icon name={darkMode ? 'mi:light_mode' : 'mi:dark_mode'} className={'!text-[20px] pr-2'} />,
      onClick: () => setDarkMode(!darkMode)
    },
    {
      key: 'motion',
      label: <span>{motion ? 'Reduce Motion' : 'Off Reduce Motion'}</span>,
      icon: <Icon name={motion ? 'fa:turtle' : 'fa:rabbit-running'} className={'!text-[16px] pr-2 pl-2'} />,
      onClick: () => setMotion(!motion)
    },
    { type: 'divider' },
    {
      key: 'logout',
      label: <span className={'text-danger'}>Logout</span>,
      icon: <Icon name="mi:logout" className={'!text-[20px] text-danger pr-2'} />,
      onClick: endUserSession
    }
  ]

  const impersonateItems: MenuProps['items'] = [
    {
      key: 'original_account',
      label: <span className="text-sm text-gray-400 mb-0">Switch Back</span>,
      icon: <Icon name="fa:arrows-rotate" className={'!text-[20px] pr-2'} />,
      onClick: () => {
        clearImpersonating()
        setTimeout(() => {
          navigate('/', { reload: true })
        }, 1000)
      }
    }
  ]

  return (
    <div className="flex items-center mr-8">
      {isImpersonating() && (
        <Dropdown
          onOpenChange={setIsImpersonateOpen}
          menu={{ items: impersonateItems }}
          trigger={['click']}
          arrow={true}
          placement={'bottomRight'}
        >
          <Tooltip placement="leftTop" title="Impersonating">
            <div className="relative inline-flex h-[25px] w-[25px] bg-green-400 rounded-full items-center justify-center mr-12 cursor-pointer text-xs">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-200 opacity-75"></span>
              <Icon name="fa:masks-theater" />
            </div>
          </Tooltip>
        </Dropdown>
      )}

      <Dropdown
        rootClassName="z-[12000]"
        onOpenChange={setIsOpen}
        menu={{ items }}
        trigger={['click']}
        arrow={true}
        placement={'bottomRight'}
      >
        <div className="flex flex-row items-center cursor-pointer shadow rounded-full pl-6 pr-10 py-4 transition hover:shadow-md hover:bg-gray-100 dark:hover:bg-gray-600">
          <Avatar
            size={32}
            className={'bg-primary-50'}
            src={contact?.image}
            icon={<Icon name="fa:user-alt" className="text-16 text-primary" />}
          />
          <div className="ml-8 text-base text-neutral dark:text-dark-neutral">{user?.username}</div>
          <Icon
            name={'fa:chevron-down'}
            className={cn('ml-2 transition', {
              '-rotate-180': isOpen
            })}
          />
        </div>
      </Dropdown>
    </div>
  )
}
