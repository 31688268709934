import { LocalDateTimePicker, LocalDateTimePickerProps } from '@/ui/date'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'timepoint_submitted'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<LocalDateTimePickerProps>
}

export const TimepointSubmittedInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { customForm, isDisabledField, isHiddenField } = useCustomForm()

  if (isHiddenField(KEY) || !customForm.show_submission_date) return <div></div>

  return (
    <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__timepoint_submitted__label'} />} {...formItemProps}>
      <LocalDateTimePicker showTime={false} className="w-full" disabled={isDisabledField(KEY)} {...inputProps} />
    </Form.Item>
  )
}
