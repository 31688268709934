import { useApp } from '@/hooks'
import { TabsView } from '@/layouts/views'
import { view } from '@/routing'

export const AccountSettingsLayout = view<any, any>(Component, {
  title: () => 'Company Settings'
})

function Component() {
  const { l } = useApp()

  return (
    <TabsView
      asLayout
      header={{ breadcrumbs: true }}
      items={[
        { to: 'company_information', label: 'Company Information' },
        { to: 'ticket_views', label: l('TableViews') },
        { to: 'accounting_name', label: l('CustomerNameReconciliation') },
        { to: 'manage_roles', label: 'Manage Roles' },
        { to: 'locales', label: 'Locales' },
        { to: 'advanced_settings', label: 'Advanced Settings' }
      ]}
    />
  )
}
