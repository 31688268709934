import { useApp } from '@/hooks'
import { ViewHandle } from '@/routing'
import { useState } from 'react'
import { UIMatch, useMatches, useParams } from 'react-router-dom'

export const useViewData = <T = unknown>() => {
  const { l } = useApp()
  const params = useParams()
  const matches = useMatches() as UIMatch<any, ViewHandle>[]
  const [currentIdx, _] = useState(matches.length - 1)
  const _current = matches[currentIdx]

  return {
    id: _current?.id,
    title: l(_current?.handle?.title?.({ data: _current.data || {}, params }) || '', '').trim(),
    current: _current,
    data: matches.reduce((acc, match) => {
      return { ...acc, ...(match.data || {}) }
    }, {}) as T,
    params,
    matches
  }
}
