import { alertApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export const useUpsertAlert = () => {
  const createAlertMutation = useMutation({
    ...alertApi.create()
  })

  const updateAlertMutation = useMutation({
    ...alertApi.update()
  })

  const upsertAlert = async (payload: any) => {
    if (payload.id) {
      return updateAlertMutation.mutateAsync(payload)
    }

    return createAlertMutation.mutateAsync(payload)
  }

  return {
    upsertAlert,
    isLoading: createAlertMutation.isLoading || updateAlertMutation.isLoading
  }
}
