import { useAppTheme } from '@/hooks'
import { ColorPicker, ColorPickerProps } from 'antd'
import { AggregationColor } from 'antd/es/color-picker/color'
import cn from 'classnames'
import { FC } from 'react'

export type ColorInputProps = Omit<ColorPickerProps, 'value' | 'onChange'> & {
  value?: string
  onChange?: (value: string, css: string, color: AggregationColor | null) => void
}

export const ColorInput: FC<ColorInputProps> = ({ presets, onChange, className, ...pickerProps }) => {
  const { token } = useAppTheme()

  presets = presets ?? [
    {
      label: 'General',
      colors: [
        token.colorPrimary,
        token.red,
        token.green,
        token.blue,
        token.cyan,
        token.yellow,
        token.magenta,
        token.purple
      ],
      defaultOpen: true
    }
  ]

  const handleChange = (color: AggregationColor, css: string) => {
    onChange?.(color.toHexString(), css, color)
  }

  return (
    <ColorPicker
      defaultFormat={'hex'}
      disabledFormat={true}
      presets={presets}
      onChange={handleChange}
      className={cn('justify-start', className)}
      onClear={() => onChange?.('', '', null)}
      {...pickerProps}
    />
  )
}
