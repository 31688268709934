import { z } from 'zod'

export const VirtualFieldsSchema = z.object({
  county: z.string().nullish(),
  state: z.string().nullish(),
  lease_number: z.number().nullish(),
  field: z.string().nullish(),
  field_operator: z.string().nullish(),
  legal_location: z.string().nullish(),
  federal_indian_lsc_number: z.string().nullish()
})

export const LocationSchema = z.object({
  id: z.number().nullish(),
  name: z.string().nullish(),
  company_id: z.number().nullish(), // Company
  producer_id: z.number().nullish(), // Company
  gps_latitude: z.string().nullish(),
  gps_longitude: z.string().nullish(),
  legal_description: z.string().nullish(),
  driving_directions: z.string().nullish(),
  user_text_1: z.string().nullish(),
  user_text_2: z.string().nullish(),
  user_text_3: z.string().nullish(),
  external_id: z.string().nullish(),
  virtual_fields: z.string().nullish(),

  // transformed fields
  _virtualFields: VirtualFieldsSchema
})

export type VirtualFields = z.infer<typeof VirtualFieldsSchema>
export type Location = z.infer<typeof LocationSchema>

export type LocationForm = {
  title: string
  mode: 'add' | 'edit'
  location: Location
}
