import { ticketAlertApi } from '@/services/api-service'
import { QueryTable } from '@/ui'
import dayjs from 'dayjs'
import { useTicket } from '../../../../hooks'

export const RaisedAlerts = () => {
  const { ticket } = useTicket()

  return (
    <QueryTable
      scroll={{ y: 400, x: 800 }}
      className={'!border-none'}
      rowKey="id"
      queryApi={ticketAlertApi.list}
      queryParams={{
        fields:
          'alert__name,created_at,created_by__profile__contact__first_name,created_by__profile__contact__last_name,notified,active',
        ticket_id__eq: ticket.id,
        order: '-created_at'
      }}
      columns={[
        { title: 'Alert Name', dataIndex: 'alert__name', key: 'alert__name' },
        { title: 'Raised At', dataIndex: 'created_at', key: 'raised_at', render: (v) => dayjs.formatLocal(v) },
        {
          title: 'Raised By',
          dataIndex: 'created_by__profile__contact__first_name',
          key: 'raised_by',
          render: (v, i) => `${i.created_by__profile__contact__first_name} ${i.created_by__profile__contact__last_name}`
        },
        { title: 'Notified?', dataIndex: 'notified', key: 'notified', render: (v) => (v ? 'Yes' : 'No') },
        { title: 'Active?', dataIndex: 'active', key: 'active', render: (v) => (v ? 'Yes' : 'No') }
      ]}
    />
  )
}
