import { ScheduleTypeItem } from './schemas'

export const SCHEDULE_TYPES: ScheduleTypeItem[] = [
  {
    type: 'J',
    name: 'Job',
    color: 'rgba(241,179,113,0.5)',
    allowPatch: false
  },
  {
    type: 'W',
    name: 'Work',
    color: 'rgba(101,250,120,0.5)',
    allowPatch: true
  },
  {
    type: 'O',
    name: 'Off day',
    color: 'rgba(94,177,241,0.5)',
    allowPatch: true
  },
  {
    type: 'U',
    name: 'Unavailable',
    color: 'rgba(162,162,162,0.5)',
    allowPatch: true
  }
]

export const CALENDAR_VIEWS = [
  { value: 'three_days', label: '3 Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' }
]
