import { useApp } from '@/hooks'
import { pricingCustomerApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'

export const useUpdatePricingExpiry = () => {
  const { notification } = useApp()

  const updatePricingExpiryMutation = useMutation(pricingCustomerApi.patch<any, { id: number; expiry: string }>())

  const updatePricingExpiry = async (id: number, expiry: string) => {
    await updatePricingExpiryMutation.mutateAsync({ id, expiry }).then(() => {
      notification.success({
        message: 'Expiry Updated',
        description: 'Expiry has been updated'
      })
    })
  }

  return {
    updatePricingExpiry,
    updatePricingExpiryMutation
  }
}
