import { SearchInput } from '@/ui'
import { Form, Select } from 'antd'
import { useSetAtom } from 'jotai'
import { searchTextAtom } from '../atoms'

export const CustomFormLayoutFilter = () => {
  const setSearchText = useSetAtom(searchTextAtom)

  return (
    <div className="h-[60px] min-h-[60px] w-full bg-white mb-10 sticky top-0 z-[100]">
      <div className="flex h-full items-center">
        <Form>
          <div className={'flex'}>
            <Form.Item label="View" className="mb-0">
              <Select
                data-cy="custom-form-layout-view-select"
                popupMatchSelectWidth={false}
                options={[
                  { value: 1, label: 'Default' },
                  { value: 2, label: 'Internal' },
                  { value: 3, label: 'Customer' }
                ]}
                defaultValue={1}
              />
            </Form.Item>
            <Form.Item label="Search" className="mb-0 ml-20">
              <SearchInput
                placeholder={'By ID, Name, Key, or Type'}
                onSearch={(text) => setSearchText(text.trim().toLowerCase())}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}
