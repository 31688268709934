import { useApp } from '@/hooks'
import { categoryTagApi } from '@/services/api-service'
import { ActionButton, QueryDeleteButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { SetOptional } from 'type-fest'
import { Tag } from '../schemas'

type Props = {
  tag: Tag
}

export const ManageTagItem = ({ tag }: Props) => {
  const { notification } = useApp()
  const [form] = Form.useForm()

  const isNew = tag.id === 0

  const saveMutation = useMutation(
    isNew ? categoryTagApi.create<any, SetOptional<Tag, 'id'>>() : categoryTagApi.update<any, Tag>()
  )

  const handleSave = async (values: { name: string }) => {
    await saveMutation.mutateAsync({ ...tag, ...values })

    notification.success({
      message: 'Tag Saved',
      description: 'Successfully saved category tag'
    })

    if (isNew) form.resetFields()
  }

  return (
    <Form form={form} layout="vertical" initialValues={{ name: tag.name }} requiredMark={false} onFinish={handleSave}>
      <div className="flex items-center gap-x-8">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the tag name!' }]}
          className="flex-1"
        >
          <Input data-cy="category-tag-name-input" />
        </Form.Item>
        <ActionButton
          iconName="fa:save"
          type="success"
          htmlType="submit"
          loading={saveMutation.isLoading}
          data-cy="category-tag-save-button"
        />
        {!isNew && (
          <QueryDeleteButton
            disabled={saveMutation.isLoading}
            api={categoryTagApi.delete}
            id={tag.id}
            data-cy="category-tag-delete-button"
          />
        )}
      </div>
    </Form>
  )
}
