import { template } from 'lodash'
import { useCallback } from 'react'
import { useTicketFormValues, useTicketObject } from '../../../../hooks'

export const useTemplateRenderer = () => {
  const { ticketObject } = useTicketObject()
  const { getAllPropertiesValueByKey } = useTicketFormValues()

  const renderTemplate = useCallback(
    (value: any, extraContext?: any) => {
      if (typeof value !== 'string') return value

      const TEMPLATE_CONTEXT = {
        ticket: {
          ...ticketObject,
          _office: ticketObject.office,
          _customer_office: ticketObject.customer_office,
          _customer: ticketObject.customer,
          _customer_contact: ticketObject.customer, // why not use _customer directly?
          _location: ticketObject.location,
          _afe: ticketObject.afe,
          _job_code: ticketObject.job_code,
          _property_by_key: getAllPropertiesValueByKey()
        },
        ...extraContext
      }

      return template(value)(TEMPLATE_CONTEXT) || ''
    },
    [ticketObject, getAllPropertiesValueByKey]
  )

  return { renderTemplate }
}
