import { Button, Sortable } from '@/ui'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { Form } from 'antd'
import cn from 'classnames'
import { sortBy, uniq } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { useFormWatch } from '../../../../../hooks'
import { LineItem, LineItemComponent } from '../../../../../schemas'
import { ItemField } from '../../../../item-form/item-editor/item-field'
import { useComponentKind } from '../hooks'
import { TableEmpty } from '../table-empty'

type Props = { isEdit: boolean; item: Partial<LineItem>; components: LineItemComponent[] }

export const TableBody: FC<Props> = ({ isEdit, item, components }) => {
  const form = Form.useFormInstance()
  const componentKind = useComponentKind(components[0] || item)

  const deletedIds = useFormWatch(['lineItems', item.id?.toString(), 'deletedComponentIds'] as any)
  const componentsOrder = useFormWatch(['lineItems', item.id?.toString(), 'componentsOrder'] as any)

  const handleDelete = useCallback(
    (componentId: number) => {
      form.setFieldsValue({
        lineItems: {
          [item.id as any]: {
            deletedComponentIds: uniq([...(deletedIds || []), componentId])
          }
        }
      })
    },
    [deletedIds, form, item.id]
  )

  const visibleComponents = useMemo(() => {
    let _items = components

    if (deletedIds && deletedIds?.length) {
      _items = components.filter((c) => !deletedIds.includes(c.id))
    }

    if (componentsOrder && componentsOrder?.length) {
      _items = sortBy(_items, (c) => (componentsOrder || []).indexOf(c.id))
    } else {
      _items = sortBy(_items, 'sequence')
    }

    return _items
  }, [components, componentsOrder, deletedIds])

  const handleSort = useCallback(
    (newComponents: LineItem[]) => {
      form.setFieldValue(
        ['lineItems', item.id?.toString(), 'componentsOrder'] as any,
        newComponents.map((c) => c.id)
      )
    },
    [form, item.id]
  )

  if (visibleComponents.length === 0) return <TableEmpty />

  return (
    <tbody>
      <Sortable
        items={visibleComponents}
        by={'id'}
        onChange={handleSort}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        render={(c, cIdx, sortable) => {
          return (
            <tr
              key={`${c.id}-${cIdx}`}
              className={cn('[&>td]:py-4 [&>td]:px-10', {
                'border-dashed shadow-primary !border-primary-500': sortable?.isDragging
              })}
              ref={sortable?.setNodeRef}
              style={sortable?.style}
              {...sortable?.attributes}
            >
              {componentKind.visibleFields.map((f) => (
                <td key={f.field}>
                  {isEdit && f.editable ? (
                    <ItemField
                      label={''}
                      className={'mb-0 min-w-[150px]'}
                      name={['lineItems', item.id?.toString(), 'components', c.id.toString(), f.field] as any}
                      fieldConfig={f}
                      inputProps={{ size: 'small', rows: 1 }}
                    />
                  ) : (
                    <span>{f.format(c)}</span>
                  )}
                </td>
              ))}

              {isEdit && (
                <td>
                  <div className={'flex flex-row'}>
                    <Button
                      type={'text'}
                      size={'small'}
                      iconName={'fa:trash'}
                      danger
                      onClick={() => handleDelete(c.id)}
                    />
                    <Button
                      type={'text'}
                      size={'small'}
                      iconName={'fa:grip-dots-vertical'}
                      className={'cursor-grab'}
                      {...sortable?.listeners}
                    />
                  </div>
                </td>
              )}
            </tr>
          )
        }}
      />
    </tbody>
  )
}
