import { useApp } from '@/hooks'
import { useAppTheme } from '@/hooks/use-app-theme'
import { JasResourceUseQueryResponse } from '@/types/jas-resource'
import { Popover } from '@/ui'
import { Icon } from '@/ui/icons'
import { SCHEDULE_TYPES, ScheduleType } from '@/utils'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { JobHourBar } from '../job-hour-bar'
import { JobsHoursPopover } from '../jobs-hours-popover'
import { firstClickedDateAtom, firstClickedDateDataAtom, selectedDatesAtom } from './atoms'

type MenuItem = ScheduleType | 'DELETE'

type Props = {
  data: {
    schedules: JasResourceUseQueryResponse['schedules']
    name: string
  }
  date: string
  onMenuItemClick: (type: ScheduleType | 'DELETE', selectedDates: Dayjs[]) => void
  isShortForm: boolean
  hiddenMenuItems?: MenuItem[]
}

const menuItems = (onMenuItemClick: (type: MenuItem) => void) => {
  return [
    {
      key: 1,
      label: SCHEDULE_TYPES.W.titleShort,
      onClick: () => onMenuItemClick(SCHEDULE_TYPES.W.titleShort),
      ['data-cy']: 'schedule-type-W'
    },
    {
      key: 2,
      label: SCHEDULE_TYPES.O.titleShort,
      onClick: () => onMenuItemClick(SCHEDULE_TYPES.O.titleShort),
      ['data-cy']: 'schedule-type-O'
    },
    {
      key: 3,
      label: SCHEDULE_TYPES.U.titleShort,
      onClick: () => onMenuItemClick(SCHEDULE_TYPES.U.titleShort),
      ['data-cy']: 'schedule-type-U'
    },
    {
      key: 4,
      label: <Icon name="mi:delete" />,
      onClick: () => onMenuItemClick('DELETE'),
      ['data-cy']: 'schedule-type-delete'
    },
    {
      key: 5,
      label: (
        <Popover
          content={
            <div>
              Press "Shift" and click on a second date to <br /> select and apply across a whole date range
            </div>
          }
          placement="left"
        >
          <Icon name="mi:info" />
        </Popover>
      ),
      ['data-cy']: 'schedule-hint'
    }
  ]
}

const Component = ({ data, date, onMenuItemClick, isShortForm, hiddenMenuItems }: Props) => {
  const { token } = useAppTheme()
  const [isOpened, setOpened] = useState(false)
  const [firstClickedDate, setFirstClickedDate] = useAtom(firstClickedDateAtom)
  const [selectedDates, setSelectedDates] = useAtom(selectedDatesAtom)
  const [firstClickedDateData, setFirstClickedDateData] = useAtom(firstClickedDateDataAtom)
  const { notification } = useApp()
  const isShiftPressed = useRef(false)

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setOpened(false)

      if (!isShiftPressed.current) {
        setFirstClickedDate(null)
        setFirstClickedDateData(null)
        setSelectedDates([])
      }
    } else {
      setOpened(true)
    }
  }

  const scheduleItem = data.schedules[date]

  const events = useMemo(() => {
    if (!Array.isArray(scheduleItem.data)) {
      return []
    }

    return scheduleItem.data
  }, [scheduleItem.data])

  const highlightDates = useMemo(() => {
    if (firstClickedDateData?.name !== data.name) {
      return []
    }

    return selectedDates.map((value) => value.format('YYYY-MM-DD')).slice(0, -1) // Remove last date
  }, [selectedDates, data, firstClickedDateData])

  const handleClick = () => {
    if (isShiftPressed.current && firstClickedDate) {
      const diff = dayjs(date).diff(firstClickedDate, 'day')

      const dates = Array.from({ length: Math.abs(diff) + 1 }, (_, i) => {
        if (dayjs(date).isAfter(firstClickedDate)) {
          return dayjs(firstClickedDate).add(i, 'day')
        }

        return dayjs(firstClickedDate).subtract(i, 'day')
      })

      setSelectedDates(dates)
      setFirstClickedDate(dayjs(date))
      setFirstClickedDateData(data)
      return
    }

    setFirstClickedDate(dayjs(date))
    setFirstClickedDateData(data)
    setSelectedDates([dayjs(date)])
  }

  const downHandler = (e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      isShiftPressed.current = true
    }
  }

  const upHandler = () => {
    isShiftPressed.current = false
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  const handleMenuItemClick = (type: MenuItem) => {
    onMenuItemClick(type, selectedDates)
    setOpened(false)
    setFirstClickedDate(null)
    setFirstClickedDateData(null)
    setSelectedDates([])
  }

  // Disable dropdown if there is a ticket
  if (events.length > 0 && scheduleItem && scheduleItem.type) {
    return (
      <div
        className="flex flex-col items-center justify-center w-full h-full cursor-default select-none relative"
        style={{
          backgroundColor: SCHEDULE_TYPES[scheduleItem.type].color,
          color: SCHEDULE_TYPES[scheduleItem.type].textColor
            ? SCHEDULE_TYPES[scheduleItem.type].textColor
            : token.colorTextBase
        }}
      >
        {isShortForm ? SCHEDULE_TYPES[scheduleItem.type].titleShort : SCHEDULE_TYPES[scheduleItem.type].title}
        {!isShortForm && (
          <>
            <JobsHoursPopover events={events} name={data.name} date={dayjs(date)} />
            <div className="w-full px-8 top-6 relative">
              <JobHourBar date={dayjs(date)} events={events} />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <Dropdown
      onOpenChange={onOpenChange}
      trigger={['click']}
      menu={{
        items: menuItems(handleMenuItemClick).filter(
          (item) => !hiddenMenuItems?.some((hiddenItem) => hiddenItem === item.label)
        )
      }}
      destroyPopupOnHide
      dropdownRender={(menu) => (
        <div className="-mt-4 -mb-4 [&>ul]:!rounded-none [&>ul>*]:!font-bold text-center">{menu}</div>
      )}
    >
      <div className="group/cell flex w-full h-full relative" onClick={handleClick}>
        {highlightDates.includes(date) ? (
          <div className="absolute w-full border-0 p-0  h-full bg-primary"></div>
        ) : (
          <>
            <button
              className={classNames(
                'absolute w-full border-0 p-0 border-none h-full justify-center items-center cursor-pointer bg-primary opacity-0 group-hover/cell:opacity-100',
                {
                  'opacity-100': isOpened
                }
              )}
            >
              <MoreOutlined className="text-white text-[24px]" />
            </button>
            {!scheduleItem || !scheduleItem.type ? (
              <button className="w-full h-full border-0 p-0 border-none bg-white cursor-pointer" />
            ) : (
              <button
                className="flex border-0 p-0 border-none justify-center items-center w-full h-full select-none cursor-pointer"
                style={{
                  backgroundColor: SCHEDULE_TYPES[scheduleItem.type].color,
                  color: SCHEDULE_TYPES[scheduleItem.type].textColor
                    ? SCHEDULE_TYPES[scheduleItem.type].textColor
                    : token.colorTextBase
                }}
              >
                {isShortForm ? SCHEDULE_TYPES[scheduleItem.type].titleShort : SCHEDULE_TYPES[scheduleItem.type].title}
              </button>
            )}
          </>
        )}
      </div>
    </Dropdown>
  )
}

export const CalendarTableDropdownCell = memo(Component, (prevProps, nextProps) => {
  if (Object.keys(prevProps.data.schedules).length !== Object.keys(nextProps.data.schedules).length) {
    return false
  }

  if (!isEqual(prevProps.data.schedules, nextProps.data.schedules)) {
    return false
  }

  return true
})
