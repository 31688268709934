import { Icon } from '@/ui'
import { Tag } from 'antd'
import cn from 'classnames'
import React from 'react'
import { JobResource } from '../schemas'
import { JobAvatar } from './JobAvatar'

type Props = {
  skill: { label: string; value: string }
  isSelected: boolean
  resources: JobResource[]
  onSelectChange: (skill: string, isSelected: boolean) => void
}

export const SkillTag: React.FC<Props> = ({ skill, isSelected, resources, onSelectChange }) => {
  return (
    <div key={skill.value} className="relative flex top-0 flex-col">
      <Tag
        bordered={false}
        className={cn('text-sm bg-gray-200 text-gray-500 rounded flex items-center cursor-pointer min-h-[24px] px-12', {
          'bg-primary text-white dark:bg-gray-500': isSelected
        })}
        onClick={() => onSelectChange(skill.value, !isSelected)}
      >
        {skill.label}
        {isSelected ? (
          <Icon name={'mi:close'} className={'text-[16px] ml-4'} />
        ) : (
          <Icon name={'mi:add_circle'} className={'text-[16px] ml-4'} />
        )}{' '}
      </Tag>
      <div className="flex flex-wrap flex-row mt-2 mb-4 min-h-[26px]">
        {resources.map((res) => (
          <div key={res.id} className="mr-2">
            <JobAvatar resource={res} />
          </div>
        ))}
        {resources.length === 0 && (
          <div key="skills-default" className="mr-2">
            <JobAvatar type="default" title="No Resource" text="-" />
          </div>
        )}
      </div>
    </div>
  )
}
