import { useApp } from '@/hooks'
import { Obj } from '@/types/general'
import { getLabel as getLabelUtils } from '@/utils'
import { get, isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useCustomFormsStatus } from './use-custom-forms-status'
import { useGlobalCustomForms } from './use-global-custom-forms'

export const useCustomFormLabels = (customFormId?: number | null, ticket?: any) => {
  const { labels: appLabels } = useApp()

  const { getCustomForm } = useGlobalCustomForms()

  const customForm = customFormId ? getCustomForm(customFormId) : null
  const { formsStatusMap } = useCustomFormsStatus()

  const labels = useMemo(() => {
    if (!customForm) return appLabels

    // property name labels
    const propertyLabels: Record<string, string> = customForm.properties.reduce((acc: any, property) => {
      const customName = ticket ? get(ticket._data?.prop_names, `${property.id}`) : null
      acc[property._field] = isEmpty(customName) ? property.name : customName
      return acc
    }, {})

    // status labels
    const statusLabels = Object.entries(formsStatusMap[customForm.id] || {})
      .map(([key, value]) => ({ [`status_${key}`]: value }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {})

    return { ...appLabels, ...(customForm._fieldAliases || {}), ...propertyLabels, ...statusLabels }
  }, [appLabels, customForm, formsStatusMap, ticket])

  const getLabel = useCallback(
    (key: string, defaultValue?: string, extra?: Obj): string => {
      let _labels = labels
      if (!isEmpty(extra)) _labels = { ...labels, ...extra }
      return getLabelUtils(key, _labels, defaultValue)
    },
    [labels]
  )

  return {
    labels,
    getLabel,
    l: getLabel
  }
}
