import { useViewData } from '@/layouts'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { v2JasResourceApi } from '@/services/api-service'
import { RESOURCE_TYPE_MAP } from '../../constants'
import {
  RelatedSkusCard,
  ResourceActivities,
  ResourceDetailsCard,
  SaveResourceButton,
  ScheduleRulesCard
} from './components'
import { transformResource } from './helpers'
import { ResourceForm, ResourceSchema } from './schemas'

export const ResourceFormView = view<any, ResourceForm>(ViewComponent, {
  name: 'ResourceFormView',
  title: ({ data }) => data?.title || '',
  loader: async ({ params }) => {
    const resourceType = params.resourceType ? RESOURCE_TYPE_MAP[params.resourceType] : null
    if (!resourceType) throw new Error('Invalid resource type')

    const resourceId = Number(params.resourceId)

    // add mode
    if (!resourceId)
      return { mode: 'add', title: `Add ${resourceType.type}`, resource: { type: resourceType.type, status: 'D' } }

    // edit mode
    const resource = transformResource(
      await fetchQuery(v2JasResourceApi.get(resourceId, undefined, { fields: ResourceSchema }))
    )
    return { mode: 'edit', title: `Edit ${resource.name}`, resource }
  },
  form: { layout: 'vertical' }
})

function ViewComponent() {
  const { title } = useViewData()

  return (
    <FormView
      header={{
        accent: false,
        border: false,
        backButton: true,
        title: title,
        actions: [<SaveResourceButton key="save" />]
      }}
    >
      <div className={'flex flex-col md:flex-row gap-20'}>
        <div className="flex-1">
          <ResourceDetailsCard />
        </div>
        <div className="flex-1 flex flex-col gap-20">
          <ScheduleRulesCard />
          <RelatedSkusCard />
        </div>
      </div>

      <ResourceActivities />
    </FormView>
  )
}
