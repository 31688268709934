import { safeJsonParse } from '@/utils'

export const getMoreInfo = (n: any) => {
  return [
    {
      label: 'Error',
      data: n.response,
      renderIf: ['E', 'S'],
      hide: n.status !== 'E'
    },
    {
      label: 'Message Id',
      data: (safeJsonParse(n.response) || '').MessageId,
      renderIf: ['E', 'S']
    },
    {
      label: 'Subject',
      data: n.title,
      renderIf: ['E']
    },
    {
      label: 'Site',
      data: n.site.domain,
      renderIf: ['E', 'S']
    },
    {
      label: 'From Email',
      data: n.data.EmailFrom,
      emailOnly: true,
      renderIf: ['E']
    },
    {
      label: 'To Email',
      data: n.data.EmailTo,
      emailOnly: true,
      renderIf: ['E']
    },
    {
      label: 'CC Emails',
      data: (n.data.cc_emails || []).join(', '),
      emailOnly: true,
      renderIf: ['E']
    },
    {
      label: 'Attachments',
      data: n.data.attachment_links,
      emailOnly: true,
      renderIf: ['E']
    }
  ]
}
