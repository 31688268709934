import { useApp } from '@/hooks'
import { ViewHandle } from '@/routing'
import { AppLink } from '@/ui/AppLink'
import { Icon } from '@/ui/icons'
import { Breadcrumb as AntBreadcrumb, BreadcrumbProps } from 'antd'
import { FC } from 'react'
import { UIMatch, useMatches, useParams } from 'react-router-dom'

type Props = Omit<BreadcrumbProps, 'items'>

export const Breadcrumbs: FC<Props> = (props) => {
  const { l } = useApp()
  const params = useParams()
  const matches = useMatches() as UIMatch<any, ViewHandle>[]

  const crumbs = matches
    .filter((m) => m.handle?.title)
    .map(({ data, pathname, handle }) => ({
      to: pathname,
      title: l(handle?.title?.({ data: data || {}, params }) || '', '').trim()
    }))

  return (
    <AntBreadcrumb
      {...props}
      items={[
        {
          title: (
            <AppLink to={'/'}>
              <Icon name={'fa:home'} />
            </AppLink>
          )
        },
        ...crumbs.map((c, idx) => ({
          title: idx + 1 === crumbs.length ? <span>{c.title}</span> : <AppLink to={c.to}>{l(c.title)}</AppLink>
        }))
      ]}
    />
  )
}
