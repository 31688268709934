import { FC, memo, useCallback, useMemo } from 'react'
import { useCustomForm, useTicket } from '../../../../hooks'
import { PropertyGroup } from '../../../../schemas'
import { useItemsStats } from '../../hooks'
import { Category } from '../../schemas'
import { ItemsCategory } from './items-category'
import { ItemsSummary } from './items-summary'

type Props = {
  propertyGroup?: PropertyGroup
}

export const ItemsSection: FC<Props> = ({ propertyGroup }) => {
  const { ticket } = useTicket()
  const { customForm } = useCustomForm()
  const { categories, kinds } = useItemsStats(ticket.id)

  const by: 'kind' | 'category' | 'forceKind' | null = useMemo(() => {
    if (customForm.group_line_items) {
      if (customForm.group_by_kind) return 'kind'
      else return 'category'
    } else if (customForm.default_group_kind_id) return 'forceKind'

    return null
  }, [customForm.default_group_kind_id, customForm.group_by_kind, customForm.group_line_items])

  // select the first category with kind or parent__kind or just the first category
  const fallbackCategory = useMemo((): Category | null => {
    const defaultCategory = categories.find((c) => c.kind || c.parent__kind) || categories[0]

    if (by === 'forceKind')
      return {
        ...defaultCategory,
        kind: customForm.default_group_kind_id,
        _kind: null
      }
    else {
      return defaultCategory
    }
  }, [by, categories, customForm.default_group_kind_id])

  const categoryByKind = useCallback(
    (kindId: number): Category | null => {
      const _cat = categories.find((c) => c.kind === kindId || c.parent__kind === kindId)
      if (!_cat) {
        console.error(`No category was found for kind ${kindId}`)
        return null
      }
      return _cat
    },
    [categories]
  )

  return (
    <>
      <div className={'flex flex-col'}>
        {by === 'category' &&
          categories.map((category) => (
            <ItemsCategory
              key={category.id}
              category={category}
              title={`${category.parent__name} - ${category.name}`}
              queryFilters={{ category_id__eq: category.id }}
            />
          ))}
        {by === 'kind' &&
          kinds?.map((kind) => (
            <ItemsCategory
              key={kind.id}
              category={categoryByKind(kind.id) as Category}
              title={kind.name}
              queryFilters={{ category__kind_id__eq: kind.id }}
              noCategoryFilter
            />
          ))}
        {by === 'forceKind' && fallbackCategory && <ItemsCategory category={fallbackCategory} noCategoryFilter />}
        {by === null && fallbackCategory && <ItemsCategory category={fallbackCategory} noCategoryFilter />}
      </div>

      {customForm.show_totals && (
        <div className={'flex flex-row justify-end'}>
          <ItemsSummary />
        </div>
      )}
    </>
  )
}

export const ItemsSectionMemo = memo(ItemsSection)
