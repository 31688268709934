import { AppVersion } from '@/types/general'
import { AppLink } from '@/ui/AppLink'
import { FC } from 'react'
import { To } from 'react-router-dom'
import { Button, ButtonProps } from '../button'

export type LinkButtonProps = Omit<ButtonProps, 'type'> & {
  type?: ButtonProps['type'] | 'plain'
  v?: AppVersion
  to?: To
}

export const LinkButton: FC<LinkButtonProps> = ({ type, to, v, target, ...buttonProps }) => {
  return (
    <AppLink to={to} v={v} target={target}>
      {type === 'plain' ? buttonProps.children : <Button type={type} {...buttonProps} />}
    </AppLink>
  )
}
