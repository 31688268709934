import { Form, Select } from 'antd'

type Props = {
  selectedFormat: string
  setSelectedFormat: (value: string) => void
}

export const FormatSelect = ({ selectedFormat, setSelectedFormat }: Props) => {
  return (
    <Form.Item label="Format">
      <Select
        value={selectedFormat}
        onChange={setSelectedFormat}
        options={[
          {
            label: 'Simple text CSV',
            value: 'csv'
          },
          {
            label: 'Excel Spreadsheet',
            value: 'xlsx'
          }
        ]}
      />
    </Form.Item>
  )
}
