import { attachmentApi } from '@/services/api-service'
import { useMutation } from '@tanstack/react-query'
import { FC, useCallback, useState } from 'react'

import { ImageCropper } from '@/components/image-cropper/ImageCropper'
import { Button, Modal } from '@/ui'
import { refreshQueries } from '../../../../../helpers'
import { TicketAttachment } from '../../../../../schemas'

export const CropImageButton: FC<{ onCrop: () => void; attachment: TicketAttachment }> = ({ onCrop, attachment }) => {
  const [showCropper, setShowCropper] = useState(false)
  const [triggerCrop, setTriggerCrop] = useState(false)

  const resetMutation = useMutation(attachmentApi.reset(attachment.id))
  const cropMutation = useMutation(attachmentApi.crop(attachment.id))

  const handleResetCrop = useCallback(async () => {
    try {
      await resetMutation.mutateAsync(attachment.id)
      setShowCropper(false)
      setTriggerCrop(false)
      refreshQueries()
    } catch (error) {
      console.error('Error undo crop')
    }
  }, [resetMutation])

  const handleCropComplete = useCallback(
    async (croppedImage: any) => {
      console.log('Cropped image:', croppedImage)

      try {
        croppedImage.id = attachment.id
        await cropMutation.mutateAsync(croppedImage)
        setShowCropper(false)
        setTriggerCrop(false)
        refreshQueries()
      } catch (error) {
        console.error('Error crop image')
      }
    },
    [cropMutation]
  )

  return (
    <>
      <Button
        onClick={() => setShowCropper(true)}
        size="small"
        shape={'circle'}
        type={'text'}
        iconName={'fa:crop-simple'}
        primary
      />
      {showCropper && (
        <Modal
          open={showCropper}
          onCancel={() => setShowCropper(false)}
          title="Crop Image"
          width={800}
          footer={[
            <Button key="crop-cancel" onClick={() => setShowCropper(false)}>
              Cancel
            </Button>,
            <Button key="crop-undo" onClick={handleResetCrop} iconName={'fa:undo'} primary>
              Reset
            </Button>,
            <Button key="crop-accept" onClick={() => setTriggerCrop(true)} type={'primary'} iconName={'fa:crop-simple'}>
              Crop
            </Button>
          ]}
        >
          <ImageCropper attachment={attachment} onCropComplete={handleCropComplete} triggerCrop={triggerCrop} />
        </Modal>
      )}
    </>
  )
}
