import { useApp } from '@/hooks'
import { REPORTS_PATHS } from '@/modules/reports/constants'
import { scheduledReportApi } from '@/services/api-service'
import { ScheduledReport } from '@/types/scheduled-report'
import { AppLink, Button, QueryTable, SearchInput } from '@/ui'
import { humanizeCronExpr } from '@/utils/humanizeCronExpr'
import { useMutation } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { ReportModule } from '../../schema'

type Props = {
  reportModule?: ReportModule
}

export const ScheduledReportsSection = ({ reportModule }: Props) => {
  const data = JSON.parse(reportModule?.data_json || '{}')
  const [search, setSearch] = useState<string | undefined>(data.report_desc_contains)
  const { notification } = useApp()
  const deleteScheduledReportMutation = useMutation(scheduledReportApi.delete())

  const handleDelete = async (id: number) => {
    await deleteScheduledReportMutation.mutateAsync({ id })
    notification.success({ message: 'Scheduled report deleted' })
  }

  return (
    <div>
      <div className="mb-16">
        <SearchInput onSearch={(text) => setSearch(text)} />
      </div>
      <QueryTable
        rowKey="id"
        queryApi={scheduledReportApi.list}
        queryParams={{
          order: 'name',
          ...(search && {
            Q: [`description__icontains|${search}`, `name__icontains|${search}`, 'or']
          })
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Description',
            dataIndex: 'description'
          },
          {
            title: 'Schedule',
            render: (_, scheduledReport: ScheduledReport) => (
              <div>
                <div>{scheduledReport.cron_spec}</div>
                <div>{humanizeCronExpr(scheduledReport.cron_spec || '')}</div>
              </div>
            )
          },
          {
            title: 'Last Run',
            dataIndex: 'last_run',
            render: (_, scheduledReport: ScheduledReport) => (
              <div>{dayjs(scheduledReport.last_run).format('MMM D, YYYY h:mm:ss A')}</div>
            )
          },
          {
            title: 'Next Run',
            dataIndex: 'next_run',
            render: (_, scheduledReport: ScheduledReport) => (
              <div>{dayjs(scheduledReport.next_run).format('MMM D, YYYY h:mm:ss A')}</div>
            )
          },
          {
            title: 'Enabled',
            dataIndex: 'enabled',
            render: (_, scheduledReport: ScheduledReport) => <div>{scheduledReport.enabled ? 'Yes' : 'No'}</div>
          },
          {
            align: 'right',
            sorter: false,
            render: (_, report: ScheduledReport) => (
              <Space>
                <AppLink to={REPORTS_PATHS.editScheduledReport(report.id)}>
                  <Button iconName="fa:edit" type="text" />
                </AppLink>
                <Popconfirm
                  title="Delete scheduled report?"
                  description="Are you sure you want to delete this scheduled report?"
                  placement="topLeft"
                  onConfirm={() => handleDelete(report.id)}
                >
                  <Button iconName="fa:trash" type="text" />
                </Popconfirm>
              </Space>
            )
          }
        ]}
      />
    </div>
  )
}
