import { ScriptedButtonConfig } from '../../types'
import { EmailSignatureButton } from './EmailSignatureButton'

export const EmailSignatureButtonConfig: ScriptedButtonConfig = {
  type: 'common_email_signature',
  iconName: 'fa:pen-swirl',
  label: 'Email for Signature',
  group: 'Common',
  render: () => <EmailSignatureButton />,
  vars: []
}
