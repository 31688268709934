import { useApp } from '@/hooks'
import { FormView } from '@/layouts/views'
import { fetchQuery } from '@/query'
import { view } from '@/routing'
import { equipmentApi } from '@/services/api-service'
import { BackButton, SaveButton } from '@/ui'
import { Icon } from '@/ui/icons'
import { useAtom } from 'jotai'
import { cloneDeep } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RECORDS_FORM_VIEW_HEADER } from '../../../constants'
import { AllFields } from './all-fields'
import { equipmentAtom, initialEquipmentAtom } from './atoms'
import { CategorySelect } from './category-select'
import { useEquipmentsQuery, useUpsertEquipment } from './hooks'
import { RecentActivitiesSection } from './recent-activities-section'
import { SkuGroup } from './sku-group'
import { SkuProperties } from './sku-properties'
import { SubCategorySelect } from './sub-category-select'
import { SubComponents } from './sub-components'
import { UpdateDefaultLiModal } from './update-default-li-modal'
import { isEquipmentChanged } from './utils'

export const SkuFormView = view<any, any>(Component, {
  title: ({ data }) => data?.title,
  loader: async ({ params }) => {
    const equipmentId = Number(params.id)

    // add mode
    if (!equipmentId) return { mode: 'add', title: 'Add', equipment: {} }

    // edit mode
    const equipment = await fetchQuery(equipmentApi.get<any>(equipmentId, undefined, { fields: 'id,sku' }))
    return { mode: 'edit', title: `Edit ${equipment.sku}`, equipment }
  },
  form: { layout: 'vertical' }
})

function Component() {
  const { l } = useApp()
  const { id } = useParams<{ id: string }>()
  const [equipment, setEquipment] = useAtom(equipmentAtom)
  const [initialEquipment, setInitialEquipment] = useAtom(initialEquipmentAtom)
  const isBlocking = isEquipmentChanged(equipment, initialEquipment)
  const { upsertEquipment, isUpsertEquipmentLoading } = useUpsertEquipment()
  const { equipmentQuery } = useEquipmentsQuery()

  // Set equipment and initialEquipment to the fetched data
  useEffect(() => {
    if (equipmentQuery.data) {
      const equipment = cloneDeep(equipmentQuery.data)
      setEquipment(equipment)
      setInitialEquipment(equipment)
    }
  }, [equipmentQuery.data, setEquipment, setInitialEquipment])

  return (
    <FormView
      header={{
        ...RECORDS_FORM_VIEW_HEADER,
        backButton: <BackButton data-cy={'sku-back-button'} />,
        title: id ? `Edit ${l('SKU')}` : `Add ${l('SKU')}`,
        actions: [
          <SaveButton
            key="save"
            disabled={!isBlocking}
            icon={<Icon name="mi:save" />}
            onSave={upsertEquipment}
            loading={isUpsertEquipmentLoading}
            type="success"
            data-cy="save-sku-button"
            returnButtonProps={{ 'data-cy': 'save-and-return-sku-button' }}
          >
            Save
          </SaveButton>
        ]
      }}
    >
      <div>
        <div className="flex gap-x-30 mb-24">
          <CategorySelect />
          <SubCategorySelect />
        </div>
        <SkuProperties />
        <SkuGroup />
        <AllFields />
        <SubComponents />
        <RecentActivitiesSection />
      </div>
      <UpdateDefaultLiModal />
    </FormView>
  )
}
