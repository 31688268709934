export const checkKeyHasDuplicates = (
  propertyId: number | undefined,
  propertyKey: string | undefined,
  propertiesKeys: { [n: string]: string }
) => {
  if (propertyKey === undefined) return false // just already created property with empty key
  // for new property just ckeck if any other property has the same key
  if (propertyId === undefined) return Object.values(propertiesKeys).some((k) => k === propertyKey)
  // when we are editing property we should check other properties (different id with this property's id)
  return Object.entries(propertiesKeys).some(([id, key]) => Number(id) !== propertyId && key === propertyKey)
}
