import { propertyTypeApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

export const usePropertyTypesQuery = () => {
  const typeQuery = useQuery(propertyTypeApi.list({ limit: 'None' as any }))

  const [typeOptions, nameByIdMap, idByNameMap] = useMemo(() => {
    const _options: { value: number; label: string }[] = []
    const _nameByIdMap = new Map<number, string>()
    const _idByNameMap = new Map<string, number>()

    if (typeQuery.data?.items) {
      typeQuery.data.items
        .sort((a, b) => a.name.localeCompare(b.name, 'en'))
        .forEach((type) => {
          _options.push({ value: type.id, label: type.name })
          _nameByIdMap.set(type.id, type.name)
          _idByNameMap.set(type.name, type.id)
        })
    }

    return [_options, _nameByIdMap, _idByNameMap]
  }, [typeQuery.data?.items])

  const getTypeIdByName = useCallback((name: string) => idByNameMap.get(name), [idByNameMap])
  const getTypeNameById = useCallback((id: number) => nameByIdMap.get(id), [nameByIdMap])

  return {
    typeQuery,
    typeOptions,
    getTypeIdByName,
    getTypeNameById
  }
}
