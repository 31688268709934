import { DataSourceManager } from '@/components/data-source-manager'
import { LINE_ITEM_FIELDS } from '@/constants/general'
import { useUserDataQuery } from '@/hooks'
import { Select } from '@/ui'
import { Button } from '@/ui/button'
import { Input, Modal } from 'antd'
import { useAtom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useState } from 'react'
import { equipmentAtom } from '../atoms'
import { useCategoryKindQuery } from '../hooks'

type FieldItem = (typeof LINE_ITEM_FIELDS)[number]

type Props = {
  fieldItem: FieldItem
}

// Component for user_text_... fields. With dynamic options fetched from API for Select component.
export const UserTextField = ({ fieldItem }: Props) => {
  const fieldItemNo = fieldItem.field.split('_')[2] // user_text_1 -> 1
  const stringSourceKey = `string_${fieldItemNo}_source`

  const [equipment, setEquipment] = useAtom(equipmentAtom)
  const { categoryKindQuery } = useCategoryKindQuery(equipment.category?.kind)
  const initialStringSourceID = useMemo(
    () =>
      (equipment[stringSourceKey] as number | null) ||
      (categoryKindQuery.data?.data?.[fieldItem.field] || {}).stringSource ||
      null,
    [equipment, categoryKindQuery.data, fieldItem.field, stringSourceKey]
  )

  const userDataQuery = useUserDataQuery({ equipment: equipment || {}, kind: categoryKindQuery.data })
  const [isDataSrcManagerOpen, setIsDataSrcManagerOpen] = useState(false)
  const [selectedUserData, setSelectedUserData] = useState<number | null>(initialStringSourceID)

  const value = useAtomValue(
    useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment[fieldItem.field]), [fieldItem.field])
  ) as any

  const userData = useMemo(
    () => userDataQuery.data?.items.find((item) => item.id === selectedUserData),
    [userDataQuery.data?.items, selectedUserData]
  )
  const options = userData ? userData.content.map((item) => ({ label: item, value: item })) : []

  const handleUserTextValueChange = (value: string | string[]) => {
    if (Array.isArray(value)) {
      value = value.join(',')
    }
    setEquipment((prev) => ({ ...prev, [fieldItem.field]: value }))
  }

  const handleOk = () => {
    if (selectedUserData !== initialStringSourceID) {
      setEquipment((prev) => ({
        ...prev,
        [stringSourceKey]: selectedUserData || null,
        ...(selectedUserData ? { [fieldItem.field]: null } : {}) // Reset value when changing data source
      }))
    }
    // If no new data source is selected, keep the current value and close the modal
    setIsDataSrcManagerOpen(false)
  }

  return (
    <div className="flex gap-x-6">
      {!userData ? (
        <div className="flex-1">
          <Input
            width={200}
            value={value}
            data-cy={`sku-${fieldItem.field}-input`}
            onChange={(e) => handleUserTextValueChange(e.target.value)}
          />
        </div>
      ) : (
        <Select
          loading={userDataQuery.isLoading}
          options={options}
          value={userData?.multiselect ? value?.split(',') : value}
          onChange={handleUserTextValueChange}
          placeholder="Select"
          mode={userData?.multiselect ? 'multiple' : undefined}
          allowClear
        />
      )}
      <Button iconName="fa:edit" type="primary" onClick={() => setIsDataSrcManagerOpen(true)} className="shrink-0">
        {userData?.name ?? 'Edit'}
      </Button>
      <Modal
        title="Data Source Manager"
        open={isDataSrcManagerOpen}
        width={600}
        onOk={handleOk}
        onCancel={() => setIsDataSrcManagerOpen(false)}
      >
        <DataSourceManager onChange={setSelectedUserData} value={selectedUserData} />
      </Modal>
    </div>
  )
}
