import { Select } from '@/ui'
import { Form, Input } from 'antd'
import { Fragment, useEffect } from 'react'
import { useBaseReportDisplayfieldSetOptions, useTransformationTaskTabsOptions } from '../../../../hooks'

export const DeleteRow = () => {
  const form = Form.useFormInstance()
  const tabsOptions = useTransformationTaskTabsOptions()
  const displayfieldSetOptions = useBaseReportDisplayfieldSetOptions()

  // set empty filter on mount if there is none
  useEffect(() => {
    const filters = form.getFieldValue(['params', 'filters'])

    if (!filters) {
      form.setFieldValue(['params', 'filters'], [{ field: '', operator: '', value: '', exclude: false }])
    }
  }, [form])

  return (
    <>
      <Form.Item name={['params', 'base_ds']} label="Base DataSet">
        <Select options={tabsOptions} />
      </Form.Item>
      <Form.List name={['params', 'filters']}>
        {(fields) =>
          fields.map((field) => (
            <Fragment key={field.name}>
              <Form.Item name={[field.name, 'field']} label="Field" rules={[{ required: true }]}>
                <Select options={displayfieldSetOptions} />
              </Form.Item>
              <Form.Item name={[field.name, 'operator']} label="Logic Type" rules={[{ required: true }]}>
                <Select options={Object.entries(OPERATORS).map(([value, label]) => ({ value, label }))} />
              </Form.Item>
              <Form.Item name={[field.name, 'value']} label="Value" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name={[field.name, 'exclude']}
                getValueProps={(value) => ({ value: Number(value) })}
                getValueFromEvent={(value) => Boolean(value)}
              >
                <Select
                  className="!w-auto"
                  options={[
                    {
                      label: 'Keep',
                      value: 0
                    },
                    {
                      label: 'Delete',
                      value: 1
                    }
                  ]}
                />
              </Form.Item>
            </Fragment>
          ))
        }
      </Form.List>
    </>
  )
}

const OPERATORS = {
  eq: 'Equals',
  neq: 'Not Equals',
  gt: 'Greater Than',
  lt: 'Less Than',
  gte: 'Greater Than Equal',
  lte: 'Less Than Equal'
} as const
