import { useApp, useModalState, useSession } from '@/hooks'
import { jobCodeApi } from '@/services/api-service'
import { ActionButton, ButtonProps, Modal } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { FC } from 'react'
import { JobCode } from '../schemas'

type Props = ButtonProps & {
  jobCode?: JobCode
}

export const ModalButton: FC<Props> = ({ jobCode, ...buttonProps }) => {
  const { company } = useSession()
  const { l, notification } = useApp()
  const [form] = Form.useForm()
  const { isRender, renderModal, isOpen, openModal, closeModal } = useModalState()

  const saveMutation = useMutation(jobCode?.id ? jobCodeApi.patch() : jobCodeApi.create())

  const handleSave = async () => {
    const data = await form.validateFields()

    if (!jobCode?.id) data.company = company.id

    await saveMutation
      .mutateAsync(data)
      .then(() => {
        closeModal()
        notification.success({
          message: `${l('JobCode')} Saved`,
          description: `${l('JobCode')} ${data.user_job_code_no} has been saved`
        })
      })
      .catch(() => {
        notification.error({
          message: `${l('JobCode')} Error`,
          description: `Failed to save ${l('JobCode')} ${data.user_job_code_no}`
        })
      })

    if (!jobCode?.id) form.resetFields()
  }

  return (
    <>
      <ActionButton onClick={openModal} onFocus={renderModal} onMouseEnter={renderModal} {...buttonProps} />
      {isRender && (
        <Modal
          open={isOpen}
          width={500}
          title={jobCode?.id ? 'Update ' + jobCode?.user_job_code_no : `Add ${l('JobCode')}`}
          onOk={handleSave}
          okButtonProps={{ loading: saveMutation.isLoading, 'data-cy': 'save-button' }}
          okText="Save"
          cancelText="Cancel"
          onCancel={closeModal}
        >
          <Form form={form} initialValues={jobCode} layout="vertical">
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item
              label={`${l('JobCode')} Number`}
              name="user_job_code_no"
              rules={[{ required: true }]}
              data-cy="user-job-code-no-input"
            >
              <Input />
            </Form.Item>
            <Form.Item label={'External ID'} name="external_id" data-cy="user-job-code-external-id">
              <Input />
            </Form.Item>
            <Form.Item label={'Description'} name="description" data-cy="user-job-code-description-input">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
