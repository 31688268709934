import { parse, stringify } from 'yaml'

export const yamlParse = parse
export const yamlStringify = stringify

export const yamlParseSafe = (data: string, options?: any) => {
  try {
    return yamlParse(data, options)
  } catch (error) {
    return undefined
  }
}

export const yamlStringifySafe = (data: any, options?: any) => {
  try {
    return yamlStringify(data, options)
  } catch (error) {
    return undefined
  }
}
