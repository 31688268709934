import { colIndexAtom } from '@/components/calendar-table/atoms'
import { CalendarTableVisibleDays } from '@/components/calendar-table/types'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import { useAtom } from 'jotai/react'

type Props = {
  date: Dayjs
  highlightDate?: Dayjs
  index: number
  visibleDays: CalendarTableVisibleDays
}

export const HeadDayCell = ({ date, index, visibleDays, highlightDate }: Props) => {
  const [colIndex] = useAtom(colIndexAtom)
  const isHighlight = highlightDate && date.isSame(highlightDate, 'day')

  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-col bg-white border-0 border-solid border-b border-border items h-52 items-center border-t-2 hover:border-t-primary hover:bg-gray-200',
        {
          'border-t-primary': isHighlight || colIndex === index,
          'bg-gray-200': colIndex === index,
          'w-full': visibleDays === 'week' || visibleDays === 'three_days',
          'w-50 shrink-0': visibleDays === 'month',
          'text-primary': isHighlight,
          'border-t-transparent': !isHighlight && colIndex !== index
        }
      )}
    >
      <span>{date.format('dd')}</span>
      <span>{date.format('D')}</span>
    </div>
  )
}
