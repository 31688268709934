import { useApp } from '@/hooks'
import { Button } from '@/ui/button'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export const TextForEmail = ({ username, firstName }: { username: string; firstName: string }) => {
  const { notification } = useApp()
  const [copy, setCopy] = useState(false)
  const handleCopy = () => {
    setCopy(true)
    notification.success({
      message: 'Copied'
    })
  }
  const contentToShow = `Subject: Oil Command Web Application Login Instructions

Hello ${firstName},

We have created an Oil Command user login for you.

Please log in at <a href="https://app.oilcommand.com" target="__blank" class="underline text-gray-500">app.oilcommand.com</a>.
You can also log in at the top of OilCommand.com by hitting “Login”.
Here is your information.
Username: <strong>${username}</strong>
Password: XXXX

Additionally, you can use the reset function to set your own password
if you have an email address in your user profile.

Thanks!
Oil Command Support
`
  const contentToCopy = `Subject: Oil Command Web Application Login Instructions

Hello ${firstName},

We have created an Oil Command user login for you.

Please log in at https://app.oilcommand.com.
You can also log in at the top of OilCommand.com by hitting “Login”.
Here is your information.
Username: ${username}
Password: XXXX

Additionally, you can use the reset function to set your own password
if you have an email address in your user profile.

Thanks!
Oil Command Support
`
  return (
    <div>
      <div className="whitespace-wrap">
        <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: contentToShow }}></div>
      </div>
      <div className="mt-16 flex justify-end">
        <CopyToClipboard text={contentToCopy} onCopy={handleCopy}>
          <Button type="primary" iconName="mi:content_copy">
            {copy ? 'Copied' : 'Copy'}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
