import { Button } from '@/ui'
import { useState } from 'react'
import { RemoveTicketModal } from './remove-ticket-modal'

export const SummaryRemoveTicket = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button primary size={'small'} onClick={() => setIsOpen(true)}>
        Remove Ticket
      </Button>
      {isOpen && <RemoveTicketModal onCancel={() => setIsOpen(false)} />}
    </>
  )
}
