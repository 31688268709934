import { officeRelationApi } from '@/services/api-service'
import { ListParams } from '@/types/api/core'
import { useQuery } from '@tanstack/react-query'
import { useSession } from './use-session'

type OfficeRelationProps = {
  from_office: number | null
  from_office__company: number | null
  from_office__name: string | null
  id: number
  manager: boolean
  modified_at: Date | null
  preferred: boolean
  to_office: number | null
  to_office__company: number | null
  to_office__name: string | null
}

export const useOfficeRelations = () => {
  const { user } = useSession()
  const query = useQuery(
    officeRelationApi.list<OfficeRelationProps>({
      manager__eq: 'True',
      to_office_id__eq: user?.profile?.office?.id
    })
  )

  return {
    ...query,
    relations: query.data?.items || []
  }
}

export const useReverseOfficeRelations = <T>(params?: ListParams) => {
  const { user } = useSession()

  const query = useQuery(
    officeRelationApi.list<T>({
      from_office_id__eq: user?.profile?.office?.id,
      limit: 'None',
      ...params
    })
  )

  return {
    ...query,
    relations: query.data?.items || []
  }
}
