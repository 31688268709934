import { NOTIFICATION_TEMPLATE_TYPE } from '@/constants/general'
import { Tag } from 'antd'
import { Dayjs } from 'dayjs'
import { FilterNames, filters } from '../../constants'

type Props = {
  appliedFilters?: Record<FilterNames, any>
  onClear: (name: FilterNames) => void
}

export const AppliedFilters = ({ onClear, appliedFilters }: Props) => {
  return (
    <div className="flex gap-x-2">
      {filters.map(({ label, name }) => {
        let filterValue = appliedFilters?.[name]

        if (label === 'Date Range' && Array.isArray(filterValue)) {
          filterValue = filterValue.map((date: Dayjs) => date.formatLocalDate()).join(' - ')
        }

        if (label === 'Channel') {
          filterValue = NOTIFICATION_TEMPLATE_TYPE[filterValue as keyof typeof NOTIFICATION_TEMPLATE_TYPE]
        }

        if (filterValue) {
          return (
            <Tag
              key={name}
              bordered
              closable
              className="px-10 py-6 text-normal text-gray-400 rounded-full"
              onClose={() => onClear(name)}
            >
              <span className="pr-7">
                {label}: {filterValue}
              </span>
            </Tag>
          )
        }

        return null
      })}
    </div>
  )
}
