import { useApp } from '@/hooks'
import { lineItemApiV2 } from '@/services/api-service'
import { Button, Popconfirm } from '@/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { useButtonContext } from '../../../hooks'

const lineItemSchema = z.object({
  id: z.number(),
  units_used: z.number().nullish()
})

export const CleanLineItemsButton = () => {
  const { ticket, property, buttonProps } = useButtonContext()
  const lineItemsQuery = useQuery({ ...lineItemApiV2.list({ ticket_id__eq: ticket.id, fields: lineItemSchema }) })
  const removeLineItemMutation = useMutation(lineItemApiV2.delete())
  const { notification } = useApp()

  const handleCleanLineItems = async () => {
    const lineItems = lineItemsQuery.data?.items || []
    const lineItemsToRemove = lineItems.filter((li) => !li.units_used)
    const promises = lineItemsToRemove.map((li) => removeLineItemMutation.mutateAsync({ id: li.id }))
    await Promise.all(promises)
    notification.success({ message: 'Cleaned blank line items successfully' })
  }

  return (
    <Popconfirm
      title="Clean blank line items"
      description="Are you sure? This will remove line items which do not have quantity."
      onConfirm={handleCleanLineItems}
    >
      <Button {...buttonProps}>{buttonProps.children ? buttonProps.children : property.name}</Button>
    </Popconfirm>
  )
}
