import { Card as AntCard, CardProps } from 'antd'

export const Card = ({ styles, ...props }: CardProps) => {
  const { body, header } = styles || {}

  return (
    <AntCard
      style={{ borderColor: '#d9d9d9' }}
      styles={{ ...styles, header: { ...header, backgroundColor: '#FBFBFB', borderColor: '#d9d9d9' } }}
      {...props}
    />
  )
}
