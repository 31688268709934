import { useApp } from '@/hooks'
import { tableViewApi } from '@/services/api-service'
import { TableView } from '@/types/table-view'
import { AppLink } from '@/ui/AppLink'
import { Button } from '@/ui/button'
import { useMutation } from '@tanstack/react-query'
import { FormInstance, Space } from 'antd'
import { isAxiosError } from 'axios'
import { useLocation } from 'react-router-dom'
import { TableViewFormValues } from '../types'

type Props = {
  form: FormInstance<TableViewFormValues>
  tableView?: TableView
  onMutationSuccess?: () => Promise<any>
}

export const Footer = ({ form, tableView, onMutationSuccess }: Props) => {
  const updateTableViewMutation = useMutation({ ...tableViewApi.patch() })
  const { notification } = useApp()
  const location = useLocation()

  const handleSave = async () => {
    try {
      const { custom_forms, selected_fields, config, ...values } = await form.validateFields()

      await updateTableViewMutation.mutateAsync({
        ...values,
        config: {
          ...config,
          custom_filters: JSON.parse(config?.custom_filters || '{}')
        },
        options: {
          custom_forms: custom_forms?.filter(({ checked }) => checked).map(({ id }) => id)
        },
        selected_fields: selected_fields?.map(
          ({
            id,
            property_id,
            name,
            field,
            field_verbose,
            type,
            path,
            path_verbose,
            custom_name,
            editable,
            extra
          }) => ({
            id,
            property_id,
            name,
            field,
            field_verbose,
            type,
            path,
            path_verbose,
            custom_name,
            editable,
            extra
          })
        )
      })

      await (onMutationSuccess && onMutationSuccess())

      notification.success({
        message: 'View updated'
      })
    } catch (err) {
      if (isAxiosError(err)) {
        notification.error({
          message: 'Something went wrong'
        })
      }
      console.error(err)
    }
  }

  return (
    <div className="flex w-full">
      <Space className="ml-auto">
        <AppLink to={location.state?.from ? location.state.from : '/'} v={2}>
          <Button iconName="mi:close">Cancel</Button>
        </AppLink>
        <Button iconName="mi:save" type="success" onClick={handleSave} loading={updateTableViewMutation.isLoading}>
          Save
        </Button>
      </Space>
    </div>
  )
}
