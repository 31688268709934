import { PropertySelect } from '@/components'
import { ACCOUNTING_SERVICES } from '@/modules/ticket/form/constants'
import { Select } from '@/ui'
import { ScriptedButtonConfig } from '../../types'
import { AccountingSubmitButton } from './AccountingSubmitButton'
import { VarsType } from './types'

export const AccountingSubmitButtonConfig: ScriptedButtonConfig<VarsType> = {
  type: 'common_accounting_submit_button',
  iconName: 'fa:money-check-alt',
  label: 'Accounting Submit',
  group: 'Common',
  render: () => <AccountingSubmitButton />,
  vars: [
    {
      name: 'service',
      label: 'Service',
      rules: [{ required: true, message: 'Service is required' }],
      render: (customForm, props) => (
        <Select options={ACCOUNTING_SERVICES.map((s) => ({ value: s.key, label: s.label }))} {...props} />
      )
    },
    {
      name: 'submittedDatePid',
      label: 'Submitted Date Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    },
    {
      name: 'emptyPid',
      label: 'Empty Prop',
      render: (form, props) => <PropertySelect formId={form.id} {...props} />
    }
  ]
}
