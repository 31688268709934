import { Property } from '@/types/property'
import { Icon } from '@/ui/icons'
import { safeJsonParse } from '@/utils'
import { Tooltip } from 'antd'
import { default as classNames, default as cn } from 'classnames'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { propertiesKeysAtom } from '../../atoms'
import { SCRIPT_FIELDS } from '../constants'
import { checkKeyHasDuplicates } from '../helpers'

type Props = {
  property: Property & { couldNotFit?: boolean }
  onClick: (property: Property) => void
  className?: string
}

export const PropertyBox = ({ property, onClick, className }: Props) => {
  const width = property.layout?.includes(',') ? property.layout.split(',')[0] : property.layout
  const elementType = property.property_type?.name
  const propertiesKeys = useAtomValue(propertiesKeysAtom)

  const data = useMemo(() => {
    const isScriptedButton = property.property_type?.name === 'ScriptedButton'
    const isV1ScriptedButton = !!(isScriptedButton && safeJsonParse(property.binding_json ?? '{}')?.web)
    const isV2ScriptedButton = isScriptedButton && !!property.button_config?.type

    return {
      isScriptedButton,
      isV1ScriptedButton,
      isV2ScriptedButton
    }
  }, [property])

  return (
    <>
      <div
        style={{
          width: `${width}%`
        }}
        className={cn('bg-white p-2 mb-6', className)}
        onClick={() => onClick(property)}
        data-cy="property-box"
      >
        <div
          className={classNames(
            'px-6 py-2 border border-border rounded font-semibold hover:bg-gray-50 cursor-pointer',
            {
              'bg-green-50 !border-green-500 text-green-500 hover:!bg-green-100':
                property.property_type?.name === 'Automation',
              'bg-blue-50 !border-blue-500 text-blue-500 hover:!bg-blue-100': SCRIPT_FIELDS.includes(
                property.property_type?.name ?? ''
              ),
              'opacity-25': property.hide_on_web
            }
          )}
        >
          <div className="flex flex-col 2xl:flex-row 2xl:justify-between items-start 2xl:items-center flex-wrap">
            <span className="font-bold">{property.sequence}</span>
            <div className="flex items-center gap-[10px] flex-wrap">
              <span
                className={classNames('rounded-full text-10 px-6 bg-gray-200 shrink-0', {
                  '!bg-green-500 !text-white': property.property_type?.name === 'Automation',
                  '!bg-blue-500 !text-white': SCRIPT_FIELDS.includes(property.property_type?.name ?? '')
                })}
              >
                ID: {property.id}, W: {width}%
              </span>
              {property.notes && (
                <Tooltip title={property.notes}>
                  <div className="h-[20px] mr-4 cursor-pointer">
                    <Icon name="fa:info-circle" className="text-primary text-15" />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex items-center">
            {property.couldNotFit && (
              <Tooltip title="This element could not fit in the previous row">
                <div className="h-[20px] mr-8 cursor-pointer">
                  <Icon name="fa:warning" className="text-red-500 text-20" />
                </div>
              </Tooltip>
            )}
            {checkKeyHasDuplicates(property.id, property.key, propertiesKeys) && (
              <Tooltip title="Property's key is not unique among custom form's properties">
                <div className="h-[20px] mr-8 cursor-pointer">
                  <Icon name="fa:warning" className="text-warning-500 text-20" />
                </div>
              </Tooltip>
            )}
            {(property.use_binding || property.is_managed) && (
              <Tooltip title="The choices on this dropdown are populated from a data binding configuration">
                <div className="h-[20px] mr-8 cursor-pointer">
                  <Icon name="mi:link" className="text-20" />
                </div>
              </Tooltip>
            )}
            <div>
              <span>
                {property.name ?? 'Unnamed Property'} ({elementType})
              </span>
              <div className={'flex gap-4'}>
                {data.isV1ScriptedButton && (
                  <span className={'rounded-full px-10 border border-blue-500 text-xs text-nowrap'}>v1</span>
                )}
                {data.isV2ScriptedButton && (
                  <span className={'rounded-full px-10 border border-blue-500 text-xs text-nowrap'}>v2</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {property.layout?.includes(',') && (
        <div
          style={{
            width: `${property.layout.split(',')[1]}%` // second part is for the skip
          }}
          className={classNames('bg-white p-2', {
            'opacity-25': property.hide_on_web
          })}
        >
          <div className="flex items-center justify-between p-6 border border-border rounded bg-gray-200 min-h-[36px]"></div>
        </div>
      )}
    </>
  )
}
