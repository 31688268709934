import { ALL_FIELDS } from '../constants'
import { CustomForm, Ticket, TicketProperty } from '../schemas'
import { parseTicketField, parseTicketProperty } from './parsers'

export const ticketToFieldsValue = (ticket: Ticket, customForm: CustomForm) => {
  const fieldValues: Record<string, any> = {}

  // transform ticket fields to form fields
  ALL_FIELDS.forEach((key) => {
    fieldValues[key] = parseTicketField(ticket, key)
  })

  // transform ticket properties to form fields
  ticket.properties.forEach((ticketProperty: TicketProperty) => {
    const property = customForm._propertyById[ticketProperty.property_id]
    if (!property) return

    fieldValues[`p_${ticketProperty.property_id}`] = parseTicketProperty(property, ticketProperty.value)
  })

  return fieldValues
}
