import { useApp } from '@/hooks'
import { LINE_ITEM_COLUMNS } from '@/modules/line-item/columns'
import { TICKET_COLUMNS_WITH_PREFIX } from '@/modules/ticket/columns'
import { TableViewSelectedField } from '@/types/table-view'
import { DraggableItem } from '../../types'
import { processPropertyFields } from './process-property-fields'

export const processField = (
  field: TableViewSelectedField | ReturnType<typeof processPropertyFields>[0],
  l: ReturnType<typeof useApp>['l']
) => {
  const id = `${field.path || ''}${field.property_id || field.field || ''}`

  const newField: DraggableItem = {
    ...field,
    id
  }

  if (field.field_verbose === 'property') {
    return newField
  }

  if ('category' in field) {
    newField.category = field.category
    return newField
  }

  // We don't store category in the database.
  // So we need to find it from the columns.
  const column = [...TICKET_COLUMNS_WITH_PREFIX, ...LINE_ITEM_COLUMNS].find(
    (col) => `${col.path || ''}${col.field || ''}` === id
  )

  let label = l('ticket__' + field.field_verbose + '__label', '')

  if (!label) {
    label = l('lineitem__' + field.field_verbose, newField.name)
  }

  newField.category = column?.category
  newField.name = label
  return newField
}
