import { inventoryIndicatorApi } from '@/services/api-service'
import { InventoryIndicator } from '@/types/inventory-indicator'
import { Button, Popconfirm } from '@/ui'
import { useMutation } from '@tanstack/react-query'

type Props = {
  inventoryIndicator: InventoryIndicator
  onDelete: (id: number) => void
}

export const DeleteButton = ({ inventoryIndicator, onDelete }: Props) => {
  const deleteInventoryIndicatorMutation = useMutation(inventoryIndicatorApi.delete())

  const handleDelete = async () => {
    await deleteInventoryIndicatorMutation.mutateAsync({ id: inventoryIndicator.id })
    onDelete(inventoryIndicator.id)
  }

  return (
    <div className="shrink grow">
      <Popconfirm
        title="Delete Indicator"
        description={`Are you sure you want to delete '${inventoryIndicator.name}' indicator?`}
        okText="Yes"
        cancelText="No"
        onConfirm={handleDelete}
      >
        <Button iconName="fa:trash" danger className="w-full">
          Delete
        </Button>
      </Popconfirm>
    </div>
  )
}
