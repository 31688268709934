import { Button, Icon } from '@/ui'
import { FC } from 'react'
import { useActionsHandlers } from '../../hooks'
import { ActionsSortable } from './ActionsSortable'

type Props = {
  ruleIndex: number
}

export const ActionsForm: FC<Props> = ({ ruleIndex }) => {
  const { addAction } = useActionsHandlers(ruleIndex)

  return (
    <div className="">
      <div className="flex flex-row items-center border-b p-10">
        <span>
          <Icon name={'fa:function'} />
          <span className="mx-8">Actions</span>
        </span>
        <div className="flex-grow" />
        <Button data-cy="add-action-button" type="primary" size="small" iconName="fa:add" onClick={() => addAction()}>
          Add Action
        </Button>
      </div>
      <div className="flex flex-col p-10 relative">
        <ActionsSortable ruleIndex={ruleIndex} />
      </div>
    </div>
  )
}
