import { useApp } from '@/hooks'
import { Form, Input } from 'antd'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { equipmentAtom, requiredFieldsErrorsAtom } from '../atoms'

export const SkuField = () => {
  const sku = useAtomValue(useMemo(() => selectAtom(equipmentAtom, (equipment) => equipment.sku), []))
  const { isSkuError } = useAtomValue(requiredFieldsErrorsAtom)
  const setRequiredFieldsErrors = useSetAtom(requiredFieldsErrorsAtom)
  const setEquipment = useSetAtom(equipmentAtom)
  const { l } = useApp()

  return (
    <Form.Item
      label={l('SKU')}
      className="w-full"
      required
      validateStatus={isSkuError ? 'error' : undefined}
      help={isSkuError ? 'Please enter SKU' : undefined}
    >
      <Input
        value={sku}
        data-cy="sku-input"
        onChange={(e) => {
          setEquipment((prev) => ({
            ...prev,
            sku: e.target.value
          }))

          if (isSkuError) {
            setRequiredFieldsErrors((prev) => ({
              ...prev,
              isSkuError: false
            }))
          }
        }}
      />
    </Form.Item>
  )
}
