import { CONTACT_ROLE } from '@/constants/general'
import { useApp, useSession } from '@/hooks'
import { companyApi, contactApi, contactNoteApi, eventApi } from '@/services/api-service'
import { Contact } from '@/types/contact'
import { ContactsNote } from '@/types/contacts-note'
import { QuerySelect } from '@/ui'
import { Button } from '@/ui/button'
import { PageTitle } from '@/ui/page-title'
import { objId } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Form, Input, Select, Space, Spin } from 'antd'
import { isAxiosError } from 'axios'
import cn from 'classnames'
import { isEqual } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { SetOptional } from 'type-fest'
import { ContactsNotesList, EditingNote } from './contact-notes-list'

type Props = {
  id?: string | number
  defaultCompany?: {
    label: string
    value: number
  }
  inModal?: boolean
  onSave?: (contact: any) => void
  onCancel?: () => void
}

export const AddEditContactView: FC<Props> = ({ id, defaultCompany, inModal, onSave, onCancel }) => {
  const [contactId, setContactId] = useState(id)
  const [form] = Form.useForm()
  const { notification } = useApp()
  const [isLoading, setIsLoading] = useState(false)
  const { company: userCompany } = useSession()
  const eventsQuery = useQuery(eventApi.list({ type: 'contact', target: id }))

  const contactQuery = useQuery({
    ...contactApi.get<Contact>(Number(id)),
    enabled: !!id
  })

  const contactNotesQuery = useQuery({
    ...contactNoteApi(id).list<ContactsNote>(),
    enabled: !!id
  })

  const [notes, setNotes] = useState<EditingNote[]>(
    contactNotesQuery.data?.items.map((note) => ({
      ...note,
      isTemp: false
    })) || []
  )

  const contactMutation = id ? contactApi.update() : contactApi.create()

  const upsertContactMutation = useMutation({
    ...contactMutation
  })

  const updateContactNoteMutation = useMutation({
    ...contactNoteApi(contactId).update<any, ContactsNote>()
  })

  const createContactNoteMutation = useMutation({
    ...contactNoteApi(contactId).create<any, SetOptional<ContactsNote, 'id'>>()
  })

  const deleteContactNoteMutation = useMutation({
    ...contactNoteApi(id).delete()
  })

  const handleRemoveNote = async (note: EditingNote) => {
    setNotes((prev) => prev.filter((n) => n.id !== note.id))
  }

  const handleUpdateNote = async (note: EditingNote) => {
    const foundNote = notes.find((n) => n.id === note.id)

    const updatedNote = { ...foundNote, ...note }
    setNotes((prev) => prev.map((n) => (n.id === note.id ? updatedNote : n)))
  }

  const handleAddNote = async (note: EditingNote) => {
    setNotes((prev) => [...prev, note])
  }

  const handleUpsertNotesMutation = async (newContactId = contactId) => {
    const initialNotes = contactNotesQuery.data?.items || []

    const upsertedNotes = notes.filter((n) => {
      const note = initialNotes.find((val) => val.id === n.id)
      return !note || !isEqual(note, n)
    })

    if (!upsertedNotes.length) {
      return
    }

    const mutations = upsertedNotes.map(({ id, isTemp, ...note }) => {
      if (!isTemp) {
        if (typeof id !== 'number') {
          console.error('Note is not temp but id is not a number')
          return
        }

        return updateContactNoteMutation.mutateAsync({ id, ...note })
      }

      return createContactNoteMutation.mutateAsync({ contactId: Number(newContactId), ...note })
    })

    await Promise.all(mutations)
  }

  const handleRemoveNotesMutation = async () => {
    const initialNotes = contactNotesQuery.data?.items || []

    const deletedNotes = initialNotes.filter((note) => {
      return !notes.find((n) => n.id === note.id)
    })

    if (!deletedNotes.length) {
      return
    }

    const mutations = deletedNotes.map((note) => {
      return deleteContactNoteMutation.mutateAsync({ id: note.id })
    })

    await Promise.all(mutations)
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const values = await form.validateFields()

      const upsertedContact = await upsertContactMutation.mutateAsync({
        ...values,
        company: objId(values.company),
        id: id ? Number(id) : undefined
      })

      if (inModal) {
        onSave?.(upsertedContact)
        return
      }

      setContactId(upsertedContact.id)

      // TODO: Workaround for the issue where the contact id is updated asyncronously
      // and in case of a new contact, the api endpoint url will not have the id
      setTimeout(async () => {
        await handleUpsertNotesMutation(upsertedContact.id)
        await handleRemoveNotesMutation()

        if (id) {
          await contactQuery.refetch()
          await contactNotesQuery.refetch()
          await eventsQuery.refetch()
          setIsLoading(false)
          notification.success({
            message: 'Contact Saved',
            description: 'Contact saved successfully'
          })
          return
        }
        form.resetFields()
        setNotes([])
        // navigate(ROLODEX_PATHS.editContact(upsertedContact.id))
        setIsLoading(false)
        notification.success({
          message: 'Contact Saved',
          description: 'Contact saved successfully'
        })
      }, 1000)
    } catch (error) {
      if (isAxiosError(error)) {
        notification.error({
          message: 'Contact Save Failed',
          description: JSON.stringify(error.response?.data, null, 2)
        })

        setIsLoading(false)
        return
      }

      notification.warning({
        message: 'Validation Error',
        description: 'Please fill out all required fields'
      })

      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!contactNotesQuery.data?.items?.length) {
      return
    }

    setNotes(contactNotesQuery.data.items.map((note) => ({ ...note, isTemp: false })))
  }, [contactNotesQuery.data?.items])

  const initialValues = useMemo(() => {
    let companyValue
    if (Number(id)) {
      // existing company,
      companyValue = contactQuery?.data?.company?.id
        ? { value: contactQuery?.data?.company?.id, label: contactQuery.data.company.name }
        : undefined
    } else {
      // default company, or user's company
      companyValue = defaultCompany || { value: userCompany.id, label: userCompany.name }
    }

    const privateCompanyValue = !(contactQuery.data?.id && contactQuery.data?.private_company)
      ? userCompany.id
      : contactQuery.data?.private_company

    return {
      ...contactQuery.data,
      company: companyValue,
      private_company: privateCompanyValue
    }
  }, [contactQuery.data, defaultCompany, id, userCompany.id, userCompany.name])

  if (contactQuery.isInitialLoading) {
    return <Spin spinning className={'flex items-center justify-center h-[600px]'} />
  }

  return (
    <Spin spinning={!!id && (contactQuery.isLoading || upsertContactMutation.isLoading)}>
      {/* <Breadcrumb
        items={[
          {
            title: <AppLink to={ROLODEX_PATHS.contacts}>Contacts</AppLink>
          },
          {
            title: id ? 'Edit Contact' : 'Add Contact'
          }
        ]}
      /> */}

      {!inModal && (
        <div className="flex justify-between mt-12 mb-16">
          <PageTitle>{id ? `Edit (ID: ${id}) Contact` : 'Add Contact'}</PageTitle>
          <Space>
            {/* <AppLink to={ROLODEX_PATHS.contacts}>
            <Button iconName="mi:close" disabled={isLoading}>
              Cancel
            </Button>
          </AppLink> */}
            <Button data-cy="save-contact" type="success" iconName="mi:save" loading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        </div>
      )}

      <Form
        form={form}
        layout="vertical"
        className={cn({ 'relative !h-[600px] overflow-y-auto p-10 pb-0': inModal })}
        validateTrigger="onBlur"
        initialValues={initialValues}
      >
        <Form.Item name="private_company" hidden>
          <Input />
        </Form.Item>
        <div className="flex flex-wrap">
          <div className="w-full">
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: 'Please enter a first name' }]}
            >
              <Input data-cy="first-name-input" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: 'Please enter a last name' }]}
            >
              <Input data-cy="last-name-input" />
            </Form.Item>
            <Form.Item label="Title" name="title">
              <Input data-cy="title-input" />
            </Form.Item>
            <Form.Item label="Company" name="company" rules={[{ required: true, message: 'Please select company' }]}>
              <QuerySelect
                apiEndpoint={companyApi.list}
                apiQueryParams={{ fields: 'id,name' }}
                apiSearchBy={'name'}
                mapOption={['id', 'name']}
                data-cy="contact-company-select"
              />
            </Form.Item>
            <Form.Item label="Role" name="role">
              <Select
                placeholder="Select a role"
                options={Object.entries(CONTACT_ROLE).map(([value, label]) => ({
                  label,
                  value
                }))}
                data-cy="role-select"
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item label="Phone Number" name="phone_number">
              <Input data-cy="phone-number-input" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input data-cy="email-input" />
            </Form.Item>
            <Form.Item label="External ID" name="external_id">
              <Input data-cy="external-id-input" />
            </Form.Item>
            <Spin
              spinning={
                !!id &&
                (updateContactNoteMutation.isLoading ||
                  createContactNoteMutation.isLoading ||
                  contactNotesQuery.isLoading)
              }
            >
              <ContactsNotesList
                onNoteDeleted={handleRemoveNote}
                onNoteAdded={handleAddNote}
                onNoteEdited={handleUpdateNote}
                notes={notes}
              />
            </Spin>
          </div>
        </div>
        {inModal && (
          <div className="flex justify-end gap-10 sticky bottom-0 pt-10 bg-container">
            <Button disabled={isLoading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type={'primary'} data-cy="save-contact" loading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </div>
        )}
      </Form>
    </Spin>
  )
}
