import { TicketStatus } from '@/modules/ticket/types'
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors'
import { LiteralUnion } from 'antd/es/_util/type'
import dayjs from 'dayjs'

export const getAssignedPctColor = (assignedPct: number): LiteralUnion<PresetColorType | PresetStatusColorType> => {
  if (assignedPct === 0) return 'default'
  else if (assignedPct === 100) return 'green'
  else return 'blue'
}

export const getStatusColor = (status: TicketStatus): LiteralUnion<PresetColorType | PresetStatusColorType> => {
  if (status == 'D') return 'orange'
  else if (status == 'A') return 'green'
  else if (status == 'O') return 'blue'
  else if (status == 'S') return 'orange-inverse'
  else if (status == 'B') return 'red-inverse'
  else if (status == 'C') return 'gold-inverse'
  else return 'default'
}

export const getEndDate = (startDate: dayjs.Dayjs, elapsedTime: number): dayjs.Dayjs => {
  return startDate.add(elapsedTime, 'hours')
}
