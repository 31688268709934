export const ActivityItemTimeline = () => {
  return (
    <div className="absolute top-0 bottom-0 left-0 flex justify-center items-center">
      <div className="w-12 h-12 absolute flex items-center justify-center bg-white rounded-full">
        <div className="w-8 h-8 bg-primary rounded-full" />
      </div>
      <div className="w-2 h-full bg-primary" />
    </div>
  )
}
