/**
 * Generates a textual avatar representation from a user's name.
 * The avatar is a combination of the initials of the name, with a limit of 2-3 characters.
 *
 * @param {string} fullName - The full name of the user.
 * @returns {string} - A string containing the initials for the avatar.
 */
export const generateAvatarInitials = (fullName: string): string => {
  // trim whitespace and split the name into parts
  const nameParts = fullName.trim().split(/\s+/) // splits by any whitespace

  // early return for empty names
  if (nameParts.length === 0 || nameParts[0] === '') {
    return ''
  }

  // single word name, take the first two characters
  // e.g. "John" -> "JO"
  if (nameParts.length === 1) {
    return nameParts[0].slice(0, 2).toUpperCase()
  }

  // more than 3 words, take first, second, and last initials
  // e.g. "John Smith Doe Jr." -> "JSJ"
  if (nameParts.length > 3) {
    return (nameParts[0][0] + nameParts[1][0] + nameParts[nameParts.length - 1][0]).toUpperCase()
  }

  // for two or three-word names, take the first letter of each part (up to 3)
  // e.g. "John Doe" -> "JD" or "John Smith Doe" -> "JSD"
  return nameParts
    .map((part) => part[0].toUpperCase())
    .join('')
    .slice(0, 3)
}
