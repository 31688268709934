import { selectedJobRoleAtom } from '@/modules/jas/job-manager/add-resource/atoms'
import { useSelectedResources } from '@/modules/jas/job-manager/add-resource/hooks'
import { JobAvatar } from '@/modules/jas/job-manager/components/job-ticket-detail/JobAvatar'
import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { JobResourceSku, JobRole } from '@/modules/jas/job-manager/types'
import { ModuleLinkButton } from '@/modules/module/components'
import { ViewTicketLink } from '@/modules/ticket/components'
import { JasResourceResponse } from '@/types/jas-resource'
import { Icon } from '@/ui/icons'
import { Alert, Button, Checkbox, Dropdown, Popover, Tag, Tooltip } from 'antd'
import { MenuItemGroupType } from 'antd/es/menu/interface'
import { useAtomValue } from 'jotai/index'
import { cloneDeep, groupBy, isEmpty, uniq } from 'lodash'
import React, { useMemo } from 'react'

export const ResourceCalenderTitleCell: React.FC<{ resource: JasResourceResponse }> = ({ resource }) => {
  const { getJobRoleName, jobRolesMap } = useJobRoles()
  const { selectedResources, addResource, removeResource } = useSelectedResources()
  const isResourceSelected = !!selectedResources[resource.id]
  const selectedSkuId = isResourceSelected ? resource.assigned_sku?.id : null

  const selectedJobRole = useAtomValue(selectedJobRoleAtom)
  const skills = uniq(Object.values(resource.attributes || {}))

  // process skus and roles
  const [skus, byRole, availableRoles] = useMemo(() => {
    const _skus = selectedJobRole.length
      ? resource.skus.filter((e) => e.job_role_id && selectedJobRole.includes(e.job_role_id))
      : resource.skus.filter((e) => e.job_role_id)
    const _byRole: Record<number, JobResourceSku[]> = groupBy(_skus, 'job_role_id')
    const _availableRoles: JobRole[] = Object.keys(_byRole)
      .map((k) => jobRolesMap[Number(k)])
      .filter(Boolean)

    return [_skus, _byRole, _availableRoles]
  }, [jobRolesMap, resource, selectedJobRole])

  const missingSku = skus.length === 0
  const multipleSkus = skus.length > 1

  const toggleResource = (sku: JobResourceSku | null) => {
    if (isResourceSelected) {
      removeResource(resource)
      if (!sku || sku?.id === selectedSkuId) return
    }

    if (missingSku) return

    if (sku) {
      resource.assigned_sku = cloneDeep(sku)
      addResource(resource)
      return
    } else if (!multipleSkus) {
      resource.assigned_sku = cloneDeep(skus[0])
      addResource(resource)
    }
  }

  const dropdownValues: MenuItemGroupType[] = Object.entries(byRole).map(([roleId, equipments]) => ({
    key: roleId,
    type: 'group' as any,
    label: `${getJobRoleName(Number(roleId))} SKUs`,
    children: equipments.map((e) => ({
      key: `${roleId}-${e.id}`,
      label: (
        <Checkbox key={e.id} checked={e.id === selectedSkuId} onChange={() => toggleResource(e)}>
          {e.sku} / {e.description}
        </Checkbox>
      )
    }))
  }))

  const jobRoleName = resource?.job_roles?.[0]?.name // take first

  return (
    <div className="w-full h-full flex flex-row items-center justify-start" key={resource.id}>
      <div className={'w-2/12 text-center'}>
        <Dropdown
          arrow={true}
          menu={{ items: dropdownValues }}
          trigger={multipleSkus && !isResourceSelected ? ['click'] : []}
          disabled={missingSku}
        >
          <div>
            <Checkbox checked={isResourceSelected} onChange={() => toggleResource(null)} disabled={missingSku} />
          </div>
        </Dropdown>
      </div>
      <div className={'w-10/12 pr-4'}>
        <Popover
          mouseEnterDelay={0.25}
          overlayStyle={{
            width: 350
          }}
          placement="right"
          title={
            <div className="flex flex-row align-middle justify-between py-4 border-b border-b-gray-100">
              <div className="flex flex-row items-center align-middle">
                <JobAvatar resource={resource} />
                <div className="flex flex-col ml-6">
                  <span>{resource.name}</span>
                  <small className={'font-normal'}>{jobRoleName}</small>
                </div>
              </div>

              <Tooltip title={`Edit ${resource.type}`}>
                <ModuleLinkButton
                  by={'type'}
                  value={'RM'}
                  size={'small'}
                  iconName={'fa:external-link'}
                  target={'_blank'}
                  urlSuffix={`/resources/employees/${resource.id}/edit`}
                >
                  Edit
                </ModuleLinkButton>
                <ViewTicketLink ticketId={resource.ticket} formId={0} target="_blank">
                  <Button size="small" icon={<Icon name={'fa:external-link'} />}>
                    Edit
                  </Button>
                </ViewTicketLink>
              </Tooltip>
            </div>
          }
          content={
            <div className="flex flex-col">
              <div className="mb-6">
                <b>Skills:</b>
                <br />{' '}
                <span>
                  {skills.map((skill) => (
                    <Tag className="mb-4" key={skill}>
                      {skill}
                    </Tag>
                  ))}
                  {skills.length === 0 && <span>No Skills</span>}
                </span>
              </div>
              <div className="mb-6">
                <b>Job Roles SKU:</b>
                <br />
                <div>
                  {Object.entries(byRole).map(([roleId, equipments]) => (
                    <div key={roleId}>
                      {equipments.map((e) => (
                        <Checkbox key={e.id} checked={e.id === selectedSkuId} onChange={() => toggleResource(e)}>
                          <div className="flex flex-row align-middle justify-between">
                            <div>
                              <Tooltip title={getJobRoleName(Number(roleId))}>
                                <Tag className="m-0" color="default">
                                  {getJobRoleName(Number(roleId))[0]}
                                </Tag>
                              </Tooltip>
                            </div>
                            <div className="px-4">{e.description}</div>
                            <div>
                              <Tooltip title={'Edit SKU'}>
                                <Button
                                  target="_blank"
                                  href={`/records/skus/${e.id}/edit`}
                                  size="small"
                                  icon={<Icon name={'fa:pencil'} />}
                                ></Button>
                              </Tooltip>
                            </div>
                          </div>
                        </Checkbox>
                      ))}
                    </div>
                  ))}
                </div>
                {missingSku && (
                  <div>
                    <Alert type="error" message="Missing SKU: Cannot be assigned to job" showIcon />
                  </div>
                )}
              </div>
            </div>
          }
        >
          <span className="flex font-semibold text-gray-500 text-md">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden">{resource.name}</span>
            <div className="bg-container dark:bg-dark-container rounded">
              {availableRoles.map((role) => (
                <Tooltip key={role.id} title={role.name}>
                  <Tag key={role.id} className="scale-75 mr-0" color="default">
                    {role.name[0]}
                  </Tag>
                </Tooltip>
              ))}
            </div>
          </span>
          <span className="block py-2 text-gray-400 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
            {isEmpty(skills) ? 'No skills' : skills.join(', ')}
          </span>
        </Popover>
      </div>
    </div>
  )
}
