import { z } from 'zod'

export const AfeSchema = z.object({
  id: z.string(),
  user_afe_no: z.string(),
  budget: z.number(),
  external_id: z.string(),
  modified_at: z.string()
})

export type Afe = z.infer<typeof AfeSchema>
