import { Button } from '@/ui/button'
import { filterSelectOption } from '@/utils/filter-select-option'
import { Form, Select, Space } from 'antd'
import { useMemo } from 'react'
import { useCategoriesQuery } from '../../hooks'

type Props = {
  category: number
  subCategory?: number
  onChange?: (category: number) => void
  onSubCategoryChange?: (subCategory: number) => void
  onClear?: () => void
}

export const CategoriesSelect = ({ onChange, onSubCategoryChange, category, subCategory }: Props) => {
  const { categoriesQuery } = useCategoriesQuery()

  const categoriesOptions = useMemo(
    () => [
      {
        label: 'All Categories',
        value: -1
      },
      ...(categoriesQuery.data?.items
        .filter((category) => !category.parent_category)
        .map((category) => ({
          label: category.name,
          value: category.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label, 'en')) ?? [])
    ],
    [categoriesQuery.data]
  )

  const subCategoriesOptions = useMemo(() => {
    if (category === -1) {
      return []
    }

    const filteredCategories =
      categoriesQuery.data?.items.filter(({ parent_category }) => parent_category?.id === category) ?? []

    const subCategories = [
      {
        label: 'All',
        value: -1
      },
      ...filteredCategories
        .map((category) => ({
          label: category.name,
          value: category.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label, 'en'))
    ]

    return subCategories
  }, [category, categoriesQuery.data?.items])

  return (
    <Form layout="vertical">
      <Space align="end">
        <Form.Item label="Category" className="w-[200px] mb-0">
          <Select
            loading={categoriesQuery.isLoading}
            style={{
              width: '100%'
            }}
            value={category}
            options={categoriesOptions}
            showSearch
            filterOption={filterSelectOption}
            onChange={onChange}
          />
        </Form.Item>
        {
          // Only show sub category if there is a category selected
        }
        {category !== -1 && (
          <Form.Item label="Sub Category" className="w-[200px] mb-0">
            <Select
              style={{
                width: '100%'
              }}
              loading={categoriesQuery.isLoading}
              value={subCategory}
              options={subCategoriesOptions}
              showSearch
              filterOption={filterSelectOption}
              onChange={(value) => {
                if (value === -1) {
                  onSubCategoryChange?.(value)
                  return
                }

                const subCategory = categoriesQuery.data?.items.find((category) => category.id === value)

                if (!subCategory) {
                  console.warn(`Sub category with id ${value} not found in categories array`)
                  return
                }

                onSubCategoryChange?.(subCategory.id)
              }}
            />
          </Form.Item>
        )}
        <Button type="primary">Clear</Button>
      </Space>
    </Form>
  )
}
