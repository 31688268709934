import { Icon, IconName } from '@/ui/icons'
import { Button as AntButton, ButtonProps as AntDButtonProps } from 'antd'
import { ButtonType as AntButtonType } from 'antd/es/button'
import cn from 'classnames'
import React from 'react'

type ButtonType = {
  type?:
    | AntButtonType

    /**
     * @deprecated: use success={true} instead
     * because antd treats type more like a button style
     * (default as outline, primary as filled, dashed, link, text, etc.)
     */
    | 'success'
}

type ButtonColors = {
  danger?: boolean
  warning?: boolean
  info?: boolean
  success?: boolean
  primary?: boolean
}

type ButtonIcon =
  | { icon: React.ReactNode; iconName?: IconName; iconClassName?: string }
  | { icon?: React.ReactNode; iconName: IconName; iconClassName?: string }
  | { icon?: React.ReactNode; iconName?: IconName; iconClassName?: string }

export type ButtonProps = Omit<AntDButtonProps, 'type'> & ButtonType & ButtonColors & ButtonIcon

/**
 * Extends AntD Button component with extra functionality.
 *
 *
 * ###  ButtonColors ###
 * Supports success and info color variants.
 *  - For filled success or info buttons, use type="primary" and success={true} or info={true}
 *  - For outlined success or info buttons, use type="default" and success={true} or info={true}
 *
 * The `ButtonColors` type enforces that only one of the 'danger', 'success', or 'info' flags can be true at a time,
 * or all of them can be false or undefined, but not simultaneously true.
 *  - `danger`: A boolean flag indicating a danger state. If true, both 'success' and 'info' must be false or not set.
 *  - `success`: A boolean flag indicating a success state. If true, both 'danger' and 'info' must be false or not set.
 *  - `info`: A boolean flag indicating an informational state. If true, both 'danger' and 'success' must be false or not set.
 */
export const Button: React.FC<ButtonProps> = ({
  type,
  danger,
  warning,
  info,
  success,
  primary,
  iconName,
  iconClassName,
  icon,
  className,
  onClick,
  children,
  ...rest
}) => {
  // temporary: type=success means filled/primary green button
  if (type === 'success') {
    success = true
    type = 'primary'
  }

  if (iconName) {
    /**
     * TODO: depreciate material, and svg icons. only support font-awesome font icon.
     *  and svg must be last resort
     */

    const isFaIcon = iconName?.startsWith('fa')

    icon = (
      <Icon
        className={cn(
          {
            'text-16': rest.size === 'small' && !iconName?.startsWith('svg:') && !isFaIcon,
            '[&>svg]:w-16 [&>svg]:h-16': rest.size === 'small' && iconName?.startsWith('svg:') && !isFaIcon,
            'text-sm': rest.size === 'small',
            'text-xl': rest.size === 'large'
          },
          iconClassName
        )}
        name={iconName}
      />
    )
  }

  return (
    <AntButton
      type={type}
      onClick={onClick}
      danger={danger}
      className={cn(
        'inline-flex items-center justify-center',
        {
          'ant-btn-warning': warning,
          'ant-btn-info': info,
          'ant-btn-success': success,
          'ant-btn-primary-alt': primary
        },
        className
      )}
      icon={icon}
      {...rest}
    >
      {children}
    </AntButton>
  )
}
