import { propertyApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Property } from '../types'

export const usePropertiesQuery = () => {
  const propertiesQuery = useQuery(
    propertyApi.list<Property>({
      is_managed__eq: 1,
      order: 'name',
      fields: 'id,name,property_group__custom_form__name,property_group__name,link,links,choices_sort',
      limit: 'None'
    })
  )

  // remove duplicates
  const properties =
    propertiesQuery.data?.items.reduce((acc, property) => {
      if (acc.find((p) => p.id === property.id)) {
        return acc
      } else {
        acc.push(property)
        return acc
      }
    }, [] as Property[]) ?? []

  return {
    propertiesQuery,
    properties
  }
}
