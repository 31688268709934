import { useCustomFormLabels } from '@/modules/custom-form/hooks'
import { Select, SelectProps } from '@/ui/select'
import { OptionHelp } from '@/ui/select/option-help'
import { useAtomValue } from 'jotai'
import { FC } from 'react'
import { customFormAtom } from '../../atoms'
import { TICKET_FIELDS } from '../../constants'

type Props = Omit<SelectProps, 'options' | 'filterBy'>

export const TicketFieldSelect: FC<Props> = (props) => {
  const customForm = useAtomValue(customFormAtom)
  const { l } = useCustomFormLabels(customForm?.id)

  return (
    <Select
      filterBy={'text'}
      options={TICKET_FIELDS.map((f) => ({
        value: f.value,
        text: l(`ticket__${f.value}__label`) + ' ' + f.label,
        label: <OptionHelp label={l(`ticket__${f.value}__label`, f.label)} tipTitle={`Default: ${f.label}`} />
      }))}
      {...props}
    />
  )
}
