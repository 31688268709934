import { JmConfig } from '@/modules/module/types'
import { TicketProperty } from '@/modules/ticket/types'
import { Obj } from '@/types/general'
import dayjs from 'dayjs'
import { JobRole, JobTicket } from '../types'
import { getAssignedPctColor, getAssignedPercent, getEndDate, getStatusColor } from './formatters'
import { parseAttributes, parseRequirements } from './parsers'
import { getResourcesByRole, getResourcesByType } from './resources'

export const processJobTicket = (
  ticket: JobTicket,
  jobRolesMap: Record<number, JobRole>,
  config: JmConfig,
  statusLabels: Obj
): JobTicket => {
  if (ticket.isProcessed) return ticket

  // ticket property value lookup by property id
  const tpMap = (ticket?.ticket_properties || []).reduce((acc: Obj, tp: TicketProperty) => {
    acc[tp.property] = tp.value
    return acc
  }, {})
  ticket.propertyById = tpMap

  // ticket property value lookup by property key
  ticket.propertyByKey = (ticket?.ticket_properties || []).reduce((acc: Obj, tp: TicketProperty) => {
    acc[tp.property__key] = tp.value
    return acc
  }, {})

  // parse start date
  const startDate = dayjs(tpMap[config.start_date_pid || 0] || ticket.timepoint_due)
  let startDateStr = '--/--/----' // iso string for grouping
  if (startDate.isValid()) startDateStr = startDate.format('YYYY-MM-DD')

  // parse estimated/elapsed hours
  let estimatedHours = Number(tpMap[config.estimated_hours_pid || 0])
  if (isNaN(estimatedHours)) estimatedHours = 0

  // calculate end date
  const endDate = getEndDate(startDate, estimatedHours)
  const endDateIsoStr = endDate.isValid() ? endDate.toISOString() : ''

  // parse requirements
  const requirements = parseRequirements(tpMap[config.requirements_pid || 0], jobRolesMap)
  const requirementsByRole = requirements.byRole
  const requirementsByType = requirements.byType

  // parse resources from events (handles missing)
  const resourcesByRole = getResourcesByRole(ticket, requirementsByRole, jobRolesMap, tpMap)
  const resourcesByType = getResourcesByType(ticket, requirementsByType, jobRolesMap, tpMap)

  // calculate assigned percent
  const assignedPct = getAssignedPercent(requirementsByType, resourcesByType)

  // enrich ticket with job data
  ticket.jobData = {
    id: ticket.id,
    name: ticket.name,
    modified_at: ticket.modified_at || '',
    statusLabel: statusLabels[ticket.status] || ticket.status,
    statusColor: getStatusColor(ticket.status),
    startDateStr: startDateStr,
    startDate: ticket.timepoint_due,
    endDate: endDateIsoStr,
    elapsedTime: estimatedHours,
    assignedPct: assignedPct,
    assignedPctColor: getAssignedPctColor(assignedPct),
    requirements: requirementsByType,
    requirementsByRole: requirementsByRole,
    attributes: parseAttributes(tpMap[config.attributes_pid || 0]),
    title: tpMap[config.title_pid || 0],
    jobType: tpMap[config.job_type_pid || 0],
    description: tpMap[config.description_pid || 0],
    line1: tpMap[config.line_1_pid || 0] || ticket.customer_office__name,
    line2: tpMap[config.line_2_pid || 0] || ticket.location__name,
    line3: tpMap[config.line_3_pid || 0],
    notes: tpMap[config.notes_pid || 0],
    resources: resourcesByType,
    resourcesByRole: resourcesByRole
  }
  ticket.isProcessed = true

  return ticket
}
