import { useSession } from '@/hooks'
import { executeMutation } from '@/query'
import { locationApi } from '@/services/api-service'
import { QuerySelect, QuerySelectProps } from '@/ui'
import { Form, FormItemProps } from 'antd'
import { FC } from 'react'
import { useCustomForm, useTicketFormValues } from '../../../hooks'
import { Label } from '../../ui'

const KEY = 'location'

type Props = {
  formItemProps?: Partial<FormItemProps>
  inputProps?: Partial<QuerySelectProps>
}

export const LocationInput: FC<Props> = ({ formItemProps, inputProps }) => {
  const { company } = useSession()
  const { isDisabledField, isHiddenField } = useCustomForm()
  const { setFieldValue, getPropertyValueByKey } = useTicketFormValues()
  const prefix = getPropertyValueByKey('location_prefix') || ''

  if (isHiddenField(KEY)) return <div></div>

  return (
    <>
      <Form.Item name={KEY} label={<Label name={KEY} k={'ticket__location__label'} />} {...formItemProps}>
        <QuerySelect
          apiEndpoint={locationApi.list}
          apiQueryParams={{ fields: 'id,name', order: 'name' }}
          apiSearchBy={'name'}
          renderOption={(item) => ({ value: item.id, label: item.name })}
          labelRender={(option) => `${prefix}${option.label}`}
          disabled={isDisabledField(KEY)}
          prefetch={true}
          showCopyText={true}
          addOption={{
            onAdd: async (text) => {
              const o: any = await executeMutation(locationApi.create(), { name: text, company: company.id })
              return { value: o.id, label: o.name }
            }
          }}
          {...(inputProps as any)}
        />
      </Form.Item>
    </>
  )
}
