import { customFormApi } from '@/services/api-service'
import { useQuery } from '@tanstack/react-query'
import { Form, FormItemProps, Select, SelectProps } from 'antd'
import { useMemo } from 'react'

type Props = {
  formItemProps?: FormItemProps
  selectProps?: SelectProps<any>
  renderOptions?: (
    options: { label: string; value: any }[],
    data: { id: number; name: string }[]
  ) => { label: string; value: any }[]
}

export const CustomFormSelect = ({ formItemProps, renderOptions, selectProps }: Props) => {
  const customFormsQuery = useQuery({
    ...customFormApi.list<{ id: number; name: string }>({ limit: 'None' as any, fields: 'id,name' })
  })

  const customFormsOptions = useMemo(() => {
    const customForms = customFormsQuery.data?.items ?? []
    const options = customForms.map((v) => ({ label: v.name, value: v.id }))

    if (renderOptions) {
      return renderOptions(options, customForms)
    }

    return options
  }, [customFormsQuery.data?.items, renderOptions])

  return (
    <Form.Item label="Custom Form" {...formItemProps}>
      <Select
        placeholder="Select"
        popupMatchSelectWidth={false}
        options={customFormsOptions}
        loading={customFormsQuery.isLoading}
        disabled={customFormsQuery.isLoading}
        showSearch
        optionFilterProp="label"
        {...selectProps}
      />
    </Form.Item>
  )
}
