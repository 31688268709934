import { useApp } from '@/hooks'
import { propertyChoiceApi } from '@/services/api-service'
import { PropertyChoice } from '@/types/property-choice'
import { Button, QueryDeleteButton } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Space } from 'antd'
import { FormValues } from '../../types'

type Props = {
  propertyChoice: PropertyChoice
}

export const ActionButtonsColumn = ({ propertyChoice }: Props) => {
  const { id, value, name } = propertyChoice
  const form = Form.useFormInstance()
  const editingPropertyChoice = Form.useWatch('editingPropertyChoice', form) as FormValues['editingPropertyChoice']
  const updatePropertyChoiceMutation = useMutation(propertyChoiceApi.patch())
  const { notification } = useApp()

  const handleSave = async () => {
    const { editingPropertyChoice } = await form.validateFields()
    const { id, name, value } = editingPropertyChoice
    await updatePropertyChoiceMutation.mutateAsync({ id, name, value })
    form.setFieldsValue({ editingPropertyChoice: undefined })
    notification.success({ message: 'Property choice successfully saved' })
  }

  return (
    <Space>
      {editingPropertyChoice?.id === id ? (
        <Button type="text" iconName="fa:check" onClick={handleSave} />
      ) : (
        <Button
          type="text"
          iconName="fa:edit"
          onClick={() => form.setFieldsValue({ editingPropertyChoice: { id, name, value } })}
        />
      )}
      {editingPropertyChoice?.id === id ? (
        <Button
          type="text"
          iconName="fa:close"
          onClick={() => form.setFieldsValue({ editingPropertyChoice: undefined })}
        />
      ) : (
        <QueryDeleteButton api={propertyChoiceApi.delete} id={String(id)} />
      )}
    </Space>
  )
}
