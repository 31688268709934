import { useApp, useSession } from '@/hooks'
import { documentApi, moduleApi } from '@/services/api-service'
import { apiHttp } from '@/services/api-service/core'
import { Button, PageTitle } from '@/ui'
import { Spin } from '@/ui/spin'
import { cn } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Editor } from '@tinymce/tinymce-react'
import { Input, Space } from 'antd'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Module } from '../module/types'

export const DocumentView = () => {
  const { moduleId } = useParams()
  const { notification } = useApp()
  const moduleQuery = useQuery(moduleApi.get<Module>(Number(moduleId)))
  const updateDocumentMutation = useMutation({
    ...documentApi.update(),
    onSuccess: () => {
      notification.success({ message: `${moduleQuery.data?.document?.title || 'Document'} successfully saved` })
    }
  })
  const [isEditing, setIsEditing] = useState(false)
  const editorRef = useRef<any>()
  const [newTitle, setNewTitle] = useState('')
  const { company } = useSession()

  const handleSave = async () => {
    if (!editorRef.current) {
      return
    }

    const content = editorRef.current.getContent()

    if (moduleQuery.data) {
      await updateDocumentMutation.mutateAsync({
        id: moduleQuery.data.document?.id,
        content,
        company: company.id,
        title: newTitle || title
      })

      await moduleQuery.refetch()
      setIsEditing(false)
    }
  }

  if (moduleQuery.isLoading) {
    return <Spin spinning isCentered />
  }

  const { title, modified_at, document } = moduleQuery.data || {}

  return (
    <div>
      <div className="flex justify-between">
        {isEditing ? (
          <div>
            <Input defaultValue={document?.title || title || ''} onChange={(e) => setNewTitle(e.target.value)} />
          </div>
        ) : (
          <PageTitle>{document?.title || title}</PageTitle>
        )}
        {isEditing ? (
          <Space>
            <Button
              iconName="fa:check"
              type="primary"
              loading={updateDocumentMutation.isLoading}
              onClick={() => handleSave()}
            >
              Save
            </Button>
            <Button iconName="fa:edit" type="primary" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              iconName="fa:print"
              type="primary"
              onClick={() => {
                if (!editorRef.current) return
                editorRef.current.execCommand('mcePrint')
              }}
            />
            <Button iconName="fa:edit" type="primary" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          </Space>
        )}
      </div>
      {!isEditing && <div>Last updated at {dayjs(modified_at).format('MMM D, YYYY h:mm:ss A')}</div>}
      <div className={cn(!isEditing && 'hidden', 'mt-16')}>
        <Editor
          apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
          onInit={(evt: any, editor: any) => (editorRef.current = editor)}
          initialValue={document?.content || ''}
          init={{
            branding: false,
            plugins:
              'code preview searchreplace autolink directionality visualblocks visualchars fullscreen image link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount',
            toolbar:
              'formatselect | bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat print fullscreen code preview',
            menubar: 'file edit view insert format tools table',
            statusbar: true,
            image_title: true,
            relative_urls: false,
            automatic_uploads: true,
            images_upload_handler: async (blobInfo: any) => {
              const formData = new FormData()
              formData.append('file', blobInfo.blob(), blobInfo.filename())

              const res = await apiHttp.post<any, { data: { location: string; file: any } }>('s3/upload/', formData, {
                headers: { 'Content-Type': undefined }
              })

              if (res.data && res.data.location) {
                return res.data.location
              } else {
                throw new Error('Failed to upload image')
              }
            }
          }}
        />
      </div>
      <div className={cn(isEditing && 'hidden')}>
        <div
          dangerouslySetInnerHTML={{
            __html: document?.content || ''
          }}
        />
      </div>
    </div>
  )
}
