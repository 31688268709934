import { ldml } from '@/utils/formatters'
import dayjs from 'dayjs'
import { DurationRateUnit, RATE_IN_MS, RATE_NAME, RateUnit } from '../constants'
import { FieldConfig, LineItem } from '../schemas'

const asFixed = ldml('#.00##')
const asDate = (value: any) => dayjs.formatLocal(value)
const asCurrency = ldml('$#,###.00##')
const asDecimal = ldml('#.00##')
const asInteger = ldml('#,###')
const asPercentage = (value: any) => (Number(value) ? asFixed(Number(value)) + '%' : '-')

const unitName = (line: Partial<LineItem>) => (line.unit ? RATE_NAME[line.unit as RateUnit] : '')

export const lineFieldFormatters: { [key: string]: FieldConfig['format'] } = {
  sku: (line, o) => line.sku || '',
  description: (line, o) => line.description || '',
  start_datetime: (line, o) => asDate(line.start_datetime) || '',
  end_datetime: (line, o) => asDate(line.end_datetime) || '',
  usage_rate: (line, o) => `${ldml('$#,##0.00##')(line.usage_rate)}${line.unit || ''}`,
  standby_rate: (line, o) => `${ldml('$#,##0.00##')(line.standby_rate)}${line.unit || ''}`,
  units_standby: (line, o) => `${ldml('#,##0.00##')(line.units_standby)} ${unitName(line)}`,
  units_min: (line, o) => `${ldml('#,##0.00##')(line.units_standby)} ${unitName(line)}`,
  discount: (line, o) => asPercentage(line.discount),
  discountable_amount: (line, o) => asCurrency(line.discountable_amount),
  tax_rate: (line, o) => asPercentage(line.tax_rate),
  taxable_amount: (line, o) => asCurrency(line.taxable_amount),
  minimum: (line, o) => asCurrency(line.minimum),
  amount: (line, o) => asCurrency(line.amount),
  barrels: (line, o) => asDecimal(line.barrels),
  user_decimal_1: (line, o) => asDecimal(line.user_decimal_1),
  user_decimal_2: (line, o) => asDecimal(line.user_decimal_2),
  user_decimal_3: (line, o) => asDecimal(line.user_decimal_3),
  user_integer_1: (line, o) => asInteger(line.user_integer_1),
  user_integer_2: (line, o) => asInteger(line.user_integer_2),
  user_integer_3: (line, o) => asInteger(line.user_integer_3),
  user_integer_4: (line, o) => asInteger(line.user_integer_4),
  user_text_1: (line, o) => line.user_text_1 || '',
  user_text_2: (line, o) => line.user_text_2 || '',
  user_text_3: (line, o) => line.user_text_3 || '',
  user_text_4: (line, o) => line.user_text_4 || '',
  user_text_5: (line, o) => line.user_text_5 || '',
  user_text_6: (line, o) => line.user_text_6 || '',
  user_text_7: (line, o) => line.user_text_7 || '',
  user_text_8: (line, o) => line.user_text_8 || '',
  user_long_text_1: (line, o) => line.user_long_text_1 || '',
  user_datetime_1: (line, o) => asDate(line.user_datetime_1) || '',
  user_datetime_2: (line, o) => asDate(line.user_datetime_2) || '',
  status: (line, o) => line.status || '',
  assignee: (line, o) => (line.assignee_id ? `${line.assignee__first_name} ${line.assignee__last_name}` : ''),
  hands: (line, o) => asDecimal(line.hands),
  units_used: (line, o) => {
    if (o?.category?.format_qty_as_duration) {
      const hourMs = RATE_IN_MS['/hr']
      const rateMs = RATE_IN_MS[line.unit as DurationRateUnit] || hourMs

      const durationHr = Math.max(0, (line.units_used || 0) * (rateMs / hourMs))
      const hours = Math.floor(durationHr)
      const minutes = Math.floor(Math.round((durationHr % 1) * 60 * 100) / 100)

      return `${hours}:${('0' + minutes).slice(-2)}`
    } else {
      return ldml('#,##0.00##')(line.units_used)
    }
  }
}

/*
todo: custom field formatters
const applyKindFieldsFormatter = (kind: CategoryKind, fields: FieldConfig[]) => {
  const fieldFormats = isEmpty(kind.formats) ? {} : kind.formats
  const _identityFn = (x: any) => x

  const _PYTHON_TO_MOMENT_JS = {
    '%p': 'A',
    '%j': 'DDDD',
    '%M': 'mm',
    '%S': 'ss',
    '%-d': 'D',
    '%f': 'SSSSSS',
    '%-m': 'M',
    '%Z': 'zz',
    '%d': 'DD',
    '%-H': 'H',
    '%b': 'MMM',
    '%z': 'ZZ',
    '%a': 'ddd',
    '%-I': 'h',
    '%B': 'MMMM',
    '%y': 'YY',
    '%A': 'dddd',
    '%H': 'HH',
    '%m': 'MM',
    '%Y': 'YYYY',
    '%w': 'd',
    '%I': 'hh',
    '%-S': 's',
    '%%': '%',
    '%-j': 'DDD',
    '%-M': 'm'
  }

  fields.forEach((f) => {
    // field formatter
    let formatType
    let formatStr = dig(fieldFormats, f.field)
    const formatSet = !!formatStr && typeof formatStr === 'string'
    const parseResult = /^\?(\w+):(.*?$)/.exec((formatSet && formatStr) || '')
    if (parseResult) {
      ;[formatType, formatStr] = parseResult.slice(1, 3)
    }

    if (formatSet && (!formatType || formatType == 'number')) {
      f._format = f.format || null
      try {
        f._format_fn = ldmlnum(formatStr)
      } catch (err) {
        console.error('failed to parse number format string', formatStr, 'error:', err)
        f._format_fn = _identityFn
      }

      f.formatVal = (value) => {
        try {
          // return original if unable to parse as number
          const val = Number(value)
          if (isNaN(val)) {
            return value
          }

          return f._format_fn(val)
        } catch (e) {
          console.error(`Failed to apply format '${formatStr}' to line items field value '${value}'`, e)
        }
      }

      f.format = (li) => f.formatVal(_.get(li, f.field))
    } else if (formatSet && formatType == 'datetime') {
      const momentJsFmt = formatStr.replace(/%-?[\w%]/g, (match) => _PYTHON_TO_MOMENT_JS[match] || match)

      f.formatVal = (value) => {
        // note: prefer null (invalid date) over `undefined` (alias for `now`), zero, and other falsey values (invalid dates)
        const m = moment(value || null)
        if (!m.isValid()) {
          console.warn('not a valid date', value)
          return ''
        }
        return m.format(momentJsFmt)
      }
      f.format = (li) => f.formatVal(li[f.field])
    }
  })
}
*/
