import { Icon, IconName } from '@/ui/icons'
import { ModalProps as AntModalProps, Modal as AntdModal } from 'antd'
import { useState } from 'react'
import moduleStyles from './Modal.module.scss'

export type ModalProps = {
  iconName?: IconName
  forceFullScreen?: boolean
  withFullscreenOption?: boolean
  withScreenMaxHeight?: boolean
} & AntModalProps

export const Modal = ({
  withFullscreenOption,
  forceFullScreen,
  width,
  style,
  iconName,
  title,
  styles,
  withScreenMaxHeight,
  onCancel,
  ...props
}: ModalProps) => {
  props.destroyOnClose = props.destroyOnClose ?? true
  props.centered = props.centered ?? true

  const [isFullScreen, setIsFullScreen] = useState(false)
  const fullScreen = forceFullScreen || isFullScreen

  return (
    <AntdModal
      width={fullScreen ? '100%' : width}
      rootClassName={fullScreen ? moduleStyles.fullScreenWrapper : ''}
      styles={{
        body:
          withScreenMaxHeight && !fullScreen
            ? {
                maxHeight: 'calc(100vh - 276px)',
                overflowY: 'auto',
                ...styles?.body
              }
            : styles?.body
      }}
      style={style}
      closeIcon={withFullscreenOption ? null : undefined}
      title={
        withFullscreenOption ? (
          <div className="flex items-center">
            <div className="flex-1">{title}</div>
            <div className="flex items-center">
              <div className="flex items-center gap-x-8">
                <button
                  className="w-[22px] h-[22px] bg-transparent inline-flex justify-center items-center cursor-pointer"
                  onClick={() => setIsFullScreen((prev) => !prev)}
                >
                  <Icon name={fullScreen ? 'mi:close_fullscreen' : 'mi:open_in_full'} className="text-20" />
                </button>
                <button
                  className="w-[22px] h-[22px] bg-transparent inline-flex justify-center items-center cursor-pointer"
                  onClick={(e) => onCancel && onCancel(e)}
                >
                  <Icon name="mi:close" className="text-20" />
                </button>
              </div>
            </div>
          </div>
        ) : iconName ? (
          <div className={'flex items-center gap-10'}>
            <Icon name={iconName} />
            <div>{title}</div>
          </div>
        ) : (
          title
        )
      }
      onCancel={onCancel}
      {...props}
    />
  )
}
