import { useFormWatch } from '@/hooks'
import { NamePath } from 'antd/es/form/interface'
import { useAtom, useAtomValue } from 'jotai'
import { get, isEmpty, isEqual } from 'lodash'
import { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { hasUnSavedValuesAtom, unSavedValuesAtom } from '../atoms'
import { RELATED_FIELDS } from '../constants'
import { useTicketFormContext } from '../context'
import { formatTicketField, formatTicketProperty, ticketToFieldsValue } from '../helpers'
import { Ticket } from '../schemas'
import { useCustomForm } from './use-custom-form'
import { useTicket } from './use-ticket'

export const useTicketForm = () => {
  const { ticketForm } = useTicketFormContext()

  if (!ticketForm) {
    throw Error(
      'InvalidFormContext: ticketFormAtom is not initialized. ' +
        'Make sure to call `useTicketForm` hook only within the context of `TicketForm` component.'
    )
  }

  return { ticketForm }
}

export const useTicketFormWatch = <T = any>(dependencies: NamePath<T>) => {
  const { ticketForm } = useTicketForm()
  return useFormWatch(dependencies, ticketForm)
}

export const useTicketFormValues = () => {
  const { ticketForm } = useTicketForm()

  const { customForm } = useCustomForm()
  const { ticket } = useTicket()

  const [unSavedValues, setUnSavedValues] = useAtom(unSavedValuesAtom)
  const hasUnSavedValues = useAtomValue(hasUnSavedValuesAtom)

  const applyChanges = useCallback(() => {
    const values: Record<string, any> = {}
    const allValues = ticketForm.getFieldsValue(true)
    for (const key in allValues) {
      if (key === 'lineItems' || key === 'inLineItems') continue // skip; handled by line items

      let originalValue
      let newValue

      if (key.startsWith('p_')) {
        // detect property changes
        const propertyId = parseInt(key.replace('p_', ''), 10)
        const property = customForm._propertyById[propertyId]
        originalValue = get(ticket._tpByPropertyId[propertyId], 'value') || null
        newValue = formatTicketProperty(property, ticketForm.getFieldValue(key))
      } else {
        // detect field changes
        const ticketFieldKey = RELATED_FIELDS.includes(key) ? `${key}_id` : key
        originalValue = get(ticket, ticketFieldKey)
        newValue = formatTicketField(key, ticketForm.getFieldValue(key))
      }

      // normalize undefined to null
      originalValue = originalValue === undefined ? null : originalValue

      if (!isEqual(originalValue, newValue)) {
        values[key] = {
          old: originalValue,
          new: newValue
        }
      }
    }

    setUnSavedValues(values)
  }, [customForm._propertyById, setUnSavedValues, ticket, ticketForm])

  const trackChanges = useDebouncedCallback(() => applyChanges(), 50)

  const loadTicket = useCallback(
    (ticket: Ticket, force = false) => {
      const fieldsValue = ticketToFieldsValue(ticket, customForm)

      // preserve unsaved values if not forced
      if (!force && hasUnSavedValues) {
        for (const key in fieldsValue) {
          if (unSavedValues[key] && !isEqual(unSavedValues[key], fieldsValue[key])) {
            delete fieldsValue[key]
          }
        }
      }

      ticketForm.setFieldsValue(fieldsValue)
      trackChanges() // run changes tracking
    },
    [customForm, hasUnSavedValues, ticketForm, trackChanges, unSavedValues]
  )

  const setFieldValue = useCallback(
    (key: string | null | undefined, value: any) => {
      if (!key) return
      ticketForm.setFieldValue(key, value)
      trackChanges() // run changes tracking
    },
    [ticketForm, trackChanges]
  )

  const setFieldsValue = useCallback(
    (values: Record<string, any>) => {
      if (!isEmpty(values)) return
      ticketForm.setFieldsValue(values)
      trackChanges() // run changes tracking
    },
    [ticketForm, trackChanges]
  )

  const getPropertyValueById = useCallback(
    (propertyId: number | null | undefined): string | null => {
      if (!propertyId) return null

      const property = customForm._propertyById[propertyId]
      if (!property) return null

      if (unSavedValues[property._field]) {
        return unSavedValues[property._field].new
      }

      return ticket._tpByPropertyId[property.id]?.value
    },
    [customForm._propertyById, ticket._tpByPropertyId, unSavedValues]
  )

  const getPropertyValueByKey = useCallback(
    (propertyKey: string | null | undefined) => {
      if (!propertyKey) return null

      const property = customForm._propertyByKey[propertyKey]
      if (!property) return null

      if (unSavedValues[property._field]) {
        return unSavedValues[property._field].new
      }

      return ticket._tpByPropertyId[property.id]?.value
    },
    [customForm._propertyByKey, ticket._tpByPropertyId, unSavedValues]
  )

  const getAllPropertiesValueByKey = useCallback(() => {
    const values: Record<string, any> = {}
    for (const key in customForm._propertyByKey) {
      values[key] = getPropertyValueByKey(key)
    }
    return values
  }, [customForm._propertyByKey, getPropertyValueByKey])

  const getAllPropertiesValueById = useCallback(() => {
    const values: Record<number, any> = {}
    for (const id in customForm._propertyById) {
      values[id] = getPropertyValueById(Number(id))
    }
    return values
  }, [customForm._propertyById, getPropertyValueById])

  return {
    ticketForm,
    unSavedValues,
    applyChanges,
    trackChanges,
    loadTicket,
    setFieldValue,
    setFieldsValue,
    getPropertyValueById,
    getPropertyValueByKey,
    getAllPropertiesValueById,
    getAllPropertiesValueByKey
  }
}
