import { z } from 'zod'

export const JobAssignConfigSchema = z.object({
  custom_form_id: z.number().nullable(),

  attributes_pid: z.number(),
  requirements_pid: z.number(),
  start_date_icon: z.string().nullable(),
  start_date_pid: z.number(),
  estimated_hours_icon: z.string().nullable(),
  estimated_hours_pid: z.number(),
  description_icon: z.string().nullable(),
  description_pid: z.number().nullable(),
  line_1_icon: z.string().nullable(),
  line_1_pid: z.number().nullable(),
  line_2_icon: z.string().nullable(),
  line_2_pid: z.number().nullable(),
  line_3_icon: z.string().nullable(),
  line_3_pid: z.number().nullable(),
  notes_icon: z.string().nullable(),
  notes_pid: z.number().nullable(),

  title_icon: z.string().nullable(),
  title_pid: z.number().nullable(),
  job_type_pid: z.number(),
  assigned_pct_pid: z.number()
})

export const JobRoleSchema = z.object({
  id: z.number(),
  name: z.string(),
  resource_type: z.string(),
  property_id: z.number(),
  job_role_color: z.string()
})

export const JobSkillSchema = z.object({
  key: z.string(),
  label: z.string(),
  type: z.enum(['Employee', 'Equipment'])
})

export const JobRequirementsByTypeSchema = z.object({
  Employee: z.number(),
  Equipment: z.number()
})

export const JobRequirementsByRoleSchema = z
  .array(
    z.object({
      role: z.number(),
      required: z.number().default(0)
    })
  )
  .nullable()

export const JobResourceDataSchema = z.object({
  id: z.number().nullable().optional(),
  name: z.string(),
  required: z.number(),
  assigned: z.number()
})

export const JobTicketEventSchema = z.object({
  id: z.number().nullish(),
  description: z.string(),
  start_datetime: z.string(),
  end_datetime: z.string(),
  resource__id: z.number(),
  resource__name: z.string(),
  resource__type: z.string(),
  resource__short_code: z.string(),
  resource__attributes: z.record(z.string()),
  assigned_sku_id: z.number(),
  job_role_id: z.string()
})

export const JobDataSchema = z.object({
  id: z.number(),
  name: z.number(),
  modified_at: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  attributes: z.array(z.string()),
  description: z.string(),
  elapsedTime: z.number(),
  title: z.string(),
  jobType: z.string(),
  line1: z.string(),
  line2: z.string(),
  line3: z.string(),
  notes: z.string(),
  requirements: z.object({
    byType: JobRequirementsByTypeSchema.nullish(),
    byRole: JobRequirementsByRoleSchema.nullish()
  })
})

export const JobTicketSchema = z.object({
  id: z.number(),
  name: z.number(),
  status: z.string(),
  computed_status: z.string(),
  custom_form_id: z.number(),
  customer_office__name: z.string(),
  location__name: z.string(),
  timepoint_due: z.string(),
  modified_at: z.string(),
  property: z.record(z.string()),
  schedule_events: z.array(JobTicketEventSchema),
  _jobData: JobDataSchema
})

export const JobResourceSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  short_code: z.string(),
  job_role__id: z.number(),
  assigned_sku_id: z.number(),
  attributes: z.record(z.string())
})

export type JobRole = z.infer<typeof JobRoleSchema>
export type JobSkill = z.infer<typeof JobSkillSchema>
export type JobRequirementsByType = z.infer<typeof JobRequirementsByTypeSchema>
export type JobRequirementsByRole = z.infer<typeof JobRequirementsByRoleSchema>
export type JobData = z.infer<typeof JobDataSchema>
export type JobTicketEvent = z.infer<typeof JobTicketEventSchema>
export type JobTicket = z.infer<typeof JobTicketSchema>
export type JobAssignConfig = z.infer<typeof JobAssignConfigSchema>
export type JobResource = z.infer<typeof JobResourceSchema>
