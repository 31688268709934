import { useApp } from '@/hooks'
import { CustomReport } from '@/modules/reports/schema'
import { reportBuilderApi } from '@/services/api-service'
import { AppLink, Button } from '@/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import { useState } from 'react'
import { REPORTS_PATHS } from '../../../constants'
import { useDownloadReportMutation } from '../../../hooks'

type Props = {
  report: CustomReport
  format: string
  encoding: string
}

export const ActionsColumn = ({ report, format, encoding }: Props) => {
  const queryClient = useQueryClient()
  const copyReportMutation = useMutation(reportBuilderApi.create())
  const deleteReportMutation = useMutation(reportBuilderApi.delete())
  const { notification } = useApp()
  const { downloadReportMutation } = useDownloadReportMutation()
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)

  const handleDelete = async (id: number) => {
    await deleteReportMutation.mutateAsync({ id })
    queryClient.invalidateQueries(['custom_reports'])
  }

  const handleDownload = async (id: number) => {
    setIsDownloadLoading(true)
    await downloadReportMutation.mutateAsync({ id, format, encoding })
    setIsDownloadLoading(false)
  }

  const handleCopy = async (report: CustomReport) => {
    const newReport = {
      ...report,
      id: undefined,
      name: report.name + ' - Copy',
      options: {
        ...(report as any).options,
        id: undefined,
        report: undefined
      },
      displayfield_set: (report as any).displayfield_set.map((displayField: any) => ({
        ...displayField,
        id: undefined,
        options: {
          ...displayField.options,
          id: undefined,
          display_field: undefined,
          filter_field: undefined
        }
      })),
      filterfield_set: (report as any).filterfield_set.map((filterField: any) => ({
        ...filterField,
        id: undefined,
        options: {
          ...filterField.options,
          id: undefined,
          display_field: undefined,
          filter_field: undefined
        }
      }))
    }

    await copyReportMutation.mutateAsync(newReport)
    queryClient.invalidateQueries(['custom_reports'])
    notification.success({ message: 'Report copied' })
  }

  return (
    <Space>
      <AppLink to={REPORTS_PATHS.editReport(report.id)}>
        <Button type="text" iconName="fa:edit" />
      </AppLink>
      <Button type="text" iconName="fa:copy" onClick={() => handleCopy(report)} />
      <Button
        type="text"
        iconName="fa:download"
        loading={isDownloadLoading}
        onClick={() => handleDownload(report.id)}
      />
      <Popconfirm
        title="Delete report"
        description="Are you sure you want to delete this report?"
        placement="topLeft"
        onConfirm={() => handleDelete(report.id)}
      >
        <Button type="text" iconName="fa:trash" />
      </Popconfirm>
    </Space>
  )
}
