import { ZodType, z } from 'zod'

/**
 * Return an actual type of Zod schema by unwrapping nested modifier types.
 *
 * @param schema The Zod schema to get the actual type of.
 */
export const zodBaseSchema = <T>(schema: ZodType<T>): ZodType<any> => {
  let current = schema as any
  while (current?._def?.innerType) {
    current = current._def.innerType
  }
  return current
}

/**
 * Returns an array of query fields for the given z.ZodObject.
 *
 * @param {z.ZodObject<any, any>} schema The z.ZodObject to extract query fields from.
 *
 * @returns {string[]} An array of query fields.
 */
export const zodQueryFields = (schema: z.ZodTypeAny): string[] => {
  if (schema instanceof z.ZodObject) {
    const objectShape = schema.shape
    return Object.keys(objectShape)
      .filter((key) => !key.startsWith('_'))
      .map((fieldName) => {
        const nestedSchema = zodBaseSchema(objectShape[fieldName])

        if (nestedSchema instanceof z.ZodArray) {
          return zodQueryFields(nestedSchema.element).map((k) => `${fieldName}__${k}`)
        } else if (nestedSchema instanceof z.ZodObject) {
          // Backend will throw an error if you try specify keys inside the django.db.models.JSONField
          // That's why, if zod object marked a json_field, we ask for it as a whole.
          if (nestedSchema.shape['_local_field']) return fieldName

          return zodQueryFields(nestedSchema).map((k) => `${fieldName}__${k}`)
        }

        return fieldName
      })
      .flat()
  } else if (schema instanceof z.ZodArray) {
    return zodQueryFields(schema.element)
  }

  return []
}

/**
 * Returns an array of query fields for the given z.ZodObject.
 *
 * @param {z.ZodObject<any, any>} schema The z.ZodObject to extract query fields from.
 *
 * @returns {string[]} An array of query fields.
 */
export const _depreciated__zodQueryFields = <T>(schema: ZodType<T>): string[] => {
  if (!(schema instanceof z.ZodObject)) {
    throw new Error('zodQueryFields only supports z.ZodObject')
  }

  return Object.keys(schema.shape)
    .filter((key) => !key.startsWith('_'))
    .map((key) => {
      if (schema.shape[key] instanceof z.ZodObject) {
        return zodQueryFields(schema.shape[key] as z.ZodObject<any, any>).map((k) => `${key}__${k}`)
      } else if (schema.shape[key] instanceof z.ZodArray) {
        return zodQueryFields(schema.shape[key].element).map((k) => `${key}__${k}`)
      }
      return key
    })
    .flat()
}
