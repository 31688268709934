import { SectionTitle } from '@/ui/section-title'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  closestCorners,
  defaultDropAnimation,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { Form, Input, Select } from 'antd'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { columnsAtom } from '../atoms'
import { DroppableColumn } from './droppable-column'
import { Item } from './item'
import { findBoardSectionContainer } from './utils/find-board-section-container'

const COLUMN_KEYS = {
  available: 'available',
  selected: 'selected'
} as const

export const DragAndDropFieldsSection = () => {
  const sensors = useSensors(useSensor(MouseSensor))
  const [availableItemsSearchText, setAvailableItemsSearchText] = useState('')
  const [activeId, setActiveId] = useState<string | null>(null)
  const [columns, setColumns] = useAtom(columnsAtom)

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id as any)
  }

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    // Find the containers
    const activeContainer = findBoardSectionContainer(columns, active.id as string)
    const overContainer = findBoardSectionContainer(columns, over?.id as string)

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return
    }

    setColumns((columns) => {
      const activeItems = columns[activeContainer as keyof typeof columns]
      const overItems = columns[overContainer as keyof typeof columns]

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === active.id)
      const overIndex = overItems.findIndex((item) => item.id !== over?.id)

      const activeContainerItems = columns[activeContainer as keyof typeof columns]
      const overContainerItems = columns[overContainer as keyof typeof columns]

      return {
        ...columns,
        [activeContainer]: [...activeContainerItems.filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...overContainerItems.slice(0, overIndex),
          activeContainerItems[activeIndex],
          ...overContainerItems.slice(overIndex, overContainerItems.length)
        ]
      }
    })
  }

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeContainer = findBoardSectionContainer(columns, active.id as string)
    const overContainer = findBoardSectionContainer(columns, over?.id as string)

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return
    }

    const activeContainerItems = columns[activeContainer as keyof typeof columns]
    const overContainerItems = columns[overContainer as keyof typeof columns]

    const activeIndex = activeContainerItems.findIndex((task) => task.id === active.id)
    const overIndex = overContainerItems.findIndex((task) => task.id === over?.id)

    if (activeIndex !== overIndex) {
      const items = arrayMove(activeContainerItems, activeIndex, overIndex)

      setColumns((columns) => ({
        ...columns,
        [overContainer]: items
      }))
    }

    setActiveId(null)
  }

  const filteredAvailableItems = columns.available.filter(
    (item) =>
      item.name.toLowerCase().includes(availableItemsSearchText.toLowerCase().trim()) ||
      item._name?.toLowerCase().includes(availableItemsSearchText.toLowerCase().trim())
  )

  const draggingField = activeId
    ? Object.values(columns)
        .flat()
        .find((field) => field?.id === activeId)
    : null

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <div className="pt-24">
        <div className="grid grid-cols-2 gap-x-24 items-end">
          <div>
            <SectionTitle rounded number={4}>
              Drag fields left to right & Organize columns for export
            </SectionTitle>
            <DroppableColumn
              id={COLUMN_KEYS.available}
              items={filteredAvailableItems}
              extra={
                <Input
                  placeholder="Search"
                  className="mb-16"
                  value={availableItemsSearchText}
                  onChange={(e) => setAvailableItemsSearchText(e.target.value)}
                />
              }
            />
          </div>
          <DroppableColumn
            id={COLUMN_KEYS.selected}
            items={columns.selected}
            extra={
              <div className="grid grid-cols-2 gap-x-16">
                <Form.Item label="Sort By">
                  <Select
                    value={columns.selected.find((item) => item.sort === 1)?.id}
                    allowClear
                    onClear={() => {
                      setColumns((columns) => ({
                        ...columns,
                        selected: columns.selected.map((item) => ({
                          ...item,
                          sort: null
                        }))
                      }))
                    }}
                    options={columns.selected.map((item) => ({
                      label: item.name,
                      value: item.id
                    }))}
                    onChange={(value) => {
                      setColumns((columns) => ({
                        ...columns,
                        selected: columns.selected.map((item) => ({
                          ...item,
                          sort: item.id === value ? 1 : null
                        }))
                      }))
                    }}
                  />
                </Form.Item>
                <Form.Item label="Then By">
                  <Select
                    value={columns.selected.find((item) => item.sort === 2)?.id}
                    allowClear
                    onClear={() => {
                      setColumns((columns) => ({
                        ...columns,
                        selected: columns.selected.map((item) => ({
                          ...item,
                          sort: item.sort === 2 ? null : item.sort
                        }))
                      }))
                    }}
                    options={
                      !columns.selected.find((item) => item.sort === 1)
                        ? []
                        : columns.selected
                            .filter((item) => item.sort !== 1)
                            .map((item) => ({
                              label: item.name,
                              value: item.id
                            }))
                    }
                    onChange={(value) => {
                      setColumns((columns) => ({
                        ...columns,
                        selected: columns.selected.map((item) => ({
                          ...item,
                          sort: item.id === value ? 2 : item.sort
                        }))
                      }))
                    }}
                  />
                </Form.Item>
              </div>
            }
          />
          {createPortal(
            <DragOverlay adjustScale={false} dropAnimation={defaultDropAnimation}>
              {activeId && draggingField ? <Item item={draggingField} isDragging /> : null}
            </DragOverlay>,
            document.body
          )}
        </div>
      </div>
    </DndContext>
  )
}
