import { useApp } from '@/hooks'
import { programmedReportApi } from '@/services/api-service'
import { Attribute } from '@/types/programmed-report'
import { Button, Icon } from '@/ui'
import { useMutation } from '@tanstack/react-query'
import { Form, Popconfirm, Space, Table } from 'antd'
import { useState } from 'react'
import { useProgrammedReportQuery } from '../../hooks'
import { Card } from '../card'
import { AddEditAttributeModal } from './add-edit-attribute-modal'

export const Attributes = () => {
  const { programmedReport, programmedReportQuery } = useProgrammedReportQuery()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [editingAttribute, setEditingAttribute] = useState<(Attribute & { index: number }) | undefined>()
  const updateProgrammedReportMutation = useMutation(programmedReportApi.patch())
  const { notification } = useApp()
  const [isSaveAttributeLoading, setIsSaveAttributeLoading] = useState(false)

  const handleSaveAttribute = async ({ index, ...attribute }: Attribute & { index: number }) => {
    try {
      setIsSaveAttributeLoading(true)
      const attributes = [...(programmedReport.attributes || [])]
      attributes[index] = attribute
      await updateProgrammedReportMutation.mutateAsync({ id: programmedReport.id, attributes })
      await programmedReportQuery.refetch()
      notification.success({ message: 'Attribute saved' })
      setIsSaveAttributeLoading(false)
      setEditingAttribute(undefined)
    } catch {
      notification.error({ message: 'Something went wrong' })
      setIsSaveAttributeLoading(false)
    }
  }

  const handleRemoveAttribute = async (index: number) => {
    try {
      setIsSaveAttributeLoading(true)
      const attributes = [...(programmedReport.attributes || [])]
      attributes.splice(index, 1)
      await updateProgrammedReportMutation.mutateAsync({ id: programmedReport.id, attributes })
      await programmedReportQuery.refetch()
      notification.success({ message: 'Attribute deleted' })
      setIsSaveAttributeLoading(false)
    } catch {
      notification.error({ message: 'Something went wrong' })
      setIsSaveAttributeLoading(false)
    }
  }

  return (
    <div>
      <Form.Item name="attributes" hidden />
      <Card
        styles={
          !isCollapsed
            ? { body: { padding: 0 }, header: { borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px' } }
            : undefined
        }
        title={
          <Space>
            <span className="cursor-pointer" onClick={() => setIsCollapsed((prev) => !prev)}>
              <Icon className="text-14" name={isCollapsed ? 'fa:chevron-up' : 'fa:chevron-down'}></Icon>
            </span>
            <span>Attributes</span>
          </Space>
        }
      >
        {isCollapsed && (
          <>
            <Table bordered dataSource={programmedReport.attributes} pagination={false}>
              <Table.Column title="Attribute Name" dataIndex="name" />
              <Table.Column title="Attribute Value(s)" dataIndex="values" render={(values) => values?.join(', ')} />
              <Table.Column
                width={100}
                align="right"
                render={(_, attribute: Attribute, index) => (
                  <Space>
                    <Button
                      type="text"
                      iconName="fa:pen"
                      onClick={() => setEditingAttribute({ ...attribute, index })}
                    />
                    <Popconfirm
                      title="Delete Argument"
                      description={`Are you sure you want to delete argument ${attribute.name}?`}
                      onConfirm={() => handleRemoveAttribute(index)}
                      okText="Yes"
                      placement="topRight"
                    >
                      <Button type="text" iconName="fa:trash" />
                    </Popconfirm>
                  </Space>
                )}
              />
            </Table>
            <Button
              iconName="fa:add"
              type="primary"
              className="mt-16"
              onClick={() =>
                setEditingAttribute({
                  editable: false,
                  key: '',
                  name: '',
                  type: 'Text',
                  values: [''],
                  index: programmedReport.attributes?.length || 0
                })
              }
            >
              Add Attribute
            </Button>
            {editingAttribute && (
              <AddEditAttributeModal
                attribute={editingAttribute}
                isLoading={isSaveAttributeLoading}
                onSave={handleSaveAttribute}
                onCancel={() => setEditingAttribute(undefined)}
              />
            )}
          </>
        )}
      </Card>
    </div>
  )
}
