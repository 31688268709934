import { Breadcrumbs } from '@/components/breadcrumbs'
import { useUrlState, useUrlStateRefresh } from '@/hooks'
import { BackButton, Button, ButtonProps } from '@/ui/button'
import { Popover } from '@/ui/popover'
import { Tooltip } from '@/ui/tooltip'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { FC, ReactNode, isValidElement } from 'react'

export type PageViewHeaderProps = {
  title?: ReactNode
  largeTitle?: ReactNode
  description?: ReactNode
  help?: ReactNode
  backButton?: boolean | ReactNode
  className?: string
  breadcrumbs?: boolean
  actions?: (ReactNode | ButtonProps)[] | ReactNode
  render?: (header: ReactNode) => ReactNode
  onRefresh?: () => void
  clearFilter?: boolean
  sticky?: boolean
  accent?: boolean
  border?: boolean
}

export const PageViewHeader: FC<PageViewHeaderProps> = ({
  title,
  largeTitle,
  description,
  help,
  backButton,
  breadcrumbs,
  className,
  actions,
  render,
  onRefresh,
  clearFilter = false,
  sticky = true,
  accent = true,
  border = true
}) => {
  const header = (
    <div className={'flex flex-row items-center justify-between px-10'}>
      <div>
        {breadcrumbs && <Breadcrumbs className={'pt-10 pb-4'} />}
        <div
          className={cn('flex items-center gap-12 justify-between', {
            'py-10': breadcrumbs || backButton || title || onRefresh || help || description || actions
          })}
        >
          {renderBackButton(backButton)}
          <div className={'flex-grow flex flex-col gap-10'}>
            <div className={'flex gap-4 items-center'}>
              <Title title={title} large={largeTitle} />
              <div className={'flex items-center gap-2'}>
                <Refresh onRefresh={onRefresh} />
                <Help help={help} />
                {clearFilter && <ClearFilterButton className={'ml-10'} />}
              </div>
            </div>
            {!!description && <div>{description}</div>}
          </div>
        </div>
      </div>
      <Actions actions={actions} />
    </div>
  )

  return (
    <div
      className={cn(
        'flex flex-col',
        {
          'sticky z-10 top-0': sticky,
          'border-b border-border-secondary': border,
          'bg-[color-mix(in_srgb,var(--ant-color-primary)_2%,transparent)] dark:bg-container': accent
        },
        className
      )}
    >
      {render ? render(header) : header}
    </div>
  )
}

const renderBackButton = (backButton: PageViewHeaderProps['backButton']) => {
  if (!backButton) return null

  if (backButton === true) return <BackButton />

  return backButton
}

type TitleProps = {
  title: PageViewHeaderProps['title']
  large?: PageViewHeaderProps['largeTitle']
}
const Title: FC<TitleProps> = ({ title, large = true }) => {
  if (!title) return null
  return <h3 className={cn('font-medium mb-0 text-20', large ? 'text-24' : 'text-20')}>{title}</h3>
}

type HelpProps = {
  help: PageViewHeaderProps['help']
}
const Help: FC<HelpProps> = ({ help }) => {
  if (!help) return null

  return (
    <Popover content={help}>
      <Button
        size={'small'}
        shape={'circle'}
        type={'text'}
        iconName={'fa:circle-info'}
        className={'text-gray-400 dark:text-gray-600'}
      />
    </Popover>
  )
}

type ActionProps = {
  actions: PageViewHeaderProps['actions']
}
const Actions: FC<ActionProps> = ({ actions }) => {
  if (isValidElement(actions)) return actions
  if (Array.isArray(actions) && actions.length)
    return (
      <div className={'flex flex-row items-center gap-8'}>
        {actions.map((action: any) => (isValidElement(action) ? action : <Button key={action.key} {...action} />))}
      </div>
    )
  return null
}

type RefreshProps = {
  onRefresh: PageViewHeaderProps['onRefresh']
}

export const Refresh: FC<RefreshProps> = ({ onRefresh }) => {
  if (!onRefresh) return null

  return (
    <Tooltip title={'Refresh'} mouseEnterDelay={0.5}>
      <Button shape={'circle'} size={'small'} type={'text'} iconName={'fa:redo'} onClick={onRefresh} />
    </Tooltip>
  )
}

export function ClearFilterButton(props: ButtonProps) {
  const [state, setState] = useUrlState()
  const { refresh } = useUrlStateRefresh()

  const handleClearFilter = () => {
    setState({ filter: null })
    refresh()
  }

  if (!state?.filter || isEmpty(state?.filter)) return null

  return (
    <Button
      size={'small'}
      color={'primary'}
      type={'default'}
      shape={'round'}
      iconPosition={'end'}
      iconName={'fa:close'}
      {...props}
      onClick={handleClearFilter}
    >
      Reset Filter
    </Button>
  )
}
