import { z } from 'zod'

export const QueryFieldSchema = z.object({
  as: z.string().nullish(),
  field: z.string(),
  label: z.string().nullish(),
  label_key: z.string().nullish(),
  data_type: z.string().nullish(),
  group: z.string().nullish()
})

export type TQueryField = z.infer<typeof QueryFieldSchema>

export type QueryFieldsProps = {
  value: TQueryField[]
  onChange: (value: TQueryField[]) => void
  options: TQueryField[]
}

export function QueryFields({ value, onChange, options }: QueryFieldsProps) {
  return <div>todo: for future</div>
}
