import { APP_DEV, APP_MODE, ENABLE_REDIRECT } from '@/constants/app'
import redirects from '@/data/redirects.json'
import { useApp } from '@/hooks/use-app'
import { navigate } from '@/routing/helpers'
import { safeJsonParse } from '@/utils'
import { atom, useAtom, useAtomValue } from 'jotai'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo } from 'react'
import { To, useLocation } from 'react-router-dom'

const _GLOBAL_REDIRECTS = redirects.slice(1)

/**
 * Atom for off-v2 cookie.
 */
const isOffV2Atom = atom<boolean>(Cookies.get('off-v2') === 'true')

/**
 * Atom for redirecting state.
 */
const isRedirectingAtom = atom<boolean>(false)

/**
 * Global redirect catch implementation.
 * If cookie off-v2 is true, redirect to v1 app.
 * If search query includes _v=1, redirect to v1 app.
 */
export const useRedirect = () => {
  const redirectHelper = useRedirectHelper()

  const { notification } = useApp()
  const { pathname, search } = useLocation()
  const isAppOff = useAtomValue(isOffV2Atom)
  const toggleIsAppOff = useCallback(() => {
    if (APP_DEV && !ENABLE_REDIRECT) {
      notification.warning({
        message: 'Development Mode Detected',
        description: 'React app cannot be disabled in development mode with ENABLE_REDIRECT=false.'
      })
      return
    }

    Cookies.set('off-v2', isAppOff ? 'false' : 'true', { expires: 365 })
    navigate('/', { v: 1 })
  }, [isAppOff, notification])

  const isBelongsToV1 = useCallback((pathname: string) => !redirectHelper.getV2Url(pathname), [redirectHelper])

  // redirect to v1 if cookie off-v2 is true
  // redirect to v1 if search query includes _v=1
  // note: this is handled in cloudfront function as well
  // to account for reloads
  const [isRedirecting, setIsRedirecting] = useAtom(isRedirectingAtom)
  useEffect(() => {
    if (isRedirecting) return

    // check if pathname matches force redirects (bypasses off-v2)
    if (redirectHelper.FORCE_REDIRECT_RULES.some((r) => r.v2Regex.test(pathname))) {
      return // force stay on v2
    }

    // disable app off redirect for development
    if (isAppOff && ENABLE_REDIRECT) {
      navigate('/', { v: 1 })
      setIsRedirecting(true)
    } else if (search.includes('_v=1')) {
      navigate(pathname, { v: 1 })
      setIsRedirecting(true)
    }
  }, [isAppOff, isRedirecting, pathname, redirectHelper.FORCE_REDIRECT_RULES, search, setIsRedirecting])

  return {
    isRedirecting,
    isAppOff,
    toggleIsAppOff,
    isBelongsToV1
  }
}

type RedirectSpec = [0 | 1, 0 | 1, 0 | 1, string, string]

type RedirectRule = {
  isEnabled: boolean
  isForce: boolean
  v1Format: string
  v2Format: string
  v1Regex: RegExp
  v2Regex: RegExp
}

export const useRedirectHelper = () => {
  /**
   * Craft redirect rule object from rule spec fetched from `@/data/redirects.json`
   */
  const transformToRule = useCallback((spec: RedirectSpec): RedirectRule => {
    // unpack spec
    const [isForce, forNonProd, forProduction, v1PathFormat, v2PathFormat] = spec

    // craft config object
    return {
      isEnabled: !!((APP_MODE !== 'production' && forNonProd) || (APP_MODE === 'production' && forProduction)),
      isForce: !!isForce,

      v1Format: v1PathFormat,
      v2Format: v2PathFormat,

      v1Regex: new RegExp(`^${v1PathFormat}$`.replace(/{(\w+)}/g, '(?<$1>.*)')),
      v2Regex: new RegExp(`^${v2PathFormat}$`.replace(/{(\w+)}/g, '(?<$1>.*)'))
    }
  }, [])

  const [GLOBAL_REDIRECTS, LOCAL_REDIRECTS, ACTIVE_REDIRECT_RULES, FORCE_REDIRECT_RULES] = useMemo(() => {
    // prepare global and local redirect specs
    const globalRedirects = (_GLOBAL_REDIRECTS as unknown as RedirectSpec[]) || []
    const localRedirects = ((safeJsonParse(window.localStorage.localRedirects || '{}') as unknown) || {}) as Record<
      string,
      RedirectSpec
    >

    // merge specs (global and local) then transform specs to rules
    const allRedirectRules = globalRedirects.concat(Object.values(localRedirects)).map(transformToRule)

    // select active redirect rules
    const activeRedirectRules = allRedirectRules.filter((r) => r?.isEnabled)

    // select force redirect rules
    const forceRedirectRules = activeRedirectRules.filter((r) => r?.isForce)

    return [globalRedirects, localRedirects, activeRedirectRules, forceRedirectRules]
  }, [transformToRule])

  /**
   * Transforms path based on fromRegex and toFormat
   */
  const convertUrl = useCallback((path: string, fromRegex: RegExp, toFormat: string) => {
    const match = fromRegex.exec(path)
    if (!match) return path

    let toPath = toFormat.toString()
    for (const [key, value] of Object.entries(match.groups || {})) {
      toPath = toPath.replace(`{${key}}`, value)
    }

    return toPath
  }, [])

  /**
   * Get v2 url from v1 url if applicable else return null
   */
  const getV2Url = useCallback(
    (to: string | To | null): string | null => {
      if (!to) return null

      const pathname = typeof to === 'string' ? to : to.pathname

      // do not redirect for home or index
      if (!pathname || pathname === '/') return null

      const redirect = ACTIVE_REDIRECT_RULES.find((r) => r.v1Regex.test(pathname))
      if (!redirect) return null

      return convertUrl(pathname, redirect.v1Regex, redirect.v2Format)
    },
    [ACTIVE_REDIRECT_RULES, convertUrl]
  )

  const getV1Url = useCallback(
    (to: string | To | null): string | null => {
      if (!to) return null

      const pathname = typeof to === 'string' ? to : to.pathname

      // do not redirect for home or index
      if (!pathname || pathname === '/') return null

      const redirect = ACTIVE_REDIRECT_RULES.find((r) => r.v2Regex.test(pathname))
      if (!redirect) return null

      return convertUrl(pathname, redirect.v2Regex, redirect.v1Format)
    },
    [ACTIVE_REDIRECT_RULES, convertUrl]
  )

  /**
   * Add redirect to local storage
   */
  const addLocalRedirect = useCallback(
    (key: string, v1Format: string, v2Format: string) => {
      const redirects = { ...LOCAL_REDIRECTS }
      redirects[key] = [0, 1, 1, v1Format, v2Format]

      localStorage.localRedirects = JSON.stringify(redirects)
    },
    [LOCAL_REDIRECTS]
  )

  /**
   * Remove redirect from local storage
   */
  const removeLocalRedirect = useCallback(
    (key: string) => {
      const redirects = { ...LOCAL_REDIRECTS }
      delete redirects[key]

      localStorage.localRedirects = JSON.stringify(redirects)
    },
    [LOCAL_REDIRECTS]
  )

  return {
    GLOBAL_REDIRECTS,
    LOCAL_REDIRECTS,
    ACTIVE_REDIRECT_RULES,
    FORCE_REDIRECT_RULES,
    addLocalRedirect,
    removeLocalRedirect,
    getV2Url,
    getV1Url
  }
}
