import { LINE_ITEM_FIELDS } from '@/constants/general'
import { DatePicker, Input, InputNumber, Select } from 'antd'
import dayjs from 'dayjs'

type FieldItem = (typeof LINE_ITEM_FIELDS)[number]

type Props = {
  fieldItem: FieldItem
  lineItem: any
  onChange: (value: any) => void
  disabled?: boolean
}

const disabledFields: FieldItem['field'][] = ['discountable_amount', 'taxable_amount']

export const DynamicField = ({ fieldItem, lineItem, onChange, ...props }: Props) => {
  const value = lineItem[fieldItem.field]

  const handleChange = (value: string | number | null) => {
    onChange({ [fieldItem.field]: value })
  }

  const componentName = fieldItem.component
  const disabled = disabledFields.includes(fieldItem.field) || props.disabled
  const options = 'options' in fieldItem && fieldItem.options ? fieldItem.options : []

  if (componentName === 'DatePicker') {
    return (
      <DatePicker
        defaultValue={value ? dayjs(value) : undefined}
        showTime={{
          minuteStep: 5,
          format: 'hh:mm A' // TODO: Use constant time format, but constant is not working with DayJS
        }}
        showNow={false}
        format="MM/DD/YYYY hh:mm A" // TODO: Use constant date/time format, but constant is not working with DayJS
        className="w-full"
        disabled={disabled}
        onOk={(date) => handleChange(date ? date.toISOString() : null)}
        data-cy="custom-form-line-item-date-picker"
      />
    )
  }

  if (componentName === 'Input') {
    return (
      <Input
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        data-cy="custom-form-line-item-input"
      />
    )
  }

  if (componentName === 'NumberInput') {
    return (
      <InputNumber
        defaultValue={value}
        className="w-full"
        disabled={disabled}
        onChange={(value) => handleChange(value)}
        type="number"
        data-cy="custom-form-line-item-number-input"
      />
    )
  }

  if (componentName === 'TextArea') {
    return (
      <Input.TextArea
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        rows={1}
        data-cy="custom-form-line-item-text-area"
      />
    )
  }

  if (componentName === 'Select') {
    return (
      <Select
        showSearch
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={options}
        defaultValue={value}
        onChange={(value) => handleChange(value)}
        disabled={disabled}
        data-cy="custom-form-line-item-select"
      />
    )
  }

  return null
}
