import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { editingComponentsAtom } from '../../../../atoms'
import { useCurrentKind } from '../../../../hooks'
import { LineItemComponent } from '../../../../schemas'

export const useComponentKind = (component: LineItemComponent) => {
  return useCurrentKind({
    type: 'table',
    category: {
      kindId: component.category__kind_id,
      parentKindId: component.category__parent_category__kind_id
    },
    options: {
      // todo: add options, see: AddEditButton.tsx
    }
  })
}

export const useEditingComponents = () => {
  const setEditingComponents = useSetAtom(editingComponentsAtom)

  const openEdit = useCallback(
    (itemId: number) => {
      setEditingComponents((prev) => [...prev, itemId])
    },
    [setEditingComponents]
  )

  const closeEdit = useCallback(
    (itemId: number) => {
      setEditingComponents((prev) => prev.filter((id) => id !== itemId))
    },
    [setEditingComponents]
  )

  return { openEdit, closeEdit }
}
