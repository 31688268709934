import { route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { TicketCalendarView } from './calendar'
import { TicketFormView } from './form/views'
import { layoutTicket } from './layout'
import { TicketListModuleView } from './list/views'

const ticketRoutes = [
  route('index', TicketListModuleView),
  route('v2', TicketListModuleView),
  route('v2/:formId/add', TicketFormView),
  route('v2/:formId/:ticketId/edit', TicketFormView)
]

export const routes: RouteObject[] = [
  route('tickets', layoutTicket('Tickets'), ticketRoutes),
  route('loads', layoutTicket('Loads'), ticketRoutes),
  route('m/:moduleId/t/:moduleSlug', layoutTicket('Tickets'), ticketRoutes),
  route('m/:moduleId/l/:moduleSlug', layoutTicket('Loads'), ticketRoutes),
  route('m2/:moduleId/tc/:moduleSlug', layoutTicket('Tickets Calendar'), [route('index', TicketCalendarView)]),

  // just so that force_v2_tickets works
  route('m2/:moduleId/t/:moduleSlug', layoutTicket('Tickets'), ticketRoutes),
  route('m2/:moduleId/l/:moduleSlug', layoutTicket('Loads'), ticketRoutes)
]
