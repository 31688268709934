import { useApp, useSession } from '@/hooks'
import { qboSessionApi } from '@/services/api-service'
import { Button, Modal } from '@/ui'
import { Spin } from '@/ui/spin'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Popconfirm, Space } from 'antd'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

export const ConnectQuickbookOnline = () => {
  const { company } = useSession()
  const { notification } = useApp()
  const [isOpen, setIsOpen] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const qboSessingConnectUrlMutation = useMutation(qboSessionApi.create(undefined, `${company.id}/connect`))
  const deleteQboSessionMutation = useMutation(qboSessionApi.delete())
  const [a, setA] = useState<any>(null)
  const [isEnabled, setIsEnabled] = useState(false)

  const refreshQboSessionMutation = useMutation({
    ...qboSessionApi.create(undefined, `${company.id}/refresh`),
    onError: (err) => {
      qboSessionEnabledQuery.refetch()
      notification.error({ message: 'Connection expired; please try connecting again' })
    }
  })

  const qboSessionEnabledQuery = useQuery({
    ...qboSessionApi.get<{ enabled: boolean }>(company.id),
    retry: (_, err: AxiosError) => {
      return err.response?.status !== 404
    }
  })

  const beginConnect = async () => {
    const url = await qboSessingConnectUrlMutation.mutateAsync({})
    setA(window.open(url))
    setIsWaiting(true)
  }

  const handleDisconnect = async () => {
    await deleteQboSessionMutation.mutateAsync({ id: company.id })
    notification.success({ message: 'Disconnected from QuickBooks Online' })
  }

  const handleRefresh = async () => {
    await refreshQboSessionMutation.mutateAsync({})
    notification.success({ message: 'Connection refreshed' })
  }

  useEffect(() => {
    if (qboSessionEnabledQuery.data?.enabled) {
      setIsEnabled(true)
    }

    if (qboSessionEnabledQuery.error && (qboSessionEnabledQuery.error as AxiosError).response?.status === 404) {
      setIsEnabled(false)
    }
  }, [qboSessionEnabledQuery.data?.enabled, qboSessionEnabledQuery.error])

  useEffect(() => {
    const handleEnabled = async () => {
      if (!a || !a.closed) {
        return
      }

      const { data } = await qboSessionEnabledQuery.refetch()

      if (data?.enabled) {
        setIsWaiting(false)
        setA(null)
        setIsOpen(false)
        notification.success({ message: 'Connected to QuickBooks Online' })
        return
      }

      setIsWaiting(false)
      setA(null)
      setIsOpen(false)
      notification.error({ message: 'Could not connect to QuickBooks Online' })
    }

    const interval = setInterval(handleEnabled, 1000)
    return () => clearInterval(interval)
  }, [isEnabled, a, isOpen, qboSessionEnabledQuery, notification])

  return (
    <>
      <div>
        {isEnabled ? (
          <Space>
            {' '}
            <Popconfirm
              title="Disconnect"
              description="Disconnect from QuickBooks Online?"
              onConfirm={handleDisconnect}
              okText="Disconnect"
            >
              <Button type="primary" danger>
                Disconnect from QuickBooks Online
              </Button>
            </Popconfirm>
            <Button type="primary" loading={refreshQboSessionMutation.isLoading} onClick={handleRefresh}>
              Refresh
            </Button>
          </Space>
        ) : (
          <Button type="primary" onClick={() => setIsOpen(true)} loading={qboSessionEnabledQuery.isLoading}>
            Connect to QuickBooks Online
          </Button>
        )}
      </div>
      <Modal open={isOpen} title="QuickBooks Online" footer={null} onCancel={() => setIsOpen(false)}>
        {isWaiting ? (
          <div className="p-24 flex w-full flex-col items-center justify-center">
            <Spin spinning />
            <div className="mb-16 mt-16">Waiting for QuickBooks Online...</div>
            <Button danger>Cancel</Button>
          </div>
        ) : (
          <div className="p-24 flex w-full flex-col items-center justify-center">
            <div className="mb-16">Click to start the authorization process</div>
            <Button type="primary" onClick={beginConnect}>
              Begin
            </Button>
          </div>
        )}
      </Modal>
    </>
  )
}
