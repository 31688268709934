import { APP_RELEASE } from '@/constants/app'
import { useAppSelector } from '@/hooks'
import { Popover } from '@/ui'
import React, { useMemo } from 'react'

export const VersionInfo: React.FC = () => {
  const authUser = useAppSelector((state) => state.session.user)

  const items = useMemo(
    () =>
      authUser
        ? [
            { label: 'User ID', value: authUser?.id },
            { label: 'Contact ID', value: authUser?.profile?.contact?.id },
            { label: 'Profile ID', value: authUser?.profile?.id },
            { label: 'Company ID', value: authUser?.profile?.company?.id }
          ]
        : [],
    [authUser]
  )

  return (
    <div className="flex fixed h-[12px] -top-[2px] right-[2px] z-50">
      <Popover
        trigger={'click'}
        placement={'leftTop'}
        content={
          items.length ? (
            <div className="flex flex-col gap-2 max-w-sm font-mono">
              {items.map((item, index) => (
                <div key={index} className="flex flex-row justify-between p-1 gap-8">
                  <span>{item.label}:</span>
                  <span className={'font-semibold'}>{item.value}</span>
                </div>
              ))}
            </div>
          ) : null
        }
        className="text-[10px] text-gray-400 bg-gray-100 dark:bg-dark-container px-8 rounded-b"
      >
        {APP_RELEASE} / {authUser?.username}
      </Popover>
    </div>
  )
}
