import { request } from '@/services/api-service/core'
import { ObjectReturn, RequestConfig } from '@/types/api/core'

export const _systemApi: {
  ping: (config?: RequestConfig) => ObjectReturn<any>
} = {
  ping: (config) => ({
    queryKey: ['system', 'ping'],
    queryFn: ({ signal }) => request({ ...config, method: 'GET', url: '/', signal: signal })
  })
}
