import { CategoryFormView, CategoryListView } from '@/modules/records/categories'
import { CategoryKindFormView, CategoryKindListView } from '@/modules/records/category-kinds'
import { JobCodeListView } from '@/modules/records/job-codes'
import { JobTypeListView } from '@/modules/records/job-types'
import { LocationFormView, LocationListView } from '@/modules/records/locations'
import { SkuFormView, SkuListView } from '@/modules/records/skus'
import { layout, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { AfeListView } from '../records/afes'
import { EditReportView } from '../reports/views/edit-report-view'
import { ContactsView } from '../rolodex/views/contacts-view'
import { AddEditCompanyView } from './views/add-edit-company-view'
import { AddEditOfficeView } from './views/add-edit-office-view'
import { AllCompaniesView } from './views/all-companies-view'
import { AllOfficesView } from './views/all-offices-view'
import { AllRegionsView } from './views/all-regions-view'
import { ProgrammedReportEtlEditorView } from './views/programmed-report-etl-editor-view'
import { ProgrammedReportsView } from './views/programmed-reports-view'
import { ViewCompanyView } from './views/view-company-view'

export const adminRoutes = [
  { path: '/afes', element: <AfeListView /> },
  {
    path: '/categories',
    element: <CategoryListView />
  },
  {
    path: '/categories/add',
    element: <CategoryFormView />
  },
  {
    path: '/categories/:id/edit',
    element: <CategoryFormView />
  },
  {
    path: '/category_kinds',
    element: <CategoryKindListView />
  },
  {
    path: '/category_kinds/add',
    element: <CategoryKindFormView />
  },
  {
    path: '/category_kinds/:id/edit',
    element: <CategoryKindFormView />
  },
  {
    path: '/contacts',
    element: <ContactsView showTabs={false} />
  },
  {
    path: '/companies',
    element: <AllCompaniesView />
  },
  {
    path: '/companies/add',
    element: <AddEditCompanyView />
  },
  {
    path: '/companies/:id/edit',
    element: <AddEditCompanyView />
  },
  {
    path: '/companies/:id/view',
    element: <ViewCompanyView />
  },
  {
    path: '/equipment',
    element: <SkuListView />
  },
  {
    path: '/equipment/add',
    element: <SkuFormView />
  },
  {
    path: '/equipment/:id/edit',
    element: <SkuFormView />
  },
  {
    path: '/job_codes',
    element: <JobCodeListView />
  },
  {
    path: '/job_types',
    element: <JobTypeListView />
  },
  {
    path: '/locations',
    element: <LocationListView />
  },
  {
    path: '/locations/add',
    element: <LocationFormView type="add" />
  },
  {
    path: '/locations/:id/edit',
    element: <LocationFormView type="edit" />
  },
  {
    path: '/offices',
    element: <AllOfficesView />
  },
  {
    path: '/offices/add',
    element: <AddEditOfficeView />
  },
  {
    path: '/offices/:id/edit',
    element: <AddEditOfficeView />
  },
  {
    path: '/regions',
    element: <AllRegionsView />
  }
  // {
  //   path: '/programmed_reports',
  //   element: <ProgrammedReportsView />
  // },
  // {
  //   path: '/programmed_reports/:prId/:id/edit',
  //   element: <EditReportView />
  // },
  // {
  //   path: '/programmed_reports/:id/etl',
  //   element: <ProgrammedReportEtlEditorView />
  // }
]

export const routes: RouteObject[] = [
  route('programmed_reports', layout('ProgrammedReportsLayout', 'nav__reports_programmed'), [
    route('index', ProgrammedReportsView),
    route(':prId/:id/edit', EditReportView),
    route(':id/etl', ProgrammedReportEtlEditorView)
  ])
]
