import { layout, redirect, route } from '@/routing'
import { RouteObject } from 'react-router-dom'
import { ReportsLayout } from './layout'
import {
  AddEditScheduledReportView,
  CustomReportsView,
  EditReportView,
  ProgrammedReportsView,
  ReportsModuleView,
  ScheduledReportsView
} from './views'

export const routes: RouteObject[] = [
  route('reports', ReportsLayout, [
    route('index', redirect('custom_reports')),
    route('custom_reports', CustomReportsView),
    route('programmed_reports', ProgrammedReportsView),
    route('scheduled_reports', layout('ScheduledReportsLayout', 'ScheduledReports'), [
      route('index', ScheduledReportsView),
      route('edit/:id', AddEditScheduledReportView),
      route('add', AddEditScheduledReportView)
    ]),
    route(':id', EditReportView)
  ]),
  route('/m/:moduleId/r/:moduleSlug', ReportsModuleView)
]
