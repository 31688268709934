import { useSession } from '@/hooks'
import { AuthUser } from '@/types/auth-user'
import { FormInstance } from 'antd'
import { FC, ReactElement, createContext, useContext, useMemo } from 'react'
import { transformTicket } from './helpers'
import { CustomForm, Ticket } from './schemas'

export type TTicketFormContext = {
  ticketForm: FormInstance<any>

  ticketId: number
  customFormId: number

  customForm: CustomForm
  ticket: Ticket

  options?: {
    disableModifiedCheck?: boolean
  }
}

export const TicketFormContext = createContext<TTicketFormContext | undefined>(undefined)

export const TicketFormProvider: FC<TTicketFormContext & { children?: ReactElement }> = ({ children, ...props }) => {
  const { user } = useSession()

  props.ticket = useMemo(
    () => transformTicket(props.ticket, props.customForm, user as AuthUser),
    [props.ticket, props.customForm, user]
  )

  return <TicketFormContext.Provider value={props}>{children}</TicketFormContext.Provider>
}

export const useTicketFormContext = () => {
  const context = useContext(TicketFormContext)
  if (!context) throw new Error('useTicketFormContext must be used within a TicketFormProvider')

  return context
}
