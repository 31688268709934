import { Form, Input, InputRef, Switch } from 'antd'
import { useSetAtom } from 'jotai'
import { useRef } from 'react'
import { configurationAtom } from '../../../../../atoms'
import { useLineItemFieldName } from '../../../../hooks'
import { useSortableItemContext } from '../../../context'

export const CustomName = () => {
  const { lineItemField } = useSortableItemContext()
  const inputRef = useRef<InputRef>(null)
  const { getLineItemFieldName } = useLineItemFieldName()
  const setConfiguration = useSetAtom(configurationAtom)

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      inputRef.current?.focus()

      setConfiguration((configuration) => {
        return {
          ...configuration,
          field_aliases: {
            ...configuration.field_aliases,
            [lineItemField]: ''
          }
        }
      })
    } else {
      setConfiguration((configuration) => {
        const { [lineItemField]: _, ...field_aliases } = configuration.field_aliases || {}

        return {
          ...configuration,
          field_aliases: {
            ...field_aliases
          }
        }
      })
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setConfiguration((configuration) => {
      return {
        ...configuration,
        field_aliases: {
          ...configuration.field_aliases,
          [lineItemField]: value
        }
      }
    })
  }

  const { isAlias, name } = getLineItemFieldName(lineItemField)

  return (
    <Form layout="vertical">
      <Form.Item label="Name" name="field_alias" className="mb-12">
        <div className="flex items-center gap-x-8">
          <Input
            ref={inputRef}
            value={name}
            disabled={!isAlias}
            onChange={handleInputChange}
            data-cy="custom-name-input"
          />
          <div className="flex items-center gap-x-6">
            <Switch onChange={handleSwitchChange} checked={isAlias} data-cy="custom-name-switch" />
            <div>Custom</div>
          </div>
        </div>
      </Form.Item>
    </Form>
  )
}
