import { queryClient } from '@/query'
import { QueryFilters } from '@tanstack/react-query'

export const useRefetchTickets = () => {
  const refetchTickets = (filters?: QueryFilters) => {
    queryClient.refetchQueries({ ...filters, queryKey: ['tickets'], type: filters?.type || 'active' })
    queryClient.refetchQueries({ ...filters, queryKey: ['v2/tickets'], type: filters?.type || 'active' })
  }

  return { refetchTickets }
}
