import { useSession } from '@/hooks'
import { view } from '@/routing'
import { Spin } from 'antd'
import { useCustomFormQuery } from '../../hooks'
import { DescriptionField } from './description-field'
import { FormIconField } from './form-icon-field'
import { NameField } from './name-field'
import { PrimaryCompanyField } from './primary-company-field'
import { TypeField } from './type-field'

export const Title = view(Component, {
  title: () => 'Title',
  scoped: false
})

function Component() {
  const { user } = useSession()
  const { customFormQuery } = useCustomFormQuery()

  return (
    <Spin spinning={customFormQuery.isLoading}>
      <div className="grid grid-cols-3 gap-x-12">
        <NameField />
        {user?.is_superuser && <PrimaryCompanyField />}
        <TypeField />
        <FormIconField />
        <DescriptionField />
      </div>
    </Spin>
  )
}
